<ion-header>
  <ion-toolbar>
    <ion-toolbar>
      <ion-title>
        {{ 'template share' | translate }}
      </ion-title>
      <ion-buttons class="m-0 mr-2" slot="end">
        <ion-button (click)="close()">
          <ion-icon name="close-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div #shareLinkCopyContainerInTemplate>
    <!--  모달에서 카피 안되는 이슈는 아래 container 선언으로 해결 가능 https://github.com/maxisam/ngx-clipboard/issues/145 -->
    <ion-button color="main" mode="ios" expand="block" ngxClipboard [container]='shareLinkCopyContainerInTemplate' style='margin: 12px 16px'
                (cbOnError)='copyError($event)'
                (cbOnSuccess)="copied($event)" [cbContent]=url
                matRipple>
      {{ 'copy link' | translate }}
    </ion-button>
  </div>
</ion-content>
