import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { UserService } from './user.service';
import { GraphqlApiService } from './api/graphql.api.service';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ApolloQueryResult, gql, FetchResult } from '@apollo/client';
import { map, tap } from 'rxjs/operators';
import { Canvas } from '../model/canvas/canvas';
import { ExecutionResult } from 'graphql';
import { CountryCode, LanguageType } from '../enum/app.enum';

@Injectable({
  providedIn: 'root'
})
export class CanvasService {
  constructor(public app: AppService, public userService: UserService, public graphql: GraphqlApiService, public alertController: AlertController) {}

  /**
   * 사용자가 생성한 canvas 의 총 수만 가져 온다.
   * 24684개 count 로 가져오기까지 32.9ms 가 걸린다.
   * @param {number}userId 사용자 아이디
   * @return {Observable<ApolloQueryResult<number>>}
   */
  canvasesCount(userId: number): Observable<ApolloQueryResult<number>> {
    return this.graphql
      .query(
        gql`
          query canvasesCount($userId: ID!) {
            canvasesCount(userId: $userId)
          }
        `,
        {
          userId
        }
      )
      .pipe(
        tap((result) => {
          result.data = +result.data.canvasesCount;
          return result;
        })
      );
  }

  /**
   * 어드민에서만 사용하는 함수로 캔버스 총 수와 포인트의 합산을 가져온다.
   * @param {number} userId
   * @return {Observable<ApolloQueryResult<number>>}
   */
  canvasesAndPointCount(userId: number): Observable<ApolloQueryResult<number>> {
    return this.graphql
      .query(
        gql`
          query CanvasesAndPoints($userId: ID!) {
            canvasesCount: canvasesCount(userId: $userId)
            points: points(userId: $userId) {
              sum
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        tap((result) => {
          result.data.pointSum = result.data.points.sum;
          return result;
        })
      );
  }

  /**
   * 캔버스 생성
   * @param {Canvas} data 생성할 캔버스 정보
   * @param {boolean} isCreateTextTemplate 텍스트템플릿인지 체크
   * @param {number} canvasGroupId 캔버스 만들어줄 폴더그룹 아이디
   * @return {Promise<void>}
   */
  async createCanvas(data: Canvas, isCreateTextTemplate: boolean, canvasGroupId?: number): Promise<any> {
    const temp: any = await this.graphql
      .mutate(
        gql`
          mutation canvasCreate($data: InputCanvasCreate!, $isCreateTextTemplate: Boolean!, $canvasGroupId: ID) {
            canvasCreate(data: $data, isCreateTextTemplate: $isCreateTextTemplate, canvasGroupId: $canvasGroupId)
          }
        `,
        {
          data,
          isCreateTextTemplate,
          canvasGroupId
        }
      )
      .toPromise();
    return temp.data.canvasCreate;
  }

  /**
   * 캔버스 업데이트
   * @param {Canvas} data 생성할 캔버스 정보
   * @param {boolean} isCreateTextTemplate 텍스트템플릿인지 체크
   * @param {Blob[]} files 업데이트 할 이미지 blob 파일
   */
  canvasUpdate(data: Canvas, isCreateTextTemplate: boolean = false, files?: Blob[]): Observable<FetchResult<any>> {
    let result;
    if (files) {
      result = this.graphql
        .mutate(
          gql`
            mutation canvasUpdate($data: InputCanvasUpdate!, $isCreateTextTemplate: Boolean!, $files: [Upload!]!) {
              canvasUpdate(data: $data, isCreateTextTemplate: $isCreateTextTemplate, files: $files)
            }
          `,
          {
            data,
            isCreateTextTemplate,
            files
          }
        )
        .pipe(
          map((results) => {
            return results;
          })
        );
    } else {
      result = this.graphql
        .mutate(
          gql`
            mutation canvasUpdate($data: InputCanvasUpdate!, $isCreateTextTemplate: Boolean!) {
              canvasUpdate(data: $data, isCreateTextTemplate: $isCreateTextTemplate)
            }
          `,
          {
            data,
            isCreateTextTemplate
          }
        )
        .pipe(
          map((results) => {
            return results;
          })
        );
    }
    return result;
  }

  /**
   * 캔버스 삭제
   * @param {number} id
   * @return {Observable<FetchResult<any>>}
   */
  canvasDelete(id: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation canvasDelete($id: ID!) {
          canvasDelete(id: $id)
        }
      `,
      {
        id
      }
    );
  }

  /**
   * 캔버스 한개를 갖고 온다
   * @param {number} id 캔버스 id
   * @param {boolean} withoutCDN
   * @return {Promise<ApolloQueryResult<any>>}
   */
  getCanvas(id: number, withoutCDN: boolean = false): Promise<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query canvas($id: ID!) {
            canvas(id: $id) {
              id
              userId
              title
              shareStatus
              authorization
              panelList
              base64
              canvasSize
              laboratory
              deleteStatus
              outsideBGColor
              pages {
                id
                base64
                order
              }
              tags {
                id
                name
              }
              orientation
              owner {
                userName
              }
              createdDate
            }
          }
        `,
        {
          id,
          withoutCDN
        }
      )
      .pipe(
        tap((results) => {
          results.data.canvas.base64 = results.data.canvas.base64 + '/200x';
          // 큰창으로 미리보기 썸네일
          results.data.canvas.pages.map((page) => {
            if (withoutCDN) {
              page.base64 = page.base64 + '?v=' + new Date().getTime();
            } else {
              page.base64 = page.base64 + '/900x';
            }
          });
        }),
        map((results) => {
          results.data = results.data.canvas;
          return results;
        })
      )
      .toPromise();
  }

  /**
   * 다른 지역의 db에서 캔버스 한개를 갖고 온다
   * @param {number} id 캔버스 id
   * @param {CountryCode} country 국가 코드
   * @param {boolean} withoutCDN
   * @return {Promise<ApolloQueryResult<any>>}
   */
  canvasWithCountry(id: number, country: CountryCode, withoutCDN: boolean = false): Promise<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query canvas($id: ID!, $country: String) {
            canvasWithCountry(id: $id, country: $country) {
              id
              userId
              title
              shareStatus
              authorization
              panelList
              base64
              canvasSize
              laboratory
              deleteStatus
              outsideBGColor
              pages {
                id
                base64
                order
              }
              tags {
                id
                name
              }
              orientation
              owner {
                userName
              }
              createdDate
            }
          }
        `,
        {
          id,
          country,
          withoutCDN
        }
      )
      .pipe(
        tap((results) => {
          results.data.canvasWithCountry.base64 = results.data.canvasWithCountry.base64 + '/200x';
          // 큰창으로 미리보기 썸네일
          results.data.canvasWithCountry.pages.map((page) => {
            if (withoutCDN) {
              page.base64 = page.base64 + '?v=' + new Date().getTime();
            } else {
              page.base64 = page.base64 + '/900x';
            }
          });
        }),
        map((results) => {
          results.data = results.data.canvasWithCountry;
          return results;
        })
      )
      .toPromise();
  }

  /**
   * 사용자의 canvas 가 삭제 되었는지 확인 한다.
   * 9.1ms 걸린다.
   * @param {number} userId 사용자 아이디
   * @param {number} canvasId 사용자 아이디
   * @return {Observable<ApolloQueryResult<boolean>>}
   */
  isCanvasDeleted(userId: number, canvasId: number): Observable<ApolloQueryResult<boolean>> {
    return this.graphql
      .query(
        gql`
          query isCanvasDeleted($userId: ID!, $canvasId: ID!) {
            isCanvasDeleted(userId: $userId, canvasId: $canvasId)
          }
        `,
        {
          userId,
          canvasId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.isCanvasDeleted;
        })
      );
  }

  /**
   * 나의 작업 페이지에서 작업물 정보를 얻는 쿼리 요청 함수
   * @param {number} userId 사용자 아이디
   * @param {number} skip 이전에 불러온 작업물의 개수를 체크해서 제외하고 받음
   * @param {number} take 요청하는 작업물 개수
   * @param {LanguageType} languageType 언어
   * @param {string} userRole 사용자 룰
   * @param {string} sort 정렬 기준
   * @param {string} order 내림차순, 오름차순
   * @param {string} searchText 제목 검색에 사용할 텍스트
   * @param {number} canvasGroupId 폴더 뷰일 경우 폴더아이를 사용해서 관련 작업물만 불러옴
   * @return {Observable<ApolloQueryResult<any>>}
   */
  searchCanvas(
    userId: number,
    skip: number,
    take: number,
    languageType: LanguageType,
    userRole: string,
    sort: string,
    order: string,
    searchText?: string,
    canvasGroupId?: number
  ): Observable<ApolloQueryResult<any>> {
    const searchCanvasInfo = {
      userId,
      skip,
      take,
      languageType,
      userRole,
      sort,
      order,
      searchText,
      canvasGroupId
    };
    return this.graphql
      .query(
        gql`
          query searchCanvas($searchCanvasInfo: InputSearchCanvasInfo!) {
            searchCanvas(searchCanvasInfo: $searchCanvasInfo) {
              id
              title
              shareStatus
              base64
              userId
              authorization
              updatedDate
              template {
                id
                status
                meme {
                  status
                }
              }
              textTemplate {
                id
                status
              }
              group {
                id
                role
                groupName
              }
            }
          }
        `,
        {
          searchCanvasInfo
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.searchCanvas;
          result.data.forEach((canvas, index) => {
            if (index === 0) {
              // https://github.com/toonsquare/tooning-repo/pull/4122#issuecomment-1277216828
              // 나의 작업 물로 이동 했을때 첫번째 캔버스의 썸네일을 브라우져가 캐쉬 하지 못하게하는 코드
              // 참고로 pageUpdate 할때 cloudfront cache 를 모두 삭제한다 (invalidation)
              // 결국은 브라우져 캐쉬만 남은 상황이고 매번 새로운 url 로 인식시켜주기 위해 사용
              canvas.base64 = canvas.base64 + `?v=${new Date().getTime()}`;
            }
          });
          return result;
        })
      );
  }

  /**
   * 캔버스 복제
   * @param {number} id 복제 할 캔버스 아이디
   * @param {number} userId 캔버스 소유 유저 아이디
   * @param {boolean} isTextTemplate 텍스트템플릿인지 체크
   * @param {number} canvasGroupId 캔버스가 들어있는 폴더 그룹 아이디
   */
  canvasClone(
    id: number,
    userId: number,
    isTextTemplate: boolean,
    canvasGroupId?: number
  ): Promise<ExecutionResult<any> & { extensions?: Record<string, any>; context?: Record<string, any> }> {
    return this.graphql
      .mutate(
        gql`
          mutation canvasClone($id: ID!, $userId: ID!, $isTextTemplate: Boolean!, $canvasGroupId: Int) {
            canvasClone(id: $id, userId: $userId, isTextTemplate: $isTextTemplate, canvasGroupId: $canvasGroupId) {
              canvasId
              result
            }
          }
        `,
        {
          id,
          userId,
          isTextTemplate,
          canvasGroupId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.canvasClone;
          return results;
        })
      )
      .toPromise();
  }

  /**
   * 다른 지역 db에서 캔버스 복제
   * @param {number} id 복제 할 캔버스 아이디
   * @param {number} userId 캔버스 소유 유저 아이디
   * @param {boolean} isTextTemplate 텍스트템플릿인지 체크
   * @param {CountryCode} country 복제해올 캔버스가 있는 db 지역
   * @param {number} canvasGroupId 캔버스가 들어있는 폴더 그룹 아이디
   * @return {Promise<ExecutionResult<any>}
   */
  canvasCloneWithTargetRegion(
    id: number,
    userId: number,
    isTextTemplate: boolean,
    country: CountryCode,
    canvasGroupId?: number
  ): Promise<ExecutionResult<any> & { extensions?: Record<string, any>; context?: Record<string, any> }> {
    return this.graphql
      .mutate(
        gql`
          mutation canvasCloneWithTargetRegion($id: ID!, $userId: ID!, $isTextTemplate: Boolean!, $country: String, $canvasGroupId: Int) {
            canvasCloneWithTargetRegion(id: $id, userId: $userId, isTextTemplate: $isTextTemplate, country: $country, canvasGroupId: $canvasGroupId) {
              canvasId
              result
            }
          }
        `,
        {
          id,
          userId,
          isTextTemplate,
          country,
          canvasGroupId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.canvasCloneWithTargetRegion;
          return results;
        })
      )
      .toPromise();
  }

  /**
   * 캔버스의 썸네일을 업데이트 한다
   * @param {number} canvasId - 캔버스 아이디
   * @returns {Promise<boolean>}
   */
  async updateCanvasB64(canvasId: number): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation updateCanvasB64($canvasId: ID!) {
            updateCanvasB64(canvasId: $canvasId)
          }
        `,
        {
          canvasId
        }
      )
      .toPromise();

    return result;
  }
}
