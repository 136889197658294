import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class Canvas {
  @IsNumber() @IsNotEmpty() id: number;
  @IsNumber() @IsNotEmpty() userId: number;
  @IsString() @IsNotEmpty() canvasSize: string;
  @IsString() @IsOptional() title?: string;
  @IsString() @IsOptional() panelList?: string;
  @IsString() @IsOptional() base64?: string;
  @IsString() @IsOptional() authorization?: string;
  @IsString() @IsOptional() shareStatus?: string;
  @IsString() @IsOptional() orientation?: string;
  @IsString() @IsOptional() laboratory?: string;
  @IsString() @IsOptional() outsideBGColor?: string;

  // tslint:disable-next-line:variable-name

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
