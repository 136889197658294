import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { AlertController, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { PaymentService } from '../../services/payment/payment.service';
import { CanvasService } from '../../services/canvas.service';
import { AnalyticsService } from '../../services/google/analytics/analytics.service';
import { GoogleService } from '../../services/google/auth/google.service';
import { UserLoginType } from '../../enum/UserLoginType.enum';
import { GoogleUser } from '../../model/user/google/googleUser';

import * as _ from 'lodash';
import {
  CustomLoginUserInfoInLocalStorage,
  CustomUserDefaultMetaData,
  KeyBoard,
  LanguageType,
  ModalCloseType,
  ServiceType,
  SocialLoginMessageType,
  UserPageViewType,
  UserRole,
  VerificationType
} from '../../enum/app.enum';
import { PaidReferralComponent } from '../../components/paid-referral/paid-referral.component';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import {
  TooningBadRequestError,
  TooningChangeViewTypeClickHandlerError,
  TooningCloneError,
  TooningCloseError,
  TooningCoolSchoolLoginGoError,
  TooningCustomJoinButtonClickHandlerError,
  TooningCustomLoginValidationError,
  TooningDecodeJwtResponseError,
  TooningErrorCode,
  TooningGetUniquRandomNumberError,
  TooningGoToSnsAgreementPageError,
  TooningHandleCredentialResponseError,
  TooningIonViewDidEnter,
  TooningLoginGoogleError,
  TooningLoginUIError,
  TooningPasswordViewModeChangeError,
  TooningReRenderGoogleLoginButtonError,
  TooningServerConnectionError,
  TooningSignUpCompleteModalError,
  TooningSnsSignupError,
  TooningUnauthorizedError,
  TooningUnkownNetworkError,
  TooningWhalespaceLoginGoError
} from '../../pages-tooning/errors/TooningErrors';
import { CustomLoginUser } from '../../model/user/cutom/cutomUser';
import { validate } from 'class-validator';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { KakaoServiceService } from 'src/app/services/kakao-service.service';
import { WhalespaceServiceService } from 'src/app/services/whalespace-service.service';
import { AppleUser } from '../../model/user/apple/appleUser';
import { environment } from 'src/environments/environment';
import { Cut4MakeManualService } from '../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { BasePage } from '../../base-page';
import { makeCloudWatchLogStream, makeLogStreamName } from '../../shared/utils/awsCloudWatch';
import { slack } from '../../shared/utils/slack';
import { CoolSchoolService } from 'src/app/services/cool-school.service';
import { ChangeLanguageService } from '../../services/changeLanguage.service';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import { FacebookAuthResponse, SnsSignupInfo } from '../../interfaces/app.interface';
import { RegexService } from '../../services/regex.service';
import { ModalSignupCompleteComponent } from '../modal-signup-complete/modal-signup-complete.component';
import { GptService } from '../../services/ai/gpt.service';
import HttpStatusCode from '../../pages-tooning/enums/httpErrors.enum';
// registerWebPlugin(FacebookLogin);

declare let FB;

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage extends BasePage implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('isModal') isModal;
  @Input('isSignup') isSignup;
  @Input('isDemo') isDemo;
  @Input('canvasId') canvasId;
  // @ts-ignore
  @ViewChild('loginEmailInput') loginEmailInput: ElementRef;
  @ViewChild('customLoginWrapper') customLoginWrapper: ElementRef;

  public isLogin = false;
  public device;
  public isLoginProgressing = false;
  public viewType: UserPageViewType = UserPageViewType.loginSocial; // 로그인 /회원가입
  public showAppleSignIn = false;
  public safariPopupNotice = false;
  public customLoginUser: CustomLoginUser;
  public isSuccessLogin = false;
  public LanguageType = LanguageType;
  public appVersion = '';
  public CustomUserDefaultMetaData = CustomUserDefaultMetaData;
  public isFromLanding = this.googleService.isFromLanding;
  private hasValidationError = false;
  private keydownObservable$: Observable<Event>;
  private hasCustomLoginError = false;
  private customLoginAlert: HTMLIonAlertElement;
  private popstateObservable$: Observable<Event>;
  private usedLanguage: string;
  private whaleEventLister;
  private coolSchoolEventLister;
  private indexOfUniqueNumber: number;
  public userPageViewType = UserPageViewType;
  public language_locale = {
    ko: 'ko_KR',
    en: 'en_US',
    fr: 'fr_FR',
    jp: 'ja_JP'
  };
  public windowResizeObservable$: Observable<Event>;
  public googleLoginButtonWidth: number = 340;
  public passwordIsView: boolean = false;
  public readyToGoogleButtonRender: boolean = false;
  public snsInfo: SnsSignupInfo;
  private kakaoLoginClick$ = new Subject<string>();
  private _googleLoginHandler: any;

  // tslint:disable-next-line:variable-name
  constructor(
    public app: AppService,
    private userService: UserService,
    public kakao: KakaoServiceService,
    public whalespace: WhalespaceServiceService,
    public route: ActivatedRoute,
    public modalCtrl: ModalController,
    public canvasService: CanvasService,
    private paymentService: PaymentService,
    private googleService: GoogleService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private alertController: AlertController,
    public dialog: MatLegacyDialog,
    private cut: Cut4MakeManualService,
    public coolSchool: CoolSchoolService,
    private router: Router,
    public changeLanguageService: ChangeLanguageService,
    private regexService: RegexService,
    private errorHandlerService: ErrorHandlerService,
    public gptService: GptService
  ) {
    super(app);
    try {
      this.subscriptions.push(
        this.kakaoLoginClick$.pipe(debounceTime(150)).subscribe(async (goUrl: string = this.app.hereUrl) => {
          this._kakaoLoginGo(goUrl);
        })
      );
      this.device = this.app.getPlatform();
      this.showAppleSignIn = this.device === 'ios';
      this.getUniqueNumber();
    } catch (e) {
      console.error(e.message);
    }
  }

  async ngOnInit() {
    super.ngOnInit();
    try {
      this.isLoginProgressing = false;
      this.isSuccessLogin = false;
      this.safariPopupNotice = false;
      this.app.dimmer = false;
      this.customLoginUser = new CustomLoginUser();
      this.isLogin = this.app.user.isTokenSaved();
      this.usedLanguage = this.getUsedLanguage();
      this.appVersion = environment.appVersion;

      window.history.pushState({ modal: true }, null);
      this.popstateObservable$ = fromEvent(window, 'popstate');
      this.subscriptions.push(
        this.popstateObservable$.subscribe(async (event) => {
          await this.popstateHandler(event);
        })
      );
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 구글과 페이스북을 제외한 소셜 로그인 핸들링 함수
   * @param {UserLoginType} loginType
   * @param {string} accessToken
   * @param event
   * @return {Promise<any>}
   */
  async handleSocialLogin(loginType: UserLoginType, accessToken: string, event: any): Promise<any> {
    const { result, user } = await this.app.user.isAlreadySignUpUser(loginType, accessToken, null);

    const socialHandler = {
      [UserLoginType.coolSchool]: () => this.coolSchoolMessageHandler(event),
      [UserLoginType.whalespace]: () => this.whaleMessageHandler(event),
      [UserLoginType.kakao]: () => this.kakaoLoginGo(event)
    };

    if (!socialHandler[loginType]) {
      return;
    }

    if (!result) {
      const fetchResult = await this.userService.checkAndSetServerRegion(loginType, true, accessToken, null);
      if (fetchResult) {
        await socialHandler[loginType]();
      } else {
        // 회원가입 안 되어있는 사용자인 경우, 회원가입으로 유도
        await this.goToSnsAgreementPage(loginType, accessToken, null, null);
      }

      return;
    }

    return user;
  }

  /**
   * popstate 가 fire 되면 modal 을 닫습니다.
   * @param $event
   * @return {Promise<void>}
   */
  async popstateHandler($event: any) {
    if (this.app.modal !== null) {
      await this.app.modal.dismiss(ModalCloseType.canceled);
    }
  }

  /**
   * 로그인 팝업이 떴을 때,  keyboard down 이벤트를 처리하는 핸들러 입니다.
   * custom login 이 문제가 있는 경우, alert 메세지가 떠있을 경우 enter 키를 누르면 alert 를 dismiss 시킵니다.
   * custom login 이 문제가 없는 경우는 enter 키를 누르면 바로 로그인을 시도합니다.
   * keyCode 가 앞으로 지원이 안된다고 하여서 key로 변경 https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
   * @param {KeyboardEvent} event
   * @return {Promise<void>}
   */
  async onKeyDown(event: KeyboardEvent): Promise<void> {
    try {
      if (!event.key) return;
      if (!this.hasCustomLoginError) {
        if (event.key === KeyBoard.enter && this.customLoginUser.email && this.customLoginUser.password) {
          await this.loginCustom();
          return;
        }
      }
      if (this.customLoginAlert) {
        if (event.key === KeyBoard.enter) {
          await this.customLoginAlert.dismiss('keyboard', 'OK');
          this.customLoginAlert = null;
          this.hasCustomLoginError = false;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 키보드 이벤트 handler 를 세팅합니다. 1초안에 오는 키보드는 debounce 시킵니다.
   * @return {void}
   */
  addKeyboardEvents(): void {
    this.keydownObservable$ = fromEvent(window, 'keydown');
    this.subscriptions.push(
      this.keydownObservable$.pipe(debounceTime(500)).subscribe(async (event: KeyboardEvent) => {
        if (this.viewType === UserPageViewType.loginCustom) {
          await this.onKeyDown(event);
        }
      })
    );
  }

  async ionViewWillEnter() {
    this.customLoginUser = new CustomLoginUser();
    this.hasValidationError = false;
    this.addKeyboardEvents();

    const reactive = this.route.snapshot.params.reactive;
    if (reactive === 'reactive') {
      this.app.orange(`reactive : ${reactive}`);
      await this.app.logout();
    } else {
      this.app.orange('reactive empty');
    }
  }

  ionViewDidEnter() {
    super.ionViewDidEnter();

    try {
      if (!this.app.isEditorOpen) {
        this.app.channelTalkShow();
      }

      const isMagic = this.route.snapshot.queryParams.isMagic;
      if (isMagic) this.app.currentService = ServiceType.Magic;

      this.viewType = UserPageViewType.loginSocial;
      this.isSignup = this.isSignup ? this.isSignup : this.router.url.includes(UserPageViewType.signup);

      this.getLoginButtonWidth().then(async (returnWidth) => {
        this.googleLoginButtonWidth = returnWidth;
        await this.loginGoogle();
      });

      this.windowResizeObservable$ = fromEvent(window, 'resize');
      this.subscriptions.push(
        this.windowResizeObservable$.subscribe(async ($event) => {
          this.getLoginButtonWidth().then(async (returnWidth) => {
            this.googleLoginButtonWidth = returnWidth;
            await this.reRenderGoogleLoginButton();
          });
        })
      );

      this.subscriptions.push(
        this.changeLanguageService.usedLanguageSubject$.subscribe(() => {
          this.reRenderGoogleLoginButton();
        })
      );
    } catch (e) {
      throw new TooningIonViewDidEnter(e, this.app, true);
    } finally {
      this.readyToGoogleButtonRender = true;
    }
  }

  /**
   * 소셜 로그인 이용약관 페이지로 이동
   * @param {UserLoginType} kind
   * @param {string | null} accessToken
   * @param {GoogleUser | null} googleUser
   * @param {AppleUser | null} appleUser
   * @return {Promise<void>}
   */
  async goToSnsAgreementPage(
    kind: UserLoginType,
    accessToken: string | null,
    googleUser: GoogleUser | null,
    appleUser: AppleUser | null
  ): Promise<void> {
    try {
      const { email } = await this.app.user.getSocialUserEmail(kind, accessToken, googleUser);

      this.snsInfo = { type: kind, email, accessToken, googleUser, appleUser };
      this.viewType = this.userPageViewType.loginSocialAgreement;
    } catch (e) {
      throw new TooningGoToSnsAgreementPageError(e);
    }
  }

  ionViewDidLeave() {
    super.ionViewDidLeave();
    this.isLoginProgressing = false;
    this.app.blue('login.page.ionViewDidLeave');
  }

  /**
   * 전달받은 유저 정보를 투닝에 맞게 넣어줌
   * @param user
   * @returns {any} 유저 정보
   */
  makeTooningUserData(user: any): GoogleUser {
    const tooningUser = new GoogleUser();
    tooningUser.id = user.jti.toString();
    tooningUser.displayName = user.name;
    tooningUser.email = user.email;
    tooningUser.photoURL = user.picture;
    tooningUser.phoneNumber = _.isEmpty(user.phoneNumber) ? '' : user.phoneNumber;

    return tooningUser;
  }

  /**
   * error 메세지 처리
   * @param {string} message
   * @return {Promise<void>}
   */
  async errorShowToast(message: string): Promise<void> {
    this.app.red(message);
    await this.app.showToast(message);
  }

  /**
   * social login 시 Tooning 쪽에서 발생하는 에러를 처리한다.
   * @param error
   * @return {Promise<void>}
   */
  async socialLoginErrorHandler(error): Promise<void> {
    try {
      this.errorHandlerService.loginErrorHandler(error);
    } catch (e) {
      if (e instanceof TooningBadRequestError) {
        await this.app.showToast(this.translate.instant('bad request'));
      } else if (e instanceof TooningUnauthorizedError) {
        this.app.orange('unauthorized access');
        this.app.redirectLogin();
      } else if (e instanceof TooningServerConnectionError) {
        this.customLoginAlert = await this.app.presentAlert(this.translate.instant('networkStatus.unknown'));
        await this.app.logout(false);
      } else if (e instanceof TooningUnkownNetworkError) {
        await this.errorShowToast(e.message);
      } else {
        await this.errorShowToast(e.message);
      }
      throw e;
    }
  }

  /**
   * 카카오 로그인 debounceTime 호출
   * @param {string} goUrl
   * @return {void}
   */
  kakaoLoginGo(goUrl: string = this.app.hereUrl): void {
    this.kakaoLoginClick$.next(goUrl);
  }

  /**
   * 카카오 로그인
   * @param {string} goUrl
   * @return {Promise<void>}
   */
  private async _kakaoLoginGo(goUrl: string = this.app.hereUrl): Promise<void> {
    try {
      await this.app.delay(250);
      //카카오 호출에서 로딩 다이어로그 뛰우지 말것!!!!!!1
      const socialAccessToken = await this.kakao.login();
      //카카오 호출에서 로딩 다이어로그 뛰우지 말것!!!!!!1

      if (!socialAccessToken) {
        return;
      }

      this.app.cache.setIsUnlink(false);

      // 회원가입 되어있는 유저인 지 확인
      const user = await this.handleSocialLogin(UserLoginType.kakao, socialAccessToken, goUrl);
      if (!user) {
        return;
      }

      const { token } = await this.app.user.doSignIn(+user.id, UserLoginType.kakao, this.usedLanguage, socialAccessToken, null);
      await this.setCacheAfterLogin(token, UserLoginType.kakao, goUrl);
      await this.cut.goUrl(this.isDemo, this.app.cache.user.id, this.canvasId, this.app.currentService);

      this.isSuccessLogin = true;
    } catch (error) {
      this.analyticsService.error('login.page.kakaoLoginGo.catch', error.message);
      await this.socialLoginErrorHandler(error);
    }
  }

  /**
   * 웨일스페이스 메세지 이벤트 핸들러
   * @param {any} e 메세지 이벤트
   * @return {Promise<void>}
   */
  async whaleMessageHandler(e: any): Promise<void> {
    try {
      this.isLoginProgressing = true;
      if (!e.data?.hasOwnProperty('messageType')) {
        return;
      }

      if (e.data.messageType === SocialLoginMessageType.popupClosed) {
        window.removeEventListener('message', this.whaleEventLister);
        this.isSuccessLogin = true;

        return;
      }

      if (!e.data.accessToken) {
        throw new Error('WhaleSpace accessToken is empty');
      }
      if (e.data.loginType !== UserLoginType.whalespace) {
        throw new Error('WhaleSpace loginType is empty');
      }

      const socialAccessToken = e.data.accessToken;

      // 회원가입 되어있는 유저인지 확인
      const user = await this.handleSocialLogin(UserLoginType.whalespace, socialAccessToken, e);
      if (!user) {
        return;
      }

      const { token } = await this.app.user.doSignIn(+user.id, UserLoginType.whalespace, this.usedLanguage, socialAccessToken, null);
      await this.setCacheAfterLogin(token, UserLoginType.whalespace);
      await this.cut.goUrl(this.isDemo, this.app.cache.user.id, this.canvasId, this.app.currentService);
      await this.isLoginPageMove();

      this.isSuccessLogin = true;
    } catch (error) {
      this.analyticsService.error('login.page.whalespaceLoginGo.catch', error.message);
      await this.socialLoginErrorHandler(error);
    } finally {
      this.isLoginProgressing = false;
    }
  }

  /** @description 웨일 스페이스 소셜 로그인
   *  팝업창에서 넘어온 accessToken을 이용해서 로그인
   *  @return {void} 없음
   */
  async whalespaceLoginGo() {
    try {
      await this.app.delay(250);
      this.app.green(`whale web login`);
      this.whaleEventLister = this.whaleMessageHandler.bind(this);
      window.addEventListener('message', this.whaleEventLister, false);
      //popup 열리는 코드
      await this.whalespace.login(window);
    } catch (error) {
      this.analyticsService.error('login.page.whalespaceLoginGo.catch', error.message);
      await this.presentAlert(this.translate.instant('refreshForLoginError')); // 새로고침 알럿
      throw new TooningWhalespaceLoginGoError(error, this.app, true);
    }
  }

  /**
   * 쿨스쿨 메세지 이벤트 핸들러
   * @param {any} event 메세지 이벤트
   * @return {Promise<void>}
   */
  async coolSchoolMessageHandler(event: any): Promise<void> {
    try {
      this.isLoginProgressing = true;
      if (!event?.data.hasOwnProperty('messageType')) {
        return;
      }
      if (event.data.messageType === SocialLoginMessageType.popupClosed) {
        window.removeEventListener('message', this.coolSchoolEventLister);
        this.isSuccessLogin = true;

        return;
      }

      if (!event.data.accessToken) {
        throw new Error('coolSchool accessToken is empty');
      }
      if (event.data.loginType !== UserLoginType.coolSchool) {
        throw new Error('coolSchool loginType is empty');
      }

      const socialAccessToken = event.data.accessToken;

      // 회원가입 되어있는 유저인지 확인
      const user = await this.handleSocialLogin(UserLoginType.coolSchool, socialAccessToken, event);
      if (!user) {
        return;
      }

      const { token } = await this.app.user.doSignIn(+user.id, UserLoginType.coolSchool, this.usedLanguage, socialAccessToken, null);
      await this.setCacheAfterLogin(token, UserLoginType.coolSchool);
      await this.cut.goUrl(this.isDemo, this.app.cache.user.id, this.canvasId, this.app.currentService);
      await this.isLoginPageMove();

      this.isSuccessLogin = true;
    } catch (error) {
      this.analyticsService.error('login.page.coolSchoolMessageHandler.catch', error.message);
      await this.socialLoginErrorHandler(error);
    } finally {
      this.isLoginProgressing = false;
    }
  }

  /** @description 쿨스쿨 소셜 로그인
   *  팝업창에서 넘어온 accessToken을 이용해서 로그인
   *  @return {void} 없음
   */
  async coolSchoolLoginGo() {
    try {
      await this.app.delay(250);
      this.coolSchoolEventLister = this.coolSchoolMessageHandler.bind(this);
      window.addEventListener('message', this.coolSchoolEventLister, false);
      //popup 열리는 코드
      await this.coolSchool.login(window);
    } catch (e) {
      this.analyticsService.error('login.page.coolSchoolLoginGo.catch', e.message);
      throw new TooningCoolSchoolLoginGoError(e, this.app, true);
    }
  }

  /**
   * 로그인 성공 후 로그인 정보를 메모리 및 로컬스토리지에 저장
   * @param {string} token
   * @param {UserLoginType} kind
   * @param {string} goUrl
   * @return {Promise<void>}
   */
  async setCacheAfterLogin(token: string, kind: UserLoginType, goUrl = this.app.hereUrl) {
    try {
      // client 로그인 정보 세팅
      await this.app.loginCacheSetting(token, kind);
      const loginRoot = this.route.snapshot.params.root;
      const cloneCanvasId = this.route.snapshot.params.canvasId;
      this.analyticsService.logIn(kind, this.app.cache.user.id);
      // 유저의 role 정보 저장
      await this.userTracking();
      this.isSuccessLogin = true;
      await this.modalControl();
      this.checkAdmin();
      await this.checkIsPaid();

      if (goUrl === 'gpt/editor') {
        await this.gptService.refreshPage();
        return;
      }

      if (goUrl === 'login') {
        if (this.app.currentService === ServiceType.Magic) goUrl = ServiceType.Magic;
        else goUrl = '/';
      }

      await this.cloneCheck(loginRoot, cloneCanvasId, goUrl);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 회원가입 완료 시 모달 생성
   * @return {Promise<void>}
   */
  async signUpCompleteModal(): Promise<void> {
    try {
      if (this.app.modal) {
        this.app.modal.dismiss();
        this.app.modal = null;
      }

      await this.analyticsService.signUp(this.app.currentService);
      const signupCompleteModal = await this.modalCtrl.create({
        component: ModalSignupCompleteComponent,
        cssClass: 'modal-size-welcome'
      });

      return signupCompleteModal.present();
    } catch (e) {
      throw new TooningSignUpCompleteModalError(e);
    }
  }

  /**
   * Google web login
   * @param {string} goUrl
   * @return {Promise<void>}
   */
  async googleLoginGo(goUrl = this.app.hereUrl): Promise<void> {
    try {
      this.isLoginProgressing = true;
      await this.app.delay(250);
      this.app.greenLog('google login start');
      this.app.orange('google web login');

      const tooningUser = this.makeTooningUserData(this.googleService.googleUserInfo);
      const data = await this.app.user.isAlreadySignUpUser(UserLoginType.google, null, tooningUser);

      if (data.result) {
        const { token } = await this.app.user.doSignIn(+data.user.id, UserLoginType.google, this.usedLanguage, null, tooningUser as GoogleUser);
        await this.setCacheAfterLogin(token, UserLoginType.google);
        await this.cut.goUrl(this.isDemo, this.app.cache.user.id, this.canvasId, this.app.currentService);
        return;
      }
      const fetchResult = await this.userService.checkAndSetServerRegion(UserLoginType.google, true, null, tooningUser);
      if (fetchResult) {
        await this.googleLoginGo(goUrl);
      } else {
        // 회원가입 안 되어있는 사용자인 경우, 회원가입으로 유도
        await this.goToSnsAgreementPage(UserLoginType.google, null, tooningUser as GoogleUser, null);
      }

      this.isSuccessLogin = true;
    } catch (error) {
      const { code, message } = error;

      this.app.orange(`code:${code} message:${message}`);
      this.analyticsService.error('GoogleOAuthLoginError', `code:${code} meesage: ${message}`);

      // 구글 error code 에러 처리
      await this.errorHandlerService.googleLoginErrorCodeHandler(code);
      await this.socialLoginErrorHandler(error);
    } finally {
      this.isLoginProgressing = false;
    }
  }

  /**
   * facebook 로그인을 promise 형태로 변경
   * @return {Promise<FacebookAuthResponse>}
   */
  async facebookLogin(): Promise<FacebookAuthResponse> {
    return new Promise((resolve, reject) => {
      FB.login(
        async (response) => {
          try {
            let facebookAuthResponse: FacebookAuthResponse;
            facebookAuthResponse = response.authResponse;
            if (response.authResponse === null) {
              const closedError = new Error('facebook oauth popup closed');
              //창을 그냥 닫을 경우;
              reject(closedError);
              return;
            }
            resolve(facebookAuthResponse);
          } catch (error) {
            reject(error);
          }
        },
        { scope: 'public_profile, email' }
      );
    });
  }

  /**
   * Facebook web login
   * @param {string} goUrl
   * @return {Promise<void>}
   */
  async facebookLoginGo(goUrl = this.app.hereUrl): Promise<void> {
    try {
      this.isLoginProgressing = true;
      await this.app.delay(250);
      this.app.green(`FB web login`);
      this.app.orange('facebook web login');
      this.analyticsService.web();

      const facebookAuthResponse: FacebookAuthResponse = await this.facebookLogin();
      if (!facebookAuthResponse) {
        throw new Error('facebookAuthResponse is null');
      }
      const { result, user } = await this.app.user.isAlreadySignUpUser(UserLoginType.facebook, facebookAuthResponse.accessToken, null);

      // 회원가입 안 되어있는 사용자인 경우, 회원가입으로 유도
      if (!result) {
        const fetchResult = await this.userService.checkAndSetServerRegion(UserLoginType.facebook, true, facebookAuthResponse.accessToken, null);
        if (fetchResult) {
          await this.facebookLoginGo(goUrl);
        } else {
          await this.goToSnsAgreementPage(UserLoginType.facebook, facebookAuthResponse.accessToken, null, null);
        }
        return;
      }

      const { token } = await this.app.user.doSignIn(+user.id, UserLoginType.facebook, this.usedLanguage, facebookAuthResponse.accessToken, null);
      await this.setCacheAfterLogin(token, UserLoginType.facebook);
      await this.cut.goUrl(this.isDemo, this.app.cache.user.id, this.canvasId, this.app.currentService);
      await this.isLoginPageMove();

      this.isSuccessLogin = true;
    } catch (error) {
      await this.socialLoginErrorHandler(error);
    } finally {
      this.isLoginProgressing = false;

      if (!this.isSuccessLogin) {
        setTimeout(() => {
          this.reRenderGoogleLoginButton();
        });
      }
    }
  }

  /**
   * custom login 진행 시 validation 에러가 출력되었다면 찾아서 지운다
   * @param element
   */
  removeValidationErrorMessage(element: HTMLElement): void {
    if (!this.hasValidationError) {
      return;
    }
    // @ts-ignore
    // tslint:disable-next-line:variable-name
    for (const div of document.getElementsByClassName(`ValidationError ${element.id}`)) {
      div.remove();
    }
  }

  /**
   * custom login 진행 시 validation 에러가 출력된다
   * @param validationErrors
   */
  showValidationErrorMessage(validationErrors): void {
    if (document.querySelector('.ValidationError') != null) {
      // @ts-ignore
      // tslint:disable-next-line:variable-name
      for (const _div of document.querySelectorAll(`.ValidationError`)) {
        _div.remove();
      }
    }

    for (const validate of validationErrors) {
      if (document.querySelector('#login-' + validate.property) != null) {
        const message = Object.values(validate.constraints)[0];
        // @ts-ignore
        const divMessage = this.translate.instant(message);
        document
          .querySelector('#login-' + validate.property)
          .insertAdjacentHTML('afterend', `<div class='ValidationError login-${validate.property}'> ${divMessage}</div>`);
      }
    }
  }

  /**
   * custom login 시 에러 핸들링 체크
   * @param error
   * @return {Promise<void>}
   */
  async loginCustomErrorHandler(error: any): Promise<void> {
    if (error instanceof TooningCustomLoginValidationError) {
      this.customLoginAlert = await this.app.presentAlert(this.translate.instant('user is empty'));
      this.customLoginUser.email = '';
      this.customLoginUser.password = '';
      return;
    }
    if (error.graphQLErrors) {
      const errorCode = Number(error.graphQLErrors[0].extensions.exception.code);
      if (errorCode === TooningErrorCode.TOONING_SERVER_CUSTOM_USER_PASSWORD_IS_NOT_MATCHED_ERROR) {
        this.customLoginAlert = await this.app.presentAlert(this.translate.instant('user exists but password is wrong'));
        this.customLoginUser.password = '';
        return;
      } else if (errorCode === TooningErrorCode.TOONING_SERVER_CUSTOM_USER_EMAIL_EMPTY_ERROR) {
        this.customLoginAlert = await this.app.presentAlert(this.translate.instant('user is empty'));
        return;
      }
    }
    if (error.networkError) {
      const networkStatus = error.networkError.statusCode;
      switch (networkStatus) {
        case HttpStatusCode.BAD_REQUEST:
          await this.app.showToast(this.translate.instant('bad request'));
          break;
        case HttpStatusCode.UNAUTHORIZED:
          this.app.orange('unauthorized access');
          this.app.redirectLogin();
          break;
        case TooningErrorCode.TOONING_UNKNOWN_NETWORK_ERROR:
          await this.errorShowToast(error.message);
          break;
        default:
          this.customLoginAlert = await this.app.presentAlert(this.translate.instant('networkStatus.unknown'));
          await this.app.logout(false);
          break;
      }
      return;
    }
    await this.errorShowToast(error.message);

    return;
  }

  /**
   * 투닝 자체 로그인 시 사용되는 함수 입니다.
   * 로그인 시  발생하는 모든 문제는 CloudWatch /client/web/oper/tooning log group 에 저장되어 있습니다
   * 로그인이 안된 케이스 저장되는 log stream 이름  notLogin@abc@tooning.io
   * 로그인이 된 케이스 저장되는 log stream 이름  2343@abc@tooning.io
   * @param {string} goUrl
   * @return {Promise<void>}
   */
  async loginCustom(goUrl = this.app.hereUrl): Promise<void> {
    try {
      if (this.isLoginProgressing) {
        this.app.orange('login is progressing');
        return;
      }

      this.isLoginProgressing = true;

      // 언어 설정 업데이트
      this.customLoginUser.language = this.usedLanguage;
      // validation check
      this.customLoginUser.email = this.customLoginUser.email.trim();
      const validationErrors = await validate(this.customLoginUser);

      if (validationErrors.length > 0) {
        this.hasValidationError = true;
        this.app.orange('validation error');
        this.showValidationErrorMessage(validationErrors);
        throw new TooningCustomLoginValidationError('Custom login validation error', null);
      }

      // 서버에 가입 및 로그인 요청
      const isAlreadySignUp = await this.userService.checkUserExists(VerificationType.email, this.customLoginUser.email);

      this.hasValidationError = false;
      // validation 성공
      let loginToken;
      try {
        const { token } = await this.app.user.loginCustom(this.customLoginUser);
        loginToken = token;
      } catch (e) {
        if (isAlreadySignUp) {
          throw e;
        }

        const fetchResult = await this.userService.checkAndSetServerRegion(
          UserLoginType.custom,
          false,
          null,
          null,
          VerificationType.email,
          this.customLoginUser.email
        );
        if (fetchResult) {
          this.isLoginProgressing = false;
          await this.loginCustom(goUrl);
          return;
        }

        throw e;
      }

      this.hasCustomLoginError = false;

      await this.setCacheAfterLogin(loginToken, UserLoginType.custom);
      await this.cut.goUrl(this.isDemo, this.app.cache.user.id, this.canvasId, this.app.currentService);
    } catch (error) {
      this.hasCustomLoginError = true;
      this.isSuccessLogin = false;
      try {
        let logStreamName = makeLogStreamName(window.localStorage.getItem(CustomLoginUserInfoInLocalStorage.customLoginUserEmail));
        await makeCloudWatchLogStream(logStreamName);
      } catch (e) {
        slack('loginCustom', e.message, e.stack);
      }

      await this.loginCustomErrorHandler(error);
    } finally {
      this.isLoginProgressing = false;
    }
  }

  /**
   * 사용하는 언어 정보를 가져온다.
   * @return {LanguageType}
   */
  getUsedLanguage(): LanguageType {
    let usedLanguage = this.app.cache.getWorkingLanguage();
    if (!usedLanguage) {
      usedLanguage = this.app.getDefaultLanguage();
    }
    return usedLanguage;
  }

  /**
   * 구독자가 아니면 canvas clone을 사용할 수 없게 팝업이 뜨다.
   * @param loginRoot
   * @param cloneCanvasId
   * @param goUrl
   * @return {Promise<void>}
   */
  async cloneCheck(loginRoot, cloneCanvasId, goUrl): Promise<void> {
    if (loginRoot !== 'clone') {
      return;
    }
    try {
      if (!this.app.isPaidMember) {
        this.app.go(`/canvas-share/${cloneCanvasId}`);

        this.dialog.open(PaidReferralComponent, {
          width: this.app.isDesktopWidth() ? '440px' : '90%',
          height: this.app.isDesktopWidth() ? '600px' : '80%',
          data: {
            message: this.translate.instant('4')
          }
        });

        return;
      }
      const { data } = await this.canvasService.canvasClone(+cloneCanvasId, +this.app.cache.user.id, false);

      if (!data.result) {
        return;
      }
      this.app.go(`/4cut-make-manual2/${+data.canvasId}`);
    } catch (error) {
      this.analyticsService.error('login.page.canvasClone', error.message);
      await this.presentAlert('cloneFail');
      this.app.go('tooning-landing-main');
      throw new TooningCloneError(error, this.app, true);
    }
  }

  /**
   * User role 이 admin 인지 확인한다.
   */
  checkAdmin(): void {
    if (this.app.cache.user.role === UserRole.admin) {
      this.app.isAdmin = true;
    }
    this.app.pink(this.app.isAdmin);
  }

  /**
   * 결제한 사용자인지 확인해본다.
   * @return {Promise<void>}
   */
  async checkIsPaid(): Promise<void> {
    this.app.isPaidMember = await this.paymentService.isPaid(this.app.cache.user.id);
    this.app.userRoleWithSubscription = await this.paymentService.getUserRoleWithSubscription();
  }

  /**
   * 모달이 열려 있으면 닫는다.
   * @return {Promise<void>}
   */
  async modalControl(): Promise<void> {
    // 모달 제어
    if (this.isModal) {
      await this.modalCtrl.dismiss(ModalCloseType.loginSucceed);
    } else {
      this.app.orange('모달로 열리지 않고 페이지로 열린 케이스');
    }
  }

  /**
   * UserRole 을 GA 에 저장한다.
   * @return {Promise<void>}
   */
  async userTracking(): Promise<void> {
    const user = await this.app.user.currentUser();

    switch (user.role) {
      case UserRole.beta:
        this.app.orange('isBeta');
        this.analyticsService.betaLogIn(UserLoginType.custom);
        break;
      case UserRole.student:
        this.app.orange('isStudent');
        this.analyticsService.studentLogIn(UserLoginType.custom);
        break;
      case UserRole.teacher:
        this.app.orange('isTeacher');
        this.analyticsService.teacherLogIn(UserLoginType.custom);
        break;
      case UserRole.template:
        this.app.orange('isTemplate');
        this.analyticsService.templateLogIn(UserLoginType.custom);
        break;
      case UserRole.textTemplate:
        this.app.orange('isTextTemplate');
        this.analyticsService.templateLogIn(UserLoginType.custom);
        break;
      case UserRole.enterprise:
        this.app.orange('isEnterprise');
        this.analyticsService.enterpriseLogIn(UserLoginType.custom);
        break;
      case UserRole.user:
        this.app.orange('isGeneralUser');
        this.analyticsService.generalUserLogIn(UserLoginType.custom);
        break;
      case UserRole.demo:
        this.app.orange('isFreeTrialUser');
        this.analyticsService.freeTrial(UserLoginType.custom);
        break;
    }
  }

  /**
   * isModal 이 true 이면 모달을 닫는다.
   * @return {Promise<void>}
   */
  async moveToSignup(): Promise<void> {
    this.app.go('signup');
    if (this.isModal) {
      await this.modalCtrl.dismiss(ModalCloseType.moveToSignUp);
    }
  }

  /**
   * login page modal close
   * @return {Promise<void>}
   */
  async close(): Promise<void> {
    try {
      const topModal = await this.modalCtrl.getTop();

      if (topModal) {
        await this.modalCtrl.dismiss(ModalCloseType.canceled);
      }
    } catch (e) {
      throw new TooningCloseError(e);
    }
  }

  /**
   * 로그인 진행 시 문제가 발생할때, 알림창을 띄워줄때 사용된다.
   * @param {string} message
   * @return {Promise<HTMLIonAlertElement>}
   */
  async presentAlert(message: string): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      header: this.translate.instant('notice'),
      cssClass: 'basic-dialog',
      message,
      buttons: ['OK']
    });

    await alert.present();
    return alert;
  }

  /**
   * 패스워드 찾기로 이동
   * @return {Promise<void>}
   */
  async moveToFindPassword(): Promise<void> {
    if (this.app.modal !== null) {
      await this.modalCtrl.dismiss(ModalCloseType.findPassword);
    }
    const url = 'find-password';

    if (this.app.currentService === ServiceType.Magic) this.app.goQueryParams(url, { ['isMagic']: true });
    else this.app.go(url);
  }

  /**
   * 회원가입이나 로그인 후, viewType 을 변경해주는 함수
   * @param {string} type 뷰 타입 ex) login, signup
   * @return {void}
   */
  viewChange(type: UserPageViewType): void {
    this.viewType = type;
  }

  ngOnDestroy() {
    try {
      super.ngOnDestroy();
      this.app.greenLog('login.page.ngOnDestory');
      this.isLoginProgressing = false;
      this.isFromLanding = false;
      this.app.uniqueNumberList.splice(this.indexOfUniqueNumber, 1);
      this.isSignup = false;
      this.viewType = this.userPageViewType.loginSocial;
      this.kakaoLoginClick$.complete();
    } catch (e) {
      this.app.orange(e.message);
    } finally {
      if (this.app.cache.user && this.app.cache.user.role !== UserRole.demo && !this.regexService.testEmailRegexTest(this.app.cache.user.userEmail)) {
        let networkInfo;
        //@ts-ignore
        if (navigator.connection) {
          //@ts-ignore
          networkInfo = navigator.connection;
        }
        const userObject = {
          profile: {
            email: this.app.cache.user.userEmail,
            name: this.app.cache.user.userName,
            role: this.app.cache.user.role
          }
        };
        if (networkInfo) {
          Object.assign(userObject.profile, {
            downlink: networkInfo.downlink,
            effectiveType: networkInfo.effectiveType,
            onchange: networkInfo.onchange,
            rtt: networkInfo.rtt,
            saveData: networkInfo.saveData
          });
        }
        //@ts-ignore
        window.ChannelIO('updateUser', userObject);
      }
    }
  }

  /**
   * 로그인이 된 상태라면 메인으로 이동
   * @return {void}
   */
  async isLoginPageMove(): Promise<void> {
    if (this.app.loginStatus && !this.isDemo) {
      switch (this.app.currentService) {
        case ServiceType.Magic:
          this.app.go('magic');
          break;
        case ServiceType.Gpt:
          this.app.go('gpt/editor');
          break;
        case ServiceType.Board:
          this.app.go('board');
          break;
        default:
          this.app.go('/');
      }
    }
  }

  /**
   * 구글 로그인 initialized 와 renderButton 해줌
   * @returns {Promise<void>}
   */
  async loginGoogle(): Promise<void> {
    try {
      // @ts-ignore
      google?.accounts.id.initialize({
        client_id: environment.google_client_id,
        callback: this.getGoogleLoginHandler(),
        auto_select: false,
        cancel_on_tap_outside: true,
        context: 'use'
      });

      this.reRenderGoogleLoginButton();
    } catch (e) {
      throw new TooningLoginGoogleError(e.message, this.app, true);
    }
  }

  /**
   * Returns the Google login handler.
   *
   * @returns {Function} The Google login handler function.
   */
  getGoogleLoginHandler(): any {
    return this._googleLoginHandler ? this._googleLoginHandler : (this._googleLoginHandler = this.handleCredentialResponse.bind(this));
  }
  /**
   * Decode google credentials
   * @param {string} token
   * @returns {object}
   */
  decodeJwtResponse(token: string): object {
    try {
      const base64Url: string = token.split('.')[1];
      const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload: string = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      throw new TooningDecodeJwtResponseError(e);
    }
  }

  /**
   * google login callback response
   * @param {{credential: string}} response
   * @returns {Promise<void>}
   */
  async handleCredentialResponse(response: { credential: string }): Promise<void> {
    try {
      this.isLoginProgressing = true;
      this.googleService.googleUserInfo = this.decodeJwtResponse(response.credential);
      await this.googleLoginGo();
    } catch (e) {
      await this.app.logout();
      this.app.go('/');
      throw new TooningHandleCredentialResponseError(e);
    }
  }

  /**
   * get Unique Number
   * @return { void }
   */
  getUniqueNumber(): void {
    try {
      do {
        this.app.googleButtonNum += 1;
        this.indexOfUniqueNumber = this.app.uniqueNumberList.indexOf(this.app.googleButtonNum);
      } while (this.indexOfUniqueNumber > -1);
      this.app.uniqueNumberList.push(this.app.googleButtonNum);
    } catch (e) {
      throw new TooningGetUniquRandomNumberError(e.message);
    }
  }

  /**
   * 뒤로가기 버튼 화면 전환
   * @return {void}
   */
  backButtonControl(): void {
    try {
      // 회원가입 이용약관에서 뒤로가기
      if (this.viewType === this.userPageViewType.signupAgreement) {
        this.viewType = this.userPageViewType.loginSocial;
        this.isSignup = true;
      }
      // 회원가입 입력폼에서 뒤로가기
      else if (this.viewType === this.userPageViewType.signupForm) this.viewType = this.userPageViewType.signupAgreement;
      else this.viewType = this.userPageViewType.loginSocial;

      setTimeout(() => {
        this.reRenderGoogleLoginButton();
      }, 0);
    } catch (e) {
      throw new TooningLoginUIError(e.message);
    }
  }

  /**
   * 로그인 버튼의 width를 가져옴
   * @return {Promise<number>}
   */
  getLoginButtonWidth(): Promise<number> {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        let offsetWidth = 0;
        const refreshInterval = setInterval(() => {
          if (offsetWidth === 0) {
            offsetWidth = self.customLoginWrapper?.nativeElement.offsetWidth;
          } else {
            clearInterval(refreshInterval);
            resolve(offsetWidth);
          }
        }, 10);
      } catch (e) {
        reject(new Error('getLoginButtonWidth error'));
      }
    });
  }

  /**
   * 구글 로그인버튼 리렌더링
   * @return {void}
   */
  reRenderGoogleLoginButton(): void {
    try {
      this.readyToGoogleButtonRender = false;
      const googleBtn = document.getElementById('google_login_' + this.app.googleButtonNum);

      // @ts-ignore
      google?.accounts.id.renderButton(googleBtn, {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: this.isSignup ? 'signup_with' : 'signin_with',
        shape: 'pill',
        width: this.googleLoginButtonWidth,
        locale: this.language_locale[this.app.usedLanguage]
      });
    } catch (e) {
      throw new TooningReRenderGoogleLoginButtonError(e, this.app, true);
    } finally {
      this.readyToGoogleButtonRender = true;
    }
  }

  /**
   * 비밀번호 보기 모드 변경
   * @param {HTMLInputElement} passwordInput 비밀번호 인풋 element
   * @return {void}
   */
  passwordViewModeChange(passwordInput: HTMLInputElement): void {
    try {
      this.passwordIsView = !this.passwordIsView;

      if (this.passwordIsView) passwordInput.type = 'text';
      else passwordInput.type = 'password';
    } catch (e) {
      throw new TooningPasswordViewModeChangeError(e);
    }
  }

  /**
   * 커스텀 로그인/회원가입 버튼 클릭 핸들러
   * @return {void}
   */
  customJoinButtonClickHandler(): void {
    try {
      if (this.isSignup) this.viewType = UserPageViewType.signupAgreement;
      else this.viewType = UserPageViewType.loginCustom;
    } catch (e) {
      throw new TooningCustomJoinButtonClickHandlerError(e);
    }
  }

  /**
   * 회원가입 버튼 클릭 핸들러
   * @return {void}
   */
  changeViewTypeClickHandler(): void {
    try {
      this.viewType = UserPageViewType.loginSocial;

      this.isSignup = !this.isSignup;
      setTimeout(() => {
        this.reRenderGoogleLoginButton();
      }, 0);
    } catch (e) {
      throw new TooningChangeViewTypeClickHandlerError(e);
    }
  }

  /**
   * 소셜 로그인 회원가입
   * @param signupInfo
   * @return {Promise<void>}
   */
  async snsSignup(signupInfo: SnsSignupInfo): Promise<void> {
    try {
      const { type, accessToken, googleUser, appleUser } = signupInfo;
      const { token, id } = await this.app.user.doSignUp(type, accessToken, googleUser, this.usedLanguage, appleUser);
      await this.setCacheAfterLogin(token, type);
      await this.cut.goUrl(this.isDemo, id, this.canvasId, this.app.currentService);
      await this.signUpCompleteModal();
    } catch (e) {
      throw new TooningSnsSignupError(e);
    }
  }
}
