import { IsArray, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { AuthorType, Version } from '../../enum/app.enum';

export class EtcUpload {
  @IsNumber() @IsOptional() id?: number;
  @IsNumber() @IsOptional() sourceId?: number;
  @IsString() @IsOptional() positionType?: string;
  @IsNumber() @IsNotEmpty() categoryId: number;
  @IsString() @IsNotEmpty() sourceType: string;
  @IsEnum(AuthorType) @IsNotEmpty() authorType: string;
  // tslint:disable-next-line:variable-name
  @IsString() @IsOptional() resource_type: string;
  // tslint:disable-next-line:variable-name
  @IsString() @IsOptional() resource_preview: string;
  @IsArray() @IsOptional() objects: Array<any>;
  @IsString() @IsOptional() base64: string;
  @IsString() @IsOptional() fabricObject: string;
  @IsNumber() @IsOptional() typeId?: number;
  @IsNotEmpty() version: Version;

  @IsNotEmpty() isSet: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.id) {
      this.sourceId = this.id;
    } else {
      this.id = this.sourceId;
    }
    return this;
  }
}
