import { Command } from './command';
import { ObjectRemoveCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { ResourceType, SelectedType } from '../../enum/app.enum';
import _ from 'lodash';

/**
 * Class ObjectRemoveCommand
 * @extends Command
 */
class ObjectRemoveCommand extends Command {
  protected layerIndex: number[];
  private callback;
  public beforeTop;
  public beforeLeft;

  /**
   * Create ObjectRemoveCommand
   * @param {ObjectRemoveCommandData} data
   */
  constructor(data: ObjectRemoveCommandData) {
    try {
      super(data);
      this.callback = data.callback;
      this.layerIndex = [];
      this.commandName = 'Object-Remove-Command';
      if (this.type === 'activeSelection') {
        this.beforeTop = new Map();
        this.beforeLeft = new Map();
        // @ts-ignore
        const obList = this.target._objects;
        for (let i = 0; i < obList.length; i++) {
          this.beforeTop.set(obList[i].resource_selection_id, obList[i].top);
          this.beforeLeft.set(obList[i].resource_selection_id, obList[i].left);
          this.layerIndex.push(this.cut.getIndex(obList[i]));
        }
      } else {
        this.layerIndex.push(this.cut.getIndex(this.target));
      }
    } catch (e) {
      throw e;
    }
  }

  /**
   * Execute ObjectRemoveCommand
   */
  async execute() {
    try {
      super.execute();
      await this.callback();
      this.updatePageThumbnail();
    } catch (e) {
      throw e;
    }
  }

  /**
   * Redo ObjectRemoveCommand
   * @param {TooningFabricObject[]} objects
   */
  async redo(objects: TooningFabricObject[]) {
    try {
      super.redo(objects);
      if (this.type === SelectedType.activeSelection) {
        for (const object of objects) {
          for (let i = 0; i < this.id.length; i++) {
            if (object.resource_selection_id === this.id[i] && this.beforeTop.has(this.id[i]) && this.beforeLeft.has(this.id[i])) {
              object.top = this.beforeTop.get(this.id[i]);
              object.left = this.beforeLeft.get(this.id[i]);
              object.resource_type === ResourceType.characterSvg && (this.cut.characterLengthOnPage -= 1);
              this.cut.canvas.remove(object);
            }
          }
        }
      } else {
        for (const object of objects) {
          if (object.resource_selection_id === this.id[0]) {
            object.resource_type === ResourceType.characterSvg && (this.cut.characterLengthOnPage -= 1);
            this.cut.canvas.remove(object);
            break;
          }
        }
      }
      this.updatePageThumbnail();
    } catch (e) {
      throw e;
    }
  }

  /**
   * Undo ObjectRemoveCommand
   * @param {TooningFabricObject[]} objects
   */
  async undo(objects: TooningFabricObject[]) {
    try {
      super.undo(objects);
      const clonedOb = _.cloneDeep(this.target);
      this.cut.canvas.add(clonedOb);
      this.target.resource_type === ResourceType.characterSvg && (this.cut.characterLengthOnPage += 1);
      if (this.type === SelectedType.activeSelection) {
        this.cut.canvas.setActiveObject(clonedOb);
        this.cut.canvas.getActiveObject().toActiveSelection();
        this.cut.forceNoFocus();
      }
      this.updatePageThumbnail();
    } catch (e) {
      throw e;
    }
  }
}

export { ObjectRemoveCommand };
