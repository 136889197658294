import { Component, OnInit } from '@angular/core';
import { LanguageOriginalType, LanguageType, ServiceType } from '../../../enum/app.enum';
import { AppService } from '../../../services/app.service';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer-icon',
  templateUrl: './footer-icon.component.html',
  styleUrls: ['./footer-icon.component.scss']
})
export class FooterIconComponent implements OnInit {
  constructor(public app: AppService, public modalCtrl: ModalController) {}

  appVersion;
  apiServer;
  public serviceTypeEnum = ServiceType;
  public languageOriginalEnum = LanguageOriginalType;

  ngOnInit() {
    this.appVersion = environment.appVersion;
    this.apiServer = environment.graphql_url;
  }

  async linkedinGo() {
    this.app.goExternal('https://www.linkedin.com/company/toonsquare/');
  }

  async facebookGo() {
    await this.app.s3JsonDataUndefine();
    this.app.s3LinkGoValidat(this.app.s3JsonData.footerLink.facebook[this.app.usedLanguage]);
  }

  async instagramGo() {
    await this.app.s3JsonDataUndefine();
    this.app.s3LinkGoValidat(this.app.s3JsonData.footerLink.instagram[this.app.usedLanguage]);
  }

  async youtubeGo() {
    await this.app.s3JsonDataUndefine();
    this.app.s3LinkGoValidat(this.app.s3JsonData.footerLink.youtube[this.app.usedLanguage]);
  }

  async discordGo() {
    this.app.goExternal('https://discord.gg/GWgcdnveZn');
  }

  protected readonly LanguageType = LanguageType;
}
