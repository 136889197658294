import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LoginPage } from './login.page';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollbarThemeModule } from '../../directive/scrollbar-theme';
import { ComponentsModule } from '../../components/components.module';
import { NgStringPipesModule } from 'ngx-pipes';
import { MatLegacyOptionModule, MatLegacyRippleModule } from '@angular/material/legacy-core';
import { LandingModule } from '../../pages-tooning/components/landing.module';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SignupPage } from '../signup/signup.page';
import { TermsAgreementComponent } from '../signup/components/terms-agreement/terms-agreement.component';
import { SnsTermsAgreementComponent } from '../sns-terms-agreement/sns-terms-agreement.component';
import { ModalSignupCompleteComponent } from '../modal-signup-complete/modal-signup-complete.component';

const routes: Routes = [
  {
    path: '',
    component: LoginPage
  }
];

@NgModule({
  imports: [
    NgxFileDropModule,
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    ComponentsModule,
    ScrollbarThemeModule,
    NgStringPipesModule,
    MatLegacyRippleModule,
    LandingModule,
    MatLegacyAutocompleteModule,
    MatLegacyOptionModule,
    ReactiveFormsModule
  ],
  declarations: [LoginPage, SignupPage, TermsAgreementComponent, SnsTermsAgreementComponent, ModalSignupCompleteComponent]
})
export class LoginPageModule {}
