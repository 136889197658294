import { IsBoolean, IsNotEmpty } from 'class-validator';

export class FeatureLimitedList {
  @IsBoolean() @IsNotEmpty() aiLimit: boolean;
  @IsBoolean() @IsNotEmpty() skinLimit: boolean;
  @IsBoolean() @IsNotEmpty() bitmapFilterLimit: boolean;
  @IsBoolean() @IsNotEmpty() layerSeparationLimit: boolean;
  @IsBoolean() @IsNotEmpty() faceHidingLimit: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
