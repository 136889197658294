import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { GraphqlApiService } from './api/graphql.api.service';
import { AppService } from './app.service';
import { gql } from '@apollo/client';
import { SocialLoginMessageType } from '../enum/app.enum';
import { TooningCoolSchoolLoginError, TooningGetAccessTokenCoolSchoolError } from '../pages-tooning/errors/TooningErrors';

@Injectable({
  providedIn: 'root'
})
export class CoolSchoolService {
  constructor(public app: AppService, public graphql: GraphqlApiService) {}

  /**
   * 쿨스쿨 로그인 팝업 띄우는 함수
   * @param openerWindow
   * @return {Promise<void>}
   */
  async login(openerWindow): Promise<void> {
    try {
      console.log('쿨스쿨 로그인 팝업 띄우기!');
      const popupWidth = 500;
      const popupHeight = 750;

      const popupX = window.screen.width / 2 - popupWidth / 2;
      const popupY = window.screen.height / 2 - popupHeight / 2;

      const popup = window.open(
        `${environment.coolSchool.loginUrl}?response_type=code&client_id=${environment.coolSchool.client_id}&redirect_uri=${environment.coolSchool.redirect_uri}`,
        'PopupWin',
        'width=' + popupWidth + ', height=' + popupHeight + ', left=' + popupX + ', top=' + popupY
      );

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
          openerWindow.postMessage({ messageType: SocialLoginMessageType.popupClosed }, '*');
        }
      }, 500);
      return;
    } catch (e) {
      throw new TooningCoolSchoolLoginError(e, this.app, true);
    }
  }

  /**
   * 인증 코드를 액세스 토큰과 교환
   * @param {string} authCode 교환할 인증코드
   * @return {Promise<any>}
   */
  async getAccessTokenCoolSchool(authCode: string): Promise<any> {
    try {
      const result = await this.graphql
        .query(
          gql`
            query getAccessTokenCoolSchool($authCode: String!) {
              getAccessTokenCoolSchool(authCode: $authCode)
            }
          `,
          { authCode }
        )
        .toPromise();

      return result.data.getAccessTokenCoolSchool;
    } catch (e) {
      throw new TooningGetAccessTokenCoolSchoolError(e, this.app, true);
    }
  }
}
