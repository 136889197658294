<div class='help-center-mobile-modal'>
  <ion-searchbar class='mobile-search-bar' (keydown.enter)='searchFaqList()' [(ngModel)]='searchKeyword' (ionClear)='clearSearchKeyword()'>
  </ion-searchbar>

  <hr class='hr-no-margin'>
  <div class='scroll-content'>
    <div *ngIf='faqList.length !== 0'>
      <ion-item *ngFor='let faq of faqList'>
        <ion-label (click)='app.go("help-detail/" + faq.id)'>
          <div>{{ faq.faqDescription[0].question }}</div>
        </ion-label>
      </ion-item>
    </div>
    <div *ngIf='faqList.length === 0'>
      <div class='no-result'>
        <p>{{ 'help-center.no-result-text1' | translate }}</p>
        <p>{{ 'help-center.no-result-text2' | translate }}</p>
      </div>
    </div>
  </div>

  <div class='modal-footer-button'>
    <div class='modal-footer-button-content' (click)='app.go("help-center/editor")'>
      <div>{{ 'help-center.go-help-center' | translate }}</div>
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </div>
  </div>
</div>