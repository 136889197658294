import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { CanvasService } from '../../../services/canvas.service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Canvas } from '../../../model/canvas/canvas';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { PaidReferralComponent } from '../../../components/paid-referral/paid-referral.component';
import { TooningCanvasShareLinkCopyError, TooningOrientationChangeError } from '../../../pages-tooning/errors/TooningErrors';
import { AnalyticsService } from '../../../services/google/analytics/analytics.service';
import { CountryCode, Deployment, gaUser, ServerRegion, ViewOrientation } from '../../../enum/app.enum';
import { Cut4MakeManualService } from '../../cut4-make-manual2/cut4-make-manual.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-popover-share',
  templateUrl: './popover-share.component.html',
  styleUrls: ['./popover-share.component.scss']
})
export class PopoverShareComponent implements OnInit {
  public url: string;
  public appVersion: string;
  public getCanvas: any;
  public orientation: string;
  public authorization: string;
  public readyToRender: boolean;
  public viewOrientationType: any;
  public isFromEditor = false;
  @ViewChild('viewOrientationTypePopover') viewOrientationTypePopover;
  public isViewOrientationTypePopoverOpen: boolean = false;

  @ViewChild('authorizationPopover') authorizationPopover;
  public isAuthorizationPopoverOpen: boolean = false;

  @Input('canvasId') canvasId;
  @Input('isModal') isModal: boolean = false;
  @ViewChild('shareLinkCopyContainerInWorkSpace') shareLinkCopyContainerInWorkSpace: ElementRef;

  constructor(
    private router: Router,
    public cut: Cut4MakeManualService,
    public modalCtrl: ModalController,
    public dialog: MatLegacyDialog,
    public translate: TranslateService,
    public app: AppService,
    private popoverController: PopoverController,
    public canvasService: CanvasService,
    public analyticsService: AnalyticsService
  ) {
    const currentUrl = this.router.url;

    if (currentUrl.includes('4cut-make-manual2')) this.isFromEditor = true;
  }

  async ngOnInit() {
    this.readyToRender = false;
    this.appVersion = environment.appVersion;
    this.viewOrientationType = ViewOrientation;

    let currentCountry;
    switch (environment.deploy) {
      case Deployment.local:
        currentCountry = CountryCode.local;
        break;
      case Deployment.dev:
        currentCountry = CountryCode.dev;
        break;
      case Deployment.beta:
        currentCountry = CountryCode.beta;
        break;
      default:
        currentCountry = this.app.cache.user?.country ?? this.app.anonymousCountry;
        break;
    }
    const serverRegion = localStorage.getItem('server-region');
    if (serverRegion) {
      currentCountry = this.app.changeFormatServerRegionToCountry(serverRegion);
    }

    this.url = `${environment.share_server_url}/canvas-share/global?id=${this.canvasId}&country=${currentCountry}`;
    this.getCanvas = await this.canvasService.getCanvas(this.canvasId);
    this.orientation = this.getCanvas.data.orientation;
    this.authorization = this.getCanvas.data.authorization;
    this.readyToRender = true;
  }

  async copied(event) {
    await this.app.showToast(this.translate.instant('SHARE_LINK_COPY.COMPLETE'), 300);
    this.analyticsService.shareSite();
    await this.close();
  }

  /**
   * 공유하기 링크 복사
   * @return {Promise<void>}
   */
  async linkCopy(): Promise<void> {
    try {
      this.cut.analyticsService.shareTool();
      this.cut.isModalOpend = true;
      if (this.app.popover !== null) {
        this.app.popover.dismiss();
      }

      await this.app.showToast(this.translate.instant('SHARE_LINK_COPY.COMPLETE'), 300);
      await this.modalCtrl.dismiss({
        copy: true
      });
    } catch (e) {
      await this.app.showToast(this.translate.instant('SHARE_LINK_COPY.FAIL'), 300);
      await this.modalCtrl.dismiss({
        copy: false
      });

      throw new TooningCanvasShareLinkCopyError(e.message, this.app, true);
    } finally {
      this.cut.isModalOpend = false;
    }
  }

  async copyError(event) {
    await this.app.showToast(this.translate.instant('SHARE_LINK_COPY.FAIL'), 300);
    await this.close();
    throw new TooningCanvasShareLinkCopyError(event.message, this.app, true);
  }

  async close() {
    if (this.app.modal && this.isModal) {
      await this.modalCtrl.dismiss({});
    } else if (this.app.popover && !this.isModal) {
      await this.popoverController.dismiss({});
    }
  }

  /**
   * 링크 공유 시 공유 설정 변화(보기 전용, 복제 가능) 함수
   * @return {Promise<void>}
   */
  async authorizationChange(selectedAuthorization): Promise<void> {
    let user: string;
    try {
      this.authorization = selectedAuthorization;
      this.authorizationPopover.dismiss(null, null, false);
      if (this.app.isPaidMember) {
        const canvas = new Canvas();
        canvas.id = +this.canvasId;
        canvas.authorization = this.authorization;
        await this.canvasService.canvasUpdate(canvas).toPromise();
        await this.app.showToast(this.translate.instant('saveChange'));
        user = gaUser.pro + ' ' + this.authorization;
      } else if (this.authorization === 'clone') {
        user = gaUser.user + ' ' + this.authorization;
        const dialogRef = this.dialog.open(PaidReferralComponent, {
          width: this.app.isDesktopWidth() ? '440px' : '90%',
          height: this.app.isDesktopWidth() ? '600px' : '80%',
          data: {
            message: this.translate.instant('4')
          }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          this.authorization = 'read';
          if (result !== 'cancel') {
            this.app.modal.dismiss();
            this.app.popover.dismiss();
          }
        });
      }
    } catch (e) {
      throw new TooningCanvasShareLinkCopyError(e, this.app, true);
    } finally {
      this.analyticsService.shareSettingSite(user);
    }
  }

  /**
   * orientationChange 보기 타입 변경
   * @returns {Promise<void>}
   */
  async orientationChange(selectedType: string): Promise<void> {
    try {
      this.orientation = selectedType;
      const canvas = new Canvas();
      canvas.id = this.canvasId;
      canvas.orientation = this.orientation;
      this.cut.viewOrientation = this.orientation;
      await this.canvasService.canvasUpdate(canvas).toPromise();
      this.viewOrientationTypePopover.dismiss(null, null, false);
      await this.app.showToast(this.translate.instant('saveChange'));
    } catch (e) {
      throw new TooningOrientationChangeError(e);
    }
  }
}
