import { Component, OnInit } from '@angular/core';
import { Cut4MakeManualService } from '../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { ModalController } from '@ionic/angular';
import { LanguageType, ServiceType } from '../../enum/app.enum';
import { AppService } from '../../services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { AiDrawingService } from '../../pages-4cut/ai-drawing/ai-drawing.service';

@Component({
  selector: 'app-modal-signup-complete',
  templateUrl: './modal-signup-complete.component.html',
  styleUrls: ['./modal-signup-complete.component.scss']
})
export class ModalSignupCompleteComponent implements OnInit {
  public languageType = LanguageType;
  public serviceTypeEnum = ServiceType;

  constructor(
    public cut: Cut4MakeManualService,
    public modalCtrl: ModalController,
    public app: AppService,
    private translate: TranslateService,
    public magic: AiDrawingService
  ) {}

  ngOnInit() {
    this.cut.doConfetti();
  }

  /**
   * 모달 닫기
   * @return {Promise<void>}
   */
  async close(): Promise<void> {
    await this.modalCtrl.dismiss();
  }
}
