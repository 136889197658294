import { Injectable } from '@angular/core';
import { GraphqlApiService } from '../api/graphql.api.service';
import { Observable } from 'rxjs';
import { FetchResult, gql } from '@apollo/client';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  constructor(public graphql: GraphqlApiService) {}

  public subscribeWithPaypal(
    billingToken: string,
    userId: number,
    payEventId: number,
    payPlanId: number,
    facilitatorAccessToken: string,
    orderID: string,
    paymentID: string,
    subscriptionID: string
  ): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation makeSubscriptionWithPaypal($input: InputPaypalCreate!) {
            makeSubscriptionWithPaypal(paypalInfo: $input) {
              result
              errorCode
              message
            }
          }
        `,
        {
          input: {
            userId,
            payEventId,
            payPlanId,
            billingToken,
            facilitatorAccessToken,
            orderID,
            paymentID,
            subscriptionID
          }
        }
      )
      .pipe(
        tap((results) => {
          results.data = results.data.makeSubscriptionWithPaypal;
          return results;
        })
      );
  }
}
