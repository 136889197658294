import { IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsString, Length, MaxLength } from 'class-validator';
import { CustomUserDefaultMetaData } from '../../../enum/app.enum';
// 서버에서 user.schema.ts에 100 이 max length 로 잡혀 있으므로, 이범위 안에서 설정 되어야한다.
const minPasswordLength = CustomUserDefaultMetaData.minPasswordLength;
const maxPasswordLength = CustomUserDefaultMetaData.maxPasswordLength;
const minNameLength = CustomUserDefaultMetaData.minNameLength;
const maxNameLength = CustomUserDefaultMetaData.maxNameLength;
const maxEmailLength = CustomUserDefaultMetaData.maxEmailLength;
const maxPhoneNumberLength = CustomUserDefaultMetaData.maxPhoneNumberLength;

export class CustomUserEmail {
  @IsString()
  @IsNotEmpty()
  @IsEmail({ allow_ip_domain: false }, { message: 'signup email format error' })
  @MaxLength(maxEmailLength, { message: 'signup email length error' })
  email: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class CustomUser {
  @IsString()
  @IsNotEmpty()
  @Length(minNameLength, maxNameLength, {
    message: 'signup name length error'
  })
  name: string;

  @IsString()
  @IsNotEmpty()
  @IsEmail({ allow_ip_domain: false }, { message: 'signup email format error' })
  @MaxLength(maxEmailLength, { message: 'signup email length error' })
  email: string;

  @IsString()
  @IsOptional()
  @MaxLength(maxPhoneNumberLength, { message: 'signup phoneNumber length error' })
  phoneNumber?: string;

  @IsString()
  @IsNotEmpty()
  @Length(minPasswordLength, maxPasswordLength, {
    message: 'signup password length error'
  })
  password: string;

  @IsString()
  @IsNotEmpty()
  @Length(minPasswordLength, maxPasswordLength, {
    message: 'signup password confirm length error'
  })
  passwordConfirm: string;

  @IsBoolean() @IsNotEmpty() promotion: boolean;

  @IsString() @IsNotEmpty() language: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class CustomUserUpdate {
  @IsString() @IsNotEmpty() loginType: string;
  @IsString()
  @IsNotEmpty()
  @Length(minNameLength, maxNameLength, {
    message: 'signup name length error'
  })
  name: string;

  @IsString()
  @IsNotEmpty()
  @IsEmail({ allow_ip_domain: false }, { message: 'signup email format error' })
  @MaxLength(maxEmailLength, { message: 'signup email length error' })
  email: string;

  @IsString()
  @IsNotEmpty()
  @Length(minPasswordLength, maxPasswordLength, {
    message: 'signup password length error'
  })
  password: string;

  @IsString()
  @IsNotEmpty()
  @Length(minPasswordLength, maxPasswordLength, {
    message: 'signup password confirm length error'
  })
  newPassword: string;

  @IsString()
  @IsNotEmpty()
  @Length(minPasswordLength, maxPasswordLength, {
    message: 'signup password confirm length error'
  })
  newPasswordConfirm: string;

  @IsString() @IsNotEmpty() language: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class CustomLoginUser {
  @IsString()
  @IsNotEmpty()
  @IsEmail({ allow_ip_domain: false }, { message: 'signup email format error' })
  @MaxLength(maxEmailLength, { message: 'signup email length error' })
  email: string;

  @IsString()
  @IsNotEmpty()
  @Length(minPasswordLength, maxPasswordLength, {
    message: 'signup password length error'
  })
  password: string;
  @IsString() @IsNotEmpty() language: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class AuthSNSResult {
  @IsBoolean()
  @IsNotEmpty()
  result: boolean;

  @IsString()
  @IsNotEmpty()
  message: string;
}
