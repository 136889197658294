import { IsNotEmpty, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { Tag } from '../tag/tag';
import { Intent } from '../intent/intent';

export class AllBody {
  @IsNumber() @IsOptional() id: number;

  @IsNumber() @IsNotEmpty() characterId: number;
  @IsNumber() @IsNotEmpty() leftArmOrder: number;
  @IsNumber() @IsNotEmpty() leftHandOrder: number;
  @IsNumber() @IsNotEmpty() rightArmOrder: number;
  @IsNumber() @IsNotEmpty() rightHandOrder: number;
  @IsNumber() @IsNotEmpty() legOrder: number;
  @IsNumber() @IsNotEmpty() leftAllOrder: number;
  @IsNumber() @IsNotEmpty() rightAllOrder: number;


  @IsString() @IsOptional() fabricObject?: string;
  @IsString() @IsOptional() bodyAngle?: string;
  @IsString() @IsOptional() headAngle?: string;
  @IsNumber() @IsOptional() rotation?: number;
  @IsNumber() @IsOptional() scale?: number;
  @IsObject() @IsOptional() tags: Tag[];
  @IsObject() @IsOptional() intent: Intent;
  @IsNumber() @IsOptional() intentId: number;

  deserialize(input: any) {
    Object.assign(this, input);

    input.tags.map((tag, index) => {
      this.tags[index] = new Tag().deserialize(tag);
    });
    input.intent = new Intent().deserialize(input.intent);

    return this;
  }
}
