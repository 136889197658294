import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { Observable } from 'rxjs';
import { FetchPolicyList, FetchPolicyWatchQuery, ServerRegion } from '../../enum/app.enum';
import { HttpLink } from 'apollo-angular/http';
import { createWithRegion } from '../../graphql.module';

@Injectable({
  providedIn: 'root'
})
export class GraphqlApiService {
  public region = 'default';

  constructor(private apollo: Apollo, private httpLink: HttpLink) {
    if (localStorage.getItem('server-region')) {
      this.region = localStorage.getItem('server-region');
      if (this.region !== 'default') {
        //@ts-ignore
        this.setClient(this.region);
      }
    }
  }

  /**
   * watchQuery를 위한 함수
   * @param {DocumentNode} gql
   * @param {FetchPolicyWatchQuery} fetchPolicy option 설정으로 캐쉬를 활용할 수 있다.
   * @returns {QueryRef<any>}
   */
  watchQuery(gql: DocumentNode, fetchPolicy: FetchPolicyWatchQuery = FetchPolicyWatchQuery.noCache): QueryRef<any> {
    // https://apollo-angular.com/docs/data/subscriptions
    return this.apollo.watchQuery({
      query: gql,
      pollInterval: 60 * 1000,
      fetchPolicy: fetchPolicy,
      notifyOnNetworkStatusChange: true
    });
  }

  /**
   * query를 위한 함수
   * @param {DocumentNode} gql
   * @param variables
   * @param {FetchPolicyList} fetchPolicy option 설정으로 캐쉬를 활용할 수 있다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  query(gql: DocumentNode, variables: any = {}, fetchPolicy: FetchPolicyList = FetchPolicyList.noCache): Observable<ApolloQueryResult<any>> {
    // https://github.com/kamilkisiela/apollo-angular
    // https://www.apollographql.com/docs/angular/basics/queries/
    return this.apollo.query({
      query: gql,
      variables,
      fetchPolicy: fetchPolicy
    });
  }

  /**
   * mutate을 위한 함수
   * @param {DocumentNode} gql
   * @param variables
   * @returns {Observable<FetchResult<any>>}
   */
  mutate(gql: DocumentNode, variables: any = {}): Observable<FetchResult<any>> {
    // https://github.com/kamilkisiela/apollo-angular
    // https://www.apollographql.com/docs/angular/basics/mutations/
    return this.apollo.mutate({
      mutation: gql,
      variables,
      context: { useMultipart: true },
      fetchPolicy: 'no-cache'
    });
  }

  /**
   * 특정 region 서버를 타겟해서 query를 날리는 경우 사용하는 함수
   * @param {DocumentNode} gql
   * @param region request 요청할 server region
   * @param variables
   * @param {FetchPolicyList} fetchPolicy option 설정으로 캐쉬를 활용할 수 있다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  queryWithRegion(
    gql: DocumentNode,
    region: ServerRegion,
    variables: any = {},
    fetchPolicy: FetchPolicyList = FetchPolicyList.noCache
  ): Observable<ApolloQueryResult<any>> {
    // https://github.com/kamilkisiela/apollo-angular
    // https://www.apollographql.com/docs/angular/basics/queries/
    return this.apollo.use(region).query({
      query: gql,
      variables,
      fetchPolicy: fetchPolicy
    });
  }

  /**
   * apollo client 삭제하는 함수
   * @param {ServerRegion} region
   * @return {void}
   */
  removeClient(region: ServerRegion): void {
    this.apollo.removeClient(region);
    this.apollo.default();
  }

  /**
   * 새로운 apollo client 만들어주는 함수
   * @param {ServerRegion} region
   * @return {void}
   */
  setClient(region: ServerRegion): void {
    this.apollo.removeClient();
    const apolloOpt = createWithRegion(this.httpLink, region);
    this.apollo.createDefault(apolloOpt);
  }

  /**
   * 이름이 있는 client 만들어준느 함수
   * @param {ServerRegion} region
   */
  createNameClient(region: ServerRegion): void {
    const apolloOpt = createWithRegion(this.httpLink, region);
    this.apollo.createNamed(region, apolloOpt);
  }
}
