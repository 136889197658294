<div class='signup-container' [style.padding-top.px]='isModal?25:52'>

  <div *ngIf='viewType===userPageViewType.signupAgreement||!isModal'
       class='title'>{{'signupPage.title' | translate}}</div>

  <ng-container [ngSwitch]='viewType'>
    <!--  사용자 이용동의  -->
    <div class='signupInit' *ngSwitchCase='userPageViewType.signupAgreement'>
      <app-terms-agreement (changeAgreement)='changeCheckbox($event)'></app-terms-agreement>
      <button data-cy='goSignUpForm' class='w-100 custom-size-m theme-gradation' matRipple
              [disabled]='!isEssentialChecked'
              (click)='viewType="signupForm"; viewChange.emit("signupForm")'>
        {{'next' | translate}}
      </button>
    </div>

    <!--  회원가입 폼  -->
    <div *ngSwitchCase='userPageViewType.signupForm'
         class='signUpForm ui form attached fluid'>
      <div class='signupForm-wrapper' data-cy='signUpForm'
           [style.padding-top.px]='isModal?0:25'>
        <div #name class='required info-container' id='name' data-cy='name'>
          <div class='label-wrapper'>
            <label>{{'name' | translate}}</label>
          </div>
          <input #nameInput [(ngModel)]='customUser.name'
                 [maxlength]='CustomUserDefaultMetaData.maxNameLength' placeholder="{{'put your name' | translate}}"
                 type='text' (input)='showWarningText(warningname)' data-cy='nameInput' />
          <div #warningname id='warningname' class='warning-text ValidationError'>
            {{'signupPage.signupform.text-1' | translate}}
          </div>
        </div>

        <div #email id='email'
             [style.margin-bottom.px]='userVerificationType===verificationType.email?0:30'>
          <div class='label-wrapper row required align-items-center'>
            <label>{{'email' | translate}}</label>
            <p *ngIf='isVerified === false && userVerificationType===verificationType.email'
               class='m-0 caution-text'>{{'verification needed' | translate}}</p>
            <p *ngIf='isVerified === true && userVerificationType===verificationType.email'
               class='m-0 caution-text'>{{'verified' | translate}}</p>
          </div>
          <div class='row m-0'>
            <div class='col p-0'>
              <input #emailInput [(ngModel)]='customUser.email'
                     [disabled]='isClickedVerificationBtn || (isVerified && userVerificationType===verificationType.email)'
                     placeholder='tooning@example.com'
                     [maxlength]='CustomUserDefaultMetaData.maxEmailLength'
                     (input)='showWarningText(warningemail)'
                     type='text' data-cy='emailInput' />
            </div>
            <div *ngIf='userVerificationType===verificationType.email' class='verification-box'>
              <button (click)='verifyEmail(customUser.email)'
                          [disabled]='isClickedVerificationBtn || !customUser.email||validationErrorCheck.email(customUser) || (isVerified && userVerificationType===verificationType.email)'
                          class='p-0 m-0 h-100 theme-main verification-button'
                          mode='ios'
                          expand='block'
                          data-cy='emailSubmit'>{{'verification' | translate}}
              </button>
            </div>
          </div>
          <div #warningemail id='warningemail' class='warning-text ValidationError'>
            {{'signupPage.signupform.text-2' | translate}}
          </div>
        </div>
        <div #phone id='phone' *ngIf='userVerificationType===verificationType.sms && isKoreaUser'>
          <div class='label-wrapper row required align-items-center'>
            <label class=''>{{'signup page.label1' | translate}}</label>
            <p *ngIf='isVerified === false && userVerificationType===verificationType.sms'
               class='m-0 caution-text'>{{'verification needed' | translate}}</p>
            <p *ngIf='isVerified === true && userVerificationType===verificationType.sms'
               class='m-0 caution-text'>{{'verified' | translate}}</p>
          </div>
          <div class='row m-0'>
            <div class='col p-0 mr-2 d-flex flex-column'>
              <div class='d-flex w-100 position-relative'>
                <div class='d-flex justify-content-between align-items-center country-select select-country-box'
                     (click)='isCountrySelectMoreClick = !isCountrySelectMoreClick'>
                  <div class='d-flex'>
                    <div class='flag-emoji'>{{getFlagEmoji(defaultCountry.isoCode2)}}</div>
                    <div>{{defaultCountry.countryCode}}</div>
                  </div>
                  <ion-icon *ngIf='isCountrySelectMoreClick' name='chevron-up-outline'></ion-icon>
                  <ion-icon *ngIf='!isCountrySelectMoreClick' name='chevron-down-outline'></ion-icon>
                </div>
                <input #phoneNumberInput [(ngModel)]='customUser.phoneNumber'
                       [disabled]='isVerified && userVerificationType===verificationType.sms'
                       style='padding: 1.1em' pattern='[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}'
                       maxlength='13'
                       type='text' (input)='autoHypenPhone()' />
              </div>
              <div *ngIf='isCountrySelectMoreClick' class='w-100 position-absolute country-select-option-wrapper'
                   [style.top.px]='phoneNumberInput.offsetHeight' style='z-index: 99999'
                   (click)='isCountrySelectMoreClick = !isCountrySelectMoreClick'>
                <div
                  class='w-100 d-flex justify-content-between align-items-center country-select country-select-option'
                  [style.height.px]='phoneNumberInput.offsetHeight'>
                  <div class='d-flex'>
                    <div class='flag-emoji'>{{getFlagEmoji(defaultCountry.isoCode2)}}</div>
                    <div>{{defaultCountry.country}}</div>
                  </div>
                  <div>{{defaultCountry.countryCode}}</div>
                </div>
              </div>
            </div>
            <div class='w-auto'>
              <ion-button (click)='verifyPhoneNumber(customUser.phoneNumber)' class='p-0 m-0 theme-main'
                          [disabled]='(isVerified && userVerificationType===verificationType.sms) || !customUser.phoneNumber || validationErrorCheck.phone(customUser)'
                          [style.height.px]='phoneNumberInput.offsetHeight'
                          mode='ios'>{{'verification' | translate}}
              </ion-button>
            </div>
          </div>
        </div>
        <!-- 인증번호  -->
        <div *ngIf='isTimer' id='verification-code' class='margin-top-10'>
          <div class='row m-0'>
            <div class='col p-0 position-relative'>
              <input #verificationCodeInput [(ngModel)]='verificationCode'
                     placeholder="{{'signupPage.signupform.placeholder-1' | translate}}"
                     [maxlength]='CustomUserDefaultMetaData.maxVerificationCodeLength'
                     [disabled]='isVerified'
                     type='text' data-cy='verificationCodeInput' />
              <div class='position-absolute timeleft'>{{timeleft}}</div>
            </div>
            <div class='w-auto' style='margin-left: 8px'>
              <ion-button (click)='verifyCode(verificationCode)' class='p-0 m-0 h-100 theme-main'
                          [disabled]='isVerified || !verificationCode || validationErrorCheck.verifyCode(verificationCode)'
                          mode='ios' data-cy='verifyCodeBtn'>{{'confirm' | translate}}
              </ion-button>
            </div>
          </div>
        </div>

        <div *ngIf='isKoreaUser' class='margin-top-10'>
            <span *ngIf='userVerificationType===verificationType.email && !isVerified && !isClickedVerificationBtn'
                  (click)='changeVerificationType()'
                  style='text-decoration: underline'
                  class='verification-change-text' type='button'>{{'signup page.button1' | translate}}</span>
          <span *ngIf='userVerificationType===verificationType.sms && !isVerified && !isClickedVerificationBtn'
                (click)='changeVerificationType()'
                style='text-decoration: underline'
                class='verification-change-text' type='button'>{{'signup page.button2' | translate}}</span>
          <div *ngIf='userVerificationType===verificationType.email && !isVerified && isClickedVerificationBtn'>
          <span
            style='text-decoration: underline'
            class='verification-change-text not-receive-mail'>{{'signupPage.signupform.email-text-1' | translate}}</span>
            <span (click)='verifyEmail(customUser.email)' type='button'
                  class='verification-change-text resend'>{{'signupPage.signupform.email-text-2' | translate}}</span>
          </div>
        </div>

        <div #password class='required info-container'
             id='password' data-cy='password' style='margin-top: 27px'>
          <div class='label-wrapper'>
            <label data-cy='passwordLabel'>{{'Password' | translate}}</label>
          </div>
          <input data-cy='passwordInput' [(ngModel)]='customUser.password'
                 placeholder="{{'signupPage.signupform.placeholder-2' | translate}}"
                 [maxlength]='CustomUserDefaultMetaData.maxPasswordLength' type='password'
                 (input)='showWarningText(warningpassword)' />
          <div #warningpassword id='warningpassword' class='warning-text ValidationError'>
            {{'signupPage.signupform.text-3' | translate}}
          </div>
          <input #passwordConfirm data-cy='passwordConfirmInput' [(ngModel)]='customUser.passwordConfirm'
                 placeholder="{{'signupPage.signupform.placeholder-3' | translate}}" class='margin-top-10'
                 [maxlength]='CustomUserDefaultMetaData.maxPasswordLength' type='password'
                 (input)='showWarningText(warningpasswordConfirm)' />
          <div #warningpasswordConfirm id='warningpasswordConfirm' class='warning-text ValidationError'>
            {{'signupPage.signupform.text-4' | translate}}
          </div>

        </div>
      </div>
      <button class='w-100 custom-size-m theme-main' matRipple [disabled]='checkDisabled()'
              (click)='customSignup()' data-cy='customSignupBtn'>
        {{'signup start' | translate}}
      </button>
    </div>
  </ng-container>
</div>
