import { NgModule, Directive, ElementRef } from '@angular/core';
@Directive({
  selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective {
  constructor(el: ElementRef) {
    const stylesheet = `
      ::-webkit-scrollbar {
            width: 0;
            height: 0;
       }
      @media (min-width: 1025px) {
        ::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

        }
      ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px grey;
            border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
            background: rgba(210, 210, 210, 0.8);
            border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
                  background: rgba(146, 146, 146, 0.5);
      }
    `;

    const styleElmt = el.nativeElement.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      el.nativeElement.shadowRoot.appendChild(barStyle);
    }
  }
}

@NgModule({
  declarations: [ScrollbarThemeDirective],
  exports: [ScrollbarThemeDirective]
})
export class ScrollbarThemeModule {}
