<!-- 전체 딤처리가 필요할때 -->
<ion-backdrop class='disable-zoom' *ngIf='app.isIonBackdrop'
              style='opacity: 0.2;background-color: #ffffff'></ion-backdrop>
<ion-backdrop class='disable-zoom' *ngIf='app.isIonBackdropOpacity0'
              style='opacity: 0;background-color: #ffffff'></ion-backdrop>

<!-- 업이트 진행시 팝업-->
<div *ngIf='app.isUpdate' class='d-flex justify-content-center align-items-center animation_fadeIn disable-zoom' style='position: absolute;height: 100vh;width: 100vw;background: rgba(13,13,13,0.5);
z-index: 1000000000;'>
  <div
    [style.border-radius.px]='app.isWidthMin(500)?8:10'
    [style.max-height.px]='updatePopUpHeight + 120'
    [style.max-width]="app.isWidthMin(500)?'80%':'400px'"
    style='background: #FFF;border-radius: 10px;border: 1px #b4b4b4 solid;min-width: 300px;width: 100%;min-height: 200px;height: 100%;overflow-y:auto;overflow-x: hidden;position: relative'

  >
    <img #isMainPopupImg (load)='updatePopupImageOnload($event)' alt='' src='assets\3d_updatePopup.png'
         class='updatePopUpImg' />
    <div class='p-4' style='height: 100px'>
      <div class='pre updatePopUpTitle'>{{ 'updatePopup.title' | translate }}</div>
      <div class='pre updatePopUpSub'>{{ 'updatePopup.sub' | translate }}</div>

    </div>

  </div>
</div>
<ion-header class='disable-zoom' *ngIf='data.topMenu' mode='ios'>
  <ion-toolbar color='light'>
    <ion-buttons slot='secondary'>
      <ion-button (click)="app.goExternalLocal('/admin')">
        <ion-icon slot='icon-only' name='home-outline'></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>    {{ appVersion }} / apiServer - {{ apiServer }}</ion-title>
    <ion-buttons slot='primary' (click)="clickLogout('/login', true)">
      <ion-button>   {{ 'logOut' | translate }} </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-app class='disable-zoom' [style.margin-top.px]="data.topMenu ? '62' : '0'">
  <!--/.Navbar-->
  <ion-split-pane (ionSplitPaneVisible)='onSplitPaneVisible($event)' [disabled]='data.splitPaneDisabled'
                  contentId='main-content' when='xl'>
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true' class='side_menu'
              contentId='main-content' disabled='true' menuId='basic' type='push'>
      <!-- Content -->
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <ion-content>
        <div class='w-100 pt-3 text-center'>기본 메뉴 입니다.</div>
      </ion-content>
    </ion-menu>

    <!--   4컷     -->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true'
              contentId='main-content'
              disabled='true' menuId='4cut' type='push'>
      <!-- Content -->
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <ion-content appScrollbarTheme scrollY='true'>

        <ul class='list-group list-group-flush animation_fadeIn'>
          <li (click)="sideMenuPageGo('4cut-admin-request-list/ready')" class='list-group-item' matRipple>
            요청 리스트
          </li>
          <li (click)="sideMenuPageGo('4cut-admin-psql-post-list')" class='list-group-item' matRipple>
            POST 리스트
          </li>
        </ul>
      </ion-content>
    </ion-menu>

    <!--    리소스 업로드 사이드 메뉴    -->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true'
              contentId='main-content'
              disabled='true' menuId='resmaker' type='push'>
      <!-- Content -->
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <ion-content appScrollbarTheme scrollY='true'>
        <!--캐릭터-->
        <div *ngIf="data.ResType === 'character-list-category'" class='animation_fadeIn'>
          <div class='w-100 p-2'>
            <ion-button
              (click)="sideMenuPageGo('type-selection')"
              expand='block'
              color='light'>
              <ion-icon name='arrow-back'></ion-icon>
              리소스 업로드 타입 선택
            </ion-button>
          </div>
        </div>
        <div *ngIf="data.ResType === 'character-category-view'" class='animation_fadeIn'>
          <div class='w-100 p-2'>
            <ion-button (click)="sideMenuPageGo('character-list-category')" expand='block'
                        color='light'>
              <ion-icon name='arrow-back'></ion-icon>
              캐릭터 카테고리 리스트
            </ion-button>
          </div>
        </div>
        <div *ngIf="data.ResType === 'character-make'" class='animation_fadeIn'>
          <div class='w-100 p-2'>
            <ion-button (click)='characterCategoryGo()' expand='block'
                        color='light'>
              <ion-icon name='arrow-back'></ion-icon>
              캐릭터 리스트
            </ion-button>

          </div>
          <!-- ! 페이지별 검증여부 확인필요!!!! -->

          <ion-list>


            <ion-item-group>
              <ion-item-divider class='mt-2'>
                <ion-label style='font-size: 18px;color: #ff2778;font-weight: bold;'> 기본 업로드</ion-label>
              </ion-item-divider>

              <ion-item *ngFor='let item of basicRecUploadList let index = index' [button]='true'
                        (click)='sideMenuPageGo(item.url)'>
                <ion-icon style='font-size: 21px;margin-right: 8px;' [name]='iconMark[item.iconMark]'></ion-icon>
                <ion-label>{{item.menuName}}</ion-label>
              </ion-item>
            </ion-item-group>

            <ion-item-group>
              <ion-item-divider class='mt-2'>
                <ion-label style='font-size: 18px;color: #ff2778;font-weight: bold;'>케릭터 기본 셋팅</ion-label>
              </ion-item-divider>

              <ion-item *ngFor='let item of basicRecSettingList let index = index' [button]='true'
                        (click)='sideMenuPageGo(item.url)'>
                <ion-icon style='font-size: 21px;margin-right: 8px;' [name]='iconMark[item.iconMark]'></ion-icon>
                <ion-label>{{item.menuName}}</ion-label>
              </ion-item>
            </ion-item-group>

            <ion-item-group>
              <ion-item-divider class='mt-2'>
                <ion-label style='font-size: 18px;color: #ff2778;font-weight: bold;'>썸네일 설정</ion-label>
              </ion-item-divider>

              <ion-item *ngFor='let item of basicReceThumbnailList let index = index' [button]='true'
                        (click)='sideMenuPageGo(item.url)'>
                <ion-label>{{item.menuName}}</ion-label>
              </ion-item>
            </ion-item-group>


            <ion-item-group>
              <ion-item-divider class='mt-2'>
                <ion-label style='font-size: 18px;color: #ff2778;font-weight: bold;'>기타 확장 기능</ion-label>
              </ion-item-divider>

              <ion-item [button]='true' (click)="sideMenuPageGo('character-make-upload-history')">
                <ion-label>업로드 히스토리</ion-label>
              </ion-item>
              <ion-item [button]='true' (click)="sideMenuPageGo('character-make-upload')" disabled>
                <ion-label>리소스 업로드 BETA(점검중)</ion-label>
              </ion-item>
            </ion-item-group>

          </ion-list>


        </div>

        <!--etcUpload-->
        <div *ngIf="data.ResType === 'etcUpload-category-list'" class='animation_fadeIn'>
          <div class='w-100 p-2'>
            <ion-button
              (click)="sideMenuPageGo('type-selection')"
              expand='block'
              color='light'>
              <ion-icon name='arrow-back'></ion-icon>
              리소스 업로드 타입 선택
            </ion-button>

          </div>
          <hr style='margin: 0'>
          <div class='w-100 p-2'>
            <ion-button
              (click)="sideMenuPageGo('etcUpload-category-list/' + activatedRoute.firstChild.snapshot.params['resourcetype'] + '/tooning', true)"
              [ngClass]="app.sourceAuthorType === 'tooning'?'z-depth-0 yellow':'z-depth-0 grey lighten-3'"
              expand='block'
              color='main'>

              {{ resourceType }} - 투닝(tooning) 리스트
            </ion-button>

            <ion-button
              (click)="sideMenuPageGo('etcUpload-category-list/' + activatedRoute.firstChild.snapshot.params['resourcetype'] + '/outside', true)"
              [ngClass]="app.sourceAuthorType === 'outside'?'z-depth-0 yellow':'z-depth-0 grey lighten-3'"
              expand='block'
              color='tertiary'>

              {{ resourceType }} - 작가(outside) 리스트
            </ion-button>
          </div>
        </div>
        <div *ngIf="data.ResType === 'etcUpload-make'" class='animation_fadeIn'>
          <div class='w-100 p-2'>

            <ion-button
              (click)="sideMenuPageGoNocheck('etcUpload-category-list/' + activatedRoute.firstChild.snapshot.params['resourcetype'] + '/' + app.sourceAuthorType)"
              *ngIf="activatedRoute.firstChild.snapshot.url[0].toString() != 'etcUpload-make-addOrSet'"
              expand='block'
              color='light'
            >
              <ion-icon name='arrow-back'></ion-icon>
              {{ resourceType }}그룹 리스트
            </ion-button>

            <ion-button
              (click)="sideMenuPageGoNocheck('etcUpload-make-list/' + activatedRoute.firstChild.snapshot.params['resourcetype'] + '/' + app.sourceAuthorType)"
              *ngIf="activatedRoute.firstChild.snapshot.url[0].toString() == 'etcUpload-make-addOrSet'"
              expand='block'
              color='light'
            >
              <ion-icon name='arrow-back'></ion-icon>
              {{ resourceType }} 리스트
            </ion-button>

          </div>
          <ion-list>
            <ion-item-group>
              <ion-item-divider class='mt-2'>
                <ion-label style='font-size: 18px;color: #ff2778;font-weight: bold;'> 기본 업로드</ion-label>
              </ion-item-divider>

              <ion-item [button]='true'
                        (click)="sideMenuPageGo('etcUpload-make-basic/' + activatedRoute.firstChild.snapshot.params['resourcetype'] + '/' + app.sourceAuthorType)">
                <ion-icon style='font-size: 21px;margin-right: 8px;' [name]='iconMark[0]'></ion-icon>
                <ion-label>그룹 소개</ion-label>
              </ion-item>

              <ion-item [button]='true'
                        (click)="sideMenuPageGo('etcUpload-make-list/' + activatedRoute.firstChild.snapshot.params['resourcetype'] + '/' + app.sourceAuthorType)">
                <ion-icon style='font-size: 21px;margin-right: 8px;' [name]='iconMark[1]'></ion-icon>
                <ion-label>그룹 목록 및 추가</ion-label>
              </ion-item>
            </ion-item-group>

          </ion-list>

        </div>

      </ion-content>

      <ion-footer
        *ngIf="data.ResType == 'character-make' || data.ResType == 'etcUpload-make' || data.ResType == 'item-make'"
        class='footer_btn animation_fadeIn publish'>
        <ion-item>
          <ion-label>발행설정</ion-label>
          <ion-select (ionChange)='publishedFn($event.detail.value)' [(ngModel)]='status' interface='popover'>
            <ion-select-option value='publish'>발행중</ion-select-option>
            <ion-select-option value='prepare'>준비중</ion-select-option>
            <ion-select-option value='adminView'>관리자뷰</ion-select-option>
          </ion-select>
          <div tabindex='0'></div>
        </ion-item>
      </ion-footer>
    </ion-menu>

    <!--   ai    -->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true' class='side_menu'
              contentId='main-content' disabled='true' menuId='ai' type='push'>
      <!-- Content -->
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <ion-content appScrollbarTheme scrollY='true'>
        <ul class='list-group list-group-flush animation_fadeIn'>
          <li (click)="sideMenuPageGo('ai/background')" class='list-group-item' matRipple>
            배경
          </li>
          <li (click)="sideMenuPageGo('ai/speech')" class='list-group-item' matRipple>
            발화
          </li>
          <li (click)="sideMenuPageGo('ai/embedding/projector')" class='list-group-item' matRipple>
            Embedding Projector
          </li>
          <li (click)="sideMenuPageGo('ai/fasttext')" class='list-group-item' matRipple>
            FastText
          </li>
        </ul>
      </ion-content>
    </ion-menu>


    <!--    tooning_landing   -->
    <!--    모바일 햄버거 메뉴  -->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false; channelIsOpen(true);' (ionDidOpen)='app.isOpenSideMenu = true'
              (ionWillOpen)='channelIsOpen(false);' [swipeGesture]='!app.isDesktopWidth()'
              class='side_menu disable-zoom' [class]='app.currentService===MAGIC?MAGIC:""'
              contentId='main-content' type='overlay'
              disabled='true' menuId='tooningLanding' side='end'>
      <ion-header>

        <ion-list lines='none'>
          <!--       로그인 한 상태     -->
          <ion-item *ngIf='app.loginStatus' style='--min-height: 100px'>
            <ion-avatar [style.border]="app.isAdmin ? '#00baff 3px solid' : '#ff7c1f 1px solid'" fill='clear'
                        mode='md'
                        slot='start'>
              <img
                [src]="app.cache.user.userImgUrl !== null ? app.cache.user.userImgUrl : '/assets/common/avatar.svg'" />
            </ion-avatar>
            <!--     ios 검수용 임시 코드    -->
            <a (click)="sideMenuPageGo('/account-setting'); sideMenu.close()"
               class='pr-4' slot='end'
               style='font-size: 14px'>{{ 'account settings' | translate }}</a>
            <a data-cy='logOutMb' (click)="clickLogout('/tooning-landing-main', false); sideMenu.close()"
               slot='end' style='font-size: 14px'>{{ 'logOut' | translate }}</a>
            <div tabindex='0'></div>
          </ion-item>

          <!--       로그인 버튼     -->
          <div class='d-flex flex-column justify-content-between' style='padding: 5px; gap: 5px'>
            <ng-container *ngIf='app.currentService===serviceType.Editor'>
              <ion-button (click)="sideMenuPageGo('/login')"
                          *ngIf='!app.loginStatus&&app.hereUrl!=="login"&&app.hereUrl!=="signup"' class='theme-outline'
                          data-cy='loginJoinMb'
                          expand='block'
                          id='header-mobile-signinup-editor'
                          matRipple mode='ios'>{{ 'logIn&Join' | translate }} </ion-button>
              <ion-button (click)='cut.canvasNewMake()' class='theme-main' data-cy='makingTooningMb' expand='block'
                          matRipple mode='ios'>{{ 'making tooning' | translate }} </ion-button>
            </ng-container>

            <ng-container *ngIf='app.currentService===serviceType.Magic'>
              <ion-button (click)="sideMenuPageGo('/login')"
                          *ngIf='!app.loginStatus&&app.hereUrl!=="login"&&app.hereUrl!=="signup"' class='theme-outline'
                          data-cy='loginJoinMb'
                          expand='block'
                          id='header-mobile-signinup-magic'
                          matRipple mode='ios'>{{ 'logIn&Join' | translate }} </ion-button>
            </ng-container>

            <ng-container *ngIf='app.currentService===serviceType.Gpt'>
              <ion-button (click)='openModalLogin()'
                          *ngIf='!app.loginStatus&&app.hereUrl!=="login"&&app.hereUrl!=="signup"' class='theme-outline'
                          data-cy='loginJoinMb'
                          expand='block'
                          id='header-mobile-signinup-gpt'
                          matRipple mode='ios'>{{ 'logIn&Join' | translate }} </ion-button>
            </ng-container>

            <ng-container *ngIf='app.currentService===serviceType.Board'>
              <ion-button (click)='openModalLogin()'
                          *ngIf='!app.loginStatus&&app.hereUrl!=="login"&&app.hereUrl!=="signup"' class='theme-outline'
                          data-cy='loginJoinMb'
                          expand='block'
                          id='header-mobile-signinup-board'
                          matRipple mode='ios'>{{ 'logIn&Join' | translate }} </ion-button>
            </ng-container>
          </div>
        </ion-list>
        <div style='padding: 0 5px'>
          <ion-button (click)='moveToCardRegistration()' *ngIf='!app.isPaidMember && app.loginStatus'
                      class='theme-outline'
                      expand='block' fill='outline' matRipple mode='ios'>
            <ion-icon class='mr-2' src='/assets/4cut-make-manual/pro_icon.svg'></ion-icon>
            {{ 'pro upgrade' | translate }}
          </ion-button>
        </div>
      </ion-header>

      <ion-content [contentDisable]='!app.isOpenSideMenu' appDisabled appScrollbarTheme scrollY='true'>

        <ion-list lines='none'>

          <!--    투닝 에디터      -->
          <ng-container *ngIf='app.currentService===serviceType.Editor'>
            <!-- 템플릿-->
            <div *ngIf='!app.loginStatus' class='pt-4'>
              <ion-item (click)="sideMenuPageGo('/template-list/home')" data-cy='templateMb' button>
                {{ 'template' | translate | titlecase}}
                <div tabindex='0'></div>
              </ion-item>

              <!-- 요금제-->
              <ion-item (click)="sideMenuPageGo('/tooning-price-plan')" data-cy='priceMb' button>
                {{ 'price' | translate | titlecase }}
                <div tabindex='0'></div>
              </ion-item>

              <!-- 고객센터 -->
              <ion-item (click)='app.goHelpCenter()' data-cy='helpCenterMb' button>
                {{ 'help' | translate }}
                <div tabindex='0'></div>
              </ion-item>


              <hr />
            </div>

            <ng-container *ngIf='app.loginStatus'>
              <!-- 나의 작업-->
              <ion-item data-cy='myWorkMb' (click)='MenuGoHome()' button class='mt-3'
              >{{ 'my work' | translate }}
                <div tabindex='0'></div>
              </ion-item>

              <!-- 템플릿-->
              <ion-item (click)="sideMenuPageGo('/template-list/home')" button>
                {{ 'template' | translate | titlecase}}
                <div tabindex='0'></div>
              </ion-item>

              <!-- 요금제-->
              <ion-item (click)="sideMenuPageGo('/tooning-price-plan')" data-cy='priceMb' button>
                {{ 'price' | translate | titlecase }}
                <div tabindex='0'></div>
              </ion-item>

              <!-- 고객센터 -->
              <ion-item (click)='app.goHelpCenter()' button>
                {{ 'help' | translate }}
                <div tabindex='0'></div>
              </ion-item>

              <hr />
            </ng-container>
            <ng-container *ngIf='app.usedLanguage !== languageEnum.ko'>
              <ion-item (click)='app.serviceIntroductionLinkGeneral()'
                        button>
                {{ 'Service Guide 5' | translate | titlecase}}
                <div tabindex='0'></div>
              </ion-item>
              <ion-item (click)='app.serviceIntroductionLinkEducation()'
                        button>
                {{ 'Service Guide 3' | translate | titlecase}}
                <div tabindex='0'></div>
              </ion-item>
              <ion-item (click)='app.serviceIntroductionLinkBusiness()'
                        button>
                {{ 'Service Guide 4' | translate | titlecase}}
                <div tabindex='0'></div>
              </ion-item>
            </ng-container>

            <ng-container *ngIf='app.usedLanguage === languageEnum.ko'>
              <ion-item-group>
                <!-- 서비스 소개-->
                <ion-item-divider mode='md'>교육</ion-item-divider>
                <ion-item (click)='app.serviceIntroductionLinkEducation()'
                          button>
                  {{ 'service introduction' | translate | titlecase}}
                  <div tabindex='0'></div>
                </ion-item>
                <ion-item (click)='app.serviceIntroductionLinkUseCases()'
                          button>
                  {{ 'use cases' | translate | titlecase}}
                  <div tabindex='0'></div>
                </ion-item>
                <ion-item (click)='app.serviceIntroductionLinkDigitalTextbook()'
                          button>
                  {{ 'digital textbook' | translate | titlecase}}
                  <div tabindex='0'></div>
                </ion-item>
                <hr />
              </ion-item-group>

              <ion-item-group>
                <!-- 서비스 소개-->
                <ion-item-divider mode='md'>기업</ion-item-divider>
                <ion-item (click)='app.goExternal("https://tooning.biz")'
                          button>
                  {{ 'tooning biz' | translate | titlecase}}
                  <div tabindex='0'></div>
                </ion-item>
                <ion-item (click)='app.goExternal("https://tooning.studio")'
                          button>
                  {{ 'tooning studio' | translate | titlecase}}
                  <div tabindex='0'></div>
                </ion-item>
              </ion-item-group>
            </ng-container>
          </ng-container>

          <!--     투닝 매직     -->
          <ng-container *ngIf='app.currentService===serviceType.Magic'>
            <ion-item button (click)='app.go("/magic/tooning-price-plan")'>
              {{ 'price' | translate }}
              <div tabindex='0'></div>
            </ion-item>
            <ion-item button (click)='app.goExternal("https://magic-edu-info.tooning.io/")'>
              {{ 'service introduction' | translate }}
              <div tabindex='0'></div>
            </ion-item>
            <ion-item button (click)='goToMagicStudio()'>
              {{ 'magic.to studio' | translate }}
              <div tabindex='0'></div>
            </ion-item>
          </ng-container>

          <!--     투닝 GPT     -->
          <ng-container *ngIf='app.currentService===serviceType.Gpt'>
            <ion-item (click)='app.go("/gpt/tooning-price-plan")'>
              {{ 'price' | translate | titlecase }}
              <div tabindex='0'></div>
            </ion-item>
            <ion-item (click)='app.goExternal("https://gpt-info.tooning.io/")'>
              {{ 'service introduction' | translate }}
              <div tabindex='0'></div>
            </ion-item>
            <ion-item (click)='app.goExternal("https://gpt-info.tooning.io/edu_webtoon")'>
              {{ '교육용 웹툰' }}
              <div tabindex='0'></div>
            </ion-item>
            <ion-item (click)='app.goExternal("https://gpt-info.tooning.io/webtoon_by_students")'>
              {{ '아이들이 만든 웹툰' }}
              <div tabindex='0'></div>
            </ion-item>
            <ion-item (click)='app.goExternal("https://tooning.io/canvas-share/global?id=2962840&country=kr")'>
              {{ 'AI 교육방침' }}
              <div tabindex='0'></div>
            </ion-item>
          </ng-container>

          <!--     투닝 보드     -->
          <ng-container *ngIf='app.currentService===serviceType.Board'>
            <ion-item button (click)='app.go("board")'>
              홈
              <div tabindex='0'></div>
            </ion-item>
            <ion-item data-cy='priceMb' button (click)='app.go("/board/tooning-price-plan")'>
              {{ 'price' | translate | titlecase }}
              <div tabindex='0'></div>
            </ion-item>
            <ion-item data-cy='priceMb' button (click)='app.goExternal("https://board-info.tooning.io")'>
              {{ 'service introduction' | translate | titlecase }}
              <div tabindex='0'></div>
            </ion-item>
          </ng-container>
        </ion-list>
      </ion-content>

      <ion-footer [contentDisable]='!app.isOpenSideMenu' appDisabled>
        <ion-toolbar>
          <ion-button data-cy='langChange' size='small' (click)='app.changeLanguageService.alertChangeLanguage()'
                      *ngIf='app.currentService !== serviceType.Gpt && app.currentService !== serviceType.Board'
                      fill='clear'> {{languageOriginalEnum[app.usedLanguage]}}</ion-button>
          <ion-buttons slot='end'>
            <ion-button (click)='app.goHelpCenter()'>
              <ion-icon name='help-circle-outline' slot='icon-only'
                        [style.color]='app.currentService===MAGIC?"#FFF":"#333333"'></ion-icon>
            </ion-button>
            <ion-button (click)="sideMenuPageGo('/tooning-setting')">
              <ion-icon name='settings-outline' slot='icon-only'
                        [style.color]='app.currentService===MAGIC?"#FFF":"#333333"'
                        style='font-size: 21px'></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>

    <!--        그룹 관리 학교-->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true' class='side_menu'
              contentId='main-content' disabled='true' menuId='School' type='push'>
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <!-- Content -->
      <ion-content appScrollbarTheme scrollY='true'>


        <div class='w-100 p-2'>
          <ion-button
            (click)="sideMenuPageGo('school-list')"
            expand='block'
            color='light'>
            <ion-icon name='arrow-back'></ion-icon>
            학교 리스트
          </ion-button>
        </div>

        <ion-list>
          <ion-item-group>
            <ion-item-divider class='mt-2'>
              <ion-label style='font-size: 18px;color: #ff2778;font-weight: bold;'> 학교 관리</ion-label>
            </ion-item-divider>

            <ion-item [button]='true'
                      (click)="sideMenuPageGo('school-make-basic')">
              <ion-label>학교 생성</ion-label>
            </ion-item>
            <ion-item [button]='true'
                      (click)="sideMenuPageGo('school-make-enrollment')">
              <ion-label>학교 등록</ion-label>
            </ion-item>
            <ion-item [button]='true'
                      (click)="sideMenuPageGo('school-registration-termination')">
              <ion-label>선생님 및 학생 해지</ion-label>
            </ion-item>
            <ion-item [button]='true'
                      (click)="sideMenuPageGo('school-make-lookup')">
              <ion-label>학생 및 선생님 조회</ion-label>
            </ion-item>


          </ion-item-group>

        </ion-list>

      </ion-content>
    </ion-menu>

    <!--        그룹 관리 기업-->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true' class='side_menu'
              contentId='main-content' disabled='true' menuId='Enterprise' type='push'>
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <!-- Content -->
      <ion-content appScrollbarTheme scrollY='true'>
      </ion-content>
    </ion-menu>


    <!--        template-->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true' class='side_menu'
              contentId='main-content' disabled='true' menuId='template' type='push'>
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <!-- Content -->
      <ion-content appScrollbarTheme scrollY='true'>
        <div class='w-100 p-2'>

          <ion-button
            (click)="sideMenuPageGo('template-category-list')"
            expand='block'
            color='light'>
            <ion-icon name='arrow-back'></ion-icon>
            템플릿 카테고리 리스트
          </ion-button>
        </div>

        <ion-item-group>
          <ion-item-divider class='mt-2'>
            <ion-label style='font-size: 18px;color: #ff2778;font-weight: bold;'>템플릿 카테고리 관리</ion-label>
          </ion-item-divider>

          <ion-item [button]='true' (click)="sideMenuPageGo('template-make-basic')">
            <ion-label>소개</ion-label>
          </ion-item>
          <ion-item [button]='true' (click)="sideMenuPageGo('template-make-list/ko')">
            <ion-label>템플릿 관리 (KO)</ion-label>
          </ion-item>

          <ion-item [button]='true' (click)="sideMenuPageGo('template-make-list/en')">
            <ion-label>템플릿 관리 (EN)</ion-label>
          </ion-item>
          <ion-item [button]='true' (click)="sideMenuPageGo('template-make-list/fr')">
            <ion-label>템플릿 관리 (FR)</ion-label>
          </ion-item>

          <ion-item [button]='true' (click)="sideMenuPageGo('template-make-list/jp')">
            <ion-label>템플릿 관리 (JP)</ion-label>
          </ion-item>
        </ion-item-group>


      </ion-content>
      <ion-footer>
        <ion-item>
          <ion-label>발행설정</ion-label>
          <ion-select (ionChange)='publishedFn($event.detail.value)' [(ngModel)]='status' interface='popover'>
            <ion-select-option value='publish'>발행중</ion-select-option>
            <ion-select-option value='prepare'>준비중</ion-select-option>
            <ion-select-option value='adminView'>관리자뷰</ion-select-option>
          </ion-select>
          <div tabindex='0'></div>
        </ion-item>
      </ion-footer>
    </ion-menu>

    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true' class='side_menu'
              contentId='main-content' disabled='true' menuId='textTemplate' type='push'>
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <!-- Content -->
      <ion-content appScrollbarTheme scrollY='true'>


        <div class='w-100 p-2'>
          <ion-button
            (click)="sideMenuPageGo('text-template-category-list')"
            expand='block'
            color='light'>
            <ion-icon name='arrow-back'></ion-icon>
            텍스트 템플릿 카테고리 리스트
          </ion-button>
        </div>

        <ion-item-group>
          <ion-item-divider class='mt-2'>
            <ion-label style='font-size: 18px;color: #ff2778;font-weight: bold;'>템플릿 카테고리 관리</ion-label>
          </ion-item-divider>

          <ion-item [button]='true' (click)="sideMenuPageGo('text-template-make-basic')">
            <ion-label>소개</ion-label>
          </ion-item>
          <ion-item [button]='true' (click)="sideMenuPageGo('text-template-make-list/ko')">
            <ion-label>템플릿 관리 (KO)</ion-label>
          </ion-item>

          <ion-item [button]='true' (click)="sideMenuPageGo('text-template-make-list/en')">
            <ion-label>템플릿 관리 (EN)</ion-label>
          </ion-item>
          <ion-item [button]='true' (click)="sideMenuPageGo('text-template-make-list/fr')">
            <ion-label>템플릿 관리 (FR)</ion-label>
          </ion-item>

          <ion-item [button]='true' (click)="sideMenuPageGo('text-template-make-list/jp')">
            <ion-label>템플릿 관리 (JP)</ion-label>
          </ion-item>
        </ion-item-group>


      </ion-content>
      <ion-footer>
        <ion-item>
          <ion-label>발행설정</ion-label>
          <ion-select (ionChange)='publishedFn($event.detail.value)' [(ngModel)]='status' interface='popover'>
            <ion-select-option value='publish'>발행중</ion-select-option>
            <ion-select-option value='prepare'>준비중</ion-select-option>
            <ion-select-option value='adminView'>관리자뷰</ion-select-option>
          </ion-select>
          <div tabindex='0'></div>
        </ion-item>
      </ion-footer>
    </ion-menu>

    <!--font-->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true' class='side_menu'
              contentId='main-content' disabled='true' menuId='font' type='push'>
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <!-- Content -->
      <ion-content appScrollbarTheme scrollY='true'>


        <div class='w-100 p-2'>
          <ion-button (click)="sideMenuPageGo('type-selection', true)" expand='block' color='light'>
            <ion-icon name='arrow-back'></ion-icon>
            뒤로가기
          </ion-button>
        </div>

      </ion-content>
    </ion-menu>


    <!--   툰스퀘어 어드민     -->
    <ion-menu (ionDidClose)='app.isOpenSideMenu = false' (ionDidOpen)='app.isOpenSideMenu = true'
              contentId='main-content'
              disabled='true' menuId='toonsquare-admin' type='push'>
      <!-- Content -->
      <ion-header>
        <app-profile></app-profile>
      </ion-header>
      <ion-content appScrollbarTheme scrollY='true'>
        <div class='w-100 p-2'>
          <ion-button (click)="sideMenuPageGo('admin', true)" expand='block' color='light'>
            <ion-icon name='arrow-back'></ion-icon>
            뒤로가기
          </ion-button>

        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id='main-content'></ion-router-outlet>
  </ion-split-pane>
  <!-- 앱 업데이트 알림 -->
  <app-update></app-update>
  <!-- 앱 업데이트 알림 -->
</ion-app>
<app-help-center-button *ngIf='data.helpCenter' [currentPath]='currentPath'></app-help-center-button>
