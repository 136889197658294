<ion-header>
  <ion-toolbar>
    <ion-toolbar>
      <ion-title>
        {{ 'template setting' | translate }}
      </ion-title>
      <ion-buttons class='m-0 mr-2' slot='end'>
        <ion-button (click)='close()'>
          <ion-icon name='close-outline' slot='icon-only'></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-toolbar>
</ion-header>
<ion-content appScrollbarTheme #content>
  <div class='row py-4 px-2' id='forTagCapyId'>
    <div class='col-md-6' *ngIf='readyToRender'>
      <div class='row'>
        <div class='col-12 py-3'>
          <mat-form-field class='w-100'>
            <input matInput maxlength='100' placeholder='제목을 입력하세요' autocomplete='off'
                   [(ngModel)]='modelTemplateInput.title' />
          </mat-form-field>
        </div>
      </div>
      <div class='row'>
        <div class='col-12'>
          <div style='width: 80%; margin: auto'>
            <div>
              <img alt='thumbnail image' src='{{ imgSrc }}' class='rounded-xl mx-2' />
            </div>
          </div>
        </div>
      </div>
      <div class='row' style='font-size: 13px; color: #333333; line-height: 30px'>
        위 이미지는 썸네일만 보여줍니다. <br />
        첫 페이지 : 썸네일 <br />
        두번째 페이지 : 실제 보여지는 텍스트 <br />( 2페이지 모두 있어야 합니다 )
      </div>
    </div>
    <div class='col-md-6' *ngIf='readyToRender'>
      <div class='ui placeholder segment p-0'>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <div class='col-12'>
              <ion-item>
                <ion-label>언어</ion-label>
                <ion-select interface='alert' [(ngModel)]='modelTemplateInput.language'>
                  <ng-container *ngFor='let languageType of app.languageTypeList'>
                    <ion-select-option [value]='languageType'>{{languageType}}</ion-select-option>
                  </ng-container>
                </ion-select>
                <div tabindex='0'></div>
              </ion-item>
            </div>
          </div>
          <div class='row'>
            <div class='col-12'>
              <ion-item detail lines='full' (click)='presentAlertCheckbox()' button='true'>
                <ion-label>카테고리</ion-label>
                <div tabindex='0'></div>
              </ion-item>
              <div class='col-md-12 p-3 d-flex flex-wrap'>
                <ion-chip *ngFor='let category of selectedCategoryList; let i = index'>
                  <ion-label>{{ category[app.languageKey] }}</ion-label>
                  <ion-icon name='close-circle' (click)='removeTextTemplateTag(i)'></ion-icon>
                </ion-chip>
              </div>
            </div>
          </div>
        </div>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <div class='col-md-12 p-3' style="font-family: 'Noto Sans', sans-serif; font-size: 14px">
              <b>전체 태그입력</b>
            </div>
          </div>

          <div class='row'>
            <mat-form-field class='w-100 px-2'>
              <input matInput maxlength='100' placeholder='예시)코로나 / 쉼표로 구분, 엔터로 자동입력.' autocomplete='off'
                     (keydown.enter)='templateEnterEvent$.next($event)' [(ngModel)]='templateTagText' />
            </mat-form-field>
            <div class='row w-100'>
              <div class='col-md-11 pt-1 d-flex flex-wrap' style='margin-top: -10px; margin-bottom: 0'>
                <ion-chip *ngFor='let tag of templateTags; let i = index'>
                  <ion-label>{{ tag }}</ion-label>
                  <ion-icon name='close-circle' (click)='removeTemplateTag(i)'></ion-icon>
                </ion-chip>
              </div>
              <div class='col-md-1 pl-1'>
                <button style='background-color: white' (click)='tagCopy(templateTags)'><i
                  class='far fa-copy fa-2x'></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <ion-item id='paid' class='w-100'>
              <ion-label>유료 템플릿</ion-label>
              <ion-toggle slot='end' name='status' [(ngModel)]='modelTemplateInput.paid'></ion-toggle>
            </ion-item>
          </div>
        </div>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <ion-item id='status' class='w-100'>
              <ion-label>발행</ion-label>
              <ion-toggle slot='end' name='status' [(ngModel)]='modelTemplateInput.status'></ion-toggle>
            </ion-item>
          </div>
        </div>

      </div>
    </div>
  </div>
</ion-content>
<ion-footer *ngIf='readyToRender' class='d-flex justify-content-between'
><!---->
  <div>
    <ion-button class='ml-2' color='medium' mode='ios' (click)='deleteTemplate()' >
      {{ 'template unload' | translate }}
    </ion-button>
  </div>
  <div class='d-flex'>
    <ion-item lines='none' button='false' *ngIf='modelTemplateInput.status'>
      <ion-label style='margin-bottom: 12px'>{{ 'Pulling up' | translate }}</ion-label>
      <ion-checkbox slot='start' color='main' [(ngModel)]='modelTemplateInput.isRenewUpdateDate'></ion-checkbox>
      <div tabindex='0'></div>
    </ion-item>
    <ion-button class='mr-2' color='main' mode='ios' (click)='createTemplate()' >
      {{ 'complete' | translate }}
    </ion-button>
  </div>
</ion-footer>
