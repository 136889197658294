import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TemplateService } from '../../services/template/template.service';
import { ResMakerCharacterService } from '../../services/res-maker/character.service';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { ModelTextTemplateInput } from '../../services/template/template.service.model';
import { Loading } from '../../services/loading';
import { AlertController, ModalController } from '@ionic/angular';
import { Cut4MakeManualService } from '../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { LanguageKey, LanguageType, TemplateType } from '../../enum/app.enum';
import {
  TooningBadRequestError,
  TooningDeleteTextTemplateError,
  TooningErrorCode,
  TooningServerConnectionError,
  TooningTagError,
  TooningUnauthorizedError,
  TooningUnkownNetworkError
} from '../../pages-tooning/errors/TooningErrors';
import HttpStatusCode from '../../pages-tooning/enums/httpErrors.enum';

@Component({
  selector: 'app-create-text-template',
  templateUrl: './create-text-template.component.html',
  styleUrls: ['./create-text-template.component.scss']
})
export class CreateTextTemplateComponent implements OnInit {
  public readyToRender = false;
  public categoryList;
  public templateData;
  public templateTags = [];
  public templageBackUpTags = [];
  public imgSrc: string;
  public pageLength: number;
  public modelTemplateInput = new ModelTextTemplateInput();
  public prevLanguage = LanguageType.ko;
  public languageTypeEnum = LanguageType;
  public loading = new Loading();
  public templateTagText;
  public selectedCategoryList = [];
  public templateEnterEvent$ = new Subject<string>();

  @Input('canvasId') canvasId;
  private templateEnterTag$ = new Subject<string>();
  // @ts-ignore
  @ViewChild('content') private content: any;

  constructor(
    public templateService: TemplateService,
    public characterService: ResMakerCharacterService,
    public app: AppService,
    public modalCtrl: ModalController,
    public alertController: AlertController,
    public cut: Cut4MakeManualService
  ) {}

  ngOnInit() {
    this.templateEnterTag$.pipe(throttleTime(500)).subscribe(async (enterTag) => {
      this.templageBackUpTags.push(enterTag);
      this.templateTags.push(enterTag);
      if (navigator.platform === 'mac' || navigator.platform === 'MacIntel') await this.app.delay(300);
      this.templateTagText = '';
    });

    this.templateEnterEvent$.pipe(throttleTime(500)).subscribe(async (event) => {
      await this.templateTagEnter(event);
    });
  }

  async ionViewWillEnter() {
    this.readyToRender = false;
    await this.getCategoryNameList();
    await this.getData();

    this.readyToRender = true;
  }

  /**
   * data set
   */
  async getData() {
    try {
      const { data } = await this.templateService.getTextTemplate(this.canvasId).toPromise();
      this.imgSrc = data.base64;
      this.pageLength = data.pageLength;

      this.selectedCategoryList = data.textTemplateCategory;
      this.templateTags = data.tags.map((tag) => tag.name);

      // modelTemplateInput type과 맞춰주기 위해 삭제
      delete data.tags;
      delete data.textTemplateCategory;
      delete data.base64;
      delete data.pageLength;
      delete data.__typename;

      this.modelTemplateInput = data;
      this.prevLanguage = data.language;
      if (this.pageLength < 2) {
        await this.app.showToast('텍스트 템플릿은 첫 번째 : 썸네일, 두 번째 : 실제 보여지는 데이터 입니다. 최소 두장의 페이지를 생성해주세요', 5000);
      }
    } catch (e) {
      console.error(`텍스트 템플릿 가져오기 실패 - canvas id : ${this.canvasId}, error - ${e.message}`);
      this.app.showToast(`텍스트 템플릿 가져오기 실패 - canvas id : ${this.canvasId}, error - ${e.message}`);
    }
  }

  /**
   * 텍스트 카테고리 이름 리스트 불러오기
   */
  async getCategoryNameList() {
    try {
      // 카테고리 이름 리스트
      const { data } = await this.templateService.selectTextTemplateCategory().toPromise();
      this.categoryList = data;
    } catch (e) {
      console.error(e);
      await this.app.showToast(`카테고리 이름 리스트 가져오기 실패 - ${e.message}`);
    }
  }

  /**
   * 텍스트 템플릿 태그 입력
   * @param event 태그 입력 이벤트
   */
  public async templateTagEnter(event) {
    try {
      if (event.target.value !== '') {
        const tagValue = event.target.value;
        this.templateTagText = tagValue;

        let splitTag = this.cut.inputTagCheck(tagValue, this.templateTags);
        if (!splitTag) {
          await this.app.showToast('중복된 태그 내용입니다. 다른 태그를 넣어주세요');
          return;
        }

        const tagInCategory = this.duplicatedName(this.modelTemplateInput.language, splitTag);

        if (tagInCategory.length > 0) {
          splitTag = splitTag.filter((tag) => tagInCategory.find((categoryTag) => categoryTag !== tag));
          this.app.showToast(`카테고리 이름과 동일한 태그를 입력할 수 없습니다. 카테고리 이름 : ${tagInCategory}`, 3000);
        }

        if (this.templateTags.length !== 0) {
          if (splitTag.length === 1) {
            this.templateEnterTag$.next(splitTag[0]);
          } else if (splitTag.length > 1) {
            this.templateTags.push(...splitTag.filter((tag) => this.templateTags.indexOf(tag) === -1));
            this.templateTagText = '';
          }
        } else {
          this.templateTags.push(...splitTag);
          event.target.value = '';
        }
      } else {
        await this.app.showToast('입력된 내용이 없습니다.');
      }
    } catch (e) {
      console.error(e);
      this.app.showToast(`테그 추가 오류 : ${e.message}`);
    }
  }

  /**
   * 텍스트 템플릿 태그 삭제
   * @param selectedTag 선택한 태그
   */
  public removeTemplateTag(selectedTag: number) {
    this.templateTags.splice(selectedTag, 1);
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  // 카테고리 선택
  async presentAlertCheckbox() {
    const inputArr = [];
    let isCheck = false;

    for (const category of this.categoryList) {
      let categoryName;

      if (this.selectedCategoryList.length > 0) {
        if (this.selectedCategoryList.find((selectedTemplate) => selectedTemplate.id === category.id)) {
          isCheck = true;
        } else {
          isCheck = false;
        }
      }

      categoryName = category[this.getLanguageKey(this.modelTemplateInput.language)];
      inputArr.push({
        name: categoryName,
        type: 'checkbox',
        label: categoryName,
        value: category,
        checked: isCheck
      });
    }
    const alert = await this.alertController.create({
      cssClass: 'basic-dialog',
      header: '카테고리 선택',
      inputs: inputArr,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        },
        {
          text: 'Ok',
          handler: (selectedTemplateValue) => {
            this.selectedCategoryList = selectedTemplateValue;
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * 텍스트 템플릿 카테고리 태그 삭제
   * @param selectedTag 선택한 태그
   */
  public removeTextTemplateTag(selectedTag: number) {
    this.selectedCategoryList.splice(selectedTag, 1);
  }

  /**
   * 템플릿 발행
   */
  async createTemplate() {
    try {
      if (this.pageLength < 2) {
        await this.app.showToast('텍스트 템플릿은 첫 번째 : 썸네일, 두 번째 : 실제 보여지는 데이터 입니다. 최소 두장의 페이지를 생성해주세요', 6000);
        return;
      }

      if (!this.modelTemplateInput.title) {
        await this.app.showToast('제목을 입력하세요');
        return;
      }

      if (this.selectedCategoryList.length <= 0) {
        await this.app.showToast('카테고리를 선택하세요');
        return;
      }
      await this.loading.showLoader('템플릿 업데이트중,,');
      this.modelTemplateInput.categoryIds = this.selectedCategoryList.map((category) => category.id);
      await this.templateService.updateTextTemplate(this.modelTemplateInput);
      await this.app.showToast('템플릿 업데이트를 완료했습니다.');

      try {
        if (this.isDifferentPrevLang()) {
          this.tagReset();
        }
        const templateNameList = this.selectedCategoryList.map((category) => {
          const languageKey = this.getLanguageKey(this.modelTemplateInput.language);
          console.log(this.modelTemplateInput.language);
          return category[languageKey];
        });
        this.templateTags.push(...templateNameList);
        // 템플릿 태그 생성
        await this.templateService.TemplateTagsCreate(this.modelTemplateInput.id, TemplateType.text, this.templateTags);
      } catch (e) {
        throw new TooningTagError(`태그 생성 실패 ${e.message}`, this.app);
      }
      await this.modalCtrl.dismiss({
        id: this.canvasId,
        isDelete: false,
        status: this.modelTemplateInput.status,
        title: this.modelTemplateInput.title
      });
    } catch (error) {
      if (error instanceof TooningTagError) {
        console.log('TooningTagError');
        throw error;
      } else {
        console.error(error);
        const { graphQLErrors, networkError } = error;

        if (graphQLErrors) {
          for (const gError of graphQLErrors) {
            const errorCode = +gError.extensions.exception.code;
            switch (errorCode) {
              case TooningErrorCode.TOONING_SERVER_NOT_EXIST_TEMPLATE_CATEGORY_ERR:
                await this.app.showToast('존재하지 않는 템플릿입니다. 관리자에게 문의해주세요 :)');
                break;
              case TooningErrorCode.TOONING_SERVER_NOT_EXIST_CANVAS_ERR:
                await this.app.showToast('존재하지 않는 캔버스입니다. 관리자에게 문의해주세요 :)');
                break;
              default:
                await this.app.showToast('템플릿 생성 및 업데이트 실패. 관리자에게 문의해주세요 :)');
                break;
            }
          }
        }

        if (networkError) {
          switch (networkError.status) {
            case HttpStatusCode.BAD_REQUEST:
              await this.app.showToast('잘못된 요청입니다');
              throw new TooningBadRequestError(error.message, this.app);
            case HttpStatusCode.UNAUTHORIZED:
              this.app.orange('unauthorized access');
              this.app.redirectLogin();
              throw new TooningUnauthorizedError(error.message, this.app);
            case 0:
              throw new TooningServerConnectionError(error.message, this.app, true);
            default:
              this.app.redLog(networkError.message);
              await this.app.showToast(error.message);
              throw new TooningUnkownNetworkError(error.message, this.app);
          }
        }
      }
    } finally {
      this.loading.hideLoader();
    }
  }

  /**
   * 텍스트 템플릿 삭제 팝업
   */
  async deleteTemplate() {
    const alert = await this.alertController.create({
      cssClass: 'basic-dialog',
      header: '템플릿을 삭제하시겠습니까?',
      message: '해당 캔버스, 템플릿 모두 삭제됩니다.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        },
        {
          text: 'Ok',
          handler: () => {
            this.deleteTemplateOk();
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * 텍스트 템플릿 삭제
   */
  async deleteTemplateOk() {
    try {
      await this.templateService.deleteTextTemplate(+this.modelTemplateInput.id, +this.canvasId).toPromise();
      await this.app.showToast('템플릿을 성공적으로 삭제했습니다.');
      await this.modalCtrl.dismiss({
        id: this.canvasId,
        isDelete: true
      });
    } catch (e) {
      console.error(e);
      await this.app.showToast('템플릿을 삭제하지 못했습니다. 관리자에게 문의하세요 :)');
      throw new TooningDeleteTextTemplateError(e.message, this.app);
    }
  }

  /**
   * 태그 목록 텍스트로 복사
   * @param tagList 복사 할 태그 리스트
   */
  async tagCopy(tagList) {
    const getBox = document.getElementById('forTagCapyId');
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = tagList;
    getBox.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    getBox.removeChild(selBox);
    await this.app.showToast('복사가 완료되었습니다.');
  }

  /**
   * 언어체크 해서 해당 언어 값 리턴
   * @param languageType 사용 언어
   */
  getLanguageKey(languageType: string): LanguageKey {
    try {
      let languageKey = LanguageKey.ko;
      switch (languageType) {
        case LanguageType.ko:
          languageKey = LanguageKey.ko;
          break;
        case LanguageType.en:
          languageKey = LanguageKey.en;
          break;
        case LanguageType.fr:
          languageKey = LanguageKey.fr;
          break;
        case LanguageType.jp:
          languageKey = LanguageKey.jp;
          break;
      }

      return languageKey;
    } catch (e) {
      throw new Error(`getLanguageKey : ${e}`);
    }
  }

  // setList(languageType: string, tags: LanguageInterface[]) {
  //   try {
  //     const languageKey = this.getLanguageKey(languageType);
  //     this.templateTags = tags.map((tag) => tag[languageKey]);
  //   } catch (e) {
  //     throw new Error(`setTag : ${e}`);
  //   }
  // }

  /**
   * 중복되는 카테고리 이름, 태그 이름 체크
   * @param languageType 템플릿 언어
   * @param tags 체크 할 태그 리스트
   */
  duplicatedName(languageType: string, tags: string[]): string[] {
    try {
      const resultList: string[] = [];
      const languageKey = this.getLanguageKey(languageType);
      // 카테고리 이름과 태그 이름이 중복되는 리스트
      for (const tag of tags as string[]) {
        const categoryName = this.categoryList.find((category) => category[languageKey] === tag);
        if (categoryName) {
          resultList.push(categoryName);
        }
      }

      return resultList;
    } catch (e) {
      throw new Error(`duplicatedName : ${e}`);
    }
  }

  /**
   * 텍스트 템플릿 언어 바꼈는지 체크
   */
  isDifferentPrevLang() {
    try {
      return this.modelTemplateInput.language !== this.prevLanguage;
    } catch (e) {
      throw new Error(`isDifferentPrevLang : ${e}`);
    }
  }

  /**
   * 텍스트 템플릿 태그 리셋
   */
  tagReset() {
    try {
      this.templateTags = [];
    } catch (e) {
      throw new Error(`tagReset : ${e}`);
    }
  }
}
