import { Component, OnInit } from '@angular/core';
import { FaqService } from '../../../services/faq/faq.service';
import { Loading } from '../../../services/loading';
import { ServiceType } from '../../../enum/app.enum';
import { Faq } from '../../../model/faq/faq';
import { AppService } from '../../../services/app.service';
import { TooningHelpCenterModalError, TooningHelpCenterWebError } from '../../../pages-tooning/errors/TooningErrors';

@Component({
  selector: 'app-help-center-web-component',
  templateUrl: './help-center-web-component.component.html',
  styleUrls: ['./help-center-web-component.component.scss']
})
export class HelpCenterWebComponentComponent implements OnInit {
  private loading = new Loading();
  public faqList: Faq[] = [];
  private isFrequent: boolean = true;
  public searchKeyword: string = '';

  constructor(public app: AppService, private faqService: FaqService) {}

  ngOnInit(): void {
    this.searchKeyword = '';
  }

  async ngAfterContentInit(): Promise<void> {
    await this.searchFaqList();
  }

  /**
   * FAQ 리스트 조회
   * @return {Promise<void>}
   */
  async searchFaqList(): Promise<void> {
    try {
      await this.loading.showLoader();
      this.isFrequent = this.searchKeyword ? false : true;

      const { data } = await this.faqService
        .getFaqListForByUser({
          serviceType: ServiceType.Editor,
          languageType: this.app.usedLanguage,
          searchKeyword: this.searchKeyword,
          isFrequent: this.isFrequent
        })
        .toPromise();

      this.faqList = data.faqList;
    } catch (e) {
      throw new TooningHelpCenterModalError(e);
    } finally {
      this.loading.hideLoader();
    }
  }

  /**
   * 검색어 초기화
   * @return {Promise<void>}
   */
  async clearSearchKeyword(): Promise<void> {
    this.searchKeyword = '';
    await this.searchFaqList();
  }
}
