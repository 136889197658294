import { environment } from '../../../environments/environment';

const Slack = require('slack');

const errorMessageToken = environment.slack_bot_token_error_message;
const errorMessageBot = new Slack({ token: errorMessageToken });
const clientCriticalErrorChannel = '02_05_client_critical_message';
const clientQuoteChannel = '08_04_교육용프로요금제문의';
const URL = 'https://talkimg.imbc.com/TVianUpload/tvian/TViews/image/2017/07/05/42bfc9e1-f7ca-4a1c-8ab4-c435d3e1dbd8.jpg';

const fetchIp = async () => {
  const response = await fetch('https://ipinfo.io/ip');
  if (response.status !== 200) {
    return `Failed to fetch IP. HTTP Status: ${response.status}`;
  }

  const ip = await response.text();
  return ip.trim();
};

/**
 * 슬랙으로 에러 메시지 전송
 * @param {string} name
 * @param {any} message
 * @param {string | boolean} stack
 */
const slack = async (name: string, message: any, stack: string | boolean = false): Promise<void> => {
  const blocks = [
    {
      type: 'divider'
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: ':critical: *크리티컬 클라이언트 에러* :critical:'
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*메세지* :  \n${message}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*에러 이름* : ${name}`
      }
    }
  ];
  if (stack) {
    blocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*스택* : ${stack}`
      }
    });
  }
  blocks.push({
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: `*시간* :  ${new Date()}`
    }
  });

  const ip = await fetchIp();

  blocks.push({
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: `*IP* :  ${ip}`
    }
  });

  blocks.push({
    type: 'divider'
  });

  errorMessageBot.chat
    .postMessage({
      channel: clientCriticalErrorChannel,
      text: message,
      blocks: JSON.stringify(blocks)
    })
    .catch((error) => console.error(error));
};

/**
 * 슬랙으로 에러 메시지 전송
 * @param {string} name
 * @param {any} message
 * @param {string} fileName
 * @param {string} functionName
 * @param {string | boolean} stack
 */
const customSlack = async (name: string, message: any, fileName: string, functionName: string, stack: string | boolean = false) => {
  const blocks = [
    {
      type: 'divider'
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: ':critical: *크리티컬 클라이언트 에러* :critical:'
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*메세지* :  \n${message}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*에러 이름* : ${name}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*파일* : ${fileName}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*함수* : ${functionName}`
      }
    }
  ];

  if (stack) {
    blocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*스택* : ${stack}`
      }
    });
  }

  blocks.push({
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: `*시간* :  ${new Date()}`
    }
  });

  const ip = await fetchIp();

  blocks.push({
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: `*IP* :  ${ip}`
    }
  });

  blocks.push({
    type: 'divider'
  });

  errorMessageBot.chat
    .postMessage({
      channel: clientCriticalErrorChannel,
      text: message,
      blocks: JSON.stringify(blocks)
    })
    .catch((error) => console.error(error));
};

/**
 * b2g 견적서 정보 슬랙으로 전송하는 함수
 * @param email 신청자 이메일
 * @param imgURL s3 url
 * @param payment 견제 방식
 * @param loginType 로그인 타입
 * @param {string} phoneNumber 신청자 휴대폰 번호
 * @param {string} officeNumber 사무실 번호
 * @param {string} officeEmail 사무실 이메일
 * @param {string} estimateStep 견적서 단계
 * @return {Promise<unknown>}
 */
const slackQuote = (
  email: any,
  imgURL: any,
  payment: any,
  loginType: any,
  phoneNumber: string,
  officeNumber: string,
  officeEmail: string,
  estimateStep: string
) => {
  const blocks = [
    {
      type: 'divider'
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: '*교육용 PRO 요금제 문의* '
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*이메일* :  ${email}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*전화번호* :  ${phoneNumber}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*행정실 이메일 (선택)* :  ${officeEmail}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*행정실 전화번호* :  ${officeNumber}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*결제 방식* :  ${payment}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*투닝 단체 계정 발급 방식* :  ${loginType}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*견적서 이미지 URL 주소 * : ${imgURL}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*견적서 단계 * : ${estimateStep}`
      }
    }
  ];

  blocks.push({
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: `*시간* :  ${new Date()}`
    }
  });

  return new Promise((resolve, reject) => {
    errorMessageBot.chat
      .postMessage({
        channel: clientQuoteChannel,
        text: imgURL,
        blocks: JSON.stringify(blocks)
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

const resourceErrMessage = async (resourceType: string, resourceId: number, fileName: string, functionName: string) => {
  const blocks = [
    {
      type: 'divider'
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*resource error*`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*리소스 타입* : ${resourceType}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*리소스 아이디* : ${resourceId}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*함수* : ${functionName}`
      }
    }
  ];

  blocks.push({
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: `*시간* :  ${new Date()}`
    }
  });

  blocks.push({
    type: 'divider'
  });

  errorMessageBot.chat
    .postMessage({
      channel: clientCriticalErrorChannel,
      text: '리소스 에러 발생',
      blocks: JSON.stringify(blocks)
    })
    .catch((error) => console.error(error));
};
export { customSlack, slack, slackQuote, resourceErrMessage };
