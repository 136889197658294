<ion-header>
  <ion-toolbar>
    <ion-title slot='start'>{{ 'hot key' | translate }}</ion-title>
    <ion-buttons slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content #content appScrollbarTheme>
  <ion-segment color='editor' mode='md' [(ngModel)]='ctrl'>
    <ion-segment-button
      style='width: 50%;text-transform: none;' value='Ctrl'>
      Windows
    </ion-segment-button>
    <ion-segment-button style='width: 50%;text-transform: none;' value='Command'>
      Mac
    </ion-segment-button>
  </ion-segment>
  <div class='p-3'>
    <!--  기본  -->
    <div style='font-size: 14px;
    color: #cccccc;'>{{ 'hotKey.title.1' | translate }}</div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey1' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>{{ctrl}}</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>C</div>
      </div>

    </div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey2' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>{{ctrl}}</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>V</div>
      </div>

    </div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey3' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>{{ctrl}}</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>X</div>
      </div>

    </div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey4' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>DEL</div>
      </div>

    </div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey5.title' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>Shift</div>
        <div class='plus_style'> +</div>
        <div style='color: #6c6c6c'>{{ 'hotKey.hotKey5.add' | translate }}</div>
      </div>

    </div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey6.title' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>space bar</div>
        <div class='plus_style'> +</div>
        <div style='color: #6c6c6c'>{{ 'hotKey.hotKey6.add' | translate }}</div>
      </div>

    </div>

    <!--  순서  -->
    <div style='margin-top:30px;font-size: 14px;
    color: #cccccc;'>{{ 'hotKey.title.2' | translate }}</div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey7' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>{{ctrl}}</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>]</div>
      </div>

    </div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey8' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>Ctrl</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>Shift</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>]</div>
      </div>

    </div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey9' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>{{ctrl}}</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>[</div>
      </div>

    </div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey10' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>Ctrl</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>Shift</div>
        <div class='plus_style'> +</div>
        <div class='button_style'>[</div>
      </div>

    </div>


    <!--  보기  -->
    <div style='margin-top:30px;font-size: 14px;
    color: #cccccc;'>{{ 'hotKey.title.3' | translate }}</div>
    <div class=' d-flex justify-content-between line_style'>
      <div>{{ 'hotKey.hotKey11' | translate }}</div>
      <div class='d-flex align-items-center'>
        <div class='button_style'>F10</div>
      </div>

    </div>


  </div>
</ion-content>
