<div *ngIf='app.isDesktopWidth()'>
  <div class='help-center-support-container'>
    <div class='help-center-support-content'>
      <div class='help-center-support-title'>{{ 'help-center.support-title' | translate }}</div>
      <div>{{ 'help-center.help-center' | translate }}(<span class='help-center-support-mail' (click)='copyEmail()'>{{ supportMail }}</span>) {{ 'help-center.help-center-sub' | translate }}</div>
    </div>
  </div>
</div>

<div *ngIf='!app.isDesktopWidth()'>
  <div class='support-container'>
    <div class='support-title'>{{ 'help-center.support-title' | translate }}</div>
    <div class='support-description'>{{ 'help-center.help-center' | translate }}(<span class='help-center-support-mail' (click)='copyEmail()'>{{ supportMail }}</span>) {{ 'help-center.help-center-sub' | translate }}</div>
  </div>
</div>