import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ResMakerCharacterService } from '../../../../../services/res-maker/character.service';
import { Cut4MakeManualService } from '../../../cut4-make-manual.service';
import { AppService } from '../../../../../services/app.service';
import {
  TooningSearchAllCharacterError,
  TooningCharacterReplacerConfirmError,
  TooningExecuteCharacterReplacerError
} from '../../../../../pages-tooning/errors/TooningErrors';
import { PromiseStatus } from '../../../../../enum/app.enum';
import { Loading } from '../../../../../services/loading';

@Component({
  selector: 'app-modal-replacer-character',
  templateUrl: './character-replacer.component.html',
  styleUrls: ['./character-replacer.component.scss']
})
export class CharacterReplacerComponent implements OnInit {
  public selectedCharacter;
  public selectedCharacterName: string;
  public selectedCharacterId: number;
  public characterCount: number;
  public characterList: any = [];
  public disableNextButton: boolean;
  public canvasNumber: number;

  @Input('canvasIdList') canvasIdList;
  @Input('userId') userId;
  @Input('canvasGroupId') canvasGroupId;

  constructor(
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public resMakerCharacterService: ResMakerCharacterService,
    public cut: Cut4MakeManualService,
    public app: AppService,
    public translate: TranslateService,
    public dialog: MatLegacyDialog
  ) {}

  async ngOnInit() {
    try {
      this.disableNextButton = true;
      let enterPriseCharacterList: any = await this.resMakerCharacterService.getEnterPriseCharacterList();
      let tooningCharacterList: any = await this.resMakerCharacterService.getTooningCharacterList();
      this.characterList = enterPriseCharacterList.concat(tooningCharacterList);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * modal 닫기
   * @returns {Promise<void>}
   */
  async close(): Promise<void> {
    if (this.modalCtrl !== null) {
      await this.modalCtrl.dismiss();
    }
  }

  /**
   * 기존의 캐릭터를 대체할 캐릭터를 선택한 후 일괄 변경 선택 가능
   * @param event - 캐릭터 선택 이벤트
   * @returns {Promise<void>}
   */
  async select(event: any): Promise<void> {
    try {
      this.disableNextButton = false;
      this.selectedCharacterName = event.value.name_ko;
      this.selectedCharacterId = event.value.id;
      await this.app.showToast(this.translate.instant('saveChange'), 100);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 캐릭터를 검색한다
   * @returns {Promise<void>}
   */
  async searchAllCharacter(): Promise<void> {
    const loading = new Loading();
    try {
      await loading.showLoader(this.translate.instant('replacer.character.searchingCharacter'), 2000 * 60 * 3);
      const data = await this.resMakerCharacterService.countSearchingCharacter(this.userId, this.canvasIdList).toPromise();
      this.characterCount = data.data.countSearchingCharacter;
    } catch (e) {
      console.error(e);
      await this.app.showToast(this.translate.instant('replacer.character.searchingCharacterFail'), 300, 'dark', 'middle');
      throw new TooningSearchAllCharacterError(e, this.app, true);
    } finally {
      loading.hideLoader();
    }
  }

  /**
   * 캐릭터 일괄 변경 실행 전 확인 알림창
   * @returns {Promise<void>}
   */
  async characterReplacerConfirm(): Promise<void> {
    try {
      await this.searchAllCharacter();

      if (this.characterCount > 0) {
        const alert = await this.alertController.create({
          cssClass: 'replacer-confirm',
          subHeader:
            this.translate.instant('replacer.total') +
            this.characterCount +
            this.translate.instant('replacer.character.changeCharacterExplain') +
            this.selectedCharacterName +
            this.translate.instant('replacer.character.changeCharacterExplain2'),
          message: this.translate.instant('replacer.askContinue'),
          buttons: [
            {
              text: this.translate.instant('replacer.cancel')
            },
            {
              text: this.translate.instant('replacer.okay'),
              handler: async () => {
                this.cut.isModalOpend = true;
                await alert.dismiss();
                await this.modalCtrl.dismiss();
                await this.executeCharacterReplacer();
              }
            }
          ]
        });
        await alert.present();
      } else {
        await this.app.showToast(this.translate.instant('replacer.character.noCharacter'), 300, 'dark', 'middle');
      }
    } catch (e) {
      throw new TooningCharacterReplacerConfirmError(e, null, true);
    }
  }

  /**
   * 캐릭터 일괄 변경 실행
   * @returns {Promise<void>}
   */
  async executeCharacterReplacer(): Promise<void> {
    const loading = new Loading();
    await loading.showLoader('', 10000 * 60 * 3);
    try {
      const canvasClonePromises = [];
      const successList = [];
      const failedList = [];

      for (const canvasId of this.canvasIdList) {
        this.canvasNumber = this.canvasIdList.indexOf(canvasId);
        // 테스트를 위해 캔버스 클론하는 방식으로 변경. 기존 코드는 https://github.com/toonsquare/tooning-repo/pull/4681
        canvasClonePromises.push(
          await this.resMakerCharacterService.replaceCharacterJSON(this.userId, canvasId, this.selectedCharacterId, this.canvasGroupId)
        );
      }

      await Promise.allSettled(canvasClonePromises).then(
        (results) => {
          results.forEach((result) => {
            if (result.status === PromiseStatus.fulfilled) {
              successList.push(result);
            } else {
              failedList.push(result);
            }
          });
        },
        async (e) => {
          console.error(e);
          await this.app.showToast(failedList + this.translate.instant('replacer.failDetail'), 1000, 'dark', 'middle');
        }
      );

      loading.hideLoader();
      const alert = await this.alertController.create({
        cssClass: 'replacer-confirm',
        subHeader: this.translate.instant('replacer.total') + successList.length + this.translate.instant('replacer.changeCompleted'),
        message: this.translate.instant('replacer.checkCompleted'),
        backdropDismiss: false,
        buttons: [
          {
            text: this.translate.instant('replacer.okay'),
            handler: async () => {
              await this.app.showToast(this.translate.instant('replacer.character.characterDone'), 3000, 'primary', 'middle');
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      throw new TooningExecuteCharacterReplacerError(e, this.app, true);
    } finally {
      loading.hideLoader();
    }
  }
}
