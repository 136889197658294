<ion-header>
  <h1>
    <p>
      단체 등록 요금제 추가
    </p>
    <button (click)='createPrice()' style='justify-content: center'>
      <ion-icon name='add-outline'></ion-icon>
      추가
    </button>
    <button *ngIf='!isEditMode' (click)='changeEditMode()'>
      <ion-icon src='/assets/icon/rename_pencil.svg'></ion-icon>
      편집
    </button>
  </h1>
  <h2>
    <ion-button fill='clear' (click)='closeModal()' >
      <ion-icon name='close-outline'></ion-icon>
    </ion-button>
  </h2>
  <!--  x-->
</ion-header>
<ion-content>
  <table class='content-header' aria-label='add edu group price table'>
    <thead>
    <tr>
      <th *ngIf='isEditMode'></th>
      <th class='header-index'>순서</th>
      <th>최소인원</th>
      <th>최대인원</th>
      <th>사용기간(달)</th>
      <th>인당가격</th>
      <th>인당원가</th>
      <th>화폐단위</th>
      <th>이벤트</th>
      <th>시작날짜</th>
      <th>종료날짜</th>
      <th></th>
    </tr>
    </thead>
    <tbody #sortableJs>
    <tr *ngFor='let eduGroupPrice of eduGroupPriceList; let i = index' [attr.data-id]='eduGroupPrice.id'>
      <td class='handle' *ngIf='isEditMode'>::</td>
      <td class='content-index'>{{eduGroupPrice.order}}</td>
      <td class='content-index'>{{eduGroupPrice.dirty}}</td>
      <td>
        <input [readOnly]='!isEditMode' type='text' size='2' [(ngModel)]='eduGroupPrice.minPersonnel'
               (input)='eduGroupPrice.dirty = true' />
      </td>
      <td>
        <input [readOnly]='!isEditMode' type='text' size='3' [(ngModel)]='eduGroupPrice.maxPersonnel'
               (input)='eduGroupPrice.dirty = true' />
      </td>
      <td>
        <input [readOnly]='!isEditMode' type='text' size='1' [(ngModel)]='eduGroupPrice.usedPeriod'
               (input)='eduGroupPrice.dirty = true' />
      </td>
      <td>
        <input [readOnly]='!isEditMode' type='text' size='5' [(ngModel)]='eduGroupPrice.pricePerPerson'
               (input)='eduGroupPrice.dirty = true' />
      </td>
      <td>
        <input [readOnly]='!isEditMode' type='text' size='5' [(ngModel)]='eduGroupPrice.costPerPerson'
               (input)='eduGroupPrice.dirty = true' />
      </td>
      <td>
        <ion-button fill='clear' (click)='eduGroupIndex = i' [matMenuTriggerFor]='currencyMenu'
                    [disabled]='!isEditMode'>{{eduGroupPrice.currency}}</ion-button>
      </td>
      <td>
        <ion-toggle [(ngModel)]='eduGroupPrice.isPromotion' [disabled]='!isEditMode'
                    (click)='eduGroupPrice.dirty = true'></ion-toggle>
      </td>
      <td [style.visibility]='eduGroupPrice.isPromotion ? "visible" : "hidden"'>
        <input [readOnly]='!isEditMode' type='datetime-local' size='5'
               [(ngModel)]='eduGroupPrice.promotionStartDate' (input)='eduGroupPrice.dirty = true' />
      </td>
      <td [style.visibility]='eduGroupPrice.isPromotion ? "visible" : "hidden"'>
        <input [readOnly]='!isEditMode' type='datetime-local' size='5'
               [(ngModel)]='eduGroupPrice.promotionEndDate' (input)='eduGroupPrice.dirty = true' />
      </td>
      <td>
        <div class='d-flex' *ngIf='!isEditMode'>
          <button style='border-bottom-right-radius: 4px; border-top-right-radius:4px'
                  (click)='deletePrice(eduGroupPrice.id, i)'>
            <ion-icon name='trash-outline'></ion-icon>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</ion-content>

<ion-footer>
  <ion-button class='tooltip-footer' (click)='tooltipOpen()'>
    <ion-icon name='help-circle-outline'></ion-icon>
    <p>삭제한 요금제를 다시 만들고 싶어요.</p></ion-button>
  <ion-toolbar>
    <ion-button *ngIf='isEditMode' slot='end' fill='clear' (click)='initEduGroupPrice()' style='margin-right: 20px'>
      취소
    </ion-button>
    <ion-button *ngIf='isEditMode' slot='end' (click)='updatePrice()' style='margin-right: 20px'>
      저장
    </ion-button>
  </ion-toolbar>
</ion-footer>
<mat-menu #usedPeriodUnitMenu='matMenu'>
  <button mat-menu-item
          (click)='eduGroupPriceList[eduGroupIndex].usedPeriodUnit = usedPeriodUnitEnum.day; eduGroupPriceList[eduGroupIndex].dirty = true'>
    일
  </button>
  <button mat-menu-item
          (click)='eduGroupPriceList[eduGroupIndex].usedPeriodUnit = usedPeriodUnitEnum.month; eduGroupPriceList[eduGroupIndex].dirty = true'>
    월
  </button>
  <button mat-menu-item
          (click)='eduGroupPriceList[eduGroupIndex].usedPeriodUnit = usedPeriodUnitEnum.year; eduGroupPriceList[eduGroupIndex].dirty = true'>
    년
  </button>
</mat-menu>

<mat-menu #currencyMenu='matMenu'>
  <button mat-menu-item
          (click)='eduGroupPriceList[eduGroupIndex].currency = currencyEnum.KRW; eduGroupPriceList[eduGroupIndex].dirty = true'>
    원
  </button>
  <button mat-menu-item
          (click)='eduGroupPriceList[eduGroupIndex].currency = currencyEnum.JPY; eduGroupPriceList[eduGroupIndex].dirty = true'>
    엔
  </button>
</mat-menu>
