export enum BookmarkResourceType {
  Character = 'Character', // 캐릭터
  Text = 'Text', // 텍스트
  Template = 'Template', // 템플릿
  Item = 'Item', // 요소
  Balloon = 'Balloon', // 말풍선
  Background = 'Background', // 배경
  Effect = 'Effect' // 효과
}

export interface BookmarkData {
  id?: number;
  isActivated: boolean;
  resourceType?: BookmarkResourceType;
  resourceId?: number;
  thumbnail?: string;
  fabricObjects?: any;
}

export class Bookmark {
  private _id: number;
  private _isActivated: boolean;
  private _resourceType: BookmarkResourceType;
  private _resourceId: number;
  private _thumbnail: string;
  private _fabricObjects: any;

  constructor(data: BookmarkData) {
    this._id = data.id;
    this._isActivated = data.isActivated;
    this._resourceType = data.resourceType;
    this._resourceId = data.resourceId;
    this._thumbnail = data.thumbnail;
    this._fabricObjects = data.fabricObjects;
  }

  get isActivated() {
    return this._isActivated;
  }

  set isActivated(value) {
    this._isActivated = value;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }
}
