import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';

declare let gtag: Function;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() bgColor: string;
  // tslint:disable-next-line:variable-name
  public user_img_url;
  // tslint:disable-next-line:variable-name
  public user_name;

  constructor(public app: AppService) {}

  async ngOnInit() {
    if (this.app.user.isTokenSaved()) {
      gtag('set', { user_id: this.app.user.getUserIdx() });
      // tslint:disable-next-line:variable-name
      const user_info = await this.app.user.currentUser();
      this.user_img_url = user_info['userImgUrl'];
      this.user_name = user_info['userName'];
    }
  }
}
