import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ApolloQueryResult, gql, FetchResult } from '@apollo/client';
import { map, tap, timeout } from 'rxjs/operators';
import { GraphqlApiService } from '../api/graphql.api.service';
import { Currency } from '../../enum/app.enum';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private defaultTimeout = 6 * 1000;

  constructor(public graphql: GraphqlApiService) {}

  public getSubscribersWithStatus(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getSubscribersWithStatus {
            getSubscribersWithStatus {
              cancelSubscription
              isSubscribingNow
              userId
              lastDayOfSubscription
            }
          }
        `
      )
      .pipe(
        map((result) => {
          result.data = result.data.getSubscribersWithStatus;
          return result;
        })
      );
  }

  /**
   * 사용자의 구독 결제 정보를 가져온다. - 다음 결제일, 구독 마지막일
   */
  public getPaymentInfo(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getPaymentInfo($userId: ID!) {
            getPaymentInfo(userId: $userId) {
              nextPaymentDay
              lastDayOfSubscription
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getPaymentInfo;
          delete result.data.__typename;
          return result;
        })
      );
  }

  public getSubscriberInfo(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getSubscriberInfo($userId: ID!) {
            getSubscriberInfo(userId: $userId) {
              userId
              isCancel
              freePeriod
              createdDate
              payEvent {
                name
                freePeriod
                price
                status
              }
              paymentPlan {
                planName
                payPeriod
              }
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getSubscriberInfo;
          return result;
        })
      );
  }

  // 현재 진행중인 이벤트를 기준으로 paymentPlan 조회
  public paymentPlans(currency?: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query paymentPlans($currency: String!) {
            paymentPlans(currency: $currency) {
              planName
              id
              payPeriod
              price
              currency
              paypalPlanId
              events {
                id
                status
                currency
                price
              }
            }
          }
        `,
        {
          currency
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.paymentPlans;
          return results;
        })
      );
  }

  // read payments history
  public paymentsHistory(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query paymentsHistory($userId: ID!) {
            paymentsHistory(userId: $userId) {
              result
              message
              createdDate
              paymentHistType
              price
              currency
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data.paymentsHistory = results.data.paymentsHistory.map((data) => {
            return data;
          });
          results.data = results.data.paymentsHistory;
          return results;
        })
      );
  }

  // read payments history
  public payments(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query payments($userId: ID!) {
            payments(userId: $userId) {
              id
              payTime
              orderId
              price
              payGoods
              currency
              approvalNumber
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((results) => {
          results = results.data.payments;
          return results;
        })
      );
  }

  // read payments history
  public getRecentPayDate(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getRecentPayDate($userId: ID!) {
            getRecentPayDate(userId: $userId) {
              payTime
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.getRecentPayDate.map((recentPayment) => {
            return recentPayment;
          });
          return results;
        })
      );
  }

  public async isPaid(userId: number): Promise<boolean> {
    const temp = await this.graphql
      .query(
        gql`
          query isPaid($userId: Int!) {
            isPaid(userId: $userId)
          }
        `,
        {
          userId
        }
      )
      .toPromise();

    return temp.data.isPaid;
  }

  /**
   * subscription 과 userRole 의 정보를 조합한, userRoleWithSubscription 을 가져온다.
   * @return {Promise<any>}
   */
  public async getUserRoleWithSubscription(): Promise<any> {
    try {
      const {
        data: {
          getUserRoleWithSubscription: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query GetUserRoleWithSubscription {
              getUserRoleWithSubscription {
                result
                errorCode
                errorMessage
                data
              }
            }
          `,
          {}
        )
      );

      if (!result) {
        console.log(`getUserRoleWithSubscription error: ${errorCode} ${errorMessage}`);
        return 'FreeUser';
      }

      return data;
    } catch (e) {
      console.log(`getUserRoleWithSubscription error: ${e}`);
    }
  }

  /**
   * 유저가 다니는 학교의 구독이 끝나는 날짜를 알려준다.
   * @param {number} userId
   * @return {Promise<boolean>}
   */
  public async getSchoolExpirationDate(userId: number): Promise<boolean> {
    const temp = await this.graphql
      .query(
        gql`
          query getSchoolExpirationDate($userId: ID!) {
            getSchoolExpirationDate(userId: $userId)
          }
        `,
        {
          userId
        }
      )
      .toPromise();

    return temp.data;
  }

  public isFreePeriodDone(userId: number, payEventId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query isFreePeriodDone($userId: ID!, $payEventId: ID!) {
            isFreePeriodDone(userId: $userId, payEventId: $payEventId)
          }
        `,
        {
          userId,
          payEventId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.isFreePeriodDone;
          return results;
        })
      );
  }

  public refund(userId: number): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation refund($userId: ID!) {
            refund(userId: $userId)
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.refund;
          return results;
        })
      );
  }

  /**
   * 아임포트에 결제예약 혹은 취소 요청
   * @param {number} userId 유저 아이디
   * @param {boolean} isCancel 취소 여부
   * @return {Observable<FetchResult<any>>}
   */
  public requestIamportReservation(userId: number, isCancel: boolean): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation requestIamportReservation($userId: ID!, $isCancel: Boolean!) {
          requestIamportReservation(userId: $userId, isCancel: $isCancel)
        }
      `,
      {
        userId,
        isCancel
      }
    );
  }

  /**
   * 페이플로 결제한 유저인지 체크 아니면 아임포트로 결제
   * @param {number} userId 유저 아이디
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public isPaypel(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query isPaypel($userId: ID!) {
          isPaypel(userId: $userId)
        }
      `,
      {
        userId
      }
    );
  }

  /**
   * 빌링키 있는지 확인하는 함수
   * @param {number} userId 유저 아이디
   * @param {string} pg pg사
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public checkBillingKey(userId: number, pg: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query checkBillingKey($userId: ID!, $pg: String!) {
            checkBillingKey(userId: $userId, pg: $pg) {
              result
              billingKey
            }
          }
        `,
        {
          userId,
          pg
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.checkBillingKey;
          return result;
        })
      );
  }

  /**
   * 결제 정보를 tooning db에 저장하는 함수,토스의 경우 아임포트 api 사용해서 결제 요청함
   * @param {number} userId 유저 정보
   * @param {any} iamportInput 포인트 아임포트 정보
   * @return {Observable<FetchResult<any>>}
   */
  public chargePointRequestIamport(userId: number, iamportInput: any): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation chargePointRequestIamport($userId: ID!, $iamportInput: InputIamportUsingPoint!) {
          chargePointRequestIamport(userId: $userId, iamportInput: $iamportInput)
        }
      `,
      {
        userId,
        iamportInput
      }
    );
  }

  public chargePointUsingBillingKey(userId: number, billingKey: string, price: number, currency: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation chargePointUsingBillingKey($userId: ID!, $billingKey: String!, $price: Int!, $currency: String!) {
          chargePointUsingBillingKey(userId: $userId, billingKey: $billingKey, price: $price, currency: $currency)
        }
      `,
      {
        userId,
        billingKey,
        price,
        currency
      }
    );
  }

  /**
   * 결제 취소 쿼리 요청 함수, 테스트 용
   * @param {number} userId
   * @return {Observable<FetchResult<any>>}
   */
  public cancelPaymentUsingIamport(userId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation cancelPaymentUsingIamport($userId: ID!) {
          cancelPaymentUsingIamport(userId: $userId)
        }
      `,
      {
        userId
      }
    );
  }

  /**
   * 결제 관련 모든 데이터(구독 내역, 결제 내역, 포인트) 초기화 쿼리 요청 함수, 테스트용
   * @param {number} userId
   * @return {Observable<FetchResult<any>>}
   */
  public resetRelatedPayment(userId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation resetRelatedPayment($userId: ID!) {
          resetRelatedPayment(userId: $userId)
        }
      `,
      {
        userId
      }
    );
  }

  /**
   * 카드사 리스트 요청하는 쿼리 함수
   * @return {Observable<ApolloQueryResult<Array<any>>>}
   */
  public getCardCompanyList(): Observable<ApolloQueryResult<Array<any>>> {
    return this.graphql
      .query(
        gql`
          query getCardCompanyList {
            getCardCompanyList {
              id
              isProblem
              companyName
            }
          }
        `,
        {}
      )
      .pipe(
        map((result) => {
          result = result.data.getCardCompanyList;
          return result;
        })
      );
  }

  /**
   * 문제있는 카드사 업데이트 해주는 쿼리 요청 함수
   * @param {number} cardCompanyId 문제있는 카드사 아이디
   * @param {boolean} isProblem 식별자
   * @return {Observable<FetchResult<any>>}
   */
  public updateStateCardCompanyProblem(cardCompanyId: number, isProblem: boolean): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateStateCardCompanyProblem($cardCompanyId: ID!, $isProblem: Boolean!) {
            updateStateCardCompanyProblem(cardCompanyId: $cardCompanyId, isProblem: $isProblem)
          }
        `,
        {
          cardCompanyId,
          isProblem
        }
      )
      .pipe(
        timeout(this.defaultTimeout),
        map((result) => {
          return result;
        })
      );
  }

  /**
   * 문제있는 카드사들만 내려주는 쿼리 요청 함수
   * @return {Observable<ApolloQueryResult<Array<any>>>}
   */
  public getCardCompanyListProblem(): Observable<ApolloQueryResult<Array<any>>> {
    return this.graphql
      .query(
        gql`
          query getCardCompanyListProblem {
            getCardCompanyListProblem {
              id
              isProblem
              companyName
            }
          }
        `,
        {}
      )
      .pipe(
        timeout(this.defaultTimeout),
        map((result) => {
          result = result.data.getCardCompanyListProblem;
          return result;
        })
      );
  }

  /**
   * 아임포트 api에 결제 요청하는 쿼리
   * @param {number} userId
   * @param {InputIamportUsingSubscription} inputIamportUsingSubscription
   * @return {Observable<FetchResult<any>>}
   */
  public requestPaymentToIamportUsingApi(userId: number, inputIamportUsingSubscription: any): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation requestPaymentToIamportUsingApi($userId: ID!, $inputIamportUsingSubscription: InputIamportUsingSubscription!) {
          requestPaymentToIamportUsingApi(userId: $userId, inputIamportUsingSubscription: $inputIamportUsingSubscription)
        }
      `,
      {
        userId,
        inputIamportUsingSubscription
      }
    );
  }

  /**
   * 모바일에서 결제하기 위해 필요 정보 요청하는 쿼리
   * @param {string} impUid 아임포트 주문번호, 이걸통해 정보를 가져옴
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getPaymentInfoFromIamport(impUid: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getPaymentInfoFromIamport($impUid: String!) {
            getPaymentInfoFromIamport(impUid: $impUid) {
              merchantUid
              pg
              isAlreadyPay
              customerUid
            }
          }
        `,
        {
          impUid
        }
      )
      .pipe(
        map((result) => {
          result = result.data.getPaymentInfoFromIamport;
          return result;
        })
      );
  }

  /**
   * 빌링키 등록 요청 함수
   * @param inputIamportUsingSubscription 아암포트 관련 변수
   * @param {boolean} isDefault 기본 결제 수단으로 등록할지 여부
   * @param {number} userId
   * @return {Observable<FetchResult<any>>}
   */
  public registerBillingKeyFromIamport(inputIamportUsingSubscription: any, isDefault: boolean, userId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation registerBillingKeyFromIamport($inputIamportUsingSubscription: InputIamportUsingSubscription!, $isDefault: Boolean!, $userId: ID!) {
          registerBillingKeyFromIamport(inputIamportUsingSubscription: $inputIamportUsingSubscription, isDefault: $isDefault, userId: $userId)
        }
      `,
      {
        inputIamportUsingSubscription,
        isDefault,
        userId
      }
    );
  }

  /**
   * 빌링키를 사용해서 결제 요청하는 함수
   * @param {number} userId
   * @param {string} paymentType 빌링키에 사용할 결제 타입, 카드, 카카오,네이버
   * @param {number} payEventId 결제 이벤트 아이디
   * @return {Observable<FetchResult<any>>}
   */
  public requestSubscriptionUsingBillingKey(userId: number, paymentType: string, payEventId: number = 1): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation requestSubscriptionUsingBillingKey($userId: ID!, $paymentType: String!, $payEventId: Int!) {
          requestSubscriptionUsingBillingKey(userId: $userId, paymentType: $paymentType, payEventId: $payEventId)
        }
      `,
      {
        userId,
        paymentType,
        payEventId
      }
    );
  }

  /**
   * 빌링키를 삭제 요청
   * @param billingKey 삭제할 빌링키
   * @return {Observable<FetchResult<any>>}
   */
  public cancelBillingKeyFromIamport(billingKey: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation cancelBillingKeyFromIamport($billingKey: String!) {
          cancelBillingKeyFromIamport(billingKey: $billingKey)
        }
      `,
      {
        billingKey
      }
    );
  }

  /**
   * 결제 v2 주문 정보를 가져오는 쿼리 요청 함수
   * @param {number} userId
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getIamportOrderList(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getIamportOrderList($userId: ID!) {
            getIamportOrderList(userId: $userId) {
              id
              merchantUid
              impUid
              createdDate
              type
              iamportPayment {
                id
                pg
                paymentType
              }
              point {
                id
                point
              }
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((result) => {
          result = result.data.getIamportOrderList;
          return result;
        })
      );
  }

  /**
   * 결제 v1 환불 요청 쿼리 함수
   * @param {number} userId
   * @param {number} paymentId 환불할 결제 정보 id
   * @return {Observable<FetchResult<any>>}
   */
  public refundPaypel(userId: number, paymentId: number): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation refundPaypel($userId: ID!, $paymentId: ID!) {
            refundPaypel(userId: $userId, paymentId: $paymentId)
          }
        `,
        {
          userId,
          paymentId
        }
      )
      .pipe(
        map((results) => {
          results = results.data.refundPaypel;
          return results;
        })
      );
  }

  /**
   * 결제 v2 환불 요청 쿼리 함수
   * @param {number} userId
   * @param {string} impUid 아임포트에 주문 정보로 취소핧때 사용
   * @param {string} reason 환물 사유
   * @return {Observable<FetchResult<any>>}
   */
  public refundIamport(userId: number, impUid: string, reason: string): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation refundIamport($userId: ID!, $impUid: String!, $reason: String!) {
            refundIamport(userId: $userId, impUid: $impUid, reason: $reason)
          }
        `,
        {
          userId,
          impUid,
          reason
        }
      )
      .pipe(
        map((results) => {
          results = results.data.refundIamport;
          return results;
        })
      );
  }

  /**
   * 모바일에서 redirect된 페이지에서 결제하기전에 이미 결제된 경우인지 체크하는 쿼리
   * @param {string} merchantId 확인하고 싶은 상품 id
   * @return {Promise<ApolloQueryResult<any>>}
   */
  public checkAlreadyChargePoint(merchantId: string): Promise<ApolloQueryResult<{ checkAlreadyChargePoint: boolean }>> {
    return this.graphql
      .query(
        gql`
          query checkAlreadyChargePoint($merchantId: String!) {
            checkAlreadyChargePoint(merchantId: $merchantId)
          }
        `,
        { merchantId }
      )
      .toPromise();
  }

  /**
   *  모바일에서 redirect한 페이지에서 결제 요청, 빌링키가 없어 상품 검색한 후 빌링키 가져옴, 최초 결제에만 사용하기때문에 빌링키 저장도 같이 함
   * @param {number} userId
   * @param {string} impUid 아임포트 고유 상품키
   * @param {string} merchantId 빌링키 발급에 사용한 상품 id
   * @param {number} price 포인트 가격
   * @param {Currency} currency
   * @return {Observable<FetchResult<Boolean>>}
   */
  public chargePointUsingImpUid(
    userId: number,
    impUid: string,
    merchantId: string,
    price: number,
    currency: Currency
  ): Observable<FetchResult<boolean>> {
    return this.graphql
      .mutate(
        gql`
          mutation chargePointUsingImpUid($userId: Int!, $impUid: String!, $merchantId: String!, $price: Int!, $currency: String!) {
            chargePointUsingImpUid(userId: $userId, impUid: $impUid, merchantId: $merchantId, price: $price, currency: $currency)
          }
        `,
        {
          userId,
          impUid,
          merchantId,
          price,
          currency
        }
      )
      .pipe(tap((results) => {}));
  }
}
