import { Component, Input } from '@angular/core';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-zoom-popover',
  templateUrl: './zoom-popover.component.html',
  styleUrls: ['./zoom-popover.component.scss']
})
export class ZoomPopoverComponent {
  @Input() imgSrc;
  @Input() pdfSrc;
  constructor(public app: AppService) {}

  mouseLeaveEvent() {
    this.app.popover.dismiss();
  }
}
