<ion-content appScrollbarTheme scrollY='false'>
  <!--  <div [style.padding-left.px]='app.isDesktopWidth()?40:15'-->
  <!--       [style.padding-right.px]='app.isDesktopWidth()?40:15'-->
  <!--       [style.padding-top.px]='app.isDesktopWidth()?40:15'-->
  <!--       class='d-flex flex-column justify-content-around'-->
  <!--       style='height: 100%;'-->
  <!--  >-->

  <!--    <div *ngIf='app.mainPopup[app.usedLanguage].fixLogo'>-->
  <!--      <img [src]='app.mainPopup[app.usedLanguage].fixLogo' class='position-absolute'-->
  <!--           [class]='app.isDesktopWidth()?"logo-position-desktop":"logo-position-mobile"'>-->
  <!--    </div>-->
  <div class='position-relative'>
    <img (click)='goPage()' [src]='app.mainPopup[app.usedLanguage].main_img' style='width: 100%;' />

  </div>
  <!--    <div [innerHTML]='app.mainPopup[app.usedLanguage].title | translate'-->
  <!--         [style.font-size.px]='app.isDesktopWidth()?56:20'-->
  <!--         style='font-weight: 600;margin-top:10%'>-->
  <!--    </div>-->
  <!--    <div class='position-relative'>-->
  <!--      <img [src]='app.mainPopup[app.usedLanguage].main_img' style='width: 100%' />-->
  <!--      <ion-button (click)='goPage()' style='bottom:0;width: 100%;height: 25%' theme='gradation'-->
  <!--                  class='d-flex justify-content-center align-items-center position-absolute'-->
  <!--                  matRipple>-->
  <!--        더 알아보기-->
  <!--      </ion-button>-->
  <!--    </div>-->


  <!--  </div>-->
</ion-content>
<ion-footer class='align-items-center' mode='ios'>
  <div class='d-flex justify-content-center'>

    <!--            setCookie('app.mainPopupClose', 'true', { 'max-age': 60 * 60 * 24 * 7 });-->
    <div (click)='close(true)' class='button_close' matRipple data-cy='landingPopUp'>{{ 'popup7day' | translate }}
    </div>
    <div class='border1px'></div>
    <div (click)='close(false)' class='button_close' matRipple
    > {{ 'close' | translate }}
    </div>

  </div>
</ion-footer>
