<ion-content style='--background: var(--custom-background-2)' *ngIf='readyToRender'>

  <ion-toolbar class='fold-all-toggle'>
    <ion-label class='font-style'>전체 개수: {{totalCount}} 건</ion-label>
    <ion-toggle slot='end' [(ngModel)]='isFoldAll' (ngModelChange)='changeIsFold(true)'></ion-toggle>
    <ion-label class='font-style' slot='end'>목록 접기</ion-label>
  </ion-toolbar>
  <div style='margin-bottom: 120px'>
    <div *ngFor='let appGroupByDate of eduApplicants'>
      <ion-label class='date-font'>{{appGroupByDate.date}} 문의 <b>{{appGroupByDate.applicants.length}} 건</b>
      </ion-label>
      <ion-icon
        *ngIf='!appGroupByDate.isFold'
        name='chevron-up-outline' class='icon-size'
        (click)='changeIsFold(false,true, appGroupByDate)'></ion-icon>
      <ion-icon
        *ngIf='appGroupByDate.isFold'
        name='chevron-down-outline'
        class='icon-size'
        (click)='changeIsFold(false,false, appGroupByDate)'></ion-icon>
      <div *ngFor='let applicant of appGroupByDate.applicants;let i = index'>
        <ion-card style='margin-top:16px' *ngIf='!appGroupByDate.isFold'>
          <ion-item>
            <ion-card-title>
              <ion-label class='font-style gray'>{{i + 1}}</ion-label>
              <ion-label class='font-style'>{{applicant.realName}}</ion-label>
              <ion-label class='font-style'>{{applicant.tooningEmail}}</ion-label>
              <ion-icon name='link-outline' class='ion-link-outline-icon'
                        (click)='copyEmail(applicant.tooningEmail)'></ion-icon>
            </ion-card-title>
            <ion-buttons (click)='currentApplicant=applicant' slot='end'>
              <ion-button class='pending-btn'
                          (click)='currentApplicant=applicant;applyStatus(EduApplicantStatusEnum.pending)'
                          [class.active-btn]='applicant.applyStatus === EduApplicantStatusEnum.pending'>신청
              </ion-button>
              <ion-button class='complete-btn'
                          (click)='currentApplicant=applicant;applyStatus(EduApplicantStatusEnum.complete)'
                          [class.active-btn]='applicant.applyStatus === EduApplicantStatusEnum.complete'>승인
              </ion-button>
              <ion-button class='rejected-btn'
                          (click)='currentApplicant=applicant' [matMenuTriggerFor]='rejectReason'
                          [class.active-btn]='applicant.applyStatus === EduApplicantStatusEnum.rejected'>반려
              </ion-button>
            </ion-buttons>

          </ion-item>

          <ion-card-content style='position: relative'>
            <ion-grid>
              <ion-row>
                <ion-col class='font-style gray'>소속</ion-col>
                <ion-col class='font-style'>{{applicant.agency}}</ion-col>
              </ion-row>
              <ion-row>
                <ion-col class='font-style gray'>직책</ion-col>
                <ion-col class='font-style'>{{applicant.others}}</ion-col>
              </ion-row>
              <ion-row>
                <ion-col class='font-style gray'>휴대폰</ion-col>
                <ion-col class='font-style d-flex' [class.isDuplicate]='applicant.isDuplicate'>
                  <div style='margin:auto 0;'>{{applicant.phoneNum}}</div>
                  <div *ngIf='applicant.isDuplicate' class='isDuplicate-box'>중복</div>
                </ion-col>
              </ion-row>
              <ion-row *ngIf='applicant.applyStatus === EduApplicantStatusEnum.rejected && applicant.rejectedReason'>
                <ion-col class='font-style gray'>반려 사유</ion-col>
                <ion-col
                  class='font-style'>{{applicant.rejectedReason === RejectReasonEnum.missSpelling ? '정보 오기입 및 문서 부적합' : '중복 신청'}}</ion-col>
              </ion-row>
            </ion-grid>
            <img *ngIf='applicant.authFile&& !applicant.fileName.includes("pdf")' class='thumbnail-img'
                 (mouseover)='zoomPopupOpen(applicant)'
                 [src]='applicant.filePath' />
            <pdf-viewer *ngIf='applicant.authFile&& applicant.fileName.includes("pdf")'
                        [src]='applicant.filePath' [original-size]='false' class='thumbnail-img'
                        (mouseover)='zoomPopupOpen(applicant)'></pdf-viewer>
            <a class='fileName'
               [href]='applicant.filePath' target='_blank'>{{applicant.fileName}}</a>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </div>
</ion-content>

<ion-card class='apply-fab'>
  <ion-item lines='none' class='font-style footer-item'>
    중복, 반려 제외 일괄 승인
    <ion-toggle class='font-style' [(ngModel)]='isAllChecked'
                (ngModelChange)='applyAllApplicants()'>
    </ion-toggle>
    <ion-button class='fill' size='default' slot='end' (click)='handleEduProRequests()'>적용</ion-button>
  </ion-item>
</ion-card>

<mat-menu #rejectReason='matMenu'>
  <div>
    <button (click)='applyStatus(EduApplicantStatusEnum.rejected, RejectReasonEnum.duplicated)' mat-menu-item>
      중복신청
    </button>
  </div>

  <div>
    <button (click)='applyStatus(EduApplicantStatusEnum.rejected, RejectReasonEnum.missSpelling)' mat-menu-item>
      정보 오기입 및 문서 부적합
    </button>
  </div>
</mat-menu>