import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-footer-company-info',
  templateUrl: './footer-company-info.component.html',
  styleUrls: ['./footer-company-info.component.scss']
})
export class FooterCompanyInfoComponent implements OnInit {
  constructor(public app: AppService, public modalCtrl: ModalController) {}

  ngOnInit() {}
}
