import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CanvasSharePageRoutingModule } from './canvas-share-routing.module';
import { CanvasSharePage } from './canvas-share.page';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ClipboardModule } from 'ngx-clipboard';
import { NgPipesModule } from 'ngx-pipes';
import { PopoverShareComponent } from './popover-share/popover-share.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { ScrollbarThemeModule } from '../../directive/scrollbar-theme';
import { MatLegacyRippleModule } from '@angular/material/legacy-core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CanvasSharePageRoutingModule,
    LazyLoadImageModule,

    ClipboardModule,
    NgPipesModule,
    TranslateModule,
    MatLegacySelectModule,
    ScrollbarThemeModule,
    MatLegacyRippleModule
  ],
  declarations: [CanvasSharePage, PopoverShareComponent],
  entryComponents: [PopoverShareComponent]
})
export class CanvasSharePageModule {}
