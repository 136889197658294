import { Injectable } from '@angular/core';
import { Capacitor3KakaoLogin } from 'capacitor3-kakao-login';
import { environment } from 'src/environments/environment';
import { LoadingController, Platform } from '@ionic/angular';
import { TooningClientError } from '../pages-tooning/errors/TooningErrors';
import { TranslateService } from '@ngx-translate/core';

const FILE_NAME = 'kakao-service.service.ts';

@Injectable({
  providedIn: 'root'
})
export class KakaoServiceService {
  constructor(public loadingController: LoadingController, private plt: Platform, private translate: TranslateService) {
    Capacitor3KakaoLogin.initializeKakao(environment.kakao);
  }

  /**
   * Performs a login operation using Kakao login.
   *
   * @async
   * @returns {Promise<any>} A Promise that resolves to the value of the logged-in user or null if not logged in.
   * @throws {TooningClientError} Throws an error if the login operation fails.
   */
  async login(): Promise<any> {
    let temp = { value: null };
    try {
      temp = await Capacitor3KakaoLogin.kakaoLogin();
      return temp.value;
    } catch (error) {
      if (error && error.error === 'invalid_request' && error.error_description === 'network error occurred' && this.plt.is('iphone')) {
        alert(this.translate.instant('kakao private relay error'));
      }
      throw new TooningClientError(FILE_NAME + JSON.stringify(error), null, true);
    }
  }

  async logout() {
    let temp = { value: null };
    try {
      temp = await Capacitor3KakaoLogin.kakaoLogout();
    } catch (error) {
      console.error(error);
      return null;
    }
    return temp.value;
  }

  async unlink() {
    let temp = { value: null };
    try {
      temp = await Capacitor3KakaoLogin.kakaoUnlink();
    } catch (error) {
      console.error(error);
    }
    return temp.value;
  }

  async share(title: string, description: string, image_url: string, image_link_url: string, button_title: string) {
    let temp = { value: null };
    try {
      temp = await Capacitor3KakaoLogin.sendLinkFeed({
        title,
        description,
        image_url,
        image_link_url,
        button_title
      });
    } catch (error) {}
    return temp.value;
  }
}
