<ion-header>
  <ion-title style='font-size: 10px'>
    문제 있는 카드사 활성(활성화시 결제 페이지에서 메시지가 보입니다)
  </ion-title>
</ion-header>
<ion-content *ngIf='readyToRender'>
  <ion-list id = 'cardCompanyList'>
    <ion-item *ngFor='let company of cardCompanyList'>
      <ion-label>{{company.companyName}}</ion-label>
      <ion-toggle slot='end' [checked]='company.isProblem' (ionChange)='updateCardCompanyStatus(company.id,$event.target.checked)'></ion-toggle>
    </ion-item>
  </ion-list>
</ion-content>