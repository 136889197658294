<div class='row justify-content-center w-100'>

  <!-- 냥이 충전 화면 -->
  <div *ngIf='order === 1' class='col-12 mb-3 text-center p-0'>
    <div *ngIf='isModal' class='d-flex justify-content-between align-items-center mypoints'>
      <ion-item>
        <ion-img slot='start' src='/assets/payment-system/tooning-coin.png'
                 style='width: 30px;'></ion-img>
        <ion-label>{{'available tooning coins'|translate}}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label>{{myPoints}}</ion-label>
      </ion-item>
    </div>
    <!-- 코인 반복문 시작 -->
    <div *ngFor='let item of pointData let index = index' class='list_style' data-cy='selectCoinList'>
      <div class='d-flex justify-content-between align-items-center'>
        <div class='d-flex'>
          <ion-img slot='start' src='/assets/payment-system/tooning-coin.png'
                   style='width: 25px;'></ion-img>
          <div class='d-flex list_style_0 flex-wrap'>
            <div>{{item.point + item.bonus}} {{"coin name" | translate}}</div>
            <div *ngIf='item.bonus > 0' class='ml-1 point'> {{item.point}} </div>
            <div *ngIf='item.bonus > 0' class='ml-1 bonus'> +{{item.bonus}} </div>
          </div>
        </div>
        <div *ngIf='!app.isWidthMin(500)' data-cy='priceCoin'>
          <button (click)='selectCoin(item.point, item.bonus, item.price, item.pointPriceId)'
                  class='custom-size-m'
                  [class]='chargingPoint === item.point ? "button-focused" : "theme-outline"'
                  fill='outline' mode='ios' slot='end'>
            {{item.price | currency: currency : 'symbol' : isDecimalPrice ? '1.2' : '1.0' }}
          </button>
        </div>
      </div>
      <div *ngIf='app.isWidthMin(500)'>
        <button (click)='selectCoin(item.point, item.bonus, item.price, item.pointPriceId)'
                class='custom-size-m w-100'
                [class]='chargingPoint === item.point ? "button-focused" : "theme-outline"'>
          {{item.price | currency: currency :'symbol' : isDecimalPrice ? '1.2' : '1.0' }}
        </button>
      </div>
    </div>


    <div *ngIf='order === 1 && isModal === true && !isChargedEnough' class='animation_fadeIn d-flex pt-4'>
      <img alt='alert.png' src='assets/payment-system/alert.png'>
      <div class='pl-1' style='color: #EB5757;'>{{'point charge more' | translate}} </div>
    </div>
    <!-- 다음 버튼 -->
    <ion-button (click)='next()' *ngIf='isModal === false' [disabled]='disableNextButton'
                class='w-100 p-0 m-0 mt-4 theme-main custom-size-m'
                mode='ios' data-cy='pointChargeNext'>{{'next' | translate}}</ion-button>

  </div>


  <!-- right master -->
  <div *ngIf='order === 2 && isModal === false' class='col-12 mb-3 text-left' id='app-point-charge'>
    <div *ngIf='isOverseasUser'>
      <div class='"row' style='margin-top: 16px'>
        <!--                    <wm-paypal [style.width.px]="width" [height]="height" [request]='Porder' fundingSource="paypal"></wm-paypal>-->
        <app-paypal-button (completed)='onCompleted($event)' [amount]='this.chargingPrice'
                           [bonus]='this.chargingBonusPoint'
                           [pointChargePricePlanId]='this.chargingPointPriceId'
                           [point]='this.chargingPoint'
                           [type]='paypalType'></app-paypal-button>
      </div>
    </div>
    <app-iamport #iamport *ngIf='!isOverseasUser' [card]='card'
                 (doPointCharge)='doPointCharge("app-point-charge-modal")'
                 class='mb-3 col-12' [isPoint]='true' [pointAmount]='chargingPrice'
                 [purchaseAfterCharging]='purchaseAfterCharging'></app-iamport>
    <div class='mt-4' style='width: 100%;'>
      <ul class='pt-4 pb-4 pr-3' style='color: #BDBDBD'>
        <li>{{'coin policy 1' | translate}} </li>
        <li>{{'coin policy 2' | translate}}</li>
        <li>{{'coin policy 3' | translate}}</li>
        <li>{{'coin policy 4' | translate}}</li>
      </ul>
    </div>
  </div>

  <div *ngIf='order === 2 && isModal === true' class='col-12 mb-3 text-left' id='app-point-charge-modal'>

    <div class='d-flex justify-content-between align-items-center p-2 mt-2 mb-2 font-weight-bold'
         style='height: 50px;font-size: 14px'>
      <div>{{ 'tooning coin 3' | translate}}</div>
      <div> {{this.chargingPoint + this.chargingBonusPoint | number}} {{'coin name' | translate}}</div>
    </div>
    <hr class='m-0 p-0' />
    <div class='d-flex justify-content-between align-items-center p-2 mt-2 mb-2 font-weight-bold'
         style='height: 50px;font-size: 14px'>
      <div>{{'payment details' | translate}}</div>
      <div> {{this.chargingPrice | number}} {{this.currency === currencyCodeDeclared['KRW'] ? ('won' | translate) : ''}}</div>
    </div>
    <hr class='m-0 p-0' />
    <div style='padding-top: 10px; padding-bottom: 20px;'>
      <div class='align-items-center p-2 mt-2 font-weight-bold'
           style='height: 20px;font-size: 14px'>{{'payment information' | translate}}</div>
      <div *ngIf='!isOverseasUser'>
        <app-iamport #iamport *ngIf='!isOverseasUser' [card]='card'
                     (doPointCharge)='doPointCharge("app-point-charge-modal")'
                     class='mb-3 col-12' [isPoint]='true' [pointAmount]='chargingPrice'
                     [purchaseAfterCharging]='purchaseAfterCharging'></app-iamport>
      </div>
    </div>
  </div>


  <!-- 셋팅 화면-->
  <div *ngIf='order === 3 && isModal === false' class='w-100' id='app-point-charge-cardRegister'>

    <div class='row d-block'>
      <div *ngIf='!app.isOverseasUser()'>
        <div class='charge-title'>{{"method-of-payment" | translate}}</div>
        <div class='w-100 payment-container'>
          <!-- 카드 등록 되어 있지 않은 경우, 결제수단 등록해주세요  -->
          <div *ngIf='(!card.id && !defaultPaymentInfo && otherPaymentInfo.length === 0) && !isUpdatingCard'>
            <div class='empty-card'>
              <div *ngIf='app.cache.user && !isOverseasUser' class='add-container'
                   (click)='isClicked=true; showCardUpdateView()' appDisabled [contentDisable]='isClicked'>
                <div class='plus-icon-wrapper'>
                  <ion-icon name='add-outline'></ion-icon>
                </div>
                <span class='add-payment-method-text'>{{'paymentWords.addPaymentMethod' | translate}}</span>
              </div>
            </div>
            <div class='guide-text'>
              {{'paymentWords.registerYourCard' | translate}}
            </div>
          </div>

          <!--  결제 수단 등록  -->
          <div *ngIf='isUpdatingCard' style='margin:0 16px'>
            <app-iamport class='w-100 h-100' [isPoint]='false' [isUpdatingCard]='isUpdatingCard' [card]='card'
                         [defaultPaymentInfo]='defaultPaymentInfo' [otherPaymentInfo]='otherPaymentInfo'
                         (showCardUpdateView)='showCardUpdateView(true)'></app-iamport>
          </div>

          <!--    paypel 카드 등록 되어 있는 경우-->
          <div *ngIf='card.id && !isUpdatingCard' class='papel-container'>
            <div class='card paple-card'>
              <div class='w-100 d-flex justify-content-between align-items-center'>
                <div>{{'Credit card' | translate}}</div>
                <ion-icon name='close-outline' (click)='isClicked=true; checkSubscriber()' appDisabled
                          [contentDisable]='isClicked'></ion-icon>
              </div>
            </div>
            <div class='payment-method-title'>신용/체크카드</div>
            <div class='basic-payment-method-text'>
              <ion-icon name='checkmark-outline'></ion-icon>
              기본 결제 수단
            </div>
          </div>

          <!--   결제 수단 등록되어 있는 경우     -->
          <div *ngIf='(defaultPaymentInfo || otherPaymentInfo.length !== 0) && !isUpdatingCard'
               class='method-view-container'>
            <div class='w-100 h-100 position-relative'>
              <div #slidePrevBtn [style.opacity]='viewCardIndex===0?0:1' (click)='onClickSlideButton($event)'
                   class='slide-btn position-absolute swiper-button-prev' style='left: -13px'>
                <ion-icon name='chevron-back-outline'></ion-icon>
              </div>
              <ion-slides #cardSlide [options]='slideOption'
                          (ionSlideTransitionEnd)='changeSlideActiveIndex()'>
                <ion-slide #defaultMethod *ngIf='defaultPaymentInfo'>
                  <div class='card'
                       [class]='defaultPaymentInfo.pg === paymentGateway.kakaopay?"kakao-card":defaultPaymentInfo.pg === paymentGateway.naverpay?"naverpay-card":"paple-card"'>
                    <div class='card-top-wrapper'>
                      <ng-container [ngSwitch]='defaultPaymentInfo.paymentType'>
                        <div
                          *ngSwitchCase='"card"'>{{ 'paymentType.' + defaultPaymentInfo.paymentType | translate}}</div>
                        <img *ngSwitchDefault [src]='"assets/payment-system/"+defaultPaymentInfo.paymentType+".png"' />
                      </ng-container>
                      <ion-icon name='close-outline'
                                (click)='isClicked=true; checkSubscriber(defaultPaymentInfo.pg, defaultPaymentInfo.paymentType)'
                                appDisabled [contentDisable]='isClicked'></ion-icon>
                    </div>
                  </div>
                </ion-slide>
                <ion-slide #otherMethod *ngFor='let payment of otherPaymentInfo'>
                  <div class='card'
                       [class]='payment.pg === paymentGateway.kakaopay?"kakao-card":payment.pg === paymentGateway.naverpay?"naverpay-card":"paple-card"'>
                    <div class='card-top-wrapper'>
                      <ng-container [ngSwitch]='payment.paymentType'>
                        <div *ngSwitchCase='"card"'>{{ 'paymentType.' + payment.paymentType | translate}}</div>
                        <img *ngSwitchDefault [src]='"assets/payment-system/"+payment.paymentType+".png"' />
                      </ng-container>
                      <ion-icon name='close-outline'
                                (click)='isClicked=true; checkSubscriber(payment.pg,payment.paymentType)' appDisabled
                                [contentDisable]='isClicked'></ion-icon>
                    </div>
                  </div>
                </ion-slide>
                <ion-slide #addCard>
                  <div class='empty-card'>
                    <div class='add-container' (click)='isClicked=true; showCardUpdateView()' appDisabled
                         [contentDisable]='isClicked'>
                      <div class='plus-icon-wrapper'>
                        <ion-icon name='add-outline'></ion-icon>
                      </div>
                      <span class='add-payment-method-text'>{{'paymentWords.addPaymentMethod' | translate}}</span>
                    </div>
                  </div>
                </ion-slide>
              </ion-slides>
              <div #slideNextBtn *ngIf='!isCardSlideEnd' (click)='onClickSlideButton($event)'
                   class='slide-btn swiper-button-next position-absolute' style='right: -13px'>
                <ion-icon name='chevron-forward-outline'></ion-icon>
              </div>
              <div *ngIf='!isCardSlideEnd' class='m-auto d-flex flex-column align-items-center'
                   style='width: fit-content'>
                <div *ngIf='slideActiveCard' class='payment-method-title'>
                  {{ 'paymentType.' + slideActiveCard.paymentType | translate}}
                </div>
                <div *ngIf='slideActiveCard.paymentType!=="card"'
                     class='warning-text'>{{'paymentWords.cantNotUsedPointCharge' | translate}}
                </div>
                <div class='basic-payment-method-text' (click)='changeDefaultPayment(slideActiveCard.paymentType)' matRipple
                     [class]='viewCardIndex!==0?"disabled-warning-text":""'
                     [style.margin-top.px]='slideActiveCard.paymentType==="card"?45:16'>
                  <ion-icon name='checkmark-outline'></ion-icon>
                  {{ 'paymentWords.defaultPayment' | translate }}
                </div>
              </div>
              <div *ngIf='isCardSlideEnd' class='guide-text'>{{'paymentWords.registerYourCard' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='charge-title'>{{ 'payment details' | translate }}</div>
      <button class='payment-history-button'
              (click)='moveToPaymentHistory()'>{{'paymentWords.checkPaymentHistory' | translate}}</button>
    </div>

  </div>

</div>
