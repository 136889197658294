import { Injectable } from '@angular/core';
import {
  TooningBadRequestError,
  TooningCustomLoginValidationError,
  TooningCustomUserEmailEmptyError,
  TooningCustomUserPasswordIsNotMatchedError,
  TooningErrorCode,
  TooningGetSocialUserInformationError,
  TooningGraphQLError,
  TooningJwtTokenExpiredError,
  TooningJwtTokenVerifyError,
  TooningKakaoLoginError,
  TooningLoginEmailNullError,
  TooningServerConnectionError,
  TooningTokenInterceptorErrorHandlerError,
  TooningUnauthorizedError,
  TooningUnkownNetworkError
} from '../../pages-tooning/errors/TooningErrors';
import HttpStatusCode from '../../pages-tooning/enums/httpErrors.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor(private app: AppService, private translate: TranslateService, private alertController: AlertController) {}

  /**
   * graphql error 만 처리한다.
   * @param error
   * @return {Promise<void>}
   */
  graphqlErrorHandler(graphQLErrors, error): void {
    if (graphQLErrors) {
      for (const gError of graphQLErrors) {
        const errorCode = +gError.extensions.exception.code;
        switch (errorCode) {
          // 페이스북이 이메일 없는 케이스 로그인이 존재하므로 이 에러는 현재 (2022.06.17) 서버에서 전달 하지 않음
          case TooningErrorCode.TOONING_SERVER_EMAIL_NULL_ERR:
            throw new TooningLoginEmailNullError(error, this.app);
          case TooningErrorCode.TOONING_SERVER_CUSTOM_USER_EMAIL_EMPTY_ERROR:
            throw new TooningCustomUserEmailEmptyError(error, this.app);
          case TooningErrorCode.TOONING_SERVER_CUSTOM_USER_PASSWORD_IS_NOT_MATCHED_ERROR:
            throw new TooningCustomUserPasswordIsNotMatchedError(error, this.app);
          case TooningErrorCode.TOONING_SERVER_ACTIVE_SESSION_UPDATE_ERROR:
            break;
          case TooningErrorCode.TOONING_SERVER_JWT_CREATE_WITH_SIGN_IN_ERROR:
            break;
          case TooningErrorCode.TOONING_SERVER_GET_SOCIAL_USER_INFORMATION_ERROR:
            throw new TooningGetSocialUserInformationError(error, this.app);
          case TooningErrorCode.TOONING_SIG_IN_ERROR:
            break;
          default:
            throw new TooningGraphQLError(error, this.app, true);
        }
      }
    }
  }

  /**
   * network error 만 처리한다.
   * @param error
   * @return {Promise<void>}
   */
  networkErrorHandler(networkError, error): void {
    if (networkError) {
      switch (networkError.status) {
        case HttpStatusCode.BAD_REQUEST:
          throw new TooningBadRequestError(error, this.app);
        case HttpStatusCode.UNAUTHORIZED:
          throw new TooningUnauthorizedError(error, this.app);
        case TooningErrorCode.TOONING_UNKNOWN_NETWORK_ERROR:
          throw new TooningUnkownNetworkError(error, this.app);
        default:
          throw new TooningServerConnectionError(error, this.app);
      }
    }
  }

  /**
   * alert 를 띄운다.
   * @param {string} message
   * @return {Promise<HTMLIonAlertElement>}
   */
  async presentAlert(message: string): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      header: this.translate.instant('notice'),
      cssClass: 'basic-dialog',
      message,
      buttons: ['OK']
    });

    await alert.present();
    return alert;
  }

  /**
   * 카카오 로그인 에러 처리
   * @param error
   * @return {Promise<void>}
   */
  async kakaoLoginErrorHandler(error): Promise<void> {
    await this.presentAlert(this.translate.instant('networkStatus.unknown'));
    throw new TooningKakaoLoginError(error, this.app, true);
  }

  /**
   * loginCustom 에러 처리
   * @param {any} error
   */
  loginErrorHandler(error: any): void {
    try {
      if (error instanceof TooningCustomLoginValidationError) {
        console.log('TooningCustomLoginValidationError');
        throw error;
      } else {
        const { graphQLErrors, networkError } = error;

        if (graphQLErrors) {
          this.graphqlErrorHandler(graphQLErrors, error);
        }

        if (networkError) {
          this.networkErrorHandler(networkError, error);
        }
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  /**
   * google login 이후 google 에서 전달하는 error code 가 있을 경우 에러 처리
   * @param {string} code
   * @return {Promise<void>}
   */
  async googleLoginErrorCodeHandler(code: string): Promise<void> {
    switch (code) {
      // signInWithPopup error codes
      case 'auth/cancelled-popup-request':
        break;
      case 'auth/popup-closed-by-user':
        await this.presentAlert(this.translate.instant('popupClosed'));
        break;
      case 'auth/popup-blocked':
        await this.presentAlert(this.translate.instant('popupBlocked'));
        break;
      case 'auth/account-exists-with-different-credential':
      case 'auth/auth-domain-config-required':
      case 'auth/operation-not-allowed':
      case 'auth/operation-not-supported-in-this-environment':
      case 'auth/unauthorized-domain':
      // setPersistence error codes
      case 'auth/invalid-persistence-type':
      case 'auth/unsupported-persistence-type':
      // other errors
      default:
    }
  }

  /**
   * token interceptor 후 헤더 변경한 후 서버에서 리턴되는 에러 메세지를 헨들링한다.
   * 네트워크 상태가 온라인일 경우 만 처리한다. 즉 status 가  0 인 경우는 처리하지 않는다.
   * @param {HttpErrorResponse} error
   * @param {string} userId
   * @param {string} token
   */
  static tokenInterceptorErrorHandler(error: HttpErrorResponse, userId: string, token: string): void {
    try {
      let code;
      try {
        code = error.error.errors[0].extensions.code;
      } catch (e) {
        throw new Error(`Tooning error code parsing error in tokenInterceptorErrorHandler :${e.message}`);
      }

      const messages = [];
      messages.push('TokenInterceptor');
      messages.push(`Message : ${error.message}`);
      messages.push(`StatusText : ${error.statusText}`);
      messages.push(`Status : ${error.status}`);
      messages.push(`UserId : ${userId}`);
      messages.push(`Token : ${token}`);

      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors')) {
        if (code === HttpStatusCode.JWT_VERIFY_ERROR) {
          messages.push(`JWT_VERIFY_ERROR`);
          window.localStorage.removeItem('token');
          throw new TooningJwtTokenVerifyError(messages.join('\n'), null, false);
        } else if (code === HttpStatusCode.JWT_EXPIRED_ERROR) {
          messages.push(`JWT_EXPIRED_ERROR`);
          window.localStorage.removeItem('token');
          throw new TooningJwtTokenExpiredError(messages.join('\n'), null, false);
        }
      }
    } catch (e) {
      if (e instanceof TooningJwtTokenVerifyError || e instanceof TooningJwtTokenExpiredError) {
        throw e;
      } else {
        throw new TooningTokenInterceptorErrorHandlerError(e, null, true);
      }
    }
  }
}
