import { Command } from './command';
import { CanvasBgColorChangeCommandData, OutsideBG } from '../../interfaces/app.interface';
import { Canvas } from '../../model/canvas/canvas';

class CanvasBgColorChangeCommand extends Command {
  public cut: any;
  public beforeOutsideBG: OutsideBG;
  public afterOutsideBG: OutsideBG;

  constructor(data: CanvasBgColorChangeCommandData) {
    super(data);
    this.cut = data.cut;
    this.commandName = 'Canvas-BgColor-Change-Command';
    this.beforeOutsideBG = data.beforeOutsideBG;
    this.afterOutsideBG = data.afterOutsideBG;
  }

  /**
   * canvas background color가 변경되었을 때 실행되는 command
   * @return {Promise<void>}
   */
  async execute() {
    super.execute();
  }

  /**
   * canvas background color의 undo coomand
   * @return {Promise<void>}
   */
  async undo() {
    try {
      super.undo();
      await this.updateOutsideBG(this.beforeOutsideBG);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * canvas background color의 undo coomand
   * @return {Promise<void>}
   */
  async redo() {
    try {
      super.redo();
      await this.updateOutsideBG(this.afterOutsideBG);
    } catch (e) {
      console.error(e);
    }
  }

  private async canvasUpdate(outsideBGColor) {
    try {
      const canvas = new Canvas();
      canvas.id = this.cut.newCanvasId;
      canvas.outsideBGColor = outsideBGColor;
      await this.cut.canvasService.canvasUpdate(canvas, true).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  async updateOutsideBG(outsideBG) {
    try {
      this.cut.outsideBG = outsideBG;
      this.cut.canvas.outsideBGColor = outsideBG.color;
      this.cut.canvas.requestRenderAll();

      await this.canvasUpdate(outsideBG.color);
    } catch (e) {
      console.error(e);
    }
  }
}

export { CanvasBgColorChangeCommand };
