import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { IonicStorageModule } from '@ionic/storage';
import { MomentModule } from 'ngx-moment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import { ColorSketchModule } from 'ngx-color/sketch';
import { FormsModule } from '@angular/forms';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { GraphQLModule } from './graphql.module';
import { TokenInterceptor } from './interceptors/token.interceptor.service';
import { ContextMenuModule } from '@arche-mc2/ngx-contextmenu';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxImageCompressService } from 'ngx-image-compress';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';

// Message (FCM) For web
import { MessageService } from './services/google/message/message.service';
import { AsyncPipe } from '@angular/common';
import { PaidGuideModalPageModule } from './pages-common/paid-guide-modal/paid-guide-modal.module';
import { PopoverMenuAacountComponent } from './pages-tooning/components/popover/popover-menu-aacount/popover-menu-aacount.component';
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Cut4ListPageModule } from './pages-4cut/cut4-list/cut4-list.module';
import { ScrollbarThemeModule } from './directive/scrollbar-theme';
import { LoginPageModule } from './pages-member/login/login.module';
import { MatLegacyRippleModule } from '@angular/material/legacy-core';
import { Pipes } from './pipes/pipes';
import { Loading } from './services/loading';
import { SpeedTestModule } from 'ng-speed-test';
import { LandingMainPopupComponent } from './pages-tooning/tooning-landing-main-v2/landing-main-popup/landing-main-popup.component';
import { NotificationComponent } from './components/notification/notification.component';
import { LoginPage } from './pages-member/login/login.page';
import { LandingModule } from './pages-tooning/components/landing.module';
import { DisabledDirectiveModule } from './directive/disabled/disabled.directive.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxPicaModule } from 'ngx-tooning-pica';

// https://github.com/ngx-translate/http-loader/issues/68
export class CachedHttpTranslationLoader implements TranslateLoader {
  cache$: Observable<object> = null;
  cachedLang: string = null;

  constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json', private loading: Loading) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<object> {
    if (!this.cache$ || this.cachedLang !== lang) {
      const time = new Date().getTime();
      this.cache$ = this.http.get(`${this.prefix}${lang}${this.suffix}?v=${time}`).pipe(shareReplay(1));
      this.cachedLang = lang;
    }
    return this.cache$;
  }
}

/**
 * zone 바깥 쪽에서 나는 에러를 캐치 할수 있다.
 * https://github.com/toonsquare/tooning-repo/issues/4599
 * webGl context 쪽에서는 에러 -> fabric -> angular zone 바깥 -> 아래 핸들러
 */
class TooningGlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const style = `
    color: orange;
    font-size: 14px;
    font-weight: bold;
    background-color: rgba(255, 0, 0, 0.1);
    padding: 5px;
    border-radius: 3px;
  `;
    // Log the message with the custom CSS style
    console.error('%c%s', style, `Tooning Global Error Handler : ${error.message}`);
    console.trace(error);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    MomentModule,
    MatLegacyDialogModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    ColorSketchModule,
    FormsModule,
    MatLegacySlideToggleModule,
    GraphQLModule,
    ContextMenuModule.forRoot(),
    NgxFileDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CachedHttpTranslationLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'ko' // default 값은 ko
    }),
    PaidGuideModalPageModule,
    SortablejsModule.forRoot({ animation: 150 }),
    Cut4ListPageModule,
    ScrollbarThemeModule,
    LoginPageModule,
    MatLegacyRippleModule,
    Pipes,
    SpeedTestModule,
    LandingModule,
    DisabledDirectiveModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    DisabledDirectiveModule,
    NgxPicaModule,
    ServiceWorkerModule.register('sw-master.js', {
      // enabled: environment.production,
      enabled: true,
      scope: './',
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: TooningGlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NgxImageCompressService,
    MessageService,
    AsyncPipe,
    Meta,
    NotificationComponent,
    LoginPage,
    DeviceDetectorService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
