import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { AlertController, AnimationController, IonModal, ModalController, Platform, PopoverController } from '@ionic/angular';
import { BasePage } from '../../base-page';
import { CanvasService } from '../../services/canvas.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Loading } from '../../services/loading';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Cut4MakeManualService } from '../cut4-make-manual2/cut4-make-manual.service';
import { Canvas } from '../../model/canvas/canvas';
import { CanvasSharePage } from '../canvas-share/canvas-share.page';
import { CanvasCheck, CanvasChecked, CanvasListWorkingType, CanvasSort, LanguageType, Order, TemplateType, UserRole } from '../../enum/app.enum';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PaidReferralComponent } from '../../components/paid-referral/paid-referral.component';
import { fromEvent, Subject } from 'rxjs';
import { CreateTemplateComponent } from '../../components/create-template/create-template.component';
import { TemplateService } from '../../services/template/template.service';
import { CreateTextTemplateComponent } from '../../components/create-text-template/create-text-template.component';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import {
  TooningCanvasClickError,
  TooningCanvasGroupAddAlertError,
  TooningCanvasGroupClickError,
  TooningCanvasGroupEnterError,
  TooningCanvasGroupNewMakeError,
  TooningCanvasGroupShareAlertError,
  TooningCanvasGroupSubmitAlertError,
  TooningCanvasListSetFailError,
  TooningChangeCanvasTitle,
  TooningCutListChangeOrderByError,
  TooningCutListIonViewDidEnterError,
  TooningDeleteCanvasesError,
  TooningDropMoveCanvasGroupError,
  TooningErrorCode,
  TooningMoveCanvasGroupAlertRadioError,
  TooningMoveCanvasGroupError,
  TooningPublishAllMemesError,
  TooningRemoveForeverError,
  TooningReplaceAllCharacterError,
  TooningReplaceAllTextError,
  TooningRestoreCanvasesFromRecycleBinError,
  TooningRestoreFromRecycleBinError,
  TooningScrollCanvasListFailError,
  TooningSearchCanvasFailError,
  TooningSetCanvasGroupListError,
  TooningSetPaginationConfigError,
  TooningSetUpdatedDateError
} from '../../pages-tooning/errors/TooningErrors';
import { NgxMasonryComponent } from 'ngx-masonry';
import { ClipboardService } from 'ngx-clipboard';
import { CanvasGroup } from '../../model/canvasGroup/canvasGroup';
import { PopoverSharingCanvasComponent } from '../../pages-tooning/components/popover/popover-sharing-canvas/popover-sharing-canvas.component';
import { PopoverSubmitCanvasComponent } from '../../pages-tooning/components/popover/popover-submit-canvas/popover-submit-canvas.component';
import { PopoverShareComponent } from '../canvas-share/popover-share/popover-share.component';
import { Cut4ListService } from './cut4-list.service';
import { MemeService } from '../../services/meme/meme.service';
import { OrderBy } from '../../model/orderBy/orderBy';
import { CharacterReplacerComponent } from '../cut4-make-manual2/modal/replacer/character/character-replacer.component';
import { TextReplacerComponent } from '../cut4-make-manual2/modal/replacer/text/text-replacer.component';
import * as moment from 'moment';
import { ConfigInstance } from '../../directive/paginationExtend/pagination-extend.directive';
import { CurrentUser } from '../../interfaces/app.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-cut4-list',
  templateUrl: './cut4-list.page.html',
  styleUrls: ['./cut4-list.page.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden',
          paddingTop: '0px',
          paddingBottom: '0'
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          overflow: 'hidden'
        })
      ),
      transition('collapsed <=> expanded', [animate('0.3s ease-out')])
    ]),
    trigger('arrowIconRotate', [
      state('up', style({ transform: 'rotate(0deg)' })),
      state('down', style({ transform: 'rotate(180deg)' })),
      transition('up <=> down', [animate('0.3s ease-out')])
    ])
  ]
})
export class Cut4ListPage extends BasePage implements OnInit {
  public readyToRender = false;
  public appVersion: string;
  public url: string;
  public target: string;
  public list = [];
  public id;
  public canvasIdList;
  public templateData;
  public title;
  public updatedDate;
  public loading;
  public listIndex;
  public authorization;
  public take: number = 20;
  public skip: number;
  public textTemplateData;
  public searchText = '';
  public isSearchView = false;
  public languageType = LanguageType;
  public languageTypeAll = LanguageType.all;
  public letters = ['#fff3f4', '#eaeaea', '#fef3da', '#f1e8e5', '#e7eaf1', '#f2f8f6', '#e6e6e6', '#ffe6e6', '#faf8e1', '#efd3e4', '#e3e4c0'];
  public isShowSelectBox = false;
  public paginationConfig: ConfigInstance = {
    id: 'advanced',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 1
  };
  @ViewChild('masonry') masonry: NgxMasonryComponent;
  @ViewChild('modalCanvasGroup') modalCanvasGroup: ModalController;
  @ViewChild('modalCanvasChangeTitle') modalCanvasChangeTitle: IonModal;

  public masonryOptions: any = {
    transitionDuration: '0.2s',
    gutter: 0,
    resize: true,
    initLayout: true,
    columnWidth: '.masonry-item',
    percentPosition: true,
    originTop: true
  };
  public searchSubject$ = new Subject();
  public isClickCanvas: boolean; // 캔버스 클릭 가능한지 체크 false가 활성화
  public isDrop: boolean;
  public isDropMoved: boolean;
  public recycleBinCount: number;
  public fromCut4List: boolean = true;
  private orientation: string;
  public isAddModal: boolean;
  public canvasChangeTitle: string;
  public UserRole = UserRole;
  public CanvasCheck = CanvasCheck;
  public isFromMoveCanvasGroup: boolean = false;
  public isCanvasEditMode: boolean = false;
  public isCanvasCheckedAll: boolean = false;
  public countCheckedCount: number = 0;
  public canvasOrderBy;
  public orderByList;
  public isSortMenu = false;
  public selectedOrderBy;
  public canvasCheckedAll = CanvasChecked.selectAll;
  public isCanvasListUpdateEnd = false;
  public user: CurrentUser;
  public isAccordionOpen: boolean = true;
  private keydownObservable$;

  constructor(
    public app: AppService,
    private animationCtrl: AnimationController,
    public dialog: MatLegacyDialog,
    public modalCtrl: ModalController,
    public canvasService: CanvasService,
    public alertController: AlertController,
    public domSanitizer: DomSanitizer,
    public platform: Platform,
    public translate: TranslateService,
    public cut: Cut4MakeManualService,
    public popoverController: PopoverController,
    public templateService: TemplateService,
    public cutList: Cut4ListService,
    private _clipboardService: ClipboardService,
    private memeService: MemeService
  ) {
    super(app);
    this.user = this.app.cache.user;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.cutList.canvasGroupDummyName = ['브랜든', '세라와 세바스찬', '김툰스', '반석이와 친구들', '김계란', '키미'];
    switch (this.user.role) {
      case UserRole.template:
        this.cutList.isTemplate = true;
        break;
      case UserRole.textTemplate:
        this.cutList.isTextTemplate = true;
        break;
      case UserRole.meme:
        this.cutList.isMeme = true;
        break;
    }

    this.cutList.isCanvasGroupView = false;
    this.cutList.isTrashView = false;
    this.appVersion = environment.appVersion;
    this.target = this.appVersion.split('-')[1];
    this.orderByList = [
      { sort: CanvasSort.createdDate, order: Order.desc, orderBy: 'cut4-list.recentCreated' },
      { sort: CanvasSort.createdDate, order: Order.asc, orderBy: 'cut4-list.oldCreated' },
      { sort: CanvasSort.updatedDate, order: Order.desc, orderBy: 'cut4-list.recentUpdated' },
      { sort: CanvasSort.title, order: Order.asc, orderBy: 'cut4-list.titleAsc' },
      { sort: CanvasSort.title, order: Order.desc, orderBy: 'cut4-list.titleDesc' }
    ];
    this.selectedOrderBy = 'cut4-list.recentCreated';
    this.canvasOrderBy = new OrderBy();
    this.canvasOrderBy.order = Order.desc;
    this.canvasOrderBy.sort = CanvasSort.createdDate;
    if (this.target === 'oper') {
      this.target = 'www';
    } else {
      this.target = this.target + '-app';
    }
  }

  reloadMasonryLayout(isForce = false) {
    if (isForce) {
      this.masonry.reloadItems();
      this.masonry.layout();
      return;
    }
    if (this.masonry !== undefined && !this.isDropMoved) {
      this.masonry.reloadItems();
      this.masonry.layout();
    }
  }

  async ionViewWillEnter() {
    try {
      if (this.user.role === UserRole.demo) {
        await this.app.showToast(this.translate.instant('demo-page.text_8'));
        this.app.goReplace('/');
        return;
      }
      this.loading = new Loading();
      this.isCanvasEditMode = false;
      this.getTake();
      await this.initPaginationConfig();
      // canvas search
      this.subscriptions.push(
        this.searchSubject$
          .pipe(
            debounceTime(300),
            switchMap((props: { skip: number; searchText: string }) =>
              this.canvasService.searchCanvas(
                this.user.id,
                props.skip,
                this.take,
                this.languageTypeAll,
                this.user.role,
                this.canvasOrderBy.sort,
                this.canvasOrderBy.order,
                props.searchText,
                this.cutList.canvasGroupId
              )
            )
          )
          .subscribe(
            async ({ data }) => {
              this.setCanvasList(data);
              this.checkSelectBoxStatus();
              this.isCanvasListUpdateEnd = true;
              await this.setPaginationConfig();
              this.loading?.hideLoader();
            },
            (err) => {
              this.readyToRender = true;
              this.myWorkErrorHandler(err);
            }
          )
      );
      if (!this.cutList.isCanvasGroupView) {
        await this.setCanvasGroupList();
        await this.reLoadWithoutSetCanvasGroupList();
      }

      this.keydownObservable$ = fromEvent(window, 'keydown');
      this.subscriptions.push(
        this.keydownObservable$.subscribe(async (event) => {
          if (this.app.isLoading()) {
            return;
          }
          await this.keyDownHandler(event);
        })
      );
    } catch (e) {
      if (e instanceof TooningSetCanvasGroupListError || e instanceof TooningSetPaginationConfigError) {
        throw e;
      } else {
        throw new TooningCutListIonViewDidEnterError(e);
      }
    }
  }

  /**
   * 나의 작업 작업물 리스트와 폴더 새로 고침
   * @return {Promise<void>}
   */
  async reLoad(): Promise<void> {
    try {
      this.isCanvasListUpdateEnd = false;
      this.app.isIonBackdrop = true; // 로딩되는 동안 딤처리 시작
      this.readyToRender = false;
      this.isCanvasCheckedAll = false;
      this.canvasCheckedAll = CanvasChecked.selectAll;
      this.cutList.isCanvasGroupView = false;
      this.cutList.isTrashView = false;
      this.cutList.canvasGroupId = null;
      this.searchText = '';
      if (this.isCanvasEditMode) this.closeExtendFab();
      this.getTake();
      await this.setCanvasGroupList();
      await this.initPaginationConfig();
      this.getCanvasList('', 1);
    } catch (e) {
      throw e;
    } finally {
      await this.setPaginationConfig();
      this.app.isIonBackdrop = false; //딤처리 끝
    }
  }

  async reLoadWithoutSetCanvasGroupList() {
    this.readyToRender = false;
    this.getTake();
    this.cutList.isCanvasGroupView = false;
    this.getCanvasList('', 1);
  }

  async ionViewDidEnter() {
    super.ionViewDidEnter();
    this.readyToRender = false;
    this.isDrop = false;
    this.isDropMoved = false;
    this.isClickCanvas = false;
    this.cutList.isTrashView = false;
    this.getTake();
    await this.fetchCanvas();
    this.checkSelectBoxStatus();
    this.readyToRender = true;
  }

  /**
   * 캔버스들 가져오는 함수, subject 트리거
   * @return {Promise<void>}
   */
  async fetchCanvas() {
    try {
      await this.loading.showLoader('');
      this.getCanvasList('', 1);
    } catch (e) {
      // 비정상적으로 캔버스가 종료된 경우 오류 발생 가능성 대비 - 리스트 리로드
      console.error(e);
      this.readyToRender = false;
    }
  }

  /**
   * 최근에 수정된 캔버스 날짜를 브라우저 사용자의 언어 설정에 맞게 형식화한다
   * @param canvas - 선택한 캔버스
   * @returns {Promise<void>}
   */
  async setUpdatedDate(canvas: any): Promise<void> {
    try {
      const dateFormat = {
        [LanguageType.en]: '[Edited on] MM.DD.YYYY',
        [LanguageType.jp]: 'YYYY[年]M[月]DD[日編集]',
        [LanguageType.fr]: 'DD[/]MM[/]YY',
        [LanguageType.ko]: 'YYYY[년] M[월] DD[일 편집]'
      };

      const format = dateFormat[this.app.usedLanguage];
      this.updatedDate = moment(canvas.updatedDate).format(`${format}`);
    } catch (e) {
      throw new TooningSetUpdatedDateError(e, this.app, true);
    }
  }

  /**
   * 캔버스 데이터 리스트를 가져온다
   * @param canvasData 검색 데이터
   */
  setCanvasList(canvasData) {
    try {
      this.app.canvasList = [];
      canvasData.forEach((canvas) => {
        canvas.isChecked = false;
      });
      this.app.canvasList.push(...canvasData);
      // 보여지는 화면 조금 더 자연스럽게 처리하기 위함
      setTimeout(() => {
        this.readyToRender = true;
        // 전체 리스트가 아닐 경우 - 검색어가 들어가면...
        if (this.searchText !== '') {
          this.isSearchView = true;
        } else {
          this.isSearchView = false;
        }
      }, 0);
    } catch (e) {
      throw new TooningCanvasListSetFailError(e, null, true);
    }
  }

  /**
   * 페이지 네이션, 페이지 이동시 발생하는 이벤트
   * @param {number} pageNum 이동하고 싶은 페이지
   * @return {Promise<void>}
   */
  async pageChangeEvent(pageNum: number): Promise<void> {
    try {
      await this.app.delay(250);
      await this.loading.showLoader();
      this.getCanvasList(this.searchText, pageNum);
    } catch (e) {
      throw new TooningScrollCanvasListFailError(e, null, true);
    }
  }

  /**
   * 작업물 검색 서버에 요청하는 함수
   * @param $event 검색 이벤트 text 값이 들어있음
   * @return {Promise<void>}
   */
  async searchCanvas($event): Promise<void> {
    try {
      await this.loading.showLoader();
      const text = $event.target.value;
      this.readyToRender = false;
      this.getCanvasList(text, 1);
    } catch (e) {
      throw new TooningSearchCanvasFailError(e, null, true);
    }
  }

  /**
   * 한번에 가져올 작업물 개수 계산하는 함수, 계산식은 이제 사용하지 않지만(ux팀과 협의완료) 히스토리 체크를 위해 남겨둠
   */
  getTake(): void {
    this.take = 20;
  }

  // ========== canvas list set end ==========
  /**
   * click을 통해 제거 버튼을 눌렀을 때 서버에서 제거해주는 함수
   * @param type
   * @return {Promise<void>}
   */
  async clickRemove(type: string = CanvasListWorkingType.work): Promise<void> {
    const loading = new Loading();
    const alert = await this.alertController.create({
      header: this.translate.instant(`DELETE_CONFIRM.${type}`),
      message: this.translate.instant(`DELETE_MESSAGE.${type}`),
      cssClass: 'basic-dialog',
      buttons: [
        {
          text: this.translate.instant('CANCEL'),
          role: 'cancel'
        },
        {
          text: this.translate.instant(`DELETE.${type}`),
          handler: async () => {
            await loading.showLoader('');
            if (type === CanvasListWorkingType.work) {
              this.canvasService
                .canvasDelete(+this.id)
                .pipe(take(1))
                .subscribe(
                  // tslint:disable-next-line:no-shadowed-variable
                  ({ data, errors }) => {
                    this.app.canvasList.splice(this.listIndex, 1);
                  }
                );
              this.recycleBinCount++;
            } else if (type === CanvasListWorkingType.canvasGroup) {
              this.cutList.canvasGroupAlertInput.splice(this.cutList.canvasGroupIndex, 1);
              this.recycleBinCount++;
              await this.cutList.canvasGroupService.canvasGroupDelete(this.user.id, +this.cutList.canvasGroupId);
              this.cutList.canvasGroupList.splice(this.cutList.canvasGroupIndex, 1);
            } else if (type === CanvasListWorkingType.trash) {
              await this.cutList.canvasGroupService.emptyRecycleBin(this.user.id);
              if (this.cutList.isTrashView) {
                this.app.canvasList = [];
                this.cutList.canvasGroupList = [];
              }
              this.recycleBinCount = 0;
            }
            loading.hideLoader();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * deleteStatu를 0으로 바꿔 삭제해주는 함수
   * @param type
   * @return {Promise<void>}
   */
  async clickRemoveForever(type: string = CanvasListWorkingType.work): Promise<void> {
    try {
      const loading = new Loading();
      const alert = await this.alertController.create({
        header: this.translate.instant('DELETE_CONFIRM.trash'),
        message: this.translate.instant('DELETE_MESSAGE.trash'),
        cssClass: 'basic-dialog',
        buttons: [
          {
            text: this.translate.instant('CANCEL'),
            role: 'cancel'
          },
          {
            text: this.translate.instant('DELETE.norm'),
            handler: async () => {
              await loading.showLoader('');
              if (type === CanvasListWorkingType.work) {
                await this.cutList.canvasGroupService.deleteForever(type, +this.id);
                this.app.canvasList.splice(this.listIndex, 1);
              } else if (type === CanvasListWorkingType.canvasGroup) {
                await this.cutList.canvasGroupService.deleteForever(type, +this.cutList.canvasGroupId);
                this.cutList.canvasGroupList.splice(this.cutList.canvasGroupIndex, 1);
              }
              loading.hideLoader();
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      throw new TooningRemoveForeverError(e.message);
    }
  }

  async openModalView() {
    if (this.app.modal !== null) {
      this.app.modal = null;
    }
    this.app.modal = true;
    this.app.modal = await this.modalCtrl.create({
      component: CanvasSharePage,
      componentProps: {
        id: this.id
      },
      cssClass: 'modal-size-1'
    });
    this.app.modal.style.cssText = 'z-index:auto';
    this.app.modal.onDidDismiss().then(async (data) => {
      this.app.modal = null;
    });
    return await this.app.modal.present();
  }

  /**
   * 캔버스 제목 변경 모달 열기
   * @return {Promise<void>}
   */
  async openModalChangeCanvasTitle(): Promise<void> {
    // @ts-ignore
    await this.modalCanvasChangeTitle.present();
  }

  /**
   * 캔버스 제목 변경 모달 전 실행
   * @return {Promise<void>}
   * */
  async willPresentChangeCanvasTitle(): Promise<void> {
    this.app.modal = this.modalCanvasGroup;
    this.cut.setKeyActivation = false;

    this.canvasChangeTitle = this.app.canvasList[this.listIndex].title;
  }

  /**
   * 캔버스 제목 변경
   * @return {Promise<void>}
   */
  async titleChange(): Promise<void> {
    try {
      this.app.canvasList[this.listIndex].title = this.canvasChangeTitle; // UI반영
      const canvas = new Canvas();
      canvas.id = this.id;
      canvas.title = this.canvasChangeTitle;
      canvas.outsideBGColor = this.cut.outsideBG.color;
      await this.canvasService.canvasUpdate(canvas, true).toPromise();
    } catch (e) {
      throw new TooningChangeCanvasTitle(e);
    } finally {
      await this.modalCanvasChangeTitle.dismiss();
    }
  }

  /**
   * 폴더 모달 열기
   * @param isAddModal {boolean} 폴더 생성 모달이면 true, 폴더 이름 변경 모달이면 false
   * @return {Promise<void>}
   */
  async openModalCanvasGroup(isAddModal: boolean): Promise<void> {
    this.isAddModal = isAddModal;

    // @ts-ignore
    await this.modalCanvasGroup.present();
  }

  /**
   * 폴더 모달 열기 전 실행
   * @return {Promise<void>}
   * */
  async willPresentAddCanvasGroup(): Promise<void> {
    try {
      this.app.modal = this.modalCanvasGroup;
      this.cut.setKeyActivation = false;

      if (this.isAddModal) {
        this.cutList.addCanvasGroupName = this.translate.instant('cut4-list.New folder');
        let newFolderCount = 0;
        for (const folder of this.cutList.canvasGroupList) {
          if (folder.groupName.startsWith(this.translate.instant('cut4-list.New folder'), 0)) {
            newFolderCount += 1;
            this.cutList.addCanvasGroupName = this.translate.instant('cut4-list.New folder') + '(' + String(newFolderCount + 1) + ')';
          }
        }
      }
    } catch (e) {
      throw e;
    }
  }

  /**
   * 폴더 내의 모든 밈을 발행
   * @return {Promise<void>}
   */
  async publishAllMemes(): Promise<void> {
    try {
      await this.loading.showLoader('밈 발행하는 중.. 잠시만 기다려주세요.');
      const { data } = await this.memeService.publishAllMemes(+this.cutList.canvasGroupId);
      if (data) {
        await this.app.showToast('밈이 정상적으로 발행되었습니다.', 2000, 'dark', 'middle');
      }
    } catch (e) {
      await this.app.showToast('밈 발행을 실패하였습니다. 관리자 문의 바람');
      console.error(e);
      throw new TooningPublishAllMemesError(e, this.app, true);
    } finally {
      await this.loading.hideLoader();
    }
  }

  /**
   * 폴더 생성하는 메소드
   * @return {Promise<void>}
   * */
  async addCanvasGroup(): Promise<void> {
    try {
      const newCanvasGroup = await this.cutList.canvasGroupService.canvasGroupNewMake(this.cutList.addCanvasGroupName, this.cutList.isTemplate);
      newCanvasGroup.canvasCount = 0;
      const alertInput = {
        id: +newCanvasGroup.id,
        type: 'radio',
        label: newCanvasGroup.groupName,
        value: { id: +newCanvasGroup.id, index: 0 },
        checked: false
      };
      this.cutList.canvasGroupList.unshift(newCanvasGroup);
      this.cutList.canvasGroupAlertInput.map((input) => (input.value.index += 1));
      this.cutList.canvasGroupAlertInput.unshift(alertInput);

      await this.modalCanvasGroup.dismiss();
      if (this.isFromMoveCanvasGroup) {
        await this.moveCanvasGroupAlertRadio();
        this.isFromMoveCanvasGroup = false;
      }
    } catch (e) {
      this.isFromMoveCanvasGroup = false;
      if (e instanceof TooningCanvasGroupNewMakeError) {
        await this.app.showToast(this.translate.instant('cut4-list.createFolderErr'));
        throw e;
      } else {
        throw new TooningCanvasGroupAddAlertError(e, null, true);
      }
    }
  }

  /**
   * 휴지통에 있는 객체들 복구하는 함수
   * @param type 캔버스 or 그룹(폴더)
   * @return {Promise<void>}
   */
  async restoreFromRecycleBin(type: string = CanvasListWorkingType.work): Promise<void> {
    try {
      const loading = new Loading();
      await loading.showLoader('');
      if (type === CanvasListWorkingType.canvasGroup) {
        await this.cutList.canvasGroupService.restoreFromRecycleBin(type, +this.cutList.canvasGroupId, this.user.id);
        this.cutList.canvasGroupList.splice(this.cutList.canvasGroupIndex, 1); //UI적용
      } else if (type === CanvasListWorkingType.work) {
        await this.cutList.canvasGroupService.restoreFromRecycleBin(type, +this.id, this.user.id);
        this.app.canvasList.splice(this.listIndex, 1); //UI적
      }

      loading.hideLoader();
    } catch (e) {
      throw new TooningRestoreFromRecycleBinError(e, null, true);
    }
  }

  /**
   * 작업물 클릭했을 휴지통일 경우 복구여부 물어보고 아닌 경우 진입
   * @param isTrashView 휴지통인지 확인하는 식별자
   * @param item 작업물
   * @param index 작업물 인덱스
   */
  async canvasClick(isTrashView: boolean, item: { id: string; title: string; authorization: string }, index) {
    try {
      if (this.isCanvasEditMode) {
        this.app.canvasList[this.listIndex].isChecked ? this.countCheckedCount-- : this.countCheckedCount++;
        this.app.canvasList[this.listIndex].isChecked = !this.app.canvasList[this.listIndex].isChecked;
        return;
      }
      if (isTrashView) {
        const alert = await this.alertController.create({
          cssClass: 'basic-dialog',
          header: this.translate.instant('cut4-list.canvasInRecycleBin'),
          message: this.translate.instant('cut4-list.messageOfRestoreCanvas'),
          buttons: [
            {
              text: this.translate.instant('cancel'),
              role: 'cancel',
              handler: () => {
                console.log('Confirm Cancel');
              }
            },
            {
              text: this.translate.instant('cut4-list.restore'),
              handler: async (data) => {
                await this.restoreFromRecycleBin(CanvasListWorkingType.work);
                console.log('Confirm Ok');
              }
            }
          ]
        });

        await alert.present();
      } else {
        await this.goCanvas(item, index);
      }
    } catch (e) {
      throw new TooningCanvasClickError(e, null, true);
    }
  }

  /**
   * 클릭한 캔버스 삭제 됐는지 체크하고 이동
   * @param item 캔버스 정보
   * @param index 인덱스 번호
   */
  async goCanvas(item: { id: string; title: string; authorization: string }, index) {
    const isDelete = await this.cut.isDeleteCheck(+item.id);
    if (isDelete) {
      await this.deleteAlertConfirm();
    } else {
      if (this.isClickCanvas) {
        return;
      }
      this.isClickCanvas = true;
      await this.app.delay(250); // 우선 UI 뛰우기 버벅이는 이슈 때문에... 여러번 클릭 방지
      try {
        await this.cut.canvasNewMake(item.id, item.title);
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * 이미 삭제된 캔버스일 때 경고창
   */
  async deleteAlertConfirm() {
    this.cut.popAlert = await this.alertController.create({
      header: this.translate.instant('SAVE_CANVAS_ERROR_MESSAGE'),
      cssClass: 'basic-dialog',
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('header'),
          handler: async () => {
            await this.reLoad();
          }
        }
      ]
    });
    this.cut.popAlert.onDidDismiss().then(async (data) => {
      this.cut.popAlert = null;
    });
    await this.cut.popAlert.present();
  }

  ionViewDidLeave() {
    try {
      super.ionViewDidLeave();
      this.searchText = '';
      this.readyToRender = false;
      this.app.canvasList = [];
    } catch (e) {
      this.app.orange(e.message);
    }
  }

  /**
   * 캔버스 복제 함수
   * @return {Promise<void>}
   */
  async canvasClone(): Promise<void> {
    try {
      await this.cut.canvasClone(this.id);
      this.readyToRender = false;
      this.getCanvasList('', 1);
    } catch (error) {
      console.log('canvasClone');
      console.log(error);
    }
  }

  async createTemplate(templateType: string) {
    if (this.app.modal !== null) {
      return;
    }
    this.app.modal = true;
    this.app.modal = await this.modalCtrl.create({
      component: templateType === TemplateType.basic ? CreateTemplateComponent : CreateTextTemplateComponent,
      componentProps: {
        canvasId: this.id
      },
      cssClass: 'modal-size-2'
    });
    this.app.modal.onDidDismiss().then(async (canvasData) => {
      const findCanvasIndex = this.app.canvasList.findIndex((canvas) => canvas.id === canvasData.data.id);
      if (findCanvasIndex <= -1 || !canvasData?.data) {
        return;
      }

      if (canvasData.data.isDelete) {
        templateType === TemplateType.text ? this.app.canvasList.splice(findCanvasIndex, 1) : (this.app.canvasList[findCanvasIndex].template = null);
      } else if (this.cutList.isTextTemplate) {
        this.app.canvasList[findCanvasIndex].textTemplate.status = canvasData.data.status;
        this.app.canvasList[findCanvasIndex].title = canvasData.data.title;
      }
      this.app.modal = null;
    });
    return await this.app.modal.present();
  }

  /**
   * 템플릿 설정할 수 있는 권한이 있는 사용자인가?
   * @return {boolean}
   */
  canCreateTemplate() {
    return this.cutList.isTemplate || this.cutList.isMeme;
  }

  async shareSettingsAlertRadio() {
    let checkedClone = false;
    let checkedRead = false;
    if (this.authorization === 'clone') {
      checkedClone = true;
    }
    if (this.authorization === 'read') {
      checkedRead = true;
    }

    const alert = await this.alertController.create({
      header: this.translate.instant('shareTitle'),
      cssClass: 'light',
      inputs: [
        {
          cssClass: 'light',
          type: 'radio',
          label: this.translate.instant('read'),
          value: 'read',
          checked: checkedRead
        },
        {
          cssClass: 'light',
          type: 'radio',
          label: this.translate.instant('clone'),
          value: 'clone',
          checked: checkedClone
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Confirm Cancel');
          }
        },
        {
          text: 'Ok',
          handler: async (data) => {
            this.app.canvasList[this.listIndex].authorization = data; // UI반영
            const canvas = new Canvas();
            canvas.id = this.id;
            canvas.authorization = data;
            canvas.outsideBGColor = this.cut.outsideBG.color;
            await this.canvasService.canvasUpdate(canvas).toPromise();
            await this.app.showToast('[' + this.translate.instant(data) + '] ' + this.translate.instant('saveChange'));
          }
        }
      ]
    });

    await alert.present();
  }

  async allView(title, id, authorization, listIndex) {
    this.title = title;
    this.id = id;
    this.authorization = authorization;
    this.listIndex = listIndex;
    await this.openModalView();
  }

  /**
   * 템플릿 계정 (role 이 template/textTemplate/meme) 에서 템플릿 발행하는 함수
   * @param {CustomEvent} event - 발행할지 여부 토글
   * @param {boolean} isMeme - 밈 템플릿인지 여부
   * @returns {Promise<void>}
   */
  async templatePublish(event: CustomEvent, isMeme?: boolean): Promise<void> {
    try {
      event.stopPropagation();
      if (this.cutList.isTextTemplate) {
        this.textTemplateData.status = event.detail.checked;
        await this.templateService.updateTextTemplateStatus(this.textTemplateData.id, event.detail.checked).toPromise();
      } else if (isMeme) {
        this.templateData.meme.status = event.detail.checked;
        await this.templateService.updateTemplateStatus(this.templateData.id, event.detail.checked, true);
        if (event.detail.checked) {
          await this.app.showToast(`삼성 발행 설정`, 20, 'tertiary');
        } else {
          await this.app.showToast(`삼성 발행 해제`, 20);
        }
      } else {
        this.templateData.status = event.detail.checked;
        await this.templateService.updateTemplateStatus(this.templateData.id, event.detail.checked, false);
        if (event.detail.checked) {
          await this.app.showToast(`투닝 발행 설정`, 20, 'primary');
        } else {
          await this.app.showToast(`투닝 발행 해제`, 20);
        }
      }
    } catch (e) {
      await this.app.showToast(`발행 설정 실패 ${e}`);
    }
  }

  itemsLoadedComplete() {
    if (this.loading) {
      this.loading.hideLoader();
    }
  }

  async myWorkErrorHandler(error) {
    if (error instanceof TooningCanvasListSetFailError) {
      await this.app.showToast(this.translate.instant('fail to set canvas list'));
    } else if (error instanceof TooningScrollCanvasListFailError) {
      await this.app.showToast(this.translate.instant('fail to scroll Event'));
    } else if (error instanceof TooningSearchCanvasFailError) {
      await this.app.showToast(this.translate.instant('fail to search'));
    } else {
      const { graphQLErrors, networkError } = error;
      if (graphQLErrors) {
        for (const gError of graphQLErrors) {
          const errorCode = +gError.extensions.exception.code;
          if (errorCode === TooningErrorCode.TOONING_SERVER_SEARCH_CANVAS_ERR) {
            await this.app.showToast(this.translate.instant('search err'));
          } else {
            await this.app.showToast(this.translate.instant('canvas search default error'));
          }
        }
      }
      await this.app.checkNetworkError(error, networkError);
    }
  }

  /**
   * 언어 변경시 나의 작업물 새로 고침
   * @return {void}
   */
  changeLanguageType(): void {
    try {
      this.loading.showLoader();
      this.getCanvasList(this.searchText, 1);
      this.checkSelectBoxStatus();
    } catch (e) {
      throw new Error(`changeLanguageType : ${e}`);
    }
  }

  checkSelectBoxStatus() {
    try {
      this.isShowSelectBox = this.app.canvasList.length !== 0 && (this.user.role === UserRole.template || this.user.role === UserRole.textTemplate);
    } catch (e) {
      throw new Error(`checkSelectBoxStatus : ${e}`);
    }
  }

  /**
   * 작업물의 링크를 공유할 때 공유 옵션을 설정할 수 있는 modal을 띄워주는 함수
   * @return Promise<void>
   */
  async copied(): Promise<void> {
    this.app.modal = await this.modalCtrl.create({
      mode: 'md',
      component: PopoverShareComponent,
      componentProps: {
        canvasId: this.id,
        isModal: true
      }
    });
    this.app.modal.style.cssText = '--min-width: 100px; --max-width: 335px; --width : 80%; --max-height: 280px; --border-radius: 8px;';
    this.app.modal.onDidDismiss().then(async (data) => {
      const loading = new Loading();
      await loading.showLoader('');
      const getCanvas = await this.canvasService.getCanvas(this.id, true);
      this.orientation = getCanvas.data.orientation;
      this.authorization = getCanvas.data.authorization;
      loading.hideLoader();
    });
    return await this.app.modal.present();
  }

  /**
   * 캐릭터 일괄 변경
   * @returns {Promise<any>}
   */
  async replaceAllCharacter(): Promise<any> {
    const targetIds = this.getCanvasEditTarget();
    if (await this.targetIdsLengthChecker(targetIds)) {
      return;
    }

    try {
      if (this.app.modal !== null) {
        return;
      }
      this.app.modal = true;
      this.app.modal = await this.modalCtrl.create({
        component: CharacterReplacerComponent,
        cssClass: 'modal-size-character-replacer',
        backdropDismiss: false,
        componentProps: {
          canvasIdList: targetIds,
          userId: this.user.id,
          canvasGroupId: +this.cutList.canvasGroupId
        }
      });
      this.app.modal.onDidDismiss().then(() => {
        this.app.modal = null;
      });
      return await this.app.modal.present();
    } catch (e) {
      throw new TooningReplaceAllCharacterError(e, null, true);
    }
  }

  /**
   * 텍스트 일괄 변경
   * @returns {Promise<any>}
   */
  async replaceAllText(): Promise<any> {
    const targetIds = this.getCanvasEditTarget();
    if (await this.targetIdsLengthChecker(targetIds)) {
      return;
    }
    try {
      if (this.app.modal !== null) {
        return;
      }
      this.app.modal = true;
      this.app.modal = await this.modalCtrl.create({
        component: TextReplacerComponent,
        cssClass: 'modal-size-text-replacer',
        backdropDismiss: false,
        componentProps: {
          canvasIdList: targetIds,
          userId: this.user.id,
          canvasGroupId: +this.cutList.canvasGroupId
        }
      });
      this.app.modal.onDidDismiss().then(() => {
        this.app.modal = null;
      });
      return await this.app.modal.present();
    } catch (e) {
      throw new TooningReplaceAllTextError(e, null, true);
    }
  }

  sortTest($event: MouseEvent) {
    $event.stopPropagation();
  }

  accordionToggle() {
    this.readyToRender = false;
    this.isAccordionOpen = !this.isAccordionOpen;
    this.readyToRender = true;
  }

  /**
   * 폴더 진입시 호출
   * @param ID - 폴더  ID null일 경우 휴지통으로 진입
   * @param title - 폴더 제목
   * @return {Promise<void>}
   */
  async canvasGroupEnter(ID: any, title: string = '폴더 타이틀'): Promise<void> {
    try {
      if (this.isCanvasEditMode) this.closeExtendFab();
      this.app.isIonBackdrop = true;
      this.app.canvasList = [];
      this.isCanvasCheckedAll = false;
      this.canvasCheckedAll = CanvasChecked.selectAll;
      this.paginationConfig.currentPage = 1;
      if (ID === null) {
        this.cutList.canvasGroupList = [];
        const fetchData = await this.cutList.canvasGroupService.getRecycleBin(this.user.id).toPromise();
        const recycleBin = fetchData.data.getRecycleBin;
        //휴지통
        this.cutList.isCanvasGroupView = false;
        this.cutList.isTrashView = true;
        this.cutList.canvasGroupName = this.translate.instant('cut4-list.Trash');
        this.cutList.canvasGroupList.push(...recycleBin.groups);
        this.app.canvasList.push(...recycleBin.canvases);
      } else {
        //일반 폴더
        this.loading.showLoader();
        this.cutList.isCanvasGroupView = true;
        this.cutList.isTrashView = false;
        this.cutList.canvasGroupName = title;
        this.cutList.canvasGroupId = ID;
        this.cutList.canvasGroupAlertInput.splice(this.cutList.canvasGroupIndex, 1);
        const myWorkAlertInput = {
          id: -1,
          type: 'radio',
          label: this.translate.instant('cut4-list.My work'),
          value: { id: -1, index: 0 },
          checked: false
        };
        const myWorkGroup = {
          canvasCount: 0,
          groupName: this.translate.instant('cut4-list.My work'),
          id: -1
        };
        this.cutList.canvasGroupAlertInput.map((input) => (input.value.index += 1));
        this.cutList.canvasGroupAlertInput.unshift(myWorkAlertInput);
        this.cutList.canvasGroupList.unshift(myWorkGroup);
        this.getCanvasList('', 1);
      }
    } catch (e) {
      throw new TooningCanvasGroupEnterError(e, null, true);
    } finally {
      await this.setPaginationConfig();
      this.app.isIonBackdrop = false;
    }
  }

  /**
   * 휴지통에서 그룹(폴더) 클릭시 진입되는 걸 막기위한 함수
   * @param isTrashView 휴지통인지 확인하는 식별자
   * @param group : canvasGroup, ID와 canvaGroupName을 사용
   * @return {Promise<void>}
   */
  async canvasGroupClick(isTrashView: boolean, group: CanvasGroup): Promise<void> {
    try {
      if (this.isCanvasEditMode) this.closeExtendFab();
      if (isTrashView) {
        const alert = await this.alertController.create({
          cssClass: 'basic-dialog',
          header: this.translate.instant('cut4-list.canvasGroupInRecycleBin'),
          message: this.translate.instant('cut4-list.messageOfRestoreCanvasGroup'),
          buttons: [
            {
              text: this.translate.instant('cancel'),
              role: 'cancel',
              handler: () => {
                console.log('Confirm Cancel');
              }
            },
            {
              text: this.translate.instant('cut4-list.restore'),
              handler: async (data) => {
                await this.restoreFromRecycleBin(CanvasListWorkingType.canvasGroup);
                console.log('Confirm Ok');
              }
            }
          ]
        });

        await alert.present();
      } else {
        await this.canvasGroupEnter(group.id, group.groupName);
      }
    } catch (e) {
      throw new TooningCanvasGroupClickError(e, null, true);
    }
  }

  /**
   * 드래그 시작
   * @param item - 드레그중인 작업물
   */
  dropStart(item, index) {
    console.log('dropStart');
    this.id = item.id; // 이동중인 작업물(캔버스)의 ID
    this.title = item.title;
    this.authorization = item.authorization;
    this.templateData = item.template;
    this.textTemplateData = item.textTemplate;
    this.listIndex = index;
    this.isDrop = true;
    setTimeout(() => {
      this.reloadMasonryLayout(true);
    }, 200);
  }

  /**
   * 드래그 끝
   * @param event
   */
  dropEnd(event) {
    console.log('dropEnd');
    this.isDropMoved = false;
    setTimeout(() => {
      this.reloadMasonryLayout();
      this.isDrop = false;
    }, 10);
  }

  dropMoved($event) {
    this.isDropMoved = true;
    console.log('dropMoved');
  }

  dropMouseupCanvasGroup(canvasGroup, i, folder) {
    this.cutList.canvasGroupIndex = i;
    this.cutList.canvasGroupName = this.cutList.isTrashView ? this.cutList.canvasGroupName : canvasGroup.groupName;
    this.cutList.canvasGroupId = canvasGroup.id;
    this.dropMovecanvasGroup(canvasGroup.id, i);
    this.dropleaveCanvas(folder);
  }

  /**
   * canvas를 드래그한 상태에서 folder 위로 mouse가 올라가면 호출
   * 올라간 폴더의 색을 바꿔준다.
   * */
  dropCanvasGroupChange(folder) {
    if (folder) {
      if (this.isDropMoved && !folder.classList.contains('mouseover-canvasGroup')) {
        folder.classList.add('mouseover-canvasGroup');
      }
    }
  }

  /**
   * mouse가 폴더에서 멀어지면 호출
   * */
  dropleaveCanvas(folder) {
    if (folder.classList.contains('mouseover-canvasGroup')) {
      folder.classList.remove('mouseover-canvasGroup');
    }
  }

  /**
   * 드레그해서 이동 호출
   * @param canvasGroupId
   * @param canvasGroupIndex
   */
  async dropMovecanvasGroup(canvasGroupId: any = CanvasListWorkingType.trash, canvasGroupIndex: number = 0) {
    try {
      console.log('dropMovecanvasGroup');
      if (!this.isDrop) {
        return;
      }
      if (canvasGroupId === CanvasListWorkingType.trash) {
        await this.canvasService.canvasDelete(this.id).toPromise();
        this.recycleBinCount++;
        //쓰레기통 이동처리
      } else {
        this.cutList.canvasGroupId = canvasGroupId;
        this.cutList.canvasGroupIndex = canvasGroupIndex;
        // this.id 를 해동 폴더로 이동 시켜주면됨
        //일반폴더 이동처리
      }
      await this.moveCanvasGroup(+canvasGroupId); //실제로 서버쪽에 이동 시키게하는 함수
    } catch (e) {
      throw new TooningDropMoveCanvasGroupError(e, null, true);
    }
  }

  /**
   * 실제 서버쪽에 폴더 이동명령시키는 함수
   * this.id - 이동하고자 하는 캔버스 아이디
   * @param canvasGroupId 폴더 아이디
   * @return {Promise<void>}
   */
  async moveCanvasGroup(canvasGroupId: number): Promise<void> {
    try {
      this.app.isIonBackdrop = true;
      const targetIds: number[] = this.getCanvasEditTarget();
      if (this.isCanvasEditMode) {
        if (await this.targetIdsLengthChecker(targetIds)) {
          return;
        }
        await this.cutList.canvasGroupService.canvasesGoToGroup(targetIds, canvasGroupId, this.user.id);
        this.cutList.canvasGroupList[this.cutList.canvasGroupIndex].canvasCount += targetIds.length;
        await this.app.showToast(
          this.translate.instant('cut4-list.moved to the folder1') +
            this.cutList.canvasGroupList[this.cutList.canvasGroupIndex].groupName +
            this.translate.instant('cut4-list.moved to the folder2')
        );
      } else if (canvasGroupId) {
        this.app.canvasList[this.listIndex].group = this.cutList.canvasGroupList[this.cutList.canvasGroupIndex];
        this.app.canvasList.splice(this.listIndex, 1); //UI 적용
        this.cutList.canvasGroupList[this.cutList.canvasGroupIndex].canvasCount += 1;
        await this.cutList.canvasGroupService.canvasGoToGroup(+this.id, canvasGroupId);
        await this.app.showToast(
          this.translate.instant('cut4-list.moved to the folder1') +
            this.cutList.canvasGroupList[this.cutList.canvasGroupIndex].groupName +
            this.translate.instant('cut4-list.moved to the folder2')
        );
      } else {
        this.app.canvasList.splice(this.listIndex, 1); //UI 적용
        await this.app.showToast(
          this.translate.instant('cut4-list.moved to the folder1') +
            this.translate.instant('cut4-list.Trash') +
            this.translate.instant('cut4-list.moved to the folder2')
        );
      }

      if (targetIds.length === this.app.canvasList.length) {
        if (this.paginationConfig.currentPage !== 1) {
          this.paginationConfig.currentPage = this.paginationConfig.currentPage - 1;
        }
      }
      this.getCanvasList('', this.paginationConfig.currentPage);
      this.closeExtendFab();
    } catch (e) {
      throw new TooningMoveCanvasGroupError(e, null, true);
    } finally {
      this.app.isIonBackdrop = false;
    }
  }

  /**
   * 폴더로 캔버스를 넣을때 알림
   * @return {Promise<void>}
   */
  async moveCanvasGroupAlertRadio(): Promise<void> {
    try {
      const alert = await this.alertController.create({
        cssClass: 'basic-dialog-horizontal-button',
        header: this.translate.instant('cut4-list.Move folder'),
        inputs: this.cutList.canvasGroupAlertInput,
        mode: 'md',
        buttons: [
          {
            text: '+',
            cssClass: 'alert-button-alone',
            handler: async () => {
              this.isFromMoveCanvasGroup = true;
              await this.openModalCanvasGroup(true);
            }
          },
          {
            text: this.translate.instant('cancel'),
            role: 'cancel',
            handler: () => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: this.translate.instant('apply'),
            handler: async (data) => {
              console.log('Confirm Ok');
              this.cutList.canvasGroupIndex = +data.index;
              await this.moveCanvasGroup(+data.id);
            }
          }
        ]
      });

      await alert.present();
    } catch (e) {
      this.isFromMoveCanvasGroup = false;
      throw new TooningMoveCanvasGroupAlertRadioError(e, null, true);
    }
  }

  /**
   * 유저가 갖고있는 그룹(폴더)리스트 세팅하는 함수
   * @return {Promise<void>}
   */
  async setCanvasGroupList(): Promise<void> {
    try {
      await this.loading.showLoader();
      await this.cut.checkAndSetDefaultCanvasGroups(this.user.id);
      this.cutList.canvasGroupList = [];
      this.cutList.canvasGroupAlertInput = [];
      const { data } = await this.cutList.canvasGroupService.getCanvasGroups(this.user.id).toPromise();
      const canvasGroupData = data;
      for (let i = 0; i < canvasGroupData.length; i++) {
        this.cutList.canvasGroupList.push(canvasGroupData[i]);
        const alertInput = {
          id: +canvasGroupData[i].id,
          type: 'radio',
          label: canvasGroupData[i].groupName,
          value: { id: +canvasGroupData[i].id, index: i },
          checked: false
        };
        this.cutList.canvasGroupAlertInput.push(alertInput);
      }
      const fetchBinData = await this.cutList.canvasGroupService.countRecycleBinData(this.user.id).toPromise();
      const canvasesInRecycleBin = fetchBinData.data.countRecycleBinData.countCanvasesinRecycleBin;
      const foldersInRecycleBin = fetchBinData.data.countRecycleBinData.countFoldersinRecycleBin;
      this.recycleBinCount = canvasesInRecycleBin + foldersInRecycleBin;
    } catch (e) {
      throw new TooningSetCanvasGroupListError(e, null, true);
    } finally {
      if (this.isCanvasListUpdateEnd) this.loading.hideLoader();
    }
  }

  //무료계정이면 열리지 않도록 처리
  openRestrictions(messageKey = '3') {
    const dialogRef = this.dialog.open(PaidReferralComponent, {
      width: this.app.isDesktopWidth() ? '440px' : '90%',
      height: this.app.isDesktopWidth() ? '600px' : '80%',
      data: {
        message: this.translate.instant(messageKey)
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {});
  }

  async sharingSettingAlert(canvasGroup) {
    try {
      this.app.popover = await this.popoverController.create({
        mode: 'md',
        translucent: true,
        component: PopoverSharingCanvasComponent,
        componentProps: {
          canvasGroup
        }
      });
      this.app.popover.onDidDismiss().then(async (data) => {
        this.app.popover = null;
      });

      this.app.popover.style.cssText =
        '--min-width: 100px; --max-width: 335px; --width : 80%; --height: 267px; --max-height: 267px; --border-radius: 15px;';

      return await this.app.popover.present();
    } catch (e) {
      throw new TooningCanvasGroupShareAlertError(e, null, true);
    }
  }

  openCanvasGroupMenu(event, canvasGroupIndex: number) {
    event.stopPropagation();
    this.cutList.canvasGroupIndex = canvasGroupIndex;
  }

  /**
   * 공유폴더에 작업물 제출하기
   * @return {Promise<void>}
   */
  async canvasSubmit(canvasCheck: CanvasCheck): Promise<void> {
    try {
      this.fromCut4List = true;
      const componentProps = {
        fromCut4List: this.fromCut4List,
        canvasId: 0,
        canvasIdList: []
      };
      if (canvasCheck === CanvasCheck.Single) {
        componentProps['canvasId'] = this.id;
      }
      if (canvasCheck === CanvasCheck.Multi) {
        componentProps['canvasIdList'] = this.getCanvasEditTarget();
        if (await this.targetIdsLengthChecker(componentProps.canvasIdList)) {
          return;
        }
      }

      this.app.modal = await this.modalCtrl.create({
        component: PopoverSubmitCanvasComponent,
        componentProps: componentProps
      });
      this.app.modal.onDidDismiss().then(async (data) => {
        this.app.modal = null;
        if (!data.data) {
          return;
        }
        const targetCanvasGroup = this.cutList.canvasGroupList.find((canvasGroup) => canvasGroup.id === data.data.targetCanvasGroup);
        if (targetCanvasGroup) targetCanvasGroup.canvasCount += canvasCheck === CanvasCheck.Single ? 1 : componentProps.canvasIdList.length;
      });
      this.app.modal.style.cssText = '--min-width: 100px; --max-width: 335px; --width : 80%; --max-height: 244px; --border-radius: 8px;';

      await this.app.modal.present();
    } catch (e) {
      throw new TooningCanvasGroupSubmitAlertError(e, null, true);
    }
  }

  /***
   * 폴더 생성 input 클릭시 텍스트 전체 선택
   * @param {object} $event 마우스 클릭 이벤트
   */
  select($event: any) {
    $event.target.select();
  }

  /**
   * 불러온 모든 캔버스 체크
   * @return {void}
   */
  checkedAllCanvas(): void {
    this.isCanvasCheckedAll = !this.isCanvasCheckedAll;
    this.app.canvasList.map((canvas) => (canvas.isChecked = this.isCanvasCheckedAll));
    this.countCheckedCount = this.isCanvasCheckedAll ? this.app.canvasList.length : 0;
    this.canvasCheckedAll = this.isCanvasCheckedAll ? CanvasChecked.fullRelease : CanvasChecked.selectAll;
  }

  /** @description 업로드한 이미지의 mouseleave 이벤트 handler
   *  @param {Event} event mouseleave한 이미지의  정보가 들어온다.
   *  @param item 비활성화 시킬 작업물
   *  @return {void} 없음
   */
  onOut(event, item): void {
    if (item.id >= 0) {
      if (!this.isCanvasEditMode) {
        event.stopPropagation();
        item.checkBoxVisible = false;
      }
    }
  }

  /** @description 업로드한 이미지의 mouseover 이벤트 handler
   *  @param {Event} event mouseover한 이미지의  정보가 들어온다.
   *  @param item 활성화 시킬 작업물
   *  @return {void} 없음
   */
  onOver(event, item): void {
    if (item.id >= 0) {
      if (!this.isCanvasEditMode) {
        event.stopPropagation();
        item.checkBoxVisible = true;
      }
    }
  }

  /**
   * 선택한 캔버스 모두 휴지통으로 보내기
   * @return {Promise<boolean>}
   */
  async deleteCanvases(): Promise<boolean> {
    try {
      let result = false;
      const alertType = this.cutList.isTrashView ? CanvasListWorkingType.trash : CanvasListWorkingType.work;
      const alert = await this.alertController.create({
        header: this.translate.instant(`DELETE_CONFIRM.${alertType}`),
        message: this.translate.instant(`DELETE_MESSAGE.${alertType}`),
        cssClass: 'basic-dialog',
        buttons: [
          {
            text: this.translate.instant('CANCEL'),
            role: 'cancel'
          },
          {
            text: this.translate.instant(`DELETE.${alertType}`),
            handler: async () => {
              await this.loading.showLoader();
              const targetIds = this.getCanvasEditTarget();
              if (await this.targetIdsLengthChecker(targetIds)) {
                return;
              }
              if (alertType === CanvasListWorkingType.work) {
                result = await this.cutList.canvasGroupService.canvasesDelete(this.user.id, targetIds);
              } else if (alertType === CanvasListWorkingType.trash) {
                result = await this.cutList.canvasGroupService.deleteForeverCanvases(targetIds);
              }
              if (!result) {
                await this.app.showToast(this.translate.instant('cut4-list.fail_delete_canvas'));
              }

              if (targetIds.length === this.app.canvasList.length) {
                if (this.paginationConfig.currentPage !== 1) {
                  this.paginationConfig.currentPage = this.paginationConfig.currentPage - 1;
                }
              }
              this.getCanvasList('', this.paginationConfig.currentPage);
              this.isCanvasCheckedAll = false;
              this.canvasCheckedAll = CanvasChecked.selectAll;
              this.closeExtendFab();
              this.loading.hideLoader();
            }
          }
        ]
      });
      await alert.present();
      return result;
    } catch (e) {
      throw new TooningDeleteCanvasesError(e, null, true);
    }
  }

  /**
   * 캔버스 선택 모드 닫는 버튼
   * @return {void}
   */
  closeExtendFab(): void {
    this.isCanvasEditMode = false;
    this.app.canvasList.forEach((canvas) => (canvas.checkBoxVisible = false));
    this.app.canvasList.forEach((canvas) => (canvas.isChecked = false));
    this.countCheckedCount = 0;
  }

  /**
   * 선택 버튼을 통해 캔버스 리스트 편집모드로 변경되게하는 함수
   * @param event
   * @return {void}
   */
  changeEditMode(event: Event): void {
    this.isCanvasEditMode = !this.isCanvasEditMode;
    this.app.canvasList.forEach((canvas) => (canvas.isChecked = false));
    this.app.canvasList.forEach((canvas) => (canvas.checkBoxVisible = false));
  }

  /**
   * 작업물 체크함수, 체크된 작업물 개수도 업데이트해준다.
   * @param item 체크할 작업물
   */
  checkedCanvas(item: any): void {
    this.isCanvasEditMode = true;
    item.isChecked ? this.countCheckedCount-- : this.countCheckedCount++;
  }

  /**
   * 체크된 작업물 확인해서 ID값만 넘겨준다.
   * @return {number[]}
   */
  getCanvasEditTarget(): number[] {
    return this.app.canvasList.filter((canvas) => canvas.isChecked).map((canvas) => +canvas.id);
  }

  async targetIdsLengthChecker(targetIds: number[]): Promise<boolean> {
    let result = targetIds.length <= 0;
    if (result) {
      await this.app.showToast(this.translate.instant('cut4-list.nonSelected'));
    }
    return result;
  }

  /**
   * 다수의 작업물을 휴지통에서 복구해주는 함수
   * @return {Promise<void>}
   */
  async restoreCanvasesFromRecycleBin(): Promise<void> {
    try {
      await this.loading.showLoader();
      const targetIds = this.getCanvasEditTarget();
      if (await this.targetIdsLengthChecker(targetIds)) {
        return;
      }
      await this.cutList.canvasGroupService.restoreCanvasesFromRecycleBin(targetIds);
      this.closeExtendFab();
    } catch (e) {
      throw new TooningRestoreCanvasesFromRecycleBinError(e, null, true);
    } finally {
      this.loading.hideLoader();
    }
  }

  /**
   * 작업물 정렬 기준을 변경해주는 함수
   * @param orderBy 작업물 정렬 기준,sort와 order로 구성
   * @return {Promise<void>}
   */
  async changeOrderBy(orderBy: any): Promise<void> {
    try {
      await this.loading.showLoader();
      this.selectedOrderBy = orderBy.orderBy;
      this.canvasOrderBy.sort = orderBy.sort;
      this.canvasOrderBy.order = orderBy.order;
      this.getCanvasList('', 1);
    } catch (e) {
      throw new TooningCutListChangeOrderByError(e);
    } finally {
      await this.setPaginationConfig();
    }
  }

  /**
   * 페이지네이션 관련 변수들 세팅해주는 함수
   * @return {Promise<void>}
   */
  async setPaginationConfig(): Promise<void> {
    try {
      if (!this.cutList.isCanvasGroupView) {
        this.cutList.canvasGroupId = null;
      }
      const { data } = await this.cutList.canvasGroupService.canvasesCountForCanvasGroup(this.user.id, this.cutList.canvasGroupId).toPromise();
      this.getTake();
      this.paginationConfig = {
        currentPage: this.paginationConfig.currentPage,
        itemsPerPage: this.take,
        totalItems: data
      };
    } catch (e) {
      throw new TooningSetPaginationConfigError(e);
    }
  }

  async initPaginationConfig() {
    const { data } = await this.cutList.canvasGroupService.canvasesCountForCanvasGroup(this.user.id, this.cutList.canvasGroupId).toPromise();
    this.paginationConfig = {
      currentPage: 1,
      itemsPerPage: this.take,
      totalItems: data
    };
  }

  async keyDownHandler(event) {
    // 제목 변경 얼럿에서 enter 키 누를때
    if (event.key === 'Enter' && this.modalCanvasChangeTitle.isCmpOpen && this.canvasChangeTitle !== '') {
      const target = document.getElementById('titleChangeModalApproveBtn');
      target.click();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.cutList.canvasGroupId = null;
  }

  /**
   *
   * @param {string} searchText 서치텍스트
   * @param {number} pageNum 해당 페이지 넘버
   */
  getCanvasList(searchText: string, pageNum: number = 1) {
    this.skip = this.paginationConfig.itemsPerPage * (pageNum - 1);
    this.searchText = searchText;

    this.cutList.isCanvasGroupView
      ? this.searchSubject$.next({
          skip: this.skip,
          searchText: this.searchText,
          canvasGroupId: this.cutList.canvasGroupId
        })
      : this.searchSubject$.next({ skip: this.skip, searchText: this.searchText });
  }
}
