<ion-header>
  <ion-toolbar>
    <ion-title slot='start'>{{ 'laboratory' | translate }}</ion-title>
    <ion-buttons slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content #content appScrollbarTheme>
  <div class='p-3'>
    <!--    그리드    -->
    <ion-item
      style='--background: #ffffff00; --border-color: #e8e8e800;--padding-start: 0;--padding-end: 0;--inner-padding-end: 0;'>
      <ion-label style='font-size: 13px'>{{'grid function' | translate}}
        <ion-icon *ngIf='app.isDesktop()' matTooltip="{{ 'grid tip' | translate }}"
                  name='information-circle-outline'
                  style='font-size: 20px;margin-bottom: -5px;cursor: pointer'
        ></ion-icon>
        <ion-icon *ngIf='!app.isDesktop()' id='grid-tip' name='information-circle-outline'
                  style='font-size: 20px;margin-bottom: -5px;cursor: pointer'></ion-icon>
        <ion-popover class='popover-tip' size='auto' trigger='grid-tip' triggerAction='click'>
          <ng-template>
            <div>{{'grid tip' | translate}}</div>
          </ng-template>
        </ion-popover>
      </ion-label>
      <ion-toggle slot='end' [(ngModel)]='cut.laboratory.grid'
                  (click)='cut.setGrid(); cut.saveLaboratoryStatus()'></ion-toggle>
    </ion-item>
    <!--    텍스트 단어 단위로 자동 내려쓰기    -->
    <ion-item *ngIf='app.usedLanguage!==languageType.jp'
              style='--background: #ffffff00; --border-color: #e8e8e800;--padding-start: 0;--padding-end: 0;--inner-padding-end: 0;'>
      <ion-label style='font-size: 13px'>{{'line break by word' | translate}}
        <ion-icon *ngIf='app.isDesktop()' matTooltip="{{ 'line break tip' | translate }}"
                  name='information-circle-outline'
                  style='font-size: 20px;margin-bottom: -5px;cursor: pointer'
        ></ion-icon>
        <ion-icon *ngIf='!app.isDesktop()' id='lineBreak-tip' name='information-circle-outline'
                  style='font-size: 20px;margin-bottom: -5px;cursor: pointer'></ion-icon>
        <ion-popover class='popover-tip' size='auto' trigger='lineBreak-tip' triggerAction='click'>
          <ng-template>
            <div>{{'line break tip' | translate}}</div>
          </ng-template>
        </ion-popover>
      </ion-label>
      <ion-toggle (click)='cut.onSplitByWord()' [(ngModel)]='cut.laboratory.splitByWord' slot='end'></ion-toggle>
    </ion-item>
    <!--    이전 이미지 보이게 하기    -->
    <ion-item
      style='--background: #ffffff00; --border-color: #e8e8e800;--padding-start: 0;--padding-end: 0;--inner-padding-end: 0;'>
      <ion-label style='font-size: 13px'>{{'setShowPreviewCanvas.title' | translate}}
        <ion-icon *ngIf='app.isDesktop()' matTooltip="{{'setShowPreviewCanvas.tip' | translate}}"
                  name='information-circle-outline'
                  style='font-size: 20px;margin-bottom: -5px;cursor: pointer'
        ></ion-icon>
        <ion-icon *ngIf='!app.isDesktop()' id='showPreviewCanvas-tip' name='information-circle-outline'
                  style='font-size: 20px;margin-bottom: -5px;cursor: pointer'></ion-icon>
        <ion-popover class='popover-tip' size='auto' trigger='showPreviewCanvas-tip' triggerAction='click'>
          <ng-template>
            <div>{{'setShowPreviewCanvas.tip' | translate}}</div>
          </ng-template>
        </ion-popover>
      </ion-label>
      <ion-toggle (ionChange)='cut.setShowPreviewCanvas()'
                  [(ngModel)]='cut.isShowPreviewCanvas'
                  slot='end'></ion-toggle>
    </ion-item>

  </div>
</ion-content>
