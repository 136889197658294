<div class='modal-wrapper'>
  <!--  이미지  -->
  <div class='img-container'>
    <ion-icon (click)='cancel()' name='close-outline' data-cy='paidReferralCancel'></ion-icon>
    <ion-img src='/assets/common/3d_pad_plz.png'></ion-img>
  </div>


  <div class='content-wrapper'>
    <div *ngIf='data.message!=="0"' class='pro-filter-wrapper' data-cy='canvasLimit'>
      <div [innerHTML]="'plz pad message ' + data.message | translate" class='content-title'></div>

      <!--   데모 계정일 때   -->
      <div *ngIf='app.cache.user.role === UserRole.demo' class='content-text-1'>
        {{ 'plz pad message add-' + data.message + '-demo'| translate }}
      </div>

      <!--   데모 계정이 아닐 때   -->
      <ng-container *ngIf='app.cache.user.role !== UserRole.demo'>
        <div class='content-text-1'>
          {{ 'plz pad message add-' + data.message | translate }}
        </div>
        <div class='content-text-2'>
          {{data.message2}}
        </div>
      </ng-container>
    </div>

    <!-- 유료 배경 안내 -->
    <div *ngIf='data.message==="0"' class='sku-filter-wrapper'>
      <div class='check-title'>{{ 'pay message-' + data.message + '-1' | translate }}</div>
      <div class='check-list-wrapper'>
        <div class='check-list'>
          <ion-icon name='checkmark-outline'></ion-icon>
          <div class='check-list__text'>{{ 'pay message-' + data.message + '-2' | translate }}</div>
        </div>
        <div class='check-list'>
          <ion-icon name='checkmark-outline'></ion-icon>
          <div class='check-list__text'>{{ 'pay message-' + data.message + '-3' | translate }}</div>
        </div>
      </div>
      <div class='small-content'>{{ 'pay message-' + data.message + '-4' | translate }}</div>
    </div>
  </div>

  <div class='button-wrapper'>
    <!--   데모 계정일 때   -->
    <ng-container *ngIf='app.cache.user.role === UserRole.demo'>
      <ion-button (click)='cancel()' class='theme-outline custom-size-m' mode='ios' expand='block'
                  matRipple>{{'free trialimg' | translate}} </ion-button>
      <ion-button (click)='cancel();app.demoClass.openModalLogin()' class='theme-main custom-size-m' mode='ios'
                  expand='block' matRipple>{{'sing-up-and-sing-in' | translate}} </ion-button>
    </ng-container>

    <!--   데모 계정이 아닐 때   -->
    <ng-container *ngIf='app.cache.user.role !== UserRole.demo'>
      <ion-button (click)='go()' expand='block' mode='ios' class='theme-main custom-size-m'
                  data-cy='paidReferralGo'>{{ '14-free' | translate }}
      </ion-button>
    </ng-container>
  </div>
</div>