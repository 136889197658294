import { Injectable } from '@angular/core';
import { ChromeBrowserCheck, LanguageType } from '../enum/app.enum';
import { CurrentUser } from '../interfaces/app.interface';
import { TooningCacheGetError, TooningCacheSetError } from '../pages-tooning/errors/TooningErrors';
import { BoardUserType } from '../board/shared/interfaces/board.type';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  user: CurrentUser | null = null;

  // 휘발성 데이터
  // window localstorage에 저장하기엔 애매하고, 상태값을 컴포넌트, 페이지간 공유하고자하는 데이터들을 모아놓는다.
  // 즉 브라우저가 혹은 앱이 꺼지게되면 날아가는 데이터 이다.
  public volatile_datas: any = {
    // list와 cu_group을 지님
    upload_user_submit: null,
    purchase_point: null,
    ready_order: [],
    coupon_upload_temp: [],
    app_flow_update: {
      download_progress: 0,
      extract_progress: 0
    },
    userResource: {
      initList: []
    },
    photoResource: {
      list: []
    },
    defaultCharacterRealArea: {},
    defaultCharacterBase64: {}
  };

  // multi tab 이동시 localStorage 사용하면 key가 공유되므로 sessionStorage로 변경
  public setLocal(key, target) {
    if (target == null) {
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, target);
    }
  }

  public getLocal(key) {
    return window.sessionStorage.getItem(key);
  }

  public removeLocal(key) {
    window.sessionStorage.removeItem(key);
  }

  // 캐릭터 캐시

  public getWorkingCharacter() {
    return window.sessionStorage.getItem('working');
  }

  public getWorkingCharacterType() {
    return window.sessionStorage.getItem('type');
  }

  public setWorkingCharacter(id) {
    window.sessionStorage.setItem('working', id);
  }

  // copy&paste
  public getCopyAndPaste() {
    return window.localStorage.getItem('copy&paste');
  }

  public setCopyAndPaste(id) {
    window.localStorage.setItem('copy&paste', id);
  }

  public removeCopyAndPaste() {
    window.localStorage.removeItem('copy&paste');
  }

  // copyTextStyle
  public getCopyText() {
    return window.localStorage.getItem('copyText');
  }

  public setCopyText(textInfo) {
    window.localStorage.setItem('copyText', textInfo);
  }

  public removeCopyText() {
    window.localStorage.removeItem('copyText');
  }

  public removeWorkingCharacter() {
    window.sessionStorage.removeItem('working');
  }

  public getWorkingCharacterName() {
    return window.sessionStorage.getItem('workingCharacterName');
  }

  public setWorkingCharacterName(name: string) {
    window.sessionStorage.setItem('workingCharacterName', name);
  }

  // 학교 캐시
  public getWorkingSchool() {
    return window.sessionStorage.getItem('school');
  }

  public setWorkingSchool(id) {
    window.sessionStorage.setItem('school', id);
  }

  public removeWorkingSchool() {
    window.sessionStorage.removeItem('school');
  }

  // 단체등록 견적서 캐시
  public setEduEstimateId(id) {
    window.localStorage.setItem('eduEstimateId', id);
  }

  public getEduEstimateId() {
    return window.localStorage.getItem('eduEstimateId');
  }

  // 견적서 아이디 삭제 함수
  public removeEduEstimateId() {
    window.localStorage.removeItem('eduEstimateId');
  }

  // template
  public getWorkingTemplate() {
    return window.sessionStorage.getItem('template');
  }

  public setWorkingTemplate(id) {
    window.sessionStorage.setItem('template', id);
  }

  public removeWorkingTemplate() {
    window.sessionStorage.removeItem('template');
  }

  // text Template
  public getWorkingTextTemplate() {
    return window.sessionStorage.getItem('textTemplate');
  }

  public setWorkingTextTemplate(id) {
    window.sessionStorage.setItem('textTemplate', id);
  }

  public removeWorkingTextTemplate() {
    window.sessionStorage.removeItem('textTemplate');
  }

  // etcUpload

  public getWorkingEtcUpload() {
    return window.sessionStorage.getItem('etc');
  }

  public setWorkingEtcUpload(id) {
    window.sessionStorage.setItem('etc', id);
  }

  // 언어 설정
  public getWorkingLanguage(): LanguageType {
    return window.sessionStorage.getItem('language') as LanguageType;
  }

  public setWorkingLanguage(language) {
    window.sessionStorage.setItem('language', language);
  }

  public removeWorkingEtcUpload() {
    window.sessionStorage.removeItem('etc');
  }

  public setWorkingEtcUploadName(name: string) {
    window.sessionStorage.setItem('workingEtcUploadName', name);
  }

  // cloud switch
  public setWorkingCloudeFront(cloudfrontState: string) {
    window.sessionStorage.setItem('turnOnCloudFront', cloudfrontState);
  }

  public getWorkingCloudeFront() {
    return window.sessionStorage.getItem('turnOnCloudFront');
  }

  /////////////////////////////////////

  // etcUpload Type cache

  public getWorkingEtcUploadType() {
    return window.sessionStorage.getItem('type');
  }

  public async setWorkingEtcUploadType(type: string) {
    window.sessionStorage.setItem('type', type);
  }

  public removeWorkingEtcUploadType() {
    window.sessionStorage.removeItem('type');
  }

  /////////////////////////

  public setRedirect(target) {
    this.setLocal('redirect', target);
  }

  public removeRedirect() {
    this.removeLocal('redirect');
  }

  public getRedirect() {
    return this.getLocal('redirect');
  }

  public setChromeBrowserCheck() {
    window.localStorage.setItem('ChromeBrowserCheck', ChromeBrowserCheck.checked);
  }

  public getChromeBrowserCheck() {
    return window.localStorage.getItem('ChromeBrowserCheck');
  }

  /**
   * 카카오 로그인 연결끊었는지 정보 저장
   * @param {boolean} isUnlink
   * @return {void}
   */
  public setIsUnlink(isUnlink: boolean): void {
    try {
      window.localStorage.setItem('isUnlink', String(isUnlink));
    } catch (e) {
      throw new TooningCacheSetError(e);
    }
  }

  /**
   * 카카오 로그인 연결 끊었는지 정보 가져오기
   * @return {string}
   */
  public getIsUnlink(): string {
    try {
      return window.localStorage.getItem('isUnlink');
    } catch (e) {
      throw new TooningCacheGetError(e);
    }
  }

  /**
   * 투닝 보드 신기능 확인 했는지 정보 저장
   * @return {void}
   */
  public setTooningBoardUpdateDot(): void {
    window.localStorage.setItem('TooningBoardUpdateDot', 'true');
  }

  /**
   * 투닝 보드 신기능 확인 했는지 정보 가져오기
   * @returns {string}
   */
  public getTooningBoardUpdateDot(): string {
    return window.localStorage.getItem('TooningBoardUpdateDot');
  }
}
