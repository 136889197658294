import { ObjectSkewedCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { fabric } from 'fabric';
import { SelectedType } from '../../enum/app.enum';
import { ObjectScaleCommand } from './object-scale-command';

class ObjectSkewedCommand extends ObjectScaleCommand {
  protected readonly beforeSkewX: number;
  protected readonly beforeSkewY: number;
  protected readonly afterSkewX: number;
  protected readonly afterSkewY: number;
  protected readonly beforeTop: number;
  protected readonly beforeLeft: number;
  protected readonly afterTop: number;
  protected readonly afterLeft: number;
  protected readonly beforeScaleX: number;
  protected readonly beforeScaleY: number;
  protected readonly afterScaleX: number;
  protected readonly afterScaleY: number;
  protected readonly beforeAngle: number;
  protected readonly afterAngle: number;
  protected readonly beforeFlipX: boolean;
  protected readonly afterFlipX: boolean;
  protected readonly data;

  constructor(data: ObjectSkewedCommandData) {
    super(data);
    this.beforeSkewX = data.beforeSkewX;
    this.beforeSkewY = data.beforeSkewY;
    this.afterSkewX = data.afterSkewX;
    this.afterSkewY = data.afterSkewY;
    this.beforeTop = data.beforeTop;
    this.beforeLeft = data.beforeLeft;
    this.afterTop = data.afterTop;
    this.afterLeft = data.afterLeft;
    this.beforeScaleX = data.beforeScaleX;
    this.beforeScaleY = data.beforeScaleY;
    this.afterScaleX = data.afterScaleX;
    this.afterScaleY = data.afterScaleY;
    this.beforeAngle = data.beforeAngle;
    this.afterAngle = data.afterAngle;
    this.beforeFlipX = data.beforeFlipX;
    this.afterFlipX = data.afterFlipX;
    this.commandName = 'Object-Skewed-Command';
  }

  /**
   * Execute ObjectSkewedCommand
   * @return {void}
   */
  execute(): void {
    try {
      super.execute();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo ObjectSkewedCommand
   * @param {TooningFabricObject[]} objects
   * @return {void}
   */
  redo(objects: TooningFabricObject[]): void {
    try {
      super.redo(objects);
      this.skewTarget(objects, this.afterTop, this.afterLeft, this.afterSkewX, this.afterSkewY, this.afterScaleX, this.afterScaleY, this.afterAngle);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo ObjectSkewedCommand
   * @param {TooningFabricObject[]} objects
   * @return {void}
   */
  undo(objects: TooningFabricObject[]): void {
    try {
      super.redo(objects);
      this.skewTarget(
        objects,
        this.beforeTop,
        this.beforeLeft,
        this.beforeSkewX,
        this.beforeSkewY,
        this.beforeScaleX,
        this.beforeScaleY,
        this.beforeAngle
      );
    } catch (e) {
      throw new Error(e);
    }
  }

  skewTarget(objects, top, left, skewX, skewY, scaleX, scaleY, angle) {
    if (this.type === SelectedType.activeSelection) {
      const selectionList = [];
      objects.forEach((object) => {
        for (const element of this.id) {
          if (object.resource_selection_id === element) {
            object.set({
              skewX: skewX,
              skewY: skewY,
              angle: angle,
              scaleX: scaleX,
              scaleY: scaleY
            });
            selectionList.push(object);
          }
        }
      });
      this.cut.canvas.discardActiveObject();
      const selectedObjects = new fabric.ActiveSelection(selectionList, {
        canvas: this.cut.canvas
      });
      selectedObjects.set({
        top: top,
        left: left
      });
      this.cut.canvas.setActiveObject(selectedObjects);
      selectedObjects.setCoords();
    } else {
      objects.forEach((object) => {
        if (object.resource_selection_id === this.id[0]) {
          object.set({
            skewX: skewX,
            skewY: skewY,
            top: top,
            left: left
          });
          object.setCoords();
        } else {
          console.log('object skew command 타겟이 없습니다.');
        }
      });
    }
    this.cut.setPositionBoxButton();
    this.cut.forceNoFocus();
    this.updatePageThumbnail();
  }
}

export { ObjectSkewedCommand };
