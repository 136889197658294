import { Command } from './command';
import { ObjectMoveCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { fabric } from 'fabric';
import { SelectedType } from '../../enum/app.enum';

/**
 * Class ObjectMoveCommand
 * @extends Command
 */
class ObjectMoveCommand extends Command {
  protected readonly beforeLeft: number;
  protected readonly beforeTop: number;
  protected readonly afterLeft: number;
  protected readonly afterTop: number;

  /**
   * Create ObjectRemoveCommand
   * @param {ObjectMoveCommandData} data
   */
  constructor(data: ObjectMoveCommandData) {
    super(data);
    this.cut = data.cut;
    this.beforeTop = data.beforeTop;
    this.beforeLeft = data.beforeLeft;
    this.afterTop = data.afterTop;
    this.afterLeft = data.afterLeft;
    this.commandName = 'Object-Move-Command';
  }

  /**
   * Execute ObjectMoveCommand
   */
  execute() {
    try {
      super.execute();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo ObjectMoveCommand
   * @param {TooningFabricObject[]} objects
   */
  redo(objects: TooningFabricObject[]) {
    try {
      super.redo(objects);
      this.moveTarget(objects, this.afterTop, this.afterLeft);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo ObjectMoveCommand
   * @param {TooningFabricObject[]} objects
   */
  undo(objects: TooningFabricObject[]) {
    try {
      super.undo(objects);
      this.moveTarget(objects, this.beforeTop, this.beforeLeft);
    } catch (e) {
      throw new Error(e);
    }
  }

  moveTarget(objects, top, left) {
    this.cut.forceNoFocus();
    if (this.type === SelectedType.activeSelection) {
      const selectionList = [];
      objects.forEach((object) => {
        for (const element of this.id) {
          if (object.resource_selection_id === element) {
            selectionList.push(object);
          }
        }
      });
      this.cut.canvas.discardActiveObject();
      const selectedObjects = new fabric.ActiveSelection(selectionList, {
        canvas: this.cut.canvas
      });

      selectedObjects.set({
        top: top,
        left: left
      });
      this.cut.canvas.setActiveObject(selectedObjects);
      selectedObjects.setCoords();
    } else {
      objects.forEach((object) => {
        if (object.resource_selection_id === this.id[0]) {
          object.set({
            top: top,
            left: left
          });
          object.setCoords();
        } else {
          console.log('object move command 타겟이 없습니다.');
        }
      });
      this.cut.forceNoFocus();
    }
    this.cut.setPositionBoxButton();
    this.updatePageThumbnail();
  }
}

export { ObjectMoveCommand };
