import { Command } from './command';
import { ObjectFlipYCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { ResourceName, SelectedType } from '../../enum/app.enum';
import { fabric } from 'fabric';

/**
 * Class ObjectFlipYCommand
 * @extends Command
 */
class ObjectFlipYCommand extends Command {
  protected readonly beforeFlipY: boolean;
  protected readonly afterFlipY: boolean;

  /**
   * Create ObjectFlipYCommand
   * @param {ObjectFlipYCommandData} data
   */
  constructor(data: ObjectFlipYCommandData) {
    super(data);
    this.beforeFlipY = data.beforeFlipY;
    this.afterFlipY = data.afterFlipY;
    this.commandName = 'Object-FlipY-Command';
  }

  /**
   * object가 flipY 되었을 때 실행되는 command
   */
  async execute() {
    try {
      super.execute();
      const originalFlips = [];
      let objects;
      const isGroup = this.cut.selectedOb.resource_name === ResourceName.groupName;
      const isActive = this.cut.selectedOb.type === SelectedType.activeSelection;
      if (isGroup || isActive) {
        objects = this.cut.selectedOb.getObjects();
        objects.forEach((object) => {
          originalFlips.push(object.flipY);
        });
      }

      console.log('originalFlips', originalFlips);
      console.log('object flipY execute');
      this.cut.selectedOb.toggle('flipY');

      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object flipY command redo command
   * flipY undo 처리된 object를 다시 뒤집어준다.
   * @param {TooningFabricObject[]} objects
   * @return {void}
   */
  redo(objects: TooningFabricObject[]): void {
    try {
      super.redo(objects);
      this.toggleFlipY(objects);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object flipY command undo command
   * flipY 처리된 object를 다시 뒤집어준다.
   * @param {TooningFabricObject[]} objects
   * @return {void}
   */
  undo(objects: TooningFabricObject[]): void {
    try {
      super.undo(objects);
      this.toggleFlipY(objects);
    } catch (e) {
      throw new Error(e);
    }
  }

  toggleFlipY(objects) {
    const originalFlips = [];
    if (this.type === SelectedType.activeSelection) {
      objects.forEach((object) => {
        for (const element of this.id) {
          if (object.resource_selection_id === element) {
            originalFlips.push(object);
          }
        }
      });
      this.cut.canvas.discardActiveObject();
      const selectedObjects = new fabric.ActiveSelection(originalFlips, {
        canvas: this.cut.canvas
      });
      this.cut.canvas.setActiveObject(selectedObjects);
      selectedObjects.toggle('flipY');
      selectedObjects.setCoords();
    } else {
      objects.forEach((object) => {
        if (object.resource_selection_id === this.id[0]) {
          object.toggle('flipY');
        }
      });
    }
    this.updatePageThumbnail();
  }
}

export { ObjectFlipYCommand };
