import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EduApplicantsGroup, RejectEduApplicant, TeacherInfoForAdmin } from '../../interfaces/app.interface';
import { EduApplicantsService } from '../../services/eduApplicants/edu-applicants.service';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { AppService } from '../../services/app.service';
import { DateType, EduApplicantStatus, LanguageType, Order, RejectedReason } from '../../enum/app.enum';
import { ClipboardService } from 'ngx-clipboard';
import {
  TooningChangeFormatApplicantError,
  TooningChangeIsFoldError,
  TooningCompleteUpgradeError,
  TooningTotalSetApplicantError,
  TooningZoomPopupOpenError
} from '../../pages-tooning/errors/TooningErrors';
import { Loading } from '../../services/loading';
import { PopoverController } from '@ionic/angular';
import { ZoomPopoverComponent } from '../zoom-popover/zoom-popover.component';
import { InputGetEduApplicantList } from '../../model/edu/eduPlan';
import { GetEduApplicantsListDataAdmin } from '../../interfaces/graphql.interface';

const cloudFrontUrl = environment.cloudfront_url;

@Component({
  selector: 'app-upgrade-pro-for-edu',
  templateUrl: './upgrade-pro-for-edu.component.html',
  styleUrls: ['./upgrade-pro-for-edu.component.scss']
})
export class UpgradeProForEduComponent implements OnInit {
  @ViewChild('zoomPopover') zoomPopover: HTMLIonPopoverElement;
  @Input() countryList: LanguageType[];
  @Input() applyStatusList: EduApplicantStatus[];
  @Input() dateType: DateType;
  @Input() startDate;
  @Input() endDate;
  @Input() dateOrder: Order = Order.desc;
  @Input() searchText: string;
  public readyToRender: boolean = false;
  public eduApplicants: EduApplicantsGroup[] = [];
  public isAllChecked: boolean = false;
  public order = Order;
  public isDuplicate: boolean;
  public loading = new Loading();
  public RejectReasonEnum = RejectedReason;
  public currentApplicant: TeacherInfoForAdmin;
  public EduApplicantStatusEnum = EduApplicantStatus;
  public popoverOpen = false;
  public inputGetEduApplicantList: InputGetEduApplicantList;
  public isFoldAll: boolean = false;
  public totalCount: number = 0;

  constructor(
    public app: AppService,
    public eduService: EduApplicantsService,
    private clipboardService: ClipboardService,
    private popoverCtrl: PopoverController
  ) {}

  async ngOnInit() {
    await this.totalSetApplicant();
    this.app.channelTalkHide();
    this.readyToRender = true;
  }

  /**
   * 신청자 리스트 가져오고 날짜별로 정렬해주는 함수
   * @return {Promise<void>}
   */
  async totalSetApplicant(): Promise<void> {
    try {
      await this.loading.showLoader();
      this.totalCount = 0;
      this.eduApplicants = [];
      this.isAllChecked = false;
      const setInput = {
        countryList: this.countryList,
        applyStatusList: this.applyStatusList,
        startDate: this.startDate,
        endDate: this.endDate,
        dateOrder: this.dateOrder,
        dateType: this.dateType,
        searchText: this.searchText
      };
      this.inputGetEduApplicantList = new InputGetEduApplicantList().deserialize(setInput);
      const { data } = await this.eduService.getEduApplicantsList(this.inputGetEduApplicantList).toPromise();
      this.changeFormatApplicant(data);
      this.sortByDate(this.dateOrder);
    } catch (e) {
      throw new TooningTotalSetApplicantError(e);
    } finally {
      this.loading.hideLoader();
    }
  }

  /**
   * 모든 신청자 승인해주는 함수
   * @return {void}
   */
  applyAllApplicants(): void {
    for (let group of this.eduApplicants) {
      group.applicants.forEach((applicant) => {
        if (this.isNotUpdate(applicant)) {
          return;
        }
        applicant.applyStatus = this.isAllChecked ? EduApplicantStatus.complete : EduApplicantStatus.pending;
        applicant.dirty = true;
      });
    }
  }

  /**
   * 더티 false && (중복 제외 || 반려 제외 || 이미 승인)된건 제외
   * @param {TeacherInfoForAdmin} applicant
   * @return {boolean}
   */
  isNotUpdate(applicant: TeacherInfoForAdmin) {
    return (
      !applicant.dirty &&
      (applicant.isDuplicate || applicant.applyStatus === EduApplicantStatus.rejected || applicant.applyStatus === EduApplicantStatus.complete)
    );
  }

  /**
   * 신청자들 상태 업데이트 함수
   * @return {Promise<void>}
   */
  async handleEduProRequests(): Promise<void> {
    try {
      const { upgradeApplicantIdList, rejectApplicantList, emailList } = this.compileEduApplicantData();
      const resultEmails = emailList.join('\n');
      await window.navigator.clipboard.writeText(resultEmails);
      await this.rejectApplicantUpdate(rejectApplicantList);
      await this.proApplicantUpdate(upgradeApplicantIdList);
      await this.app.showToast(`승인: ${upgradeApplicantIdList.length} | 거절: ${rejectApplicantList.length}`);
      await this.totalSetApplicant();
    } catch (e) {
      throw new TooningCompleteUpgradeError(e);
    }
  }

  compileEduApplicantData() {
    const upgradeApplicantIdList = [];
    const emailList = [];
    const rejectApplicantList: RejectEduApplicant[] = [];
    for (let groupApp of this.eduApplicants) {
      const applicants = groupApp.applicants;
      applicants.forEach((applicant) => {
        if (!applicant.dirty) return;
        if (applicant.applyStatus === EduApplicantStatus.complete) {
          upgradeApplicantIdList.push(+applicant.id);
          emailList.push(applicant.tooningEmail);
        } else if (applicant.applyStatus === EduApplicantStatus.rejected) {
          rejectApplicantList.push({ applicantId: +applicant.id, rejectedReason: applicant.rejectedReason });
        }
      });
    }
    return { upgradeApplicantIdList, rejectApplicantList, emailList };
  }

  /**
   * 반려된 신청자들 업데이트
   * @param {RejectEduApplicant[]} applicantList
   * @return {Promise<void>}
   */
  async rejectApplicantUpdate(applicantList: RejectEduApplicant[]) {
    if (applicantList.length <= 0) return;
    await this.eduService.updateRejectForEduApplicants(applicantList).toPromise();
  }

  /**
   *
   * @param {Array<number>} applicantList
   * @return {Promise<void>}
   */
  async proApplicantUpdate(applicantList: Array<number>) {
    if (applicantList.length <= 0) return;
    await this.eduService.updateProForEduApplicants(applicantList).toPromise();
  }

  /**
   * 날짜 오름차순 내림차순으로 정렳해주는 함수
   * @param {Order} order 오름차순 내림차순
   * @return {void}
   */
  sortByDate(order: Order): void {
    this.dateOrder = order;
    let compareFunction;
    if (order === Order.desc) {
      compareFunction = (a, b) => {
        if (a.date > b.date) {
          return -1;
        }
        if (a.date < b.date) {
          return 1;
        }
        // a must be equal to b
        return 0;
      };
    } else {
      compareFunction = (a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        // a must be equal to b
        return 0;
      };
    }
    this.eduApplicants.sort(compareFunction);
  }

  /**
   * 클립보드에 이메일 복사하는 함수
   * @param {string} tooningEmail 복사할 투닝 이메일
   * @return {Promise<void>}
   */
  async copyEmail(tooningEmail: string) {
    await window.navigator.clipboard.writeText(tooningEmail);
    await this.app.showToast(`${tooningEmail}이 복사 되었습니다`);
  }

  /**
   * 서버에서 받아온 데이터를 인터페이스 맞게 세팅해주는 함수
   * @param {GetEduApplicantsListDataAdmin} applicantList
   * @return {void}
   */
  changeFormatApplicant(applicantList: GetEduApplicantsListDataAdmin[]): void {
    try {
      applicantList.forEach((applicant) => {
        applicant.createdDate = moment(applicant.createdDate).format('YYYY-MM-DD');
        applicant.updatedDate = moment(applicant.updatedDate).format('YYYY-MM-DD');
        applicant.filePath = cloudFrontUrl + applicant.authFile;
      });

      const groupEduApplicants = applicantList.reduce((acc, obj) => {
        if (obj.isDuplicate) this.isDuplicate = true;
        const key = obj[this.dateType];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
      const dateKeys = Object.keys(groupEduApplicants);
      for (let date of dateKeys) {
        this.totalCount += groupEduApplicants[date].length;
        groupEduApplicants[date].forEach((applicant: TeacherInfoForAdmin) => (applicant.dirty = false));
        const applicantObj: EduApplicantsGroup = {
          isFold: false,
          date: date,
          applicants: groupEduApplicants[date]
        };
        this.eduApplicants.push(applicantObj);
      }
    } catch (e) {
      throw new TooningChangeFormatApplicantError(e);
    }
  }

  /**
   * 신청 상태 변경, 완료 선택시 beta로 업그레이드
   * @param {EduApplicantStatus} status 상태
   * @param {RejectedReason} rejectReason 반려인 경우 반려 사유
   * @return {Promise<void>}
   */
  async applyStatus(status: EduApplicantStatus, rejectReason?: RejectedReason): Promise<void> {
    if (status === EduApplicantStatus.rejected) {
      if (!rejectReason) {
        await this.app.showToast('취소 사유를 정해주세요');
        return;
      }
      this.currentApplicant.rejectedReason = rejectReason;
    }
    this.currentApplicant.applyStatus = status;
    this.currentApplicant.dirty = true;
  }

  /**
   * 마우스 오버로 발동되는 함수로, 이미지가 너무 작아 마우스 오버하면 팝업을 크게 보여주는 함수
   * @param {TeacherInfoForAdmin} applicant
   * @return {Promise<void>}
   */
  async zoomPopupOpen(applicant: TeacherInfoForAdmin): Promise<void> {
    try {
      if (this.app.popover !== null) return;
      if (this.popoverOpen === true) return;
      this.popoverOpen = true;
      const componentInput = applicant.fileName.includes('pdf') ? { pdfSrc: applicant.filePath } : { imgSrc: applicant.filePath };
      this.app.popover = await this.popoverCtrl.create({
        component: ZoomPopoverComponent,
        componentProps: componentInput,
        showBackdrop: false
      });
      this.app.popover.style.cssText = `--max-width:1080px;--max-height:1080px;--width: 50%;--height: 50%`;
      this.app.popover.onDidDismiss().then(() => {
        this.app.popover = null;
        this.popoverOpen = false;
      });
      await this.app.popover.present();
    } catch (e) {
      throw new TooningZoomPopupOpenError(e);
    }
  }

  /**
   * 카드 안보이게 접어주는 함수(e)
   * @param {boolean} isAll 전체 다 접을 건지
   * @param {boolean} value 접기 여부
   * @param {EduApplicantsGroup} applicantsGroup
   * @return {void}
   */
  changeIsFold(isAll: boolean, value?: boolean, applicantsGroup?: EduApplicantsGroup): void {
    try {
      if (isAll) {
        this.eduApplicants.forEach((applicants) => (applicants.isFold = this.isFoldAll));
      }
      if (applicantsGroup) {
        applicantsGroup.isFold = value;
        if (value !== this.isFoldAll) {
          this.isFoldAll = value;
        }
      }
    } catch (e) {
      throw new TooningChangeIsFoldError(e);
    }
  }
}
