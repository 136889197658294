import { Injectable } from '@angular/core';
import { GraphqlApiService } from '../api/graphql.api.service';
import { gql, FetchResult } from '@apollo/client';
import { tap } from 'rxjs/operators';
import { ModelCreateFaq, ModelGetFaqList, ModelUpdateFaq, ModelGetFaqByIdAndLanguage, ModelGetFaqListForUser } from './faq.service.model';
import { Faq, FaqListPaging } from '../../model/faq/faq';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(private graphql: GraphqlApiService) {}

  /**
   * Faq 생성 mutation
   * @param data {ModelCreateFaq} Faq 생성 데이터
   * @returns {Observable<FetchResult<void>>}
   * */
  public createFaq(data: ModelCreateFaq): Observable<FetchResult<void>> {
    return this.graphql.mutate(
      gql`
        mutation createFaq($data: InputCreateFaq!) {
          createFaq(createFaq: $data)
        }
      `,
      {
        data
      }
    );
  }

  /**
   * Faq 수정 mutation
   * @param data {ModelUpdateFaq} Faq 수정 데이터
   * @returns {Observable<FetchResult<void>>}
   * */
  public updateFaq(data: ModelUpdateFaq): Observable<FetchResult<void>> {
    return this.graphql.mutate(
      gql`
        mutation updateFaq($data: InputUpdateFaq!) {
          updateFaq(updateFaq: $data)
        }
      `,
      {
        data
      }
    );
  }

  public getFaqListForByUser(data: ModelGetFaqListForUser): Observable<FetchResult<FaqListPaging>> {
    return this.graphql
      .query(
        gql`
          query getFaqsForUser($data: InputGetFaqListForUser!) {
            getFaqListForUser(getFaqListForUser: $data) {
              paging {
                totalCount
                currentPageItems
                totalPages
                last
              }
              faqList {
                id
                faqDescription {
                  id
                  question
                }
              }
            }
          }
        `,
        {
          data
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getFaqListForUser;
        })
      );
  }

  /**
   * Faq 목록 조회 query
   * @param data {ModelGetFaqList} Faq 목록 조회 데이터
   * @returns {Observable<FetchResult<Faq[]>>} Faq 목록
   * */
  public getFaqList(data: ModelGetFaqList): Observable<FetchResult<Faq[]>> {
    return this.graphql
      .query(
        gql`
          query getFaqs($data: InputGetFaqList!) {
            getFaqs(getFaqList: $data) {
              id
              order
              serviceType
              faqDescription {
                question
                languageType
                isFrequent
                status
              }
              faqCategory {
                id
                name
              }
              faqCountry {
                isKr
                isJp
                isFr
                isUs
              }
            }
          }
        `,
        {
          data
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getFaqs;
        })
      );
  }

  /**
   * Faq 상세조회
   * @param id {number} Faq id
   * @returns {Observable<FetchResult<Faq | null>>} Faq 상세
   * */
  public faqById(id: number): Observable<FetchResult<Faq | null>> {
    return this.graphql
      .query(
        gql`
          query getFaqById($id: Float!) {
            getFaqById(id: $id) {
              id
              order
              serviceType
              faqDescription {
                id
                question
                answer
                languageType
                searchKeyword
                isFrequent
                status
              }
              faqCategory {
                id
                name
              }
              faqCountry {
                isKr
                isJp
                isFr
                isUs
              }
            }
          }
        `,
        {
          id
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getFaqById;
        })
      );
  }

  /**
   * id와 언어로 Faq 조회 query
   * @param {ModelGetFaqByIdAndLanguage} data
   * @return {Observable<FetchResult<Faq | null>>}
   */
  public faqByIdAndLanguage(data: ModelGetFaqByIdAndLanguage): Observable<FetchResult<Faq | null>> {
    return this.graphql
      .query(
        gql`
          query getFaqByIdAndLanguage($data: InputGetFaqByIdAndLanguage!) {
            getFaqByIdAndLanguage(getFaqByIdAndLanguage: $data) {
              id
              faqCategory {
                id
              }
              faqDescription {
                id
                question
                answer
                isFrequent
              }
            }
          }
        `,
        {
          data
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getFaqByIdAndLanguage;
        })
      );
  }
}
