import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult, gql, FetchResult } from '@apollo/client';
import { map, tap } from 'rxjs/operators';
import { GraphqlApiService } from '../api/graphql.api.service';
import { Point } from '../../model/point/point';

@Injectable({
  providedIn: 'root'
})
export class PointService {
  constructor(public graphql: GraphqlApiService) {}

  /**
   * 페이팔로 포인트 충전 요청 쿼리 함수
   * @param {{userId: number, point: number, bonusPoint: number, paypalOrderId: string, amount: number, pointPriceId: number}} paypalPointData
   * @return {Observable<FetchResult<Point>>}
   */
  public pointChargeWithPaypal(paypalPointData: {
    userId: number;
    point: number;
    bonusPoint: number;
    paypalOrderId: string;
    amount: number;
    pointPriceId: number;
  }): Observable<FetchResult<Point>> {
    return this.graphql
      .mutate(
        gql`
          mutation pointChargeWithPaypal($paypalPointData: InputPaypalPointCharge!) {
            pointChargeWithPaypal(paypalPointData: $paypalPointData) {
              result
              errorCode
              message
            }
          }
        `,
        {
          paypalPointData
        }
      )
      .pipe(
        tap((results) => {
          results.data = results.data.pointChargeWithPaypal;
          return results;
        })
      );
  }

  /**
   * pointCharge 함수는 point 객체를 인자로 받아서 포인트 충전을 수행합니다.
   * @param {object} point - 포인트 충전 정보를 담고 있는 객체입니다.
   * @param {number} point.userId - 포인트를 충전한 사용자의 고유 식별자입니다.
   * @param {number} point.point - 포인트 충전량입니다.
   * @param {number} point.bonusPoint - 포인트 충전 시 사용한 보너스 포인트입니다.
   * @param {number} point.pointPriceId - 포인트 충전 시 사용한 포인트 가격의 고유 식별자입니다.
   * @returns {Observable<FetchResult<Point>>} - 포인트 충전 결과를 담고 있는 Observable 객체입니다.
   */
  public pointCharge(point: { userId: number; point: number; bonusPoint: number; pointPriceId: number }): Observable<FetchResult<Point>> {
    return this.graphql
      .mutate(
        gql`
          mutation pointChargeWithoutThreadPool($pointData: InputPointCharge!) {
            pointChargeWithoutThreadPool(pointData: $pointData)
          }
        `,
        {
          pointData: point
        }
      )
      .pipe(
        tap((results) => {
          results.data = results.data.pointCharge;
        })
      );
  }

  /**
   * @description 포인트 사용 요청 함수, 더 이상 포인트 사용은 스레드 풀을 사용하지 않습니다.
   * https://github.com/toonsquare/tooning-repo/pull/6944
   * @param {{userId: number, point: number}} point
   * @return {Observable<FetchResult<Point>>}
   */
  public pointUse(point: { userId: number; point: number }): Observable<FetchResult<Point>> {
    return this.graphql
      .mutate(
        gql`
          mutation pointUseWithoutThreadPool($pointData: InputPointUse!) {
            pointUseWithoutThreadPool(pointData: $pointData)
          }
        `,
        {
          pointData: point
        }
      )
      .pipe(
        tap((results) => {
          results.data = results.data.pointUse;
          return results;
        })
      );
  }

  public isChargedDoneInOneMinute(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query isChargedDoneInOneMinute($userId: Float!) {
            isChargedDoneInOneMinute(userId: $userId)
          }
        `,
        {
          userId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.isChargedDoneInOneMinute;
          return result;
        })
      );
  }

  public getPointPlan(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getPointPlan {
            getPointPlan {
              point
              bonus
              prices {
                id
                currency
                price
              }
            }
          }
        `
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getPointPlan;
          return result;
        })
      );
  }

  public points(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query points($userId: ID!) {
            points(userId: $userId) {
              sum
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.points;
          return result;
        })
      );
  }

  /**
   * 포인트 지급 함수
   * @param {number} userId
   * @param {number} point
   * @return {Observable<FetchResult<void>>}
   */
  public givePoint(userId: number, point: number): Observable<FetchResult<void>> {
    return this.graphql.mutate(
      gql`
        mutation givePointByAdmin($userId: Float!, $point: Float!) {
          givePointByAdmin(userId: $userId, point: $point)
        }
      `,
      {
        userId,
        point
      }
    );
  }

  /**
   * 아임포트 관련 결제 정보 가져오는 함수
   * @param {number} userId
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getIamportPaymentList(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getIamportPaymentList($userId: ID!) {
            getIamportPaymentList(userId: $userId) {
              pg
              paymentType
              isDefault
              cardNumber
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((result) => {
          result = result.data.getIamportPaymentList;
          return result;
        })
      );
  }

  /**
   * 빌링키 삭제 요청 함수
   * @param {number} userId
   * @param {string} pg 삭제 요청할 결제대행사
   * @param {string} payType 삭제할 빌링키의 결제 타입
   * @return {Observable<FetchResult<Point>>}
   */
  public deleteBillingKeyFromIamport(userId: number, payType: string, isSubscriber?: boolean): Observable<FetchResult<Point>> {
    return this.graphql
      .mutate(
        gql`
          mutation deleteBillingKeyFromIamport($userId: ID!, $payType: String!, $isSubscriber: Boolean) {
            deleteBillingKeyFromIamport(userId: $userId, payType: $payType, isSubscriber: $isSubscriber)
          }
        `,
        {
          userId,
          payType,
          isSubscriber
        }
      )
      .pipe(
        map((result) => {
          result = result.data.deleteBillingKeyFromIamport;
          return result;
        })
      );
  }

  /**
   * 기본 결제 수단 변경 함수
   * @param {number} userId
   * @param {string} targetPaymentType 변경할 기본 결제 수단 결제 타입
   * @return {Observable<FetchResult<Point>>}
   */
  public setDefaultBillingKeyFromIamport(userId: number, targetPaymentType: string): Observable<FetchResult<Point>> {
    return this.graphql
      .mutate(
        gql`
          mutation setDefaultBillingKeyFromIamport($userId: ID!, $targetPaymentType: String!) {
            setDefaultBillingKeyFromIamport(userId: $userId, targetPaymentType: $targetPaymentType)
          }
        `,
        {
          userId,
          targetPaymentType
        }
      )
      .pipe(
        tap((results) => {
          results = results.data.setDefaultBillingKeyFromIamport;
          return results;
        })
      );
  }

  /**
   * 아임포트 빌링키에 구독 결제 예약되어 있는지 확인하는 쿼리 요청 함수
   * @param {number} userId
   * @param {string} paymentType 결제 타입으로 확인함
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public checkIamportSubscriber(userId: number, paymentType: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query checkIamportSubscriber($userId: ID!, $paymentType: String!) {
            checkIamportSubscriber(userId: $userId, paymentType: $paymentType)
          }
        `,
        {
          userId,
          paymentType
        }
      )
      .pipe(
        map((result) => {
          result = result.data.checkIamportSubscriber;
          return result;
        })
      );
  }

  /**
   * 마스킹된 카드 번호를 만들어주는 함수
   * @param {string} cardNumber 마스킹할 카드번호
   * @return {string} 마스킹된 카드번호
   */
  makeMaskingCardNumber(cardNumber: string): string {
    if (!cardNumber) return ''; // 네이버 페이는 등록만 할 경우 카드 번호가 안넘어 온다. 그부분 방어 코드
    const masking = '*';
    const hyphen = '-';
    const firstCardNum = cardNumber.slice(0, 4);
    const secondCardNum = cardNumber.slice(4, 6) + masking + masking;

    const maskingCardNum = firstCardNum + hyphen + secondCardNum + hyphen + '****' + hyphen + '****';
    return maskingCardNum;
  }
}
