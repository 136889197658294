export enum NewsStatus {
  publish = 'publish',
  prepare = 'prepare'
}

export enum ResourceStatus {
  publish = 'publish',
  prepare = 'prepare',
  adminView = 'adminView'
}

export enum CharacterStatus {
  publish = 'publish',
  prepare = 'prepare',
  adminView = 'adminView',
  enterpriseView = 'enterpriseView'
}

export enum UserRole {
  admin = 'admin',
  user = 'user',
  beta = 'beta',
  student = 'student',
  teacher = 'teacher',
  template = 'template',
  textTemplate = 'textTemplate',
  donation = 'donation',
  event = 'event',
  enterprise = 'enterprise',
  demo = 'demo',
  etri = 'etri',
  meme = 'meme',
  test = 'test'
}

export enum AdultVerificationStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export enum EtcUploadType {
  all = 0
}

export enum SourceType {
  item = '소품',
  balloon = '말풍선',
  background = '배경',
  effect = '효과',
  characterKo = '캐릭터',
  character = 'character',
  text = 'text'
}

export enum SourceSetType {
  singleSource = 'singleSource',
  multiSource = 'multiSource',
  setSource = 'setSource'
}

export enum categoryType {
  recent = 'recent',
  purpose = 'purpose',
  subject = 'subject',
  education = 'education',
  meme = 'meme',
  memeResource = 'memeResource'
}

export enum ViewType {
  detail = 'detail',
  search = 'search',
  main = 'main',
  list = 'list',
  integratedSearch = 'integrateSearch',
  move = 'move',
  type = 'type',
  more = 'viewMore'
}

export enum EditType {
  list = 'list',
  edit = 'edit',
  add = 'add'
}

export enum SearchType {
  templateTag = 'templateTag',
  pageTag = 'pageTag',
  all = 'all'
}

export enum ValueType {
  viewType = 'viewType',
  searchText = 'searchText',
  templateId = 'templateId',
  title = 'title',
  integratedSearch = 'integrateSearch'
}

export enum CharacterSourceType {
  face = 'face',
  expression = 'expression',
  body = 'body',
  hand = 'hand',
  head = 'head',
  headAll = 'headAll',
  accessory = 'accessory',
  left_arm = 'left_arm',
  right_arm = 'right_arm',
  leg = 'leg',
  bridge = 'bridge',
  right_hand = 'right_hand',
  left_hand = 'left_hand',
  front_hair = 'front_hair',
  back_hair = 'back_hair',
  beard = 'beard',
  glasses = 'glasses',
  accessary = 'accessary',
  etc = 'etc',
  hair = 'hair'
}

export enum TemplateType {
  basic = 'basic',
  text = 'text'
}

export enum PlatformSize {
  bigSize = 2100,
  mediumSize = 1085,
  smallSize = 600
}

export enum TemplatePlatformSize {
  bigSize = 1600,
  mediumSize = 1024,
  smallSize = 700
}

export enum FuntThumbnail {
  size = 50
}

export enum sliderSize {
  maxSize = 250,
  minSize = 230
}

export enum TemplateCategorySize {
  maxSize = 300,
  minSize = 200
}

export enum TemplateSize {
  maxSize = 220,
  minSize = 100
}

export enum TemplateLineCount {
  maxCount = 8,
  minCount = 2
}

export enum addTemplateCategorySize {
  maxSize = 200,
  minSize = 150
}

export enum EtcSize {
  maxSize = 75,
  minSize = 75
}

export enum LanguageType {
  ko = 'ko',
  en = 'en',
  fr = 'fr',
  jp = 'jp',
  ja = 'ja',
  vi = 'vi',
  all = 'all'
}

export enum LanguageOriginalType {
  ko = '한국어',
  en = 'English',
  fr = 'Français',
  jp = '日本語',
  ja = '日本語'
}

export enum Base64Type {
  koBase64 = 'koBase64',
  enBase64 = 'enBase64',
  frBase64 = 'frBase64',
  jpBase64 = 'jpBase64',
  viBase64 = 'viBase64'
}

export enum EditorElementCount {
  characterBigSizeCount = 12,
  characterSmallCount = 3,
  characterCategoryCount = 3,
  sourceBigSizeCount = 12,
  sourceSmallCount = 4,
  sourceCategoryCount = 4,
  photoSmallCount = 2
}

export enum EditorElementSize {
  characterSize = 200,
  sourceSize = 90
}

export enum EtcuploadStatus {
  open = 'OPEN',
  hide = 'HIDE'
}

export enum DownloadFormat {
  pdf = 'PDF',
  jpg = 'JPG',
  png = 'PNG',
  ppt = 'PPTX',
  gif = 'GIF',
  mp4 = 'MP4',
  mov = 'MOV',
  webp = 'WEBP'
}

export enum MIMEtype {
  jpg = 'image/jpeg',
  png = 'image/png',
  webp = 'image/webp'
}

export enum PrintType {
  web = 'web',
  print = 'print'
}

export enum SideBarType {
  integrateSearch = 'integrateSearch',
  character = 'character',
  item = 'item',
  template = 'template',
  drawing = 'drawing',
  etc = 'etc',
  effect = 'effect',
  bubble = 'bubble',
  text = 'text',
  background = 'background',
  photo = 'photo',
  upload = 'upload',
  diffusion = 'diffusion',
  library = 'library'
}

export enum ZIndex {
  forward = 99,
  back = 0
}

export enum ModalCloseType {
  moveToSignUp,
  canceled,
  findPassword,
  loginSucceed
}

export enum ModeType {
  add = 'add',
  modify = 'modify',
  setWord = 'setWord'
}

export enum WordType {
  integrated = 'integrated',
  image = 'image'
}

export enum VariableName {
  wordList = 'WordList',
  checkList = 'CheckList',
  inputName = 'InputName',
  selectedId = 'SelectedId',
  isAdd = 'IsAdd',
  modeType = 'ModeType'
}

export enum ColorType {
  stroke = 'stroke',
  fill = 'fill',
  character = 'character'
}

export enum PositionType {
  default = 'Default',
  random = 'Random',
  top = 'Top',
  middle = 'Middle',
  bottom = 'Bottom',
  side = 'Side',
  none = 'None',
  etc = 'Etc'
}

export enum ColorManagementType {
  basic = 'default color',
  myColor = 'my color',
  history = 'history'
}

export enum EditType {
  order = 'order',
  delete = 'delete'
}

export enum TextType {
  order = 'order',
  cancel = 'cancel',
  delete = 'delete',
  apply = 'apply',
  orderCancel = 'order cancel'
}

export enum TextStyleType {
  FontFamily = 'fontFamily',
  FontSize = 'fontSize',
  TextAlign = 'textAlign',
  FontWeight = 'fontWeight',
  FontStyle = 'fontStyle',
  Underline = 'underline',
  Fill = 'fill',
  Width = 'width',
  StrokeWidth = 'strokeWidth',
  Stroke = 'stroke',
  Opacity = 'opacity',
  LineHeight = 'lineHeight',
  CharSpacing = 'charSpacing',
  Gradient = 'gradient',
  Shadow = 'shadow'
}

export enum FontStyle {
  Normal = 'normal',
  Italic = 'italic',
  Oblique = 'oblique'
}

export enum FontWeight {
  Bold = 'bold',
  Normal = 'normal'
}

export enum TextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify'
}

export enum ShadowStyle {
  Distance = 'shadowDistance',
  Direction = 'shadowDirection',
  Color = 'shadowColor',
  Blur = 'shadowBlur',
  Opacity = 'shadowOpacity'
}

export enum GradientStyle {
  Angle = 'gradientAngle',
  Offset = 'gradientOffset',
  ColorStop = 'colorStop'
}

export enum TextSelectedStyleType {
  Mix = 'mix',
  StringTrue = 'true',
  StringFalse = 'false'
}

export enum SelectedType {
  largeGroup = 'Group',
  smallGroup = 'group',
  multiSelection = 'multiSelection',
  activeSelection = 'activeSelection',
  unselected = 'unselected',
  singleSelection = 'singleSelection'
}

export enum ResourceType {
  characterSvg = 'character-svg',
  item = 'item', // photo 와 사용자가 업로드한 사진
  photo = 'item',
  effect = 'effect',
  userUpload = 'item',
  itemSvg = 'item-svg',
  text = 'text',
  textbox = 'textbox',
  background = 'background',
  backgroundSvg = 'background-svg',
  guideMask = 'guide_mask',
  waterMark = 'water-mark',
  chatBubbleSvg = 'chatBubble-svg',
  chatBubble = 'chatBubble',
  group = 'group',
  Group = 'Group',
  bgColor = 'bg_color'
}

export enum ResourceName {
  groupName = '그룹화된 툰요소',
  noName = 'noName', //해당 언어 맞게 변경 처리시 사용될 키,
  noNameKr = '이름없는 이미지',
  textBox = '텍스트 박스'
}

export enum ResourceGroupName {
  kr = '그룹화된 툰요소',
  en = 'Grouped elements',
  fr = 'Éléments groupés',
  jp = 'グループ化されたToon要素'
}

export enum PayOption {
  paid = 'paid',
  free = 'free'
}

export enum AuthorType {
  tooning = 'tooning',
  outside = 'outside'
}

export enum SkuType {
  item = 'item', // etc
  balloon = 'balloon', // etc
  background = 'background', // etc
  effect = 'effect', // etc
  character = 'character',
  text = 'text',
  feature = 'feature',
  ai = 'ai' // ai 기능 사용
}

export enum NameType {
  None = 'none',
  Author = 'author'
}

export enum FormatConvert {
  vectorToRaster = 'vectorToRaster',
  rasterToVector = 'rasterToVector'
}

export enum PaypalRequestType {
  subscription,
  order
}

export enum MoreType {
  share = 'share',
  download = 'download',
  resourcePurchase = 'resourcePurchase',
  proUpgrade = 'proUpgrade',
  save = 'save',
  saveNew = 'saveNew',
  saveAs = 'saveAs',
  submitCanvas = 'submitCanvas',
  hotKey = 'hotKey',
  initialization = 'initialization',
  laboratory = 'laboratory',
  setting = 'setting',
  sendBoard = 'sendBoard'
}

export enum BlockType {
  cancel = 'cancel',
  purchase = 'purchase',
  continue = 'continue',
  none = 'none'
}

export enum MaxStorageSize { // Unit is M
  pro = 1000,
  starter = 50
}

export enum CharacterDirection {
  front = 'front',
  back = 'back',
  side = 'side'
}

export enum CountryCode {
  kr = 'kr',
  us = 'us',
  fr = 'fr',
  jp = 'jp',
  ca = 'ca',
  sg = 'sg',
  others = 'others',
  dev = 'dev',
  unknown = 'unknown',
  beta = 'beta',
  local = 'local'
}

export enum Area {
  all = 'all',
  byCountry = 'byCountry'
}

export enum FaqStatus {
  publish = 'publish',
  prepare = 'prepare'
}

export enum Locale {
  ko = 'ko',
  en = 'en',
  fr = 'fr',
  ja = 'ja'
}

export enum Currency {
  KRW = 'KRW',
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  CAD = 'CAD'
}

export enum Step {
  S1,
  S2,
  S3
}

export enum LanguageKey {
  ko = 'name_ko',
  en = 'name_en',
  fr = 'name_fr',
  jp = 'name_jp'
}

export enum LanguageKeyV2 {
  ko = 'ko_name',
  en = 'en_name',
  fr = 'fr_name',
  jp = 'jp_name'
}

export enum LanguageKeyV3 {
  ko = 'nameKo',
  en = 'nameEn',
  fr = 'nameFr',
  jp = 'nameJp'
}

export enum LanguageKeyV4 {
  ko = 'koName',
  en = 'enName',
  fr = 'frName',
  jp = 'jpName',
  vi = 'viName'
}

export enum BillingType {
  CARD,
  PAYPAL
}

export enum Version {
  v1,
  v2
}

export enum SetFontListType {
  init = 'init',
  search = 'search'
}

export enum InputType {
  search = 'search',
  number = 'number',
  textarea = 'textarea',
  text = 'text',
  submit = 'submit'
}

export enum Boundary {
  left = 'left',
  top = 'top',
  right = 'right',
  bottom = 'bottom'
}

export enum Deployment {
  local = 'local',
  dev = 'dev',
  beta = 'beta',
  oper = 'oper'
}

export enum layoutShotName {
  faceShot = 'face_shot',
  bustShot = 'bust_shot',
  mediumShot = 'medium_shot',
  kneeShot = 'knee_shot',
  fullShot = 'full_shot'
}

export enum Emotion {
  angry = 'angry',
  joy = 'joy',
  disgust = 'disgust'
}

export enum characterResourcePartType {
  face = 'face',
  faceEffect = 'face_effect',
  wrinkle = 'wrinkle',
  faceExpression = 'face_expression',
  faceShape = 'face_shape',
  expression = 'expression',
  hand = 'hand',
  head = 'head',
  allHead = 'allHead',
  accessory = 'accessory',
  leftArm = 'left_arm',
  rightArm = 'right_arm',
  leg = 'leg',
  bridge = 'bridge',
  rightHand = 'right_hand',
  leftHand = 'left_hand',
  frontHair = 'front_hair',
  backHair = 'back_hair',
  beard = 'beard',
  glasses = 'glasses',
  accessary = 'accessary',
  accessaryHead = 'accessary_head',
  left = 'left',
  right = 'right',
  etc = 'etc',
  hair = 'hair',
  backHeadAll = 'backHeadAll',
  armLDown = 'armLDown',
  armRDown = 'armRDown',
  bridgeG = 'bridgeG',
  legDown = 'legDown',
  body = 'body',
  legUpFront = 'legUp_Front',
  armLMid = 'armLMid',
  armRMid = 'armRMid',
  headAll = 'headAll',
  legUpSide = 'legUp_Side',
  legUp = 'legUp',
  armLUp = 'armLUp',
  armRUp = 'armRUp'
}

export enum EtcUploadGroup {
  category = 'category',
  type = 'type'
}

export enum droppedType {
  change = 'change'
}

export enum CanvasListWorkingType {
  work = 'work',
  trash = 'trash',
  list = 'list',
  canvasGroup = 'canvasGroup',
  sharedCanvasGroup = 'sharedCanvasGroup',
  rootCanvasGroup = 'rootCanvasGroup'
}

export enum CanvasChecked {
  selectAll = 'SELECT_ALL',
  fullRelease = 'FULL_RELEASE'
}

export enum CanvasTitle {
  titleKo = '제목 없는 디자인',
  titleEn = 'Untitled Design',
  titleJp = 'タイトルなしのデザイン',
  titleFn = 'Conception Sans Titre',
  demo_titleKo = 'demo_제목 없는 디자인',
  demo_titleEn = 'demo_Untitled Design',
  demo_titleJp = 'demo_タイトルなしのデザイン',
  demo_titleFn = 'demo_Conception Sans Titre'
}

export enum CommandType {
  execute = 'execute',
  redo = 'redo',
  undo = 'undo'
}

export enum PromiseStatus {
  fulfilled = 'fulfilled',
  rejected = 'rejected'
}

export enum SocialLoginMessageType {
  popupClosed = 'popupClosed',
  tokenSend = 'tokenSend'
}

export enum BrushType {
  eraser = 'eraser',
  pencil = 'pencil',
  ink = 'ink',
  marker = 'marker',
  spray = 'spray',
  glow = 'glow'
}

export enum DrawingType {
  draw = 'drawing',
  aiSearch = 'aiBackground',
  aiDrawing = 'aiDrawing'
}

export enum ItemGroupStyleType {
  mixed = 'mixed'
}

export enum CursorType {
  default = 'default',
  beforeCatching = 'beforeCatching',
  afterCatching = 'afterCatching',
  colorPicker = 'colorPicker'
}

export enum ImageOptionType {
  opacity = 'opacity',
  blur = 'blur',
  line = 'line',
  shadow = 'shadow',
  brightness = 'brightness',
  contrast = 'contrast',
  saturation = 'saturation',
  rotation = 'rotation'
}

export enum ModeInDrawing {
  handmove = 'handmove',
  colorpick = 'colorpick'
}

export enum FreeUserPageLimit {
  freeUserPageLimit = 10
}

/**
 * 클라이언트 사용자 정보 length 값은, 서버 사용자 데이터 베이스의 length 값과 작던지 같아야한다.
 * user.schema.ts
 *  @Field({ nullable: true, description: '유저 비밀번호' })
 *   @Column({ type: 'varchar', default: null, length: 100, nullable: true })
 *   userPw: string;
 *
 *   @Field({ nullable: true, description: '유저 이름' })
 *   @Column({ type: 'varchar', default: null, length: 50, nullable: true })
 *   userName: string;
 *
 *   @Field({ nullable: true, description: '로그인 할때 사용하는 이메일' })
 *   @Column({ type: 'varchar', default: null, length: 50, nullable: true })
 *   userEmail: string;
 * 서버 쪽 length 와 sync 가 맞아야 한다
 */
export enum CustomUserDefaultMetaData {
  minPasswordLength = 4,
  maxPasswordLength = 20,
  minNameLength = 2,
  maxNameLength = 20,
  maxEmailLength = 50,
  maxVerificationCodeLength = 50,
  maxPhoneNumberLength = 15
}

export enum CanvasSize {
  defaultWidth = 1080,
  defaultHeight = 1080
}

export enum FillType {
  type = 'type',
  string = 'string',
  none = 'none',
  blank = ''
}

export enum Order {
  desc = 'DESC',
  asc = 'ASC'
}

export enum CanvasSort {
  createdDate = 'createdDate',
  updatedDate = 'updatedDate',
  title = 'title'
}

export enum ButtonStyle {
  solid = 'solid',
  clear = 'clear',
  main = 'main',
  medium = 'medium'
}

export enum PrimitiveType {
  object = 'object',
  string = 'string'
}

export enum DefaultLoading {
  fontName = 'Noto Sans'
}

export enum CardPaymentType {
  card = 'card',
  billing = 'billing'
}

export enum ChromeBrowserCheck {
  checked = 'checked'
}

export enum DialogResultType {
  paste = 'paste',
  openModalReorder = 'openModalReorder'
}

export enum ErrorName {
  TimeoutError = 'TimeoutError'
}

export enum ResourceProperty {
  storke = 'stroke',
  strokeWidth = 'strokeWidth'
}

export enum CanvasShareStatusType {
  delete = 'delete',
  notExists = 'not-exists'
}

export enum CustomLoginUserInfoInLocalStorage {
  customLoginUserEmail = 'customLoginUserEmail'
}

export enum HelpEmail {
  default = 'support@tooning.io',
  jp = 'support.jp@tooning.io'
}

export enum AiFilterType {
  edgeDetection = 'edgeDetection',
  animeGan = 'animeGAN',
  removeBackground = 'rembg',
  removeBackgroundAnime = 'rembgAnime'
}

export enum PaymentGateyway {
  toss = 'tosspayments',
  kakaopay = 'kakaopay',
  naverpay = 'naverpay'
}

export enum PayMethod {
  card = 'card',
  kakao = 'kakao',
  naver = 'naver'
}

export enum KeyBoard {
  enter = 'Enter'
}

export enum CropShapeType {
  rect = 'rect',
  circle = 'circle',
  triangle = 'triangle',
  ellipse = 'ellipse'
}

export enum thresholdType {
  low = 'low',
  medium = 'medium',
  high = 'high'
}

export enum StableDiffusionOptions {
  prompt = 'prompt',
  seed = 'seed',
  size = 'size',
  width = 'width',
  height = 'height',
  numbers = 'num_images_per_prompt',
  steps = 'num_inference_steps',
  guidance_scale = 'guidance_scale',
  negative_prompt = 'negative_prompt',
  denoise = 'denoise'
}

export enum TTISize {
  user = 'user',
  square = 'square',
  width = 'width',
  height = 'height',
  longWidth = 'longWidth',
  vertical = 'vertical',
  horizontal = 'horizontal'
}

export enum gaUser {
  pro = 'Pro-Subscriber',
  user = 'User'
}

export enum FontListIdType {
  label = 'label',
  number = 'number'
}

export enum VerificationType {
  email = 'email',
  sms = 'sms'
}

export enum PaymentInfoType {
  normal = 'normal',
  school = 'school',
  teacher = 'teacher',
  enterprise = 'enterprise'
}

export enum EventStatus {
  on = 'on',
  off = 'off'
}

export enum BrowserType {
  safari = 'safari',
  chrome = 'chrome'
}

//// Apollo cache 관련 enum 설명
// cacheFirst:
// 1. 쿼리를 사용할 때 캐시를 확인한다.
// 2. 캐시 데이터가 있으면 이를 반환한다. 없으면 3단계로 넘어간다.
// 3. 서버에 쿼리를 사용해서 데이터를 요청한다.
// 4. 캐시 데이터를 업데이트한다.
// 5. 받아온 데이터를 반환한다.

//   cacheOnly:
// 1. 쿼리를 사용할 때 캐시를 확인한다.
// 2. 캐시 데이터가 있으면 이를 반환하고 없으면 에러를 발생시킨다.

//   cacheAndNetwork:
// 1. 쿼리를 사용할 때 캐시를 확인한다.
// 2. 캐시 데이터가 있으면 일단 이를 반환한다.
// 3. 2단계의 캐시 데이터 유무와 무관하게 서버에 쿼리를 사용해서 데이터를 요청한다.
// 4. 캐시 데이터를 업데이트한다.
// 5. 받아온 데이터를 반환한다.

//   networkOnly:
// 1. 서버에 쿼리를 사용해서 데이터를 요청한다.
// 2. 캐시 데이터를 업데이트한다.
// 3. 받아온 데이터를 반환한다.

//   noCache:
// 1. 서버에 쿼리를 사용해서 데이터를 요청한다.
// 2. 받아온 데이터를 반환한다.

//   standBy = 'standby'
// 1. cache-first와 동일한 놀리로 진행이 되지만, 기본 field 값이 변경 될 때 쿼리가 자동으로 업데이트 되지 않는다는 차이점이 있음

export enum FetchPolicyWatchQuery {
  cacheFirst = 'cache-first',
  cacheOnly = 'cache-only',
  cacheAndNetwork = 'cache-and-network',
  networkOnly = 'network-only',
  noCache = 'no-cache',
  standBy = 'standby'
}

export enum FetchPolicyList {
  cacheFirst = 'cache-first',
  cacheOnly = 'cache-only',
  networkOnly = 'network-only',
  noCache = 'no-cache',
  standBy = 'standby'
}

export enum ObjectTransform {
  move = 'drag',
  rotate = 'rotate',
  skewX = 'skewX',
  skewY = 'skewY',
  scale = 'scale',
  scaleX = 'scaleX',
  scaleY = 'scaleY',
  resizing = 'resizing'
}

export enum ClothType {
  Cloth = 'Cloth',
  Top = 'ClothTop',
  Outer = 'ClothOuter',
  Bottom = 'ClothBottom',
  Shoes = 'ClothShoes',
  Acc = 'ClothAcc',
  Hair = 'ClothHair'
}

export enum ClothTypeName {
  Top = '상의',
  Outer = '겉옷',
  Bottom = '하의',
  Shoes = '신발',
  Acc = '악세사리',
  Hair = '헤어'
}

export enum Pg {
  paypel = 'paypel',
  paypal = 'paypal',
  toss = 'tosspayments',
  kakao = 'kakaopay',
  naver = 'naverpay'
}

export enum PaymentType {
  subscription = 'subscription',
  point = 'point'
}

export enum ViewOrientationEnum {
  vertical = 'vertical',
  horizontal = 'horizontal',
  cut = 'cut',
  presentation = 'presentation'
}

export enum ViewOrientation {
  vertical = 'up and down scroll',
  horizontal = 'horizontal scroll',
  cut = 'four cut cartoon scroll',
  presentation = 'horizontal scroll fullscreen'
}

export enum KobertStatus {
  timeout = 'timeout'
}

export enum SizeUnit {
  px = 'px',
  mm = 'mm',
  cm = 'cm',
  in = 'in'
}

export enum TemplateAddType {
  integratedSearch = 'integratedSearch', // 통합검색에서 추가
  createSearch = 'createSearch', // 템플릿 탭에서 검색 결과에서 추가
  single = 'single', // 템플릿 탭에서 바로 추가
  detailSingle = 'detailSingle', // 템플릿 탭에서 여러장인 템플릿을 클릭해서 들어가서 한 장만 추가
  detailAll = 'detailAll' // 템플릿 탭에서 여러장인 템플릿을 클릭해서 들어가서 전부 추가
}

export enum CanvasSizeInputType {
  width = 'width',
  height = 'height'
}

export enum UserPageViewType {
  login = 'login',
  signup = 'signup',
  loginSocial = 'loginSocial',
  loginCustom = 'loginCustom',
  loginSocialAgreement = 'loginSocialAgreement',
  signupAgreement = 'signupAgreement',
  signupForm = 'signupForm'
}

export enum SignupInputType {
  name = 'name',
  email = 'email',
  password = 'password',
  passwordConfirm = 'passwordConfirm',
  verifyCode = 'verifyCode'
}

export enum GptMessageType {
  question = 'question',
  answer = 'answer'
}

export enum GptChatSettingType {
  delete = 'delete'
}

export enum EstimatePageViewType {
  userForm = 'userForm',
  estimate = 'estimate'
}

export enum CopyTargetId {
  chatBox = 'chat-box'
}

export enum IndexedDBCmd {
  TOONING_COPY_KEY = 'TOONING_COPY_KEY'
}

export enum outsideBGColor {
  white = '#ffffff',
  lightGray = '#eaeaea',
  darkGray = '#969696',
  black = '#000000',
  otherColor = 'undefined'
}

export enum MagicApiType {
  txt2img = 'txt2img',
  img2img = 'img2img',
  inpaint = 'inpaint',
  coloring = 'coloring',
  realtime = 'realtime'
}

export enum MagicCurrentTap {
  none = 'none',
  beginner = 'beginner',
  expert = 'expert',
  style = 'style',
  character = 'character',
  expression = 'expression',
  pose = 'pose',
  hair = 'hair'
}

export enum MagicKeywordSetType {
  add = 'add',
  delete = 'delete'
}

export enum MagicCategoryList {
  character = 'character',
  background = 'background',
  element = 'element',
  generate = 'image generate',
  training = 'style training'
}

export enum MagicModel {
  semiReal = 'semiReal',
  toon = 'toon',
  real = 'real',
  lineart = 'lineart',
  illustration = 'illustration',
  monochrome = 'monochrome'
}

export enum MagicModelSub {
  stella = 'STELLA',
  riley = 'RILEY',
  seri = 'SERI',
  nine = 'NINE',
  sera = 'SERA',
  yuna = 'YUNA',
  mumu = 'MUMU',
  wani = 'WANI',
  ari = 'ARI',
  john = 'JOHN',
  chu = 'CHU',
  kevin = 'KEVIN',
  monica = 'MONICA',
  merrie = 'MERRIE'
}

export enum MagicApiProcess {
  run = 'run',
  runsync = 'runsync',
  health = 'health',
  status = 'status',
  cancel = 'cancel',
  purge = 'purge-queue'
}

export enum MagicViewMode {
  grid = 'grid',
  scroll = 'scroll'
}

export enum EduApplicantStatus {
  pending = 'pending',
  complete = 'complete',
  rejected = 'rejected',
  notApply = 'notApply'
}

export enum RejectedReason {
  duplicated = 'duplicated',
  missSpelling = 'missSpelling'
}

export enum DateType {
  createdDate = 'createdDate',
  updatedDate = 'updatedDate'
}

export enum DomainType {
  Io = 'io',
  Studio = 'studio'
}

export enum ServiceType {
  All = 'all',
  Studio = 'studio',
  LandingMain = 'tooning-landing-main',
  Editor = 'editor',
  Magic = 'magic',
  MagicStudio = 'magic-studio',
  World = 'world',
  Character = 'character',
  Gpt = 'gpt',
  Board = 'board'
}

export enum LoadingType {
  default = 'default',
  magic = 'magic'
}

export enum GreekType {
  online = 'online',
  webtoon = 'webtoon'
}

export enum BoneNameType {
  mixamorig1 = 'mixamorig1_',
  mixamorig2 = 'mixamorig_',
  mixamorig3 = 'mixamorig1',
  mixamorig4 = 'mixamorig'
}

export enum EstimateStep {
  info = 'info', // 정보 입력이 끝나 기본 견적서 발급 준비 완료 단계
  check = 'check', // 견적서를 사용자가 확인한 단계
  issuedAccount = 'issuedAccount', //단체 계정 발급 대기 상태
  payment = 'payment', // 현재 사용 X
  pending = 'pending', // 결제 대기 상태
  complete = 'complete' // 모든 단계가 완료되어 투닝계정이 사용 가능한 단계
}

export enum EduGroupIssuedAccountStep {
  setIssued = 'setIssued',
  setStartDate = 'setStartDate',
  complete = 'complete'
}

export enum AgencyType {
  elementary = 'elementary',
  middle = 'middle',
  high = 'high',
  university = 'university',
  others = 'others'
}

export enum UsedPeriodUnit {
  month = 'month',
  day = 'day',
  year = 'year'
}

export enum XlsxInvalidType {
  valid = 'valid',
  err = 'err'
}

export enum EduPayType {
  card = 'card',
  accountTransfer = 'accountTransfer'
}

//value만 사용 할것
export enum ServerRegion {
  default = 'default',
  ca = 'ca-central-1',
  us = 'us-west-2',
  sg = 'ap-southeast-1',
  kr = 'ap-northeast-2'
}

export enum MagicModelType {
  stableDiffusion = 'SD',
  lora = 'Lora',
  vae = 'Vae'
}

export enum BatchEditPromptType {
  add = 'all add',
  remove = 'all remove',
  modify = 'modify'
}

export enum PromptType {
  prompt = 'prompt',
  negative = 'negative_prompt'
}

export enum MagicStyleAdminViewMode {
  view = 'view',
  reorder = 'reorder',
  delete = 'delete'
}

export enum MagicUploadType {
  style = 'style',
  desc = 'desc'
}

export enum MagicEditorGoButton {
  header = 'header',
  main = 'main',
  bottom = 'bottom'
}

export enum CanvasCheck {
  Single = 'Single',
  Multi = 'Multi'
}
export enum PaymentHistType {
  pointCharge = 'point charge history',
  subscription = 'subscription history'
}

export enum PageAddThrottleTime {
  fast = 100,
  moderate = 500,
  slow = 800
}

export enum GptInfoPage {
  Main = 'main',
  Edu = 'edu',
  Student = 'student',
  Policy = 'policy'
}

/**
 * @description
 * @ProUser 구독중이면, 모두 ProUser 이고,
 * 구독중이 아니더라도,
 *    userRole 이 admin 이거나, ( 구독 중 X )
 *    userRole 이 student 이고, 속한 학교가 active 인 경우, ( 구독 중 X )
 * @EduUser 구독 중이 아니고, userRole 이 beta 인 경우,
 * @FreeUser 구독 중이 아니고, userRole 이 admin, beta 가 아닌 경우, 전부
 **/
export enum UserRoleWithSubscriptionType {
  ProUser = 'ProUser',
  EduUser = 'EduUser',
  FreeUser = 'FreeUser'
}

export enum HtmlElementType {
  Input = 'INPUT',
  Textarea = 'TEXTAREA'
}

export enum MagicEduStyleType {
  Artist = 'artist',
  Preset = 'preset'
}
