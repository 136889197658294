import { Directive, HostListener } from '@angular/core';
import { StringUtil } from '../../shared/utils/string';

@Directive({
  selector: '[appInputInteger]'
})
export class InputIntegerDirective {
  @HostListener('input', ['$event'])
  onInput(event: any) {
    event.target.value = StringUtil.removeNonNumerics(event.target.value);
  }
}
