import { AfterViewChecked, AfterViewInit, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  IonRouterOutlet,
  MenuController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { AppService } from './services/app.service';

// 패러럭스를 위한
import { filter, map, take } from 'rxjs/operators';
import 'jarallax';
import * as Moment from 'moment';

import { ResMakerCharacterService } from './services/res-maker/character.service';
import { Observable } from 'rxjs';
import { ModelCharacterMeta } from './services/res-maker/character.service.model';
import { EachStepTF } from './model/step/eachStepTF';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { ResMakerEtcUloadService } from './services/res-maker/etcUpload.service';
import { EtcUploadEachStepTF } from './model/step/etcUploadEachStepTF';

import { environment } from '../environments/environment';
import { Location } from '@angular/common';
import { ModelEtcUploadMeta } from './services/res-maker/etcUpload.service.model';
import { Cut4MakeManualService } from './pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { PaymentService } from './services/payment/payment.service';
import HttpStatusCode from './pages-tooning/enums/httpErrors.enum';
import { AnalyticsService } from './services/google/analytics/analytics.service';
import {
  ChromeBrowserCheck,
  Deployment,
  DomainType,
  GreekType,
  LanguageOriginalType,
  LanguageType,
  ResourceStatus,
  ServiceType,
  UserRole
} from './enum/app.enum';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from './services/google/message/message.service';
import { TemplateService } from './services/template/template.service';
import {
  TooningAllTabsLoginError,
  TooningallTabsLogoutError,
  TooningAppConstructError,
  TooningAppInitError,
  TooningAppNgAfterViewInit,
  TooningAppPlatformInitError,
  TooningBackButtonError,
  TooningBadRequestError,
  TooningBrowserCheckError,
  TooningCancelPaymentError,
  TooningClientError,
  TooningCustomClientError,
  TooningInitUpdateCheckerError,
  TooningNavigatorError,
  TooningRegisterStorageListenerError,
  TooningResetPaymentDataError,
  TooningRouteEventHandlerError,
  TooningServerConnectionError,
  TooningSetRTLError,
  TooningUnauthorizedError,
  TooningUnkownNetworkError
} from './pages-tooning/errors/TooningErrors';
import { MagicGoExternalError, MagicShowpPreventMagicEditorAlertError } from './pages-tooning/errors/MagicErrors';
import { UpdateService } from './services/update/update.service';
import { IdleTimer } from './idleTimer';
import { ChangeLanguageService } from './services/changeLanguage.service';
import { LoginPage } from './pages-member/login/login.page';
import { FabricjsOverride } from './fabricjs-override';
import { CurrentUser } from './interfaces/app.interface';
import { PaidGuideModalPage } from './pages-common/paid-guide-modal/paid-guide-modal.page';
import { BoardCommonService } from './board/shared/services/board-common.service';

declare let jarallax: any;
const FILE_NAME = 'studio.component.ts';
@Component({
  selector: 'biz-root',
  templateUrl: './biz.component.html',
  styleUrls: ['./biz.component.scss']
})
export class BizComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public MAGIC = 'magic';
  public updatePopUpHeight = 0;
  public languageEnum = LanguageType;
  public data = {
    title: '',
    sidemenu: false,
    sidemenuID: '',
    topMenu: false,
    ResType: '',
    mostChrome: false,
    splitPaneDisabled: false,
    url: '',
    description: '',
    imageUrl: '',
    serviceID: '',
    helpCenter: false
  };
  public isEnabledRTL: boolean;
  public serviceType = ServiceType;
  // menu-service 파일에서 모두 처리합니다.
  // menu-service 파일에서 모두 처리합니다.
  // 캐릭터 생성메뉴 - 항목별 완료 및 발행 여부
  public characterStatus: ModelCharacterMeta;
  public etcUploadMeta: ModelEtcUploadMeta;
  public iconMark = [];
  public exitNumber = 0;
  public stepData: EachStepTF = new EachStepTF();
  public etcUploadStepData: EtcUploadEachStepTF = new EtcUploadEachStepTF();
  public status = ResourceStatus.prepare;
  public stepUrl = [
    {
      '/character-make-basic': 'baseStep',
      '/character-make-face': 'faceStep',
      '/character-make-body': 'bodyStep',
      '/character-make-hand': 'handStep',
      '/character-make-hair': 'hairStep',
      '/character-make-etc': 'etcStep',
      '/character-make-position': 'headPositionStep',
      '/character-make-arm-index': 'armIndexStep',
      '/character-make-leg-index': 'legIndexStep',
      '/character-make-rotate-head': 'headRotateStep',
      '/character-make-rotate-body': 'bodyRotateStep',
      '/character-make-tag-head': 'expressionTagStep',
      '/character-make-color-set': 'colorSetStep',
      '/character-make-intention-body': 'bodyIntentionStep',
      '/character-make-tag-body': 'bodyTagStep',
      '/character-make-default-set': 'defaultSetStep'
    }
  ];
  public basicRecUploadList = [
    {
      iconMark: 0,
      menuName: '소개',
      url: 'character-make-basic'
    },
    {
      iconMark: 1,
      menuName: '얼굴',
      url: 'character-make-face'
    },
    {
      iconMark: 2,
      menuName: '몸, 브릿지, 다리, 팔',
      url: 'character-make-body'
    },
    {
      iconMark: 3,
      menuName: '손',
      url: 'character-make-hand'
    },
    {
      iconMark: 4,
      menuName: '헤어',
      url: 'character-make-hair'
    },
    {
      iconMark: 5,
      menuName: '기타( 안경 , 수염 )',
      url: 'character-make-etc'
    }
  ];
  public basicRecSettingList = [
    {
      iconMark: 6,
      menuName: '몸과 머리 위치 정하기',
      url: 'character-make-position'
    },
    {
      iconMark: 7,
      menuName: '몸과 팔의 Index 정하기',
      url: 'character-make-arm-index'
    },
    {
      iconMark: 8,
      menuName: '몸과 다리의 Index 정하기',
      url: 'character-make-leg-index'
    },
    {
      iconMark: 9,
      menuName: '머리 회전축 설정',
      url: 'character-make-rotate-head'
    },
    {
      iconMark: 10,
      menuName: '몸 회전축 설정',
      url: 'character-make-rotate-body'
    },
    {
      iconMark: 11,
      menuName: '표정 태그 추가',
      url: 'character-make-tag-head'
    },
    {
      iconMark: 12,
      menuName: '칼라 설정',
      url: 'character-make-color-set'
    },
    {
      iconMark: 13,
      menuName: '추천 몸 만들기(인텐션)',
      url: 'character-make-intention-body'
    },
    {
      iconMark: 14,
      menuName: '몸 태그 추가',
      url: 'character-make-tag-body'
    },
    {
      iconMark: 15,
      menuName: '디폴트 캐릭터 설정',
      url: 'character-make-default-set'
    }
  ];
  public basicReceThumbnailList = [
    {
      menuName: '얼굴표정',
      url: 'character-make-face-thumbnail/face_expression'
    },
    {
      menuName: '얼굴효과',
      url: 'character-make-face-thumbnail/face_effect'
    },
    {
      menuName: '주름',
      url: 'character-make-face-thumbnail/wrinkle'
    },
    {
      menuName: '기타',
      url: 'character-make-face-etc-thumbnail'
    }
  ];

  public etcUploadStepUrl = [
    {
      '/etcUpload-make-basic': 'baseStep',
      '/etcUpload-make-list': 'listStep',
      '/etcUpload-make-addOrSet': 'structureStep'
    }
  ];
  public characterId: number;
  public resourceType = this.app.cache.getWorkingEtcUploadType();
  public appVersion;
  public apiServer;
  // public prevUrl;
  public defaultPubl = false;
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  currentUrl = '/';
  rootPage = ['/tooning-landing-main', '/4cut-list', '/']; // exit app
  exitButton = 0;
  public customActionSheetOptions: any = {
    buttons: []
  };
  private isPopUpOpened: boolean = false;
  private isNotifying: boolean = false;
  private confirmSafariUpdate = false;
  private confirmSamsungBrowserUpdate = false;
  private browserCheckConfirmTimeOut = 3000;
  private subscriptions = [];
  public languageOriginalEnum = LanguageOriginalType;
  public exceptionEmail = ['eventktoon@gmail.com', 'tooneed1@gmail.com'];
  private storageListener: EventListener;
  public readyToRender: boolean = false;
  public isDesktopWidth: boolean = false;
  public greekType = GreekType;
  public currentPath: string = '';
  private autoLogoutExceptIds = [266435, 266436, 266437, 266438];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private platform: Platform,
    private location: Location,
    private navController: NavController,
    private toastCtrl: ToastController,
    public sideMenu: MenuController,
    public activatedRoute: ActivatedRoute,
    private titleService: Title,
    public app: AppService,
    public character: ResMakerCharacterService,
    public etcUpload: ResMakerEtcUloadService,
    public cut: Cut4MakeManualService,
    private paymentService: PaymentService,
    private analyticsService: AnalyticsService,
    private messageService: MessageService,
    private translate: TranslateService,
    public templateService: TemplateService,
    private elementRef: ElementRef,
    private updateService: UpdateService,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public actionsheetCtrl: ActionSheetController,
    public changeLanguageService: ChangeLanguageService,
    private ngZone: NgZone,
    public fabricjsOverride: FabricjsOverride,
    private loginPage: LoginPage,
    private metaService: Meta,
    private renderer: Renderer2,
    private board: BoardCommonService
  ) {
    try {
      TooningClientError.setAppService(this.app);
      this.translate.setDefaultLang('ko');
      Moment.locale('ko');
      this.appVersion = environment.appVersion;
      this.apiServer = environment.graphql_url;

      const usedlanguage = this.app.cache.getWorkingLanguage();
      this.app.cache.setWorkingCloudeFront(this.app.cloudfrontStatus);
      this.app.RootServiceType = DomainType.Studio;

      if (!usedlanguage) {
        // 캐쉬에 없으면 디폴트로 설정
        const defaultLanguage = this.app.getDefaultLanguage();

        this.app.cache.setWorkingLanguage(defaultLanguage);
        this.translate.use(defaultLanguage);
        this.app.usedLanguage = defaultLanguage;
      } else {
        this.app.usedLanguage = usedlanguage;
        this.translate.use(usedlanguage);
      }
      this.app.setLanguageKey(this.app.usedLanguage);
      if (this.app.usedLanguage === LanguageType.jp) {
        // ja 기본 css 이므로 jp가 오더라도 ja로 처리
        document.getElementsByTagName('html')[0].lang = LanguageType.ja;
      } else {
        document.getElementsByTagName('html')[0].lang = this.app.usedLanguage;
      }

      this.isEnabledRTL = localStorage.getItem('isEnabledRTL') === 'true';
      this.initializeApp().then((r) => {});
      this.sideMenu.enable(false).then((r) => {});
      this.characterStatus = new ModelCharacterMeta();

      this.registBackKeyEvent();
      this.registerStorageListener();

      this.isDesktopWidth = this.app.isDesktopWidth();
    } catch (e) {
      throw new TooningAppConstructError(e, this.app, true);
    }
  }

  ngOnDestory() {
    console.log('releaseBackKeyEvent');
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.messageService.resetSubscriptions();

    // Remove the event listener when the component/service is destroyed
    if (this.storageListener) {
      window.removeEventListener('storage', this.storageListener);
      this.storageListener = null;
    }
  }

  /** update popup 의 이미지가 로드가 완료 됐을때 실행되며 popup 이미지의 높이를 저장한다.
   *
   * @param event popup 이미지
   */
  updatePopupImageOnload(event): void {
    try {
      const target = event.target;
      this.updatePopUpHeight = target.height;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'updatePopupImageOnload()', this.app, true);
    }
  }

  /**
   * 1. 업데이트 시 popup 을 올리기 위해 status를 주기적으로 확인합니다.
   * 업데이트 완료시 popup 이 닫히며, 웹페이지 새로 갱신
   * 2. 탑바를 보여주기 위해 notificationStatus, dueDate을 주기적으로 확인합니다.
   * @returns {Promise<void>}
   */
  async initUpdateChecker(): Promise<void> {
    this.subscriptions.push(
      this.updateService.checkAnnouncement().valueChanges.subscribe(async ({ data, errors }) => {
        try {
          if (errors) {
            throw errors;
          }
          // 업데이트 중이라는 모달
          this.app.isUpdate = data.checkAnnouncement.status;
          // 공지 관련 노티
          this.app.isNotify = data.checkAnnouncement.notificationStatus;
          // 점검 공지 노티
          this.app.isMaintenanceNotify = data.checkAnnouncement.maintenanceNotificationStatus;
          // 카운트 다운 노티
          this.app.isCountDownNotify = data.checkAnnouncement.countDownNotificationStatus;
          // 탑바 점건 start date
          this.app.startMaintenanceDate = data.checkAnnouncement.startDate;
          // 탑바 점검 due date
          this.app.dueMaintenanceDate = data.checkAnnouncement.dueDate;
          // 탑바 유무
          this.app.isTopbar = this.app.isNotify || this.app.isMaintenanceNotify || this.app.isCountDownNotify;

          // 뒤에 모달이 열려있을경우
          if (this.app.isUpdate && typeof this.app.modal === 'object') {
            try {
              this.app.modal.dismiss();
            } catch (e) {}
          }

          // 탑바를 close 눌렀는지 확인
          this.app.isSessionClose = sessionStorage.getItem('sessionClose') === 'true';
          if (this.app.isTopbar && !this.app.isSessionClose) {
            this.isNotifying = true;
          }

          if (this.app.isUpdate === false && this.isPopUpOpened) {
            window.location.reload();
          }
          if (this.app.isTopbar === false && this.isNotifying) {
            window.location.reload();
          }
        } catch (e) {
          this.app.isUpdate = false;
          this.isNotifying = false;
          throw new TooningInitUpdateCheckerError(e, this.app);
        }
      })
    );
  }

  initNotification() {
    if (this.app.getPlatform() === 'web') {
      this.messageService.requestPermission();
      this.messageService.receiveMessage();
      this.subscriptions.push(
        this.messageService.currentMessage.subscribe((message) => {
          try {
            if (message) {
              this.analyticsService.getNotification();
              console.log(message);
              console.log(message.notification.body);
              this.notification(message.notification.body);
            } else {
              console.log('message is empty');
            }
          } catch (e) {
            console.error(e.message);
          }
        })
      );
    }
  }

  async browserCheck(isMustChrome) {
    try {
      // 크롭 부라우저 체크
      const browser = this.app.isBrowserCheck().toLowerCase();
      this.app.analyticsService.browser(browser);

      console.log(this.app.getDeviceInfo());
      this.app.orange(browser);

      if (isMustChrome) {
        // 데스크탑 && 크롬아니면...
        if (!this.app.isMobile() && browser !== 'chrome') {
          setTimeout(async () => {
            await this.openChromeBrowserDownloadPopup();
          }, this.browserCheckConfirmTimeOut);
        }
      }
    } catch (error) {
      this.app.analyticsService.error('app.component.browserCheck', error.message);
      throw new TooningBrowserCheckError(error, this.app, true);
    }
  }

  async ngOnInit() {
    try {
      this.routeEvent(this.router);

      try {
        await this.initUpdateChecker();
      } catch (e) {
        throw new TooningCustomClientError(e, FILE_NAME, 'ngOninit()', this.app, true);
      }
      this.app.changeLanguageService = this.changeLanguageService;
      // 30분동안 움직임 없으면 로그아웃 시키기
      new IdleTimer({
        timeout: 3600, // expire after 30 min - 3600 * 100 / 30 = (60분) >
        onTimeout: () => {
          this.periodicLogoutProcessing();
        },
        onExpired: () => {
          this.periodicLogoutProcessing();
        }
      });

      // 로션 링크 다운로드
    } catch (e) {
      throw new TooningAppInitError(e, this.app, true);
    } finally {
      this.initNotification();
      this.navigatorNetworkInfo();
    }
  }

  async ngAfterViewInit() {
    try {
      // 투니드 계정 3D 집외관 배경 버튼 보이고 테스트바 보이지 않도록

      const user = this.app.cache.user;
      if (user && this.exceptionEmail.includes(user.userEmail)) this.cut.isTooneed = true;
      this.cut.disableZoom(this.elementRef);

      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.fabricjsOverride.overriddenFabricJs();
        });
      });

      // 패러럭스를 위한
      jarallax(document.querySelectorAll('.jarallax'), {
        speed: 0.2
      });
    } catch (e) {
      throw new TooningAppNgAfterViewInit(e, null, true);
    }
  }

  makeMemoryChecker() {
    const script = document.createElement('script');
    script.onload = function () {
      // @ts-ignore
      const stats = new MemoryStats();

      const elem = stats.domElement;
      elem.style.position = 'fixed';
      elem.style.left = '0px';
      elem.style.bottom = '0px';
      elem.style.zIndex = 100000;

      document.body.appendChild(stats.domElement);

      requestAnimationFrame(function rAFloop() {
        stats.update();
        requestAnimationFrame(rAFloop);
      });
    };

    script.src = 'https://rawgit.com/paulirish/memory-stats.js/master/memory-stats.js';
    document.head.appendChild(script);
  }

  /**
   * 실시간 메모리 체크용 info box
   */
  memoryCheckerStart() {
    const script = document.createElement('script');
    // @ts-ignore
    script.src = this.makeMemoryChecker();
    document.head.appendChild(script);
  }

  ngAfterViewChecked() {
    // 캐릭터 생성메뉴 일때만 상태받기
    if (this.router.url.startsWith('/character-make')) {
      this.characterStatus = this.character.dataMetaCopy;
    }
  }

  /**
   * App에서 백키 누르면 이벤트
   */
  registBackKeyEvent() {
    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(10000, async () => {
        try {
          if (!this.app.isEditorOpen) {
            let toast;
            if (this.app.modal !== null) {
              this.app.modal.dismiss();
              return;
            }
            if (this.app.popover !== null) {
              this.app.popover.dismiss();
              return;
            }
            if (this.rootPage.includes(this.router.url)) {
              this.exitButton++;
              if (this.exitButton === 1) {
                toast = await this.toastCtrl.create({
                  message: this.translate.instant('exit popup title'),
                  duration: 1000,
                  color: 'dark',
                  position: 'bottom',
                  cssClass: 'exit-button'
                });
                toast.present();
                toast.onDidDismiss().then(() => {
                  this.exitButton = 0;
                });
              }
              if (this.exitButton > 1) {
                console.log('will close');
                navigator['app'].exitApp(); // work in ionic 4
              }
            } else if (this.routerOutlet?.canGoBack()) {
              await this.routerOutlet.pop();
            }
          }
        } catch (e) {
          throw new TooningBackButtonError(e, this.app, true);
        }
      })
    );
  }

  setRTL() {
    try {
      document.getElementsByTagName('html')[0].setAttribute('dir', this.isEnabledRTL ? 'rtl' : 'ltr');
    } catch (e) {
      throw new TooningSetRTLError(e, this.app, true);
    }
  }

  /**
   * Ionic platform 이 준비가 되면, RTL 설정, 테마 설정, 캐릭터 데이터 fetch, onesignal 설정, 투닝 업데이트 설정을 수행한다.
   * @return {Promise<void>}
   */
  async initializeApp(): Promise<void> {
    this.platform
      .ready()
      .then(async () => {
        this.setRTL();
        this.isEnabledRTL = false;
        if (this.resourceType === '캐릭터') {
          await this.getCharacterData();
        }

        if (this.app.isApp()) {
          // 푸시 알림 초기화 시작

          // 푸시 알림 초기화 끝

          //onResume onPause
          this.subscriptions.push(
            this.platform.pause.subscribe(() => {
              console.log('pause!!!!!!!');
              this.app.update.next_time = false;
              this.app.cache.setLocal('paused_time', new Date().getTime());
            })
          );

          this.subscriptions.push(
            this.platform.resume.subscribe(async () => {
              console.log('resume!!!!!!!');
              this.app.update.next_time = false;
              // tslint:disable-next-line:variable-name
              const reload_next = this.app.cache.getLocal('reload_next');
              if (reload_next) {
                this.app.cache.setLocal('reload_next', false);
                await this.app.reloadApp();
              } else {
                await this.app.checkUpdate();
              }
            })
          );
        }
      })
      .catch((error) => {
        throw new TooningAppPlatformInitError(error, this.app, true);
      });
    await this.initUserInformation();
  }

  /**
   * 로그인을 했다면, 사용자 정보(권한, 지역 및 언어 정보, 유료 or 무료 회원 구분 등) 을 세팅한다.
   * 언어는 최초에는 브라우져 언어 정보를 기반을 설정, 이후 투닝의 언어 변경이 일어나면, 해당 값을 저장한다
   * 지역 정보는 아이피를 기준으로 분석하여 서버에서 저장한다.
   * 로그인을 안 했다면, 기본 사용자 country 가 세팅된다.
   * @return {Promise<void>}
   */
  async initUserInformation(): Promise<void> {
    try {
      if (this.app.user.isTokenSaved()) {
        this.app.cache.user = await this.app.user.currentUser();
        this.app.isAdmin = this.app.cache.user.role === UserRole.admin;
        if (!this.app.cache.user) {
          throw new Error('token exist but user is empty');
        }
        this.loginPage.userTracking();
        this.app.loginStatus = true;
        //    유료 회원 체크 // 로그인에서도 채크 두군대서 채크해야함.
        this.app.isPaidMember = await this.paymentService.isPaid(this.app.cache.user.id);
        this.app.userRoleWithSubscription = await this.paymentService.getUserRoleWithSubscription();
        this.app.isDonation = this.app.cache.user.role === UserRole.donation;
        this.app.blueLog(this.app.isPaidMember);
      } else {
        this.app.loginStatus = false;
        const result = await this.app.user.getAnonymousUserCountry().toPromise();
        this.app.anonymousCountry = result?.data?.message;
      }
      this.app.greenLog('initializeApp this.app.loginStatus ---- ' + this.app.loginStatus);
    } catch (error) {
      console.error(`app.component : ${error.message}`);

      const networkError = error.networkError;

      if (networkError) {
        switch (networkError.status) {
          case HttpStatusCode.BAD_REQUEST:
            await this.app.showToast(this.translate.instant('bad request'));
            throw new TooningBadRequestError(error.message, this.app, true);
          case HttpStatusCode.UNAUTHORIZED:
            this.app.orange('unauthorized access');
            this.app.redirectLogin();
            throw new TooningUnauthorizedError(error.message, this.app, true);
          case 0:
            throw new TooningServerConnectionError(error.message, this.app, true);
          default:
            this.app.redLog(networkError.message);
            await this.app.showToast(networkError.message);
            throw new TooningUnkownNetworkError(error.message, this.app, true);
        }
      } else {
        throw error;
      }
    }
  }

  async notification(message) {
    const alert = await this.alertCtrl.create({
      header: '알림',
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: '확인',
          handler: () => {
            this.analyticsService.confirmNotification();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * 크롬 브라우저를 사용하지 않을 경우 다운로드를 권장하는 팝업을 실행
   * 최초에 한번만 실행되고 localstorage 에 저장 후 다시는 실행하지 않느다.
   * @returns {Promise<void>}
   */
  async openChromeBrowserDownloadPopup(): Promise<void> {
    try {
      if (this.app.cache.getChromeBrowserCheck() !== ChromeBrowserCheck.checked) {
        const message = this.translate.instant('environment 1 comment 2');
        const cancel = this.translate.instant('cancel');
        const download = this.translate.instant('download');
        const header = this.translate.instant('notice');
        const alert = await this.alertCtrl.create({
          header,
          message,
          backdropDismiss: false,
          buttons: [
            {
              text: cancel,
              role: 'cancel',
              handler: () => {
                console.log('Confirm Cancel');
              }
            },
            {
              text: download,
              handler: () => {
                console.log('Confirm Okay');
                this.app.goExternalLocal('https://www.google.com/intl/ko/chrome/');
              }
            }
          ]
        });
        await alert.present();
        this.app.cache.setChromeBrowserCheck();
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'openChromeBrowserDownloadPopup()', this.app, true);
    }
  }

  async upgradeSafari(): Promise<void> {
    const message = this.translate.instant('environment 1 comment 5');
    const header = this.translate.instant('notice');
    const text = this.translate.instant('header');
    const alert = await this.alertCtrl.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text,
          handler: () => {
            console.log('Confirm Okay');
            this.confirmSafariUpdate = true;
          }
        }
      ]
    });
    await alert.present();
  }

  async upgradeSamsungBrowser(): Promise<void> {
    const message = this.translate.instant('environment 1 comment 6');
    const header = this.translate.instant('notice');
    const text = this.translate.instant('header');
    const alert = await this.alertCtrl.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text,
          handler: () => {
            console.log('Confirm Okay');
            this.confirmSamsungBrowserUpdate = true;
          }
        }
      ]
    });
    await alert.present();
  }

  // 탑 해더 쪽 로그아웃
  async clickLogout(URL, goExternalLocalType = false) {
    await this.app.logout();

    if (goExternalLocalType) {
      this.app.goExternalLocal(URL);
      return;
    }

    const isMagic = this.app.hereUrl.includes(ServiceType.Magic);
    if (isMagic) {
      this.app.go(ServiceType.Magic);
      return;
    }

    const isGpt = this.app.hereUrl.includes(ServiceType.Gpt);
    if (isGpt) {
      this.app.go('/gpt/editor');
      return;
    }

    const isBoard = this.app.hereUrl.includes(ServiceType.Board);
    if (isBoard) {
      this.app.go('/board');
      await this.board.setBoardUser();
      return;
    }

    this.app.go(URL);
  }

  /**
   * RouteEvent 처리
   * @param router
   */
  routeEvent(router: Router) {
    this.subscriptions.push(
      router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map((e) => {
            // @ts-ignore
            this.data.url = e.url.split('?')[0];
            const child = this.activatedRoute.firstChild;

            if (child.snapshot.data['title']) {
              this.data.title = this.translate.instant(child.snapshot.data['title']);
            } else {
              this.data.title = 'Admin';
            }

            if (child.snapshot.data['description']) {
              this.data.description = this.translate.instant(child.snapshot.data['description']);
            } else {
              this.data.description = this.translate.instant('meta.landing.description'); // 랜딩 description 이용
            }

            if (child.snapshot.data['imageUrl']) {
              this.data.imageUrl = child.snapshot.data['imageUrl'];
            } else {
              this.data.imageUrl = 'https://tooning.io/assets/kakao/tooning2.png'; // 랜딩 image 이용
            }

            if (child.snapshot.data['sidemenu']) {
              this.data.sidemenu = child.snapshot.data['sidemenu'];
            } else {
              this.data.sidemenu = false;
            }

            if (child.snapshot.data['serviceID']) {
              this.app.serviceID = child.snapshot.data['serviceID'];
            } else {
              this.app.serviceID = 'tooning';
            }
            if (child.snapshot.data['sidemenuID']) {
              this.data.sidemenuID = child.snapshot.data['sidemenuID'];
            } else {
              this.data.sidemenuID = '';
            }
            if (child.snapshot.data['topMenu']) {
              this.data.topMenu = child.snapshot.data['topMenu'];
            } else {
              this.data.topMenu = false;
            }
            if (child.snapshot.data['topMenu']) {
              this.data.ResType = child.snapshot.data['ResType'];
            } else {
              this.data.ResType = '';
            }
            if (child.snapshot.data['splitPaneDisabled']) {
              this.data.splitPaneDisabled = child.snapshot.data['splitPaneDisabled'];
            } else {
              this.data.splitPaneDisabled = false;
            }

            if (child.snapshot.data['helpCenter']) {
              this.data.helpCenter = true;
            } else {
              this.data.helpCenter = false;
            }
            this.currentPath = child.snapshot.routeConfig.path;

            if (this.data.url.includes('template-list')) {
              this.cut.showSearchButton = true;
              this.cut.showSearchBar = false;
            } else {
              this.cut.showSearchButton = false;
              this.cut.showSearchBar = false;
            }

            // 툰에디터 인지 체크
            if (child.snapshot.data['editor']) {
              this.app.isEditorOpen = child.snapshot.data.editor;
            } else {
              this.app.isEditorOpen = false;
            }

            const faviconSizeList = ['main', '192x192', '96x96', '32x32', '16x16'];
            const appleFaviconSizeList = ['57x57', '60x60', '72x72', '76x76', '114x114', '120x120', '144x144', '152x152', '180x180'];
            this.changeFavicon(faviconSizeList, false);
            this.changeFavicon(appleFaviconSizeList, true);
            this.browserCheck(child.snapshot.data.mostChrome).then((r) => {});
            return this.data;
          })
        )
        .subscribe(async (data: object) => {
          try {
            this.updatePageMetaTags(data);

            // @ts-ignore
            if (data.sidemenu) {
              await this.sideMenu.enable(true, this.data.sidemenuID);
            } else {
              await this.sideMenu.enable(false);
            }

            // @ts-ignore
            const tempHereUrl = decodeURI(data.url);
            this.app.hereUrl = tempHereUrl.replace('/', '');

            //에디터 페이지가 아니면서 데모계정이면 무조건 로그아웃
            //https://github.com/toonsquare/tooning-repo/issues/3323
            if (!this.app.isEditorOpen && this.app.cache.user?.role === UserRole.demo) {
              await this.app.logout(false);
            }

            this.app.loadingAllhide();
            const topModal = await this.modalCtrl.getTop();
            if (topModal) {
              if (topModal.classList?.length >= 2 && topModal.classList[1] != 'modal-go-home') {
                await topModal.dismiss();
              }
            }
            const topPopover = await this.popoverCtrl.getTop();
            if (topPopover) {
              await topPopover.dismiss();
            }
            const topAlert = await this.alertCtrl.getTop();
            if (topAlert) {
              await topAlert.dismiss();
            }
            const topActionsheet = await this.actionsheetCtrl.getTop();
            if (topActionsheet) {
              await topActionsheet.dismiss();
            }

            await this.sideMenu.close();
            await this.app.checkUpdate();
            this.defaultThemeSettings(); // 기본 테마설정
            this.app.currentService = this.app.checkServiceType();

            this.readyToRender = true;
          } catch (e) {
            throw new TooningRouteEventHandlerError(e, this.app, true);
          }
        })
    );
  }

  /**
   * 웹 페이지의 메타 데이터를 업데이트하기 위한 함수
   * 이 함수는 SEO 를 향상 시키기 위해, 페이지의 메타 데이터를 동적으로 변경합니다.
   * @param {object} data
   * @returns {void}
   */
  updatePageMetaTags(data: object): void {
    //@ts-ignore
    this.titleService.setTitle(data.title);

    this.metaService.updateTag({
      property: 'og:title', // @ts-ignore
      content: data.title
    });

    this.metaService.updateTag({
      name: 'twitter:title', // @ts-ignore
      content: data.title
    });

    this.metaService.updateTag({
      name: 'description', // @ts-ignore
      content: data.description
    });

    this.metaService.updateTag({
      property: 'og:description', // @ts-ignore
      content: data.description
    });

    this.metaService.updateTag({
      name: 'twitter:description', // @ts-ignore
      content: data.description
    });

    this.updateCanonicalUrl(window.location.href);
    // @ts-ignore
    this.updateImage(data.imageUrl);
  }

  /**
   * canonical URL 을 업데이트하는 함수입니다.
   * @param {string} url - 새로운 canonical URL 로 설정할 URL
   */
  updateCanonicalUrl(url: string) {
    const head = document.head;
    let canonicalElement = head.querySelector('link[rel="canonical"]');
    if (!canonicalElement) {
      canonicalElement = this.renderer.createElement('link');
      this.renderer.setAttribute(canonicalElement, 'rel', 'canonical');
      this.renderer.appendChild(head, canonicalElement);
    }
    this.renderer.setAttribute(canonicalElement, 'href', url);
  }

  /**
   * Open Graph 이미지와 Twitter image 를 업데이트하는 함수입니다.
   * @param {string} imageUrl - 새로운 이미지 URL
   */
  updateImage(imageUrl: string): void {
    const head = document.head;

    // Update og:image
    const existingOgImage = head.querySelector('meta[property="og:image"]');

    if (existingOgImage) {
      this.renderer.setAttribute(existingOgImage, 'content', imageUrl);
    } else {
      const ogImage = this.renderer.createElement('meta');
      this.renderer.setAttribute(ogImage, 'property', 'og:image');
      this.renderer.setAttribute(ogImage, 'content', imageUrl);
      this.renderer.appendChild(head, ogImage);
    }

    // Update twitter:image
    const existingTwitterImage = head.querySelector('meta[name="twitter:image"]');

    if (existingTwitterImage) {
      this.renderer.setAttribute(existingTwitterImage, 'content', imageUrl);
    } else {
      const twitterImage = this.renderer.createElement('meta');
      this.renderer.setAttribute(twitterImage, 'name', 'twitter:image');
      this.renderer.setAttribute(twitterImage, 'content', imageUrl);
      this.renderer.appendChild(head, twitterImage);
    }
  }

  // 배포상태 변경
  public async publishedFn(event) {
    // 선택중인 리소스 종류에 따라 분기
    const sourceStatus = event;
    switch (this.data.ResType) {
      // 캐릭터
      case 'character-make':
        await this.changeCharacterStatus(sourceStatus);
        break;
      // 배경
      case 'etcUpload-make':
        await this.changeBackgroundStatus(sourceStatus);
        break;
      case 'font-make':
        break;
      case 'template-make':
        await this.changeTemplateStatus();
        break;
      case 'text-template-make':
        await this.changeTextTemplateStatus();
        break;
      // 예외
      default:
        break;
    }
  }

  async changeCharacterStatus(sourceStatus) {
    let isAllStepCheck: boolean;
    const characterId = +this.app.cache.getWorkingCharacter();
    if (this.app.cache.getWorkingCharacter() === null) {
      await this.app.showToast('실행중인 캐릭터의 데이터가 손실되었습니다. 다시 캐릭터를 선택해주세요');
      this.status = ResourceStatus.prepare;
      return;
    }

    const getDataTF = await this.getStepData();
    if (getDataTF === false) {
      console.error('stepData 가져오기를 실패하였습니다');
      await this.app.showToast('stepData 가져오기를 실패하였습니다.');
      return;
    }

    const stepArr: boolean[] = Object.values(this.stepData);
    isAllStepCheck = await this.character.isAllStepChecked(stepArr, characterId);
    if (isAllStepCheck) {
      // 모든 스탭 완료
      // 업로드시도
      const observable: Observable<ApolloQueryResult<any>> = this.character.setPublishState(sourceStatus, characterId);
      this.subscriptions.push(
        observable.subscribe(
          (result) => {
            this.status = sourceStatus;
            let resultMessage: string;
            if (this.status === ResourceStatus.publish) {
              resultMessage = '정상적으로 발행 완료된 캐릭터!! :)';
            } else if (this.status === ResourceStatus.prepare) {
              resultMessage = '준비중인 캐릭터!! :)';
            } else if (this.status === ResourceStatus.adminView) {
              resultMessage = '관리자만 볼 수 있는 캐릭터!! :)';
            }
            this.app.showToast(resultMessage);
          },
          (err) => {
            this.status = ResourceStatus.prepare;
          }
        )
      );
    } else {
      // 모든 스탭 완료되지 않은 상태
      this.status = ResourceStatus.prepare;
      await this.character.setPublishState(this.status, characterId).toPromise();
      await this.app.showToast('모든 스탭을 완료해야 발행할 수 있습니다.');
    }
    return isAllStepCheck;
  }

  async changeBackgroundStatus(sourceStatus) {
    let isAllStepCheck: boolean;
    const getEtcUploadDataTF = await this.getEtcUploadStepData();

    if (getEtcUploadDataTF === false) {
      console.error('stepData 가져오기를 실패하였습니다');
      await this.app.showToast('stepData 가져오기를 실패하였습니다.');
      return;
    }

    const etcUploadStepArr: boolean[] = Object.values(this.etcUploadStepData);
    let obser: Observable<FetchResult<any>>;
    this.etcUploadMeta = new ModelEtcUploadMeta();
    this.etcUploadMeta.id = +this.app.cache.getWorkingEtcUpload();
    isAllStepCheck = await this.character.isAllStepChecked(etcUploadStepArr, this.etcUploadMeta.id);
    if (isAllStepCheck) {
      // 스탭 중 false가 없을 때
      obser = this.etcUpload.updateEtcUploadCategory({ status: sourceStatus });
      let etcResultMessage: string;
      if (this.status === ResourceStatus.publish) {
        etcResultMessage = `정상적으로 발행 완료된 ${this.resourceType}!! :)`;
      } else if (this.status === ResourceStatus.prepare) {
        etcResultMessage = `준비중인 ${this.resourceType}!! :)`;
      } else if (this.status === ResourceStatus.adminView) {
        etcResultMessage = `관리자만 볼 수 있는 ${this.resourceType}!! :)`;
      }
      // 업로드시도
      this.subscriptions.push(
        obser.subscribe(
          (result) => {
            this.app.showToast(etcResultMessage);
            this.status = sourceStatus;
          },
          (err) => {
            this.app.showToast('시스템 오류입니다.');
          }
        )
      );
    } else {
      this.status = ResourceStatus.prepare;
      await this.etcUpload.updateEtcUploadCategory({ status: ResourceStatus.prepare }).toPromise();
      await this.app.showToast('모든 STEP을 진행 완료를 해야 발행을 할 수 있습니다.');
    }
  }

  async changeTemplateStatus() {
    if (this.app.cache.getWorkingTemplate()) {
      try {
        const templateCategoryData = {
          categoryId: +this.app.cache.getWorkingTemplate(),
          status: this.status
        };
        await this.templateService.updateTemplateCategory(templateCategoryData);

        await this.app.showToast(`템플릿 발행 설정 완료`);
      } catch (e) {
        await this.app.showToast(`템플릿 발행 실패 ${e}`);
      }
    } else {
      this.status = ResourceStatus.prepare;
      await this.app.showToast('템플릿 카테고리 먼저 생성해주세요');
    }
  }

  async changeTextTemplateStatus() {
    if (this.app.cache.getWorkingTextTemplate()) {
      try {
        const templateCategoryData = {
          id: +this.app.cache.getWorkingTextTemplate(),
          status: this.status
        };
        await this.templateService.updateTextTemplateCategory(templateCategoryData).toPromise();

        await this.app.showToast(`템플릿 발행 설정 완료`);
      } catch (e) {
        await this.app.showToast(`템플릿 발행 실패 ${e}`);
      }
    } else {
      this.status = ResourceStatus.prepare;
      await this.app.showToast('템플릿 카테고리 먼저 생성해주세요');
    }
  }

  async sideMenuPageGoNocheck(url) {
    console.log(this.activatedRoute.firstChild.snapshot);
    this.app.go(url);
  }

  // 사이드 메뉴 이동 => 스텝 있을 시 스텝 체크 후 이동 컨트롤
  async sideMenuPageGo(url, isNew = false) {
    console.log(url);

    let stepNmArr;
    let stepTFArr;
    let stepNm: string;
    let nextStepNm: string;

    let goPrev = false; // 이전 단계 완료가 안되었을 시 이전으로 갈지말지 여부 결정
    let goNext = true; // 클릭한 목록으로 갈지말지 결정
    const urlSplit = this.router.url.split('-');
    const firstUrlName = urlSplit[0]; // 캐릭터인지, etcUpload인지 파악
    const secondUrlName = urlSplit[1];

    let { firstFalseIndex, stepTF, stepIndex, nextStepIndex, stepUrlArr } = await this.characterSideMenu(url, isNew);
    switch (firstUrlName) {
      case '/character':
        break;
      case '/etcUpload':
        await this.getEtcUploadStepData(); // 각 step TF 받아오기

        stepNmArr = Object.keys(this.etcUploadStepData); // 각 스텝 이름 ( baseStep 등 )d
        stepTFArr = Object.values(this.etcUploadStepData); // 각 스텝 TF
        firstFalseIndex = stepTFArr.indexOf(false); // false인 첫 번째 인덱스 -> 무작위 목록 클릭 시 이전 단계 미완성 시 첫 false step으로 넘어감
        // ;
        if (url === 'etcUpload-make-basic/' + this.activatedRoute.firstChild.snapshot.params['resourcetype'] && isNew === true) {
          this.app.cache.removeWorkingEtcUpload();
          this.status = ResourceStatus.prepare;
          this.app.go(url); // basic 들어갈 시 밑 로직 실행 불필요
          return;
        }

        // 다음 step 넘어갈 시 이전 step 완료 안할 시 못넘어감.
        stepNm = this.etcUploadStepUrl[0]['/' + this.router.url.split('/')[1]]; // 현 stepName get
        stepTF = this.etcUploadStepData[stepNm]; // 현 stepTF
        stepIndex = stepNmArr.indexOf(stepNm); // 현 step index

        if (
          stepNm === 'baseStep' &&
          stepTFArr.length === 0 &&
          url !== 'etcUpload-category-list/' + this.activatedRoute.firstChild.snapshot.params['resourcetype']
        ) {
          await this.app.showToast(`${this.resourceType} 카테고리 생성 후 다음을 진행해주세요.`);
          return;
        }

        nextStepNm = this.etcUploadStepUrl[0]['/' + url.split('/')[0]]; // 다음 step 이름
        nextStepIndex = stepNmArr.indexOf(nextStepNm);

        if (
          stepNm === 'baseStep' &&
          this.app.cache.getWorkingEtcUpload() === null &&
          url !== 'etcUpload-category-list/' + this.activatedRoute.firstChild.snapshot.params['resourcetype']
        ) {
          await this.app.showToast('카테고리 생성 후 다음을 진행해주세요.');
          return;
        }

        stepUrlArr = Object.keys(this.etcUploadStepUrl[0]);
        break;
      case '/school':
        if (this.app.cache.getWorkingSchool() === null) {
          if (url !== 'school-list') {
            await this.app.showToast('학교 생성 후 다음을 진행해주세요.');
            this.app.go('school-make-basic');
            return;
          }
        }
        this.app.go(url);
        break;
      case '/template':
        // template-list 페이지일 경우
        if (secondUrlName.includes('list/home') || secondUrlName.includes('list/back')) {
          this.templateService.defaultCategory = false;
          this.app.go(url);
          break;
        }
        if (secondUrlName.includes('detail')) {
          this.app.go(url);
          break;
        }
        if (this.app.cache.getWorkingTemplate() === null) {
          if (url !== 'template-category-list') {
            await this.app.showToast('템플릿 생성 후 다음을 진행해주세요.');
            this.app.go('template-make-basic');
            return;
          }
        }
        this.app.go(url);
        break;
    }
    if (firstUrlName === '/school') {
      return;
    }
    // 현재 step이 아직 완료 안되었으면 돌아간다.
    // 다음 목록 클릭 url이랑 vaildation false 첫 번째 step index 비교 후 다음이 더 클 경우 첫 falseindex로 돌아가게 한다.
    if (stepTF === false && stepIndex < nextStepIndex) {
      goNext = false;
    }

    if (firstFalseIndex < nextStepIndex && firstFalseIndex > -1) {
      goNext = false;
      goPrev = true;
    }

    const isMagic = this.app.currentService === ServiceType.Magic && url !== '/account-setting';
    await this.moveUrl(goNext, goPrev, url, firstFalseIndex, stepUrlArr, isMagic); // 해당하는 url 이동
  }

  async characterSideMenu(url, isNew) {
    let stepNmArr;
    let stepTFArr;
    let firstFalseIndex;
    let stepNm: string;
    let nextStepNm: string;
    let stepTF: boolean;
    let stepIndex: number;
    let nextStepIndex: number;
    let stepUrlArr;
    let result;

    await this.getStepData(); // 각 step TF 받아오기
    stepNmArr = Object.keys(this.stepData); // 각 스텝 이름 ( baseStep 등 )
    stepTFArr = Object.values(this.stepData); // 각 스텝 TF
    firstFalseIndex = stepTFArr.indexOf(false); // false인 첫 번째 인덱스 -> 무작위 목록 클릭 시 이전 단계 미완성 시 첫 false step으로 넘어감

    // 리스트로 돌아갈때 캐릭터 생성 작업내역 날려버림
    if (url === 'character-list') {
      this.character.clear();
    } else if (url === 'character-make-basic' && isNew === true) {
      this.app.cache.removeWorkingCharacter();
      this.status = ResourceStatus.prepare;
      this.app.go(url); // basic 들어갈 시 밑 로직 실행 불필요
      return;
    }
    // 다음 step 넘어갈 시 이전 step 완료 안할 시 못넘어감.
    stepNm = this.stepUrl[0][this.router.url]; // 현 stepName get
    stepTF = this.stepData[stepNm]; // 현 stepTF
    stepIndex = stepNmArr.indexOf(stepNm); // 현 step index

    nextStepNm = this.stepUrl[0]['/' + url]; // 다음 step 이름
    nextStepIndex = stepNmArr.indexOf(nextStepNm);

    if (stepNm === 'baseStep' && stepTFArr.length === 0 && url !== 'character-list') {
      await this.app.showToast('캐릭터 생성 후 다음을 진행해주세요.');
      this.app.go('/character-list-category');
      return;
    }

    stepUrlArr = Object.keys(this.stepUrl[0]);
    result = { firstFalseIndex, stepTF, stepIndex, nextStepIndex, stepUrlArr };
    return result;
  }

  // 해당 url 이동
  public async moveUrl(goNext, goPrev, url, firstFalseIndex, stepUrlArr, isMagic = false) {
    const sideMenuOpen = await this.sideMenu.isOpen();

    if (goNext) {
      if (sideMenuOpen) {
        this.sideMenu.close().then((r) => {
          if (isMagic) this.app.goQueryParams(url, { ['isMagic']: isMagic });
          else this.app.go(url);
        });
      } else if (!sideMenuOpen) {
        if (isMagic) this.app.goQueryParams(url, { ['isMagic']: isMagic });
        else this.app.go(url);
      }
    } else {
      const alert = await this.alertCtrl.create({
        // header: '스타일이 삭제됩니다!',
        message: `이전 단계 완료 후에 다음 단계를 진행하실 수 있습니다.`,
        buttons: [
          {
            text: 'Okay',
            handler: () => {
              if (goPrev) {
                if (this.activatedRoute.firstChild.snapshot.params['resourcetype']) {
                  this.app.go(stepUrlArr[firstFalseIndex].split('/')[1] + '/' + this.activatedRoute.firstChild.snapshot.params['resourcetype']);
                } else {
                  this.app.go(stepUrlArr[firstFalseIndex].split('/')[1]);
                }
              }
            }
          }
        ]
      });
      await alert.present();
    }
  }

  // 로그인 모달
  async openModalLogin() {
    if (this.app.modal !== null) {
      return;
    }
    this.app.modal = true;
    this.app.modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: {
        isModal: true
      },
      cssClass: 'modal-size-login'
    });

    this.app.modal.onDidDismiss().then(async ({ data }) => {
      this.app.modal = null;
    });
    return await this.app.modal.present();
  }

  // get stepData
  public async getStepData() {
    return new Promise((resolve, reject) => {
      try {
        const characterId = +this.app.cache.getWorkingCharacter();
        this.subscriptions.push(
          this.character.getStepData(characterId).subscribe(
            (result) => {
              const step = result.data.getStepData[0];
              this.stepData = new EachStepTF();
              for (const stepKey in step) {
                this.stepData[stepKey] = step[stepKey];
              }
              resolve(true);
            },
            (err) => {
              console.error(err);
              reject(new Error(err));
            }
          )
        );
      } catch (e) {
        console.error('getStepData Err', e);
        return reject(e);
      }
    });
  }

  // get etcUploadStepData
  public async getEtcUploadStepData() {
    const itemId = +this.app.cache.getWorkingEtcUpload();
    const sourceType = this.app.cache.getWorkingEtcUploadType();

    return new Promise((resolve, reject) => {
      try {
        this.etcUpload
          .getEtcUploadEachStepData(itemId, sourceType)
          .pipe(take(1))
          .subscribe(
            (result) => {
              const step = result.data.getEtcUploadEachStepData;
              for (const stepKey in step) {
                if (stepKey !== 'structureStep') {
                  this.etcUploadStepData[stepKey] = step[stepKey];
                }
              }
              resolve(true);
            },
            (err) => {
              console.error(err);
              reject(new Error(err));
            }
          );
      } catch (e) {
        console.error('getStepData Err', e);
        return reject(e);
      }
    });
  }

  // 캐릭터 데이터 가져오기
  public async getCharacterData() {
    return new Promise((resolve, reject) => {
      try {
        if (+this.app.cache.getWorkingCharacter() !== 0) {
          this.subscriptions.push(
            this.character.import(+this.app.cache.getWorkingCharacter()).subscribe(({ data, errors, loading, networkStatus }) => {
              if (data) {
                if (data.character !== null) {
                  this.status = data.character.status;
                }
              }
              resolve(true);
            })
          );
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  onSplitPaneVisible($event) {
    console.log('onSplitPaneVisible');
    console.log($event.detail.visible);
    this.app.isSplitPane = $event.detail.visible;
  }

  // 투닝 사이드 메뉴 관련함수
  async MenuGoHome() {
    try {
      if (this.app.loginStatus) {
        await this.sideMenuPageGo('4cut-list');
      } else {
        await this.sideMenuPageGo('tooning-landing-main');
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'MenuGoHome()', this.app, true);
    }
  }

  async changeLanguage(language) {
    try {
      if (this.app.cache.getWorkingLanguage() !== language) {
        this.analyticsService.changeLanguage(language);
      }
      this.app.cache.setWorkingLanguage(language);
      this.app.usedLanguage = language;

      if (this.app.usedLanguage === LanguageType.jp) {
        document.getElementsByTagName('html')[0].lang = 'ja';
      } else {
        document.getElementsByTagName('html')[0].lang = this.app.usedLanguage;
      }

      await this.translate.use(language).toPromise(); // return 값은 JSON 나옴
      this.app.setLanguageKey(this.app.usedLanguage);

      if (this.router.url.includes('/template-list/')) {
        this.templateService.isLanguageChange = true;
        this.templateService.defaultCategory = false;
        this.templateService.templateSearch$.next({ skip: 0 });
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'changeLanguage()', this.app, true);
    }
  }

  /**
   * 구독 페이지 이동 함수 (로그인이 안되어 있으면 로그인 페이지로 이동)
   * @return {Promise<void>}
   */
  async moveToCardRegistration() {
    if (this.app.loginStatus) {
      await this.openModalPaidGuide();
    } else {
      await this.sideMenuPageGo('login');
    }
  }

  async characterCategoryGo() {
    try {
      const categoryId = window.sessionStorage.getItem('character-category-ID');
      await this.sideMenuPageGo('/character-category-view/' + categoryId, true);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'characterCategoryGo()', this.app, true);
    }
  }

  /**
   * 구독 장려 페이지 모달 오픈 함수
   * @return {Promise<any>}
   */
  async openModalPaidGuide() {
    if (this.app.modal !== null) {
      return;
    }
    this.app.modal = true;
    this.app.modal = await this.modalCtrl.create({
      component: PaidGuideModalPage,
      cssClass: 'modal-size-pay-guide',
      componentProps: {
        isModal: true
      }
    });
    this.app.modal.onDidDismiss().then(async (data) => {
      this.app.modal = null;
    });
    return await this.app.modal.present();
  }

  /**
   * 모바일 햄버거 메뉴 닫을 때 채널 톡 오픈용 함수
   * @param {boolean} type
   * @returns {void}
   */
  channelIsOpen(type: boolean): void {
    try {
      if (type === true) {
        if (['/help-detail', '/magic', '/4cut-list', '/gpt/editor', '/board'].includes(this.router.url)) {
          return;
        }
        this.app.channelTalkShow();
      } else {
        this.app.channelTalkHide();
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'channelIsOpen()', this.app, true);
    }
  }

  /**
   * 로그인 후 30분 동안 마우스 터치가 없을 경우 로그아웃 처리
   */
  private async periodicLogoutProcessing() {
    if (this.app.isAdmin || this.cut.userRole === UserRole.etri) {
      return; //관리자, 에트리면 제외
    }

    if (this.app.cache.user) {
      if (this.app.cache.user.id && this.app.cache.user.id in this.autoLogoutExceptIds) {
        return;
      }
    }
    if (this.app.loginStatus) {
      console.log('1시간 간격으로 로그아웃 처리');
      await this.clickLogout('/tooning-landing-main', true);
    }
  }

  /**
   * 아임포트 관련 결제를 모두 취소하는 버튼, 테스트용
   * @return {Promise<void>}
   */
  async cancelPayment(): Promise<void> {
    try {
      const requestData = await this.paymentService.cancelPaymentUsingIamport(+this.app.cache.user.id).toPromise();
      const responseResult = requestData.data.cancelPaymentUsingIamport;
      if (responseResult) {
        await this.app.showToast('결제 최소 완료, 내역 확인 후 문제 있으면 폴한테 말씀해주세요');
      } else {
        await this.app.showToast('결제 취소 실패 폴한테 문의 주세요', 3000, 'danger');
      }
    } catch (e) {
      throw new TooningCancelPaymentError(e);
    }
  }

  /**
   * 구독, 포인트, 결제 내역 초기화 버튼, 테스트용
   * @return {Promise<void>}
   */
  async resetPaymentData(): Promise<void> {
    try {
      const requestData = await this.paymentService.resetRelatedPayment(+this.app.cache.user.id).toPromise();
      const responseData = requestData.data.resetRelatedPayment;
      if (responseData) {
        await this.app.showToast('모든 데이터가 구독하기 전, 포인투 충전 전, 리소스 구매 전으로 돌아갔습니다. 테스트 감사합니다');
      } else {
        await this.app.showToast('데이터 초기화 실패 폴한테 문의 주세요', 3000, 'danger');
      }
    } catch (e) {
      throw new TooningResetPaymentDataError(e);
    }
  }

  /**
   * 메인 페이지 도달 하였을 때 사용자의 네트워크 상태
   *  @return <void>
   */
  navigatorNetworkInfo(): void {
    try {
      // @ts-ignore
      const { effectiveType, rtt, downlink, saveData } = navigator.connection;

      this.analyticsService.networkInfo(effectiveType, rtt, downlink, saveData);
    } catch (e) {
      throw new TooningNavigatorError(e);
    }
  }

  /**
   * 하나의 브라우저 탭에서 로그아웃 하면 모든 투닝 텝 로그아웃 후 홈으로 이동
   * @returns {Promise<void>}
   */
  async allTooningTabsLogout(): Promise<void> {
    try {
      await this.app.logout();
      this.app.go('/');
    } catch (e) {
      throw new TooningallTabsLogoutError(e, null, true);
    }
  }

  /**
   * 하나의 브라우저 탭에서 로그인 하면 모든 투닝 텝 로그인
   * @param newUser localStorage에 user정보가 바뀌었을 때 정보.
   * @param oldValue 새로운 User정보가 들어오기 이전 정보. oldValue가  1. null이면 새롭게 로그인 , 2. 이전 값이 있으면 다른 계정으로 로그인
   * @returns {Promise<void>}
   */
  async allTooningTabsLogin(newUser: CurrentUser, oldValue: any): Promise<void> {
    try {
      const kind = newUser.loginType;
      const token = window.localStorage.getItem('token');
      const oldUserRole = oldValue ? JSON.parse(oldValue).role : null;

      await this.loginPage.setCacheAfterLogin(token, kind);
      await this.cut.goUrl(oldUserRole === UserRole.demo, newUser.id, this.cut.newCanvasId);

      if (this.app.modal !== null) {
        await this.loginPage.close();
      }
    } catch (e) {
      throw new TooningAllTabsLoginError(e, null, true);
    }
  }

  /**
   * localStorage event를 listen하는 listener
   * @return <void>
   */
  async registerStorageListener(): Promise<void> {
    try {
      // Register the event listener only if it has not been registered before
      if (!this.storageListener) {
        this.storageListener = (event: StorageEvent) => {
          const isLocalStorage = event.storageArea === localStorage;
          const isUserModified = event.key === 'user';
          // Check if the key being updated is the user data key
          if (isLocalStorage && isUserModified) {
            const newUser: CurrentUser | null = JSON.parse(event.newValue);
            // Check if the user data has been removed (i.e. user has logged out)
            // newUser === null이면 다른 텝에서 로그아웃 ( O )
            if (!newUser) {
              this.allTooningTabsLogout();
            } else if (event.newValue !== event.oldValue && newUser.role !== UserRole.demo) {
              this.allTooningTabsLogin(newUser, event.oldValue);
            }
          }
        };
        window.addEventListener('storage', this.storageListener);
      }
    } catch (e) {
      throw new TooningRegisterStorageListenerError(e, null, true);
    }
  }

  /**
   * 사이트 기본 테마 설정(스킨
   */
  defaultThemeSettings() {
    // MDB 테마설정
    document.body.removeAttribute('class');

    const isMagic = this.activatedRoute.snapshot.queryParamMap.get('isMagic');
    if (this.app.serviceID === ServiceType.Magic || isMagic) {
      document.documentElement.setAttribute('service-theme', ServiceType.Magic);
    } else if (this.app.serviceID === ServiceType.Gpt) {
      document.documentElement.setAttribute('service-theme', ServiceType.Gpt);
    } else if (this.app.serviceID === ServiceType.Board) {
      document.documentElement.setAttribute('service-theme', ServiceType.Board);
    } else {
      document.documentElement.setAttribute('service-theme', ServiceType.Editor);
    }

    document.body.classList.add('default-font-setting');
  }

  /**
   * 모바일은 지원안하다는 알럿 띄우기
   * @return {Promise<void>}
   */
  async showpPreventMagicEditorAlert(): Promise<void> {
    try {
      const alert = await this.alertCtrl.create({
        cssClass: 'basic-dialog',
        header: this.translate.instant('notice'),
        message: this.translate.instant('magic-landing.alert.text1'),
        buttons: [
          {
            text: this.translate.instant('confirm'),
            handler: async () => {
              await alert.dismiss();
            }
          }
        ]
      });
      await alert.present();
      return;
    } catch (e) {
      throw new MagicShowpPreventMagicEditorAlertError(e, this.app, true);
    }
  }

  /**
   * 그리스 신화 작품 이동
   * @param {GreekType.online | GreekType.webtoon} linkType
   */
  goToMagicWorkIntro(linkType: GreekType.online | GreekType.webtoon) {
    try {
      switch (linkType) {
        case GreekType.online:
          this.app.goExternal('https://greek.tooning.io/');
          break;
        case GreekType.webtoon:
          this.app.goExternal('https://greek.tooning.io/webtoon');
          break;
      }
    } catch (e) {
      throw new MagicGoExternalError(e);
    }
  }

  goToMagicPayPlan() {
    try {
      if (this.app.usedLanguage === LanguageType.ko) {
        this.app.goExternal('https://magic-info.tooning.io/pricing-kr');
      } else {
        this.app.goExternal('https://magic-info.tooning.io/pricing-en');
      }
    } catch (e) {
      throw new MagicGoExternalError(e);
    }
  }

  changeFavicon(sizeList: Array<string>, isApple: boolean) {
    sizeList.forEach((size) => {
      const id = isApple ? `#apple-icon-${size}` : `#favicon-${size}`;
      let linkElement = document.querySelector(id) as HTMLLinkElement;
      const extensions = size === 'main' ? 'svg' : 'png';
      if (this.app.serviceID === 'magic') {
        linkElement.href = `assets/favicon/favicon-magic-${size}.${extensions}`;
      } else if (this.app.serviceID === ServiceType.Gpt) {
        linkElement.href = `assets/favicon/favicon-gpt-${size}.${extensions}`;
      } else if (this.app.isBoard()) {
        linkElement.href = `assets/favicon/favicon-board-${size}.${extensions}`;
      } else {
        linkElement.href = `assets/favicon/favicon-editor-${size}.${extensions}`;
      }
    });
  }
}
