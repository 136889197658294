import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleLogoComponent } from './title-logo/title-logo.component';
import { IonicModule } from '@ionic/angular';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { FabButtonComponent } from './fab-button/fab-button.component';
import { NoItemComponent } from './no-item/no-item.component';
import { UpdateComponent } from './update/update.component';
import { PaidReferralComponent } from './paid-referral/paid-referral.component';
import { TranslateModule } from '@ngx-translate/core';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { CreateTextTemplateComponent } from './create-text-template/create-text-template.component';
import { PointChargeComponent } from './point-charge/point-charge.component';
import { PaypalButtonComponent } from './paypal-button/paypal-button.component';
import { InputModule } from '../directive/input/input.module';
import { MatLegacyRippleModule } from '@angular/material/legacy-core';
import { IamportComponent } from './iamport-component/iamport-component';
import { CardCompanySetComponent } from '../pages-tooning/components/card-company-set/card-company-set.component';
import { NotificationComponent } from './notification/notification.component';
import { ChargeDefaultPaymentComponent } from './chage-default-payment/charge-default-payment.component';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { UpgradeProForEduComponent } from '../pages-member/upgrade-pro-for-edu/upgrade-pro-for-edu.component';
import { DisabledDirectiveModule } from '../directive/disabled/disabled.directive.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ZoomPopoverComponent } from '../pages-member/zoom-popover/zoom-popover.component';
import { AddEduGroupPriceComponent } from '../pages-member/add-edu-group-price/add-edu-group-price.component';
import { HelpCenterSupportComponent } from './help-center-support/help-center-support.component';
import { HelpCenterButtonComponent } from './help-center-button/help-center-button.component';
import { HelpCenterSearchModalComponent } from './help-center-search-modal/help-center-search-modal.component';
import { HelpCenterWebComponentComponent } from './help-center-button/help-center-web-component/help-center-web-component.component';
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
import { TooningTooltipDirective } from '../directive/tooltip/tooning-tooltip.directive';

@NgModule({
  declarations: [
    PointChargeComponent,
    UpdateComponent,
    TitleLogoComponent,
    ProfileComponent,
    FabButtonComponent,
    NoItemComponent,
    PaidReferralComponent,
    CreateTemplateComponent,
    CreateTextTemplateComponent,
    PaypalButtonComponent,
    IamportComponent,
    CardCompanySetComponent,
    NotificationComponent,
    ChargeDefaultPaymentComponent,
    UpgradeProForEduComponent,
    ZoomPopoverComponent,
    AddEduGroupPriceComponent,
    HelpCenterSupportComponent,
    HelpCenterButtonComponent,
    HelpCenterSearchModalComponent,
    HelpCenterWebComponentComponent,
    TooningTooltipDirective
  ],
  imports: [
    CommonModule,
    IonicModule,

    FormsModule,
    MatLegacySlideToggleModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    TranslateModule,
    MatLegacyInputModule,
    ReactiveFormsModule,
    InputModule,
    MatLegacyRippleModule,
    MatLegacyMenuModule,
    DisabledDirectiveModule,
    PdfViewerModule,
    SortablejsModule
  ],
  exports: [
    PointChargeComponent,
    UpdateComponent,
    TitleLogoComponent,
    ProfileComponent,
    FabButtonComponent,
    NoItemComponent,
    PaidReferralComponent,
    CreateTemplateComponent,
    CreateTextTemplateComponent,
    PaypalButtonComponent,
    IamportComponent,
    NotificationComponent,
    ChargeDefaultPaymentComponent,
    UpgradeProForEduComponent,
    HelpCenterSupportComponent,
    HelpCenterButtonComponent,
    TooningTooltipDirective
  ],
  entryComponents: [PointChargeComponent, PaidReferralComponent, CreateTemplateComponent, CreateTextTemplateComponent, IamportComponent]
})
export class ComponentsModule {}
