export enum NetworkStatus {
  'offline' = 'offline',
  'verySlow' = 'verySlow',
  'slow' = 'slow',
  'average' = 'average',
  'fast' = 'fast',
  'veryFast' = 'veryFast',
  'online' = 'online',
  'timeOut' = 'timeOut'
}
