<div *ngIf='isOpenWebSearchComponent && app.isDesktopWidth()'>
  <app-help-center-web-component></app-help-center-web-component>
</div>

<ion-fab
  [style.bottom.px]='app.isDesktopWidth() ? 30 : 20'
  [style.right.px]='app.isDesktopWidth() ? 20 : 10'
  horizontal='end'
  slot='fixed'
  vertical='bottom'
  class='help-center-fab-button'
  *ngIf='!app.isOpenSideMenu'
>
  <div class='tooltip-text' [style.width.px]='longLanguageList.includes(app.usedLanguage) ? 80 : 56'>
    {{ 'help-center.need-help' | translate }}
  </div>
  <ion-fab-button
    [style.height.px]='app.isDesktopWidth() ? 48 : 44'
    [style.width.px]='app.isDesktopWidth() ? 48 : 44'
    class='fab-button-theme-gradation'
    (click)='clickHelpCenterButton()'
  >
    <ion-icon *ngIf='app.isDesktopWidth()' [name]='isOpenWebSearchComponent ? "close-outline" : "help-outline"'></ion-icon>
    <ion-icon *ngIf='!app.isDesktopWidth()' [name]='isOpenMobileSearchModal ? "close-outline" : "help-outline"'></ion-icon>
  </ion-fab-button>
</ion-fab>