import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class GoogleUser {
  @IsString() @IsNotEmpty() id: string;
  @IsString() @IsNotEmpty() displayName: string;
  @IsString() @IsNotEmpty() email: string;
  @IsString() @IsOptional() phoneNumber?: string;
  @IsString() @IsOptional() photoURL?: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
