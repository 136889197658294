import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Loading } from '../../../../services/loading';
import { AppService } from '../../../../services/app.service';
import { TemplateService } from '../../../../services/template/template.service';
import { IonSlides, ModalController, PopoverController } from '@ionic/angular';
import { CanvasService } from '../../../../services/canvas.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { PaidReferralComponent } from '../../../../components/paid-referral/paid-referral.component';
import { BasePage } from '../../../../base-page';
import { Cut4MakeManualService } from '../../../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { PopoverLinkcopyComponent } from '../../popover/popover-linkcopy/popover-linkcopy.component';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { TooningDemoDummyLoginError, TooningDemoEditTemplateError, TooningEditTemplateError } from '../../../errors/TooningErrors';
import { AnalyticsService } from '../../../../services/google/analytics/analytics.service';

@Component({
  selector: 'app-modal-template-view',
  templateUrl: './modal-template-view.component.html',
  styleUrls: ['./modal-template-view.component.scss']
})
export class ModalTemplateViewComponent extends BasePage implements OnInit {
  // @ts-ignore
  @ViewChild('slides') slides: IonSlides;
  // @ts-ignore
  // @ViewChild('content') private content: any;
  @Input('templateId') templateId;
  public readyToRender = false;
  public loading = new Loading();
  public tagList;
  public languageType;
  public canvasSize;
  public canvasId;
  public canvasNameKo;
  public templateData;
  public slidesIndex = 0;
  public pageListbase64 = [];
  public slideOpts: any;
  public isEditTemplateClicking = false;
  private popstateObservable$: Observable<Event>;
  private popstateSubscription$: Subscription;

  constructor(
    private location: Location,
    public app: AppService,
    public templateService: TemplateService,
    public modalCtrl: ModalController,
    public cut: Cut4MakeManualService,
    public popoverController: PopoverController,
    public canvasService: CanvasService,
    public dialog: MatLegacyDialog,
    private translate: TranslateService,
    public analyticsService: AnalyticsService
  ) {
    super(app);
  }

  // tslint:disable-next-line:variable-name
  async popstateHandler($event: any) {
    this.app.modal.dismiss();
  }

  ngOnInit() {
    super.ngOnInit();
    window.history.pushState({ modal: true }, null);
    this.popstateObservable$ = fromEvent(window, 'popstate');
    this.popstateSubscription$ = this.popstateObservable$.subscribe(async (event) => {
      await this.popstateHandler(event);
    });
  }

  async ionViewDidEnter() {
    this.app.setLanguageKey(this.app.usedLanguage);
    super.ionViewDidEnter();
    this.readyToRender = false;

    this.slideOpts = {
      initialSlide: 0,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };

    await this.getTemplateData();
    this.readyToRender = true;
  }

  async ionViewWillLeave() {
    if (this.popstateSubscription$) {
      this.popstateSubscription$.unsubscribe();
    }
    this.app.channelTalkShow();
  }

  /**
   * 템플릿 데이터 가져오기
   */
  async getTemplateData() {
    try {
      await this.loading.showLoader('');

      const { data } = await this.templateService.getTemplate(this.templateId).toPromise();
      this.languageType = data.language;
      this.canvasId = data.canvas.id;
      if (data.canvas.canvasSize) {
        const parseSize = JSON.parse(data.canvas.canvasSize);
        this.canvasSize = parseSize;
        if (parseSize.id === 'custom') {
          this.canvasSize.size = `${Math.round(parseSize.w * parseSize.web)} x ${Math.round(parseSize.h * parseSize.web)}px`;
        } else {
          this.canvasSize.size = parseSize.size;
        }
      } else {
        this.canvasSize = this.cut.canvasSizeNull;
      }
      this.templateData = data;
      this.tagList = data.tags;

      // 페이지 썸네일 리스트 get
      for (const page of data.canvas.pages) {
        this.pageListbase64.push(page.base64);
      }
    } catch (e) {
      console.error(e);
      await this.app.showToast('템플릿 데이터를 가져오지 못했습니다. 관리자에게 문의해주세요 :)');
    } finally {
      this.loading.hideLoader();
    }
  }

  /**
   * 템플릿 편집툴로 열기
   * @param {string} canvasId 템플릿의 캔버스 ID
   * @param {number} canvasPages 템플릿의 캔버스 페이지 수
   * @param {string} canvasInfo 템플릿의 캔버스 이름 + 사이즈
   * @return {Promise}
   */
  async editTemplate(canvasId: string, canvasPages: number, canvasInfo: string): Promise<any> {
    try {
      if (this.app.user.isTokenSaved()) {
        try {
          let getCanvasesLength: number;
          if (this.isEditTemplateClicking) {
            return;
          }
          this.isEditTemplateClicking = true;
          // 무료 사용자인 경우
          if (!this.app.isPaidMember) {
            // 캔버스 수 체크
            const { data } = await this.canvasService.canvasesCount(+this.app.cache.user.id).toPromise();
            getCanvasesLength = data;

            if (getCanvasesLength >= this.cut.freeUserCanvasLimit) {
              // 캔버스 개수 초과
              this.showPaidReferralComponent('3');
              return;
            } else if (canvasPages > this.cut.freeUserPageLimit) {
              // 페이지 10개 초과
              this.showPaidReferralComponent('2');
              return;
            }
          }
          // 템플릿 편집
          const { data } = await this.canvasService.canvasClone(+canvasId, +this.app.cache.user.id, false);
          if (data.result) {
            this.isEditTemplateClicking = false;
            this.app.go(`/4cut-make-manual2/${+data.canvasId}`);
          }
        } catch (e) {
          this.app.go('tooning-landing-main');
          console.error(e);
          throw new Error(`canvas 복제 실패`);
        } finally {
          await this.modalCtrl.dismiss();
        }
      } else {
        await this.modalCtrl.dismiss();
        this.app.go(`login`);
      }
    } catch (e) {
      throw new TooningEditTemplateError(e.message, this.app, true);
    } finally {
      this.analyticsService.templateSiteSelect(canvasInfo);
    }
  }

  showPaidReferralComponent(messageKey: string): void {
    const dialogRef = this.dialog.open(PaidReferralComponent, {
      width: this.app.isDesktopWidth() ? this.cut.paidPopupWidthForDesktop : this.cut.paidPopupWidthForMobile,
      height: this.app.isDesktopWidth() ? this.cut.paidPopupHeightForDesktop : this.cut.paidPopupHeightForMobile,
      data: {
        message: this.translate.instant(messageKey)
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isEditTemplateClicking = false;
    });
  }
  /**
   * 데모 계정으로 템플릿 편집툴로 열기
   * @param {string} canvasId 템플릿의 캔버스 ID
   * @param {number} canvasPages 템플릿의 캔버스 페이지 수
   * @param {string} canvasInfo 템플릿의 캔버스 이름 + 사이즈
   * @return {Promise}
   */
  async editTemplateForDemo(canvasId: string, canvasPages: number, canvasInfo): Promise<void> {
    if (this.app.user.isTokenSaved()) {
      try {
        if (this.isEditTemplateClicking) {
          return;
        }
        this.isEditTemplateClicking = true;

        // 템플릿 편집
        const { data } = await this.canvasService.canvasClone(+canvasId, +this.app.cache.user.id, false);
        if (data.result) {
          this.isEditTemplateClicking = false;
          this.app.go(`/4cut-make-manual2/${+data.canvasId}`);
        }
        this.app.isPaidMember = false;
      } catch (e) {
        await this.app.logout(false);
        console.error(e);
        throw new Error(`canvas 복제 실패`);
      } finally {
        await this.modalCtrl.dismiss();
        this.analyticsService.templateSiteSelect(canvasInfo);
      }
    } else {
      await this.modalCtrl.dismiss();
      this.app.go(`login`);
    }
  }

  /**
   * 체험하기 계정으로 템플릿 편집툴로 열기
   * @param {string} canvasId 템플릿의 캔버스 ID
   * @param {number} canvasPages 템플릿의 캔버스 페이지 수
   * @param {string} canvasInfo 템플릿의 캔버스 이름 + 사이즈
   * @return {Promise}
   */
  async demoEditTemplate(canvasId: string, canvasPages: number, canvasInfo: string): Promise<void> {
    try {
      await this.cut.demoDummyLogin(true);
      await this.editTemplateForDemo(canvasId, canvasPages, canvasInfo);
    } catch (e) {
      if (e instanceof TooningDemoDummyLoginError) {
        throw new TooningDemoEditTemplateError('체험하기 로그인 실패');
      } else throw new TooningDemoEditTemplateError(e);
    }
  }

  async slideChanged() {
    this.slidesIndex = await this.slides.getActiveIndex();
  }

  async close() {
    if (this.app.modal !== null) {
      await this.modalCtrl.dismiss();
      this.location.back();
    }
  }

  async sharePopover(ev: any) {
    this.app.popover = await this.popoverController.create({
      mode: 'md',
      component: PopoverLinkcopyComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
      componentProps: {
        canvasId: this.canvasId
      }
    });
    this.app.popover.style.cssText = '--min-width: 100px; --max-width: 335px; --width : 335px;z-index:10000000';
    this.app.popover.onDidDismiss().then(async (data) => {});
    return await this.app.popover.present();
  }
}
