import { Injectable } from '@angular/core';
import { GraphqlApiService } from '../api/graphql.api.service';
import { gql } from '@apollo/client';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(private graphql: GraphqlApiService) {}

  /**
   * 탑바 공지 또는 업데이트 중이라는 모달을 위한 정보를 확인함
   * @returns {any}
   */
  public checkAnnouncement(): any {
    return this.graphql.watchQuery(gql`
      query checkAnnouncement {
        checkAnnouncement {
          status
          maintenanceNotificationStatus
          countDownNotificationStatus
          notificationStatus
          dueDate
          startDate
        }
      }
    `);
  }
}
