import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Card } from '../../model/card/card';
import { Subject } from 'rxjs';
import { Loading } from '../../services/loading';
import { FormBuilder } from '@angular/forms';
import { AppService } from '../../services/app.service';
import { CardService } from '../../services/card/card.service';
import { PointChargeSubscriptionService } from '../../services/subscriptions/pointChargeSubscription.service';
import { PointService } from '../../services/point/point.service';
import { AnalyticsService } from '../../services/google/analytics/analytics.service';
import { AlertController, IonSlides, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, take, timeout } from 'rxjs/operators';
import { validate } from 'class-validator';
import { Currency, LanguageType, PaymentGateyway, PaypalRequestType, UserRole } from '../../enum/app.enum';
import * as _ from 'lodash';
import { cardErrorHandler } from '../../pages-tooning/common/handlers/error.handler';
import { PaymentHistoryPage } from '../../pages-tooning/payment-history/payment-history.page';
import {
  TooningCheckSubscriberError,
  TooningCustomClientError,
  TooningErrorCode,
  TooningFailIamportBillingPaymentError,
  TooningFailRequestIamportGetBillingKeyError,
  TooningGetIamportPaymentInfoError,
  TooningIamportPointChargeError,
  TooningPointChargeUIError,
  TooningRequestChargePointError,
  TooningShowCardUpdateViewError
} from '../../pages-tooning/errors/TooningErrors';
import { IamportComponent } from '../iamport-component/iamport-component';
import { IamportPaymentInfo } from 'src/app/interfaces/app.interface';
import { Router } from '@angular/router';

const FILE_NAME = 'point-charge.component.ts';
@Component({
  selector: 'app-point-charge',
  templateUrl: './point-charge.component.html',
  styleUrls: ['./point-charge.component.scss']
})
export class PointChargeComponent implements OnInit, OnDestroy {
  public languageEnum = LanguageType;
  public isChargedEnough = true; // 충전해야하는 금액보다 적은 금액을 충전하는 경우 띄워주는 메세지
  public viewTargetID: string;
  public chargingPoint = 0; // 충전될 포인트
  public chargingBonusPoint = 0; // 충전될 보너스 포인트
  public chargingPointPriceId; // 충전될 포인트의 실가격을 가지고 있는 id
  public myPoints = 0; // 보유 포인트
  public isCardRegistoredFirst = false; // 카드 처음으로 등록하는건 지 여부
  public cardRegisterView = false; // 내 계정 페이지
  // tslint:disable-next-line:no-input-rename
  @Input('isModal') isModal = false;
  @Input() order = 1; // 1: 코인선택view , 2: 카드등록view , 3: 내 계정 > 카드등록view
  @Input() requiredPoint; // 리소스를 구매하는데 필요한 총 포인트
  @Output() viewTypeChange: EventEmitter<any> = new EventEmitter();
  @Output() getPoint: EventEmitter<any> = new EventEmitter();
  @Output() purchaseAfterCharging: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() disableBtn: EventEmitter<any> = new EventEmitter();
  @ViewChild('iamport') iamport: IamportComponent;
  public isUpdatingCard = false; // 카드 데이터 수정중인가
  public cardBeforeUpdate: Card;
  public card: Card = new Card();
  public isCardRegistored = false;
  public isCardSavingChecked = false;
  public isRender: boolean;
  public isTTI = false;
  public disableNextButton: boolean;
  public pointData = [];
  private subscriptionsList = [];
  private cardRegistor$ = new Subject<Card>();
  private createCardSuccessMessage;
  private loading = new Loading();
  private hasValidationError = false;
  private deleteCardSuccessMessage;
  public paypalType = PaypalRequestType.order;
  public currency: string;
  public isDecimalPrice: boolean = false;
  public isOverseasUser: boolean = false;
  public chargingPrice: number;
  public doPointChargeViewElementRef: ElementRef;
  public UserRole = UserRole;
  currencyCodeDeclared = Currency;
  width = 220;
  height = 35;
  private timeToWait = 10000;
  paymentGateway = PaymentGateyway;
  public isPaypel;
  public defaultPaymentInfo: IamportPaymentInfo;
  public otherPaymentInfo: Array<IamportPaymentInfo> = [];

  @ViewChild('cardSlide') cardSlide: IonSlides;
  @ViewChild('slidePrevBtn') slidePrevBtn: ElementRef<HTMLDivElement>;
  @ViewChild('slideNextBtn') slideNextBtn: ElementRef<HTMLDivElement>;
  public viewCardIndex: number = 0;
  public isCardSlideEnd: boolean = false;
  public slideActiveCard: IamportPaymentInfo;

  public slideOption = {
    initialSlide: 0,
    spaceBetween: 0,
    centeredSlides: true,
    simulateTouch: true,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      // 화면의 넓이가 250px 이상일 때
      250: {
        slidesPerView: 1.1
      },
      // 화면의 넓이가 300px 이상일 때
      300: {
        slidesPerView: 1.2
      },
      // 화면의 넓이가 350px 이상일 때
      350: {
        slidesPerView: 1.4
      },
      // 화면의 넓이가 400px 이상일 때
      400: {
        slidesPerView: 1.5
      },
      // 화면의 넓이가 445px 이상일 때
      445: {
        slidesPerView: 1.8
      }
    }
  };
  // fast click 방지
  public isClicked: boolean = false;

  constructor(
    public app: AppService,
    public cardService: CardService,
    private pointChargeSubscriptionService: PointChargeSubscriptionService,
    private pointService: PointService,
    private analyticsService: AnalyticsService,
    public modalCtrl: ModalController,
    private translate: TranslateService,
    private router: Router,
    public alertController: AlertController,
    public fb: FormBuilder
  ) {}

  async ngOnInit() {
    this.loading.loadingType = this.app.serviceID;
    this.disableNextButton = true;
    this.isRender = false;
    this.initCardRegistor();
    this.currency = this.app.getCurrencyCode();
    this.isDecimalPrice = this.app.hasDecimalPrice();
    this.isOverseasUser = this.app.isOverseasUser();
    await this.getPointPlan();
    this.hasValidationError = false;
    this.app.greenLog('ionViewWillEnter');
    this.createCardSuccessMessage = this.translate.instant('complete card registered');
    this.deleteCardSuccessMessage = this.translate.instant('card deleted');
    this.isPaypel = await this.app.checkPaypelUser();
    if (this.isPaypel) {
      await this.getCards();
    } else {
      await this.getIamportPaymentInfo();
    }
    await this.initPointSumSubscription();
  }

  /**
   * 해당 user가 보유중인 포인트 조회
   */
  async initPointSumSubscription() {
    try {
      const userId = +this.app.cache.user.id;
      this.subscriptionsList.push(
        this.pointService.points(userId).subscribe(
          ({ data }) => {
            this.myPoints = data.sum;
            this.getPoint.emit(this.myPoints);
          },
          (error) => {
            this.app.orange(error.message);
          }
        )
      );
    } catch (e) {
      console.error(`initPointSumSubscription : ${e}`);
    }
  }

  /**
   * 포인트 충전이 정상적으로 완료되었는 지에 대해 subscription 한다.
   * timeToWait 시간이 지나도 응답이 없으면 에러로 핸들러가 직접 데이터베이스 polling 하여 처리한다.
   * @returns {Promise<void>}
   */
  async initPointChargeSubscription(): Promise<void> {
    try {
      this.subscriptionsList.push(
        this.pointChargeSubscriptionService
          .subscribe({ topic: +this.app.cache.user.id })
          .pipe(take(1), timeout(this.timeToWait))
          .subscribe(
            ({ data }) => {
              this.loading.hideLoader();
              if (data.pointChargeDone.result) {
                this.app.showToast(this.translate.instant('complete tooning coin charge'));
                this.pointChargeDone();
              } else {
                this.pointChargeErrorHandler(data.pointChargeDone.message);
              }
            },
            (error) => {
              this.app.orange(error.message);
              this.checkPointChargeDoneWithoutError();
            },
            () => {
              this.app.green('pointCharge subscription complete');
            }
          )
      );
    } catch (e) {
      console.error(`initPointChargeSubscription : ${e}`);
    }
  }

  ngOnDestroy() {
    try {
      this.app.orange('point charge unsubscribed');
      if (this.isCardRegistoredFirst && !this.isCardSavingChecked && this.order !== 3) {
        this.deleteCard();
      }
      this.subscriptionsList.forEach((subscription) => {
        subscription.unsubscribe();
      });
      this.subscriptionsList = [];
      this.order = 1;
    } catch (e) {
      this.app.orange(e.message);
    }
  }

  initCardRegistor(callback?: any) {
    this.cardRegistor$
      .pipe(
        debounceTime(500) // 여러번 클릭을 막기 위해
      )
      .subscribe(async (card) => {
        await this.loading.showLoader('');
        this.app.blueLog(`ok ${JSON.stringify(card)}`);
        this.app.blueLog('valid card number');
        if (!this.isCardRegistored) {
          // 카드 생성 및 포인트 충전
          this.app.blueLog('card create and point charge');
          const validationErrors = await validate(card);
          if (validationErrors.length > 0) {
            this.hasValidationError = true;
            this.app.orange('validation error');
            console.log(validationErrors);
            await this.showValidationErrorMessage(validationErrors);
          } else {
            await this.cardService
              .createPointCard(card)
              .subscribe(
                callback ? callback : this.cardCreateAndUpdateSuccessHandler,
                this.cardCreateAndUpdateErrorHandler,
                this.cardCreateAndUpdateCompleteHandler
              );
          }
        } else {
          // 충전만 하는 경우
          this.app.blueLog('only point charge');
          await this.pointCharge();
        }
      });
  }

  next() {
    this.order = 2;
    this.analyticsService.sendGA(
      'selectPoint',
      this.chargingPoint + this.chargingBonusPoint,
      this.app.cache.user.country + '/page/' + this.app.currentService
    );
    // '카드등록하는화면' 으로 바꿔준다
    this.viewTypeChange.emit(['isShowCardRegisterView', this.chargingPoint, this.chargingBonusPoint]);
  }

  /**
   * 포인트 충전이 끝난 후 실행할 작업
   * 모달에서 리소스 구매의 경우, 다시 리소스 구매 창으로 이동
   * 모달에서 TTI의 경우, 모달 닫음
   * 결제 페이지에서의 경우, 결제완료 안내 페이지로 이동
   * @return {Promise<void>}
   */
  async pointChargeDone(): Promise<void> {
    if (this.isModal) {
      // 충분히 충전한 경우, 리소스 구매까지 이어지도록 알람창을 띄워준다.
      if (this.requiredPoint <= this.chargingPoint + this.chargingBonusPoint + this.myPoints && !this.isTTI) {
        this.purchaseAfterCharging.emit();
      } else {
        await this.app.delay(300);
        await this.close();
      }
    } else {
      this.app.go('./point-card-registration-complete');
    }
  }

  /**
   * 포인트 충전 도중 에러가 생긴 경우, 다시 충전 테이블을 조회해 충전이 완료되었는 지 여부를 확인하여
   * 클라이언트에서 정상적으로 처리될 수 있도록 한다.
   */
  async checkPointChargeDoneWithoutError() {
    const { data } = await this.pointService.isChargedDoneInOneMinute(+this.app.cache.user.id).toPromise();
    if (data) {
      await this.app.showToast(this.translate.instant('complete tooning coin charge'));
      await this.pointChargeDone();
    } else {
      await this.pointChargeErrorHandler('');
    }
  }

  async pointCharge() {
    try {
      await this.initPointChargeSubscription();
      this.app.greenLog(`${this.card.number}를 기본카드로 포인트 충전을 시도합니다.`);
      const user = await this.app.user.currentUser();
      this.pointService
        .pointCharge({
          userId: user.id,
          point: +this.chargingPoint,
          bonusPoint: +this.chargingBonusPoint,
          pointPriceId: +this.chargingPointPriceId
        })
        .subscribe(this.pointChargeSuccessHandler, this.pointChargeErrorHandler, this.pointChargeCompleteHandler);
    } catch (e) {
      throw new Error(`pointCharge : ${e.message}`);
    }
  }

  /**
   * @param point selected point.
   * @param bonusPoint bonusPoint for selected point.
   * @param price selected price.
   * @param pointPriceId can get a price of selected point.
   */
  async selectCoin(point, bonusPoint, price, pointPriceId) {
    this.chargingPoint = point;
    this.chargingBonusPoint = bonusPoint;
    this.chargingPrice = price;
    this.chargingPointPriceId = pointPriceId;

    this.disableNextButton = false;
    this.disableBtn.emit(false);
    await this.checkEnoughCoinSelected();
  }

  async checkEnoughCoinSelected() {
    if (this.requiredPoint <= this.chargingPoint + this.chargingBonusPoint + this.myPoints) {
      this.isChargedEnough = true;
    } else {
      this.isChargedEnough = false;
    }
  }

  async getCards() {
    if (this.app.user.isTokenSaved()) {
      this.cardService.getCards(this.app.cache.user.id).subscribe(this.getCardSuccessHandler, this.getCardErrorHandler, this.getCardCompleteHandler);
    } else {
      this.app.go('login');
    }
  }

  async getPointPlan() {
    try {
      const { data } = await this.pointService.getPointPlan().toPromise();
      console.log('currency', this.currency);
      for (const pointPlan of data) {
        pointPlan.price = pointPlan.prices.filter((price) => price.currency === this.currency)[0].price;
        pointPlan.pointPriceId = pointPlan.prices.filter((price) => price.currency === this.currency)[0].id;
        console.log('price', pointPlan.price);
        this.pointData.push(pointPlan);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async showValidationErrorMessage(validationErrors) {
    console.log(validationErrors);
    if (document.querySelector('.ValidationError') != null) {
      // @ts-ignore
      // tslint:disable-next-line:variable-name
      for (const _div of document.querySelectorAll('.ValidationError')) {
        _div.remove();
      }
    }

    // tslint:disable-next-line:no-shadowed-variable
    for (const validate of validationErrors) {
      if (document.getElementById(this.viewTargetID).querySelector('#' + validate.property) != null) {
        const message = Object.values(validate.constraints)[0];
        // @ts-ignore
        const divMessage = this.translate.instant(message);

        document
          .getElementById(this.viewTargetID)
          .querySelector('#' + validate.property)
          .insertAdjacentHTML(
            'afterend',
            `<div class='ValidationError ui pointing red basic label m-0 mb-4 animation_fadeIn w-100 text-center ${validate.property}'> ${divMessage}</div>`
          );
      } else {
        this.app.orange(`validate.property is emtpy ${validate.property}`);
        if (this.app.usedLanguage === LanguageType.ko) {
          await this.app.showToast(`${validate.property} 가 없습니다`);
        } else {
          await this.app.showToast(`There is no ${validate.property}`);
        }
      }
    }
    try {
      this.loading.hideLoader();
    } catch (e) {
      this.app.orange(e.message);
    }
  }

  setCardMetaData() {
    this.card.default = true;
    this.card.userId = this.app.cache.user.id;
  }

  showCardRegisterView() {
    this.cardRegisterView = true;
  }

  doCardRegistry() {
    this.viewTargetID = 'app-point-charge-cardRegister';
    this.app.green(`do card registry`);
    this.analyticsService.checkoutProgress();
    this.setCardMetaData();
    this.cardRegistor$.next(this.card);
  }

  /**
   * 포인트 결제 시도
   * @param {string} id - 결제 모달 ID
   * @param {boolean} isTTI - TTI 기능에서 결제 시도
   */
  doPointCharge(id: string, isTTI: boolean = false): void {
    this.isTTI = isTTI;
    this.viewTargetID = id;
    this.analyticsService.checkoutProgress();
    this.setCardMetaData();
    this.cardRegistor$.next(this.card);
  }

  getCardSuccessHandler = async (result) => {
    const { data, errors } = result;
    if (!errors) {
      this.app.greenLog(data);
      if (data.length > 0) {
        this.isCardRegistored = true;
        this.card = data[0];
        this.cardBeforeUpdate = _.cloneDeep(data[0]);
      } else {
        this.card = new Card();
        this.isCardRegistored = false;
        this.app.orange('등록된 카드가 없습니다.');
        await this.app.showToast(this.translate.instant('no regit card'));
      }
    } else {
      this.app.orange(errors);
      console.error(errors);
    }
  };

  getCardErrorHandler = async (err) => {
    try {
      const { graphQLErrors, networkError } = err;
      if (graphQLErrors) {
        for (const gError of graphQLErrors) {
          const errorCode = +gError.extensions.exception.code;
          switch (errorCode) {
            case TooningErrorCode.TOONING_SERVER_GET_CARD_ERR:
              await this.app.showToast(gError.message, 3000, 'danger', 'middle');
              break;
            default:
              await this.app.showToast(this.translate.instant('card validation err'), 3000, 'danger', 'middle');
              break;
          }
        }
      }
      await this.app.checkNetworkError(err, networkError);
    } catch (e) {
      this.app.redLog(`getCardErrorHandler : ${e}`);
    }
  };

  getCardCompleteHandler = async () => {
    this.isRender = true;
    this.loading.hideLoader();
  };

  pointChargeSuccessHandler = async (result) => {};

  pointChargeErrorHandler = async (err) => {
    this.app.orange(err);
    await this.app.showToast(err, 3000, 'danger', 'middle');
    this.loading.hideLoader();
    if (this.isModal) {
      await this.app.delay(300);
      await this.close();
    }
  };

  pointChargeCompleteHandler = async () => {
    this.analyticsService.sendGA('purchasePoint', this.app.cache.user.country + ': ' + this.chargingPrice, this.app.currentService);
  };

  async close() {
    await this.modalCtrl.dismiss({});
  }

  cardCreateAndUpdateSuccessHandler = async ({ data, errors }) => {
    if (errors) {
      this.app.orange(errors);
      await this.app.showToast(errors);
    } else {
      if (data.pointCardCreate.result) {
        this.isCardRegistored = true;
        this.isCardRegistoredFirst = true;
        this.app.blueLog(data.pointCardCreate.id);
        this.card.id = data.pointCardCreate.id;
        if (this.order === 3) {
          // 내 계정에서 온 경우
          this.loading.hideLoader();
        } else {
          await this.pointCharge();
        }
      } else {
        this.analyticsService.error('card-registration.cardCreate.validationError', data.pointCardCreate.message);
        this.app.orange(`errorCode :${data.pointCardCreate.errorCode},, ErrMessage :${data.pointCardCreate.message}`);
        await cardErrorHandler(data.pointCardCreate, this.app, this.translate, true);
        this.loading.hideLoader();
      }
    }
  };
  // tslint:disable-next-line:no-shadowed-variable
  cardCreateAndUpdateErrorHandler = async (error) => {
    this.app.redLog(error);
    this.analyticsService.error('card-registration.cardCreate.internalServerError', error.message);
    await this.app.showToast(error);
    this.loading.hideLoader();
  };

  cardCreateAndUpdateCompleteHandler = async () => {
    this.app.blueLog('complete');
  };

  goToAccountSetting = () => {
    this.app.go('account-setting');
  };

  async back() {
    await this.modalCtrl.dismiss();
  }

  removeValidationErrorMessage(element) {
    if (!this.hasValidationError) {
      return;
    }
    console.log(element.el.id);
    // @ts-ignore
    // tslint:disable-next-line:variable-name
    for (const div of document.getElementsByClassName(`ValidationError ${element.el.id}`)) {
      console.log(div);
      div.remove();
    }
  }

  /**
   * 선택한 결제 수단이 구독에 걸려있는지 확인하고 아니면 삭제하는 얼럿 띄우는 함수
   * @param {string} pg 아임포트의 경우 pg와 payType을 통해 확인함
   * @param {string} payType
   * @return {Promise<void>}
   */
  async checkSubscriber(pg?: string, payType?: string): Promise<void> {
    try {
      let isIamportSubscriber = false;
      if (payType) {
        //@ts-ignore
        isIamportSubscriber = await this.pointService.checkIamportSubscriber(+this.app.cache.user.id, payType).toPromise();
      }
      if ((this.card.subscriber && !this.card.subscriber.isCancel) || isIamportSubscriber) {
        const alert = await this.alertController.create({
          header: this.translate.instant('pro delete payment method'),
          message: this.translate.instant('pro delete payment method2'),
          cssClass: 'basic-dialog',
          buttons: [
            {
              text: this.translate.instant('cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            },
            {
              text: this.translate.instant('confirm'),
              handler: async () => {
                try {
                  await this.loading.showLoader('');
                  if (this.isPaypel) {
                    await this.deleteCard();
                  } else {
                    // 서버에서 삭제 요청 함수
                    await this.pointService.deleteBillingKeyFromIamport(+this.app.cache.user.id, payType, isIamportSubscriber).toPromise();
                    await this.getIamportPaymentInfo();
                  }
                } catch (e) {
                  throw e;
                } finally {
                  this.loading.hideLoader();
                }
              }
            }
          ]
        });
        await alert.present();
      } else {
        const alert = await this.alertController.create({
          header: this.translate.instant('delete payment method'),
          message: this.translate.instant('delete payment method2'),
          cssClass: 'basic-dialog',
          buttons: [
            {
              text: this.translate.instant('cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            },
            {
              text: this.translate.instant('confirm'),
              handler: async () => {
                try {
                  await this.loading.showLoader('');
                  if (this.isPaypel) {
                    await this.deleteCard();
                  } else {
                    // 서버에서 삭제 요청 함수
                    await this.pointService.deleteBillingKeyFromIamport(+this.app.cache.user.id, payType, isIamportSubscriber).toPromise();
                    await this.getIamportPaymentInfo();
                  }
                } catch (e) {
                  throw e;
                } finally {
                  this.loading.hideLoader();
                }
              }
            }
          ]
        });
        await alert.present();
      }
    } catch (e) {
      throw new TooningCheckSubscriberError(e, this.app, true);
    } finally {
      this.isClicked = false;
    }
  }

  setCardMetaDataBeforeUpdate() {
    // 새 카드 객체로 카드 데이터 수정 (cardId 값은 pk 이므로 보존)
    const cardId = this.card.id;
    this.card = new Card();
    this.card.id = +cardId;
    this.card.userId = this.app.cache.user.id;
    this.card.default = true;
  }

  /**
   * 카드 정보 업데이트 뷰 전환 함수
   * @return {Promise<void>}
   */
  async update(): Promise<void> {
    try {
      this.isUpdatingCard = true; // 카드 수정중인가?
      this.setCardMetaDataBeforeUpdate();
    } catch (e) {
      await this.cardUpdateErrorHandler(e);
    }
  }

  async updateCardData() {
    try {
      this.viewTargetID = 'app-point-charge-cardRegister';
      const validationErrors = await validate(this.card);
      if (validationErrors.length > 0) {
        this.hasValidationError = true;
        this.app.orange('validation error');
        console.log(validationErrors);
        await this.showValidationErrorMessage(validationErrors);
      } else {
        await this.loading.showLoader('');
        const { data } = await this.cardService.updateCard(this.card).toPromise();
        await this.cardUpdateSuccessHandler(data);
      }
    } catch (e) {
      await this.cardUpdateErrorHandler(e);
    } finally {
      this.loading.hideLoader();
    }
    this.app.blueLog(`update card : ${this.card.id}`);
  }

  async deleteCard() {
    try {
      const { data } = await this.cardService.deleteCard(this.card.id).toPromise();
      await this.cardDeleteSuccessHandler(data);
    } catch (e) {
      await this.cardDeleteErrorHandler(e);
    }
    this.app.blueLog(`card id to delete : ${this.card.id}`);
  }

  cardDeleteSuccessHandler = async (data) => {
    if (data.cardDelete.result) {
      this.isCardRegistored = false;
      this.card = new Card();
    } else {
      this.app.orange(`errorCode :${data.cardDelete.errorCode}`);
      await cardErrorHandler(data.cardDelete.errorCode, this.app, this.translate, true);
    }
  };

  cardDeleteErrorHandler = async (error) => {
    this.app.redLog(error);
    await this.app.showToast(error);
  };

  cardUpdateSuccessHandler = async (data) => {
    if (data.cardUpdate.result) {
      this.isCardRegistored = true;
      this.isUpdatingCard = false;
    } else {
      this.app.orange(`errorCode :${data.cardUpdate.errorCode}`);
      await cardErrorHandler(data.cardUpdate, this.app, this.translate, false);
    }
  };

  cardUpdateErrorHandler = async (error) => {
    this.app.redLog(error.message);
    await this.app.showToast(error.message);
  };

  // 수정요망
  async saveCardChecked(event) {
    console.log(event.detail.checked); // true, false
    this.isCardSavingChecked = event.detail.checked;
  }

  moveToPaymentHistory = async () => {
    const modal = await this.modalCtrl.create({
      component: PaymentHistoryPage,
      componentProps: {
        type: 'modal'
      },
      cssClass: 'modal-size-payment-history'
    });
    return await modal.present();
  };

  /**
   * 페이팔 프로세스 종료 콜백 함수
   * Params: result – Object { result: boolean, errorCode?: string, message: string }
   */
  public onCompleted(result: any) {
    if (result.result) {
      console.count();
      this.app.green(`충전 완료`);
      this.app.showToast(this.translate.instant('complete tooning coin charge'));
      if (this.isModal) {
        // 충분히 충전한 경우, 리소스 구매까지 이어지도록 알람창을 띄워준다.
        if (this.requiredPoint <= this.chargingPoint + this.chargingBonusPoint + this.myPoints && !this.app.isMagic() && !this.app.isGpt()) {
          this.purchaseAfterCharging.emit();
        } else {
          this.app.delay(300);
          this.close();
        }
      } else {
        this.app.go('./point-card-registration-complete');
      }
    } else {
      this.loading.hideLoader();
      this.app.orange(`충전 실패`);
    }
  }

  async cardInfo() {
    // 한국만 지원되는 함수
    const alert = await this.alertController.create({
      cssClass: 'basic-dialog',
      header: '결제 가능한 카드',
      message:
        '신한카드, BC카드, SC BC, 우리 BC, 경남 BC, 대구 BC, NH BC, 기업 BC, 부산 BC, 하나 BC, 신한 BC, 국민 BC, KB국민, 현대카드, 삼성카드, 롯데AMX, 하나카드, 하나(구외환), 롯데카드, NH, 씨티카드, 우리카드, 광주카드, 전북카드, 제주카드, 수협카드, 신협카드, 새마을금고, 우체국, 저축은행, KDB산업, 평화카드, 한미, 신세계한미, 케이뱅크, 카카오뱅크체크',
      buttons: ['확인']
    });

    await alert.present();
  }

  /**
   * 아임포트 통해서 포인트 결제
   * @param isOnlyPoint 포인트 충전만 할건지 리소스 구매도 할건지 구분하는 식별자
   * @return {Promise<void>}
   */
  async iamportPointCharge(isOnlyPoint: boolean): Promise<void> {
    try {
      this.analyticsService.sendGA('pointChargeStart', 'modal', this.app.currentService);
      this.app.cache.setRedirect(this.router.url);
      await this.iamport.requestChargePoint(this.iamport.requestBillingKeyIamportOps, isOnlyPoint);
    } catch (e) {
      if (
        e instanceof TooningFailIamportBillingPaymentError ||
        e instanceof TooningFailRequestIamportGetBillingKeyError ||
        e instanceof TooningRequestChargePointError
      ) {
        throw e;
      } else {
        throw new TooningIamportPointChargeError(e, this.app, true);
      }
    }
  }

  /**
   * 아임포트를 통해 결제한 결제수단 정보 가져오기
   * @return {Promise<any>}
   */
  async getIamportPaymentInfo(): Promise<any> {
    try {
      await this.loading.showLoader();
      this.otherPaymentInfo = [];
      delete this.defaultPaymentInfo;
      // 서버에서 아임포트 결제 정보 가져오는 코드
      const paymentList = await this.pointService.getIamportPaymentList(+this.app.cache.user.id).toPromise();
      // @ts-ignore
      for (let payment of paymentList) {
        payment.cardNumber = this.pointService.makeMaskingCardNumber(payment.cardNumber);
        if (payment.isDefault) {
          this.defaultPaymentInfo = payment;
          this.slideActiveCard = this.defaultPaymentInfo;
        } else {
          this.otherPaymentInfo.push(payment);
        }
      }
    } catch (e) {
      throw new TooningGetIamportPaymentInfoError(e);
    } finally {
      this.loading.hideLoader();
    }
  }

  /**
   * 결제 등록을 위한 페이지로 전환, 업데이트가 끝나면 다시 뷰 전환
   * @param {boolean} isUpdatedDone 뷰 전환하고 결제 정보 다시 가져옴
   * @return {Promise<void>}
   */
  async showCardUpdateView(isUpdatedDone?: boolean) {
    try {
      this.isUpdatingCard = !this.isUpdatingCard;
      if (isUpdatedDone) {
        await this.getIamportPaymentInfo();
      }
    } catch (e) {
      if (e instanceof TooningGetIamportPaymentInfoError) throw e;
      else throw new TooningShowCardUpdateViewError(e);
    } finally {
      this.isClicked = false;
    }
  }

  /**
   * 기본 결제 수단 변경 함수
   * @param {string} paymentType  카카오, 네이버, 카드
   * @return {Promise<any>}
   */
  async changeDefaultPayment(paymentType: string): Promise<any> {
    try {
      const changeResult = await this.pointService.setDefaultBillingKeyFromIamport(this.app.cache.user.id, paymentType).toPromise();

      if (changeResult) {
        await this.app.showToast(this.translate.instant('change-default-payment'));
        await this.getIamportPaymentInfo();
      } else {
        const alert = await this.alertController.create({
          header: this.translate.instant('change-default-payment-fail-title'),
          cssClass: 'basic-dialog-payment-change-alert',
          message: this.translate.instant('change-default-payment-fail-text'),
          mode: 'md',
          buttons: [
            {
              text: this.translate.instant('confirm'),
              handler: async () => {
                console.log('Confirm Cancel');
              }
            }
          ]
        });
        await alert.present();
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'changeDefaultPayment()');
    }
  }

  /**
   * 카드 슬라이드가 전환이 종료되면 발생
   * 슬라이드 중앙에 있는 인덱스와 카드정보를 저장
   * @return {Promise<void>}
   */
  async changeSlideActiveIndex(): Promise<void> {
    try {
      this.viewCardIndex = await this.cardSlide.getActiveIndex();
      this.isCardSlideEnd = await this.cardSlide.isEnd();

      if (this.isCardSlideEnd) return;

      if (this.viewCardIndex === 0) this.slideActiveCard = this.defaultPaymentInfo;
      else this.slideActiveCard = this.otherPaymentInfo[this.viewCardIndex - 1];
    } catch (e) {
      throw new TooningPointChargeUIError(e);
    }
  }

  /**
   * 카드 슬라이드 버튼을 눌렀을 때
   * @param {Event} event 클릭 이벤트
   * @return {Promise<void>}
   */
  async onClickSlideButton(event: Event): Promise<void> {
    try {
      const clickedElement = event.target as Element;

      if (clickedElement === this.slidePrevBtn.nativeElement || this.slidePrevBtn.nativeElement.contains(clickedElement))
        await this.cardSlide.slidePrev();
      else await this.cardSlide.slideNext();
    } catch (e) {
      throw new TooningPointChargeUIError(e);
    }
  }
}
