<div>
  <ion-toolbar>
    <ion-title>{{ 'replacer.character.changeAllCharacter' | translate }}</ion-title>
    <ion-buttons class='m-0 mr-2' slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</div>

<div class='replacer-content'>
    <div class='replacer-text'>{{'replacer.character.replacerText' | translate }}</div>
    <div class='d-flex justify-content-between replacer-choice '>
      <div class='replacer-choice-text'>{{ 'replacer.character.choiceCharacter' | translate }}</div>
        <mat-form-field  class='mr-2 replacer-choice-box' >
          <mat-select (selectionChange)='select($event)' [(ngModel)]='selectedCharacter'>
            <mat-option *ngFor='let character of characterList' [value]='character'>
             {{ character.name_ko }}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
</div>

<ion-footer class='p-2' mode='ios'>
    <div class='d-flex justify-content-between replacer-warning'>
        <ion-icon name='alert-circle-outline' class='replacer-info-icon'></ion-icon>
        <div class='replacer-warning-text'>{{ 'replacer.replaceWarning'  | translate }}</div>
    </div>
  <ion-button  color='main' expand='block' [disabled]="disableNextButton" (click)=characterReplacerConfirm() matRipple>
    {{ 'replacer.changeAll' | translate }} </ion-button>
</ion-footer>