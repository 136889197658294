import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { StudioModule } from './app/stuidio.module';
import { BizModule } from './app/biz.module';

const hireMessage = () => {
  const lang = navigator.language;
  if (/^ko\b/.test(lang)) {
    console.groupCollapsed(`%c안녕? 🤗`, 'text-shadow: #a0f9fa 0 0 2px; font-family: monospace; font-size: 3em');
    console.log(
      '%c투닝은 TypeScript, Angular, Rxjs, Webpack, Graphql, Tpeorm, Tensorflow, Pytorch, ml5.js 와 여러분의 사랑과 아이디어로 만들어 졌어요',
      'text-shadow: #a0f9fa 0 0 2px; font-family: monospace; font-size: 2em'
    );
    console.log(
      '%c같이 일하고싶은 분은 이메일 주세요. "support@tooning.io" ',
      'text-shadow: #a0f9fa 0 0 2px; font-family: monospace; font-size: 2em'
    );
    console.groupEnd();
  } else {
    console.groupCollapsed(`%cWhat's up 🤗`, 'text-shadow: #a0f9fa 0 0 2px; font-family: monospace; font-size: 3em');
    console.log(
      '%cHello Tooning was build with TypeScript, Angular, Rxjs, Webpack, Graphql, Tpeorm, Tensorflow, Pytorch, ml5.js and lots of your love',
      'text-shadow: #a0f9fa 0 0 2px; font-family: monospace; font-size: 2em'
    );
    console.log('%cWant to work with us? Just email "support@tooning.io" ', 'text-shadow: #a0f9fa 0 0 2px; font-family: monospace; font-size: 2em');
    console.groupEnd();
  }
};
hireMessage();
if (environment.production && environment.deploy === 'oper') {
  enableProdMode();
  // tslint:disable-next-line:no-console
  console.clear = console.timeEnd = console.time = console.log = console.debug = console.error = console.warn = () => {};

  // Sentry.init({
  //   environment: environment.production ? 'production' : 'dev',
  //   dsn: 'https://7dc3b0b1c5f141e2ad6c043f4042d19e@o976771.ingest.sentry.io/5933120',
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       // tracingOrigins: ['localhost', 'https://www.tooning.io', 'https://dev-app.tooning.io', 'https://beta-app.tooning.io', /^\//],
  //       tracingOrigins: ['https://www.tooning.io', 'https://tooning.io'],
  //       routingInstrumentation: Sentry.routingInstrumentation
  //     })
  //   ],
  //
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate
  // });
}
const isStudio = sessionStorage.getItem('RootServiceType') === 'studio';
const isBiz = sessionStorage.getItem('RootServiceType') === 'biz';

if (window.location.href.indexOf('tooning.studio') != -1 || isStudio) {
  platformBrowserDynamic()
    .bootstrapModule(StudioModule)
    .catch((err) => console.error(err));
} else if (window.location.href.indexOf('tooning.biz') != -1 || isBiz) {
  platformBrowserDynamic()
    .bootstrapModule(BizModule)
    .catch((err) => console.error(err));
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}
