import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { CanvasListWorkingType } from '../../enum/app.enum';

export class CanvasGroup {
  @IsNumber() @IsNotEmpty() id: number;
  @IsString() @IsNotEmpty() role: string;
  @IsString() @IsNotEmpty() groupName: string;
  @IsNumber() @IsNotEmpty() userId: number;
  @IsString() @IsOptional() canvases?: string;
  @IsNumber() @IsOptional() canvasCount?: number;
  @IsBoolean() @IsOptional() share?: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    if (input.role === CanvasListWorkingType.sharedCanvasGroup) {
      this.share = true;
    } else {
      this.share = false;
    }
    return this;
  }
}

export class CanvasGroupShare {
  @IsNumber() @IsNotEmpty() id: number;
  @IsBoolean() @IsNotEmpty() share: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
