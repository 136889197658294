import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Cut4ListPageRoutingModule } from './cut4-list-routing.module';
import { Cut4ListPage } from './cut4-list.page';

import { ComponentsModule } from '../../components/components.module';
import { LandingModule } from '../../pages-tooning/components/landing.module';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { CanvasSharePageModule } from '../canvas-share/canvas-share.module';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { ScrollbarThemeModule } from '../../directive/scrollbar-theme';
import { NgxMasonryModule } from 'ngx-masonry';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyRippleModule } from '@angular/material/legacy-core';
import { PopoverSharingCanvasComponent } from '../../pages-tooning/components/popover/popover-sharing-canvas/popover-sharing-canvas.component';
import { LongPressDirective } from '../../directive/longpress/longpress.directive';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { CharacterReplacerComponent } from '../cut4-make-manual2/modal/replacer/character/character-replacer.component';
import { TextReplacerComponent } from '../cut4-make-manual2/modal/replacer/text/text-replacer.component';
import { PaginationExtendDirective } from '../../directive/paginationExtend/pagination-extend.directive';
import { PaginationExtendModule } from '../../directive/paginationExtend/pagination-extend.module';
import { DisabledDirectiveModule } from '../../directive/disabled/disabled.directive.module';

@NgModule({
  imports: [
    MatLegacyRippleModule,
    CommonModule,
    FormsModule,
    IonicModule,
    Cut4ListPageRoutingModule,

    ComponentsModule,
    CanvasSharePageModule,
    LandingModule,
    MatLegacyMenuModule,
    MatLegacyDialogModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    DragDropModule,
    TranslateModule,
    ClipboardModule,
    MatLegacySelectModule,
    ScrollbarThemeModule,
    NgxMasonryModule,
    MatLegacyTooltipModule,
    PaginationExtendModule,
    DisabledDirectiveModule,
    ReactiveFormsModule
  ],
  declarations: [Cut4ListPage, PopoverSharingCanvasComponent, CharacterReplacerComponent, LongPressDirective, TextReplacerComponent],
  exports: [LongPressDirective, PaginationExtendDirective],
  entryComponents: []
})
export class Cut4ListPageModule {}
