import { Command } from './command';
import { ObjectRotateCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { fabric } from 'fabric';
import { SelectedType } from '../../enum/app.enum';

/**
 * Class ObjectRotateCommand
 * @extends Command
 */
class ObjectRotateCommand extends Command {
  protected readonly beforeAngle: number;
  protected readonly afterAngle: number;

  /**
   * Create ObjectRotateCommand
   * @param {ObjectRotateCommandData} data
   */
  constructor(data: ObjectRotateCommandData) {
    super(data);
    this.beforeAngle = data.beforeAngle;
    this.afterAngle = data.afterAngle;
    this.commandName = 'Object-Rotate-Command';
  }

  /**
   * Execute ObjectRotateCommand
   */
  execute() {
    super.execute();
  }

  /**
   * Redo ObjectRotateCommand
   * @param {TooningFabricObject[]} objects
   */
  redo(objects: TooningFabricObject[]) {
    try {
      super.redo(objects);
      if (this.type === SelectedType.activeSelection) {
        const selectionList = [];
        objects.forEach((object) => {
          for (const element of this.id) {
            if (object.resource_selection_id === element) {
              selectionList.push(object);
            }
          }
        });
        this.cut.canvas.discardActiveObject();
        const selectedObjects = new fabric.ActiveSelection(selectionList, {});
        selectedObjects.set({
          angle: selectedObjects.angle + this.afterAngle - this.beforeAngle
        });

        this.cut.canvas.setActiveObject(selectedObjects);
        selectedObjects.setCoords();
      } else {
        objects.forEach((object) => {
          if (object.resource_selection_id === this.id[0]) {
            object.set({
              angle: this.afterAngle
            });
            object.setCoords();
          } else {
            console.log('object move command 타겟이 없습니다.');
          }
        });
      }
      this.cut.setPositionBoxButton();
      this.cut.forceNoFocus();
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo ObjectRotateCommand
   * @param {TooningFabricObject[]} objects
   */
  undo(objects: TooningFabricObject[]) {
    try {
      super.undo(objects);
      if (this.type === SelectedType.activeSelection) {
        const selectionList = [];
        objects.forEach((object) => {
          for (const element of this.id) {
            if (object.resource_selection_id === element) {
              selectionList.push(object);
            }
          }
        });
        this.cut.canvas.discardActiveObject();
        const selectedObjects = new fabric.ActiveSelection(selectionList, {});
        selectedObjects.set({
          angle: selectedObjects.angle - this.afterAngle + this.beforeAngle
        });
        this.cut.canvas.setActiveObject(selectedObjects);
        selectedObjects.setCoords();
      } else {
        objects.forEach((object) => {
          if (object.resource_selection_id === this.id[0]) {
            object.set({
              angle: this.beforeAngle
            });
            object.setCoords();
          } else {
            console.log('object move command 타겟이 없습니다.');
          }
        });
      }
      this.cut.setPositionBoxButton();
      this.cut.forceNoFocus();
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { ObjectRotateCommand };
