import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BasePage } from '../../base-page';
import { AppService } from '../../services/app.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { PaymentService } from '../../services/payment/payment.service';
import { TooningErrorCode } from '../../pages-tooning/errors/TooningErrors';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from '../../enum/app.enum';

@Component({
  selector: 'app-paid-guide-modal',
  templateUrl: './paid-guide-modal.page.html',
  styleUrls: ['./paid-guide-modal.page.scss']
})
export class PaidGuideModalPage extends BasePage implements OnInit {
  @Input('isModal') isModal;
  private popstateObservable$: Observable<Event>;
  private popstateSubscription$: Subscription;
  public plans;
  public isBetaUser = this.app.cache.user.role === UserRole.beta;

  constructor(public modalCtrl: ModalController, public app: AppService, private payment: PaymentService, private translate: TranslateService) {
    super(app);
  }

  ngOnInit() {
    super.ngOnInit();
    window.history.pushState({ modal: true }, null);
    this.popstateObservable$ = fromEvent(window, 'popstate');
    this.subscriptions.push(
      this.popstateObservable$.subscribe(async (event) => {
        await this.popstateHandler(event);
      })
    );
  }

  async ionViewDidEnter() {
    try {
      const { data } = await this.payment.paymentPlans(this.app.getCurrencyCode()).toPromise();
      this.plans = data;
    } catch (e) {
      await this.paymentPlanErrorHandler(e);
    }
  }

  /**
   * 결제 플랜 가져오기 에러 핸들러
   * @param error
   * @return {Promise<void>}
   */
  async paymentPlanErrorHandler(error) {
    const { graphQLErrors, networkError } = error;
    if (graphQLErrors) {
      for (const gError of graphQLErrors) {
        const errorCode = +gError.extensions.exception.code;
        if (errorCode === TooningErrorCode.TOONING_SERVER_GET_PAYMENTPLAN_ERR) {
          await this.app.showToast(this.translate.instant('subscription err 1'), 3000, 'danger');
        } else {
          await this.app.showToast(this.translate.instant('subscription err 2'), 3000, 'danger');
        }
      }
    }
    await this.app.checkNetworkError(error, networkError);
  }

  async popstateHandler($event: any) {
    if (this.app.modal !== null) {
      this.app.modal.dismiss();
    }
  }

  async ionViewWillLeave() {
    if (this.popstateSubscription$) {
      this.popstateSubscription$.unsubscribe();
    }
  }

  ionViewDidLeave() {
    super.ionViewDidLeave();
  }

  async close() {
    if (this.isModal) {
      await this.modalCtrl.dismiss({});
    }
    await this.app.delay(500);
    this.app.go('card-registration/pro');
  }
}
