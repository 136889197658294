import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  public googleUserInfo: any;
  public isFromLanding: boolean;

  constructor(public afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe((user: User) => {
      if (user) {
        console.log(`google oauth  user`);
        console.log(user);
      } else {
        console.log(`!!!!google oauth user is empty`);
      }
    });
  }

  /**
   * 구글 로그아웃
   * @returns {Promise<void>}
   */
  async logout(): Promise<void> {
    this.googleUserInfo = undefined;
    this.isFromLanding = undefined;
  }
}
