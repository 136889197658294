<ion-header>
  <ion-toolbar>
    <ion-buttons slot='start'>
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-buttons class='m-0 mr-2' slot='end'>
      <ion-button style='margin-right: 5px' (mousedown)='sharePopover($event)'>
        <ion-icon style='color: #333333' slot='icon-only' name='share-social-outline'></ion-icon>
      </ion-button>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content appScrollbarTheme>
  <div style='border: 1px #e0e0e0 solid; margin: 10px'>
    <div class='row p-0' *ngIf='readyToRender' style='min-height: 620px'>
      <div class='p-0 col-12 col-md-7'>
        <div #slidesDiv>
          <ion-slides #slides [options]='slideOpts' pager='false' style='background-color: #ececec'
                      (ionSlideDidChange)='slideChanged()'>
            <ion-slide *ngFor='let img of pageListbase64; let i = index' background-white>
              <img src='{{ img }}' style='width: 100%' class='templateModalImage rounded-xl mx-2'
                   [style.max-height.px]='slidesDiv.offsetWidth'
                   [ngClass]="templateData.paid && i >= 1 ? 'paidBlur' : ''" alt='template image'/>
            </ion-slide>
          </ion-slides>
        </div>
        <div style='position: relative; width: 150px; margin: 10px auto 10px auto;'>
          <div class='swiper-button-next' (click)='slides.slideNext()'
               *ngIf='pageListbase64.length !== 1 && slidesIndex + 1 !== pageListbase64.length'></div>
          <div class='swiper-button-prev' (click)='slides.slidePrev()'
               *ngIf='pageListbase64.length !== 1 && slidesIndex !== 0'></div>
          <div
            style='font-size: 1.2rem; color: #4f4f4f; margin: auto; width: 45px; border-radius: 20px 20px 20px 20px; text-align: center'>{{ slidesIndex + 1 }}
            /{{ pageListbase64.length }}</div>
        </div>
      </div>

      <div class='p-0 col-12 col-md-5 p-4' style='background-color: #f9f9f9'>
        <div class='d-flex align-items-start flex-column w-100 h-100'>
          <div class='mb-auto p-2'>
            <div style='font-size: 1.5rem; font-weight: bold'>{{ templateData.title }}</div>
            <div style='font-size: 1rem; margin-top: 5px'>
              {{ canvasSize.size }}
            </div>
            <div style='font-size: 1rem'>{{ templateData.author }}</div>
            <hr />
            <div class='d-flex flex-column justify-content-between'>
              <div class='d-flex flex-wrap' style='margin-top: -10px; margin-bottom: 0'>
                <ng-container *ngFor='let tag of tagList; let i = index'>
                  <ion-chip *ngIf='tag.name'>
                    <ion-label>{{ tag.name }}</ion-label>
                  </ion-chip>
                </ng-container>
              </div>
            </div>
          </div>

          <div class='d-flex justify-content-center w-100' *ngIf='templateData.paid'>
            <div class='p-3 m-1 paidComment'>
              <div class='d-flex'>
                <ion-icon class='mr-2' src='/assets/4cut-make-manual/pro_icon.svg' style='font-size: 2rem'></ion-icon>
                <div>
                  <b style='font-size: 1.2rem'>
                    {{ 'lock template' | translate }}
                  </b>
                  <div style='font-size: 1.2rem'>
                    {{ 'plz update' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer *ngIf='readyToRender' class='d-flex justify-content-between'>
  <div></div>
  <!--  {{'complete' | translate}}-->
  <ion-button class='ml-auto theme-main custom-size-m' mode='ios' style='margin: 12px 16px'
              (click)='app.loginStatus?editTemplate(templateData.canvas.id, pageListbase64.length, templateData.title + canvasSize.size):demoEditTemplate(templateData.canvas.id, pageListbase64.length, templateData.title + canvasSize.size)'
              matRipple>
    {{ 'edit template' | translate }}
  </ion-button>
</ion-footer>
