<ng-container *ngIf="show && app.isApp()">
  <div class="app-version text-center">
    {{ data.appVersion }} - {{ data.graphql_url }}, {{ data.version_code }}<br />
    {{ deploy_infor.channel }}
    <div>
      <ion-button [disabled]="deploy_infor.channel == 'oper'" color="dark" (click)="clickChannel('oper')"> Oper </ion-button>
      <ion-button [disabled]="deploy_infor.channel == 'beta'" color="dark" (click)="clickChannel('beta')"> Beta </ion-button>
      <ion-button [disabled]="deploy_infor.channel == 'develop'" color="dark" (click)="clickChannel('develop')"> Develop </ion-button>
    </div>

    <div>
      <ion-button (click)="show = false" color="dark"> 닫기 </ion-button>
    </div>
  </div>
</ng-container>
