import { IsArray, IsBoolean, IsDate, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { AgencyType, Currency, DateType, EduApplicantStatus, EstimateStep, LanguageType, Order, UsedPeriodUnit } from '../../enum/app.enum';

/**
 * 교육용 프로 어드민 페이지에서 신청자 리스트 가져올때 필요한 인풋
 */
export class InputGetEduApplicantList {
  @IsArray() @IsNotEmpty() countryList: LanguageType[]; //국가 필터
  @IsArray() @IsNotEmpty() applyStatusList: EduApplicantStatus[]; // 신청 상태
  @IsString() @IsNotEmpty() startDate: string;
  @IsString() @IsNotEmpty() endDate: string;
  @IsEnum(Order) @IsNotEmpty() dateOrder: Order;
  @IsEnum(DateType) @IsNotEmpty() dateType: DateType;
  @IsString() @IsOptional() searchText: string;

  deserialize(input: any) {
    Object.assign(this, input);
    if (!this.countryList) {
      this.countryList = [LanguageType.ko];
    }
    if (!this.applyStatusList) {
      this.applyStatusList = [EduApplicantStatus.pending];
    }
    return this;
  }
}

export class InputGetEduGroupPrice {
  @IsNumber() @IsOptional() userCount?: number;
  @IsNumber() @IsOptional() usedPeriod?: number;
  @IsDate() @IsOptional() now?: Date;
  @IsEnum(UsedPeriodUnit) @IsOptional() usedPeriodUnit?: UsedPeriodUnit;
  @IsBoolean() @IsOptional() isPromotion?: boolean;
  @IsEnum(Currency) @IsNotEmpty() currency: Currency;
}

export class InputCreateEduEstimate {
  @IsEnum(EstimateStep) @IsNotEmpty() estimateStep: EstimateStep;
  @IsString() @IsNotEmpty() applicantName: string;
  @IsString() @IsNotEmpty() applicantPhoneNum: string;
  @IsString() @IsNotEmpty() applicantEmail: string;
  @IsString() @IsNotEmpty() officePhoneNum: string;
  @IsString() @IsOptional() officeEmail?: string;
  @IsEnum(AgencyType) @IsNotEmpty() agencyType: AgencyType;
  @IsString() @IsNotEmpty() agencyName: string;
  @IsNumber() @IsNotEmpty() eduGroupPriceId: number;
  @IsNumber() @IsNotEmpty() userCount: number;
  @IsDate() @IsNotEmpty() startDate: Date;
  @IsNumber() @IsNotEmpty() totalPrice: number;
  @IsNumber() @IsOptional() userId?: number;
  @IsString() @IsNotEmpty() language: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class InputEduGroupPayment {
  @IsNumber() @IsNotEmpty() estimateId: number;
  @IsString() @IsNotEmpty() payType: string;
  @IsString() @IsNotEmpty() estimateImgUrl: string;
  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class InputGetEduEstimateList {
  @IsString() @IsNotEmpty() startDate: string;
  @IsString() @IsNotEmpty() endDate: string;
  @IsEnum(Order) @IsNotEmpty() dateOrder: Order;
  @IsEnum(DateType) @IsNotEmpty() dateType: DateType;
  @IsString() @IsOptional() searchText?: string;
  @IsArray() @IsNotEmpty() stepFilters: Array<EstimateStep>;
}
