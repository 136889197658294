<div class='d-flex justify-content-center w-100' *ngIf='!isProduction'>
  <ion-button class='w-25' color='warning' (click)='changeToIo()'>Io</ion-button>
  <ion-button class='w-25' color='tertiary' (click)='changeToStudio()'>Studio</ion-button>
  <ion-button class='w-25' color='danger' (click)='changeToBiz()'>Biz</ion-button>
  <ion-button class='w-25' color='light' (click)='isProduction = true'>닫기</ion-button>
</div>

<!-- PC 용 -->
<ion-header [ngClass]="{
            'editor-mode': app.currentService === serviceTypeEnum.Editor,
                'magic-mode': app.currentService === serviceTypeEnum.Magic,
                'studio-mode': app.currentService === serviceTypeEnum.Studio,
                'gpt-mode': app.currentService === serviceTypeEnum.Gpt,
                'board-mode': app.currentService === serviceTypeEnum.Board
            }"
            class='disable-zoom ion-no-border' *ngIf='app.plt.width()>1024'>
  <div *ngIf='!app.isEditorOpen||app.isDesktopWidth()'>
    <app-service-tab *ngIf='app.RootServiceType===DomainType.Io'></app-service-tab>
    <app-service-teb-studio *ngIf='app.RootServiceType===DomainType.Studio'></app-service-teb-studio>
  </div>
  <div class='d-flex justify-content-between max-width-container'>

    <div class='d-flex justify-content-start align-items-center' data-cy='headerMenu'
         style='height: 70px; gap: 11px'>
      <!--  Tooning Editor   -->
      <ng-container *ngIf='app.currentService===serviceTypeEnum.Editor'>
        <!-- 오리지날 로고 -->
        <div class='h-100 position-relative logo-wrapper editor'>
          <img
            src='/assets/editor/landing/logo.svg' data-cy='logo'
            class='img-fluid position-absolute cursor-pointer editor-logo'
            (click)="app.go('/')"
            alt='logo.svg'
          />
        </div>

        <ion-button (click)='goHome()'
                    *ngIf='app.loginStatus' class='pc-top-menu overflow-unset' color='dark' data-cy='myWorkList'
                    expand='block'
                    fill='clear'
                    id='header-my-work' mode='md'
        >
          {{ 'my work' | translate }}
        </ion-button>

        <ion-button (click)='goTemplate()' class='pc-top-menu overflow-unset' color='dark' expand='block'
                    fill='clear'
                    id='header-template'
                    mode='md'>
          {{ 'templates' | translate | titlecase }}
        </ion-button>


        <div
          (mouseenter)='serviceMenu($event,"price")'
          (mouseleave)='serviceMenuleave()' class='position-relative cursor-pointer'
        >

          <ion-button
            (click)='goPayPlan()' class='pc-top-menu overflow-unset' color='dark' data-cy='tooning-price-page'
            expand='block'
            fill='clear'
            id='header-pricing'
            mode='md'
          >
            {{ 'price' | translate | titlecase }}
          </ion-button>

          <div
            (mouseenter)='isPriceMenuOver = true' (mouseleave)='isPriceMenu = false; isPriceMenuOver = false'
            *ngIf='isPriceMenu' class='menu_sub_out' style='left: -25px;'
          >
            <div class='z-depth-1-half menu_sub' style='width: 100%;'>
              <!--   style="pointer-events: none" 위에 삭제 클릭이 안되는 이슈. 전체적인 버전업이되면서 발생하는 이슈         -->
              <ion-button (click)='goToPricePlan(cut.paymentInfoType.normal)'
                          class='pc-sub-menu w-100' color='dark' expand='block' fill='clear' id=''
                          mode='md' data-cy='general-page'
              >
                {{ 'tooning-price-plan.general-price' | translate }}
              </ion-button>
              <hr class='p-0 m-0' />
              <ion-button (click)='goToPricePlan(cut.paymentInfoType.school)'
                          class='pc-sub-menu w-100' color='dark' expand='block' fill='clear'
                          mode='md' data-cy='edu-page'
              >
                {{ 'tooning-price-plan.edu-price' | translate }}
              </ion-button>
            </div>
          </div>

        </div>


        <ion-button (click)='app.goHelpCenter()' class='pc-top-menu overflow-unset' color='dark' expand='block'
                    fill='clear'
                    id='header-help'
                    mode='md'>
          {{ 'help' | translate }}
        </ion-button>

        <!--서비스 소개-->
        <div
          (mouseenter)='serviceMenu($event,"edu")'
          (mouseleave)='serviceMenuleave()' class='position-relative cursor-pointer'
        >
          <ion-button class='pc-top-menu overflow-unset'
                      color='dark' expand='block' fill='clear' id='header-intro' mode='md'
                      (click)='serviceMenuGo()'
          >
            {{ 'edu' | translate }}
          </ion-button>
          <div
            (mouseenter)='isServiceMenuOver = true' (mouseleave)='isServiceMenu = false; isServiceMenuOver = false'
            *ngIf='isServiceMenu' class='menu_sub_out'
          >
            <div class='z-depth-1-half menu_sub' style='width: 100%;'>
              <!--   style="pointer-events: none" 위에 삭제 클릭이 안되는 이슈. 전체적인 버전업이되면서 발생하는 이슈         -->
              <hr class='p-0 m-0' />
              <ion-button (click)='app.serviceIntroductionLinkUseCases()'
                          class='pc-sub-menu w-100' color='dark' expand='block' fill='clear'
                          mode='md'
              >
                {{ 'use cases' | translate }}
              </ion-button>
              <hr class='p-0 m-0' />
              <ion-button (click)='app.serviceIntroductionLinkDigitalTextbook()'
                          class='pc-sub-menu w-100' color='dark' expand='block' fill='clear'
                          mode='md'
              >
                {{ 'digital textbook' | translate }}
              </ion-button>
            </div>
          </div>
        </div>

        <div
          (mouseenter)='serviceMenu($event,"enterprise")'
          (mouseleave)='serviceMenuleave()' class='position-relative cursor-pointer'
        >
          <ion-button class='pc-top-menu overflow-unset'
                      color='dark' expand='block' fill='clear' mode='md'
                      (click)='app.serviceIntroductionLinkBusiness()'
          >
            {{ 'enterprise' | translate }}
          </ion-button>
          <div
            (mouseenter)='isEnterpriseMenuOver = true'
            (mouseleave)='isEnterpriseMenu = false; isEnterpriseMenuOver = false'
            *ngIf='isEnterpriseMenu' class='menu_sub_out' style='left: -25px;'
          >
            <div class='z-depth-1-half menu_sub' style='width: 100%;'>
              <ion-button (click)='app.goExternal("https://tooning.biz")'
                          class='pc-sub-menu w-100' color='dark' expand='block' fill='clear'
                          mode='md' style='text-transform: none'
              >
                {{ 'tooning biz' | translate }}
              </ion-button>
              <hr class='p-0 m-0' />
              <ion-button (click)='app.goExternal("https://tooning.studio")'
                          class='pc-sub-menu w-100' color='dark' expand='block' fill='clear'
                          mode='md' style='text-transform: none'
              >
                {{ 'tooning studio' | translate }}
              </ion-button>
            </div>
          </div>
        </div>

        <!-- 임시 3d 버튼 -->
        <ion-button
          (click)='moveTo3dEditor()' *ngIf='app.isAdmin' class='pc-top-menu overflow-unset' data-cy='myWork'
          expand='block'
          fill='clear'
          mode='md'
          style='width: 100px; border-radius: 50%; background: #000000;'
        >
          <img alt='logo.svg' src='assets/4cut-edit-3d/logo.png' />
        </ion-button>
      </ng-container>

      <!--   Tooning Studio   -->
      <ng-container *ngIf='app.currentService===serviceTypeEnum.Studio'>
        <!-- 오리지날 로고 -->
        <div class='w-100 h-100 position-relative logo-wrapper studio'>
          <img
            (click)='app.go("/")'
            alt='logo.svg' class='img-fluid position-absolute cursor-pointer'
            src='/assets/studio/logo_studio.svg'
            style='height: 30px; top: 19px; left: 0'
          />
        </div>

      </ng-container>

      <!--   Tooning Magic   -->
      <ng-container *ngIf='app.currentService===serviceTypeEnum.Magic'>
        <!-- 오리지날 로고 -->
        <div class='w-100 h-100 position-relative logo-wrapper magic' (click)='app.go("/")'>
          <img
            (click)='app.go(serviceTypeEnum.Magic)'
            alt='logo.svg' class='img-fluid position-absolute cursor-pointer'
            src='/assets/magic/landing/magic-edu-logo.svg'
            style='height: 20px; top: 28px; left: 0'
          />
        </div>
        <ion-button
          color='dark' expand='block' fill='clear' mode='md' class='pc-top-menu'
          (click)='app.go("magic/tooning-price-plan")'
        >
          {{ 'price' | translate }}
        </ion-button>
        <ion-button
          color='dark' expand='block' fill='clear' mode='md' class='pc-top-menu'
          (click)='app.goExternal("https://magic-edu-info.tooning.io/")'
        >
          {{ 'service introduction' | translate }}
        </ion-button>
        <ion-button *ngIf='app.serviceID!==serviceTypeEnum.Studio'
                    color='dark' expand='block' fill='clear' mode='md' class='pc-top-menu' (click)='goToMagicStudio()'
        >
          {{ 'magic.to studio' | translate }}
        </ion-button>
      </ng-container>

      <!-- 투닝 GPT -->
      <ng-container *ngIf='app.currentService===serviceTypeEnum.Gpt'>
        <div class='w-100 h-100 position-relative d-flex align-items-center logo-wrapper gpt'>
          <img (click)='app.go("gpt/editor")'
               class='img-fluid position-absolute cursor-pointer'
               src='/assets/gpt/editor/tooning_gpt_logo.svg'
               style='height: 32px;' />
        </div>
        <ion-button
          (click)='goPayPlan()'
          class='pc-top-menu overflow-unset' color='dark' data-cy='price' expand='block' fill='clear'
          id='header-pricing-gpt'
          mode='md'>
          {{ 'price' | translate | titlecase }}
        </ion-button>
        <ion-button
          (click)='goToGptInfoPage(gptInfoPage.Main)'
          class='pc-top-menu overflow-unset' color='dark' expand='block'
          fill='clear'
          id='header-intro-gpt'
          mode='md'>
          {{ 'service introduction' | translate }}
        </ion-button>
        <ion-button
          (click)='goToGptInfoPage(gptInfoPage.Edu)'
          class='pc-top-menu overflow-unset' color='dark' expand='block'
          fill='clear'
          id='header-edu-gpt'
          mode='md'>
          {{ '교육용 웹툰' | translate }}
        </ion-button>
        <ion-button
          (click)='goToGptInfoPage(gptInfoPage.Student)'
          class='pc-top-menu overflow-unset' color='dark' expand='block'
          fill='clear'
          id='header-student-gpt'
          mode='md'>
          {{ '아이들이 만든 웹툰' | translate }}
        </ion-button>
        <ion-button
          (click)='goToGptInfoPage(gptInfoPage.Policy)'
          class='pc-top-menu overflow-unset' color='dark' expand='block'
          fill='clear'
          mode='md'>
          {{ 'AI 교육방침' | translate }}
        </ion-button>
      </ng-container>

      <!-- 투닝 BOARD -->
      <ng-container *ngIf='app.currentService===serviceTypeEnum.Board'>
        <div class='w-100 h-100 position-relative d-flex align-items-center logo-wrapper board'>
          <img (click)='app.go("board")'
               class='img-fluid position-absolute cursor-pointer' src='/assets/board/logo.svg' alt='logo'
               style='height: 32px; object-fit: contain' />
        </div>
        <ion-button
          class='pc-top-menu overflow-unset' color='dark' data-cy='price' expand='block' fill='clear'
          mode='md' (click)='goPayPlan()'>
          {{ 'price' | translate | titlecase }}
        </ion-button>
        <ion-button
          class='pc-top-menu overflow-unset' color='dark' data-cy='service introduction' expand='block'
          fill='clear'
          mode='md' (click)='goToBoardInfoPage()'>
          {{ 'service introduction' | translate }}
        </ion-button>
      </ng-container>

    </div>

    <!--        뒤부분           -->
    <div class='d-flex justify-content-end align-items-center' style='height: 70px'>

      <div *ngIf='!app.isIos()'>

        <ion-button (click)='moveToCardRegistration()'
                    *ngIf='!app.isStudio() && app.currentService!==serviceTypeEnum.Magic&&!app.isPaidMember && app.loginStatus'
                    class='btn_2 pro-btn' data-cy='moveToCardRegistration'
                    expand='block'
                    fill='outline' mode='ios' style='height: 44px !important;'>
          <ion-icon src='/assets/4cut-make-manual/pro_icon.svg'></ion-icon>
          {{ 'tooning PRO' | translate }}
        </ion-button>
      </div>

      <button (click)='openModalLogin()'
              *ngIf='!app.isStudio() && !app.loginStatus&&app.hereUrl!=="login"&&app.hereUrl!=="signup"'
              class='sign-btn' data-cy='login-modal-btn'
              id='header-signin'
              matRipple>{{ 'login' | translate }}
      </button>
      <div *ngIf='!app.isStudio() && !app.loginStatus&&app.hereUrl!=="login"&&app.hereUrl!=="signup"'
           style='background: #c2c2c2; width: 1px; height: 12px'></div>
      <button (click)='openModalLogin(true)'
              *ngIf='!app.isStudio() && !app.loginStatus&&app.hereUrl!=="login"&&app.hereUrl!=="signup"'
              class='sign-btn'
              data-cy='signup-modal-btn' id='header-signup'
              matRipple>{{ 'signup' | translate }}
      </button>


      <ion-button (click)='openModalLogin()'
                  *ngIf='!app.isStudio() && app.currentService===serviceTypeEnum.Editor && !app.loginStatus&&app.hereUrl!=="login"&&app.hereUrl!=="signup"&&!app.isWidthMin(850)'
                  class='ion-btn-style right-button-margin' expand='block'
                  mat-ripple mode='ios'
      >{{ 'making tooning' | translate }}
      </ion-button>
      <ion-button (click)='goToEditor()' *ngIf='!app.isStudio() && app.loginStatus && !app.isWidthMin(850) &&
                    app.currentService===serviceTypeEnum.Editor'
                  class='ion-btn-style right-button-margin' data-cy='topOpenMakeToon' expand='block'
                  id='header-get-started' mat-ripple
                  mode='ios'
      >{{ 'making tooning' | translate }} </ion-button>

      <div (mousedown)='showPopover($event)' *ngIf='!app.isStudio() && app.loginStatus' class='right-button-margin'
           data-cy='showPopover'
           slot='end'>
        <ion-avatar
          [ngClass]='app.isAdmin && userRole.admin' class='cursor-pointer' fill='clear' matRipple
          mode='md'
          slot='end'
        >
          <img [src]="app.cache.user.userImgUrl !== null ? app.cache.user.userImgUrl : '/assets/common/avatar.svg'"
               alt='User Image' data-cy='userImgUrl' />
        </ion-avatar>

      </div>

      <div
        (click)='app.changeLanguageService.alertChangeLanguage()'
        *ngIf='app.currentService !== serviceTypeEnum.Gpt && app.currentService !== serviceTypeEnum.Board'
        class='d-flex justify-content-center align-items-center cursor-pointer'
        style='margin-left: 13px'
      >
        <ion-icon name='globe-outline' data-cy='desktop-header-language-changer'
                  style='font-size: 19px; margin-right: 12px'></ion-icon>
      </div>
    </div>
  </div>
</ion-header>

<!-- 모바일용-->
<ion-header *ngIf='!app.isStudio() && app.plt.width()<=1024' [ngClass]='{
     "editor-mode": app.currentService!==serviceTypeEnum.Editor,
     "magic-mode": app.currentService===serviceTypeEnum.Magic,
     "studio-mode": app.currentService===serviceTypeEnum.Studio,
     "gpt-mode": app.currentService===serviceTypeEnum.Gpt }'
            class='disable-zoom ion-no-border header-must-be-top'
            data-cy='appHeaderMb'
            mode='ios'>

  <ion-toolbar>
    <ng-container *ngIf='!cut.showSearchBar'>
      <div (click)='isServiceSelectPopoverOpen=!isServiceSelectPopoverOpen'
           (clickOutside)='isServiceSelectPopoverOpen = false'
           class='service-cell'>
        <div class='logo' data-cy='logo'>
          <img (click)='goToLanding(app.currentService)'
               [src]='"/assets/tooning_landing/logos/logo_"+app.currentService+".svg"' alt='logo-title.svg'
               class='img-fluid' />
        </div>

        <div
          class='service-type-text d-flex align-items-start'>{{ app.usedLanguage === languageEnum.ko ? titles[app.currentService][languageEnum.ko] : titles[app.currentService][languageEnum.en] }}
          <span *ngIf='app.currentService === serviceTypeEnum.Gpt' class='gpt-beta'>Beta</span>
        </div>
        <button [ngClass]='isServiceSelectPopoverOpen&&"show-popover"' class='chevron-down'>
          <ion-icon name='chevron-down-outline'></ion-icon>
        </button>
      </div>
      <ion-buttons data-cy='headerBtnMb' slot='end'>
        <ion-button (click)='openSideMenu()' data-cy='hamburger' id='hamburger'>
          <ion-icon [src]='"/assets/template/menu_button.svg"' slot='icon-only'></ion-icon>
        </ion-button>
      </ion-buttons>
    </ng-container>
  </ion-toolbar>
</ion-header>
<div (click)='isServiceSelectPopoverOpen=!isServiceSelectPopoverOpen'
     *ngIf='isServiceSelectPopoverOpen&&app.plt.width()<=1024'
     [ngClass]='{
     "editor-mode": app.currentService!==serviceTypeEnum.Editor,
     "magic-mode": app.currentService===serviceTypeEnum.Magic,
     "gpt-mode": app.currentService===serviceTypeEnum.Gpt }'
     class='service-popover'>
  <div (click)='goToLanding(title)' *ngFor='let title of serviceTitles; let last = last;'>
    <div
      [ngClass]='app.currentService===title?"selected-cell":"unselected"'
      class='service-cell' *ngIf='app.usedLanguage === languageEnum.ko || visibleServiceTitleByKo.includes(title)'
    >
      <div class='logo'>
        <img [src]='"/assets/tooning_landing/logos/logo_"+title+".svg"'
             alt='logo-title.svg' class='img-fluid' />
      </div>
      <div
        class='service-type-text d-flex align-items-start '>
        {{ app.usedLanguage === languageEnum.ko ? titles[title][languageEnum.ko] : titles[title][languageEnum.en] }}
        <span *ngIf='title === serviceTypeEnum.Gpt || title === serviceTypeEnum.Board'
              style='color:#242424'
              class='gpt-beta'>Beta</span>
      </div>
      <div *ngIf='app.currentService===title' class='check-icon'>
        <img [src]='"/assets/tooning_landing/check-icon_"+app.currentService+".svg"' alt='check-icon-service.svg'
             class='img-fluid' />
      </div>
    </div>

  </div>
</div>
<ion-backdrop (ionBackdropTap)='isServiceSelectPopoverOpen = false' *ngIf='isServiceSelectPopoverOpen' [visible]='true'
              style='opacity: 0.4; background: #000; z-index:500'></ion-backdrop>
