import { IsOptional, IsString } from 'class-validator';

/**
 * 캔버스 정렬할때 필요한 OrderBy
 */
export class OrderBy {
  /**
   * sort: 어떤걸로 정렬할지
   * order: 내림차순 오름차순
   */
  @IsString() @IsOptional() sort?: string;
  @IsString() @IsOptional() order?: string;

  // tslint:disable-next-line:variable-name

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
