import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { GraphqlApiService } from '../api/graphql.api.service';
import { EduEstimate, EduGroupPriceForm, RejectEduApplicant } from '../../interfaces/app.interface';
import { gql, ApolloQueryResult, FetchResult } from '@apollo/client';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  InputCreateEduEstimate,
  InputEduGroupPayment,
  InputGetEduApplicantList,
  InputGetEduEstimateList,
  InputGetEduGroupPrice
} from '../../model/edu/eduPlan';
import { UserLoginType } from '../../enum/UserLoginType.enum';
import { GetEduApplicantsListData } from '../../interfaces/graphql.interface';
import { EduApplicantStatus, EstimateStep, RejectedReason } from '../../enum/app.enum';

@Injectable({
  providedIn: 'root'
})
export class EduApplicantsService {
  constructor(private graphql: GraphqlApiService, public app: AppService) {}

  /**
   * 신청자 업로드 쿼리 요청 함수
   * @param inputEduApplicants
   * @return {Observable<FetchResult<boolean>>}
   */
  public createEduApplicants(inputEduApplicants: any): Observable<FetchResult<boolean>> {
    return this.graphql
      .mutate(
        gql`
          mutation createEduApplicants($inputEduApplicants: InputEduApplicants!) {
            createEduApplicants(inputEduApplicants: $inputEduApplicants)
          }
        `,
        { inputEduApplicants }
      )
      .pipe(
        tap((result) => {
          result = result.data.createEduApplicants;
          return result;
        })
      );
  }

  /**
   * 신청자 리스트 가져워는 쿼리 요청 함수
   * @param inputGetApplicantList
   * @return {Observable<ApolloQueryResult<any>>} *
   */
  public getEduApplicantsList(inputGetApplicantList: InputGetEduApplicantList): Observable<ApolloQueryResult<GetEduApplicantsListData[]>> {
    return this.graphql
      .query(
        gql`
          query getEduApplicantsList($inputGetApplicantList: InputGetEduApplicantList!) {
            getEduApplicantsList(inputGetApplicantList: $inputGetApplicantList) {
              id
              realName
              agency
              others
              phoneNum
              fileName
              authFile
              createdDate
              updatedDate
              isDuplicate
              tooningEmail
              rejectedReason
              applyStatus
            }
          }
        `,
        { inputGetApplicantList }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getEduApplicantsList;
        })
      );
  }

  /**
   * Pro 업데이트 해주는 쿼리 요청 함수
   * @param {number[]} applicantIdList
   * @return {Observable<FetchResult<boolean>>}
   */
  public updateProForEduApplicants(applicantIdList: number[]): Observable<FetchResult<boolean>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateProForEduApplicants($applicantIdList: [ID!]!) {
            updateProForEduApplicants(applicantIdList: $applicantIdList)
          }
        `,
        { applicantIdList }
      )
      .pipe(
        tap((result) => {
          result = result.data.updateProForEduApplicants;
          return result;
        })
      );
  }

  /**
   * 반려처리 함수 쿼리 요청 함수
   * @param {RejectEduApplicant[]} applicantIdList
   * @return {Observable<FetchResult<any>>}
   */
  public updateRejectForEduApplicants(applicantList: RejectEduApplicant[]): Observable<FetchResult<boolean>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateRejectForEduApplicants($applicantList: [InputEduPlanRejectApplicants!]!) {
            updateRejectForEduApplicants(applicantList: $applicantList)
          }
        `,
        { applicantList }
      )
      .pipe(
        tap((result) => {
          result = result.data.updateRejectForEduApplicants;
          return result;
        })
      );
  }

  /**
   * 대기중인 신청자 확인 쿼리 요청 함수
   * @return {Observable<ApolloQueryResult<boolean>>}
   */
  public checkPendingApplicants(): Observable<ApolloQueryResult<boolean>> {
    return this.graphql
      .query(
        gql`
          query {
            checkPendingApplicants
          }
        `
      )
      .pipe(
        map((result) => {
          result = result.data.checkPendingApplicants;
          return result;
        })
      );
  }

  /**
   * 이미 신청한 유저인지 체크하는 쿼리 요청 함수
   * @param {number} userId
   * @return {Observable<ApolloQueryResult<boolean>>}
   */
  public checkAlreadyAppliedUser(userId: number): Observable<ApolloQueryResult<boolean>> {
    return this.graphql
      .query(
        gql`
          query checkAlreadyAppliedUser($userId: ID!) {
            checkAlreadyAppliedUser(userId: $userId)
          }
        `,
        { userId }
      )
      .pipe(
        map((result) => {
          result = result.data.checkAlreadyAppliedUser;
          return result;
        })
      );
  }

  /**
   * 교육 pro 신청을 return 받는 함수, notApply, complete, rejected, pending
   * @param {number} userId
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getApplicantStatus(userId: number): Observable<ApolloQueryResult<{ getApplicantStatus: EduApplicantStatus }>> {
    return this.graphql.query(
      gql`
        query getApplicantStatus($userId: ID!) {
          getApplicantStatus(userId: $userId)
        }
      `,
      { userId }
    );
  }

  /**
   * 중복으로 신청한 경우 관련 메일을 가져오는 함수
   * @param {number} userId
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getDuplicateApplicantEmail(userId: number): Observable<ApolloQueryResult<{ getDuplicateApplicantEmail: string }>> {
    return this.graphql.query(
      gql`
        query getDuplicateApplicantEmail($userId: ID!) {
          getDuplicateApplicantEmail(userId: $userId)
        }
      `,
      { userId }
    );
  }

  /**
   * 반려 사유 가져오는 함수
   * @param {number} userId
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getRejectedReason(userId: number): Observable<ApolloQueryResult<{ getRejectedReason: RejectedReason }>> {
    return this.graphql.query(
      gql`
        query getRejectedReason($userId: ID!) {
          getRejectedReason(userId: $userId)
        }
      `,
      { userId }
    );
  }

  /**
   * 단일 신청자 정보 가져오는 쿼리
   * @param {number} userId
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getApplicantInfo(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getApplicantInfo($userId: ID!) {
            getApplicantInfo(userId: $userId) {
              realName
              agency
              others
              phoneNum
              authFile
              educatorNumber
              fileName
            }
          }
        `,
        { userId }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getApplicantInfo;
          return result;
        })
      );
  }

  /**
   * 반려된 신청자 재신청 하는 쿼리
   * @param inputEduApplicants
   * @return {Observable<FetchResult<any>>}
   */
  public updateEduApplicantInfo(inputEduApplicants: any): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateEduApplicantInfo($inputEduApplicants: InputEduApplicants!) {
            updateEduApplicantInfo(inputEduApplicants: $inputEduApplicants)
          }
        `,
        { inputEduApplicants }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.updateEduApplicantInfo;
          return result;
        })
      );
  }

  /**
   * 신청자 전체 개수 가져오는 쿼리, 한번에 너무 많이 가져 오면 컴퓨터에 렉이 걸려 먼저 체크해주는 함수입니다
   * @param {InputGetEduApplicantList} inputGetApplicantList
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getEduApplicantsCount(inputGetApplicantList: InputGetEduApplicantList): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getEduApplicantsCount($inputGetApplicantList: InputGetEduApplicantList!) {
            getEduApplicantsCount(inputGetApplicantList: $inputGetApplicantList)
          }
        `,
        { inputGetApplicantList }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getEduApplicantsCount;
          return result;
        })
      );
  }

  /**
   * 견적서 생성 요청 함수
   * @param {InputCreateEduEstimate} inputCreateEduEstimate
   * @return {Observable<FetchResult<any>>}
   */
  public createEduEstimate(inputCreateEduEstimate: InputCreateEduEstimate): Observable<FetchResult<number>> {
    return this.graphql
      .mutate(
        gql`
          mutation createEduEstimate($inputCreateEduEstimate: InputCreateEduEstimate!) {
            createEduEstimate(inputCreateEduEstimate: $inputCreateEduEstimate)
          }
        `,
        { inputCreateEduEstimate }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.createEduEstimate;
          return result;
        })
      );
  }

  /**
   * 이전에 견적서 가져오는 쿼리 요청 함수
   * @param {number} estimateId 견적서 아이디
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getEduEstimate(estimateId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getEduEstimate($estimateId: ID!) {
            getEduEstimate(estimateId: $estimateId) {
              estimateStep
              applicantName
              applicantPhoneNum
              applicantEmail
              officeEmail
              officePhoneNum
              agency {
                agencyType
                agencyName
              }
              eduGroupPrice {
                id
                usedPeriod
                usedPeriodUnit
                pricePerPerson
              }
              user {
                id
              }
              userCount
              totalPrice
              startDate
              loginType
              userListFilePath
              userListFileName
            }
          }
        `,
        { estimateId }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getEduEstimate;
          return result;
        })
      );
  }

  /**
   * 견적서 유저 리스트 업데이트 쿼리 요청 함수
   * @param {number} estimateId 견적서 아이디
   * @param {string} userListFileName 유저 리스트 파일 명
   * @param {string} userListFilePath 유저 리스트 파일 s3 경로
   * @param {UserLoginType} loginType 로그안 타입
   * @return {Observable<FetchResult<boolean>>}
   */
  updateEduEstimateUserList(
    estimateId: number,
    userListFileName: string,
    userListFilePath: string,
    loginType: UserLoginType
  ): Observable<FetchResult<boolean>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateEduEstimateUserList($estimateId: ID!, $userListFileName: String!, $userListFilePath: String!, $loginType: EduLoginType!) {
            updateEduEstimateUserList(
              estimateId: $estimateId
              userListFileName: $userListFileName
              userListFilePath: $userListFilePath
              loginType: $loginType
            )
          }
        `,
        { estimateId, userListFileName, userListFilePath, loginType }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.updateEduEstimateUserList;
          return result;
        })
      );
  }

  /**
   * 결제 정보 업데이트 쿼리 요청 함수
   * @param {InputEduGroupPayment} inputEduGroupPayment
   * @return {Observable<FetchResult<any>>}
   */
  updateEduEstimatePayment(inputEduGroupPayment: InputEduGroupPayment): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateEduEstimatePayment($inputEduGroupPayment: InputEduGroupPayment!) {
            updateEduEstimatePayment(inputEduGroupPayment: $inputEduGroupPayment)
          }
        `,
        { inputEduGroupPayment }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.updateEduEstimatePayment;
          return result;
        })
      );
  }

  /**
   * 견적서 이미지 업데이트 쿼리 요청 함수
   * @param {number} estimateId 견적서 아이디
   * @param {string} estimateImgPath 견적서 이미지 s3 경로
   * @return {Observable<FetchResult<any>>}
   */
  updateEstimateImg(estimateId: number, estimateImgPath: string): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateEstimateImg($estimateId: ID!, $estimateImgPath: String!) {
            updateEstimateImg(estimateId: $estimateId, estimateImgPath: $estimateImgPath)
          }
        `,
        { estimateId, estimateImgPath }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.updateEstimateImg;
          return result;
        })
      );
  }

  /**
   * 단체 등록 요금제 생성 쿼리 요청 함수
   * @param {EduGroupPriceForm} inputAddEduGroupPrice
   * @return {Observable<FetchResult<any>>}
   */
  createEduGroupPrice(inputAddEduGroupPrice: EduGroupPriceForm): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation createEduGroupPrice($inputAddEduGroupPrice: InputAddEduGroupPrice!) {
            createEduGroupPrice(inputAddEduGroupPrice: $inputAddEduGroupPrice)
          }
        `,
        { inputAddEduGroupPrice }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.createEduGroupPrice;
          return result;
        })
      );
  }

  /**
   * 단체등록 요금제 get 쿼리 요청 함수
   * @return {Observable<ApolloQueryResult<Array<EduGroupPriceForm>>>}
   */
  getEduGroupPriceList(): Observable<ApolloQueryResult<Array<EduGroupPriceForm>>> {
    return this.graphql
      .query(
        gql`
          query getEduGroupPriceList {
            getEduGroupPriceList {
              id
              minPersonnel
              maxPersonnel
              usedPeriod
              usedPeriodUnit
              pricePerPerson
              costPerPerson
              currency
              isPromotion
              promotionStartDate
              promotionEndDate
              order
            }
          }
        `
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getEduGroupPriceList;
          result.data.forEach((groupPrice) => {
            delete groupPrice['__typename'];
          });
        })
      );
  }

  /**
   * 단체등록 요금제 갱신 쿼리 요청 함수
   * @return {Observable<FetchResult<boolean>>}
   * @param inputAddEduGroupPriceList
   */
  updateEduGroupPrice(inputAddEduGroupPriceList: Array<EduGroupPriceForm>): Observable<FetchResult<boolean>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateEduGroupPrice($inputAddEduGroupPriceList: [InputAddEduGroupPrice!]!) {
            updateEduGroupPrice(inputAddEduGroupPriceList: $inputAddEduGroupPriceList)
          }
        `,
        { inputAddEduGroupPriceList }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.updateEduGroupPrice;
          return result;
        })
      );
  }

  /**
   * 단체등록 요금제 삭제 쿼리 요청 함수
   * @param {number} targetId
   * @return {Observable<FetchResult<boolean>>}
   */
  deleteEduGroupPrice(targetId: number): Observable<FetchResult<boolean>> {
    return this.graphql
      .mutate(
        gql`
          mutation deleteEduGroupPrice($targetId: ID!) {
            deleteEduGroupPrice(targetId: $targetId)
          }
        `,
        { targetId }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.deleteEduGroupPrice;
          return result;
        })
      );
  }

  /**
   * 견적서 단계만 업데이트 요청 쿼리
   * @param {number} estimateId 견적서 아이디
   * @param {EstimateStep} estimateStep 견적서 단계
   * @param {Date} startDate 단체 요금 시작일자
   * @return {Observable<FetchResult<boolean>>}
   */
  updateEstimateStep(estimateId: number, estimateStep: EstimateStep, startDate?: Date): Observable<FetchResult<boolean>> {
    return this.graphql.mutate(
      gql`
        mutation updateEstimateStep($estimateId: ID!, $estimateStep: EstimateStep!, $startDate: DateTime) {
          updateEstimateStep(estimateId: $estimateId, estimateStep: $estimateStep, startDate: $startDate)
        }
      `,
      {
        estimateId,
        estimateStep,
        startDate
      }
    );
  }

  /**
   * 견적서 리스트 가져오는 쿼리 요청 함수
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getEduEstimateList(inputGetEduEstimateList: InputGetEduEstimateList): Observable<ApolloQueryResult<Array<EduEstimate>>> {
    return this.graphql
      .query(
        gql`
          query getEduEstimateList($inputGetEduEstimateList: InputGetEduEstimateList!) {
            getEduEstimateList(inputGetEduEstimateList: $inputGetEduEstimateList) {
              estimateStep
              applicantName
              applicantPhoneNum
              applicantEmail
              officeEmail
              officePhoneNum
              createdDate
              agency {
                agencyType
                agencyName
              }
              eduGroupPrice {
                id
                usedPeriod
                usedPeriodUnit
                pricePerPerson
              }
              groupPayment {
                payType
                payStatus
                paypelLinkPayment {
                  payTime
                  approvalNumber
                  saleSlipLink
                  orderId
                  payGoods
                }
              }
              user {
                id
              }
              userCount
              totalPrice
              startDate
              loginType
              userListFilePath
              userListFileName
            }
          }
        `,
        { inputGetEduEstimateList }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getEduEstimateList;
        })
      );
  }

  /**
   * 단체 등록 이메일 검증 쿼리
   * @param {string[]} emails
   * @param {UserLoginType} type
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public emailValidationCheck(emails: string[], type: UserLoginType): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query emailValidationCheck($emails: [String!]!, $type: String!) {
          emailValidationCheck(emails: $emails, type: $type)
        }
      `,
      { emails, type }
    );
  }

  public deleteEstimate(estimateId: number): Observable<FetchResult<boolean>> {
    return this.graphql.mutate(gql`
      mutation deleteEstimate($estimateId: Int!) {
        deleteEstimate(estimateId: $estimateId)
      }
    `);
  }
}
