import { Injectable } from '@angular/core';
import { GraphqlApiService } from '../api/graphql.api.service';
import { gql, ApolloQueryResult, FetchResult } from '@apollo/client';
import { Loading } from '../loading';
import { AppService } from '../app.service';
import { CanvasGroup, CanvasGroupShare } from '../../model/canvasGroup/canvasGroup';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TooningCanvasGroupNewMakeError } from '../../pages-tooning/errors/TooningErrors';

@Injectable({
  providedIn: 'root'
})
export class CanvasGroupService {
  public newCanvasGroupId;

  constructor(private graphql: GraphqlApiService, public app: AppService) {}

  /**
   * 캔버스그룹 생성 함수
   * @param data 캔버스 그룹 생성에 필요한 데이터듫
   * @param isTemplateId 템플릿 아이디인지 여부 확인하는 식별자
   */
  public async canvasGroupCreate(data: CanvasGroup, isTemplateId): Promise<any> {
    const canvasGroup: any = await this.graphql
      .mutate(
        gql`
          mutation canvasGroupCreate($data: InputCanvasGroupCreate!, $isTemplateId: Boolean!) {
            canvasGroupCreate(data: $data, isTemplateId: $isTemplateId)
          }
        `,
        { data, isTemplateId }
      )
      .toPromise();
    return +canvasGroup.data.canvasGroupCreate;
  }

  // get canvas multi
  /**
   * 캔버스 그룹들 가져와는 함수
   * @param userId 유저아이디를 넣어서 root 바로 밑에 캔버스 그룹들만 가져옴
   */
  public getCanvasGroups(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getCanvasGroups($userId: ID!) {
            getCanvasGroups(userId: $userId) {
              id
              role
              groupName
              canvasCount
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getCanvasGroups.map((canvasGroup) => {
            canvasGroup = new CanvasGroup().deserialize(canvasGroup);
            return canvasGroup;
          });
        })
      );
  }

  /**
   * 캔버스 그룹 제목 변경 함수
   * @param data 필요한 정보, 캔버스 그룹 관련
   * @param afterGroupName 변경할 제목
   */
  async canvasGroupRename(data: CanvasGroup, afterGroupName: string): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation canvasGroupRename($data: InputCanvasGroupUpdate!, $afterGroupName: String!) {
            canvasGroupRename(data: $data, afterGroupName: $afterGroupName)
          }
        `,
        { data, afterGroupName }
      )
      .toPromise();
    return result;
  }

  /**
   * 캔버스를 캔버스 그룹으로 옯기는 함수
   * @param canvasId 옮길 캔버스 아이디
   * @param groupId 캔버스 그룹 아이디
   */
  public async canvasGoToGroup(canvasId: number, groupId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation canvasGoToGroup($canvasId: Int!, $groupId: Int!) {
            canvasGoToGroup(canvasId: $canvasId, groupId: $groupId)
          }
        `,
        {
          canvasId,
          groupId
        }
      )
      .toPromise();
    return result;
  }

  /**
   * 캔버스 그룹을 휴지통으로 보내는 함수
   * @param userId 휴지통 찾을때 사용하는 유저아이다
   * @param groupId 타겟 그룹 아이디
   */
  public async canvasGroupDelete(userId: number, groupId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation canvasGroupDelete($userId: Float!, $groupId: Float!) {
            canvasGroupDelete(userId: $userId, groupId: $groupId)
          }
        `,
        { userId, groupId }
      )
      .toPromise();
    return result;
  }

  /**
   * 휴지통에 있는 캔버스랑 캔버스 그룹 가져오는 함수
   * @param userId 휴지통 찾읋때 필요한 유저아이디
   */
  public getRecycleBin(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query getRecycleBin($userId: Float!) {
          getRecycleBin(userId: $userId) {
            groups {
              id
              canvasCount
              groupName
            }
            canvases {
              id
              title
              shareStatus
              base64
              userId
              authorization
            }
            recycleBinGroup {
              id
              groupName
            }
          }
        }
      `,
      { userId }
    );
  }

  /**
   * 휴지통에 있는 작업물과 캔버스의 개수를 가져오는 함수
   * @param userId
   */
  public countRecycleBinData(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query countRecycleBinData($userId: Float!) {
          countRecycleBinData(userId: $userId) {
            countCanvasesinRecycleBin
            countFoldersinRecycleBin
          }
        }
      `,
      { userId }
    );
  }

  /**
   * 휴지통 비우기(영구 삭제) 함수
   * @param userId 휴지통 찾을때 필요한 유저 아이디
   */
  async emptyRecycleBin(userId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation emptyRecycleBin($userId: ID!) {
            emptyRecycleBin(userId: $userId)
          }
        `,
        { userId }
      )
      .toPromise();
    return result;
  }

  /**
   * 휴지통에서 복구하는 함수
   * @param targetType work이면 작업물, canvasGroup이면 폴더
   * @param targetId 타겟의 아이디
   * @param userId 휴지통 찾을때 필요한 유저 아이디
   */
  async restoreFromRecycleBin(targetType: string, targetId: number, userId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation restoreFromRecycleBin($targetType: String!, $targetId: Float!, $userId: Float!) {
            restoreFromRecycleBin(targetType: $targetType, targetId: $targetId, userId: $userId)
          }
        `,
        { targetType, targetId, userId }
      )
      .toPromise();
    return result;
  }

  /**
   * 영구 삭제 함수
   * @param targetType work면 작업물, canvasGroup이면 폴더
   * @param targetId 타겟 아이디
   */
  async deleteForever(targetType: string, targetId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation deleteForever($targetType: String!, $targetId: Float!) {
            deleteForever(targetType: $targetType, targetId: $targetId)
          }
        `,
        { targetType, targetId }
      )
      .toPromise();
    return result;
  }

  /**
   * 캔버스 그룹 새로 만들기
   * @param canvasGroupName
   * @param isTemplate
   */
  public async canvasGroupNewMake(canvasGroupName: string = 'no name', isTemplate: boolean = false): Promise<CanvasGroup> {
    let newCanvasGroup;
    const loading = new Loading();
    await loading.showLoader('');
    try {
      if (!this.app.loginStatus) {
        this.app.go('/login');
        return;
      }

      // 폴더 생성코드
      newCanvasGroup = new CanvasGroup();
      newCanvasGroup.userId = this.app.cache.user.id;
      newCanvasGroup.groupName = canvasGroupName;
      this.newCanvasGroupId = await this.canvasGroupCreate(newCanvasGroup, isTemplate);
      newCanvasGroup.id = this.newCanvasGroupId;
      return newCanvasGroup;
    } catch (e) {
      throw new TooningCanvasGroupNewMakeError(e, null, true);
    } finally {
      loading.hideLoader();
    }
  }

  /**
   * 휴지통 있는지 확인하는 함수
   * @param userId
   */
  async checkRecycleBinGroup(userId: number): Promise<ApolloQueryResult<any>> {
    return (
      await this.graphql
        .query(
          gql`
            query checkRecycleBinGroup($userId: Float!) {
              checkRecycleBinGroup(userId: $userId)
            }
          `,
          { userId }
        )
        .toPromise()
    ).data.checkRecycleBinGroup;
  }

  /**
   * 휴지통 생성 함수
   * @param userId
   */
  async createRecycleBinGroup(userId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation createRecycleBinGroup($userId: Float!) {
            createRecycleBinGroup(userId: $userId)
          }
        `,
        { userId }
      )
      .toPromise();
    return result;
  }

  public canvasGroupShare(data: CanvasGroupShare): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation CanvasGroupShare($data: InputCanvasGroupShare!) {
          canvasGroupShare(data: $data) {
            share
            id
          }
        }
      `,
      { data }
    );
  }

  public async submitCanvasGroup(canvasId: number, userId: number, isTextTemplate: boolean, submitCode: string): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation submitCanvasGroup($canvasId: Int!, $userId: Int!, $isTextTemplate: Boolean!, $submitCode: String!) {
            submitCanvasGroup(canvasId: $canvasId, userId: $userId, isTextTemplate: $isTextTemplate, submitCode: $submitCode)
          }
        `,
        { canvasId, userId, isTextTemplate, submitCode }
      )
      .toPromise();
    return result.data.submitCanvasGroup;
  }

  /**
   * 폴더 클릭시 그 폴더에 속한 캔버스 가져오는 함수
   * @param groupId 폴더 아이디
   */
  async getCanvasesOFCanvasGroup(groupId: number): Promise<Array<any>> {
    return (
      await this.graphql
        .query(
          gql`
            query getCanvasesOFCanvasGroup($groupId: ID!) {
              getCanvasesOFCanvasGroup(groupId: $groupId) {
                id
                title
                shareStatus
                base64
                userId
                authorization
                template {
                  id
                  status
                }
                textTemplate {
                  id
                  status
                }
                group {
                  id
                  groupName
                }
              }
            }
          `,
          { groupId }
        )
        .toPromise()
    ).data.getCanvasesOFCanvasGroup;
  }

  /**
   * 루트그룹 있는지 확인하는 함수
   * @param userId
   */
  async checkRootCanvasGroup(userId: number): Promise<ApolloQueryResult<any>> {
    return (
      await this.graphql
        .query(
          gql`
            query checkRootCanvasGroup($userId: Float!) {
              checkRootCanvasGroup(userId: $userId)
            }
          `,
          { userId }
        )
        .toPromise()
    ).data.checkRootCanvasGroup;
  }

  /**
   * 루트 그룹 만들어주는 함수
   * @param userId
   */
  async createRootCanvasGroup(userId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation createRootCanvasGroup($userId: Float!) {
            createRootCanvasGroup(userId: $userId)
          }
        `,
        { userId }
      )
      .toPromise();
    return result;
  }

  /**
   * 켄버스 그룹들에 루트 캔버스 그룹 세팅해주는함수
   * @param userId
   */
  async setRootCanvasGroup(userId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation setRootCanvasGroup($userId: Float!) {
            setRootCanvasGroup(userId: $userId)
          }
        `,
        { userId }
      )
      .toPromise();
    return result;
  }

  /**
   * 캔버스에 루트 캔버스 그룹 세팅해주는 함수
   * @param userId
   */
  async setRootCanvasGroupToCanvas(userId: number): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation setRootCanvasGroupToCanvas($userId: Float!) {
            setRootCanvasGroupToCanvas(userId: $userId)
          }
        `,
        { userId }
      )
      .toPromise();
    return result;
  }

  /**
   * 다수의 캔버스를 휴지통으로 보내는 쿼리 요청 함수
   * @param {number} userId 현재 유저 아이디
   * @param {number[]}idList 캔버스 아이디 Array
   * @return {Promise<boolean>}
   */
  async canvasesDelete(userId: number, idList: number[]): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation canvasesDelete($userId: ID!, $idList: [ID!]!) {
            canvasesDelete(userId: $userId, idList: $idList)
          }
        `,
        { userId, idList }
      )
      .toPromise();
    return result.data.canvasesDelete;
  }

  /**
   * 다수의 캔버스를 폴더로 이동시키는 쿼리 요청 함수
   * @param {number[]} canvasIds 이동할 캔버스 아이디 Array
   * @param {number} groupId 타켓 캔버스 그룹 아이다
   * @param userId rootCanvasGroup을 찾기 위한 유저 아이디
   * @return {Promise<boolean>}
   */
  public async canvasesGoToGroup(canvasIds: number[], groupId: number, userId: number): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation canvasesGoToGroup($canvasIds: [ID!]!, $groupId: Int!, $userId: ID!) {
            canvasesGoToGroup(canvasIds: $canvasIds, groupId: $groupId, userId: $userId)
          }
        `,
        {
          canvasIds,
          groupId,
          userId
        }
      )
      .toPromise();
    return result;
  }

  /**
   * 다수의 캔버스를 영구 삭제 쿼리 요청 함수
   * @param {number[]} targetIds 영구 삭제할 캔버스 아이디 Array
   * @return {Promise<boolean>}
   */
  async deleteForeverCanvases(targetIds: number[]): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation deleteForeverCanvases($targetIds: [ID!]!) {
            deleteForeverCanvases(targetIds: $targetIds)
          }
        `,
        { targetIds }
      )
      .toPromise();
    return result.data.canvasesDelete;
  }

  /**
   * 다수의 캔버스를 휴지통에서 복구시키는 쿼리 요청 함수
   * @param {number[]} targetIds 복구할 타겟 아이디 Array
   * @return {Promise<boolean>}
   */
  async restoreCanvasesFromRecycleBin(targetIds: number[]): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation restoreCanvasesFromRecycleBin($targetIds: [ID!]!) {
            restoreCanvasesFromRecycleBin(targetIds: $targetIds)
          }
        `,
        { targetIds }
      )
      .toPromise();
    return result;
  }

  public async submitCanvasesGroup(canvasIds: number[], userId: number, submitCode: string): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation submitCanvasesGroup($canvasIds: [Int!]!, $userId: Int!, $submitCode: String!) {
            submitCanvasesGroup(canvasIds: $canvasIds, userId: $userId, submitCode: $submitCode)
          }
        `,
        { canvasIds, userId, submitCode }
      )
      .toPromise();
    return result.data.submitCanvasesGroup;
  }

  /**
   * 제출한 폴더 이름과 소유주 이름 가져오는 쿼리 요청함수
   * @param {number} canvasGroupId 알고싶은 폴더의 아이디
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public getSubmittedCanvasGroupInfo(canvasGroupId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getSubmittedCanvasGroupInfo($canvasGroupId: ID!) {
            getSubmittedCanvasGroupInfo(canvasGroupId: $canvasGroupId) {
              canvasGroupName
              userName
            }
          }
        `,
        {
          canvasGroupId
        }
      )
      .pipe(
        map((result) => {
          result = result.data.getSubmittedCanvasGroupInfo;
          return result;
        })
      );
  }

  /**
   * 페이지네이션전용 캔버스 개수 체크함수, 폴더아이디도 들어감
   * @param {number} userId
   * @param {number} canvasGroupId
   * @return {Observable<ApolloQueryResult<any>>}
   */
  public canvasesCountForCanvasGroup(userId: number, canvasGroupId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query canvasesCountForCanvasGroup($userId: ID!, $canvasGroupId: ID) {
            canvasesCountForCanvasGroup(userId: $userId, canvasGroupId: $canvasGroupId)
          }
        `,
        { userId, canvasGroupId }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.canvasesCountForCanvasGroup;
          return result;
        })
      );
  }
}
