import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { Router } from '@angular/router';
import { LanguageType, ServiceType } from '../../../enum/app.enum';
import { MagicGoToSelectServiceError } from '../../errors/MagicErrors';
import { AnalyticsService } from '../../../services/google/analytics/analytics.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-service-tab',
  templateUrl: './service-tab.component.html',
  styleUrls: ['./service-tab.component.scss']
})
export class ServiceTabComponent implements OnInit {
  public serviceTypeEnum = ServiceType;
  public languageType = LanguageType;

  constructor(public app: AppService, public router: Router, public analyticsService: AnalyticsService) {}

  ngOnInit() {

  }

  goToSelectService(selectedService: string): void {
    try {
      switch (selectedService) {
        case ServiceType.Editor:
          this.app.go('/');
          break;
        case ServiceType.Magic:
          this.app.go('magic');
          this.analyticsService.magicTabClick();
          break;
        case ServiceType.Gpt:
          this.app.go('gpt/editor');
          break;
        case ServiceType.Board:
          this.app.go('board');
          break;
        // 다른 선택 서비스에 대한 처리 추가
        case ServiceType.Character:
          // 다른 선택 서비스에 대한 처리 추가
          break;
        default:
          break;
      }
    } catch (e) {
      throw new MagicGoToSelectServiceError(e);
    }
  }

}
