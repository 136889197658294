import { Injectable } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { BoardUserType } from '../interfaces/board.type';
import { UserFromLocalType } from '../interfaces/common.type';
import { BoardApiService } from './board-api.service';
import { ServerErrorCode, SupportedImageFormats } from '../enums/common.enum';
import { LoadingController, ModalController } from '@ionic/angular';
import { PaidGuideModalPage } from '../../../pages-common/paid-guide-modal/paid-guide-modal.page';
import BoardAPIRequestError from '../errors/boardApiError';
import BoardClientError from '../errors/BoardClientError';

@Injectable({
  providedIn: 'root'
})
export class BoardCommonService {
  public loader: HTMLIonLoadingElement = null;
  constructor(private app: AppService, private api: BoardApiService, private loadingCtrl: LoadingController, private modalCtrl: ModalController) {}

  /**
   * 에러 핸들러
   * @param e
   * @param {boolean} showToast
   * @return {Promise<void>}
   */
  async errorHandler(e?: any, showToast: boolean = false): Promise<void> {
    try {
      // 서버에서 내려준 에러의 경우
      if (e instanceof BoardAPIRequestError) {
        await this.serverResultErrorHandler(e);
        return;
      }

      if (showToast) await this.app.showToast('요청하신 작업에 실패하였습니다.');
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - errorHandler');
    }
  }

  /**
   * 서버 에러 핸들러
   * @param e
   * @return {Promise<void>}
   */
  async serverResultErrorHandler(e?: any): Promise<void> {
    const errorCode = e.errorCode;
    const errorMessage = e.errorMessage;

    switch (errorCode) {
      case ServerErrorCode.USER_NOT_FOUND:
        await this.app.showToast('요청하신 작업에 실패하였습니다.');
        this.removeBoardUserFromLocal();
        await this.app.logout();
        break;
      case ServerErrorCode.BOARD_USER_NOT_FOUND:
        await this.app.showToast('요청하신 작업에 실패하였습니다.');
        this.removeBoardUserFromLocal();
        this.app.go('/board');
        break;
      default:
        await this.app.showToast('요청하신 작업에 실패하였습니다.');
    }
  }

  /**
   * TODO: 추후 errorHandler로 전부 변경후 삭제
   * 에러알림 토스트 띄우기
   * @param {string | ServerErrorCode} errorCode
   * @param {string} errorMessage
   * @return {Promise<void>}
   */
  async errorShowToast(errorCode?: string | ServerErrorCode, errorMessage?: string): Promise<void> {
    try {
      switch (errorCode) {
        case ServerErrorCode.USER_NOT_FOUND:
          await this.app.showToast('요청하신 작업에 실패하였습니다.');
          this.removeBoardUserFromLocal();
          await this.app.logout();
          break;
        case ServerErrorCode.BOARD_USER_NOT_FOUND:
          this.removeBoardUserFromLocal();
          this.app.go('/board');
          break;
      }

      await this.app.showToast(errorMessage || '요청하신 작업에 실패하였습니다.');
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - errorShowToast');
    }
  }

  /**
   * 보드 유저 셋팅
   * @return {Promise<void>}
   */
  async setBoardUser(): Promise<void> {
    try {
      console.log('보드 유저 셋팅');
      const user = this.getUserFromLocal();
      const boardUserFromLocal = this.getBoardUserFromLocal();

      // 1. 로그인 한 유저
      if (user) {
        // 1.1 로그인한 유저의 보드유저가 로컬에 저장되어 있을 경우
        if (boardUserFromLocal && boardUserFromLocal.userId === user.id) return;

        // 1.2 로그인한 유저의 보드유저 정보 요청
        const { result, data } = await this.api.getBoardUserByUserId();
        // 1.2.1 로그인한 유저의 보드 유저가 있는 경우 - 로컬에 저장
        if (result && data) {
          this.setBoardUserToLocal(data);
          return;
        }

        // 1.2.2 로그인한 유저의 보드 유저가 없는 경우 - 생성 후 로컬에 저장
        const { result: createBoardUserResult, data: createBoardUser } = await this.api.createBoardUser();
        if (createBoardUserResult && createBoardUser) {
          this.setBoardUserToLocal(createBoardUser);
          return;
        }
      } else {
        // 2. 로그인 안한 유저
        // 2.1 로그인 안한 유저의 보드 유저(익명) 정보가 로컬에 저장되어 있는 경우
        if (boardUserFromLocal && boardUserFromLocal.userId === null) return;

        // 2.2 로그인 안한 유저의 보드 유저(익명) 생성 후 로컬에 저장
        const { result: createBoardUserResult, data: createBoardUser } = await this.api.createBoardUser();
        if (createBoardUserResult && createBoardUser) {
          this.setBoardUserToLocal(createBoardUser);
          return;
        }
      }
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - setBoardUser');
    }
  }

  /**
   * 보드 유저 정보 로컬스토리지에 저장
   * @param {BoardUserType} boardUser
   */
  setBoardUserToLocal(boardUser: BoardUserType): void {
    try {
      const stringData = JSON.stringify(boardUser);
      window.localStorage.setItem('boardUser', stringData);
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - setBoardUserToLocal');
    }
  }

  /**
   * 유저 정보 로컬스토리지에서 가져오기
   * @return {UserFromLocalType}
   */
  getUserFromLocal(): UserFromLocalType {
    try {
      const user = window.localStorage.getItem('user');
      return JSON.parse(user);
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - getUserFromLocal');
    }
  }

  /**
   * 보드 유저 정보 로컬스토리지에서 가져오기
   * @return {BoardUserType}
   */
  getBoardUserFromLocal(): BoardUserType {
    try {
      const boardUser = window.localStorage.getItem('boardUser');

      return JSON.parse(boardUser);
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - getBoardUserFromLocal');
    }
  }

  /**
   * 보드 유저 정보 로컬스토리지에서 삭제
   * @return {void}
   */
  removeBoardUserFromLocal(): void {
    try {
      window.localStorage.removeItem('boardUser');
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - removeBoardUserFromLocal');
    }
  }

  /**
   * 서버에서 받은 date를 클라이언트 표기법으로 변경
   * @return {string}
   */
  convertServerDateToClientFormat(targetDate: string): string {
    try {
      const koreaTimeDiff = 9 * 60 * 60 * 1000;
      const koreaDate = new Date(new Date(targetDate).getTime() - koreaTimeDiff);

      const year = koreaDate.getFullYear();
      const month = String(koreaDate.getMonth() + 1).padStart(2, '0');
      const day = String(koreaDate.getDate()).padStart(2, '0');
      const hours = String(koreaDate.getHours()).padStart(2, '0');
      const minutes = String(koreaDate.getMinutes()).padStart(2, '0');

      return `${year}.${month}.${day} ${hours}:${minutes}`;
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - convertServerDateToClientFormat');
    }
  }

  /**
   * 브라우저에서 표시할 수 있는 이미지 형식인지 체크
   * @param {string} fileType
   * @return {boolean}
   */
  isSupportedImageFormat(fileType: string): boolean {
    try {
      const supportedFormats: string[] = Object.values(SupportedImageFormats);
      return supportedFormats.includes(fileType);
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - isSupportedImageFormat');
    }
  }

  /**
   * 로딩 보여주기
   * @return {Promise<void>}
   */
  async showLoader(): Promise<void> {
    try {
      const loadingImg = `<img src="/assets/board/images/img-loading.png"/>`;

      this.loader = await this.loadingCtrl.create({
        message: loadingImg,
        cssClass: 'board-loading'
      });

      await this.loader.present();
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - showLoader');
    }
  }

  /**
   * 로딩 숨기기
   * @return {Promise<void>}
   */
  async hideLoader(): Promise<void> {
    try {
      if (this.loader) await this.loadingCtrl.dismiss();
      this.loader = null;
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - hideLoader');
    }
  }

  /**
   * 플랜 업그레이드 안내 모달 띄우기
   * @return {Promise<void>}
   */
  async openPaidGuideModal(): Promise<void> {
    try {
      const paidGuidModal = await this.modalCtrl.create({
        component: PaidGuideModalPage,
        cssClass: 'modal-size-pay-guide',
        componentProps: {
          isModal: true
        }
      });
      return await paidGuidModal.present();
    } catch (e) {
      throw new BoardClientError(e, 'BoardCommonService - openPaidGuideModal');
    }
  }
}
