export enum BoardMenuViews {
  share = 'share',
  setting = 'setting',
  participation = 'participation',
  export = 'export'
}

export enum BoardModalGeneratePostStatus {
  none = 'none',
  small = 'small',
  large = 'large',
  fullscreen = 'fullscreen'
}
export enum BoardFilterTypes {
  recent = 'Recent',
  shared = 'Shared',
  my = 'My',
  myTrash = 'MyTrash'
}

export enum BoardFilterDisplayName {
  Recent = '최근',
  Shared = '참가 보드',
  My = '나의 보드',
  MyTrash = '휴지통'
}

export enum BoardSettingOptionsEnum {
  All = 'All',
  Layout = 'Layout',
  PostOrder = 'PostOrder',
  LinkPrivacy = 'LinkPrivacy',
  VisitorPermission = 'VisitorPermission',
  ReactionType = 'ReactionType',
  PostType = 'PostType',
  Wallpaper = 'Wallpaper',
  ShowGroup = 'ShowGroup'
}

export enum BoardSettingOptionsDisplayName {
  Secret = '비공개',
  Public = '링크를 가진 누구나',
  'No Access' = 'No Access',
  Reader = '독자 - 읽기 전용',
  Writer = '작가 - 포스트 작성 가능',
  Editor = '편집자 - 그룹과 포스트 편집 및 작성 가능'
}

export enum BoardUserPermissions {
  NoAccess = 'No Access',
  Reader = 'Reader',
  Writer = 'Writer',
  Editor = 'Editor',
  Moderator = 'Moderator'
}

export enum AppSyncEvents {
  createPost = 'create_post',
  updatePost = 'update_post',
  deletePost = 'delete_post',
  createGroup = 'create_group',
  updateGroup = 'update_group',
  deleteGroup = 'delete_group',
  updateBoard = 'update_board',
  deleteBoard = 'delete_board',
  updateGroupOrder = 'update_group_order',
  updatePostOrder = 'update_post_order',
  createComment = 'create_comment',
  updateComment = 'update_comment',
  deleteComment = 'delete_comment'
}

export enum displayBoardSettingTitle {
  share = '공유 설정',
  setting = '보드 설정'
}

export enum PostType {
  Text = 'Text',
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio',
  Document = 'Document',
  Archive = 'Archive'
}

export enum BoardNotAccessReasonType {
  NotFound = 'NotFound',
  NotPermission = 'NotPermission',
  RequiredPassword = 'RequiredPassword',
  RequiredSignIn = 'RequiredSignIn'
}

export enum BoardLimitCapacity {
  MB05 = 500000, // 0.5MB
  MB500 = 524288000, // 500MB
  GB1 = 1073741824, // 1GB
  GB10 = 10737418240 // 10GB
}
