import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult, gql } from '@apollo/client';
import { map } from 'rxjs/operators';
import { GraphqlApiService } from '../api/graphql.api.service';

@Injectable({
  providedIn: 'root'
})
export class MemeService {
  constructor(public graphql: GraphqlApiService) {}

  /**
   * 밈 관련 전체 태그 리스트 불러오기
   * @param language - 해당 템플릿 속해있는 언어
   */
  getAllMemeCategories(language?: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getAllMemeCategories($language: MemeLanguageType) {
            getAllMemeCategories(language: $language)
          }
        `,
        {
          language
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getAllMemeCategories;
          return result;
        })
      );
  }

  /**
   * 밈 리스트 불러오기
   * @param language
   * @param tag 검색어
   */
  getAllMemes(language?: string, tag?: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getAllMemes($language: MemeLanguageType, $tag: String) {
            getAllMemes(language: $language, tag: $tag) {
              canvas {
                width
                height
                thumbnail
              }
              objects {
                ... on MemeText {
                  __typename
                  thumbnail
                }
                ... on MemeGroup {
                  __typename
                  thumbnail
                }
              }
              tags {
                name
              }
            }
          }
        `,
        {
          language,
          tag
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getAllMemes;
          return result;
        })
      );
  }

  /**
   * 밈 정보 내려주기
   * @param {number} id - 밈 아이디
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getMeme(id: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getMeme($id: ID!) {
            getMeme(id: $id) {
              canvas {
                id
                width
                height
                thumbnail
              }
              objects {
                ... on MemeGroup {
                  id
                  type
                  __typename
                  width
                  height
                  imgPath
                  fabricObject
                }
                ... on MemeText {
                  id
                  type
                  __typename
                  width
                  height
                  imgPath
                  fabricObject
                }
              }
              tags {
                name
              }
            }
          }
        `,
        {
          id
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getMeme;
          return result;
        })
      );
  }

  /**
   * 밈 말풍선 리스트 불러오기
   */
  getAllBubbleLists(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getAllBubbleLists {
            getAllBubbleLists {
              bubble {
                type
                width
                height
                top
                left
                thumbnail
              }
              text {
                type
                width
                height
                top
                left
                thumbnail
                fontUrl
                fontFamily
              }
            }
          }
        `
      )
      .pipe(
        map((result) => {
          result.data = result.data.getAllBubbleLists;
          return result;
        })
      );
  }

  /**
   * 얼굴 표정 바꾸기
   * @param fabricObj
   * @param faceExpressionId - 바꾸려고하는 얼굴 표정 ID
   * @param characterId - 캐릭터 ID
   * @param memeId - 바꾸려고하는 캐릭터가 속한 캔버스 ID
   */
  changeFaceExpression(fabricObj: string, faceExpressionId: number, characterId: number, memeId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query changeFaceExpression($fabricObj: String!, $faceExpressionId: Int!, $characterId: Int!, $memeId: ID!) {
            changeFaceExpression(fabricObj: $fabricObj, faceExpressionId: $faceExpressionId, characterId: $characterId, memeId: $memeId) {
              memeId
              memeTitle
              base64
              fabricObj
            }
          }
        `,
        {
          fabricObj,
          faceExpressionId,
          characterId,
          memeId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.changeFaceExpression;
          return result;
        })
      );
  }

  /**
   * 캐릭터 리소스 조회
   * @param characterId - 캐릭터 ID
   * @param part - ex) 얼굴표정, 몸통, 팔, 다리 ..
   */
  // 예시 파라미터
  // {
  //   "characterId": 108,
  //   "part": "face_expression"
  // }
  getCharacterResources(characterId: number, part: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getCharacterResources($characterId: Int!, $part: String!) {
            getCharacterResources(characterId: $characterId, part: $part) {
              part
              resourceId
              base64
            }
        `,
        {
          characterId,
          part
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getCharacterResources;
          return result;
        })
      );
  }

  /**
   * 폴더 내의 모든 밈을 발행
   * @param canvasGroupId 발행할 canvas group ID
   * @param isBubbleAndText 말풍선 밈을 발행할 것인가?
   * @return {Promise<any>}
   */
  publishAllMemes(canvasGroupId: number, isBubbleAndText: boolean = false): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation PublishAllMemes($isBubbleAndText: Boolean!, $canvasGroupId: Int!) {
            publishAllMemes(isBubbleAndText: $isBubbleAndText, canvasGroupId: $canvasGroupId)
          }
        `,
        {
          isBubbleAndText,
          canvasGroupId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.publishAllMemes;
          return result;
        })
      )
      .toPromise();
  }
}
