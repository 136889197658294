import { Deployment } from '../app/enum/app.enum';

const clientVersion = 'dev-313-2024-03-08';

export const environment = {
  production: false,
  deploy: Deployment.dev,
  cloudfrontStatus: 'off',
  appVersion: clientVersion,
  api_server_url: 'https://api.4cut.ai',
  pixabay_url: 'https://pixabay.com/api/?key=38338771-c9772b48894b2344cb1e3adb2&safesearch=true&image_type=photo&pretty=true',
  graphql_url: 'https://dev-api.tooning.io/graphql',
  graphql_cc_url: 'https://dev-api.tooning.io/graphql',
  graphql_uw_url: 'https://dev-api.tooning.io/graphql',
  graphql_as_url: 'https://dev-api.tooning.io/graphql',
  graphql_an_url: 'https://dev-api.tooning.io/graphql',
  server_url: 'https://dev-api.tooning.io',
  share_server_url: 'https://dev-api.tooning.io',
  merge_server_url: 'https://image4cut.toonsquare.co',
  legacy_api_server_url: 'https://itself.toonsquare.co',
  legacy_stage_api_server_url: 'https://stage.toonsquare.co',
  user_endpoint: 'https://dev-app.tooning.io',
  one_signal_key: 'af404b2c-f970-4034-8c54-b59fd61ab0cb',
  // kakao_client_id: '4171b821a82c4e7f6e450ff463406b53',
  kakao: {
    web_key: '4171b821a82c4e7f6e450ff463406b53',
    app_key: 'b7f711c31421c4c63dab7e47ef78c810'
  },
  facebook_app_id: '718624692407474',
  onesignal: {
    app_id: '64a930d9-0777-4b5e-90f1-fa449e19311b',
    sender_id: '684128321565'
  },
  slack_bot_token_error_message: 'xoxb-1192421890996-1669178811558-hwKhr3jeUIlll1ddo25DOKLW',
  firebaseConfig: {
    apiKey: 'AIzaSyD0Otj9pHU77qVjZIHhrGroFSZKX7hcZgI',
    authDomain: 'tooning-acb36.firebaseapp.com',
    databaseURL: 'https://tooning-acb36.firebaseio.com',
    projectId: 'tooning-acb36',
    storageBucket: 'tooning-acb36.appspot.com',
    messagingSenderId: '684128321565',
    appId: '1:684128321565:web:632c2457b28b1c7f7986f4',
    measurementId: 'G-SFR4L9XSF5'
  },
  websocket: {
    schema: 'wss://',
    host: 'dev-api.tooning.io',
    port: 8081
  },
  websocket_cc: {
    schema: 'wss://',
    host: 'dev-api.tooning.io',
    port: 8081
  },
  websocket_uw: {
    schema: 'wss://',
    host: 'dev-api.tooning.io',
    port: 8081
  },
  websocket_as: {
    schema: 'wss://',
    host: 'dev-api.tooning.io',
    port: 8081
  },
  websocket_an: {
    schema: 'wss://',
    host: 'dev-api.tooning.io',
    port: 8081
  },
  paypalConfig: {
    clientId: 'AQGUoKpjwHNcTgHgmuhUHXh0ILob0IcBL7HH-ytq4MXaf9AYonLRCJGv5DqNGwnw4NE6LxnK7UhCr_OT'
  },
  enable_liveupdate: false,
  sbir_ai_url: 'http://127.0.0.1:8088/predictions/sem_pcyc',
  cloudfront_url: 'https://dgm6wyji0s5kf.cloudfront.net/',
  whalespace: {
    getAuthCodeUrl: 'https://auth.whalespace.io/oauth2/v1.1/authorize',
    redirect_uri: 'https://dev-app.tooning.io/oauth/callback/whalespace',
    client_id: 'NPiuVeb750coC_Ga24ea',
    client_secret: 'Bwco3OAurj'
  },
  proxy_api: {
    url: 'https://dev-api-proxy.tooning.io'
  },
  ai: {
    ttt_url: 'https://rxyosezo6a.execute-api.ap-northeast-2.amazonaws.com/ttt'
  },
  toss_client_key: 'test_ck_YPBal2vxj81XNRavLkAr5RQgOAND',
  api_gateway: {
    getBillingKeyWebhook: 'https://vwx1eyz3u1.execute-api.ap-northeast-2.amazonaws.com/beta',
    billingWebhookForIamport: 'https://o4gvxy0in0.execute-api.ap-northeast-2.amazonaws.com/beta'
  },
  iamportKeys: {
    iamportBillingRequestURL: 'https://api.iamport.kr/subscribe/payments/again',
    kakaopaySecretKey: 'CALLSUOYM2',
    naverpaySecretKey: 'np_kfafn863294'
  },
  coolSchool: {
    loginUrl: 'http://member.coolschool.co.kr/login',
    client_id: 'NjM2YzY5NjU2ZTc0NWY2OTY0M2E0MzRmNGY0YzUzNDM0ODRmNGY0Yw==',
    client_secret: 'NjM2YzY5NjU2ZTc0NWY3MzY1NjM3MjY1NzQzYTQzNGY0ZjRjNTM0MzQ4NGY0ZjRj',
    client_name: 'COOLSCHOOL',
    client_site: 'H',
    redirect_uri: 'https://tooning.io/oauth/callback/cool-school'
  },
  google_client_id: '684128321565-0719v9vu09n2ud06eelvkjms758ah3rh.apps.googleusercontent.com',
  s3: {
    bucket: 'dev-toonsquare-resource-image'
  },
  board: { bucket: 'dev-board-resource-image', cloudfront: 'https://d2fd7o6toomcon.cloudfront.net' }
};
