import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'directionChangeMonth'
})
export class DirectionChangeMonthPipe implements PipeTransform {
  transform(value: number): any {
    let converted = '';
    switch (value) {
      case 1:
        converted = 'January';
        break;
      case 2:
        converted = 'February';
        break;
      case 3:
        converted = 'March';
        break;
      case 4:
        converted = 'April';
        break;
      case 5:
        converted = 'May';
        break;
      case 6:
        converted = 'June';
        break;
      case 7:
        converted = 'July';
        break;
      case 8:
        converted = 'August';
        break;
      case 9:
        converted = 'September';
        break;
      case 10:
        converted = 'October';
        break;
      case 11:
        converted = 'November';
        break;
      case 12:
        converted = 'December';
        break;
    }
    return converted;
  }
}
