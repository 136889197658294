import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { GraphqlApiService } from './api/graphql.api.service';
import { gql, ApolloQueryResult, FetchResult } from '@apollo/client';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { CustomLoginUserInfoInLocalStorage, Deployment, FetchPolicyList, ServerRegion, UserRole, VerificationType } from '../enum/app.enum';
import { GoogleUser } from '../model/user/google/googleUser';
import { AuthSNSResult, CustomLoginUser, CustomUser, CustomUserEmail, CustomUserUpdate } from '../model/user/cutom/cutomUser';
import { Cache } from '../interfaces/cache.interface';
import { AppleUser } from '../model/user/apple/appleUser';
import {
  TooningCheckAndSetServerRegionError,
  TooningCurrentUserError,
  TooningFetchCurrentUserError,
  TooningGetSocialUserEmailError,
  TooningGetUserCountryError,
  TooningGetUserEmailError,
  TooningGetUserIndexError
} from '../pages-tooning/errors/TooningErrors';
import { CurrentUser, LoginSocialOutput } from '../interfaces/app.interface';
import { UserLoginType } from '../enum/UserLoginType.enum';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // tslint:disable-next-line:variable-name
  user_img_url: any;
  private caches: Cache = {
    userCacheKey: null
  };

  constructor(public graphql: GraphqlApiService, private cache: CacheService) {}

  /**
   * 서버에서 발급한 json web token 이 있는지 확인한다.
   * @return {boolean}
   */
  isTokenSaved(): boolean {
    const token = window.localStorage.getItem('token');
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  // 중복 로그인 검사
  async isDuplicateLogin() {
    try {
      const token = window.localStorage.getItem('token');
      const user = window.localStorage.getItem('user');
      if (token) {
        const userId = JSON.parse(user).id;
        // 이 admin 계정은 중복 로그인 가능하도록
        const tooningIoAccountId = ['2933', '1774', '79487', '99686', '99150', '99125'];
        if (tooningIoAccountId.includes(_.toString(userId))) {
          return true;
        }
        const isTokenActivation = await this.getTokenActivation(userId, token);
        return isTokenActivation;
      } else {
        return false;
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * admin role인지 확인
   * @returns {Promise<boolean>} true : 사용자의 역활이 admin 이다.
   */
  async isAdmin(): Promise<boolean> {
    const user = await this.currentUser();
    if (user.role === UserRole.admin) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * beta role인지 확인
   * @returns {Promise<boolean>} true : 사용자의 역활이 beta 이다.
   */
  async isBeta(): Promise<boolean> {
    const user = await this.currentUser();
    if (user.role === UserRole.beta) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * student role인지 확인
   * @returns {Promise<boolean>} true : 사용자의 역활이 student 이다.
   */
  async isStudent(): Promise<boolean> {
    const user = await this.currentUser();
    if (user.role === UserRole.student) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * teacher role인지 확인
   * @returns {Promise<boolean>} true : 사용자의 역활이 teacher 이다.
   */
  async isTeacher(): Promise<boolean> {
    const user = await this.currentUser();
    if (user.role === UserRole.teacher) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * template role인지 확인
   * @returns {Promise<boolean>} true : 사용자의 역활이 template 이다.
   */
  async isTemplate(): Promise<boolean> {
    const user = await this.currentUser();
    if (user.role === UserRole.template) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 사용자의 역활이 textTemplate 인지 확인 한다.
   * @return {Promise<boolean>} true : 사용자의 역활이 textTemplate 이다.
   */
  async isTextTemplate(): Promise<boolean> {
    const user = await this.currentUser();
    if (user.role === UserRole.textTemplate) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 사용자의 역활이 enterprise 인지 확인 한다.
   * @return {Promise<boolean>} true : 사용자의 역활이 enterprise 이다.
   */
  async isEnterprise(): Promise<boolean> {
    try {
      const user = await this.currentUser();
      if (user.role === UserRole.enterprise) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  login(token, kind: UserLoginType = null) {
    console.log('save token = ' + token);
    window.localStorage.setItem('token', token);
    if (kind) {
      window.localStorage.setItem('kind', kind);
    }
  }

  /**
   * LocalStorage 에서 User email 을 가져 온다
   * @return {number|null}
   */
  getUserIdx(): number | null {
    try {
      if (this.isTokenSaved()) {
        // 로컬에서 데이터 검색
        if (!this.cache.user) {
          const temp = window.localStorage.getItem('user');
          if (temp) {
            this.cache.user = JSON.parse(temp);
          }
        }
        // 서버에서 패치
        if (!this.cache.user) {
          return null;
        }
        return this.cache.user.id;
      } else {
        return null;
      }
    } catch (e) {
      throw new TooningGetUserIndexError(e);
    }
  }

  /**
   * LocalStorage 에서 User email 을 가져 온다
   * @return {number|null}
   */
  getUserEmail(): string | null {
    try {
      if (this.isTokenSaved()) {
        // 로컬에서 데이터 검색
        if (!this.cache.user) {
          const temp = window.localStorage.getItem('user');
          if (temp) {
            this.cache.user = JSON.parse(temp);
          }
        }
        // 서버에서 패치
        if (!this.cache.user) {
          return null;
        }
        return this.cache.user.userEmail;
      } else {
        return null;
      }
    } catch (e) {
      throw new TooningGetUserEmailError(e);
    }
  }

  /**
   * LocalStorage 에서 User country 을 가져 온다
   * @return {number|null}
   */
  getUserCountry(): string | null {
    try {
      if (this.isTokenSaved()) {
        // 로컬에서 데이터 검색
        if (!this.cache.user) {
          const temp = window.localStorage.getItem('user');
          if (temp) {
            this.cache.user = JSON.parse(temp);
          }
        }
        // 서버에서 패치
        if (!this.cache.user) {
          return null;
        }
        return this.cache.user.country;
      } else {
        return null;
      }
    } catch (e) {
      throw new TooningGetUserCountryError(e);
    }
  }

  /**
   * 유저 정보를 받아오는 함수. 캐쉬에 없으면 새로 fetch.
   * @return {Promise<CurrentUser | null>}
   */
  async currentUser(): Promise<CurrentUser | null> {
    try {
      let user: CurrentUser | null = null;
      if (this.isTokenSaved()) {
        // 로컬에서 데이터 검색
        if (!this.cache.user) {
          const temp = window.localStorage.getItem('user');
          if (temp) {
            user = this.cache.user = JSON.parse(temp) as CurrentUser;
            this.cache.user.id = +this.cache.user.id;
          } else {
            // 서버에서 가져온다.
            try {
              user = await this.fetch();
            } catch (e) {
              throw new TooningFetchCurrentUserError(e.message, null, true);
            }
          }
        } else {
          user = this.cache.user;
        }
      }
      return user;
    } catch (e) {
      if (e instanceof TooningFetchCurrentUserError) {
        // 여기 까지 왔다면, serviceworker 의 캐쉬 문제로 발생하는 문제라고 첫번째로 가정해 보세요
        // https://github.com/toonsquare/tooning-repo/issues/5928
        alert(e.message);
        throw e;
      } else {
        throw new TooningCurrentUserError(e.message, null, true);
      }
    }
  }

  /**
   * 로그인 한 사용자의 정보를 가져 온다.
   * 1.localstorage 에 user selectMy 정보 저장
   * 2.memory this.cache.user selectMy 정보 저장
   * @return {Promise<any>}
   */
  async fetch(isUpdate?: boolean): Promise<CurrentUser> {
    try {
      const result = await this.selectMy(isUpdate).toPromise();
      const selectMy: CurrentUser = result.data.selectMy as CurrentUser;
      // 옛날 디비 서버의 user_idx를 사용하는 코드가 많아서 추가 한다.
      if (_.isEmpty(selectMy)) {
        throw Error(`user data is empty ${selectMy}`);
      }
      selectMy.id = +selectMy.id;
      if (!selectMy) {
        throw Error('no user_data');
      }
      window.localStorage.setItem('user', JSON.stringify(selectMy));
      this.cache.user = selectMy;
      this.cache.user.id = +this.cache.user.id;
      return this.cache.user;
    } catch (error) {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('user');
      this.cache.user = null;
      console.error(error);
      throw error;
    }
  }

  public async getTokenActivation(userId: number, token: string): Promise<boolean> {
    const result: any = await this.graphql
      .query(
        gql`
          query getTokenActivation($userId: Int!, $token: String!) {
            getTokenActivation(userId: $userId, token: $token)
          }
        `,
        {
          userId,
          token
        }
      )
      .toPromise();
    console.log('getTokenActivation', result);
    return result.data.getTokenActivation;
  }

  // 네이티브 로그인
  public async loginSocial(kind: string, accessToken: string, googleUser: GoogleUser, language: string): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation loginSocial($kind: String!, $accessToken: String, $googleUser: GoogleUser, $language: String!) {
            loginSocial(kind: $kind, accessToken: $accessToken, googleUser: $googleUser, language: $language) {
              token
              engagement
            }
          }
        `,
        {
          kind,
          accessToken,
          googleUser,
          language
        }
      )
      .toPromise();
    console.log('loginSocial', result);
    return result.data.loginSocial;
  }

  public async doSignIn(id: number, kind: string, language: string, accessToken: string, googleUser: GoogleUser): Promise<any> {
    const result: any = await this.graphql
      .query(
        gql`
          query doSignIn($id: Float!, $kind: String!, $language: String!, $accessToken: String, $googleUser: GoogleUser) {
            doSignIn(id: $id, kind: $kind, language: $language, accessToken: $accessToken, googleUser: $googleUser) {
              token
            }
          }
        `,
        {
          id,
          kind,
          language,
          accessToken,
          googleUser
        }
      )
      .toPromise();
    console.log('doSignIn', result);
    return result.data.doSignIn;
  }

  public async doSignUp(kind: UserLoginType, accessToken: string, googleUser: GoogleUser, language: string, appleUser: AppleUser): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation doSignUp($kind: String!, $accessToken: String, $googleUser: GoogleUser, $language: String!, $appleUser: AppleUser) {
            doSignUp(kind: $kind, accessToken: $accessToken, googleUser: $googleUser, language: $language, appleUser: $appleUser) {
              token
              engagement
              id
            }
          }
        `,
        {
          kind,
          accessToken,
          googleUser,
          language,
          appleUser
        }
      )
      .toPromise();
    console.log('doSignUp', result);
    return result.data.doSignUp;
  }

  // 이미 회원가입된 사용자인지 확인
  public async isAlreadySignUpUser(kind: string, accessToken: string, googleUser: GoogleUser | AppleUser): Promise<any> {
    const result: any = await this.graphql
      .query(
        gql`
          query isAlreadySignUpUser($kind: String!, $accessToken: String, $googleUser: GoogleUser) {
            isAlreadySignUpUser(kind: $kind, accessToken: $accessToken, googleUser: $googleUser) {
              result
              user {
                role
                id
              }
            }
          }
        `,
        {
          kind,
          accessToken,
          googleUser
        }
      )
      .toPromise();
    console.log('isAlreadySignUpUser', result);
    return result.data.isAlreadySignUpUser;
  }

  /**
   * 투닝 전용 로그인시 사용되는 쿼리
   * LocalStorage 에 이메일 정보를 임시로 저장한다.
   * FetchPolicyList.networkOnly 로 설정하여 캐시를 사용하지 않도록 한다.
   * @param {CustomLoginUser} customLoginUser
   * @return {Promise<any>}
   */
  public async loginCustom(customLoginUser: CustomLoginUser): Promise<any> {
    try {
      window.localStorage.setItem(CustomLoginUserInfoInLocalStorage.customLoginUserEmail, customLoginUser.email);
    } catch (e) {
      console.warn(e);
    }
    const result: any = await this.graphql
      .query(
        gql`
          query loginCustom($customLoginUser: CustomLoginUser!) {
            loginCustom(customLoginUser: $customLoginUser) {
              token
              engagement
            }
          }
        `,
        {
          customLoginUser
        },
        FetchPolicyList.networkOnly
      )
      .toPromise();
    return result.data.loginCustom;
  }

  // custom signup
  public async signupCustom(customUser: CustomUser): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation signupCustom($customUser: CustomUser!) {
            signupCustom(customUser: $customUser) {
              engagement
              id
              token
            }
          }
        `,
        {
          customUser
        }
      )
      .toPromise();
    console.log('signupCustom', result);
    return result.data.signupCustom;
  }

  // custom signup
  public async updateCustomUser(customUserUpdate: CustomUserUpdate): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation updateCustomUser($customUserUpdate: CustomUserUpdate!) {
            updateCustomUser(customUserUpdate: $customUserUpdate)
          }
        `,
        {
          customUserUpdate
        }
      )
      .toPromise();
    console.log('updateCustomUser', result);
    return result.data.updateCustomUser;
  }

  public async resetCustomUserPassword(customUserEmail: CustomUserEmail) {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation resetCustomUserPassword($customUserEmail: CustomUserEmail!) {
            resetCustomUserPassword(customUserEmail: $customUserEmail)
          }
        `,
        {
          customUserEmail
        }
      )
      .toPromise();
    return result.data.resetCustomUserPassword;
  }

  public selectMy(isUpdate?: boolean): Observable<ApolloQueryResult<any>> {
    let result$;
    const token = window.localStorage.getItem('token');
    const cacheKey = _.compact(['selectMy', token]).join('_');
    if (!isUpdate && this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            {
              selectMy {
                id
                role
                userId
                userName
                userImgUrl
                userEmail
                loginType
                country
                lastIpAddress
              }
            }
          `
        )
        .pipe(shareReplay());
    }

    return result$;
  }

  /**
   * User가 성인인지 체크하는 쿼리
   * @return {Promise<boolean>}
   */
  public async isAdultUser(): Promise<boolean> {
    const result = await this.graphql
      .query(
        gql`
          query {
            isAdultUser
          }
        `
      )
      .pipe(
        tap((result) => {
          result.data = result.data.isAdultUser;
        })
      )
      .toPromise();

    return result.data;
  }

  // user 가져오기
  public getAllUser(skip: number, take: number, userType: string, searchText: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getAllUser($skip: Int!, $take: Int!, $userType: String!, $searchText: String) {
            getAllUser(skip: $skip, take: $take, userType: $userType, searchText: $searchText) {
              id
              role
              loginType
              userId
              userName
              userImgUrl
              userGender
              userEmail
              adultVerificationStatus
              createdDate
            }
          }
        `,
        {
          skip,
          take,
          userType,
          searchText
        },
        FetchPolicyList.cacheFirst
      )
      .pipe(
        map((results) => {
          results.data = results.data.getAllUser;
          return results;
        })
      );
  }

  public updateRole(userId: number, role: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation updateRole($userId: ID!, $role: String!) {
          updateRole(userId: $userId, role: $role)
        }
      `,
      {
        userId,
        role
      }
    );
  }

  public updateAdultVerificationStatus(userId: number, adultVerificationStatus: string): Observable<FetchResult<void>> {
    return this.graphql.mutate(
      gql`
        mutation updateAdultVerificationStatus($userId: Int!, $adultVerificationStatus: String!) {
          updateAdultVerificationStatus(userId: $userId, adultVerificationStatus: $adultVerificationStatus)
        }
      `,
      {
        userId,
        adultVerificationStatus
      }
    );
  }

  public deleteUser(userId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation deleteUser($userId: ID!) {
          deleteUser(userId: $userId)
        }
      `,
      {
        userId
      }
    );
  }

  /**
   * Toggle User Country KR <-> International (for testing purpose)
   */
  public toggleCountry(userId: number): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateCountry($userId: ID!) {
            updateCountry(userId: $userId) {
              result
              message
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.updateCountry;
          return results;
        })
      );
  }

  /**
   * To Determine not logged in users country
   */
  public getAnonymousUserCountry(): Observable<FetchResult<any>> {
    return this.graphql
      .query(
        gql`
          query getAnonymousUserCountry {
            getAnonymousUserCountry {
              message
            }
          }
        `
      )
      .pipe(
        map((results) => {
          results.data = results.data.getAnonymousUserCountry;
          return results;
        })
      );
  }

  /**
   * 특정 디바이스에서 발급해준 특수 url로 로그인 시도할경우 사용하는 쿼리, ip도 체크함
   * @param {number} deviceId 투닝 db에 등록된 device table pk
   * @return {Promise<LoginSocialOutput>}
   */
  public async deviceLoginWithIp(deviceId: number): Promise<LoginSocialOutput> {
    const results = await this.graphql
      .query(
        gql`
          query deviceLoginWithIp($id: Int!) {
            deviceLoginWithIp(id: $id) {
              token
            }
          }
        `,
        { id: deviceId }
      )
      .toPromise();
    return results.data.deviceLoginWithIp;
  }

  public async redisClear(): Promise<boolean> {
    const result: any = await this.graphql
      .query(
        gql`
          query redisClear {
            redisClear
          }
        `
      )
      .toPromise();
    console.log('redisClear', result);
    return result.data.redisClear;
  }

  /**
   * send Verification Email and get secret code
   * @param email email
   */
  public async sendVerificationEmail(email: string, language: string): Promise<any> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation sendVerificationEmail($email: String!, $language: String!) {
            sendVerificationEmail(email: $email, language: $language) {
              result
              message
            }
          }
        `,
        {
          email,
          language
        }
      )
      .toPromise();
    console.log('sendVerificationEmail', result);
    return result.data.sendVerificationEmail;
  }

  /**
   * 핸드폰 번호로 인증번호 발송
   * @param {string} phoneNumber 인증번호 발송할 핸드폰 번호
   * @return {Promise<any>}
   */
  async authSNS(phoneNumber: string): Promise<AuthSNSResult> {
    const result = await this.graphql
      .query(
        gql`
          query authSNS($phoneNumber: String!) {
            authSNS(phoneNumber: $phoneNumber) {
              result
              message
            }
          }
        `,
        { phoneNumber }
      )
      .toPromise();
    return result.data.authSNS;
  }

  /**
   * 이미 인증 이메일 혹은 휴대폰 번호로 가입한 유저가 있는지 확인한다.
   * @param {VerificationType} verificationType 인증 타입 email, sms
   * @param {string} verificationInfo 이메일, 휴대폰 번호
   * @return {Promise<boolean>}
   */
  async checkUserExists(verificationType: VerificationType, verificationInfo: string): Promise<boolean> {
    const result = await this.graphql
      .query(
        gql`
          query checkUserExists($verificationType: String!, $verificationInfo: String!) {
            checkUserExists(verificationType: $verificationType, verificationInfo: $verificationInfo)
          }
        `,
        { verificationType, verificationInfo }
      )
      .toPromise();
    return result.data.checkUserExists;
  }

  /**
   * 이미 인증 이메일 혹은 휴대폰 번호로 가입한 유저가 있는지 확인한다.특정 region을 타겟할때 사용
   * @param {VerificationType} verificationType 인증 타입 email, sms
   * @param {string} verificationInfo 이메일, 휴대폰 번호
   * @param {ServerRegion} region target region
   * @return {Promise<boolean>}
   */
  async checkUserExistsWithRegion(verificationType: VerificationType, verificationInfo: string, region: ServerRegion): Promise<boolean> {
    const result = await this.graphql
      .queryWithRegion(
        gql`
          query checkUserExists($verificationType: String!, $verificationInfo: String!) {
            checkUserExists(verificationType: $verificationType, verificationInfo: $verificationInfo)
          }
        `,
        region,
        { verificationType, verificationInfo }
      )
      .toPromise();
    this.graphql.removeClient(region);
    return result.data.checkUserExists;
  }

  /**
   * 이미 회원가입된 사용자인지 확인, 특정 region 서버를 타겟할때 사용
   * @param {string} kind 로그인 종류
   * @param {string} accessToken social로그인 토큰
   * @param {GoogleUser | AppleUser} googleUser 구글 유저인 경우 사용
   * @param {ServerRegion} region target server
   * @returns {Promise<any>}
   */
  public async isAlreadySignUpUserWithRegion(
    kind: string,
    accessToken: string,
    googleUser: GoogleUser | AppleUser,
    region: ServerRegion
  ): Promise<any> {
    const result: any = await this.graphql
      .queryWithRegion(
        gql`
          query isAlreadySignUpUser($kind: String!, $accessToken: String, $googleUser: GoogleUser) {
            isAlreadySignUpUser(kind: $kind, accessToken: $accessToken, googleUser: $googleUser) {
              result
              user {
                role
                id
              }
            }
          }
        `,
        region,
        {
          kind,
          accessToken,
          googleUser
        }
      )
      .toPromise();
    this.graphql.removeClient(region);
    return result.data.isAlreadySignUpUser;
  }

  /**
   * 각 지역 서버를 돌면서 회원가입 한 지역을 찾아 세팅해주는 함수
   * 디폴트 api가 어느 region을 타겟하고 있는지 몰라 전체 region에 request를 보낸다.(접속하고 있는 region 포함)
   * @param {boolean} isSocialLogin 소셜 로그인 여부
   * @param {string} socialAccessToken 소셜 로그인 토큰
   * @param {GoogleUser | AppleUser | null} googleUser 구글 유저인 경우 사용
   * @param {VerificationType} verificationType 확인하고 싶은 정보 종류
   * @param {string} verificationInfo 확인하고 싶은 정보
   * @returns {Promise<boolean>}
   */
  public async checkAndSetServerRegion(
    kind: UserLoginType,
    isSocialLogin: boolean,
    socialAccessToken?: string,
    googleUser?: GoogleUser | AppleUser | null,
    verificationType?: VerificationType,
    verificationInfo?: string
  ): Promise<boolean> {
    const serverRegionList = Object.values(ServerRegion);
    try {
      if (environment.deploy !== Deployment.oper) return;
      this.createAllRegionClient();
      let result;
      const promiseArr = [];
      if (isSocialLogin) {
        serverRegionList.forEach((region) =>
          promiseArr.push({
            region: region,
            request: this.isAlreadySignUpUserWithRegion(kind, socialAccessToken, googleUser, region)
          })
        );
      } else {
        serverRegionList.forEach((region) =>
          promiseArr.push({
            serverRegion: region,
            request: this.checkUserExistsWithRegion(verificationType, verificationInfo, region)
          })
        );
      }

      const alreadyExistRegions = (
        await Promise.allSettled(
          promiseArr.map(async (ob) => ({
            region: ob.region,
            result: await ob.request
          }))
        )
      )
        .filter((ob) => ob.status === 'fulfilled' && ob.value.result === true)
        //@ts-ignore
        .map((ob) => ob.value.region);
      this.setServerRegion(alreadyExistRegions?.[0] ?? ServerRegion.default);
      return alreadyExistRegions?.[0] != null;
    } catch (e) {
      throw new TooningCheckAndSetServerRegionError(e);
    }
  }

  /**
   * 서버 provisioning, 다른 나라에서 가입한 내역이 있을 경우 그 지역으로 서버를 설정해줌, 어드민에서는 선택 가능
   * @param {ServerRegion} region
   */
  public setServerRegion(region: ServerRegion): void {
    localStorage.setItem('server-region', region);
    this.graphql.region = region;
    this.graphql.setClient(region);
  }

  /**
   * 소셜 로그인 이메일 가져오기
   * @param {string} kind 소셜 로그인 종류
   * @param {string} accessToken
   * @param {GoogleUser | AppleUser} googleUser
   * @return {Promise<any>}
   */
  public async getSocialUserEmail(kind: string, accessToken: string, googleUser: GoogleUser | AppleUser): Promise<any> {
    try {
      const result: any = await this.graphql
        .query(
          gql`
            query getSocialUserEmail($kind: String!, $accessToken: String, $googleUser: GoogleUser) {
              getSocialUserEmail(kind: $kind, accessToken: $accessToken, googleUser: $googleUser) {
                email
              }
            }
          `,
          {
            kind,
            accessToken,
            googleUser
          }
        )
        .toPromise();
      return result.data.getSocialUserEmail;
    } catch (e) {
      throw new TooningGetSocialUserEmailError(e);
    }
  }

  /**
   * 모든 서버 리전 클라이언트 생성
   */
  createAllRegionClient() {
    const serverRegionList = Object.values(ServerRegion);
    serverRegionList.forEach((region) => this.graphql.createNameClient(region));
  }
}
