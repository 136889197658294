import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApolloQueryResult, gql, FetchResult } from '@apollo/client';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { GraphqlApiService } from '../api/graphql.api.service';
import { ExecutionResult } from 'graphql';
import * as _ from 'lodash';
import { Cache } from '../../interfaces/cache.interface';
import { ModelTemplateCategoryInput, TemplatePageCopyId } from './template.service.model';
import { TooningErrorCode, TooningPageCopyError, TooningPagePushError } from '../../pages-tooning/errors/TooningErrors';
import { Loading } from '../loading';
import { Cut4MakeManualService } from '../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Page } from '../../model/page/page';
import { AnalyticsService } from '../google/analytics/analytics.service';
import { TooningPage } from '../../interfaces/app.interface';
import { ResourceType, SizeUnit, TemplateAddType } from '../../enum/app.enum';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  public loading = new Loading();
  public templateSearch$ = new Subject();
  public isLanguageChange = false;
  public defaultCategory = false;
  public inputTemplateCategory: ModelTemplateCategoryInput = new ModelTemplateCategoryInput();
  private caches: Cache = {
    templateListCacheKey: null
  };
  private gaTemplateInfo: string;

  constructor(
    public app: AppService,
    public graphql: GraphqlApiService,
    public cut: Cut4MakeManualService,
    private translate: TranslateService,
    public route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    public alertController: AlertController
  ) {}

  /**
   * 카테고리 리스트 불러오기
   */
  getCategoryList(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getCategoryList {
            getCategoryList {
              id
              name
              name_ko
              name_en
              name_fr
              name_jp
              imgPath
              categoryType
              status
              order
            }
          }
        `
      )
      .pipe(
        map((result) => {
          result.data = result.data.getCategoryList;
          return result;
        })
      );
  }

  getTemplateCategory(categoryId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getTemplateCategory($categoryId: ID!) {
            getTemplateCategory(categoryId: $categoryId) {
              id
              name_ko
              name_en
              name_fr
              name_jp
              imgPath
              order
              status
              categoryType
            }
          }
        `,
        {
          categoryId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getTemplateCategory;
          this.inputTemplateCategory = new ModelTemplateCategoryInput(result.data);
          return result;
        })
      );
  }

  /**
   * 템플릿 받아오기
   * @param templateId 원하는 템플릿 ID
   */
  getTemplate(templateId: number): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['getTemplate', templateId]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query getTemplate($templateId: ID!) {
              getTemplate(templateId: $templateId) {
                id
                title
                author
                language
                thumbnailOrder
                paid
                tags {
                  name
                }
                canvas {
                  id
                  canvasSize
                  shareStatus
                  pages {
                    base64
                  }
                }
                templateCategory {
                  name
                }
              }
            }
          `,
          {
            templateId
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data = cloneResult.data.getTemplate;
            return cloneResult;
          })
        )
        .pipe(shareReplay());
    }
    return result$;
  }

  /**
   * 추가할 템플릿의 캔버스 사이즈를 가져옴
   * @param {number} templateId
   * @return {Observable<ApolloQueryResult<any>>}
   */
  getTemplateSize(templateId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getTemplateSize($templateId: ID!) {
            getTemplateSize(templateId: $templateId) {
              canvas {
                id
                canvasSize
                shareStatus
              }
            }
          }
        `,
        {
          templateId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getTemplateSize;
          return result;
        })
      );
  }

  createTemplateCategory(data, files) {
    return this.graphql
      .mutate(
        gql`
          mutation createTemplateCategory($data: InputTemplateCategoryCreate!, $files: [Upload!]!) {
            createTemplateCategory(data: $data, files: $files) {
              id
              imgPath
            }
          }
        `,
        {
          data,
          files
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.createTemplateCategory;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 템플릿 발행
   * @param data 템플릿 데이타
   */
  createTemplate(data) {
    return this.graphql
      .mutate(
        gql`
          mutation createTemplate($data: InputTemplateCreate!) {
            createTemplate(data: $data) {
              id
            }
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.createTemplate;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 템플릿 삭제
   * @param templateId 삭제할 템플릿 id
   */
  deleteTemplate(templateId) {
    return this.graphql
      .mutate(
        gql`
          mutation deleteTemplate($templateId: ID!) {
            deleteTemplate(templateId: $templateId)
          }
        `,
        {
          templateId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.deleteTemplate;
          return result;
        })
      )
      .toPromise();
  }

  deleteTemplateCategory(categoryId) {
    return this.graphql
      .mutate(
        gql`
          mutation deleteTemplateCategory($categoryId: ID!) {
            deleteTemplateCategory(categoryId: $categoryId)
          }
        `,
        {
          categoryId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.deleteTemplateCategory;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 템플릿 업데이트
   * @param data 업데이트 할 템플릿
   */
  updateTemplate(data) {
    return this.graphql
      .mutate(
        gql`
          mutation updateTemplate($data: InputTemplateUpdate!) {
            updateTemplate(data: $data)
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.updateTemplate;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 템플릿 발행 상태를 업데이트한다.
   * @param {number} templateId - 템플릿 ID
   * @param {boolean} status - 발행 상태
   * @param {boolean} isMeme - 밈 템플릿인지 여부
   * @returns {Promise<ExecutionResult<any>>}
   */
  updateTemplateStatus(templateId: number, status: boolean, isMeme?: boolean): Promise<ExecutionResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateTemplateStatus($templateId: ID!, $status: Boolean!, $isMeme: Boolean!) {
            updateTemplateStatus(templateId: $templateId, status: $status, isMeme: $isMeme)
          }
        `,
        {
          templateId,
          status,
          isMeme
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.updateTemplateStatus;
          return result;
        })
      )
      .toPromise();
  }

  updateTemplateCategory(data, file?) {
    return this.graphql
      .mutate(
        gql`
          mutation updateTemplateCategory($data: InputTemplateCategoryUpdate!, $file: [Upload!]) {
            updateTemplateCategory(data: $data, file: $file)
          }
        `,
        {
          data,
          file
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.updateTemplateCategory;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 템플릿 태그 생성
   * @param templateId 템플릿 id
   * @param templateType basic, text
   * @param tags 태그 목록
   */
  TemplateTagsCreate(templateId: number, templateType: string, tags?: string[]) {
    return this.graphql
      .mutate(
        gql`
          mutation TemplateTagsCreate($templateId: ID!, $templateType: String!, $tags: [String!]) {
            TemplateTagsCreate(templateId: $templateId, templateType: $templateType, tags: $tags)
          }
        `,
        {
          templateId,
          templateType,
          tags
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.TemplateTagsCreate;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 템플릿 태그 리스트 불러오기
   * @param categoryId 카테고리 이름
   * @param templateType basic / text
   * @param languageType 템플릿 언어
   */
  allTemplateGetTags(categoryId: number, templateType: string, languageType: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query allTemplateGetTags($categoryId: ID!, $templateType: String!, $languageType: String!) {
            allTemplateGetTags(categoryId: $categoryId, templateType: $templateType, languageType: $languageType) {
              id
              name
            }
          }
        `,
        {
          categoryId,
          templateType,
          languageType
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.allTemplateGetTags;
          return result;
        })
      );
  }

  /**
   * 템플릿 카테고리에 태그 넣기
   * @param categoryId 카테고리 id
   * @param templateType basic, text
   * @param languageType 템플릿 언어
   * @param tag 태그
   */
  async allTemplateTagsCreate(categoryId: number, templateType: string, languageType: string, tag: string) {
    return this.graphql
      .mutate(
        gql`
          mutation allTemplateTagsCreate($categoryId: ID!, $templateType: String!, $languageType: String!, $tag: String!) {
            allTemplateTagsCreate(categoryId: $categoryId, templateType: $templateType, languageType: $languageType, tag: $tag) {
              id
              name
            }
          }
        `,
        {
          categoryId,
          templateType,
          languageType,
          tag
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.allTemplateTagsCreate;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 템플릿 카테고리 태그 삭제
   * @param categoryId 카테고리 id
   * @param templateType basic, text
   * @param tagId 태그 id
   * @param languageType 템플릿 언어
   */
  allTemplateTagsDelete(categoryId: number, templateType: string, tagId: number, languageType: string) {
    return this.graphql
      .mutate(
        gql`
          mutation allTemplateTagsDelete($categoryId: ID!, $templateType: String!, $tagId: ID!, $languageType: String!) {
            allTemplateTagsDelete(categoryId: $categoryId, templateType: $templateType, tagId: $tagId, languageType: $languageType)
          }
        `,
        {
          categoryId,
          templateType,
          tagId,
          languageType
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.allTemplateTagsDelete;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 템플릿 받아오기
   * @param canvasId canvasId
   */
  checkTemplate(canvasId: number) {
    return this.graphql
      .query(
        gql`
          query checkTemplate($canvasId: ID!) {
            checkTemplate(canvasId: $canvasId) {
              id
              canvasSize
              pages {
                id
                base64
              }
              template {
                id
                title
                base64
                thumbnailOrder
                language
                status
                paid
                isGifTemplate
                meme {
                  status
                }
                templateCategory {
                  id
                  name_ko
                  name_en
                  name_fr
                  name_jp
                  categoryType
                }
                tags {
                  name
                }
              }
            }
          }
        `,
        {
          canvasId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.checkTemplate;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 특정 categoryId 를 가진 템플릿을 조회한다.
   * @param {number} skip template 테이블 전용 pagination 파라미터
   * @param {number} take template 테이블 전용 pagination 파라미터
   * @param {number} categoryId
   * @param {string} language
   * @return {Observable<ApolloQueryResult<any>>}
   */
  getTemplatesInCategory(skip: number, take: number, categoryId: number, language: string): Observable<ApolloQueryResult<any>> {
    let result$;
    result$ = this.graphql
      .query(
        gql`
          query GetTemplatesInCategory($language: String!, $categoryId: Int!, $take: Int!, $skip: Int!) {
            getTemplatesInCategory(language: $language, categoryId: $categoryId, take: $take, skip: $skip) {
              id
              title
              base64
              language
            }
          }
        `,
        {
          skip,
          take,
          language,
          categoryId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getTemplatesInCategory;
          return result;
        })
      )
      .pipe(shareReplay());
    return result$;
  }

  /**
   * categoryType 별 템플릿 조회
   * @param {number} skip templateCategory 테이블 전용 pagination 파라미터
   * @param {number} take templateCategory 테이블 전용 pagination 파라미터
   * @param {string} role
   * @param {string} language
   * @param {boolean} defaultCategory
   * @param {number} templateSkip  template 테이블 전용 pagination 파라미터
   * @param {number} templateTake  template 테이블 전용 pagination 파라미터
   * @return {Observable<ApolloQueryResult<any>>}
   */
  conditionalTemplateCategory(
    skip: number,
    take: number,
    role: string,
    language: string,
    defaultCategory: boolean,
    templateSkip: number,
    templateTake: number
  ): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['conditionalTemplateCategory', skip, take, role, defaultCategory, language]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query conditionalTemplateCategory(
              $skip: Int!
              $take: Int!
              $role: String!
              $language: String!
              $defaultCategory: Boolean!
              $templateSkip: Int!
              $templateTake: Int!
            ) {
              conditionalTemplateCategory(
                skip: $skip
                take: $take
                role: $role
                language: $language
                defaultCategory: $defaultCategory
                templateSkip: $templateSkip
                templateTake: $templateTake
              ) {
                id
                name_ko
                name_en
                name_fr
                name_jp
                imgPath
                status
                categoryType
                template {
                  id
                  title
                  base64
                  language
                }
              }
            }
          `,
          {
            skip,
            take,
            role,
            language,
            defaultCategory,
            templateSkip,
            templateTake
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data = cloneResult.data.conditionalTemplateCategory.map((templateCategory) => {
              templateCategory.imgPath = templateCategory.imgPath + '/400x';
              return templateCategory;
            });
            return cloneResult;
          })
        );
    }
    return result$;
  }

  // viewAllCategory (conditionalCategory에서 분리)
  AllTemplate(role: string, categoryType: string): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['AllTemplate', role, categoryType]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query AllTemplate($role: String!, $categoryType: String!) {
              AllTemplate(role: $role, categoryType: $categoryType) {
                id
                name_ko
                name_en
                name_fr
                name_jp
                imgPath
                status
                categoryType
              }
            }
          `,
          {
            role,
            categoryType
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data = cloneResult.data.AllTemplate.map((template) => {
              template.imgPath = template.imgPath + '/400px';
              return template;
            });
            return cloneResult;
          })
        )
        .pipe(shareReplay());
    }
    return result$;
  }

  // editor page: get template list in category
  editorTemplateCategory(skip: number, take: number, role: string, language: string): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['editorTemplateCategory', skip, take, role, language]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query editorTemplateCategory($skip: Int!, $take: Int!, $role: String!, $language: String!) {
              editorTemplateCategory(skip: $skip, take: $take, role: $role, language: $language) {
                id
                name_ko
                name_en
                name_fr
                name_jp
                imgPath
                status
                categoryType
                template {
                  id
                  title
                  base64
                  language
                  canvas {
                    id
                    pages {
                      id
                      base64
                    }
                  }
                }
              }
            }
          `,
          {
            skip,
            take,
            role,
            language
          }
        )
        .pipe(
          map((results) => {
            const cloneResult = _.cloneDeep(results);
            cloneResult.data.editorTemplateCategory.map((category) => {
              category.template.map((temp) => {
                temp.base64 = temp.base64 + '/400x';
              });
            });
            return cloneResult;
          }),
          map((result) => {
            result.data = result.data.editorTemplateCategory;
            return result;
          })
        )
        .pipe(shareReplay());
    }
    return result$;
  }

  /**
   * 템플릿 검색
   * @param skip 이미 받은 앞의 템플릿 length
   * @param take 창 크기에 따른 받아올 템플릿 수
   * @param language 언어
   * @param searchType 템플릿, 페이지, 모두
   * @param searchText 검색어
   */
  searchTemplates(skip: number, take: number, language: string, searchType: string, searchText?: any): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['searchTemplates', skip, take, language, searchType, searchText]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query searchTemplates($skip: Int!, $take: Int!, $language: String!, $searchType: String!, $searchText: String) {
              searchTemplates(skip: $skip, take: $take, language: $language, searchType: $searchType, searchText: $searchText) {
                id
                title
                base64
                language
                pageCount
                canvas {
                  pages {
                    id
                  }
                }
              }
            }
          `,
          {
            skip,
            take,
            language,
            searchType,
            searchText
          }
        )
        .pipe(
          map((results) => {
            const cloneResult = _.cloneDeep(results);
            cloneResult.data.searchTemplates.map((category) => {
              category.base64 = category.base64 + '/400x';
            });

            return cloneResult;
          }),
          map((result) => {
            result.data = result.data.searchTemplates;
            return result;
          })
        )
        .pipe(shareReplay());
    }
    return result$;
  }

  getPageInTemplate(templateId: number, skip: number, take: number): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['getPageInTemplate', templateId, skip, take]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query getPageInTemplate($templateId: ID!, $skip: Int!, $take: Int!) {
              getPageInTemplate(templateId: $templateId, skip: $skip, take: $take) {
                id
                base64
              }
            }
          `,
          {
            templateId,
            skip,
            take
          }
        )
        .pipe(
          map((results) => {
            const cloneResult = _.cloneDeep(results);
            cloneResult.data.getPageInTemplate.map((category) => {
              category.base64 = category.base64 + '/400x';
            });

            return cloneResult;
          }),
          map((result) => {
            result.data = result.data.getPageInTemplate;
            return result;
          })
        )
        .pipe(shareReplay());
    }
    return result$;
  }

  /**
   * 카테고리의 템플릿 리스트 불러오기
   * @param categoryId 카테고리 이름
   * @param templateLanguageType 템플릿 언어
   */
  getTemplates(categoryId: number, templateLanguageType: string): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getTemplates($categoryId: ID!, $templateLanguageType: String!) {
            getTemplates(categoryId: $categoryId, templateLanguageType: $templateLanguageType) {
              id
              title
              base64
              language
              templateCategory {
                id
                name_ko
                name_en
                name_fr
                name_jp
              }
              canvas {
                id
              }
            }
          }
        `,
        {
          categoryId,
          templateLanguageType
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getTemplates;
          return result;
        })
      );
  }

  templateCategoryOrderUpdate(categoryId: number, order: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation templateCategoryOrderUpdate($categoryId: ID!, $order: Int!) {
          templateCategoryOrderUpdate(categoryId: $categoryId, order: $order)
        }
      `,
      {
        categoryId,
        order
      }
    );
  }

  /**
   * 단일 template 의 order 를 변경한다.
   * @param {number} templateId
   * @param {number} templateCategoryId
   * @param {number} order update 할 순서
   * @return {Observable<FetchResult<any>>}
   */
  updateTemplateOrder(templateId: number, templateCategoryId: number, order: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation UpdateTemplateOrder($order: Int!, $templateCategoryId: Int!, $templateId: Int!) {
          updateTemplateOrder(order: $order, templateCategoryId: $templateCategoryId, templateId: $templateId)
        }
      `,
      {
        templateId,
        templateCategoryId,
        order
      }
    );
  }

  /**
   * 텍스트 템플릿 카테고리 불러오기
   */
  selectTextTemplateCategory(): Observable<ApolloQueryResult<any>> {
    const cacheKey = 'selectTextTemplateCategory';
    let result$;
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query {
              selectTextTemplateCategory {
                id
                name_ko
                name_en
                name_fr
                name_jp
                order
                status
              }
            }
          `
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data = cloneResult.data.selectTextTemplateCategory;
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  getTextTemplateCategory(skip: number, take: number, role: string, language: string): Observable<ApolloQueryResult<any>> {
    const cacheKey = _.compact(['getTextTemplateCategory', skip, take, role, language]).join('_');
    let result$;

    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query getTextTemplateCategory($skip: Int!, $take: Int!, $role: String!, $language: String!) {
              getTextTemplateCategory(skip: $skip, take: $take, role: $role, language: $language) {
                id
                name_ko
                name_en
                name_fr
                name_jp
                textTemplate {
                  id
                  title
                  base64
                  fabricObj
                  paid
                }
              }
            }
          `,
          {
            skip,
            take,
            role,
            language
          }
        )
        .pipe(
          map((results) => {
            const cloneResult = _.cloneDeep(results);
            cloneResult.data.getTextTemplateCategory.map((category) => {
              category.textTemplate.map((temp) => {
                temp.base64 = temp.base64 + '/100x';
              });
            });

            return cloneResult;
          }),
          map((result) => {
            result.data = result.data.getTextTemplateCategory;
            return result;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  createTextTemplateCategory(data): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation createTextTemplateCategory($data: InputTextTemplateCategoryCreate!) {
            createTextTemplateCategory(data: $data)
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.createTextTemplateCategory;
          return result;
        })
      );
  }

  updateTextTemplateCategory(data): Observable<FetchResult<boolean>> {
    return this.graphql.mutate(
      gql`
        mutation updateTextTemplateCategory($data: InputTextTemplateCategoryUpdate!) {
          updateTextTemplateCategory(data: $data)
        }
      `,
      {
        data
      }
    );
  }

  deleteTextTemplateCategory(categoryId: number): Observable<FetchResult<boolean>> {
    return this.graphql.mutate(
      gql`
        mutation deleteTextTemplateCategory($categoryId: ID!) {
          deleteTextTemplateCategory(categoryId: $categoryId)
        }
      `,
      {
        categoryId
      }
    );
  }

  findOneTemplateCategory(categoryId: number): Observable<FetchResult<any>> {
    const cacheKey = _.compact(['findOneTemplateCategory', categoryId]).join('_');
    let result$;

    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query findOneTemplateCategory($categoryId: ID!) {
              findOneTemplateCategory(categoryId: $categoryId) {
                id
                status
                name_ko
                name_en
                name_fr
                name_jp
              }
            }
          `,
          {
            categoryId
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data = cloneResult.data.findOneTemplateCategory;
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  textTemplateCategoryOrderUpdate(categoryId: number, order: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation textTemplateCategoryOrderUpdate($categoryId: ID!, $order: Int!) {
          textTemplateCategoryOrderUpdate(categoryId: $categoryId, order: $order)
        }
      `,
      {
        categoryId,
        order
      }
    );
  }

  getTextTemplates(
    categoryId: number,
    templateLanguageType: string,
    skip: number,
    take: number,
    role: string,
    isAdmin: boolean
  ): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getTextTemplates($categoryId: ID!, $templateLanguageType: String!, $skip: Int!, $take: Int!, $role: String!, $isAdmin: Boolean!) {
            getTextTemplates(
              categoryId: $categoryId
              templateLanguageType: $templateLanguageType
              skip: $skip
              take: $take
              role: $role
              isAdmin: $isAdmin
            ) {
              id
              title
              base64
              fabricObj
              paid
              canvas {
                id
              }
            }
          }
        `,
        {
          categoryId,
          templateLanguageType,
          skip,
          take,
          role,
          isAdmin
        }
      )
      .pipe(
        tap((results) => {
          results.data.getTextTemplates.map((category) => {
            category.base64 = category.base64 + '/100x';
          });
        })
      );
  }

  /**
   * 텍스트 템플릿 불러오기
   * @param canvasId 텍스트 템플릿 캔버스 id
   */
  getTextTemplate(canvasId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getTextTemplate($canvasId: ID!) {
            getTextTemplate(canvasId: $canvasId) {
              id
              title
              base64
              language
              paid
              status
              pageLength
              textTemplateCategory {
                id
                name_ko
                name_en
                name_fr
                name_jp
                order
              }
              tags {
                id
                name
              }
            }
          }
        `,
        {
          canvasId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getTextTemplate;
        })
      );
  }

  searchTextTemplates(
    templateLanguageType: string,
    skip: number,
    take: number,
    role: string,
    searchText?: string
  ): Observable<ApolloQueryResult<any>> {
    const cacheKey = _.compact(['searchTextTemplates', templateLanguageType, skip, take, role, searchText]).join('_');
    let result$;

    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query searchTextTemplates($templateLanguageType: String!, $skip: Int!, $take: Int!, $role: String!, $searchText: String) {
              searchTextTemplates(templateLanguageType: $templateLanguageType, skip: $skip, take: $take, role: $role, searchText: $searchText) {
                id
                title
                base64
                fabricObj
                paid
              }
            }
          `,
          {
            templateLanguageType,
            skip,
            take,
            role,
            searchText
          }
        )
        .pipe(shareReplay());
    }
    return result$;
  }

  fetchTextTemplateFabric(textTemplateId: number) {
    return this.graphql
      .query(
        gql`
          query fetchTextTemplateFabric($textTemplateId: ID!) {
            fetchTextTemplateFabric(textTemplateId: $textTemplateId)
          }
        `,
        {
          textTemplateId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.fetchTextTemplateFabric;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 텍스트 템플릿 업데이트
   * @param data 업데이트 할 텍스트 템플릿
   */
  updateTextTemplate(data) {
    return this.graphql
      .mutate(
        gql`
          mutation updateTextTemplate($data: InputTextTemplateUpdate!) {
            updateTextTemplate(data: $data)
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.updateTemplate;
          return result;
        })
      )
      .toPromise();
  }

  updateTextTemplateStatus(templateId: number, status: boolean): Observable<FetchResult<boolean>> {
    return this.graphql.mutate(
      gql`
        mutation updateTextTemplateStatus($templateId: ID!, $status: Boolean!) {
          updateTextTemplateStatus(templateId: $templateId, status: $status)
        }
      `,
      {
        templateId,
        status
      }
    );
  }

  /**
   * 텍스트 템플릿 삭제
   * @param templateId 템플릿 id
   * @param canvasId 캔버스 id
   */
  deleteTextTemplate(templateId: number, canvasId: number): Observable<FetchResult<boolean>> {
    return this.graphql.mutate(
      gql`
        mutation deleteTextTemplate($templateId: ID!, $canvasId: ID!) {
          deleteTextTemplate(templateId: $templateId, canvasId: $canvasId)
        }
      `,
      {
        templateId,
        canvasId
      }
    );
  }

  copyPage(data) {
    return this.graphql
      .mutate(
        gql`
          mutation copyPage($data: InputTemplatePageIdList!) {
            copyPage(data: $data) {
              id
              base64
              json
              order
              canvas {
                canvasSize
              }
            }
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.copyPage;
          this.changeTemplateCanvasSize(result);
          return result;
        })
      );
  }

  copyAllPage(data) {
    return this.graphql
      .mutate(
        gql`
          mutation copyAllPage($data: InputTemplateCopyAllPageIdList!) {
            copyAllPage(data: $data) {
              id
              base64
              json
              order
              canvas {
                id
                canvasSize
              }
            }
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.copyAllPage.map((page) => {
            page.base64 = page.base64 + '/400x';
            this.changeTemplateCanvasSize(page, TemplateAddType.detailAll);
            return page;
          });
          return result;
        })
      );
  }

  /**
   * 페이지를 카피한다. template 추가할때 사용된다.
   * @param  {TooningPage} page 복사할 페이지
   * @param {number} canvasId page 를 담고 있는 canvas id
   * @param {number} templateId canvas 를 담고 있는 template id
   * @param {string} templateTitle canvas 를 담고 있는 template Title
   * @return {Promise<any>}
   */
  async pageCopy(page: TooningPage, canvasId: number, templateId: number, templateTitle: string): Promise<any> {
    await this.loading.showLoader('');
    const templatePageCopyId = new TemplatePageCopyId();
    const modelInput = this.setParameter(templatePageCopyId, canvasId, templateId, +page.id);
    try {
      const { data } = await this.copyPage(modelInput).toPromise();
      const copiedPage = data;
      const prevLength = this.cut.pageList.length;
      const canvasSizeJSON = data.canvas.canvasSize;
      const canvasSizeObj = JSON.parse(canvasSizeJSON);
      this.gaTemplateInfo = templateTitle + ' ' + canvasSizeObj.size;
      try {
        // 1. 0 번째 페이지에 캐릭터 추가 후
        // 2. 1 번째템플릿 한장 추가,
        // 3.  바로  0 페이지로 이동 할때  mime-type error hotfix 5.0.2
        if (this.cut.panelIndex >= 0) {
          await this.cut.updatePageSync(this.cut.panelIndex);
        }
        await this.pushPage([copiedPage], this.cut.panelIndex, prevLength, true);
      } catch (e) {
        console.warn(`pushPage 에러`);
        throw e;
      }

      await this.app.showToast(this.translate.instant('added'), 1000);
      if (!this.app.isMobile()) {
        await this.pageScroll(this.cut.panelIndex);
      }
      return data;
    } catch (e) {
      throw new TooningPageCopyError(e.message, this.app, true);
    } finally {
      this.loading.hideLoader();
      // @ts-ignore
      this.analyticsService.templateToolApplyOneSelect(this.gaTemplateInfo);
    }
  }

  /**
   * 모델에 param 부여해주는 함수
   * @param modelInput
   * @param {number} canvasId
   * @param {number} templateId
   * @param {number} pageId
   * @returns {any} modelInput
   */
  setParameter(modelInput: any, canvasId: number, templateId: number, pageId?: number) {
    modelInput.canvasId = +canvasId;
    modelInput.userId = +this.app.cache.user.id;
    modelInput.templateId = templateId;

    if (pageId) {
      modelInput.pageId = pageId;
    }

    return modelInput;
  }

  /**
   * 템플릿 캔버스에 넣어줌
   * @param pages 캔버스에 넣을 템플릿의 모든 페이지 정보
   * @param goIndex 캔버스에 추가 될 페이지 위치
   * @param prevLength 템플릿의 페이지 수
   * @param isSinglePage 한장짜리인지 체크
   */
  async pushPage(pages, goIndex, prevLength, isSinglePage = false) {
    try {
      let fabricObject;
      const pagesOrder = [];

      const [pageJsons, rejectedIndexs] = await this.cut.fetchPages(pages, true);

      rejectedIndexs.map((rejectedIndex) => {
        // 데이터 없는 페이지 배열에서 삭제
        pages.splice(rejectedIndex, 1);
      });

      for (const [index, pageData] of pages.entries()) {
        if (index === 0) {
          const pageJson = pageJsons[index];
          fabricObject = typeof pageJson === 'string' ? JSON.parse(pageJson) : pageJson;
        } else {
          fabricObject = null;
        }

        const page = new Page().default();

        if (fabricObject) {
          page.ai.layoutPreset.characterCount = this.cut.getCharacterOnCanvas(fabricObject.objects, false).length;
        } else {
          page.ai.layoutPreset.characterCount = 0;
        }

        if (fabricObject && fabricObject.objects.length >= 2) {
          if (fabricObject.objects[0].resource_type === ResourceType.bgColor) fabricObject.objects.shift();
        }

        page.json = pageData.json;
        page.fabricObject = fabricObject;
        page.fetched = false;
        page.dirty = true;
        page.base64 = pageData.base64;
        page.img = pageData.base64;
        page.id = pageData.id;
        page.isChecked = false;
        page.order = goIndex;
        page.isFirstEnter = false;
        this.cut.pageList.splice(isSinglePage ? ++goIndex : goIndex + index, 0, page);

        pagesOrder.push(goIndex + index);
      }
      await this.cut.pagesOrderUpdate();

      if (!this.app.isAdmin) {
        for (const panel of this.cut.pageList) {
          if (panel.fabricObject) {
            this.cut.setWaterMark(panel.fabricObject.objects);
          }
        }
      }
      isSinglePage && !this.isChangeThumbnail(pages[0]) ? await this.cut.goPage(goIndex, true) : await this.allSize(goIndex, prevLength);
      return pagesOrder;
    } catch (e) {
      throw new TooningPagePushError(e, this.app, true);
    }
  }

  /**
   * 썸네일 변경 필요 여부 조사 및
   * 기존 템플릿 사이즈 규격과 해당 페이지 사이즈 규격이 일치한지 조사
   * @param page 템플릿 사이즈 변경 필요 여부를 확인할 페이지
   */
  isChangeThumbnail(page) {
    let isChangeThumbnail = false;
    const parseSize = JSON.parse(page.canvas.canvasSize);

    if (parseSize.id !== this.cut.canvasSize.id) {
      // id 다를 경우 모조건 Change
      isChangeThumbnail = true;
    } else if (parseSize.id === 'custom') {
      // custom은 h, w 비교
      if (parseSize.h !== this.cut.canvasSize.h || parseSize.w !== this.cut.canvasSize.w) {
        isChangeThumbnail = true;
      }
    } else if (parseSize.size !== this.cut.canvasSize.size) {
      // custom 아닌 경우는 size 비교
      isChangeThumbnail = true;
    }
    return isChangeThumbnail;
  }

  /**
   * 기본 템플릿 사이즈와 다른 템플릿 사이즈 추가
   * 모든 페이지를 한번씩 이동하며 업데이트하는 부분 삭제, 마지막 페이지로 이동 후 object:modified를 통해 해당 페이지 업데이트
   * @param goIndex 페이지 추가 후, 선택되는 인덱스
   * @param prevLength 템플릿이 추가되기 전 길이
   */
  async allSize(goIndex, prevLength) {
    const loading = new Loading();
    const newAddSize = this.cut.pageList.length - prevLength;
    try {
      await loading.showLoader('');
      // 기존에 모든 페이지를 이동하는 로직을 삭제 후 마지막 페이지로 이동하고 수정
      const lastPageIndex = newAddSize + goIndex - 1;
      await this.cut.goPage(lastPageIndex);
      this.cut.updatePage(lastPageIndex);
      this.cut.panelIndex = lastPageIndex;
      this.cut.canvas.fire('object:modified');
    } catch (e) {
      await this.app.showToast('apply size error');
    } finally {
      loading.hideLoader();
    }
  }

  async pageScroll(pageOrder) {
    try {
      if (pageOrder === undefined) {
        await this.app.showToast('page order undefined');
        return;
      }
      const scrollPoint = this.cut.pageHeight * --pageOrder;
      await this.cut.scrollContent.scrollToPoint(0, scrollPoint, 0);
    } catch (e) {
      console.error(e);
      await this.app.showToast('page scroll error');
    }
  }

  async errorMessage(error) {
    const { graphQLErrors, networkError } = error;
    if (graphQLErrors) {
      for (const gError of graphQLErrors) {
        const errorCode = +gError.extensions.exception.code;
        switch (errorCode) {
          case TooningErrorCode.TOONING_SERVER_TEMPLATE_GET_S3_ERR:
            await this.app.showToast(this.translate.instant('template get s3 error'));
            break;
          case TooningErrorCode.TOONING_SERVER_TEMPLATE_ERR:
            await this.app.showToast(this.translate.instant('template page error'));
            break;
          default:
            await this.app.showToast(this.translate.instant('template apply page default error'));
            break;
        }
      }
    } else if (!networkError) {
      await this.app.showToast(this.translate.instant('template apply page default error'));
      return;
    }

    await this.app.checkNetworkError(error, networkError);
  }

  /**
   * 추가할 템플릿의 캔버스 사이즈와 현재 캔버스 사이즈를 비교
   * @param {number} templateId 추가할 템플릿 아이디
   * @return {boolean} 다른지 여부
   */
  async checkTemplateSize(templateId: number): Promise<boolean> {
    let isDifferent;
    const { data } = await this.getTemplateSize(templateId).toPromise();
    const templateSize = JSON.parse(data.canvas.canvasSize);
    if (this.cut.canvasSize.w === templateSize.w && this.cut.canvasSize.h === templateSize.h) {
      isDifferent = false;
    } else {
      isDifferent = true;
    }
    return isDifferent;
  }

  /**
   * DB로부터 가져온 예전 캔버스 데이터 중, size를 실제에 맞도록 변경
   * @param {any} result
   * @param {string} type
   * @return {void}
   */
  changeTemplateCanvasSize(result: any, type = TemplateAddType.detailSingle): void {
    let data;
    if (type === TemplateAddType.detailSingle) {
      data = result.data;
    } else {
      data = result;
    }
    const sizeData = JSON.parse(data.canvas.canvasSize);
    const sizeNum = sizeData.size.substring(0, sizeData.size.length - 2);
    const sizeUnit = sizeData.size.substring(sizeData.size.length - 2, sizeData.size.length);
    let stringW = +sizeNum.split(' x ')[0];
    let stringH = +sizeNum.split(' x ')[1];
    if (
      sizeData.id === 'custom' &&
      sizeUnit === SizeUnit.px &&
      (stringW !== Math.round(sizeData.w * sizeData.web) || stringH !== Math.round(sizeData.h * sizeData.web))
    ) {
      stringW = Math.round(sizeData.w * sizeData.web);
      stringH = Math.round(sizeData.h * sizeData.web);
      sizeData.size = stringW.toString() + ' x ' + stringH.toString() + SizeUnit.px;
      data.canvas.canvasSize = JSON.stringify(sizeData);
    }
  }

  /**
   * 템플릿 추가 종류 / 구성 페이지 수에 따라 alert를 띄우거나 바로 추가
   * @param {MouseEvent} event 클릭 이벤트
   * @param fnVariables 함수에 필요한 인자
   * @param {string} addType 추가 종류 (ex. 통합검색에서 추가, 상세검색에서 추가 등)
   * @param fn 각 추가 종류에 따라 실행될 함수
   * @return {Promise<void>}
   */
  async templateAdd(event: MouseEvent, fnVariables: any, addType: string = undefined, fn: any = undefined): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    if (addType === TemplateAddType.detailSingle || addType === TemplateAddType.detailAll) {
      await this.showSizeAlert(addType, fnVariables.templateId, fn, fnVariables);
    } else if (fnVariables.template.pageCount > 1) {
      await this.templateAddFn(addType, fn, fnVariables);
    } else {
      await this.showSizeAlert(addType, +fnVariables.template.id, fn, fnVariables);
    }
  }

  /**
   * 템플릿 추가 시, 캔버스 사이즈 변경/유지 alert
   * @param {string} addType
   * @param {number} templateId
   * @param fn
   * @param fnVariables
   * @return {Promise<void>}
   */
  async showSizeAlert(addType: string, templateId: number, fn: any, fnVariables: any): Promise<void> {
    const needToChange = await this.checkTemplateSize(templateId);

    if (!needToChange) {
      await this.templateAddFn(addType, fn, fnVariables);
    } else {
      if (this.cut.popAlert !== null) {
        return;
      }
      this.cut.popAlert = await this.alertController.create({
        mode: 'md',
        cssClass: 'canvas-size-alert',
        header: this.translate.instant('screen size alert.title'),
        subHeader: this.translate.instant('screen size alert.subtitle'),
        inputs: [
          {
            name: 'change',
            type: 'radio',
            label: this.translate.instant('screen size alert.radio1'),
            value: false,
            checked: true
          },
          {
            name: 'maintain',
            type: 'radio',
            label: this.translate.instant('screen size alert.radio2'),
            value: true
          }
        ],
        buttons: [
          {
            text: this.translate.instant('cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: this.translate.instant('apply'),
            handler: async (data) => {
              this.cut.isMaintainCanvasSize = data;
              await this.templateAddFn(addType, fn, fnVariables);
            }
          }
        ]
      });
      this.cut.popAlert.onDidDismiss().then(async (data) => {
        this.cut.popAlert = null;
        this.cut.setKeyActivation = true;
      });
      this.cut.setKeyActivation = false;
      this.cut.popAlert.present();
    }
  }

  /**
   * 템플릿 추가 종류에 따라 실제 추가에 활용되는 함수 실행
   * @param {string} addType
   * @param fn
   * @param variables
   * @return {void}
   */
  async templateAddFn(addType: string, fn: any, variables: any): Promise<void> {
    switch (addType) {
      case TemplateAddType.integratedSearch:
        await fn(variables.type, variables.templateId, variables.template);
        break;
      case TemplateAddType.createSearch:
      case TemplateAddType.single:
        await fn(variables.template);
        break;
      case TemplateAddType.detailSingle:
        await fn(variables.page, variables.templateId);
        break;
      case TemplateAddType.detailAll:
        await fn();
        break;
    }
    this.cut.pageList.forEach((page) => (page.isChecked = false));
  }
}
