import { Command } from './command';
import { PageSwitchCommandData } from '../../interfaces/app.interface';

/**
 * Class PageSwitchCommand 우측의 page 들의  위치를 바꾼다.
 * @extends Command
 */
class PageSwitchCommand extends Command {
  public beforeSwitchIndex: number;
  public afterSwitchIndex: number;
  protected callback;

  /**
   * Create PageSwitchCommand
   * @param {PageSwitchCommandData} data
   */
  constructor(data: PageSwitchCommandData) {
    super(data);
    this.beforeSwitchIndex = data.beforeSwitchIndex;
    this.afterSwitchIndex = data.afterSwitchIndex;
    this.commandName = 'Page-Switch-Command';
    this.callback = data.callback;
  }

  /**
   * Execute PageSwitchCommand
   */
  async execute() {
    try {
      super.execute();
      await this.callback();
      this.cut.setShowPreviewCanvas();
    } catch (e) {
      throw new Error(e);
    } finally {
      await this.cut.pageSwitchUpdate(this.beforeSwitchIndex, this.afterSwitchIndex);
    }
  }

  /**
   * Redo PageSwitchCommand
   */
  async redo() {
    try {
      super.redo();
      const item = this.cut.pageList.splice(this.beforeSwitchIndex, 1);
      await this.switchPage(this.afterSwitchIndex, item);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo PageSwitchCommand
   */
  async undo() {
    try {
      super.undo();
      const item = this.cut.pageList.splice(this.afterSwitchIndex, 1);
      await this.switchPage(this.beforeSwitchIndex, item);
    } catch (e) {
      throw new Error(e);
    }
  }

  async switchPage(SwitchIndex, item) {
    this.cut.pageList.splice(SwitchIndex, 0, item[0]);
    await this.cut.pagesOrderUpdate();
    await this.cut.goPage(SwitchIndex);
    this.cut.setShowPreviewCanvas();
  }
}

export { PageSwitchCommand };
