import { AppService } from '../../services/app.service';
import { slack, customSlack } from '../../shared/utils/slack';
import { environment } from '../../../environments/environment';
import { cloudWatch } from '../../shared/utils/awsCloudWatch';
import { CustomLoginUserInfoInLocalStorage, Deployment } from '../../enum/app.enum';

const clientVersion = environment.appVersion;

enum TooningErrorCode {
  /**
   * tooning client error
   */
  TOONING_UNKNOWN_NETWORK_ERROR = 0,

  /**
   * tooning server error
   */

  TOONING_SERVER_CUSTOM_SIGNUP_EMAIL_EXIST_ERROR = 1990,
  TOONING_SERVER_CUSTOM_USER_EMAIL_EMPTY_ERROR = 2030,
  TOONING_SERVER_CUSTOM_USER_PASSWORD_IS_NOT_MATCHED_ERROR = 2031,

  TOONING_SERVER_CUSTOM_USER_UPDATE_ORIGIN_PASSWORD_ERROR = 2040,
  TOONING_SERVER_CUSTOM_USER_UPDATE_NEW_PASSWORD_ERROR = 2050,
  TOONING_SERVER_CUSTOM_USER_UPDATE_ERROR = 2060,

  TOONING_SERVER_GET_FONT_ERROR = 2081,
  TOONING_SERVER_FONT_S3_ERROR = 2082,

  TOONING_SERVER_TEMPLATE_ERR = 2100,
  TOONING_SERVER_NOT_EXIST_TEMPLATE_CATEGORY_ERR = 2101,
  TOONING_SERVER_TEMPLATE_SEARCH_ERR = 2102,
  TOONING_SERVER_TEMPLATE_GET_S3_ERR = 2103,

  TOONING_SERVER_NOT_EXIST_CANVAS_ERR = 2110,

  TOONING_SERVER_TEMPLATE_TAGS_CREATE_ERR = 4253,

  TOONING_SERVER_TEMPLATE_CATEGORY_GET_ERR = 2120,

  TOONING_SERVER_COMMON_UPLOAD_ERR = 2140,
  TOONING_SERVER_COMMON_UPLOAD_NOT_EXIST_ERR = 2141,
  TOONING_SERVER_COMMON_UPLOAD_UPDATE_ERR = 2142,
  TOONING_SERVER_COMMON_UPLOAD_CREATE_ERR = 2143,
  TOONING_SERVER_COMMON_UPLOAD_CHECK_ERR = 2144,
  TOONING_SERVER_COMMON_UPLOAD_HAND_ERR = 2145,
  TOONING_SERVER_COMMON_UPLOAD_TRANSLATE_ARM_ID_ERR = 2146,
  TOONING_SERVER_COMMON_UPLOAD_NOT_FOUND_ARM_ID_ERR = 2147,
  TOONING_SERVER_COMMON_UPLOAD_MAKE_ARM_HAND_SET_ERR = 2148,
  TOONING_SERVER_COMMON_UPLOAD_S3_CREATE_ERR = 2149,
  TOONING_SERVER_COMMON_UPLOAD_S3_UPDATE_ERR = 2150,
  TOONING_SERVER_COMMON_UPLOAD_DIFFERENCE_LENGTH_ERR = 2151,
  TOONING_SERVER_COMMON_UPLOAD_GET_INTENT_ERR = 2152,

  // ai
  TOONING_SERVER_GET_KHAIII_ERR = 2161,
  TOONING_SERVER_GET_CHARACTER_ERR = 2162,
  TOONING_SERVER_GET_ETC_ERR = 2163,
  TOONING_SERVER_GET_DEPENDENCY_ERR = 2164,
  TOONING_SERVER_UNKNOWN_TYPE_ERR = 2165,
  TOONING_SERVER_GET_ENTITY_ERR = 2166,
  TOONING_SERVER__GET_DEFAULT_CHARACTER_ERR = 2167,
  TOONING_SERVER__PREDICTION_ERR = 2160,
  TOONING_SERVER_GET_INTENT_ERR = 2170,

  // user
  TOONING_SERVER_GET_USER_ERR = 2180,
  TOONING_SERVER_ACTIVE_SESSION_UPDATE_ERROR = 4306,
  TOONING_SERVER_JWT_CREATE_WITH_SIGN_IN_ERROR = 4279,
  TOONING_SIG_IN_ERROR = 1994,

  // integrate search

  TOONING_SERVER_INTEGRATED_SEARCH_ERR = 2185,
  TOONING_SERVER_GET_SEARCH_DATA_ERR = 2184,
  TOONING_SERVER_GET_S3_PROMISE_ERR = 2182,
  TOONING_SERVER_RESULT_SETTING_ERR = 2183,

  // text template 에러
  TOONING_SERVER_GET_TEXT_TEMPLATE_CATEGORY_ERR = 2300,

  // color server error
  TOONIN_SERVER_GET_MYCOLOR_ERR = 2500,
  TOONIN_SERVER_ADD_MYCOLOR_ERR = 2501,
  TOONIN_SERVER_UPDATE_ORDER_MYCOLOR_ERR = 2502,
  TOONIN_SERVER_DELETE_MYCOLOR_ERR = 2503,

  // 나의 작업 에러
  TOONING_SERVER_SEARCH_CANVAS_ERR = 2800,

  // email null error
  TOONING_SERVER_GET_SOCIAL_USER_INFORMATION_ERROR = 2902,
  TOONING_SERVER_EMAIL_NULL_ERR = 2901,

  // paymentPlan 에러
  TOONING_SERVER_GET_PAYMENTPLAN_ERR = 2410,

  // card 에러
  TOONING_SERVER_GET_CARD_ERR = 2312,

  // Tooning world 에러
  NOT_ENOUGH_POINT = 5927
}

const deploy = environment.deploy;

/**
 * cloudWatch , slack으로 전송될 메세지를 만든다.
 * @param {string} name cloudWatch, slack 에서 구분 할수 있는 함수 이름
 * @param {string} message error message
 * @param {string} stack
 * @param {string | null} userEmail? user email
 * @param {boolean} isSlack slack 에 보내고 싶으면 true
 * @param {string} country user 의 국가 코드
 * @return {void}
 */
function logErrorMessage(name: string, message: string, stack: string, userEmail?: string | null, isSlack: boolean = true, country?: string): void {
  try {
    let messages = [];

    if (country) {
      messages.push(`DEPLOY/COUNTRY : ${deploy}/${country}`);
    } else {
      messages.push(`DEPLOY : ${deploy}`);
    }
    messages.push(`MESSAGE : ${message}`);
    if (userEmail) {
      messages.push(`UserEmail : ${userEmail}`);
    } else {
      messages.push(`UserEmail : Not login`);
    }
    getNetworkStatus(messages);
    messages.push(`VERSION : ${clientVersion}`);
    if (stack) {
      stack = `${stack.split('\n').splice(1, 1).join('\n')}`;
    }
    //TODO 이부분 winston 으로 전환
    const mergedMessage = messages.join('\n');

    if (isSlack && deploy === Deployment.oper) {
      slack(name, mergedMessage, stack);
    }
    cloudWatch(mergedMessage, userEmail);
  } catch (e) {
    console.error(e);
  }
}

/**
 * cloudWatch , slack으로 전송될 메세지를 만든다.
 * TODO: 전부 교체하면 이걸 logErrorMessage 로 대체
 * @param {string} name cloudWatch, slack 에서 구분 할수 있는 함수 이름
 * @param {string} message error message
 * @param {string} stack
 * @param {string} fileName 파일 이름
 * @param {string} functionName 에러 발생한 함수 이름
 * @param {boolean} isSlack slack 에 보내고 싶으면 true
 * @param {string | null} userEmail? user email
 * @param {string} country? user 의 국가 코드
 * @return {void}
 */
function logCustomErrorMessage(
  name: string,
  message: string,
  stack: string,
  fileName: string,
  functionName: string,
  isSlack: boolean = true,
  userEmail?: string | null,
  country?: string
): void {
  try {
    let messages = [];

    if (country) {
      messages.push(`DEPLOY/COUNTRY : ${deploy}/${country}`);
    } else {
      messages.push(`DEPLOY : ${deploy}`);
    }
    messages.push(`MESSAGE : ${message}`);
    if (userEmail) {
      messages.push(`UserEmail : ${userEmail}`);
    } else {
      messages.push(`UserEmail : Not login`);
    }

    messages.push(`FileName : ${fileName}`);
    messages.push(`FunctionName : ${functionName}`);

    getNetworkStatus(messages);
    messages.push(`VERSION : ${clientVersion}`);
    if (stack) {
      stack = `${stack.split('\n').splice(1, 1).join('\n')}`;
    }
    //TODO 이부분 winston 으로 전환
    const mergedMessage = messages.join('\n');

    if (isSlack && deploy === Deployment.oper) {
      customSlack(name, mergedMessage, fileName, functionName, stack);
    }
    cloudWatch(mergedMessage, userEmail);
  } catch (e) {
    console.error(e);
  }
}

/**
 * 네트워크 속도를 알기 위해 정보를 수집한다.
 * Safari 는 navigator.connection property 가 없다.
 * @param {Array<string>} messages
 * @return {void}
 */
function getNetworkStatus(messages: Array<string>): void {
  try {
    //@ts-ignore
    const { effectiveType, rtt, downlink, saveData } = navigator.connection;
    messages.push(`effectiveType : ${effectiveType}`);
    messages.push(`rtt : ${rtt}`);
    messages.push(`downlink : ${downlink}`);
    messages.push(`saveData : ${saveData}`);
  } catch (e) {
    messages.push(`Network Status : unknown`);
  }
}

/**
 * Error 를 상속 해서 만든 기본 투닝 클래스
 */
class TooningClientError extends Error {
  private static _app: AppService;
  private userEmail: string | null;
  private country: string | null;

  constructor(message: string | Error, private app: AppService = TooningClientError._app, isSlack: boolean = false) {
    super(message instanceof Error ? (message = message.message) : message);
    try {
      this.name = this.constructor.name;
      this.userEmail = null;

      if (app) {
        try {
          this.userEmail = app.user.getUserEmail();
          this.country = app.user.getUserCountry();
        } catch (e) {
          console.warn(e);
        }
      } else {
        if (TooningClientError._app) {
          try {
            // custom login 시도 시 에러가 난 케이스 인 경우는 로그인 정보가 없으므로
            // loginCustom 시도 임시로 저장한 이메일 정보를 가져온다.
            this.userEmail = TooningClientError._app.user.getUserEmail();
            this.country = TooningClientError._app.user.getUserCountry();
          } catch (e) {
            console.warn(e);
          }
        }
      }
      if (!this.userEmail) {
        this.userEmail = window.localStorage.getItem(CustomLoginUserInfoInLocalStorage.customLoginUserEmail);
      }
      logErrorMessage(this.name, message, this.stack, this.userEmail, isSlack, this.country);
      try {
        if (this.app && this.app.analyticsService) {
          this.app.analyticsService.error(this.name, message);
        }
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      throw e;
    }
  }

  static setAppService(app: AppService) {
    this._app = app;
  }
}

/**
 * Error 를 상속 해서 만든 기본 투닝 클래스
 * TODO: 전부 교체하면 이걸 TooningClientError 로 대체
 */
class TooningCustomClientError extends Error {
  private static _app: AppService;
  private userEmail: string | null;
  private country: string | null;

  constructor(
    message: string | Error,
    fileName: string,
    functionName: string,
    private app: AppService = TooningCustomClientError._app,
    isSlack: boolean = false
  ) {
    super(message instanceof Error ? (message = message.message) : message);
    try {
      this.name = this.constructor.name;
      this.userEmail = null;

      if (app) {
        try {
          this.userEmail = app.user.getUserEmail();
          this.country = app.user.getUserCountry();
        } catch (e) {
          console.warn(e);
        }
      } else {
        if (TooningCustomClientError._app) {
          try {
            // custom login 시도 시 에러가 난 케이스 인 경우는 로그인 정보가 없으므로
            // loginCustom 시도 임시로 저장한 이메일 정보를 가져온다.
            this.userEmail = TooningCustomClientError._app.user.getUserEmail();
            this.country = TooningCustomClientError._app.user.getUserCountry();
          } catch (e) {
            console.warn(e);
          }
        }
      }
      if (!this.userEmail) {
        this.userEmail = window.localStorage.getItem(CustomLoginUserInfoInLocalStorage.customLoginUserEmail);
      }
      logCustomErrorMessage(this.name, message, this.stack, fileName, functionName, isSlack, this.userEmail, this.country);
      try {
        if (this.app && this.app.analyticsService) {
          this.app.analyticsService.error(this.name, message);
        }
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      throw e;
    }
  }

  static setAppService(app: AppService) {
    this._app = app;
  }
}

class TooningSignUpError extends TooningClientError {}

class TooningBrowserCheckError extends TooningClientError {}

class TooningAppConstructError extends TooningClientError {}

class TooningAppNgAfterViewInit extends TooningClientError {}

class TooningAppInitError extends TooningClientError {}

class TooningAppPlatformInitError extends TooningClientError {}

class TooningFetchCurrentUserError extends TooningClientError {}

class TooningCanvasSetupError extends TooningClientError {}

class TooningBadRequestError extends TooningClientError {}

class TooningUnauthorizedError extends TooningClientError {}

class TooningChangeAuthorizeError extends TooningClientError {}

class TooningUnkownNetworkError extends TooningClientError {}

class TooningNetworkError extends TooningClientError {}

class TooningCustomUserSignUpError extends TooningClientError {}

class TooningCustomUserUpdateError extends TooningClientError {}

class TooningCustomUserEmailEmptyError extends TooningClientError {}

class TooningCustomUserPasswordIsNotMatchedError extends TooningClientError {}

class TooningCustomUserEmailEmptyResetError extends TooningClientError {}

class TooningCustomUserPasswordResetError extends TooningClientError {}

class TooningCustomLoginValidationError extends TooningClientError {}

class TooningCustomLoginUnknownError extends TooningClientError {}

class TooningLogOutError extends TooningClientError {}

class TooningLoginError extends TooningClientError {}

class TooningCloneError extends TooningClientError {}

class TooningSaveCutError extends TooningClientError {}

class TooningShowPanelError extends TooningClientError {}

class TooningGoPanelError extends TooningClientError {}

class TooningPageCopyError extends TooningClientError {}

class TooningPageDeleteError extends TooningClientError {}

class TooningPagesDeleteError extends TooningClientError {}

class TooningPageUpdateSubjectError extends TooningClientError {}

class TooningPageUpdateTimeOutError extends TooningClientError {}

class TooningPageUpdateError extends TooningClientError {}

class TooningFirstCanvasOrderPreviewUpdateError extends TooningClientError {}

class TooningDownloadIndivisualFileError extends TooningClientError {}

class TooningCalculatePageCountByCanvasHeightError extends TooningClientError {}

class TooningCheckPageCountExceededError extends TooningClientError {}

class TooningMergeAllImageError extends TooningClientError {}

class TooningDownloadZipError extends TooningClientError {}

class TooningPrintTypeChangeError extends TooningClientError {}

class TooningUserResourceConvertError extends TooningClientError {}

class TooningUserResourceMatchingError extends TooningClientError {}

class TooningUserResourceUploadError extends TooningClientError {}

class TooningItemReorderError extends TooningClientError {}

class TooningTagError extends TooningClientError {}

class TooningAuthorTypeSaveError extends TooningClientError {}

class TooningPagePushError extends TooningClientError {}

class TooningLoadFromJSONError extends TooningClientError {}

class TooningSlideError extends TooningClientError {}

class TooningNotSelectedError extends TooningClientError {}

class TooningNotMatchError extends TooningClientError {}

class TooningNotAllowedFileError extends TooningClientError {}

class TooningFetchAiDataError extends TooningClientError {}

class TooningFetchItemDataError extends TooningClientError {}

class TooningDefaultCharacterError extends TooningClientError {}

class TooningDrawBackgroundError extends TooningClientError {}

class TooningDrawItemError extends TooningClientError {}

class TooningActionBodyError extends TooningClientError {}

class TooningClickUserError extends TooningClientError {}

class TooningAddTextError extends TooningClientError {}

class TooningGetPageDataInPanelError extends TooningClientError {}

class TooningDeleteTextTemplateError extends TooningClientError {}

class TooningTextSelectionChangeError extends TooningClientError {}

class TooningTextSelectionStyleError extends TooningClientError {}

class TooningStrokeWidthStyleError extends TooningClientError {}

class TooningTextSetStrokeWidthError extends TooningClientError {}

class TooningTextComponentInitError extends TooningClientError {}

class TooningTextScrollFontSearchError extends TooningClientError {}

class TooningTextNgOnChangesError extends TooningClientError {}

class TooningCheckSelectStatusError extends TooningClientError {}

class TooningAuthGuardError extends TooningClientError {}

class TooningTextScalingError extends TooningClientError {}

class TooningTextChangedError extends TooningClientError {}

class TooningTextEditingEnteredError extends TooningClientError {}

class TooningTextEditingExitedError extends TooningClientError {}

class TooningTextAlignError extends TooningClientError {}

class TooningTextIsBoldError extends TooningClientError {}

class TooningTextFontWeightError extends TooningClientError {}

class TooningTextUnderlineError extends TooningClientError {}

class TooningTextFontStyleError extends TooningClientError {}

class TooningTextChangeColorError extends TooningClientError {}

class TooningTextObjectApplyColorError extends TooningClientError {}

class TooningTextSetFontSizeError extends TooningClientError {}

class TooningTextFontSizeBlurError extends TooningClientError {}

class TooningTextFontSizefocusError extends TooningClientError {}

class TooningTextFontSizeChangeError extends TooningClientError {}

class TooningTextCheckSizeError extends TooningClientError {}

class TooningTextIsNumberError extends TooningClientError {}

class TooningTextNgOnDestroyError extends TooningClientError {}

class TooningTextEmptyError extends TooningClientError {}

class TooningTextGetFontListError extends TooningClientError {}

class TooningTextGetFontTypeError extends TooningClientError {}

class TooningTextGetVisibleFontCountError extends TooningClientError {}

class TooningTextSetFontListError extends TooningClientError {}

class TooningTextOpacityChangeError extends TooningClientError {}

class TooningTextIsEqualStyleError extends TooningClientError {}

class TooningTextMixedChangeError extends TooningClientError {}

class TooningTextCheckError extends TooningClientError {}

class TooningTextinitTextStrokeStyleError extends TooningClientError {}

class TooningServerConnectionError extends TooningClientError {}

class TooningGetFontFamilyError extends TooningClientError {}

class TooningB64toBlobError extends TooningClientError {}

class TooningFabricToBlobError extends TooningClientError {}

class TooningCapturePngError extends TooningClientError {}

class TooningCreatePageNullError extends TooningClientError {}

class TooningGetNerError extends TooningClientError {}

class TooningFetchAiElementError extends TooningClientError {}

class TooningColorPopUpError extends TooningClientError {}

class TooningLineWidthError extends TooningClientError {}

class TooningInjectColorInObjError extends TooningClientError {}

class TooningCombineColorError extends TooningClientError {}

class TooningHexToRgbaError extends TooningClientError {}

class TooningStcWebCamSetupOnError extends TooningClientError {}

class TooningInitFillHistoryError extends TooningClientError {}

class TooningUnshiftFillColorError extends TooningClientError {}

class TooningDuplicateDeleteError extends TooningClientError {}

class TooningInitCanvasColorHistoryError extends TooningClientError {}

class TooningAddMyColorFailError extends TooningClientError {}

class TooningInitCanvasFailError extends TooningClientError {}

class TooningCanvasListUpdateFailError extends TooningClientError {}

class TooningCanvasListSetFailError extends TooningClientError {}

class TooningScrollCanvasListFailError extends TooningClientError {}

class TooningSearchCanvasFailError extends TooningClientError {}

class TooningChangeColorBoxStateError extends TooningClientError {}

class TooningEditColorError extends TooningClientError {}

class TooningWhitchLineError extends TooningClientError {}

class TooningCheckIndexError extends TooningClientError {}

class TooningStrokeUniformToggleError extends TooningClientError {}

class TooningColorScrollErrorError extends TooningClientError {}

class TooningColorSelectAllErrorError extends TooningClientError {}

class TooningOutsideClickError extends TooningClientError {}

class TooningEnNameCheckError extends TooningClientError {}

class TooningGetFaceAiPredictionError extends TooningClientError {}

class TooningTextBoxButtonError extends TooningClientError {}

class TooningIntegratedSearchSetListError extends TooningClientError {}

class TooningIntegratedSearchNgOnInitError extends TooningClientError {}

class TooningClipBoardCopyError extends TooningClientError {}

class TooningSelectUpdateError extends TooningClientError {}

class TooningInstagramShareError extends TooningClientError {}

class TooningCanvasShareLinkCopyError extends TooningClientError {}

class TooningCanvasShareIonViewDidEnterError extends TooningClientError {}

class TooningResourcePurchaseInitError extends TooningClientError {}

class TooningDownloadError extends TooningClientError {}

class TooningDownloadPptError extends TooningClientError {}

class TooningDownloadPdfError extends TooningClientError {}

class TooningWaterMarkCheckError extends TooningClientError {}

class TooningPageSetUpError extends TooningClientError {}

class TooningLoadFromJsonEmptyError extends TooningClientError {}

class TooningEditingTextUpdateError extends TooningClientError {}

class TooningInitCanvasHistoryEventHandlerError extends TooningClientError {}

class ToonigSetColorError extends TooningClientError {}

class ToonigCut4MakeManulPageInitError extends TooningClientError {}

class TooningClickFontError extends TooningClientError {}

class TooningForceNoFocusError extends TooningClientError {}

class TooningFontMatchingError extends TooningClientError {}

class TooningPageWithCanvasUpdateStateError extends TooningClientError {}

class TooningCropModalInitError extends TooningClientError {}

class TooningCropModalEnteredError extends TooningClientError {}

class TooningCropPathError extends TooningClientError {}

class TooningCheckControlsInCropError extends TooningClientError {}

class TooningCropModifiedError extends TooningClientError {}

class TooningCropModalSetZoomError extends TooningClientError {}

class TooningCropApplyError extends TooningClientError {}

class TooningCropCancelError extends TooningClientError {}

class TooningAddObToCropModalError extends TooningClientError {}

class TooningBoundaryCheckAllImageIsTransparentError extends TooningClientError {}

class TooningBoundaryCheckError extends TooningClientError {}

class TooningActionChangeInitError extends TooningClientError {}

class TooningTemplateListViewDidEnterError extends TooningClientError {}

class TooningAnalyticsServiceError extends TooningClientError {}

class TooningEdgeDetectionError extends TooningClientError {}

class TooningGetUserIndexError extends TooningClientError {}

class TooningGetUserEmailError extends TooningClientError {}

class TooningGetUserCountryError extends TooningClientError {}

class TooningTextAllLengthSetError extends TooningClientError {}

class TooningCanvasDeletedCheckError extends TooningClientError {}

class TooningAiOneCharacterSituationLayoutError extends TooningClientError {}

class TooningAiTwoCharacterSituationLayoutError extends TooningClientError {}

class TooningAiThreeCharacterSituationLayoutError extends TooningClientError {}

class TooningGetElementRealAreaError extends TooningClientError {}

class TooningChangeTttLayoutError extends TooningClientError {}

class TooningChangeAllCharacterError extends TooningClientError {}

class TooningChangeCharacterDirectError extends TooningClientError {}

class TooningResetAllPagesError extends TooningClientError {}

class TooningInitCanvasSizeError extends TooningClientError {}

class TooningCheckAiSentenceError extends TooningClientError {}

class TooningCharacterResourcePartsCheckError extends TooningClientError {}

class TooningObjectMovingError extends TooningClientError {}

class TooningObjectMovingAltCopyPasteError extends TooningClientError {}

class TooningAltCopyAndPasteError extends TooningClientError {}

class TooningGoPanelWithCanvasUpdateState extends TooningClientError {}

class TooningPageCreateTimeOutError extends TooningClientError {}

class TooninglayerPopoverError extends TooningClientError {}

class TooningCommandExecuteError extends TooningClientError {}

class TooningCommandUndoError extends TooningClientError {}

class TooningCommandRedoError extends TooningClientError {}

class TooningObjectModifiedHandlerError extends TooningClientError {}

class TooningObjectMovedHandlerError extends TooningClientError {}

class TooningObjectScaledHandlerError extends TooningClientError {}

class TooningObjectSkewedHandlerError extends TooningClientError {}

class TooningObjectRotatedHandlerError extends TooningClientError {}

class TooningObjectPurchasedHandlerError extends TooningClientError {}

class TooningObjectRemoveError extends TooningClientError {}

class TooningHammerMobileTouchEventSetupError extends TooningClientError {}

class TooningHammerMobileTouchEventSetupOffError extends TooningClientError {}

class TooningHammerMobileHammerManagerError extends TooningClientError {}

class TooningTouchGestureError extends TooningClientError {}

class TooningHammerDoubleTapError extends TooningClientError {}

class TooningTouchLongPressError extends TooningClientError {}

class TooningCustomUserUpdateHandlerError extends TooningClientError {}

class TooningPresentAlertError extends TooningClientError {}

class TooningDemoModalError extends TooningClientError {}

class TooningWhalespacePagError extends TooningClientError {}

class TooningRemoveForeverError extends TooningClientError {}

class TooningRestoreFromRecycleBinError extends TooningClientError {}

class TooningCanvasClickError extends TooningClientError {}

class TooningCanvasGroupEnterError extends TooningClientError {}

class TooningCanvasGroupClickError extends TooningClientError {}

class TooningDropMoveCanvasGroupError extends TooningClientError {}

class TooningMoveCanvasGroupError extends TooningClientError {}

class TooningSetCanvasGroupListError extends TooningClientError {}

class TooningCanvasGroupNewMakeError extends TooningClientError {}

class TooningCanvasGroupAddAlertError extends TooningClientError {}

class TooningCanvasBoundingBoxError extends TooningClientError {}

class TooningCanvasGroupShareAlertError extends TooningClientError {}

class TooningCanvasGroupSubmitAlertError extends TooningClientError {}

class TooningCanvasGroupSubmitError extends TooningClientError {}

class TooningCanvasGroupCodeCopyError extends TooningClientError {}

class TooningCanvasCloneError extends TooningClientError {}

class TooningCheckAndSetDefaultCanvasGroupsError extends TooningClientError {}

class TooningColorPopupCancelError extends TooningClientError {}

class TooningBlurError extends TooningClientError {}

class TooningDemoDummyLoginError extends TooningClientError {}

class TooningDemoEditTemplateError extends TooningClientError {}

class TooningPaidReferralModalError extends TooningClientError {}

class TooningEventSetupError extends TooningClientError {}

class TooningInitCommandManagerSetupError extends TooningClientError {}

class TooningRemoveWaterMarkError extends TooningClientError {}

class TooningResetBrushOptionsError extends TooningClientError {}

class TooningCopyHandlerError extends TooningClientError {}

class TooningPasteHandlerError extends TooningClientError {}

class TooningChangeCanvasGropTitle extends TooningClientError {}

class TooningChangeCanvasTitle extends TooningClientError {}

class TooningAllCleanError extends TooningClientError {}

class TooningMoveCanvasGroupAlertRadioError extends TooningClientError {}

class TooningRemovePagesError extends TooningClientError {}

class TooningCanvasZoomInDrawingError extends TooningClientError {}

class TooningColorChangeInDrawingError extends TooningClientError {}

class TooningModeChangeInDrawingError extends TooningClientError {}

class TooningImageForColorPickerInDrawingError extends TooningClientError {}

class TooningReadPixelColorInDrawingError extends TooningClientError {}

class TooningSetPixelColorInDrawingError extends TooningClientError {}

class TooningDeleteCanvasesError extends TooningClientError {}

class TooningRestoreCanvasesFromRecycleBinError extends TooningClientError {}

class TooningSetFeatureLimitError extends TooningClientError {}

class TooningCheckDetailChangedError extends TooningClientError {}

class TooningInitCanvasZoomInDrawingError extends TooningClientError {}

class TooningResizeCanvasInDrawingError extends TooningClientError {}

class TooningInitDrawingError extends TooningClientError {}

class TooningSbriResultPreviewError extends TooningClientError {}

class TooningBrushSettingError extends TooningClientError {}

class TooningChangeBrushWidthError extends TooningClientError {}

class TooningChangeBrushIntensityError extends TooningClientError {}

class TooningDrawingModalCancelError extends TooningClientError {}

class TooningSetColorPickerInDrawingError extends TooningClientError {}

class TooningSetZoomModeInDrawingError extends TooningClientError {}

class TooningSetDefaultWaterMarkError extends TooningClientError {}

class TooningSaveCopyTextInfoError extends TooningClientError {}

class TooningTossError extends TooningClientError {}

class TooningMobileCopyError extends TooningClientError {}

class TooningCopyObjectError extends TooningClientError {}

class TooningMobilePasteError extends TooningClientError {}

class TooningTextCopyClipboardError extends TooningClientError {}

class TooningSlideChangeError extends TooningClientError {}

class TooningCheckBeginOrEndError extends TooningClientError {}

class TooningGoToFirstIndexError extends TooningClientError {}

class TooningOnSplitByWordError extends TooningClientError {}

class TooningChangeSplitByWordError extends TooningClientError {}

class TooningSetLaboratoryError extends TooningClientError {}

class TooningPagesToGifError extends TooningClientError {}

class TooningFfmpegInitError extends TooningClientError {}

class TooningGifMakPaletteError extends TooningClientError {}

class TooningToOriginItemError extends TooningClientError {}

class TooningGetAiFilterImageGoError extends TooningClientError {}

class TooningGetResizedBase64ImageError extends TooningClientError {}

class TooningSetPositionError extends TooningClientError {}

class TooningGetCanvasError extends TooningClientError {}

class TooningGetAiFilterImageError extends TooningClientError {}

class TooningOpenDrawingToolBoxError extends TooningClientError {}

class TooningSetGroupResourceTextStrokeWidthError extends TooningClientError {}

class TooningPageSwitchUpdateError extends TooningClientError {}

class TooningOpenMakeToonError extends TooningClientError {}

class TooningCanvasNewMakeError extends TooningClientError {}

class TooningResizeBase64Error extends TooningClientError {}

class TooningSetGridError extends TooningClientError {}

class TooningHeadRotationChangeError extends TooningClientError {}

class TooningLineChangeWidthError extends TooningClientError {}

class TooningOnShadowChangeError extends TooningClientError {}

class TooningFilterResetError extends TooningClientError {}

class TooningFilterDetailChangeError extends TooningClientError {}

class TooningGetEdgeImgSrcError extends TooningClientError {}

class TooningImageComponentOnChangeError extends TooningClientError {}

class TooningApplyFilterValueError extends TooningClientError {}

class TooningFilterDetailResetError extends TooningClientError {}

class TooningSetOpacityError extends TooningClientError {}

class TooningPagesToVideoError extends TooningClientError {}

class TooningFfmpegExportError extends TooningClientError {}

class TooningFramePerSecChangeError extends TooningClientError {}

class TooningUpdateVideoThumbnailError extends TooningClientError {}

class TooningUpdateGifThumbnailError extends TooningClientError {}

class TooningFileTypeChangeError extends TooningClientError {}

class TooningAddBgColorError extends TooningClientError {}

class TooningSetBgColorSizeError extends TooningClientError {}

class TooningBgColorPopupOpenError extends TooningClientError {}

class TooningChangeColorError extends TooningClientError {}

class TooningPayPalInitError extends TooningClientError {}

class TooningPublishAllMemesError extends TooningClientError {}

class TooningCanvasNewMakeForDemoError extends TooningClientError {}

class TooningAddPageError extends TooningClientError {}

class TooningOpenModalAIError extends TooningClientError {}

class TooningFreeCheckError extends TooningClientError {}

class TooningKakaoLoginError extends TooningClientError {}

class TooningWhalespaceLoginGoError extends TooningClientError {}

class TooningAppleLoginGoError extends TooningClientError {}

class TooningGetEmotionError extends TooningClientError {}

class TooningGetEmotionTimeOutError extends TooningClientError {}

class TooningGraphQLError extends TooningClientError {}

class TooningAddPageCommandError extends TooningClientError {}

class TooningBackButtonError extends TooningClientError {}

class TooningSetRTLError extends TooningClientError {}

class TooningRouteEventHandlerError extends TooningClientError {}

class TooningMakeLogStreamNameError extends TooningClientError {}

class TooningText2ImgError extends TooningClientError {}

class TooningOnText2ImgError extends TooningClientError {}

class TooningStableDiffusionInputError extends TooningClientError {}

class TooningStableDiffusionStyleError extends TooningClientError {}

class TooningInputLengthCheckError extends TooningClientError {}

class TooningRestrictedWordsError extends TooningClientError {}

class TooningSimulationGifError extends TooningClientError {
  code = 2370;
}

class TooningSimulationVideoError extends TooningClientError {}

class TooningGifOfPlayChangeError extends TooningClientError {
  code = 2380;
}

class TooningAIRelearnngError extends TooningClientError {
  code = 2390;
}

class TooningGuideMaskBringToFrontError extends TooningClientError {}

class TooningAllSelectError extends TooningClientError {}

class TooningAllUnLockError extends TooningClientError {}

class TooningAllLockError extends TooningClientError {}

class TooningIsLockIconChangeError extends TooningClientError {}

class TooningIsCanvasBoundingOutError extends TooningClientError {}

class TooningCompleteColorError extends TooningClientError {}

class TooningColorPickerComponentNgDestroyError extends TooningClientError {}

class TooningOnFocusError extends TooningClientError {}

class TooningIsViewPropertyError extends TooningClientError {}

class TooningRequestPaymentToIamportError extends TooningClientError {}

class TooningPayMethodClickError extends TooningClientError {}

class TooningControlSubscriptionError extends TooningClientError {}

class TooningCharacterAngleChangeError extends TooningClientError {}

class TooningAlerOnDidDismissError extends TooningClientError {}

class TooningWebCamPauseAndCaptureError extends TooningClientError {}

class TooningStcResultViewError extends TooningClientError {}

class TooningStcOnFileChanged extends TooningClientError {}

class TooningStcInitError extends TooningClientError {}

class TooningDoPointChargeError extends TooningClientError {}

class TooningFailIamportBillingPaymentError extends TooningClientError {}

class TooningFailRequestIamportGetBillingKeyError extends TooningClientError {}

class TooningFailRequestIamportPaymentError extends TooningClientError {}

class TooningRequestChargePointError extends TooningClientError {}

class TooningIamportPointChargeError extends TooningClientError {}

class TooningMakeVideoBlobError extends TooningClientError {}

class TooninSetPageListError extends TooningClientError {}

class TooningSetWaterMarkResizeError extends TooningClientError {}

class TooningLandingCharacterSlideError extends TooningClientError {}

class TooningLandingLoadAnimationError extends TooningClientError {}

class TooningLandingCheckVisibleError extends TooningClientError {}

class TooningLandingOnScrollError extends TooningClientError {}

class TooningLandingFadeInAnimationError extends TooningClientError {}

class TooningLandingFadeDownAnimation70Error extends TooningClientError {}

class TooningLandingFadeDownAnimation100Error extends TooningClientError {}

class TooningLandingAnimationExcuteError extends TooningClientError {}

class TooningCoolSchoolLoginError extends TooningClientError {}

class TooningGetAccessTokenCoolSchoolError extends TooningClientError {}

class TooningCoolSchoolLoginGoError extends TooningClientError {}

class TooningCoolSchoolPageError extends TooningClientError {}

class TooningClearSlideIntervalError extends TooningClientError {}

class TooningPrivacyPolicyError extends TooningClientError {}

class TooningTermsAndUseError extends TooningClientError {}

class TooningLoginGoogleError extends TooningClientError {}

class TooningAppBlockError extends TooningClientError {}

class TooningOriginalAddError extends TooningClientError {}

class TooningOriginalAddGoError extends TooningClientError {}

class TooningToSvgError extends TooningClientError {}

class TooningShortCutCtrlSError extends TooningClientError {}

class TooningPagePreviewError extends TooningClientError {}

class TooningUpdatePagePreviewInLocalError extends TooningClientError {}

class TooningZoomCanvasError extends TooningClientError {}

class TooningHandMoveModeChange extends TooningClientError {}

class TooningCancelPaymentError extends TooningClientError {}

class TooningResetPaymentDataError extends TooningClientError {}

class TooningIamportRequestPayError extends TooningClientError {}

class TooningIamportSdkError extends TooningClientError {}

class TooningCharacterIdNullError extends TooningClientError {}

class TooningApplyCanvasError extends TooningClientError {}

class TooningEditTemplateError extends TooningClientError {}

class TooningSetSceneLightError extends TooningClientError {}

class TooningChangeMaterialToLineError extends TooningClientError {}

class TooningChangeModelLineError extends TooningClientError {}

class TooningSetGroundError extends TooningClientError {}

class TooningCheckLineAppliedError extends TooningClientError {}

class TooningFontSearchError extends TooningClientError {}

class TooningSetFontListMapError extends TooningClientError {}

class TooningSetAllfontListInOrderError extends TooningClientError {}

class TooningDetailPageError extends TooningClientError {}

class TooningTemplateCopyError extends TooningClientError {}

class TooningNavigatorError extends TooningClientError {}

class TooningAiButtonError extends TooningClientError {}

class TooningAddImageAndSvgFromObjectForHandlerError extends TooningClientError {}

class TooningPasteAddFromImageError extends TooningClientError {}

class TooningPasteAddImageFromOutsideError extends TooningClientError {}

class TooningModileIamportRedirectError extends TooningClientError {}

class TooningModileIamportPaymentError extends TooningClientError {}

class TooningUpdateCardCompanyStatusError extends TooningClientError {}

class TooningSetServerRegionError extends TooningClientError {}

class TooningIamportComponentNgOnInitError extends TooningClientError {}

class TooningGetTimeDifferenceError extends TooningClientError {}

class TooningIsDeleteCheckError extends TooningClientError {}

class TooningReplaceAllCharacterError extends TooningClientError {}

class TooningSearchAllCharacterError extends TooningClientError {}

class TooningCharacterReplacerConfirmError extends TooningClientError {}

class TooningExecuteCharacterReplacerError extends TooningClientError {}

class TooningReplaceAllTextError extends TooningClientError {}

class TooningSearchAllTextError extends TooningClientError {}

class TooningisValidSearchTextError extends TooningClientError {}

class TooningTextReplacerConfirmError extends TooningClientError {}

class TooningExecuteTextReplacerError extends TooningClientError {}

class TooningCaluateTTICostError extends TooningClientError {}

class TooningTTISizeChangeError extends TooningClientError {}

class TooningOpenTTIGuideError extends TooningClientError {}

class TooningVerifyPhoneNumberError extends TooningClientError {}

class TooningMakeTextEditableOnKeyboardError extends TooningClientError {}

class TooningIsDuplicatedEditableTextError extends TooningClientError {}

class TooningOpenTTIAdvancedClickError extends TooningClientError {}

class TooningTTILoadingEnterError extends TooningClientError {}

class TooningTTIProgressBarError extends TooningClientError {}

class TooningInitUpdateCheckerError extends TooningClientError {}

class TooningNotificationCloseError extends TooningClientError {}

class TooningChangeDefaultPaymentApplyClickError extends TooningClientError {}

class TooningChangeDefaultPaymentRadioClickError extends TooningClientError {}

class TooningRequestSubscriptionUsingBillingKeyError extends TooningClientError {}

class TooningOpenIamportSdkForRegisterBillingKeyError extends TooningClientError {}

class TooningFailRegisterBillingKeyError extends TooningClientError {}

class TooningGetIamportPaymentInfoError extends TooningClientError {}

class TooningChangeDefaultPaymentError extends TooningClientError {}

class TooningCheckPaypelUserError extends TooningClientError {}

class TooningClickRequestBtnError extends TooningClientError {}

class TooningLeftLoadTimeOutError extends TooningClientError {}

class TooningRightLoadTimeOutError extends TooningClientError {}

class TooningLegLoadTimeOutError extends TooningClientError {}

class TooningDecodeJwtResponseError extends TooningClientError {}

class TooningHandleCredentialResponseError extends TooningClientError {}

class TooningOnCreateAddCharacterError extends TooningClientError {}

class TooningClickResetBtnError extends TooningClientError {}

class TooningOpenAskRefundAlertError extends TooningClientError {}

class TooningCheckBeforeClickPayMethodError extends TooningClientError {}

class TooningAllocateTimeUnitsError extends TooningClientError {}

class TooningModifyNumberError extends TooningClientError {}

class TooningGetUniquRandomNumberError extends TooningClientError {}

class TooningShareFullScreenError extends TooningClientError {}

class TooningShareSlideDidChangeError extends TooningClientError {}

class TooningSlideszoomOutError extends TooningClientError {}

class TooningSlideszoomInError extends TooningClientError {}

class TooninghandleScrollError extends TooningClientError {}

class TooningChangeSafeUrlToBase64Error extends TooningClientError {}

class TooningResizeImageError extends TooningClientError {}

class TooningOrientationChangeError extends TooningClientError {}

class TooningFaceSelectedError extends TooningClientError {}

class TooningAllBodySelectedError extends TooningClientError {}

class TooningGetOnCreateAddCharacterError extends TooningClientError {}

class TooningChangeObItemError extends TooningClientError {}

class TooningChangeObArmError extends TooningClientError {}

class TooningChangeObItemGetFabricHttpError extends TooningClientError {}

class TooningChangeObArmGetFabricHttpError extends TooningClientError {}

class TooningHeadChangeDidEnterError extends TooningClientError {}

class TooningLazyLoadingError extends TooningClientError {}

class TooningCrcChainError extends TooningClientError {}

class TooningCharacterSelectedGoError extends TooningClientError {}

class TooningColorSetAddError extends TooningClientError {}

class TooningSetResourceRealAreaForAi extends TooningClientError {}

class TooningGetRealAreaError extends TooningClientError {}

class TooningUserIdIsNullError extends TooningClientError {}

class TooningSetUpdatedDateError extends TooningClientError {}

class TooningLoginUIError extends TooningClientError {}

class TooningSignUpUIError extends TooningClientError {}

class TooningOpenModalLoginError extends TooningClientError {}

class TooningSignupVerificationUIError extends TooningClientError {}

class TooningVerifyEmailError extends TooningClientError {}

class TooningSignUpChangeVerificationTypeError extends TooningClientError {}

class TooningIonViewDidEnter extends TooningClientError {}

class TooningConvertToBase64Error extends TooningClientError {}

class TooningViewChangeError extends TooningClientError {}

class TooningGenerateElementRefBase64Error extends TooningClientError {}

class TooningConvertImgBase64ToBlobError extends TooningClientError {}

class TooningCalculateTotalCostError extends TooningClientError {}

class TooningCountControltError extends TooningClientError {}

class TooningButtonDisabledCheckError extends TooningClientError {}

class TooningGenerateRequestNumberError extends TooningClientError {}

class TooningEdeQuoteError extends TooningClientError {}

class TooningResizeInputError extends TooningClientError {}

class TooningSaveAsError extends TooningClientError {}

class TooningGetListList extends TooningClientError {}

class TooningTeacherCertificationSubmitError extends TooningClientError {}

class TooningPointChargeUIError extends TooningClientError {}

class TooningAlertExistsUser extends TooningClientError {}

class TooningIndexedDBAddCopyData extends TooningClientError {}

class TooningIndexedDBGetCopyData extends TooningClientError {}

class TooningOpenEstimateModalError extends TooningClientError {}

class TooningConvertToFormattedDateError extends TooningClientError {}

class TooningSetNextPaymentDayForEventUserError extends TooningClientError {}

class TooningGetPaymentInfoError extends TooningClientError {}

class TooningEstimateChangeUserCountError extends TooningClientError {}

class TooningReRenderGoogleLoginButtonError extends TooningClientError {}

class TooningJwtTokenVerifyError extends TooningClientError {}

class TooningJwtTokenExpiredError extends TooningClientError {}

class TooningTokenInterceptorErrorHandlerError extends TooningClientError {}

class TooningLoginEmailNullError extends TooningClientError {}

class TooningGetSocialUserInformationError extends TooningClientError {}

class TooningallTabsLogoutError extends TooningClientError {}

class TooningAllTabsLoginError extends TooningClientError {}

class TooningRegisterStorageListenerError extends TooningClientError {}

class TooningCloseError extends TooningClientError {}

class TooningTemplateApplyAllError extends TooningClientError {}

class TooningTemplateApplyPageError extends TooningClientError {}

class TooningViewMoreError extends TooningClientError {}

class TooningCanvasSizePresetChangeError extends TooningClientError {}

class TooningCanvasSizeCustomChangeError extends TooningClientError {}

class TooningCanvasSizePopoverCloseError extends TooningClientError {}

class TooningCanvasSizeRatioLockError extends TooningClientError {}

class TooningCanvasSizeUnitChangeError extends TooningClientError {}

class TooningCanvasSizeNumOverCheckError extends TooningClientError {}

class TooningCanvasSizeUnitToPxError extends TooningClientError {}

class TooningCanvasSizeUnitToMmError extends TooningClientError {}

class TooningCanvasSizeUnitToCmError extends TooningClientError {}

class TooningCanvasSizeUnitToInError extends TooningClientError {}

class TooningCanvasSizeCalculationError extends TooningClientError {}

class TooningCanvasSizeLimitError extends TooningClientError {}

class TooningCanvasEventOffError extends TooningClientError {}

class TooningPopoverSizeInitError extends TooningClientError {}

class TooningSetOutsideBgColorError extends TooningClientError {}

class TooningColorPickerApplyError extends TooningClientError {}

class TooningCheckOutsideTaskError extends TooningClientError {}

class TooningValidationError extends TooningClientError {}

class TooningCutListChangeOrderByError extends TooningClientError {}

class TooningCheckSubscriberError extends TooningClientError {}

class TooningShowCardUpdateViewError extends TooningClientError {}

class TooningOpenIamportSdkForPointError extends TooningClientError {}

class TooningFailTossPaymentUsingBillingKeyError extends TooningClientError {}

class TooningOnFileChangedError extends TooningClientError {}

class TooningTotalSetApplicantError extends TooningClientError {}

class TooningApplyApplicantError extends TooningClientError {}

class TooningRejectApplicantError extends TooningClientError {}

class TooningChangeFormatApplicantError extends TooningClientError {}

class TooningCompleteUpgradeError extends TooningClientError {}

class TooningCutListIonViewDidEnterError extends TooningClientError {}

class TooningSetPaginationConfigError extends TooningClientError {}

class TooningGetInstanceError extends TooningClientError {}

class TooningSetCut3dError extends TooningClientError {}

class TooningCommandManager3dExecuteCommandError extends TooningClientError {}

class TooningCommandManager3dUndoError extends TooningClientError {}

class TooningCommnadManager3dRedoError extends TooningClientError {}

class TooningGetSelectedObBeforeValue extends TooningClientError {}

class TooningObject3dMoveHandler extends TooningClientError {}

class TooningObject3dResizeHandler extends TooningClientError {}

class TooningObject3dRotateHandler extends TooningClientError {}

class TooningcloseRemoteController extends TooningClientError {}

class TooningMakeGifError extends TooningClientError {}

class TooningAddBase64ToImageError extends TooningClientError {}

class TooningSetThresholdImageError extends TooningClientError {}

class TooningSetImageSourceFromUrlError extends TooningClientError {}

class TooningDownloadGifError extends TooningClientError {}

class TooningDownloadModalInitError extends TooningClientError {}

class TooningFindSafeUrlIndexError extends TooningClientError {}

class TooningCurrentUserError extends TooningClientError {}

class TooningCheckRegexWarningMessage extends TooningClientError {}

class TooningIsResourceWithWaterMarkError extends TooningClientError {}

class TooningOpenAiFilterModalError extends TooningClientError {}

class TooningClickApplyEduProError extends TooningClientError {}

class TooningEmailMaskError extends TooningClientError {}

class TooningB2gAdminApplyError extends TooningClientError {}

class TooningGetSubscribersError extends TooningClientError {}

class TooningAdminListPageNgOninitError extends TooningClientError {}

class TooningSearchUserError extends TooningClientError {}

class TooningOnScrollDownError extends TooningClientError {}

class TooningZoomPopupOpenError extends TooningClientError {}

class TooningChangeIsFoldError extends TooningClientError {}

class TooningOutsideMouseCheckError extends TooningClientError {}

class TooningConfettiError extends TooningClientError {}

class TooningCheckCharacterPurchasedError extends TooningClientError {}

class TooningSetCursorInDrawingError extends TooningClientError {}

class TooningKakaoUnlinkError extends TooningClientError {}

class TooningGoEditorError extends TooningClientError {}

class TooningCacheSetError extends TooningClientError {}

class TooningCacheGetError extends TooningClientError {}

class TooningShowCanvasCountWarningError extends TooningClientError {}

class TooningSetViewMagicStyleError extends TooningClientError {}

class TooningSetMagicStyleCharacterError extends TooningClientError {}

class TooningInitAligningGuidelinesError extends TooningClientError {}

class TooningBackToDownloadModalError extends TooningClientError {}

class TooningChangeGifPreviewError extends TooningClientError {}

class TooningSimulationGifPerPage extends TooningClientError {}

class TooningSimulationGifPerPageDelayError extends TooningClientError {}

class TooningShowThumbnailError extends TooningClientError {}

class TooningCalculateTotalPlayTiemeError extends TooningClientError {}

class TooningSelectPageDurationError extends TooningClientError {}

class TooningOpenAddEduGroupPriceError extends TooningClientError {}

class TooningCreatePriceError extends TooningClientError {}

class TooningCreateEstimateIdError extends TooningClientError {}

class TooningEstimateIssuedOnFileChangedError extends TooningClientError {}

class TooningCheckXlsxInvalidError extends TooningClientError {}

class TooningUpdatePriceError extends TooningClientError {}

class TooningDeletePriceError extends TooningClientError {}

class TooningInitEduGroupPriceError extends TooningClientError {}

class TooningTooltipOpenError extends TooningClientError {}

class TooningEstimateIssuedChangeViewError extends TooningClientError {}

class TooningSetResourceScaleError extends TooningClientError {}

class TooningGetIndexInfoError extends TooningClientError {}

class TooningApplyFromJsonError extends TooningClientError {}

class TooningImageForColorPickerError extends TooningClientError {}

class TooningReadPixelColorError extends TooningClientError {}

class TooningSetPixelColorError extends TooningClientError {}

class TooningSetColorPickerError extends TooningClientError {}

class TooningObSelectionForColorPickError extends TooningClientError {}

class TooningEstimateChangeViewError extends TooningClientError {}

class TooningUpdateCharacterActionsError extends TooningClientError {}

class TooningProcessPageCharactersError extends TooningClientError {}

class TooningApplyActionsToAllCharactersError extends TooningClientError {}

class TooningSyncStylesToAllCharactersError extends TooningClientError {}

class TooningApplyColorToAllCharactersError extends TooningClientError {}

class TooningEstimateDropdownCtlError extends TooningClientError {}

class TooningSetEduGroupPriceListError extends TooningClientError {}

class TooningFindEduGroupPriceError extends TooningClientError {}

class TooningFindPromotionPriceError extends TooningClientError {}

class TooningExternalIssuedAccountNgonInitError extends TooningClientError {}

class TooningApplyAccessoryToAllCharactersError extends TooningClientError {}

class TooningCheckAndSetServerRegionError extends TooningClientError {}

class TooningCanvasShareAuthorCheckError extends TooningClientError {}

class TooningApplyCanvasShareCloneCanvasError extends TooningClientError {}

class TooningSetEduEstimateListError extends TooningClientError {}

class TooningCanvasDragTaskError extends TooningClientError {}

class TooningResourceControlTaskError extends TooningClientError {}

class TooningDesktopCheckTaskError extends TooningClientError {}

class TooningWhenTargetIsExistSelectedObSetupTaskError extends TooningClientError {}

class TooningWhenTargetIsExistGroupSelectionTaskError extends TooningClientError {}

class TooningWhenTargetIsExitDefenceTaskError extends TooningClientError {}

class TooningWhenTargetIsExistSelectableTaskError extends TooningClientError {}

class TooningWhenTargetIsEmptyTaskError extends TooningClientError {}

class TooningMakeFaqError extends TooningClientError {}

class TooningFaqAdminApplyError extends TooningClientError {}

class TooningCreateFaqCategoryError extends TooningClientError {}

class TooningHelpCenterCategoryError extends TooningClientError {}

class TooningSearchFaqError extends TooningClientError {}

class TooningHelpCenterModalError extends TooningClientError {}

class TooningHelpCenterWebError extends TooningClientError {}

class TooningModalDestroyHandlerError extends TooningClientError {}

class TooningPasswordViewModeChangeError extends TooningClientError {}

class TooningCanvasTitleInputFocusError extends TooningClientError {}

class TooningCanvasTitleInputUnfocusError extends TooningClientError {}

class TooningCanvasTitleUpdateError extends TooningClientError {}

class TooningSubscribeToColorChanges extends TooningClientError {}

class TooningGetEduGroupPriceInfoError extends TooningClientError {}

class TooningTitleChangeAlertError extends TooningClientError {}

class TooningPointCardREgistrationComplteOnInitError extends TooningClientError {}

class TooningRemoveCacheCanvasError extends TooningClientError {}

class TooningOpenPricingDetailError extends TooningClientError {}

class TooningGetLastObjectError extends TooningClientError {}

class TooningSetGroupWaterMark extends TooningClientError {}

class TooningSetWaterMark extends TooningClientError {}

class TooningSignUpCompleteModalError extends TooningClientError {}

class TooningSnsSignupError extends TooningClientError {}

class TooningGoToSnsAgreementPageError extends TooningClientError {}

class TooningGetSocialUserEmailError extends TooningClientError {}

class TooningCustomJoinButtonClickHandlerError extends TooningClientError {}

class TooningChangeViewTypeClickHandlerError extends TooningClientError {}

class TooningSetBookmarkError extends TooningClientError {}

class TooningActivatedBookmarsError extends TooningClientError {}

export {
  TooningOrientationChangeError,
  TooninghandleScrollError,
  TooningSlideszoomInError,
  TooningSlideszoomOutError,
  TooningShareSlideDidChangeError,
  TooningShareFullScreenError,
  TooningRouteEventHandlerError,
  TooningSetRTLError,
  TooningBackButtonError,
  TooningAddPageCommandError,
  TooningAppleLoginGoError,
  TooningWhalespaceLoginGoError,
  TooningKakaoLoginError,
  TooningFreeCheckError,
  TooningOpenModalAIError,
  TooningBrowserCheckError,
  TooningDemoModalError,
  TooninglayerPopoverError,
  TooningSignUpError,
  TooningPageSetUpError,
  TooningWaterMarkCheckError,
  TooningInstagramShareError,
  TooningCanvasShareLinkCopyError,
  TooningCanvasShareIonViewDidEnterError,
  TooningEnNameCheckError,
  TooningSelectUpdateError,
  TooningClipBoardCopyError,
  TooningStcWebCamSetupOnError,
  TooningOutsideClickError,
  TooningColorSelectAllErrorError,
  TooningColorScrollErrorError,
  TooningSearchCanvasFailError,
  TooningScrollCanvasListFailError,
  TooningCanvasListSetFailError,
  TooningCanvasListUpdateFailError,
  TooningCheckIndexError,
  TooningWhitchLineError,
  TooningEditColorError,
  TooningChangeColorBoxStateError,
  TooningInitCanvasFailError,
  TooningAddMyColorFailError,
  TooningInitCanvasColorHistoryError,
  TooningDuplicateDeleteError,
  TooningUnshiftFillColorError,
  TooningInitFillHistoryError,
  TooningCheckSelectStatusError,
  TooningAuthGuardError,
  TooningStrokeWidthStyleError,
  TooningTextSelectionStyleError,
  TooningTextSelectionChangeError,
  TooningDeleteTextTemplateError,
  TooningGetPageDataInPanelError,
  TooningAddTextError,
  TooningHexToRgbaError,
  TooningCombineColorError,
  TooningInjectColorInObjError,
  TooningColorPopUpError,
  TooningLineWidthError,
  TooningIntegratedSearchSetListError,
  TooningIntegratedSearchNgOnInitError,
  TooningClickUserError,
  TooningActionBodyError,
  TooningDrawItemError,
  TooningDrawBackgroundError,
  TooningDefaultCharacterError,
  TooningNotAllowedFileError,
  TooningNotMatchError,
  TooningNotSelectedError,
  TooningErrorCode,
  TooningBadRequestError,
  TooningUnauthorizedError,
  TooningUnkownNetworkError,
  TooningCustomUserSignUpError,
  TooningCustomUserUpdateError,
  TooningCustomUserEmailEmptyError,
  TooningCustomUserEmailEmptyResetError,
  TooningCustomUserPasswordResetError,
  TooningCustomLoginValidationError,
  TooningCustomLoginUnknownError,
  TooningLogOutError,
  TooningLoginError,
  TooningCloneError,
  TooningCanvasSetupError,
  TooningSaveCutError,
  TooningPageCopyError,
  TooningPageDeleteError,
  TooningPagesDeleteError,
  TooningShowPanelError,
  TooningGoPanelError,
  TooningPageUpdateTimeOutError,
  TooningPageUpdateError,
  TooningPageUpdateSubjectError,
  TooningFirstCanvasOrderPreviewUpdateError,
  TooningDownloadIndivisualFileError,
  TooningDownloadZipError,
  TooningUserResourceConvertError,
  TooningUserResourceMatchingError,
  TooningUserResourceUploadError,
  TooningServerConnectionError,
  TooningItemReorderError,
  TooningTagError,
  TooningLoadFromJSONError,
  TooningGetFontFamilyError,
  TooningB64toBlobError,
  TooningFabricToBlobError,
  TooningCapturePngError,
  TooningCreatePageNullError,
  TooningPagePushError,
  TooningSlideError,
  TooningGetFaceAiPredictionError,
  TooningGetNerError,
  TooningFetchAiElementError,
  TooningFetchAiDataError,
  TooningFetchItemDataError,
  TooningTextBoxButtonError,
  TooningChangeAuthorizeError,
  TooningStrokeUniformToggleError,
  TooningAuthorTypeSaveError,
  TooningResourcePurchaseInitError,
  TooningDownloadError,
  TooningDownloadPdfError,
  TooningDownloadPptError,
  TooningLoadFromJsonEmptyError,
  TooningEditingTextUpdateError,
  TooningEdgeDetectionError,
  TooningInitCanvasHistoryEventHandlerError,
  ToonigSetColorError,
  ToonigCut4MakeManulPageInitError,
  TooningClickFontError,
  TooningForceNoFocusError,
  TooningFontMatchingError,
  TooningTextSetStrokeWidthError,
  TooningTextComponentInitError,
  TooningTextScrollFontSearchError,
  TooningTextNgOnChangesError,
  TooningTextScalingError,
  TooningTextChangedError,
  TooningTextEditingEnteredError,
  TooningTextEditingExitedError,
  TooningTextAlignError,
  TooningTextIsBoldError,
  TooningTextFontWeightError,
  TooningTextUnderlineError,
  TooningTextFontStyleError,
  TooningTextChangeColorError,
  TooningTextObjectApplyColorError,
  TooningTextSetFontSizeError,
  TooningTextFontSizeBlurError,
  TooningTextFontSizefocusError,
  TooningTextFontSizeChangeError,
  TooningTextCheckSizeError,
  TooningTextIsNumberError,
  TooningTextNgOnDestroyError,
  TooningTextEmptyError,
  TooningTextGetFontListError,
  TooningTextGetFontTypeError,
  TooningTextGetVisibleFontCountError,
  TooningTextSetFontListError,
  TooningTextOpacityChangeError,
  TooningTextIsEqualStyleError,
  TooningTextMixedChangeError,
  TooningPageWithCanvasUpdateStateError,
  TooningCropModalInitError,
  TooningCropPathError,
  TooningCheckControlsInCropError,
  TooningCropModalEnteredError,
  TooningCropModifiedError,
  TooningCropModalSetZoomError,
  TooningCropApplyError,
  TooningCropCancelError,
  TooningAddObToCropModalError,
  TooningBoundaryCheckAllImageIsTransparentError,
  TooningBoundaryCheckError,
  TooningActionChangeInitError,
  TooningTextCheckError,
  TooningTextinitTextStrokeStyleError,
  TooningTemplateListViewDidEnterError,
  TooningAnalyticsServiceError,
  TooningGetUserIndexError,
  TooningTextAllLengthSetError,
  TooningCanvasDeletedCheckError,
  TooningAiOneCharacterSituationLayoutError,
  TooningAiTwoCharacterSituationLayoutError,
  TooningAiThreeCharacterSituationLayoutError,
  TooningGetElementRealAreaError,
  TooningChangeTttLayoutError,
  TooningChangeAllCharacterError,
  TooningChangeCharacterDirectError,
  TooningResetAllPagesError,
  TooningInitCanvasSizeError,
  TooningCheckAiSentenceError,
  TooningCharacterResourcePartsCheckError,
  TooningObjectMovingError,
  TooningObjectMovingAltCopyPasteError,
  TooningAltCopyAndPasteError,
  TooningGoPanelWithCanvasUpdateState,
  TooningPageCreateTimeOutError,
  TooningCommandExecuteError,
  TooningCommandUndoError,
  TooningCommandRedoError,
  TooningObjectRemoveError,
  TooningHammerMobileTouchEventSetupError,
  TooningHammerMobileTouchEventSetupOffError,
  TooningHammerMobileHammerManagerError,
  TooningTouchGestureError,
  TooningHammerDoubleTapError,
  TooningTouchLongPressError,
  TooningCustomUserUpdateHandlerError,
  TooningPresentAlertError,
  TooningWhalespacePagError,
  TooningRemoveForeverError,
  TooningRestoreFromRecycleBinError,
  TooningCanvasClickError,
  TooningCanvasGroupEnterError,
  TooningCanvasGroupClickError,
  TooningDropMoveCanvasGroupError,
  TooningMoveCanvasGroupError,
  TooningSetCanvasGroupListError,
  TooningCanvasGroupNewMakeError,
  TooningCanvasGroupAddAlertError,
  TooningCanvasBoundingBoxError,
  TooningCanvasGroupShareAlertError,
  TooningCanvasGroupSubmitAlertError,
  TooningCanvasGroupSubmitError,
  TooningCanvasGroupCodeCopyError,
  TooningCanvasCloneError,
  TooningCanvasZoomInDrawingError,
  TooningCheckAndSetDefaultCanvasGroupsError,
  TooningBlurError,
  TooningDemoDummyLoginError,
  TooningDemoEditTemplateError,
  TooningPaidReferralModalError,
  TooningEventSetupError,
  TooningInitCommandManagerSetupError,
  TooningRemoveWaterMarkError,
  TooningResetBrushOptionsError,
  TooningCopyHandlerError,
  TooningPasteHandlerError,
  TooningChangeCanvasGropTitle,
  TooningChangeCanvasTitle,
  TooningAllCleanError,
  TooningMoveCanvasGroupAlertRadioError,
  TooningRemovePagesError,
  TooningColorPopupCancelError,
  TooningColorChangeInDrawingError,
  TooningModeChangeInDrawingError,
  TooningImageForColorPickerInDrawingError,
  TooningReadPixelColorInDrawingError,
  TooningSetPixelColorInDrawingError,
  TooningDeleteCanvasesError,
  TooningRestoreCanvasesFromRecycleBinError,
  TooningSetFeatureLimitError,
  TooningCheckDetailChangedError,
  TooningInitCanvasZoomInDrawingError,
  TooningResizeCanvasInDrawingError,
  TooningInitDrawingError,
  TooningSbriResultPreviewError,
  TooningBrushSettingError,
  TooningChangeBrushWidthError,
  TooningChangeBrushIntensityError,
  TooningDrawingModalCancelError,
  TooningSetColorPickerInDrawingError,
  TooningSetZoomModeInDrawingError,
  TooningSaveCopyTextInfoError,
  TooningTossError,
  TooningMobileCopyError,
  TooningCopyObjectError,
  TooningMobilePasteError,
  TooningTextCopyClipboardError,
  TooningSlideChangeError,
  TooningCheckBeginOrEndError,
  TooningGoToFirstIndexError,
  TooningSetDefaultWaterMarkError,
  TooningOnSplitByWordError,
  TooningChangeSplitByWordError,
  TooningSetLaboratoryError,
  TooningPagesToGifError,
  TooningFfmpegInitError,
  TooningGifMakPaletteError,
  TooningDownloadModalInitError,
  TooningToOriginItemError,
  TooningGetAiFilterImageGoError,
  TooningGetAiFilterImageError,
  TooningGetResizedBase64ImageError,
  TooningResizeBase64Error,
  TooningLineChangeWidthError,
  TooningHeadRotationChangeError,
  TooningSetPositionError,
  TooningOnShadowChangeError,
  TooningFilterResetError,
  TooningFilterDetailChangeError,
  TooningGetEdgeImgSrcError,
  TooningImageComponentOnChangeError,
  TooningGetCanvasError,
  TooningOpenDrawingToolBoxError,
  TooningSetGroupResourceTextStrokeWidthError,
  TooningPageSwitchUpdateError,
  TooningOpenMakeToonError,
  TooningCanvasNewMakeError,
  TooningApplyFilterValueError,
  TooningFilterDetailResetError,
  TooningSetOpacityError,
  TooningAddBgColorError,
  TooningSetBgColorSizeError,
  TooningBgColorPopupOpenError,
  TooningChangeColorError,
  TooningPagesToVideoError,
  TooningFfmpegExportError,
  TooningText2ImgError,
  TooningOnText2ImgError,
  TooningStableDiffusionInputError,
  TooningStableDiffusionStyleError,
  TooningInputLengthCheckError,
  TooningRestrictedWordsError,
  TooningSetGridError,
  TooningPayPalInitError,
  TooningPublishAllMemesError,
  TooningCanvasNewMakeForDemoError,
  TooningAddPageError,
  TooningClientError,
  TooningCustomClientError,
  logCustomErrorMessage,
  logErrorMessage,
  TooningGetEmotionError,
  TooningGetEmotionTimeOutError,
  TooningGraphQLError,
  TooningNetworkError,
  TooningGetUserEmailError,
  TooningGetUserCountryError,
  TooningCustomUserPasswordIsNotMatchedError,
  TooningAppInitError,
  TooningAppConstructError,
  TooningAppNgAfterViewInit,
  TooningAppPlatformInitError,
  TooningFetchCurrentUserError,
  TooningMakeLogStreamNameError,
  TooningSimulationGifError,
  TooningGifOfPlayChangeError,
  TooningAIRelearnngError,
  TooningFramePerSecChangeError,
  TooningUpdateVideoThumbnailError,
  TooningUpdateGifThumbnailError,
  TooningFileTypeChangeError,
  TooningGuideMaskBringToFrontError,
  TooningAllSelectError,
  TooningAllUnLockError,
  TooningAllLockError,
  TooningIsLockIconChangeError,
  TooningCharacterAngleChangeError,
  TooningIsCanvasBoundingOutError,
  TooningCompleteColorError,
  TooningColorPickerComponentNgDestroyError,
  TooningOnFocusError,
  TooningIsViewPropertyError,
  TooningRequestPaymentToIamportError,
  TooningPayMethodClickError,
  TooningSetWaterMarkResizeError,
  TooningControlSubscriptionError,
  TooningDoPointChargeError,
  TooningFailIamportBillingPaymentError,
  TooningFailRequestIamportGetBillingKeyError,
  TooningFailRequestIamportPaymentError,
  TooningRequestChargePointError,
  TooningIamportPointChargeError,
  TooningMakeVideoBlobError,
  TooningAlerOnDidDismissError,
  TooningWebCamPauseAndCaptureError,
  TooningStcResultViewError,
  TooningStcOnFileChanged,
  TooningStcInitError,
  TooninSetPageListError,
  TooningLandingCharacterSlideError,
  TooningLandingLoadAnimationError,
  TooningLandingCheckVisibleError,
  TooningLandingOnScrollError,
  TooningLandingFadeInAnimationError,
  TooningLandingFadeDownAnimation70Error,
  TooningLandingFadeDownAnimation100Error,
  TooningLandingAnimationExcuteError,
  TooningCoolSchoolLoginError,
  TooningGetAccessTokenCoolSchoolError,
  TooningCoolSchoolLoginGoError,
  TooningCoolSchoolPageError,
  TooningPrivacyPolicyError,
  TooningTermsAndUseError,
  TooningAppBlockError,
  TooningOriginalAddGoError,
  TooningToSvgError,
  TooningShortCutCtrlSError,
  TooningClearSlideIntervalError,
  TooningPagePreviewError,
  TooningUpdatePagePreviewInLocalError,
  TooningZoomCanvasError,
  TooningHandMoveModeChange,
  TooningCancelPaymentError,
  TooningResetPaymentDataError,
  TooningIamportRequestPayError,
  TooningIamportSdkError,
  TooningCharacterIdNullError,
  TooningApplyCanvasError,
  TooningEditTemplateError,
  TooningSetSceneLightError,
  TooningChangeMaterialToLineError,
  TooningChangeModelLineError,
  TooningSetGroundError,
  TooningGetTimeDifferenceError,
  TooningCheckLineAppliedError,
  TooningFontSearchError,
  TooningSetFontListMapError,
  TooningSetAllfontListInOrderError,
  TooningNavigatorError,
  TooningDetailPageError,
  TooningTemplateCopyError,
  TooningOriginalAddError,
  TooningAiButtonError,
  TooningAddImageAndSvgFromObjectForHandlerError,
  TooningPasteAddFromImageError,
  TooningPasteAddImageFromOutsideError,
  TooningModileIamportRedirectError,
  TooningModileIamportPaymentError,
  TooningUpdateCardCompanyStatusError,
  TooningSetServerRegionError,
  TooningIamportComponentNgOnInitError,
  TooningIsDeleteCheckError,
  TooningReplaceAllCharacterError,
  TooningSearchAllCharacterError,
  TooningCharacterReplacerConfirmError,
  TooningExecuteCharacterReplacerError,
  TooningReplaceAllTextError,
  TooningSearchAllTextError,
  TooningisValidSearchTextError,
  TooningTextReplacerConfirmError,
  TooningExecuteTextReplacerError,
  TooningCaluateTTICostError,
  TooningTTISizeChangeError,
  TooningOpenTTIGuideError,
  TooningVerifyPhoneNumberError,
  TooningMakeTextEditableOnKeyboardError,
  TooningIsDuplicatedEditableTextError,
  TooningOpenTTIAdvancedClickError,
  TooningTTILoadingEnterError,
  TooningTTIProgressBarError,
  TooningInitUpdateCheckerError,
  TooningNotificationCloseError,
  TooningChangeDefaultPaymentApplyClickError,
  TooningChangeDefaultPaymentRadioClickError,
  TooningRequestSubscriptionUsingBillingKeyError,
  TooningOpenIamportSdkForRegisterBillingKeyError,
  TooningFailRegisterBillingKeyError,
  TooningGetIamportPaymentInfoError,
  TooningChangeDefaultPaymentError,
  TooningCheckPaypelUserError,
  TooningClickRequestBtnError,
  TooningLeftLoadTimeOutError,
  TooningRightLoadTimeOutError,
  TooningLegLoadTimeOutError,
  TooningLoginGoogleError,
  TooningDecodeJwtResponseError,
  TooningHandleCredentialResponseError,
  TooningOnCreateAddCharacterError,
  TooningCheckBeforeClickPayMethodError,
  TooningClickResetBtnError,
  TooningOpenAskRefundAlertError,
  TooningAllocateTimeUnitsError,
  TooningModifyNumberError,
  TooningChangeSafeUrlToBase64Error,
  TooningResizeImageError,
  TooningFaceSelectedError,
  TooningAllBodySelectedError,
  TooningGetOnCreateAddCharacterError,
  TooningChangeObArmGetFabricHttpError,
  TooningChangeObArmError,
  TooningChangeObItemGetFabricHttpError,
  TooningChangeObItemError,
  TooningHeadChangeDidEnterError,
  TooningLazyLoadingError,
  TooningCrcChainError,
  TooningCharacterSelectedGoError,
  TooningColorSetAddError,
  TooningSetResourceRealAreaForAi,
  TooningGetRealAreaError,
  TooningSetUpdatedDateError,
  TooningGetUniquRandomNumberError,
  TooningUserIdIsNullError,
  TooningLoginUIError,
  TooningSignUpUIError,
  TooningOpenModalLoginError,
  TooningSignupVerificationUIError,
  TooningVerifyEmailError,
  TooningSignUpChangeVerificationTypeError,
  TooningIonViewDidEnter,
  TooningViewChangeError,
  TooningGenerateElementRefBase64Error,
  TooningConvertImgBase64ToBlobError,
  TooningCalculateTotalCostError,
  TooningCountControltError,
  TooningButtonDisabledCheckError,
  TooningGenerateRequestNumberError,
  TooningEdeQuoteError,
  TooningResizeInputError,
  TooningSaveAsError,
  TooningGetListList,
  TooningTeacherCertificationSubmitError,
  TooningPointChargeUIError,
  TooningAlertExistsUser,
  TooningIndexedDBAddCopyData,
  TooningIndexedDBGetCopyData,
  TooningOpenEstimateModalError,
  TooningConvertToFormattedDateError,
  TooningSetNextPaymentDayForEventUserError,
  TooningGetPaymentInfoError,
  TooningEstimateChangeUserCountError,
  TooningConvertToBase64Error,
  TooningJwtTokenVerifyError,
  TooningJwtTokenExpiredError,
  TooningTokenInterceptorErrorHandlerError,
  TooningLoginEmailNullError,
  TooningGetSocialUserInformationError,
  TooningSetOutsideBgColorError,
  TooningColorPickerApplyError,
  TooningCheckOutsideTaskError,
  TooningallTabsLogoutError,
  TooningAllTabsLoginError,
  TooningRegisterStorageListenerError,
  TooningCloseError,
  TooningTemplateApplyAllError,
  TooningTemplateApplyPageError,
  TooningViewMoreError,
  TooningCanvasSizePresetChangeError,
  TooningCanvasSizePopoverCloseError,
  TooningCanvasSizeCustomChangeError,
  TooningCanvasSizeRatioLockError,
  TooningCanvasSizeUnitChangeError,
  TooningCanvasSizeNumOverCheckError,
  TooningCanvasSizeUnitToPxError,
  TooningCanvasSizeUnitToMmError,
  TooningCanvasSizeUnitToCmError,
  TooningCanvasSizeUnitToInError,
  TooningCanvasSizeCalculationError,
  TooningCanvasSizeLimitError,
  TooningCanvasEventOffError,
  TooningPopoverSizeInitError,
  TooningValidationError,
  TooningReRenderGoogleLoginButtonError,
  TooningCheckSubscriberError,
  TooningShowCardUpdateViewError,
  TooningOpenIamportSdkForPointError,
  TooningFailTossPaymentUsingBillingKeyError,
  TooningOnFileChangedError,
  TooningTotalSetApplicantError,
  TooningCompleteUpgradeError,
  TooningCutListChangeOrderByError,
  TooningApplyApplicantError,
  TooningRejectApplicantError,
  TooningSimulationVideoError,
  TooningChangeFormatApplicantError,
  TooningSetPaginationConfigError,
  TooningCutListIonViewDidEnterError,
  TooningGetInstanceError,
  TooningSetCut3dError,
  TooningCommandManager3dExecuteCommandError,
  TooningCommandManager3dUndoError,
  TooningCommnadManager3dRedoError,
  TooningGetSelectedObBeforeValue,
  TooningObject3dMoveHandler,
  TooningObject3dResizeHandler,
  TooningObject3dRotateHandler,
  TooningcloseRemoteController,
  TooningMakeGifError,
  TooningAddBase64ToImageError,
  TooningSetImageSourceFromUrlError,
  TooningDownloadGifError,
  TooningFindSafeUrlIndexError,
  TooningCurrentUserError,
  TooningIsResourceWithWaterMarkError,
  TooningOpenAiFilterModalError,
  TooningCheckRegexWarningMessage,
  TooningClickApplyEduProError,
  TooningEmailMaskError,
  TooningSetThresholdImageError,
  TooningGetSubscribersError,
  TooningAdminListPageNgOninitError,
  TooningSearchUserError,
  TooningOnScrollDownError,
  TooningB2gAdminApplyError,
  TooningZoomPopupOpenError,
  TooningChangeIsFoldError,
  TooningOutsideMouseCheckError,
  TooningCheckCharacterPurchasedError,
  TooningGoEditorError,
  TooningSetCursorInDrawingError,
  TooningCalculatePageCountByCanvasHeightError,
  TooningCheckPageCountExceededError,
  TooningMergeAllImageError,
  TooningPrintTypeChangeError,
  TooningKakaoUnlinkError,
  TooningCacheSetError,
  TooningCacheGetError,
  TooningConfettiError,
  TooningChangeGifPreviewError,
  TooningSimulationGifPerPage,
  TooningSimulationGifPerPageDelayError,
  TooningShowThumbnailError,
  TooningCalculateTotalPlayTiemeError,
  TooningSelectPageDurationError,
  TooningShowCanvasCountWarningError,
  TooningSetViewMagicStyleError,
  TooningSetMagicStyleCharacterError,
  TooningInitAligningGuidelinesError,
  TooningMakeFaqError,
  TooningFaqAdminApplyError,
  TooningHelpCenterCategoryError,
  TooningCreateFaqCategoryError,
  TooningSearchFaqError,
  TooningOpenAddEduGroupPriceError,
  TooningCreatePriceError,
  TooningCreateEstimateIdError,
  TooningEstimateIssuedOnFileChangedError,
  TooningCheckXlsxInvalidError,
  TooningEstimateIssuedChangeViewError,
  TooningSetResourceScaleError,
  TooningGetIndexInfoError,
  TooningApplyFromJsonError,
  TooningObjectModifiedHandlerError,
  TooningObjectMovedHandlerError,
  TooningObjectScaledHandlerError,
  TooningObjectSkewedHandlerError,
  TooningObjectRotatedHandlerError,
  TooningObjectPurchasedHandlerError,
  TooningUpdatePriceError,
  TooningDeletePriceError,
  TooningTooltipOpenError,
  TooningInitEduGroupPriceError,
  TooningImageForColorPickerError,
  TooningReadPixelColorError,
  TooningSetPixelColorError,
  TooningSetColorPickerError,
  TooningObSelectionForColorPickError,
  TooningEstimateChangeViewError,
  TooningUpdateCharacterActionsError,
  TooningProcessPageCharactersError,
  TooningApplyActionsToAllCharactersError,
  TooningSyncStylesToAllCharactersError,
  TooningApplyColorToAllCharactersError,
  TooningApplyAccessoryToAllCharactersError,
  TooningCanvasDragTaskError,
  TooningResourceControlTaskError,
  TooningDesktopCheckTaskError,
  TooningWhenTargetIsExistSelectedObSetupTaskError,
  TooningWhenTargetIsExistGroupSelectionTaskError,
  TooningWhenTargetIsExitDefenceTaskError,
  TooningWhenTargetIsExistSelectableTaskError,
  TooningWhenTargetIsEmptyTaskError,
  TooningEstimateDropdownCtlError,
  TooningSetEduGroupPriceListError,
  TooningFindEduGroupPriceError,
  TooningFindPromotionPriceError,
  TooningExternalIssuedAccountNgonInitError,
  TooningCheckAndSetServerRegionError,
  TooningCanvasShareAuthorCheckError,
  TooningApplyCanvasShareCloneCanvasError,
  TooningSetEduEstimateListError,
  TooningBackToDownloadModalError,
  TooningModalDestroyHandlerError,
  TooningPasswordViewModeChangeError,
  TooningHelpCenterModalError,
  TooningHelpCenterWebError,
  TooningCanvasTitleInputFocusError,
  TooningCanvasTitleInputUnfocusError,
  TooningCanvasTitleUpdateError,
  TooningGetEduGroupPriceInfoError,
  TooningTitleChangeAlertError,
  TooningSubscribeToColorChanges,
  TooningPointCardREgistrationComplteOnInitError,
  TooningSignUpCompleteModalError,
  TooningSnsSignupError,
  TooningGoToSnsAgreementPageError,
  TooningGetSocialUserEmailError,
  TooningCustomJoinButtonClickHandlerError,
  TooningChangeViewTypeClickHandlerError,
  TooningRemoveCacheCanvasError,
  TooningOpenPricingDetailError,
  TooningGetLastObjectError,
  TooningSetGroupWaterMark,
  TooningSetWaterMark,
  TooningSetBookmarkError,
  TooningActivatedBookmarsError
};
