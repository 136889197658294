import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppService } from '../../../../services/app.service';
import { LanguageType } from '../../../../enum/app.enum';

@Component({
  selector: 'app-laboratory-modal',
  templateUrl: './laboratory-modal.component.html',
  styleUrls: ['./laboratory-modal.component.css']
})
export class LaboratoryModalComponent {
  @Input('cut') cut; // 해당 서비스에서 모달을 뛰워서 constructor에서 쓸수가 없음. 그래서 전달 받아서 써야함.
  public languageType = LanguageType;

  constructor(public modalCtrl: ModalController, public app: AppService) {}

  async close() {
    await this.modalCtrl.dismiss({});
  }
}
