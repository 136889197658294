import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itentionFace'
})
export class IntentionFacePipe implements PipeTransform {
  transform(value: number, args?: any): any {
    let converted = '';
    switch (value) {
      case 0:
        converted = 'neutrual1 / 무표정1';
        break;
      case 1:
        converted = 'neutrual2 / 무표정2';
        break;
      case 2:
        converted = 'neutrual3 / 무표정3';
        break;
      case 3:
        converted = 'angry1 / 분노1';
        break;
      case 4:
        converted = 'angry2 / 분노2';
        break;
      case 5:
        converted = 'angry3 / 분노3';
        break;
      case 6:
        converted = 'disgust1 / 역겨움1';
        break;
      case 7:
        converted = 'disgust2 / 역겨움2';
        break;
      case 8:
        converted = 'disgust3 / 역겨움3';
        break;
      case 9:
        converted = 'scary1 / 공포1';
        break;
      case 10:
        converted = 'scary2 / 공포2';
        break;
      case 11:
        converted = 'scary3 / 공포3';
        break;
      case 12:
        converted = 'joy1 / 기쁨1';
        break;
      case 13:
        converted = 'joy2 / 기쁨2';
        break;
      case 14:
        converted = 'joy3 / 기쁨3';
        break;
      case 15:
        converted = 'sad1 / 슬픔1';
        break;
      case 16:
        converted = 'sad2 / 슬픔2';
        break;
      case 17:
        converted = 'sad3 / 슬픔3';
        break;
      case 18:
        converted = 'surprised1 / 충격1';
        break;
      case 19:
        converted = 'surprised2 / 충격2';
        break;
      case 20:
        converted = 'surprised3 / 충격3';
        break;

      default:
        converted = '해당 되는 값이 없습니다.';
    }
    return converted;
  }
}
