import { ObjectMoveCommand } from './commands/object-move-command';
import { ObjectScaleCommand } from './commands/object-scale-command';
import { ObjectRotateCommand } from './commands/object-rotate-command';
import { PageChangeCommand } from './commands/page-change-command';
import { ExecuteCommandType } from '../interfaces/app.interface';
import { PageRemoveCommand } from './commands/page-remove-command';
import { ObjectFlipXCommand } from './commands/object-flipX-command';
import { ObjectFlipYCommand } from './commands/object-flipY-command';
import { PagesRemoveCommand } from './commands/pages-remove-command';
import { ObjectAddCommand } from './commands/object-add-command';
import { PageAddCommand } from './commands/page-add-command';
import { PageCopyCommand } from './commands/page-copy-command';
import { AnalyticsService } from '../services/google/analytics/analytics.service';
import {
  TooningCanvasEventOffError,
  TooningCommandExecuteError,
  TooningCommandRedoError,
  TooningCommandUndoError,
  TooningObjectModifiedHandlerError,
  TooningObjectMovedHandlerError,
  TooningObjectPurchasedHandlerError,
  TooningObjectRotatedHandlerError,
  TooningObjectScaledHandlerError,
  TooningObjectSkewedHandlerError,
  TooningPageCreateTimeOutError,
  TooningPageUpdateTimeOutError
} from '../pages-tooning/errors/TooningErrors';
import { TemplateCopyCommand } from './commands/template-copy-command';
import { Loading } from '../services/loading';
import { TemplatesCopyCommand } from './commands/templates-copy-command';
import { PageSwitchCommand } from './commands/page-switch-command';
import { ObjectRemoveCommand } from './commands/object-remove-command';
import { CommandType, ErrorName, ObjectTransform, SelectedType } from '../enum/app.enum';
import { ObjectChangeCommand } from './commands/object-change-command';
import { ObjectSkewedCommand } from './commands/object-skewed-command';

/**
 * Command 를 관리하는 클래스로 Singleton pattern 으로 구현되어 있으며,
 * CommandManager.getInstance()로 인스턴스를 가져와 사용해야 합니다.
 * 초기 작업으로 setCut(cut:FabricInstance)를 세팅 후 사용 해야합니다.
 */
class CommandManager {
  private static instance: CommandManager;
  public undoHistory: ExecuteCommandType[];
  public redoHistory: ExecuteCommandType[];
  public altCopyUndoCommands = [];
  public altCopyRedoCommands = [];
  public cut;
  private isHistoryOn = true;
  private isCommandExecuting = false;
  private isUndoCommandProcessing = false;
  private isRedoCommandProcessing = false;
  private analytics;
  private loading = new Loading();

  constructor() {
    this.undoHistory = [];
    this.redoHistory = [];
  }

  /**
   * instance 가 없을 경우 생성하며, 생성된 경우는 사용 중인 instance 를 사용합니다.
   */
  public static getInstance() {
    try {
      if (!CommandManager.instance) {
        CommandManager.instance = new CommandManager();
      }
      return CommandManager.instance;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * fabric canvas 와 연결되 event handler off
   * fabric canvas 삭제
   * CommandManager instance 삭제
   */
  public destroyInstance() {
    try {
      this.canvasEventOff();
      this.cut = null;
      CommandManager.instance = null;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * CommandManager 에서 사용할 canvas 인스턴스를 세팅합니다.
   * @param cut fabric main instance
   */
  setCut(cut) {
    try {
      this.cut = cut;
      this.canvasEventSetup();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * CommandManager 에서 사용할 Analytics 인스턴스를 세팅합니다.
   * @param {AnalyticsService} analytics
   */
  setAnalytics(analytics: AnalyticsService) {
    this.analytics = analytics;
  }

  /**
   * CommandManager 에서 사용 중인 fabric main instance 를 리턴 합니다.
   */
  getCut() {
    return this.cut;
  }

  /**
   * CommandManager 에서 필요한 fabric canvas event handler 를 세팅합니다.
   */
  canvasEventSetup() {
    if (!this.cut.canvas) {
      throw new Error('this.cut.canvas 가 command-manager 에 없습니다.');
    }
    try {
      this.cut.canvas.on('object:modified', this.objectModifiedHandler);
      this.cut.canvas.on('object:resizing', this.objectModifiedHandler);
      this.cut.canvas.on('object:purchased', this.objectPurchasedHandler);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * CommandManager 에서 필요한 fabric canvas event handler 를 off 합니다.
   */
  canvasEventOff() {
    if (!this.cut) {
      throw new Error('this.cut 이 command-manager 에 없습니다.');
    }
    if (!this.cut.canvas) {
      throw new Error('this.cut.canvas 가 command-manager 에 없습니다.');
    }
    try {
      this.cut.canvas.off('object:modified', this.objectModifiedHandler);
      this.cut.canvas.off('object:resizing', this.objectModifiedHandler);
      this.cut.canvas.off('object:purchased', this.objectPurchasedHandler);
    } catch (e) {
      throw new TooningCanvasEventOffError(e);
    }
  }

  /**
   * CommandManger 에서 사용하는  fabric canvas 에 object 수정 이벤트가 발생시
   * 1. redoHistory array 리셋,
   * 2. history:append event 발생
   * @param event fabric main canvas 에서 발생하는 이벤트
   */
  objectModifiedHandler = (event) => {
    try {
      if (!event.target || !event.e) return;

      switch (event.action) {
        case ObjectTransform.move:
          if (!this.cut.isGesture) {
            this.objectMovedHandler(event);
          }
          break;

        case ObjectTransform.scale:
        case ObjectTransform.scaleX:
        case ObjectTransform.scaleY:
        case ObjectTransform.resizing:
          this.objectScaledHandler(event);
          break;

        case ObjectTransform.skewX:
        case ObjectTransform.skewY:
          this.objectSkewedHandler(event);
          break;

        case ObjectTransform.rotate:
          this.objectRotatedHandler(event);
          break;
      }

      if (this.redoHistory.length > 0) this.redoHistory = [];
      this.cut.canvas.fire('history:append');
    } catch (e) {
      throw new TooningObjectModifiedHandlerError(e, null, true);
    }
  };

  /**
   * CommandManger 에서 사용하는  fabric canvas 에 object 이동 이벤트가 발생시 (데스크탑에서는 마우스, 모바일에서 터치)
   * 1. ObjectMoveCommand 캡슐화
   * 2. undoHistory 에 추가
   * 3. history:append 이벤트 발생
   * @param event fabric main canvas 에서 발생하는 이벤트
   */
  objectMovedHandler = (event) => {
    let command;
    try {
      const target = event.target;
      command = new ObjectMoveCommand({
        cut: this.cut,
        beforeLeft: target.beforeLeft,
        beforeTop: target.beforeTop,
        afterLeft: target.left,
        afterTop: target.top
      });
      command.id = [];
      if (target.type === SelectedType.activeSelection) {
        command.type = SelectedType.activeSelection;
        const list = target.getObjects();
        for (const element of list) {
          command.id.push(element.resource_selection_id);
        }
      } else {
        command.id = [target.resource_selection_id];
        command.type = SelectedType.singleSelection;
      }

      // altCopyAndPaste 함수에서 리소스가 add 된 후 move가 되었을 때 마찬가지로 this.altCopyUndoCommands에 push한다.
      // array를 clone하기 위해 slice를 하고 altCopy 함수를 통해 커맨드가 쌓인 배열을 this.undoHistory에 push한다.
      // 일반적으로 this.undoHistory에는 command object가 들어가지만 2개의 커맨드를 한 번에 undo & redo 처리를 하기위해 this.altCopyUndoCommands에 담고 그 배열을 undoHistory에 담는 것이다.
      // 그리고 altCopyAndPaste 함수가 종료되어 isAltCopied를 false로 변경한다.
      // 여기까지가 altCopyAndPaste execute 끝임
      if (command instanceof ObjectMoveCommand && this.cut.isAltCopied) {
        this.altCopyUndoCommands.push(command);
        const newAltCopyUndoCommands = this.altCopyUndoCommands.slice(undefined);
        this.cut.isAltCopied = false;
        this.undoHistory.push(newAltCopyUndoCommands);
        this.altCopyUndoCommands = [];
        return;
      }
      this.undoHistory.push(command);
    } catch (e) {
      throw new TooningObjectMovedHandlerError(`${command.commandName} 에서 ${e} 에러가 발생하였습니다.`, null, true);
    }
  };

  /**
   * CommandManger 에서 사용하는  fabric canvas 에 object 스케일 이벤트가 발생시 (데스크탑에서는 마우스, 모바일에서 터치)
   * 1. ObjectScaleCommand 캡슐화
   * 2. undoHistory 에 추가
   * 3. history:append 이벤트 발생
   * @param event fabric main canvas 에서 발생하는 이벤트
   */
  objectScaledHandler = (event) => {
    let command;
    try {
      const target = event.target;
      command = new ObjectScaleCommand({
        cut: this.cut,
        target: target,
        beforeLeft: target.beforeLeft,
        beforeTop: target.beforeTop,
        afterLeft: target.left,
        afterTop: target.top,
        beforeScaleX: target.beforeScaleX,
        beforeScaleY: target.beforeScaleY,
        afterScaleX: target.scaleX,
        afterScaleY: target.scaleY,
        beforeWidth: target.beforeWidth,
        afterWidth: target.width,
        beforeFlipX: target.beforeFlipX,
        afterFlipX: target.flipX,
        beforeFlipY: target.beforeFlipY,
        afterFlipY: target.flipY
      });
      this.undoHistory.push(command);
    } catch (e) {
      throw new TooningObjectScaledHandlerError(`${command.commandName} 에서 ${e} 에러가 발생하였습니다.`, null, true);
    }
  };

  /**
   * CommandManger 에서 사용하는  fabric canvas 에 object skew 이벤트가 발생시 (데스크탑에서는 마우스, 모바일에서 터치)
   * 1. ObjectSkewedCommand 캡슐화
   * 2. undoHistory 에 추가
   * 3. history:append 이벤트 발생
   * @param event fabric main canvas 에서 발생하는 이벤트
   * @return {void}
   */
  objectSkewedHandler = (event): void => {
    let command;
    try {
      const target = event.target;
      command = new ObjectSkewedCommand({
        cut: this.cut,
        target: target,
        beforeLeft: target.beforeLeft,
        beforeTop: target.beforeTop,
        afterLeft: target.left,
        afterTop: target.top,
        beforeSkewX: target.beforeSkewX,
        beforeSkewY: target.beforeSkewY,
        afterSkewX: target.skewX,
        afterSkewY: target.skewY,
        beforeScaleX: target.beforeScaleX,
        beforeScaleY: target.beforeScaleY,
        afterScaleX: target.scaleX,
        afterScaleY: target.scaleY,
        beforeWidth: target.beforeWidth,
        afterWidth: target.width,
        beforeAngle: target.beforeAngle,
        afterAngle: target.afterAngle,
        beforeFlipX: target.beforeFlipX,
        afterFlipX: target.afterFlipX,
        beforeFlipY: target.beforeFlipY,
        afterFlipY: target.afterFlipY
      });
      this.undoHistory.push(command);
    } catch (e) {
      throw new TooningObjectSkewedHandlerError(`${command.commandName} 에서 ${e} 에러가 발생하였습니다.`, null, true);
    }
  };

  /**
   * CommandManger 에서 사용하는  fabric canvas 에 object rotation 이벤트가 발생시 (데스크탑에서는 마우스, 모바일에서 터치)
   * 1. ObjectScaleCommand 캡슐화
   * 2. undoHistory 에 추가
   * 3. history:append 이벤트 발생
   * @param event fabric main canvas 에서 발생하는 이벤트
   */
  objectRotatedHandler = (event) => {
    let command;
    try {
      const target = event.target;
      command = new ObjectRotateCommand({
        cut: this.cut,
        target: target,
        beforeLeft: target.beforeLeft,
        beforeTop: target.beforeTop,
        afterLeft: target.left,
        afterTop: target.top,
        beforeAngle: target.beforeAngle,
        afterAngle: target.angle
      });
      this.undoHistory.push(command);
    } catch (e) {
      throw new TooningObjectRotatedHandlerError(`${command.commandName} 에서 ${e} 에러가 발생하였습니다.`, null, true);
    }
  };

  objectPurchasedHandler = (event) => {
    try {
      const resource_id = event.resource_id;
      for (const command of this.undoHistory.concat(this.redoHistory)) {
        try {
          command.removeWaterMark(resource_id);
        } catch (e) {
          console.error(`히스토리안에서 워터마크가 있는 캐릭터의 워터마크 제거 중 문제 발생  : ${e.message} in command-manager.ts`);
        }
      }
    } catch (e) {
      throw new TooningObjectPurchasedHandlerError(e, null, true);
    }
  };

  /**
   * 캡슐화된 커맨드를 실행하는 함수
   * 명령어의 종류에 따라 동기 또는 비동기 호출이 된다.
   * 완료 후 history:append 이벤트를 발생 시킨다
   * @param command 캡슐화된 커맨드 인스턴스
   * @return {Promise <any>}
   */
  async executeCommand(command: ExecuteCommandType): Promise<any> {
    try {
      let result;
      if (!this.isHistoryOn) {
        this.cut.app.red('히스토리가 기능이 꺼져 있습니다. 중입니다');
        return;
      }
      if (this.isCommandExecuting) {
        this.cut.app.red('CommandManager 의 executeCommand 가 실행 중입니다');
        return;
      }
      if (command instanceof ObjectChangeCommand && this.cut.afterAIView) {
        await command.execute();
        return;
      }
      if (command instanceof PageRemoveCommand && this.cut.isPagesRemoveCommand) {
        await command.execute();
        return;
      }
      // altCopyAndPaste 함수에서 리소스가 add 될 때 execute만 하고
      // undoHistory에 push하지 않고 this.altCopyUndoCommands에 push를 한다.
      if (command instanceof ObjectAddCommand && this.cut.isAltCopied) {
        await command.execute();
        this.altCopyUndoCommands.push(command);
        return;
      }
      if (command) {
        this.isCommandExecuting = true;
        if (command instanceof PageChangeCommand) {
          if (command.beforeIndex !== command.afterIndex) {
            this.undoHistory.push(command);
          } else {
            console.warn('같은 인덱스는 히스토리에 기록하지 않습니다');
          }
          result = await command.execute();
        } else if (
          command instanceof ObjectFlipXCommand ||
          command instanceof ObjectFlipYCommand ||
          command instanceof ObjectAddCommand ||
          command instanceof ObjectRemoveCommand ||
          command instanceof PageRemoveCommand ||
          command instanceof PageAddCommand ||
          command instanceof PageCopyCommand ||
          command instanceof PageSwitchCommand ||
          command instanceof TemplateCopyCommand ||
          command instanceof TemplatesCopyCommand
        ) {
          if (command instanceof PageAddCommand || command instanceof PageRemoveCommand) {
            if (this.cut.pageList.length >= 1) {
              this.undoHistory.push(command);
            }
          } else if (command instanceof ObjectFlipXCommand || ObjectFlipYCommand || ObjectAddCommand || PageCopyCommand) {
            this.undoHistory.push(command);
          }

          try {
            result = await command.execute();
          } catch (e) {
            // 어떤 명령어의 timeout 인지 확인 하여 해당 명령어의 timeout error 를 만든다.
            if (e && e.name === ErrorName.TimeoutError) {
              if (command instanceof PageAddCommand) {
                throw new TooningPageCreateTimeOutError(e, null, true);
              }
            } else {
              throw e;
            }
          }
        } else {
          this.undoHistory.push(command);
          result = command.execute();
        }

        // redoHistory에 command가 있는 채 execute가 되면 reset하기
        if (this.redoHistory.length > 0) {
          this.redoHistory = [];
        }
        this.tracking(CommandType.execute, command.commandName);
        this.isCommandExecuting = false;
        this.cut.canvas.fire('history:append');
        console.log(this.undoHistory.length);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e instanceof TooningPageCreateTimeOutError || e instanceof TooningPageUpdateTimeOutError) {
        throw e;
      } else {
        throw new TooningCommandExecuteError(`${command.commandName} 에서 ${e} 에러가 발생하였습니다.`, null, true);
      }
    } finally {
      this.isCommandExecuting = false;
    }
  }

  /**
   * undoHistory 리스트에서 캡슐화된 명령을 하나 가져온 뒤, 해당 명령의 redo 함수를 실행한다.
   * isRedoCommandProcessing 실행 중이라면 리턴한다.
   * 완료 후 history:redo 이벤트를 발생 시킨다.
   * @return {Promise <void>}
   */
  async redo(): Promise<void> {
    let command;
    try {
      if (this.cut.isRedoing) {
        this.cut.app.red('CommandManager 의 redo 가 실행 중 입니다');
        return;
      }
      this.isRedoCommandProcessing = true;
      command = this.redoHistory.pop();

      // altCopyAndPaste()로 인해 this.redoHistory에서 배열이 pop이된다면 this.altCopyRedo()를 실행한다.
      if (Array.isArray(command)) {
        await this.altCopyRedo(command);
        return;
      }

      if (command) {
        this.undoHistory.push(command);
        const objects = this.cut.canvas.getObjects();

        if (
          command instanceof ObjectMoveCommand ||
          command instanceof ObjectScaleCommand ||
          command instanceof ObjectFlipXCommand ||
          command instanceof ObjectFlipYCommand
        ) {
          command.redo(objects);
        } else if (
          command instanceof PageChangeCommand ||
          command instanceof PagesRemoveCommand ||
          command instanceof PageRemoveCommand ||
          command instanceof PageCopyCommand
        ) {
          await command.redo();
        } else if (command instanceof PageAddCommand || command instanceof TemplatesCopyCommand) {
          await this.loading.showLoader();
          await command.redo();
          this.loading.hideLoader();
        } else {
          // @ts-ignore
          await command.redo(objects);
        }

        this.tracking(CommandType.redo, command.commandName);
        this.isRedoCommandProcessing = false;
        this.cut.canvas.fire('history:redo');
      }
    } catch (e) {
      throw new TooningCommandRedoError(`${command.commandName} 에서 ${e} 에러가 발생하였습니다.`, null, true);
    } finally {
      if (this.cut.app.popover) await this.cut.app.popover.dismiss();
      this.isRedoCommandProcessing = false;
      this.loading.hideLoader();
    }
  }

  /**
   * redoHistory 리스트에서 캡슐화된 명령을 하나 가져온 뒤, 해당 명령의 redo 함수를 실행한다.
   * isUndoCommandProcessing 실행 중이라면 리턴한다.
   * 완료 후, history:undo 이벤트를 발생 시킨다.
   * @return {Promise <void>}
   */
  async undo(): Promise<void> {
    let command;
    try {
      if (this.cut.isUndoing) {
        this.cut.app.red('CommandManager 의 undo 가 실행 중 입니다');
        return;
      }
      command = this.undoHistory.pop();

      // altCopyAndPaste()로 인해 this.undoHistory에서 배열이 pop이된다면 this.altCopyUndo()를 실행한다.
      if (Array.isArray(command)) {
        await this.altCopyUndo(command);
        return;
      }

      if (this.isUndoCommandProcessing) {
        this.cut.app.red('CommandManager 의 undo 가 실행 중 입니다');
        return;
      }
      if (command) {
        console.log(command instanceof TemplateCopyCommand);
        console.log(command instanceof PageAddCommand);
        console.log(`command type : ${command.constructor.name}`);

        this.redoHistory.push(command);
        const objects = this.cut.canvas.getObjects();
        if (
          command instanceof ObjectMoveCommand ||
          command instanceof ObjectScaleCommand ||
          command instanceof ObjectFlipXCommand ||
          command instanceof ObjectFlipYCommand
        ) {
          command.undo(objects);
        } else if (command instanceof PagesRemoveCommand || command instanceof PageRemoveCommand || command instanceof PageCopyCommand) {
          await command.undo();
        } else if (command instanceof PageAddCommand || command instanceof TemplatesCopyCommand) {
          await this.loading.showLoader();
          await command.undo();
          this.loading.hideLoader();
        } else {
          await command.undo(objects);
        }

        this.tracking(CommandType.undo, command.commandName);
        this.isUndoCommandProcessing = false;
        this.cut.canvas.fire('history:undo');
      }
    } catch (e) {
      throw new TooningCommandUndoError(`${command.commandName} 에서 ${e} 에러가 발생하였습니다.`, null, true);
    } finally {
      if (this.cut.app.popover) await this.cut.app.popover.dismiss();
      this.isUndoCommandProcessing = false;
      this.loading.hideLoader();
    }
  }

  /**
   * altCopy를 undo 하는 함수
   * @param command array로서 altCopy를 하면서 ObjectAddCommand와 ObjectMoveCommand가 담긴다.
   * @return {Promise<void>}
   */
  async altCopyUndo(command): Promise<void> {
    const lengthAltCopyCommand = command.length;
    const objects = this.cut.canvas.getObjects();
    // for문을 돌면서 altCopyAndPaste를 하면서 담긴 addCommand와 moveCommand가 pop이 되며
    // 해당 command의 undo()를 실행한다.
    // undo된 command를 다시 redo하기 위해 this.altCopyRedoCommands에 다시 push한다.
    for (let i = 0; i < lengthAltCopyCommand; i++) {
      const altCopySingleCommand = command.pop();
      await altCopySingleCommand.undo(objects);
      this.altCopyRedoCommands.push(altCopySingleCommand);
    }
    const newAltCopyRedoCommands = this.altCopyRedoCommands.slice(undefined);
    this.redoHistory.push(newAltCopyRedoCommands);
    this.altCopyRedoCommands = [];
    this.tracking(CommandType.undo, command.commandName);
    this.isUndoCommandProcessing = false;
    this.cut.canvas.fire('history:undo');
  }

  /**
   * altCopy를 redo 하는 함수
   * @param command array로서 altCopy를 하면서 ObjectAddCommand와 ObjectMoveCommand가 담긴다.
   * @return {Promise<void>}
   */
  async altCopyRedo(command): Promise<void> {
    const lengthAltCopyCommand = command.length;
    const objects = this.cut.canvas.getObjects();
    // for문을 돌면서 undo를 하면서 this.redoHistory에 담긴 addCommand와 moveCommand가 pop이 되며
    // 해당 command의 redo()를 실행한다.
    // redo된 command를 다시 undo하기 위해 this.altCopyUndoCommands에 다시 push한다.
    for (let i = 0; i < lengthAltCopyCommand; i++) {
      const altCopySingleCommand = command.pop();
      await altCopySingleCommand.redo(objects);
      this.altCopyUndoCommands.push(altCopySingleCommand);
    }
    const newAltCopyUndoCommands = this.altCopyUndoCommands.slice(undefined);
    this.undoHistory.push(newAltCopyUndoCommands);
    this.altCopyUndoCommands = [];
    this.tracking(CommandType.redo, command.commandName);
    this.isRedoCommandProcessing = false;
    this.cut.canvas.fire('history:redo');
  }

  /**
   * history 기능을 off 시킨다. 기존의 memento pattern 으로 구현되 interface 의 backward compatability 를 위해 만들어는 두었지만
   * 실제 사용하지 않음, 추후에 사용 가능성이 있어보여서 생성해 둠
   */
  offHistory() {
    console.warn('히스토리가 꺼졌습니다');
  }

  /**
   * history 기능을 on 시킨다. 기존의 memento pattern 으로 구현되 interface 의 backward compatability 를 위해 만들어는 두었지만
   * 실제 사용하지 않음, 추후에 사용 가능성이 있어보여서 생성해 둠
   */
  onHistory() {
    console.warn('히스토리가 켜졌습니다');
  }

  /**
   * 기본 state full 변수와, undoHistory, redoHistory 리스트 리셋
   * 완료 후 history:clear 이벤트 발생
   */
  clearHistory() {
    try {
      console.warn('히스토리가 클리어 됩니다');
      this.undoHistory = [];
      this.redoHistory = [];
      this.isUndoCommandProcessing = false;
      this.isRedoCommandProcessing = false;
      this.isCommandExecuting = false;
      this.cut.canvas.fire('history:clear');
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Command execute, redo, undo analytics
   * @param type
   * @param instanceName
   */
  tracking(type: CommandType, instanceName) {
    try {
      if (this.analytics) {
        this.analytics.command(type, instanceName);
      }
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { CommandManager };
