<ion-header>
  <ion-toolbar class='canvas-share-toolbar d-flex align-items-center toolbar-padding'>
    <div *ngIf='userRole !== UserRole.etri' class='w-100 h-100 position-relative logo-wrapper justify-content-start'
         slot='start'>
      <img
        (click)="app.go('/')"
        *ngIf='!isModal'
        class='img-fluid editor-logo'
        src='/assets/editor/landing/logo.svg' alt='logo'
      />
      <img *ngIf='id !== undefined' class='img-fluid mx-3 editor-logo'
           src='/assets/editor/landing/logo.svg' alt='logo' />
    </div>

    <ion-buttons *ngIf='readyToRender&&!isModal' slot='end'>
      <ion-button (click)='toggleFullScreen()' *ngIf='app.isDesktop()' [ngClass]='app.isDesktopWidth()?"btn_3":"btn_3_m"'
                  style='margin-right: 5px'>
        <ion-icon name='easel-outline' style='margin-right: 10px;'></ion-icon>
        {{ 'fullscreen' | translate }}
      </ion-button>
    </ion-buttons>

    <ion-buttons *ngIf='!isModal||app.isAdmin' slot='end'>
      <ion-button (click)='openModalLogin()' *ngIf='!app.loginStatus && app.isDesktopWidth()' class='btn_2 custom-size-l'
                  matRipple>{{'login' | translate}}</ion-button>
      <ion-button (click)='openModalLogin()' *ngIf='!app.loginStatus && !app.isDesktopWidth()' class='btn_2Mb'
                  matRipple>{{'login' | translate}}</ion-button>

      <ion-button (click)='cloneCanvas()' *ngIf='!isModal' class='custom-size-l' [ngClass]='fromEditor?"theme-editor":"theme-main"'
                    matRipple> {{'copy4' | translate}}</ion-button>

    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button (click)='sharePopover($event)' *ngIf='isModal&& userRole !== UserRole.demo'
                  style='margin-right: 5px'>
        <ion-icon name='share-social-outline' slot='icon-only' style='color: #333333'></ion-icon>
      </ion-button>
      <ion-button (click)='modalCtrl.dismiss();' *ngIf='isModal' data-cy='closePreviewPopup' style='margin-right: 5px'>
        <ion-icon name='close-outline' slot='icon-only' style='color: #333333'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content (ionScroll)='handleScroll($any($event))' [scrollEvents]='true'
             [style.--background]='isFullscreen?"#000000":"#ffffff"'
             appScrollbarTheme>


  <div [style.max-width]='isFullscreen?"100%":"1024px"'
       style='width: 100%;height: 100%; margin: auto; display: block;'>
    <div *ngIf='!isFullscreen' [style.text-align]="app.isDesktopWidth()?'left':'center'" style='padding: 20px'>
      <div style='margin-top: 40px; font-size: 24px'>{{title}}</div>
      <div style='margin-top: 10px; font-size: 16px; color: #828282'>{{canvasSize}}</div>
      <div style='margin-top: 15px; margin-bottom: 30px; font-size: 16px; color: #828282'>{{ 'writer' | translate }}
        : {{name}}</div>
    </div>

    <div *ngIf='fullscreenSlides !== undefined && isControlView' [style.top.px]='scrollTop'
         class='animation_fadeIn animation'
         style='width: 100vw;height: 100vh;position: absolute;'>
      <div (click)='fullscreenSlides.slideNext()'
           class='full-swiper-button-next d-flex justify-content-center align-items-center' mat-ripple>
        <ion-icon color='white' name='chevron-forward-outline' style='font-size: 40px'></ion-icon>
      </div>
      <div (click)='fullscreenSlides.slidePrev()'
           class='full-swiper-button-prev  d-flex justify-content-center align-items-center' mat-ripple>
        <ion-icon color='white' name='chevron-back-outline' style='font-size: 40px'></ion-icon>
      </div>
      <div class='swiper-bottom-button d-flex justify-content-between text-white'>
        <div class='d-flex justify-content-between align-items-center'
             style='width: 65%;background: rgba(0,0,0,0.5);border-radius: 8px'>
          <div (click)='slideSzoomOut()' class='d-flex justify-content-center align-items-center'
               mat-ripple style='margin-left:10px;border-radius: 50%;width: 35px;height: 35px;'>
            <ion-icon name='remove-circle-outline' style='font-size: 22px;margin: 0;'></ion-icon>
          </div>

          <div style='font-size: 14px'>{{slideSzoom}}%</div>
          <div (click)='slideSzoomIn()' class='d-flex justify-content-center align-items-center'
               mat-ripple style='margin-right:10px;border-radius: 50%;width: 35px;height: 35px;'>
            <ion-icon name='add-circle-outline' style='font-size: 22px;margin: 0;'></ion-icon>
          </div>

        </div>
        <div class='d-flex justify-content-center align-items-center'
             style='width: 30%;background: rgba(0,0,0,0.5);border-radius: 8px;font-size: 14px'>
          {{slidesIndex + 1}} / {{pages.length}}
        </div>
      </div>
    </div>

    <ion-slides #slidesFullscreen (ionSlideDidChange)='ionSlideDidChangeFn($event)' (ionSlideTap)='ionSlideTap()' *ngIf='isFullscreen'
                [options]='slideOpts'>
      <ion-slide *ngFor="let item of pages | orderBy: 'order'; let i = index;">
        <img [src]='item.base64' background-white class='w-auto h-auto' alt='item' />
      </ion-slide>
    </ion-slides>


    <div #viewDiv style='position: relative;'>
      <div *ngIf='readyToRender&&!isFullscreen && pages.length !== 0'>
        <div *ngIf='orientation === viewOrientationEnumType.vertical' data-cy='canvasShare'
             style='padding-bottom: 100px'>
          <img src="{{ pages[0].base64 }}" id="img_0" alt='image'/>

          <div *ngFor="let item of pages.slice(1) | orderBy: 'order'; let i = index" class='d-flex justify-content-center'>
            <img [lazyLoad]="item.base64" [offset]="100" id="img_{{i+1}}" alt='image' />
          </div>

        </div>
        <div
          *ngIf='orientation === viewOrientationEnumType.horizontal || orientation === viewOrientationEnumType.presentation'
          style='padding-bottom: 20px'>
          <ion-slides #Slider (ionSlideDidChange)='viewHeightChanged()' class='animation_fadeIn'>
            <ion-slide *ngFor="let item of pages | orderBy: 'order'; ">
              <ion-card no-box-shadow>

                <img
                  (onLoad)='viewHeightChanged();'
                  [defaultImage]="'/assets/common/loading.png'"
                  alt='loading'
                  [lazyLoad]='item.base64'
                  [offset]='100'
                  class='div_img'
                />
              </ion-card>
            </ion-slide>
          </ion-slides>
          <div style='position: relative; width: 150px; margin: 10px auto auto'>
            <div (click)='slides.slideNext()' *ngIf='pages.length !== 1  && slidesIndex + 1 !== pages.length'
                 class='swiper-button-next'></div>
            <div (click)='slides.slidePrev()' *ngIf='pages.length !== 1 && slidesIndex !== 0'
                 class='swiper-button-prev'></div>
            <div
              style='font-size: 1.2rem; color: #4f4f4f; margin: auto; width: 45px; border-radius: 20px 20px 20px 20px; text-align: center'>
              {{slidesIndex + 1}} /{{pages.length}}
            </div>
          </div>
        </div>
        <ion-grid *ngIf='orientation === viewOrientationEnumType.cut'>
          <ion-row>
            <ion-col *ngFor="let item of pages | orderBy: 'order'; let i = index;" size='6' style='padding: 3px'>
              <div style='border: 1px solid #9a9a9a'>
                <ion-img [src]='item.base64' id='imgCut_{{i}}'></ion-img>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</ion-content>
