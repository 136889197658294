import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CountryCode, Currency, Deployment, PaymentGateyway, PayMethod } from '../../enum/app.enum';
import { AppService } from '../../services/app.service';
import {
  TooningCheckBeforeClickPayMethodError,
  TooningClickRequestBtnError,
  TooningFailIamportBillingPaymentError,
  TooningFailRegisterBillingKeyError,
  TooningFailRequestIamportGetBillingKeyError,
  TooningFailRequestIamportPaymentError,
  TooningFailTossPaymentUsingBillingKeyError,
  TooningIamportComponentNgOnInitError,
  TooningIamportRequestPayError,
  TooningIamportSdkError,
  TooningOpenIamportSdkForPointError,
  TooningOpenIamportSdkForRegisterBillingKeyError,
  TooningPayMethodClickError,
  TooningRequestChargePointError,
  TooningRequestPaymentToIamportError,
  TooningRequestSubscriptionUsingBillingKeyError
} from '../../pages-tooning/errors/TooningErrors';
import { IamportPaymentInfo, PayMethodInfo, RequestIamportParam } from '../../interfaces/app.interface';
import { PaymentService } from '../../services/payment/payment.service';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PointService } from '../../services/point/point.service';
import { TranslateService } from '@ngx-translate/core';
import { CardService } from '../../services/card/card.service';
import { Card } from '../../model/card/card';
import { Loading } from '../../services/loading';
import { AnalyticsService } from '../../services/google/analytics/analytics.service';

const ImpInitKey = 'imp67149825';
const kakaoKey = environment.iamportKeys.kakaopaySecretKey;
const naverPay = environment.iamportKeys.naverpaySecretKey;
const userEndpoint = environment.user_endpoint;
const billkeyStr = 'billingKey_';
//@ts-ignore
const IMP = window.IMP;
const payEventId = 1; // 이거 없으면 정가로 결제
@Component({
  selector: 'app-iamport',
  templateUrl: './iamport-component.html',
  styleUrls: ['./iamport-component.scss']
})
export class IamportComponent implements OnInit {
  @Input() isUpdatingCard: boolean;
  @Input() pointAmount: number;
  @Input() isPoint: boolean;
  @Input() purchaseAfterCharging: EventEmitter<any> = new EventEmitter();
  @Input() defaultPaymentInfo;
  @Input() otherPaymentInfo: Array<any>;
  @Input() card: Card;
  @Output() showCardUpdateView: EventEmitter<any> = new EventEmitter();
  @Output() doPointCharge: EventEmitter<any> = new EventEmitter();
  public payMethod: Array<PayMethodInfo>;
  public requestBillingKeyIamportOps: RequestIamportParam;
  public selectedPayment: string;
  public currency: string;
  public isCutPage: boolean;
  public isMagicPage: boolean;
  public isGptPage = false;
  public problemedCardCompanyList: Array<any>;
  public problemedCardCompanys: string;
  public isProblemCardCompany: boolean;
  private isBillingKey: boolean;
  private billingKey: string;
  public choicePayIndex: number = 0;
  public isDefaultPayment = false;
  public alreadyPaymentInfo: Array<IamportPaymentInfo>;
  public readyToRender = false;
  public payMethodIndex: number;
  public loading: Loading;
  public isClicked: boolean = false;
  public payMethodEnum = PayMethod;

  constructor(
    public app: AppService,
    public alertController: AlertController,
    private paymentService: PaymentService,
    private pointService: PointService,
    private router: Router,
    private translate: TranslateService,
    private cardService: CardService,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * 1. 결제 방식 세팅(카드,카카오페이 등, 네이버페이는 추후에 추가예정)
   * 2. 나라별 화폐 세팅, 한국의 경우 KRW
   * 3. 토스측에서 카드사 문제있을 경우 알려줌 => 카드사 문제 여부 체크
   * 4. 디폴트 결제 수단 선택(this.payMethodClick() 함수 이용)
   * 5. 등록한 결제 수단 불러옴
   * @return {Promise<void>}
   */
  async ngOnInit() {
    try {
      this.loading = new Loading();
      this.loading.loadingType = this.app.serviceID;
      await this.loading.showLoader();
      this.readyToRender = false;
      IMP.init(ImpInitKey);
      //@ts-ignore
      this.requestBillingKeyIamportOps = {};
      this.isBillingKey = false;
      const country = this.app.cache.user.country;
      switch (country) {
        case CountryCode.jp:
          this.currency = Currency.JPY;
          break;
        case CountryCode.fr:
          this.currency = Currency.EUR;
          break;
        case CountryCode.others:
          this.currency = Currency.USD;
          break;
        case CountryCode.ca:
          this.currency = Currency.CAD;
          break;
        default:
          this.currency = Currency.KRW;
          break;
      }
      this.isCutPage = this.router.url.includes('4cut-make-manual2');
      this.isMagicPage = this.router.url.includes('magic');
      this.isGptPage = this.router.url.includes('gpt');
      //@ts-ignore
      this.problemedCardCompanyList = await this.paymentService.getCardCompanyListProblem().toPromise();
      this.isProblemCardCompany = this.problemedCardCompanyList.length !== 0;
      for (let i = 0; i < this.problemedCardCompanyList.length; i++) {
        if (i === 0) {
          if (i + 1 === this.problemedCardCompanyList.length) {
            this.problemedCardCompanys = this.problemedCardCompanyList[i].companyName;
          } else {
            this.problemedCardCompanys = this.problemedCardCompanyList[i].companyName + ',';
          }
        } else if (i + 1 === this.problemedCardCompanyList.length) {
          this.problemedCardCompanys += this.problemedCardCompanyList[i].companyName;
        } else {
          this.problemedCardCompanys += this.problemedCardCompanyList[i].companyName + ',';
        }
      }
      this.payMethod = this.isPoint
        ? [{ payMethod: PayMethod.card, isAlreadyExist: false }]
        : [
            { payMethod: PayMethod.card, isAlreadyExist: false },
            { payMethod: PayMethod.naver, isAlreadyExist: false },
            { payMethod: PayMethod.kakao, isAlreadyExist: false }
          ];
      //@ts-ignore
      this.alreadyPaymentInfo = await this.pointService.getIamportPaymentList(+this.app.cache.user.id).toPromise();
      if (this.isPoint) {
        this.payMethodIndex = 0;
        this.payMethodClickForPoint(this.payMethod[0].payMethod);
      } else {
        if (this.alreadyPaymentInfo.length === 0) {
          this.payMethodIndex = 0;
          this.payMethodClick(this.payMethod[0].payMethod, 0);
        } else {
          for (let i = 0; i < this.payMethod.length; i++) {
            if (!this.payMethod[i].isAlreadyExist) {
              this.payMethodIndex = i;
              this.payMethodClick(this.payMethod[i].payMethod, i);
            }
          }
        }
        this.checkAlreadyPamyent();
      }

      this.readyToRender = true;
    } catch (e) {
      throw new TooningIamportComponentNgOnInitError(e);
    } finally {
      this.loading.hideLoader();
    }
  }

  /**
   * 결제 방식 선택하면 그에 맞는 requset param 설정하는 함수, 정기결제에 사용
   * @param value 결제 방식 값
   * @param index 클릭한 payMethod index
   * @return {void}
   */
  payMethodClick(value: string, index: number): void {
    try {
      this.choicePayIndex = index;
      this.selectedPayment = value;
      const successUrl = userEndpoint + '/card-registration-complete/';
      const now = new Date();
      const today =
        now.getFullYear().toString() +
        `${now.getMonth() + 1}` +
        now.getDate().toString() +
        now.getHours().toString() +
        now.getMinutes().toString() +
        now.getSeconds().toString();
      const defaultValue = {
        merchant_uid:
          environment.deploy === Deployment.oper
            ? billkeyStr + this.app.cache.user.id + '_' + today
            : Deployment.beta + '_' + billkeyStr + this.app.cache.user.id + '_' + today, // 상점에서 관리하는 주문 번호, 주문 번호 규칙: 상품정보(point,subscribe)_userId_결제일자
        name: '투닝최초인증결제',
        amount: 0, // 실제 승인은 발생되지 않고 오직 빌링키만 발급됩니다.
        customer_uid: this.app.cache.user.id + today, // 필수 입력.
        buyer_email: this.app.cache.user.userEmail,
        buyer_name: this.app.cache.user.userName,
        m_redirect_url: successUrl,
        customer_id: this.app.cache.user.id //가맹점이 회원에게 부여한 고유 ID
      };
      Object.assign(this.requestBillingKeyIamportOps, defaultValue);
      switch (value) {
        case PayMethod.card:
          Object.assign(this.requestBillingKeyIamportOps, {
            pay_method: PayMethod.card,
            pg: PaymentGateyway.toss
          });
          break;
        case PayMethod.kakao:
          Object.assign(this.requestBillingKeyIamportOps, {
            pay_method: PayMethod.kakao,
            pg: PaymentGateyway.kakaopay + '.' + kakaoKey
          });
          break;
        case PayMethod.naver:
          Object.assign(this.requestBillingKeyIamportOps, {
            pay_method: PayMethod.naver,
            pg: PaymentGateyway.naverpay + '.' + naverPay,
            naverProductCode: 'subscription_tooning',
            naverPopupMode: true
          });
          break;
      }
    } catch (e) {
      throw new TooningPayMethodClickError(e);
    }
  }

  /**
   * 포인트 결제 클릭시 필요 정보 세팅 함수
   * @param {string} value 카카오 토스 네이버
   * @return {void}
   */
  payMethodClickForPoint(value: string): void {
    try {
      this.choicePayIndex = this.payMethodIndex;
      this.selectedPayment = value;
      const successUrl = userEndpoint + '/point-card-registration-complete/' + this.pointAmount;
      const now = new Date();
      const today =
        now.getFullYear().toString() +
        `${now.getMonth() + 1}` +
        now.getDate().toString() +
        now.getHours().toString() +
        now.getMinutes().toString() +
        now.getSeconds().toString();
      const defaultValue = {
        merchant_uid: billkeyStr + this.app.cache.user.id + '_' + today, // 상점에서 관리하는 주문 번호, 주문 번호 규칙: 상품정보(point,subscribe)_userId_결제일자
        name: '투닝포인트비인증결제',
        amount: this.pointAmount, // 실제 승인은 발생되지 않고 오직 빌링키만 발급됩니다.
        customer_uid: this.app.cache.user.id + today, // 필수 입력.
        buyer_email: this.app.cache.user.userEmail,
        buyer_name: this.app.cache.user.userName,
        m_redirect_url: successUrl,
        customer_id: this.app.cache.user.id //가맹점이 회원에게 부여한 고유 ID
      };
      Object.assign(this.requestBillingKeyIamportOps, defaultValue);
      switch (value) {
        case PayMethod.card:
          Object.assign(this.requestBillingKeyIamportOps, {
            pay_method: PayMethod.card,
            pg: PaymentGateyway.toss
          });
          break;
        // 카카오 페이는 포인트 미지원(추후에 추가될수있어 남겨둠)
        case PayMethod.kakao:
          Object.assign(this.requestBillingKeyIamportOps, {
            pay_method: PayMethod.kakao,
            pg: PaymentGateyway.kakaopay + '.' + kakaoKey
          });
          break;
        //네이버 페이는 포인트 미지원(추후에 추가될수도 있어 남겨둠)
        case PayMethod.naver:
          Object.assign(this.requestBillingKeyIamportOps, {
            pay_method: PayMethod.naver,
            pg: PaymentGateyway.naverpay,
            naverProductCode: 'billingKey_' + this.app.cache.user.id + '_' + today,
            naverPopupMode: true,
            naverProducts: [
              {
                categoryType: 'BOOK',
                categoryId: 'GENERAL',
                uid: '107922211',
                name: '한국사',
                payReferrer: 'NAVER_BOOK',
                sellerId: 'sellerA',
                count: 10
              },
              {
                categoryType: 'MUSIC',
                categoryId: 'CD',
                uid: '299911002',
                name: '러블리즈',
                payReferrer: 'NAVER_BOOK',
                sellerId: 'sellerB',
                count: 1
              }
            ]
          });
          break;
      }
    } catch (e) {
      throw new TooningPayMethodClickError(e);
    }
  }

  /**
   * 아임포트에 결제 요청하는 함수, 서버에 빌링키 저장 -> 아임포트에 빌링키 요청 -> 아임포트에 결제 요청
   * @param opt {RequestIamportParam} 아임포트 측 doc에 따른 param options
   * @returns {Promise<void>}
   */
  async requestPaymentToIamport(opts: RequestIamportParam) {
    try {
      if (!this.selectedPayment) {
        await this.app.showToast('결제 수단을 선택해주세요');
        return;
      }
      const impDiv = document.querySelectorAll<HTMLElement>('.imp-dialog');
      impDiv[0].style.backgroundColor = 'rgba(0,0,0,0.32)';
      const impFrame = impDiv[0].querySelectorAll<HTMLElement>('.imp-frame');
      impFrame[0].style.borderRadius = '20px';

      await IMP.request_pay(opts, async (rsp) => {
        try {
          await this.loading.showLoader();
          if (!rsp.success) {
            throw new TooningIamportRequestPayError(rsp.error_msg, this.app, true);
          }
          if (rsp.merchant_uid === opts.merchant_uid) {
            const inputIamportUsingSubscription: any = {
              customerUid: opts.customer_uid,
              payType: opts.pay_method,
              pg: rsp.pg_provider,
              payEventId: payEventId,
              merchantUid: opts.merchant_uid,
              cardNumber: rsp.card_number
            };
            const response = await this.paymentService
              .requestPaymentToIamportUsingApi(+this.app.cache.user.id, inputIamportUsingSubscription)
              .toPromise();
            if (response.data.requestPaymentToIamportUsingApi) {
              this.app.go('./card-registration-complete/');
            }
          } else {
            throw new TooningFailIamportBillingPaymentError('상품정보와 요청정보가 다른 경우', this.app, true);
          }
        } catch (e) {
          // 사용자가 결제를 취소한경우 대비 방어 코드
          if (e.message.includes('사용자') || e.message.includes('취소')) {
            await this.app.showToast(e.message);
            return;
          } else {
            await this.paymentService.cancelBillingKeyFromIamport(opts.customer_uid).toPromise();
            const message = e.message.split(']').at(-1);
            this.app.goQueryParams(`/card-registration-fail`, { ['message']: message });
          }
          if (
            e instanceof TooningFailRequestIamportPaymentError ||
            e instanceof TooningFailIamportBillingPaymentError ||
            e instanceof TooningFailRequestIamportGetBillingKeyError ||
            e instanceof TooningIamportRequestPayError
          ) {
            throw e;
          } else {
            throw new TooningIamportSdkError(e);
          }
        } finally {
          this.loading.hideLoader();
        }
      });
    } catch (e) {
      throw new TooningRequestPaymentToIamportError(e, this.app, true);
    }
  }

  /**
   * 포인트 전용 요청 함수, 카드의 경우 빌링키를 이용하여 계속 결제 가능, 간편결제는 불가능
   * @param {RequestIamportParam} opts 아임포트 변수들
   * @param isOnlyPoint 포인트만 충전할건지 리소스 구매도 할건지 구분하는 식별자
   * @return {Promise<void>}
   */
  async requestChargePoint(opts: RequestIamportParam, isOnlyPoint: boolean) {
    try {
      if (!this.selectedPayment) {
        await this.app.showToast('결제 수단을 선택해주세요');
        return;
      }
      const billingKeyData = await this.paymentService.checkBillingKey(+this.app.cache.user.id, this.requestBillingKeyIamportOps.pg).toPromise();
      this.isBillingKey = billingKeyData.data.result;
      if (this.isBillingKey && this.requestBillingKeyIamportOps.pg === PaymentGateyway.toss) {
        const alert = await this.alertController.create({
          header: '기존에 사용하신 카드가 확인되었습니다.',
          message: '이전에 사용하신 카드를 이어서 사용하시겠습니까?',
          cssClass: 'basic-dialog',
          buttons: [
            {
              text: '아니요',
              role: 'cancel',
              cssClass: 'secondary',
              handler: async () => {
                await this.loading.showLoader();
                // 기존 카드 삭제후 재등록
                await this.pointService.deleteBillingKeyFromIamport(this.app.cache.user.id, PayMethod.card).toPromise();
                await this.openIamportSdkForPoint(opts, isOnlyPoint);
                this.loading.hideLoader();
              }
            },
            {
              text: '예',
              handler: async () => {
                try {
                  await this.loading.showLoader();
                  this.billingKey = billingKeyData.data.billingKey;
                  const result = await this.paymentService
                    .chargePointUsingBillingKey(this.app.cache.user.id, this.billingKey, this.pointAmount, this.currency)
                    .toPromise();
                  if (!result) {
                    throw new TooningFailTossPaymentUsingBillingKeyError('토스 이전빌링키로 포인트 결제실패', this.app, true);
                  } else {
                    await this.afterPurchasePoint(isOnlyPoint);
                  }
                } catch (e) {
                  throw new TooningFailIamportBillingPaymentError('토스 이전빌링키로 포인트 결제실패', this.app, true);
                } finally {
                  this.loading.hideLoader();
                }
              }
            }
          ]
        });
        await alert.present();
      } else {
        await this.openIamportSdkForPoint(opts, isOnlyPoint);
      }
    } catch (e) {
      if (
        e instanceof TooningFailIamportBillingPaymentError ||
        e instanceof TooningFailRequestIamportGetBillingKeyError ||
        e instanceof TooningFailTossPaymentUsingBillingKeyError
      ) {
        throw e;
      } else {
        throw new TooningRequestChargePointError(e, this.app, true);
      }
    }
  }

  /**
   * 포인트 결제인지 구독 결제인지 체크해서 함수 호출
   * 1. 결제 수단 등록(식별자: isUpdatingCard)
   *  - 이미 같은 수단(식별자: alreadyExistCheck)으로 등록된 결제가 있다면 삭제 후 등록 얼럿
   *  - 아니면 등록
   * 2. 포인트 결제(식별자: isPoint)
   *  - 페이플이면 페이플로 결제 (식별자 card.id)
   *  - 아니면 아임포트로 결제
   * 3. 구독 결제
   * - 최초 구독 결제 => 이미 같은 수단(식별자: alreadyExistCheck)으로 등록된 결제가 있다면 등록된 결제 수단으로 결제
   * - 페이플로 이전에 구독한 이력이있다면(식별자: card.id) 삭제후 결제
   * @return {Promise<void>}
   */
  async clickRequestBtn(): Promise<void> {
    try {
      await this.loading.showLoader();
      const alreayExistCheck = this.payMethod[this.payMethodIndex].isAlreadyExist;
      if (this.isUpdatingCard) {
        // 결제 수단 등록만
        if (!this.defaultPaymentInfo) this.isDefaultPayment = true;
        this.requestBillingKeyIamportOps.m_redirect_url = environment.user_endpoint + '/account-setting';
        await this.openIamportSdkForRegisterBillingKey(this.requestBillingKeyIamportOps, this.isDefaultPayment, +this.app.cache.user.id);
      } else if (this.isPoint) {
        if (this.card.id) {
          //페이플로 결제
          this.doPointCharge.emit();
        } else {
          this.analyticsService.sendGA('pointChargeStart', 'page', this.app.currentService);
          await this.requestChargePoint(this.requestBillingKeyIamportOps, true);
        }
      } else {
        // 구독 결제
        if (alreayExistCheck && !this.card.id) {
          const cardNum = this.card.id
            ? this.card.number
            : this.alreadyPaymentInfo.find((payment) => payment.paymentType === this.payMethod[this.payMethodIndex].payMethod).cardNumber;
          const payMethod = this.payMethod[this.payMethodIndex].payMethod;
          const alert = await this.alertController.create({
            header: this.translate.instant('notice'),
            cssClass: 'basic-dialog',
            message:
              this.translate.instant(`paymentType.${payMethod}`) +
              this.pointService.makeMaskingCardNumber(cardNum) +
              '<br>' +
              this.translate.instant('paymentWords.startSubscriptionThisPayment'),
            mode: 'md',
            buttons: [
              {
                text: this.translate.instant('CANCEL'),
                role: 'cancel'
              },
              {
                text: this.translate.instant('header'),
                handler: async (data) => {
                  try {
                    await this.loading.showLoader();
                    const response = await this.paymentService
                      .requestSubscriptionUsingBillingKey(this.app.cache.user.id, this.requestBillingKeyIamportOps.pay_method)
                      .toPromise();
                    if (response.data.requestSubscriptionUsingBillingKey) {
                      this.app.go('./card-registration-complete/');
                    }
                  } catch (e) {
                    const message = e.message.split('[')[-1];
                    this.app.goQueryParams(`/card-registration-fail`, { ['message']: message });
                    throw new TooningRequestSubscriptionUsingBillingKeyError(e);
                  } finally {
                    this.loading.hideLoader();
                  }
                }
              }
            ]
          });
          await alert.present();
        } else {
          if (this.card.id) {
            const alert = await this.alertController.create({
              header: this.translate.instant('paymentWords.alreadyRegisterHeader'),
              cssClass: 'basic-dialog',
              message: this.translate.instant('paymentWords.deleteAndRegister'),
              mode: 'md',
              buttons: [
                {
                  text: this.translate.instant('CANCEL'),
                  role: 'cancel'
                },
                {
                  text: this.translate.instant('delete'),
                  handler: async (data) => {
                    //페이플 카드 지우는 코드 구독 체크하고 지우는 코드
                    await this.cardService.deleteCard(this.card.id).toPromise();
                    await this.openIamportSdkForRegisterBillingKey(this.requestBillingKeyIamportOps, this.isDefaultPayment, +this.app.cache.user.id);
                  }
                }
              ]
            });
            await alert.present();
          } else {
            await this.requestPaymentToIamport(this.requestBillingKeyIamportOps);
          }
        }
      }
    } catch (e) {
      if (
        e instanceof TooningIamportRequestPayError ||
        e instanceof TooningFailRegisterBillingKeyError ||
        e instanceof TooningIamportSdkError ||
        e instanceof TooningOpenIamportSdkForRegisterBillingKeyError ||
        e instanceof TooningRequestSubscriptionUsingBillingKeyError ||
        e instanceof TooningRequestChargePointError ||
        e instanceof TooningFailIamportBillingPaymentError ||
        e instanceof TooningFailRequestIamportGetBillingKeyError
      ) {
        throw e;
      } else {
        throw new TooningClickRequestBtnError(e);
      }
    } finally {
      this.loading.hideLoader();
      this.isClicked = false;
    }
  }

  /**
   * 아임포트 sdk를 열어주는 함수. 포인트 전용
   * @param {RequestIamportParam} opts
   * @param isOnlyPoint 포인트만 충전할건지 구분하는 식별자
   * @return {Promise<void>}
   */
  async openIamportSdkForPoint(opts: RequestIamportParam, isOnlyPoint: boolean) {
    const impDiv = document.querySelectorAll<HTMLElement>('.imp-dialog');
    impDiv[0].style.backgroundColor = 'rgba(0,0,0,0.32)';
    const impFrame = impDiv[0].querySelectorAll<HTMLElement>('.imp-frame');
    impFrame[0].style.borderRadius = '20px';
    await IMP.request_pay(opts, async (rsp) => {
      try {
        await this.loading.showLoader();
        if (rsp.success) {
          if (rsp.merchant_uid === opts.merchant_uid) {
            const iamportInput = Object.assign(
              {},
              {
                status: rsp.status,
                customerUid: rsp.customer_uid,
                pg: rsp.pg_provider,
                impUid: rsp.imp_uid,
                merchantUid: rsp.merchant_uid,
                price: +this.pointAmount,
                currency: rsp.currency,
                cardNumber: rsp.card_number
              }
            );
            // 카카오와 네이버는 빌링키 요청과 동시에 결제가 이루어짐, 토스는 아임포트에 request해야함
            const responseData = await this.paymentService.chargePointRequestIamport(this.app.cache.user.id, iamportInput).toPromise();
            const result = responseData.data.chargePointRequestIamport;
            if (!result) {
              throw new TooningFailIamportBillingPaymentError(`${rsp.pg} 새로운 빌링키 발급후 포인트 결제 실패`, this.app, true);
            } else {
              await this.afterPurchasePoint(isOnlyPoint);
            }
          }
        } else {
          throw new TooningIamportRequestPayError(rsp.error_msg, this.app, true);
        }
      } catch (e) {
        if (e instanceof TooningFailIamportBillingPaymentError || e instanceof TooningIamportRequestPayError) {
          throw e;
        } else {
          throw new TooningOpenIamportSdkForPointError(e, this.app, true);
        }
      } finally {
        this.loading.hideLoader();
      }
    });
  }

  /**
   * 빌링키 발급후 투닝 서버에 등록하기위한 함수
   * @param opts 아임포트에 빌링키 발급 요청을 위한 변수
   * @param {boolean} isDefault 기본 결제 수단으로 사용할지 여부, 만약 첫 등록이면 무조건 true
   * @param {number} userId
   * @return {Promise<void>}
   */
  async openIamportSdkForRegisterBillingKey(opts: any, isDefault: boolean, userId: number): Promise<void> {
    try {
      if (!this.selectedPayment) {
        await this.app.showToast(this.translate.instant('paymentWords.selectPaymentMethod'));
        return;
      }
      const impDiv = document.querySelectorAll<HTMLElement>('.imp-dialog');
      impDiv[0].style.backgroundColor = 'rgba(0,0,0,0.32)';
      const impFrame = impDiv[0].querySelectorAll<HTMLElement>('.imp-frame');
      impFrame[0].style.borderRadius = '20px';
      await IMP.request_pay(opts, async (rsp) => {
        try {
          await this.loading.showLoader();
          if (!rsp.success) {
            throw new TooningIamportRequestPayError(rsp.error_msg, this.app, true);
          }
          if (rsp.merchant_uid === opts.merchant_uid) {
            const inputIamportUsingSubscription: any = {
              customerUid: opts.customer_uid,
              payType: opts.pay_method,
              pg: rsp.pg_provider,
              payEventId: payEventId,
              merchantUid: opts.merchant_uid,
              cardNumber: rsp.card_number
            };
            // 서버에 빌링키 등록
            const queryResponse = await this.paymentService
              .registerBillingKeyFromIamport(inputIamportUsingSubscription, isDefault, userId)
              .toPromise();
            if (!queryResponse.data.registerBillingKeyFromIamport) {
              await this.app.showToast('등록 실패했습니다.');
              throw new TooningFailRegisterBillingKeyError('fail');
            } else {
              this.showCardUpdateView.emit();
            }
          }
        } catch (e) {
          throw new TooningIamportSdkError(e);
        } finally {
          this.loading.hideLoader();
        }
      });
    } catch (e) {
      if (e instanceof TooningIamportRequestPayError || e instanceof TooningFailRegisterBillingKeyError || e instanceof TooningIamportSdkError) {
        throw e;
      } else {
        throw new TooningOpenIamportSdkForRegisterBillingKeyError(e);
      }
    }
  }

  /**
   * 이미 등록된 결제 수단인지 체크하는 함수
   * @return {void}
   */
  checkAlreadyPamyent(): void {
    if (this.alreadyPaymentInfo.length > 0) {
      for (let paymentInfo of this.alreadyPaymentInfo) {
        const index = this.payMethod.findIndex((method) => method.payMethod === paymentInfo.paymentType);
        this.payMethod[index].isAlreadyExist = true;
      }
    }
    if (this.card.id) {
      const index = this.payMethod.findIndex((method) => method.payMethod === PayMethod.card);
      this.payMethod[index].isAlreadyExist = true;
    }
  }

  /**
   *
   * @param {string} value
   * @return {Promise<void>}
   */
  async checkBeforeClickPayMethod(value: string, isAlreadyExist: boolean, index: number): Promise<void> {
    try {
      if (isAlreadyExist && this.isUpdatingCard) {
        const alert = await this.alertController.create({
          header: this.translate.instant('paymentWords.alreadyRegisterHeader'),
          cssClass: 'basic-dialog',
          message: this.translate.instant('paymentWords.deleteAndRegister'),
          mode: 'md',
          buttons: [
            {
              text: this.translate.instant('CANCEL'),
              role: 'cancel'
            },
            {
              text: this.translate.instant('delete'),
              handler: async (data) => {
                if (this.card.id) {
                  //페이플 카드 지우는 코드 구독 체크하고 지우는 코드
                  await this.cardService.deleteCard(this.card.id).toPromise();
                  await this.showCardUpdateView.emit();
                } else {
                  await this.pointService.deleteBillingKeyFromIamport(+this.app.cache.user.id, value).toPromise();
                }
                this.payMethodClick(value, index);
              }
            }
          ]
        });
        await alert.present();
      } else {
        this.payMethodClick(value, index);
      }
    } catch (e) {
      throw new TooningCheckBeforeClickPayMethodError(e);
    }
  }

  /**
   * 결제 후 포인트 충전 or 포인트충전 후 리소스 구매인지 체크
   * @param {boolean} isOnlyPoint
   * @returns {Promise<void>}
   */
  async afterPurchasePoint(isOnlyPoint: boolean): Promise<void> {
    if (!this.isCutPage && !this.isMagicPage && !this.isGptPage) {
      this.app.go('/point-card-registration-complete/');
      this.analyticsService.sendGA('purchasePoint', this.app.cache.user.country + ': ' + this.pointAmount, this.app.currentService);
    } else {
      if (isOnlyPoint) {
        if (this.app.modal !== null) {
          await this.app.modal.dismiss();
          this.app.modal = null;
        }
        await this.app.showToast(this.translate.instant('complete coin charging2'));
        this.analyticsService.sendGA('purchasePoint', this.app.cache.user.country + ': ' + this.pointAmount, this.app.currentService);
      } else {
        this.purchaseAfterCharging.emit();
        this.analyticsService.sendGA('purchasePoint', this.app.cache.user.country + ': ' + this.pointAmount, this.app.currentService);
      }
    }
  }
}
