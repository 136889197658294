import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DirectionChangeString'
})
export class DirectionChangeStringPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    let converted = '';
    switch (value) {
      case 0:
        converted = 'front';
        break;
      case 1:
        converted = 'side';
        break;
      case 2:
        converted = 'back';
        break;
      default:
        converted = 'front';
    }
    return converted;
  }
}
