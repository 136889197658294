import { Injectable } from '@angular/core';
import { LegacyApiService } from '../api/legacy.api.service';
import { UserService } from '../user.service';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { GraphqlApiService } from '../api/graphql.api.service';
import { gql, ApolloQueryResult, FetchResult } from '@apollo/client';
import { AppService } from '../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModelEtcUploadBasic, ModelEtcUploadMeta } from './etcUpload.service.model';
import { EtcUploadCategory } from '../../model/etcUpload/etcUploadCategory';
import { EtcUploadEachStepTF } from '../../model/step/etcUploadEachStepTF';
import * as _ from 'lodash';
import { Cache } from '../../interfaces/cache.interface';
import { HttpClient } from '@angular/common/http';
import { Sku, SkuData } from '../../model/sku/sku';

// ? 역할 : 생성 및 수정중인 캐릭터의 데이터 관리
// * 각 페이지에 해당하는 필수 데이터들에 대한 공통적인 검증로직 및 저장/로드 관리

@Injectable({
  providedIn: 'root'
})
export class ResMakerEtcUloadService {
  // 캐릭터 상세 페이지별 데이터
  public dataMeta: ModelEtcUploadMeta;
  public dataBasic: ModelEtcUploadBasic;
  private caches: Cache = {};
  public allowMultiple: boolean = false;
  public sourceIdList: number[] = [];

  // 발행상태 및 상세페이지별 완료 여부
  // public stepStatus: ModelCharacterStatus;

  constructor(
    public app: AppService,
    public appL: LegacyApiService,
    public userService: UserService,
    public graphql: GraphqlApiService,
    private domSanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.clear();
  }

  public cacheClear() {
    this.caches = {};
  }

  // 신규 배경 생성 (배경 생성시에 실행)
  create() {
    this.dataMeta = new ModelEtcUploadMeta();
    this.dataBasic = new ModelEtcUploadBasic();
  }

  // 배경 정보 초기화
  clear() {
    this.dataMeta = this.dataBasic = null;
  }

  // 새배경 여부 확인
  public isNewEtcUpload(): boolean {
    return !this.dataMeta?.id;
  }

  // 새배경 null 여부 확인
  public isAvailableEtcUpload(): boolean {
    return this.dataMeta != null;
  }

  // step TF 목록 가져오기
  public getStepData(characterId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query getStepData($characterId: ID!) {
          getStepData(characterId: $characterId) {
            baseStep
            faceStep
            bodyStep
            handStep
            hairStep
            etcStep
            headPositionStep
            armIndexStep
            legIndexStep
            headRotateStep
            bodyRotateStep
            expressionTagStep
            bodyIntentionStep
            bodyTagStep
            defaultSetStep
          }
        }
      `,
      {
        characterId
      }
    );
  }

  // 배경 만들기 with 기본정보
  createEtcUploadCategory(data, file): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation etcUploadCategoryCreate($data: InputEtcUploadCategoryCreate!, $file: [Upload!]!) {
            etcUploadCategoryCreate(data: $data, file: $file) {
              categoryId
              imgPath
              err
            }
          }
        `,
        {
          data,
          file
        }
      )
      .pipe(
        tap((result) => {
          if (result.data.etcUploadCategoryCreate.err !== null) {
            return;
          }
          this.dataMeta.id = result.data.etcUploadCategoryCreate.categoryId;
          this.dataBasic = new ModelEtcUploadBasic(data);

          this.dataBasic.imgPath = result.data.etcUploadCategoryCreate.imgPath;

          // 캐릭터 캐쉬
          this.app.cache.setWorkingEtcUpload(result.data.etcUploadCategoryCreate.categoryId.toString());
          this.app.cache.setWorkingEtcUploadName(data.name_ko);
        })
      );
  }

  etcUploadUpdate(data, files, skuData?: SkuData): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation etcUploadUpdate($data: InputEtcUploadUpdate!, $files: [Upload!]!, $skuData: InputSkuData) {
          etcUploadUpdate(data: $data, files: $files, skuData: $skuData) {
            sourceId
          }
        }
      `,
      {
        data,
        files,
        skuData
      }
    );
  }

  // 아이템 삭제
  deleteEtcUpload(sourceId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation deleteEtcUpload($sourceId: ID!) {
          deleteEtcUpload(sourceId: $sourceId)
        }
      `,
      {
        sourceId
      }
    );
  }

  createEtcUpload(data, files, skuData?: SkuData): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation etcUploadCreate($data: InputEtcUploadCreate!, $files: [Upload!]!, $skuData: InputSkuData) {
            etcUploadCreate(data: $data, files: $files, skuData: $skuData) {
              sourceId
            }
          }
        `,
        {
          data,
          files,
          skuData
        }
      )
      .pipe(
        tap((result) => {
          this.dataBasic = new ModelEtcUploadBasic(data);
        })
      );
  }

  /**
   * etcUpload 에 속한 태그들을 조회한다.
   * @param id etcUpload ID
   */
  getTagListForEtcUpload(id: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query getTagListForEtcUpload($id: ID!) {
          getTagListForEtcUpload(id: $id) {
            name {
              ko
              en
              fr
              ja
            }
            tagId
          }
        }
      `,
      {
        id
      }
    );
  }

  /**
   * 직접 입력했던 기존 태그를 가져온다
   * @param {number} tagId - 태그 아이디
   * @returns {Promise<ApolloQueryResult<any>>}
   */
  getOriginalTags(tagId: number): Promise<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getOriginalTags($tagId: ID!) {
            getOriginalTags(tagId: $tagId) {
              languageType
            }
          }
        `,
        {
          tagId
        }
      )
      .toPromise();
  }

  /**
   * etcUploads 에 대한 태그 및 태그 번역본을 저장한다.
   * @param categoryId - etcUpload 리소스의 카테고리 아이디
   * @param sourceId - etcUpload 리소스의 아이디
   * @param tag - 저장할 태그
   */
  etcUploadsTagsCreate(categoryId: number, sourceId: number, tags: string[]): Observable<FetchResult<any>> {
    let query: any;
    let param: any;
    if (categoryId != null) {
      query = gql`
        mutation EtcUploadTagsCreate($categoryId: ID!, $sourceId: ID!, $tags: [JSONObject!]!) {
          EtcUploadTagsCreate(categoryId: $categoryId, sourceId: $sourceId, tags: $tags)
        }
      `;
      param = { categoryId, sourceId, tags };
    }
    for (const tag of tags) {
      // @ts-ignore
      delete tag.__typename;
    }
    return this.graphql.mutate(query, param);
  }

  /**
   * 특정 대분류에 속해 있는 리소스들 조회
   * @param typeId - 대분류 PK
   * @param userId - 유저아이디
   * @param sourceType - ex) 소품, 배경 ..
   * @param role - 유저권한
   * @param skip - 몇 번째부터
   * @param take - 몇 개의 데이터를 조회해올 것인지
   * @param authorType - 작가 타입 ex) 외부작가 or 투닝
   */
  getEtcUploadInType(
    typeId: number,
    userId: number,
    sourceType: string,
    role: string,
    skip: number,
    take: number,
    authorType: string
  ): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getEtcUploadInType(
            $typeId: Int!
            $userId: ID!
            $sourceType: String!
            $role: String!
            $skip: Int!
            $take: Int!
            $authorType: String!
          ) {
            getEtcUploadInType(
              typeId: $typeId
              userId: $userId
              sourceType: $sourceType
              role: $role
              skip: $skip
              take: $take
              authorType: $authorType
            ) {
              id
              name_ko
              name_en
              name_fr
              name_jp
              etcUpload {
                sourceId
                sourceType
                authorType
                isSet
                base64
                etcUploadVersion {
                  version
                }
                sku {
                  id
                  point
                  purchase {
                    id
                  }
                }
              }
            }
          }
        `,
        {
          typeId,
          userId,
          sourceType,
          role,
          skip,
          take,
          authorType
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getEtcUploadInType;
          return result;
        })
      );
  }

  getAllEtcUploadType(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getAllEtcUploadType {
            getAllEtcUploadType {
              id
              name_ko
            }
          }
        `
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getAllEtcUploadType;
          return result;
        })
      );
  }

  getAllEtcUploadTypeWithLength(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getAllEtcUploadTypeWithLength {
            getAllEtcUploadTypeWithLength {
              id
              name
              length
            }
          }
        `
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getAllEtcUploadTypeWithLength;
          return result;
        })
      );
  }

  updateEtcUploadType(data): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation etcUploadTypeUpdate($data: InputEtcUploadTypeUpdate!) {
            etcUploadTypeUpdate(data: $data) {
              id
            }
          }
        `,
        {
          data
        }
      )
      .pipe(tap((result) => {}));
  }

  createEtcUploadType(data): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation etcUploadTypeCreate($data: InputEtcUploadTypeCreate!) {
          etcUploadTypeCreate(data: $data) {
            id
            name
          }
        }
      `,
      {
        data
      }
    );
  }

  /**
   * 특정 etcUpload(소품) 의 대분류를 바꾼다.
   * @param sourceId - etcUpload PK
   * @param typeId - etcUploadType PK
   */
  changeEtcUploadType(sourceId: number[], typeId: number): Observable<FetchResult<any>> {
    let query: any;
    let param: any;
    query = gql`
      mutation changeEtcUploadType($sourceId: [Int!]!, $typeId: Int!) {
        changeEtcUploadType(sourceId: $sourceId, typeId: $typeId)
      }
    `;
    param = { sourceId, typeId };
    return this.graphql.mutate(query, param);
  }

  /**
   * 특정 etcUpload(소품) 의 카테고리를 바꾼다.
   * @param sourceIdList - etcUpload PK
   * @param categoryId - etcUploadCategory PK
   */
  changeEtcUploadCategory(sourceIdList: number[], categoryId: number): Observable<FetchResult<any>> {
    let query: any;
    let param: any;
    query = gql`
      mutation changeEtcUploadCategory($sourceIdList: [Int!]!, $categoryId: Int!) {
        changeEtcUploadCategory(sourceIdList: $sourceIdList, categoryId: $categoryId)
      }
    `;
    param = { sourceIdList, categoryId };
    return this.graphql.mutate(query, param);
  }

  // etc 리소스와 태그리스트 연결
  createTag(etcUploadId: number, tag?: string[]): Observable<FetchResult<any>> {
    let query: any;
    let param: any;
    if (etcUploadId != null) {
      query = gql`
        mutation addTag($etcUploadId: ID!, $tag: [String!]) {
          TagsCreate(tags: $tag, etcUploadId: $etcUploadId)
        }
      `;
      param = { tag, etcUploadId };
    }

    return this.graphql.mutate(query, param);
  }

  // etcUpload 리스트에 필요한 정보들 갖고오기
  getEtcUploads(categoryId: number, sourceType: string): Observable<ApolloQueryResult<any>> {
    // 목록 뿌릴 떄 사
    return this.graphql
      .query(
        gql`
          query etcUploads($categoryId: Int!, $sourceType: String!) {
            etcUploads(categoryId: $categoryId, sourceType: $sourceType) {
              positionType
              sourceId
              sourceType
              isSet
              base64
              fabricObject
              order
              status
              authorType
              etcUploadVersion {
                version
              }
              sku {
                id
                point
              }
            }
          }
        `,
        {
          categoryId,
          sourceType
        }
      )
      .pipe(tap((result) => {}));
  }

  etcUploadAuthorTypeUpdate(categoryId: number, authorType: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation etcUploadAuthorTypeUpdate($categoryId: Int!, $authorType: String!) {
          etcUploadAuthorTypeUpdate(categoryId: $categoryId, authorType: $authorType)
        }
      `,
      {
        categoryId,
        authorType
      }
    );
  }

  getEtcUpload(sourceId, sourceType: string, userId: number): Observable<ApolloQueryResult<any>> {
    // add, set 설정 시 사용
    return this.graphql
      .query(
        gql`
          query etcUpload($sourceId: ID!, $sourceType: String!, $userId: ID!) {
            etcUpload(sourceId: $sourceId, sourceType: $sourceType, userId: $userId) {
              sourceId
              positionType
              authorType
              etcUploadVersion {
                version
              }
              etcUploadCategory {
                name_ko
                name_en
                name_fr
                name_jp
                categoryId
              }
              etcUploadType {
                id
                name_ko
              }
              sourceType
              base64
              fabricObject
              tags {
                id
                name
              }
              sku {
                id
                point
                purchase {
                  id
                }
              }
            }
          }
        `,
        {
          sourceId,
          sourceType,
          userId
        }
      )
      .pipe(
        tap((result) => {
          if (result.data.etcUpload[0].sku != null) {
            // test push
            result.data.etcUpload[0].sku = new Sku().deserialize(result.data.etcUpload[0].sku);
          }
          return result;
        })
      );
  }

  // 캐릭터 업데이트 with 기본정보
  updateEtcUploadCategory(data, file?): Observable<FetchResult<any>> {
    if (!this.isAvailableEtcUpload() || this.isNewEtcUpload()) {
      // 배경 체크
      return null;
    }

    // 파일변경 여부에 따라 gql데이터 준비
    this.app.redLog(this.dataMeta.id);
    data = { categoryId: this.dataMeta.id, ...data };
    const query =
      file == null
        ? gql`
            mutation updateEtcUploadCategory($data: InputEtcUploadCategoryUpdate!) {
              updateEtcUploadCategory(data: $data) {
                categoryId
                sourceType
              }
            }
          `
        : gql`
            mutation updateEtcUploadCategory($data: InputEtcUploadCategoryUpdate!, $file: [Upload!]!) {
              updateEtcUploadCategory(data: $data, file: $file) {
                sourceType
                imgPath
              }
            }
          `;
    const param: any = file == null ? { data } : { data, file };

    // 보내고 끗
    return this.graphql.mutate(query, param).pipe(
      tap((data) => {
        console.log(data.data.etcUploadUpdateBasic);
      })
    );
  }

  public getTooningAuthorEtcUpload(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getTooningAuthorEtcUpload {
            getTooningAuthorEtcUpload {
              sourceId
            }
          }
        `
      )
      .pipe(
        tap((results) => {
          results.data = results.data.getTooningAuthorEtcUpload;
          return results;
        })
      );
  }

  /**
   * 특정 카테고리에 대한 정보 조회
   * @param sourceType - 소스타입 ex) 소품, 배경
   * @param authorType - 작가타입 ex) 투닝, 외부
   * @param categoryId - 카테고리 ID
   */
  getEtcUploadCategory(sourceType: string, authorType: string, categoryId?: number): Observable<ApolloQueryResult<Array<EtcUploadCategory>>> {
    return this.graphql
      .query(
        gql`
          query getEtcUploadCategory($sourceType: String!, $categoryId: ID, $authorType: String!) {
            getEtcUploadCategory(sourceType: $sourceType, categoryId: $categoryId, authorType: $authorType) {
              categoryId
              imgName
              name_ko
              name_en
              desc_ko
              desc_en
              name_fr
              name_jp
              desc_fr
              desc_jp
              base64
              status
              sourceType
              imgPath
              order
            }
          }
        `,
        {
          sourceType,
          categoryId,
          authorType
        }
      )
      .pipe(
        tap((results) => {
          results.data = results.data.getEtcUploadCategory.map((category) => {
            return new EtcUploadCategory().deserialize(category);
          });
          return results;
        })
      );
  }

  // 아이템 삭제
  deleteEtcUploadCategory(itemId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation deleteEtcUploadCategory($itemId: ID!) {
          deleteEtcUploadCategory(itemId: $itemId)
        }
      `,
      {
        itemId
      }
    );
  }

  // 스텝 체크 데이터 가져오기
  getEtcUploadEachStepData(itemId: number, sourceType: string): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query getEtcUploadEachStepData($itemId: ID!, $sourceType: String!) {
          getEtcUploadEachStepData(itemId: $itemId, sourceType: $sourceType) {
            baseStep
            listStep
            structureStep
          }
        }
      `,
      {
        itemId,
        sourceType
      }
    );
  }

  // 각 step validation 저장
  etcUploadEachStepTFControl(etcUploadEachStepData: EtcUploadEachStepTF): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation etcUploadEachStepTFControl($etcUploadEachStepData: InputEtcUploadEachStepTFControl!) {
          etcUploadEachStepTFControl(etcUploadEachStepData: $etcUploadEachStepData) {
            baseStep
            listStep
            structureStep
          }
        }
      `,
      {
        etcUploadEachStepData
      }
    );
  }

  // 소품 배경 tag 검색
  etcUploadTagSearch(sourceType: string, isSet: boolean, categoryId: number, tagText?: string): Observable<ApolloQueryResult<any>> {
    let result$;

    const cacheKey = _.compact(['etcUploadTagSearch', categoryId, sourceType, isSet, tagText]).join('_');

    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query etcUploadTagSearch($sourceType: String!, $isSet: Boolean!, $categoryId: ID!, $tagText: String) {
              etcUploadTagSearch(sourceType: $sourceType, isSet: $isSet, categoryId: $categoryId, tagText: $tagText) {
                sourceId
                sourceType
                isSet
                base64
                fabricObject
                tags {
                  id
                  name
                }
              }
            }
          `,
          {
            sourceType,
            isSet,
            categoryId,
            tagText
          }
        )
        .pipe(shareReplay());
    }
    return result$;
  }

  // 소품 배경 tag 검색 tagSearchFor4Cut
  tagSearchEtc(
    userId: number,
    sourceType: string,
    role: string,
    skip: number,
    take: number,
    tagText?: string,
    typeId?: number
  ): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['tagSearchEtc', userId, sourceType, role, skip, take, tagText, typeId]).join('_');

    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query tagSearchEtc($userId: ID!, $sourceType: String!, $role: String!, $skip: Int!, $take: Int!, $tagText: String, $typeId: Int) {
              tagSearchEtc(userId: $userId, sourceType: $sourceType, role: $role, skip: $skip, take: $take, tagText: $tagText, typeId: $typeId) {
                sourceId
                sourceType
                isSet
                base64
                authorType
                etcUploadVersion {
                  version
                }
                sku {
                  id
                  point
                  purchase {
                    id
                  }
                }
              }
            }
          `,
          {
            userId,
            sourceType,
            role,
            skip,
            take,
            tagText,
            typeId
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data.tagSearchEtc = cloneResult.data.tagSearchEtc.map((etcUpload) => {
              etcUpload.base64 = etcUpload.base64 + '/100x';
              if (etcUpload.sku != null) {
                etcUpload.sku = new Sku().deserialize(etcUpload.sku);
                return etcUpload;
              }
              return etcUpload;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  getEtcUploadInCategory(
    userId: number,
    categoryId: number,
    sourceType: string,
    role: string,
    skip: number,
    take: number
  ): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['getEtcUploadInCategory', userId, categoryId, sourceType, role, skip, take]).join('_');

    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query getEtcUploadInCategory($userId: ID!, $categoryId: ID!, $sourceType: String!, $role: String!, $skip: Int!, $take: Int!) {
              getEtcUploadInCategory(userId: $userId, categoryId: $categoryId, sourceType: $sourceType, role: $role, skip: $skip, take: $take) {
                sourceId
                sourceType
                authorType
                isSet
                base64
                etcUploadVersion {
                  version
                }
                tags {
                  id
                  name
                }
                sku {
                  id
                  point
                  purchase {
                    id
                  }
                }
              }
            }
          `,
          {
            userId,
            categoryId,
            sourceType,
            role,
            skip,
            take
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data.getEtcUploadInCategory = cloneResult.data.getEtcUploadInCategory.map((etcUpload) => {
              etcUpload.base64 = etcUpload.base64 + '/100x';
              if (etcUpload.sku != null) {
                etcUpload.sku = new Sku().deserialize(etcUpload.sku);
                return etcUpload;
              }
              return etcUpload;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  /**
   * 카테고리별 etcUpload 조회
   * @param userId - 유저 아이디
   * @param sourceType - etcUpload 타입 (배경, 소품 등)
   * @param skip - 몇 번째 row 부터 조회?
   * @param take - 몇 개의 데이터 조회?
   * @param role - 유저 권한
   * @param authorType - 투닝? 외부작가?
   */
  etcUploadEditorSearch(
    userId: number,
    sourceType: string,
    skip: number,
    take: number,
    role: string,
    authorType?: string
  ): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['etcUploadEditorSearch', userId, skip, take, sourceType, role, authorType]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query etcUploadEditorSearch($userId: ID!, $sourceType: String!, $skip: Int!, $take: Int!, $role: String!, $authorType: String) {
              etcUploadEditorSearch(userId: $userId, sourceType: $sourceType, skip: $skip, take: $take, role: $role, authorType: $authorType) {
                categoryId
                name_ko
                name_en
                name_fr
                name_jp
                sourceType
                status
                etcUpload {
                  sourceId
                  sourceType
                  authorType
                  isSet
                  base64
                  etcUploadVersion {
                    version
                  }
                  sku {
                    id
                    point
                    purchase {
                      id
                    }
                  }
                }
              }
            }
          `,
          {
            userId,
            sourceType,
            skip,
            take,
            role,
            authorType
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data.etcUploadEditorSearch = cloneResult.data.etcUploadEditorSearch.map((categories) => {
              for (const etcUpload of categories.etcUpload) {
                etcUpload.base64 = etcUpload.base64 + '/100x';
                if (etcUpload.sku != null) {
                  etcUpload.sku = new Sku().deserialize(etcUpload.sku);
                }
              }
              return categories;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  /**
   * 대분류별 etcUpload 조회
   * @param userId - 유저 아이디
   * @param sourceType - etcUpload 타입 (배경, 소품 등)
   * @param skip - 몇 번째 row 부터 조회?
   * @param take - 몇 개의 데이터 조회?
   * @param role - 유저 권한
   * @param authorType - 투닝? 외부작가?
   */
  etcUploadEditorSearchByType(
    userId: number,
    sourceType: string,
    skip: number,
    take: number,
    role: string,
    authorType: string
  ): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['etcUploadEditorSearchByType', userId, skip, take, sourceType, role, authorType]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query etcUploadEditorSearchByType($userId: ID!, $sourceType: String!, $skip: Int!, $take: Int!, $role: String!, $authorType: String!) {
              etcUploadEditorSearchByType(userId: $userId, sourceType: $sourceType, skip: $skip, take: $take, role: $role, authorType: $authorType) {
                id
                name_ko
                name_en
                name_fr
                name_jp
                etcUpload {
                  sourceId
                  sourceType
                  authorType
                  isSet
                  base64
                  etcUploadVersion {
                    version
                  }
                  sku {
                    id
                    point
                    purchase {
                      id
                    }
                  }
                }
              }
            }
          `,
          {
            userId,
            sourceType,
            skip,
            take,
            role,
            authorType
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data.etcUploadEditorSearchByType = cloneResult.data.etcUploadEditorSearchByType.map((types) => {
              for (const etcUpload of types.etcUpload) {
                etcUpload.base64 = etcUpload.base64 + '/100x';
                if (etcUpload.sku != null) {
                  etcUpload.sku = new Sku().deserialize(etcUpload.sku);
                }
              }
              return types;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  // 카테고리 order 변경
  etcUploadCategoryOrderUpdate(categoryId: number, order: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation etcUploadCategoryOrderUpdate($categoryId: ID!, $order: Int!) {
          etcUploadCategoryOrderUpdate(categoryId: $categoryId, order: $order)
        }
      `,
      {
        categoryId,
        order
      }
    );
  }

  /**
   * 대분류 순서를 변경한다.
   * @param id - 순서 변경할 typeId
   * @param order - 몇번째로 변경할건지?
   */
  etcUploadTypeOrderUpdate(id: number, order: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation etcUploadTypeOrderUpdate($id: ID!, $order: Int!) {
          etcUploadTypeOrderUpdate(id: $id, order: $order)
        }
      `,
      {
        id,
        order
      }
    );
  }

  // 소품 배경.. order 변경
  etcUploadOrderUpdate(sourceId: number, sourceType: string, isSet: boolean, order: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation etcUploadOrderUpdate($sourceId: ID!, $sourceType: String!, $isSet: Boolean!, $order: Int!) {
          etcUploadOrderUpdate(sourceId: $sourceId, sourceType: $sourceType, isSet: $isSet, order: $order)
        }
      `,
      {
        sourceId,
        sourceType,
        isSet,
        order
      }
    );
  }

  etcUploadStatusUpdate(sourceId: number, status: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation etcUploadStatusUpdate($sourceId: ID!, $status: String!) {
          etcUploadStatusUpdate(sourceId: $sourceId, status: $status)
        }
      `,
      {
        sourceId,
        status
      }
    );
  }

  public getHttpUrl(url): Observable<any> {
    let results$;
    if (this.caches[url]) {
      results$ = this.caches[url];
    } else {
      results$ = this.caches[url] = this.http.get(url).pipe(shareReplay());
    }
    return results$;
  }

  /**
   * etcUpload 리소스의 base64 를 가져온다
   * @param {number} etcUploadId - etcUpload 아이디
   * @return {Promise<ApolloQueryResult<any>>}
   */
  getEtcUploadJPG(etcUploadId: number): Promise<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getEtcUploadJPG($etcUploadId: ID!) {
            getEtcUploadJPG(etcUploadId: $etcUploadId) {
              base64
            }
          }
        `,
        {
          etcUploadId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getEtcUploadJPG;
          return result;
        })
      )
      .toPromise();
  }
}
