import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../../base-page';
import { AppService } from '../../services/app.service';
import { AlertController, AnimationController, IonSlides, ModalController, PopoverController, ScrollDetail } from '@ionic/angular';
import { CanvasService } from '../../services/canvas.service';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Cut4MakeManualService } from '../cut4-make-manual2/cut4-make-manual.service';
import { environment } from '../../../environments/environment';
import { PopoverShareComponent } from './popover-share/popover-share.component';
import { TranslateService } from '@ngx-translate/core';
import { Loading } from '../../services/loading';
import { PaidReferralComponent } from '../../components/paid-referral/paid-referral.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { fromEvent, Observable } from 'rxjs';
import { LoginPage } from '../../pages-member/login/login.page';
import {
  TooningApplyCanvasShareCloneCanvasError,
  TooningCanvasShareAuthorCheckError,
  TooningCanvasShareIonViewDidEnterError,
  TooningGetCanvasError,
  TooninghandleScrollError,
  TooningShareFullScreenError,
  TooningShareSlideDidChangeError,
  TooningSlideszoomInError,
  TooningSlideszoomOutError
} from '../../pages-tooning/errors/TooningErrors';
import { UserRole, ViewOrientationEnum } from '../../enum/app.enum';

@Component({
  selector: 'app-canvas-share',
  templateUrl: './canvas-share.page.html',
  styleUrls: ['./canvas-share.page.scss']
})
export class CanvasSharePage extends BasePage implements OnInit {
  @Input('id') id;
  @Input('pageIndex') pageIndex = 0;
  @Input('fromEditor') fromEditor;
  @ViewChild('viewDiv', { static: true }) viewDiv: ElementRef;
  public appVersion: string;
  public scrollTop: number;
  public slideSzoom: number;
  public viewDivH: number;
  public url: string;
  public list: any;
  public pages: any;
  public CanvasId: any;
  public orientation: string;
  public name: string;
  public title: string;
  public canvasSize: string;
  public letters = ['ffffff'];
  public getCanvas: any;
  public authorization: string;
  public popover: any;
  public readyToRender = false;
  public slidesIndex = 0;
  public slides: IonSlides;
  public fullscreenSlides: IonSlides;
  public UserRole = UserRole;
  public isModal: boolean;
  public isFullscreen: boolean;
  public slideOpts: any;
  public isControlView: boolean;
  public viewOrientationEnumType: any;
  public userRole: UserRole | undefined;
  public userId: number | undefined;
  @ViewChild('content') private content: any;
  private popstateObservable$: Observable<Event>;
  private keydownObservable$: Observable<Event>;
  private fullscreenchangeObservable$: Observable<Event>;
  private isCloneTemplateClicking = false;
  private country;

  constructor(
    public app: AppService,
    public route: ActivatedRoute,
    private animationCtrl: AnimationController,
    public dialog: MatLegacyDialog,
    public modalCtrl: ModalController,
    public canvasService: CanvasService,
    public alertController: AlertController,
    public domSanitizer: DomSanitizer,
    public cut: Cut4MakeManualService,
    public popoverController: PopoverController,
    private translate: TranslateService,
    private metaService: Meta
  ) {
    super(app);
  }

  // @ts-ignore
  // this.slides 가 렌더가 안되는 상태 버그해결
  // https://stackoverflow.com/questions/34947154/angular-2-viewchild-annotation-returns-undefined
  @ViewChild('Slider') set controlSlides1(element: IonSlides) {
    this.slides = element;
    try {
      this.viewDivH = this.viewDiv.nativeElement.offsetHeight;
      if (this.orientation === 'horizontal') {
        this.slides.getActiveIndex().then((data) => {
          this.slidesIndex = data;
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // @ts-ignore
  // this.slides 가 렌더가 안되는 상태 버그해결
  // https://stackoverflow.com/questions/34947154/angular-2-viewchild-annotation-returns-undefined
  @ViewChild('slidesFullscreen') set controlSlides2(element: IonSlides) {
    this.fullscreenSlides = element;
    try {
      if (this.fullscreenSlides) {
        this.fullscreenSlides.slideTo(this.cut.panelIndex).then((r) => {});
      }
    } catch (e) {
      console.log(e);
    }
  }

  async ngOnInit() {
    super.ngOnInit();
    this.setUpUserInformation();
    this.viewOrientationEnumType = ViewOrientationEnum;
    this.slideOpts = {
      initialSlide: 1,
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.full-swiper-button-next',
        prevEl: '.full-swiper-button-prev'
      }
    };

    window.history.pushState({ modal: true }, null);
    this.popstateObservable$ = fromEvent(window, 'popstate');
    this.fullscreenchangeObservable$ = fromEvent(document, 'fullscreenchange');
    this.keydownObservable$ = fromEvent(window, 'keydown');

    this.subscriptions.push(
      this.keydownObservable$.subscribe(async (event) => {
        if (this.isFullscreen) {
          // @ts-ignore
          if (event.key === 'ArrowRight') {
            console.log('ArrowRight');
            await this.fullscreenSlides.slideNext();
          }
          // @ts-ignore
          if (event.key === 'ArrowLeft') {
            console.log('ArrowLeft');
            await this.fullscreenSlides.slidePrev();
          }
        }
      })
    );

    this.subscriptions.push(
      //.pipe(debounceTime(300))
      this.fullscreenchangeObservable$.subscribe(async (event) => {
        if (document.fullscreenElement) {
          this.isFullscreen = true;
        } else {
          this.isFullscreen = false;
          //프리젠테이션 모드 이면 바로 실행
          if (this.orientation === 'presentation' && this.isModal) {
            await this.modalCtrl.dismiss();
          }
        }
      })
    );

    this.subscriptions.push(
      this.popstateObservable$.subscribe(async (event) => {
        await this.popstateHandler(event);
      })
    );
  }

  async popstateHandler($event: any) {
    if (this.app.modal !== null) {
      this.app.modal.dismiss();
    }
  }

  /**
   * 슬라이드 클릭시 처리함수
   * @returns {Promise<void>}
   */
  ionSlideTap(): void {
    this.isControlView = !this.isControlView;
  }

  /**
   * userRole, userId 를 세팅한다.
   * @return {void}
   */
  setUpUserInformation(): void {
    try {
      const user = this.app.cache.user;
      if (user) {
        this.userRole = user.role;
        this.userId = user.id;
      } else {
        throw new Error('user is empty');
      }
    } catch (e) {
      this.userRole = undefined;
      this.userId = undefined;
    }
  }

  /**
   * ionViewWillEnter
   * @returns {Promise<void>}
   */
  async ionViewWillEnter() {
    try {
      this.readyToRender = false;
      this.isControlView = true;
      super.ionViewDidEnter();

      this.orientation = this.viewOrientationEnumType.vertical;
      if (!this.id) {
        //링크로 공유받은 경우
        if (this.route.snapshot.params.id === 'global') {
          this.CanvasId = this.route.snapshot.queryParams.id;
          this.country = this.route.snapshot.queryParams.country;
          this.isModal = false;
        } else {
          this.CanvasId = this.route.snapshot.params.id;
        }
      } else {
        //모달로 뜨는 케이스
        this.CanvasId = this.id;
        this.isModal = true;
      }

      try {
        this.getCanvas = this.country
          ? await this.canvasService.canvasWithCountry(this.CanvasId, this.country, true)
          : await this.canvasService.getCanvas(this.CanvasId, true);
      } catch (e) {
        throw new TooningGetCanvasError(e, null, true);
      }
      this.appVersion = environment.appVersion;

      if (this.getCanvas?.data.deleteStatus) {
        this.app.goExternalLocal('/canvas-share-error/delete');
      }

      this.url = `${environment.share_server_url}/canvas-share/${this.CanvasId}`;

      this.pages = this.getCanvas.data.pages;
      this.orientation = this.getCanvas.data.orientation;

      this.name = this.getCanvas.data.owner.userName;
      this.title = this.getCanvas.data.title;
      const getCanvasSize = JSON.parse(this.getCanvas.data.canvasSize);
      this.canvasSize = Math.ceil(getCanvasSize.w * getCanvasSize.web) + 'x' + Math.ceil(getCanvasSize.h * getCanvasSize.web) + 'px';
      this.authorization = this.getCanvas.data.authorization;

      this.readyToRender = true;
      await this.app.delay(200);
      this.viewDivH = this.viewDiv.nativeElement.offsetHeight;

      //프리젠테이션 모드 이면 바로 실행
      if (this.orientation === 'presentation' && this.isModal) {
        this.toggleFullScreen();
      }

      if (this.pages && this.pages.length > 0 && this.pages[0].base64) {
        this.metaService.updateTag({
          property: 'og:description',
          content: this.title
        });
        this.metaService.updateTag({
          property: 'twitter:description',
          content: this.title
        });

        this.metaService.updateTag({
          property: 'og:image',
          content: this.pages[0].base64
        });
      }
    } catch (e) {
      this.app.goExternalLocal('/canvas-share-error/not-exists');
      throw new TooningCanvasShareIonViewDidEnterError(e, null, true);
    }
  }

  // randomBackground(imgPreview: string) {
  //     let color = '#';
  //     color += this.letters[Math.floor(Math.random() * this.letters.length)];
  //     document.getElementById(imgPreview).style.background = color;
  // }

  // async ionImgWillLoad(img: any) {
  //     const animation: Animation = this.animationCtrl.create()
  //         .addElement(document.getElementById(img))
  //         .duration(1000)
  //         .fromTo('opacity', '0', '1');
  //     await animation.play();
  //
  // }

  async viewHeightChanged() {
    this.viewDivH = this.viewDiv.nativeElement.offsetHeight;
    if (this.orientation === 'horizontal') {
      this.slidesIndex = await this.slides.getActiveIndex();
    }
  }

  async copied(event) {
    await this.app.showToast(this.translate.instant('COMPLETE_COPY'), 300);
  }

  async cloneCanvasAmin() {
    try {
      let fetchData;
      if (this.country) {
        fetchData = await this.canvasService.canvasCloneWithTargetRegion(+this.CanvasId, this.app.cache.user.id, false, this.country);
      } else {
        fetchData = await this.canvasService.canvasClone(+this.CanvasId, +this.app.cache.user.id, false);
      }
      const data = fetchData.data;
      if (data.result) {
        this.app.go(`/4cut-make-manual2/${+data.canvasId}`);
      }
      await this.app.showToast('관리자입니다. 무조건 복제합니다.', 3500);
    } catch (e) {
      this.app.go('tooning-landing-main');
      console.error(e);
      throw new Error(`canvas 복제 실패`);
    }
  }

  /**
   * canvas를 복제해주는 함수
   * @returns {Promise<void>}
   */
  async cloneCanvas(): Promise<void> {
    const loading = new Loading();
    try {
      await loading.showLoader();
      // 관리자면 무조건 클론
      if (this.app.modal !== null) {
        await this.modalCtrl.dismiss();
      }

      // 로그인이 안되있으면..
      if (!this.app.user.isTokenSaved()) {
        await this.openModalLogin();
        return;
      }
      // 관리자면 무조건 복제
      if (this.app.isAdmin || this.app.cache.user.role === UserRole.template) {
        await this.cloneCanvasAmin();
        return;
      }
      if (this.isCloneTemplateClicking) {
        return;
      }
      this.isCloneTemplateClicking = true;
      if (!this.app.isPaidMember) {
        // 무료 사용자면 캔버스 수 체크
        let { data } = await this.canvasService.canvasesCount(+this.app.cache.user.id).toPromise();
        let getCanvasesLength: number = data;

        if (getCanvasesLength >= this.cut.freeUserCanvasLimit) {
          // 무료 사용자 캔버스 초과
          const dialogRef = this.dialog.open(PaidReferralComponent, {
            width: this.app.isDesktopWidth() ? this.cut.paidPopupWidthForDesktop : this.cut.paidPopupWidthForMobile,
            height: this.app.isDesktopWidth() ? this.cut.paidPopupHeightForDesktop : this.cut.paidPopupHeightForMobile,
            data: {
              message: this.translate.instant('3')
            }
          });

          dialogRef.afterClosed().subscribe(() => {
            this.isCloneTemplateClicking = false;
          });
          return;
        } else if (this.pages.length > this.cut.freeUserPageLimit) {
          // 무료 사용자 페이지 초과
          const dialogRef = this.dialog.open(PaidReferralComponent, {
            width: this.app.isDesktopWidth() ? this.cut.paidPopupWidthForDesktop : this.cut.paidPopupWidthForMobile,
            height: this.app.isDesktopWidth() ? this.cut.paidPopupHeightForDesktop : this.cut.paidPopupHeightForMobile,
            data: {
              message: this.translate.instant('2')
            }
          });

          dialogRef.afterClosed().subscribe(() => {
            this.isCloneTemplateClicking = false;
          });
          return;
        }
      }

      if (this.getCanvas.data.authorization !== 'clone') {
        await this.app.showToast(this.translate.instant('LIMIT_COPY_COMMENT'), 3500);
        return;
      }
      try {
        let fetchData;
        if (this.country) {
          fetchData = await this.canvasService.canvasCloneWithTargetRegion(+this.CanvasId, this.app.cache.user.id, false, this.country);
        } else {
          fetchData = await this.canvasService.canvasClone(+this.CanvasId, +this.app.cache.user.id, false);
        }
        const data = fetchData.data;
        if (data.result) {
          if (this.app.modal !== null) {
            await this.modalCtrl.dismiss();
          }
          this.app.go(`/4cut-make-manual2/${+data.canvasId}`);
        }
      } catch (e) {
        this.app.go('/');
        throw new TooningCanvasShareAuthorCheckError(`canvas 복제 실패`);
      }
    } catch (e) {
      if (e instanceof TooningCanvasShareAuthorCheckError) {
        throw e;
      } else {
        throw new TooningApplyCanvasShareCloneCanvasError(e);
      }
    } finally {
      loading.hideLoader();
    }
  }

  async sharePopover(ev: any) {
    if (this.app.popover !== null) {
      return;
    }
    this.app.popover = true;
    this.app.popover = await this.popoverController.create({
      mode: 'md',
      component: PopoverShareComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
      componentProps: {
        canvasId: this.CanvasId,
        isModal: false
      }
    });
    this.app.popover.style.cssText = '--min-width: 100px; --max-width: 335px; --width : 335px;';
    this.app.popover.onDidDismiss().then(async (data) => {
      this.app.popover = null;
      const loading = new Loading();
      await loading.showLoader('');
      this.getCanvas = await this.canvasService.getCanvas(this.CanvasId, true);
      this.orientation = this.getCanvas.data.orientation;
      this.authorization = this.getCanvas.data.authorization;
      loading.hideLoader();
    });
    return await this.app.popover.present();
  }

  ionViewDidLeave() {
    super.ionViewDidLeave();
  }

  // 로그인 모달
  async openModalLogin() {
    if (this.app.modal !== null) {
      return;
    }
    this.app.modal = true;
    this.app.modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: {
        isModal: true
      },
      cssClass: 'modal-size-login'
    });

    this.app.modal.onDidDismiss().then(async ({ data }) => {
      this.app.modal = null;
      // 로그인 모달이 닫히고 이쪽으로 온다
      // 캐쉬에서 서버 정보를
      this.app.blueLog(`
            login;
            modal;
            dissmiss;
            handler : ${data}`);
    });
    return await this.app.modal.present();
  }

  /**
   * 전체 토글
   * @returns {any}
   */
  toggleFullScreen(): any {
    try {
      this.isControlView = false;
      const element = this.content.el;
      if (!document.fullscreenElement) {
        setTimeout(() => {
          this.scrollTop = 0;
          this.isControlView = true;
          this.slideSzoom = 100;
          setTimeout(() => {
            this.isControlView = false;
          }, 8000);
        }, 500);

        if (element.requestFullscreen) return element.requestFullscreen();
        if (element.webkitRequestFullscreen) return element.webkitRequestFullscreen();
        if (element.mozRequestFullScreen) return element.mozRequestFullScreen();
        if (element.msRequestFullscreen) return element.msRequestFullscreen();

        return;
      }
      if (document.exitFullscreen) return document.exitFullscreen();
      // @ts-ignore
      if (document.webkitCancelFullscreen) {
        // @ts-ignore
        return document.webkitCancelFullscreen();
      }
      // @ts-ignore
      if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
      // @ts-ignore
      if (document.msExitFullscreen) return document.msExitFullscreen();
    } catch (e) {
      throw new TooningShareFullScreenError(e);
    }
  }

  /**
   * 슬라이더 이벤트
   * @param event
   * @returns {Promise<void>}
   */
  async ionSlideDidChangeFn(event) {
    try {
      this.slidesIndex = await this.fullscreenSlides.getActiveIndex();
    } catch (e) {
      throw new TooningShareSlideDidChangeError(e);
    }
  }

  /**
   * 화면 줌 아웃
   */
  slideSzoomOut() {
    try {
      if (this.slideSzoom === 10) {
        return;
      }
      this.slideSzoom = this.slideSzoom - 10;
      const setSlideSzoom = this.slideSzoom / 100;
      // @ts-ignore
      this.fullscreenSlides.el.style.transform = 'scale(' + setSlideSzoom + ', ' + setSlideSzoom + ')';
    } catch (e) {
      throw new TooningSlideszoomOutError(e);
    }
  }

  /**
   * 화면 줌인
   */
  slideSzoomIn() {
    try {
      if (this.slideSzoom === 300) {
        return;
      }
      this.slideSzoom = this.slideSzoom + 10;
      const setSlideSzoom = this.slideSzoom / 100;
      // @ts-ignore
      this.fullscreenSlides.el.style.transform = 'scale(' + setSlideSzoom + ', ' + setSlideSzoom + ')';
    } catch (e) {
      throw new TooningSlideszoomInError(e);
    }
  }

  /**
   * 컨텐츠 스크롤 이벤트 처리
   * @param {CustomEvent<ScrollDetail>} ev
   */
  handleScroll(ev: CustomEvent<ScrollDetail>) {
    try {
      this.scrollTop = ev.detail.scrollTop;
    } catch (e) {
      throw new TooninghandleScrollError(e);
    }
  }
}
