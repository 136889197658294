export enum SupportedImageFormats {
  gif = 'gif',
  jpg = 'jpeg',
  jpeg = 'jpeg',
  png = 'png',
  bmp = 'bmp'
}

export enum ServerErrorCode {
  BOARD_USER_NOT_FOUND = 2005,
  USER_NOT_FOUND = 2006,
  BOARD_CREATE_LIMIT_REACHED_FREE_PERSONAL = 2014,
  BOARD_CAPACITY_LIMIT_REACHED = 2017
}
