import { TooningClientError } from './TooningErrors';

class MagicInitError extends TooningClientError {}

class MagicResizeCanvasError extends TooningClientError {}

class MagicCanvasMouseEventSetError extends TooningClientError {}

class MagicHammerEventSetError extends TooningClientError {}

class MagicObjectEventSetError extends TooningClientError {}

class MagicBackImageSetError extends TooningClientError {}

class MagicKeyDownError extends TooningClientError {}

class MagicKeyUpError extends TooningClientError {}

class MagicDidEnterError extends TooningClientError {}

class MagicDidLeaveError extends TooningClientError {}

class MagicResetCanvasError extends TooningClientError {}

class MagicColorChangeInDrawingError extends TooningClientError {}

class MagicColorChangeHexInDrawingError extends TooningClientError {}

class MagicBrushSettingError extends TooningClientError {}

class MagicResetBrushOptionsError extends TooningClientError {}

class MagicChangeBrushWidthError extends TooningClientError {}

class MagicChangeBrushIntensityError extends TooningClientError {}

class MagicInitCanvasZoomError extends TooningClientError {}

class MagicDestroyError extends TooningClientError {}

class MagicDrawingUndoError extends TooningClientError {}

class MagicDrawingRedoError extends TooningClientError {}

class MagicCanvasClearError extends TooningClientError {}

class MagicCanvasZoomError extends TooningClientError {}

class MagicCanvasModeChangeError extends TooningClientError {}

class MagicCanvasIsDraggingError extends TooningClientError {}

class MagicSetCursorError extends TooningClientError {}

class MagicSetZoomModeError extends TooningClientError {}

class MagicSetColorPickerError extends TooningClientError {}

class MagicSetImageForColorPickerError extends TooningClientError {}

class MagicReadPixelColorError extends TooningClientError {}

class MagicSetPixelColorError extends TooningClientError {}

class MagicOpenToolError extends TooningClientError {}

class MagicOptionInputError extends TooningClientError {}

class MagicSizeChangeError extends TooningClientError {}

class MagicSendRequestError extends TooningClientError {}

class MagicSetInputError extends TooningClientError {}

class MagicGeneratePromptNegativeError extends TooningClientError {}

class MagicFileChangeError extends TooningClientError {}

class MagicSendToBaseImageError extends TooningClientError {}

class MagicSendToControlImageError extends TooningClientError {}

class MagicSendToCanvasError extends TooningClientError {}

class MagicResetBaseImageError extends TooningClientError {}

class MagicResetControlImageError extends TooningClientError {}

class MagicGenerateImageError extends TooningClientError {}

class MagicResetHistoryError extends TooningClientError {}

class MagicExecuteAddCommandError extends TooningClientError {}

class MagicExecuteEraserCommandError extends TooningClientError {}

class MagicExecuteDrawingCommandError extends TooningClientError {}

class MagicSetApiTypeError extends TooningClientError {}

class MagicChangeModelError extends TooningClientError {}

class MagicCaptureImageError extends TooningClientError {}

class MagicOpen3DModalError extends TooningClientError {}

class MagicInitDrawingError extends TooningClientError {}

class MagicInitMaskingError extends TooningClientError {}

class MagicFinishDrawingError extends TooningClientError {}

class MagicDownloadMultiError extends TooningClientError {}

class MagicDownloadSingleError extends TooningClientError {}

class MagicInitDrawingCommandManagerError extends TooningClientError {}

class MagicLevelChangeError extends TooningClientError {}

class MagicImageSlidePrevError extends TooningClientError {}

class MagicImageSlideNextError extends TooningClientError {}

class MagicSlideChangeHandlerError extends TooningClientError {}

class MagicOpenDrawingToolError extends TooningClientError {}

class MagicSetOneViewModeError extends TooningClientError {}

class MagicSetKeywordError extends TooningClientError {}

class MagicMoreKeywordError extends TooningClientError {}

class MagicClearPromptError extends TooningClientError {}

class MagicBeginnerMoreViewAnimationError extends TooningClientError {}

class MagicRefreshImageError extends TooningClientError {}

class MagicSetMenuError extends TooningClientError {}

class MagicAddKeywordChipError extends TooningClientError {}

class MagicScrollBeginnerPromptError extends TooningClientError {}

class MagicPromptAreaFocusError extends TooningClientError {}

class MagicPromptAreaBlurError extends TooningClientError {}

class MagicLoadMoreImageError extends TooningClientError {}

class MagicLoadedImageError extends TooningClientError {}

class MagicCalculateImageFetchError extends TooningClientError {}

class MagicGetApiHealthError extends TooningClientError {}

class MagicAwakenApiError extends TooningClientError {}

class MagicSaveImageError extends TooningClientError {}

class MagicGroupSetupError extends TooningClientError {}

class MagicImageSetupError extends TooningClientError {}

class MagicNewGroupError extends TooningClientError {}

class MagicUpdateGroupError extends TooningClientError {}

class MagicDeleteGroupError extends TooningClientError {}

class MagicChangeGroupError extends TooningClientError {}

class MagicGetAllUserGroupError extends TooningClientError {}

class MagicGetRecentUserGroupError extends TooningClientError {}

class MagicUploadS3Error extends TooningClientError {}

class MagicDeleteImageError extends TooningClientError {}

class MagicGetImageByGroupError extends TooningClientError {}

class MagicGetImageByIdError extends TooningClientError {}

class MagicOpenEditGroupError extends TooningClientError {}

class MagicOpenAddGroupError extends TooningClientError {}

class MagicOpenDeleteGroupError extends TooningClientError {}

class MagicOpenSendToGroupError extends TooningClientError {}

class MagicSendToGroupError extends TooningClientError {}

class MagicAllCheckError extends TooningClientError {}

class MagicCheckIndeterminateError extends TooningClientError {}

class MagicSparkleEventSetupError extends TooningClientError {}

class MagicSparkleStartError extends TooningClientError {}

class MagicResizeAreaEventSetupError extends TooningClientError {}

class MagicResizeStartError extends TooningClientError {}

class MagicResizeMoveError extends TooningClientError {}

class MagicResizeEndError extends TooningClientError {}

class MagicAdminCategoryInitError extends TooningClientError {}

class MagicAdminCategoryNameEditError extends TooningClientError {}

class MagicAdminCategoryAddError extends TooningClientError {}

class MagicAdminCategoryDeleteError extends TooningClientError {}

class MagicAdminCategoryOrderUpdateError extends TooningClientError {}

class MagicAdminKeywordOrderUpdateError extends TooningClientError {}

class MagicAdminKeywordInitError extends TooningClientError {}

class MagicAdminExcelUploadError extends TooningClientError {}

class MagicAdminExcelRestError extends TooningClientError {}

class MagicAdminExcelDeleteError extends TooningClientError {}

class MagicAdminExcelAllDeleteError extends TooningClientError {}

class MagicAdminSegChangeError extends TooningClientError {}

class MagicAdminCheckIndeterminateError extends TooningClientError {}

class MagicAdminAllCheckError extends TooningClientError {}

class MagicAdminKeywordSelectError extends TooningClientError {}

class MagicAdminKeywordDeleteError extends TooningClientError {}

class MagicAdminKeywordUpdateError extends TooningClientError {}

class MagicAdminGetKeywordListError extends TooningClientError {}

class MagicAdminSaveKeywordListError extends TooningClientError {}

class Magic3dMouseupEventError extends TooningClientError {}

class Magic3dInitCanvasSizeError extends TooningClientError {}

class Magic3dSetCanvasSizeError extends TooningClientError {}

class Magic3dInitRendererError extends TooningClientError {}

class Magic3dInitGroundError extends TooningClientError {}

class Magic3dInitObjectControlError extends TooningClientError {}

class Magic3dInitCameraError extends TooningClientError {}

class Magic3dInitControlsError extends TooningClientError {}

class Magic3dInitLightsError extends TooningClientError {}

class Magic3dRenderError extends TooningClientError {}

class Magic3dResizeRendererToDisplaySizeError extends TooningClientError {}

class Magic3dApplyError extends TooningClientError {}

class Magic3dDestroy3dModalError extends TooningClientError {}

class Magic3dDisposeMaterialError extends TooningClientError {}

class Magic3dInitResourceArrayError extends TooningClientError {}

class Magic3dCheckFileError extends TooningClientError {}

class Magic3dAddCharacterError extends TooningClientError {}

class Magic3dAddBoneControlMeshError extends TooningClientError {}

class Magic3dChangeMaterialError extends TooningClientError {}

class Magic3dSelectAnimationError extends TooningClientError {}

class Magic3dLoadAnimationError extends TooningClientError {}

class Magic3dSelectObjectError extends TooningClientError {}

class Magic3dUnselectObjectError extends TooningClientError {}

class Magic3dAnimationSearchError extends TooningClientError {}

class Magic3dCheckAnimationPlayError extends TooningClientError {}

class Magic3dRangeIonChangeError extends TooningClientError {}

class Magic3dPlayAnimationError extends TooningClientError {}

class Magic3dCheck3dAdminError extends TooningClientError {}

class Magic3dCreateThumbnailError extends TooningClientError {}

class Magic3dDidEnterError extends TooningClientError {}

class Magic3dDeleteResourceError extends TooningClientError {}

class Magic3dDisposeObjectPropertiesError extends TooningClientError {}

class Magic3dSelectBoneControllerError extends TooningClientError {}

class Magic3dUnselectBoneControllerError extends TooningClientError {}

class Magic3dSetResourceEditModeError extends TooningClientError {}

class Magic3dInitOutlineRenderError extends TooningClientError {}

class MagicSendToExpertError extends TooningClientError {}

class MagicGoToSelectServiceError extends TooningClientError {}

class MagicGoExternalError extends TooningClientError {}

class MagicHomeButtonClickHandlerError extends TooningClientError {}

class MagicAddWindowEventsError extends TooningClientError {}

class MagicShowpPreventMagicEditorAlertError extends TooningClientError {}

class MagicGoToEditorError extends TooningClientError {}

class MagicPromptResetError extends TooningClientError {}

class MagicSetDefaultPromptError extends TooningClientError {}

class MagicRenderEventSetError extends TooningClientError {}

class Magic3dInitSceneError extends TooningClientError {}

class Magic3dDestroy3dAllError extends TooningClientError {}

class MagicSetBeginnerOptionsError extends TooningClientError {}

class MagicFileInputResetError extends TooningClientError {}

class MagicOpenStyleInfoError extends TooningClientError {}

class MagicShowDetailMagicStyleCharacterError extends TooningClientError {}

class MagicTranslatePromptError extends TooningClientError {}

class MagicLoadImageError extends TooningClientError {}

class MagicCheckImageNeedBlurError extends TooningClientError {}

class MagicEndMultipleGenerateModeError extends TooningClientError {}

class MagicSetBeforeCanvasError extends TooningClientError {}

class MagicsetCanvasSelectImageError extends TooningClientError {}

class MagicSetPreventCanvasRightClickError extends TooningClientError {}

class MagicAddReferenceError extends TooningClientError {}

class MagicAddOriginalImageError extends TooningClientError {}

class MagicSelectDeleteImageError extends TooningClientError {}

class MagicModifyImageError extends TooningClientError {}

class MagicAddOriginalObjsError extends TooningClientError {}

class MagicMoreStyleError extends TooningClientError {}

class MagicGenerationLoadingError extends TooningClientError {}

class MagicGenerationProgressBarError extends TooningClientError {}

class MagicDoPageMagicError extends TooningClientError {}

class MagicDoPageMagicInternalError extends TooningClientError {}

class MagicCreateMagicInitError extends TooningClientError {}

class MagicCreateMagicDestroyError extends TooningClientError {}

class MagicBatchGenerateInitError extends TooningClientError {}

class MagicBatchGenerateCloseError extends TooningClientError {}

class MagicApplyPromptError extends TooningClientError {}

class MagicSearchWordError extends TooningClientError {}

class MagicGetWordCountError extends TooningClientError {}

class MagicChangeWordError extends TooningClientError {}

class MagicChangeAllWordError extends TooningClientError {}

class MagicAddAllWordError extends TooningClientError {}

class MagicRemoveAllWordError extends TooningClientError {}

class MagicGetPageWordError extends TooningClientError {}

class MagicSetPageWordError extends TooningClientError {}

class MagicMinimizeDuplicatesError extends TooningClientError {}

class MagicGetAllListError extends TooningClientError {}

class MagicResetWordListError extends TooningClientError {}

class MagicEditPromptOpenError extends TooningClientError {}

class MagicDeleteSearchResultError extends TooningClientError {}

class MagicResetSearchResultError extends TooningClientError {}

class MagicDoAllPageMagicError extends TooningClientError {}

class MagicBatchGenerateDestroyError extends TooningClientError {}

class MagicGetModelListInternalError extends TooningClientError {}

class MagicGetOptionsInternalError extends TooningClientError {}

class MagicSetOptionsInternalError extends TooningClientError {}

class MagicTxt2imgInternalError extends TooningClientError {}

class MagicImg2imgInternalError extends TooningClientError {}

class MagicChangeModelInternalError extends TooningClientError {}

class MagicSetPortError extends TooningClientError {}

class MagicLoadResultImageError extends TooningClientError {}

class MagicCreateHeadersError extends TooningClientError {}

class MagicGoDetailPageError extends TooningClientError {}

class MagicOnSortHnalderError extends TooningClientError {}

class MagicSetMagicStylesError extends TooningClientError {}

class MagicSetPublishedIdsError extends TooningClientError {}

class MagicSetStyleDataError extends TooningClientError {}

class MagicOnFileSelectedError extends TooningClientError {}

class MagicGenerateThumbnailError extends TooningClientError {}

class MagicModalDismissError extends TooningClientError {}

class MagicModalPresentError extends TooningClientError {}

class MagicAddStyleError extends TooningClientError {}

class MagicUpdateStyleError extends TooningClientError {}

class MagicAddTagError extends TooningClientError {}

class MagicGetSaveMagicStyleError extends TooningClientError {}

class MagicGetUpdateMagicStyleError extends TooningClientError {}

class MagicSetControlStyleEmptyError extends TooningClientError {}

class MagicSetTagInputEmptyError extends TooningClientError {}

class MagicSetIndicesToDeleteError extends TooningClientError {}

class MagicViewModeChangeError extends TooningClientError {}

class MagicSelectedStylesDeleteError extends TooningClientError {}

class MagicFinishDeleteModeError extends TooningClientError {}

class MagicIsControlStyleEmptyError extends TooningClientError {}

class MagicBeginnerViewChangeHandlerError extends TooningClientError {}

class MagicSelectedKeywordChangeHandlerError extends TooningClientError {}

class MagicSelectModelHandlerError extends TooningClientError {}

class MagicGetNewShowedModelsError extends TooningClientError {}

class MagicAddMagicStyleError extends TooningClientError {}

class MagicModelClickHandlerError extends TooningClientError {}

class MagicOptionInputChangeBackgroundError extends TooningClientError {}

class MagicCheckServiceTypeError extends TooningClientError {}

export {
  MagicLoadMoreImageError,
  MagicLoadedImageError,
  MagicInitError,
  MagicResizeCanvasError,
  MagicCanvasMouseEventSetError,
  MagicHammerEventSetError,
  MagicObjectEventSetError,
  MagicBackImageSetError,
  MagicKeyDownError,
  MagicKeyUpError,
  MagicDidEnterError,
  MagicDidLeaveError,
  MagicResetCanvasError,
  MagicColorChangeInDrawingError,
  MagicColorChangeHexInDrawingError,
  MagicBrushSettingError,
  MagicResetBrushOptionsError,
  MagicChangeBrushWidthError,
  MagicChangeBrushIntensityError,
  MagicInitCanvasZoomError,
  MagicDestroyError,
  MagicDrawingUndoError,
  MagicDrawingRedoError,
  MagicCanvasClearError,
  MagicCanvasZoomError,
  MagicCanvasModeChangeError,
  MagicCanvasIsDraggingError,
  MagicSetCursorError,
  MagicSetZoomModeError,
  MagicSetColorPickerError,
  MagicSetImageForColorPickerError,
  MagicReadPixelColorError,
  MagicSetPixelColorError,
  MagicOpenToolError,
  MagicOptionInputError,
  MagicSizeChangeError,
  MagicSendRequestError,
  MagicSetInputError,
  MagicGeneratePromptNegativeError,
  MagicFileChangeError,
  MagicSendToBaseImageError,
  MagicSendToControlImageError,
  MagicSendToCanvasError,
  MagicResetBaseImageError,
  MagicResetControlImageError,
  MagicGenerateImageError,
  MagicResetHistoryError,
  MagicExecuteAddCommandError,
  MagicExecuteEraserCommandError,
  MagicExecuteDrawingCommandError,
  MagicSetApiTypeError,
  MagicChangeModelError,
  MagicCaptureImageError,
  MagicOpen3DModalError,
  MagicInitDrawingError,
  MagicInitMaskingError,
  MagicFinishDrawingError,
  MagicDownloadMultiError,
  MagicDownloadSingleError,
  MagicInitDrawingCommandManagerError,
  MagicLevelChangeError,
  MagicImageSlidePrevError,
  MagicImageSlideNextError,
  MagicSlideChangeHandlerError,
  MagicOpenDrawingToolError,
  MagicSetOneViewModeError,
  MagicSetKeywordError,
  MagicMoreKeywordError,
  MagicClearPromptError,
  MagicBeginnerMoreViewAnimationError,
  MagicRefreshImageError,
  MagicSetMenuError,
  MagicAddKeywordChipError,
  MagicScrollBeginnerPromptError,
  MagicPromptAreaFocusError,
  MagicPromptAreaBlurError,
  MagicCalculateImageFetchError,
  MagicGetApiHealthError,
  MagicAwakenApiError,
  MagicSaveImageError,
  MagicGroupSetupError,
  MagicImageSetupError,
  MagicNewGroupError,
  MagicUpdateGroupError,
  MagicDeleteGroupError,
  MagicChangeGroupError,
  MagicGetAllUserGroupError,
  MagicGetRecentUserGroupError,
  MagicUploadS3Error,
  MagicDeleteImageError,
  MagicGetImageByGroupError,
  MagicGetImageByIdError,
  MagicOpenEditGroupError,
  MagicOpenAddGroupError,
  MagicOpenDeleteGroupError,
  MagicOpenSendToGroupError,
  MagicSendToGroupError,
  MagicAllCheckError,
  MagicCheckIndeterminateError,
  MagicAdminCategoryInitError,
  MagicAdminCategoryNameEditError,
  MagicAdminCategoryAddError,
  MagicAdminCategoryDeleteError,
  MagicAdminCategoryOrderUpdateError,
  MagicAdminKeywordInitError,
  MagicAdminKeywordOrderUpdateError,
  MagicAdminExcelUploadError,
  MagicAdminExcelRestError,
  MagicAdminSegChangeError,
  MagicAdminCheckIndeterminateError,
  MagicAdminAllCheckError,
  MagicAdminKeywordSelectError,
  MagicAdminKeywordDeleteError,
  MagicAdminKeywordUpdateError,
  MagicAdminGetKeywordListError,
  MagicAdminSaveKeywordListError,
  MagicAdminExcelDeleteError,
  MagicAdminExcelAllDeleteError,
  Magic3dMouseupEventError,
  Magic3dInitCanvasSizeError,
  Magic3dSetCanvasSizeError,
  Magic3dInitRendererError,
  Magic3dInitGroundError,
  Magic3dInitObjectControlError,
  Magic3dInitCameraError,
  Magic3dInitControlsError,
  Magic3dInitLightsError,
  Magic3dRenderError,
  Magic3dResizeRendererToDisplaySizeError,
  Magic3dApplyError,
  Magic3dDestroy3dModalError,
  Magic3dDisposeMaterialError,
  Magic3dInitResourceArrayError,
  Magic3dCheckFileError,
  Magic3dAddCharacterError,
  Magic3dAddBoneControlMeshError,
  Magic3dChangeMaterialError,
  Magic3dSelectAnimationError,
  Magic3dLoadAnimationError,
  Magic3dSelectObjectError,
  Magic3dUnselectObjectError,
  Magic3dAnimationSearchError,
  Magic3dCheckAnimationPlayError,
  Magic3dRangeIonChangeError,
  Magic3dPlayAnimationError,
  Magic3dCheck3dAdminError,
  Magic3dCreateThumbnailError,
  Magic3dDidEnterError,
  Magic3dDeleteResourceError,
  Magic3dDisposeObjectPropertiesError,
  Magic3dSelectBoneControllerError,
  Magic3dUnselectBoneControllerError,
  Magic3dSetResourceEditModeError,
  Magic3dInitOutlineRenderError,
  MagicSparkleEventSetupError,
  MagicSparkleStartError,
  MagicResizeAreaEventSetupError,
  MagicResizeStartError,
  MagicResizeMoveError,
  MagicResizeEndError,
  MagicSendToExpertError,
  MagicGoToSelectServiceError,
  MagicGoExternalError,
  MagicHomeButtonClickHandlerError,
  MagicAddWindowEventsError,
  MagicShowpPreventMagicEditorAlertError,
  MagicGoToEditorError,
  MagicPromptResetError,
  MagicSetDefaultPromptError,
  MagicRenderEventSetError,
  Magic3dInitSceneError,
  Magic3dDestroy3dAllError,
  MagicSetBeginnerOptionsError,
  MagicFileInputResetError,
  MagicOpenStyleInfoError,
  MagicShowDetailMagicStyleCharacterError,
  MagicTranslatePromptError,
  MagicLoadImageError,
  MagicCheckImageNeedBlurError,
  MagicEndMultipleGenerateModeError,
  MagicSetBeforeCanvasError,
  MagicsetCanvasSelectImageError,
  MagicSetPreventCanvasRightClickError,
  MagicAddReferenceError,
  MagicAddOriginalImageError,
  MagicSelectDeleteImageError,
  MagicModifyImageError,
  MagicAddOriginalObjsError,
  MagicMoreStyleError,
  MagicGenerationLoadingError,
  MagicGenerationProgressBarError,
  MagicDoPageMagicError,
  MagicDoPageMagicInternalError,
  MagicCreateMagicInitError,
  MagicCreateMagicDestroyError,
  MagicBatchGenerateInitError,
  MagicBatchGenerateCloseError,
  MagicApplyPromptError,
  MagicSearchWordError,
  MagicGetWordCountError,
  MagicChangeWordError,
  MagicChangeAllWordError,
  MagicAddAllWordError,
  MagicRemoveAllWordError,
  MagicGetPageWordError,
  MagicSetPageWordError,
  MagicMinimizeDuplicatesError,
  MagicGetAllListError,
  MagicResetWordListError,
  MagicEditPromptOpenError,
  MagicDeleteSearchResultError,
  MagicResetSearchResultError,
  MagicDoAllPageMagicError,
  MagicBatchGenerateDestroyError,
  MagicGetModelListInternalError,
  MagicGetOptionsInternalError,
  MagicSetOptionsInternalError,
  MagicTxt2imgInternalError,
  MagicImg2imgInternalError,
  MagicChangeModelInternalError,
  MagicSetPortError,
  MagicLoadResultImageError,
  MagicCreateHeadersError,
  MagicGoDetailPageError,
  MagicOnSortHnalderError,
  MagicSetMagicStylesError,
  MagicSetPublishedIdsError,
  MagicSetStyleDataError,
  MagicOnFileSelectedError,
  MagicGenerateThumbnailError,
  MagicModalDismissError,
  MagicModalPresentError,
  MagicAddStyleError,
  MagicUpdateStyleError,
  MagicAddTagError,
  MagicGetSaveMagicStyleError,
  MagicGetUpdateMagicStyleError,
  MagicSetControlStyleEmptyError,
  MagicSetTagInputEmptyError,
  MagicSetIndicesToDeleteError,
  MagicViewModeChangeError,
  MagicSelectedStylesDeleteError,
  MagicFinishDeleteModeError,
  MagicIsControlStyleEmptyError,
  MagicBeginnerViewChangeHandlerError,
  MagicSelectedKeywordChangeHandlerError,
  MagicSelectModelHandlerError,
  MagicGetNewShowedModelsError,
  MagicAddMagicStyleError,
  MagicModelClickHandlerError,
  MagicOptionInputChangeBackgroundError,
  MagicCheckServiceTypeError
};
