import { Injectable } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Cut4MakeManualService } from '../cut4-make-manual2/cut4-make-manual.service';
import { gql } from '@apollo/client';
import { map, tap, timeout } from 'rxjs/operators';
import { GraphqlApiService } from '../../services/api/graphql.api.service';
import { MagicUpload } from '../../model/magic/magicUpload';
import { FileService } from '../../services/file.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  GroupedKeywords,
  MagicApi,
  MagicGroup,
  MagicImage,
  MagicInputOptions,
  MagicKeyword,
  MagicParameters,
  MagicResponse,
  MagicRunpodApi,
  MagicSettingOptions,
  MagicStyleQueryResult
} from '../../interfaces/app.interface';
import { MagicExcelFile } from '../../model/magic/magicExcel';
import { MagicKeywordList } from '../../model/magic/magicKeyword';
import { MagicCategory } from '../../model/magic/magicCategory';
import {
  AiFilterType,
  BrushType,
  DownloadFormat,
  MagicApiProcess,
  MagicApiType,
  MagicCategoryList,
  MagicModel,
  MagicModelSub,
  MagicModelType,
  MIMEtype,
  ResourceType
} from '../../enum/app.enum';
import {
  MagicAddMagicStyleError,
  MagicAllCheckError,
  MagicAwakenApiError,
  MagicChangeGroupError,
  MagicChangeModelError,
  MagicChangeModelInternalError,
  MagicCheckIndeterminateError,
  MagicCreateHeadersError,
  MagicDeleteGroupError,
  MagicDeleteImageError,
  MagicDoAllPageMagicError,
  MagicDoPageMagicError,
  MagicDoPageMagicInternalError,
  MagicDownloadMultiError,
  MagicDownloadSingleError,
  MagicGetAllUserGroupError,
  MagicGetApiHealthError,
  MagicGetImageByGroupError,
  MagicGetImageByIdError,
  MagicGetModelListInternalError,
  MagicGetOptionsInternalError,
  MagicGetRecentUserGroupError,
  MagicGroupSetupError,
  MagicImageSetupError,
  MagicImg2imgInternalError,
  MagicLoadResultImageError,
  MagicNewGroupError,
  MagicOpenAddGroupError,
  MagicOpenDeleteGroupError,
  MagicOpenEditGroupError,
  MagicOpenStyleInfoError,
  MagicSelectDeleteImageError,
  MagicSendRequestError,
  MagicSendToGroupError,
  MagicSetDefaultPromptError,
  MagicSetOptionsInternalError,
  MagicSetPortError,
  MagicTxt2imgInternalError,
  MagicUpdateGroupError,
  MagicUploadS3Error
} from '../../pages-tooning/errors/MagicErrors';
import { AiFilterService } from '../../services/ai/ai-filter.service';
import { StyleInformationComponent } from './modal/style-information/style-information.component';
import { AnalyticsService } from '../../services/google/analytics/analytics.service';
import { TooningClientError } from '../../pages-tooning/errors/TooningErrors';
import * as JSZip from 'jszip';

const fabric = require('fabric').fabric;
const FILE_NAME: string = 'ai-drawing.service.ts';

@Injectable({
  providedIn: 'root'
})
export class AiDrawingService {
  public modelList: MagicApi[] = [];
  public showedModel: MagicApi[];
  public selectedModel: MagicApi;
  public modelInternalList = [];
  public loraInternalList = [];
  public vaeInternalList = [];
  public internalModel: string;
  public internalVae: string;
  public port: number = undefined;
  public apiKey = 'O8Y1G502ZO5O01FBAM6DJIXF1ULMIMMSMRF46GJE';
  public apiIdleTime: number = 100 * 1000;

  public defaultGroupTitle: string = this.translate.instant('magic.new group');
  public defaultGroupTitleInEduBefore = 'Magic Default Group (For Edu)';
  public defaultGroupTitleInEdu = 'Magic Default Group';
  public groupTitle: string = this.translate.instant('magic.new group');
  public currentUser: any;
  public currentGroup: MagicGroup;
  public groupList: MagicGroup[] = [];
  public imageList: any[] = [];
  public imageSkipTakeNum: number = 8;
  public popAlert: any = null;
  public recentSavedImageId: number;
  public isEndInfiniteScroll: boolean = false;
  public beginnerMainCategory: string = MagicCategoryList.character;
  public expertMainCategory: string = MagicCategoryList.generate;
  public mainCategory: string = MagicCategoryList.character;
  public subCategory: string = '';
  public selectedImageCount: number = 0;
  public isIndeterminate: boolean = false;
  public allSelected: boolean = false;
  public isImageLoading: boolean = false;
  public isOneViewMode: boolean = false;
  public selectedImgIndex: number = 1;
  public prompt: string = '';
  public beginnerPrompt: string = '';
  public internalURL: string = 'https://tooning.ngrok.app';
  public freeCount: number = 0;
  public userPoint: number = 0;
  public totalImageCount: number = 0;
  public readyImageCount: number = 0;
  public isCompressing: boolean = false;

  public isMagicBatch: boolean = false;
  public isRemBg: boolean = false;
  public defaultPromptList = [{ word: 'best quality', weight: 1.4 }];
  public defaultNegativeList = [
    { word: 'low quality', weight: 1.4 },
    {
      word: 'worst quality',
      weight: 1.4
    }
  ];
  public DEFAULT_NOT_ADULT = { word: 'nude', weight: 1.5 };
  public imageCount: number = 1;
  public multiplier: number = 1;
  public costList: number[] = [4, 7, 10, 12, 14];
  public disCountList: number[] = [0, 13, 17, 25, 30];
  public isAdult: boolean = false;
  public brush = BrushType.pencil;
  public isPressure: boolean = false;
  public generationLimit: number = 5;
  public downloadFormat: DownloadFormat = DownloadFormat.png;

  constructor(
    public app: AppService,
    public cut: Cut4MakeManualService,
    public graphql: GraphqlApiService,
    private fileService: FileService,
    public alertController: AlertController,
    private translate: TranslateService,
    public aiFilterService: AiFilterService,
    public analyticsService: AnalyticsService
  ) {}

  /**
   * 매직 그룹 세팅
   * @return {Promise<void>}
   */
  async groupSetUp(isFirst = false): Promise<void> {
    try {
      const userId = this.currentUser.id;
      this.groupList = await this.getAllUserGroupList(userId);
      if (isFirst) {
        const { data } = await this.getRecentUserMagicGroup(userId);
        this.currentGroup = data;
      }
    } catch (e) {
      throw new MagicGroupSetupError(e.message, null, true);
    }
  }

  /**
   * 해당 그룹 내 이미지 불러오기
   * @param {boolean} isFirst
   * @return {Promise<void>}
   */
  async imageSetUp(isFirst: boolean = false): Promise<void> {
    try {
      this.isImageLoading = true;
      this.isEndInfiniteScroll = false;
      const userId = this.currentUser.id;
      const groupId = this.currentGroup.id;
      const images = await this.getImageByGroup(isFirst ? 0 : this.imageList.length, this.imageSkipTakeNum, userId, groupId);
      for (const img of images) {
        img.isChecked = false;
      }

      this.imageList = isFirst ? images : this.imageList.concat(images);

      if (images.length < this.imageSkipTakeNum) this.isEndInfiniteScroll = true;
      this.isImageLoading = false;
    } catch (e) {
      throw new MagicImageSetupError(e.message, null, true);
    }
  }

  /**
   * 새 그룹 생성
   * @param {string} title
   * @return {Promise<void>}
   */
  async newGroup(title: string): Promise<void> {
    try {
      await this.magicNewGroup(title, this.currentUser.id);
    } catch (e) {
      throw new MagicNewGroupError(e.message, null, true);
    }
  }

  /**
   * 그룹명 변경
   * @param {number} groupId
   * @param {string} title
   * @return {Promise<void>}
   */
  async updateGroup(groupId: number, title: string): Promise<void> {
    try {
      await this.magicUpdateGroup(groupId, title);
    } catch (e) {
      throw new MagicUpdateGroupError(e.message, null, true);
    }
  }

  /**
   * 그룹 삭제
   * @param {number} groupId
   * @return {Promise<void>}
   */
  async deleteGroup(groupId: number): Promise<void> {
    try {
      await this.magicDeleteGroup(groupId);
    } catch (e) {
      throw new MagicDeleteGroupError(e.message, null, true);
    }
  }

  /**
   * 선택된 이미지 그룹 변경
   * @param {number} groupId
   * @return {Promise<void>}
   */
  async changeGroup(groupId: number): Promise<void> {
    try {
      this.currentGroup = this.groupList.filter((group) => group.id === groupId)[0];
      await this.imageSetUp(true);
      await this.getTotalNumberMagicImageByGroup(this.currentUser.id, this.currentGroup.id);
      this.selectedImageCount = 0;
      this.checkIndeterminate();
    } catch (e) {
      throw new MagicChangeGroupError(e.message, null, true);
    }
  }

  /**
   * 유저의 모든 매직 그룹 리스트를 가져옴
   * @param {number} userId
   * @return {Promise<MagicGroup[]>}
   */
  async getAllUserGroupList(userId: number): Promise<MagicGroup[]> {
    try {
      let result: MagicGroup[];
      const { data } = await this.getAllUserMagicGroup(userId);
      if (data.length === 0) {
        await this.newGroup(this.defaultGroupTitle);
        const { data } = await this.getAllUserMagicGroup(userId);
        result = data;
      } else {
        result = data;
      }
      return result;
    } catch (e) {
      throw new MagicGetAllUserGroupError(e.message, null, true);
    }
  }

  /**
   * 유저의 최근 수정한 매직 그룹을 가져옴
   * @param {number} userId
   * @return {Promise<MagicGroup>}
   */
  async getRecentUserGroupList(userId: number): Promise<MagicGroup> {
    try {
      const { data } = await this.getRecentUserMagicGroup(userId);
      return data;
    } catch (e) {
      throw new MagicGetRecentUserGroupError(e.message, null, true);
    }
  }

  /**
   * 생성된 이미지를 s3에 저장
   * @param {MagicParameters} data
   * @param {Blob} file
   * @param {boolean} isBlur
   * @return {Promise<void>}
   */
  async uploadS3(data: MagicParameters, file: Blob, isBlur: boolean = false): Promise<void> {
    try {
      const outputData = data;
      if (this.currentUser.id) {
        const userData = new MagicUpload();
        userData.userId = this.currentUser.id;
        userData.groupId = this.currentGroup.id;
        userData.parameter = JSON.stringify(outputData);
        userData.isBlur = isBlur;
        const { data } = await this.magicImageSave(userData, [file]);
        this.recentSavedImageId = +data;
      }
    } catch (e) {
      throw new MagicUploadS3Error(e.message, null, true);
    }
  }

  /**
   * 이미지 삭제 시, 확인 알람
   * @param {number} userId 유저 아이디
   * @param {boolean} isMulti  이미지 멀티 체크
   * @param {MagicImage} image 해당 이미지 정보
   * @return {Promise<void>}
   */
  async deleteImageConfirm(userId: number, isMulti: boolean, image?: MagicImage): Promise<void> {
    try {
      const imageArr = this.imageList.filter((img) => img.isChecked === true);
      if (imageArr.length === 0 && isMulti) {
        await this.app.showToast(this.translate.instant('magic.error.select delete image', 2000));
        throw new MagicSelectDeleteImageError('select delete image', null, true);
      } else {
        const alert = await this.alertController.create({
          header: this.translate.instant('magic.delete confirm.header'),
          message: this.translate.instant('magic.delete confirm.message'),
          cssClass: 'basic-dialog',
          buttons: [
            {
              text: this.translate.instant('magic.delete confirm.cancel'),
              role: 'cancel'
            },
            {
              text: this.translate.instant('magic.delete confirm.okay'),
              handler: async () => {
                await this.deleteImage(userId, isMulti, image);
              }
            }
          ]
        });
        await alert.present();
      }
    } catch (e) {
      throw new MagicDeleteImageError(e.message, null, true);
    }
  }

  /**
   * 일본 성인인증 팝업
   * @return {Promise<void>}
   */
  async adultVerificationRequest(): Promise<void> {
    try {
      const alert = await this.alertController.create({
        header: this.translate.instant('magic.adult-confirm.header'),
        message: this.translate.instant('magic.adult-confirm.message'),
        cssClass: 'basic-dialog',
        buttons: [
          {
            text: this.translate.instant('magic.adult-confirm.cancel'),
            role: 'cancel'
          },
          {
            text: this.translate.instant('magic.adult-confirm.confirm'),
            handler: async () => {
              this.app.goExternal('https://lbro1wiz3du.typeform.com/to/WPfr3sFH');
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      throw new TooningClientError(FILE_NAME + ' adultVerificationRequest ' + e.message, null, true);
    }
  }

  /**
   * 이미지 삭제, s3에서도 같이 날림
   * @param {number} userId
   * @param {boolean} isMulti
   * @param {MagicImage} image
   * @return {Promise<void>}
   */
  async deleteImage(userId: number, isMulti: boolean, image?: MagicImage): Promise<void> {
    try {
      let idList = [];
      if (isMulti) {
        const imageArr = this.imageList.filter((img) => img.isChecked === true);
        imageArr.forEach((image) => {
          idList.push(image.id);
        });
      } else {
        idList = [image.id];
      }
      await this.magicImageDelete(idList, userId);
      this.imageList = this.imageList.filter((img) => !idList.includes(img.id));

      if (!this.isEndInfiniteScroll && this.imageList.length === 0) {
        await this.imageSetUp(true);
      } else if (!this.isEndInfiniteScroll && this.imageList.length !== 0) {
        await this.imageSetUp();
      }

      this.checkIndeterminate();

      this.totalImageCount -= idList.length;

      this.analyticsService.magicDeleteImage(isMulti);
    } catch (e) {
      throw new MagicDeleteImageError(e.message, null, true);
    }
  }

  /**
   * 해당 그룹 내 이미지를 가져옴
   * @param {number} skip
   * @param {number} take
   * @param {number} userId
   * @param {number} groupId
   * @return {Promise<MagicImage[]>}
   */
  async getImageByGroup(skip: number, take: number, userId: number, groupId: number): Promise<MagicImage[]> {
    try {
      const { data } = await this.getMagicImageByGroup(skip, take, userId, groupId);
      if (data.length === 0) {
        return [];
      }

      if (!skip) {
        const initValue: MagicImage = {
          id: 0,
          generatedImage: '',
          parameter: {},
          groupId: 0
        };
        this.imageList = new Array(data.length).fill(initValue);
      }

      data.forEach((image) => {
        image.id = +image.id;
        image.parameter = JSON.parse(image.parameter);
        image.groupId = +this.currentGroup.id;
      });

      const imageListPromises: Promise<MagicImage>[] = data.map(async (image, i) => {
        return new Promise<MagicImage>((resolve) => {
          if (image.isBlur && !this.isAdult) {
            fabric.Image.fromURL(
              image.generatedImage,
              (img) => {
                const blurFilter = new fabric.Image.filters.Blur({ blur: 2 });
                img.filters.push(blurFilter);
                img.applyFilters();
                const url = img.toDataURL({ format: 'png' });
                const magicImage: MagicImage = {
                  id: +image.id,
                  generatedImage: url,
                  parameter: image.parameter,
                  groupId: +this.currentGroup.id,
                  isBlur: image.isBlur
                };
                resolve(magicImage);
              },
              {
                crossOrigin: 'anonymous'
              }
            );
          } else {
            resolve(image);
          }
        });
      });

      const images = await Promise.all(imageListPromises);
      return images;
    } catch (e) {
      throw new MagicGetImageByGroupError(e.message, null, true);
    }
  }

  /**
   * id로 이미지 한 장만 가져옴
   * @param {number} id
   * @return {Promise<MagicImage>}
   */
  async getImageById(id: number): Promise<MagicImage> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.getMagicImageById(id);
        let image: MagicImage;
        image = data;
        if (image.isBlur && !this.isAdult) {
          fabric.Image.fromURL(
            image.generatedImage,
            (img) => {
              const blurFilter = new fabric.Image.filters.Blur({ blur: 2 });
              img.filters.push(blurFilter);
              img.applyFilters();
              const url = img.toDataURL({ format: 'png' });
              const magicImage: MagicImage = {
                id: +image.id,
                generatedImage: url,
                parameter: image.parameter,
                groupId: +this.currentGroup.id,
                isBlur: image.isBlur
              };
              resolve(magicImage);
            },
            {
              crossOrigin: 'anonymous'
            }
          );
        } else {
          resolve(image);
        }
      } catch (e) {
        reject(new MagicGetImageByIdError(e.message, null, true));
      }
    });
  }

  /**
   * 그룹명 변경 alert 오픈
   * @param event
   * @param {MagicGroup} group
   * @return {Promise<void>}
   */
  async openEditGroup(event, group: MagicGroup): Promise<void> {
    try {
      if (!group) return;

      event.preventDefault();
      event.stopPropagation();

      if (this.popAlert !== null) {
        return;
      }
      this.popAlert = await this.alertController.create({
        cssClass: 'basic-dialog',
        mode: 'md',
        header: this.translate.instant('magic.edit group'),
        inputs: [
          {
            name: 'name',
            type: 'text',
            id: 'name',
            value: group.title,
            placeholder: group.title
          }
        ],
        buttons: [
          {
            text: this.translate.instant('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: this.translate.instant('apply'),
            handler: async (data) => {
              const existTitle = this.groupList.find((group) => group.title === data.name);
              if (existTitle) {
                await this.app.showToast(this.translate.instant('magic.already exist group'));
                return false;
              } else if (data.name === this.defaultGroupTitleInEdu || data.title === this.defaultGroupTitleInEduBefore) {
                await this.app.showToast(this.translate.instant('magic.not available group title'));
                return false;
              } else if (data.name === '') {
                await this.app.showToast(this.translate.instant('magic.no group name'));
                return false;
              }
              this.groupTitle = data.name;
              const current = this.currentGroup.id === group.id;
              await this.updateGroup(group.id, this.groupTitle);
              await this.groupSetUp(current);
              console.log(data.name);
            }
          }
        ]
      });
      this.popAlert.onDidDismiss().then((data) => {
        this.popAlert = null;
      });
      this.popAlert.present().then(async () => {
        // tslint:disable-next-line:only-arrow-functions
        document.getElementById('name').onfocus = (ev) => {
          // @ts-ignore
          ev.target.select();
        };
        await this.app.delay(100);
        document.getElementById('name').focus();
      });
      return;
    } catch (e) {
      throw new MagicOpenEditGroupError(e.message, null, true);
    }
  }

  /**
   * 그룹 추가 alert 오픈
   * @param event
   * @return {Promise<void>}
   */
  async openAddGroup(event): Promise<void> {
    try {
      event.preventDefault();
      event.stopPropagation();

      if (this.popAlert !== null) {
        return;
      }
      this.popAlert = await this.alertController.create({
        cssClass: 'basic-dialog',
        mode: 'md',
        header: this.translate.instant('magic.add group'),
        inputs: [
          {
            name: 'name',
            type: 'text',
            id: 'name',
            value: this.defaultGroupTitle,
            placeholder: this.defaultGroupTitle
          }
        ],
        buttons: [
          {
            text: this.translate.instant('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: this.translate.instant('apply'),
            handler: async (data) => {
              const existTitle = this.groupList.find((group) => group.title === data.name);
              if (existTitle) {
                await this.app.showToast(this.translate.instant('magic.already exist group'));
                return false;
              } else if (data.name === this.defaultGroupTitleInEdu || data.title === this.defaultGroupTitleInEduBefore) {
                await this.app.showToast(this.translate.instant('magic.not available group title'));
                return false;
              } else if (data.name === '') {
                await this.app.showToast(this.translate.instant('magic.no group name'));
                return false;
              }
              this.groupTitle = data.name;
              await this.newGroup(this.groupTitle);
              await this.groupSetUp();
              console.log(data.name);
              this.analyticsService.magicAddGroup();
            }
          }
        ]
      });
      this.popAlert.onDidDismiss().then((data) => {
        this.popAlert = null;
      });
      this.popAlert.present().then(async () => {
        // tslint:disable-next-line:only-arrow-functions
        document.getElementById('name').onfocus = (ev) => {
          // @ts-ignore
          ev.target.select();
        };
        await this.app.delay(100);
        document.getElementById('name').focus();
      });
      return;
    } catch (e) {
      throw new MagicOpenAddGroupError(e.message, null, true);
    }
  }

  /**
   * 그룹 삭제 alert 오픈
   * @param event
   * @param {MagicGroup} group
   * @return {Promise<void>}
   */
  async openDeleteGroup(event, group: MagicGroup): Promise<void> {
    try {
      event.preventDefault();
      event.stopPropagation();

      if (group.title === this.defaultGroupTitleInEdu || group.title === this.defaultGroupTitleInEduBefore) {
        await this.app.showToast(this.translate.instant('magic.cannot delete group'));
        return;
      }

      if (this.popAlert !== null) {
        return;
      }
      this.popAlert = await this.alertController.create({
        cssClass: 'basic-dialog',
        header: this.translate.instant('magic.delete group'),
        message: this.translate.instant('magic.delete group message'),
        buttons: [
          {
            text: this.translate.instant('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: this.translate.instant('delete'),
            handler: async () => {
              const current = this.currentGroup.id === group.id;
              await this.deleteGroup(group.id);
              await this.groupSetUp(current);
              current && (await this.imageSetUp(true));
            }
          }
        ]
      });
      this.popAlert.onDidDismiss().then((data) => {
        this.popAlert = null;
      });
      this.popAlert.present();
      return;
    } catch (e) {
      throw new MagicOpenDeleteGroupError(e.message, null, true);
    }
  }

  /**
   * 선택된 이미지를 해당 그룹으로 이동
   * @param {number} groupId
   * @return {Promise<void>}
   */
  async sendToGroup(groupId: number): Promise<void> {
    try {
      let idList = [];
      if (!this.isOneViewMode) {
        const imageArr = this.imageList.filter((img) => img.isChecked === true);
        imageArr.forEach((image) => {
          idList.push(image.id);
        });
      } else {
        idList = [this.imageList[this.selectedImgIndex - 1].id];
      }
      await this.magicSendToGroup(idList, groupId);
      this.imageList = this.imageList.filter((img) => !idList.includes(img.id));
      this.checkIndeterminate();
    } catch (e) {
      throw new MagicSendToGroupError(e.message, null, true);
    }
  }

  /**
   * 이미지 전체 선택 / 해제
   * @return {void}
   */
  allCheck(): void {
    try {
      setTimeout(() => {
        this.imageList.forEach((image) => {
          image.isChecked = this.allSelected;
        });
        if (this.allSelected) {
          this.selectedImageCount = this.imageList.length;
        } else {
          this.selectedImageCount = 0;
        }
      });
    } catch (e) {
      throw new MagicAllCheckError(e.message, null, true);
    }
  }

  /**
   * 이미지 중에서 일부 선택된 것이 있는지 체크
   * @return {void}
   */
  checkIndeterminate(): void {
    try {
      const totalItems = this.imageList.length;
      let checked = 0;
      this.imageList.map((image) => {
        if (image.isChecked) checked++;
      });
      if (checked > 0 && checked < totalItems) {
        //If even one item is checked but not all
        this.isIndeterminate = true;
        this.allSelected = false;
      } else if (checked === totalItems && totalItems !== 0) {
        //If all are checked
        this.allSelected = true;
        this.isIndeterminate = false;
      } else {
        //If none is checked
        this.isIndeterminate = false;
        this.allSelected = false;
      }
      const imageArr = this.imageList.filter((img) => img.isChecked === true);
      this.selectedImageCount = imageArr.length;
    } catch (e) {
      throw new MagicCheckIndeterminateError(e.message, null, true);
    }
  }

  /**
   * 스타일 정보 팝오버 열기
   * @param {Event} $event
   * @param {MagicStyleInfomation | undefined} modelInformation 스타일 정보 데이터
   * @return {Promise<void>}
   */
  async openStyleInfo($event: Event, model: MagicApi | undefined, popoverController: any): Promise<void> {
    try {
      $event.stopPropagation();

      if (this.app.popover !== null) {
        return;
      }

      if (!model.information) {
        return;
      }

      this.cut.setKeyActivation = false;
      this.app.popover = await popoverController.create({
        mode: 'md',
        component: StyleInformationComponent,
        event: $event,
        translucent: true,
        showBackdrop: false,
        componentProps: {
          data: model,
          type: 'popover'
        }
      });
      this.app.popover.style.cssText = '--min-width: 100px; --max-width: 335px; --width : 335px;';
      this.app.popover.onDidDismiss().then(async (data) => {
        this.app.popover = null;
        this.cut.setKeyActivation = true;
      });
      await this.app.popover.present();
    } catch (e) {
      throw new MagicOpenStyleInfoError(e.message, null, true);
    }
  }

  magicNewGroup(title: string, userId: number): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicNewGroup($title: String!, $userId: ID!) {
            magicNewGroup(title: $title, userId: $userId)
          }
        `,
        {
          title,
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicNewGroup;
          return results;
        })
      )
      .toPromise();
  }

  magicUpdateGroup(groupId: number, afterTitle: string): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicUpdateGroup($groupId: ID!, $afterTitle: String!) {
            magicUpdateGroup(groupId: $groupId, afterTitle: $afterTitle)
          }
        `,
        {
          groupId,
          afterTitle
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicUpdateGroup;
          return results;
        })
      )
      .toPromise();
  }

  magicDeleteGroup(groupId: number): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicDeleteGroup($groupId: ID!) {
            magicDeleteGroup(groupId: $groupId)
          }
        `,
        {
          groupId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicDeleteGroup;
          return results;
        })
      )
      .toPromise();
  }

  getAllUserMagicGroup(userId: number): Promise<any> {
    return this.graphql
      .query(
        gql`
          query getAllUserMagicGroup($userId: ID!) {
            getAllUserMagicGroup(userId: $userId) {
              id
              title
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.getAllUserMagicGroup;
          return results;
        })
      )
      .toPromise();
  }

  getRecentUserMagicGroup(userId: number): Promise<any> {
    return this.graphql
      .query(
        gql`
          query getRecentUserMagicGroup($userId: ID!) {
            getRecentUserMagicGroup(userId: $userId) {
              id
              title
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.getRecentUserMagicGroup;
          return results;
        })
      )
      .toPromise();
  }

  magicImageGenerate(apiType: string, parameter: any): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicImageGenerate($apiType: String!, $parameter: InputMagicParameter!) {
            magicImageGenerate(apiType: $apiType, parameter: $parameter) {
              base64
              seed
              finishReason
            }
          }
        `,
        {
          apiType,
          parameter
        }
      )
      .pipe(
        timeout(30000),
        map((results) => {
          results.data = results.data.magicImageGenerate;
          return results;
        })
      )
      .toPromise();
  }

  magicImageSave(data: MagicUpload, files: Blob[]): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicImageSave($data: InputMagic!, $files: [Upload!]!) {
            magicImageSave(data: $data, files: $files)
          }
        `,
        {
          data,
          files
        }
      )
      .pipe(
        timeout(10000),
        map((results) => {
          results.data = results.data.magicImageSave;
          return results;
        })
      )
      .toPromise();
  }

  magicImageDelete(idList: number[], userId: number): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicImageDelete($idList: [ID!]!, $userId: ID!) {
            magicImageDelete(idList: $idList, userId: $userId)
          }
        `,
        {
          idList,
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicImageDelete;
          return results;
        })
      )
      .toPromise();
  }

  getMagicImageById(id: number): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          query getMagicImageById($id: ID!) {
            getMagicImageById(id: $id) {
              id
              generatedImage
              parameter
              isBlur
              group {
                id
              }
            }
          }
        `,
        {
          id
        }
      )
      .pipe(
        timeout(5000),
        map((results) => {
          results.data = results.data.getMagicImageById;
          return results;
        })
      )
      .toPromise();
  }

  getTotalNumberMagicImageByGroup(userId: number, groupId: number): Promise<any> {
    return this.graphql
      .query(
        gql`
          query getTotalNumberMagicImageByGroup($userId: ID!, $groupId: ID!) {
            getTotalNumberMagicImageByGroup(userId: $userId, groupId: $groupId)
          }
        `,
        {
          userId,
          groupId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getTotalNumberMagicImageByGroup;
          return result;
        })
      )
      .toPromise()
      .then((result) => {
        this.totalImageCount = result.data;
        return result; // 반환된 값이 필요한 경우 반환할 수 있음
      });
  }

  getMagicImageByGroup(skip: number, take: number, userId: number, groupId: number): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          query getMagicImageByGroup($skip: Int!, $take: Int!, $userId: ID!, $groupId: ID!) {
            getMagicImageByGroup(skip: $skip, take: $take, userId: $userId, groupId: $groupId) {
              id
              generatedImage
              parameter
              isBlur
            }
          }
        `,
        {
          skip,
          take,
          userId,
          groupId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.getMagicImageByGroup;
          return results;
        })
      )
      .toPromise();
  }

  magicSendToGroup(idList: number[], groupId: number): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicSendToGroup($idList: [ID!]!, $groupId: ID!) {
            magicSendToGroup(idList: $idList, groupId: $groupId)
          }
        `,
        {
          idList,
          groupId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicSendToGroup;
          return results;
        })
      )
      .toPromise();
  }

  magicExcelUpload(data: MagicExcelFile, files: Blob[]): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicExcelUpload($data: InputMagicExcel!, $files: [Upload!]!) {
            magicExcelUpload(data: $data, files: $files)
          }
        `,
        {
          data,
          files
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicExcelUpload;
          return results;
        })
      )
      .toPromise();
  }

  getMagicExcelList(mainCategory: string, subCategory: string): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          query getMagicExcelList($mainCategory: String!, $subCategory: String!) {
            getMagicExcelList(mainCategory: $mainCategory, subCategory: $subCategory) {
              id
              filename
              s3
              author
              createdDate
              category {
                id
                maincategory
                subcategory
              }
            }
          }
        `,
        {
          mainCategory,
          subCategory
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.getMagicExcelList;
          return results;
        })
      )
      .toPromise();
  }

  magicExcelDelete(idList: number[]): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicExcelDelete($idList: [ID!]!) {
            magicExcelDelete(idList: $idList)
          }
        `,
        {
          idList
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicExcelDelete;
          return results;
        })
      )
      .toPromise();
  }

  magicUpdateAllKeyword(data: MagicKeywordList): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicUpdateAllKeyword($data: InputMagicKeywordList!) {
            magicUpdateAllKeyword(data: $data)
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicUpdateAllKeyword;
          return results;
        })
      )
      .toPromise();
  }

  getMagicKeywordList(mainCategory: string, subCategory: string): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          query getMagicKeywordList($mainCategory: String!, $subCategory: String!) {
            getMagicKeywordList(mainCategory: $mainCategory, subCategory: $subCategory) {
              id
              order
              en
              ko
              jp
              fr
              vn
              paid
              adult
              expose
            }
          }
        `,
        {
          mainCategory,
          subCategory
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.getMagicKeywordList;
          return results;
        })
      )
      .toPromise();
  }

  /**
   * 특정한 메인 카테고리의 키워드들을 가져온다.
   * @param {string} mainCategory 가져오길 원하는 메인 카테고리
   * @return {Promise<GroupedKeywords>}
   */
  getMagicAllKeyword(mainCategory: string): Promise<GroupedKeywords> {
    return this.graphql
      .mutate(
        gql`
          query getMagicAllKeyword($mainCategory: String!) {
            getMagicAllKeyword(mainCategory: $mainCategory) {
              id
              order
              en
              ko
              jp
              fr
              vn
              paid
              adult
              expose
              category {
                subcategory
              }
            }
          }
        `,
        {
          mainCategory
        }
      )
      .pipe(
        map((results) => {
          const keywords = results.data.getMagicAllKeyword;

          // 그룹화
          const groupedKeywords = keywords.reduce((result: GroupedKeywords, keyword: MagicKeyword): GroupedKeywords => {
            const subCategory = keyword.category.subcategory;

            if (!result[subCategory]) {
              result[subCategory] = [];
            }
            result[subCategory].push(keyword);
            return result;
          }, []);

          return groupedKeywords;
        })
      )
      .toPromise();
  }

  magicCategoryUpdate(data: MagicCategory): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicCategoryUpdate($data: InputMagicCategory!) {
            magicCategoryUpdate(data: $data)
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicCategoryUpdate;
          return results;
        })
      )
      .toPromise();
  }

  magicCategoryOrderUpdate(data: MagicCategory): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicCategoryOrderUpdate($data: InputMagicCategory!) {
            magicCategoryOrderUpdate(data: $data)
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicCategoryOrderUpdate;
          return results;
        })
      )
      .toPromise();
  }

  getMagicCategoryList(mainCategory: string): Promise<any> {
    return this.graphql
      .query(
        gql`
          query getMagicCategoryList($mainCategory: String!) {
            getMagicCategoryList(mainCategory: $mainCategory) {
              id
              order
              maincategory
              subcategory
            }
          }
        `,
        {
          mainCategory
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.getMagicCategoryList;
          return results;
        })
      )
      .toPromise();
  }

  magicCategoryDelete(data: MagicCategory): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation magicCategoryDelete($data: InputMagicCategory!) {
            magicCategoryDelete(data: $data)
          }
        `,
        {
          data
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.magicCategoryDelete;
          return results;
        })
      )
      .toPromise();
  }

  /**
   * 내부용 api의 모델 리스트를 가져온다
   * @param {string} type SD인지 Lora인지 vae인지 구분
   * @return {Promise<string[]>} 모델 목록
   */
  async getModelListInternal(type: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      try {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow'
        };

        fetch(`${this.internalURL}/get${type}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(new MagicGetModelListInternalError(error.message, null, true));
          });
      } catch (e) {
        throw new MagicGetModelListInternalError(e.message, null, true);
      }
    });
  }

  /**
   * 해당 포트의 api 세팅 정보를 가져온다
   * @param {number} port
   * @return {Promise<MagicSettingOptions>}
   */
  async getOptionsInternal(port: number): Promise<MagicSettingOptions> {
    return new Promise((resolve, reject) => {
      try {
        const myHeaders = this.createHeaders();

        const body = JSON.stringify({
          port: port
        });

        const requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: body,
          redirect: 'follow'
        };

        fetch(`${this.internalURL}/getOptions`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(new MagicGetOptionsInternalError(error.message, null, true));
          });
      } catch (e) {
        throw new MagicGetOptionsInternalError(e.message, null, true);
      }
    });
  }

  /**
   * 해당 포트의 webiui api의 세팅을 변경한다
   * @param {number} port
   * @param {MagicSettingOptions} options
   * @return {Promise<boolean>}
   */
  async setOptionsInternal(port: number, options: MagicSettingOptions): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const myHeaders = this.createHeaders();

        const body = JSON.stringify({
          port: port,
          input: options
        });

        const requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: body,
          redirect: 'follow'
        };

        fetch(`${this.internalURL}/setOptions`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            resolve(true);
          })
          .catch((error) => {
            reject(new MagicSetOptionsInternalError(error.message, null, true));
          });
      } catch (e) {
        throw new MagicSetOptionsInternalError(e.message, null, true);
      }
    });
  }

  /**
   * 내부 워크스테이션으로 txt2img 실행
   * @param {number} port
   * @param {MagicInputOptions} inputData
   * @return {Promise<MagicResponse>}
   */
  async txt2imgInternal(port: number, inputData: MagicInputOptions): Promise<MagicResponse> {
    return new Promise((resolve, reject) => {
      try {
        const myHeaders = this.createHeaders();

        const body = JSON.stringify({
          port: port,
          input: inputData.input
        });

        const requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: body,
          redirect: 'follow'
        };

        fetch(`${this.internalURL}/txt2img`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(new MagicTxt2imgInternalError(error.message, null, true));
          });
      } catch (e) {
        throw new MagicTxt2imgInternalError(e.message, null, true);
      }
    });
  }

  /**
   * 내부 워크스테이션으로 img2img 실행
   * @param {number} port
   * @param {MagicInputOptions} inputData
   * @return {Promise<MagicResponse>}
   */
  async img2imgInternal(port: number, inputData: MagicInputOptions): Promise<MagicResponse> {
    return new Promise((resolve, reject) => {
      try {
        const myHeaders = this.createHeaders();

        const body = JSON.stringify({
          port: port,
          input: inputData.input
        });

        const requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: body,
          redirect: 'follow'
        };

        fetch(`${this.internalURL}/img2img`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(new MagicImg2imgInternalError(error.message, null, true));
          });
      } catch (e) {
        throw new MagicImg2imgInternalError(e.message, null, true);
      }
    });
  }

  /**
   * 모델 세팅
   * @param {MagicApi} model
   * @return {Promise<void>}
   */
  async setModel(model: MagicApi): Promise<void> {
    try {
      if (!model || model.disabled) {
        return;
      }
      this.selectedModel = model;
      this.internalModel = undefined;
      this.internalVae = undefined;
      this.port = undefined;

      await this.getApiHealth(this.selectedModel);
      await this.awakenApi();
    } catch (e) {
      throw new MagicChangeModelError(e.message, null, true);
    }
  }

  /**
   * 내부 워크스테이션의 모델 세팅
   * @param {string} model
   * @param {string} type
   * @return {Promise<void>}
   */
  async setModelInternal(model: string, type: string): Promise<void> {
    try {
      if (!model) {
        return;
      }
      let options: MagicSettingOptions;
      if (type === MagicModelType.stableDiffusion) {
        options = {
          sd_model_checkpoint: model
        };
      } else if (type === MagicModelType.vae) {
        options = {
          sd_vae: model
        };
      }
      await this.setOptionsInternal(this.port, options);
      this.selectedModel = undefined;
    } catch (e) {
      throw new MagicChangeModelInternalError(e.message, null, true);
    }
  }

  /**
   * API worker 깨움
   * @return {Promise<void>}
   */
  async awakenApi(): Promise<void> {
    try {
      console.log('api 깨우기!!!!!');
      const endpoint = this.selectedModel.endpoint + MagicApiProcess.run;

      const myHeaders = this.createHeaders(true);

      const inputData = {
        input: {
          api_type: MagicApiType.txt2img,
          prompt: 'time to work!'
        }
      };

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(inputData),
        redirect: 'follow'
      };

      await fetch(`${endpoint}`, requestOptions)
        .then((response) => {
          if (response.ok) {
            console.log('success');
          } else {
            console.log('fail');
          }
        })
        .catch((error) => {
          throw new MagicAwakenApiError(error.message, null, true);
        });
    } catch (e) {
      throw new MagicAwakenApiError(e.message, null, true);
    }
  }

  /**
   * 현재 API 상태 체크
   * @param {MagicApi} model
   * @return {Promise<void>}
   */
  async getApiHealth(model: MagicApi): Promise<void> {
    try {
      const endpoint = this.selectedModel.endpoint + MagicApiProcess.health;

      const myHeaders = this.createHeaders(true);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      //@ts-ignore
      await fetch(`${endpoint}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.selectedModel.status = result;
        })
        .catch((error) => {
          throw new MagicGetApiHealthError(error.message, null, true);
        });
    } catch (e) {
      throw new MagicGetApiHealthError(e.message, null, true);
    }
  }

  /**
   * 포트 변경 시 옵션 세팅
   * @return {Promise<void>}
   */
  async setPort(): Promise<void> {
    try {
      const options = await this.getOptionsInternal(this.port);
      this.internalModel = options.sd_model_checkpoint;
      this.internalVae = options.sd_vae;
      await this.setModelInternal(this.internalModel, MagicModelType.stableDiffusion);
      await this.setModelInternal(this.internalVae, MagicModelType.vae);
    } catch (e) {
      throw new MagicSetPortError(e.message, null, true);
    }
  }

  /**
   * 현재 페이지를 매직으로 이미지 변환
   * @return {Promise<fabric.Image>}
   */
  async doPageMagic(): Promise<fabric.Image[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = this.selectedModel.endpoint + MagicApiProcess.runsync;
        let inputData: any;
        const myHeaders = this.createHeaders(true);
        const magicInfo = this.cut.pageList[this.cut.panelIndex].ai.magicInfo.input;
        inputData = {
          input: {
            api_type: MagicApiType.img2img,
            init_images: [this.cut.capturePng()],
            prompt: magicInfo.prompt,
            negative_prompt: magicInfo.negative_prompt,
            batch_size: this.imageCount,
            width: this.cut.canvasSize.w,
            height: this.cut.canvasSize.h,
            steps: magicInfo.steps,
            cfg_scale: magicInfo.cfg_scale,
            seed: magicInfo.seed,
            denoising_strength: magicInfo.denoising_strength,
            override_settings: { sd_vae: 'vae-ft-mse-840000-ema-pruned.safetensors' }
          }
        };

        const defaultPromptSet = this.setDefaultPrompt(magicInfo.prompt, magicInfo.negative_prompt);
        inputData.input.prompt = defaultPromptSet[0];
        inputData.input.negative_prompt = defaultPromptSet[1];

        const prompt = inputData.input.prompt;
        if (this.selectedModel.name === MagicModel.toon && this.selectedModel.subname === MagicModelSub.riley) {
          inputData.input.prompt = prompt + '  <lora:magic30Lora:0.4> <lora:toonRileyLora:0.5>';
        } else if (this.selectedModel.name === MagicModel.toon && this.selectedModel.subname === MagicModelSub.sera) {
          inputData.input.prompt = prompt + '  <lora:sera_sebastian:0.5>';
        } else if (this.selectedModel.name === MagicModel.lineart && this.selectedModel.subname === MagicModelSub.sera) {
          inputData.input.prompt = prompt + '  <lora:sera_sebastian:0.5> <lora:lineart2:0.5>';
        } else if (this.selectedModel.name === MagicModel.monochrome && this.selectedModel.subname === MagicModelSub.sera) {
          inputData.input.prompt = prompt + '  <lora:sera_sebastian:0.5> <lora:marvin:0.3>';
        } else if (this.selectedModel.name === MagicModel.toon && this.selectedModel.subname === MagicModelSub.yuna) {
          inputData.input.prompt = prompt + '  <lora:yuna_jiho_lora:0.5>';
        }

        const requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(inputData),
          redirect: 'follow'
        };

        await fetch(`${endpoint}`, requestOptions)
          .then((response) => response.json())
          .then(async (result) => {
            console.log(result);
            const promises: Promise<fabric.Image>[] = result.output.images.map(
              (imgUrl: string, index: number): Promise<fabric.Image> => this.loadResultImage(imgUrl, index)
            );
            Promise.all(promises).then(async (images) => {
              this.cut.canvas.requestRenderAll();
              await this.cut.updatePageSync(this.cut.panelIndex);
              inputData.input.prompt = magicInfo.prompt;
              inputData.input.negative_prompt = magicInfo.negative_prompt;
              this.cut.pageList[this.cut.panelIndex].ai.magicInfo = inputData;
              console.log(images);
              resolve(images);
            });
          })
          .catch(async (error) => {
            await this.app.showToast(this.translate.instant('magic.fetch fail'));
            reject(new MagicSendRequestError(error, null, true));
          });
      } catch (e) {
        throw new MagicDoPageMagicError(e.message, null, true);
      }
    });
  }

  /**
   * 내부 워크스테이션을 통해 현재 페이지를 이미지로 변환
   * @return {Promise<fabric.Image>}
   */
  async doPageMagicInternal(): Promise<fabric.Image[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let inputData: any;
        const magicInfo = this.cut.pageList[this.cut.panelIndex].ai.magicInfo.input;
        inputData = {
          input: {
            api_type: MagicApiType.img2img,
            init_images: [this.cut.capturePng()],
            prompt: magicInfo.prompt,
            negative_prompt: magicInfo.negative_prompt,
            batch_size: this.imageCount,
            width: this.cut.canvasSize.w * this.multiplier,
            height: this.cut.canvasSize.h * this.multiplier,
            steps: magicInfo.steps,
            cfg_scale: magicInfo.cfg_scale,
            seed: magicInfo.seed,
            denoising_strength: magicInfo.denoising_strength,
            override_settings: { sd_vae: 'vae-ft-mse-840000-ema-pruned.safetensors' }
          }
        };

        const defaultPromptSet = this.setDefaultPrompt(magicInfo.prompt, magicInfo.negative_prompt);
        inputData.input.prompt = defaultPromptSet[0];
        inputData.input.negative_prompt = defaultPromptSet[1];

        const result = await this.img2imgInternal(this.port, inputData);

        console.log(result);
        const promises: Promise<fabric.Image>[] = result.images.map(
          (imgUrl: string, index: number): Promise<fabric.Image> => this.loadResultImage(imgUrl, index)
        );
        Promise.all(promises).then(async (images) => {
          this.cut.canvas.requestRenderAll();
          await this.cut.updatePageSync(this.cut.panelIndex);
          inputData.input.prompt = magicInfo.prompt;
          inputData.input.negative_prompt = magicInfo.negative_prompt;
          this.cut.pageList[this.cut.panelIndex].ai.magicInfo = inputData;
          console.log(images);
          resolve(images);
        });
      } catch (e) {
        reject(new MagicDoPageMagicInternalError(e.message, null, true));
      }
    });
  }

  /**
   * 캔버스 내 모든 페이지를 돌면서 이미지 변환
   * @return {Promise<void>}
   */
  async doAllMagic(): Promise<void> {
    try {
      for (let i = 0; i < this.cut.pageList.length; i++) {
        await this.cut.goPage(i, false, '', false, false);
        this.selectedModel ? await this.doPageMagic() : await this.doPageMagicInternal();
      }
    } catch (e) {
      throw new MagicDoAllPageMagicError(e.message, null, true);
    }
  }

  /**
   * 프롬프트에 기본적인 단어 세팅
   * @param {string} prompt
   * @param {string} negative
   * @return {string[]}
   */
  setDefaultPrompt(prompt: string, negative: string): string[] {
    try {
      let result = [];

      this.defaultPromptList.forEach((data) => {
        if (!prompt.includes(data.word)) {
          prompt = prompt + `,(${data.word}:${data.weight})`;
        }
      });

      this.defaultNegativeList.forEach((data) => {
        if (!negative.includes(data.word)) {
          negative = negative + `,(${data.word}:${data.weight})`;
        }
      });

      result.push(prompt);
      result.push(negative);
      return result;
    } catch (e) {
      throw new MagicSetDefaultPromptError(e.message, null, true);
    }
  }

  /**
   * 생성된 이미지를 캔버스에 추가
   * @param {string} imageUrl
   * @return {Promise<Image>}
   */
  loadResultImage(imageUrl: string, index: number = undefined): Promise<fabric.Image> {
    return new Promise(async (resolve, reject) => {
      try {
        let imgBase64 = 'data:image/png;base64,' + imageUrl;
        if (this.isRemBg) {
          let { data } = await this.aiFilterService.aiFilter(imgBase64, AiFilterType.removeBackgroundAnime);
          imgBase64 = data;
        }

        fabric.Image.fromURL(imgBase64, async (img) => {
          img.set(
            {
              originX: 'center',
              originY: 'center',
              top: 540,
              left: 540
            },
            {
              crossOrigin: 'anonymous'
            }
          );

          // @ts-ignore
          img.set('resource_type', ResourceType.item);
          // @ts-ignore
          img.set('resource_name', 'magic-generated-image');
          if (!index) {
            const objs = this.cut.canvas
              .getObjects()
              .filter(
                (obj) =>
                  obj.resource_type !== ResourceType.bgColor &&
                  obj.resource_type !== ResourceType.guideMask &&
                  obj.resource_name !== 'magic-generated-image'
              );
            let objList = [];
            objs.forEach((obj) => {
              objList.push(JSON.stringify(obj.toDatalessObject()));
            });
            //@ts-ignore
            img.original_object = objList;
            for (const obj of objs) {
              this.cut.canvas.remove(obj);
            }
          } else {
            const objs = this.cut.canvas.getObjects().filter((obj) => obj.resource_name === 'magic-generated-image');
            //@ts-ignore
            img.original_object = objs[0].original_object;
          }
          this.cut.canvas.add(img);
          resolve(img);
        });
      } catch (e) {
        reject(new MagicLoadResultImageError(e.message, null, true));
      }
    });
  }

  /**
   * fetch request를 위한 헤더 생성
   * @param {boolean} api key가 필요한지 여부
   * @return {Headers}
   */
  createHeaders(isNeedKey: boolean = false): Headers {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      isNeedKey && myHeaders.append('Authorization', `Bearer ${this.apiKey}`);
      return myHeaders;
    } catch (e) {
      throw new MagicCreateHeadersError(e.message, null, true);
    }
  }

  /**
   * 매직 스타일 추가하기
   * @param {MagicApi} magicStyle
   * @return {Promise<MagicStyleQueryResult>}
   */
  async addMagicStyle(magicStyle: MagicApi): Promise<MagicStyleQueryResult> {
    try {
      return this.graphql
        .mutate(
          gql`
            mutation addMagicStyle($magicStyle: InputMagicStyle!) {
              addMagicStyle(magicStyle: $magicStyle) {
                id
                result
              }
            }
          `,
          {
            magicStyle
          }
        )
        .pipe(
          map((results) => {
            const result = results.data.addMagicStyle;
            delete result.__typename;

            return result;
          })
        )
        .toPromise();
    } catch (e) {
      throw new MagicAddMagicStyleError(e, this.app, true);
    }
  }

  /**
   * 전체 매직 스타일 가져오기
   * @return {Promise<MagicApi[]>}
   */
  async getAllMagicStyle(isOrderByDisabled: boolean = false): Promise<MagicApi[]> {
    try {
      return this.graphql
        .query(
          gql`
            query getAllMagicStyle($isOrderByDisabled: Boolean!) {
              getAllMagicStyle(isOrderByDisabled: $isOrderByDisabled) {
                id
                order
                disabled
                name
                subname
                thumbnail
                endpoint
                vae
                hasLora
                characterId
                information
              }
            }
          `,
          { isOrderByDisabled }
        )
        .pipe(
          map((results) => {
            const styles = results.data.getAllMagicStyle;

            const modifiedStyles = styles.reduce((result: [MagicApi], style) => {
              if (style.information) {
                style.information = JSON.parse(style.information);
                style.isEditorCharacter = true;
              } else style.isEditorCharacter = false;

              delete style.__typename;

              result.push(style);

              return result;
            }, []);

            return modifiedStyles;
          })
        )
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 매직 스타일 순서 변경하기
   * @param {number} targetId
   * @param {number} beforeIndex
   * @param {number} afterIndex
   * @return {Promise<MagicStyleQueryResult>}
   */
  async reorderStyles(targetId: number, beforeIndex: number, afterIndex: number): Promise<MagicStyleQueryResult> {
    try {
      return this.graphql
        .mutate(
          gql`
            mutation reorderStyles($targetId: Int!, $beforeIndex: Int!, $afterIndex: Int!) {
              reorderStyles(targetId: $targetId, beforeIndex: $beforeIndex, afterIndex: $afterIndex) {
                id
                result
              }
            }
          `,
          {
            targetId,
            beforeIndex,
            afterIndex
          }
        )
        .pipe(
          map((results) => {
            const result = results.data.reorderStyles;
            delete result.__typename;

            return results.data.reorderStyles;
          })
        )
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 선택한 매직 스타일 수정
   * @param {MagicApi} magicStyle 수정할 매직 스타일
   * @return {Promise<MagicStyleQueryResult>}
   */
  async setMagicStyle(magicStyle: MagicApi): Promise<MagicStyleQueryResult> {
    try {
      return this.graphql
        .mutate(
          gql`
            mutation setMagicStyle($magicStyle: InputMagicStyle!) {
              setMagicStyle(magicStyle: $magicStyle) {
                id
                result
              }
            }
          `,
          { magicStyle }
        )
        .pipe(
          map((results) => {
            const result = results.data.setMagicStyle;
            delete result.__typename;

            return result;
          })
        )
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 선택한 스타일 삭제
   * @param {number[]} indicesToDelete 선택한 스타일의 id가 모여있는 배열
   * @return {Promise<boolean>}
   */
  async deleteMagicStyle(indicesToDelete: number[]): Promise<boolean> {
    try {
      return this.graphql
        .mutate(
          gql`
            mutation deleteMagicStyle($indicesToDelete: [Int!]!) {
              deleteMagicStyle(indicesToDelete: $indicesToDelete)
            }
          `,
          { indicesToDelete }
        )
        .pipe(
          map((results) => {
            return results.data.deleteMagicStyle;
          })
        )
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 유저 ID를 기반으로 해당 유저의 남은 무료 횟수를 DB로부터 가져옴
   * @param {number} userId
   * @return {Promise<any>}
   */
  getMagicFreeCount(userId: number): Promise<number> {
    return this.graphql
      .query(
        gql`
          query getMagicFreeCount($userId: Int!) {
            getMagicFreeCount(userId: $userId) {
              magicFreeCount
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((result) => {
          const freeCount = result.data.getMagicFreeCount.magicFreeCount;
          return freeCount;
        })
      )
      .toPromise();
  }

  /**
   * 해당 유저의 무료 TTI 횟수를 지정한 숫자로 변경
   * @param {number} userId
   * @param {number} freeCount
   * @return {Promise<any>}
   */
  updateMagicFreeCount(userId: number, freeCount: number): Promise<{ result: boolean; message: string }> {
    return this.graphql
      .query(
        gql`
          mutation updateMagicFreeCount($userId: Int!, $freeCount: Float!) {
            updateMagicFreeCount(userId: $userId, freeCount: $freeCount) {
              result
              message
            }
          }
        `,
        {
          userId,
          freeCount
        }
      )
      .pipe(
        map((result) => {
          const updateResult = result.data.updateFreeCount;
          return updateResult;
        })
      )
      .toPromise();
  }

  /**
   * 전체 매직 스타일 런팟 가져오기
   * @return {Promise<MagicApi[]>}
   */
  async getAllMagicRunpod(): Promise<MagicRunpodApi[]> {
    try {
      return this.graphql
        .query(
          gql`
            query getAllMagicRunpod {
              getAllMagicRunpod {
                subname
                thumbnail
                endpoint
              }
            }
          `,
          {}
        )
        .pipe(
          map(async (results) => {
            const styles = results.data.getAllMagicRunpod;
            for (let style of styles) {
              style = await this.getRunpodInfo(style);
            }
            return styles;
          })
        )
        .toPromise();
    } catch (e) {
      throw new TooningClientError('ai-drawing.service.ts, getAllMagicRunpod()' + e, null, true);
    }
  }

  /**
   * 실제 런팟 정보 불러오기
   * @param {MagicRunpodApi} style
   * @return {Promise<MagicRunpodApi>}
   */
  async getRunpodInfo(style: MagicRunpodApi): Promise<MagicRunpodApi> {
    let endpoint = style.endpoint + MagicApiProcess.health;
    const myHeaders = this.createHeaders(true);
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    try {
      //@ts-ignore
      const res = await fetch(`${endpoint}`, requestOptions);
      const res2 = await res.json();
      style.workers = res2.workers.running;
      style.queued = res2.jobs.inQueue;
      style.inProgress = res2.jobs.inProgress;
      return style;
    } catch (e) {
      throw new TooningClientError('ai-drawing.service.ts, getRunpodInfo()' + e, null, true);
    }
  }

  /**
   * 여러 장의 이미지 선택 후 다운로드 시 zip 파일로 다운로드
   * @return {Promise<void>}
   */
  async downloadImagesWithZip(): Promise<void> {
    try {
      const imageArr = this.imageList.filter((img) => img.isChecked === true);
      if (!imageArr || imageArr.length === 0) {
        await this.app.showToast(this.translate.instant('magic.select images for download'), 1000);
        return;
      }

      // 이미지가 1장일 경우 1장만 다운되는 함수로 이동
      if (imageArr.length === 1) {
        await this.downloadImage(imageArr[0]);
        return;
      }

      const zip = new JSZip();
      for (let i = 0; i < imageArr.length; i++) {
        this.readyImageCount = i + 1;
        let dataUrl = imageArr[i].generatedImage;
        if (this.downloadFormat !== DownloadFormat.webp) {
          dataUrl = await this.convertWebp2Others(dataUrl, this.downloadFormat);
        }
        if (dataUrl.startsWith('http')) {
          const image = await fetch(dataUrl);
          const ext = image.headers.get('Content-type').split('/')[1];
          const blob = image.blob();
          zip.file(`image${i + 1}.${ext}`, blob);
        } else {
          let base64 = dataUrl.replace(/^data:image\/\w+;base64,/, '');
          const binaryString = window.atob(base64);
          const buffer = new ArrayBuffer(binaryString.length);
          const view = new Uint8Array(buffer);
          for (let j = 0; j < binaryString.length; j++) {
            view[j] = binaryString.charCodeAt(j);
          }
          zip.file(`image${i + 1}.png`, buffer);
        }
      }

      this.isCompressing = true;
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const blobUrl = URL.createObjectURL(zipBlob);

      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = 'images.zip';
      downloadLink.click();
      URL.revokeObjectURL(blobUrl);
      this.readyImageCount = 0;
      this.isCompressing = false;
      this.analyticsService.magicImageDownload('many');
    } catch (e) {
      throw new MagicDownloadMultiError(e.message, null, true);
    }
  }

  /**
   * selected 이미지 한장 다운로드
   * @param {MagicImage} image
   * @return {void}
   */
  async downloadImage(image: MagicImage): Promise<void> {
    try {
      if (!image) {
        await this.app.showToast(this.translate.instant('magic.select images for download'), 1000);
        return;
      }
      let dataUrl: string = image.generatedImage;
      if (this.downloadFormat !== DownloadFormat.webp) {
        dataUrl = await this.convertWebp2Others(dataUrl, this.downloadFormat);
      }

      const isB64 = dataUrl.startsWith('data');

      if (isB64) {
        let base64 = dataUrl.replace(/^data:image\/\w+;base64,/, '');
        const binaryString = window.atob(base64);
        const buffer = new ArrayBuffer(binaryString.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < binaryString.length; i++) {
          view[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([buffer], { type: 'image/png' });
        dataUrl = URL.createObjectURL(blob);
      }

      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'generated-image.png';
      downloadLink.click();
      isB64 && URL.revokeObjectURL(dataUrl);
      this.analyticsService.magicImageDownload('one');
    } catch (e) {
      throw new MagicDownloadSingleError(e.message, null, true);
    }
  }

  /**
   * webp로 저장된 파일을 가져와 png base64로 변경
   * @param {string} imageUrl
   * @return {Promise<string>}
   */
  async convertWebp2Others(imageUrl: string, format: DownloadFormat): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const ext = format === DownloadFormat.png ? MIMEtype.png : MIMEtype.jpg;
        const img = new Image();
        img.src = imageUrl;
        img.crossOrigin = 'anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0);
          resolve(canvas.toDataURL(ext));
        };
      } catch (e) {
        this.app.showToast('download failed, please retry later');
        reject(e);
      }
    });
  }

  /**
   * 매직(플러스아님) 디폴트 그룹 세팅, 존재하면 가져오고 없으면 생성함
   * @return {Promise<void>}
   */
  async defaultGroupSetupInEdu(): Promise<void> {
    const userId = this.currentUser.id;
    const { data } = await this.getAllUserMagicGroup(userId);
    const defaultGroup = data.filter((group) => group.title === this.defaultGroupTitleInEdu || group.title === this.defaultGroupTitleInEduBefore);
    if (defaultGroup.length === 0) {
      await this.newGroup(this.defaultGroupTitleInEdu);
      const { data } = await this.getRecentUserMagicGroup(userId);
      this.currentGroup = data;
    } else {
      this.currentGroup = defaultGroup[0];
    }
  }
}
