import { Component, OnInit } from '@angular/core';
import {LanguageType, ServiceType} from "../../../enum/app.enum";
import {AppService} from "../../../services/app.service";
import {Router} from "@angular/router";
import {AnalyticsService} from "../../../services/google/analytics/analytics.service";
import {environment} from "../../../../environments/environment";
import {MagicGoToSelectServiceError} from "../../errors/MagicErrors";

@Component({
  selector: 'app-service-teb-studio',
  templateUrl: './service-teb-studio.component.html',
  styleUrls: ['./service-teb-studio.component.scss'],
})
export class ServiceTebStudioComponent  implements OnInit {
  public serviceTypeEnum = ServiceType;
  public languageType = LanguageType;
  constructor(public app: AppService, public router: Router, public analyticsService: AnalyticsService) {}

  ngOnInit() {

  }

  goToSelectService(selectedService: string): void {
    try {
      switch (selectedService) {
        case ServiceType.Studio:
          this.app.go('/');
          break;
        case ServiceType.Editor:
          this.app.go('/tooning-editor');
          break;
        case ServiceType.Magic:
          this.app.go('magic');
          this.analyticsService.magicTabClick();
          break;
        case ServiceType.Gpt:
          this.app.go('gpt/editor');
          break;
        case ServiceType.Board:
          this.app.go('board');
          break;
          // 다른 선택 서비스에 대한 처리 추가
        case ServiceType.Character:
          // 다른 선택 서비스에 대한 처리 추가
          break;
        default:
          break;
      }
    } catch (e) {
      throw new MagicGoToSelectServiceError(e);
    }
  }


}
