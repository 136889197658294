import { Injectable, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { TooningChangeCanvasGropTitle } from '../../pages-tooning/errors/TooningErrors';
import { CanvasGroup } from '../../model/canvasGroup/canvasGroup';
import { CanvasGroupService } from '../../services/canvasGroup/canvas-group.service';

@Injectable({
  providedIn: 'root'
})
export class Cut4ListService {
  public canvasGroupName: string;
  public canvasGroupId: number;
  public canvasGroupDummyName: string[];
  public canvasGroupList: Array<any> = [];
  public canvasGroupIndex: number;
  public canvasGroupAlertInput: Array<any> = [];
  public isCanvasGroupView: boolean;
  public isTrashView: boolean;
  public addCanvasGroupName: string = '';
  public isTemplate = false;
  public isTextTemplate = false;
  public isMeme = false;

  constructor(
    public app: AppService,
    public canvasGroupService: CanvasGroupService,
    private router: Router,
    private _clipboardService: ClipboardService
  ) {}

  @ViewChild('modalAddCanvasGroup') modalAddCanvasGroup: ModalController;

  /**
   * 폴더 이름 변경
   * @param {any} modalChangeCanvasGroupName 폴더 이름변경 모달
   * @return {Promise<void>}
   */
  async canvasGroupTitleChange(modalChangeCanvasGroupName: any): Promise<void> {
    try {
      this.canvasGroupList[this.canvasGroupIndex].groupName = this.canvasGroupName; //UI적용
      this.canvasGroupAlertInput[this.canvasGroupIndex].label = this.canvasGroupName; //UI적용
      const newCanvasGroup = new CanvasGroup();
      newCanvasGroup.id = +this.canvasGroupId;
      newCanvasGroup.groupName = this.canvasGroupName;
      await this.canvasGroupService.canvasGroupRename(newCanvasGroup, this.canvasGroupName);
    } catch (e) {
      throw new TooningChangeCanvasGropTitle(e);
    } finally {
      await modalChangeCanvasGroupName.dismiss();
    }
  }
}
