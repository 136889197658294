export enum PanelUpdateTypeEnum {
  byClick = 'byClick',
  objectRemoved = 'object:removed',
  objectAdded = 'object:added',
  objectModified = 'object:modified',

  pinchEndRotatesEnd = 'pinchend rotatesend',

  drop = 'drop',

  historyRedo = 'history:redo',
  historyUndo = 'history:undo',
  historyAppend = 'history:append',
  historyClear = 'history:clear',

  mouseDown = 'mouse:down'
}
