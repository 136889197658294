import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { AlertController, IonSearchbar, MenuController, ModalController, PopoverController } from '@ionic/angular';
import { LoginPage } from '../../../pages-member/login/login.page';
import { Menu } from '../../enums/menu.enum';
import { Cut4MakeManualService } from '../../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { AnalyticsService } from '../../../services/google/analytics/analytics.service';
import { PaidGuideModalPage } from '../../../pages-common/paid-guide-modal/paid-guide-modal.page';
import { PopoverMenuAacountComponent } from '../popover/popover-menu-aacount/popover-menu-aacount.component';
import { TemplateService } from '../../../services/template/template.service';
import { environment } from '../../../../environments/environment';
import {
  Deployment,
  DomainType,
  GptInfoPage,
  GreekType,
  LanguageOriginalType,
  LanguageType,
  MagicEditorGoButton,
  ServiceType,
  UserRole
} from '../../../enum/app.enum';
import { PaymentService } from '../../../services/payment/payment.service';
import { TooningCustomClientError, TooningGoEditorError, TooningOpenModalLoginError } from '../../errors/TooningErrors';
import { MagicGoExternalError } from '../../errors/MagicErrors';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TooningPricePlanPage } from '../../tooning-price-plan/tooning-price-plan.page';

const STUDIO = ServiceType.Studio;
const EDITOR = ServiceType.Editor;
const MAGIC = ServiceType.Magic;
const WORLD = ServiceType.World;
const GPT = ServiceType.Gpt;
const BOARD = ServiceType.Board;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public DomainType = DomainType;
  public serviceTitles = [EDITOR, MAGIC, GPT, BOARD];
  public visibleServiceTitleByKo = [EDITOR, MAGIC];
  public languageEnum = LanguageType;
  public languageOriginalEnum = LanguageOriginalType;
  public serviceTypeEnum = ServiceType;
  public DomainTypeEnum = DomainType;
  public greekType = GreekType;
  public userRole = UserRole;
  public gptInfoPage = GptInfoPage;
  public popover;
  public readyToRender: boolean;
  public modal: HTMLIonModalElement;
  public message;
  public isIos = false;
  public isProduction = false;
  public isServiceMenu = false;
  public isServiceMenuOver = false;
  public isPriceMenu = false;
  public isPriceMenuOver = false;
  public isEnterpriseMenu = false;
  public isEnterpriseMenuOver = false;
  public isServiceSelectPopoverOpen: boolean = false;
  public titles = {
    studio: {
      ko: '스튜디오',
      en: 'Studio'
    },
    editor: {
      ko: '에디터',
      en: 'Editor'
    },
    magic: {
      ko: '매직',
      en: 'Magic'
    },
    gpt: {
      ko: 'GPT',
      en: 'GPT'
    },
    board: {
      ko: '보드',
      en: 'Board'
    }
  };

  constructor(
    public app: AppService,
    public popoverController: PopoverController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private menu: MenuController,
    public cut: Cut4MakeManualService,
    public analyticsService: AnalyticsService,
    public templateService: TemplateService,
    public paymentService: PaymentService,
    private elementRef: ElementRef,
    private translate: TranslateService,
    private router: Router
  ) {}

  /**
   * 햄버거 메뉴 열기
   */
  async openSideMenu() {
    this.isServiceSelectPopoverOpen = false;
    await this.menu.enable(true, 'tooningLanding');
    await this.menu.open('tooningLanding');
  }

  async ngOnInit() {
    if (environment.deploy === Deployment.oper) {
      this.isProduction = environment.production;
    }
    if (this.app.RootServiceType === DomainType.Io) {
      this.serviceTitles = [EDITOR, MAGIC, GPT, BOARD];
    } else if (this.app.RootServiceType === DomainType.Studio) {
      this.serviceTitles = [STUDIO];
    } else {
      this.serviceTitles = [EDITOR, MAGIC, GPT, BOARD];
    }
  }

  ngAfterViewInit() {
    try {
      this.cut.disableZoom(this.elementRef);
    } catch (e) {
      this.app.orange(e.message);
    }
  }

  async ng() {
    this.app.greenLog('header ionViewWillEnter');
  }

  async moveToCardRegistration() {
    this.analyticsService.beginCheckout();
    if (this.app.loginStatus) {
      await this.openModalPaidGuide();
    } else {
      await this.openModalLogin();
    }
  }

  /**
   * 3d editor 진입 함수
   * @return {void}
   */
  moveTo3dEditor(): void {
    this.app.go('/4cut-edit-3d');
    this.analyticsService.enterTo3dEditor();
  }

  async showPopover(event) {
    if (this.app.popover !== null) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.app.popover = true;
    this.app.popover = await this.popoverController.create({
      mode: 'md',
      component: PopoverMenuAacountComponent,
      event,
      componentProps: {
        headerComponent: this
      },
      translucent: true,
      keyboardClose: true
    });
    this.app.popover.style.cssText = '--min-width: 100px; --max-width: 300px;';

    this.app.popover.onDidDismiss().then(({ data }) => {
      this.app.popover = null;
      this.app.blueLog(data);
      if (data === Menu.LOGOUT) {
        const serviceUrl = this.app.hereUrl;

        switch (true) {
          case serviceUrl.includes(ServiceType.Magic):
            this.app.go(ServiceType.Magic);
            break;
          case serviceUrl.includes(ServiceType.Gpt):
            this.app.go('gpt/editor');
            break;
          case serviceUrl.includes(ServiceType.Board):
            this.app.go('board');
            break;
          default:
            this.app.go(ServiceType.LandingMain);
        }
      }
    });
    return this.app.popover.present();
  }

  /**
   * 로그인 모달 열기
   * @param {boolean} isSignup 회원가입 페이지 보여줄지
   * @return {Promise<void>}
   */
  async openModalLogin(isSignup: boolean = false): Promise<void> {
    try {
      if (this.app.modal !== null) {
        return;
      }
      this.app.modal = true;
      this.app.modal = await this.modalCtrl.create({
        component: LoginPage,
        componentProps: {
          isModal: true,
          isSignup: isSignup
        },
        cssClass: 'modal-size-login'
      });

      this.app.modal.onDidDismiss().then(async ({ data }) => {
        this.app.modal = null;
        // 로그인 모달이 닫히고 이쪽으로 온다
        // 캐쉬에서 서버 정보를
        this.app.blueLog(`
            login;
            modal;
            dissmiss;
            handler : ${data}`);
        if (this.app.currentService === ServiceType.Magic && data) {
          location.reload();
        }
      });
      return await this.app.modal.present();
    } catch (e) {
      throw new TooningOpenModalLoginError(e);
    }
  }

  goPayPlan() {
    if (this.app.currentService === ServiceType.Gpt) {
      this.app.go('/gpt/tooning-price-plan');
      return;
    }

    if (this.app.currentService === ServiceType.Board) {
      this.app.go('/board/tooning-price-plan');
      return;
    }

    this.app.go('/tooning-price-plan');
  }

  goHome() {
    if (this.app.loginStatus) {
      this.app.go('/4cut-list');
    } else {
      this.app.go('/tooning-landing-main');
    }
  }

  async openModalPaidGuide() {
    if (this.app.modal !== null) {
      return;
    }
    this.app.modal = true;
    this.app.modal = await this.modalCtrl.create({
      component: PaidGuideModalPage,
      cssClass: 'modal-size-pay-guide',
      componentProps: {
        isModal: true
      }
    });
    this.app.modal.onDidDismiss().then(async (data) => {
      this.app.modal = null;
    });
    return await this.app.modal.present();
  }

  goTemplate() {
    this.templateService.defaultCategory = false;
    this.app.go('/template-list/home');
  }

  serviceMenu($event: MouseEvent, menu) {
    if (this.app.currentService === this.serviceTypeEnum.Editor && this.app.usedLanguage !== LanguageType.ko) {
      return;
    }
    if (menu === 'edu' && !this.isServiceMenu) {
      this.isServiceMenu = true;
    }
    if (menu === 'price' && !this.isPriceMenu) {
      this.isPriceMenu = true;
    }
    if (menu === 'enterprise' && !this.isEnterpriseMenu) {
      this.isEnterpriseMenu = true;
    }
  }

  serviceMenuGo() {
    if (this.app.currentService === this.serviceTypeEnum.Editor && this.app.usedLanguage !== LanguageType.ko) {
      this.app.serviceIntroductionLinkEducation();
      return;
    }
    if (!this.isServiceMenu) {
      this.isServiceMenu = true;
    }
  }

  async searchTag(searchText) {
    if (searchText.target.value) {
      this.app.go('template-detail/' + searchText.target.value);
    } else {
      return;
    }
  }

  serviceMenuleave() {
    setTimeout(() => {
      if (this.isServiceMenuOver === false) {
        this.isServiceMenu = false;
      }
      if (this.isPriceMenuOver === false) {
        this.isPriceMenu = false;
      }
      if (this.isEnterpriseMenuOver === false) {
        this.isEnterpriseMenu = false;
      }
    }, 200);
  }

  /**
   * 선택한 서비스의 랜딩페이지로 이동
   * @param {string} serviceTitle
   * @return {void}
   */
  goToLanding(serviceTitle: string): void {
    let goRouter = '/';
    if (this.app.RootServiceType === DomainType.Studio) {
      switch (serviceTitle) {
        case MAGIC:
          goRouter = 'magic';
          break;
        case EDITOR:
          goRouter = 'tooning-editor';
          break;
        case GPT:
          goRouter = 'gpt/editor';
          break;
        case WORLD:
          goRouter = 'world';
          break;
        default:
          break;
      }
    } else {
      switch (serviceTitle) {
        case MAGIC:
          goRouter = 'magic';
          break;
        case GPT:
          goRouter = 'gpt/editor';
          break;
        case WORLD:
          goRouter = 'world';
          break;
        case BOARD:
          goRouter = 'board';
          break;
        default:
          break;
      }
    }
    this.app.go(goRouter);
  }

  /**
   * 편집툴로 이동
   * @return {Promise<void>}
   */
  async goToEditor(): Promise<void> {
    try {
      if (this.app.currentService === ServiceType.Magic) {
        if (this.app.loginStatus) {
          this.app.go('/magic/editor');
          this.analyticsService.enterToMagicEditor(MagicEditorGoButton.header);
        } else {
          await this.openModalLogin();
        }
      } else if (this.app.currentService === ServiceType.Gpt) {
        if (this.app.loginStatus) {
          this.app.go('/gpt/editor');
        } else {
          await this.openModalLogin();
        }
      } else {
        await this.cut.openMakeToon();
      }
    } catch (e) {
      throw new TooningGoEditorError(e, this.app, true);
    }
  }

  /**
   * 그리스 작품 안내 이동
   * @param {'online' | 'webtoon'} linkType
   * @return {void}
   */
  goToMagicWorkIntro(linkType: GreekType.online | GreekType.webtoon): void {
    try {
      switch (linkType) {
        case GreekType.online:
          this.app.goExternal('https://greek.tooning.io/');
          break;
        case GreekType.webtoon:
          this.app.goExternal('https://greek.tooning.io/webtoon');
          break;
      }
    } catch (e) {
      throw new MagicGoExternalError(e);
    }
  }

  /**
   * 작가 지원 안내 이동
   * @return {void}
   */
  goToSupport(): void {
    try {
      this.app.goExternal('https://magic-info.tooning.io/');
    } catch (e) {
      throw new MagicGoExternalError(e);
    }
  }

  /**
   * GPT 안내 페이지 이동
   * @param {GptInfoPage} page - 페이지 상세
   */
  goToGptInfoPage(page: GptInfoPage): void {
    try {
      let url = 'https://gpt-info.tooning.io/';
      switch (page) {
        case GptInfoPage.Edu:
          url += 'edu_webtoon';
          break;
        case GptInfoPage.Student:
          url += 'webtoon_by_students';
          break;
        case GptInfoPage.Policy:
          url = 'https://tooning.io/canvas-share/global?id=2962840&country=kr';
          break;
      }
      this.app.goExternal(url);
    } catch (e) {
      throw new TooningCustomClientError(e, 'header.component', 'goToGptInfoPage()', this.app);
    }
  }

  /**
   * 매직 요금제 페이지 이동
   * @return {void}
   */
  goToPricing(): void {
    try {
      switch (this.app.usedLanguage) {
        case LanguageType.ko:
          this.app.goExternal('https://magic-info.tooning.io/pricing-kr');
          break;
        default:
          this.app.goExternal('https://magic-info.tooning.io/pricing-en');
      }
    } catch (e) {
      throw new MagicGoExternalError(e);
    }
  }

  /**
   * 보드 안내 페이지 이동
   * @return {void}
   */
  goToBoardInfoPage(): void {
    try {
      this.app.goExternal('https://board-info.tooning.io/');
    } catch (e) {
      throw new TooningCustomClientError(e, 'header.component', 'goToBoardInfoPage()', this.app);
    }
  }

  /**
   * 준비중 경고 표시
   * @return {Promise<void>}
   */
  async notReadyAlert(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('notice'),
      message: this.translate.instant('preparing'),
      backdropDismiss: true,
      buttons: [
        {
          text: this.translate.instant('confirm')
        }
      ]
    });
    await alert.present();
  }

  /**
   * 스튜디오 매직 에디터로 이동
   * @return {void}
   */
  goToMagicStudio(): void {
    window.open('/magic/editor');
  }

  /**
   * changeToStudio 개발용 테스트 함수
   */
  changeToStudio() {
    sessionStorage.setItem('RootServiceType', 'studio');
    window.location.replace('/');
  }

  /**
   * changeToIo 개발용 테스트 함수
   */
  changeToIo() {
    sessionStorage.setItem('RootServiceType', 'io');
    window.location.replace('/');
  }

  changeToBiz() {
    sessionStorage.setItem('RootServiceType', 'biz');
    window.location.replace('/');
  }

  /**
   * 요금제 페이지 이동 함수
   * @param {string} paymentInfoType normal, school
   */
  goToPricePlan(paymentInfoType: string): void {
    this.cut.pricePlanChoiceInfoType = paymentInfoType;
    this.app.go('/tooning-price-plan');
  }
}
