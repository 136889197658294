import { Command } from './command';
import { PageRemoveCommandData } from '../../interfaces/app.interface';
import { Loading } from '../../services/loading';
import { TooningPageDeleteError } from '../../pages-tooning/errors/TooningErrors';
import * as _ from 'lodash';

/**
 * Class PageRemoveCommand
 * @extends Command
 */
class PageRemoveCommand extends Command {
  protected indexList: number[];
  protected pages: any[] = [];
  protected pageIdList: number[] = [];
  protected callback;
  protected indexAndPageMap: any;

  /**
   * Create PageRemoveCommand
   * @param {PageRemoveCommandData} data
   */
  constructor(data: PageRemoveCommandData) {
    super(data);
    this.indexList = data.indexList;
    this.commandName = 'Page-Remove-Command';
    this.indexList.forEach((index) => {
      this.pages.push(this.cut.pageList[index]);
      this.pageIdList.push(this.cut.pageList[index].id);
    });
    this.callback = data.callback;
    this.indexAndPageMap = _.zip(this.indexList, this.pages);
  }

  /**
   * Execute PageRemoveCommand
   */
  execute() {
    try {
      super.execute();
      let result;
      if (this.indexList.length === 1) {
        result = this.callback(this.indexList[0]);
      } else {
        result = this.callback(this.indexList);
      }
      setTimeout(() => {
        this.cut.setShowPreviewCanvas(); // 딜레이가 필요한 케이스
      }, 100);

      return result;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo PageRemoveCommand
   */
  redo() {
    try {
      super.redo();
      if (this.indexList.length === 1) {
        this.callback(this.indexList[0]);
      } else {
        this.callback(this.indexList);
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo PageRemoveCommand
   */
  async undo() {
    try {
      super.undo();
      if (!this.pageIdList.length) {
        console.log(`page remove undo 할 pageId 가 없습니다.`);
        return;
      }
      await this.restorePage(this.pageIdList);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 삭제된 page 를 복구한다.
   * 실제로 서버에서는 deletePage.deleteStatus = false 처리힌다
   * @param pageIdList 복구 할 페이지의 id
   */
  async restorePage(pageIdList: number[]) {
    const loading = new Loading();
    await loading.showLoader('');
    try {
      await Promise.all(
        pageIdList.map((pageId) => {
          this.cut.pageService.restorePage(pageId).toPromise();
        })
      );
      this.indexAndPageMap.forEach((indexAndPage) => {
        this.cut.pageList.splice(indexAndPage[0], 0, indexAndPage[1]);
      });
    } catch (e) {
      throw new TooningPageDeleteError(e.message, null, true);
    } finally {
      loading.hideLoader();
    }
    if (this.indexList[0] === 0) {
      await this.cut.goPage(this.indexList[0] + 1);
    }
  }
}

export { PageRemoveCommand };
