import { CardErrors } from '../../enums/cardErrors.enum';

const cardErrorHandler = async (errorResult, app, translate, isToast) => {
  let message;
  const errorCode = errorResult.errorCode;
  switch (errorCode) {
    case CardErrors.BILLING_KEY_DUPLICATION:
      message = translate.instant('already registered');
      break;
    case CardErrors.USER_EMPTY:
      message = translate.instant('not registered user');
      break;
    case CardErrors.CARDID_EMPTY:
      message = translate.instant('please refresh');
      break;
    case CardErrors.DB_CONNECT_FAILE:
      message = translate.instant('connect err');
      break;
  }

  if (!message) {
    message = errorResult.message;
    if (!message) {
      message = translate.instant('card regit fail');
    }
  }

  if (isToast) {
    await app.showToast(message);
  } else {
    const alert = await app.alertController.create({
      header: translate.instant('updating card err'),
      message: translate.instant('already registered'),
      cssClass: 'basic-dialog',
      buttons: [
        {
          text: 'Okay',
          handler: async () => {}
        }
      ]
    });
    await alert.present();
  }
};

export { cardErrorHandler };
