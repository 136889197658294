import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { AppService } from '../app.service';
import { ModelReorderItem } from './legacy.api.service.model';
import * as $ from 'jquery';
import { TooningGetEmotionError, TooningGetEmotionTimeOutError, TooningGetFaceAiPredictionError } from '../../pages-tooning/errors/TooningErrors';
import { KobertStatus } from '../../enum/app.enum';

const multiPartHeaders = new HttpHeaders({
  // 'Content-Type': '', //꼭 빠져있어야 자동으로 브라우져에서 처리한다.
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
});
const httpOptions = {
  // headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept-Encoding': 'gzip' }),
};
// multiPartHeaders = multiPartHeaders.append('Content-Type', '');
// multiPartHeaders = multiPartHeaders.append('Access-Control-Allow-Origin', '*');
// multiPartHeaders = multiPartHeaders.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
const endpoint = environment.legacy_api_server_url;
const stage_endpoint = environment.legacy_stage_api_server_url;
const NO_EMOTION = 'empty';

@Injectable({
  providedIn: 'root'
})
export class LegacyApiService {
  private cache = {};

  constructor(private http: HttpClient, private app: AppService) {}

  /**
   * 입력 텍스트에 대한 감정 분석 요청
   * @param text 분석할 텍스트
   * @return Promise<any>
   */
  async getEmotion(text: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url = environment.ai.ttt_url;
      const form = new FormData();
      form.append('input', text);

      const settings = {
        url,
        method: 'POST',
        timeout: 10000,
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        data: form
      };
      if (this.cache[text]) {
        this.app.greenLog(`emotion data is cached`);
        resolve(this.cache[text]);
      } else {
        // @ts-ignore
        $.ajax(settings)
          .done((response) => {
            this.app.greenLog(`emotion data is first call`);
            const { prediction } = JSON.parse(response);
            console.log(prediction);
            const emotion = prediction.emotion;
            const score = emotion !== NO_EMOTION ? prediction.data[emotion] : 0;
            const percentage = this.toPercentage(score);
            const normalizedEmotionDepth = this.emotionNormalize(percentage);
            console.log(normalizedEmotionDepth);
            prediction.depth = normalizedEmotionDepth;
            this.cache[text] = prediction;
            resolve(prediction);
          })
          .fail((error, textStatus) => {
            if (textStatus === KobertStatus.timeout) {
              reject(new TooningGetEmotionTimeOutError(`${error}`, this.app, true));
            } else {
              reject(new TooningGetEmotionError(`Kobert error :${textStatus}`, this.app, true));
            }
          });
      }
    });
  }

  ///http://stage.toonsquare.co/4cut/post?convert=true&page=0&limit=1&version=2

  characterReorder(reOrderItems: ModelReorderItem[]): Observable<any> {
    const url = endpoint + '/toon/character/admin/reorder';
    return this.http.post<any>(url, reOrderItems).pipe(catchError(this.handleError));
  }

  characterDelete(id: string): Observable<any> {
    const url = endpoint + '/toon/character/admin/character';
    const params = new HttpParams().set('id', id);
    return this.http.delete<any[]>(url, { params }).pipe(catchError(this.handleError));
  }

  upLoadFiles(files: FileList, userIndex: number): Observable<any> {
    // return new Promise<any>((resolve, reject) => {
    const uploadUrl = stage_endpoint + '/4cut/upload';
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const _file = files[i];
      formData.append('upload', _file, _file.name);
    }
    console.log('userIndex : ', userIndex.toString());
    formData.append('user_idx', userIndex.toString());
    console.log(formData);

    return this.http
      .post<any>(uploadUrl, formData, {
        headers: multiPartHeaders,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(catchError(this.handleError));
    // })
  }

  private toPercentage(num: number) {
    return parseInt((num * 100).toString(), 10);
  }

  private emotionNormalize(num) {
    let n = 0;

    if (0 < num && num < 33) {
      n = 1;
    } else if (33 <= num && num < 66) {
      n = 2;
    } else if (66 <= num && num <= 100) {
      n = 3;
    }

    return n;
  }

  private handleError(error) {
    console.log(error);
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
