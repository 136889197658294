import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { BasePage } from '../../base-page';
import { Loading } from '../../services/loading';
import { PaymentService } from '../../services/payment/payment.service';
import { AnalyticsService } from '../../services/google/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { PaymentHistoryResult } from '../../interfaces/app.interface';
import * as Moment from 'moment';
import { PaymentHistType } from '../../enum/app.enum';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.page.html',
  styleUrls: ['./payment-history.page.scss']
})
export class PaymentHistoryPage extends BasePage implements OnInit, AfterViewInit {
  public historyList: PaymentHistoryResult[] = [];
  public paymentHistoryEnum = PaymentHistType;
  private currentUser;
  private loading = new Loading();

  constructor(
    private cdRef: ChangeDetectorRef,
    private paymentService: PaymentService,
    private analyticsService: AnalyticsService,
    public app: AppService,
    private translate: TranslateService,
    public modalCtrl: ModalController
  ) {
    super(app);
  }

  async ngOnInit() {
    console.clear();
    this.app.greenLog('payment history ngOnInit');
    super.ngOnInit();
    await this.loading.showLoader('');
    this.currentUser = await this.app.user.currentUser();

    this.subscriptions.push(
      this.paymentService.paymentsHistory(+this.currentUser.id).subscribe(
        ({ data }) => {
          for (const payment of data) {
            if (payment.result) {
              this.historyList.push(payment);
            }
          }
        },
        (error) => {
          this.analyticsService.error('payment-history.page.payments', error.message);
          console.error(error);
          this.loading.hideLoader();
        },
        () => {
          this.loading.hideLoader();
          if (this.historyList.length === 0) {
            this.app.showToast(this.translate.instant('no payment record'));
          }
        }
      )
    );
  }

  ionViewDidEnter() {
    super.ionViewDidEnter();
  }

  ionViewDidLeave() {
    super.ionViewDidLeave();
  }

  ngAfterViewInit() {
    this.app.greenLog('payment history ngAfterViewInit');
    this.cdRef.detectChanges();
  }

  async close() {
    await this.modalCtrl.dismiss({});
  }

  /**
   * 데이트 포멧 변경 함수
   * @param {number} date
   * @param {string} format
   * @return {string}
   */
  changeDateFormat(date: number, format: string): string {
    return Moment(date).format(format);
  }
}
