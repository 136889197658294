<ion-list class='p-0' [ngClass]='app.hereUrl.includes(serviceTypeEnum.MagicStudio)&&"dark-mode"'>
  <div class='border-wrapper' tabindex='0'>
    <ion-item>
      <ion-avatar fill='clear' slot='start' mode='md'
                  [ngClass]='app.isAdmin&&"admin-img"'>
        <img [src]="app.cache.user?.userImgUrl !== null ? app.cache.user.userImgUrl : '/assets/common/avatar.svg'" alt='User Image'/>
      </ion-avatar>

      <ion-label class='ml-2'>
        <h2 *ngIf='app.cache.user?.userName'>{{ app.cache.user?.userName }} {{ 'respect' | translate }}</h2>
        <h2
          *ngIf='!app.cache.user?.userName'>{{ app.cache.user?.userEmail.split('@')[0] }}  {{ 'respect' | translate }}</h2>
        <p class='user-email'>{{ app.cache.user?.userEmail }}</p>
        <div *ngIf='!isIos'>
          <img *ngIf='app.isPaidMember && !app.isDonation' class='ml-0 pl-0'
               style='margin-left: -3px !important' src='/assets/4cut-make-manual/pro_icon.svg' alt='pro_icon.svg'/>
          <img *ngIf='app.isPaidMember && app.isDonation' class='ml-0 pl-0'
               style='margin-left: -3px !important' src='/assets/4cut-make-manual/donation.svg' alt='donation.svg'/>
          <div style='float: left' class='plan' style='display: inline-block'>{{ plan }}</div>
          <div style='float: right' *ngIf='!app.isAdmin'></div>
        </div>
      </ion-label>
      <div tabindex='0'></div>
    </ion-item>
  </div>

  <div *ngIf='!app.isAdmin' class='border-wrapper' tabindex='0'>
    <ion-item button (click)='goChargeCoin()' data-cy='goChargeCoin'>
      {{ 'coin name' | translate }}
      <div class='point' *ngIf='!app.isAdmin'>
        {{myPoints | number}}
      </div>
      <div style='float: right'>
        <ion-img src='/assets/coin_charge.svg' class='mr-0'
                 style='margin-right: auto; margin-left: 10px'></ion-img>
      </div>
    </ion-item>
  </div>

  <div class='border-wrapper' tabindex='0' *ngIf='app.currentService === serviceTypeEnum.Editor'>
    <ion-item button (click)='goMyWork()'>
      <ion-label>{{ 'my work' | translate }}</ion-label>
      <div tabindex='0'></div>
    </ion-item>
  </div>

  <ng-container *ngIf='!isIos'>
    <div class='border-wrapper' tabindex='0'>
      <ion-item *ngIf='!isBeta || !isStudent || !isTeacher || !isTemplate || !isTextTemplate' button
                data-cy='moveToAccountSetting'
                (click)='moveToAccountSetting()'>
        <ion-label>{{ 'account settings' | translate }}</ion-label>
        <div tabindex='0'></div>
      </ion-item>
    </div>
  </ng-container>

  <div class='border-wrapper' tabindex='0'>
    <ion-item style='--highlight-color-valid:rgb(224,224,224)' (click)='app.changeLanguageService.alertChangeLanguage()'
              button detail>
      <ion-label>{{ 'language setting' | translate }}</ion-label>
      <ion-label slot='end'> {{languageOriginalEnum[app.usedLanguage]}}</ion-label>
      <div tabindex='0'></div>

    </ion-item>
  </div>

  <div tabindex='0'>
    <ion-item lines='none' detail='false' button (click)='logOut()' data-cy='popoverMenuAccountLogOut'>
      <ion-label>{{ 'logOut' | translate }}</ion-label>
      <div tabindex='0'></div>
    </ion-item>
  </div>

  <div *ngIf='app.isAdmin' class='border-wrapper' tabindex='0'>
    <ion-item lines='none' detail='false' button (click)='goAllList()'>
      <ion-label>{{ appVersion }}</ion-label>
      <div tabindex='0'></div>
    </ion-item>
  </div>
  <!-- 개발용 -->
  <ion-item *ngIf='app.cache.user?.role === "admin"' detail='false' button (click)='updateCardCompanyStatus()'>
    <ion-label>카드사 알림 설정(어드민용)</ion-label>
    <div tabindex='0'></div>
  </ion-item>

  <ion-item *ngIf='app.cache.user?.role === "admin"' detail='false' button (click)='setServerRegion()'>
    <ion-label>접속할 서버 선택</ion-label>
    <div tabindex='0'></div>
  </ion-item>

  <ng-container *ngIf='!isProduction'>
    <hr />
    <div class='text-center'>개발용</div>
    <ion-item detail='false' button (click)='refund()'>
      <ion-label>환불</ion-label>
      <div tabindex='0'></div>
    </ion-item>
    <ion-item lines='none' detail='false' button>
      <ion-label>클라우드프런트 on/off</ion-label>
      <ion-toggle slot='end' name='status' [(ngModel)]='cloudFrontState'
                  (ionChange)='switchCloudFront()'></ion-toggle>
      <div tabindex='0'></div>
    </ion-item>
  </ng-container>
</ion-list>
