import { Injectable } from '@angular/core';
import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { InputPageCreateObjectKeys } from '../../interfaces/s3.interface';
import { UserRole } from 'src/app/enum/app.enum';
import { PutObjectCommandInput } from '@aws-sdk/client-s3/dist-types/commands/PutObjectCommand';
import { ApolloQueryResult, gql } from '@apollo/client';
import { tap } from 'rxjs/operators';
import { GraphqlApiService } from '../api/graphql.api.service';
import { Page } from '../../model/page/page';

const prefix = 'tooning_files_';

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  private s3Client;

  constructor(public graphql: GraphqlApiService) {
    if (!this.s3Client) {
      this.s3Client = new S3Client({
        region: 'ap-northeast-2',
        credentials: {
          accessKeyId: 'AKIATUBRPYG3BHO4MTV6',
          secretAccessKey: '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'
        }
      });
    }
  }

  /**
   * @description s3에 데이터를 생성하거나,  data 정보가 있을 경우는 업데이트한다.
   * 서버쪽의 pageUpdate 부분을 클라이언트에서 구현했다.
   * @param {Array<Blob>} files 업로드할 파일들이며, maxLength = 3
   *                            최대 3개가 세팅될 경우는, index=2 에는 textTemplate 이 세팅된다,
   * @param {Page} [page] 업로드할 Page 데이터
   * @param {UserRole} [userRole] 유저 롤
   * @return {Promise<any>}
   */
  async putPageObject(files: Array<Blob>, page?: Page, userRole?: UserRole): Promise<InputPageCreateObjectKeys> {
    try {
      if (!files) {
        throw new Error('empty upload files to s3 with aws sdk');
      }
      if (files.length < 2) {
        throw new Error('not enough files to upload with aws sdk');
      }

      let keys: InputPageCreateObjectKeys = { base64: '', json: '', textTemplateJson: '' };
      let key: string;
      let promises: Array<Promise<any>>;
      let textTemplate: Blob;

      if (files.length === 3) {
        textTemplate = files.pop();
      }

      promises = files.map((file) => {
        let type;
        switch (file.type) {
          case 'text/plain':
            type = 'base64';
            key = page?.base64 ? this.getS3KeyFromUrl(page.base64) : `${prefix}${Date.now()}.${type}`;
            keys.base64 = key;
            break;
          case 'application/json':
            type = 'fabric';
            key = page?.json ? this.getS3KeyFromUrl(page.json) : `${prefix}${Date.now()}.${type}`;
            keys.json = key;
            break;
          default:
            throw new Error('unknown file type in putObject');
        }

        let putObjectCommandInput: PutObjectCommandInput;
        if (userRole === UserRole.template || userRole === UserRole.textTemplate) {
          putObjectCommandInput = {
            Bucket: environment.s3.bucket,
            Key: key,
            Body: file,
            CacheControl: 'no-cache',
            Metadata: {
              Invalidation: 'true'
            }
          };
        } else {
          putObjectCommandInput = {
            Bucket: environment.s3.bucket,
            Key: key,
            Body: file,
            CacheControl: 'no-cache'
          };
        }

        const command = new PutObjectCommand(putObjectCommandInput);
        return this.s3Client.send(command);
      });

      if (textTemplate) {
        if (userRole === UserRole.textTemplate) {
          let { data } = await this.getTextTemplate(page.canvasId).toPromise();
          if (!data) {
            throw new Error(`textTemplate is empty canvasId : ${page.canvasId}`);
          }
          let type = 'fabric';
          let textTemplateJson: string;
          if (data.fabricObj) {
            textTemplateJson = data.fabricObj;
          } else {
            textTemplateJson = `${prefix}${Date.now()}.${type}`;
          }
          keys.textTemplateJson = textTemplateJson;
          const command = new PutObjectCommand({
            Bucket: environment.s3.bucket,
            Key: textTemplateJson,
            Body: textTemplate,
            CacheControl: 'no-cache'
          });
          promises.push(this.s3Client.send(command));
        }
      }
      await Promise.all(promises);
      return keys;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * s3에서 바로 데이터를 가져온다.
   * @param {string} key
   * @return {Promise<any>}
   */
  async getObject(key?: string): Promise<any> {
    try {
      const command = new GetObjectCommand({
        Bucket: environment.s3.bucket,
        Key: key ?? '123.svg'
      });
      return await this.s3Client.send(command);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 텍스트 템플릿 불러오기
   * @param canvasId 텍스트 템플릿 캔버스 id
   */
  getTextTemplate(canvasId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getTextTemplate($canvasId: ID!) {
            getTextTemplate(canvasId: $canvasId) {
              fabricObj
            }
          }
        `,
        {
          canvasId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.getTextTemplate;
        })
      );
  }

  /**
   * url 에서 page.json, page.base64 키를 추출한다.
   * @param {string} url
   * @return {string | undefined}
   */
  getS3KeyFromUrl(url: string): string | undefined {
    if (!url) return;
    const regex = /\/?([^\/]+\.(fabric|base64))/;
    const match = url.match(regex);

    if (match?.length > 1) {
      return match[1];
    }
    return undefined;
  }
}
