import { Component, EventEmitter, Output } from '@angular/core';
import { LanguageType } from '../../../../enum/app.enum';
import { AppService } from '../../../../services/app.service';
import { TooningSignUpUIError } from '../../../../pages-tooning/errors/TooningErrors';

@Component({
  selector: 'app-terms-agreement',
  templateUrl: './terms-agreement.component.html',
  styleUrls: ['./terms-agreement.component.scss']
})
export class TermsAgreementComponent {
  @Output() changeAgreement: EventEmitter<any> = new EventEmitter();

  public languageType = LanguageType;
  public userCheckbox = [false, false, false, false, false];

  constructor(public app: AppService) {}

  /**
   * 사용자 동의 전체 동의 체크박스 컨트롤
   * @param {Event} event 체크박스 변경 이벤트
   * @return {void}
   */
  changeCheckbox(event: Event, index: number, isCheckBox: boolean): void {
    try {
      const target = event.target as HTMLElement;
      // 전체 동의
      if (target.id === 'all-check' || index === 0) {
        this.userCheckbox[0] = !this.userCheckbox[0];
        this.userCheckbox.fill(this.userCheckbox[0]);
        this.changeAgreement.emit(this.userCheckbox);
        return;
      }

      this.userCheckbox[index] = !this.userCheckbox[index];

      if (this.userCheckbox[1] && this.userCheckbox[2] && this.userCheckbox[3] && this.userCheckbox[4]) this.userCheckbox[0] = true;
      else this.userCheckbox[0] = false;

      this.changeAgreement.emit(this.userCheckbox);
    } catch (e) {
      throw new TooningSignUpUIError(e);
    } finally {
      if (isCheckBox) {
        this.userCheckbox[index] = !this.userCheckbox[index];
        event.stopPropagation();
      }
    }
  }

  /**
   * 서비스 이용약관 보기
   * @return {void}
   */
  showServiceAgreement(): void {
    switch (this.app.usedLanguage) {
      case LanguageType.fr:
        this.app.goExternal('https://toonsquare.notion.site/Conditions-g-n-rales-d-utilisation-4913cf0f017d4e078cc598f40b0b5e4c?pvs=4');
        break;
      case LanguageType.jp:
        this.app.goExternal('https://toonsquare.notion.site/bbfe6567923a43dcb07ca44dfed2d824?pvs=4');
        break;
      case LanguageType.ko:
        this.app.goExternal('https://toonsquare.notion.site/4a7bbd9a994848e6b25098452c51050d?pvs=4');
        break;
      default:
        this.app.goExternal('https://toonsquare.notion.site/Terms-Of-Service-15c5340ba08840b5950982f04a685cf4?pvs=4');
    }
  }

  /**
   * 개인정보 수집 및 이용 동의 보기
   * @return {void}
   */
  showPersonalInfoConsent(): void {
    switch (this.app.usedLanguage) {
      case LanguageType.fr:
        this.app.goExternal(
          'https://toonsquare.notion.site/Accord-sur-la-collecte-et-l-utilisation-d-informations-personnelles-FR-880bce472ccb4a53b78eab43732d551a?pvs=4'
        );
        break;
      case LanguageType.jp:
        this.app.goExternal('https://toonsquare.notion.site/JP-958daef557894bcc95a4eaa2326d432f?pvs=4');
        break;
      case LanguageType.ko:
        this.app.goExternal('https://toonsquare.notion.site/03c164f82af4438aa2d47943b16e17c3?pvs=4');
        break;
      default:
        this.app.goExternal(
          'https://toonsquare.notion.site/Personal-Information-Collection-and-Use-Agreement-ENG-72ae765d35104fce92cd2116878fbe16?pvs=4'
        );
    }
  }
}
