import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss']
})
export class FabButtonComponent implements OnInit {
  @Input() fabOpen: boolean;
  @Input() title: string;
  @Input() icon: string;
  @Input() openW: string;
  @Input() closeW: string;
  @Input() bottomPosition: any;
  @Input() isRotate: boolean;
  @Output() clickNew: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.fabOpen = false;
    this.isRotate = false;
    console.log('this.bottomPosition');
    console.log(this.bottomPosition);
    if (this.bottomPosition == undefined) {
      this.bottomPosition = 70;
    }
  }

  buttonClick(e) {
    this.clickNew.emit(e);
  }
}
