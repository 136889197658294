import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { AppService } from '../app.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateServiceService {
  private updatedCheckerInterval: number = 30 * 60 * 1000;

  constructor(private appRef: ApplicationRef, private updates: SwUpdate, private app: AppService, private translate: TranslateService) {
    this.updatedCheckerInterval = environment.production ? 30 * 60 * 1000 : 1 * 60 * 1000;
    this.versionUpdateCheck();
    this.intervalUpdateCheck();
  }

  /**
   * Checks for updates at regular intervals.
   *
   * @returns {void}
   */
  intervalUpdateCheck() {
    const updateCheckerInterval$ = interval(this.updatedCheckerInterval);
    updateCheckerInterval$.subscribe(async () => {
      try {
        await this.updates.checkForUpdate();
      } catch (err) {
        this.app.goReplace('/');
      }
    });
  }

  versionUpdateCheck(): void {
    this.updates.unrecoverable.subscribe((evt) => {
      this.updatedFailAlert(evt);
    });
    this.updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log('version detected');
          break;
        case 'VERSION_READY':
          this.updatedNow();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          this.updatedFailAlert(evt);
          break;
        case 'NO_NEW_VERSION_DETECTED':
          break;
      }
    });
  }

  updatedNow() {
    const message = this.translate.instant('service-worker.is-updated-now');
    const isUpdatedNow = window.confirm(message);
    if (isUpdatedNow) document.location.reload();
  }

  updatedFailAlert(evt) {
    alert(`[service worker] Unrecoverable error occurred during automatic UI update check: ${evt.reason}`);
    this.app.goReplace('/');
  }
}
