import { IsBoolean, IsNotEmpty, IsNumber, IsOptional } from 'class-validator';

export class EachStepTF {
  @IsNumber() @IsNotEmpty() characterId: number;
  @IsBoolean() @IsOptional() baseStep?: boolean;
  @IsBoolean() @IsOptional() faceStep?: boolean;
  @IsBoolean() @IsOptional() bodyStep?: boolean;
  @IsBoolean() @IsOptional() handStep?: boolean;
  @IsBoolean() @IsOptional() hairStep?: boolean;
  @IsBoolean() @IsOptional() etcStep?: boolean;

  @IsBoolean() @IsOptional() headPositionStep?: boolean;
  @IsBoolean() @IsOptional() armIndexStep?: boolean;
  @IsBoolean() @IsOptional() legIndexStep?: boolean;
  @IsBoolean() @IsOptional() headRotateStep?: boolean;
  @IsBoolean() @IsOptional() bodyRotateStep?: boolean;
  @IsBoolean() @IsOptional() expressionTagStep?: boolean;
  @IsBoolean() @IsOptional() colorSetStep?: boolean;
  @IsBoolean() @IsOptional() bodyIntentionStep?: boolean;
  @IsBoolean() @IsOptional() bodyTagStep?: boolean;
  // @IsBoolean() @IsOptional() hairStyleStep?: boolean;
  @IsBoolean() @IsOptional() defaultSetStep?: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
