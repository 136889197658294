import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { GraphqlApiService } from '../api/graphql.api.service';
import { Observable } from 'rxjs';
import { ApolloQueryResult, gql } from '@apollo/client';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyColorService {
  constructor(public app: AppService, public graphql: GraphqlApiService) {}

  getMyColor(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getMyColor($userId: ID!) {
            getMyColor(userId: $userId) {
              id
              color
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getMyColor;
          return result;
        })
      );
  }

  addMyColor(userId: number, myColor: string) {
    return this.graphql
      .mutate(
        gql`
          mutation addMyColor($userId: ID!, $myColor: String!) {
            addMyColor(userId: $userId, myColor: $myColor) {
              id
              color
            }
          }
        `,
        {
          userId,
          myColor
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.addMyColor;
          return result;
        })
      )
      .toPromise();
  }

  deleteMyColor(myColorIDList: number[], userId: number) {
    return this.graphql
      .mutate(
        gql`
          mutation deleteMyColor($myColorIDList: [ID!]!, $userId: ID!) {
            deleteMyColor(myColorIDList: $myColorIDList, userId: $userId) {
              id
              color
            }
          }
        `,
        {
          myColorIDList,
          userId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.deleteMyColor;
          return result;
        })
      )
      .toPromise();
  }

  updateOrderMyColor(myColorIDList: number[], userId: number) {
    return this.graphql
      .mutate(
        gql`
          mutation updateOrderMyColor($myColorIDList: [ID!]!, $userId: ID!) {
            updateOrderMyColor(myColorIDList: $myColorIDList, userId: $userId)
          }
        `,
        {
          myColorIDList,
          userId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.updateOrderMyColor;
          return result;
        })
      )
      .toPromise();
  }
}
