<div [class]='app.currentService' class='tab-container'>
  <div class='max-width-container d-flex'>
    <div (click)='goToSelectService("editor")'
         [ngClass]='app.checkServiceType()===serviceTypeEnum.Editor&&"selected-cell"'
         class='tab-cell'
         id='top-header-editor-tap'>
      <div class='tab-cell-text'>{{'tooning editor'|translate}}</div>
    </div>
    <div (click)='goToSelectService("magic")'
         [ngClass]='app.checkServiceType()===serviceTypeEnum.Magic&&"selected-cell"'
         class='tab-cell'
         id='top-header-magic-tap'>
      <div class='tab-cell-text'>{{'magic-landing.dep1.text2'|translate}}</div>
    </div>
    <div (click)='goToSelectService("gpt")' *ngIf='app.usedLanguage === languageType.ko'
         [ngClass]='app.checkServiceType()===serviceTypeEnum.Gpt&&"selected-cell"'
         class='tab-cell with-beta'
         [style.min-width.px]='132'
         id='top-header-gpt-tap'>
      <div class='tab-cell-text'>{{'투닝 GPT'|translate}}</div>
      <span class='gpt-beta'>Beta</span>
    </div>
    <div (click)='goToSelectService("board")' *ngIf='app.usedLanguage === languageType.ko'
         [ngClass]='app.checkServiceType()===serviceTypeEnum.Board&&"selected-cell"'
         class='tab-cell with-beta'>
      <div class='tab-cell-text'>{{'투닝 보드'|translate}}</div>
      <span class='board-beta'>Beta</span>
    </div>
  </div>
</div>
