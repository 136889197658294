import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { UserRole } from '../enum/app.enum';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(public app: AppService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirect = state.url.replace('/', '');
    this.app.greenLog(`canActivate, redirect :${redirect}`);
    return this.canActiveCheck(redirect);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirect = state.url.replace('/', '');
    this.app.greenLog(`canActivateChild, redirect :${redirect}`);
    return this.canActiveCheck(redirect);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const currentUserString = window.localStorage.getItem('user');
    let user;
    let result = false;
    if (currentUserString) {
      user = JSON.parse(currentUserString);
      if (user.role === UserRole.admin) {
        result = true;
      }
    } else {
      this.app.greenLog('어드민 확인을 위해 로그인이 필요합니다.');
      this.app.go('/login');
    }

    return result;
  }

  canActiveCheck(redirect) {
    const currentUserString = window.localStorage.getItem('user');
    let user;
    let result = false;
    const ALLOWED_IP: readonly string[] = ['127.0.0.1', '218.145.175.221'];
    if (currentUserString) {
      user = JSON.parse(currentUserString);
      if (!ALLOWED_IP.includes(user.lastIpAddress)) {
        this.app.showToast('admin은 사내에서만 접근이 가능합니다.', 4000, 'danger', 'middle').then(() => {
          this.app.goReplace('/');
        });
        return false;
      }
      if (user.role === UserRole.admin) {
        result = true;
      }

      if (!result) {
        this.app.showToast('관리자에게 권한을 요청하세요<br>cto@toonsquare.co', 4000, 'danger', 'middle').then(() => {
          this.app.go('/');
        });
      }
    } else {
      this.app.greenLog('어드민 확인을 위해 로그인이 필요합니다.');
      this.app.cache.setRedirect(redirect);
      this.app.go('/login');
    }

    return result;
  }
}
