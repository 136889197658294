import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class EtcUploadCategory {
  @IsNumber() @IsOptional() categoryId: number;
  // tslint:disable-next-line:variable-name
  @IsString() @IsNotEmpty() name_ko: string;
  @IsString() @IsNotEmpty() name_en: string;
  @IsString() @IsNotEmpty() desc_ko: string;
  @IsString() @IsNotEmpty() desc_en: string;
  @IsString() @IsNotEmpty() base64: string;
  @IsString() @IsNotEmpty() status: string;
  @IsString() @IsNotEmpty() sourceType: string;
  @IsString() @IsOptional() imgPath: string;
  @IsString() @IsOptional() imgName: number;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
