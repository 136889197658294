<div style='padding-bottom:22px'>

  <div style='line-height: 25px;margin-top: 10px; font-size: 14px;  text-align: end'
      [style.text-align]='app.isDesktopWidth() ? "end" : ""'
      >
    <span *ngIf='app.usedLanguage === LanguageType.ko' style='font-weight: 600; color: #595959'> {{'help center' | translate}} 050-7458-2020 </span>
    <span *ngIf='app.usedLanguage !== LanguageType.ko' style='font-weight: 600; color: #595959'> {{'help center' | translate}}</span>
    <br>
    <span style='font-weight: 600; color: #969696'> support@tooning.io </span>
  </div>

  <div style='width: fit-content; margin-top:40px;'
       class='d-flex justify-content-between align-items-center'>
    <div class='d-flex justify-content-around'>
      <img
        [src]='"/assets/tooning_landing/footer_icon_6.svg"'
        (click)='linkedinGo()'
        style='object-fit: none;width: 25px;cursor: pointer; margin-right: 20px;' alt='' />
      <img
           [src]='"/assets/tooning_landing/footer_icon_3.svg"'
           (click)='facebookGo()'
           style='object-fit: none;width: 25px;cursor: pointer; margin-right: 20px' alt='' />
      <img
        [src]='"/assets/tooning_landing/footer_icon_4.svg"'
        (click)='instagramGo()'
        style='object-fit: none;width: 25px;cursor: pointer; margin-right: 20px' alt='' />
      <img
        [src]='"/assets/tooning_landing/footer_icon_5.svg"'
        (click)='youtubeGo()'
        style='object-fit: none;width: 25px;cursor: pointer; margin-right: 20px' alt='' />

      <img
        [src]='"/assets/tooning_landing/footer_icon_7.svg"'
        (click)='discordGo()'
        style='object-fit: none;width: 25px;cursor: pointer;' alt=''
        matTooltip='{{"customer.feedback" | translate}}'
        matTooltipPosition='above'>
    </div>
  </div>



</div>