<ion-header>
  <ion-toolbar mode='md'>
    <ion-title>{{ 'magic.' + data.name |translate }} {{data.subname}}</ion-title>
    <ion-buttons slot='end'>
      <ion-button (click)='close()'>
        <ion-icon slot='icon-only' name='close-outline' style='color:#FFFFFF'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class='style-wrapper'>
    <div class='target-img' [style.background-image]='"url(" +data.information.thumbnail +")"'>
    </div>
    <div class='target-info' [innerHTML]='data.information.desc[app.usedLanguage]'></div>
    <ul class='target-tags'>
      <ng-container *ngFor='let tag of data.information.tags; let i = index'>
        <li class='tag'>{{tag[app.usedLanguage]}}</li>
      </ng-container>
    </ul>
  </div>
  <ion-toolbar mode='md'>
    <ion-title>{{ "magic-information.button" | translate }}</ion-title>
    <ion-buttons slot='end'>
      <ion-button (click)='cut.openMakeToon(data.characterId)'>
        <ion-icon slot='icon-only' name='chevron-forward-outline' style='color:#FFFFFF'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-content>
