import { Component, Input, OnInit } from '@angular/core';
import { IamportPaymentInfo } from '../../interfaces/app.interface';
import { AppService } from '../../services/app.service';
import { PointService } from '../../services/point/point.service';
import { PayMethod } from '../../enum/app.enum';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TooningChangeDefaultPaymentApplyClickError, TooningChangeDefaultPaymentRadioClickError } from '../../pages-tooning/errors/TooningErrors';

@Component({
  selector: 'app-chage-default-payment',
  templateUrl: './charge-default-payment.component.html',
  styleUrls: ['./charge-default-payment.component.scss']
})
export class ChargeDefaultPaymentComponent implements OnInit {
  @Input() alertInput: Array<IamportPaymentInfo>;
  public checkedValue;

  constructor(
    private app: AppService,
    private pointService: PointService,
    public alertController: AlertController,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    const checkedInput = this.alertInput.find((input) => input.isDefault === true);
    this.checkedValue = checkedInput.paymentType;
  }

  /**
   *모달 끄기
   * @return{void}
   */
  cancelClick(): void {
    this.app.modal.dismiss();
  }

  /**
   * 결제 수단 변경 등록
   * @return {Promise<void>}
   */
  async applyClick(): Promise<void> {
    try {
      const changeResult = await this.pointService.setDefaultBillingKeyFromIamport(this.app.cache.user.id, this.checkedValue).toPromise();
      if (changeResult) {
        await this.app.showToast('기본 결제 수단이 변경되었습니다');
      } else {
        await this.app.showToast('기본 결제 수단 변경에 실패했습니다', 1000, 'danger');
      }
    } catch (e) {
      throw new TooningChangeDefaultPaymentApplyClickError(e);
    } finally {
      this.app.modal.dismiss();
    }
  }

  /**
   * 버튼 클릭시 카드가 아니면 포인트 충전 못한다는 얼럿
   * @param {string} targetValue 체크할 값
   * @return {Promise<void>}
   */
  async radioClick(targetValue: string): Promise<void> {
    try {
      if (targetValue !== PayMethod.card) {
        const alert = await this.alertController.create({
          header: this.translate.instant('notice'),
          cssClass: 'basic-dialog',
          message: this.translate.instant('paymentWords.cantNotUsedPointCharge'),
          mode: 'md',
          buttons: [
            {
              text: this.translate.instant('header'),
              role: 'cancel',
              handler: () => {
                this.checkedValue = targetValue;
              }
            }
          ]
        });
        await alert.present();
      } else {
        this.checkedValue = targetValue;
      }
    } catch (e) {
      throw new TooningChangeDefaultPaymentRadioClickError(e);
    }
  }
}
