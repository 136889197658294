import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LanguageOriginalType } from '../../../../enum/app.enum';
import { AppService } from '../../../../services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkStatusService } from '../../../../services/network/networkStatus.service';

@Component({
  selector: 'app-editer-setting-modal',
  templateUrl: './editer-setting-modal.component.html',
  styleUrls: ['./editer-setting-modal.component.css']
})
export class EditerSettingModalComponent {
  @Input('cut') cut; // 해당 서비스에서 모달을 뛰워서 constructor에서 쓸수가 없음. 그래서 전달 받아서 써야함.
  public languageOriginalEnum = LanguageOriginalType;

  constructor(
    public modalCtrl: ModalController,
    public app: AppService,
    public alertController: AlertController,
    private translate: TranslateService,
    public networkStatusService: NetworkStatusService
  ) {}

  async close() {
    await this.modalCtrl.dismiss({});
  }
}
