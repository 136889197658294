import { AlertController, LoadingController } from '@ionic/angular';
import { LoadingTimerManager } from './loadingTimerManager';
import { logErrorMessage } from '../pages-tooning/errors/TooningErrors';
import { LoadingType } from '../enum/app.enum';

/**
 * Class of Loading
 */
export class Loading {
  public loaderEnabled: boolean;
  public loadingController: LoadingController;
  public loading: any;
  public alert: any;
  private alertController: AlertController;
  private loadingTimerManager: LoadingTimerManager;
  private usedLanguage;
  private timerList = [];
  private controllerList = [];
  private language = {
    ko: {
      header: '네트워크가 불안정합니다.',
      message: '데이터를 불러올 수 없습니다.',
      refresh: '새로고침',
      waiting: '계속 시도'
    },
    en: {
      header: 'The network is unstable.',
      message: 'Unable to load data.',
      refresh: 'Refresh',
      waiting: 'Keep Trying'
    },
    ja: {
      header: 'ネットワークが不安定です。',
      message: 'データを読み込めません。',
      refresh: '更新',
      waiting: '続けよう'
    },
    fr: {
      header: 'Le réseau est instable.',
      message: 'Impossible de charger les données.',
      refresh: 'Rafraîchir',
      waiting: "Continue d'essayer"
    }
  };
  public loadingType: string = LoadingType.default;

  /**
   * LoadingTimerManager를 singleton 으로 가져온다
   */
  constructor() {
    this.loadingController = new LoadingController();
    this.alertController = new AlertController();
    this.loaderEnabled = false;
    this.loadingTimerManager = LoadingTimerManager.getInstance();
    this.usedLanguage = document.documentElement.lang;
  }

  private static loadingAlldismiss() {
    const loadingAll = document.getElementsByClassName('_loadingClass');
    if (loadingAll.length != 0) {
      for (const i in loadingAll) {
        // @ts-ignore
        try {
          loadingAll[i].remove();
          // LanguageType.jp
        } catch (e) {
          console.warn(e.message);
        }
      }
    }
  }

  /**
   * 기본 로딩을 보여주는 함수
   * @param {string} message  showLoader 에 출력될 메세지
   * @param {number} timeout 기본 시간 1분이 지나면 timeout 에러 메세지가 뜬다. 1000 * 60
   * @return {Promise<void>}
   */
  public async showLoader(message: string = '', timeout: number = 1000 * 60 * 3): Promise<void> {
    Loading.loadingAlldismiss(); // 중복 로딩 방지 방어코드
    // return 을 넣어줘야 async 처리가됨.
    let msg: string;
    switch (this.loadingType) {
      case LoadingType.magic:
        msg = '<img src="/assets/common/loading_magic.png" ><br><p class="text-center">' + message + '</p>';
        break;
      default:
        msg = '<img src="/assets/common/loading.png" ><br><p class="text-center">' + message + '</p>';
    }
    const res = await this.loadingController.create({
      spinner: null,
      // @ts-ignore
      message: msg,
      // message: message,
      cssClass: 'custom-loading _loadingClass'
    });
    const setTimeoutFn = this.setTimeoutPopup(message, res, timeout);
    this.timerList.push(setTimeoutFn);
    this.loadingTimerManager.saveTimer(setTimeoutFn);
    this.controllerList.push(res);
    await res.present();
  }

  public hideLoader() {
    try {
      //timerList 다죽이기
      for (const time of this.timerList) {
        clearTimeout(time);
      }
      this.timerList = [];

      //timerList 다죽이기
      for (const controller of this.controllerList) {
        controller.dismiss();
      }
      this.controllerList = [];
    } catch (e) {
      console.error(e);
    }
  }

  // 타임 아웃 뜨는 팝업
  private setTimeoutPopup(message, loadingController, timeout) {
    return setTimeout(async () => {
      const userString = window.localStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        logErrorMessage('setTimeoutPopup', `loading.hideLoader timeOut  네트워크 상태가 느릴때 발생`, '', user.id, true);
      }

      await loadingController.dismiss();
      await this.alertController
        .create({
          cssClass: 'basic-dialog',
          header: this.language[this.usedLanguage].header,
          message: this.language[this.usedLanguage].message,
          backdropDismiss: false,
          buttons: [
            {
              text: this.language[this.usedLanguage].refresh,
              handler: () => {
                window.location.replace('/');
              }
            },
            {
              text: this.language[this.usedLanguage].waiting,
              handler: async () => {
                await this.showLoader(message, timeout);
              }
            }
          ]
        })
        .then((res) => {
          this.controllerList.push(res);
          res.present();
        });
    }, timeout);
  }
}
