<div *ngIf='readyToRender'>
  <div class='payment-choice-container'>
    <label *ngFor='let pay of payMethod; let i = index' class='paymethod-wrapper w-100'
           (click)='payMethodIndex= i;isPoint ? payMethodClickForPoint(pay.payMethod) : checkBeforeClickPayMethod(pay.payMethod, pay.isAlreadyExist,i)'>
      <div class='radio-wrapper flex-wrap justify-content-between'>
        <div class='title-wrapper'>
          <input [value]='pay.payMethod' type='radio' name='payMethod' [checked]='choicePayIndex === i' />
          <span class='custom-radio'></span>
          <div class='payments-img {{pay.payMethod}}-img' *ngIf='pay.payMethod!==payMethodEnum.card'> </div>
          <!--국내 전용이라 국제화는 필요 없음 -->
          <span class='paymethod-title'>{{'paymentType.' + pay.payMethod | translate}}</span>
        </div>
        <div class='tag-wrapper'>
          <div class='tag'>{{'iamportPayment.subscription' | translate}}</div>
          <div *ngIf='i===0' class='tag'>{{'iamportPayment.point' | translate}}</div>
        </div>
      </div>
      <div *ngIf='payMethodIndex===0 && i===0 && isProblemCardCompany && selectedPayment === payMethod[0].payMethod'
           class='warning-message'>{{'paymentWords.cardCompanyError1' | translate}}{{problemedCardCompanys}}{{'paymentWords.cardCompanyError2' | translate}}</div>
    </label>
  </div>

  <button class='theme-main' *ngIf='!isCutPage&&!isMagicPage&&!isGptPage' (click)='isClicked=true; clickRequestBtn()'
          [disabled]='isClicked'
          matRipple>
    <div *ngIf='isPoint && !isUpdatingCard'>{{'do point charge' | translate}}</div>
    <div *ngIf='!isPoint && !isUpdatingCard'>{{'do subscription' | translate}}</div>
    <div *ngIf='isUpdatingCard'>{{'Register your payment method' | translate}}</div>
  </button>
  <button *ngIf='isUpdatingCard' class='cancel theme-text-highlight' (click)='showCardUpdateView.emit()'
          matRipple>{{ 'cancel' | translate }}</button>
</div>
