<div class='signup-complete'>
  <div class='welcome-image'></div>

  <ion-buttons class='top-close'>
    <ion-button (click)='close()'>
      <ion-icon name='close-outline' slot='icon-only'></ion-icon>
    </ion-button>
  </ion-buttons>

  <div class=' text-center font-weight-bold translate-space w-100 welcome-content'>
    <div class='tooning-welcome'>{{'welcome-tooning.welcome' | translate}}</div>
    <div class='font-weight-normal tooning-welcome-explian'>{{'welcome-tooning.explain1' | translate}}</div>
    <div class='font-weight-normal tooning-welcome-explian'>{{'welcome-tooning.explain2' | translate}}</div>
    <div
      class='font-weight-normal tooning-verification-explian'
      *ngIf='app.usedLanguage === languageType.jp && app.checkServiceType() === serviceTypeEnum.Magic'
    >
      {{'welcome-tooning.verification-explain' | translate}}
    </div>
  </div>

  <div class='start-buttons'>
    <!--  -->
    <button class='adult-verification-button'
            *ngIf='app.usedLanguage === languageType.jp && app.checkServiceType() === serviceTypeEnum.Magic'
            (click)='magic.adultVerificationRequest()'>{{'welcome-tooning.adult-verification' | translate}}</button>
    <button class='start-button'
            (click)='close()'>{{'welcome-tooning.start.' + app.checkServiceType() | translate}}</button>
  </div>
</div>