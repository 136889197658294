import { IsNotEmpty, IsNumber, IsObject, IsOptional } from 'class-validator';
import { Tag } from '../tag/tag';
import { Intent } from '../intent/intent';

export class DefaultCharacter {
  @IsNumber() @IsOptional() id: number;

  @IsNumber() @IsNotEmpty() characterId: number;
  @IsNumber() @IsNotEmpty() leftArmOrder: number;
  @IsNumber() @IsNotEmpty() leftHandOrder: number;
  @IsNumber() @IsNotEmpty() rightArmOrder: number;
  @IsNumber() @IsNotEmpty() rightHandOrder: number;
  @IsNumber() @IsNotEmpty() legOrder: number;

  @IsNumber() @IsOptional() leftAllOrder: number;
  @IsNumber() @IsOptional() rightAllOrder: number;

  @IsNumber() @IsOptional() backHairOrder: number;
  @IsNumber() @IsOptional() faceExpressionOrder: number;
  @IsNumber() @IsOptional() faceShapeOrder: number;
  @IsNumber() @IsOptional() frontHairOrder: number;
  @IsNumber() @IsOptional() accessaryOrder: number;
  @IsNumber() @IsOptional() accessaryHeadOrder: number;
  @IsNumber() @IsOptional() glassesOrder: number;
  @IsNumber() @IsOptional() beardOrder: number;
  @IsNumber() @IsOptional() wrinkleOrder: number;
  @IsNumber() @IsOptional() faceEffectOrder: number;

  // 메뉴 선택을 위한 오더정보
  @IsNumber() @IsOptional() hairSetOrder: number;
  @IsNumber() @IsOptional() bodySetOrder: number;

  @IsObject() @IsOptional() tags: Tag[];
  @IsObject() @IsOptional() intent: Intent;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
