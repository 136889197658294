<ion-card style='background-color: #f3f3f3;'>
  <div *ngIf='user_img_url != null' [style.background-image]="'url(' +user_img_url +')'"
       class='rounded-circle' style='    margin: 10px auto;
    height: 80px;
    width: 80px;background-color: #ffffff;background-position: center;background-size: contain;background-repeat: no-repeat;'
  ></div>
  <div *ngIf='user_img_url === null' class='rounded-circle'
       style='    margin: 10px auto;
    height: 80px;
    width: 80px;background-color: #ffffff;background-image: url("assets/common/avatar.svg");background-position: center;background-size: cover'></div>


  <ion-card-header *ngIf='user_name'>
    <ion-card-title style='text-align: center;'>  {{ user_name }}</ion-card-title>
  </ion-card-header>


</ion-card>
