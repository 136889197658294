<div class='sns-agreement-wrapper'>
  <div class='info-wrapper'>
    <span
      class='email-label-text'>{{"loginPage.agreement-text1" | translate}} <strong>{{("social-login-type." + snsInfo.type) | translate}}</strong> {{"loginPage.agreement-text2" | translate}}</span>
    <div class='email-container'>{{snsInfo.email}}</div>
  </div>
  <!--약관 동의-->
  <div class='agreement-content-wrapper'>
    <app-terms-agreement (changeAgreement)='changeAgreementHandler($event)'></app-terms-agreement>
  </div>
  <button class='signup-button theme-main' (click)='signupButtonClick.emit(snsInfo)'
          [disabled]='!(checkBoxStatus[1]&&checkBoxStatus[2]&&checkBoxStatus[3])'>
    {{"signup start" | translate}}
  </button>
</div>