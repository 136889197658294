import { Injectable } from '@angular/core';
import { GraphqlApiService } from './api/graphql.api.service';
import { gql } from '@apollo/client';
import { environment } from '../../environments/environment';
import { SocialLoginMessageType } from '../enum/app.enum';

@Injectable({
  providedIn: 'root'
})
export class WhalespaceServiceService {
  public accessToken: string;

  constructor(public graphql: GraphqlApiService) {}

  async login(openerWindow) {
    console.log('네이버 웨일 로그인 팝업 띄우기!');
    const popupWidth = 500;
    const popupHeight = 750;

    const popupX = window.screen.width / 2 - popupWidth / 2;
    const popupY = window.screen.height / 2 - popupHeight / 2;

    const popup = window.open(
      `${environment.whalespace.getAuthCodeUrl}?response_type=code&state=exampestatecode&redirect_uri=${environment.whalespace.redirect_uri}&client_id=${environment.whalespace.client_id}`,
      'PopupWin',
      'width=' + popupWidth + ', height=' + popupHeight + ', left=' + popupX + ', top=' + popupY
    );

    const timer = setInterval(() => {
      if (popup.closed) {
        clearInterval(timer);
        openerWindow.postMessage({ messageType: SocialLoginMessageType.popupClosed }, '*');
      }
    }, 500);
  }

  /** @description 인증코드를 서버에 넘기고 인증 토큰을 받음
   * @param authCode 인증토큰을 받기 위한 인증코드
   * getAccessToken(authCode)를 통해서 서버에서 인증토큰 받음
   * @return data.data.getAccessToken 서버에서 받은 인증토큰
   */
  async sendAuthCode(authCode: string) {
    const data = await this.getAccessToken(authCode);

    return data.data.getAccessToken;
  }

  async getAccessToken(authCode: string): Promise<any> {
    return this.graphql
      .query(
        gql`
          query getAccessToken($authCode: String!) {
            getAccessToken(authCode: $authCode)
          }
        `,
        { authCode }
      )
      .toPromise();
  }
}
