<div>
  <ion-toolbar>
    <ion-title>{{ 'replacer.text.changeAllText'| translate}}</ion-title>
    <ion-buttons class='m-0 mr-2' slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</div>

<div class='replacer-content'>
    <div class='replacer-text' >{{ 'replacer.text.replacerText' | translate }}</div>
    <div class='d-flex justify-content-between replacer-choice'>
      <div class='replacer-choice-text '>{{ 'replacer.text.find' | translate }}</div>
        <!--데스크탑 서치 UI-->
        <div *ngIf='app.isDesktopWidth()' class="replacer-button">
            <div class="searchDT">
                <input type="text" class="searchTerm replacer-fontsize" [(ngModel)]='searchText'>
                <button (click)=searchAllText(searchText) class="searchButton">
                    <i class="fa fa-search"></i>
                </button>
            </div>
            <div *ngIf='searchComplete' class='d-flex replacer-total' >{{ 'replacer.total'| translate }}{{totalTextCount }}{{'replacer.text.searched'| translate }}</div>
            <div *ngIf='undefinedText' class='d-flex replacer-total'> {{ 'replacer.text.inputText' | translate}}</div>
            <div *ngIf='multipleSpaceText' class='d-flex replacer-total'> {{ 'replacer.text.twoSpace' | translate}}</div>
            <div *ngIf='singleSpaceText' class='d-flex replacer-total'> {{ 'replacer.text.noSpace' | translate}}</div>
        </div>
        <!--모바일 서치 UI-->
        <div *ngIf='!app.isDesktopWidth()' class="replacer-button">
            <div class="searchMB">
                <input type="text" class="searchTerm replacer-fontsize" [(ngModel)]='searchText'>
                <button (click)=searchAllText(searchText) class="searchButton">
                    <i class="fa fa-search"></i>
                </button>
            </div>
            <div *ngIf='searchComplete' class='d-flex replacer-total' >{{ 'replacer.total'| translate }}{{totalTextCount }}{{'replacer.text.searched'| translate }}</div>
            <div *ngIf='undefinedText' class='d-flex replacer-total'> {{ 'replacer.text.inputText' | translate}}</div>
            <div *ngIf='multipleSpaceText' class='d-flex replacer-total'> {{ 'replacer.text.twoSpace' | translate}}</div>
            <div *ngIf='singleSpaceText' class='d-flex replacer-total'> {{ 'replacer.text.noSpace' | translate}}</div>
        </div>
    </div>
    <div class='d-flex justify-content-between replacer-choice-ch' >
      <div class='replacer-choice-text' >{{ 'replacer.text.replaceWith' | translate }}</div>
        <div *ngIf='app.isDesktopWidth()' class="replacer-button">
            <div class="searchDT">
                <input type="text" class="replaceBox replacer-fontsize" [(ngModel)]='inputText'>
            </div>
        </div>
        <div *ngIf='!app.isDesktopWidth()' class="replacer-button">
            <div class="searchMB">
                <input type="text" class="replaceBox replacer-fontsize" [(ngModel)]='inputText'>
            </div>
        </div>
    </div>
</div>


<ion-footer class='p-2' mode='ios' >
  <div class='d-flex justify-content-between replacer-warning'>
    <ion-icon name='alert-circle-outline' class='replacer-info-icon'></ion-icon>
    <div class='replacer-warning-text'>{{ 'replacer.replaceWarning'  | translate }}</div>
  </div>
  <ion-button  color='main' expand='block' [disabled]="disableNextButton" (click)=textReplacerConfirm() matRipple>
    {{ 'replacer.changeAll' | translate }} </ion-button>
</ion-footer>