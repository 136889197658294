import Dexie from 'dexie';

class EditorDB extends Dexie {
  tooningObjects: Dexie.Table<any, string>;

  constructor() {
    super('EditorDB');
    this.version(1).stores({
      tooningObjects: 'cmd'
    });
    this.tooningObjects = this.table('tooningObjects');
  }
}

export const editorDB = new EditorDB();
