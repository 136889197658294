class BoardAPIRequestError extends Error {
  public readonly errorCode: number;
  public readonly errorMessage: string;
  private readonly functionName: string;
  constructor(errorCode: number, errorMessage: string, functionName: string) {
    super(errorMessage);
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.functionName = functionName;
    this.logError();
  }

  private logError() {
    console.log(`[Board] API request Error in "${this.functionName}". ${this.errorCode} : ${this.errorMessage}`);
  }
}

export default BoardAPIRequestError;
