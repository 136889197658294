import { Injectable } from '@angular/core';
import { merge, Observable, timer } from 'rxjs';
import { SpeedTestService } from 'ng-speed-test';
import { concatMapTo, filter, map, skip, tap } from 'rxjs/operators';
import { NetworkStatus } from '../../enum/NetworkStatus';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  private _delayForNetworkStatus = 6 * 1000;

  private _iterations = 1;
  private _showToastDuration: number = 6000;
  private _delayForPeriod = 20 * 60 * 1000 + this._showToastDuration;
  private _timer = timer(this._delayForNetworkStatus, this._delayForPeriod);

  private _lastNetworkStatus: NetworkStatus | null = null;

  private _enableNetworkStatus: boolean;
  public _subscription$ = null;

  private _beforeNetworkStatusToast: HTMLIonToastElement | null = null;

  private SPEED_THRESHOLD: number = 2.8;

  private SPEED_SECTIONS: Array<number> = [
    1,
    50 / this.SPEED_THRESHOLD,
    100 / this.SPEED_THRESHOLD,
    250 / this.SPEED_THRESHOLD,
    500 / this.SPEED_THRESHOLD
  ].map((num) => Math.floor(num));

  private _localStorageEnableNetworkStatusKey = 'enableNetworkStatus';

  constructor(private speedTestService: SpeedTestService, private toastController: ToastController, private translate: TranslateService) {
    // 모든 ionic component 는 lazy 로딩이라 offline 시 toastController 모듈이 로딩이 안된다 따라서,
    // 아래와 같이 미리 로딩 한번 처리한다.
    // https://github.com/ionic-team/ionic-framework/issues/17450
    this.toastController.create({ animated: false }).then(async (t) => {
      await t.present();
      await t.dismiss();
    });
    const savedEnableNetworkStatus = localStorage.getItem(this._localStorageEnableNetworkStatusKey);
    if (savedEnableNetworkStatus) {
      if (savedEnableNetworkStatus === 'true') {
        this.enableNetworkStatus = true;
      } else {
        this.enableNetworkStatus = false;
      }
    } else {
      this.enableNetworkStatus = true;
    }
  }

  /**
   * _enableNetworkStatus 의 setter 이며,
   * status 가 false 인 경우 기존의 network status 모니터링을 unsubscribe 처리해 작업을 정지한다.
   * @param {boolean} status
   * @return {void}
   */
  set enableNetworkStatus(status: boolean) {
    this._enableNetworkStatus = status;
    if (!status) {
      console.log('network status off');
      this.stop();
    } else {
      console.log('network status on');
      this.start();
    }
    localStorage.setItem(this._localStorageEnableNetworkStatusKey, JSON.stringify(status));
  }

  /**
   * _enableNetworkStatus 의 getter
   * @return {boolean}
   */
  get enableNetworkStatus(): boolean {
    return this._enableNetworkStatus;
  }

  /**
   * 마지막 NetworkStatus 를 리턴하던지 초기 세팅이 전이라면 null 을 리턴 한다.
   * 사용하는 쪽에서 null 체크 필수
   * @return {NetworkStatus | null}
   */
  get lastNetworkStatus(): NetworkStatus | null {
    return this._lastNetworkStatus;
  }

  /**
   * 마지막 NetworkStatus 를 저장한다.
   * @param {NetworkStatus} status
   */
  set lastNetworkStatus(status: NetworkStatus) {
    this._lastNetworkStatus = status;
  }

  /**
   * 기본 5m 짜리 이미지를 다운로드 하여 인터넷속도를 mbps 로  측정 후, NetWorkStatus 로 변경해주는 내부함수이다.
   * @return {Observable<NetworkStatus>}
   */
  _getMbps(): Observable<NetworkStatus> {
    return this.speedTestService
      .getMbps({
        iterations: this._iterations
      })
      .pipe(
        map((mbps) => {
          return this.getStatus(mbps);
        })
      );
  }

  /**
   * Network status 모니터링을 모니터링 시작한다.
   * @return void
   */
  start(): void {
    if (this._subscription$) {
      this._subscription$.unsubscribe();
      this._subscription$ = null;
    }
    this._subscription$ = this.networkStatus()
      .pipe(filter(() => this._enableNetworkStatus))
      .subscribe(async (status: NetworkStatus) => {
        await this.showToastForNetWorkStatus(status, this._showToastDuration);
      });
  }

  /**
   * _beforeNetworkStatusToast 가 존재하면 dismiss 한다.
   * _subscription$ 이 존재하면 unsubscribe 한다.
   * @return {void}
   */
  stop(): void {
    if (this._subscription$) {
      this._subscription$.unsubscribe();
      this._subscription$ = null;
    }
    if (this._beforeNetworkStatusToast) {
      this._beforeNetworkStatusToast.dismiss().then(() => {});
    }
  }

  /**
   * 네트워크 상태를 보여주는 toast 입니다. toast 의 클래스 custom해서 사용합니다.
   * css 내용은  src/style/tooning/network-status/toast.scss 에 정의 되어 있습니다.
   * @param {number} duration - toast 지속 시간
   * @param {NetworkStatus} status - 네트워크 상태
   * @param {boolean} timeOut - 타임아웃 여부
   * @returns {Promise<void>}
   */
  public async showToastForNetWorkStatus(status: NetworkStatus, duration = this._showToastDuration, timeOut = false): Promise<void> {
    if (!this._enableNetworkStatus) {
      return;
    }
    if (this._beforeNetworkStatusToast) {
      await this._beforeNetworkStatusToast.dismiss();
    }
    let toast;
    let message = this.translate.instant(`networkStatus.${status}`);
    let saveError = this.translate.instant(`networkStatus.saveError`);
    let cssClass = timeOut ? `network-status-timeOut-${status}` : `network-status-${status}`;
    switch (status) {
      case NetworkStatus.verySlow:
      case NetworkStatus.slow:
        message = message + `<br/>${saveError}`;
        break;
    }
    this._beforeNetworkStatusToast = toast = await this.toastController.create({
      message,
      position: timeOut ? 'bottom' : 'top',
      duration,
      icon: '',
      cssClass,
      buttons: [
        {
          role: 'cancel'
        }
      ]
    });

    toast.onDidDismiss().then(() => {});

    await toast.present();
  }

  /**
   * isOnline, getMbps 를 병합해 하나의 Observable 로 만들어 네트워크상태를 전달한다.
   * 이때, online, offline, verySlow, slow 의 경우만 전달한다.
   * @return {Observable<NetworkStatus>}
   */
  networkStatus(): Observable<NetworkStatus> {
    return merge(this.scheduledGetMbps(), this.isOnline()).pipe(
      filter((status: NetworkStatus) => {
        let result;
        switch (status) {
          case NetworkStatus.online:
          case NetworkStatus.offline:
          case NetworkStatus.verySlow:
          case NetworkStatus.slow:
            result = true;
            break;
          default:
            result = false;
            break;
        }
        return result;
      }),
      tap((status) => {
        this.lastNetworkStatus = status;
      })
    );
  }

  /**
   * 일정 간격으로 인터넷 속도를 측정한다.
   * @return {Observable<NetworkStatus>}
   */
  scheduledGetMbps(): Observable<NetworkStatus> {
    return this._timer.pipe(concatMapTo(this._getMbps()));
  }

  /**
   * 온라인 오프라인을 확인한다.
   * offline 인 경우는 getStatus 에서 mbps = 0 인 경우랑 겹친다.
   * @return {Observable<NetworkStatus>}
   */
  isOnline(): Observable<NetworkStatus> {
    return this.speedTestService.isOnline().pipe(
      skip(1),
      map((status) => {
        return status ? NetworkStatus.online : NetworkStatus.offline;
      })
    );
  }

  /**
   * 네트워크상태를 offline, verySlow, slow, fast, veryFast 로 나눈다.
   * offline 인 경우, mbps 가 0 으로 계속 온다
   * https://www.google.com/url?sa=i&url=https%3A%2F%2Fnetworkshardware.com%2Finternet-speed%2F&psig=AOvVaw0PxGICZVd8UZjtExt5CWah&ust=1675155460165000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCODC_ML27vwCFQAAAAAdAAAAABAE
   * @param {number} mbps
   * @return {NetworkStatus}
   */
  getStatus(mbps: number): NetworkStatus {
    let result: NetworkStatus;
    if (mbps >= this.SPEED_SECTIONS[4]) {
      result = NetworkStatus.veryFast;
    } else if (this.SPEED_SECTIONS[3] <= mbps && mbps < this.SPEED_SECTIONS[4]) {
      result = NetworkStatus.fast;
    } else if (this.SPEED_SECTIONS[2] <= mbps && mbps < this.SPEED_SECTIONS[3]) {
      result = NetworkStatus.average;
    } else if (this.SPEED_SECTIONS[1] <= mbps && mbps < this.SPEED_SECTIONS[2]) {
      result = NetworkStatus.slow;
    } else if (this.SPEED_SECTIONS[0] <= mbps && mbps < this.SPEED_SECTIONS[1]) {
      result = NetworkStatus.verySlow;
    } else {
      // mbps = 0 으로 오는 경우가 있다.
      result = NetworkStatus.offline;
    }
    return result;
  }
}
