import { ElementRef, Injectable, QueryList, ViewChildren } from '@angular/core';
import { fabric } from 'fabric';
import { AppService } from '../../services/app.service';
import { IndexedDBService } from '../../services/indexedDB/indexed-db.service';
import { AlertController, IonInput, ModalController, Platform } from '@ionic/angular';
import { Loading } from '../../services/loading';
import { Page } from '../../model/page/page';
import { ResMakerCharacterService } from '../../services/res-maker/character.service';
import { CanvasService } from '../../services/canvas.service';
import { PageService } from '../../services/page.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { debounceTime, shareReplay, take } from 'rxjs/operators';
import { Cache } from '../../interfaces/cache.interface';
import { FileService } from '../../services/file.service';
import { Canvas } from '../../model/canvas/canvas';
import { UserResourceType } from './create/create-upload/enum/userResourceType';
import { AnalyticsService } from '../../services/google/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { PaidReferralComponent } from '../../components/paid-referral/paid-referral.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { v1 as uuidv1 } from 'uuid';
import {
  logCustomErrorMessage,
  TooningActivatedBookmarsError,
  TooningAddPageError,
  TooningChangeObArmError,
  TooningChangeObArmGetFabricHttpError,
  TooningChangeObItemError,
  TooningChangeObItemGetFabricHttpError,
  TooningColorSetAddError,
  TooningCustomClientError,
  TooningErrorCode,
  TooningGetEmotionError,
  TooningGetEmotionTimeOutError,
  TooningGetLastObjectError,
  TooningLoadFromJsonEmptyError,
  TooningPageUpdateError,
  TooningPageUpdateTimeOutError,
  TooningRemovePagesError,
  TooningSetBookmarkError,
  TooningSetGroupWaterMark,
  TooningSetResourceRealAreaForAi,
  TooningSetWaterMark
} from '../../pages-tooning/errors/TooningErrors';
import * as _ from 'lodash';
import { PanelUpdateTypeEnum } from '../../enum/panelUpdateType.enum';
import { PanelUpdateTriggerEnum } from '../../enum/panelUpdateTrigger.enum';
import { PageUpdate, PageUpdateComplete } from '../../interfaces/panelUpdate.interface';
import * as WebFont from 'webfontloader';
import { LegacyApiService } from '../../services/api/legacy.api.service';
import { EventHandlerInterface } from '../../interfaces/eventHandler.interface';
import * as guideMaskJson from './guideMask.json';
import {
  AuthorType,
  BlockType,
  CanvasSize,
  CanvasTitle,
  CharacterDirection,
  characterResourcePartType,
  CursorType,
  DefaultLoading,
  EtcSize,
  FillType,
  FormatConvert,
  IndexedDBCmd,
  InputType,
  LanguageType,
  ObjectTransform,
  outsideBGColor,
  PaymentInfoType,
  PrimitiveType,
  PromiseStatus,
  ResourceName,
  ResourceType,
  SelectedType,
  ServiceType,
  SideBarType,
  SkuType,
  SourceType,
  TemplateLineCount,
  TemplatePlatformSize,
  TemplateSize,
  TextStyleType,
  UserRole,
  Version
} from '../../enum/app.enum';
import { ResourceType3d } from '../../enum/app3d.enum';
import HttpStatusCode from '../../pages-tooning/enums/httpErrors.enum';
import { CharacterMake } from '../../services/character-make';
import { ResMakerEtcUloadService } from '../../services/res-maker/etcUpload.service';
import { Group } from 'fabric/fabric-impl';
import { ModelDefaultWordData } from '../../services/defaultWord/defaultWord.service.model';
import { MyColorService } from '../../services/myColor/myColor.service';
import { Sku } from '../../model/sku/sku';
import { data } from '../../json/tooning-default-data';
import {
  BoundingCheck,
  CharacterFeatureLimitInterface,
  IndexInfo,
  Laboratory,
  OutsideBG,
  SetLanguageInterface,
  SnapHorizontalCoords,
  SnapVerticalCoords,
  TooningFabricObject,
  TooningPage,
  UpdatePage
} from '../../interfaces/app.interface';
import { EtcUpload } from '../../model/etcUpload/etcUpload';
import { CommandManager } from '../../command-manager/command-manager';
import { ObjectChangeCommand } from '../../command-manager/commands/object-change-command';
import { PageRemoveCommand } from '../../command-manager/commands/page-remove-command';
import { ObjectAddCommand } from '../../command-manager/commands/object-add-command';
import { ObjectRemoveCommand } from '../../command-manager/commands/object-remove-command';
import { ObjectFlipXCommand } from '../../command-manager/commands/object-flipX-command';
import { ObjectFlipYCommand } from '../../command-manager/commands/object-flipY-command';
import { ObjectAddMultiCommand } from '../../command-manager/commands/object-add-multi-command';
import { PageAddCommand } from '../../command-manager/commands/page-add-command';
import { ObjectGroupCancelCommand } from '../../command-manager/commands/object-group-cancel-command';
import { ObjectGroupCommand } from '../../command-manager/commands/object-group-command';
import { ObjectIndexChangeSetCommand } from '../../command-manager/commands/object-index-change-set-command';
import { ObjectLockSetCommand } from '../../command-manager/commands/object-lock-set-command';
import { PageSwitchCommand } from '../../command-manager/commands/page-switch-command';
import { PageChangeCommand } from '../../command-manager/commands/page-change-command';
import { CustomLoginUser } from '../../model/user/cutom/cutomUser';
import { UserLoginType } from '../../enum/UserLoginType.enum';
import { JsonChangeCommand } from '../../command-manager/commands/json-change-command';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CharacterSvgComponent } from './property/character-svg/character-svg.component';
import { globalLanguageFont } from './create/create-text/globalLanguageFont';
import { Cut4ListService } from '../cut4-list/cut4-list.service';
import { HotkeyComponent } from './modal/hotkey/hotkey.component';
import { EditerSettingModalComponent } from './modal/editer-setting-modal/editer-setting-modal.component';
import { LaboratoryModalComponent } from './modal/laboratory-modal/laboratory-modal.component';
import * as bgColorObjJson from './bgColorObj.json';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { CommandManagerDrawing } from '../../command-manager-drawing/command-manager-drawing';
import JSConfetti from 'js-confetti';
import { CanvasBgColorChangeCommand } from '../../command-manager/commands/canvas-bgColor-change-command';
import { RegexService } from '../../services/regex.service';
import { BoardCommonService } from '../../board/shared/services/board-common.service';
import { BookmarkService } from '../../services/bookmark/bookmark.service';
import { Bookmark, BookmarkResourceType } from 'src/app/directive/bookmark/bookmark';
import { PagesRemoveCommand } from '../../command-manager/commands/pages-remove-command';

const FILE_NAME: string = 'cut4-make-manual.service.ts';
const TOP = 540;
const LEFT = 540;
const TEXT_LIMIT_LENGTH = 500;
const NEW: string = 'new';
const NEW_MAGIC: string = 'new_magic';
const MAGIC: string = 'magic';
const WATERMARK_NOT_NEEDED_ROLES: UserRole[] = [UserRole.admin, UserRole.template, UserRole.textTemplate, UserRole.meme];

@Injectable({
  providedIn: 'root'
})
export class Cut4MakeManualService {
  @ViewChildren('colorPickerComponent')
  public activePageIndex: number = 0;
  public colorPickerComponent: QueryList<ColorPickerComponent>;
  public isPageSwitched: boolean = false;
  public isClipboardCopy: boolean = false;
  public isShowPreviewCanvas: boolean = false; // 이전 이미지를 보이게 할지 말지
  public isCutEnabled: boolean = false;
  public isModalOpend: boolean = false;
  public addedPageId: number;
  public beforeDataLessJsonString: string;
  public afterDataLessJsonString: string;
  public isAltCopied: boolean = false;
  public textForTtt: string;
  public dragTargetOb = []; // 캔버스 드레그 시 오브젝트가 따라 움직이는것을 방지하고자 만들 플레그
  // 따라 움직이면서 포지션이 조금 바낌.
  public pointImgUrl = '/assets/tran_1px.png';
  public imageComponentSegmentIndex = 0;
  public afterAIView = false;
  public isPanelEditPcComponent: boolean;
  public characterId: number;
  public isSavePocessingComplete: number; // 0 저장 시작 , 1 쿼리 완료 , 2 UI 잠깐보이기 , 3 최종 숨김
  public clipboardVersion = true;
  public isShiftKeyPushed: boolean = false;
  public panelIsRemoving = false;
  public title: string;
  public isMobileAddItem: boolean = false; // 모바일 + 버튼이 눌렸느냐?
  public isLayerOpen = false; // 레이어 팝업 처리
  public layerPinActive = false; // 레이어 팝업 고정
  public isLayerOpenTime = true; // 레이어 팝업 연속 터치 방지
  public characterV2List = [809, 838, 839, 840, 815, 827, 831, 832, 833, 834, 845];
  public characterIsTransparencyIcon = '/assets/4cut-make-manual/characterTransparencyIcon.svg'; // 케릭터 투명 리소스 이미지 경로
  public moveInfo = {
    isMove: false,
    startX: 0,
    startY: 0,
    nowX: 0,
    nowY: 0,
    startObjectLeft: 0,
    startObjectTop: 0,
    nowObjectLeft: 0,
    nowObjectTop: 0
  }; // 무빙할때 초기 정보 넣기
  public waterMarkDummy: any;
  public applyWaterMarkList = ['character-svg', 'background-svg', 'background', 'item', 'item-svg', 'Group']; // 워터마크 적용 타입
  public copyFunctionState = true;
  public notPurchasedCharacterListInSku: Array<Sku> = []; // 구매하지 않은 '캐릭터' 상품 리스트
  public notPurchasedEtcUploadListInSku: Array<Sku> = []; // 구매하지 않은 Etc 상품 리스트
  public purchaseSkuList: Array<Sku> = []; // 캔버스에서 구매 가능한 상품 리스트
  public downLoadFileType: string;
  public printType: string;
  public multipleSelectionMode = false;
  public multipleSelectionList = [];
  public selectColor: string;
  public searchTextString: string;
  public isAllLock = {
    selectable: false,
    isSame: false
  };
  public controllerPostion2 = 'calc(48% - 111px)';
  public textBoxButton: ElementRef;
  public characterButton: ElementRef;
  public pipetteInfo: ElementRef;
  public pipetteX: number = 0;
  public pipetteY: number = 0;
  public pipetteDisplay: string = 'none';
  public pipetteHex: string;
  public background3DButton: ElementRef;
  public moveButton: ElementRef;
  public isMoveButton = false;
  public isMoveButtonDrag = false;
  public isMoveButtonStartX = 0;
  public isMoveButtonStartObX = 0;
  public isMoveButtonStartY = 0;
  public isTextTemplate;
  public isMoveButtonStartObY = 0;
  public isMoveButtonX = 0;
  public isMoveButtonY = 0;
  public selectedBase64: string | SafeUrl;
  public selectedCanvas;
  public isToEditorUrl: boolean;
  public historyColores: Array<string> = [];
  public dataLessList = ['historyColores'];
  public maxColorLength = 9;
  public myColorList: Array<{ id: number | undefined; color: string }> = []; // 모든 컬리 리스트
  public viewColor: Array<{ id: number | undefined; color: string }> = []; // 보여지고 있는 컬리 리스트
  public selectedColorId: number;
  public isMyColorBoxOpen = false;
  public isMyColorEditMode = false;
  public myColorHistory: Array<{ id: number | undefined; color: string }> = [];
  public isControllerPostionTop = false;
  public startColorPopup = false;
  public iPhone6Width = 375;
  public iPhone6PlusWidth = 414;
  public iPone5Width = 320;
  public selectionColorItem: any; // SVG 오브젝트 내의 OB 리스트 - 칼라 변경될때 임시로 담아놔서 일괄변경처리하기 위한 변수
  public freeSetColor = [
    '#4D4D4D',
    '#999999',
    '#FFFFFF',
    '#F44E3B',
    '#FE9200',
    '#FCDC00',
    '#DBDF00',
    '#A4DD00',
    '#68CCCA',
    '#73D8FF',
    '#AEA1FF',
    '#FDA1FF',
    '#333333',
    '#808080',
    '#cccccc',
    '#D33115',
    '#E27300',
    '#FCC400',
    '#B0BC00',
    '#68BC00',
    '#16A5A5',
    '#009CE0',
    '#7B64FF',
    '#FA28FF'
  ];
  public allPathlist: any;
  public charactersList = [];
  public drawingWidth = 10;
  public timerId;
  public timerIdSavePocessing;
  public firstTimeOnlyCheck: boolean;
  public isEditPanelMobile = false;
  public isMultiSelectionText = false;
  public isCharacterDirection = false;
  public isTextBoxButton = false;
  public isCharacterButton = false;
  public isBackground3DButton = false;
  public isCharacterButtonMenu = true;
  public resourceDirection = 0;
  public bgImg: ElementRef = null;
  public fabAddIcon: ElementRef;
  public canvasDomain: ElementRef;
  public bgImgWidth: number;
  public controlBox: ElementRef;
  public viewRatio: number;
  public canvasContainer: any;
  public helpGuideComplete: boolean;
  public prevCopyIndex: number = 0;
  public isViewAiButtonGroup = false;
  public userRole: UserRole;
  public helpGuidePopup = {
    x: 0,
    y: 0
  };
  public newCanvasId: number;
  public canvasSizeNull = {
    id: 0,
    name_en: 'Social media',
    size: '1080 x 1080px',
    w: 1080,
    h: 1080,
    web: 1,
    print: 2.08333
  };
  public canvasSize: any;
  public canvasSizeString: string;
  public popAlert: any;
  public isAiBtnView: boolean;
  public isTextCopyBtnView: boolean;
  public textCopyString: string = null;
  public textTabIndex: number;
  public widthOrigin: number;
  public heightOrigin: number;
  public canvas: any;
  public zoomData: number;
  public existGroupStroke = false;
  public isItemSvgGroup: boolean;
  public selectedOb: any;
  public selectedObAngle = 0;
  public speechOb: any;
  public selectedResourceType: any;
  public pageList: Array<TooningPage>;
  public panelIndex: number;
  public viewOrientation: string;
  public viewAuthorization: string;

  public tooningTabScrollPosition = 0;
  public outsideTabScrollPosition = 0;
  public scrollPosition = 0;
  public scrollDuration = this.app.isMobile() ? 800 : 450;
  public resetCanvasSize;
  public resetPageList = [];
  public localStorageId;
  public prevLocalStorageId;
  public prevClonedObj;
  public selectedColorList: Array<number> = [];
  public controlStyle = {
    transparentCorners: false,
    cornerStyle: 'circle',
    cornerSize: 15,
    cornerStrokeColor: '#FFFFFF',
    cornerColor: 'rgb(86,118,255)',
    borderColor: 'rgb(54,89,255)',
    originX: 'center',
    originY: 'center',
    selectable: true,
    evented: true
  };
  public characterFeatureLimit: CharacterFeatureLimitInterface = {
    aiLimit: false,
    skinLimit: false,
    bitmapFilterLimit: false,
    layerSeparationLimit: false,
    faceHidingLimit: false
  };
  // strokeUniform : true // 선이 안찌러지게 처리하는 속성  단점 이미지를 작게줄이면 선이 두드려져보임.
  public filters = [
    'grayscale',
    'invert',
    'remove-color',
    'sepia',
    'brownie',
    'brightness',
    'contrast',
    'saturation',
    'noise',
    'vintage',
    'pixelate',
    'blur',
    'sharpen',
    'emboss',
    'technicolor',
    'polaroid',
    'blend-color',
    'gamma',
    'kodachrome',
    'blackwhite',
    'blend-image',
    'hue',
    'resize'
  ];
  public isBackdisabled: boolean;
  public isNewCanvas = false;
  public isNextdisabled: boolean;
  public balloonEditing: fabric.Group;
  public nullJson = data.nullJson;
  public nullBase64 = data.nullBase64;
  public nullBackgroundImage = data.defaultBackgroundImage;
  public isPanelsInitFinished = false;
  public scrollContent;
  public pageHeight: number;
  public isDownSpaceKey: any;
  public isDownMouseLeftKey: any;
  public guideMask: any;
  public bgColorObj: any;
  public bgColorPick: string;
  public renderer: any;
  public showSearchButton = false;
  public showSearchBar = false;
  public clonedJson = null;
  public isCharacterOut = false;
  public segmentZIndex = 10;
  public searchZIndex = 0;
  public addSelectedID = SideBarType.integrateSearch;
  public addSelectY: number;
  public isShowSearchBar = true;
  public segmentIndex: number;
  public searchText: string;
  public isColorBox = false;
  public searchObj = {
    viewStatus: true, // 검색창 보이도록 할건지
    searchText: '',
    isTemplateDetail: false,
    templateId: -1,
    fromIntegrateSearch: false
  };
  public photoList = [];
  public integratedInitWord: ModelDefaultWordData;
  public imageInitWord: ModelDefaultWordData;
  public isAddGuideLine = false;
  public isSplitByWord = false;
  public isObjSplitByWord = false;
  public allSelectComment: string;
  // 오브젝트 이동, 스케일, 회전 중 Flag
  public isObjectMoving = false;
  public controlBoxWidth: any;
  public updatePageComplete$: Subject<PageUpdateComplete> = new Subject();
  public toPngMaxScale = 1724; // toPngItem() 가능한 최대 height||width
  // 무료 사용자 관련
  public freeUserPageLimit = 10; // 무료 사용자 페이지 수 제한
  public freeUserCanvasLimit = 3; // 무료 사용자 캔버스 수 제한
  public isVisibleWaterMark = false;
  public pageScroller;
  // 워터마크 크기 계산용
  public isTextSelectionUpdated = false;
  public isSelectionUpdated = false;
  public pointElement: ElementRef;
  // 마우스 좌표 저장
  public mousePoint = {
    x: 0,
    y: 0
  };
  public altKeyCopy = false; // 마우스 무빙시 얼랏키누르면 복제로 처리
  public characterPartsCount = 13; // 캐릭터 리소스 파트 개수
  public commandManager: CommandManager;
  public commandManagerDrawing: CommandManagerDrawing;
  public characterLengthOnPage = 0;
  public isPageListEditMode: boolean;
  public isCheckedAll: boolean;
  public filterDetailChanged: boolean;
  private _snapStatus: boolean;
  public inputDebounceTime = 500;
  public isSubmitAlertOpen: boolean = false;
  public objectCachImgView: ElementRef;
  public showPreviewCanvasView: ElementRef;
  public objectCachSrc: string;
  public previewCanvasViewSrc: string | SafeUrl;
  public isObjectCachView: boolean;
  public isRemovedWaterMark: boolean = false;
  public paidPopupWidthForDesktop = '440px';
  public paidPopupWidthForMobile = '90%';
  public paidPopupHeightForDesktop = '600px';
  public paidPopupHeightForMobile = '80%';
  public isFromDemoLogin: boolean = false;
  public laboratory: Laboratory = { grid: false, splitByWord: false, showPreview: false };
  public BookmarkResourceType = BookmarkResourceType;
  private keyActivation: boolean;
  private intentList: any;
  private alertController = new AlertController();
  private loading = new Loading();
  private caches: Cache = {};
  private corsOption = {
    crossOrigin: 'anonymous'
  };
  private updatePage$: Subject<PageUpdate> = new Subject();
  private isPanelChanging = false;
  private fontLoadTimeOut = 3 * 1000;
  private objectEventHandlers: EventHandlerInterface = {};
  private canvasEventHandlers: EventHandlerInterface = {};
  private onHistoryTimer = null;
  private onHistoryTimeOut = 300;
  private debounceTime = 10 * 1000;
  private subscriptions = [];
  public pastedData: string = '';
  public pasteStartIndex: number;
  private isAddTextClick: boolean = false;
  public pageSavingCheckTimer: any;
  private pageSavingInterval: number = 1000;
  private pageSavingCheckCount: number = this.pageSavingInterval;
  private maxPageSavingCheckCount: number = 3 * this.pageSavingInterval;
  public isChangeColorCancel: boolean = false;
  public isPagesRemoveCommand: boolean = false;
  public ResourceType: ResourceType;
  public isBackgroundColorMode: boolean = false;
  public isPasting: boolean = false;
  public isChangeCharacterClicking: boolean = false; // 레이아웃 변경, 캐릭터 머리/몸 각도 변경
  public isChangeAllCharacterClicking: boolean = false; // 캐릭터 정측후 변환
  public isInputFocus: boolean = false;
  public controllerPosition = '52%';
  // 로딩 전 버튼 여러 번 클릭 막기
  public isLandingStartClicked: boolean = false;
  public isMakeToonClicked: boolean = false;
  public isClickedBackgoundColorSettingButton: boolean = false;
  public isClickedOutsideBGColorSettingButton: boolean = false;
  public isGesture: boolean;
  public edgeCaches = null;
  //stable diffusion 메뉴 open/close
  public isSD: boolean = false;
  public isMagic: boolean = false;
  public SDGuide: boolean = false;
  public prompt: string = '';
  public outsideBgColorPick: string;
  public historyBackColor: string;
  // 마우스 안인지 밖인지 체크
  public isOutsideMouse: boolean;
  public outsideBG: OutsideBG = {
    color: outsideBGColor.lightGray,
    number: 1
  };
  public outsideBGs = [
    {
      color: outsideBGColor.white,
      name: 'white'
    },
    {
      color: outsideBGColor.lightGray,
      name: 'light gray'
    },
    {
      color: outsideBGColor.darkGray,
      name: 'dark gray'
    },
    {
      color: outsideBGColor.black,
      name: 'black'
    },
    {
      color: outsideBGColor.otherColor,
      name: 'other color'
    }
  ];

  // 3D 배경 모달 임시 변수
  public background3DModel;
  public isClose3DModal: boolean = true;
  public isResourceButton = false;
  public isTooneed: boolean = false;
  public isSavingCut: boolean = false;

  public imageUrl: string | SafeResourceUrl;
  public pagePreview: NodeJS.Timeout;

  public fontListIndexLanguage: string;
  public fontListIndex = new Map();

  public isViewedBlurWarningAlert: boolean = false;

  public canvasSizeUnit: string = 'px';
  public isMaintainCanvasSize: boolean = false;
  public isLockRatio: boolean = false; // 커스텀에서 가로세로 비율 고정

  public isUndo: boolean = false;
  public isRedo: boolean = false;

  public confetti = new JSConfetti();

  private _localStorageEnableSnapStatusKey = 'enableSnapStatus';

  public firstCopiedTopVal: number;
  public firstCopiedLeftVal: number;
  public characterSegmentIndex: number = 0;

  public beforeOutsideBG: OutsideBG;
  public afterOutsideBG: OutsideBG;

  // 캔버스 대비 리소스 크기
  public FULL_SIZE: number = 1;
  public ALMOST_SIZE: number = 0.9;

  public isColorPicker: boolean = false;

  public isStyleSyncPanelOpen = false;
  public styleSyncProgress: number = 0;

  // popover scale component에서 사용
  public scaleX: string;
  public scaleY: string;
  public scaleXY: string;
  public rotation: string;
  public scaleXStart: number;
  public scaleYStart: number;
  public scaleXYStart: number;
  public rotationStart: number;

  public isUndoing: boolean = false;
  public isRedoing: boolean = false;

  //page 가 add 중인지 확인하는 변수
  public addPageIsWorking: boolean = false;

  public face_expression_index;
  public TooningBoardUpdateDotComplete: boolean;
  public paymentInfoType = PaymentInfoType;
  public pricePlanChoiceInfoType: string = this.paymentInfoType.normal;

  constructor(
    private domSanitizer: DomSanitizer,
    public app: AppService,
    public resMakerCharacterService: ResMakerCharacterService,
    public canvasService: CanvasService,
    public pageService: PageService,
    private http: HttpClient,
    private platform: Platform,
    private fileService: FileService,
    public analyticsService: AnalyticsService,
    private translate: TranslateService,
    public dialog: MatLegacyDialog,
    public appL: LegacyApiService,
    public etcUploadService: ResMakerEtcUloadService,
    public myColorService: MyColorService,
    public modalCtrl: ModalController,
    public cutList: Cut4ListService,
    public bookmarkService: BookmarkService,
    private router: Router,
    public indexedDB: IndexedDBService,
    private regex: RegexService,
    private board: BoardCommonService
  ) {
    this.commandManager = CommandManager.getInstance();
    this.commandManagerDrawing = CommandManagerDrawing.getInstance();
    this.initCanvasHistoryEventHandler();
    this.initObjectEventHandler();
  }

  /**
   * Snap 기능을 초기화 합니다.
   * 기존에 저장된 snap status 가 있으면 그 값을 사용하고 없으면 true 로 초기화 합니다.
   * @return {void}
   */
  initSnap(): void {
    const savedEnableSnapStatus = localStorage.getItem(this._localStorageEnableSnapStatusKey);
    if (savedEnableSnapStatus) {
      if (savedEnableSnapStatus === 'true') {
        this.snapStatus = true;
      } else {
        this.snapStatus = false;
      }
    } else {
      this.snapStatus = true;
    }
  }

  /**
   * snap status 를 변경한다.
   * @param {boolean} status
   * @return {void}
   */
  set snapStatus(status: boolean) {
    this._snapStatus = status;
    if (status) {
      this.initAligningGuidelines(this.canvas, this._snapStatus);
    } else {
      this.canvas.off('mouse:down', this.objectEventHandlers.beforeSnap);
      this.canvas.off('before:render', this.objectEventHandlers.clearSnap);
      this.canvas.off('object:moving', this.objectEventHandlers.objectSnap);
      this.canvas.off('after:render', this.objectEventHandlers.drawSnap);
      this.canvas.off('mouse:up', this.objectEventHandlers.afterSnap);
    }
    localStorage.setItem(this._localStorageEnableSnapStatusKey, JSON.stringify(status));
  }

  /**
   * snap status 를 가져온다.
   * @return {boolean}
   */
  get snapStatus(): boolean {
    return this._snapStatus;
  }

  set setKeyActivation(value: boolean) {
    this.keyActivation = value;
  }

  get getKeyActivation(): boolean {
    return this.keyActivation;
  }

  /**
   * 레이아웃 추천 버튼을 보여줄 지 말지 계산하는 함수
   * @return {boolean}
   */
  showLayoutRecommendBtn(): boolean {
    return this.characterLengthOnPage < 4 && this.characterLengthOnPage > 0;
  }

  /**
   * 페이지 업데이트 함수 생성
   * return {void}
   */
  updatePageSetup(): void {
    this.subscriptions.push(
      this.updatePage$.pipe(debounceTime(this.debounceTime)).subscribe(async (data: PageUpdate) => {
        try {
          // https://github.com/toonsquare/tooning-repo/issues/3372
          // panelIndex 싱크에 주의 해야한다 showPage 전에 showing 할 페이지 인덱스를 미리 할당하면 전 페이지가 다음페이지를 덮었는 문제가 발생한다.
          const page = this.pageList[this.panelIndex];
          if (page) {
            this.app.blueLog(`${this.panelIndex} subject updatePanelSubject type : ${data.type} by :${data.by} `);
            if (page.hasOwnProperty('objectSize')) {
              this.app.blueLog(`object size : ${page.objectsSize}`);
            }

            // https://github.com/toonsquare/tooning-repo/issues/1049
            // page 전환을 빠르게 할때 page 순서가 지켜지지 않고 저장되는 문제 막기 위해  수정
            // page.dirty = true;
            // page.fetched = false;
            this.app.blueLog(`updatePanelSubject fired`);
            page.img = this.capturePng();
          } else {
            console.warn(`update 할 page 가  없습니다.`);
          }
        } catch (e) {
          throw new TooningCustomClientError(e, FILE_NAME, 'updatePageSetup()', this.app, true);
        } finally {
          await this.updatePage(this.panelIndex);
        }
      })
    );
  }

  resetSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * guideMask가 있으면 bringToFront, 없으면 추가 후 bringToFront
   * @return {Promise<void>}
   */
  async guideMaskBringToFront(): Promise<void> {
    try {
      if (this.canvas.hasOwnProperty('_objects')) {
        const getObjects = this.canvas.getObjects();

        let isGuideMaskEmpty = true;
        for (const [_, object] of getObjects.entries()) {
          if (object.resource_type === ResourceType.guideMask) {
            isGuideMaskEmpty = false;
            this.guideMask = object;
            // 사이즈 채크
            this.guideMask.set('originY', 'center');
            this.guideMask.set('originX', 'center');
            this.guideMask.set('top', TOP);
            this.guideMask.set('left', LEFT);
            this.guideMask.set('width', this.canvasSize.w);
            this.guideMask.set('height', this.canvasSize.h);
            this.guideMask.bringToFront();
            this.canvas.requestRenderAll();
          }
        }
        if (isGuideMaskEmpty) {
          this.app.red('guide is empty');
          // @ts-ignore
          await this.addObFromJson([guideMaskJson.default], false, false);
          await this.guideMaskBringToFront();
          const updatedPanel = this.pageList[this.panelIndex];
          updatedPanel.dirty = true;
          await this.updatePage(this.panelIndex);
        }
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'guideMaskBringToFront()', null, true);
    }
  }

  /**
   * 캔버스 이벤트 핸들러 초기화
   * @return {void}
   */
  initCanvasHistoryEventHandler(): void {
    this.canvasEventHandlers.historyAppend = async (event) => {
      console.log('history:append');
      try {
        await this.guideMaskBringToFront();
        this.canvas.historyRedo = []; // redo 히스토리 초기화
        this.historyButtonCheckDisabled();
        this.maskSetting(); // 최상위로 올려주는 코드
      } catch (e) {
        throw new TooningCustomClientError(e.message, FILE_NAME, 'initCanvasHistoryEventHandler()', this.app);
      }
    };
    this.canvasEventHandlers.historyUndo = async () => {
      console.log('undo');
      this.historyButtonCheckDisabled();
      this.checkUpdatePage(PanelUpdateTypeEnum.historyUndo);
      this.maskSetting(); // 최상위로 올려주는 코드
    };
    this.canvasEventHandlers.historyRedo = async () => {
      console.log('redo');
      this.historyButtonCheckDisabled();
      this.checkUpdatePage(PanelUpdateTypeEnum.historyRedo);
      this.maskSetting(); // 최상위로 올려주는 코드
    };
    this.canvasEventHandlers.historyClear = async () => {
      console.log('history:clear');
      this.historyButtonCheckDisabled();
      this.maskSetting(); // 최상위로 올려주는 코드
    };
  }

  /**
   * 캔버스 이벤트 히스토리 관련
   */
  canvasHistoryEventSetup() {
    try {
      if (this.canvas === null) {
        return;
      }
      this.app.orange(`canvasHistoryEventSetup`);
      this.canvas.on('history:append', this.canvasEventHandlers.historyAppend);

      this.canvas.on('history:undo', this.canvasEventHandlers.historyUndo);

      this.canvas.on('history:redo', this.canvasEventHandlers.historyRedo);

      this.canvas.on('history:clear', this.canvasEventHandlers.historyClear);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 캔버스 이벤트 히스토리 관련
   */
  canvasHistoryEventSetupOff() {
    try {
      if (this.canvas === null) {
        return;
      }
      this.app.orange(`canvasHistoryEventSetupOff`);
      this.canvas.off('history:append', this.canvasEventHandlers.historyAppend);

      this.canvas.off('history:undo', this.canvasEventHandlers.historyUndo);

      this.canvas.off('history:redo', this.canvasEventHandlers.historyRedo);

      this.canvas.off('history:clear', this.canvasEventHandlers.historyClear);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Alt 로 복사 붙여넣기
   * @param {TooningFabricObject} ob 원본이 될 리소스
   * @param {number} index 원본이 들어갈 위치 인덱스
   */
  async altCopyAndPaste(ob: TooningFabricObject, index: number) {
    // 지금은 altCopyAndPaste가 실행될 때 커맨드들을 하나의 array에 담고 그 array를 udno & redo하는 식으로 로직을 짰음
    // rxjs를 이용하여 altCopyAndPaste Command를 짜야 함
    ob.visible = true;
    // altCopyAndPaste()함수가 호출되는 경우를 체크하기 위한 boolean
    // Command manager에서 add & move command를 execute할 때 isAltCopied = true 이면 undoHistory에 넣지 않고 altCopyCommands 배열에 push한다.
    this.isAltCopied = true;
    try {
      const command = new ObjectAddCommand({
        cut: this,
        callback: async () => {
          const temp = JSON.stringify(ob.toDatalessObject());
          const addOb = await this.addObFromJson([JSON.parse(temp)], false, false, false, null, null, null, null, false);

          if (ob.group && ob.group.type === SelectedType.activeSelection && this.canvas._activeObject === ob.group) {
            //@ts-ignore
            fabric.util.addTransformToObject(addOb, this.canvas._activeObject.calcOwnMatrix());
          }

          if (ob.hasOwnProperty('isEditableOnSamsungKeyboard')) {
            addOb.set('isEditableOnSamsungKeyboard', false);
          }

          this.canvas.add(addOb);
          // @ts-ignore
          addOb.resource_selection_id = undefined;
          this.assignmentID(addOb);
          // @ts-ignore
          addOb.moveTo(index); // 인덱스 이동
          return addOb;
        }
      });
      if (ob.resource_type === ResourceType.characterSvg) {
        this.characterLengthOnPage += 1;
      }
      await this.commandManager.executeCommand(command);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'altCopyAndPaste()', this.app);
    }
  }

  /**
   * Main thread 에 양보
   * Long task 최적화 : https://web.dev/optimize-long-tasks/?utm_source=devtools
   * @return {Promise<any>}
   */
  yieldToMain(): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(resolve, 0);
    });
  }

  /**
   * Fabric object handler 를 세팅한다.
   * @return {void}
   */
  initObjectEventHandler(): void {
    const modifiedTask = {
      objectMovedTask: (event?): void => {
        if (event.action === ObjectTransform.move) {
          console.log('objectMoved');

          const ob = event.target;

          this.isObjectMoving = false;
          this.isCharacterDirection = true; // 캐릭터 정측후 버튼
          this.isMoveButton = true;
          this.isTextBoxButton = true; // 캐릭터 정측후 버튼
          this.isCharacterButton = true; // 캐릭터 정측후 버튼
          this.isBackground3DButton = true;
          this.isObjectCachView = false;

          setTimeout(() => {
            this.setPositionBoxButton();
            ob.hasControls = true;
            ob.hasBorders = true;

            this.moveInfo.isMove = false;
            this.canvas.setZoom(this.canvas.getZoom()); // out 이벤트가 업데이트가 안되는 버그 개선 코드
            this.canvas.requestRenderAll();
          }, 0);
        }
      },
      objectScaledTask: (event?): void => {
        if (event.action === ObjectTransform.scale) {
          console.log('objectScaled');

          this.isCharacterDirection = true; // 캐릭터 정측후 버튼
          this.isObjectCachView = false;
          this.isMoveButton = true;
          this.isTextBoxButton = true;
          this.isCharacterButton = true;
          this.isBackground3DButton = true;

          setTimeout(() => {
            this.setPositionBoxButton();
          }, 0);
        }
      },
      objectRotatedTask: (event?): void => {
        if (event.action === ObjectTransform.rotate) {
          console.log('objectRotated');

          this.isCharacterDirection = true; // 캐릭터 정측후 버튼
          this.isMoveButton = true;
          this.isTextBoxButton = true; // 캐릭터 정측후 버튼
          this.isCharacterButton = true; // 캐릭터 정측후 버튼
          this.isBackground3DButton = true;
          this.isObjectCachView = false;
          console.log('rotated');
          setTimeout(() => {
            this.setPositionBoxButton();
          }, 0);
        }
      },
      objectSkewedTask: (event?): void => {
        if (event.action === ObjectTransform.skewX || event.action === ObjectTransform.skewY) {
          console.log('objectSkewed');
        }
      },
      /**
       * object:modified 가 왔을때 수행, 페이지 dirty 체크 후 업데이트
       * Long task 최적화 : https://web.dev/optimize-long-tasks/?utm_source=devtools
       * @param event
       * @return {void}
       */
      pageDirtyCheckTask: (event?): void => {
        if (!this.isPanelChanging) {
          // https://github.com/toonsquare/tooning-repo/issues/1049
          // 현재 패널이 업데이트가 있으므로 dirty 를 true 으로 변
          const workingPage = this.pageList[this.panelIndex];
          workingPage.dirty = true;
          this.isSavePocessingComplete = 0;
          clearTimeout(this.timerIdSavePocessing);
          clearTimeout(this.pagePreview);
          this.pagePreview = setTimeout(() => {
            this.updatePagePreviewInLocal(this.panelIndex, true);
          }, 1000);
        }
      }
    };
    this.objectEventHandlers.objectModified = async (event) => {
      this.app.greenLog('modified');

      let deadline = performance.now() + 50;

      const tasks = [
        modifiedTask.pageDirtyCheckTask,
        modifiedTask.objectMovedTask,
        modifiedTask.objectScaledTask,
        modifiedTask.objectRotatedTask,
        modifiedTask.objectSkewedTask
      ];
      while (tasks.length > 0) {
        // Optional chaining operator used here helps to avoid
        // errors in browsers that don't support `isInputPending`:
        //@ts-ignore
        if (navigator.scheduling?.isInputPending() || performance.now() >= deadline) {
          // There's a pending user input, or the
          // deadline has been reached. Yield here:
          await this.yieldToMain();

          // Extend the deadline:
          deadline = performance.now() + 50;

          // Stop the execution of the current loop and
          // move onto the next iteration:
          continue;
        }

        // Shift the the task out of the queue:
        const task = tasks.shift();

        // Run the task:
        task(event);
      }
    };
    this.objectEventHandlers.objectRotating = async (event) => {
      this.isCharacterDirection = false; // 캐릭터 정측후 버튼
      this.isMoveButton = false;
      this.isTextBoxButton = false; //
      this.isCharacterButton = false; //
      this.isBackground3DButton = false;
      console.log('rotating');
    };

    const scalingTasks = {
      /**
       * scaling 시 수행되는 첫번째 task
       * @param event
       * @return {void}
       */
      groupResourceTask: (event): void => {
        const ob = event.target;
        if (ob.resource_type === SelectedType.largeGroup) {
          this.setGroupResourceTextStrokeWidth(ob);
        }
      },
      /**
       * scaling 시 수행되는 두번째 task
       * @param event
       * @return {void}
       */
      waterMarkResizeTask: (event): void => {
        const ob = event.target;
        this.setWaterMarkResize(ob, ob.scaleX, ob.scaleY);
      },
      /**
       * scaling 시 수행되는 세번째 task
       * @param event
       * @return {void}
       */
      setPositionBoxTask: (event): void => {
        this.setPositionBoxButton();
      }
    };

    /**
     * 선택한 오브젝트 크기 변경 이벤트 발생시
     * @param event 크기 변경 이벤트
     */
    this.objectEventHandlers.objectScaling = async (event) => {
      this.isCharacterDirection = false; // 캐릭터 정측후 버튼
      this.isMoveButton = false;
      this.isTextBoxButton = false; // 캐릭터 정측후 버튼
      this.isCharacterButton = false; // 캐릭터 정측후 버튼
      this.isBackground3DButton = false;
      console.log('object:scaling!');

      const tasks = [scalingTasks.groupResourceTask, scalingTasks.waterMarkResizeTask, scalingTasks.setPositionBoxTask];

      let deadline = performance.now() + 50;

      while (tasks.length > 0) {
        // Optional chaining operator used here helps to avoid
        // errors in browsers that don't support `isInputPending`:
        //@ts-ignore
        if (navigator.scheduling?.isInputPending() || performance.now() >= deadline) {
          // There's a pending user input, or the
          // deadline has been reached. Yield here:
          await this.yieldToMain();

          // Extend the deadline:
          deadline = performance.now() + 50;

          // Stop the execution of the current loop and
          // move onto the next iteration:
          continue;
        }

        // Shift the the task out of the queue:
        const task = tasks.shift();

        // Run the task:
        task(event);
      }
    };

    /**
     * 선택한 오브젝트 위치 변경 이벤트 발생시
     * @param event 위치 변경 이벤트
     */
    this.objectEventHandlers.objectMoving = async (event) => {
      try {
        console.log('objectMoving');
        this.isObjectMoving = true;
        this.isCharacterDirection = false; // 캐릭터 정측후 버튼
        this.isMoveButton = false;
        this.isTextBoxButton = false;
        this.isCharacterButton = false;
        this.isBackground3DButton = false;
        const ob = event.target;

        //복제 후 이동
        if (event.e.altKey === true && this.altKeyCopy === false) {
          try {
            await this.altKeyCopyFn(ob);
          } catch (e) {
            throw new TooningCustomClientError(e, FILE_NAME, 'objectEventHandlers.objectMoving', this.app);
          }
        }

        // 시프트 락 기능
        if (!this.moveInfo.isMove) {
          this.moveInfo.isMove = true;
          this.moveInfo.startX = event.e.x;
          this.moveInfo.startY = event.e.y;
          this.moveInfo.startObjectTop = ob.top;
          this.moveInfo.startObjectLeft = ob.left;
        }
        this.moveInfo.nowX = event.e.x;
        this.moveInfo.nowY = event.e.y;
        this.moveInfo.nowObjectTop = ob.top;
        this.moveInfo.nowObjectLeft = ob.left;

        // 스넵 처리 가이드 추가시
        if (this.laboratory.grid) {
          event.target.set({
            left: Math.round(event.target.left / 10) * 10,
            top: Math.round(event.target.top / 10) * 10
          });
        }
      } catch (e) {
        throw new TooningCustomClientError(e, FILE_NAME, 'objectEventHandlers.objectMoving', this.app);
      }
    };

    this.objectEventHandlers.objectRemoved = (event) => {
      console.log('object:removed');
    };

    this.objectEventHandlers.objectAdded = (event) => {
      try {
        if (!this.isPanelChanging) {
          const workingPanel = this.pageList[this.panelIndex];
          workingPanel.dirty = true;
        }
      } catch (e) {
        this.app.orange(e.message);
      }
    };
  }

  /**
   * 캐릭터 동작 체임지 칼라 번쩍이는 부분 대응 함수
   * 칼라가 변경되는 시점에 캐쉬 이미지를 뛰워서 완료 후 감춤.
   * @param {boolean} end
   * @return {void}
   */
  characterActionLoading(end = false): void {
    try {
      if (end) {
        this.isObjectCachView = false;
        this.objectCachSrc = null;
        return;
      }
      this.objectCachSrc = this.selectedOb.toDataURL();
      const ob = this.selectedOb;
      let pointX = [];
      let pointY = [];
      const pointList = ob.getCoords(false, true);
      for (const point of pointList) {
        pointX.push(point.x);
        pointY.push(point.y);
      }
      const width = Math.max(...pointX) - Math.min(...pointX);
      const height = Math.max(...pointY) - Math.min(...pointY);
      const centerX = (Math.max(...pointX) + Math.min(...pointX)) / 2;
      const centerY = (Math.max(...pointY) + Math.min(...pointY)) / 2;
      const top = centerY - height / 2;
      const left = centerX - width / 2;

      this.renderer.setStyle(this.objectCachImgView.nativeElement, 'left', left + 'px');
      this.renderer.setStyle(this.objectCachImgView.nativeElement, 'top', top + 'px');
      this.renderer.setStyle(this.objectCachImgView.nativeElement, 'width', width + 'px');
      this.renderer.setStyle(this.objectCachImgView.nativeElement, 'height', height + 'px');

      this.isObjectCachView = true;
    } catch (e) {
      console.error(e);
    }
  }

  objectEventSetup() {
    this.app.orange('objectEventSetup');
    this.canvas.on('object:modified', this.objectEventHandlers.objectModified);

    this.canvas.on('object:rotating', this.objectEventHandlers.objectRotating);

    this.canvas.on('object:scaling', this.objectEventHandlers.objectScaling);

    this.canvas.on('object:moving', this.objectEventHandlers.objectMoving);

    this.canvas.on('object:removed', this.objectEventHandlers.objectRemoved);

    this.canvas.on('object:added', this.objectEventHandlers.objectAdded);
  }

  objectEventSetupOff() {
    this.app.orange('objectEventSetupOff');

    this.canvas.off('object:modified', this.objectEventHandlers.objectModified);

    this.canvas.off('object:rotating', this.objectEventHandlers.objectRotating);

    this.canvas.off('object:scaling', this.objectEventHandlers.objectScaling);

    this.canvas.off('object:moving', this.objectEventHandlers.objectMoving);

    this.canvas.off('object:removed', this.objectEventHandlers.objectRemoved);

    this.canvas.off('object:added', this.objectEventHandlers.objectAdded);
  }

  balloonTextEdit() {
    this.balloonEditing = this.selectedOb;
    // 텍스트를 그룹에서 제거 후 캔버스에 직접 추가
    this.selectedOb.removeWithUpdate(this.speechOb);
    this.canvas.add(this.speechOb);
    this.speechOb.setCoords();
    this.canvas.requestRenderAll();
    this.onFocus(this.speechOb);
    this.enterEditing();
    // 커서를 맨뒤로
    this.speechOb.setSelectionStart(this.speechOb.text.length);
    this.speechOb.setSelectionEnd(this.speechOb.text.length);
  }

  /** @description 말풍선의 방향에 따라 내부 레이어의 visiable 및 flip조정
   *  @param {fabric.Group} balloon 선택된 말풍선(미지정시 선택중인 말풍선 이미지)
   *  @param {string} direction 말풍선 방향(미지정시 세팅되어 있는 말풍선 방향) - ul,uc,ur,ml,mc,mr,bl,bc,br
   *  @return {void} 없음
   */

  /** @description 말풍선 텍스트 width를 이미지 사이즈에 맞춰 보정
   *  @param {fabric.Group | fabric.Textbox} balloon 말풍선 객체, 혹은 수정중인 말풍선 텍스트 객체
   *  @return {void} 없음
   */
  balloonAdjTextWidth(balloon: fabric.Group | fabric.Textbox) {
    console.log(balloon);
    if (balloon == null) {
      return;
    }
    let bubble: fabric.Path;
    let speech: fabric.Textbox;
    if (balloon instanceof fabric.Group) {
      bubble = balloon.getObjects().find((obj: fabric.Object) => obj.type === 'path' && obj.visible === true) as fabric.Path;
      speech = balloon.getObjects().find((obj: fabric.Object) => obj.type === 'textbox') as fabric.Textbox;
    } else {
      bubble = this.balloonEditing.getObjects().find((obj: fabric.Object) => obj.type === 'path' && obj.visible === true) as fabric.Path;
      speech = balloon;
    }
    const widthRatio = 0.9;
    // @ts-ignore
    speech.set({ width: (bubble.width * (bubble.scaleX / speech.scaleX) - bubble.strokeWidth) * widthRatio });
  }

  // 유저의 포인트를 초기화한다.
  async resetPointData() {
    const result = await this.resMakerCharacterService.resetPointData(this.app.cache.user.id).toPromise();
    if (result.data.resetPointData) {
      await this.app.showToast('포인트와 구매목록이 초기화 되었습니다.', 1000, 'dark', 'middle');
    } else {
      await this.app.showToast('포인트와 구매목록 초기화가 실패하였습니다. 다시 시도해주세요.', 1000, 'dark', 'middle');
    }
  }

  /**
   * 새로운 캔버스를 생성하거나 캔버스로 진입할때 사용하는 함수
   * @param {string} id 아이디 인풋이 없어 default인 'new'일 경우 새로운 캔버스 생성
   * @param {string} title 캔버스 제목
   * @return {Promise<void>>}
   */
  async canvasNewMake(id: string = NEW, title: string = '제목없는 스토리'): Promise<void> {
    if (this.isMakeToonClicked) {
      return;
    }

    this.isMakeToonClicked = true;
    const loading = new Loading();
    await loading.showLoader('');
    try {
      if (!this.app.loginStatus) {
        this.app.go('/login');
        return;
      }
      await this.checkAndSetDefaultCanvasGroups(this.app.cache.user.id);
      this.analyticsService.editor();
      let canvasId = '';

      // 새로 만든 캔버스일 경우
      if (id.includes(NEW)) {
        this.isNewCanvas = true;

        let isShowCanvasCountWarning: boolean = false;

        // 매직에서 띄우는게 아닐 때만 캔버스 초과 안내 모달 보여주기
        if (!id.includes(MAGIC)) isShowCanvasCountWarning = await this.showCanvasCountWarning(loading);

        if (!isShowCanvasCountWarning) {
          const isTextTemplate = await this.app.user.isTextTemplate();

          const newCanvas = new Canvas();
          newCanvas.userId = this.app.cache.user.id;
          newCanvas.canvasSize = JSON.stringify(this.canvasSizeNull);
          newCanvas.outsideBGColor = this.outsideBG.color;
          if (this.cutList.isCanvasGroupView && this.cutList.canvasGroupId && !this.cutList.isTrashView) {
            this.newCanvasId = await this.canvasService.createCanvas(newCanvas, isTextTemplate, +this.cutList.canvasGroupId);
          } else {
            this.newCanvasId = await this.canvasService.createCanvas(newCanvas, isTextTemplate);
          }

          const newCanvas2 = new Canvas();
          const files: Blob[] = [];
          const base64File = await this.fileService.b64toBlob(this.nullBase64, 'base64');

          const titles = {
            [LanguageType.en]: CanvasTitle.titleEn,
            [LanguageType.jp]: CanvasTitle.titleJp,
            [LanguageType.fr]: CanvasTitle.titleFn,
            [LanguageType.ko]: CanvasTitle.titleKo
          };

          newCanvas2.title = titles[this.app.usedLanguage];
          newCanvas2.id = this.newCanvasId;
          newCanvas2.outsideBGColor = this.outsideBG.color;
          // @ts-ignore
          files.push(base64File);

          await this.canvasService.canvasUpdate(newCanvas2, isTextTemplate, files).toPromise();

          await this.createPageNull();
          // @ts-ignore
          canvasId = this.newCanvasId;
        }
      } else {
        canvasId = id;
      }
      if (!canvasId) {
        return;
      }
      try {
        this.app.orange(`canvasId : ${canvasId}`);

        const goRoutingUrl = '4cut-make-manual2/' + canvasId;

        if (id.includes(MAGIC)) {
          const characterId = id.split(NEW_MAGIC)[1];
          this.app.goExternal(goRoutingUrl + '?characterId=' + characterId);
        } else this.app.go(goRoutingUrl);
      } catch (e) {
        this.app.orange(e.message);
        console.error(e);
      } finally {
        loading.hideLoader();
      }
    } catch (e) {
      this.analyticsService.error('cut4-make-manual.service.canvasNewMake', e.message);
      throw new TooningCustomClientError(e, FILE_NAME, 'canvasNewMake()', this.app, true);
    } finally {
      loading.hideLoader();
      this.isMakeToonClicked = false;
    }
  }

  /**
   * 신규 / 수정 캔버스 이동
   * @returns {Promise<void>}
   */
  async canvasNewMakeForDemo(): Promise<void> {
    try {
      if (!this.app.loginStatus) {
        this.app.go('/login');
        return;
      }

      this.analyticsService.editor();
      let canvasId = '';
      this.isNewCanvas = true;

      const isTextTemplate = await this.app.user.isTextTemplate();

      const newCanvas = new Canvas();
      newCanvas.userId = this.app.cache.user.id;
      newCanvas.canvasSize = JSON.stringify(this.canvasSizeNull);
      newCanvas.outsideBGColor = this.outsideBG.color;
      this.newCanvasId = await this.canvasService.createCanvas(newCanvas, isTextTemplate);

      const newCanvas2 = new Canvas();
      const files: Blob[] = [];
      const base64File = await this.fileService.b64toBlob(this.nullBase64, 'base64');

      const titles = {
        [LanguageType.en]: CanvasTitle.demo_titleEn,
        [LanguageType.jp]: CanvasTitle.demo_titleJp,
        [LanguageType.fr]: CanvasTitle.demo_titleFn,
        [LanguageType.ko]: CanvasTitle.demo_titleKo
      };

      newCanvas2.title = titles[this.app.usedLanguage];
      newCanvas2.id = this.newCanvasId;
      newCanvas2.outsideBGColor = this.outsideBG.color;
      // @ts-ignore
      files.push(base64File);

      await this.canvasService.canvasUpdate(newCanvas2, isTextTemplate, files).toPromise();

      await this.createPageNull();
      // @ts-ignore
      canvasId = this.newCanvasId;

      try {
        this.app.orange(`canvasId : ${canvasId}`);
        this.app.go('4cut-make-manual2/' + canvasId);
        this.isFromDemoLogin = true;
      } catch (e) {
        this.app.orange(e.message);
        console.error(e);
      }
    } catch (e) {
      this.analyticsService.error('cut4-make-manual.service.canvasNewMakeForDemo', e.message);
      throw new TooningCustomClientError(e, FILE_NAME, 'canvasNewMakeForDemo()', this.app, true);
    }
  }

  public async loadFromJsonNative(json: string, resolve, reject): Promise<any> {
    return new Promise((resolveInside, rejectInside) => {
      try {
        this.canvas.loadFromJSON(
          json,
          () => {
            this.app.redLog(`loadfromJson done  isPanelChanging: ${this.isPanelChanging}`);
            resolve(() => {
              resolveInside(() => {
                this.forceNoFocus();
              });
            });
          },
          (o, object, error) => {
            if (error) {
              // hotfix 6.11.24 https://www.notion.so/toonsquare/a479a8cdaa0b484482714d4162878ec1
              if (o && o.src && !o.src.includes('assets/4cut-make-manual/watermark_individual.png')) {
                reject(() => {
                  rejectInside(error);
                });
              }
            } else if (!this.app.isSafari()) {
              //저사항 기기에서 WebGL 비장상 작동 - applyFilters
              //미지원 확인 불가. 방어코드로 사용
              //https://github.com/toonsquare/tooning-repo/issues/3175

              if (object.type === 'image' && object.filters.length) {
                object.applyFilters();
              }
              this.canvas.renderAll();
            }
          }
        );
      } catch (e) {
        reject(e);
      }
    });
  }

  public enlivenObjects(array, resolve, reject): void {
    console.log(array);
    this.app.orange(`${new Blob([JSON.stringify(array)]).size / 1024 / 1024}M`);
    // console.time("enlivenObjects");
    // @ts-ignore
    fabric.util.enlivenObjects(array, (objets) => {
      objets.forEach((item) => {
        this.canvas.add(item);
      });
    });
    resolve(true);
    this.canvas.requestRenderAll();
  }

  /**
   * Json 전체를 읽어 오는 함수(화면 전체 캡처한걸 가져온다고 보면됨
   * @param json text 로 변환해서 넘겨야함 > stringify
   */
  public async loadFromJSON(json: string | object, index: number = this.panelIndex): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (json === null) {
          reject(new TooningCustomClientError('json is empty', FILE_NAME, 'loadFromJSON()', this.app, true));
        } else {
          if (_.isObject(json)) {
            this.removeOldWaterMark(json);
          }

          let objectedJson: any;

          // page 갯수 와 pageIndex 싱크 체크
          // panelIndex 는 pageList.length 보다 작어야 한다
          // page = 0 ,1, 2 총 3개의 페이지
          // panelIndex = 0 ~ 2 index 를 가져야한다.
          if (0 < this.pageList.length && this.pageList.length <= this.panelIndex) {
            this.app.orange(`page 갯수와 page index 싱크가 깨졌습니다.`);
            if (this.pageList.length === 1) {
              this.app.orange(`page 가 1개 있으므로 page index 를 ${this.panelIndex}에서 0 으로 변경합니다`);
              this.panelIndex = 0;
            }
          }
          const panel = this.pageList[index];

          objectedJson = typeof json === 'string' ? JSON.parse(json as string) : json;

          // tslint:disable-next-line:no-unused-expression
          if (objectedJson.hasOwnProperty('objects')) {
            this.checkSelectable(objectedJson.objects);
          }
          if (!panel) {
            throw Error(`page[${index}] is empty`);
          }
          const hasFontFamiliesProperty = panel.hasOwnProperty('fontFamilies');
          if (!hasFontFamiliesProperty || (hasFontFamiliesProperty && !panel.fontFamilies)) {
            if (objectedJson.hasOwnProperty('objects')) {
              const fontFamilies = this.getFontFamily(objectedJson.objects);

              this.app.orange(fontFamilies);
              if (fontFamilies.length > 0) {
                WebFont.load({
                  timeout: this.fontLoadTimeOut,
                  custom: {
                    families: fontFamilies
                  },
                  loading: () => {
                    this.app.orange('font init');
                  },
                  fontloading: (familyName: string, fvd: string) => {
                    this.app.orange(`fontloading : ${familyName}`);
                  },
                  fontactive: (familyName: string, fvd: string) => {
                    this.app.greenLog(`fontactive : ${familyName}`);
                  },
                  fontinactive: (familyName: string, fvd: string) => {
                    try {
                      this.app.redLog(`font inactive : ${familyName} : ${fvd}`);
                      const messages = [];
                      messages.push(`fontFamilyName : ${familyName}`);
                      messages.push(`fvd : ${fvd}`);
                      this.app.showToast(`page : ${this.panelIndex + 1}, ${familyName} ${fvd} loading error`, 3000, 'danger', 'middle');

                      logCustomErrorMessage('loadFromJSON', messages.join('\n'), '', FILE_NAME, 'loadFromJSON()', true, this.app.user.getUserEmail());
                    } catch (e) {
                      console.error(e);
                    }
                  },
                  active: async () => {
                    try {
                      this.app.redLog('fonts rendered');
                      panel.fontFamilies = fontFamilies;
                      await this.loadFromJsonNative(objectedJson, resolve, reject);
                    } catch (e) {
                      console.error(e);
                    }
                  },
                  inactive: async () => {
                    this.app.redLog(`fonts inactive`);
                    await this.loadFromJsonNative(objectedJson, resolve, reject);
                  }
                });
              } else {
                panel.fontFamilies = '';
                await this.loadFromJsonNative(objectedJson, resolve, reject);
              }
            } else {
              await this.loadFromJsonNative(objectedJson, resolve, reject);
            }
          } else {
            await this.loadFromJsonNative(objectedJson, resolve, reject);
          }
        }
      } catch (e) {
        throw new TooningCustomClientError(e, FILE_NAME, 'loadFromJSON()', this.app, true);
      }
    });
  }

  /**
   * JSON 가져와서 fabric 으로 변환시켜서 추가
   * 오브젝트를 추가하는것 전체를 갱신하는 함수로 잘 구별해서 사용해야함
   * 스트링 이면 JSON.parse로 변환해서 호출해야함
   * await this.addObFromJson([JSON.parse(스트링이면...)]);
   * @param {Array<TooningFabricObject | string>} listArr 넣어줄 JSON 배열
   * @param {boolean} isFocus 추가하고 선택 할것인지
   * @param {boolean} isAnimate 넣을때 애니메이션 효과 줄것인지
   * @param {boolean} add 캔버스에 추가 해줄것인지
   * @param {CharacterMake} characterMake characterMake service
   * @param {number} moveTo 인덱스 이동
   * @param {number} top top 위치 지정 해줄 값
   * @param {number} left left 위치 지정 해줄 값
   * @param {boolean} isNoFocus 기존 선택 된것 풀것인지
   * @return {Promise<TooningFabricObject>} json 을 해석한 TooningFabricObject
   */
  public async addObFromJson(
    listArr: Array<TooningFabricObject | string>,
    isFocus: boolean,
    isAnimate: boolean = true,
    add: boolean = true,
    characterMake: CharacterMake = null,
    moveTo: number = null,
    top: number = null,
    left: number = null,
    isNoFocus: boolean = true
  ): Promise<TooningFabricObject> {
    const self = this;
    let ob;
    return new Promise((resolve, reject) => {
      // @ts-ignore
      fabric.util.enlivenObjects(listArr, async (objects) => {
        try {
          if (objects.length === 0) {
            reject(new Error('enlivenObjects return empty'));
          }

          for (const o of objects) {
            ob = o;

            this.checkText(ob);
            if (add) {
              if (ob.resource_type === ResourceType.characterSvg) {
                this.characterLengthOnPage++;
              }
              self.canvas.add(ob);
            }

            if (moveTo !== null) {
              ob.moveTo(moveTo); // 인덱스 이동
            }
            if (top !== null) {
              ob.top = top;
            }
            if (left !== null) {
              ob.left = left;
            }
          }

          // renderAll 은 sync 라서 조금 느리다 reqeustAnimationFrame 을 사용한 reqeustRenderAll 이 더 성능이 좋다
          // http://fabricjs.com/v2-breaking-changes
          if (isNoFocus) {
            self.forceNoFocus();
          }
          // 마지막에 추가된 오브젝트
          // ob = self.canvas.getObjects()[self.canvas.getObjects().length - 1];

          if (isFocus) {
            self.onFocus(ob);
          }

          if (Object.getPrototypeOf(ob).hasOwnProperty('getObjects')) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0, j = ob.getObjects().length; i < j; i++) {
              ob.getObjects()[i].paintFirst = 'stroke';
            }

            if (isAnimate && ob.getObjects().length < 500) {
              const setObject = await this.animateObject(ob);
              resolve(setObject);
            } else {
              resolve(ob);
            }
          } else {
            ob.paintFirst = 'stroke';

            if (isAnimate) {
              const setObject = await this.animateObject(ob);
              resolve(setObject);
            } else {
              resolve(ob);
            }
          }
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  /**
   * 아이템 추가 후 애니메이션 시키는 함수
   * @param {TooningFabricObject} ob - 페브릭 오브젝트
   * @return {Promise<TooningFabricObject>} ob에 animation 효과가 추가된 TooningFabricObject
   */
  public async animateObject(ob): Promise<TooningFabricObject> {
    // tslint:disable-next-line:variable-name
    let ob_scaleX;
    // tslint:disable-next-line:variable-name
    let ob_scaleY;
    const opacity = ob.opacity ? ob.opacity : 1;
    const self = this;
    return new Promise((resolve, reject) => {
      try {
        ob_scaleX = ob.get('scaleX');
        ob_scaleY = ob.get('scaleY');
        ob.set('scaleX', ob_scaleX + 0.3);
        ob.set('scaleY', ob_scaleY + 0.3);
        ob.set('opacity', 0);
        ob.animate(
          { scaleX: ob_scaleX, scaleY: ob_scaleY, opacity },
          {
            duration: 250,
            easing: fabric.util.ease.easeInOutSine,
            onChange: self.canvas.requestRenderAll.bind(self.canvas),
            onComplete() {
              // Here
              self.maskSetting();
              resolve(ob);
            }
          }
        );
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * etcUpload sourceType 을 한국어로 변환
   * ex) 'bubble' -> 말풍선
   * @param addSelectedId - 작업툴에서 현재 선택된 sourceType
   */
  changeSourceTypeNameToKr(addSelectedId: string) {
    let sourceType;
    switch (this.addSelectedID) {
      case 'bubble':
        sourceType = SourceType.balloon;
        break;
      case 'item':
        sourceType = SourceType.item;
        break;
      case 'effect':
        sourceType = SourceType.effect;
        break;
      case 'background':
        sourceType = SourceType.background;
        break;
    }
    return sourceType;
  }

  /**
   * 앞에 aws 버킷에 접근하는 url 붙여줌
   * @param url url 붙여줄 파일
   */
  public getHttpUrl(url): Observable<any> {
    const self = this;
    let results$;
    if (this.caches[url]) {
      results$ = this.caches[url];
    } else {
      results$ = this.caches[url] = self.http.get(url).pipe(shareReplay());
    }
    return results$;
  }

  /**
   * 이미지 또는 SVG를 추가하는 함수
   * @param ob - 페브릭 오브젝트
   * @param loading - 로딩
   * @param characterMake - 클래스
   */
  public async addImageAndSvgFromObject(ob, loading = null, characterMake = null): Promise<unknown> {
    // tslint:disable-next-line:no-unused-expression
    return new Promise(async (resolve, reject) => {
      if (!ob?.src) {
        reject(new Error('selected object is empty'));
      }

      this.app.greenLog(`upload item type : ${ob.type}`);

      if (!ob.type) {
        throw new Error(`object type is empty : ${ob.type}`);
      }

      if (this.guideMask === null) {
        await this.guideMaskBringToFront();
      }

      if (ob.type === UserResourceType.vector) {
        // @ts-ignore
        fabric.loadSVGFromURL(
          ob.src,
          async (objects, options) => {
            const loadedObjects = fabric.util.groupSVGElements(objects, options);
            // @ts-ignore
            loadedObjects.set({
              originX: 'center',
              originY: 'center',
              left: LEFT,
              top: TOP
            });
            // @ts-ignore
            loadedObjects.set('resource_type', 'item-svg');

            if (characterMake !== null) {
              await characterMake.setResourceRealArea(loadedObjects, this.canvas);
            }

            this.svgDefaultColorSet(loadedObjects);

            if (ob.resource_name === undefined) {
              // @ts-ignore
              loadedObjects.set('resource_name', this.translate.instant('noname'));
            } else {
              // @ts-ignore
              loadedObjects.set('resource_name', ob.resource_name);
            }

            // @ts-ignore
            loadedObjects.set('resource_preview', ob.resource_preview);

            this.setResourceScale(loadedObjects, this.FULL_SIZE);
            this.canvas.add(loadedObjects);
            loadedObjects.setCoords();
            resolve(loadedObjects);
          },
          null,
          this.corsOption
        );
      } else {
        this.app.greenLog(`image toDataUrl start`);
        let imgBase64 = await this.imgToDataURL(ob.src);
        this.app.greenLog(`image toDataUrl finish`);

        if (typeof imgBase64 === 'string') {
          let imgObj = new Image();
          imgObj.src = imgBase64;
          imgObj.crossOrigin = 'anonymous';

          const canvas = this.canvas;

          try {
            imgObj.onload = () => {
              const img = new fabric.Image(imgObj);

              this.app.greenLog(`fromUrl`);
              const width = +img.get('width');
              const height = +img.get('height');
              const longIsWidth = width > height;
              console.log(`width : ${width} | height : ${height}`);
              console.log(`longIsWith : ${longIsWidth}`);

              img.set({
                originX: 'center',
                originY: 'center',
                left: LEFT,
                top: TOP,
                scaleX: 0.7,
                scaleY: 0.7
              });

              // @ts-ignore
              img.set('resource_type', 'item');
              // @ts-ignore

              if (ob.resource_name === undefined) {
                // @ts-ignore
                img.set('resource_name', this.translate.instant('noname'));
              } else {
                // @ts-ignore
                img.set('resource_name', ob.resource_name);
              }

              // @ts-ignore
              img.set('resource_preview', imgBase64);

              this.setResourceScale(img, this.FULL_SIZE);
              canvas.add(img);
              img.setCoords();
              canvas.renderAll();

              resolve(img);
            };
          } catch (e) {
            this.analyticsService.error('cut4-make-manual.service:addImageAndSvgFromObject', e.message);
            this.app.orange(e.message);
            reject(e);
          }
        }
      }
    });
  }

  /**
   * 에디터에서 작업 중 복사 되어 clipbaord 에 있는 데이터를 붙여 넣기 시 데이터를 로딩하는 함수
   * @param {DataTransferItemList} items clipboard 에 있는 데이터
   * @return {Promise<boolean | Error>}
   */
  public async addImageAndSvgFromObjectForHandler(items: DataTransferItemList): Promise<boolean | Error> {
    return new Promise(async (resolve, reject) => {
      try {
        const self = this;
        let blob = null;
        for (let i = 0, j = items.length; i < j; i++) {
          const item = items[i];
          // 이미지 처리 - 스크린 캡처 또는 드래그 할때
          if (item.type.includes('image')) {
            blob = item.getAsFile();

            // load image if there is a pasted image
            if (blob === null) {
              continue;
            }
            const reader = new FileReader();

            if (blob.type === 'image/svg+xml') {
              reader.readAsText(blob);
            } else {
              reader.readAsDataURL(blob);
            }

            // tslint:disable-next-line:only-arrow-functions no-shadowed-variable
            reader.onload = function ($event) {
              if (blob.type === 'image/svg+xml') {
                console.log('image/svg+xml svg 드레그');
                const svgString = reader.result;
                fabric.loadSVGFromString(svgString as string, async (objects, options) => {
                  // fabricObject group화
                  const loadedObjects = fabric.util.groupSVGElements(objects, options);

                  // 생성된 fabricObject에서 base64 생성
                  loadedObjects.toDataURL({
                    format: 'jpeg',
                    quality: 0.8
                  });

                  // @ts-ignore
                  loadedObjects.set({
                    originX: 'center',
                    originY: 'center',
                    left: LEFT,
                    top: TOP
                  });
                  // @ts-ignore
                  loadedObjects.set('resource_type', 'item-svg');
                  // @ts-ignore
                  loadedObjects.set('resource_name', 'Capture Img');
                  // @ts-ignore
                  loadedObjects.set('resource_preview', loadedObjects.toDataURL());
                  self.canvas.add(loadedObjects);
                  loadedObjects.setCoords();
                  self.onFocus(loadedObjects);
                  const command = new ObjectAddCommand({
                    cut: self,
                    callback: async () => {
                      return loadedObjects;
                    }
                  });
                  await self.commandManager.executeCommand(command);
                });
              } else {
                console.log('string - 스크린 캡처');
                if (typeof $event.target.result === 'string') {
                  let imgObj = new Image();
                  let ratio = 1;
                  const maxSize = 1080;
                  imgObj.src = $event.target.result;
                  imgObj.crossOrigin = 'anonymous';
                  imgObj.onload = async () => {
                    if (imgObj.width > maxSize || imgObj.height > maxSize) {
                      if (imgObj.width > maxSize) {
                        ratio = maxSize / imgObj.width;
                        imgObj.width *= ratio;
                        imgObj.height *= ratio;
                      }
                      if (imgObj.height > maxSize) {
                        ratio = maxSize / imgObj.height;
                        imgObj.width *= ratio;
                        imgObj.height *= ratio;
                      }
                      const canvas = document.createElement('canvas');
                      const ctx = canvas.getContext('2d');
                      canvas.width = imgObj.width;
                      canvas.height = imgObj.height;
                      ctx.drawImage(imgObj, 0, 0, canvas.width, canvas.height);

                      const imgUrl = canvas.toDataURL();
                      fabric.Image.fromURL(imgUrl, async (img) => {
                        img.scale(1 / (self.canvasSize.web * ratio));
                        await self.pasteAddFromImage(img, $event.target.result);
                      });
                    } else {
                      const img = new fabric.Image(imgObj);
                      img.scale(1 / (self.canvasSize.web * ratio));
                      await self.pasteAddFromImage(img, $event.target.result);
                    }
                  };
                  imgObj.onerror = (e) => {
                    reject(
                      new TooningCustomClientError(
                        'paste Image from outside error',
                        FILE_NAME,
                        'addImageAndSvgFromObjectForHandler()',
                        self.app,
                        true
                      )
                    );
                  };
                }
              }
              console.log($event.target.result); // data url!
            };
          }

          // 페브릭 오브젝트 실제 복사 할때
          if (item.type === 'text/plain') {
            console.log('text/plain');
            // Safari 에서 클립보드 관련 함수는 콜백 안에서 요청 되면 실행되지 않아서  item.getAsString  실행 안되게 막음
            // https://github.com/toonsquare/tooning-repo/issues/1593
            this.clipboardVersion ? await this.pasteCloneFromClipboard() : await this.paste();
          }
        }
        resolve(true);
      } catch (e) {
        throw new TooningCustomClientError(e, FILE_NAME, 'addImageAndSvgFromObjectForHandler()', this.app, true);
      }
    });
  }

  /**
   * 붙여넣기로 이미지 추가
   * @param {fabric.Image} img
   * @param {string | ArrayBuffer} preview
   * @return {Promise<void>}
   */
  async pasteAddFromImage(img: fabric.Image, preview: string | ArrayBuffer): Promise<void> {
    try {
      img.set({
        originX: 'center',
        originY: 'center',
        left: LEFT,
        top: TOP
      });
      // @ts-ignore
      img.set('resource_type', 'item');
      // @ts-ignore
      img.set('resource_name', 'Capture Img');
      // @ts-ignore
      img.set('resource_preview', preview);

      if (img.width * img.scaleX > this.canvasSize.w) {
        img.scale(this.canvasSize.w / img.width);
      }

      if (img.height * img.scaleY > this.canvasSize.h) {
        img.scale(this.canvasSize.h / img.height);
      }

      this.canvas.add(img);
      img.setCoords();
      this.onFocus(img);
      const command = new ObjectAddCommand({
        cut: this,
        callback: async () => {
          return img;
        }
      });
      await this.commandManager.executeCommand(command);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'pasteAddFromImage()', this.app, true);
    }
  }

  public async fromUrl(url) {
    return new Promise((resolve, reject) => {
      if (!url) {
        reject(new Error('url is empty'));
      }
      this.canvas.fromURL(
        url,
        (img) => {
          resolve(img);
        },
        {
          crossOrigin: 'Anonymous'
        }
      );
    });
  }

  // scaleX: 1.3
  // scaleY: 1.3
  // 마스크 최상위 로 처리하는 부분
  maskSetting() {
    try {
      let getObjects;
      if (this.canvas.hasOwnProperty('_objects')) {
        getObjects = this.canvas.getObjects();
        if (getObjects[getObjects.length - 1]?.resource_type === ResourceType.guideMask) {
          this.maskSettingPropertyReset(getObjects[getObjects.length - 1]);
          return;
        }
        // tslint:disable-next-line:forin
        for (const i in getObjects) {
          const ob = getObjects[i];
          if (ob?.resource_type === ResourceType.guideMask) {
            ob.bringToFront();
            this.maskSettingPropertyReset(ob);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  // 최상위 마스크가 이상하게 올라갔을경우 방어 코드
  maskSettingPropertyReset(ob) {
    if (ob.opacity !== 1) {
      ob.opacity = 1;
    }
    if (ob.scaleX !== 1) {
      ob.scaleX = 1;
    }
    if (ob.scaleY !== 1) {
      ob.scaleY = 1;
    }
    this.canvas.requestRenderAll();
  }

  /**
   * 아이템 이나 배경 SVG 생성시 디폴트 칼라 셋팅
   * @param selectedOb 디폴트 칼라 셋팅할 리소스 오브젝트
   * @return {void}
   */
  svgDefaultColorSet(selectedOb): void {
    try {
      if (selectedOb.colorSet) {
        return;
      }

      // @ts-ignore
      selectedOb.colorSet = {
        fill: {},
        stroke: {}
      };

      let itemList = [];
      if (Object.getPrototypeOf(selectedOb).hasOwnProperty('getObjects')) {
        itemList = selectedOb.getObjects();
      } else {
        itemList.push(selectedOb);
      }

      // tslint:disable-next-line:forin
      for (const k in itemList) {
        const ob = itemList[k];
        const obFill = ob.fill;
        const obStroke = ob.stroke;
        // 패턴 그리데이션 제외
        // 그라데이션 / 패턴 이면 타입을 가지고 있음 일반색을 없음.
        if (!obFill.hasOwnProperty(FillType.type)) {
          // fill 칼라
          if (typeof obFill === FillType.string && obFill !== FillType.blank && obFill !== FillType.none) {
            const fillColor = new fabric.Color(obFill).toRgba();

            // 그룹화된 툰요소가 아닌 경우에만 ob의 fill_id를 바꾼다.
            if (selectedOb.resource_type !== SelectedType.largeGroup) {
              ob.fill_id = fillColor;
            }
            if (!selectedOb.colorSet.fill.hasOwnProperty(fillColor)) {
              selectedOb.colorSet.fill[fillColor] = {
                keyName: fillColor,
                objectColor: fillColor,
                prevColor: null
                // object: []
              };
            }
          }
        }

        // stroke 칼라
        if (typeof obStroke === FillType.string && obStroke !== FillType.blank && obStroke !== FillType.none) {
          const strokeColor = new fabric.Color(obStroke).toRgba();

          // 그룹화된 툰요소가 아닌 경우에만 ob의 stroke_id 바꾼다.
          if (selectedOb.resource_type !== SelectedType.largeGroup) {
            ob.stroke_id = strokeColor;
          }
          if (!selectedOb.colorSet.stroke.hasOwnProperty(strokeColor)) {
            selectedOb.colorSet.stroke[strokeColor] = {
              keyName: strokeColor,
              objectColor: strokeColor,
              prevColor: null
              // object: []
            };
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 포커스 캔슬
   * @return {void}
   */
  forceNoFocus(): void {
    try {
      if (this.selectedOb === SelectedType.unselected) {
        return;
      }
      if (this.multipleSelectionMode) {
        this.multipleSelectionModeCancel(false);
      }

      this.selectedResourceType = SelectedType.unselected;
      this.selectedOb = SelectedType.unselected; // 선택된 오브젝트
      this.isColorBox = false;
      return;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'forceNoFocus()', this.app);
    } finally {
      // 무조건 실행
      if (this.canvas) {
        this.canvas.discardActiveObject();
        this.canvas.requestRenderAll();
      }
    }
  }

  /**
   * 리소스 오브젝트 선택 (focus)
   * @param {object} obj
   * @param {number} selectionStart
   * @param {number} selectionEnd
   * @return {void}
   */
  onFocus(obj: object, selectionStart: number = null, selectionEnd: number = null): void {
    try {
      // @ts-ignore
      if (
        this.selectedResourceType !== ResourceType.bgColor &&
        // @ts-ignore
        obj.resource_type !== ResourceType.bgColor &&
        // @ts-ignore
        obj.resource_type !== ResourceType.guideMask
      ) {
        this.canvas.setActiveObject(obj);
      }

      // @ts-ignore
      this.selectedResourceType = obj.get('resource_type');
      this.selectedOb = obj; // 선택된 오브젝트

      if (selectionStart !== null && selectionEnd !== null) {
        this.selectedOb.selectionStart = selectionStart;
        this.selectedOb.selectionEnd = selectionEnd;
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'onFocus()');
    }
  }

  noFocus(event) {
    // 배경을 클릭하면 실행
    if (event != null) {
      if (event.target.id === 'noFocusDiv' || event.target.id === 'noFocusDiv2') {
        this.forceNoFocus();
      }
    }
  }

  /**
   * 캔버스 상태 string 으로 변환
   */
  canvasCapturetoJson() {
    return JSON.stringify(this.canvas.toDatalessJSON());
  }

  /**
   * 화면 지우기
   * @param index{number} 지울 페이지 번호
   * @return {Promise<void>}
   */
  async allClean(index?: number): Promise<void> {
    try {
      if (index) {
        await this.goPage(index, true);
      }
      await this.loadFromJSON(this.nullJson, index);
      this.afterDataLessJsonString = JSON.stringify(this.canvas.toDatalessJSON());
      const command = new JsonChangeCommand({
        cut: this,
        beforeDataLessJsonString: this.beforeDataLessJsonString,
        afterDataLessJsonString: this.afterDataLessJsonString
      });
      await this.commandManager.executeCommand(command);
      this.updatePagePreview();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'allClean()', null, true);
    }
  }

  /**
   * 초기화 재확인 팝업
   */
  async resetAlertConfirm() {
    this.popAlert = await this.alertController.create({
      cssClass: 'basic-dialog',
      header: this.translate.instant('real initialization'),
      message: this.translate.instant('initialization comment'),
      buttons: [
        {
          text: this.translate.instant('cancel'),
          role: 'cancel',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        },
        {
          text: this.translate.instant('header'),
          handler: async () => {
            await this.resetAllPages();
          }
        }
      ]
    });
    this.popAlert.onDidDismiss().then(async (data) => {
      this.popAlert = null;
    });
    await this.popAlert.present();
  }

  /**
   * 현재 캔버스 사이즈로 클라/서버 데이터 변경
   * @return {Promise<void>}
   */
  async initCanvasSize(): Promise<void> {
    try {
      const size = this.canvasSize.size;
      const sizeNum = size.substring(0, size.length - 2);
      const w = +sizeNum.split(' x ')[0];
      const showW = w.toFixed(0);
      const h = +sizeNum.split(' x ')[1];
      const showH = h.toFixed(0);
      this.canvasSizeUnit = size.substring(size.length - 2, size.length);
      this.canvasSizeString = showW + ' x ' + showH + ' ' + this.canvasSizeUnit;
      const newCanvas = new Canvas();
      newCanvas.canvasSize = JSON.stringify(this.canvasSize);
      newCanvas.id = this.newCanvasId;
      newCanvas.outsideBGColor = this.outsideBG.color;
      await this.canvasService.canvasUpdate(newCanvas).toPromise();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'initCanvasSize()', this.app, true);
    }
  }

  /**
   * 초기화 처음 상태로 변경
   */
  async resetAllPages() {
    const loading = new Loading();
    await loading.showLoader('처음 상태로');
    try {
      this.canvasSize = this.resetCanvasSize;
      if (this.guideMask === null) {
        await this.guideMaskBringToFront();
      }

      this.guideMask.set('originY', 'center');
      this.guideMask.set('originX', 'center');
      this.guideMask.set('top', TOP);
      this.guideMask.set('left', LEFT);
      this.guideMask.set('width', this.canvasSize.w);
      this.guideMask.set('height', this.canvasSize.h);
      await this.initCanvasSize();
      await this.canvasResizeZoom();

      // 모든 페이지 삭제
      for (let i = this.pageList.length - 1; i >= 0; i--) {
        await this.removePanel(i, false);
      }

      // 페이지 처음 상태로 변경
      for (let i = 0; i < this.resetPageList.length; i++) {
        // 리셋페이지 세팅
        const pageJSON = JSON.parse(this.resetPageList[i]);
        pageJSON.isFirstEnter = true;
        pageJSON.dirty = true;
        pageJSON.order = i;
        this.pageList.push(pageJSON);
        await this.pageService.restorePage(pageJSON.id).toPromise();
        await this.goPage(i, true);
        this.updatePagePreviewInLocal(i);
        await this.updatePage(i);
      }
      await this.pagesOrderUpdate();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'resetAllPages()', this.app);
    } finally {
      await this.goPage(0);
      this.commandManager.clearHistory();
      loading.hideLoader();
    }
  }

  /**
   * 전체 선택
   * @return {Promise<void>}
   */
  async allSelect() {
    try {
      this.canvas.discardActiveObject();
      const obList = [];
      for (const obj of this.canvas.getObjects()) {
        if (obj.resource_type !== ResourceType.guideMask && obj.resource_type !== ResourceType.bgColor && obj.selectable) {
          obList.push(obj);
        }
      }
      if (obList.length === 0) {
        await this.app.showToast(this.translate.instant('no objects'));
        return;
      }
      const selectedObjects = new fabric.ActiveSelection(obList, {
        canvas: this.canvas
      });
      this.canvas.setActiveObject(selectedObjects);
      this.canvas.requestRenderAll();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'allSelect()');
    }
  }

  /**
   * 멀티셀렉일 때 그룹화
   */
  async groupCreate() {
    const command = new ObjectGroupCommand({
      cut: this,
      target: this.selectedOb,
      callback: () => this.groupCreateGo()
    });
    await this.commandManager.executeCommand(command);
    this.multipleSelectionModeCancel(true);
  }

  /**
   * 캔버스에서 선택된 객체를 그룹화하고 추가 속성을 적용
   * @returns {any} 선택된 객체가 포함된 새로 생성된 그룹 객체
   */
  groupCreateGo() {
    try {
      this.objectEventSetupOff();
      let addOb: any;
      let isChatBubbleAddText = 0;
      const selectedObjects = this.canvas.getActiveObjects();
      for (const object of selectedObjects) {
        if (object.resource_type === ResourceType.characterSvg) {
          this.characterLengthOnPage -= 1;
        }
      }
      const selectedObjectIndexes = selectedObjects.map((sobject) => this.canvas.getObjects().findIndex((object) => object === sobject));
      addOb = this.canvas.getActiveObject().sortObjectByZindex().toGroup();
      addOb.moveTo(Math.max(...selectedObjectIndexes) - (selectedObjectIndexes.length - 1));
      addOb.set({
        resource_type: SelectedType.largeGroup,
        resource_name: this.translate.instant('grouped elements'),
        resource_preview: addOb.toDataURL({ format: 'png', quality: 0.5 }),
        selectable: true,
        evented: true
      });

      this.canvas.requestRenderAll();
      this.forceNoFocus();
      this.onFocus(addOb);
      // 삼성 밈을 위한 말풍선 그룹화  - 그룹화 후 속성값 추가
      const objects = addOb._objects;
      for (let i = 0, j = objects.length; i < j; i++) {
        switch (objects[i].resource_type) {
          case ResourceType.chatBubbleSvg:
          case ResourceType.text:
            isChatBubbleAddText++;
            break;
        }
      }
      if (isChatBubbleAddText === 2) addOb.isBubbleGroup = true;

      // 삼성 밈을 위한 말풍선 그룹화  - 그룹화 후 속성값 추가
      return addOb;
    } catch (e) {
      console.log(e);
    } finally {
      this.objectEventSetup();
    }
  }

  /**
   * 선택한 오브젝트 그룹일 때 그룹 해제
   */
  async groupCancel(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const idList = [];
    const objects = this.selectedOb._objects;
    for (let i = 0, j = objects.length; i < j; i++) {
      this.assignmentID(objects[i]);
      idList.push(objects[i].resource_selection_id);
    }

    const command = new ObjectGroupCancelCommand({
      cut: this,
      target: this.selectedOb,
      id: idList,
      afterGroupID: this.selectedOb.resource_selection_id
    });
    await this.commandManager.executeCommand(command);

    await this.groupCancelGo();
  }

  async groupCancelGo() {
    try {
      this.objectEventSetupOff();
      const activeObject = this.canvas.getActiveObject();
      const subObjects = activeObject.getObjects();
      for (const object of subObjects) {
        if (object.resource_type === ResourceType.characterSvg) {
          this.characterLengthOnPage += 1;
        }
      }
      const activeObjectIndex = this.canvas.getObjects().findIndex((object) => object === activeObject);
      activeObject.toActiveSelection();

      for (const obj of subObjects.sort(() => -1)) {
        // 배경요소중 그룹으로 된 요소를 해제 시 워터마크 이미지를 삭제해야할때 필요한 코드 - 방어코드
        if (obj.resource_type === ResourceType.waterMark) {
          this.canvas.remove(obj);
        } else {
          obj.moveTo(activeObjectIndex);
          if (!obj.colorSet) {
            this.svgDefaultColorSet(obj);
          }
        }
      }
      this.canvas.discardActiveObject();
      this.canvas.requestRenderAll();
      this.forceNoFocus();
      this.objectEventSetup();
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 투명 설정
   * @param value{number} 적용시킬 투명도
   * @param {any} target 해당 input 태그가 변경하는 요소를 구분하기 위한 변수
   * @param {IonInput} inputType
   * @returns {Promise<void>}
   */
  async setOpacity(value: number, target = this.selectedOb, inputType?: IonInput): Promise<void> {
    try {
      const isV2 = this.selectedOb.etcUploadVersion === Version.v2;
      if (isV2) {
        target = this.selectedOb._objects[0];
      } else {
        target = this.selectedOb;
      }

      target.set('opacity', value * 0.01);
      this.canvas.fire('object:modified');
      this.canvas.requestRenderAll();
      if (value !== 100) {
        this.filterDetailChanged = true;
      }
      if (inputType) {
        // @ts-ignore
        inputType.getInputElement().then((inputElement) => inputElement.blur());
      }
      this.checkDetailChanged();
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'setOpacity()', null, true);
    }
  }

  // 최신 카피
  async copy() {
    this.objectEventSetupOff();
    this.clonedJson = await this.cloneFabricObject(this.canvas.getActiveObject());
    this.objectEventSetup();
  }

  /**
   * 페브릭 오브젝트 복사하기
   */
  cloneFabricObject(source: any) {
    return new Promise((resolve, reject) => {
      source.clone((result) => {
        resolve(result);
      });
    });
  }

  /**
   * indexedDB에 데이터 복사
   * @param {boolean} enableLoader 로딩 띄울지
   * @return {Promise<void>}
   */
  async cloneToClipboard(enableLoader = false): Promise<void> {
    if (this.canvas.getActiveObject() === null || this.canvas.getActiveObject() === undefined) {
      return;
    }

    // safari 에서 clipboard 에 복사할때 로딩이 있으면 permission 에러남
    // https://developer.apple.com/forums/thread/691873
    let loading;
    if (enableLoader) {
      loading = new Loading();
      await loading.showLoader();
    }

    this.setKeyActivation = false;
    this.isClipboardCopy = true;

    const type = 'text/plain';
    const blob = new Blob([IndexedDBCmd.TOONING_COPY_KEY], { type });
    // @ts-ignore
    const copyData = [new ClipboardItem({ [type]: blob })];

    // @ts-ignore
    await navigator.clipboard.write(copyData);

    try {
      const sessionId = `${new Date().getTime()}_${Math.random().toString(36).substr(2, 15)}`;
      this.localStorageId = sessionId;
      this.app.cache.setCopyAndPaste(sessionId);

      // 멀티셀렉일 때만 리소스 정렬
      const activeObjects = this.canvas.getActiveObject().toDatalessObject();

      this.indexedDB
        .addCopyData(activeObjects)
        ?.then(async () => {
          this.clonedJson = activeObjects;
          this.firstCopiedTopVal = this.clonedJson.top;
          this.firstCopiedLeftVal = this.clonedJson.left;
          console.log('success');
        })
        .catch((e) => {
          throw new TooningCustomClientError(e, FILE_NAME, 'cloneToClipboard().indexedDB.addCopyData().then()', this.app, true);
        });
    } catch (e) {
      await this.app.showToast(this.translate.instant('clipboard err'));
      throw new TooningCustomClientError(e, FILE_NAME, 'cloneToClipboard()', this.app, true);
    } finally {
      this.setKeyActivation = true;
      this.isClipboardCopy = false;
      if (enableLoader) {
        loading.hideLoader();
      }
    }
  }

  /**
   * 컨트롤로 선택시 레이어 순서 유지를 위한 함수 - 재정렬
   * @param getActiveObject - 카피된 데이터
   */
  copyDataRearrangement(getActiveObject) {
    try {
      if (getActiveObject.objects !== undefined) {
        const activeObjectList = [];
        const getObjects = this.canvas.getObjects();
        const getActiveObjects = getActiveObject.objects;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0, p = getObjects.length; i < p; i++) {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0, q = getActiveObjects.length; j < q; j++) {
            if (getObjects[i].resource_selection_id && getObjects[i].resource_selection_id === getActiveObjects[j].resource_selection_id) {
              activeObjectList.push(getActiveObjects[j]);
            }
          }
        }
        getActiveObject.objects = activeObjectList;
      }
    } catch (e) {
      console.log(e);
    }
    return getActiveObject;
  }

  /**
   * 오브젝트 복사를 할 경우
   * @param {boolean} showToast 토스트문구를 보여줄지
   * @return {Promise<void>}
   */
  async copyOb(showToast: boolean = true): Promise<void> {
    try {
      this.textCopyString = undefined;
      this.canvas.getActiveObject().clone(async (cloned) => {
        this.clonedJson = cloned;
      });
      if (showToast) {
        await this.app.showToast(this.translate.instant('complete copy'));
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'copyOb()', null, true);
    }
  }

  /**
   * 모바일에서 붙여넣기
   * @return {Promise<void>}
   */
  async paste(): Promise<void> {
    try {
      this.isPasting = true;

      if (this.clonedJson === null) {
        // 아무것도 복사하지 않은 경우
        if (this.textCopyString === null) {
          await this.app.showToast(this.translate.instant('ob unCopy'));
          return;
        }
        // 복사한 텍스트의 길이가 500자 이상인 경우
        if (this.textCopyString.length > TEXT_LIMIT_LENGTH) {
          await this.app.showToast(this.translate.instant('text over'));
          return;
        }
        await this.onCreateAddPasteText(this.textCopyString);
        return;
      }

      console.clear();
      this.objectEventSetupOff();
      this.clonedJson.clone((clonedObj) => {
        this.canvas.discardActiveObject();

        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true
        });

        //다른 에디터(ex. 윈도우)에서 텍스트 복사해올때 개행문자가 두개(\r,\n)가 들어가는 케이스 방어 코드
        if (clonedObj.type === 'textbox' && clonedObj.text.includes('\r')) {
          clonedObj.text = clonedObj.text.replaceAll('\r', '');
        }

        if (clonedObj.type === SelectedType.activeSelection) {
          this.app.orange('multi select');
          // active selection needs a reference to the canvas.
          clonedObj.canvas = this.canvas;

          clonedObj.forEachObject((obj) => {
            if (obj.hasOwnProperty('isEditableOnSamsungKeyboard')) {
              obj.isEditableOnSamsungKeyboard = false;
            }
            this.canvas.add(obj);
          });
          // this should solve the unselectability
          clonedObj.setCoords();
        } else {
          if (clonedObj.hasOwnProperty('isEditableOnSamsungKeyboard')) {
            clonedObj.isEditableOnSamsungKeyboard = false;
          }
          this.canvas.add(clonedObj);
        }

        this.clonedJson.top += 10;
        this.clonedJson.left += 10;
        this.canvas.setActiveObject(clonedObj);
        this.canvas.requestRenderAll();
      });
      this.objectEventSetup();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'paste()', null, true);
    }
  }

  /**
   * clipboard에 있는 저장된 데이터 붙여넣기
   * @return {Promise <void>}
   */
  async pasteCloneFromClipboard(): Promise<void> {
    try {
      // 예전에 memento pattern으로 작업할때는 object:modified -> history 저장 되는 문제가 있어서
      // 아래 처럼 objectEventSetupOff()를 사용 했지만 이제는 command pattern , command 가 execute될때만 히스토리가 한개 쌓이므로 아래 주석 처리
      // 예) 옛날에 캐릭터 5개를 복사 해서 붙여넣으면 5번의 object:add 이벤트가 오고  5번의 히스토리가 저장되는 문제가 있었다
      // this.objectEventSetupOff();
      let getFabric;
      let clonedText;
      let clonedObj;

      if (this.isSubmitAlertOpen) {
        return;
      }
      if (!this.prevLocalStorageId) {
        clonedText = await navigator.clipboard.readText();
        clonedObj = await this.indexedDB.getCopyData(clonedText);

        if (!clonedObj || clonedText !== IndexedDBCmd.TOONING_COPY_KEY) {
          await this.onCreateAddPasteText(clonedText);
          return;
        }

        //다른 에디터(ex. 윈도우)에서 텍스트 복사해올때 개행문자가 두개(\r,\n)가 들어가는 케이스 방어 코드
        if (clonedObj?.type === 'textbox' && clonedObj?.text.includes('\r')) {
          clonedObj.text = clonedObj.text.replaceAll('\r', '');
        }
      } else if (this.prevLocalStorageId === this.localStorageId) {
        clonedObj = this.prevClonedObj;
        this.prevLocalStorageId = this.localStorageId;
      }

      this.canvas.discardActiveObject();

      if (this.localStorageId === this.app.cache.getCopyAndPaste()) {
        this.prevCopyIndex === this.panelIndex ? this.setPosition(true, clonedObj) : this.setPosition(false, clonedObj);
        this.prevClonedObj = clonedObj;
      } else if (this.clonedJson) {
        if (_.isEqual(this.clonedJson.objects, clonedObj.objects)) {
          this.prevCopyIndex === this.panelIndex ? this.setPosition(true, clonedObj) : this.setPosition(false, clonedObj);
          this.prevClonedObj = clonedObj;
        } else {
          this.clonedJson = clonedObj;
        }
      } else {
        this.clonedJson = clonedObj;
      }

      // @ts-ignore
      if (clonedObj.type === SelectedType.activeSelection) {
        clonedObj.canvas = this.canvas;
        this.clonedJson = _.cloneDeep(clonedObj);
        const addlist = [];
        // tslint:disable-next-line:prefer-for-of
        getFabric = await this.addObFromJson([clonedObj], false, false, false);
        getFabric.forEachObject((obj) => {
          this.canvas.add(obj);
          if (obj.resource_type === ResourceType.characterSvg) {
            this.characterLengthOnPage += 1;
          }
          if (obj.hasOwnProperty('isEditableOnSamsungKeyboard')) {
            obj.isEditableOnSamsungKeyboard = false;
          }
          obj.resource_selection_id = undefined;
          this.assignmentID(obj);
          addlist.push(obj);
        });
        const command = new ObjectAddMultiCommand({
          cut: this,
          callback: async () => {
            return addlist;
          }
        });
        await this.commandManager.executeCommand(command);

        getFabric.setCoords();
      } else {
        const command = new ObjectAddCommand({
          cut: this,
          callback: async () => {
            getFabric = await this.addObFromJson([clonedObj], false, false, false);
            if (getFabric.resource_type === ResourceType.characterSvg) {
              this.characterLengthOnPage += 1;
            }
            if (getFabric.hasOwnProperty('isEditableOnSamsungKeyboard')) {
              getFabric.isEditableOnSamsungKeyboard = false;
            }
            getFabric.resource_selection_id = null;
            this.canvas.add(getFabric);
            return getFabric;
          }
        });
        await this.commandManager.executeCommand(command);
      }

      this.canvas.setActiveObject(getFabric);
      this.canvas.requestRenderAll();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 붙여넣기 할 object의 top. left값 설정
   * @param sameIndex prevCopyIndex와 panelIndex가 동일한지 boolean
   * @param clonedObj 붙여넣기 할 object
   * @return {void}
   */
  setPosition(sameIndex: boolean, clonedObj: { top: number; left: number }): void {
    try {
      if (this.isShiftKeyPushed) {
        if (this.firstCopiedTopVal && this.firstCopiedLeftVal) {
          clonedObj.top = this.firstCopiedTopVal;
          clonedObj.left = this.firstCopiedLeftVal;
          return;
        } else {
          throw new Error('this.firstCopiedTopVal 또는 this.firstCopiedLeftVal값이 없습니다.');
        }
      }
      if (sameIndex) {
        this.clonedJson.top += 10;
        this.clonedJson.left += 10;
        clonedObj.top = this.clonedJson.top;
        clonedObj.left = this.clonedJson.left;
      } else {
        this.prevCopyIndex = this.panelIndex;
        this.clonedJson.top = clonedObj.top;
        this.clonedJson.left = clonedObj.left;
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'setPosition()', null, true);
    } finally {
      this.isShiftKeyPushed = false;
    }
  }

  /**
   * 붙여넣기 위치 좌표를 +10 해주는 함수
   * @return {void}
   */
  setAddPosition(): void {
    this.clonedJson.top += 10;
    this.clonedJson.left += 10;
  }

  /**
   * 붙여넣기 위치 좌표를 -10 해주는 함수
   * @return {void}
   */
  setDeletePosition(): void {
    this.clonedJson.top -= 10;
    this.clonedJson.left -= 10;
  }

  onHistory() {
    this.app.orange('clear onHistoryTimer');
    if (this.onHistoryTimer) {
      clearTimeout(this.onHistoryTimer);
    }
    this.onHistoryTimer = setTimeout(() => {
      this.commandManager.onHistory();
    }, this.onHistoryTimeOut);
  }

  /**
   * copay & paste
   * @param event - 복제 아이콘 click event
   * @returns {Promise<void>}
   */
  async copyAndPaste(event): Promise<void> {
    this.objectEventSetupOff();
    event.preventDefault();
    event.stopPropagation();
    if (this.selectedOb.type === SelectedType.activeSelection) {
      const idList = [];
      for (let i = 0, j = this.selectedOb.getObjects().length; i < j; i++) {
        const object = this.selectedOb.getObjects()[i];
        idList.push(object.resource_selection_id);
        if (object.resource_type === ResourceType.characterSvg) {
          this.characterLengthOnPage += 1;
        }
      }

      const canvasAllobLength = this.canvas.getObjects().length;
      const cloneObList = [];
      for (let j = 0; j < canvasAllobLength; j++) {
        const object = this.canvas.getObjects()[j];
        for (let k = 0, l = idList.length; k < l; k++) {
          if (object.resource_selection_id === idList[k]) {
            cloneObList.push(object);
          }
        }
      }
      const addObList = [];
      for (let i = 0, j = cloneObList.length; i < j; i++) {
        const cloneOb = JSON.stringify(cloneObList[i].toDatalessObject());
        const addOb = await this.addObFromJson([JSON.parse(cloneOb)], false, false, true, null, null);
        // @ts-ignore
        addOb.top = cloneObList[i].top + 10;
        // @ts-ignore
        addOb.left = cloneObList[i].left + 10;
        // @ts-ignore
        addOb.resource_selection_id = null;
        if (addOb.hasOwnProperty('isEditableOnSamsungKeyboard')) {
          addOb.isEditableOnSamsungKeyboard = false;
        }
        this.assignmentID(addOb);
        addObList.push(addOb);
      }
      const command = new ObjectAddMultiCommand({
        cut: this,
        callback: async () => {
          return addObList;
        }
      });
      await this.commandManager.executeCommand(command);
      const selectedObjects = new fabric.ActiveSelection(addObList, {
        canvas: this.canvas
      });
      this.canvas.setActiveObject(selectedObjects);
    } else {
      const object = this.selectedOb;
      if (object.resource_type === ResourceType.characterSvg) {
        this.characterLengthOnPage += 1;
      }
      const cloneOb = JSON.stringify(object.toDatalessObject());
      const addOb = await this.addObFromJson([JSON.parse(cloneOb)], false, false, true, null, null, object.top + 10, object.left + 10);
      // @ts-ignore
      addOb.resource_selection_id = null;
      if (addOb.hasOwnProperty('isEditableOnSamsungKeyboard')) {
        addOb.isEditableOnSamsungKeyboard = false;
      }
      this.assignmentID(addOb);
      const command = new ObjectAddCommand({
        cut: this,
        callback: async () => {
          return addOb;
        }
      });
      await this.commandManager.executeCommand(command);
      this.onFocus(addOb);
    }

    this.objectEventSetup();
    this.canvas.fire('object:modified');
  }

  /**
   * 좌우 반전
   */
  reversalRL(event) {
    event.preventDefault();
    const originalFlips = [];
    let objs;
    const isGroup = this.selectedOb.resource_name === ResourceName.groupName;
    const isActive = this.selectedOb.type === SelectedType.activeSelection;
    if (isGroup || isActive) {
      // 그룹, 멀티 셀렉 요소 originalFlip 저장
      objs = this.selectedOb.getObjects();
      objs.forEach((ob) => {
        originalFlips.push(ob.flipX);
      });
    }

    this.selectedOb.toggle('flipX');

    if (isGroup || isActive) {
      // 그룹, 멀티 셀렉 내부 요소 originalFlip 적용
      for (let i = 0; i < objs.length; i++) {
        if (objs[i].flipX !== originalFlips[i]) {
          objs[i].set('flipX', originalFlips[i]);
        }
        if (isActive) {
          // 멀티 셀렉은 left 값 반전
          objs[i].set('left', -objs[i].left);
        }
      }
    }
    this.canvas.requestRenderAll();
    this.canvas.fire('object:modified');
  }

  /**
   * 수평 뒤집기
   * FlipX command pattern
   * @param {Event} event click event
   * @return {Promise<void>}
   */
  async flipXCommandPattern(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    console.log('flipX Command Pattern');
    const target = this.selectedOb;
    const flipXCommand = new ObjectFlipXCommand({
      cut: this,
      target,
      beforeLeft: target.beforeLeft,
      beforeTop: target.beforeTop,
      afterLeft: target.afterLeft,
      afterTop: target.afterTop,
      beforeFlipX: target.beforeFlipX,
      afterFlipX: target.flipX
    });
    await this.commandManager.executeCommand(flipXCommand);
    this.multipleSelectionModeCancel(true);
  }

  /**
   * 수직 뒤집기
   * FlipY command pattern
   * @param {Event} event click event
   * @return {Promise<void>}
   */
  async flipYCommandPattern(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    console.log('flipY Command Pattern');
    const target = this.selectedOb;
    const flipYCommand = new ObjectFlipYCommand({
      cut: this,
      target,
      beforeLeft: target.beforeLeft,
      beforeTop: target.beforeTop,
      afterLeft: target.afterLeft,
      afterTop: target.afterTop,
      beforeFlipY: target.beforeFlipY,
      afterFlipY: target.flipY
    });
    await this.commandManager.executeCommand(flipYCommand);
    this.multipleSelectionModeCancel(true);
  }

  /**
   * 상하 반전
   */
  reversalUD(event) {
    event.preventDefault();
    const originalFlips = [];
    let objs;
    const isGroup = this.selectedOb.resource_name === ResourceName.groupName;
    const isActive = this.selectedOb.type === SelectedType.activeSelection;
    if (isGroup || isActive) {
      // 그룹, 멀티 셀렉 요소 originalFlip 저장
      objs = this.selectedOb.getObjects();
      objs.forEach((ob) => {
        originalFlips.push(ob.flipY);
      });
    }

    this.selectedOb.toggle('flipY');

    if (isGroup || isActive) {
      // 그룹, 멀티 셀렉 내부 요소 originalFlip 적용
      for (let i = 0; i < objs.length; i++) {
        if (objs[i].flipY !== originalFlips[i]) {
          objs[i].set('flipY', originalFlips[i]);
        }
        if (isActive) {
          // 멀티 셀렉은 top 값 반전
          objs[i].set('top', -objs[i].top);
        }
      }
    }
    this.canvas.requestRenderAll();
    this.canvas.fire('object:modified');
  }

  // 센터 정렬
  // Ob 중앙정렬시키기
  async centerOb(ob) {
    if (ob !== 'undefined') {
      // @ts-ignore
      ob.set('top', TOP);
      // @ts-ignore
      ob.set('left', LEFT);
      ob.setCoords();
      this.canvas.requestRenderAll();
    }
  }

  /**
   * 리소스 삭제 함수
   * @param event
   * @param enableObjectEvent
   * @return {Promise<any>}
   */
  async obRemove(event, enableObjectEvent = true): Promise<any> {
    try {
      let clonedOb;
      switch (this.selectedResourceType) {
        case SelectedType.unselected:
          this.app.orange('selectedOb is unselected so nothing to remove');
          return;
        case SelectedType.multiSelection:
          clonedOb = _.cloneDeep(this.canvas.getActiveObject());
          clonedOb = clonedOb.sortObjectByZindex().toGroup();
          clonedOb.isMultiSelected = true;
          break;
        default:
          clonedOb = _.cloneDeep(this.selectedOb);
          break;
      }
      switch (this.selectedOb.resource_type) {
        case ResourceType.bgColor:
          this.app.orange('selectedOb is unselected so nothing to remove');
          return 0;
        case ResourceType.guideMask:
          this.app.orange('selectedOb is outside background');
          return 0;
      }
      event.preventDefault();
      event.stopPropagation();
      if (enableObjectEvent) {
        this.objectEventSetupOff();
      }

      const command = new ObjectRemoveCommand({
        target: clonedOb,
        cut: this,
        callback: async () => {
          if (this.selectedOb.type === SelectedType.activeSelection) {
            const loading = new Loading();
            await loading.showLoader();
            let objectParentIdArray = [];
            const list = this.selectedOb.getObjects();
            for (let i = 0; i < list.length; i++) {
              let object = clonedOb._objects[i];
              object.position = {};
              this.removeCacheCanvas(list[i]);
              this.canvas.remove(list[i]);
              if (list[i].resource_type === ResourceType.characterSvg) {
                this.characterLengthOnPage -= 1;
                this.afterAIView = false;
              }
              objectParentIdArray.push(list[i].parentId);
              const workingPanel = this.pageList[this.panelIndex];
              workingPanel.dirty = true;
            }
            if (this.checkIsCopyResource(objectParentIdArray)) {
              this.setDeletePosition();
            }
            loading.hideLoader();
            await this.app.delay(350);
          } else {
            this.removeCacheCanvas(this.selectedOb);
            this.canvas.remove(this.selectedOb);
            if (this.selectedOb.resource_type === ResourceType.characterSvg) {
              this.characterLengthOnPage -= 1;
              this.afterAIView = false;
            }
            if (this.clonedJson && this.selectedOb.parentId === this.clonedJson.resource_selection_id) {
              this.setDeletePosition();
            }
            const workingPanel = this.pageList[this.panelIndex];
            workingPanel.dirty = true;
          }
          this.forceNoFocus();
          if (enableObjectEvent) {
            this.objectEventSetup();
            this.commandManager.onHistory();
          }
        }
      });
      await this.commandManager.executeCommand(command);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'obRemove()', null, true);
    }
  }

  /**
   * object들이 복사, 붙여넣기한 리소스인지 확인
   * parentIdArray와 clonedJson의 objects 각각의 resource_selection_id와 비교해서 같으면 true, 다르면 false를 반환
   * @param {Array<string>} parentIdArray objects에 들어있는 리소스들의 parentId를 저장한 배열
   * @return {boolean}
   */
  checkIsCopyResource(parentIdArray: Array<string>): boolean {
    if (!this.clonedJson) {
      return false;
    }

    // copy한 대상이 2개 이상일 경우
    if (this.clonedJson.objects) {
      for (let i = 0, j = this.clonedJson.objects.length; i < j; i++) {
        // @ts-ignore
        if (parentIdArray.includes(this.clonedJson.objects[i].resource_selection_id) === false) {
          return false;
        }
      }
    } else if (parentIdArray.includes(this.clonedJson.resource_selection_id) === false) {
      return false;
    }

    return true;
  }

  /**
   * 벡터이미지 비트맵이미지로 변환
   * @param characterMake: getResourceRealArea() 사용 위해서 CharacterMake 서비스 불러옴
   * @param {string | undefined} originalData: textBox 그룹화 후 변환하므로 미리 originalData 받아옴
   * @param {boolean} onHistory: 히스토리 키는 변수 - 상위에서 이벤트 off일 경우 유지 시켜주기 위해
   * @param {number} customMargin 실제 이미지와 핸들러 사이의 임의의 마진값 기본값은 2
   * @param {boolean} passLoading: toPngItem 함수를 호출할 때 loading 걸지 않고 지나치고 싶을 때 사용
   */
  async toPngItem(
    characterMake,
    originalData?: string | undefined,
    onHistory: boolean = true,
    customMargin = 2,
    passLoading: boolean = false
  ): Promise<fabric.Image | number> {
    return new Promise(async (resolve, reject) => {
      try {
        this.commandManager.offHistory();
        const target = this.selectedOb;
        const clone: any = await this.cloneFabricObject(target);
        let imgSrc: string;
        this.app.isBrowserCheck().toLowerCase();

        // 텍스트 아니면 오리지날 저장
        // 텍스트면 히스토리언두 추가삭제 체크
        if (originalData === undefined) {
          target.clipPath = null;
          originalData = JSON.stringify(target.toDatalessObject());
        } else if (typeof originalData !== 'string') {
          throw new Error('originalData is not string type');
        }
        // realArea 받아오기
        let imgDataURL = clone.toDataURL({ format: 'png' });
        let realArea = await characterMake.getResourceRealArea(imgDataURL, customMargin);
        this.app.blueLog(realArea);
        if (!passLoading) {
          await this.loading.showLoader('');
        }
        // 이미지 가로 혹은 세로 크기 2048 이상되면 변환할때 우측, 하단 잘림
        // 2048 이상일 땐 scale 계산
        let bigRatio;
        let smallRatio;
        if (realArea.width > this.toPngMaxScale && realArea.width > realArea.height) {
          bigRatio = realArea.width / this.toPngMaxScale;
          smallRatio = this.toPngMaxScale / realArea.width;
        } else if (realArea.height > this.toPngMaxScale && realArea.height > realArea.width) {
          bigRatio = realArea.height / this.toPngMaxScale;
          smallRatio = this.toPngMaxScale / realArea.height;
        }
        this.app.redLog(bigRatio);
        this.app.redLog(smallRatio);
        if (bigRatio) {
          clone.set('scaleX', clone.scaleX * smallRatio);
          clone.set('scaleY', clone.scaleY * smallRatio);

          // realArea 재설정
          imgDataURL = clone.toDataURL({ format: 'png' });
          realArea = await characterMake.getResourceRealArea(imgDataURL, customMargin);
        }

        imgSrc = clone.toDataURL({
          format: 'png',
          width: realArea.width,
          height: realArea.height,
          left: realArea.left,
          top: realArea.top
        });
        try {
          // tslint:disable-next-line:only-arrow-functions
          fabric.Image.fromURL(
            imgSrc,
            // tslint:disable-next-line:only-arrow-functions
            async (img) => {
              // @ts-ignore
              img.set({
                originX: 'center',
                originY: 'center',
                top: target.top - realArea.yToMove,
                left: target.left - realArea.xToMove,
                opacity: clone.get('opacity'),
                scaleX: bigRatio || 1,
                scaleY: bigRatio || 1,
                selectable: true,
                evented: true
              });
              // @ts-ignore
              img.set('previous_conversion_type', clone.get('resource_type'));
              // @ts-ignore
              img.set('resource_type', ResourceType.item);
              // @ts-ignore
              img.set('resource_id', clone.get('resource_id'));
              // @ts-ignore
              img.set('resource_name', clone.get('resource_name'));
              // @ts-ignore
              img.set('resource_name_en', clone.get('resource_name_en'));
              // @ts-ignore
              img.set('resource_name_fr', clone.get('resource_name_fr'));
              // @ts-ignore
              img.set('resource_name_jp', clone.get('resource_name_jp'));
              // @ts-ignore
              img.set('resource_filterType', 'Normal'); // 필터 기본으로 지정
              // @ts-ignore
              img.set('toPngBeforeAngle', target.angle);

              img.set('opacity', 1);

              const selectedObject = this.getImageObject(target);
              if (selectedObject.original_object) {
                // @ts-ignore
                img.set('original_object', selectedObject.original_object);
              } else {
                // @ts-ignore
                img.set('original_object', originalData);
              }

              let isFromSvg: boolean = false;
              // 최초의 원본이 svg인지 확인해주기
              if (!target.wasText) {
                isFromSvg = target.resource_type.split('-')[1] === 'svg' || target.resource_type === ResourceType.Group;
              }

              isFromSvg && (await this.app.showToast(this.translate.instant('convert bitmap comment')));

              console.log(img);
              this.canvas.add(img);
              img.moveTo(this.getIndex());
              if (target.resource_type === ResourceType.characterSvg) {
                this.characterLengthOnPage--;
              }

              this.canvas.remove(target);

              this.onFocus(img);
              this.canvas.requestRenderAll();

              if (!passLoading) {
                this.loading.hideLoader();
              }
              if (onHistory) {
                this.commandManager.onHistory(); // 히스토리 키고 하나 추가
              }
              resolve(img);
            },
            { crossOrigin: 'anonymous' }
          );
        } catch (e) {
          throw e;
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * 오리지날 리소스 불러오기
   * @return Promise<TooningFabricObject>
   */
  async toOriginItem(): Promise<TooningFabricObject> {
    try {
      this.analyticsService.formatConvert(FormatConvert.rasterToVector);
      const target = this.selectedOb;

      const original = JSON.parse(this.selectedOb.original_object);
      this.setWaterMark([original]);

      // target 대비 original_object 값 설정
      // lastTopOfTarget: target object의 top의 마지막 점의 값, 즉 바운더리 박스의 좌측 맨 아래의 점의 값
      // lastLeftOfTarget: target object의 left의 마지막 점의 값, 즉 바운더리 박스의 아래쪽 맨 오른쪽 점의 값
      const lastTopOfTarget = target.get('top') + (target.get('height') * target.scaleY * original.scaleY) / 2;
      const lastLeftOfTarget = target.get('left') + (target.get('width') * target.scaleX * original.scaleX) / 2;

      // topOfOriginal: 원본 리소스를 불러올 때, target 리소스의 우하향 점에 맞게 생성할 수 있는 top값
      // leftOfOriginal: 원본 리소스를 불러올 때, target 리소스의 우하향 점에 맞게 생성할 수 있는 left값
      const topOfOriginal = lastTopOfTarget + (original.height * target.scaleY * original.scaleY) / 2;
      const leftOfOriginal = lastLeftOfTarget + (original.width * target.scaleX * original.scaleX) / 2;

      // target 리소스의 height와 width의 반만큼의 위치에 생성
      original.top = topOfOriginal - (target.get('height') * target.scaleY * original.scaleY) / 2;
      original.left = leftOfOriginal - (target.get('width') * target.scaleX * original.scaleX) / 2;

      const targetScaleX = target.get('scaleX');
      const targetScaleY = target.get('scaleY');
      const originalScaleX = +original.scaleX;
      const originalScaleY = +original.scaleY;
      original.scaleX = originalScaleX * targetScaleX;
      original.scaleY = originalScaleY * targetScaleY;

      if (target.get('toPngBeforeAngle')) {
        original.angle = target.get('angle') + target.get('toPngBeforeAngle');
      } else {
        original.angle = target.get('angle');
      }
      // threshold값이 1이상이라면(선화가 적용되었다면)
      if (original.threshold >= 1) {
        original.src = original.edgeCurrentImgSrc;
        original.threshold = 0;
      }
      return await this.addObFromJson([original], true, true);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'toOriginItem()', null, true);
    }
  }

  /**
   * 이미지 filter 적용
   * @param index filter 번호
   * @param filter filter 값
   * @param target  filter 적용 대상 - 없으면 기본값(this.selectedOb)
   * @return void
   */
  applyFilterNew(index, filter, target = this.selectedOb): void {
    // @ts-ignore
    target.filters[index] = filter;
    // @ts-ignore
    target.applyFilters();
  }

  /**
   * 이미지 첫 클릭시 filter 값만 초기화
   * @param index filter 번호
   * @param filter filter 값
   */
  addImgFilterSet(index, filter) {
    try {
      if (this.selectedOb === 'unselected') {
        return;
      }
      this.selectedOb.filters[index] = filter;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * filter value를 적용하는 함수
   * @param {number}index filter의 index
   * @param prop filter의 property
   * @param value filter 적용할 value
   * @param {any} target filter 적용할 target
   * @return {Promise<void>}
   */
  async applyFilterValue(index: number, prop, value, target = this.selectedOb): Promise<void> {
    try {
      // @ts-ignore
      if (target.filters[index]) {
        // @ts-ignore
        target.filters[index][prop] = value;
        // @ts-ignore
        target.applyFilters();
        this.canvas.requestRenderAll();
      }
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'applyFilterValue()', null, true);
    }
  }

  /**
   * @param item 캔버스에 위치한 오브젝트
   * 없으면 선택된 오브젝트가 디폴트
   */
  public getIndex(item = null): number {
    let r;
    let activeObj;
    if (item) {
      activeObj = item;
    } else {
      activeObj = this.canvas.getActiveObject();
    }

    r = activeObj && this.canvas.getObjects().indexOf(activeObj);
    console.log('index ----> ', r);
    return r;
  }

  /**
   *히스토리 관리 - this.canvas.fire('object:modified');
   */
  historyUndoPush() {
    // 미사용
    this.canvas.fire('object:modified');
  }

  historyButtonCheckDisabled() {
    if (this.commandManager.redoHistory.length !== 0) {
      this.isNextdisabled = false;
    } else {
      this.isNextdisabled = true;
    }
    if (this.commandManager.undoHistory.length !== 0) {
      this.isBackdisabled = false;
    } else {
      this.isBackdisabled = true;
    }
  }

  /**
   * 선택한 리소스 순서 맨 뒤로 이동
   */
  async bringToBack(event) {
    event.preventDefault();
    event.stopPropagation();
    const beforeIdList = this.getIdList();
    this.selectedOb.sortObjectByZindex().sendToBack();
    if (this.isBackgroundColorMode) {
      this.selectedOb.bringForward();
    }
    this.maskSetting();
    const afterIdList = this.getIdList();
    this.canvas.requestRenderAll();
    this.canvas.fire('object:modified');
    if (JSON.stringify(beforeIdList) == JSON.stringify(afterIdList)) {
      return;
    }
    const command = new ObjectIndexChangeSetCommand({
      cut: this,
      target: this.selectedOb,
      beforeIdList: beforeIdList,
      afterIdList: afterIdList
    });
    await this.commandManager.executeCommand(command);

    this.multipleSelectionModeCancel(true);
  }

  /**
   * 선택한 리소스 순서 하나 앞으로 이동
   * @param {Event} event click event
   * @return {Promise<void>}
   */
  async bringForward(event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    const beforeIdList = this.getIdList();
    this.selectedOb.bringForward();
    this.maskSetting();
    this.canvas.requestRenderAll();
    this.canvas.fire('object:modified');
    const afterIdList = this.getIdList();
    if (_.isEqual(beforeIdList, afterIdList)) {
      return;
    }

    const command = new ObjectIndexChangeSetCommand({
      cut: this,
      target: this.selectedOb,
      beforeIdList: beforeIdList,
      afterIdList: afterIdList
    });
    await this.commandManager.executeCommand(command);

    this.multipleSelectionModeCancel(true);
  }

  /**
   * 선택한 리소스 순서 맨 앞으로 이동
   * @param {Event} event click event
   * @return {Promise<void>>}
   */
  async bringToFront(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    const beforeIdList = this.getIdList();
    this.selectedOb.sortObjectByZindex().bringToFront();
    this.maskSetting();
    this.canvas.requestRenderAll();
    this.canvas.fire('object:modified');
    const afterIdList = this.getIdList();
    if (_.isEqual(beforeIdList, afterIdList)) {
      return;
    }
    const command = new ObjectIndexChangeSetCommand({
      cut: this,
      target: this.selectedOb,
      beforeIdList: beforeIdList,
      afterIdList: afterIdList
    });
    await this.commandManager.executeCommand(command);

    this.multipleSelectionModeCancel(true);
  }

  /**
   * 선택한 리소스 순서 하나 뒤로 이동
   * @param {Event} event click event
   * @return {Promise<void>}
   */
  async sendBackward(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    const beforeIdList = this.getIdList();
    this.selectedOb.sendBackwards();
    this.maskSetting();
    this.canvas.requestRenderAll();
    this.canvas.fire('object:modified');
    const afterIdList = this.getIdList();

    if (this.isBackgroundColorMode && beforeIdList && beforeIdList[0] !== afterIdList[0]) {
      this.selectedOb.bringForward();
    }

    if (JSON.stringify(beforeIdList) == JSON.stringify(afterIdList)) {
      return;
    }
    const command = new ObjectIndexChangeSetCommand({
      cut: this,
      target: this.selectedOb,
      beforeIdList: beforeIdList,
      afterIdList: afterIdList
    });
    await this.commandManager.executeCommand(command);

    this.multipleSelectionModeCancel(true);
  }

  /**
   * 삼성 키보드에서 수정 가능한 텍스트로 변경
   * @param {Event} event - click 이벤트
   * @param {boolean} isContextMenuItem - contextMenu 에서 변경하는지 여부. 레이어 창에서 변경하면 false
   * @param {any} ob - 레이어 창에서 변경하면 레이어 object 를 지칭
   * @returns {Promise<void>}
   */
  async makeTextEditable(event: Event, isContextMenuItem: boolean, ob: any = null): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    try {
      let target = isContextMenuItem ? this.selectedOb : ob;

      if (target.resource_type !== ResourceType.text || this.app.cache.user.role !== UserRole.meme) {
        this.app.orange('Invalid resource type or Inaccessible user role');
        return;
      }
      target.set('isEditableOnSamsungKeyboard', !target.isEditableOnSamsungKeyboard);

      if (this.isDuplicatedEditableText()) {
        target.set('isEditableOnSamsungKeyboard', false);
        await this.app.showToast('해당 텍스트의 상태를 변경할 수 없습니다. 다른 텍스트를 확인해주세요.', 500, 'dark');
      }
    } catch (e) {
      await this.app.showToast('키보드에서 변경 설정 오류. 관리자에게 문의해주세요.');
      throw new TooningCustomClientError(e, FILE_NAME, 'makeTextEditable()', this.app, true);
    }
  }

  /**
   * 삼성 키보드에서 수정 가능 여부를 판단하는 구분자 property 를 가진 텍스트가 2개 이상 존재하는지 체크
   * @returns {boolean} 중복이면 true
   */
  isDuplicatedEditableText(): boolean {
    try {
      let editableList = [];
      this.canvas
        .getObjects()
        .filter((object) => object.resource_type === ResourceType.text)
        .forEach((obj) => {
          if (obj.isEditableOnSamsungKeyboard) editableList.push(obj);
        });
      return editableList.length > 1 ? true : editableList.length > 1;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'isDuplicatedEditableText()', this.app, true);
    }
  }

  getIdList(): Array<string> {
    let idList = [];
    const getObjects = this.canvas.getObjects();
    for (let i = 0, j = getObjects.length; i < j; i++) {
      const object = getObjects[i];
      if (!object.resource_selection_id) {
        this.assignmentID(object);
      }
      const id = object.resource_selection_id;
      idList.push(id);
    }
    return idList;
  }

  getLockList(): boolean[] {
    let lockList = [];
    const getObjects = this.canvas.getObjects();
    for (let i = 0, j = getObjects.length; i < j; i++) {
      const evented = getObjects[i].evented;
      const selectable = getObjects[i].selectable;
      let isLock = true;
      if (evented && selectable) {
        isLock = false;
      }
      lockList.push(isLock);
    }
    return lockList;
  }

  async canvasResizeZoom(event = null) {
    console.log('canvasResizeZoom!!!!!!!!');
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.canvas) {
      // 컨트롤 넒이값 가져오기
      this.controlBoxWidth = this.controlBox.nativeElement.offsetWidth;
      console.log('컨트롤 넒이 ----- ' + this.controlBoxWidth);
      // 캔버스 넒이값 가져오기
      const canvasDomainHeight = this.canvasDomain.nativeElement.offsetHeight;
      const canvasDomainWidth = this.canvasDomain.nativeElement.offsetWidth;
      this.bgImgWidth = this.bgImg.nativeElement.offsetWidth;
      this.viewRatio = (this.bgImgWidth * 100) / 1080 / 100;
      console.log(this.viewRatio);
      // 캔버스 전체 설정
      this.canvas.setZoom(this.viewRatio).setHeight(canvasDomainHeight).setWidth(canvasDomainWidth);
      // 캡버스 위치 잡는 부분
      if (this.platform.width() < 767) {
        this.canvas.viewportTransform[5] = (1080 * this.viewRatio - this.canvasSize.h * this.viewRatio) * -0.5;
        this.canvas.viewportTransform[4] = 0;
        this.canvas.setViewportTransform(this.canvas.viewportTransform);
      } else {
        this.canvas.setViewportTransform([
          this.viewRatio,
          0,
          0,
          this.viewRatio,
          canvasDomainWidth / 2 - this.bgImg.nativeElement.offsetWidth / 2,
          canvasDomainHeight / 2 - this.bgImg.nativeElement.offsetWidth / 2
        ]);
        this.zoomCanvas(
          this.canvas.viewportTransform,
          this.canvasDomain.nativeElement.offsetWidth / 2,
          this.canvasDomain.nativeElement.offsetHeight / 2,
          +300
        );
      }
      this.canvas.requestRenderAll();
      this.setShowPreviewCanvas();
    }
  }

  /**
   * 현재 캔버스를 캡쳐 뜬다.
   * @param {boolean} multiply 확대할지 여부
   * @return {string | SafeUrl} 현재 캔버스의 base64
   */
  capturePng(isMultiply: boolean = true): string | SafeUrl {
    try {
      if (!this.canvas) {
        return;
      }
      let dataURL: string | SafeUrl;

      const canvasViewportTransform = this.canvas.viewportTransform;
      //  투명 처리됨.
      this.canvas.setBackgroundColor('rgb(255,255,255,0)', this.canvas.requestRenderAll.bind(this.canvas));
      // 방어코드 추가 backgroundImage 가 null이 되는 상황 방어 코드
      if (this.canvas.backgroundImage === null || !this.canvas.hasOwnProperty('backgroundImage')) {
        this.addObFromJson([JSON.parse(this.nullBackgroundImage)], false, false, false).then((value) => {
          this.canvas.setBackgroundImage(value as unknown as fabric.Image, this.canvas.renderAll.bind(this.canvas), {});
          this.canvas.backgroundImage.opacity = 0;
        });
      } else {
        this.canvas.backgroundImage.opacity = 0;
      }
      if (this.canvas.overlayImage !== null) {
        this.canvas.overlayImage.opacity = 0;
      }

      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      try {
        dataURL = this.canvas.toDataURL({
          format: 'png',
          left: LEFT - this.canvasSize.w / 2,
          top: TOP - this.canvasSize.h / 2,
          width: this.canvasSize.w,
          height: this.canvasSize.h,
          multiplier: isMultiply ? this.canvasSize.web : 1,
          quality: 0.7,
          withoutTransform: true
        });
      } catch (e) {
        this.app.orange(`toDataUrl is not working : ${e.message}`);
        // issue : https://github.com/toonsquare/tooning-repo/issues/3434
        dataURL = this.domSanitizer.bypassSecurityTrustUrl(data.panelDefaultThumbnailBase64);
      }

      // 화면 배율 원상 복구
      this.canvas.setViewportTransform(canvasViewportTransform);
      //  원상복구 처리됨.
      this.canvas.setBackgroundColor('rgb(255,255,255,1)', this.canvas.requestRenderAll.bind(this.canvas));

      // 방어코드 추가 backgroundImage 가 null이 되는 상황 방어 코드
      if (this.canvas.backgroundImage && this.canvas.hasOwnProperty('backgroundImage')) {
        this.canvas.backgroundImage.opacity = 1;
      }
      if (this.canvas.overlayImage !== null) {
        this.canvas.overlayImage.opacity = 1;
        this.canvas.requestRenderAll();
      }
      return dataURL;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'capturePng()', this.app, true);
    }
  }

  /**
   * url routing
   * @param isDemo - 무료 체험하기로 들어왔는가?
   * @param userId - 유저 ID
   * @param canvasId - 작업한 캔버스 ID
   * @param {string} goService 현재 서비스 종류
   * @return {Promise<void>}
   */
  async goUrl(isDemo = false, userId: number, canvasId: number, goService: string = ServiceType.Editor): Promise<void> {
    let newCanvasId;
    if (isDemo) {
      const { data } = await this.canvasService.canvasClone(+canvasId, userId, false);
      newCanvasId = data.canvasId;
      await this.canvasNewMake(newCanvasId);
    } else {
      switch (goService) {
        case ServiceType.Magic:
          this.app.go('magic');
          break;
        case ServiceType.Gpt:
          this.app.go('gpt/editor');
          break;
        case ServiceType.Board:
          this.app.go('board');
          await this.board.setBoardUser();
          break;
        default:
          this.app.go('/');
      }
    }
  }

  /**
   * @description 아무것도 없는 기본 페이지 흰배경 생성
   * @return {Promise<void>}
   */
  async createPageNull(): Promise<void> {
    try {
      const newPageFile: Blob[] = [];
      const newPage = new Page();
      // @ts-ignore
      // 신규추가
      const b64 = await this.fileService.b64toBlob(this.nullBase64, 'Base64');
      const newFabricBlob = new Blob([this.nullJson], { type: 'application/json' });
      // @ts-ignore
      // newPage.json = '{"version":"3.6.3","objects":[]}';
      newPage.userId = this.app.cache.user.id;
      newPage.canvasId = this.newCanvasId;
      newPage.order = 0;
      newPage.aiResult = null;
      newPageFile.push(b64);
      newPageFile.push(newFabricBlob);

      await this.pageService.createPage(newPage, newPageFile).toPromise();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'createPageNull()', this.app, true);
    }
  }

  /**
   * Desktop 과 Mobile addPage 시 사용되는 wrapper 함
   * this.addPage()에서 생성된 에러를 모두 caller 한테 throw 하고 최초 caller 가 처리한다.
   * @return {Promise<any>}
   */
  async addPageCommand(): Promise<any> {
    try {
      const command = new PageAddCommand({
        cut: this,
        callback: async () => {
          return await this.addPage();
        }
      });
      return await this.commandManager.executeCommand(command);
    } catch (e) {
      if (e instanceof TooningAddPageError) {
        throw e;
      } else {
        throw new TooningCustomClientError(e, FILE_NAME, 'addPageCommand()', this.app, true);
      }
    }
  }

  /**
   * 신규 페이지를 추가 한다.
   * 현재 선택된 페이지 (panelIndex 의 숫자)를  async 방식으로 업데이트 처리 후 신규 페이지는 sync 방식으로 생성 후
   * 긴규 페이지 아이디 리턴
   * @return {number} 신규 페이지 아이디
   */
  async addPage(): Promise<number | false> {
    let loading;
    let resultNewPageId: number;
    try {
      if (this.pageList.length > 1 && this.isTextTemplate) {
        await this.goPage(this.panelIndex);
        await this.app.showToast('템플릿 계정은 최대 페이지 두 장입니다.', 5000);
        return false;
      }

      // 텍스트 락 걸리는 버그 수정
      if (this.selectedOb.resource_type === ResourceType.text) {
        this.selectedOb.set({ selectable: true });
      }
      // 무료 사용자 페이지 수 제한
      if (!this.app.isPaidMember && this.app.cache.user.role !== UserRole.demo && this.pageList.length >= this.freeUserPageLimit) {
        this.app.blueLog('not paidMember over page');
        await this.goPage(this.panelIndex);
        const dialogRef = this.dialog.open(PaidReferralComponent, {
          width: this.app.isDesktopWidth() ? '440px' : '90%',
          height: this.app.isDesktopWidth() ? '600px' : '80%',
          data: {
            message: this.translate.instant('2')
          }
        });

        await dialogRef.afterClosed().toPromise();
      } else {
        this.app.blueLog('add page');
        const files: Blob[] = [];
        const newPageFile: Blob[] = [];
        const selectedPage = new Page();
        const newPage = new Page();
        if (this.pageList.length !== 0) {
          // 모바일 경우 panel 다 삭제 가능하기 때문에
          const fabricObjAfterUpdate = this.canvas.toDatalessJSON();

          // 기존 업데이트
          const r = this.updatePagePreviewInLocal();
          const baseFile = await this.fileService.b64toBlob(r, 'base64');
          const currentPage = this.pageList[this.panelIndex];
          selectedPage.id = currentPage.id;
          selectedPage.base64 = currentPage.base64;
          selectedPage.json = currentPage.json;

          files.push(baseFile);

          const fabricFile = this.fileService.fabricToBlob(fabricObjAfterUpdate);
          files.push(fabricFile);

          if (this.isTextTemplate && this.panelIndex === 1) {
            const copyObj = _.cloneDeep(this.canvas.getObjects());
            const textBoxJson = copyObj.map((canvasJson) => {
              if (canvasJson.resource_type !== ResourceType.guideMask) {
                textBoxJson.push(canvasJson);
              }
              {
                return canvasJson;
              }
            });
            if (textBoxJson.length > 0) {
              const groupOfObject: Group = new fabric.Group();
              groupOfObject.set('originX', 'center');
              groupOfObject.set('originY', 'center');
              // @ts-ignore
              groupOfObject.set('resource_type', SelectedType.largeGroup);
              // @ts-ignore
              groupOfObject.set('resource_preview', r);

              textBoxJson.forEach((textJson) => {
                groupOfObject.add(textJson);
              });
              const textBoxFabricFile = this.fileService.fabricToBlob(groupOfObject);
              files.push(textBoxFabricFile);
            }
          }

          const fabricObjBeforeUpdate = this.pageList[this.panelIndex].fabricObject;
          //  현재 선택된 페이지를 sync 로 저장한다.
          await this.pageService.updatePage(selectedPage, this.userRole, files, fabricObjBeforeUpdate, fabricObjAfterUpdate).toPromise();
        }

        // 신규추가
        const b64 = await this.fileService.b64toBlob(this.nullBase64, 'base64');
        const newFabricBlob = new Blob([this.nullJson], { type: 'application/json' });
        // @ts-ignore
        // newPage.json = '{"version":"3.6.3","objects":[]}';
        newPage.userId = this.app.cache.user.id;
        newPage.canvasId = this.newCanvasId;
        newPage.order = this.panelIndex + 1;
        newPageFile.push(b64);
        newPageFile.push(newFabricBlob);
        delete newPage.id;
        let newPageID: number;
        const { data } = await this.pageService.createPage(newPage, newPageFile).toPromise();
        newPageID = +data.id;
        resultNewPageId = newPageID;

        const newPageInfo = new Page().default();
        newPageInfo.fetched = false;
        newPageInfo.dirty = false;
        newPageInfo.id = newPageID;
        newPageInfo.base64 = data.base64;
        newPageInfo.img = this.nullBase64;
        newPageInfo.order = this.pageList.length;
        newPageInfo.json = data.json;
        newPageInfo.fabricObject = JSON.parse(this.nullJson); // s3 에 저장된 fabricObject 와 sync 를 맞추기 위해, 클라이언트 코드에서도 빈 페이지 (this.nullJson) 를 넣어준다.

        if (this.pageList.length > 0) {
          // 현재 선택된 패널이 기존 패널들 중 마지막이면 그냥 제일 뒤에 추가
          if (this.panelIndex === this.pageList.length - 1) {
            this.pageList.push(newPageInfo);
          } else {
            // 중간에 있는 패널이라면 선택된 패널 바로 뒤에 추가
            this.pageList.splice(this.panelIndex + 1, 0, newPageInfo);
          }

          // 추가된 페이지로 선택 이동
          this.panelIndex++;
        } else {
          this.panelIndex = 0;
          this.pageList.push(newPageInfo);
        }
        this.pageList[this.panelIndex].isChecked = true;

        await this.loadFromJSON(this.nullJson);
        await this.setGrid();
        await this.setBgColorSize();

        //페이지를 추가하자 마자 업데이트가 필요 없다고 판단
        // await this.updatePage(this.panelIndex);
        this.characterLengthOnPage = this.pageList[this.panelIndex].ai.layoutPreset.characterCount;
      }
      return resultNewPageId;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'addPage()', this.app, true);
    } finally {
      if (loading) {
        loading.hideLoader();
      }
    }
  }

  checkUpdatePage(type: PanelUpdateTypeEnum, by: PanelUpdateTriggerEnum = PanelUpdateTriggerEnum.fabric) {
    this.app.yellow(`checkUpdatePage type : ${type}, by : ${by}`);

    if (this.isPanelChanging) {
      this.app.yellow(`skip : now panel is changing`);
      return;
    }

    if (!this.isPanelsInitFinished) {
      this.app.yellow(`skip : isPanelsInitFinished`);
      return;
    }
    this.updatePage$.next({
      type,
      by
    });
  }

  /**
   * 브라우져 안에서만 페이지의 썸네일을 업데이트한다.
   * @param {number} [index=this.panelIndex];
   * @return {string | SafeUrl}
   */
  updatePagePreviewInLocal(index: number = this.panelIndex, isModified = false): string | SafeUrl {
    try {
      if (!isModified) {
        const base64 = this.capturePng();
        this.pageList[index].img = base64; // 로컬 업데이트
        return base64;
      } else {
        this.previewUpdate(index);
        return this.imageUrl;
      }
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'updatePagePreviewInLocal()', this.app, true);
    }
  }

  /**
   * 클라이언트에서 오브젝트 수정시에 페이지 미리보기 업데이트
   * @param {number} index
   * @return {void}
   */
  previewUpdate(index: number): void {
    try {
      const zoom = this.canvas.getZoom();
      const left = ((CanvasSize.defaultWidth - this.canvasSize.w) / 2) * zoom + this.canvas.viewportTransform[4];
      const top = ((CanvasSize.defaultHeight - this.canvasSize.h) / 2) * zoom + this.canvas.viewportTransform[5];
      const width = this.canvasSize.w * zoom;
      const height = this.canvasSize.h * zoom;
      //@ts-ignore
      this.canvas.setBackgroundColor('rgb(255,255,255,0)', this.canvas.requestRenderAll.bind(this.canvas));
      if (this.canvas.backgroundImage === null || !this.canvas.hasOwnProperty('backgroundImage')) {
        this.addObFromJson([JSON.parse(this.nullBackgroundImage)], false, false, false).then((value) => {
          this.canvas.setBackgroundImage(value as unknown as fabric.Image, this.canvas.renderAll.bind(this.canvas), {});
          this.canvas.backgroundImage.opacity = 0;
        });
      } else {
        this.canvas.backgroundImage.opacity = 0;
      }

      if (this.canvas.overlayImage !== null) {
        this.canvas.overlayImage.opacity = 0;
      }

      const el = this.canvas.toCanvasElement(Math.floor(1 / zoom), {
        left: left,
        top: top,
        width: width,
        height: height
      });

      this.canvas.setBackgroundColor('rgb(255,255,255,1)', this.canvas.requestRenderAll.bind(this.canvas));
      if (this.canvas.backgroundImage) {
        this.canvas.backgroundImage.opacity = 1;
      }

      el.toBlob((blob) => {
        this.imageUrl = URL.createObjectURL(blob);
        this.imageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.imageUrl as string);
        this.pageList[index].img = this.imageUrl;
        URL.revokeObjectURL(this.imageUrl as string);
        if (this.canvas.overlayImage !== null) {
          this.canvas.overlayImage.opacity = 1;
          this.canvas.requestRenderAll();
        }
      });
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'previewUpdate()', this.app, true);
    }
  }

  /**
   * 브라우져와 서버에서 page thumbnail 을 업데이트 한다.
   */
  updatePagePreview() {
    const base64 = this.updatePagePreviewInLocal();
    this.updatePageThumbnail(base64, this.panelIndex); // 서버 업데이트
  }

  /**
   * 해당 인덱스 페이지 thumbnail 정보를 update 한다.
   * @param {number} index 업데이트 하고자하는 페이지의 인덱스
   * @param {string} capturePng 캡쳐한 base64 이미지
   * @return {void}
   */
  updatePageThumbnail(capturePng: string | SafeUrl, index: number = this.panelIndex): void {
    // 페이지 정보
    const pageToUpdate = this.pageList[index];
    const newPage = new Page();
    newPage.id = pageToUpdate.id;

    // 페이지 thumbnail 정보
    const baseFile = this.fileService.b64toBlobSync(capturePng);
    this.pageService
      .updatePageThumbnail(newPage, baseFile)
      .pipe(take(1))
      .subscribe((data) => {});
  }

  /**
   * 해당 인덱스 페이지의 정보를 update , 완료 시점은 알수 없음
   * @param {number} index 업데이트 하고자하는 페이지의 인덱스
   * @param {string} pageBase64? 업데이트할 페이지의 썸네일 base64
   * @param {string} pageJson? 업데이트할 페이지의 json
   * @return {Promise} void
   * @return void
   */
  async updatePage(index: number, pageBase64?: string, pageJson?: string): Promise<void> {
    try {
      // 현재 페이지 선택
      const pageToUpdate = this.pageList[index];
      // 현재 페이지가 비어 있다면 리턴
      if (!pageToUpdate) {
        this.app.redLog(`updatePage 에서  index: ${index} 에 업데이트할 page 가 없습니다.`);
        this.updatePageComplete$.next({ status: true, dirty: false });
        return;
      }
      // 현재 페이지에  수정 사항이 없다면 리턴
      // 페이지의 스위치는 dirty 체크와 상관없으므로 조건문 추가
      if (!pageToUpdate.dirty && !this.isPageSwitched) {
        this.app.redLog(`updatePage ${index} is not dirty skip`);
        this.updatePageComplete$.next({ status: true, dirty: pageToUpdate.dirty });
        return;
      }

      if (this.isPageSwitched) {
        if (!pageBase64 || !pageJson) {
          this.app.redLog(`page switch에서 pageBase64 또는 pageJson이 없습니다.`);
          return;
        }
      }

      this.app.green(`index : ${index} : updatePage`);
      const files: Blob[] = [];
      // 현재 캔버스를 base64로 캡쳐
      const panelCapture = this.isPageSwitched ? pageBase64 : this.capturePng();
      // 현재 캔버스의 json 파일 추출
      const fabricObjAfterUpdate = this.isPageSwitched ? pageJson : this.canvas.toDatalessJSON(this.dataLessList);
      // base64 를 blob 으로 변경
      const baseFile = this.fileService.b64toBlobSync(panelCapture);

      // 업데이트하기 위해 비어있는 page 생성
      const newPage = new Page();

      // update 할 페이지의 아이디 설정
      newPage.id = pageToUpdate.id;
      // update 할 페이지의 순서 설정
      newPage.order = index;
      // update 할 페이지를 포함하는 캔버스 아이디 설정
      newPage.canvasId = this.newCanvasId;
      // update 할 페이지가 인공지능을 통해 생성된 경우, 인공지능 데이터 설정
      newPage.aiResult = JSON.stringify(pageToUpdate.ai);

      //1. 브라우져에서 s3로 바로 업로드하기 위해 페이지 썸네일과, fabric 의 주소가 필요하다
      //2. 주소를 파싱해서 s3 key 를 추출한다.
      newPage.base64 = pageToUpdate.base64;
      newPage.json = pageToUpdate.json;
      // 캔버스 썸네일 설정
      files.push(baseFile);

      const fabricFile = this.fileService.fabricToBlob(fabricObjAfterUpdate);
      files.push(fabricFile);

      pageToUpdate.img = panelCapture; // 우측 페이지 리스트 썸네일 다시 설정

      if (this.userRole === UserRole.textTemplate && index === 1) {
        const copyObj = _.cloneDeep(this.canvas.getObjects());
        const textBoxJson = [];
        copyObj.forEach((canvasJson) => {
          if (canvasJson.resource_type !== ResourceType.guideMask) {
            textBoxJson.push(canvasJson);
          }
        });
        if (textBoxJson.length > 0) {
          const groupOfObject: Group = new fabric.Group();
          groupOfObject.set('originX', 'center');
          groupOfObject.set('originY', 'center');
          // @ts-ignore
          groupOfObject.set('resource_type', SelectedType.largeGroup);
          // @ts-ignore
          groupOfObject.set('resource_preview', panelCapture);
          textBoxJson.forEach((textBox) => {
            groupOfObject.add(textBox);
          });
          const textBoxFabricFile = this.fileService.fabricToBlob(groupOfObject);
          files.push(textBoxFabricFile);
        }
      }

      const fabricObjBeforeUpdate = pageToUpdate.fabricObject; // 페이지 업데이트가 적용되기 이전의 fabric obj
      try {
        this.isSavePocessingComplete = 1;
        await this.pageService.updatePage(newPage, this.userRole, files, fabricObjBeforeUpdate, fabricObjAfterUpdate).toPromise();
        this.isSavePocessingComplete = 2;

        pageToUpdate.fabricObject = fabricObjAfterUpdate;
        pageToUpdate.objectsSize = fabricObjAfterUpdate.objects.length;
        pageToUpdate.dirty = false;
        console.log(`page.order : ${pageToUpdate.order}, id : ${pageToUpdate.id} , dirty : ${pageToUpdate.dirty}`);
        pageToUpdate.fetched = true;
        pageToUpdate.ai.layoutPreset.characterCount = this.characterLengthOnPage;
        this.app.redLog(`${index} update panel success fetched : ${pageToUpdate.fetched} dirty : ${pageToUpdate.dirty}`);
        this.updatePageComplete$.next({ status: true, dirty: pageToUpdate.dirty });
      } catch (error) {
        // 서버에서 페이지 업데이트가 실패하면, 클라이언트에서도 페이지 업데이트 되기 이전의 fabricObj 를 세팅해준다.
        pageToUpdate.fabricObject = fabricObjBeforeUpdate;
        pageToUpdate.objectsSize = fabricObjBeforeUpdate.objects.length;
        throw error;
      }
    } catch (error) {
      if (error && error.name === 'TimeoutError') {
        throw new TooningCustomClientError(error.message, FILE_NAME, 'updatePage().TimeOutError', this.app);
      } else {
        throw new TooningCustomClientError(error.message, FILE_NAME, 'updatePage().PageUpdateErro', this.app);
      }
    } finally {
      setTimeout(() => {
        this.isSavePocessingComplete = 3;
      }, 2000);
    }
  }

  /**
   * 현재 보이는 캔버스의 데이터와 썸네일을 생성해 index 번째 있는 page 에 업데이트한다.
   * index 와 this.panelIndex 가 다른 경우도 존재하는 경우가 있다.
   * goPage(index)-> updatePageSync(index) 순으로 call 하면 싱크가 잘되나
   * updatePageSync(index) 만 하면 안되는 경우도 존재한다. 따라서 보안하기 위해 updatePageSyncByIndex 함수 를 추가했다.
   * update return 되는 시점에 실제로 page update 완료
   * @param index [this.panelIndex ]업데이트 하고자하는 페이지의 인덱스 기본값은 현재 페이지 index
   * @param force [true] dirty 체크 없이 무조건 없데이트 한다. 기본값은 true
   * @return {Promise} {status, dirty}
   */
  async updatePageSync(index = this.panelIndex, force = true): Promise<UpdatePage> {
    try {
      const pageToUpdate = this.pageList[index];
      if (!pageToUpdate) {
        this.app.redLog(`updatePage 에서  index: ${index} 에 업데이트할 page 가 없습니다.`);
        return { status: false, dirty: false };
      }
      if (!force) {
        if (!pageToUpdate.dirty) {
          this.app.redLog(`updatePanel ${index} is not dirty skip`);
          return { status: false, dirty: pageToUpdate.dirty };
        }
      }
      this.app.green(`index : ${index} : updatePanel`);
      const files: Blob[] = [];
      const panelCapture = this.capturePng();
      // 현재 캔버스의 json 파일 추출
      const fabricObjAfterUpdate = this.canvas.toDatalessJSON(this.dataLessList);

      // base64 를 blob 으로 변경
      const baseFile = this.fileService.b64toBlobSync(panelCapture);
      // json 을 blob 으로 변경

      // 업데이트하기 위해 비어있는 page 생성
      const newPage = new Page();

      // update 할 페이지의 아이디 설정
      newPage.id = pageToUpdate.id;
      // update 할 페이지의 순서 설정
      newPage.order = index;
      // update 할 페이지를 포함하는 캔버스 아이디 설정
      newPage.canvasId = this.newCanvasId;
      // update 할 페이지가 인공지능을 통해 생성된 경우, 인공지능 데이터 설정
      newPage.aiResult = JSON.stringify(pageToUpdate.ai);

      //1. 브라우져에서 s3로 바로 업로드하기 위해 페이지 썸네일과, fabric 의 주소가 필요하다
      //2. 주소를 파싱해서 s3 key 를 추출한다.
      newPage.base64 = pageToUpdate.base64;
      newPage.json = pageToUpdate.json;
      // 캔버스 썸네일 설정
      files.push(baseFile);

      const fabricFile = this.fileService.fabricToBlob(fabricObjAfterUpdate);
      files.push(fabricFile);

      pageToUpdate.img = panelCapture; // 우측 페이지 리스트 썸네일 다시 설정

      if (this.userRole === UserRole.textTemplate && index === 1) {
        const copyObj = _.cloneDeep(this.canvas.getObjects());
        const textBoxJson = [];
        copyObj.forEach((canvasJson) => {
          if (canvasJson.resource_type !== ResourceType.guideMask) {
            textBoxJson.push(canvasJson);
          }
        });
        if (textBoxJson.length > 0) {
          const groupOfObject: Group = new fabric.Group();
          groupOfObject.set('originX', 'center');
          groupOfObject.set('originY', 'center');
          // @ts-ignore
          groupOfObject.set('resource_type', SelectedType.largeGroup);
          // @ts-ignore
          groupOfObject.set('resource_preview', panelCapture);
          textBoxJson.forEach((textBox) => {
            groupOfObject.add(textBox);
          });
          const textBoxFabricFile = this.fileService.fabricToBlob(groupOfObject);
          files.push(textBoxFabricFile);
        }
      }
      try {
        const fabricObjBeforeUpdate = pageToUpdate.fabricObject;
        await this.pageService.updatePage(newPage, this.userRole, files, fabricObjBeforeUpdate, fabricObjAfterUpdate).toPromise();
      } catch (e) {
        if (e && e.name === 'TimeoutError') {
          throw new TooningCustomClientError(e, FILE_NAME, 'updatePageSync().TimeoutError', this.app);
        } else {
          throw e;
        }
      }

      this.isSavePocessingComplete = 1;
      this.timerIdSavePocessing = setTimeout(() => {
        this.isSavePocessingComplete = 2;
        this.timerIdSavePocessing = setTimeout(() => {
          this.isSavePocessingComplete = 3;
        }, 1000);
      }, 800);
      pageToUpdate.fabricObject = fabricObjAfterUpdate;
      pageToUpdate.dirty = false;
      pageToUpdate.fetched = false;
      pageToUpdate.objectsSize = fabricObjAfterUpdate.objects.length;
      pageToUpdate.ai.layoutPreset.characterCount = this.characterLengthOnPage;
      this.app.redLog(`${index} update panel success fetched : ${pageToUpdate.fetched} dirty : ${pageToUpdate.dirty}`);
      return { status: true, dirty: pageToUpdate.dirty };
    } catch (e) {
      this.app.orange(e.message);
      if (e instanceof TooningPageUpdateTimeOutError) {
        throw e;
      } else {
        throw new TooningCustomClientError(e, FILE_NAME, 'updatePageSync().PageUpdateError', this.app, true);
      }
    } finally {
      if (!this.guideMask) await this.guideMaskBringToFront();
    }
  }

  /** updatePageSync 가 index 의 page 정보를 가져오는 것이아니라 현재 캔버스르 가져오는 문제가 있어서 이 함수를 만듬
   *
   * index 에  있는 페이지 정보를 가져와서 업데이트 시도한다. 현재캔버스를 긁어 오지 않는다.!!
   * @param index [this.panelIndex ]업데이트 하고자하는 페이지의 인덱스 기본값은 현재 페이지 index
   * @param force [true] dirty 체크 없이 무조건 없데이트 한다. 기본값은 true
   * @return {Promise} {status, dirty}
   */
  async updatePageSyncByIndex(index = this.panelIndex, force = true): Promise<UpdatePage> {
    try {
      const pageToUpdate = this.pageList[index];
      if (!pageToUpdate) {
        this.app.redLog(`updatePage 에서  index: ${index} 에 업데이트할 page 가 없습니다.`);
        return { status: false, dirty: false };
      }
      if (!force) {
        if (!pageToUpdate.dirty) {
          this.app.redLog(`updatePanel ${index} is not dirty skip`);
          return { status: false, dirty: pageToUpdate.dirty };
        }
      }
      this.app.green(`index : ${index} : updatePanel`);
      const files: Blob[] = [];
      // updatePageSync 는 현재 캔버스를 캡쳐해서 page[index] 에 저장하는 문제가 있ek.
      const panelCapture = this.pageList[index].img;
      const fabricObjAfterUpdate = this.pageList[index].fabricObject;

      let baseFile;
      try {
        //blob을 이용해도 이후에 이전 로직 그대로 쓰기위해서 추가
        //@ts-ignore
        if (panelCapture.hasOwnProperty('changingThisBreaksApplicationSecurity')) {
          //@ts-ignore
          if (panelCapture.changingThisBreaksApplicationSecurity.substr(0, 4) === 'blob') {
            //@ts-ignore
            baseFile = await fetch(panelCapture.changingThisBreaksApplicationSecurity).then((r) => r.blob());
            baseFile = new Blob([baseFile], { type: 'text/plain' });
          }
        } else {
          //base64 를 blob 으로 변경
          baseFile = this.fileService.b64toBlobSync(panelCapture);
        }
      } catch (e) {
        const response = await fetch(panelCapture as string);
        baseFile = await response.blob();
      }
      // json 을 blob 으로 변경
      this.selectedBase64 = index === 0 ? this.pageList[0].img : panelCapture; // 첫페이지 이미지 ( 업데이트된 캔버스 cut4-list에서 로딩 없이 바로 보이게 하기 위한 용도 )

      // 업데이트하기 위해 비어있는 page 생성
      const newPage = new Page();

      // update 할 페이지의 아이디 설정
      newPage.id = pageToUpdate.id;
      // update 할 페이지의 순서 설정
      newPage.order = index;
      // update 할 페이지를 포함하는 캔버스 아이디 설정
      newPage.canvasId = this.newCanvasId;
      // update 할 페이지가 인공지능을 통해 생성된 경우, 인공지능 데이터 설정
      newPage.aiResult = JSON.stringify(pageToUpdate.ai);

      //1. 브라우져에서 s3로 바로 업로드하기 위해 페이지 썸네일과, fabric 의 주소가 필요하다
      //2. 주소를 파싱해서 s3 key 를 추출한다.
      newPage.base64 = pageToUpdate.base64;
      newPage.json = pageToUpdate.json;
      // 캔버스 썸네일 설정
      files.push(baseFile);

      const fabricFile = this.fileService.fabricToBlob(fabricObjAfterUpdate);
      files.push(fabricFile);

      pageToUpdate.img = panelCapture; // 우측 페이지 리스트 썸네일 다시 설정

      if (this.userRole === UserRole.textTemplate && index === 1) {
        const copyObj = _.cloneDeep(this.canvas.getObjects());
        const textBoxJson = [];
        copyObj.forEach((canvasJson) => {
          if (canvasJson.resource_type !== ResourceType.guideMask) {
            textBoxJson.push(canvasJson);
          }
        });
        if (textBoxJson.length > 0) {
          const groupOfObject: Group = new fabric.Group();
          groupOfObject.set('originX', 'center');
          groupOfObject.set('originY', 'center');
          // @ts-ignore
          groupOfObject.set('resource_type', SelectedType.largeGroup);
          // @ts-ignore
          groupOfObject.set('resource_preview', panelCapture);
          textBoxJson.forEach((textBox) => {
            groupOfObject.add(textBox);
          });
          const textBoxFabricFile = this.fileService.fabricToBlob(groupOfObject);
          files.push(textBoxFabricFile);
        }
      }
      const fabricObjBeforeUpdate = pageToUpdate.fabricObject;
      try {
        await this.pageService.updatePage(newPage, this.userRole, files, fabricObjBeforeUpdate, fabricObjAfterUpdate).toPromise();
      } catch (e) {
        if (e && e.name === 'TimeoutError') {
          throw new TooningCustomClientError(e, FILE_NAME, 'updatePageSyncByIndex().TimeoutError', this.app, true);
        } else {
          throw e;
        }
      }

      this.isSavePocessingComplete = 1;
      this.timerIdSavePocessing = setTimeout(() => {
        this.isSavePocessingComplete = 2;
        this.timerIdSavePocessing = setTimeout(() => {
          this.isSavePocessingComplete = 3;
        }, 1000);
      }, 800);
      pageToUpdate.fabricObject = fabricObjAfterUpdate;
      pageToUpdate.dirty = false;
      pageToUpdate.fetched = false;
      pageToUpdate.objectsSize = fabricObjAfterUpdate.objects.length;
      pageToUpdate.ai.layoutPreset.characterCount = this.characterLengthOnPage;
      this.app.redLog(`${index} update panel success fetched : ${pageToUpdate.fetched} dirty : ${pageToUpdate.dirty}`);
      return { status: true, dirty: pageToUpdate.dirty };
    } catch (e) {
      this.app.orange(e.message);
      if (e instanceof TooningPageUpdateTimeOutError) {
        throw e;
      } else {
        throw new TooningCustomClientError(e, FILE_NAME, 'updatePageSyncByIndex().PageUpdateError', this.app, true);
      }
    }
  }

  /**
   * fabric objects 안에 fontFamily 을 찾아 낸다.
   * objects 가 group 인 경우도 recursive 하게 search 한다
   * @param {TooningFabricObject}objects fontFamily 정보를 찾으려는 fabricObject
   * @returns {Array<string>}
   */
  getFontFamily(objects: Array<TooningFabricObject>): Array<string> {
    try {
      let fontsFamilyList: Array<string> = [];
      for (let i = 0; i < objects.length; i++) {
        const object = objects[i];
        if (object.type === ResourceType.group) {
          fontsFamilyList = fontsFamilyList.concat(this.getFontFamily(object.objects));
        } else if (object.type === ResourceType.textbox) {
          for (const [_, value] of Object.entries(object.styles)) {
            for (const [_, value2] of Object.entries(value as object)) {
              for (const [key3, value3] of Object.entries(value2 as object)) {
                if (key3 === TextStyleType.FontFamily) {
                  if (value3 !== DefaultLoading.fontName) {
                    fontsFamilyList.push(value3 as string);
                  }
                }
              }
            }
          }
        }
      }
      fontsFamilyList = _.uniq<string>(fontsFamilyList).filter((font) => !_.isEmpty(font));
      this.app.redLog(`fontFamily : ${fontsFamilyList}`);
      return fontsFamilyList;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'getFontFamily()', this.app, true);
    }
  }

  /**
   * 페이지를 실제로 보여줄때 사용하는 기능을 제공
   * 내부적으로 loadFromJson 을 통해 fabric json 파일을 로딩한다.
   * @param index 이동하려는 pages 의 array index
   * @return {Promise<void>}
   */
  async showPage(index: number): Promise<void> {
    try {
      const pageToGo = this.pageList[index];

      await this.fetchPage(pageToGo, index);
      if (_.isNumber(this.panelIndex) && _.isNumber(index) && this.panelIndex !== index) {
        // 다른 페이지로 이동했을 때만 removeCacheCanvas 처리
        this.canvas.getObjects().forEach((obj) => {
          this.removeCacheCanvas(obj);
        });
      }
      await this.loadFromJSON(pageToGo.fabricObject, index);
      this.characterLengthOnPage = this.getCharacterOnCanvas(pageToGo.fabricObject.objects, false).length;
      this.canvas.historyColores = [...this.historyColores];
    } catch (e) {
      if (e instanceof TooningLoadFromJsonEmptyError) {
        throw e;
      } else {
        throw new TooningCustomClientError(e.message, FILE_NAME, 'showPage()', this.app);
      }
    }
  }

  /**
   * page object의 json 프로퍼티를 통해 page를 fetch함
   * @param pageToGo fetch할 page
   * @param index fetch할 page의 index
   * @return {Promise<void>}
   */
  async fetchPage(pageToGo, index): Promise<void> {
    try {
      const isNeedToFetch = !pageToGo.fetched && pageToGo.dirty && !pageToGo.fabricObject;
      if (isNeedToFetch) {
        this.app.orange(`fetch panel :${index}`);
        if (!pageToGo.json) {
          throw new Error('data.json is empty');
        }
        this.app.orange('fetch panel 2');
        let data = await this.getHttpUrl(pageToGo.json).toPromise();

        if (typeof data === PrimitiveType.string) {
          data = JSON.parse(data);
        }
        // text, styles[] undefined 인 경우 방어코드
        for (let i = data.objects.length - 1; i >= 0; i--) {
          if (data.objects[i].type && data.objects[i].type === ResourceType.textbox && data.objects[i].text === undefined) {
            if (data.objects[i].userInputText === undefined) {
              // userInputText 도 없는 경우는 삭제
              data.objects.splice(i, 1);
            } else {
              // text 에 userInputText 넣어줌
              data.objects[i].text = data.objects[i].userInputText;
              data.objects[i].resource_name = data.objects[i].userInputText;
              data.objects[i].styles = {};
              if (data.objects[i].isEditing) {
                data.objects[i].isEditing = false;
              }
            }
          }
        }

        const result = typeof data === 'string' ? JSON.parse(data) : data;
        this.app.orange('fetch panel 3');
        pageToGo.dirty = false;
        pageToGo.fetched = true;
        pageToGo.fabricObject = result;
        if (result.hasOwnProperty('objects')) {
          pageToGo.objectsSize = result.objects.length;
        } else {
          pageToGo.objectsSize = null;
        }

        this.setWaterMark(pageToGo.fabricObject.objects);
        this.app.orange(`page fetched :  ${pageToGo.fetched} , dirty : ${pageToGo.dirty}`);
      } else {
        this.app.orange('page cached');
      }
    } catch (e) {
      if (e instanceof TooningLoadFromJsonEmptyError) {
        throw e;
      } else {
        throw new TooningCustomClientError(e.message, FILE_NAME, 'fetchPage()', this.app);
      }
    }
  }

  removeOldWaterMark(fabricObject: object): void {
    if (
      fabricObject.hasOwnProperty('overlayImage') &&
      // @ts-ignore
      fabricObject.overlayImage.hasOwnProperty('src') &&
      // @ts-ignore
      fabricObject.overlayImage.src.includes('watermark_0.png')
    ) {
      // @ts-ignore
      fabricObject.overlayImage = null;
    }
  }

  /**
   * goPage 에 필요한 tasks를 objet 형식으로 리턴한다.
   * @return {any}
   */
  goPageTasksReturn(): any {
    return {
      /**
       * goPage task 중 첫번째,
       * 페이지가 존재하는지 확인, 들어가려는 페이지와 나가려는 페이지가 같다면 그냥 return;
       * @param {number} index 이동할 페이지의 인덱스
       * @param {boolean} forceUpdate 보고있는 페이지와 이동 할 페이지가 같을 때 스킵할지, false 면 스킵
       * @param {string} message
       * @param {boolean} enableLoading
       * @param {boolean} close Home으로 나갈지 말지 판단하는 파라미터, true면 goHomeBack()에서 전달
       * @return {Promise<void>}
       */
      goPageReturnConditionCheckTask: async (
        index?: number,
        forceUpdate?: boolean,
        message?: string,
        enableLoading?: boolean,
        close?: boolean
      ): Promise<void> => {
        const indexPanelToLeave = this.panelIndex;
        const indexPanelToGo = index;

        const pageToGo = this.pageList[indexPanelToGo];
        if (pageToGo === undefined || pageToGo === null) {
          throw new Error('The page to move is undefined or empty. please try again.');
        }
        if (!pageToGo && close) {
          await this.app.showToast('Something has interrupted connecting to Home. This page will move to My work page.', 2000);
          this.app.go('4cut-list');
          return;
        }

        if (!forceUpdate) {
          if (indexPanelToLeave === indexPanelToGo) {
            this.app.green(`skip indexPanelToLeave : ${indexPanelToLeave} == indexPanelToGo : ${indexPanelToGo}`);
            return;
          }
        }
      },
      /**
       * goPage task 중 두번째, 텍스트 박스 가 수정 중인지 확인한다.
       * @param {number} index
       * @param {boolean} forceUpdate
       * @param {string} message
       * @param {boolean} enableLoading
       * @return {Promise<void>}
       */
      goPageTextEditingCheckTask: async (index?: number, forceUpdate?: boolean, message?: string, enableLoading?: boolean): Promise<void> => {
        try {
          await this.panelTextEditing();
        } catch (e) {
          this.app.presentAlert(this.translate.instant('error.editing text page update error')).then(() => {
            throw e;
          });
        }
      },
      /**
       * goPage task 중 세번째, 페이지 이동전에 해야할 작업을 세팅한다.
       * @param {number} index
       * @param {boolean} forceUpdate
       * @param {string} message
       * @param {boolean} enableLoading
       */
      goPageStartSettingTask: (index?: number, forceUpdate?: boolean, message?: string, enableLoading?: boolean): void => {
        this.isPanelChanging = true;
        this.objectEventSetupOff();
        this.commandManager.offHistory();
        this.historyColores = this.canvas.historyColores ? [...this.canvas.historyColores] : [];
      },
      /**
       * goPage task 중 네번째, 이동하려는 페이지 조건을 확인한다.
       * @param {number} index
       * @param {boolean} forceUpdate
       * @param {string} message
       * @param {boolean} enableLoading
       * @param {Loading} loading
       */
      goPagePageToGoConditionTask: (index?: number, forceUpdate?: boolean, message?: string, enableLoading?: boolean, loading?: Loading): void => {
        const indexPanelToGo = index;
        const pageToGo = this.pageList[index];
        this.app.greenLog(`panel to go index : ${indexPanelToGo}`);
        this.app.greenLog(`panel fetched : ${pageToGo.fetched}`);
        if (!pageToGo.fetched && pageToGo.dirty && !pageToGo.fabricObject) {
          if (enableLoading) {
            loading.showLoader(message).then();
          }
        }
      },
      /**
       * goPage task 중 다섯번째, showPage 호출
       * @param {number} index
       * @param {boolean} forceUpdate
       * @param {string} message
       * @param {boolean} enableLoading
       * @return {Promise<void>}
       */
      goPageShowPageTask: async (index?: number, forceUpdate?: boolean, message?: string, enableLoading?: boolean): Promise<void> => {
        await this.showPage(index);
        // https://github.com/toonsquare/tooning-repo/issues/3372
        // 썸네일이 직전 페이지의 썸네일로 변경되는 버그가 있어서 위치를 showPage 아래로 이동
        // 의미상으로 showPage 이후 지금의 인덱스를 변경하는게 맞음
        this.panelIndex = index;
      },
      /**
       * goPage task 중 여섯째, page preview update
       * @param {number} index
       * @param {boolean} forceUpdate
       * @param {string} message
       * @param {boolean} enableLoading
       */
      goPageRemoveWaterMarkTask: (index?: number, forceUpdate?: boolean, message?: string, enableLoading?: boolean): void => {
        if (this.isRemovedWaterMark) {
          this.updatePagePreview();
          this.isRemovedWaterMark = false;
        }
      },
      /**
       * goPage task 중 일곱번째, 기본 배경 세팅
       * @param {number} index
       * @param {boolean} forceUpdate
       * @param {string} message
       * @param {boolean} enableLoading
       * @return {Promise<void>}
       */
      goPageBackgroundCheckTask: async (index?: number, forceUpdate?: boolean, message?: string, enableLoading?: boolean): Promise<void> => {
        // 방어코드 추가 backgroundImage 가 null이 되는 상황 방어 코드
        if (this.canvas.backgroundImage === null || !this.canvas.hasOwnProperty('backgroundImage')) {
          const temp = await this.addObFromJson([JSON.parse(this.nullBackgroundImage)], false, false, false);
          this.canvas.setBackgroundImage(temp, this.canvas.renderAll.bind(this.canvas), {});
        }
      },
      /**
       * goPage task 중 여덟째, canvas 위에 올라간 page 의 objects 를 확인 후, 후처리
       * @param {number} index
       * @param {boolean} forceUpdate
       * @param {string} message
       * @param {boolean} enableLoading
       */
      goPageObjectsCheckTask: (index?: number, forceUpdate?: boolean, message?: string, enableLoading?: boolean): void => {
        const pageToGo = this.pageList[index];
        if (this.canvas.hasOwnProperty('_objects')) {
          this.app.greenLog(`${index} 페이지 첫 진입: ${pageToGo.isFirstEnter}`);
          const objList = this.canvas.getObjects();
          const uniqueIds = [];
          objList.map((obj) => {
            this.checkText(obj, pageToGo.isFirstEnter);

            // 간헐적 락 현상 방지코드
            if (obj.evented && !obj.selectable && obj.resource_type !== ResourceType.bgColor) {
              obj.selectable = true;
              obj.evented = true;
            }

            // 유니크ID 겹칠경우 재할당
            uniqueIds.push(obj.resource_selection_id);
            for (let i = 0; i < uniqueIds.length - 1; i++) {
              if (uniqueIds[i] === obj.resource_selection_id) {
                obj.resource_selection_id = undefined;
                this.assignmentID(obj);
              }
            }

            // 캐릭터 리소스 내부 오브젝트 겹쳐서 추가 생성되어있을 때 삭제
            if (obj.resource_type === ResourceType.characterSvg) {
              this.characterResourcePartsCheck(obj);
            }
          });
        }
        pageToGo.isFirstEnter = false;
      }
    };
  }

  /**
   * 페이지 이동
   * @param index 이동 할 페이지
   * @param forceUpdate 보고있는 페이지와 이동 할 페이지가 같을 때 스킵할지
   *                    false 면 스킵
   * @param message
   * @param enableLoading
   * @param close Home으로 나갈지 말지 판단하는 파라미터, true면 goHomeBack()에서 전달
   */
  async goPage(index: number, forceUpdate = false, message: string = '', enableLoading = true, close: boolean = false) {
    try {
      if (typeof index === 'string') {
        index = +index;
      }
      this.activePageIndex = index;
      const loading = new Loading();
      try {
        const tasksObject = this.goPageTasksReturn();
        this.app.greenLog(`goPanel : ${index}`);
        const tasks = [
          tasksObject.goPageReturnConditionCheckTask,
          tasksObject.goPageTextEditingCheckTask,
          tasksObject.goPageStartSettingTask,
          tasksObject.goPagePageToGoConditionTask,
          tasksObject.goPageShowPageTask,
          tasksObject.goPageRemoveWaterMarkTask,
          tasksObject.goPageBackgroundCheckTask,
          tasksObject.goPageObjectsCheckTask
        ];

        let deadline = performance.now() + 50;

        while (tasks.length > 0) {
          // Optional chaining operator used here helps to avoid
          // errors in browsers that don't support `isInputPending`:
          // @ts-ignore
          if (navigator.scheduling?.isInputPending() || performance.now() >= deadline) {
            // There's a pending user input, or the
            // deadline has been reached. Yield here:
            await this.yieldToMain();

            // Extend the deadline:
            deadline = performance.now() + 50;

            // Stop the execution of the current loop and
            // move onto the next iteration:
            continue;
          }

          // Shift the task out of the queue:
          const task = tasks.shift();

          // Run the task:
          await task(index, forceUpdate, message, enableLoading, loading, close);
        }
      } catch (e) {
        this.analyticsService.error('cut4-make-manual.service:goPanel', e.message);
        if (e instanceof TooningLoadFromJsonEmptyError) {
          throw e;
        } else {
          throw new TooningCustomClientError(e, FILE_NAME, 'goPage()', this.app, true);
        }
      } finally {
        await this.setGrid();
        this.objectEventSetup();
        this.commandManager.onHistory();

        if (enableLoading) {
          loading.hideLoader();
        }

        // 캔번스 사이즈 변경 후 업데이트 안되는 문제 코드 수정
        // https://github.com/toonsquare/tooning-repo/issues/193
        // 예전에는 페이지 전환 중인 시간이 길어서 업데이트가 안되는 문제가 있었음

        this.isPanelChanging = false;
        await this.setBgColorSize();
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'goPage()', this.app);
    }
  }

  /**
   * 천수관음 현상 방지를 위해 캐릭터 리소스의 파트 개수 체크하는 함수
   * @param obj 체크 할 캐릭터 리소스
   */
  characterResourcePartsCheck(obj) {
    const parts = obj._objects;
    if (parts.length > 0) {
      try {
        const isWaterMarkNum = parts[parts.length - 1].resource_type === ResourceType.waterMark ? 1 : 0;
        const partsLength = this.characterPartsCount + isWaterMarkNum;
        if (parts.length > partsLength) {
          const spliceLength = parts.length - partsLength;
          parts.splice(0, spliceLength);
        }
      } catch (e) {
        throw new TooningCustomClientError(e, FILE_NAME, 'characterResourcePartsCheck()', this.app);
      }
    }
  }

  /**
   * 텍스트박스 리소스 윤곽선 초기화하고
   * 텍스트 투명도 적용시 paintFirst = 'stroke' 안 먹는 버그 방어
   * @param obj 초기화 할 텍스트박스 리소스
   */
  initTextStrokeStyle(obj, topScale = 1) {
    try {
      const styles = obj.getSelectionStyles(0, obj.text.length);
      this.deleteLegacyStroke(styles);
      for (let i = 0; i < styles.length; i++) {
        if (styles[i].hasOwnProperty(TextStyleType.StrokeWidth)) {
          const width = Math.round(styles[i].hasOwnProperty(TextStyleType.Width) ? styles[i].width : styles[i].strokeWidth * obj.scaleX * topScale);
          const stroke = styles[i].hasOwnProperty(TextStyleType.Stroke) ? styles[i].stroke : obj.stroke;
          obj.setSelectionStyles(
            {
              width,
              stroke
            },
            i,
            i + 1
          );
        }
      }

      if (Object.keys(styles).length !== 0) {
        for (let j = 0; j < styles.length; j++) {
          if (!styles[j].fontFamily) {
            obj.setSelectionStyles({ fontFamily: obj?.textHistory?.fontFamily || obj.fontFamily }, j, j + 1);
          }
        }
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'initTextStrokeStyle()', this.app);
    }
  }

  /**
   * 캔버스 순서 변경시 첫번째 캔버스 썸네일 리스트에 보이도록 설정 업데이트 함수
   * @return {Promise<void>}
   */
  async firstCanvasOrderPreviewUpdate(): Promise<void> {
    try {
      const newPage = new Page();
      // @ts-ignore
      newPage.id = this.pageList[0].id;
      newPage.order = 0;
      newPage.canvasId = this.newCanvasId;
      // subscribe 를 사용해서 async 하게 처리하는 코드를, toPromise 를 사용해서 sync 하게 처리하게 수정
      await this.pageService.updatePage(newPage, this.userRole).pipe(take(1)).toPromise();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'firstCanvasOrderPreviewUpdate()', this.app);
    }
  }

  /**
   * page 스키마의 order 컬럼을 현재 에디터의 page 순서와 일치하도록 업데이트 해준다.
   * @return {Promise<void>}
   */
  async pagesOrderUpdate(): Promise<void> {
    const promises: Array<Promise<any>> = [];
    for (const [index, panel] of this.pageList.entries()) {
      if (index !== +panel.order) {
        panel.order = index;

        const newPage = new Page();
        newPage.id = panel.id;
        newPage.order = index;

        promises.push(this.pageService.updatePage(newPage, this.userRole).toPromise());
      }
    }
    await Promise.all(promises);
  }

  /**
   * 텍스트 수정 모드시,  페널 업데이트 완료가 오면, 텍스트 수정 모드에서 나온다.
   */
  async panelTextEditing(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.selectedOb.isEditing) {
        this.updatePageComplete$.pipe(take(1)).subscribe(async ({ status }) => {
          if (status) {
            this.app.green(`editing text save status :  ${status}`);
            this.selectedOb.exitEditing();
            resolve(status);
          } else {
            reject(new TooningCustomClientError('editing text page update error', FILE_NAME, 'panelTextEditing()', this.app));
          }
        });
        this.canvas.fire('object:modified');
      } else {
        resolve(true);
      }
    });
  }

  async pageReorder2Sortablejs(event: any, pageGo = false) {
    const command = new PageSwitchCommand({
      cut: this,
      beforeSwitchIndex: event.oldIndex,
      afterSwitchIndex: event.newIndex,
      callback: async () => {
        await this.pagesOrderUpdate();
        if (event.oldIndex === this.panelIndex) {
          this.panelIndex = event.newIndex;
        } else {
          if (event.oldIndex > this.panelIndex && this.panelIndex >= event.newIndex) {
            this.panelIndex = this.panelIndex + 1;
            console.log('값보다 클때');
          }
          if (event.oldIndex < this.panelIndex && this.panelIndex <= event.newIndex) {
            this.panelIndex = this.panelIndex - 1;
            console.log('값보다 작을때');
          }
        }
      }
    });
    await this.commandManager.executeCommand(command);
    if (!this.pageList[event.newIndex].isChecked) {
      await this.goPageWithCanvasUpdateState(event.newIndex, event, null, false, true);
    }
  }

  /**
   * pagwSwitch를 할 때 switch한 page에 dirty가 있을 때 update한다.
   * @param beforeSwitchIndex pagwSwitch할 때 시작한 index
   * @param afterSwitchIndex pageSwitch할 때 이동한 index
   * @return {Promise<void>}
   */
  async pageSwitchUpdate(beforeSwitchIndex: number, afterSwitchIndex: number): Promise<void> {
    try {
      await this.loading.showLoader('');
      this.isPageSwitched = true;
      const updateToIndex = [beforeSwitchIndex, afterSwitchIndex];
      for (let index = 0, j = updateToIndex.length; index < j; index++) {
        const pageToUpdate = this.pageList[updateToIndex[index]];
        if (pageToUpdate.dirty === true) {
          let pageJson;
          let pageBase64;
          if (pageToUpdate.fetched === false) {
            await this.fetchPage(pageToUpdate, updateToIndex[index]);
            this.app.orange(`${pageToUpdate.order + 1}페이지가 fetch 되었습니다.`);
          } else {
            // dirty true & fetch true인 경우 update
            pageBase64 =
              typeof pageToUpdate.img === 'object'
                ? // @ts-ignore
                  await this.fileService.convertToBase64(pageToUpdate.img.changingThisBreaksApplicationSecurity)
                : await this.imgToDataURL(pageToUpdate.img);
            pageJson = this.canvas.toDatalessJSON();
            await this.updatePage(updateToIndex[index], pageBase64, pageJson);
            this.app.orange(`${pageToUpdate.order + 1}페이지가 update 되었습니다.`);
          }
        }
      }
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'pageSwitchUpdate()');
    } finally {
      this.isPageSwitched = false;
      this.loading.hideLoader();
    }
  }

  /**
   * 페이지 복제
   * @param {number} index 복제 할 페이지의 index
   * @param {boolean} pageGo 복제 후 복제 한 페이지로 이동 할 것인가
   * @return {number} 복사 후 새로 생성된 페이지 id
   */
  async copyPage(index: number, pageGo = false, event?: Event, characterMake?: CharacterMake): Promise<number> {
    return new Promise(async (resolve, reject) => {
      if (!this.app.isPaidMember && this.app.cache.user.role !== UserRole.demo && this.pageList.length >= this.freeUserPageLimit) {
        await this.goPage(this.panelIndex);
        this.dialog.open(PaidReferralComponent, {
          width: this.app.isDesktopWidth() ? '440px' : '90%',
          height: this.app.isDesktopWidth() ? '600px' : '80%',
          data: {
            message: this.translate.instant('2')
          }
        });
        // dialog가 닫히는 시점에 무엇인가 할 작업이 없으면 subscribe필요 없음
        // dialogRef.afterClosed().subscribe(async (result) => {});
        resolve(index);
      } else {
        const loading = new Loading();
        const files: Blob[] = [];
        const newPage = new Page();
        const originalPanelIndex = index;
        const copyPageIndex = index + 1;
        let pageScrollerTopBackup;
        let pageScrollerLeftBackup;

        try {
          // 카피 이후에 다시 원위치로 스크롤을 이동시키기 위해 스크롤 값 저
          if (this.app.isDesktopWidth()) {
            let scrollerForDesktop = await this.pageScroller.getScrollElement();
            pageScrollerTopBackup = scrollerForDesktop.scrollTop;
            pageScrollerLeftBackup = scrollerForDesktop.scrollLeft;
          } else {
            let scrollerForMobile = this.pageScroller.nativeElement.parentElement;
            pageScrollerTopBackup = scrollerForMobile.scrollTop;
            pageScrollerLeftBackup = scrollerForMobile.scrollLeft;
          }

          this.app.shoutOut(`${originalPanelIndex} : original index`);

          await loading.showLoader('');
          newPage.userId = this.app.cache.user.id;
          newPage.canvasId = this.newCanvasId;
          newPage.order = copyPageIndex;

          // https://github.com/toonsquare/tooning-repo/issues/4098
          // copy 하기 전에 복사할 원본 페이지를 한번 저장한다.
          await this.updatePageSync(index);

          // 서버에 page 생성
          // (서버에 생성 후 ui 보여주면 너무느리다. 추후에 indexedDB 버전에서 개선 필요)
          const blobToFile = this.fileService.fabricToBlob(this.pageList[index].fabricObject);
          const baseFile = await this.fileService.b64toFile(this.capturePng(), 'base64');
          files.push(baseFile);
          files.push(blobToFile);
          this.pageService
            .createPage(newPage, files)
            .pipe(take(1))
            .subscribe(
              async ({ data }) => {
                const id = data.id;
                const json = data.json;
                //@ts-ignore
                this.pageList.splice(copyPageIndex, 0, {
                  id,
                  // @ts-ignore
                  img: this.pageList[originalPanelIndex].img,
                  order: copyPageIndex,
                  dirty: true,
                  fetched: false,
                  fabricObject: null,
                  ai: this.pageList[originalPanelIndex].ai,
                  json
                });

                setTimeout(async () => {
                  // ngx-sortablejs 11.1.0 으로 업그레이드 하고 아래 부분 주석처리
                  // this.pageList = originPanelList;
                  await this.pagesOrderUpdate();
                  await this.firstCanvasOrderPreviewUpdate();
                  await this.app.delay(200);
                  this.app.orange(`x:${pageScrollerLeftBackup}, y :${pageScrollerTopBackup}`);
                  // 카피된 이후 직전의 스크롤 위치에 추가된 항목의 크기를 더해 스크롤 이동
                  if (this.app.isDesktopWidth()) {
                    await this.pageScroller.scrollToPoint(pageScrollerLeftBackup + 124, pageScrollerTopBackup + 124);
                  } else {
                    this.app.delay(200).then(() => {
                      let scroller = this.pageScroller.nativeElement.parentElement;
                      scroller.scrollLeft = this.activePageIndex * 153;
                    });
                  }

                  if (pageGo) {
                    try {
                      await this.goPage(copyPageIndex);
                      this.pageList[copyPageIndex].dirty = true;
                      await this.updatePage(copyPageIndex);
                      resolve(id);
                    } catch (e) {
                      reject(e);
                    }
                  } else {
                    resolve(id);
                  }
                });
              },
              (error) => {
                loading.hideLoader();
                reject(new TooningCustomClientError(error.message, FILE_NAME, 'copyPage()', this.app));
              },
              () => {
                loading.hideLoader();
                this.app.green('createPage complete');
              }
            );
        } catch (e) {
          if (e instanceof TooningPageUpdateError || e instanceof TooningPageUpdateTimeOutError) {
            reject(e);
          } else {
            reject(new TooningCustomClientError(e.message, FILE_NAME, 'copyPage()', this.app));
          }
        } finally {
          loading.hideLoader();
        }
      }
    });
  }

  /**
   * 선택된 page를 삭제한다. 실제로는 서버에서 삭제는 하지 않고
   *  deletePage.deleteStatus = true 처리힌다
   * @param index 현재 page 의 Index
   * @param isKeepOnePage 페이지 없을 경우 새 페이지 생성 할것인지
   */
  async removePanel(index, isKeepOnePage = true) {
    const command = new PageRemoveCommand({
      cut: this,
      indexList: [index],
      callback: async (index) => {
        this.panelIsRemoving = true;
        const loading = new Loading();
        await loading.showLoader('');
        try {
          // @ts-ignore
          const currentId = this.pageList[index].id;
          this.pageList.splice(index, 1);
          this.loading.hideLoader();

          await this.pageService.deletePage(currentId).toPromise();

          let goIndex = 0;
          if (isKeepOnePage) {
            if (this.pageList.length === 0) {
              // 패널이 하나 있을경우, 신규 추가
              this.panelIndex = 0;
              await this.addPage();
            }

            await this.pagesOrderUpdate();
            await this.firstCanvasOrderPreviewUpdate();
            this.app.green('deletePage complete');
            // 삭제 후 바로 앞에 인덱스를 가져온다. 단 0이면 0
            if (index !== 0) {
              goIndex = index - 1;
            }
            this.panelIsRemoving = false;
          }
          return goIndex;
        } catch (e) {
          throw new TooningCustomClientError(e, FILE_NAME, 'removePanel()', this.app, true);
        } finally {
          this.panelIsRemoving = false;
          loading.hideLoader();
        }
      }
    });
    return await this.commandManager.executeCommand(command);
  }

  /**
   * 페이지 리스트에서 여러개 지우는 함수
   * @return {Promise<number>}
   */
  async removePanelList() {
    try {
      const pageIdList = [];
      for (let i = 0; i < this.pageList.length; i++) {
        if (this.pageList[i].isChecked) {
          pageIdList.push(this.pageList[i].id);
        }
      }
      const command = new PagesRemoveCommand({
        cut: this,
        pageIdList,
        userId: +this.app.cache.user.id,
        canvasId: +this.newCanvasId,
        restorePageIndex: this.panelIndex,
        callback: async (pageIdList) => {
          await this.pageService.pageMultiDelete(+this.app.cache.user.id, +this.newCanvasId, pageIdList).toPromise();

          // UI상 패널 삭제
          this.pageList = this.pageList.filter((item) => !pageIdList.includes(item.id));
          if (this.pageList.length === 0) {
            if (this.addPageIsWorking) {
              return;
            }
            this.addPageIsWorking = true;

            if (this.afterAIView) this.afterAIView = false;
            await this.addPage();
            this.addPageIsWorking = false;
            // 생성된 pageId를 담음
            this.addedPageId = this.pageList[this.panelIndex].id;
          }
          // 포커스 처리
          if (this.panelIndex !== 0) {
            this.panelIndex = this.pageList.findIndex((page) => !page.isChecked);
          }

          // 화면 갱신
          this.isPageListEditMode = false;
        }
      });
      await this.commandManager.executeCommand(command);

      return this.pageList.findIndex((page) => !pageIdList.includes(page.id));
    } catch (e) {
      throw new TooningRemovePagesError(e, null, true);
    }
  }

  // ionic app 에서 위의 함수가 작동하지 않아서 다시 작성 : URL 이미지 형태 전달  > 베이스64 리턴
  async imgToDataURL(url: string): Promise<string | ArrayBuffer> {
    return fetch(url, { cache: 'no-store' })
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            let reader = new FileReader();
            // Is this a "real" file? In other words, is this an instance of the original `File` class (not the one overriden by cordova-plugin-file).
            // If so, then we need to use the "real" FileReader (not the one overriden by cordova-plugin-file).
            const realFileReader = (reader as any)._realReader;
            if (realFileReader) {
              reader = realFileReader;
            }
            // @ts-ignore
            reader.onloadend = () => resolve(reader.result.replace('application/octet-stream', 'image/png'));
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  }

  /**
   *
   * @param {Array<number>} viewportTransform fabric.iMatrix = [1, 0, 0, 1, 0, 0];
   * @param {number} zoomToPointX  to zoom with respect to X
   * @param {number} zoomToPointY to zoom with respect to Y
   * @param {number} delta to set zoom to
   * @param {Event} [event] mouse or touch event
   * @return {void}
   */
  zoomCanvas(viewportTransform: Array<number>, zoomToPointX: number, zoomToPointY: number, delta: number, event?: Event): void {
    try {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (this.isColorPicker) {
        return;
      }
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) {
        console.log('더이상 안커짐!!');
        zoom = 20;
      }
      if (zoom < 0.01) {
        console.log('더이상 안작이짐');
        zoom = 0.01;
      }

      this.canvas.zoomToPoint({ x: zoomToPointX, y: zoomToPointY }, zoom);

      const vpt = viewportTransform;
      if (zoom < 400 / 1000) {
        vpt[4] = 200 - (1000 * zoom) / 2;
        vpt[5] = 200 - (1000 * zoom) / 2;
      } else {
        if (vpt[4] >= 0) {
          vpt[4] = 0;
        } else if (vpt[4] < this.canvas.getWidth() - 1000 * zoom) {
          vpt[4] = this.canvas.getWidth() - 1000 * zoom;
        }
        if (vpt[5] >= 0) {
          vpt[5] = 0;
        } else if (vpt[5] < this.canvas.getHeight() - 1000 * zoom) {
          vpt[5] = this.canvas.getHeight() - 1000 * zoom;
        }
      }
      this.canvas.requestRenderAll();
      this.setShowPreviewCanvas();
      console.log(this.canvas.getZoom());
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'zoomCanvas()', null, true);
    }
  }

  async onResizeW(): Promise<void> {
    try {
      this.zoomData = 0;
      await this.canvasResizeZoom();
      await this.app.delay(500);
      // 확인차 한번더 실행
      await this.canvasResizeZoom();

      if (!this.helpGuideComplete) {
        this.helpGuidePopup.x = this.canvas.getWidth() / 2 - (1080 * this.canvas.getZoom()) / 2 - 280;
        this.helpGuidePopup.y = this.canvasDomain.nativeElement.offsetHeight / 2 - 213;
      }
      return;
    } catch (e) {
      console.log(e);
    }
  }

  // 캐릭터 AI 적용
  async getIntents(): Promise<void> {
    const getList = await this.resMakerCharacterService.getAllIntent().toPromise();
    this.intentList = getList.data.Intent;
  }

  /**
   * 텍스트로 캐릭터 동작 연출
   * TTT 사용할 때 call 되는 코드
   * @param item
   * @param characterMake
   */
  async characterSelectedGo(item: any, characterMake) {
    const loading = new Loading();
    this.beforeDataLessJsonString = JSON.stringify(this.canvas.toDatalessJSON());
    await loading.showLoader('');
    // tslint:disable-next-line:prefer-const
    const object = item[0];
    try {
      const text = this.textForTtt;
      let allBodyItem;
      let allBodyis = false;
      const characterId = object.resource_id;
      const characterDirection = object.resource_direction[1];

      this.app.analyticsService.sentimentAI(text);
      const { emotion, depth } = await this.appL.getEmotion(text);
      const prediction = emotion + depth;
      this.app.greenLog(prediction);
      const matchedIntent = this.intentList.filter((intent) => intent.name_ko === prediction);

      const matchedIntentNum = matchedIntent.shift().id;

      const allBodyList = await this.resMakerCharacterService.getAllBody(characterId).toPromise();

      const allBodyItemArr = [];
      // @ts-ignore
      for (const body of allBodyList) {
        if (body.intent != null) {
          if (body.intent.id === matchedIntentNum) {
            allBodyis = true;
            console.log(body);
            allBodyItemArr.push(body);
          }
        }
      }
      allBodyItem = _.sample(allBodyItemArr);

      const promises = [];
      await characterMake.colorReset(object);

      // 표정 변경 전 기존 표정이 아무 표정 없을 때, 바뀐 표정이 반영이 되도록 변경
      if (object.resource_isVisible && !object.resource_isVisible?.face_expression) {
        object.resource_isVisible.face_expression = true;
      }
      // 표정 변경
      promises.push(characterMake.changeObItem(characterId, object, 'face_expression', characterDirection, +matchedIntentNum));

      if (allBodyis) {
        promises.push(characterMake.changeObItem(characterId, object, 'leg', characterDirection, allBodyItem.legOrder));
        promises.push(
          characterMake.changeObArm(characterId, object, 'left', characterDirection, allBodyItem.leftArmOrder, allBodyItem.leftHandOrder)
        );
        promises.push(
          characterMake.changeObArm(characterId, object, 'right', characterDirection, allBodyItem.rightArmOrder, allBodyItem.rightHandOrder)
        );
      }

      await Promise.all(promises);

      await characterMake.colorSetAdd(object);
      await characterMake.colorReMapping(object);

      await characterMake.setResourceRealAreaForAi(object, this.canvas);

      this.canvas.requestRenderAll();
    } catch (e) {
      console.error(e);
      this.app.analyticsService.error('text.component', e.message);

      switch (e.constructor) {
        case TooningGetEmotionTimeOutError:
          await this.app.showToast(this.translate.instant('networkStatus.timeOut'));
          break;
        case TooningGetEmotionError:
          await this.app.showToast('Error: Kobert GetEmotion');
          break;
        case TooningChangeObItemError:
        case TooningChangeObArmError:
        case TooningChangeObItemGetFabricHttpError:
        case TooningChangeObArmGetFabricHttpError:
        case TooningColorSetAddError:
        case TooningSetResourceRealAreaForAi:
          break;
        default:
          await this.app.showToast('Error: characterSelectedGo');
          throw new TooningCustomClientError(e, FILE_NAME, 'characterSelectedGo()', this.app, true);
      }
    } finally {
      this.afterDataLessJsonString = JSON.stringify(this.canvas.toDatalessJSON());
      const command = new JsonChangeCommand({
        cut: this,
        beforeDataLessJsonString: this.beforeDataLessJsonString,
        afterDataLessJsonString: this.afterDataLessJsonString
      });
      await this.commandManager.executeCommand(command);
      await this.updatePageSync(this.panelIndex);
      loading.hideLoader();
    }
  }

  /**
   * 글로 캐릭터 연출을 위한 캐릭터 선택 함수
   * @param item {Array}
   * @param characterMake {object}
   * @param textForTtt {string}
   * @return {void}
   */
  async characterSelected(item: any, characterMake, textForTtt?) {
    this.textForTtt = textForTtt;
    const object = item[0];
    if (object.clipPath === null || object.clipPath === undefined) {
      await this.characterSelectedGo(item, characterMake);
    } else {
      this.setKeyActivation = false;
      this.popAlert = await this.alertController.create({
        header: this.translate.instant('crop-page.title'),
        message: this.translate.instant('crop-page.message'),
        cssClass: 'basic-dialog',
        buttons: [
          {
            text: this.translate.instant('crop-page.btn1'),
            handler: () => {}
          },
          {
            text: this.translate.instant('crop-page.btn2'),
            handler: async () => {
              object.clipPath = null;
              await this.characterSelectedGo(item, characterMake);
            }
          }
        ]
      });
      this.popAlert.onDidDismiss().then(async (data) => {
        this.popAlert = null;
        this.setKeyActivation = true;
      });
      await this.popAlert.present();
    }
  }

  /**
   * 글로 캐릭터 연출 기능에서 캐릭터 모두 적용 함수
   * @param characterMake {object}
   * @param textForTtt {string}
   * @return {void}
   */
  async characterAllSelected(characterMake, textForTtt?) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0, j = this.charactersList.length; i < j; i++) {
      await this.characterSelected(this.charactersList[i], characterMake, textForTtt);
    }
  }

  /**
   * 캐릭터 정측 후 변경 함수
   * @param selectedDirection - 방향 설정
   * @param characterMake - CharacterMake 서비스
   * @param {CharacterSvgComponent | undefined} characterSvg - 장면 추천에서 사용하는 경우 undefined, 선택한 캐릭터를 변경하는 경우 CharacterSvgComponent
   * @param {boolean} enableObjectEvent - CharacterMake 서비스의 기능이 작동하는 동안 , objectEvent 와 canvas history 를 잠시 off 한 후 , 마지막 setResourceRealArea 에 다시 on 한다.
   * @param {boolean} showLoader - 로딩이 보일지 결정하는 boolean
   * @return {Promise<void>}
   */
  async changeAllCharacterGo(
    selectedDirection,
    characterMake,
    characterSvg: CharacterSvgComponent | undefined,
    enableObjectEvent = true,
    showLoader = true
  ): Promise<void> {
    const command = new ObjectChangeCommand({
      cut: this,
      target: this.selectedOb,
      callback: async () => {
        const loading = new Loading();
        try {
          const characterId = this.selectedOb.resource_id;
          const promises = [];
          if (showLoader) {
            await loading.showLoader('');
          }
          // 앞 옆 뒤 레이아웃설정
          console.log('레이아웃 변경');
          // 칼라 리셋
          await characterMake.colorReset(this.selectedOb);
          await characterMake.bodyLayoutSet(characterId, this.selectedOb, selectedDirection, false);

          await characterMake.changeObItem(characterId, this.selectedOb, 'body', selectedDirection, 0);
          const bridge = this.getChatacterFabricObjects(this.selectedOb, 'bridge');
          if (this.selectedOb.resource_isVisible?.leg) bridge.visible = this.selectedOb.resource_isVisible?.leg;
          const body = this.getChatacterFabricObjects(this.selectedOb, 'body');
          if (this.selectedOb.resource_isVisible?.body) body.visible = this.selectedOb.resource_isVisible?.body;

          const pushList = [
            'back_hair',
            'front_hair',
            'face_shape',
            'accessary',
            'accessary_head',
            'beard',
            'glasses',
            'face_effect',
            'wrinkle',
            'face_expression',
            'leg'
          ];
          for (let i = 0, j = pushList.length; i < j; i++) {
            const part = pushList[i];
            let isVisible = true;
            if (this.selectedOb.resource_isVisible?.[part] !== undefined) isVisible = this.selectedOb.resource_isVisible?.[part];

            promises.push(
              characterMake.changeObItem(characterId, this.selectedOb, part, selectedDirection, this.selectedOb.recList[part], null, isVisible)
            );
          }

          let isVisible = true;
          if (this.selectedOb.resource_isVisible?.left !== undefined) isVisible = this.selectedOb.resource_isVisible?.left;
          promises.push(
            characterMake.changeObArm(
              characterId,
              this.selectedOb,
              'left',
              selectedDirection,
              this.selectedOb.recList.left_arm,
              this.selectedOb.recList.left_hand,
              null,
              null,
              isVisible
            )
          );
          if (this.selectedOb.resource_isVisible?.right !== undefined) isVisible = this.selectedOb.resource_isVisible?.right;
          promises.push(
            characterMake.changeObArm(
              characterId,
              this.selectedOb,
              'right',
              selectedDirection,
              this.selectedOb.recList.right_arm,
              this.selectedOb.recList.right_hand,
              null,
              null,
              isVisible
            )
          );
          await Promise.all(promises);
          await characterMake.colorDefault(this.selectedOb);
          characterMake.colorSetAdd(this.selectedOb);
          characterMake.colorReMapping(this.selectedOb);
          if (characterSvg !== undefined) {
            characterSvg.colorReMapping();
          }
          await characterMake.setResourceRealArea(this.selectedOb, this.canvas, enableObjectEvent);
          this.allPathlist = characterMake.allPathObjectList(this.selectedOb, false);
          this.resourceDirection = this.selectedOb.resource_direction[0];
          this.canvas.fire('object:modified');
          this.canvas.requestRenderAll();
          return this.selectedOb;
        } catch (e) {
          console.error(`'캐릭터 정측후 변경 애러' : ${e.message}`);
          throw e;
        } finally {
          this.setWaterMarkResize(this.selectedOb, this.selectedOb.scaleX, this.selectedOb.scaleY);
          if (showLoader) {
            loading.hideLoader();
          }
          this.isChangeAllCharacterClicking = false;
          this.isChangeCharacterClicking = false;
        }
      }
    });
    await this.commandManager.executeCommand(command);
  }

  /**
   * 캐릭터 정측 후 변경 함수
   * @param selectedDirection - 방향 설정
   * @param characterMake - CharacterMake 서비스
   * @param {CharacterSvgComponent | undefined} characterSvg - 장면 추천에서 사용하는 경우 undefined, 선택한 캐릭터를 변경하는 경우 CharacterSvgComponent
   * @param {boolean} enableObjectEvent - CharacterMake 서비스의 기능이 작동하는 동안 , objectEvent 와 canvas history 를 잠시 off 한 후 , 마지막 setResourceRealArea 에 다시 on 한다.
   * @param {boolean} showLoader - 로딩이 보일지 결정하는 boolean
   * @param {boolean} replacer - 캐릭터들에 대하여 일괄 액션 변경을 해주기 위해 추가해주는 optional Boolean. updateCharacterActions 함수에서 쓰인다.
   * @returns {Promise<void>}
   */
  async changeAllCharacter(
    selectedDirection,
    characterMake,
    characterSvg: CharacterSvgComponent | undefined,
    enableObjectEvent = true,
    showLoader?: boolean,
    replacer?: boolean
  ): Promise<void> {
    try {
      if (this.isChangeAllCharacterClicking) {
        return;
      }
      this.isChangeAllCharacterClicking = true;
      if (this.selectedOb.clipPath === null || this.selectedOb.clipPath === undefined) {
        if (this.selectedOb.resource_direction[1] !== selectedDirection || replacer) {
          await this.changeAllCharacterGo(selectedDirection, characterMake, characterSvg, enableObjectEvent, showLoader);
        } else {
          this.isChangeAllCharacterClicking = false;
        }
      } else {
        this.setKeyActivation = false;
        this.popAlert = await this.alertController.create({
          header: this.translate.instant('crop-page.title'),
          message: this.translate.instant('crop-page.message'),
          cssClass: 'basic-dialog',
          buttons: [
            {
              text: this.translate.instant('crop-page.btn1'),
              handler: () => {}
            },
            {
              text: this.translate.instant('crop-page.btn2'),
              handler: async () => {
                this.selectedOb.clipPath = null;
                await this.changeAllCharacterGo(selectedDirection, characterMake, characterSvg, enableObjectEvent);
              }
            }
          ]
        });
        this.popAlert.onDidDismiss().then(async (data) => {
          this.popAlert = null;
          this.setKeyActivation = true;
          this.isChangeAllCharacterClicking = false;
        });
        await this.popAlert.present();
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'changeAllCharacter()', this.app);
    }
  }

  /**
   * 현재 캔버스의 리소스 전체 잠금 해제
   * @return {void}
   */
  allUnLock(): void {
    try {
      const beforeIsLockList = this.getLockList();
      for (const item of this.canvas.getObjects()) {
        if (item.resource_type !== ResourceType.guideMask && item.resource_type !== ResourceType.bgColor) {
          item.selectable = true;
          item.evented = true;
        }
      }

      this.app.delay(0).then(async (r) => {
        this.isLockIconChange();
        const afterIsLockList = this.getLockList();
        const command = new ObjectLockSetCommand({
          cut: this,
          beforeIsLockList: beforeIsLockList,
          afterIsLockList: afterIsLockList
        });
        await this.commandManager.executeCommand(command);
      });
      this.canvas.fire('object:modified');
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'allUnLock()');
    }
  }

  /**
   * 현재 캔버스의 리소스 전체 잠금
   * @return {void}
   */
  allLock(): void {
    try {
      const beforeIsLockList = this.getLockList();
      this.forceNoFocus();
      for (const item of this.canvas.getObjects()) {
        if (item.resource_type !== ResourceType.guideMask && item.resource_type !== ResourceType.bgColor) {
          item.selectable = false;
          item.evented = false;
        }
      }

      this.app.delay(0).then(async (r) => {
        this.isLockIconChange();
        const afterIsLockList = this.getLockList();
        const command = new ObjectLockSetCommand({
          cut: this,
          beforeIsLockList: beforeIsLockList,
          afterIsLockList: afterIsLockList
        });
        await this.commandManager.executeCommand(command);
      });
      this.canvas.fire('object:modified');
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'allLock()');
    }
  }

  /**
   * 현재 캔버스의 리소스 전체 잠금 상태인지 체크
   * @return {void}
   */
  isLockIconChange(): void {
    try {
      this.isAllLock.selectable = true;
      this.isAllLock.isSame = true;

      const isAllLockList = [];
      this.app.delay(0).then((r) => {
        const objects = this.canvas.getObjects();

        objects.forEach((object) => {
          if (object.resource_type !== ResourceType.guideMask && object.resource_type !== ResourceType.bgColor) {
            isAllLockList.push(object.selectable);
          }
        });
        this.isAllLock.isSame = isAllLockList.every((item) => item === isAllLockList[0]);
        if (this.isAllLock.isSame) {
          this.isAllLock.selectable = isAllLockList[0];
        } else {
          this.isAllLock.selectable = null;
        }

        return this.isAllLock;
      });
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'isLockIconChange()');
    }
  }

  async objectLock() {
    if (this.selectedOb === 'unselected') {
      return;
    }
    const beforeIsLockList = this.getLockList();
    const target = this.selectedOb;
    // 멀티선택
    if (target.get('type') === SelectedType.activeSelection) {
      for (const item of target.getObjects()) {
        item.selectable = false;
        item.evented = false;
      }
    } else {
      // 단일 선택
      target.selectable = false;
      target.evented = false;
    }

    this.forceNoFocus();
    this.canvas.fire('object:modified');
    const afterIsLockList = this.getLockList();
    const command = new ObjectLockSetCommand({
      cut: this,
      beforeIsLockList: beforeIsLockList,
      afterIsLockList: afterIsLockList
    });
    await this.commandManager.executeCommand(command);
  }

  /**
   * 컨트롤 버튼 위치 설정
   * @return {void}
   */
  setPositionBoxButton(): void {
    try {
      if (this.selectedOb === SelectedType.unselected || this.isColorPicker) {
        return;
      }
      const targetObjeect = this.selectedOb;

      const transformOrigin = `left top`;
      // 워터마크 그룹 참조하기
      const pointList = targetObjeect.getCoords(false, true);
      const angle = (this.selectedObAngle = this.selectedOb.angle);
      const textBoxButtonWidthSize = 90;
      let left;
      let top;
      const rad = (angle * Math.PI) / 180;
      let transX;
      let transY;
      let transform;
      this.isMoveButton = false;
      this.isMoveButtonX = 0;
      this.isMoveButtonY = 0;

      const limit = 50 / this.canvas.getZoom();
      // 너무 작아질 경우
      if (
        targetObjeect.getScaledWidth() < limit ||
        (targetObjeect.getScaledHeight() < limit && this.selectedOb.resource_type !== ResourceType.text)
      ) {
        this.isMoveButton = true;
        left = (pointList[2].x + pointList[3].x) / 2; // 중심
        top = (pointList[2].y + pointList[3].y) / 2;
        transX = -1 * Math.cos(rad) * textBoxButtonWidthSize;
        transY = -1 * Math.sin(rad) * textBoxButtonWidthSize;
        transform = `translateX(${transX}px) translateY(${transY}px) rotate(${angle}deg)`;
        if (this.moveButton === undefined) {
          return;
        }
        this.renderer.setStyle(this.moveButton.nativeElement, 'left', left + 'px');
        this.renderer.setStyle(this.moveButton.nativeElement, 'top', top + 'px');
        this.renderer.setStyle(this.moveButton.nativeElement, 'transform-origin', transformOrigin);
        this.renderer.setStyle(this.moveButton.nativeElement, 'transform', transform);
      }

      if (targetObjeect.resource_type === ResourceType.text) {
        left = (pointList[2].x + pointList[3].x) / 2; // 중심
        top = (pointList[2].y + pointList[3].y) / 2;
        transX = -1 * Math.cos(rad) * textBoxButtonWidthSize;
        transY = -1 * Math.sin(rad) * textBoxButtonWidthSize;
        transform = `translateX(${transX}px) translateY(${transY}px) rotate(${angle}deg)`;

        if (this.textBoxButton === undefined) {
          return;
        }
        this.renderer.setStyle(this.textBoxButton.nativeElement, 'left', left + 'px');
        this.renderer.setStyle(this.textBoxButton.nativeElement, 'top', top + 'px');
        this.renderer.setStyle(this.textBoxButton.nativeElement, 'transform-origin', transformOrigin);
        this.renderer.setStyle(this.textBoxButton.nativeElement, 'transform', transform);
      }

      if (targetObjeect.resource_type === ResourceType.characterSvg) {
        left = pointList[1].x;
        top = pointList[1].y;

        // 영역 밖으로 나갈경우
        if (this.getOuterArea() || this.isMoveButton) {
          this.isCharacterOut = true;
          transform = `rotate(${0}deg)`;
          if (this.characterButton === undefined) {
            return;
          }
          this.renderer.setStyle(this.characterButton.nativeElement, 'left', 0 + 'px');
          this.renderer.setStyle(this.characterButton.nativeElement, 'top', 0 + 'px');
          this.renderer.setStyle(this.characterButton.nativeElement, 'transform-origin', transformOrigin);
          this.renderer.setStyle(this.characterButton.nativeElement, 'transform', transform);
        } else {
          this.isCharacterOut = false;
          transform = `rotate(${angle}deg)`;
          if (this.characterButton === undefined) {
            return;
          }
          this.renderer.setStyle(this.characterButton.nativeElement, 'left', left + 'px');
          this.renderer.setStyle(this.characterButton.nativeElement, 'top', top + 'px');
          this.renderer.setStyle(this.characterButton.nativeElement, 'transform-origin', transformOrigin);
          this.renderer.setStyle(this.characterButton.nativeElement, 'transform', transform);
        }
      }

      if (targetObjeect.resource_type === ResourceType3d.Background3D) {
        left = pointList[0].x - 65;
        top = pointList[0].y;
        transform = `rotate(${angle}deg)`;

        if (this.background3DButton === undefined) {
          return;
        }
        this.renderer.setStyle(this.background3DButton.nativeElement, 'left', left + 'px');
        this.renderer.setStyle(this.background3DButton.nativeElement, 'top', top + 'px');
        this.renderer.setStyle(this.background3DButton.nativeElement, 'transform-origin', transformOrigin);
        this.renderer.setStyle(this.background3DButton.nativeElement, 'transform', transform);
      }
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'setPositionBoxButton()', this.app);
    }
  }

  isMoveButtonDragstart(event) {
    this.isMoveButtonDrag = true;

    if (event.type === 'dragstart') {
      // 마우스
      this.isMoveButtonStartX = event.clientX;
      this.isMoveButtonStartY = event.clientY;
    } else {
      // 터치
      this.isMoveButtonStartX = event.changedTouches[0].clientX;
      this.isMoveButtonStartY = event.changedTouches[0].clientY;
    }

    this.isMoveButtonStartObX = this.selectedOb.left;
    this.isMoveButtonStartObY = this.selectedOb.top;
    console.log('isMoveButtonDragstart');
  }

  async isMoveButtonDragover(event) {
    if (this.selectedOb === 'unselected') {
      return;
    }

    if (event.altKey === true && this.altKeyCopy === false) {
      try {
        await this.altKeyCopyFn(this.selectedOb);
      } catch (e) {
        throw new TooningCustomClientError(e, FILE_NAME, 'isMoveButtonDragover()', this.app);
      }
    }

    if (event.type === 'drag') {
      // 가헐적 팅기는 튀는 이슈 해결 코드
      if (event.clientX === 0 || event.clientY === 0) {
        return;
      }
      // 마우스
      this.isMoveButtonX = event.clientX - this.isMoveButtonStartX;
      this.isMoveButtonY = event.clientY - this.isMoveButtonStartY;
    } else {
      // 가헐적 팅기는 튀는 이슈 해결 코드
      if (event.changedTouches[0].clientX === 0 || event.changedTouches[0].clientY === 0) {
        return;
      }
      // 터치
      this.isMoveButtonX = event.changedTouches[0].clientX - this.isMoveButtonStartX;
      this.isMoveButtonY = event.changedTouches[0].clientY - this.isMoveButtonStartY;
    }
    this.selectedOb.left = this.isMoveButtonStartObX + this.isMoveButtonX / this.canvas.getZoom();
    this.selectedOb.top = this.isMoveButtonStartObY + this.isMoveButtonY / this.canvas.getZoom();

    this.canvas.requestRenderAll();
    this.renderer.setStyle(event.target, 'opacity', 0);
    console.log('isMoveButtonDragover');
  }

  isMoveButtonDragend(event) {
    if (this.selectedOb === 'unselected') {
      return;
    }
    if (event.type === 'dragend') {
      // 마우스
      this.isMoveButtonX = event.clientX - this.isMoveButtonStartX;
      this.isMoveButtonY = event.clientY - this.isMoveButtonStartY;
    } else {
      // 터치
      this.isMoveButtonX = event.changedTouches[0].clientX - this.isMoveButtonStartX;
      this.isMoveButtonY = event.changedTouches[0].clientY - this.isMoveButtonStartY;
    }

    this.renderer.setStyle(event.target, 'opacity', 1);
    this.selectedOb.setCoords();
    this.canvas.requestRenderAll();
    this.canvas.fire('object:modified');
    this.setPositionBoxButton();
    this.isMoveButtonDrag = false;
    console.log('isMoveButtonDragend');
  }

  isMoveButtonDragLeave(event) {
    console.log('isMoveButtonDragLeave');
  }

  enterEditing() {
    this.selectedOb.enterEditing();
    this.selectedOb.hiddenTextarea.focus();
    this.selectedOb.selectionStart = this.selectedOb.text.length;
    this.selectedOb.selectionEnd = this.selectedOb.text.length;
    this.selectedOb.setSelectionStart(this.selectedOb.text.length);
    this.selectedOb.setSelectionEnd(this.selectedOb.text.length);
  }

  // 실제 영역을 벗어 났는지 채크하는 함수
  getOuterArea() {
    let data = false;

    const outerArea = this.selectedOb.getCoords(true, true);

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0, j = outerArea.length; i < j; i++) {
      if (outerArea[i].x < (1080 - this.canvasSize.w) * 0.5) {
        data = true;
      }

      if (outerArea[i].x > (1080 - this.canvasSize.w) * 0.5 + this.canvasSize.w) {
        data = true;
      }

      if (outerArea[i].y < (1080 - this.canvasSize.h) * 0.5) {
        data = true;
      }

      if (outerArea[i].y > (1080 - this.canvasSize.h) * 0.5 + this.canvasSize.h) {
        data = true;
      }
    }
    return data;
  }

  // 이건 사용하지 않지 만 혹시 나중에 쓸수도 있어서 남
  rotationMatrix(degrees: number, x: number, y: number, offsetX: number, offsetY: number, distance: number = 1): object {
    console.log(`degrees : ${degrees}, x : ${x}, y : ${y}, offsetX : ${offsetX}, offsetY : ${offsetY}`);
    const rad = (degrees * Math.PI) / 180;
    x = x - offsetX;
    y = y - offsetY;
    const rotatedX = Math.cos(rad) * x - Math.sin(rad) * y + offsetX;
    const rotatedY = Math.sin(rad) * x + Math.cos(rad) * y + offsetY;

    return {
      degrees,
      rotatedX,
      rotatedY,
      offsetX,
      offsetY
    };
  }

  /**
   * 에디터에서 캐릭터를 클릭 할때 호출되는 함수 이며, 캐릭터를 캔버스에 추가한다.
   * 추가되는 과정은 다음과 같다.
   * 1. 캐릭터 정보 graphql query (캐쉬한다)
   * 2. 캐릭터 정보 json fetch (캐쉬한다)
   * 3. setResourceRealArea 를 통해 투영한 영역을 제외한 크기 정보 가져온다. (200ms ~ 300ms 정도 걸림)
   * 4. 3.번이 반영된 fabric object 를 loading 한다.
   *
   * 1,2번은 캐쉬되므로 최초에만 느리며,
   * 3번은 캐릭터 추가하는 경우만 realArea 값을 resource_id 기준으로 캐쉬한다.
   *
   * @param {object} item 캐릭터 정보를 담고 있는 object
   * @param {number} index 에디터 뷰에서 보이는 index
   * @param {CharacterMake} characterMake scharacterMake service instance
   * @return {Promise<TooningFabricObject>} setResourceRealArea 가 적용된 TooningFabricObject
   */
  async getOnCreateAddCharacter(item: any, index: number, characterMake: CharacterMake): Promise<TooningFabricObject> {
    return new Promise(async (resolve, reject) => {
      try {
        this.objectEventSetupOff();
        this.commandManager.offHistory();
        try {
          this.analyticsService.viewItem(item.__typename.toLowerCase(), item.name_ko);
        } catch (e) {
          logCustomErrorMessage(
            'getOnCreateAddCharacter',
            e.message,
            e.stack,
            FILE_NAME,
            'getOnCreateAddCharacter()',
            true,
            this.app.user.getUserEmail()
          );
        }

        this.resMakerCharacterService
          .getCharacter(item.id)
          .pipe(take(1))
          .subscribe(
            async ({ data }) => {
              let fabricObject: TooningFabricObject;
              if (data.character.defaultCharacters.length > 0) {
                console.log(`add_character fabricObject url  : ${data.character.defaultCharacters[0].fabricObject}`);
                this.getHttpUrl(data.character.defaultCharacters[0].fabricObject).subscribe(
                  async (result) => {
                    fabricObject = result;
                    // 선택 이벤트, 수정 이벤트가 다를 경우 모두 허용으로 변경
                    // 간헐적으로 fabric 선택 시 제대로 선택이 되지 않는 버그
                    if (fabricObject.evented !== fabricObject.selectable) {
                      fabricObject.evented = true;
                      fabricObject.selectable = true;
                    }
                    fabricObject.characterVersion = data.character.version;
                    this.commandManager.offHistory();

                    // 워터마크 처리
                    const isInsertWaterMark = this.app.isNotPurchased(item) && this.applyWaterMarkList.includes(fabricObject.resource_type);
                    const isGroup = fabricObject.type.toLowerCase() === SelectedType.smallGroup;
                    if (isGroup) {
                      const isFabricObj = fabricObject instanceof fabric.Object;
                      const getObjects = isFabricObj ? fabricObject.getObjects() : fabricObject.objects;
                      this.setGroupWaterMark(getObjects as TooningFabricObject[], fabricObject, this.existWaterMark(fabricObject), isInsertWaterMark);
                    }
                    if (this.guideMask === null) {
                      await this.guideMaskBringToFront();
                    }
                    this.setResourceScale(fabricObject);
                    const ob: TooningFabricObject = await this.addObFromJson([fabricObject], false, false, false);
                    const { name_ko, name_en, name_fr, name_jp } = data.character;
                    const name = { ko: name_ko, en: name_en, fr: name_fr, jp: name_jp };
                    this.fabricNameSet({ fabricOb: ob, name });
                    // 칼라 관련
                    // 신규 생성시에만 실행
                    try {
                      // DB 에서 칼라 셋 가져오기
                      // @ts-ignore
                      const colorSet = JSON.parse(data.character.colorSet);
                      // @ts-ignore
                      ob.colorSet = colorSet;
                      await characterMake.colorDefault(ob);
                      characterMake.colorSetAdd(ob);
                      characterMake.colorReMapping(ob);
                    } catch (e) {
                      throw new TooningCustomClientError(e, FILE_NAME, 'getOnCreateAddCharacter()', this.app);
                    }

                    await characterMake.setResourceRealArea(ob, this.canvas, true, false, 2, false, true);

                    ob.left === LEFT || (ob.left = LEFT);
                    ob.top === TOP || (ob.top = TOP);

                    this.canvas.add(ob);
                    this.objectEventSetup();
                    this.canvas.fire('object:modified');
                    this.commandManager.onHistory();
                    // @ts-ignore
                    // ob.set('resource_preview', item.imgPath + '/100x');
                    this.assignmentID(ob);
                    await this.app.showToast(this.translate.instant('added'), 1000);
                    resolve(ob);
                  },
                  async (e) => {
                    if (e instanceof HttpErrorResponse && e.status === HttpStatusCode.FORBIDDEN) {
                      await this.app.showToast(`CloudFront requests an character of S3 that doesn't exist... 관리자에게 문의해주세요`, 3000);
                      throw new TooningCustomClientError(e.message, FILE_NAME, 'getOnCreateAddCharacter()', this.app, true);
                    } else {
                      console.error(e);
                    }
                  },
                  async () => {
                    this.app.greenLog('fabricObject fetch complete');
                    this.characterLengthOnPage += 1;
                  }
                );
              } else {
                await this.app.showToast('캐릭터(디폴트) 정보가 존재하지 않습니다.');
                reject(`캐릭터(디폴트) 정보가 존재하지 않습니다`);
              }
            },
            (error) => {
              this.app.showToast(error.message);
              console.log(error);
              reject(error);
            },
            () => {
              this.app.greenLog('get character complete');
            }
          );
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * 캐릭터 캔버스에 추가
   * @param item - 캐릭터 페브릭 오브젝트
   * @param index - 뷰에서 보이는 순서의 인덱스
   * @param characterMake - CharacterMake 서비스
   */
  async onCreateAddCharacter(item: any, index: number, characterMake: CharacterMake) {
    let loading;
    loading = new Loading();
    await loading.showLoader();
    const isReject = await this.checkRejectCharacterAddingCanvas(item);
    try {
      if (isReject) {
        this.analyticsService.preparingCharacter(item.name_ko);
        return;
      }
      const command = new ObjectAddCommand({
        cut: this,
        callback: async () => {
          return await this.getOnCreateAddCharacter(item, index, characterMake);
        }
      });
      await this.commandManager.executeCommand(command);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'onCreateAddCharacter()', this.app, true);
    } finally {
      setTimeout(() => {
        loading.hideLoader();
      }, 100);
    }
  }

  /**
   * 말풍선, 요소, 효과, 배경 캔버스에 추가
   * @param {number} sourceId 소스 ID
   * @param {string} sourceType 타입
   * @param {CharacterMake} characterMake 서비스
   * @param {EtcUpload | boolean} etcUpload 타입
   * @returns {Promise<void>}
   */
  async getOnCreateAddEtc(sourceId: number, sourceType: string, characterMake: CharacterMake, etcUpload: EtcUpload | boolean = false): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const loading = new Loading();
      try {
        this.objectEventSetupOff();
        this.commandManager.offHistory();
        await loading.showLoader('');
        let data;
        if (!etcUpload) {
          data = await this.getSelectedFabric(sourceId, sourceType);
        } else {
          data = { etcUpload: [etcUpload] };
        }
        let etcName = data.etcUpload[0].etcUploadCategory;
        this.analyticsService.viewItem(sourceType, etcName.name_ko);
        const selectedFabric = data.etcUpload[0].fabricObject;
        const selectedBase64 = data.etcUpload[0].base64;
        const etcUploadVersion = data.etcUpload[0].etcUploadVersion.version; // 0 , 1 > 1번 부터 유료 버전 가능한 형태

        if (typeof selectedFabric === 'string') {
          try {
            const getObj = await this.getHttpUrl(selectedFabric).toPromise();
            getObj.resource_id = sourceId;
            getObj.etcUploadVersion = etcUploadVersion;
            let object = getObj;

            const isThereObjects = getObj.objects !== undefined || getObj._objects !== undefined;
            if (etcUploadVersion === Version.v2 && !isThereObjects) {
              // const cloneFabricObject = await this.cloneFabricObject(object);
              // @ts-ignore
              const group = new fabric.Group();

              group.originX = 'center';
              group.originY = 'center';
              group.width = getObj.width;
              group.height = getObj.height;
              group.left = LEFT;
              group.top = TOP;
              // @ts-ignore
              group.resource_id = sourceId;
              // @ts-ignore
              group.resource_type = object.resource_type;
              // @ts-ignore
              group.etcUploadVersion = etcUploadVersion;
              const toDatalessObjectGroup = group.toDatalessObject();
              toDatalessObjectGroup.objects.push(getObj);
              object = toDatalessObjectGroup;

              getObj.top = 0;
              getObj.left = 0;
              getObj.scaleX = 1;
              getObj.scaleY = 1;
            }

            // 워터마크 처리 가능 여부 체크
            const isInsertWaterMark = this.app.isNotPurchased(data.etcUpload[0]) && this.applyWaterMarkList.includes(object.resource_type);
            const isGroup = object.type.toLowerCase() === SelectedType.smallGroup;
            if (isGroup) {
              const isFabricObj = object instanceof fabric.Object;
              const getObjects = isFabricObj ? object.getObjects() : object.objects;
              this.setGroupWaterMark(getObjects, object, this.existWaterMark(object), isInsertWaterMark);
            }
            // 선택 이벤트, 수정 이벤트가 다를 경우 모두 허용으로 변경
            // 간헐적으로 fabric 선택 시 제대로 선택이 되지 않는 버그
            if (object.evented !== object.selectable) {
              object.evented = true;
              object.selectable = true;
            }

            const isBackground: boolean = sourceType === SourceType.background;
            const applyScale: number = isBackground ? this.FULL_SIZE : this.ALMOST_SIZE;
            if (this.guideMask === null) {
              await this.guideMaskBringToFront();
            }
            this.setResourceScale(object, applyScale, sourceType as SourceType);
            object = await this.applyAddFromJson(sourceType, object, characterMake);
            const etcUploadCategory = data.etcUpload[0].etcUploadCategory;
            const { name_ko, name_en, name_fr, name_jp } = etcUploadCategory;
            const name = {
              ko: name_ko,
              en: name_en,
              fr: name_fr,
              jp: name_jp
            };
            this.fabricNameSet({ fabricOb: object, name });
            this.setStrokeUniform(object, !isBackground);
            await characterMake.setResourceRealArea(object, this.canvas, true, false, 2, false, true);
            loading.hideLoader();

            setTimeout(async () => {
              try {
                object.set('resource_preview', selectedBase64 + '/100x');
              } catch (e) {}
            }, 500);
            this.objectEventSetup();
            this.canvas.fire('object:modified');
            this.commandManager.onHistory();

            this.assignmentID(object);
            resolve(object);
          } catch (e) {
            loading.hideLoader();
            console.error(e);
            reject(e);
          }
        }
      } catch (e) {
        console.error(e);
        reject(e);
      } finally {
        loading.hideLoader();
      }
    });
  }

  /**
   * 말풍선, 요소, 효과, 배경 캔버스에 추가 전 커맨드
   * @param {number} sourceId 소스ID
   * @param {string} sourceType 타입
   * @param {CharacterMake} characterMake 서비스
   * @param {EtcUpload | boolean} etcUpload 타입
   * @return {Promise<void>}
   */
  public async onCreateAddEtc(
    sourceId: number,
    sourceType: string,
    characterMake: CharacterMake,
    etcUpload: EtcUpload | boolean = false
  ): Promise<void> {
    const command = new ObjectAddCommand({
      cut: this,
      callback: async () => {
        return await this.getOnCreateAddEtc(sourceId, sourceType, characterMake, etcUpload);
      }
    });
    await this.commandManager.executeCommand(command);
  }

  // 클릭한 fabric data 가져오기
  public async getSelectedFabric(sourceId: number, sourceType: string) {
    try {
      const { data } = await this.etcUploadService.getEtcUpload(+sourceId, sourceType, this.app.cache.user.id).toPromise();

      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 리소스 json을 캔버스에 추가하기
   * @param {string} sourceType
   * @param {string} getStringFabric
   * @param {CharacterMake} characterMake
   * @return {Promise<any>}
   */
  async applyAddFromJson(sourceType: string, getStringFabric: string, characterMake: CharacterMake): Promise<any> {
    try {
      const indexInfo: IndexInfo = this.getIndexInfo(sourceType);
      indexInfo.index = this.isBackgroundColorMode && indexInfo.index === 0 ? indexInfo.index + 1 : indexInfo.index;
      const object: TooningFabricObject = await this.addObFromJson(
        [getStringFabric],
        false,
        true,
        true,
        characterMake,
        indexInfo.index,
        indexInfo.top,
        indexInfo.left
      );

      // 효과 소품 타입중에 배경으로 오는 타입을 수정하는 코드
      if (sourceType === SourceType.effect || sourceType === SourceType.item) {
        if (object.resource_type === ResourceType.background) object.resource_type = ResourceType.item;
        if (object.resource_type === ResourceType.backgroundSvg) object.resource_type = ResourceType.itemSvg;
      }

      await this.app.showToast(this.translate.instant('added'), 1000);
      return object;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'applyAddFromJson()', this.app, true);
    }
  }

  async setFocus() {
    await this.app.delay(10);
    const queryId = !this.app.isDesktopWidth() ? 'searchBarMobile' : 'searchBar';
    // @ts-ignore
    const searchbar = document.querySelector(`#${queryId}`) as HTMLIonSearchbarElement;
    await searchbar.setFocus();
  }

  // 텍스트 템플릿 붙이기
  async addText(fabricKey: string) {
    this.objectEventSetupOff();
    this.commandManager.offHistory();
    let index = 1;
    const getStringFabric = await this.getHttpUrl(fabricKey).toPromise();
    const objects = _.cloneDeep(getStringFabric.objects);
    // 그룹으로 묶어 놓은 경우 한 depth 풀어서 넘겨주기
    getStringFabric.objects.forEach((object) => {
      if (object.type === 'group' && object.resource_name === '그룹화된 툰요소') {
        this.checkSelectable(object.objects);
        objects.push(...object.objects);
      } else {
        if (object.selectable !== object.evented) {
          object.selectable = true;
          object.evented = true;
        }
        if (object.resource_type === 'text') {
          object.isEditableOnSamsungKeyboard = false;
        }
      }
    });

    // fontFamily 추출
    const fontFamilies = this.getFontFamily(objects);
    // pre font loading
    if (fontFamilies.length > 0) {
      WebFont.load({
        timeout: this.fontLoadTimeOut,
        custom: {
          families: fontFamilies
        },
        loading: () => {
          this.app.orange('font init');
        },
        fontloading: (familyName: string, fvd: string) => {
          this.app.orange(`fontloading : ${familyName}`);
        },
        fontactive: async (familyName: string, fvd: string) => {
          this.app.greenLog(`fontactive : ${familyName}`);
          index === fontFamilies.length ? await this.textTempApplyJson(getStringFabric) : ++index;
          this.objectEventSetup();
          this.canvas.fire('object:modified');
          this.commandManager.onHistory();
        },
        fontinactive: (familyName: string, fvd: string) => {
          this.app.redLog(`font inactive : ${familyName} : ${fvd}`);
          const messages = [];
          messages.push(`fontFamilyName : ${familyName}`);
          messages.push(`fvd : ${fvd}`);
          logCustomErrorMessage('addText', messages.join('\n'), '', FILE_NAME, 'addText()', true, this.app.user.getUserEmail());
        }
      });
    } else {
      await this.textTempApplyJson(getStringFabric);
      this.objectEventSetup();
      this.canvas.fire('object:modified');
      this.commandManager.onHistory();
    }
  }

  /**
   * textBox 일 경우 텍스트 겹침 현상 방어, 윤곽선 초기화.
   * 그룹 리소스일 경우 재귀
   * @param object 체크 할 오브젝트
   */
  checkText(object, isFirstEnter = false, topScale = 1) {
    if (object.resource_type === SelectedType.largeGroup || object.resource_type === SelectedType.smallGroup) {
      // 그룹일 경우 재귀
      object.getObjects().map((obj) => {
        this.checkText(obj, isFirstEnter, topScale * object.scaleX);
      });
    } else if (object.resource_type === ResourceType.text) {
      try {
        if (isFirstEnter) {
          // 최초 진입시 텍스트 스타일 정리
          const changes = _.cloneDeep(object.styles);
          object.styles = {};
          let lineIndex = 0;
          let charIndex = 0;

          //다른 에디터(ex. 윈도우)에서 텍스트 복사해와서 개행문자가 두개(\r,\n)가 들어가는 케이스 방어 코드
          object.text = object.text.replaceAll('\r', '');
          for (let i = 0; i < object.text.length; i++) {
            if (changes?.[lineIndex]?.[charIndex]) {
              object.setSelectionStyles(changes[lineIndex][charIndex], i, i + 1);
            }
            if (object.text[i] === '\n') {
              lineIndex++;
              charIndex = 0;
            } else {
              charIndex++;
            }
          }

          // 최초 진입시 텍스트 겹침 현상 방어코드
          for (let i = 0; i < object.textLines.length; i++) {
            object._measureLine(i);
          }
        }

        this.initTextStrokeStyle(object, topScale);
      } catch (e) {
        throw new TooningCustomClientError(e, FILE_NAME, 'checkText()', this.app);
      }
    }
  }

  /**
   *  텍스트 템플릿 제이슨 붙이는 함수
   * @param getStringFabric 오비젝트 배열
   * @return {Promise<any>}
   */
  async textTempApplyJson(getStringFabric): Promise<any> {
    let differenceTop;
    let differenceLeft;
    let isFirst = true;

    // 텍스트를 추가하고 있는 중에 다른 텍스트를 추가하려고 하는 경우 무시
    if (this.isAddTextClick) {
      return;
    }
    return new Promise(async (resolve, reject) => {
      try {
        this.isAddTextClick = true;
        for (const getFabric of getStringFabric.objects) {
          if (isFirst) {
            isFirst = false;
            differenceTop = getFabric.top;
            differenceLeft = getFabric.left;
            getFabric.top = TOP;
            getFabric.left = LEFT;
          } else {
            getFabric.top = TOP + (getFabric.top - differenceTop);
            getFabric.left = LEFT + (getFabric.left - differenceLeft);
          }

          if (getFabric.resource_type !== ResourceType.bgColor) {
            const command = new ObjectAddCommand({
              cut: this,
              callback: async () => {
                return await this.addObFromJson([getFabric], false, true, true, null, null, null, null);
              }
            });
            await this.commandManager.executeCommand(command);
          }
        }

        resolve(true);
      } catch (e) {
        reject(e);
      } finally {
        this.isAddTextClick = false;
      }
    });
  }

  // 텍스트 컬러 주의 사항
  // 문제 상황 : 텍스트 템플릿 캔버스에 그린 후 텍스트를 모두 지운 후 다시 입력하면 원래 있던 디자인이 깨진다.
  // 원인 : 텍스트 history 관리 잘못
  // Text는 textHistory 속성 값으로 모든 텍스트 지워졌을 경우 history값을 통해 텍스트 디자인을 이어 나감.
  // 작업자가 일부 텍스트 디자인 변경하면 이 부분이 history에 저장 됨. 변경 후 지우고 남은 text를 템플릿으로 하면
  // 이 부분은 history에 없어 깨지는 것처럼 보임.

  checkSelectable(
    objects:
      | Array<{ selectable: boolean; evented: boolean; resource_type: string }>
      | {
          selectable: boolean;
          evented: boolean;
        }
  ) {
    try {
      // 선택 이벤트, 수정 이벤트가 다를 경우 모두 허용으로 변경
      // 간헐적으로 fabric 선택 시 제대로 선택이 되지 않는 버그
      if (Array.isArray(objects)) {
        objects.forEach((object) => {
          if (object.selectable !== object.evented && object.resource_type !== ResourceType.bgColor) {
            object.selectable = true;
            object.evented = true;
          }
        });
      } else if (objects.selectable !== objects.evented) {
        objects.selectable = true;
        objects.evented = true;
      }
    } catch (e) {
      this.app.showToast(this.translate.instant('check select status')).then();
      throw new TooningCustomClientError(e, FILE_NAME, 'checkSelectable()', this.app);
    }
  }

  // canvas 속성에 color history init
  async initCanvasColorHistory() {
    try {
      if (this.canvas) {
        this.canvas.historyColores = [...this.historyColores];
      } else {
        throw new Error('존재하지 않는 canvas');
      }
    } catch (e) {
      await this.app.showToast(this.translate.instant('history bug'));
      throw new TooningCustomClientError(e, FILE_NAME, 'initCanvasColorHistory()', this.app);
    }
  }

  // 이전 color와 중복 color 제거
  duplicateDeleteColor() {
    try {
      if (this.historyColores[0] && this.historyColores[1] && this.historyColores[0] === this.historyColores[1]) {
        this.historyColores.shift();
      }
    } catch (e) {
      this.app.showToast(this.translate.instant('history bug'));
      throw new TooningCustomClientError(e, FILE_NAME, 'duplicateDeleteColor()', this.app);
    }
  }

  // fill color array 앞에 넣기
  unshiftColor(color: string) {
    try {
      // max 길이일 경우 마지막 color 제거
      if (this.historyColores.length === this.maxColorLength) {
        this.historyColores.pop();
      }

      this.historyColores.unshift(color);
    } catch (e) {
      this.app.showToast(this.translate.instant('history bug'));
      throw new TooningCustomClientError(e, FILE_NAME, 'unshiftColor()', this.app);
    }
  }

  // 초기 진입 시 canvas에 있는 history를 color list에 할당
  initHistoryColor() {
    try {
      if (this.canvas) {
        if (this.canvas.historyColores) {
          this.historyColores = [...this.canvas.historyColores];
        } else {
          this.canvas.historyColores = [];
        }
      } else {
        throw new Error('canvas가 존재하지 않습니다.');
      }
    } catch (e) {
      this.app.showToast(this.translate.instant('history bug'));
      throw new TooningCustomClientError(e, FILE_NAME, 'initHistoryColor()', this.app);
    }
  }

  // 멀티 셀렉션 모드
  async multipleSelectionModeCreate() {
    console.log('multipleSelectionModeCreate');
    this.multipleSelectionMode = true;
    this.multipleSelectionList.push(this.getIndex());
    console.log(this.multipleSelectionList);
    console.log(this.getIndex());
    await this.app.showToast(this.translate.instant('multipleSelectionMode2'));
  }

  multipleSelectionModeAdd() {
    const index = this.getIndex();
    if (index > -1) {
      console.log('multipleSelectionModeAdd');

      let addActive = true;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0, j = this.multipleSelectionList.length; i < j; i++) {
        if (this.multipleSelectionList[i] === index) {
          addActive = false;
        }
      }
      if (addActive) {
        this.multipleSelectionList.push(index);
      }
      const activeSelection = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0, j = this.multipleSelectionList.length; i < j; i++) {
        activeSelection.push(this.canvas.getObjects()[this.multipleSelectionList[i]]);
      }
      this.canvas.discardActiveObject();
      this.canvas.requestRenderAll();
      const selectedObjects = new fabric.ActiveSelection(activeSelection, {
        canvas: this.canvas
      });
      this.canvas.setActiveObject(selectedObjects);
      this.canvas.requestRenderAll();
      const text = this.translate.instant('multipleSelectionMode3') + activeSelection.length + this.translate.instant('multipleSelectionMode4');
      this.app.showToast(text).then();
      console.log(this.multipleSelectionList);
    }
  }

  multipleSelectionModeCancel(stillFocus: boolean = false): void {
    console.log('multipleSelectionModeCancel');
    this.multipleSelectionMode = false;
    this.multipleSelectionList = [];
    console.log(this.multipleSelectionList);

    if (!stillFocus) {
      this.forceNoFocus();
    }

    this.isMultiSelectionText = false;
  }

  async changeMyColorBoxState() {
    try {
      if (this.myColorList.length === 0) {
        return;
      }
      this.isMyColorBoxOpen = !this.isMyColorBoxOpen;

      if (this.isMyColorBoxOpen) {
        // open
        // 애니메이션을 위함
        await this.app.delay(40);

        // 순서 변경을 반영하기 위함
        const prevColorList = this.myColorList.slice(8, this.myColorList.length); // 순서 변경된 viewColor길이 만큼 자르기
        this.viewColor = [...this.viewColor, ...prevColorList];
      } else {
        // close
        // 애니메이션을 위함
        await this.app.delay(40);
        this.myColorList = [...this.viewColor]; // 순서 변경된 리스트 mycolor 리스트에 반영
        this.viewColor = [...this.viewColor.slice(0, 8)];
      }
    } catch (e) {
      await this.app.showToast(this.translate.instant('color box'));
      throw new TooningCustomClientError(e, FILE_NAME, 'changeMyColorBoxState()', this.app);
    }
  }

  /**
   * 페이지에 그리드 이미지 설정
   * 실제 10픽셀 단위 스냅 기능은 오브젝트 무브 이벤트에 걸려있음
   * @return {Promise<void>}
   */
  async setGrid(): Promise<void> {
    await this.app.delay(100);
    try {
      if (this.laboratory.grid && this.canvas.overlayImage === null) {
        this.canvas.setOverlayImage('/assets/4cut-make-manual/line_guide.svg', this.canvas.renderAll.bind(this.canvas), {
          left: LEFT,
          top: TOP
        });
        this.canvas.requestRenderAll();
      } else if (!this.laboratory.grid && this.canvas.overlayImage !== null) {
        this.canvas.overlayImage = null;
        this.canvas.requestRenderAll();
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'setGrid()', null, true);
    }
  }

  /**
   * 현재 캔버스의 실험실 기능들의 상태를 DB에 저장
   * @return {void}
   */
  saveLaboratoryStatus(): void {
    this.app.delay(100).then(async () => {
      const newCanvas = new Canvas();
      newCanvas.laboratory = JSON.stringify(this.laboratory);
      newCanvas.id = this.newCanvasId;
      newCanvas.outsideBGColor = this.outsideBG.color;
      await this.canvasService.canvasUpdate(newCanvas).toPromise();
    });
  }

  async getMyColorList() {
    try {
      const { data } = await this.myColorService.getMyColor(this.app.cache.user.id).toPromise();
      this.myColorList = data;
    } catch (e) {
      console.error(e);
      await this.colorErrorHandler(e);
    }
  }

  async addMyColorList(selectedColor: string) {
    try {
      const { data } = await this.myColorService.addMyColor(this.app.cache.user.id, selectedColor);
      this.myColorList.unshift(data);

      if (this.myColorList.length > 8) {
        this.isMyColorBoxOpen = true;
      }
      this.viewColor = [...this.myColorList];
      this.myColorHistory = [...this.myColorList];
    } catch (e) {
      await this.app.showToast(this.translate.instant('add myColor'));
      throw new TooningCustomClientError(e, FILE_NAME, 'addMyColorList()', this.app);
    }
  }

  /**
   * 내 색상에서 삭제할 컬러 선택
   * @param id 컬러 id
   * @return Promise<void>
   */
  async editColor(id: number): Promise<void> {
    try {
      const checkedIndex = this.checkedIndex(id);
      this.selectedColorList.length === 0 || checkedIndex === -1 ? this.selectedColorList.push(id) : this.selectedColorList.splice(checkedIndex, 1);
      this.allSelectComment =
        this.selectedColorList.length !== 0 && this.selectedColorList.length === this.viewColor.length
          ? this.translate.instant('FULL_RELEASE')
          : this.translate.instant('SELECT_ALL');
    } catch (e) {
      await this.app.showToast(this.translate.instant(this.translate.instant('editColor error')));
      throw new TooningCustomClientError(e, FILE_NAME, 'editColor()', this.app);
    }
  }

  // 오른쪽 아래 선
  whichLine(index: number): string {
    try {
      const oneLineCnt = this.setOneLineCnt();
      const { bottomRight, bottom, noLine, right } = this.setLineImg();

      const lastIndex = this.viewColor.length - 1;

      // 길이 1인 경우 noLine만 true
      if (this.viewColor.length === 1) {
        return noLine;
      }

      // 8번쨰인 경우 bottom만 true
      if (index % oneLineCnt === oneLineCnt - 1 && lastIndex !== index) {
        return bottom;
      }

      // 마지막 인덱스 noLine만 true
      if (lastIndex === index) {
        return noLine;
      }

      // 마지막줄인 경우 right만 true
      const lastLineFirstElement = Math.floor(lastIndex / oneLineCnt) * oneLineCnt;
      const lastLineScope = lastLineFirstElement + (lastIndex % oneLineCnt);
      if (lastLineFirstElement <= index && index <= lastLineFirstElement + lastLineScope) {
        return right;
      }

      // 이외에는 bottom right
      return bottomRight;
    } catch (e) {
      this.app.showToast(this.translate.instant('line box'));
      throw new TooningCustomClientError(e, FILE_NAME, 'whichLine()', this.app);
    }
  }

  // 모바일, 웹 이미지 Setting
  setLineImg(): { bottomRight: string; bottom: string; noLine: string; right: string } {
    const lineImg = {
      bottomRight: '',
      bottom: '',
      noLine: '',
      right: ''
    };

    if (this.app.isDesktopWidth()) {
      lineImg.bottomRight = './assets/4cut-make-manual/bottomRight.jpg';
      lineImg.bottom = './assets/4cut-make-manual/bottom.jpg';
      lineImg.noLine = './assets/4cut-make-manual/noLine.jpg';
      lineImg.right = './assets/4cut-make-manual/right.jpg';
    } else {
      lineImg.bottomRight = './assets/4cut-make-manual/bottomRight_mobile.jpg';
      lineImg.bottom = './assets/4cut-make-manual/bottom_mobile.jpg';
      lineImg.noLine = './assets/4cut-make-manual/noLine_mobile.jpg';
      lineImg.right = './assets/4cut-make-manual/right_mobile.jpg';
    }

    return lineImg;
  }

  // 한줄에 몇개 있는지 갯수 체크
  setOneLineCnt(): number {
    let cnt = 0;

    switch (this.platform.width()) {
      case this.iPhone6PlusWidth:
        cnt = 8;
        break;
      case this.iPhone6Width:
        cnt = 7;
        break;
      case this.iPone5Width:
        cnt = 6;
        break;
      default:
        cnt = 8;
        break;
    }

    return cnt;
  }

  checkedIndex(id: number): number {
    try {
      let result = -1;
      const findIndex = this.selectedColorList.findIndex((selectedId) => selectedId === id);
      if (findIndex > -1) {
        result = findIndex;
      }
      return result;
    } catch (e) {
      this.app.showToast(this.translate.instant('check index'));
      throw new TooningCustomClientError(e, FILE_NAME, 'checkedIndex()', this.app);
    }
  }

  editCancel() {
    this.selectedColorList = [];
    this.isMyColorEditMode = false;
    this.myColorList = [...this.myColorHistory];
    this.allSelectComment = this.translate.instant('SELECT_ALL');
    if (this.app.isDesktopWidth()) {
      this.viewColor = this.isMyColorBoxOpen ? [...this.myColorHistory] : this.myColorHistory.slice(0, 8);
    } else {
      this.viewColor = [...this.myColorHistory];
    }
  }

  async orderUpdate() {
    try {
      const idList = this.viewColor.map((color) => {
        return color.id;
      });

      // close 상태에서는 나머지 컬러리스트 가져와서 idlist push
      if (this.app.isDesktopWidth() && !this.isMyColorBoxOpen) {
        for (let i = this.viewColor.length; i < this.myColorList.length; i++) {
          idList.push(this.myColorList[i].id);
        }
      }

      this.setHistory();
      await this.myColorService.updateOrderMyColor(idList, this.app.cache.user.id);
      this.isMyColorEditMode = false;
    } catch (e) {
      console.error(e);
      this.colorErrorHandler(e);
    }
  }

  async deleteMyColor(isDesktop: boolean) {
    try {
      try {
        if (isDesktop) {
          if (this.selectedColorList.length === 0) {
            await this.app.showToast(this.translate.instant('select delete color'));
            return;
          }
          this.popAlert = await this.alertController.create({
            header: this.translate.instant('delete color'),
            message: this.translate.instant('IRREVERSIBLE_COMMENT'),
            cssClass: 'basic-dialog',
            buttons: [
              {
                text: this.translate.instant('CANCEL'),
                role: 'cancel'
              },
              {
                text: this.translate.instant('confirm'),
                handler: async () => {
                  try {
                    await this.deleteColor();
                  } catch (e) {
                    throw new Error(e);
                  }
                }
              }
            ]
          });
          this.popAlert.onDidDismiss().then(async (data) => {
            this.popAlert = null;
          });
          await this.popAlert.present();
        } else {
          await this.deleteColor();
        }
      } catch (e) {
        throw new Error(e);
      }
    } catch (e) {
      console.error(e);
      this.colorErrorHandler(e);
    }
  }

  async deleteColor() {
    try {
      const { data } = await this.myColorService.deleteMyColor(this.selectedColorList, this.app.cache.user.id);
      this.myColorList = data;

      if (this.app.isDesktopWidth()) {
        this.viewColor = this.isMyColorBoxOpen ? this.myColorList : this.myColorList.slice(0, 8);
      } else {
        this.viewColor = [...this.myColorList];
      }

      this.setHistory();
    } catch (e) {
      throw new Error(`deleteColor : ${e.message}`);
    }
  }

  setHistory() {
    try {
      if (this.app.isDesktopWidth()) {
        // open일 경우 viewcolor 그대로 할당
        // close일 경우 viewColor + myColorList.slice
        const restColorList = this.myColorList.slice(8, this.myColorList.length);
        this.myColorHistory = this.isMyColorBoxOpen ? [...this.viewColor] : [...this.viewColor, ...restColorList];
      } else {
        this.myColorHistory = [...this.viewColor];
      }
    } catch (e) {
      console.error(e);
      throw new Error(e.message);
    }
  }

  fabricNameSet(nameInfo: SetLanguageInterface) {
    try {
      const { fabricOb, name } = nameInfo;
      const { ko, en, fr, jp } = name;

      fabricOb.resource_name = ko;
      fabricOb.resource_name_en = en;
      fabricOb.resource_name_fr = fr;
      fabricOb.resource_name_jp = jp;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'fabricNameSet()', this.app);
    }
  }

  async colorErrorHandler(error) {
    const { graphQLErrors, networkError } = error;
    if (graphQLErrors) {
      for (const gError of graphQLErrors) {
        const errorCode = +gError.extensions.exception.code;
        switch (errorCode) {
          case TooningErrorCode.TOONIN_SERVER_GET_MYCOLOR_ERR:
            await this.app.showToast(this.translate.instant('get my color'));
            break;
          case TooningErrorCode.TOONIN_SERVER_ADD_MYCOLOR_ERR:
            await this.app.showToast(this.translate.instant('add my color'));
            break;
          case TooningErrorCode.TOONIN_SERVER_UPDATE_ORDER_MYCOLOR_ERR:
            await this.app.showToast(this.translate.instant('order update my color'));
            break;
          case TooningErrorCode.TOONIN_SERVER_DELETE_MYCOLOR_ERR:
            await this.app.showToast(this.translate.instant('delete my color'));
            break;
          default:
            await this.app.showToast(this.translate.instant('my color server err'));
            break;
        }
      }
    }

    await this.app.checkNetworkError(error, networkError);
  }

  /**
   * 최소 단위의 워터마크 이미지를 반복해서 만튼 패턴으로 채워진 워터마크 사각형 생성
   * https://github.com/toonsquare/tooning-repo/issues/3434 문제 때문에 crossOrigin 추가 해야 한다
   * @return {void}
   */
  async watermarkImgLoad(): Promise<void> {
    try {
      const defaultRect = new fabric.Rect({ width: 500, height: 500 });
      fabric.util.loadImage(
        '/assets/4cut-make-manual/crop_watermark.png',
        (img) => {
          const pattern = new fabric.Pattern({ source: img, repeat: 'repeat' });
          defaultRect.set('fill', pattern);
          this.waterMarkDummy = defaultRect;
        },
        { crossOrigin: 'anonymous' }
      );
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'watermarkImgLoad()');
    }
  }

  /**
   * disable-zoom class 만 찾아서 pinch zoom 을 막는다
   * @param {ElementRef} elementRef dom elements
   * @return {void}
   */
  disableZoom(elementRef: ElementRef): void {
    try {
      // https://jasonbla.tistory.com/13
      elementRef.nativeElement.querySelectorAll('.disable-zoom').forEach((selector) => {
        selector.addEventListener(
          'touchstart',
          (e) => {
            if (e.touches.length > 1) {
              e.preventDefault();
            }
          },
          { passive: false }
        );
      });
      // }
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   *  리소스가 투닝이 정해 놓은 상품 리스트에 있는지 확인 한다.
   * @param {ResourceType} skuType 리소스 타입
   * @param {number} sourceId 리소스 아이디
   * @return {boolean} true : 해당 리소스는 투닝이 정해 놓은 상품인 경우
   */
  isIncludeSku(skuType: ResourceType, sourceId: number): boolean {
    try {
      // isPaidMember -> author type tooing open + 포인트 구매한 아이템
      // 무료 -> 포인트 구매한 아이템만
      if (skuType === ResourceType.characterSvg) {
        const findNotPurchaseCharacter = this.notPurchasedCharacterListInSku.find((sku) => +sku.character.id === +sourceId);

        // 해당 품목이 구매하지 않은 목록에 존재? && 구독자? => type이 outside 이면 워터마크
        if (findNotPurchaseCharacter && this.app.isPaidMember) {
          return findNotPurchaseCharacter.character.authorType === AuthorType.outside;
        } else {
          return !!findNotPurchaseCharacter;
        }
      } else if (this.isEtcWaterMarkType(skuType)) {
        const findNotPurchaseEtcUpload = this.notPurchasedEtcUploadListInSku.find((sku) => {
          if (sku.skuType !== SkuType.ai) {
            return +sku.etcUpload.sourceId === +sourceId;
          }
        });
        // 해당 품목이 구매하지 않은 목록에 존재? && 구독자? => type이 outside이면 워터마크
        if (findNotPurchaseEtcUpload && this.app.isPaidMember) {
          return findNotPurchaseEtcUpload.etcUpload.authorType === AuthorType.outside;
        } else {
          return !!findNotPurchaseEtcUpload;
        }
      }

      return false;
    } catch (e) {
      throw new Error(`isIncludeSku : ${e}`);
    }
  }

  isEtcWaterMarkType(skuType: ResourceType): boolean {
    try {
      const etcList = [ResourceType.item, ResourceType.itemSvg, ResourceType.background, ResourceType.backgroundSvg];
      return etcList.includes(skuType);
    } catch (e) {
      throw new Error(e);
    }
  }

  checkWaterMark(fabObjList: Array<TooningFabricObject>): boolean {
    try {
      for (const fab of fabObjList) {
        const isFabricObj = fab instanceof fabric.Object;
        const isGroup = fab.type.toLowerCase() === SelectedType.smallGroup;

        if (this.isContinueType(fab)) {
          continue;
        }
        if (this.isVisibleWaterMark) {
          break;
        }

        if (!isGroup) {
          continue;
        }
        //@ts-ignore
        const fabObjs = isFabricObj ? fab.getObjects() : fab.objects;
        const visibleWaterMark = this.visibleWaterMark(fabObjs as TooningFabricObject[]);
        if (visibleWaterMark) {
          this.isVisibleWaterMark = true;
        }

        if (fab.resource_type.toLowerCase() === SelectedType.smallGroup) {
          this.checkWaterMark(fabObjs as TooningFabricObject[]);
        }
      }

      return this.isVisibleWaterMark;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'checkWaterMark()', this.app, true);
    }
  }

  visibleWaterMark(fabObjs: TooningFabricObject[]): boolean {
    try {
      const lastIndex = fabObjs.length - 1;
      const lastObj = fabObjs[lastIndex];

      return lastObj.resource_type === ResourceType.waterMark && lastObj.visible;
    } catch (e) {
      throw new Error(`visibleWaterMark: ${e}`);
    }
  }

  /**
   * 워터 마트를 보일지 말지 결정하는 함수
   * @param fabObjList
   */
  setWaterMark(fabObjList: Array<TooningFabricObject>): void {
    try {
      for (const fab of fabObjList) {
        const isFabricObj = fab instanceof fabric.Object;
        const isGroup = fab.type.toLowerCase() === SelectedType.smallGroup;

        if (this.isContinueType(fab)) {
          continue;
        }
        if (isGroup) {
          //@ts-ignore
          const fabObjs = isFabricObj ? fab.getObjects() : fab.objects;
          if (fab.resource_type.toLowerCase() === SelectedType.smallGroup) {
            this.setWaterMark(fabObjs as TooningFabricObject[]);
          }

          const isInsertWaterMark = this.isIncludeSku(fab.resource_type, fab.resource_id);
          this.setGroupWaterMark(fabObjs as TooningFabricObject[], fab, this.existWaterMark(fab), isInsertWaterMark);
        }
      }
    } catch (e) {
      if (e instanceof TooningGetLastObjectError) {
        throw e;
      } else {
        throw new TooningSetWaterMark(e, null, true);
      }
    }
  }

  /**
   * 워터마크를 안붙여야하는 리소스 타입을 확인한다.
   * resource_type 이 없거나, guideMask, waterMark,인 경우는 워터마크를 붙이지 않는다.
   * 워터 마크 적용 타입은 아래와 같다.
   * public applyWaterMarkList = ['character-svg', 'background-svg', 'background', 'item', 'item-svg', 'Group'];
   * @param fab
   * @return {boolean} true : 워터마크를 안 붙여도 되는 경우
   */
  isContinueType(fab: TooningFabricObject): boolean {
    try {
      let isContinue = false;
      if (
        !fab.resource_type ||
        fab.resource_type === ResourceType.guideMask ||
        fab.resource_type === ResourceType.bgColor ||
        fab.resource_type === ResourceType.waterMark ||
        !this.applyWaterMarkList.includes(fab.resource_type)
      ) {
        isContinue = true;
      }

      return isContinue;
    } catch (e) {
      console.error(e);
      throw new Error(`isContinueType : ${e}`);
    }
  }

  /**
   * 워터마크가 존재 시, visible 을 true, false 제어 하며,
   * 워터마크가 없을 시, 워터마크 추가
   * crossOrigin = 'anonymous' 가 없을 시 문제가 발생하는 경우가 생김
   * @param getObjects
   * @param originFab
   * @param isExistWaterMark
   * @param isInsertWaterMark
   */
  setGroupWaterMark(getObjects: TooningFabricObject[], originFab: TooningFabricObject, isExistWaterMark: boolean, isInsertWaterMark: boolean): void {
    try {
      if (this.isExceptionWaterMarkAuth()) {
        if (isExistWaterMark) {
          this.removeWaterMark(getObjects);
        }
        return;
      }

      // 그룹인 경우
      const lastFabObj = this.getLastObj(originFab);
      if (!lastFabObj) {
        return;
      }
      lastFabObj.crossOrigin = 'anonymous';
      if (isExistWaterMark && isInsertWaterMark && getObjects) {
        // 이미 layer에 워터마크 존재 + 구매하지 않은 목록에 존재
        lastFabObj.visible = true;
        lastFabObj.originX = 'left';
        lastFabObj.originY = 'top';
      } else if (isExistWaterMark && !isInsertWaterMark && getObjects) {
        // 이미 layer에 워터마크 존재 + 구매하지 않은 목록에 존재X (이미 구매 함)
        lastFabObj.visible = false;
        lastFabObj.originX = 'left';
        lastFabObj.originY = 'top';

        this.canvas.fire('object:purchased', {
          resource_selection_id: originFab.resource_selection_id,
          resource_id: originFab.resource_id,
          waterMark: { visible: false }
        });
      } else if (!isExistWaterMark && getObjects) {
        // lastFabObj.getCoords()[0].x
        // 기존 layer에 워터마크 없을 경우 insert
        this.waterMarkDummy.set({
          originX: 'left',
          originY: 'top',
          left: -(originFab.width / 2 + originFab.strokeWidth),
          top: -(originFab.height / 2 + originFab.strokeWidth),
          width: originFab.width,
          height: originFab.height,
          resource_type: ResourceType.waterMark,
          crossOrigin: 'anonymous'
        });

        getObjects.push(this.waterMarkDummy.toObject());
        const lastIndex = getObjects.length - 1;
        getObjects[lastIndex].visible = isInsertWaterMark;
      }
    } catch (e) {
      if (e instanceof TooningGetLastObjectError) {
        throw e;
      } else {
        throw new TooningSetGroupWaterMark(e, null, true);
      }
    }
  }

  setWaterMarkSize(originFab, visibleStatus: boolean, realArea: { xToMove: number; yToMove: number }) {
    try {
      if (!(originFab.objects || originFab.getObjects)) {
        return;
      }
      const waterMarkObj = this.getLastObj(originFab);
      if (waterMarkObj && waterMarkObj.resource_type === ResourceType.waterMark) {
        waterMarkObj.width = originFab.width;
        waterMarkObj.height = originFab.height;

        const moveToX = -(originFab.width / 2 + realArea.xToMove);
        const moveToY = -(originFab.height / 2 + realArea.yToMove);
        waterMarkObj.left = moveToX;
        waterMarkObj.top = moveToY;
        waterMarkObj.visible = visibleStatus;
        originFab.isFitWaterMark = true;
      } else {
        console.warn(`워터 마크가 없거나 리소스 타입이 워터마크가 아닙니다`);
      }
    } catch (e) {
      throw new Error(`setWaterMarkSize : ${e}`);
    }
  }

  /**
   * 워터마크 크기 유지
   * @param selected 오브젝트
   * @param {number} scaleX 계산을 위해 필요한 부모 scaleX
   * @param {number} scaleY 계산을 위해 필요한 부모 scaleY
   * @return {void}
   */
  setWaterMarkResize(selected, scaleX: number, scaleY: number): void {
    try {
      if (
        selected.type === SelectedType.smallGroup ||
        selected.resource_type === ResourceType.Group ||
        selected.type === SelectedType.activeSelection
      ) {
        let strokeWidth = selected.strokeWidth;
        const objs = selected.getObjects();
        for (const obj of objs) {
          const strokeUniform = obj.strokeUniform;
          if (strokeWidth === 0 && obj.strokeWidth) {
            strokeWidth = obj.strokeWidth;
          }

          if (obj.resource_type === ResourceType.waterMark) {
            // 워터마크니까 리사이즈
            // 리소스가 캐릭터일 경우
            if (selected.resource_type === ResourceType.characterSvg) {
              obj.set({
                width: scaleX * selected.width,
                height: scaleY * selected.height,
                scaleX: 1 / scaleX,
                scaleY: 1 / scaleY
              });
              // 리소스가 요소 아이템일 경우
            } else if (strokeUniform) {
              // 윤곽선 고정일 경우
              obj.set({
                width: scaleX * (selected.width + strokeWidth),
                height: scaleY * (selected.height + strokeWidth),
                scaleX: 1 / scaleX,
                scaleY: 1 / scaleY,
                top: -(selected.height / 2 + selected.strokeWidth),
                left: -(selected.width / 2 + selected.strokeWidth)
              });
            } else {
              // 윤곽선 고정이 아닐 경우
              obj.set({
                width: scaleX * selected.width,
                height: scaleY * selected.height,
                scaleX: 1 / scaleX,
                scaleY: 1 / scaleY,
                top: -((selected.height + strokeWidth) / 2),
                left: -((selected.width + strokeWidth) / 2)
              });
            }
          } else {
            // 워터마크 아니니까 재귀
            const newX = scaleX * obj.scaleX;
            const newY = scaleY * obj.scaleY;
            this.setWaterMarkResize(obj, newX, newY);
          }
        }
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'setWaterMarkResize()', this.app, true);
    }
  }

  /**
   * waterMark object 를 뽑아내는 메소드
   * @param originFab
   * @return {TooningFabricObject} that is getObjects[lastIndex]
   */
  getLastObj(originFab: TooningFabricObject): TooningFabricObject {
    try {
      if (originFab.type !== SelectedType.smallGroup) {
        return;
      }
      const isFabricObj = originFab instanceof fabric.Object;
      //@ts-ignore
      const getObjects = isFabricObj ? originFab.getObjects() : originFab.objects;
      if (getObjects instanceof Array && getObjects.length === 0) {
        throw new Error(`getObjects 가 빈 배열입니다.`);
      }

      const lastIndex = getObjects.length - 1;
      return getObjects[lastIndex] as TooningFabricObject;
    } catch (e) {
      throw new TooningGetLastObjectError(e, null, false);
    }
  }

  /**
   * 해당 fabricObject 에 워터마크가 있는지 확인하는 함수
   * @param {TooningFabricObject} fab 워터마크 존재 유무를 확인 하려는 fabricObject
   * @return {booelan} true : 해당 fabricObject 마지막 레이어에 워터마크가 존재
   */
  existWaterMark(fab): boolean {
    try {
      const isFabricObj = fab instanceof fabric.Object;
      //@ts-ignore
      const fabObjects = isFabricObj ? fab.getObjects() : fab.objects;
      return fabObjects ? this.checkExistGroup(fabObjects) : this.checkExistImg();
    } catch (e) {
      console.error(e);
      throw new Error(`existWaterMark : ${e}`);
    }
  }

  checkExistGroup(fabObjects: TooningFabricObject[]): boolean {
    try {
      if (fabObjects.length !== 0) {
        const lastIndex = fabObjects.length - 1;
        const lastFabric = fabObjects[lastIndex];
        return lastFabric.resource_type && lastFabric.resource_type === ResourceType.waterMark;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      throw new Error(`checkExistGroup : ${e}`);
    }
  }

  checkExistImg(): boolean {
    return false;
  }

  /**
   * aws 에 저장된 실제 페이지들 불러오기
   * @param {Page[]} pages 서버에 저장된 페이지 정보
   */
  async fetchPages(pages: Page[], onlyFirstPage = false) {
    try {
      const pagesPromise = [];
      const returnPages = [];
      const rejectedIndexs = [];

      for (const [index, page] of pages.entries()) {
        if (onlyFirstPage && index === 1) {
          break;
        }
        pagesPromise.push(this.getHttpUrl(page.json).toPromise());
      }
      // @ts-ignore
      await Promise.allSettled(pagesPromise).then((promiseResults) => {
        promiseResults.forEach((result, index) => {
          if (result.status === PromiseStatus.fulfilled) {
            // DB에서 꺼내온 json 값
            returnPages.push(result.value);
          } else {
            console.error(':::rejected promise:::');
            console.log(result);
            // DB에 json이 없는 페이지 index 값
            rejectedIndexs.push(index);
          }
        });
      });

      return [returnPages, rejectedIndexs];
    } catch (e) {
      console.error(e);
      throw new Error(`fetchPages: ${e}`);
    }
  }

  /**
   * 무료 이용자 팝업 차단 함수
   * @returns {Promise<BlockType>}
   */
  async isBlockFunction(): Promise<BlockType> {
    if (this.app.cache.user.role === UserRole.demo) {
      await this.app.demoClass.openmodalDemoLimit();
      return;
    }
    return new Promise(async (resolve, reject) => {
      try {
        if (this.selectedOb.type === SelectedType.smallGroup) {
          this.isVisibleWaterMark = false;
          const checkFabOb =
            this.selectedOb.resource_type.toLowerCase() === SelectedType.smallGroup ? this.selectedOb.getObjects() : [this.selectedOb];
          const existWaterMark = this.checkWaterMark(checkFabOb);
          if (existWaterMark) {
            if (this.popAlert !== null) {
              return;
            }
            this.popAlert = await this.alertController.create({
              header: this.translate.instant('notice'),
              cssClass: 'basic-dialog',
              message: this.translate.instant('feature block'),
              buttons: [
                {
                  text: this.translate.instant('CANCEL'),
                  role: BlockType.cancel,
                  handler: (blah) => {
                    console.log('Confirm Cancel: blah');
                    return { returnValue: BlockType.cancel };
                  }
                },
                {
                  text: this.translate.instant('purchase'),
                  role: BlockType.purchase,
                  handler: () => {
                    console.log('open purchase modal');
                    return { returnValue: BlockType.purchase };
                  }
                }
              ]
            });
            await this.popAlert.present();
            this.popAlert.onDidDismiss().then(({ role }) => {
              role === BlockType.purchase ? resolve(BlockType.purchase) : resolve(BlockType.cancel);
              console.log(`format-change alert dissmiss`);
              this.popAlert = null;
            });
          } else {
            resolve(BlockType.continue);
          }
        } else {
          resolve(BlockType.continue);
        }
      } catch (e) {
        console.error(e);
        reject(`blockFunction: ${e}`);
      }
    });
  }

  /**
   * 유료 상품 리스트
   * @param objs - 이 obj 안에서 유료 상품들을 찾아낸다  ex) 캐릭터, 요소, 그룹화 등..
   * @param pageIndex - 몇 번째 페이지
   */
  getSkuList(objs, pageIndex) {
    try {
      if (Array.isArray(objs)) {
        this.getSkuListFromFabricObjs(objs, pageIndex);
      } else {
        this.getSkuListFromSelectedObj(objs, pageIndex);
      }
    } catch (e) {
      console.error(e);
      throw new Error(`getSkuIdListInCanvas : ${e}`);
    }
  }

  /**
   * selectedObj 안에서 유료 상품을 찾아 '구매해야할 리스트' 에 넣어준다.
   * @param obj - 선택한 obj (= selectedObj)
   * @param pageIndex - 몇 번째 페이지
   */
  getSkuListFromSelectedObj(obj, pageIndex) {
    try {
      if (obj.resource_type.toLowerCase() === SelectedType.smallGroup) {
        for (const fab of obj._objects) {
          if (!this.applyWaterMarkList.includes(fab.resource_type)) {
            continue;
          }
          this.getSkuListFromSelectedObj(fab, pageIndex);
        }
      } else if (obj.type === SelectedType.smallGroup) {
        const getObjects = obj._objects;
        const lastIndex = getObjects.length - 1;

        // 마지막 인덱스가 water mark + 활성화 됨 => 유료
        if (getObjects[lastIndex].resource_type === ResourceType.waterMark && getObjects[lastIndex].visible) {
          obj.resource_type === ResourceType.characterSvg ? this.characterSkuSet(obj, pageIndex) : this.etcSkuSet(obj, pageIndex);
        }
      }
    } catch (e) {
      console.error(e);
      throw new Error(`getSkuIdListInCanvas : ${e}`);
    }
  }

  /**
   * 하나의 page (= fabricOb) 내에서 유료 상품을 찾아 '구매해야할 리스트' 에 넣어준다.
   * @param fabricObjs - 이 페이지의 fabric obj
   * @param pageIndex - 몇 번째 페이지
   */
  getSkuListFromFabricObjs(fabricObjs, pageIndex) {
    try {
      // 구매할 상품 리스트 체크
      for (const fab of fabricObjs) {
        if (!this.applyWaterMarkList.includes(fab.resource_type)) {
          continue;
        }

        if (fab.resource_type.toLowerCase() === SelectedType.smallGroup) {
          // 그룹인 경우 재귀
          this.getSkuListFromFabricObjs(fab.objects, pageIndex);
          // 캐릭터, 소품 등 그룹 형태
        } else if (fab.type === SelectedType.smallGroup) {
          const getObjects = fab.objects;
          const lastIndex = getObjects.length - 1;

          // 마지막 인덱스가 water mark + 활성화 됨 => 유료
          if (getObjects[lastIndex].resource_type === ResourceType.waterMark && getObjects[lastIndex].visible) {
            fab.resource_type === ResourceType.characterSvg ? this.characterSkuSet(fab, pageIndex) : this.etcSkuSet(fab, pageIndex);
          }
        }
      }
    } catch (e) {
      console.error(e);
      throw new Error(`getSkuIdListInCanvas : ${e}`);
    }
  }

  characterSkuSet(fab: TooningFabricObject, index: number) {
    try {
      const notPurchaseCharacterSku = this.notPurchasedCharacterListInSku.find((sku) => sku.character && +sku.character.id === +fab.resource_id);
      if (notPurchaseCharacterSku) {
        notPurchaseCharacterSku.thumbnail = fab.resource_preview;
        notPurchaseCharacterSku.nameKo = fab.resource_name;
        notPurchaseCharacterSku.nameEn = fab.resource_name_en;
        notPurchaseCharacterSku.page = index;
      }

      // 중복 고려
      const existPurchaseCharacter = this.purchaseSkuList.find((sku) => sku.character && +sku.character.id === +notPurchaseCharacterSku.character.id);

      if (!existPurchaseCharacter) {
        this.purchaseSkuList.push(notPurchaseCharacterSku);
      }
    } catch (e) {
      console.error(e);
      throw new Error(`characterSkuSet : ${e}`);
    }
  }

  etcSkuSet(fab: TooningFabricObject, index: number) {
    try {
      const notPurchaseEtcSku = this.notPurchasedEtcUploadListInSku.find((sku) => sku.etcUpload && +sku.etcUpload.sourceId === +fab.resource_id);
      if (notPurchaseEtcSku) {
        notPurchaseEtcSku.thumbnail = fab.resource_preview;
        notPurchaseEtcSku.nameKo = fab.resource_name;
        notPurchaseEtcSku.nameEn = fab.resource_name_en;
        notPurchaseEtcSku.page = index;
      }
      // 중복 고려
      const existPurchaseEtc = this.purchaseSkuList.find((sku) => sku.etcUpload && +sku.etcUpload.sourceId === +notPurchaseEtcSku.etcUpload.sourceId);
      if (!existPurchaseEtc) {
        this.purchaseSkuList.push(notPurchaseEtcSku);
      }
    } catch (e) {
      console.error(e);
      throw new Error(`etcSkuSet : ${e}`);
    }
  }

  /**
   * page 에 존재하는 object 의 실제 영역을 확인 하여 반영한다.
   * @param characterMake
   * @return {Promise<void>}
   */
  async pageSetRealArea(characterMake?): Promise<void> {
    const load = new Loading();
    let isSetResourceRealAreaStart = false;
    try {
      for (const getObj of this.canvas.getObjects()) {
        if (this.isSetRealArea(getObj) && (getObj.clipPath === null || getObj.clipPath === undefined)) {
          if (!isSetResourceRealAreaStart) {
            isSetResourceRealAreaStart = true;
            await load.showLoader(this.translate.instant('waterMarkCheck'));
          }
          if (characterMake) {
            await characterMake.setResourceRealArea(getObj, this.canvas, false);
          } else {
            console.warn(`characterMake 가 없는 케이스 입니다.`);
          }
        }
      }
    } catch (e) {
      throw new Error(`pageSetRealArea : ${e}`);
    } finally {
      if (isSetResourceRealAreaStart) {
        load.hideLoader();
      }
    }
  }

  /**
   * getObj 이 setRealArea 가 필요한지 확인하는 함수
   * 캐릭터인 경우, 캐릭터의 마지막 레이어가 워터마크이고, visible한데, 캐릭터에 fit하지 않은 경우만 setRealArea를 호출한다.
   * @param {TooningFabricObject} getObj
   * @return {boolean}
   */
  isSetRealArea(getObj): boolean {
    try {
      const isCharacterType = getObj.resource_type === ResourceType.characterSvg;
      if (!isCharacterType) {
        return false;
      }

      const lastObject = this.getLastObj(getObj);
      if (lastObject.resource_type !== ResourceType.waterMark) {
        return false;
      }

      // lastObject 이 워터마크인 경우
      const isVisibleWaterMark = lastObject.visible;
      if (!isVisibleWaterMark) {
        return false;
      }

      const isFitWaterMark = getObj.isFitWaterMark;
      if (isFitWaterMark) {
        return false;
      }
      return isCharacterType && isVisibleWaterMark && !isFitWaterMark;
    } catch (e) {
      throw new Error(`isSetRealArea : ${e}`);
    }
  }

  /**
   * 삭제 된 캔버스인지 체크
   * @param {number} canvasId 체크하려는 캔버스 id
   * @returns {Promise<boolean>}
   */
  async isDeleteCheck(canvasId: number): Promise<boolean> {
    try {
      if (!this.app.cache.user?.id) {
        this.app.cache.user = await this.app.user.currentUser();

        if (this.app.cache.user.id === null) {
          throw new TooningCustomClientError('user.id is null', FILE_NAME, 'isDeleteCheck().UserIdIsNullError', this.app, true);
        }
      }
      const { data } = await this.canvasService.isCanvasDeleted(+this.app.cache.user.id, canvasId).toPromise();
      return data;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'isDeleteCheck().IsDeleteCheckError', this.app, true);
    }
  }

  /** 워터 마크를 붙이지 않아도 되는 사용자 role 체크
   *@return {boolean} true : admin, template, textTemplate 인 경우
   */
  isExceptionWaterMarkAuth(): boolean {
    try {
      return WATERMARK_NOT_NEEDED_ROLES.includes(this.app.cache.user.role);
    } catch (e) {
      throw new Error(`isExceptionWaterMarkAuth : ${e}`);
    }
  }

  getVersion(fabricOb: TooningFabricObject): Version {
    try {
      let version;
      if (+fabricOb.characterVersion >= 0) {
        version = +fabricOb.characterVersion;
      } else {
        const selectedId = fabricOb.resource_id;
        version = this.characterV2List.includes(selectedId) ? Version.v2 : Version.v1;
      }

      return version;
    } catch (e) {
      throw new Error(`getVersion : ${e}`);
    }
  }

  // 패널 삭제
  /**
   * 개별로 패널을 삭제 - 확인 팝업 호출
   * @param indexList - 패널 리스트 전달
   * @param popoverController - popoverController 형태로 호출됬는지 파악하기 위한 인자
   */
  async removeAlertConfirm(popoverController = null) {
    if (this.popAlert !== null) {
      return;
    }
    this.popAlert = true;
    this.setKeyActivation = false;
    this.popAlert = await this.alertController.create({
      cssClass: 'basic-dialog',
      header: this.translate.instant('real delete'),
      message: this.translate.instant('irreversible comment'),
      buttons: [
        {
          text: this.translate.instant('cancel'),

          role: 'cancel',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        },
        {
          text: this.translate.instant('delete'),
          cssClass: 'deleteOK',
          handler: async () => {
            try {
              let goIndex = 0;
              try {
                await this.updatePageSync(this.panelIndex);
              } catch (e) {
                console.error(e.message);
              }

              // 1개
              const indexList = [];
              this.pageList.forEach((page, index) => {
                if (page.isChecked) indexList.push(index);
              });
              if (indexList.length > 1) {
                // 2개 이상
                goIndex = await this.removePanelList();
              } else {
                goIndex = await this.removePanel(indexList[0]);
              }
              await this.goPage(goIndex, true);
              this.pageList.forEach((page) => (page.isChecked = false));
              if (this.isPageListEditMode) {
                this.isPageListEditMode = false;
              }

              if (popoverController !== null) {
                await popoverController.dismiss({});
              }
            } catch (e) {
              console.error(e);
              console.trace(e);
              throw e;
            }
          }
        }
      ]
    });
    this.popAlert.onDidDismiss().then(async (data) => {
      this.popAlert = null;
      this.setKeyActivation = true;
    });
    this.popAlert.present().then(() => {
      // @ts-ignore
      document.getElementsByClassName('deleteOK')[0].focus();
      document.getElementsByClassName('deleteOK')[0].addEventListener('click', (event) => {
        // @ts-ignore
        event.target.dataset.clickoutside = false;
      });
    });
  }

  /**
   * 입력받은 태그 중복,'' 체크
   * @param tagValue 입력받은 문자열
   */
  public inputTagCheck(tagValue, originTags) {
    tagValue = tagValue.normalize();
    const splitTag = tagValue.split(',');
    const trimTagList = [];

    // 입력값 중복체크
    for (let tag of splitTag) {
      const tagToTrim = _.trim(tag);
      if (trimTagList.length > 0 && trimTagList.some((trimTag) => trimTag === tag)) {
        return false;
      }
      if (tagToTrim !== '') {
        trimTagList.push(tagToTrim);
      }
    }

    // 원래 리스트랑 중복체크
    for (const tag of trimTagList) {
      if (originTags.some((origin) => origin === tag)) {
        return false;
      }
    }

    return trimTagList;
  }

  isClipPath() {
    let data = true;
    if (this.selectedOb.clipPath === null || this.selectedOb.clipPath === undefined) {
      data = false;
    }
    return data;
  }

  clipReSet() {
    this.selectedOb.clipPath = null;
    this.canvas.requestRenderAll();
  }

  /**
   *  커서 모양을 변경하는 함수
   * @param {string} type - 일반 형태 - 커스텀한 형태 인지 바꾸는 인자
   */
  setCursor(type: string = CursorType.default) {
    try {
      let cursorUrl;
      if (type === CursorType.default) {
        cursorUrl = '/assets/4cut-make-manual/default-cursor.png';
        this.canvas.defaultCursor = `url("${cursorUrl}"), auto`;
        fabric.Object.prototype.moveCursor = `move`;
        fabric.Object.prototype.hoverCursor = `url("${cursorUrl}"), auto`;
        fabric.Canvas.prototype.defaultCursor = `url("${cursorUrl} "), auto`;
        fabric.Canvas.prototype.moveCursor = `url("${cursorUrl}"), auto`;
        fabric.Canvas.prototype.hoverCursor = `url("${cursorUrl}"), auto`;
        fabric.Canvas.prototype.rotationCursor = `crosshair`;
        fabric.Canvas.prototype.notAllowedCursor = `url("${cursorUrl}"), auto`;
        this.canvas.setCursor(`url("${cursorUrl}"), auto`);
        setTimeout(() => {
          this.renderer.setStyle(this.pointElement.nativeElement, 'visibility', 'hidden');
        }, 100);
      } else if ((type === CursorType.beforeCatching && !this.isDownMouseLeftKey) || type === CursorType.afterCatching) {
        this.canvas.defaultCursor = 'none';
        fabric.Object.prototype.moveCursor = `none`;
        fabric.Object.prototype.hoverCursor = `none`;
        fabric.Canvas.prototype.defaultCursor = `none`;
        fabric.Canvas.prototype.moveCursor = `none`;
        fabric.Canvas.prototype.hoverCursor = `none`;
        fabric.Canvas.prototype.rotationCursor = `none`;
        fabric.Canvas.prototype.notAllowedCursor = `none`;
        this.canvas.setCursor(`none`);
        setTimeout(() => {
          this.pointImgUrl =
            type === CursorType.afterCatching ? '/assets/4cut-make-manual/icon_grab.png' : '/assets/4cut-make-manual/icon_ungrab.png';
          this.renderer.setStyle(this.pointElement.nativeElement, 'visibility', 'visible');
          // 스페이스 누르고 마우스 포인터 이동
          const top = this.mousePoint.y - this.pointElement.nativeElement.offsetHeight / 2;
          const left = this.mousePoint.x - this.pointElement.nativeElement.offsetWidth / 2;
          this.renderer.setStyle(this.pointElement.nativeElement, 'top', top + 'px');
          this.renderer.setStyle(this.pointElement.nativeElement, 'left', left + 'px');
        }, 100);
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   *   // 화면 지우기 재확인 팝업
   * @param index
   * @param {Event} event
   */

  async allCleanConfirm(index, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.beforeDataLessJsonString = JSON.stringify(this.canvas.toDatalessJSON());
    this.popAlert = await this.alertController.create({
      cssClass: 'basic-dialog',
      header: this.translate.instant('real empty'),
      message: this.translate.instant('empty comment'),
      buttons: [
        {
          text: this.translate.instant('cancel'),
          role: 'cancel',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.beforeDataLessJsonString = undefined;
          }
        },
        {
          text: this.translate.instant('header'),
          handler: async () => {
            await this.allClean(index);
          }
        }
      ]
    });
    this.popAlert.onDidDismiss().then(async () => {
      this.popAlert = null;
    });
    await this.popAlert.present();
  }

  /**
   * 유니크 아이디 생성 시간 기준으로..
   */
  uuidv1Make() {
    return uuidv1();
  }

  /**
   * 유니크한 아이디를 할당하줌.
   * @param object - 페브릭 오브젝트
   */
  assignmentID(object) {
    try {
      if (object.resource_selection_id === undefined || object.resource_selection_id === null) {
        object.resource_selection_id = this.uuidv1Make();
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  }

  //객체가 캔버스 박으로 나가면 삭제처리
  async canvasBoundingOutRemoveObject() {
    try {
      const data = this.isCanvasBoundingOut();
      console.log('15초 간격으로 외부 오브젝트 체크 후 삭제 - canvasBoundingOutRemoveObject');
      if (data.isCanvasBoundingOut) {
        if (this.popAlert !== null) {
          return;
        }
        this.forceNoFocus();
        this.setKeyActivation = false;
        this.popAlert = await this.alertController.create({
          header: this.translate.instant('canvasBoundingOut.title'),
          message: this.translate.instant('canvasBoundingOut.message'),
          cssClass: 'basic-dialog',
          backdropDismiss: false,
          buttons: [
            {
              text: this.translate.instant('canvasBoundingOut.btn1'),
              role: 'cancel',
              handler: () => {}
            },
            {
              text: this.translate.instant('canvasBoundingOut.btn2'),
              role: 'ok',
              handler: async () => {
                for (let i = 0, j = data.obList.length; i < j; i++) {
                  // 삭제하기 전에 실행해야함.
                  const command = new ObjectRemoveCommand({
                    target: _.cloneDeep(data.obList[i]),
                    cut: this,
                    callback: async () => {
                      this.canvas.remove(data.obList[i]);
                    }
                  });
                  await this.commandManager.executeCommand(command);
                }
              }
            }
          ]
        });
        this.popAlert.onDidDismiss().then(async (data) => {
          this.popAlert = null;
          this.setKeyActivation = true;
        });
        this.forceNoFocus();
        await this.popAlert.present();
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 캔버스에서 벗어난 리소스 체크
   * @return {BoundingCheck}
   */
  isCanvasBoundingOut(): BoundingCheck {
    try {
      let returnData = false;
      const obList = [];
      const objects = this.canvas.getObjects();
      const zoom = this.canvas.getZoom();
      for (let i = 0, j = objects.length; i < j; i++) {
        const object = objects[i];
        if (object.resource_type !== ResourceType.guideMask && object.resource_type !== ResourceType.bgColor) {
          const bound = object.getBoundingRect();
          const leftCheck = object.left - (bound.width / zoom) * 0.5;
          const rightCheck = object.left + (bound.width / zoom) * 0.5;
          const topCheck = object.top - (bound.height / zoom) * 0.5;
          const bottomCheck = object.top + (bound.height / zoom) * 0.5;

          if (this.canvasSize.w + 1000 < leftCheck || -1000 > rightCheck || this.canvasSize.h + 1000 < topCheck || -1000 > bottomCheck) {
            obList.push(object);
            returnData = true;
          }
        }
      }

      return { isCanvasBoundingOut: returnData, obList };
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'isCanvasBoundingOut()');
    }
  }

  /**
   * 캔버스에 있는 캐릭터 가져와는 함수
   */
  getCharacterOnCanvas(fabricList, isFabric: boolean = true) {
    const objectList = isFabric ? fabricList.getObjects() : fabricList;
    return objectList.filter((object) => object.resource_type === ResourceType.characterSvg);
  }

  /**
   * 이동중 알트키를 누르면 복제 시키는 함수
   */
  async altKeyCopyFn(ob) {
    this.altKeyCopy = true;
    if (ob.type === SelectedType.activeSelection) {
      let selectedIndex;
      const objs = ob._objects;
      for (let i = objs.length - 1; i >= 0; i--) {
        const index = this.canvas.getObjects().indexOf(objs[i]);
        if (i === objs.length - 1) {
          // 선택한 오브젝트 이동 할 인덱스
          selectedIndex = index + 1;
        }
        await this.altCopyAndPaste(objs[i], index);

        // 선택한 오브젝트 인덱스 이동
        objs[i].moveTo(selectedIndex);
      }
    } else {
      const index = this.getIndex();
      await this.altCopyAndPaste(ob, index);
    }
  }

  /**
   * 이미지 타입_item > 을 신규 구조로 변환 시켜주는 함수 - 이미지를 그룹으로 감싸주는 형식
   * @param target
   */
  imageChangeVersion(target) {
    // 이미지 사이즈 등이 변하는 경우를 위함
    const originSacleX = target.scaleX;
    const originSacleY = target.scaleY;

    target.scaleX = 1;
    target.scaleY = 1;

    let group = new fabric.Group([target]);
    group.scaleX = originSacleX;
    group.scaleY = originSacleY;
    // @ts-ignore
    group.resource_type = target.resource_type;
    // @ts-ignore
    group.etcUploadVersion = Version.v2;
    this.canvas.remove(target);
    this.canvas.add(group);
    this.canvas.requestRenderAll();
  }

  /**
   * 제작하기 또는 체험하기
   * @param {number} magicStyleCharacterId 매직 스타일의 캐릭터 아이디
   * @return {Promise<void>}
   */
  async openMakeToon(magicStyleCharacterId?: number): Promise<void> {
    try {
      if (this.isLandingStartClicked) {
        return;
      }

      this.isLandingStartClicked = true;
      if (this.app.loginStatus) {
        if (magicStyleCharacterId !== undefined) await this.canvasNewMake(NEW_MAGIC + magicStyleCharacterId);
        else await this.canvasNewMake();
      } else {
        await this.demoDummyLogin();
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'openMakeToon()');
    } finally {
      this.isLandingStartClicked = false;
    }
  }

  /**
   * 에트리 전시를 위한 접근 함수
   * @return {Promise<void>}
   */
  public async etriDummyLogin(): Promise<void> {
    try {
      const etriLogin = {
        email: 'etri_test@tooning.io',
        language: this.app.usedLanguage,
        password: '1111'
      };
      const { token } = await this.app.user.loginCustom(etriLogin as CustomLoginUser);
      // client 로그인 처리
      await this.app.user.login(token, UserLoginType.custom);
      this.app.cache.user = await this.app.user.currentUser();
      this.app.loginStatus = true;
      this.app.isAdmin = false;
      // this.app.isPaidMember = await this.paymentService.isPaid(this.app.cache.user.id); - 서버에서 받기
      this.app.isPaidMember = true; // 클라이언트에서 강제로 처리
      await this.canvasNewMakeForDemo();
    } catch (e) {
      if (this.app.loginStatus) {
        await this.app.logout(false);
      }
      throw new TooningCustomClientError(e, FILE_NAME, 'etriDummyLogin()');
    }
  }

  /**
   * 체험하기 걔정 아디로 로그인 후 에디터 열기 this.app.cache.user.id > 79487 // 체험하기 - 오퍼 베타 디벨롭 동일한 ID
   * @param {boolean} fromTemplateList 템플릿 리스트 페이지에서 접근했는지
   * @return {Promise<void>}
   */
  public async demoDummyLogin(fromTemplateList: boolean = false): Promise<void> {
    const loading = new Loading();
    await loading.showLoader('');
    try {
      const dummyLogin = {
        email: 'dummy@tooning.io',
        language: this.app.usedLanguage,
        password: '1111'
      };
      const { token } = await this.app.user.loginCustom(dummyLogin as CustomLoginUser);
      // client 로그인 처리
      await this.app.user.login(token, UserLoginType.custom);
      this.app.cache.user = await this.app.user.currentUser();
      this.app.loginStatus = true;
      this.app.isAdmin = false;
      this.app.isDemo = true;
      this.analyticsService.freeTrial(UserLoginType.custom);
      if (!fromTemplateList) {
        await this.canvasNewMakeForDemo();
      }
    } catch (e) {
      if (this.app.loginStatus) {
        await this.app.logout(false);
      }
      throw new TooningCustomClientError(e, FILE_NAME, 'demoDummyLogin()');
    } finally {
      loading.hideLoader();
    }
  }

  /**
   * 마우스로 패널을 클릭 선택하는 함수 - 시프트 컨트롤을 눌러서 선택 모바일과 데스트크탑이 같이 사용
   * @param {number} index 이동하려는 page 의  인덱스
   * @param {Event} $event 마우스 이벤트
   */
  async goPageWithCanvasUpdateState(index: number, $event: Event, characterMake, fromCheckBox = false, isOrderChange = false): Promise<void> {
    try {
      if (this.afterAIView) {
        this.afterAIView = false;
        return;
      }
      //실제로 이동전에 이동할 페이지가 서버와 동기화가 완료 되었는지 체크
      if (this.pageList[index].dirty && this.pageList[index].fabricObject !== null && index !== this.panelIndex) {
        const loading = new Loading();
        await loading.showLoader('');
        await this.goPageCheckIsSave(index, $event, characterMake, loading);
        return;
      }

      const command = new PageChangeCommand({
        cut: this,
        beforeIndex: this.panelIndex,
        afterIndex: index,
        event: $event,
        callback: async (index, $event) => {
          try {
            // 컨트롤 키를 눌러서 페널 선택 리스트 처리
            if ($event.ctrlKey || $event.metaKey || (isOrderChange && this.isPageListEditMode)) {
              this.setActivatedPageIndex(index);
            } else if ($event.shiftKey) {
              // shiftKey 키를 눌러서 페널 선택 리스트 처리
              if (this.pageList.some((page) => page.isChecked)) {
                let start = this.pageList.findIndex((page) => page.isChecked);
                this.pageList.forEach((page) => (page.isChecked = false));
                let step = start < index ? 1 : -1;
                while (start != index + step) {
                  this.pageList[start].isChecked = true;
                  start += step;
                }
              } else {
                this.setActivatedPageIndex(index);
              }
            } else if (fromCheckBox) {
              //이미 checkbox에 ngmodel로 바인딩해놔서 값이 바뀐상태로 넘어옴, 아무것도 없는것이 맞음
            } else {
              this.pageList.forEach((page) => (page.isChecked = false));
              this.setActivatedPageIndex(index);
            }

            // index: 가려는 페이지의 index
            // this.panelIndex: 현재 위치한 페이지의 index
            if (index !== this.panelIndex && this.pageList[index].isChecked) {
              await this.goPage(index);
              await this.pageSetRealArea(characterMake);
            }

            // 선택된 페널의 수를 바탕으로 삭제 모드 어떻게 보여줄지
            if (this.pageList.filter((page) => page.isChecked).length > 1) {
              this.isPageListEditMode = true;
            }
          } catch (e) {
            throw new TooningCustomClientError(e, FILE_NAME, 'goPageWithCanvasUpdateState().PageWithCanvasUpdateStateError', this.app, true);
          }
        }
      });
      if (this.commandManager) {
        await this.commandManager.executeCommand(command);
      } else {
        throw new Error('this.cut.commandManager 인스턴스가 없습니다.');
      }
    } catch (e) {
      if (e instanceof TooningPageUpdateTimeOutError) {
        throw e;
      } else {
        throw new TooningCustomClientError(e, FILE_NAME, 'goPageWithCanvasUpdateState().GoPanelWithCanvasUpdateState');
      }
    }
  }

  setActivatedPageIndex(index: number) {
    if (this.pageList[index].isChecked && this.isPageListEditMode) {
      this.pageList[index].isChecked = false;
    } else {
      // 없으면 추가
      this.pageList[index].isChecked = true;
    }
  }

  /**
   * 1. pageSavingCheckTimer 를 clear
   * 2. pageSavingCheckCount 를 기본값으로 변경합니다.
   */
  resetPageSavingChecker(): void {
    if (this.pageSavingCheckTimer) {
      clearTimeout(this.pageSavingCheckTimer);
      this.pageSavingCheckCount = this.pageSavingInterval;
    }
  }

  /**
   * 이동하려는 페이지가 서버에 저장되어 있는지 확인 후,
   * 서버에 저장되어 있다면, 페이지 이동을 한다.
   * maxPageSavingCheckCount 횟수 만큼 확인 후에도, 저장되어 있지 않다면 다시한번 이동하려는 페이지를 강제로 저장한다.
   * 이때 timeout 에 걸려 저장이 안되면, TooningPageUpdateTimeOutError 을 throw 한다.
   * @param {number} index
   * @param {Event} $event
   * @param characterMake
   * @param loading
   * @return {Promise<void>}
   */
  async goPageCheckIsSave(index: number, $event: Event, characterMake, loading): Promise<void> {
    this.pageSavingCheckTimer = setTimeout(async () => {
      try {
        //사용자가 해당 페이지에 수정 사항이 있고, 그내용이 서버와 싱크가 아직되지 않았음
        if (!this.pageList[index].dirty) {
          //저장 완료
          await this.goPageWithCanvasUpdateState(index, $event, characterMake);
          loading.hideLoader();
          return;
        }
        // 이동하려는 페이지가 서버와 싱크가 안된경우 이며, 3번째까지 loop 돌며 기다리다 저장이 안되어 있는 경우, updatePageSync 로 다시한번 저장을 시도한다.
        if (this.pageSavingCheckCount === this.maxPageSavingCheckCount) {
          try {
            console.warn(`page 저장 확인 loop 를 3초동안 지났으므로 강제로 현재 페이지를 저장합니다.`);
            await this.updatePageSyncByIndex(index);
            await this.goPageWithCanvasUpdateState(index, $event, characterMake);
          } catch (e) {
            //updatePageSync 도 6초 안에 안된 케이스
            console.error(e);
            if (e instanceof TooningPageUpdateTimeOutError) {
              await this.app.showToast(this.translate.instant('error.TooningPageUpdateTimeOutError'), 3000, 'warning', 'middle');
            } else {
              await this.app.showToast(e.message, 10000, 'warning', 'middle');
            }
          } finally {
            loading.hideLoader();
            // 기본같으로 다시 세팅한다.
            this.pageSavingCheckCount = this.pageSavingInterval;
            if (this.pageSavingCheckTimer) {
              clearTimeout(this.pageSavingCheckTimer);
            }
          }
        } else {
          this.pageSavingCheckCount += this.pageSavingInterval;
          await this.goPageCheckIsSave(index, $event, characterMake, loading);
        }
      } catch (e) {
        throw new TooningCustomClientError(e, FILE_NAME, 'goPageCheckIsSave()', this.app);
      }
    }, this.pageSavingInterval);
  }

  /**
   * 전체 선택
   * @param isChecked 채크할지 말지
   */
  allIsChecked(isChecked) {
    this.isCheckedAll = isChecked;
    this.pageList.forEach((page) => {
      page.isChecked = isChecked;
    });
  }

  /**
   * saveCut
   * @param {boolean} isClose
   * @param {boolean} isSaveText
   * @param {number} goPageIndex
   * @param {boolean} isShowToast
   * @return {Promise<UpdatePage>} UpdatePage that return {status, dirty}
   */
  async saveCut(
    isClose: boolean = false,
    isSaveText: boolean = true,
    goPageIndex: number = this.panelIndex,
    isShowToast: boolean = true
  ): Promise<UpdatePage> {
    const loading = new Loading();
    try {
      if (this.isSavingCut) {
        return;
      }

      if (!isClose) {
        loading.showLoader().then();
      }
      this.isSavingCut = true;
      this.canvasHistoryEventSetupOff();
      if (this.selectedOb.resource_type === ResourceType.text) {
        this.selectedOb.exitEditing();
      }

      if (this.selectedOb !== SelectedType.unselected && isClose === true) {
        this.selectedOb.isEditing = false;
      }

      const result: UpdatePage = await this.updatePageSync(this.panelIndex);
      this.isToEditorUrl = this.isEditorUrl();

      if (isClose) {
        this.app.green(`updatePage success ${status}`);

        //필요 없는 코드인지 확인 필요
        if (this.panelIndex !== 0) {
          await this.goPage(0, true, '', true, isClose);
          await this.updatePageSync(0);
        }
        this.app.goReplace('4cut-list');
      }
      if (isSaveText) {
        await this.app.showToast(this.translate.instant('complete save'), 1500);
      }
      return result;
    } catch (error) {
      if (!(error instanceof TooningPageUpdateTimeOutError)) {
        await this.app.showToast(error.message);
      }
      throw new TooningCustomClientError(error, FILE_NAME, 'saveCut()', this.app, true);
    } finally {
      if (!isClose) {
        loading.hideLoader();
      }
      this.multipleSelectionModeCancel();
      this.canvasHistoryEventSetup();
      this.isSavingCut = false;
    }
  }

  /**
   * 캔버스 이동 - 손가락 아이콘으로 변경해되 이동할 때 오브젝트 선택을 막기위한코드 - 일반적으로 포커스날리는 코드로 해결불가능
   */
  dragCanvasAllLock() {
    for (const item of this.canvas.getObjects()) {
      this.dragTargetOb.push({
        object: item,
        selectable: item.selectable
      }); // 이전 상태로 돌리기 위해서 정보를 저장함
      item.selectable = false;
      item.evented = false;
    }
  }

  /**
   * dragCanvasAllLock 묶인 캔버스 오브젝트 다시 되돌리기
   */
  dragCanvasunLock() {
    for (const item of this.dragTargetOb) {
      item.object.selectable = item.selectable;

      if (item.object.resource_type === ResourceType.bgColor) {
        item.object.evented = true;
      } else {
        item.object.evented = item.selectable;
      }
    }
    this.dragTargetOb = [];
  }

  /**
   * 캐릭터 특정 값 체크 시 part와 want가 type이랑 맞는지 여부 체크
   * @param {any} part
   * @param {characterResourcePartType} type
   * @param {any} want
   * @param {string} checkKey
   * @return {boolean}
   */
  isValidTypeForKey(part: any, type: characterResourcePartType, want: any, checkKey: string): boolean {
    return part.get(checkKey) === type && want === type;
  }

  /**
   * 캐릭터 head의 특정 값 일치하는 값 반환
   * @param list
   * @param want
   * @param direction
   */
  findMatchingCharacterHeadPart(headAll: any, want: any, direction = CharacterDirection.front): any {
    if (want === characterResourcePartType.headAll) {
      return headAll;
    }

    for (const h in headAll) {
      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.faceExpression, want, 'part')) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.faceShape, want, 'part')) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.faceEffect, want, 'part')) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.wrinkle, want, 'part')) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.beard, want, 'part')) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.frontHair, want, 'part')) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.glasses, want, 'part')) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.backHair, want, 'part') && direction === CharacterDirection.back) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.accessary, want, 'part')) {
        return headAll[h];
      }

      if (this.isValidTypeForKey(headAll[h], characterResourcePartType.accessaryHead, want, 'part')) {
        return headAll[h];
      }
    }
  }

  /**
   * 캐릭터 leg의 특정 값 일치하는 값 반환
   * @param character
   * @return {any}
   */
  findMatchingCharacterLegPart(character: any): any {
    if (character.get('resource_type') === characterResourcePartType.legDown) {
      return character.item(0);
    }
    if (character.get('resource_type') === characterResourcePartType.legUpSide) {
      return character.item(0);
    }
    if (character.get('resource_type') === characterResourcePartType.legUpFront) {
      return character.item(0);
    }
    if (character.get('resource_type') === characterResourcePartType.legUp) {
      return character.item(0);
    }
  }

  /**
   * 캐릭터 arm의 특정 값 일치하는 값 반환
   * @param character
   * @param want
   * @return {any}
   */
  findMatchingCharacterArmPart(character: any, want: any): any {
    if (character.get('resource_type') === characterResourcePartType.armLDown && want === characterResourcePartType.left) {
      return character.item(0);
    }
    if (character.get('resource_type') === characterResourcePartType.armRDown && want === characterResourcePartType.right) {
      return character.item(0);
    }
    if (character.get('resource_type') === characterResourcePartType.armLMid && want === characterResourcePartType.left) {
      return character.item(0);
    }
    if (character.get('resource_type') === characterResourcePartType.armRMid && want === characterResourcePartType.right) {
      return character.item(0);
    }
    if (character.get('resource_type') === characterResourcePartType.armLUp && want === characterResourcePartType.left) {
      return character.item(0);
    }
    if (character.get('resource_type') === characterResourcePartType.armRUp && want === characterResourcePartType.right) {
      return character.item(0);
    }
  }

  /**
   0 : backHeadAll
   1 : armLDown
   2 : armRDown
   3 : bridgeG
   4 : legDown
   5 : body
   6 : legUp_Front
   7 : armLMid
   8 : armRMid
   9 : headAll
   10 : legUp_Side
   11 : armLUp
   12 : armRUp
   * @param character
   * @param want
   * @param direction
   * @return {any}
   */
  getChatacterFabricObjects(character, want, direction = CharacterDirection.front): any {
    const chatacterAllList = character.getObjects();
    let FabricObject;
    for (const j in chatacterAllList) {
      if (chatacterAllList[j].get('resource_type') === characterResourcePartType.bridgeG && want === characterResourcePartType.bridge) {
        FabricObject = chatacterAllList[j]; // 3
        return FabricObject;
      }
      if (this.isValidTypeForKey(chatacterAllList[j], characterResourcePartType.body, want, 'resource_type')) {
        FabricObject = chatacterAllList[j].item(0);
        return FabricObject;
      }

      if (chatacterAllList[j].get('resource_type') === characterResourcePartType.headAll) {
        const headAll = chatacterAllList[j].item(0).item(0).getObjects();
        const headFabriceObject = this.findMatchingCharacterHeadPart(headAll, want, direction);
        if (headFabriceObject) {
          return headFabriceObject;
        }
      }
      if (
        chatacterAllList[j].get('resource_type') === characterResourcePartType.backHeadAll &&
        want === characterResourcePartType.backHair &&
        direction !== CharacterDirection.back
      ) {
        if (chatacterAllList[j].getObjects().length !== 0) {
          FabricObject = chatacterAllList[j].item(0).item(0).item(0);
          return FabricObject;
        }
      }

      if (chatacterAllList[j].hasOwnProperty('getObjects') && chatacterAllList[j].getObjects().length === 0) {
        continue;
      }

      if (want === characterResourcePartType.leg) {
        const legFabriceObject = this.findMatchingCharacterLegPart(chatacterAllList[j]);

        if (legFabriceObject) {
          return legFabriceObject;
        }
      }

      const armFabricObject = this.findMatchingCharacterArmPart(chatacterAllList[j], want);
      if (armFabricObject) {
        return armFabricObject;
      }
    }

    return FabricObject;
  }

  /**
   * 선택된 오브젝트가 이미지일 경우, 세부설정 값이 디폴트인지 아닌지 체크
   * @return {void}
   */
  checkDetailChanged(): void {
    try {
      const isV2 = this.selectedOb.etcUploadVersion === Version.v2;
      let target;
      if (isV2) {
        target = this.selectedOb._objects[0];
      } else {
        target = this.selectedOb;
      }

      if (this.selectedOb === SelectedType.unselected) {
        return;
      }
      const filters = target.get('filters');

      // 처음 이미지 변환 시, select가 이미지 옵션 지정보다 먼저 콜되기 때문에 추가
      if (target.get('shadow') == null) {
        this.filterDetailChanged = false;
        return;
      }

      if (target.type === 'image') {
        if (
          target.opacity !== 1 ||
          target.shadow.color !== 'rgba(0,0,0,0)' ||
          filters[11].blur !== 0 ||
          filters[5].brightness !== 0 ||
          filters[6].contrast !== 0 ||
          filters[7].saturation !== 0 ||
          filters[21].rotation !== 0 ||
          target.threshold !== 0
        ) {
          this.filterDetailChanged = true;
          return;
        }

        this.filterDetailChanged = false;
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'checkDetailChanged()');
    }
  }

  /**
   * 관리자 및 템플릿 계정에서 이미 워터마크를 가지고 있는 캐릭터 로드시 워터마크를 삭제해주는 함수
   * @param getObjects TooningFabricObject[]
   * @return {void}
   */
  removeWaterMark(getObjects: TooningFabricObject[]): void {
    try {
      if (!getObjects || getObjects.length === 0) {
        return;
      }
      const lastIndex = getObjects.length - 1;
      const waterMark = getObjects[lastIndex];
      if (waterMark && waterMark.resource_type === ResourceType.waterMark) {
        waterMark.visible = false;
        this.isRemovedWaterMark = true;
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'removeWaterMark()', this.app, true);
    }
  }

  /**
   * 캔버스가 들어 있는 rootCanvasGroup을 체크하고 없으면 만들어주는 함수
   * 휴지통 있는지 체크하고 없으면 만들어주는 함수
   * (2022년9월5일 기준 사용하지 않지만 버그 발생시 핫픽스로 넣어줘야해서 남겨뒀습니다.)
   * @param {number}userId 유저 아이디
   * @return {Promise<void>}
   */
  public async checkAndSetDefaultCanvasGroups(userId: number): Promise<void> {
    try {
      const isRecycleBinExist = await this.cutList.canvasGroupService.checkRecycleBinGroup(userId);
      if (!isRecycleBinExist) {
        await this.cutList.canvasGroupService.createRecycleBinGroup(userId);
      }
      const isRootCanvasGroupExist = await this.cutList.canvasGroupService.checkRootCanvasGroup(userId);
      if (!isRootCanvasGroupExist) {
        await this.cutList.canvasGroupService.createRootCanvasGroup(userId);
      }
      const key = `${userId}_setRootCanvasGroup`;
      const isSynced: string = window.localStorage.getItem(key);
      if (isSynced !== 'synced') {
        const loading = new Loading();
        try {
          await this.app.showToast(this.translate.instant('warningMessage01'), 3000);
          await loading.showLoader();
          await this.cutList.canvasGroupService.setRootCanvasGroup(userId);
          await this.cutList.canvasGroupService.setRootCanvasGroupToCanvas(userId);
          window.localStorage.setItem(key, 'synced');
        } catch (e) {
          console.error(e);
        } finally {
          loading.hideLoader();
        }
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'checkAndSetDefaultCanvasGroups()');
    }
  }

  /**
   * 캔버스 위에 여러 리소스가 있고, 멀티셀렉이 되었을 때 선택된 오브젝트의 리소스를 반환한다.
   * @param multiSelectObList 멀티 셀렉된 오브젝트의 배열
   * @return {array} 선택된 오브젝트들의 index 번호를 담은 배열
   */
  getMultiSelectObIndex(multiSelectObList): Array<number> {
    const allObject = this.canvas.getObjects();
    const MultiSeletecObIndex = [];
    for (let i = 0; i < allObject.length - 1; i++) {
      for (let j = 0, k = multiSelectObList.length; j < k; j++) {
        if (allObject[i].resource_selection_id === multiSelectObList[j].resource_selection_id) {
          MultiSeletecObIndex.push(this.getIndex(allObject[i]));
        }
      }
    }
    return MultiSeletecObIndex;
  }

  /**
   * input 태그를 클릭했을 때, 인풋 태그 내용을 전체 선택(블럭처리)
   * @param event 클릭 이벤트
   * @return void
   */
  selectInputValue(event: any): void {
    if (event.target.select) {
      // tapped directly on html input
      event.target.select();
    } else if (event.target.children[0].select) {
      // tapped on the wrapper
      event.target.children[0].select();
    }
  }

  /**
   * cut4-list을 진입할 때 편집툴에서 진입했는지 확인을 도와주는 함수
   */
  isEditorUrl() {
    return this.router.url.includes('4cut-make-manual2');
  }

  /**
   * 리소스 추가할 때 어디 위치(인덱스)로 배치할지 알려주는 함수
   *   - 기본 규칙 : 최상단
   *   - 배경 : 배경 있을 경우 가장 위쪽 배경리소스 바로 위, 없으면 최하단
   *   - 말풍선 : 텍스트 있을 경우 가장 위쪽 텍스트의 바로 아래, 없으면 최상단
   * @param {string} sourceType
   * @return {IndexInfo} 리턴 할 인덱스 정보
   * @private
   */
  getIndexInfo(sourceType: string): IndexInfo {
    const data: IndexInfo = {
      index: null,
      left: null,
      top: null
    };
    const isBackground = sourceType === SourceType.background;
    let obList: Array<TooningFabricObject>, compareTypes: Array<ResourceType>;

    try {
      obList = this.canvas.getObjects();
      compareTypes = this.setResourceTypes(sourceType);

      for (let i = obList.length - 1; i >= 0; i--) {
        // 캔버스에 같은 타입 리소스 있는지 체크
        let isSameTypeResource: boolean = compareTypes.includes(obList[i].resource_type);
        if (isBackground && obList[i].resource_type === ResourceType.Group) {
          isSameTypeResource = compareTypes.includes((obList[i]._objects[0] as TooningFabricObject).resource_type);
        }

        if (isSameTypeResource) {
          data.index = isBackground ? i + 1 : i;
          if (sourceType === SourceType.balloon) {
            data.left = obList[i].left;
            data.top = obList[i].top;
          }

          break;
        }

        if (isBackground && i === 0) data.index = 0;
      }
      return data;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'getIndexInfo()', this.app, true);
    }
  }

  /**
   * 비교 할 리소스타입 배열 생성
   * @param {string} sourceType
   * @return {Array<ResourceType>}
   */
  setResourceTypes(sourceType: string): Array<ResourceType> {
    switch (sourceType) {
      case SourceType.background:
        return [ResourceType.background, ResourceType.backgroundSvg];
      case SourceType.balloon:
        return [ResourceType.text];
      default:
        return [];
    }
  }

  /**
   *  요소를 구성하는 모든 하위 요소들의 strokeUniform 의 상태 변경
   * @param {TooningFabricObject} object 최상위 요소
   * @param {boolean} isNotBackground 배경리소스인지 아닌지
   * @return {void}
   */
  setStrokeUniform(object: TooningFabricObject, isNotBackground: boolean): void {
    object.strokeUniform = isNotBackground;

    if (object.hasOwnProperty('_objects')) {
      object._objects.forEach((ob: TooningFabricObject) => {
        if (ob.resource_type) this.setStrokeUniform(ob, isNotBackground);
      });
    }
  }

  // FeatureLimit 안내 팝업
  async featureLimitAlertController() {
    this.popAlert = await this.alertController.create({
      header: this.translate.instant('limit.header'),
      message: this.translate.instant('limit.message'),
      cssClass: 'basic-dialog',
      buttons: [
        {
          text: this.translate.instant('HEADER'),
          handler: (data) => {}
        }
      ]
    });
    this.popAlert.onDidDismiss().then(async (data) => {
      this.popAlert = null;
    });
    await this.popAlert.present();
  }

  /**
   * 붙여넣을 텍스트를 canvas에 추가하는 함수
   * @param {string} pasteText 붙여넣을 텍스트
   * @return {Promise<fabric.Textbox>}
   */
  async getOnCreateAddPasteText(pasteText: string): Promise<fabric.Textbox> {
    return new Promise(async (resolve, reject) => {
      try {
        // @ts-ignore
        const textbox = new fabric.Textbox(pasteText, {
          originX: 'center',
          originY: 'center',
          left: LEFT,
          top: TOP,
          width: 810,
          fontFamily: globalLanguageFont.get(this.app.usedLanguage),
          textAlign: 'center',
          fill: 'rgba(0,0,0,1)',
          fontSize: 100,
          fontStyle: 'normal',
          stroke: 'rgba(0,0,0,1)',
          strokeWidth: 0,
          paintFirst: 'stroke',
          splitByGrapheme: !this.laboratory.splitByWord,
          isEditing: false
        });
        // @ts-ignore
        textbox.set('breakWords', 'true'); // 좌우로 줄어들게 하는 코드
        // @ts-ignore
        textbox.set('resource_type', 'text');
        // @ts-ignore
        textbox.set('resource_preview', '/assets/4cut-make-manual/text_icon.svg');
        // @ts-ignore
        textbox.set('resource_name', ResourceName.textBox);
        // @ts-ignore
        textbox.set('userInputText', '');
        // @ts-ignore
        textbox.set('textHistory', {
          fontFamily: globalLanguageFont.get(this.app.usedLanguage),
          fontWeight: 'normal',
          fontStyle: 'normal',
          underline: false,
          fill: 'rgba(0,0,0,1)',
          strokeWidth: 0,
          stroke: 'rgba(0,0,0,1)',
          fontSize: 100
        });
        this.canvas.add(textbox);
        // tslint:disable-next-line:variable-name
        const ob_scaleX = textbox.get('scaleX');
        // tslint:disable-next-line:variable-name
        const ob_scaleY = textbox.get('scaleY');
        textbox.set('scaleX', ob_scaleX + 0.3);
        textbox.set('scaleY', ob_scaleY + 0.3);
        textbox.set('opacity', 0);
        this.assignmentID(textbox);
        textbox.animate(
          { scaleX: ob_scaleX, scaleY: ob_scaleY, opacity: 1 },
          {
            duration: 250,
            easing: fabric.util.ease.easeInOutSine,
            onChange: this.canvas.requestRenderAll.bind(this.canvas),
            onComplete() {
              resolve(textbox);
            }
          }
        );
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * 스타일이 있는 붙여넣을 텍스트를 canvas에 추가하는 함수
   * @param {object} pasteObj 붙여넣을 텍스트 정보
   * @return {Promise<fabric.Textbox>}
   */
  async getOnCreateAddPasteTextWithStyle(pasteObj: object): Promise<fabric.Textbox> {
    return new Promise(async (resolve, reject) => {
      try {
        // @ts-ignore
        const copyWidth = pasteObj.width;
        // @ts-ignore
        const copySplitByGrapheme = pasteObj.splitByGrapheme;
        // @ts-ignore
        const textbox = new fabric.Textbox(pasteObj.text, {
          originX: 'center',
          originY: 'center',
          left: LEFT,
          top: TOP,
          width: copyWidth,
          fontFamily: globalLanguageFont.get(this.app.usedLanguage),
          textAlign: 'center',
          fill: 'rgba(0,0,0,1)',
          fontSize: 100,
          fontStyle: 'normal',
          stroke: 'rgba(0,0,0,1)',
          strokeWidth: 0,
          paintFirst: 'stroke',
          splitByGrapheme: copySplitByGrapheme,
          isEditing: false
        });
        // @ts-ignore
        textbox.set('breakWords', 'true'); // 좌우로 줄어들게 하는 코드
        // @ts-ignore
        textbox.set('resource_type', 'text');
        // @ts-ignore
        textbox.set('resource_preview', '/assets/4cut-make-manual/text_icon.svg');
        // @ts-ignore
        textbox.set('resource_name', ResourceName.textBox);
        // @ts-ignore
        textbox.set('userInputText', '');
        // @ts-ignore
        textbox.set('textHistory', {
          fontFamily: globalLanguageFont.get(this.app.usedLanguage),
          fontWeight: 'normal',
          fontStyle: 'normal',
          underline: false,
          fill: 'rgba(0,0,0,1)',
          strokeWidth: 0,
          stroke: 'rgba(0,0,0,1)',
          fontSize: 100
        });
        this.canvas.add(textbox);

        // @ts-ignore
        const scaleX = pasteObj.scaleX;
        // @ts-ignore
        const scaleY = pasteObj.scaleY;

        textbox.set('scaleX', scaleX);
        textbox.set('scaleY', scaleY);
        textbox.set('opacity', 0);

        this.assignmentID(textbox);
        textbox.animate(
          { scaleX: scaleX, scaleY: scaleY, opacity: 1 },
          {
            duration: 250,
            easing: fabric.util.ease.easeInOutSine,
            onChange: this.canvas.requestRenderAll.bind(this.canvas),
            onComplete() {
              resolve(textbox);
            }
          }
        );

        // 스타일 적용
        // @ts-ignore
        pasteObj.styles.map((style, i) => {
          textbox.setSelectionStyles(style, i, i + 1);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * 붙여넣을 텍스트 캔버스에 추가하는 command 호출 함수
   * @param {string} pasteText 붙여넣을 텍스트
   * @return {Promise<void>}
   */
  async onCreateAddPasteText(pasteText: string): Promise<void> {
    try {
      // 붙여넣을 텍스트가 jsonParse 불가능하면서 길이가 500자 이상이면
      if (pasteText.length > TEXT_LIMIT_LENGTH) {
        await this.app.showToast(this.translate.instant('text over'));
        return;
      }

      const command = new ObjectAddCommand({
        cut: this,
        callback: async () => {
          const pasteTextInfo = JSON.parse(this.app.cache.getCopyText());

          if (pasteTextInfo && pasteTextInfo.text === pasteText) {
            return await this.getOnCreateAddPasteTextWithStyle(pasteTextInfo);
          } else {
            this.app.cache.removeCopyText();
            return await this.getOnCreateAddPasteText(pasteText);
          }
        }
      });
      await this.commandManager.executeCommand(command);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'onCreateAddPasteText()', this.app);
    }
  }

  /**
   * 스냅기능 on하는 함수
   * @param {fabric.Canvas} canvas 적용할 캔버스
   * @param {boolean} status on/off를 통제할 flag
   * @return {void}
   */
  initAligningGuidelines(canvas: fabric.Canvas, status: boolean): void {
    try {
      let ctx = canvas.getSelectionContext();
      let aligningLineOffset = 1;
      let aligningLineMargin = 5;
      let aligningLineWidth = 1;
      let aligningLineColor = 'rgb(254,146,0)';
      let viewportTransform;
      let verticalLines = [];
      let horizontalLines = [];
      let centerLines = [];

      /**
       * 수직 스냅선 그리는 함수
       * @param {SnapVerticalCoords} coords 선을 그릴 기준 좌표
       * @return {void}
       */
      function drawVerticalLine(coords: SnapVerticalCoords): void {
        drawLine(coords.x + 0.5, coords.y1 > coords.y2 ? coords.y2 : coords.y1, coords.x + 0.5, coords.y2 > coords.y1 ? coords.y2 : coords.y1);
      }

      /**
       * 수평 스냅선 그리는 함수
       * @param {SnapHorizontalCoords} coords 선을 그릴 기준 좌표
       * @return {void}
       */
      function drawHorizontalLine(coords: SnapHorizontalCoords): void {
        drawLine(coords.x1 > coords.x2 ? coords.x2 : coords.x1, coords.y + 0.5, coords.x2 > coords.x1 ? coords.x2 : coords.x1, coords.y + 0.5);
      }

      /**
       * 선을 그리는 함수
       * @param {number} x1 첫번째 기준 x 좌표
       * @param {number} y1 첫번째 기준 y 좌표
       * @param {number} x2 두번째 기준 x 좌표
       * @param {number} y2 두번째 기준 y 좌표
       * @param {boolean} center 중앙 가이드라인을 그리는지 아닌리 flag
       * @return {void}
       */
      function drawLine(x1: number, y1: number, x2: number, y2: number, center: boolean = false): void {
        const v = canvas.viewportTransform;
        ctx.save();
        //@ts-ignore
        ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
        ctx.lineWidth = aligningLineWidth;
        ctx.strokeStyle = aligningLineColor;
        ctx.beginPath();
        if (!center) {
          ctx.setLineDash([8, 4]);
        }
        //@ts-ignore
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        //@ts-ignore
        ctx.stroke();
        ctx.restore();
      }

      /**
       * 스냅 범위(5픽셀)내에 존재하는지 판단하는 함수
       * @param {number} value1 비교할 두 포인트 중 첫번째 값
       * @param {number} value2 비교할 두 포인트 중 두번째 값
       * @return {boolean} 비교대상이 서로 범위내에 있으면 true, 아니면 false
       */
      function isInRange(value1: number, value2: number): boolean {
        value1 = Math.round(value1);
        value2 = Math.round(value2);
        for (let i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
          if (i === value2) {
            return true;
          }
        }
        return false;
      }

      this.objectEventHandlers.objectSnap = (event) => {
        let activeObject = event.target,
          canvasObjects = canvas.getObjects(),
          activeObjectCenter = activeObject.getCenterPoint(),
          activeObjectLeft = activeObjectCenter.x,
          activeObjectTop = activeObjectCenter.y,
          activeObjectBoundingRect = activeObject.getBoundingRect(),
          activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3],
          activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
          //@ts-ignore
          transform = canvas._currentTransform;
        if (!transform) {
          return;
        }

        // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
        // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

        for (let i = canvasObjects.length; i--; ) {
          let objectCenter = canvasObjects[i].getCenterPoint(),
            objectLeft = objectCenter.x,
            objectTop = objectCenter.y,
            objectBoundingRect = canvasObjects[i].getBoundingRect(),
            objectHeight = objectBoundingRect.height / viewportTransform[3],
            objectWidth = objectBoundingRect.width / viewportTransform[0];

          // 선택된 오브젝트면 아무것도 안하고 패스
          //@ts-ignore
          if (canvasObjects[i] === activeObject || canvasObjects[i].resource_type === ResourceType.bgColor) {
          }

          // 가이드마스크라면 중앙 라인만 표시
          //@ts-ignore
          else if (canvasObjects[i].resource_type === ResourceType.guideMask) {
            // horizontal center line
            if (isInRange(objectLeft, activeObjectLeft)) {
              centerLines.push({
                x1: objectLeft,
                y1: objectTop - objectHeight / 2 - aligningLineOffset,
                x2: objectLeft,
                y2: objectTop + objectHeight / 2 + aligningLineOffset
              });
              activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
            }
            // vertical center line
            if (isInRange(objectTop, activeObjectTop)) {
              centerLines.push({
                x1: objectLeft - objectWidth / 2 - aligningLineOffset,
                y1: objectTop,
                x2: objectLeft + objectWidth / 2 + aligningLineOffset,
                y2: objectTop
              });
              activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
            }
          }

          // 그 외 오브젝트면 상하좌우 라인 모두 표시
          else {
            // snap by the horizontal center line
            if (isInRange(objectLeft, activeObjectLeft)) {
              verticalLines.push({
                x: objectLeft,
                y1:
                  objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
                y2:
                  activeObjectTop > objectTop
                    ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                    : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
            }

            // snap by the vertical center line
            if (isInRange(objectTop, activeObjectTop)) {
              horizontalLines.push({
                y: objectTop,
                x1:
                  objectLeft < activeObjectLeft
                    ? objectLeft - objectWidth / 2 - aligningLineOffset
                    : objectLeft + objectWidth / 2 + aligningLineOffset,
                x2:
                  activeObjectLeft > objectLeft
                    ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                    : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
            }

            // object left + activeobject left(왼,왼)
            if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
              verticalLines.push({
                x: objectLeft - objectWidth / 2,
                y1:
                  objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
                y2:
                  activeObjectTop > objectTop
                    ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                    : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(
                new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop),
                'center',
                'center'
              );
            }

            // object left + activeobject right(왼,오)
            if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
              verticalLines.push({
                x: objectLeft - objectWidth / 2,
                y1:
                  objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
                y2:
                  activeObjectTop > objectTop
                    ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                    : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(
                new fabric.Point(objectLeft - objectWidth / 2 - activeObjectWidth / 2, activeObjectTop),
                'center',
                'center'
              );
            }

            // object right + activeobject right(오,오)
            if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
              verticalLines.push({
                x: objectLeft + objectWidth / 2,
                y1:
                  objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
                y2:
                  activeObjectTop > objectTop
                    ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                    : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(
                new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop),
                'center',
                'center'
              );
            }

            // object right + activeobject left(오,왼)
            if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
              verticalLines.push({
                x: objectLeft + objectWidth / 2,
                y1:
                  objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
                y2:
                  activeObjectTop > objectTop
                    ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                    : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(
                new fabric.Point(objectLeft + objectWidth / 2 + activeObjectWidth / 2, activeObjectTop),
                'center',
                'center'
              );
            }

            // object top + activeobject top(위,위)
            if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
              horizontalLines.push({
                y: objectTop - objectHeight / 2,
                x1:
                  objectLeft < activeObjectLeft
                    ? objectLeft - objectWidth / 2 - aligningLineOffset
                    : objectLeft + objectWidth / 2 + aligningLineOffset,
                x2:
                  activeObjectLeft > objectLeft
                    ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                    : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(
                new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2),
                'center',
                'center'
              );
            }

            // object top + activeobject bottom(위,아래)
            if (isInRange(objectTop - objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
              horizontalLines.push({
                y: objectTop - objectHeight / 2,
                x1:
                  objectLeft < activeObjectLeft
                    ? objectLeft - objectWidth / 2 - aligningLineOffset
                    : objectLeft + objectWidth / 2 + aligningLineOffset,
                x2:
                  activeObjectLeft > objectLeft
                    ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                    : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(
                new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 - activeObjectHeight / 2),
                'center',
                'center'
              );
            }

            // object bottom + activeobject bottom(아래,아래)
            if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
              horizontalLines.push({
                y: objectTop + objectHeight / 2,
                x1:
                  objectLeft < activeObjectLeft
                    ? objectLeft - objectWidth / 2 - aligningLineOffset
                    : objectLeft + objectWidth / 2 + aligningLineOffset,
                x2:
                  activeObjectLeft > objectLeft
                    ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                    : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(
                new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2),
                'center',
                'center'
              );
            }

            // object bottom + activeobject top(아래,위)
            if (isInRange(objectTop + objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
              horizontalLines.push({
                y: objectTop + objectHeight / 2,
                x1:
                  objectLeft < activeObjectLeft
                    ? objectLeft - objectWidth / 2 - aligningLineOffset
                    : objectLeft + objectWidth / 2 + aligningLineOffset,
                x2:
                  activeObjectLeft > objectLeft
                    ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                    : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
              });
              activeObject.setPositionByOrigin(
                new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 + activeObjectHeight / 2),
                'center',
                'center'
              );
            }
          }
        }
      };

      this.objectEventHandlers.beforeSnap = (event) => {
        viewportTransform = canvas.viewportTransform;
      };

      this.objectEventHandlers.clearSnap = (event) => {
        canvas.clearContext(ctx);
      };

      this.objectEventHandlers.drawSnap = (event) => {
        for (let i = verticalLines.length; i--; ) {
          drawVerticalLine(verticalLines[i]);
        }
        for (let i = horizontalLines.length; i--; ) {
          drawHorizontalLine(horizontalLines[i]);
        }
        for (let i = centerLines.length; i--; ) {
          drawLine(centerLines[i].x1, centerLines[i].y1, centerLines[i].x2, centerLines[i].y2, true);
        }
        verticalLines.length = horizontalLines.length = centerLines.length = 0;
      };

      this.objectEventHandlers.afterSnap = (event) => {
        verticalLines.length = horizontalLines.length = centerLines.length = 0;
        canvas.renderAll();
      };

      canvas.on('mouse:down', this.objectEventHandlers.beforeSnap);
      canvas.on('before:render', this.objectEventHandlers.clearSnap);
      canvas.on('object:moving', this.objectEventHandlers.objectSnap);
      canvas.on('after:render', this.objectEventHandlers.drawSnap);
      canvas.on('mouse:up', this.objectEventHandlers.afterSnap);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'initAligningGuidelines()', null, true);
    }
  }

  /**
   * 텍스트의 스타일 정보를 로컬 스토리지에 저장하는 함수
   * @return {void}
   */
  saveCopyTextInfo(): void {
    try {
      const copyObj = this.selectedOb;
      const copyStart = copyObj.selectionStart;
      const copyEnd = copyObj.selectionEnd;
      const copyText = copyObj.text.substring(copyStart, copyEnd);
      const copyStyles = copyObj.getSelectionStyles(copyStart, copyEnd);
      const copyScaleX = copyObj.scaleX;
      const copyScaleY = copyObj.scaleY;
      const copyWidth = copyObj.width;
      const copySplitByGrapheme = this.selectedOb.splitByGrapheme;

      const textInfo = {
        text: copyText,
        styles: copyStyles,
        scaleX: copyScaleX,
        scaleY: copyScaleY,
        width: copyWidth,
        spilitByGrapheme: copySplitByGrapheme
      };

      this.app.cache.setCopyText(JSON.stringify(textInfo));
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'saveCopyTextInfo()', this.app, true);
    }
  }

  /**
   * 단어 단위로 줄 바꿈 기능을 on/off
   * 1. 각 텍스트 박스의 설정 창에 기능 on/off 토글 표시
   * 2. 새로 추가되는 텍스트박스의 기능 설정
   * 3. on 상태에서 off할 경우, 작업물 전체 텍스트 박스의 기능을 off
   * @return {Promise<void>}
   */
  async onSplitByWord(): Promise<void> {
    try {
      const loading = new Loading();
      this.app.isIonBackdrop = true;
      await loading.showLoader();

      if (this.laboratory.splitByWord === false) {
        const presentIdx = this.panelIndex;
        await this.updatePage(presentIdx);

        for (let i = 0; i < this.pageList.length; i++) {
          await this.goPage(i, true);
          const objs = this.canvas.getObjects();
          for (let obj of objs) {
            this.offSplitByWord(obj);
          }
          await this.updatePageSync(i, true);
        }

        await this.goPage(presentIdx, true);
      }
      this.saveLaboratoryStatus();
      this.app.isIonBackdrop = false;
      loading.hideLoader();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'onSplitByWord()');
    }
  }

  /**
   * 각 텍스트 박스의 단어 단위 줄바꿈 기능 on/off
   * @param {fabric.Textbox} ob 기능을 적용할 텍스트 박스
   * @return {void}
   */
  changeSplitByWord(ob: fabric.Textbox): void {
    try {
      this.app.delay(100).then(async (r) => {
        const command = new ObjectChangeCommand({
          cut: this,
          target: this.selectedOb,
          callback: async () => {
            ob.splitByGrapheme = !this.isObjSplitByWord;
            // property 변경 후, 뷰에서도 변경된 것을 바로 확인하기 위해 width를 늘렸다 줄임
            ob.set('width', this.selectedOb.width + 0.01);
            setTimeout(() => {
              ob.set('width', this.selectedOb.width - 0.01);
              this.canvas.fire('changed');
              this.canvas.requestRenderAll();
              this.updatePageSync(this.panelIndex, true);
            }, 10);
            return this.selectedOb;
          }
        });
        await this.commandManager.executeCommand(command);
      });
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'changeSplitByWord()');
    }
  }

  /**
   * 해당 오브젝트의 단어 단위 줄 바꿈 속성을 무조건 off
   * @param { any } obj 페이지 내 단일 또는 그룹 오브젝트
   * @return { void }
   */
  offSplitByWord(objs: any): void {
    if (objs._objects) {
      for (let obj of objs._objects) {
        this.offSplitByWord(obj);
      }
    } else if (objs.resource_type === ResourceType.text && objs.splitByGrapheme === false) {
      this.objectEventSetupOff();
      this.canvasHistoryEventSetupOff();
      objs.splitByGrapheme = true;
      objs.set('width', objs.width + 0.01);
      setTimeout(() => {
        objs.set('width', objs.width - 0.01);
        this.canvas.requestRenderAll();
      }, 10);
      this.objectEventSetup();
      this.canvasHistoryEventSetup();
    }
  }

  /**
   * 캔버스의 이전 이미지 위로 보이도록 하는 함수
   * @returns void
   */

  setShowPreviewCanvas(isSaveStatus = true) {
    try {
      if (!this.laboratory.showPreview || this.panelIndex === 0 || this.canvas === null) {
        this.isShowPreviewCanvas = false;
        return;
      }
      const zoom = this.canvas.getZoom();
      const nativeElement = this.showPreviewCanvasView.nativeElement;
      const top = this.canvas.viewportTransform[5] + ((1080 - this.canvasSize.h) / 2) * zoom;
      const left = this.canvas.viewportTransform[4] + ((1080 - this.canvasSize.w) / 2) * zoom;
      const width = this.canvasSize.w * zoom;
      const height = this.canvasSize.h * zoom;
      this.renderer.setStyle(nativeElement, 'top', top + 'px');
      this.renderer.setStyle(nativeElement, 'left', left + 'px');
      this.renderer.setStyle(nativeElement, 'width', width + 'px');
      this.renderer.setStyle(nativeElement, 'height', height + 'px');
      this.previewCanvasViewSrc = this.pageList[this.panelIndex - 1].img;
      this.isShowPreviewCanvas = true;

      isSaveStatus && this.saveLaboratoryStatus();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 그룹화된 리소스가 텍스트일 경우 윤곽선 두께 유지 함수 호출
   * @param {TooningFabricObject} ob 그룹화 되어있는 리소스
   * @return {void}
   */
  setGroupResourceTextStrokeWidth(ob: TooningFabricObject): void {
    try {
      const objects = ob._objects;
      const selectedObScaleX = this.selectedOb.scaleX ? this.selectedOb.scaleX : 1;

      objects.forEach((resource: any) => {
        if (resource.resource_type === ResourceType.text) {
          this.groupResourceKeepStrokeWidthStyle(resource, selectedObScaleX);
        } else if (resource.resource_type === SelectedType.largeGroup) {
          this.setGroupResourceTextStrokeWidth(resource);
        }
      });
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'setGroupResourceTextStrokeWidth()', this.app);
    }
  }

  /**
   * 그룹화된 리소스 사이즈 조절시 윤곽선 두께 유지
   * @param {fabric.Textbox} ob 그룹화 되어있는 텍스트 오브젝트
   * @param {number} selectedObScaleX 그룹화한 리소스의 scaleX 값
   * @return {void}
   */
  groupResourceKeepStrokeWidthStyle(ob: fabric.Textbox, selectedObScaleX: number): void {
    try {
      if (typeof selectedObScaleX !== InputType.number) {
        selectedObScaleX = 1;
      }
      const styles = ob.getSelectionStyles(0, ob._text.length);
      styles.map((style, i) => {
        let width;
        const fontSize = ob.scaleX;
        if (style?.hasOwnProperty(TextStyleType.StrokeWidth)) {
          width = style.width ? style.width : Math.round(style.strokeWidth * fontSize * selectedObScaleX);

          ob.setSelectionStyles(
            {
              strokeWidth: width / fontSize / selectedObScaleX
            },
            i,
            i + 1
          );
        }
      });
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'groupResourceKeepStrokeWidthStyle()', this.app);
    }
  }

  getImageObject(selectedOb): any {
    const isV2 = selectedOb.etcUploadVersion === Version.v2;
    let selectedObject = selectedOb;
    if (isV2) {
      selectedObject = selectedOb.item(0);
      if (selectedOb.resource_filterType === undefined) {
        selectedOb.resource_filterType = 'Normal';
      }
      if (selectedOb.filters === undefined) {
        selectedOb.filters = selectedObject.filters;
      }
    }
    return selectedObject;
  }

  /**
   * 단축키 모달
   * @param {Event} [event] click event
   * @return {Promise<void>}
   */
  async openModalHotKey(event?: Event): Promise<void> {
    try {
      if (this.app.modal !== null) {
        return;
      }
      this.app.modal = true;

      this.app.modal = await this.modalCtrl.create({
        component: HotkeyComponent,
        componentProps: {},
        cssClass: 'modal-size-vertical'
      });
      this.app.modal.onDidDismiss().then(async (data) => {
        this.app.modal = null;
        this.setKeyActivation = true;
      });
      this.setKeyActivation = false;
      await this.app.modal.present();
    } catch (e) {
      throw new TooningCustomClientError(e.message + '_openModalHotKey', FILE_NAME, 'openModalHotKey()');
    }
  }

  /**
   * 셋팅 모달
   * @param {Event} [event] click event
   * @return {Promise<void>}
   */
  async openModalEditerSetting(event?: Event): Promise<void> {
    try {
      if (this.app.modal !== null) {
        return;
      }
      this.app.modal = true;

      this.app.modal = await this.modalCtrl.create({
        component: EditerSettingModalComponent,
        componentProps: {
          cut: this
        },
        cssClass: 'modal-size-vertical'
      });
      this.app.modal.onDidDismiss().then(async (data) => {
        this.app.modal = null;
        this.setKeyActivation = true;
      });
      this.setKeyActivation = false;
      await this.app.modal.present();
    } catch (e) {
      throw new TooningCustomClientError(e.message + '_EditerSettingModalComponent', FILE_NAME, 'openModalEditerSetting()');
    }
  }

  /**
   * 실험실 모달
   * @param {Event} [event] click event
   * @return {Promise<void>}
   */
  async openModalLaboratory(event?: Event): Promise<void> {
    try {
      if (this.app.modal !== null) {
        return;
      }
      this.app.modal = true;

      this.app.modal = await this.modalCtrl.create({
        component: LaboratoryModalComponent,
        componentProps: {
          cut: this
        },
        cssClass: 'modal-size-vertical'
      });
      this.app.modal.onDidDismiss().then(async (data) => {
        this.app.modal = null;
        this.setKeyActivation = true;
      });
      this.setKeyActivation = false;
      await this.app.modal.present();
    } catch (e) {
      throw new TooningCustomClientError(e.message + '_LaboratoryModalComponent', FILE_NAME, 'openModalLaboratory()');
    }
  }

  /**
   * 캔버스 복제 함수
   * @return {Promise<void>}
   */
  async canvasClone(CanvasId): Promise<void> {
    if (!this.app.isPaidMember) {
      // 무료 사용자면 캔버스 수 체크
      let { data } = await this.canvasService.canvasesCount(+this.app.cache.user.id).toPromise();
      let getCanvasesLength: number = data;
      const toastMessage = this.translate.instant('cut4-list.total') + getCanvasesLength + this.translate.instant('cut4-list.checkRecycleBin');

      if (getCanvasesLength >= this.freeUserCanvasLimit) {
        this.dialog.open(PaidReferralComponent, {
          width: this.app.isDesktopWidth() ? '440px' : '90%',
          height: this.app.isDesktopWidth() ? '600px' : '80%',
          data: {
            message: this.translate.instant('3'),
            message2: toastMessage
          }
        });
        return;
      }
    }

    const loading = new Loading();
    await loading.showLoader('');
    try {
      const isTextTemplate = this.app.cache.user.role === UserRole.textTemplate;
      if (this.cutList.isCanvasGroupView) {
        await this.canvasService.canvasClone(+CanvasId, +this.app.cache.user.id, isTextTemplate, +this.cutList.canvasGroupId);
      } else {
        await this.canvasService.canvasClone(+CanvasId, +this.app.cache.user.id, isTextTemplate);
      }
      await this.app.showToast(this.translate.instant('cloneSuccess'));
    } catch (error) {
      await this.app.showToast(this.translate.instant('cloneFail'));
      throw new TooningCustomClientError(error, FILE_NAME, 'canvasClone()');
    } finally {
      loading.hideLoader();
      this.app.analyticsService.canvasClone();
    }
  }

  /**
   * 준비되지 않은 캐릭터 클릭시 캔버스에 추가 안되게 막는 함수
   * @param item 검수할 캐릭터
   * @return {Promise<boolean>}
   */
  async checkRejectCharacterAddingCanvas(item: any): Promise<boolean> {
    const isReject = item.name_ko.includes('미팅용');
    // 어드민 뷰인 캐릭터중 캔버스에 추가하고 싶지 않은 캐릭터 걸러내는 코드
    if (isReject) {
      // 디자인팀과 얘기해서 캐릭터 한국어 이름에 미팅용이라는 단어가 있으면 캔버스에 추가안되고 알림창 띄워줌
      this.popAlert = await this.alertController.create({
        header: '준비중인 캐릭터입니다.', // 어드민 전용이라 한국어만 추가
        mode: 'ios',
        cssClass: 'info-dialog',
        buttons: [
          {
            text: this.translate.instant('header'),
            handler: () => {}
          }
        ]
      });
      this.popAlert.onDidDismiss().then(async (data) => {
        this.popAlert = null;
      });
      await this.popAlert.present();
    }
    return isReject;
  }

  /**
   * 색상 변경 배경 리소스 추가하는 함수
   * @return {Promise<void>}
   */
  async addBgColor(): Promise<void> {
    try {
      if (this.canvas._objects[0].resource_type !== ResourceType.bgColor) {
        // @ts-ignore
        await this.addObFromJson([bgColorObjJson.default], false, false, true, null, 0);
        // 배경색과 캔버스의 사이즈가 다를 경우 사이즈 변경
        this.setBgColorSize();
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'addBgColor()');
    }
  }

  /**
   * 배경색과 캔버스의 사이즈가 다를 경우 사이즈 변경
   * @return {void}
   */
  setBgColorSize(): void {
    try {
      if (!this.canvas._objects.length || !this.canvas._objects) {
        logCustomErrorMessage('canvas.objects Empty', 'canvas.objects Empty', '', FILE_NAME, 'setBgColorSize()', true, this.app.user.getUserEmail());
      }

      const background = this.canvas._objects[0];

      if (background.resource_type !== ResourceType.bgColor) {
        this.isBackgroundColorMode = false;
        this.bgColorPick = 'none';
        return;
      }

      if (background.width !== this.canvasSize.w || background.height !== this.canvasSize.h) {
        background.set('width', this.canvasSize.w);
        background.set('height', this.canvasSize.h);

        this.canvas.fire('object:modified');
        this.canvas.requestRenderAll();
      }

      this.isBackgroundColorMode = true;
      this.bgColorPick = background.fill;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'setBgColorSize()');
    }
  }

  /**
   * 기능 제약설정
   * @param id {number} 캐릭터 ID
   * @return {Promise<void>}
   */
  public async getFeatureLimit(id: number): Promise<void> {
    try {
      const fetchData = await this.resMakerCharacterService.getCharacterFeatureLimit(+id);
      this.characterFeatureLimit = fetchData.data.getCharacterFeatureLimit;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'getFeatureLimit()');
    }
  }

  /**
   * getresource 과정에서 characterId 가 없을 경우 리소스 삭제 알람과 모달 닫기
   * @return {Promise<void>}
   */
  async isCharacterIdNullModal(): Promise<void> {
    try {
      let errorName = this.selectedOb.resource_name;
      const alert = await this.alertController.create({
        message: this.translate.instant('CharacterNullError'),
        buttons: [
          {
            text: this.translate.instant('confirm'),
            handler: async () => {
              this.canvas.remove(this.selectedOb);
              this.canvas.requestRenderAll();
              await this.modalCtrl.dismiss();
            }
          }
        ]
      });
      alert.onDidDismiss().then(() => {
        throw new TooningCustomClientError(errorName + ' CharacterId 값 없음', FILE_NAME, 'isCharacterIdNullModal()', this.app, true);
      });
      await alert.present();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'isCharacterIdNullModal()', this.app, true);
    }
  }

  /**
   * getresource 과정에서 characterId 가 없을 경우 리소스 삭제 알람
   * @return {Promise<void>}
   */
  async isCharacterIdNull(): Promise<void> {
    try {
      let errorName = this.selectedOb.resource_name;
      const alert = await this.alertController.create({
        message: this.translate.instant('CharacterNullError'),
        buttons: [
          {
            text: this.translate.instant('confirm'),
            handler: async () => {
              this.canvas.remove(this.selectedOb);
              this.canvas.requestRenderAll();
            }
          }
        ]
      });
      alert.onDidDismiss().then(() => {
        throw new TooningCustomClientError(errorName + ' CharacterId 값 없음', FILE_NAME, 'isCharacterIdNull()', this.app, true);
      });
      await alert.present();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'isCharacterIdNull()', this.app, true);
    }
  }

  /**
   * 아트보드 바깥 영역 배경색 변경
   * @param {number} index 바꿀 색 번호. 기본값 1 (#eaeaea)
   * @param {string} color 기타 색상 클릭 시 색 번호(rgb 형태)
   * @return {void}
   */
  async setOutsideBgColor(index: number, color?: string): Promise<void> {
    try {
      this.beforeOutsideBG = _.cloneDeep(this.outsideBG);
      if (index >= 0 && index <= 3) {
        this.outsideBG.color = this.outsideBGs[index].color;
        this.outsideBG.number = index;
        this.canvas.outsideBGColor = this.outsideBG.color;
        this.canvas.requestRenderAll();

        // 서버 업데이트
        const canvas = new Canvas();
        canvas.id = this.newCanvasId;
        canvas.outsideBGColor = this.outsideBG.color;
        await this.canvasService.canvasUpdate(canvas, true).toPromise();
      } else {
        const hexColor = '#' + new fabric.Color(color).toHex();
        this.outsideBG.color = hexColor;
        this.canvas.outsideBGColor = hexColor;
        this.canvas.requestRenderAll();
      }
      this.afterOutsideBG = _.cloneDeep(this.outsideBG);
      const command = new CanvasBgColorChangeCommand({
        cut: this,
        beforeOutsideBG: this.beforeOutsideBG,
        afterOutsideBG: this.afterOutsideBG
      });
      await this.commandManager.executeCommand(command);
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'setOutsideBgColor()', this.app, true);
    }
  }

  /**
   * 컬러피커 팝업 열기 함수
   * @return {void}
   */
  outsideBGColorPopupOpen(): void {
    try {
      this.isClickedOutsideBGColorSettingButton = true;
      let outsideBackground = this.outsideBG.color;
      this.historyBackColor = outsideBackground; // 리셋용
      this.outsideBG.number = 4;
      this.forceNoFocus();
      this.duplicateDeleteColor();
      this.unshiftColor(outsideBackground);
      this.outsideBgColorPick = outsideBackground;
      this.isColorBox = true;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'outsideBGColorPopupOpen()', this.app, true);
    }
  }

  /**
   * 아트보드 바깥 색상 변경시 실행하는 함수
   * @param {any} $event 선택한 색상
   * @param {any} onChange - 최종 변화 감지 - 사용안함
   * @return {Promise<void>}
   */
  async outsideBGchangeColor($event: string, onChange: any): Promise<void> {
    try {
      let color = new fabric.Color($event).toRgba();
      // 변경 되는 컬러는 첫번쨰 히스토리이기 떄문에 index = 0을 변경
      this.historyColores[0] = color;
      this.outsideBgColorPick = color;
      await this.initCanvasColorHistory();
      this.selectColor = color;
      await this.setOutsideBgColor(5, color);

      // 서버 업데이트
      const canvas = new Canvas();
      canvas.id = this.newCanvasId;
      canvas.outsideBGColor = this.outsideBG.color;
      await this.canvasService.canvasUpdate(canvas, true).toPromise();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'outsideBGchangeColor()');
    }
  }

  /**
   * 아트보드 바깥 색 변경 시 기타 색상을 누르면 컬러팝업 정보 전달
   * @return {Promise<void>}
   */
  async outsideBGColorPopup(isContextmenu: boolean): Promise<void> {
    try {
      if (!isContextmenu) {
        this.outsideBGColorPopupOpen();
      } else {
        setTimeout(async () => {
          this.outsideBGColorPopupOpen();
        }, 300);
      }
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'outsideBGColorPopup()');
    }
  }

  /**
   * 닫기 버튼
   * @param {boolean} isCancel true :  colorPicker 가 cancel 로 닫힌 경우이며, historyColores 맨 앞의 색을 하나 뺀다.
   *                           false : colorPicker 가 apply 로 닫힌 경우이며, keyActivation(키보드로로 복사하기, 붙여넣기) 기능을 사용할 수 있게 한다.
   * @return {Promise<void>}
   */
  async closeColor(isCancel: boolean): Promise<void> {
    this.isColorBox = false;
    this.setKeyActivation = true;
    if (isCancel) {
      this.historyColores.shift();
    }
  }

  /**
   * lazy-loading 이미지 로드된 후 실행
   * @param {HTMLImageElement} img 보여줄 이미지
   * @param {HTMLDivElement} placeholder 이미지가 로드되기 전 보여줄 플레이스홀더
   * @return {void}
   */
  afterLoadImage(img: HTMLImageElement, placeholder: HTMLDivElement): void {
    try {
      placeholder.style.animation = 'none';
      img.style.opacity = '1';
      placeholder.style.minHeight = `${img.clientHeight}px`;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'afterLoadImage()', this.app, true);
    }
  }

  /**
   *
   */
  checkIsUndoRedo(): void {
    try {
      this.isUndo = this.commandManagerDrawing.undoHistory.length > 0;
      this.isRedo = this.commandManagerDrawing.redoHistory.length > 0;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 회원가입/구독 시 컨페티 효과 함수
   * @return {void}
   */
  doConfetti(): void {
    try {
      this.confetti.addConfetti();
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'doConfetti()', this.app, true);
    }
  }

  /**
   * 캔버스 개수 초과 안내 모달 보여주기
   * @param {Loading} loading
   * @param {() => void} afterClosedHandler
   * @return {Promise<boolean>}
   */
  async showCanvasCountWarning(loading?: Loading, afterClosedHandler: () => void = null): Promise<boolean> {
    try {
      const { data } = await this.canvasService.canvasesCount(+this.app.cache.user.id).toPromise();
      const getCanvasesLength: number = data;
      this.app.redLog(getCanvasesLength);
      const toastMessage = this.translate.instant('cut4-list.total') + getCanvasesLength + this.translate.instant('cut4-list.checkRecycleBin');
      if (!this.app.isPaidMember && getCanvasesLength >= this.freeUserCanvasLimit) {
        if (loading) loading.hideLoader();

        const dialogRef = this.dialog.open(PaidReferralComponent, {
          width: this.app.isDesktopWidth() ? '440px' : '90%',
          height: this.app.isDesktopWidth() ? '600px' : '80%',
          data: {
            message: this.translate.instant('3'),
            message2: toastMessage
          }
        });

        if (afterClosedHandler !== null) {
          dialogRef.afterClosed().subscribe(afterClosedHandler);
        }

        return true;
      }
      return false;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'showCanvasCountWarning()', this.app, true);
    }
  }

  /**
   * 캔버스보다 리소스 크게 들어가는 경우 리소스의 scale 값 조정
   * 배경 리소스는 무조건 가로 크기에 100% 로 맞춰 들어감
   * @param {TooningFabricObject | fabric.Textbox | fabric.Image | fabric.Object | fabric.Group} ob scale 값 조정 할 리소스
   * @return {void}
   */
  setResourceScale(
    ob: TooningFabricObject | fabric.Textbox | fabric.Image | fabric.Object | fabric.Group,
    applyScale: number = this.ALMOST_SIZE,
    sourceType: SourceType = null
  ): void {
    let realOb: TooningFabricObject = ob as TooningFabricObject,
      newScaleX: number = ob.scaleX,
      newScaleY: number = ob.scaleY,
      obScaleX: number = 1,
      obScaleY: number = 1,
      newLeftAdd: number = 0,
      newTopAdd: number = 0;

    try {
      const isBackground: boolean = sourceType === SourceType.background;
      const isGroupBackground: boolean = isBackground && realOb.resource_type === ResourceType.Group;
      const maxWidth: number = this.guideMask.width * applyScale;
      const maxHeight: number = this.guideMask.height * applyScale;

      if (isGroupBackground) {
        // 그룹 배경일 경우 실제 배경으로 계산하기 위한 세팅
        realOb = realOb.objects[0];
        obScaleX = ob.scaleX;
        obScaleY = ob.scaleY;
      }

      // else if 로 할 경우 계산 잘못되는 경우 있음
      // 캔버스 기본 1080 x 1080 사용하고
      // 리소스의 width height 가 300 200
      // 기본 scale 이 10 10 인 상황이 있다고 가정하면
      // 위 세로값 비교해서 계산 했을때 newScale 값은
      // 1080 * 0.9 / 200 = 4.86
      // 이 값을 width 에 적용시킬 경우 300 * 4.86 = 1458 로 캔버스 크기보다 커져버림
      // else if 로 할 경우 if 문 실행했기 때문에 지나가서 가로사이즈 1458 인 채로 캔버스에 들어가게 됨
      if (realOb.height * realOb.scaleY * obScaleY > maxHeight && !isBackground) {
        const height = isGroupBackground ? realOb.height * realOb.scaleY * obScaleY : realOb.height;
        newScaleY = maxHeight / height;
        newScaleX = (ob.scaleX * newScaleY) / ob.scaleY;
      }
      if (realOb.width * realOb.scaleX * obScaleX > maxWidth || isGroupBackground) {
        const width = isGroupBackground ? realOb.width * realOb.scaleX * obScaleX : realOb.width;
        const compareScale = maxWidth / width;
        if (compareScale < newScaleX) {
          // 가로대비, 세로대비 모두 타는 경우 작은 값 따라가야함
          newScaleX = compareScale;
          newScaleY = (ob.scaleY * newScaleX) / ob.scaleX;
        }
      }

      if (isGroupBackground) {
        newLeftAdd = realOb.left * newScaleX * -1;
        newTopAdd = realOb.top * newScaleY * -1;
      }

      ob.left = LEFT + newLeftAdd;
      ob.top = TOP + newTopAdd;
      ob.scaleX = newScaleX;
      ob.scaleY = newScaleY;
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'setResourceScale()', this.app, true);
    }
  }

  /**
   * 컬러 피커의 변경사항을 구독함
   * @param {QueryList<ColorPickerComponent>} colorPickerComponent
   * @param {string} historyBackColor
   * @param {string} colorType
   * @return {void}
   */
  subscribeToColorChanges(colorPickerComponent: QueryList<ColorPickerComponent>, historyBackColor: string, colorType: string): void {
    try {
      colorPickerComponent.changes.pipe(take(1)).subscribe(
        (colorComponent: QueryList<ColorPickerComponent>) => {
          if (colorComponent.first && this.isColorBox) {
            colorComponent.first.startColorPopup(historyBackColor, colorType);
          }
        },
        () => {
          this.setKeyActivation = true;
        },
        () => {
          this.setKeyActivation = true;
        }
      );
    } catch (e) {
      throw new TooningCustomClientError(e, FILE_NAME, 'subscribeToColorChanges()', null, true);
    }
  }

  /**
   * 각 오브젝트에 대해 dispose() 호출 및 캐시캔버스 강제 제거, 그룹인 경우가 존재하기 떄문에 재귀
   * 캔버스에서 해당 오브젝트가 더 이상 필요없어질 때 호출(ex.요소 제거, 페이지 이동)
   * @param obj fabric object
   */
  removeCacheCanvas(obj: TooningFabricObject): void {
    try {
      obj.dispose();
      obj._removeCacheCanvas();
      if (obj._objects) {
        obj._objects.forEach((o) => {
          this.removeCacheCanvas(o as TooningFabricObject);
        });
      }
    } catch (e) {
      throw new TooningCustomClientError(e.message, FILE_NAME, 'removeCacheCanvas()', null, true);
    }
  }

  getColorOpacity(color: string): number | null {
    if (this.regex.rgbaColorRegex.test(color)) {
      const match = this.regex.rgbaColorRegex.exec(color);
      return match ? parseFloat(match[4]) : null;
    } else if (this.regex.hslaColorRegex.test(color)) {
      const match = this.regex.hslaColorRegex.exec(color);
      return match ? parseFloat(match[4]) : null;
    } else if (
      this.regex.hexColorRegex.test(color) ||
      this.regex.rgbColorRegex.test(color) ||
      this.regex.hslColorRegex.test(color) ||
      this.regex.colorNameRegex.test(color)
    ) {
      return 1; // 기본 opacity는 1
    } else {
      return null; // 색상 형식이 아닌 경우
    }
  }

  deleteLegacyStroke(styles): void {
    if (!styles) {
      return;
    }
    for (const key in styles) {
      if (this.isLegacyStroke(styles[key])) {
        delete styles[key][TextStyleType.Stroke];
        delete styles[key][TextStyleType.StrokeWidth];
        delete styles[key][TextStyleType.Width];
      }
    }
  }

  isLegacyStroke(target) {
    return (
      target[TextStyleType.StrokeWidth] === 0 ||
      this.getColorOpacity(target[TextStyleType.Stroke]) === 0 ||
      target[TextStyleType.Width] === 0 ||
      (target.hasOwnProperty(TextStyleType.Width) &&
        (!target.hasOwnProperty(TextStyleType.Stroke) || !target.hasOwnProperty(TextStyleType.StrokeWidth)))
    );
  }

  /**
   * 내 보관함 캐릭터,요소,배경 불러울 때 높이에 따른 가져올 갯수 정하기
   * @param {boolean} isCharacter
   * @returns {Promise<number>}
   */
  async getTake(isCharacter: boolean): Promise<number> {
    let take: number;
    let minSize: number;
    let maxSize: number;
    const minCount: number = TemplateLineCount.minCount;
    const maxCount: number = TemplateLineCount.maxCount;
    if (isCharacter) {
      minSize = TemplateSize.minSize;
      maxSize = TemplateSize.maxSize;
    } else {
      minSize = EtcSize.minSize;
      maxSize = EtcSize.maxSize;
    }
    if (this.platform.width() > TemplatePlatformSize.bigSize) {
      // big pc
      if (this.platform.height() > 1500) take = Math.ceil(this.platform.height() / minSize) * minCount;
      take = Math.floor(this.platform.height() / minSize) * minCount;
    } else if (this.platform.width() > TemplatePlatformSize.mediumSize && this.platform.width() < TemplatePlatformSize.bigSize) {
      // laptop
      take = Math.floor(this.platform.height() / maxSize) * (maxCount - minCount);
    } else if (this.platform.width() > TemplatePlatformSize.smallSize && this.platform.width() < TemplatePlatformSize.mediumSize) {
      // pad
      if (this.platform.height() > 500) take = Math.floor(this.platform.height() / minSize) * (maxCount - minCount);
      take = Math.floor(this.platform.height() / maxSize) * maxCount;
    } else if (this.platform.width() < TemplatePlatformSize.smallSize) {
      // mobile
      if (this.platform.height() > 600) take = Math.floor(this.platform.height() / minSize) * (maxCount - minCount);
      take = Math.floor(this.platform.height() / maxSize) * maxCount;
    } else {
      // small or large
      take = Math.ceil(this.platform.height() / maxSize) * minCount;
    }
    return take;
  }

  /**
   * 북마크 추가, 해제 함수
   * @param resource 북마크 추가(해제)할 리소스
   * @param {BookmarkResourceType} resourceType
   * @param {number} resourceId
   * @param {boolean} isDefault
   * @param {boolean} isActivated 활성화 여부, true인 경우 이미 활성화 된 상태 => 해제, false인 경우 활성화가 안된 상태 => 추가
   * @return {Promise<void>}
   */
  async setBookmark(resource: any, resourceType: BookmarkResourceType, resourceId: number, isDefault: boolean, isActivated = false): Promise<void> {
    try {
      if (this.app.cache.user.role === UserRole.demo) return;
      if (!isActivated) {
        const input = {
          resourceType: resourceType,
          resourceId: +resourceId,
          isDefault: isDefault
        };
        const { data } = await this.bookmarkService.addBookmark(input).toPromise();
        resource.bookmark = new Bookmark({
          id: data.addBookmark.bookmarkId,
          resourceId: resourceId,
          resourceType: resourceType,
          isActivated: true
        });
      } else {
        const { data } = await this.bookmarkService.deleteBookmark(+resource.bookmark.id).toPromise();
        resource.bookmark = new Bookmark({
          resourceId: resourceId,
          resourceType: resourceType,
          isActivated: !data.deleteBookmark
        });
      }
      await this.bookmarkService.getBookmarkCount().toPromise();
    } catch (e) {
      throw new TooningSetBookmarkError(e);
    }
  }

  /**
   * 북마크 버튼 활성화 함수
   * @param {Array<any>} targetList 북마크가 보여지는 리소스들의 리스트
   * @param {BookmarkResourceType} resourceType
   * @param {number[]} ids
   * @return {Promise<void>}
   */
  async activatedBookmarks(targetList: Array<any>, resourceType: BookmarkResourceType, ids: number[]): Promise<void> {
    try {
      const { data } = await this.bookmarkService.getBookmarkedListByResources(resourceType, ids).toPromise();
      const etcType = [BookmarkResourceType.Item, BookmarkResourceType.Background, BookmarkResourceType.Effect, BookmarkResourceType.Balloon];
      const key = etcType.includes(resourceType) ? 'sourceId' : 'id';
      for (let bookmark of data.getBookmarkedListByResources) {
        const targetResource = targetList.find((target) => +target[key] === bookmark.resourceId);
        if (targetResource) {
          targetResource.bookmark = new Bookmark({
            id: bookmark.bookmarkId,
            resourceId: bookmark.resourceId,
            resourceType: bookmark.resourceType,
            isActivated: true
          });
        }
      }
    } catch (e) {
      throw new TooningActivatedBookmarsError(e);
    }
  }
}
