<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'share' | translate }}</ion-title>
    <ion-buttons class='m-0 mr-2' slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content appScrollbarTheme style='--height: 156px;'>
  <div class='popover-box'>
    <div class='d-flex justify-content-between px-2 pt-2' #shareLinkCopyContainerInWorkSpace>
      <div class='d-flex justify-content-start' style='padding-top: 17px'>
        <span class='matMenu_span'>{{ 'view settings' | translate }}</span>
      </div>
      <div class='view-orientation-type-wrapper' id='viewOrientationType'>
        <span class='text-overflow'>{{ viewOrientationType[orientation] | translate }}</span>
        <ion-icon
          [name]='isViewOrientationTypePopoverOpen?"chevron-up-outline":"chevron-down-outline"'></ion-icon>
      </div>
      <ion-popover #viewOrientationTypePopover trigger='viewOrientationType' triggerAction='click' side='bottom'
                   show-backdrop='false'
                   size='cover' class='view-orientation-type-popover'
                   (willPresent)='isViewOrientationTypePopoverOpen=true'
                   (willDismiss)='isViewOrientationTypePopoverOpen=false'
                   mode='md'>
        <ng-template>
          <div class='item-wrapper'>
            <div class='view-orientation-type-cell' [class.selected]='orientation==="vertical"'
                 (click)='orientationChange("vertical")'>
              {{ viewOrientationType.vertical | translate }}
            </div>
            <div class='view-orientation-type-cell' [class.selected]='orientation==="horizontal"'
                 (click)='orientationChange("horizontal")'>
              {{ viewOrientationType.horizontal | translate }}
            </div>
            <div class='view-orientation-type-cell' [class.selected]='orientation==="cut"'
                 (click)='orientationChange("cut")'>
              {{ viewOrientationType.cut | translate }}
            </div>
            <div class='view-orientation-type-cell' [class.selected]='orientation==="presentation"'
                 (click)='orientationChange("presentation")'>
              {{ viewOrientationType.presentation | translate }}
            </div>
          </div>
        </ng-template>
      </ion-popover>
    </div>
    <div class='d-flex justify-content-between px-2'>
      <div class='d-flex justify-content-start' style='padding-top: 17px'>
        <span class='matMenu_span'>{{ 'share setting' | translate }}</span>
      </div>

      <div class='view-orientation-type-wrapper' id='authorization'>
        <span>{{ (authorization === "read" ? "view only" : "cloneable") | translate }}</span>
        <ion-icon [name]='isAuthorizationPopoverOpen?"chevron-up-outline":"chevron-down-outline"'></ion-icon>
      </div>
      <ion-popover #authorizationPopover trigger='authorization' triggerAction='click' side='bottom'
                   show-backdrop='false' mode='md'
                   size='cover' class='view-orientation-type-popover'
                   (willPresent)='isAuthorizationPopoverOpen=true'
                   (willDismiss)='isAuthorizationPopoverOpen=false'>
        <ng-template>
          <div class='item-wrapper'>
            <div class='view-orientation-type-cell' [class.selected]='authorization==="read"'
                 (click)='authorizationChange("read")'>
              {{ 'view only' | translate }}
            </div>
            <div class='view-orientation-type-cell' [class.selected]='authorization==="clone"'
                 (click)='authorizationChange("clone")'>
              {{ 'cloneable' | translate }}
              <ion-icon class='pay-icon' *ngIf='!app.isPaidMember'
                        src='/assets/4cut-make-manual/pro_icon.svg'></ion-icon>
            </div>
          </div>
        </ng-template>
      </ion-popover>
    </div>
  </div>
</ion-content>
<ion-footer>
  <!--  모달에서 카피 안되는 이슈는 아래 container 선언으로 해결 가능 https://github.com/maxisam/ngx-clipboard/issues/145 -->
  <ion-button class='custom-size-m' mode='ios' expand='block' ngxClipboard *ngIf='readyToRender'
              [container]='shareLinkCopyContainerInWorkSpace'
              [ngClass]='isFromEditor?"theme-editor":"theme-main"'
              (cbOnError)='copyError($event)'
              (cbOnSuccess)='linkCopy()' [cbContent]=url
  >
    {{ 'copy link' | translate }}
  </ion-button>
</ion-footer>
