class CommandManagerDrawing {
  public cut;
  public undoHistory;
  public redoHistory;

  private static instance: CommandManagerDrawing | null = null;

  private constructor() {
    this.undoHistory = [];
    this.redoHistory = [];
  }

  /**
   * 클래스의 인스턴스를 반환
   * @return {CommandManagerDrawing}
   */
  static getInstance(): CommandManagerDrawing {
    try {
      if (CommandManagerDrawing.instance === null) {
        CommandManagerDrawing.instance = new CommandManagerDrawing();
      }
      return CommandManagerDrawing.instance;
    } catch (e) {
      console.error(e);
    }
  }

  setCut(cut) {
    try {
      this.cut = cut;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   *
   * @param command
   * @return {Promise<any>}
   */
  async executeCommand(command): Promise<any> {
    try {
      let result;
      if (command) {
        result = command.execute();
        this.undoHistory.push(command);

        // redoHistory에 command가 있는 채 execute가 되면 reset하기
        if (this.redoHistory.length > 0) {
          this.redoHistory = [];
        }
        return result;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.cut.checkIsUndoRedo();
    }
  }

  /**
   *
   * @return {Promise<void>}
   */
  async undo(): Promise<void> {
    try {
      const command = this.undoHistory.pop();
      if (command) {
        command.undo();
        this.redoHistory.push(command);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.cut.checkIsUndoRedo();
    }
  }

  /**
   *
   * @return {Promise<void>}
   */
  async redo(): Promise<void> {
    try {
      const command = this.redoHistory.pop();
      if (command) {
        command.redo();
        this.undoHistory.push(command);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.cut.checkIsUndoRedo();
    }
  }
}

export { CommandManagerDrawing };
