import { Command } from './command';
import { ObjectAddCommandData } from '../../interfaces/app.interface';
import { ResourceType } from '../../enum/app.enum';

/**
 * Class ObjectAddCommand
 * @extends Command
 */
class ObjectAddCommand extends Command {
  protected dataLessObjectString;
  protected callback;
  protected addOb;
  protected id;
  protected layerIndex: number;

  /**
   * Create ObjectAddCommand
   * @param {ObjectAddCommandData} data
   */
  constructor(data: ObjectAddCommandData) {
    super(data);
    this.callback = data.callback;
    this.commandName = 'Object-Add-Command';
  }

  /**
   * object가 캔버스에 추가되었을 때 실행되는 command
   * @return {Promise<void>}
   */
  async execute(): Promise<void> {
    try {
      super.execute();
      this.addOb = await this.callback();
      // 결제 후 처리 시 interface 통일 위해 target 에 복구할 것을 저장
      this.target = this.addOb.toDatalessObject();

      if (!this.addOb.resource_selection_id) {
        this.cut.assignmentID(this.addOb);
      }
      this.id = this.addOb.resource_selection_id;
      // 처음에 커맨드 패턴 만들때 string 으로 가지고 있었으므로, 삭제 하지 않고 가지고 있음
      // 이걸 안사용해서 문제가 생길 수도 있음
      this.dataLessObjectString = JSON.stringify(this.target);
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object add command의 redo command
   * object add undo처리해서 삭제된 object를 다시 불러온다.
   * @return {Promise<void>}
   */
  async redo(): Promise<void> {
    try {
      super.redo();
      // 예전에는 this.dataLessObjectString 을 사용해서 복구 지금은 object을 사용 혹시라도 문제가 생길 경우 this.dataLessObjectString 로 변경 필요
      const ob = await this.cut.addObFromJson([this.target], false, false, true, null, this.layerIndex);
      ob.resource_selection_id = this.id;
      ob.resource_type === ResourceType.characterSvg && (this.cut.characterLengthOnPage += 1);
      if (ob.parentId !== undefined && ob.parentId === this.cut.clonedJson.resource_selection_id) {
        this.cut.setAddPosition();
      }

      const updatedPanel = this.cut.pageList[this.cut.panelIndex];
      if (updatedPanel) {
        updatedPanel.dirty = true;
      } else {
        throw new Error('The panel does not exist. Please ensure the panel');
      }

      this.cut.updatePage(this.cut.panelIndex);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object add command의 undo command
   * 캔버스에 추가된 object를 제거한다.
   * @param objects add undo할 objects
   * @return {Promise<void>}
   */
  async undo(objects): Promise<void> {
    try {
      super.undo(objects);
      for (let i = 0; i < objects.length; i++) {
        if (objects[i].resource_selection_id === this.id) {
          objects[i].resource_type === ResourceType.characterSvg && (this.cut.characterLengthOnPage -= 1); // 캐릭터 요소일 경우에만 페이지 내 캐릭터 수를 -1
          this.cut.canvas.remove(objects[i]);

          if (objects[i].parentId !== undefined && objects[i].parentId === this.cut.clonedJson.resource_selection_id) {
            this.cut.setDeletePosition();
          }

          this.layerIndex = i;
          break;
        }
      }
      this.cut.forceNoFocus();
      const updatedPanel = this.cut.pageList[this.cut.panelIndex];
      if (updatedPanel) {
        updatedPanel.dirty = true;
      } else {
        throw new Error('The panel does not exist. Please ensure the panel');
      }
      this.cut.updatePage(this.cut.panelIndex);
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { ObjectAddCommand };
