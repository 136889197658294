import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegexService {
  public phoneNumRex = /^\d{2,3}-\d{3,4}-\d{4}$/;
  public emailRex = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  public tooningTestEmailRex = /^test\d+@tooning.io/;
  public amountRex = /\B(?=(\d{3})+(?!\d))/g;
  public hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;
  public rgbColorRegex = /^rgb\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\s*\)$/;
  public rgbaColorRegex = /^rgba\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(1|0|0?\.\d+)\s*\)$/;
  public hslColorRegex = /^hsl\(\s*(\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\s*\)$/;
  public hslaColorRegex = /^hsla\(\s*(\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%,\s*(1|0|0?\.\d+)\s*\)$/;
  public colorNameRegex = /^[a-zA-Z]+$/;

  /**
   * 폰번호 자동 하이픈
   * @param {string} phoneNum
   * @return {string}
   */
  phoneNumberAutoHypen(phoneNum: string): string {
    phoneNum = phoneNum
      .replace(/[^0-9]/g, '') // 숫자를 제외한 모든 문자 제거
      .replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
    return phoneNum;
  }

  /**
   * 핸드폰 번호 정규식 체크
   * @param {string} phoneNum
   * @return {boolean}
   */
  phoneNumberRegexTest(phoneNum: string): boolean {
    const result = this.phoneNumRex.test(phoneNum);
    return result;
  }

  /**
   * 이메일 정규식 체크
   * @param {string} email
   * @return {boolean}
   */
  emailRegexTest(email: string): boolean {
    const result = this.emailRex.test(email);
    return result;
  }

  /**
   * 투닝 e2e 테스트 계정 정규식 체크
   * @param {string} email
   * @return {boolean}
   */
  testEmailRegexTest(email: string): boolean {
    return this.tooningTestEmailRex.test(email);
  }

  /**
   * 요금 , 추가 정규식
   * @param {number} amount
   * @return {string}
   */
  amountRegex(amount: number): string {
    const newAmount = amount;
    return newAmount.toString().replace(this.amountRex, ',');
  }
}
