import gql from 'graphql-tag';
import { AppService } from '../app.service';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { GraphqlApiService } from '../api/graphql.api.service';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GptEntity } from '../../pages-gpt/shared/enums/gpt.enum';
import {
  AdminNewRoleCategoryModel,
  AdminNewRoleModel,
  AdminNewRoleTypeModel,
  AdminNewSampleModel,
  AdminRoleBaseModel
} from '../../model/gpt/gpt.model';
import { GptRole, GptSetting } from '../../pages-gpt/shared/interfaces/gpt.interface';
import {
  InputGptAdditional,
  InputGptDemoData,
  InputGptGroup,
  InputGptGroups,
  InputGptRequest,
  InputGptRole,
  InputGptRoles,
  InputUpdatedOrder
} from '../../pages-gpt/shared/interfaces/input.interface';

@Injectable({
  providedIn: 'root'
})
export class GptService {
  public pageRefreshed$: Subject<boolean> = new Subject<boolean>();
  private gptRolesCache: { [key: string]: GptRole[] } = {};
  private gptSettingsCache: { [key: string]: GptSetting[] } = {};

  constructor(public app: AppService, public graphql: GraphqlApiService) {}

  /**
   * 사용자의 GPT 무료 체험 횟수를 가져온다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getGptFreeCount(): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query GptFreeCount {
          gptFreeCount
        }
      `
    );
  }

  /**
   * 사용자의 GPT 무료 체험 횟수를 변경한다
   * @param {number} gptFreeCount - 무료 체험 횟수
   * @returns {Promise<Observable<FetchResult<any>>>}
   */
  updateGptFreeCount(gptFreeCount: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation updateGptFreeCount($gptFreeCount: Int!) {
          updateGptFreeCount(gptFreeCount: $gptFreeCount)
        }
      `,
      {
        gptFreeCount
      }
    );
  }

  /**
   * 채팅 그룹의 title 을 변경한다
   * @param {number} groupId - 채팅 그룹 ID
   * @param {string} groupTitle - 채팅 그룹 title
   * @returns {Promise<boolean>}
   */
  async updateGptGroupTitle(groupId: number, groupTitle: string): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation UpdateGptGroupTitle($groupId: Int!, $groupTitle: String!) {
            updateGptGroupTitle(groupId: $groupId, groupTitle: $groupTitle)
          }
        `,
        {
          groupId,
          groupTitle
        }
      )
      .toPromise();
    return result;
  }

  /**
   * 선택한 그룹의 GPT 를 삭제합니다.
   * @param {number} groupId - 그룹 ID
   * @returns {Observable<FetchResult<any>>}
   */
  cleanGptGroup(groupId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation cleanGptGroup($groupId: Int!) {
          cleanGptGroup(groupId: $groupId)
        }
      `,
      {
        groupId
      }
    );
  }

  /**
   * 선택한 그룹을 삭제합니다.
   * @param {number} groupId - 그룹 ID
   * @returns {Observable<FetchResult<any>>}
   */
  deleteGptGroup(groupId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation deleteGptGroup($groupId: Int!) {
          deleteGptGroup(groupId: $groupId)
        }
      `,
      {
        groupId
      }
    );
  }

  /**
   * (어드민) 새로운 샘플을 생성합니다.
   * @param {AdminNewSampleModel} data - 새로운 샘플 정보
   * @returns {Observable<FetchResult<any>>}
   */
  adminCreateNewSamples(data: AdminNewSampleModel[]): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation adminCreateNewSamples($data: [AdminInputNewSample!]!) {
          adminCreateNewSamples(data: $data)
        }
      `,
      {
        data
      }
    );
  }

  /**
   * (어드민) 역할에 속한 모든 샘플을 가져옵니다.
   * @param {number} roleId - 역할 ID
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  adminReadAllSamples(roleId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query adminReadAllSamples($roleId: Int!) {
          adminReadAllSamples(roleId: $roleId) {
            id
            viewState
            useCount
            title
          }
        }
      `,
      { roleId }
    );
  }

  /**
   * (어드민) 시스템 역할을 생성합니다.
   * @param {AdminNewRoleModel} data - 새로운 역할 정보
   * @returns {Observable<FetchResult<any>>}
   */
  adminCreateNewRole(data: AdminNewRoleModel[]): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation adminCreateNewRole($data: [InputNewGptRole!]!) {
          adminCreateNewRole(data: $data)
        }
      `,
      {
        data
      }
    );
  }

  /**
   * (어드민) 모든 역할을 가져옵니다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  adminReadAllRole(): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query adminReadAllRole {
          adminReadAllRole {
            categoryNames
            createdDate
            eduInfoUrl
            iconUrl
            id
            infusion
            model
            name
            name
            tagNames
            typeName
            useCount
            viewState
          }
        }
      `
    );
  }

  /**
   * (어드민) 역할 기본 정보를 업데이트합니다.
   * @param {number} id - 역할 ID
   * @param {AdminRoleBaseModel} base - 기본 정보
   * @returns {Observable<FetchResult<any>>}
   */
  adminUpdateRoleBase(id: number, base: AdminRoleBaseModel): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation adminUpdateRoleBase($id: Int!, $base: AdminInputRoleBase!) {
          adminUpdateRoleBase(id: $id, base: $base)
        }
      `,
      { id, base }
    );
  }

  /**
   * (어드민) 역할의 아이콘을 업데이트합니다.
   * @param {number} id - 역할 ID
   * @param {File} icon - 아이콘 파일
   * @returns {Observable<FetchResult<any>>}
   */
  adminUpdateRoleIcon(id: number, icon: File): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation adminUpdateRoleIcon($id: ID!, $icon: Upload!) {
          adminUpdateRoleIcon(id: $id, icon: $icon)
        }
      `,
      { id, icon }
    );
  }

  /**
   * (어드민) 시스템 역할 타입을 생성합니다.
   * @param {AdminNewRoleTypeModel} data - 새로운 역할 타입 정보
   * @returns {Observable<FetchResult<any>>}
   */
  adminCreateNewRoleType(data: AdminNewRoleTypeModel): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation adminCreateNewRoleType($data: AdminInputNewRoleType!) {
          adminCreateNewRoleType(data: $data)
        }
      `,
      {
        data
      }
    );
  }

  /**
   * (어드민) 모든 역할 타입을 가져옵니다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  adminReadAllRoleTypes(): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query adminReadAllRoleTypes {
          adminReadAllRoleTypes {
            id
            name
          }
        }
      `
    );
  }

  /**
   * (어드민) 시스템 역할 카테고리를 생성합니다.
   * @param {AdminNewRoleCategoryModel} data - 새로운 역할 카테고리 정보
   * @returns {Observable<FetchResult<any>>}
   */
  adminCreateNewRoleCategory(data: AdminNewRoleCategoryModel): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation adminCreateNewRoleCategory($data: AdminInputNewRoleCategory!) {
          adminCreateNewRoleCategory(data: $data)
        }
      `,
      {
        data
      }
    );
  }

  /**
   * (어드민) 모든 역할 카테고리를 가져옵니다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  adminReadAllRoleCategories(): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query adminReadAllRoleCategories {
          adminReadAllRoleCategories {
            id
            name
          }
        }
      `
    );
  }

  /**
   * (어드민) 엔티티의 서비스에서의 노출 상태를 변경합니다.
   * @param {number} id - Entity ID
   * @param {GptEntity} entity - 역할/샘플
   * @returns {Observable<FetchResult<any>>}
   */
  adminUpdateEntityViewState(id: number, entity: GptEntity): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation adminUpdateEntityViewState($id: Int!, $entity: String!) {
          adminUpdateEntityViewState(id: $id, entity: $entity)
        }
      `,
      { id, entity }
    );
  }

  /**
   * (어드민) 역할의 순서를 변경합니다.
   * @param {InputUpdatedOrder} updatedOrder - 순서 업데이트
   * @returns {Observable<FetchResult<any>>}
   */
  adminUpdateRoleOrder(updatedOrder: InputUpdatedOrder): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation AdminUpdateRoleOrder($updatedOrder: InputUpdatedOrder!) {
          adminUpdateRoleOrder(updatedOrder: $updatedOrder)
        }
      `,
      { updatedOrder }
    );
  }

  /**
   * (초기화) 기본 역할, 기본 샘플들을 가져옵니다.
   * @param {InputGptRole} input
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getGptRole(input?: InputGptRole): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query GptRole($input: InputGptRole) {
          gptRole(input: $input) {
            role {
              id
              name
              iconUrl
            }
            samples {
              id
              title
              content
              demoAnswer
            }
          }
        }
      `,
      { input }
    );
  }

  /**
   * (초기화) 모든 역할들을 가져옵니다.
   * @param {InputGptRoles} input
   * @param {string} cacheKey
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getGptRoles(input?: InputGptRoles, cacheKey: string = 'gptRoles'): Observable<ApolloQueryResult<any>> {
    if (this.gptRolesCache[cacheKey]) {
      return of({
        data: { gptRoles: this.gptRolesCache[cacheKey] },
        loading: false,
        networkStatus: 7,
        stale: false
      });
    }

    return this.graphql
      .query(
        gql`
          query GptRoles($input: InputGptRoles!) {
            gptRoles(input: $input) {
              id
              iconUrl
              name
              eduInfoUrl
            }
          }
        `,
        {
          input
        }
      )
      .pipe(
        tap((result) => {
          this.gptRolesCache[cacheKey] = result.data.gptRoles;
        })
      );
  }

  /**
   * 선택한 역할들을 말인사와 함께 가져옵니다.
   * @param {InputGptRoles} input
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getGptRolesWithGreeting(input?: InputGptRoles): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query GptRolesWithGreeting($input: InputGptRoles!) {
          gptRolesWithGreeting(input: $input) {
            id
            iconUrl
            name
            greeting
          }
        }
      `,
      { input }
    );
  }

  /**
   * 특정 그룹을 가져옵니다.
   * @param {InputGptGroup} input
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getGptGroup(input?: InputGptGroup): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query GptGroup($input: InputGptGroup) {
          gptGroup(input: $input) {
            id
            title
            isSingleRoleGroup
            roles {
              id
              iconUrl
              name
            }
            gpts {
              json
              role {
                id
                iconUrl
                name
              }
              samples {
                id
                title
                content
                demoAnswer
              }
            }
          }
        }
      `,
      { input }
    );
  }

  /**
   * 그룹 리스트를 가져옵니다.
   * @param {InputGptGroups} input
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getGptGroups(input?: InputGptGroups): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query GptGroups($input: InputGptGroups) {
          gptGroups(input: $input) {
            id
            roles {
              id
              name
              iconUrl
              eduInfoUrl
            }
            lastGpt
            title
            isSingleRoleGroup
          }
        }
      `,
      { input }
    );
  }

  /**
   * GPT 데이터를 추가적으로 가져옵니다.
   * @param {InputGptAdditional} input
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  gptGptAdditional(input?: InputGptAdditional): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query GptAdditional($input: InputGptAdditional) {
          gptAdditional(input: $input) {
            gpts {
              json
              role {
                id
                name
                iconUrl
              }
              samples {
                id
                title
              }
            }
          }
        }
      `,
      { input }
    );
  }

  /**
   * GPT 응답을 스트리밍 방식으로 요청합니다.
   * @param {InputGptRequest} requestArgs - 요청 인자
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  requestGptResponse(requestArgs: InputGptRequest): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query GptResponse($requestArgs: InputGptRequest!) {
          gptResponse(requestArgs: $requestArgs) {
            result
            group {
              id
              title
              lastGpt
            }
          }
        }
      `,
      { requestArgs }
    );
  }

  /**
   * GPT 응답 요청 재귀 함수.
   * @param {InputGptRequest} requestArgs - 요청 인자
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  requestGptRecursiveResponse(requestArgs: InputGptRequest): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query GptRecursiveResponse($requestArgs: InputGptRequest!) {
          gptRecursiveResponse(requestArgs: $requestArgs) {
            result
            group {
              id
              title
              lastGpt
            }
          }
        }
      `,
      { requestArgs }
    );
  }

  /**
   * 로그인 시 비로그인 상태에서의 작업 내용을 저장합니다.
   * @returns {Observable<FetchResult<any>>}
   * @param demoData
   */
  linkDemoDataWithUser(demoData: InputGptDemoData): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation linkDemoDataWithUser($demoData: InputGptDemoData!) {
          linkDemoDataWithUser(demoData: $demoData) {
            id
            title
            lastGpt
          }
        }
      `,
      { demoData }
    );
  }

  /**
   * 유지보수 모드는 유지보수 중이거나 업데이트 중으로, 사용자가 서비스에 접근할 수 없는 상태를 말합니다. 이 상태를 변경합니다.
   * @param {boolean} isActive - 변경 상태
   * @returns {Observable<FetchResult<any>>}
   */
  setMaintenanceMode(isActive: boolean): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation SetMaintenanceMode($isActive: Boolean!) {
          setMaintenanceMode(isActive: $isActive)
        }
      `,
      {
        isActive
      }
    );
  }

  /**
   * GPT 업데이트 상태 정보를 가져옵니다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  gptMaintenanceMode(): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(gql`
      query GptMaintenanceMode {
        gptMaintenanceMode
      }
    `);
  }

  /**
   * 사용자의 설정값을 생성합니다.
   * @returns {Observable<FetchResult<any>>}
   */
  createGptSettings(): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation createGptSettings {
          createGptSettings
        }
      `
    );
  }

  /**
   * 사용자의 설정값을 가져옵니다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getGptSettings(cacheKey: string = 'settings'): Observable<ApolloQueryResult<any>> {
    if (this.gptSettingsCache[cacheKey]) {
      return of({
        data: { gptSettings: this.gptSettingsCache[cacheKey] },
        loading: false,
        networkStatus: 7,
        stale: false
      });
    }

    return this.graphql
      .query(
        gql`
          query GptSettings {
            gptSettings {
              level
            }
          }
        `
      )
      .pipe(
        tap((result) => {
          this.gptSettingsCache[cacheKey] = result.data.gptSettings;
        })
      );
  }

  /**
   * 대답의 난이도를 변경합니다.
   * @returns {Observable<FetchResult<any>>}
   */
  updateGptAnswerLevel(level: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation UpdateGptAnswerLevel($level: String!) {
          updateGptAnswerLevel(level: $level)
        }
      `,
      { level }
    );
  }

  /**
   * 로그인 성공 후 GPT 에디터 페이지를 새로고침 합니다.
   * @returns {Promise<void>}
   */
  public async refreshPage(): Promise<void> {
    this.pageRefreshed$.next(true);
  }
}
