<ion-header>
  <ion-toolbar class='border-bottom'>
    <div class='popover-title'>{{ 'Copy To Folder' | translate }}</div>
    <ion-buttons class='m-0 mr-2' slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class='h-100 p-2'>
  <div class='folder-code'>
    <div style='font-weight: 500; margin-bottom: 16px'>{{ 'share-folder.Folder code' | translate }}</div>
    <input class='custom-input folder-code-input' [class.validation-error-input]='!isValidate'
           [formControl]='folderCode' placeholder="{{ 'share-folder.Folder code' | translate }}"
           type='text' maxlength='20' pattern='[0-9]+@[0-9]+' />
    <div *ngIf='!isValidate'
         class='validation-error'>{{ 'share-folder.Please check the folder code again.' | translate }}</div>
  </div>
</div>
<ion-footer mode='md' class='p-2'>
  <ion-button class='custom-size-m' [ngClass]='fromEditor?"theme-editor":"theme-main"' mode='ios' expand='block'
              matRipple
              (click)='submitCanvas()' [disabled]='isClicked'>{{ 'share-folder.Send' | translate }}</ion-button>
</ion-footer>
