import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../../services/payment/payment.service';
import { AppService } from '../../../services/app.service';
import { TooningUpdateCardCompanyStatusError } from '../../errors/TooningErrors';

@Component({
  selector: 'app-card-company-set',
  templateUrl: './card-company-set.component.html',
  styleUrls: ['./card-company-set.component.scss']
})
export class CardCompanySetComponent implements OnInit {
  public readyToRender = false;
  public cardCompanyList;
  constructor(public paymentService: PaymentService, private app: AppService) {}

  async ngOnInit() {
    this.cardCompanyList = await this.paymentService.getCardCompanyList().toPromise();
    this.readyToRender = true;
  }

  /**
   * 카드사 상태를 변경하는 함수
   * @param {number} companyId 변경할 카드사 아이디
   * @param {boolean} isProblem 상태,true이면 결제 페이지에서 메세지가 보임
   * @return {Promise<void>}
   */
  async updateCardCompanyStatus(companyId: number, isProblem: boolean): Promise<void> {
    try {
      const responseData = await this.paymentService.updateStateCardCompanyProblem(companyId, isProblem).toPromise();
      const result = responseData.data.updateStateCardCompanyProblem;
      if (result) {
        await this.app.showToast('성공');
      } else {
        await this.app.showToast('실패', 1000, 'danger');
      }
    } catch (e) {
      throw new TooningUpdateCardCompanyStatusError(e);
    }
  }
}
