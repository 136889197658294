import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MessageService {
  currentMessage = new BehaviorSubject(null);
  private subscriptions = [];

  constructor(private angularFireMessaging: AngularFireMessaging) {}

  resetSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  requestPermission() {
    this.subscriptions.push(
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          console.log(token);
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      )
    );
  }

  receiveMessage() {
    this.subscriptions.push(
      this.angularFireMessaging.messages.subscribe((payload) => {
        console.log('new message received. ', payload);
        this.currentMessage.next(payload);
      })
    );
  }
}
