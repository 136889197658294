import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PaidData } from './paid-data';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { CardService } from '../../services/card/card.service';
import { PaymentService } from '../../services/payment/payment.service';
import { UserRole } from '../../enum/app.enum';

@Component({
  selector: 'app-paid-referral',
  templateUrl: './paid-referral.component.html',
  styleUrls: ['./paid-referral.component.scss']
})
export class PaidReferralComponent implements OnInit {
  @Input() childMessage: string;
  public userId;
  public isFreePeriodDone;
  public UserRole = UserRole;

  constructor(
    public dialogRef: MatLegacyDialogRef<PaidReferralComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: PaidData,
    private translate: TranslateService,
    public app: AppService,
    public cardService: CardService,
    public paymentService: PaymentService
  ) {}

  async ngOnInit() {
    this.userId = this.app.user.getUserIdx();
    const r = await this.paymentService.isFreePeriodDone(this.userId, 1).toPromise();
    this.isFreePeriodDone = r.data;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  go() {
    this.dialogRef.close('go');
    this.app.go('card-registration/pro');
  }
}
