<app-header></app-header>
<ion-content appScrollbarTheme>

  <!-- 검색 -->
  <div
    class='d-flex align-items-center justify-content-center searchBar-input-background'
    [style.background-size]="app.isDesktopWidth()?'cover':'850px'"
    [style.height.px]='app.isDesktopWidth()?180:128'
  >
    <ion-searchbar
      (ionClear)='reLoad()'
      (keydown.enter)='searchCanvas($event)'
      *ngIf="readyToRender || searchText !== '' "
      [style.padding-left.px]='app.isDesktopWidth()?0:8'
      [value]='searchText'
      class='input-style2'
      placeholder="{{'put title' | translate}}"
      style='--border-radius: 30px; padding: 10px 12px'
      data-cy='listSearchBar'
      mode='md'
    >
    </ion-searchbar>
  </div>

  <div class='d-flex flex-column' style='width: 100%; height: 100%'>
    <!--  폴더 진입시 타이틀 맵   -->
    <div *ngIf='app.isDesktopWidth()' class='container p-3 pt-4 d-flex align-items-center' style='font-size: 14px'>
      <ion-button [attr.disabled]='!cutList.isCanvasGroupView&&!cutList.isTrashView' class='my-work hover' fill='clear'
                  mode='md'
                  (click)='reLoad()'
      >{{'cut4-list.My work' | translate}}
      </ion-button>
      <ion-icon
        *ngIf='cutList.isCanvasGroupView||cutList.isTrashView'
        name='chevron-forward-outline'
        style='font-size: 15px; color: #bdbdbd; padding: 0 0.5rem'
      ></ion-icon>
      <div *ngIf='cutList.isCanvasGroupView||cutList.isTrashView' class='textOverflow'
           style='color: #bdbdbd; font-size: 16px; padding: 0 0.5rem'>
        {{cutList.canvasGroupName}}
      </div>
    </div>
    <div
      (change)='changeLanguageType()'
      *ngIf='isShowSelectBox'
      class='container p-2 py-4 d-flex align-items-center'
      style='font-size: 20px; font-weight: bold'
    >
      {{'cut4-list.My work' | translate}}
      <select [(ngModel)]='languageTypeAll' class='browser-default custom-select text-uppercase' id='grade'
              style='width: 15%; margin-left: auto'>
        <option [value]='languageType.all'>all</option>
        <option [value]='languageType.ko'>ko</option>
        <option [value]='languageType.en'>en</option>
        <option [value]='languageType.fr'>fr</option>
        <option [value]='languageType.jp'>jp</option>
      </select>
    </div>
    <div class='container p-3 d-flex align-items-center' *ngIf='cutList.isCanvasGroupView||cutList.isTrashView'>
      <ion-button
        class='mr-3 hover'
        fill='clear'
        (click)='reLoad()'
        style='width: 33px; height: 33px; --padding-start: 0; --padding-end: 0; border-radius: 5px'
        data-cy='backToMyWork'
      >
        <ion-icon name='chevron-back-outline' style='font-size: 30px; color: #151515'
                  *ngIf='!app.isDesktopWidth()'></ion-icon>
        <ion-icon name='arrow-back-outline' style='font-size: 30px; color: #151515'
                  *ngIf='app.isDesktopWidth()'></ion-icon>
      </ion-button>
      <div class='textOverflow'
           style='font-weight: 700; font-size: 22px; color: #242424'>{{cutList.canvasGroupName}}</div>
    </div>

    <!--  휴지통 진입시   -->
    <div
      *ngIf='cutList.isTrashView'
      [ngClass]="app.isDesktopWidth()?'container p-3 pt-4 d-flex align-items-center':'container p-3 pt-0 align-items-center text-center'"
      style='font-size: 14px'
    >
      <div style='padding: 8px; background: #e0e0e0; border-radius: 5px'>
        {{'cut4-list.Trash_text1' | translate}} {{'cut4-list.Trash_text2' | translate}}
      </div>
      <div
        (click)="clickRemove('trash')"
        [style.margin-left]="app.isDesktopWidth()?'20px':'unset'"
        [style.margin-top]="app.isDesktopWidth()?'unset':'10px'"
        [style.width]="app.isDesktopWidth()?'unset':'100%'"
        [style.pointer-events]="recycleBinCount===0? 'none':'visible'"
        [style.color]='recycleBinCount===0?"#C2C2C2":"var(--tooning-brand-color-1)"'
        style='text-decoration: underline; cursor: pointer; padding: 0 0.5rem'
      >
        {{'cut4-list.Empty Trash' | translate}}
      </div>
    </div>
    <!--  폴더 리스트   -->
    <div
      *ngIf='!cutList.isCanvasGroupView&&!isSearchView'
      class='container p-1 pt-2 tooning-cut4-list-custom-accordion'
    >
      <ion-item
        slot='header'
        style='--color-focused: #000; --color-hover: #000; --color-activated: #000; background: #fff'
        [style.padding]='!app.isDesktop()?"0 10px":""'
      >
        <ion-label *ngIf='!cutList.isCanvasGroupView' class='ml-1'
                   style='color: #242424; font-weight: 500; font-size: 20px'
        >{{'cut4-list.Folder' | translate}}</ion-label
        >
        <ion-icon name='chevron-up-outline' [@arrowIconRotate]='isAccordionOpen ? "up" : "down"'
                  (click)='accordionToggle()'></ion-icon>
      </ion-item>

      <!-- 리스트 -->
      <div *ngIf='readyToRender' #accordion class='accordion-content'
           [@expandCollapse]="isAccordionOpen ? 'expanded' : 'collapsed'">
        <ion-grid slot='content'>
          <div *ngIf='!readyToRender'
               style='position: absolute; z-index: 1; width: 100%; height: 100%;opacity: 0.2;background-color: #ffffff'></div>
          <ion-row cdkDropList style='background: #f9f9f9' class='overflow-hidden'>
            <ion-col *ngIf='!cutList.isTrashView' size='6' size-md='3'
                     [style.padding]='app.isDesktopWidth()?"10px":"4px"'>
              <div style='position: relative'>
                <!--    무료계정 오픈안되도록 막는 UI     -->
                <div
                  (click)='openRestrictions("7")'
                  class='block p-3'
                  *ngIf='!app.isPaidMember'
                  style='cursor: pointer; position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0.2; z-index: 1'
                ></div>
                <div
                  (click)='openModalCanvasGroup(true)'
                  class='block d-flex align-items-center justify-content-center position-relative'
                  matRipple
                  style='color: #8c8c8c; cursor: pointer; border-style: dashed; background: #fff'
                  data-cy='addNewFolder'
                >
                  <div class='position-absolute' style='top: 50%; left: 50%;  transform: translate(-50%, -50%)'>
                    <ion-img
                      *ngIf='!app.isPaidMember'
                      class='position-relative d-flex m-auto'
                      style='width: 30px;height: 30px;'
                      src='/assets/4cut-make-manual/pro_icon.svg'
                    ></ion-img>
                    <ion-icon
                      *ngIf='app.isPaidMember'
                      name='add-outline'
                      style='font-size: 26px;'
                    ></ion-icon>
                    <div class='mx-auto'>{{'cut4-list.New folder' | translate}}</div>
                  </div>
                </div>
              </div>
            </ion-col>

            <ion-col
              *ngFor='let canvasGroup of cutList.canvasGroupList;let i = index'
              size='6'
              size-md='3'
              [style.padding]='app.isDesktopWidth()?"10px":"4px"'
              data-cy='canvasGroupList'
            >
              <div style='position: relative'>
                <!--    무료계정 오픈안되도록 막는 UI     -->
                <div
                  (click)='openRestrictions("7")'
                  class='block p-3 open-restrictions'
                  *ngIf='!app.isPaidMember'
                  style='cursor: pointer; position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0.2; z-index: 1'
                ></div>
                <div
                  #folder
                  (click)='canvasGroupClick(cutList.isTrashView,canvasGroup)'
                  (mouseup)='dropMouseupCanvasGroup(canvasGroup, i, folder)'
                  (mouseover)='dropCanvasGroupChange(folder)'
                  (mouseleave)='dropleaveCanvas(folder)'
                  class='block p-3'
                  matRipple
                  style='background: #ffffff; cursor: pointer'
                >
                  <ion-icon name='folder-open-outline'
                            style='position: absolute;font-size: 23px;top: 25px;left: 21px;'></ion-icon>
                  <ion-icon name='cloudy-outline'
                            *ngIf='canvasGroup.share'
                            class='cloudy-icon'></ion-icon>
                  <div
                    [style.max-width]="app.isDesktopWidth()?'160px':'70px'"
                    style='position: absolute; top: 24px; left: 50px; text-align: left; overflow: hidden'
                  >
                    <div
                      style='
                        font-size: 16px;
                        color: #333333;
                        display: inline-block;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      '
                    >
                      {{canvasGroup.groupName}}
                    </div>
                    <div
                      style='font-size: 15px; color: #7c7c7c; display: inline-block; width: 100%; white-space: nowrap; overflow: hidden'>
                      {{canvasGroup.canvasCount}}{{'cut4-list.task(s)' | translate}}
                    </div>
                  </div>
                  <img
                    (click)='$event.stopPropagation();'
                    [matMenuTriggerFor]='cutList.isTrashView?menucanvasGroupInRecycleBin:menucanvasGroup'
                    matRipple
                    src='/assets/4cut-make-manual/panel_btn_1.svg'
                    style='position: absolute; right: 8px; top: 15px'
                    alt='panel button 1'
                    data-cy='folderMeatball'
                  />
                </div>
              </div>
            </ion-col>
            <ion-col *ngIf='!cutList.isTrashView' size='6' size-md='3'
                     [style.padding]='app.isDesktopWidth()?"10px":"4px"'>
              <div
                #trashCan
                (click)='canvasGroupEnter(null)'
                (mouseup)='dropMovecanvasGroup()'
                class='block p-3'
                (mouseover)='dropCanvasGroupChange(trashCan)'
                (mouseleave)='dropleaveCanvas(trashCan)'
                matRipple
                style='background: #ffffff; cursor: pointer'
              >
                <ion-icon name='trash-outline'
                          style='position: absolute; font-size: 23px; top: 25px; left: 21px'></ion-icon>
                <div
                  style='position: absolute; top: 24px; left: 50px; text-align: left; max-width: 160px; overflow: hidden'>
                  <div
                    style='font-size: 16px; color: #333333; display: inline-block; width: 100%; white-space: nowrap; overflow: hidden'>
                    {{'cut4-list.Trash' | translate}}
                  </div>
                  <div
                    *ngIf='!cutList.isTrashView'
                    style='font-size: 15px; color: #7c7c7c; display: inline-block; width: 100%; white-space: nowrap; overflow: hidden'
                  >
                    {{recycleBinCount}}{{'cut4-list.item(s)' | translate}}
                  </div>
                </div>
                <img
                  (click)='$event.stopPropagation();'
                  [matMenuTriggerFor]='menuTrash'
                  matRipple
                  src='/assets/4cut-make-manual/panel_btn_1.svg'
                  style='position: absolute; right: 8px; top: 15px'
                  alt='panel button 1'
                  data-cy='trashMeatball'
                />
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>

    <!--  하단 작업물 리스트   -->
    <div class='container p-1 pt-2 mt-5'>
      <div class='d-flex align-items-center justify-content-between'>
        <ion-label class='ml-1'
                   style='font-size: 20px; font-weight: 500; color: #242424'>{{'cut4-list.Work List' | translate}}</ion-label>
        <div class='d-flex float-right' style='z-index: 1000;'>
          <ion-button *ngIf='!app.isDesktopWidth()' slot='icon-only' fill='clear' (click)='changeEditMode($event)'>
            <ion-icon name='checkmark-outline'></ion-icon>
          </ion-button>
          <ion-button *ngIf='!cutList.isTrashView' slot='icon-only' fill='clear' [disabled]='isCanvasEditMode'
                      [matMenuTriggerFor]='menuSort'
                      (click)='isSortMenu=!isSortMenu'>
            <img *ngIf='!app.isDesktopWidth()' src='/assets/4cut-list/filter_outline.png' width='20' alt='filter icon'>
            <div *ngIf='app.isDesktopWidth()'
                 style='font-size: 16px;font-weight: 400;color: #828282;'>{{selectedOrderBy | translate}}</div>
            <div *ngIf='app.isDesktopWidth()' class='pl-2'>
              <i class='fas fa-angle-down' style='font-size: 14px; margin-top: 4px; color:#151515'></i>
            </div>
          </ion-button>
        </div>
      </div>
      <div slot='content'>
        <!-- 리스트 -->
        <div
          style='background: #f9f9f9'
          [ngClass]="app.canvasList.length === 0 && readyToRender?'container p-1 pt-2 d-flex align-items-center justify-content-center':'container p-1 pt-2'"
        >
          <ngx-masonry
            #masonry
            (itemsLoaded)='itemsLoadedComplete()'
            (layoutComplete)='reloadMasonryLayout()'
            *ngIf='app.canvasList.length !== 0 &&readyToRender '
            [options]='masonryOptions'
            [ordered]='true'
            cdkDropList
            cdkDropListSortingDisabled
            updateLayout
            data-cy='cut4ListMasonry'
          >
            <div
              (cdkDragEnded)='dropEnd($event)'
              (cdkDragStarted)='dropStart(item,i)'
              (cdkDragMoved)='dropMoved($event)'
              *ngFor='let item of app.canvasList;let i = index'
              [cdkDragDisabled]='cutList.isCanvasGroupView || cutList.isTrashView ||!app.isDesktopWidth() || isCanvasEditMode || app.isTablet() || app.isTableWidth() || !app.isPaidMember'
              [contentDisable]='isClickCanvas'
              [style.padding]='app.isDesktopWidth()?"10px":"4px"'
              appDisabled
              cdkDrag
              class='masonry-item cdkDrag-box'
              ngxMasonryItem
              data-cy='canvasList'
              style='background: #f9f9f9'
            >
              <div class='d-flex justify-content-between' data-cy='canvasTitle'>
                <ion-label
                  *ngIf='item.title != null'
                  id='canvasTitle{{i}}'
                  style='
                      padding-top: 10px;
                      width: calc(100% - 56px);
                      display: inline-block;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    '
                >{{item.title}}</ion-label
                >
                <div class='d-flex justify-content-end' data-cy='canvasTemplate'>
                  <img
                    (click)="id=item.id;createTemplate('basic')"
                    *ngIf='item.template && user.role === UserRole.template'
                    src='/assets/template/btn_{{item.template.status}}.svg'
                    style='margin: auto; display: block'
                    alt='{{item.template.status}} button'
                    type='button'
                  />
                  <img
                    (click)="id=item.id; createTemplate('basic')"
                    *ngIf='item.template && user.role === UserRole.meme'
                    src='/assets/template/btn_{{item.template.meme.status}}.svg'
                    alt='{{item.template.status}} button'
                    style='margin: auto; display: block'
                    type='button'
                  />
                  <img
                    (click)="id=item.id;createTemplate('text')"
                    *ngIf='item.textTemplate'
                    src='/assets/template/btn_{{item.textTemplate.status}}.svg'
                    alt='{{item.textTemplate.status}} button'
                    style='margin: auto; display: block'
                    type='button'
                  />
                  <img
                    data-cy='canvasMenu'
                    (click)='title=item.title;id=item.id;authorization=item.authorization; templateData = item.template; textTemplateData = item.textTemplate; listIndex=i; setUpdatedDate(item)'
                    [matMenuTriggerFor]='cutList.isTrashView? menuCanvasInRecycleBin:menu'
                    id='canvasMenu_{{i}}'
                    src='/assets/4cut-make-manual/panel_btn_1.svg'
                    alt='panel button'
                    style='margin: auto; display: block; width: 56px; height: 40px'
                    type='button'
                  />
                </div>
              </div>
              <div [style.background]='letters[i % letters.length]' style='border-radius: 8px; overflow: hidden'>
                <div style='position: relative'>
                  <!--    무료계정 오픈안되도록 막는 UI     -->
                  <div class='open-restrictions' (click)='openRestrictions()'
                       *ngIf='(cutList.isCanvasGroupView&&!app.isPaidMember) || (i > 2&&!app.isPaidMember)'>
                  </div>
                  <div (mouseleave)='onOut($event, item)' (mouseover)='onOver($event, item)'
                       class='position-relative' data-cy='workCheckBox'>
                    <ion-checkbox *ngIf='(item.checkBoxVisible||isCanvasEditMode) && !isDropMoved'
                                  class='position-absolute' style='left: 5px;top: 5px;' slot='start'
                                  [(ngModel)]='item.isChecked' (click)='checkedCanvas(item)'
                                  data-cy='workCheckBoxClick'
                    ></ion-checkbox>
                    <img
                      longPress (mouseLongPress)='isCanvasEditMode = !app.isDesktopWidth();'
                      (click)='title=item.title;id=item.id;authorization=item.authorization; templateData = item.template; textTemplateData = item.textTemplate; listIndex=i; canvasClick(cutList.isTrashView,item, i);'
                      matRipple
                      src='{{item.base64}}'
                      alt='{{item.title}} image'
                      oncontextmenu='return false'
                      style='margin: auto; display: block;-webkit-touch-callout:none '
                      type='button'
                    />
                  </div>
                </div>

              </div>
            </div>
          </ngx-masonry>

        </div>

        <div class='d-flex justify-content-center' style='margin-top: 24px'
             *ngIf='app.canvasList.length !== 0 && !cutList.isTrashView'>
          <div appPaginationExtend class='my-pagination align-content-center' #p='appPaginationExtend'
               [instance]='paginationConfig' [maxSize]='app.isDesktopWidth()?10:5'
               (pageChange)='pageChangeEvent($event)'
               (pageBoundsCorrection)='pageChangeEvent($event)'>

            <img src='assets/4cut-list/arrow_icon_left.svg' class='pagination-previous' (click)='p.previous()'
                 alt='left arrow icon'
                 [style.visibility]="p.isFirstPage()? 'hidden':'visible'">
              <li *ngFor='let page of p.pages;' style='margin: 1px 8px; cursor:pointer'>
                <a class='ng-star-inserted' (click)='p.setCurrent(page.value)' *ngIf='p.getCurrent() !== page.index'>
                  <span>{{ page.label}}</span>
                </a>
                <a class='current' *ngIf='p.getCurrent() === page.index'>
                  <span>{{ page.label }}</span>
                </a>
              </li>
            <img src='assets/4cut-list/arrow_icon_right.svg' (click)='p.next() ' class='pagination-next'
                 alt='right arrow icon'
                 [style.visibility]="p.isLastPage()? 'hidden': 'visible'">

          </div>
        </div>
        <!--  결과 없을때 또는 리스트가 0 일때  -->
        <div *ngIf='app.canvasList.length === 0 && readyToRender' class='mt-auto text-center p-5'
             style='background: #f9f9f9'>
          <!--        아무것도 없을때            -->
          <div *ngIf='searchText === "" && !isShowSelectBox && !cutList.isTrashView' data-cy='emptyList'>
            <img class='img-fluid' src='/assets/4cut-list/3d_list_noitem.png' style='max-width: 220px'
                 alt='no item image' />
            <div *ngIf='!cutList.isCanvasGroupView && !cutList.isTrashView' class='h2-responsive font-weight-bold'
                 style='margin-top: 20px'>
              {{'cut4-list.noitem 1' | translate}}
            </div>
            <div *ngIf='cutList.isCanvasGroupView && !cutList.isTrashView' class='h2-responsive font-weight-bold'
                 style='margin-top: 20px'>
              {{'cut4-list.noitem 3' | translate}}
            </div>
            <div class='h4-responsive'
                 style='margin-top: 20px; margin-bottom: 20px; color: #505050'>{{'cut4-list.noitem 2' | translate}}</div>
            <button (click)='cut.canvasNewMake()' class='ion-btn-style' matRipple data-cy='start'>{{ 'start' | translate }}
            </button>
          </div>
          <!--        검색결과 없을때            -->
          <div *ngIf='searchText !== "" ' class='notFound-img-wrapper' data-cy='searchEmptyList'>
            <div>
              <img class='img-fluid' src='/assets/4cut-list/3d_notExistResult.png' style='max-width: 280px'
                   alt='no item image' />
            </div>
            <div class='h2-responsive font-weight-bold'>{{'not match word' | translate}}</div>
            <div class='h4-responsive mt-3'
                 style='margin-top: 20px; margin-bottom: 20px; color: #505050'>{{'search other' | translate}}</div>
            <ion-button data-cy='goBack' (click)='reLoad()' class='ion-btn-style' matRipple
                        mode='ios'>{{ 'go back' | translate }} </ion-button>
          </div>
          <!--휴지통에 아무것도 없을때-->
          <div *ngIf='cutList.isTrashView && cutList.canvasGroupList.length ===0' class='notFound-img-wrapper'
               data-cy='recycleBinEmptyList'>
            <div>
              <img class='img-fluid recyclingBinEmpty' src='/assets/4cut-list/3d_recyclebinEmpty.png'
                   alt='recyclebin is empty' />
            </div>
            <div class='h2-responsive font-weight-bold'>{{'cut4-list.recycleBinEmpty' | translate}}</div>
            <div class='h4-responsive mt-3' style='margin-top: 20px; margin-bottom: 20px; color: #505050'>
              {{'cut4-list.Trash_text3' | translate}}
            </div>
            <button data-cy='goBack' (click)='reLoad()' class='ion-btn-style' matRipple>{{ 'go back' | translate }} </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</ion-content>
<!--  생성 -->
<ion-fab
  *ngIf='!isCanvasEditMode'
  [style.bottom.px]='app.isDesktopWidth()?30:20'
  [style.right.px]='app.isDesktopWidth()?20:10'
  horizontal='end'
  slot='fixed'
  vertical='bottom'
>
  <ion-fab-button
    (click)='cut.canvasNewMake()'
    [style.height.px]='app.isDesktopWidth()?60:45'
    [style.width.px]='app.isDesktopWidth()?60:45'
    color='main'
  >
    <ion-icon name='add-outline'></ion-icon>
  </ion-fab-button>
</ion-fab>
<ion-toolbar *ngIf='isCanvasEditMode'
             [class]='app.isDesktopWidth()? "work-list-floating-btn":"work-list-floating-btn-mobile"'>
  <ion-buttons class='justify-content-between' style='padding: 0 24px'>


    <div class='d-flex align-items-center overflow-hidden w-100' style='white-space:nowrap; flex: 1 1 0; gap: 16px;'>
      <ion-label *ngIf='app.isDesktopWidth()' class='selected-items'>
        {{countCheckedCount}}
        {{'selected items' | translate}}
      </ion-label>
      <button
        mat-ripple
        class='check-canvas'
        [style]='app.isDesktopWidth()?"font-size:16px;":"font-size:12px;"'
        (click)='checkedAllCanvas()'>{{ canvasCheckedAll | translate}}</button>
    </div>

    <div class='d-flex' [class]='app.isDesktopWidth()?"justify-content-center":"justify-content-end"'
         style='flex: 0 0 0; gap: 10px'>
      <ion-button *ngIf='readyToRender&&!cutList.isTrashView&&app.isPaidMember' slot='icon-only'
                  (click)='moveCanvasGroupAlertRadio()' matTooltip='{{"cut4-list.Move folder" | translate}}'
                  data-cy='mouseOverMoveToFolder'>
        <ion-icon color='main' name='folder-open-outline'></ion-icon>
      </ion-button>
      <ion-button *ngIf='readyToRender&&!cutList.isTrashView && user.role !== UserRole.demo && app.isPaidMember'
                  slot='icon-only'
                  (click)='canvasSubmit(CanvasCheck.Multi)' matTooltip='{{"Copy To Folder" | translate}}'
                  data-cy='mouseOverCopyToFolder'>
        <ion-icon color='main' name='cloudy-outline'></ion-icon>
      </ion-button>
      <ion-button *ngIf='readyToRender&&cutList.isTrashView' slot='icon-only'
                  (click)='restoreCanvasesFromRecycleBin()' matTooltip='{{"cut4-list.restore" | translate}}'>
        <ion-icon color='main' name='refresh-outline'></ion-icon>
      </ion-button>
      <ion-button *ngIf='readyToRender' slot='icon-only'
                  (click)='deleteCanvases()' matTooltip='{{"delete" | translate}}'>
        <ion-icon color='main' name='trash-outline'></ion-icon>
      </ion-button>
    </div>

    <div class='d-flex justify-content-end'
         [style]='app.isDesktopWidth()?"flex: 1":"flex: 0"'>
      <ion-img *ngIf='!app.isDesktopWidth()'
               [src]='"/assets/4cut-make-manual/rod.svg"'></ion-img>
      <div
        *ngIf='user.role === UserRole.admin || user.role === UserRole.template'
        (click)='$event.stopPropagation();'
        [matMenuTriggerFor]='menuReplacer'
        matRipple
        style='cursor: pointer;'
        matTooltip="{{ 'change' | translate }}"
      >
        <ion-button>
          <ion-icon name='ellipsis-horizontal-circle-outline' class='ion-icon-replacer'></ion-icon>
        </ion-button>
      </div>

      <mat-menu #menuReplacer='matMenu'>
        <button (click)='replaceAllCharacter()' class='d-flex align-items-center' mat-menu-item>
          <span class='matMenu-replacer'>{{ 'replacer.character.changeAllCharacter' | translate }}</span>
        </button>
        <button (click)='replaceAllText()' class='d-flex align-items-center' mat-menu-item>
          <span class='matMenu-replacer'>{{ 'replacer.text.changeAllText' | translate }}</span>
        </button>
      </mat-menu>
      <ion-button size='large' (click)='closeExtendFab()'>
        <ion-icon color='main' size='large' name='close-outline'></ion-icon>
      </ion-button>
    </div>

  </ion-buttons>
</ion-toolbar>
<mat-menu #menu='matMenu'>
  <div class='canvas-matMenu'>
    <div [ngClass]="this.app.usedLanguage === languageType.fr ? 'matMenu-title-fr' : 'matMenu-title'"
         [ngStyle]="{'margin-top.px': this.app.usedLanguage === languageType.fr ? 16 : ''}">
      {{title}}
    </div>
    <div class='matMenu-updatedDate'>
      {{updatedDate}}
    </div>
  </div>
  <hr class='m-0 p-0' />

  <button (click)='openModalChangeCanvasTitle()' mat-menu-item data-cy='canvasChangeTitle'>
    <ion-icon class='matMenu_icon' name='create-outline'></ion-icon>
    <span class='matMenu_span'>{{'change title' | translate}}</span>
  </button>
  <button (click)='openModalView()' mat-menu-item data-cy='canvasPreview'>
    <ion-icon class='matMenu_icon' name='easel-outline'></ion-icon>
    <span class='matMenu_span'>{{'preview' | translate}}</span>
  </button>
  <button (click)='copied()' mat-menu-item data-cy='canvasCopyLink'>
    <ion-icon class='matMenu_icon' name='share-social-outline'></ion-icon>
    <span class='matMenu_span'>{{'Share Link' | translate}}</span>
  </button>
  <button (click)='canvasSubmit(CanvasCheck.Single)' mat-menu-item
          *ngIf='user.role !== UserRole.demo && app.isPaidMember' data-cy='copyToFolder'>
    <ion-icon class='matMenu_icon' src='/assets/4cut-make-manual/share-folder.svg'></ion-icon>
    <span class='matMenu_span'>{{ 'Copy To Folder' | translate }}</span>
  </button>
  <button (click)='canvasClone()' mat-menu-item data-cy='canvasClone'>
    <ion-icon class='matMenu_icon' name='copy-outline'></ion-icon>
    <span class='matMenu_span'>{{'copy2' | translate}}</span>
  </button>
  <button *ngIf='app.isPaidMember' (click)='moveCanvasGroupAlertRadio()' mat-menu-item data-cy='moveToFolder'>
    <ion-icon class='matMenu_icon' name='folder-open-outline'></ion-icon>
    <span class='matMenu_span'>{{'cut4-list.Go to folder' | translate}}</span>
  </button>
  <button (click)="createTemplate('basic')" *ngIf='canCreateTemplate()' mat-menu-item>
    <ion-icon class='matMenu_icon' name='receipt-outline'></ion-icon>
    <span class='matMenu_span'>{{'template setting' | translate}}</span>
  </button>
  <button (click)="createTemplate('text')" *ngIf='cutList.isTextTemplate' mat-menu-item>
    <ion-icon class='matMenu_icon' name='receipt-outline'></ion-icon>
    <span class='matMenu_span'>{{'template setting' | translate}}</span>
  </button>
  <button (click)="clickRemove('work')" id='canvasRemove' mat-menu-item data-cy='canvasRemove'>
    <ion-icon class='matMenu_icon' name='trash-outline'></ion-icon>
    <span class='matMenu_span'>{{'DELETE.work' | translate}}</span>
  </button>
  <ion-item *ngIf='(cutList.isTemplate || cutList.isMeme) && templateData' lines='none' class='w-100 px-3'
            id='templateStatus'>
    <ion-label>투닝 발행</ion-label>
    <ion-toggle (ionChange)='templatePublish($event)' [(ngModel)]='templateData.status'
                (click)='$event.stopPropagation();' name='status'
                slot='end'></ion-toggle>
  </ion-item>
  <ion-item *ngIf='cutList.isMeme && templateData' class='w-100 px-3 pb-3' id='memeStatus'>
    <ion-label>삼성 발행</ion-label>
    <ion-toggle (ionChange)='templatePublish($event, true)' [(ngModel)]='templateData.meme.status'
                (click)='$event.stopPropagation();' name='status'
                slot='end'></ion-toggle>
  </ion-item>
  <ion-item *ngIf='cutList.isTextTemplate && textTemplateData' class='w-100 p-3' id='textStatus'>
    <ion-label>발행</ion-label>
    <ion-toggle (ionChange)='templatePublish($event)' [(ngModel)]='textTemplateData.status' name='status'
                slot='end'></ion-toggle>
  </ion-item>
</mat-menu>
<mat-menu #menuTrash='matMenu'>
  <button (click)="clickRemove('trash')" mat-menu-item data-cy='emptyTrashMeatball'>
    <span>{{'cut4-list.Empty Trash' | translate}}</span>
  </button>
</mat-menu>

<mat-menu #menuSort='matMenu'>
  <div *ngFor='let item of orderByList'>
    <button (click)='changeOrderBy(item)' mat-menu-item>
      <div
        [style.color]='selectedOrderBy===item.orderBy?"var(--tooning-brand-color-1)":"#333333"'>{{item.orderBy| translate}}</div>
    </button>
  </div>
</mat-menu>

<mat-menu #menucanvasGroup='matMenu'>
  <div
    class='px-2'
    style='text-align: center; height: 50px; width: 100%; line-height: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis'
  >
    {{cutList.canvasGroupName}}
  </div>
  <hr class='m-0 p-0' />

  <button (click)='openModalCanvasGroup(false)' mat-menu-item>
    <ion-icon class='matMenu_icon' name='create-outline'></ion-icon>
    <span class='matMenu_span'>{{'change title' | translate}}</span>
  </button>
  <button (click)='sharingSettingAlert(cutList.canvasGroupList[cutList.canvasGroupIndex])' mat-menu-item
          data-cy='sharingSetting'>
    <ion-icon class='matMenu_icon' name='person-add-outline'></ion-icon>
    <span class='matMenu_span'>{{ 'share-folder.Sharing settings' | translate }}</span>
  </button>
  <button (click)="clickRemove('canvasGroup')" mat-menu-item data-cy='removeFolder'>
    <ion-icon class='matMenu_icon' name='trash-outline'></ion-icon>
    <span class='matMenu_span'>{{'DELETE.canvasGroup' | translate}}</span>
  </button>
</mat-menu>

<mat-menu #menucanvasGroupInRecycleBin='matMenu'>
  <div
    class='px-2'
    style='text-align: center; height: 50px; width: 100%; line-height: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis'
  >
    {{cutList.canvasGroupName}}
  </div>
  <hr class='m-0 p-0' />

  <button (click)="restoreFromRecycleBin('canvasGroup')" mat-menu-item>
    <ion-icon class='matMenu_icon' name='refresh-outline'></ion-icon>
    <span class='matMenu_span'>{{'cut4-list.restore' | translate}}</span>
  </button>
  <button (click)="clickRemoveForever('canvasGroup')" mat-menu-item>
    <ion-icon class='matMenu_icon' name='trash-outline'></ion-icon>
    <span class='matMenu_span'>{{'DELETE.canvasGroup' | translate}}</span>
  </button>
</mat-menu>

<mat-menu #menuCanvasInRecycleBin='matMenu'>
  <div
    class='px-2'
    style='text-align: center; height: 50px; width: 100%; line-height: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis'
  >
    {{title}}
  </div>
  <hr class='m-0 p-0' />

  <button (click)="restoreFromRecycleBin('work')" mat-menu-item>
    <ion-icon class='matMenu_icon' name='refresh-outline'></ion-icon>
    <span class='matMenu_span'>{{'cut4-list.restore' | translate}}</span>
  </button>
  <button (click)="clickRemoveForever('work')" mat-menu-item>
    <ion-icon class='matMenu_icon' name='trash-outline'></ion-icon>
    <span class='matMenu_span'>{{'delete' | translate}}</span>
  </button>
</mat-menu>

<!--폴더 모달-->
<ion-modal
  *ngIf='readyToRender'
  #modalCanvasGroup
  (ionModalDidDismiss)='app.modal=null; cut.setKeyActivation = true'
  (ionModalWillPresent)='willPresentAddCanvasGroup()'
  style='--width: 250px; --height: 200px; --border-radius: 4px'
>
  <ng-template>
    <div *ngIf='isAddModal' class='ion-modal-title'>{{'cut4-list.Create folder' | translate}}</div>
    <div *ngIf='!isAddModal' class='ion-modal-title'>{{'cut4-list.changeName' | translate}}</div>
    <ion-item class='pr-4 pl-4'>
      <ion-label position='floating'>{{'cut4-list.folderName'|translate}}</ion-label>
      <ion-input *ngIf='isAddModal' class='ion-modal-input' (click)='select($event)'
                 type='text' placeholder='{{"cut4-list.folderName"|translate}}'
                 [(ngModel)]='cutList.addCanvasGroupName'></ion-input>
      <ion-input *ngIf='!isAddModal' class='ion-modal-input' (click)='select($event)'
                 type='text' placeholder='{{"cut4-list.folderName"|translate}}'
                 [(ngModel)]='cutList.canvasGroupName'></ion-input>
    </ion-item>
    <ion-buttons class='w-100 justify-content-end' style='padding: 8px'>
      <ion-button class='ion-modal-button'
                  (click)='modalCanvasGroup.dismiss()'>{{'cancel' | translate}}</ion-button>
      <ion-button class='ion-modal-button'
                  [disabled]='isAddModal?cutList.addCanvasGroupName==="":cutList.canvasGroupName===""'
                  (click)='isAddModal?addCanvasGroup():cutList.canvasGroupTitleChange(modalCanvasGroup)'
      >{{'apply' | translate}}</ion-button>
    </ion-buttons>
  </ng-template>
</ion-modal>

<!--작업 title 변경 모달-->
<ion-modal
  *ngIf='readyToRender'
  #modalCanvasChangeTitle
  (ionModalDidDismiss)='app.modal=null; cut.setKeyActivation = true'
  (ionModalWillPresent)='willPresentChangeCanvasTitle()'
  style='--width: 250px; --height: 200px; --border-radius: 4px'
>
  <ng-template>
    <div class='ion-modal-title'>{{'change title' | translate}}</div>
    <ion-item class='pr-4 pl-4'>
      <ion-label position='floating'>{{'title'|translate}}</ion-label>
      <ion-input class='ion-modal-input' (click)='select($event)'
                 type='text' placeholder='{{canvasChangeTitle}}'
                 [(ngModel)]='canvasChangeTitle' data-cy='inputCanvasTitle'></ion-input>
    </ion-item>
    <ion-buttons class='w-100 justify-content-end' style='padding: 8px'>
      <ion-button class='ion-modal-button'
                  (click)='modalCanvasChangeTitle.dismiss()'>{{'cancel' | translate}}</ion-button>
      <ion-button class='ion-modal-button'
                  id='titleChangeModalApproveBtn'
                  [disabled]='canvasChangeTitle===""'
                  (click)='titleChange()'
                  data-cy='changeTitleApplyButton'
      >{{'apply' | translate}}</ion-button
      >
    </ion-buttons>
  </ng-template>
</ion-modal>