import { Command } from './command';
import { Loading } from '../../services/loading';
import { TooningPagesDeleteError } from '../../pages-tooning/errors/TooningErrors';
import * as _ from 'lodash';
import { PagesRemoveCommandData } from '../../interfaces/app.interface';

/**
 * Class PagesRemoveCommand
 * @extends Command
 */
class PagesRemoveCommand extends Command {
  protected indexList: number[] = [];
  protected pages: any[] = [];
  protected pageIdList: number[];
  protected userId: number;
  protected canvasId: number;
  protected callback;
  protected indexAndPageMap: any;
  protected restorePageIndex: number;

  /**
   * Create PagesRemoveCommand
   * @param {PagesRemoveCommandData} data
   */
  constructor(data: PagesRemoveCommandData) {
    super(data);
    this.pageIdList = data.pageIdList;
    this.commandName = 'Pages-Remove-Command';
    this.pages = this.cut.pageList.filter((item, index) => {
      if (item.isChecked === true) {
        this.indexList.push(index);
        return true;
      }
    });
    this.indexAndPageMap = _.zip(this.indexList, this.pages);
    this.userId = data.userId;
    this.canvasId = data.canvasId;
    this.callback = data.callback;
    this.restorePageIndex = data.restorePageIndex;
  }

  /**
   * Execute PagesRemoveCommand
   */
  async execute() {
    try {
      super.execute();
      await this.callback(this.pageIdList);
      if (this.cut.pageList.length > 0) {
        this.cut.pageList.forEach((page) => (page.isChecked = false));
      }
      setTimeout(() => {
        this.cut.setShowPreviewCanvas();
      }, 100);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo PagesRemoveCommand
   */
  async redo() {
    try {
      super.redo();
      await this.callback(this.pageIdList);
      this.cut.pageList.forEach((page) => (page.isChecked = false));
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo PagesRemoveCommand
   */
  async undo() {
    try {
      super.undo();
      this.cut.isPagesRemoveCommand = true;
      if (!this.pageIdList.length) {
        console.log(`pages remove undo 할 pageIds 가 없습니다.`);
        return;
      }
      // undo시 pagesRemove할 때 생성된 page가 있으면 삭제
      if (this.cut.pageList[0].id === this.cut.addedPageId) {
        await this.cut.removePanel(0, false);
      }
      await this.restorePages(this.pageIdList);
      this.cut.pageList.forEach((page) => (page.isChecked = false));
    } catch (e) {
      throw new Error(e);
    } finally {
      this.cut.isPagesRemoveCommand = false;
    }
  }

  /**
   * Pages 를 복구 한다.
   * @param {number} pageIdList 복구하려는 page 들의 인덱스
   */
  async restorePages(pageIdList: number[]) {
    const loading = new Loading();
    await loading.showLoader('');
    try {
      await this.cut.pageService.pageMultiRestore(this.userId, this.canvasId, pageIdList).toPromise();
      this.indexAndPageMap.forEach((indexAndPage) => {
        this.cut.pageList.splice(indexAndPage[0], 0, indexAndPage[1]);
      });
    } catch (e) {
      throw new TooningPagesDeleteError(e, null, true);
    } finally {
      loading.hideLoader();
    }
    await this.cut.goPage(this.restorePageIndex);
  }
}

export { PagesRemoveCommand };
