import { Injectable } from '@angular/core';
import { ResMakerCharacterService } from './res-maker/character.service';
import { fabric } from 'fabric';
import validator from 'validator';
import {
  TooningB64toBlobError,
  TooningConvertToBase64Error,
  TooningFabricToBlobError,
  TooningResizeImageError
} from '../pages-tooning/errors/TooningErrors';
import { SafeUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import exifr from 'exifr';
import { NgxPicaService } from 'ngx-tooning-pica';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  public isBase64 = require('is-base64');

  constructor(private characterService: ResMakerCharacterService, private ngxPicaService: NgxPicaService) {}

  // blob -> file
  public blobToFile = (theBlob: object, fileName: string): File => {
    const b: any = theBlob;
    const fabricFile = new File([b], fileName);
    return fabricFile;
  };

  /**
   * Blob 을 base64 string 으로 변환하는 함수
   * @param {Blob} blob
   * @return {Promise<string>}
   */
  async blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        //@ts-ignore
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Blob 객체 또는 url을 base64 문자열로 변환하여 반환한다.
   * @param {string | Blob} data
   * @return {Promise<string>}
   * @throws {Error} 유효하지 않은 인자가 들어왔을 때 에러를 throw
   */
  async convertToBase64(data: string | Blob): Promise<string> {
    try {
      // Blob 객체인 경우
      if (data instanceof Blob) {
        return await this.blobToB64(data);
        // url 문자열인 경우 fetch 후 blob 객체를 가지고 온 후 변환
      } else if (typeof data === 'string' && data.startsWith('blob:')) {
        const response = await fetch(data);
        const blob = await response.blob();
        return await this.blobToB64(blob);
      } else {
        // custom 에러로 변경
        throw new Error('An error occurred while converting blob to base64 (Incorrect type of parameter was entered.)');
      }
    } catch (e) {
      throw new TooningConvertToBase64Error(e, null, true);
    }
  }

  /**
   * Blob 객체를 인자로 받아서 base64로 변경하는 고차함수
   * @param {Blob} blob
   * @return {Promise<string>}
   */
  async blobToB64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(reader.result as string);
      };
      reader.onerror = (event) => {
        reject(event);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * base64를 리사이즈한다.
   * @param base64Str
   * @param {number} maxWidth
   * @param {number} maxHeight
   * @return {Promise<unknown>}
   */
  async resizeImage(base64Str, maxWidth = 540, maxHeight = 540) {
    return new Promise((resolve, reject) => {
      try {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          try {
            let canvas = document.createElement('canvas');
            const MAX_WIDTH = maxWidth;
            const MAX_HEIGHT = maxHeight;
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL());
            canvas = null;
            img = null;
          } catch (e) {
            reject(new TooningResizeImageError(e, null, true));
          }
        };
        img.onerror = (error) => {
          reject(error);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  // base64 -> file and blob and ArrayBuffer
  public base64ToArrayBuffer(base64Data: string): ArrayBuffer {
    try {
      const byteString = atob(base64Data.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      // ia 지우면 안된다 arraybuffer 의 view로 사용
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/ArrayBuffer
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return ab;
    } catch (e) {
      console.error(`base64ToArrayBuffer : ${e.message}`);
      throw e;
    }
  }

  public async b64toFile(dataURI, baseName, type = 'text/plain'): Promise<File> {
    try {
      let base64Data: string;
      if (!dataURI) {
        throw new Error('dataURI is empty');
      }

      const base64WithoutMime = this.removeMime(dataURI);
      if (validator.isBase64(base64WithoutMime)) {
        base64Data = dataURI;
      } else {
        base64Data = await this.characterService.getHttpUrl(dataURI).toPromise();
      }
      const ab = this.base64ToArrayBuffer(base64Data);
      const blob = new Blob([ab], { type: type });
      const baseFile = new File([blob], baseName, { type: type, lastModified: new Date().getTime() });
      return baseFile;
    } catch (e) {
      return null;
    }
  }

  public b64toBlobSync(dataURI: string | SafeUrl): Blob {
    try {
      let base64: string;
      if (_.isString(dataURI)) {
        base64 = dataURI as string;
      }

      if (dataURI instanceof Object) {
        // @ts-ignore
        base64 = dataURI.changingThisBreaksApplicationSecurity;
      }
      if (base64) {
        const blob = new Blob([this.base64ToArrayBuffer(base64)], { type: 'text/plain' });
        return blob;
      } else {
        throw new Error('base64 is empty');
      }
    } catch (e) {
      console.error(`b64toBlobSync error : ${e.message}`);
      throw e;
    }
  }

  /**
   * dataUri 에서 mime 정보를 제거한다. (data:image/png;base64, 제거)
   * @param {string} dataUri
   * @return {string}
   */
  public removeMime(dataUri: string): string {
    const base64Split = dataUri.split(',');
    if (base64Split.length < 2) {
      return base64Split[0];
    }

    return base64Split[1];
  }

  /**
   * base64를 blob으로 변경한다.
   * @param dataURI
   * @param baseName
   * @return {Promise<Blob>}
   */
  public async b64toBlob(dataURI, baseName): Promise<Blob> {
    try {
      let base64Data: string;
      if (!dataURI) {
        throw new Error('dataURI is empty');
      }

      const base64WithoutMime = this.removeMime(dataURI);
      if (validator.isBase64(base64WithoutMime)) {
        console.log(`b64toBlob isBase64`);
        base64Data = dataURI;
      } else {
        console.log(`b64toBlob isUrl`);
        base64Data = await this.characterService.getHttpUrl(dataURI).toPromise();
      }
      const blob = new Blob([this.base64ToArrayBuffer(base64Data)], { type: 'text/plain' });
      return blob;
    } catch (e) {
      throw new TooningB64toBlobError(e);
    }
  }

  public async b64toImageBlob(dataURI, fileType): Promise<Blob> {
    try {
      return new Blob([this.base64ToArrayBuffer(dataURI)], { type: fileType });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  // fabric -> File
  // tslint:disable-next-line:no-shadowed-variable
  public fabricToFile(fabric) {
    const json = JSON.stringify(fabric);
    const fabricObjectBlob = new Blob([json], {
      type: 'application/json'
    });
    const fabricFile = this.blobToFile(fabricObjectBlob, 'fabric');
    return fabricFile;
  }

  // tslint:disable-next-line:no-shadowed-variable
  public fabricToBlob(fabric: object | string) {
    try {
      let json: string;
      if (typeof false !== 'string') {
        json = JSON.stringify(fabric);
      }
      const fabricObjectBlob = new Blob([json], {
        type: 'application/json'
      });

      return fabricObjectBlob;
    } catch (e) {
      throw new TooningFabricToBlobError(e);
    }
  }

  public getImageFromBase64(dataURL: string): Promise<fabric.Image> {
    return new Promise((resolve, reject) => {
      try {
        fabric.Image.fromURL(dataURL, (img) => {
          resolve(img);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * png base64를 jpeg base64로 변환
   * @param {string} pngBase64
   * @param {number} quality
   * @return {Promise<string>}
   */
  convertPNGtoJPEG(pngBase64: string, quality: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              const result = reader.result as string;
              resolve(result.split(',')[1]);
            };
            reader.onerror = (error) => {
              reject(error);
            };
            reader.readAsDataURL(blob);
          },
          'image/jpeg',
          quality
        );
      };
      img.onerror = function (error) {
        reject(error);
      };
      img.src = pngBase64;
    });
  }

  async getEXIFOrientation(file: Blob): Promise<number> {
    try {
      let data: number | undefined = await exifr.orientation(file);
      if (data === undefined) {
        data = 0;
      }
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  async getResizedImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const resizeTargetMaxLength = 1080;
      let img = new Image();
      img.setAttribute('crossorigin', 'anonymous'); // works for me
      img.onloadstart = () => {};
      img.onloadeddata = () => {};
      img.onprogress = () => {};
      img.onload = async () => {
        const exifOrientation: number = await this.getEXIFOrientation(file);
        const targetLength = resizeTargetMaxLength;
        let width = img.width;
        let height = img.height;

        let targetWidth;
        let targetHeight;

        switch (+exifOrientation) {
          case 1:
          case 6: // 일반적으로 세워서 찍은 경우
          case 8: // upside down 으로 찍은 경우
            targetHeight = targetLength;
            targetWidth = targetLength * (width / height);
            const temp = targetHeight;
            targetHeight = targetWidth;
            targetWidth = temp;
            break;
          default:
            const longIsWith = width > height;
            if (longIsWith) {
              targetWidth = targetLength;
              targetHeight = targetLength * (height / width);
            } else {
              targetHeight = targetLength;
              targetWidth = targetLength * (width / height);
            }
            break;
        }

        if (width > 1080 || height > 1080) {
          try {
            const resizedImage = await this.ngxPicaService
              .resizeImage(file, targetWidth, targetHeight, {
                alpha: true,
                exifOptions: {
                  forceExifOrientation: true
                }
              })
              .toPromise();
            resolve(resizedImage);
          } catch (e) {
            reject(e);
          }
        } else {
          resolve(file);
        }
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.onabort = (error) => {
        reject(error);
      };

      img.src = URL.createObjectURL(file);
    });
  }
}
