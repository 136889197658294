import { Command } from './command';
import { ObjectLockSetCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { ResourceType } from '../../enum/app.enum';

/**
 * Class ObjectLockSetCommand
 * @extends Command
 */
class ObjectLockSetCommand extends Command {
  protected beforeIsLockList: boolean[];
  protected afterIsLockList: boolean[];

  /**
   * Create ObjectLockSetCommand
   * @param {ObjectLockSetCommandData} data
   */
  constructor(data: ObjectLockSetCommandData) {
    super(data);
    this.beforeIsLockList = data.beforeIsLockList;
    this.afterIsLockList = data.afterIsLockList;
    this.commandName = 'Object-Lock-Set-Command';
  }

  /**
   * Execute ObjectLockSetCommand
   */
  async execute() {
    try {
      super.execute();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo ObjectLockSetCommand
   * @param {TooningFabricObject[]} objects
   */
  async redo(objects: TooningFabricObject[]) {
    try {
      super.redo(objects);
      this.lockSet(objects, this.afterIsLockList);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo ObjectLockSetCommand
   * @param {TooningFabricObject[]} objects
   */
  async undo(objects: TooningFabricObject[]) {
    try {
      super.undo(objects);
      this.lockSet(objects, this.beforeIsLockList);
    } catch (e) {
      throw new Error(e);
    }
  }

  lockSet(objects, lockList) {
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (object.resource_type !== ResourceType.guideMask) {
        object.evented = !lockList[i];
        object.selectable = !lockList[i];
      }
    }
    this.cut.canvas.requestRenderAll();
  }
}

export { ObjectLockSetCommand };
