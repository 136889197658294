<div class='w-100 privacy-policy-wrapper'>
  <ion-item class='all-check-wrapper' (click)='changeCheckbox($event, 0, false)'>
    <ion-checkbox data-cy='allCheck' id='all-check' [(ngModel)]='userCheckbox[0]' (click)='changeCheckbox($event, 0, true);'></ion-checkbox>
    <ion-label class='all-check-label' text-wrap>{{'signupPage.signupinit.text-1' | translate}}</ion-label>
  </ion-item>

  <div class='detail-check-wrapper'>

    <ion-item (click)='changeCheckbox($event, 1, false)'>
      <ion-checkbox mode='md' [(ngModel)]='userCheckbox[1]' (click)='changeCheckbox($event, 1, true);'></ion-checkbox>
      <ion-label text-wrap>
        <strong>{{'signupPage.signupinit.strong' | translate}}</strong>{{'signupPage.signupinit.text-2' | translate}}
      </ion-label>
    </ion-item>

    <div *ngIf='app.usedLanguage===languageType.ko' class='parental-consent-text'>
      {{'signupPage.signupinit.text-3' | translate}}
    </div>

    <ion-item (click)='changeCheckbox($event,2, false)'>
      <ion-checkbox mode='md' [(ngModel)]='userCheckbox[2]' (click)='changeCheckbox($event, 2, true);'></ion-checkbox>
      <ion-label>
        <strong>{{'signupPage.signupinit.strong' | translate}} </strong>
        <span class='hyperlink-style' (click)='showServiceAgreement(); $event.stopPropagation()'>{{'signupPage.signupinit.text-4' | translate}}</span>
      </ion-label>
    </ion-item>

    <ion-item (click)='changeCheckbox($event,3, false)'>
      <ion-checkbox mode='md' [(ngModel)]='userCheckbox[3]' (click)='changeCheckbox($event, 3, true);'></ion-checkbox>
      <ion-label>
        <strong>{{'signupPage.signupinit.strong' | translate}} </strong>
        <span class='hyperlink-style' (click)='showPersonalInfoConsent(); $event.stopPropagation()'>{{'signupPage.signupinit.text-5' | translate}}</span>
      </ion-label>
    </ion-item>

    <ion-item (click)='changeCheckbox($event,4, false)'>
      <ion-checkbox mode='md' [(ngModel)]='userCheckbox[4]' (click)='changeCheckbox($event, 4, true);'></ion-checkbox>
      <ion-label text-wrap>
        <strong
          class='promotion-text'>{{'signupPage.signupinit.optional' | translate}}</strong> {{'signupPage.signupinit.text-6' | translate}}
      </ion-label>
    </ion-item>

  </div>
</div>