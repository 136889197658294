import { OnInit, ViewChild, Directive } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { AppService } from './services/app.service';
import { Subscription } from 'rxjs';

@Directive()
export class BasePage implements OnInit {
  // @ts-ignore
  @ViewChild(IonContent) myContent: IonContent;
  protected subscriptions: Array<Subscription> = [];
  private landingUrl = '/';

  constructor(public app: AppService) {}

  ngOnInit() {
    // this.styleScrollbars(this.myContent['el']);
  }

  ionViewDidEnter() {
    try {
      // 채널톡 기본 모든 페이지 안보이도록 설정
      this.app.channelTalkHide();
    } catch (e) {
      console.error(e);
    }
  }

  ionViewDidLeave() {
    this.app.orange(`BasePage ionViewDidLeave`);
    this.subscriptions.forEach((subscription) => {
      console.log(`BasePage ngOnDestory unsubscribe`);
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnDestroy() {
    console.log(`BasePage ngOnDestory`);
    this.subscriptions.forEach((subscription) => {
      console.log(`BasePage ngOnDestory  unsubscribe`);
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngAfterViewInit() {
    console.log(`BasePage ngAfterViewInit`);
  }
}
