import BoardAPIError from './boardApiError';
import slackBoardClientError from '../utils/slack';
import { environment } from '../../../../environments/environment';
import { Deployment } from '../../../enum/app.enum';

class BoardClientError extends Error {
  public readonly error: Error;
  private readonly functionName: string;
  constructor(error: Error, functionName: string) {
    super();
    this.error = error;
    this.functionName = functionName;
    this.logError();

    // 슬랙으로 보내기
    if (!(error instanceof BoardAPIError) && (environment.deploy === Deployment.beta || environment.deploy === Deployment.oper))
      slackBoardClientError(this.error, this.functionName).then();
  }

  private logError() {
    console.error(`[Board] ${this.error} in "${this.functionName}".`);
  }
}

export default BoardClientError;
