import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss']
})
export class AppVersionComponent implements OnInit {
  show = true;
  data: any = {};
  deploy_infor: any = {
    channel: 'channel',
    currentVersionId: 'currentVersionId',
    currentBuildId: 'currentBuildId'
  };

  constructor(public app: AppService) {}

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    const env = await this.app.getEnv();
    this.data.graphql_url = env.graphql_url;
    this.data.appVersion = env.appVersion;

    if (this.app.isApp()) {
      console.log('this.deploy_infor', JSON.stringify(this.deploy_infor));
    }
  }

  async clickChannel(target_version) {
    if (this.app.isApp()) {
      try {
        console.log('update this.deploy_infor', JSON.stringify(this.deploy_infor));
        const targetConfig: any = {
          channel: target_version || 'oper',
          appId: this.deploy_infor.appId,
          updateMethod: 'none',
          host: this.deploy_infor.host,
          maxVersions: 2
        };
        console.log('update targetConfig', targetConfig);
        console.log('after update targetConfig' + JSON.stringify(this.deploy_infor));
      } catch (error) {
        alert(error);
      }
    }
    await this.loadData();
  }
}
