import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from '../interfaces/app.interface';
import { TooningAuthGuardError } from '../pages-tooning/errors/TooningErrors';
import { ServiceType } from '../enum/app.enum';

@Injectable({
  providedIn: 'root'
})
/**
 * Guard to check if a user is authenticated and if there is a duplicate login.
 */
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private app: AppService, private router: Router, private alertController: AlertController, private translate: TranslateService) {}

  /**
   * Checks if the user is authenticated and if there is a duplicate login.
   * @param {ActivatedRouteSnapshot} route The activated route.
   * @param {RouterStateSnapshot} state The router state snapshot.
   * @returns {Promise<boolean>} A promise that resolves to true if the user is authenticated and there is no duplicate login, false otherwise.
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const redirect = state.url.replace('/', '');
    return this.canActivateCheck(redirect);
  }

  /**
   * Checks if the user is authenticated and if there is a duplicate login for child routes.
   * @param {ActivatedRouteSnapshot} next The activated route.
   * @param {RouterStateSnapshot} state The router state snapshot.
   * @returns {Observable<boolean> | Promise<boolean> | boolean} A promise that resolves to true if the user is authenticated and there is no duplicate login, false otherwise.
   */
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirect = state.url.replace('/', '');
    this.app.orange(`canActivateChild redirect : ${redirect}`);
    return this.canActivateCheck(redirect);
  }

  /**
   * Checks if the user is authenticated for lazy-loaded routes.
   * @param {Route} route The route to be loaded.
   * @param {UrlSegment[]} segments The URL segments.
   * @returns {Observable<boolean> | Promise<boolean> | boolean} True if the user is authenticated, false otherwise.
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canLoad -' + segments);
    this.app.orange(`canActivateChild canLoad : ${segments}`);

    if (this.app.user.isTokenSaved()) {
      return true;
    } else {
      this.app.go('/login');
      return false;
    }
  }

  /**
   * Checks the user information is available && duplicated login
   * @param {string} redirect The URL to redirect the user to.
   * @return {Promise<boolean>} A promise that resolves to true if the user is authenticated and there is no duplicate login, false otherwise.
   */
  async canActivateCheck(redirect: string): Promise<boolean> {
    try {
      const user: CurrentUser | null = await this.app.user.currentUser();
      if (!user) {
        this.app.cache.setRedirect(redirect);

        if (this.app.checkServiceType() === ServiceType.Magic) this.app.goQueryParams('/login', { ['isMagic']: true });
        else this.app.go('/login');

        return false;
      }

      if (await this.app.user.isDuplicateLogin()) {
        return true;
      }

      const alert = await this.alertController.create({
        cssClass: 'basic-dialog',
        header: this.translate.instant('block multi login'),
        message: this.translate.instant('multi login dectected'),
        buttons: [
          {
            text: 'Ok',
            handler: async () => {
              await this.app.logout();
              if (this.app.checkServiceType() === ServiceType.Magic) this.app.goQueryParams('/magic', { ['isMagic']: true });
              else this.app.go('/');
            }
          }
        ]
      });
      await alert.present();
      return false;
    } catch (e) {
      this.app.showToast(this.translate.instant('duplicate login error'), 3000);
      this.app.cache.setRedirect(redirect);
      this.app.go('/page404');
      throw new TooningAuthGuardError(e, this.app);
    }
  }
}
