import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// loadChildren: () => import('./pages-marketing/studio/studio.module').then((m) => m.StudioPageModule)

// @ts-ignore
const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Tooning Biz',
      helpCenter: false,
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.landing.description',
      imageUrl: 'https://tooning.io/assets/kakao/tooning2.png',
      serviceID: 'biz'
    },
    loadChildren: () => import('./pages-biz/biz-index/biz-index.module').then((m) => m.BizIndexPageModule)
  },

  // 잘못된 URL 접근시 페이지 이동
  { path: '**', redirectTo: '/page404', pathMatch: 'full' },
  {
    path: 'page404',
    loadChildren: () => import('./pages-common/page404/page404.module').then((m) => m.Page404PageModule)
  }
];

// @ts-ignore
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class BizRoutingModule {}
