import { Injectable } from '@angular/core';
import { editorDB } from './idx-fabric';
import { TooningFabricObject } from '../../interfaces/app.interface';
import { IndexedDBCmd } from '../../enum/app.enum';
import { TooningIndexedDBAddCopyData, TooningIndexedDBGetCopyData } from '../../pages-tooning/errors/TooningErrors';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {
  constructor(public app: AppService) {}

  /**
   * 복사 데이터 indexedDB에 저장
   * @param {TooningFabricObject} tooningFabricObject 복사한 데이터
   * @return {Promise<void>}
   */
  async addCopyData(tooningFabricObject: TooningFabricObject): Promise<void> {
    try {
      const newData = { cmd: IndexedDBCmd.TOONING_COPY_KEY, ...tooningFabricObject };

      // @ts-ignore
      await editorDB.tooningObjects
        .put(newData)
        .then(() => console.log('indexedDB 추가 완료!'))
        .catch((e) => console.error(e));
    } catch (e) {
      throw new TooningIndexedDBAddCopyData(e, this.app, true);
    }
  }

  /**
   * 복사 데이터 indexedDB에서 가져오기
   * @param {string} cmd indexedDB에서 찾을 때 사용할 key
   * @return {Promise<TooningFabricObject[]>}
   */
  async getCopyData(cmd: string = IndexedDBCmd.TOONING_COPY_KEY): Promise<TooningFabricObject[]> {
    try {
      return await editorDB.tooningObjects.get(cmd);
    } catch (e) {
      throw new TooningIndexedDBGetCopyData(e, this.app, true);
    }
  }
}
