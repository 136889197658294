<ion-header>
  <ion-toolbar>
    <ion-title> {{ 'share-folder.Folder sharing settings' | translate }}</ion-title>
    <ion-buttons class='m-0 mr-2' slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<div class='h-100'>
  <div class='toggle-wrapper'>
    <div class='toggle-text textOverflow' style='width: 80%'>
      {{ 'share-folder.Allow sharing' | translate }}
    </div>
    <ion-toggle slot='start' [(ngModel)]='isSharing' (ionChange)='changeIsSharing()'></ion-toggle>
  </div>

  <hr />

  <div *ngIf='!isSharing' class='d-flex justify-content-center align-items-center' style='height: 60%'>
    <div class='before-sharing-text pre'>
      {{ 'share-folder.To receive tasks delivered to the folder, please allow folder sharing.' | translate }}
    </div>
  </div>
  <div *ngIf='isSharing' class='folder-code'>
    <div class='folder-code-label'>{{ 'share-folder.Folder code' | translate }}</div>
    <div class='ui action fluid input d-flex justify-content-between align-items-center'
         style='height: 48px; border: 1px solid #E0E0E0; border-radius: 5px; padding: 12px 0 13px 16px;'>
      <div #shareCanvasId class='folder-code-text' style='color: #828282'>{{canvasGroupId}}</div>
      <button (click)='copyToClipboard(shareCanvasId)' class='ui button folder-code-text'
              style='background: var(--tooning-brand-color-1);color: white'>
        {{"hotKey.hotKey1"|translate}}
      </button>
    </div>
  </div>

</div>
