import { Directive, HostListener } from '@angular/core';
import { StringUtil } from '../../shared/utils/string';

@Directive({
  selector: '[appInputCharacters]'
})
export class InputCharactersDirective {
  @HostListener('input', ['$event'])
  onInput(event: any) {
    event.target.value = StringUtil.removeNumberics(event.target.value);
  }
}
