<ng-container>
  <div (mouseleave)='mouseLeaveEvent()'>
    <img *ngIf='imgSrc' [src]='imgSrc' alt='img'/>
    <pdf-viewer *ngIf='pdfSrc' [src]='pdfSrc'
                [rotation]='0'
                [original-size]='false'
                [show-all]='true'
                [fit-to-page]='false'
                [zoom]='1'
                [zoom-scale]="'page-width'"
                [stick-to-page]='false'
                [render-text]='true'
                [external-link-target]="'blank'"
                [autoresize]='true'
                [show-borders]='false'
                style='width: 100%; height: 1080px;'></pdf-viewer>
  </div>
</ng-container>