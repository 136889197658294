import { Command } from './command';
import { ObjectChangeCommandData } from '../../interfaces/app.interface';
import { ResourceType, SelectedType } from '../../enum/app.enum';

/**
 * Class ObjectChangeCommand
 * @extends Command
 */
class ObjectChangeCommand extends Command {
  protected priorityBeforeDataLessObjectList;
  protected callback;
  protected beforeDataLessObjectStringList;
  protected afterDataLessObjectStringList;

  /**
   * Create ObjectChangeCommand
   * @param {ObjectChangeCommandData} data
   */
  constructor(data: ObjectChangeCommandData) {
    super(data);
    this.callback = data.callback;
    this.priorityBeforeDataLessObjectList = data.priorityBeforeDataLessObjectList;
    // 슬라이더와 같은 경우 이전 값이 실시간으로 변경되는경우. 미리 저장된 값을 가지고 컨트롤 해야함.
    this.commandName = 'Object-Change-Command';
  }

  /**
   * 캔버스에 있는 object에 change가 있을 때 실행되는 command
   * 1. 타입이 activeSelection인지 multiSelection인지 한 개인지를 파악
   * 2. 변경되기 이전의 object를 JSON.stringify()로 string으로 만든 후 beforeDataLessObjectStringList에 push
   * 3. 현재의 object를 JSON.stringify()로 string으로 만든 후 afterDataLessObjectStringList에 push
   */
  async execute() {
    try {
      super.execute();
      this.beforeDataLessObjectStringList = [];
      this.afterDataLessObjectStringList = [];
      if (this.type === SelectedType.activeSelection) {
        if (this.priorityBeforeDataLessObjectList !== undefined) {
          for (const element of this.priorityBeforeDataLessObjectList) {
            const object = element;
            // @ts-ignore
            object.top = this.target.top + object.top;
            // @ts-ignore
            object.left = this.target.left + object.left;
            this.beforeDataLessObjectStringList.push(JSON.stringify(object));
          }
          await this.callback();
          let objects = await this.cut.cloneFabricObject(this.target);
          objects = objects.getObjects();
          for (const element of objects) {
            const object = element;
            // @ts-ignore
            object.top = this.target.top + object.top;
            // @ts-ignore
            object.left = this.target.left + object.left;
            this.afterDataLessObjectStringList.push(JSON.stringify(object));
          }
        } else {
          let objects = await this.cut.cloneFabricObject(this.target);
          objects = objects.getObjects();
          for (const element of objects) {
            const object = element;
            // @ts-ignore
            object.top = this.target.top + object.top;
            // @ts-ignore
            object.left = this.target.left + object.left;
            this.beforeDataLessObjectStringList.push(JSON.stringify(object));
          }
          await this.callback();
          objects = await this.cut.cloneFabricObject(this.target);
          objects = objects.getObjects();
          for (const element of objects) {
            const object = element;
            // @ts-ignore
            object.top = this.target.top + object.top;
            // @ts-ignore
            object.left = this.target.left + object.left;
            this.afterDataLessObjectStringList.push(JSON.stringify(object));
          }
        }
      } else {
        //한개 처리
        if (this.priorityBeforeDataLessObjectList !== undefined) {
          this.beforeDataLessObjectStringList = [JSON.stringify(this.priorityBeforeDataLessObjectList[0])]; // 미리 떠서 받는 경우
        } else {
          this.beforeDataLessObjectStringList = [JSON.stringify(this.target)]; // 콜백 처리도 받는 경우
        }
        const selectedOb = await this.callback();
        this.id = [selectedOb.resource_selection_id];
        this.afterDataLessObjectStringList = [JSON.stringify(selectedOb.toDatalessObject())];
        console.log('object ObjectChangeCommand execute');
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object change command의 redo command
   * 1. 타입이 activeSelection인지 multiSelection인지 한 개인지를 파악
   * 2. 다시 변경된 object를 가져오기 위해 findObjectToRedo() 함수 호출
   * @param objects
   */
  async redo(objects) {
    try {
      super.redo();
      if (this.type === SelectedType.activeSelection) {
        for (let i = 0; i < this.id.length; i++) {
          await this.findObjectToRedo(objects, i);
        }
      } else {
        await this.findObjectToRedo(objects, 0);
      }

      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object change command의 undo command
   * 1. 타입이 activeSelection인지 multiSelection인지 한 개인지를 파악
   * 2. 변경되기 이전의 object를 가져오기 위해 findObjectToUndo() 함수 호출
   * @param objects
   */
  async undo(objects) {
    try {
      super.undo(objects);
      if (this.type === SelectedType.activeSelection) {
        for (let i = 0; i < this.id.length; i++) {
          await this.findObjectToUndo(objects, i);
        }
      } else {
        await this.findObjectToUndo(objects, 0);
      }
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object change를 한 후 리소스를 구입시 waterMark를 제거해주는 함수
   * 1. beforeDataLessObjectStringList에 담겨있던 기존의 object를 꺼내서 visibel = false처리
   * 2. 다시 beforeDataLessObjectStringList에 push하여 undo 처리에도 waterMark가 보이지 않도록 처리
   * 3. afterDataLessObjectStringList에 담겨있던 기존의 object를 꺼내서 visibel = false처리
   * 4. 다시 afterDataLessObjectStringList에 push하여 redo 처리에도 waterMark가 보이지 않도록 처리
   */
  removeWaterMark() {
    try {
      this.removeWaterMarkFromList(this.beforeDataLessObjectStringList);
      this.removeWaterMarkFromList(this.afterDataLessObjectStringList);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 주어진 JSON 문자열 배열에서 마지막 객체의 워터마크를 제거
   * @param {string[]} dataLessObjectStringList
   * @returns {void}
   *
   */
  removeWaterMarkFromList(dataLessObjectStringList: string[]) {
    try {
      const fabricString = dataLessObjectStringList.pop();
      if (fabricString) {
        const fabricJson = JSON.parse(fabricString);
        const waterMark = this.cut.getLastObj(fabricJson);
        if (waterMark && waterMark.resource_type === ResourceType.waterMark) {
          waterMark.visible = false;
          dataLessObjectStringList.push(JSON.stringify(fabricJson));
          console.log(`${fabricJson.resource_id} remove water mark in  ${this.constructor.name}`);
        } else {
          console.warn(`워터 마크가 없거나 리소스 타입이 워터마크가 아닙니다 in object-change-command.ts`);
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 변경되기 이전의 object를 가져오는 함수
   * beforeDataLessObjectStringList에 담긴 이전 object를 addObFromJson()를 통해 캔버스에 출력
   * @param objects
   * @param index
   * @return {Promise<void>}
   */
  async findObjectToUndo(objects, index): Promise<void> {
    try {
      for (const object of objects) {
        if (object.resource_selection_id === this.id[index]) {
          const layerIndex = this.cut.getIndex(object);
          const ob = await this.addObFromJson(this.beforeDataLessObjectStringList[index], layerIndex);
          ob.isEditing = false;
          this.cut.canvas.remove(object);
          this.id[index] = ob.resource_selection_id; // 새로운 객체로 생성으로 ID가 새로 발급될수도 있음.
          break;
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 변경된 object를 다시 가져오는 함수
   * afterDataLessObjectStringList에 변경된 object를 addObFromJson()를 통해 캔버스에 출력
   * @param objects
   * @param index
   */
  async findObjectToRedo(objects, index) {
    try {
      for (const object of objects) {
        if (object.resource_selection_id === this.id[index]) {
          const layerIndex = this.cut.getIndex(object);
          const ob = await this.addObFromJson(this.afterDataLessObjectStringList[index], layerIndex);
          ob.isEditing = false;
          this.cut.canvas.remove(object);
          this.id[index] = ob.resource_selection_id; // 새로운 객체로 생성으로 ID가 새로 발급될수도 있음.
          break;
        }
      }
      this.cut.canvas.requestRenderAll();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * JSON.stringify()를 통해 string으로 변경되어 저장된 object를 다시 parse해서 object로 변경하고 캔버스에 출력
   * @param data
   * @param layerIndex
   */
  async addObFromJson(data, layerIndex) {
    return await this.cut.addObFromJson([JSON.parse(data)], false, false, true, null, layerIndex);
  }
}

export { ObjectChangeCommand };
