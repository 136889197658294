<ion-header class='ion-no-border'>
  <ion-toolbar>
    <ion-title slot='start'>{{'paymentWords.defaultPayment' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content force-overscroll='true'>
  <ion-list>
    <ion-radio-group [value]='checkedValue'>
      <div *ngFor='let input of alertInput'>
        <ion-item (click)='radioClick(input.paymentType)'>
          <ion-radio slot='start' [value]='input.paymentType'></ion-radio>
          <ion-label>{{'paymentType.' + input.paymentType | translate}}</ion-label>
        </ion-item>
      </div>
    </ion-radio-group>
  </ion-list>

</ion-content>
<ion-footer class='ion-no-border'>
  <ion-item>
    <ion-label style='margin-right: 24px;' class='footer' slot='end' (click)='cancelClick()'>{{'cancel'|translate}}</ion-label>
    <ion-label class='footer' slot='end' (click)='applyClick()'>{{'apply' | translate}}</ion-label>
  </ion-item>
</ion-footer>