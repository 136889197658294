import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { AppService } from '../../services/app.service';
import {
  TooningAllocateTimeUnitsError,
  TooningGetTimeDifferenceError,
  TooningModifyNumberError,
  TooningNotificationCloseError
} from '../../pages-tooning/errors/TooningErrors';
import { UpdateService } from '../../services/update/update.service';
import * as moment from 'moment';
import { Locale, LanguageType } from '../../enum/app.enum';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() timeDifference: number;
  @ViewChild('notificationAlert') public notificationAlert;
  // @ViewChild(IonSlides) slides: IonSlides;

  slideOpts = {
    initialSlide: 0,
    slidesPerView: 1, // to show one slide at a time
    speed: 400,
    loop: true, // to loop through slides
    autoplay: {
      delay: 5000
    },
    slideShadows: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };

  public subscription: Subscription;

  private milliSecondsInASecond = 1000;
  private hoursInADay = 24;
  private minutesInAnHour = 60;
  private SecondsInAMinute = 60;

  //업데이트 예정 notification 관련
  public secondsToDday: string;
  public minutesToDday: string;
  public hoursToDday: string;
  public daysToDday: string;
  public localizedSchedule: string;

  constructor(public app: AppService, public updateService: UpdateService) {}

  ngOnInit() {
    if (this.app.isCountDownNotify) {
      const period: number = 1000;
      this.subscription = timer(0, period).subscribe((x) => {
        this.getTimeDifference();
      });
    }
    if (this.app.isMaintenanceNotify) {
      this.getStartDate();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async ngAfterViewInit() {
    // await this.slides.startAutoplay();
  }

  /**
   * 공지 닫기 버튼을 눌렀을 때 공지를 숨겨줌
   * @return {void}
   */
  notificationClose(): void {
    try {
      this.notificationAlert.hidden = true;
      this.app.isSessionClose = true;
      sessionStorage.setItem('sessionClose', 'true');
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    } catch (e) {
      throw new TooningNotificationCloseError(e);
    }
  }

  /**
   * 업데이트 날짜 기간을 설정해주는 함수
   * @return {{void}}
   */
  getStartDate(): void {
    const startDate: moment.Moment = moment(this.app.startMaintenanceDate);
    const endDate: moment.Moment = moment(this.app.dueMaintenanceDate);

    let locale;
    switch (this.app.usedLanguage) {
      case LanguageType.ko:
        locale = Locale.ko;
        break;
      case LanguageType.jp:
        locale = Locale.ja;
        break;
      case LanguageType.fr:
        locale = Locale.fr;
        break;
      default:
        locale = Locale.en;
        break;
    }

    this.localizedSchedule = this.getLocalizedDate(startDate, endDate, locale);
  }

  /**
   * local timezone format으로 바꿔주는 함수
   * @param {moment.Moment} startDate
   * @param {moment.Moment} endDate
   * @param {string} locale
   * @return {string}
   */
  getLocalizedDate(startDate: moment.Moment, endDate: moment.Moment, locale: string): string {
    // Convert the dates to the local timezone
    const localStartDate = startDate.local();
    const localEndDate = endDate.local();

    // Set the locale for formatting
    localStartDate.locale(locale);
    localEndDate.locale(locale);

    // Define the custom formats for each locale
    const startFormats = {
      en: '[from] h:mm A',
      ja: 'M月D日[(]ddd[)] HH:mm',
      fr: '[Le] D MMMM [de] H[h]',
      ko: 'M월 D일[(]ddd[)] HH:mm'
    };
    const endFormats = {
      en: 'h:mm A [on] MMMM D [(]dddd[)]',
      ja: 'HH:mm',
      fr: 'H[h]',
      ko: 'HH:mm'
    };

    // Use the format for the specified locale, or a default format
    const startFormat = startFormats[locale];
    const endFormat = endFormats[locale];

    // Format the dates
    const startDateStr = localStartDate.format(startFormat);
    const endDateStr = localEndDate.format(endFormat);
    let result;
    // Return the formatted date string
    switch (locale) {
      case Locale.ko:
      case Locale.ja:
        result = `${startDateStr}~${endDateStr}`;
        break;
      case Locale.fr:
        result = `${startDateStr} à ${endDateStr}`;
        break;
      default:
        result = `${startDateStr} to ${endDateStr}`;
        break;
    }

    return result;
  }

  /**
   * 현재 시간와 목표하는 시간의 차이를 계산해주는 함수
   * @returns {void}
   */
  getTimeDifference(): void {
    try {
      const now = moment();
      const dueDate = moment(this.app.dueMaintenanceDate);
      this.timeDifference = dueDate.diff(now);
      if (this.timeDifference >= 0) {
        this.allocateTimeUnits(this.timeDifference);
      } else {
        throw new Error('timeDifference를 구하지 못했습니다.');
      }
    } catch (e) {
      throw new TooningGetTimeDifferenceError(e.message, null, true);
    }
  }

  /**
   * 계산된 시간을 정리하는 함수
   * @param timeDifference 현재와 목표하는 dDay의 시간차이
   * @returns {void}
   */
  allocateTimeUnits(timeDifference: number): void {
    try {
      const duration = moment.duration(timeDifference);
      this.secondsToDday = this.modifyNumber(duration.seconds());
      this.minutesToDday = this.modifyNumber(duration.minutes());
      this.hoursToDday = this.modifyNumber(duration.hours());
      // this.daysToDday = this.modifyNumber(duration.days());
    } catch (e) {
      throw new TooningAllocateTimeUnitsError(e.message, null, true);
    }
  }

  /**
   * 00:00 포맷을 위한 함수
   * @param time 시간
   * @returns {string}  한자리 수면 두자리 수로 변경된 값
   */
  modifyNumber(time: number): string {
    try {
      if (time < 0) {
        return '00';
      }
      if (time < 10) {
        return '0' + time;
      }
      return time.toString();
    } catch (e) {
      throw new TooningModifyNumberError(e.message);
    }
  }

  routeTo(): any {
    let urlLink: string;
    switch (this.app.usedLanguage) {
      case LanguageType.ko:
        urlLink = 'https://toonsquare.notion.site/4a7bbd9a994848e6b25098452c51050d';
        break;
      case LanguageType.en:
        urlLink = 'https://toonsquare.notion.site/Terms-Of-Service-15c5340ba08840b5950982f04a685cf4';
        break;
      case LanguageType.fr:
        urlLink = 'https://toonsquare.notion.site/Conditions-g-n-rales-d-utilisation-4913cf0f017d4e078cc598f40b0b5e4c';
        break;
      case LanguageType.jp:
        urlLink = 'https://toonsquare.notion.site/bbfe6567923a43dcb07ca44dfed2d824';
        break;
    }

    this.app.goExternal(urlLink);
  }
}
