import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { AlertController, AlertInput, ModalController, PopoverController } from '@ionic/angular';
import { AnalyticsService } from '../../../../services/google/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { Menu } from '../../../enums/menu.enum';
import { Deployment, LanguageOriginalType, LanguageType, ServerRegion, ServiceType, UserRole } from '../../../../enum/app.enum';
import { PaymentService } from '../../../../services/payment/payment.service';
import { Loading } from '../../../../services/loading';
import { PointService } from '../../../../services/point/point.service';
import { CardCompanySetComponent } from '../../card-company-set/card-company-set.component';
import { TooningSetServerRegionError, TooningUpdateCardCompanyStatusError } from '../../../errors/TooningErrors';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-popover-menu-aacount',
  templateUrl: './popover-menu-aacount.component.html',
  styleUrls: ['./popover-menu-aacount.component.scss']
})
export class PopoverMenuAacountComponent implements OnInit {
  public appVersion: string;
  public language: string;
  public isBeta = false;
  public isStudent = false;
  public isTeacher = false;
  public isTemplate = false;
  public isTextTemplate = false;
  public plan: string;
  public isIphoneApp = false;
  public isIpadApp = false;
  public isIos = false;
  public UserRole = UserRole;
  public allList = 0;
  public isProduction = false;
  public cloudFrontState = false;
  public myPoints = 0;
  public user: any;
  public languageEnum = LanguageType;
  public languageOriginalEnum = LanguageOriginalType;
  public serviceTypeEnum = ServiceType;

  constructor(
    public app: AppService,
    public pointService: PointService,
    public popoverController: PopoverController,
    public paymentService: PaymentService,
    public analyticsService: AnalyticsService,
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private userService: UserService,
    private alertCtrl: AlertController
  ) {
    console.log(this);
  }

  async ngOnInit() {
    await this.getMyPoints();

    try {
      this.cloudFrontState = this.app.cache.getWorkingCloudeFront() === 'on';
      switch (this.app.cache.user.role) {
        case UserRole.admin:
          this.plan = this.translate.instant('admin');
          break;
        case UserRole.beta:
        case UserRole.student:
        case UserRole.teacher:
          if (this.app.isPaidMember) {
            this.plan = this.translate.instant('education plan');
          } else {
            this.plan = this.translate.instant('free plan');
          }

          if (this.app.cache.user.role === UserRole.beta) {
            this.isBeta = true;
          } else if (this.app.cache.user.role === UserRole.student) {
            this.isStudent = true;
          } else {
            this.isTeacher = true;
          }
          break;
        case UserRole.template:
          this.plan = this.translate.instant('template editor');
          this.isTemplate = true;
          break;
        case UserRole.donation:
          this.plan = this.translate.instant('donation plan');
          break;
        case UserRole.user:
        case UserRole.event:
        case UserRole.enterprise:
          this.plan = this.translate.instant('free plan');
          if (this.app.isPaidMember) {
            this.plan = this.translate.instant('pro plan');
          }
          break;
        case UserRole.textTemplate:
          this.isTextTemplate = true;
      }

      this.isIphoneApp = this.app.isHybrid() && this.app.isIphone();
      this.isIpadApp = this.app.isHybrid() && this.app.isIpad();
      const device = this.app.getPlatform();
      this.isIos = device === 'ios';
      this.app.orange(`is iphone app : ${this.isIphoneApp}`);
      this.app.orange(`is ipad app : ${this.isIpadApp}`);
      this.app.orange(`is ios app : ${this.isIos}`);
      this.isProduction = environment.production;
    } catch (e) {
      this.isBeta = false;
      this.isStudent = false;
      this.isTeacher = false;
      this.isTemplate = false;
      this.isTextTemplate = false;
    }
    this.appVersion = environment.appVersion;
  }

  async ionViewDidEnter() {
    this.allList = 0;
  }

  async getMyPoints() {
    const userId = +this.app.cache.user.id;
    const { data } = await this.pointService.points(userId).toPromise();
    console.log(data);
    this.myPoints = data.sum;
  }

  async logOut() {
    this.analyticsService.logOut();
    this.app.greenLog('logout from popover');
    await this.popoverController.dismiss(Menu.LOGOUT);
    await this.app.logout();
    if (this.app.currentService === ServiceType.Magic) {
      location.reload();
    }
  }

  async moveToAccountSetting() {
    this.analyticsService.accountSetting();
    this.app.greenLog('move to account setting');
    this.app.go('account-setting');
    await this.popoverController.dismiss(Menu.ACCOUNT_SETTING);
  }

  /**
   * 나의 작업으로 이동하는 함수
   * @return {Promise<void>}
   */
  async goMyWork(): Promise<void> {
    this.app.go('/4cut-list');
    await this.popoverController.dismiss();
  }

  async goChargeCoin() {
    this.app.go('point-card-registration');
    await this.popoverController.dismiss();
  }

  goAllList() {
    this.allList++;
    if (this.allList > 9) {
      this.app.go('/4cut-list/all-list');
    }
  }

  async refund() {
    const loading = new Loading();
    try {
      await loading.showLoader();
      await this.paymentService.refund(+this.app.cache.user.id).toPromise();
    } catch (e) {
      throw new Error(`refund : ${e}`);
    } finally {
      loading.hideLoader();
    }
  }

  switchCloudFront() {
    try {
      const state = this.cloudFrontState ? 'on' : 'off';
      this.app.cache.setWorkingCloudeFront(state);
    } catch (e) {
      throw new Error(`switchCloudFront : ${e}`);
    }
  }

  /**
   * 카드사 상태를 변경하는 모달을 띄우는 함수
   * @return {Promise<void>}
   */
  async updateCardCompanyStatus(): Promise<void> {
    try {
      this.app.modal = await this.modalCtrl.create({
        component: CardCompanySetComponent,
        cssClass: 'modal-size-login'
      });
      this.app.modal.onDidDismiss().then(async () => {
        this.app.modal = null;
      });
      await this.app.modal.present();
    } catch (e) {
      throw new TooningUpdateCardCompanyStatusError(e);
    }
  }

  /**
   * provisioning 핸들링 얼럿, 서버 리전 선택 할 수 있음
   * @return {Promise<void>}
   */
  async setServerRegion(): Promise<void> {
    try {
      const currentServerRegion = localStorage.getItem('server-region');
      const alertInput: AlertInput[] = [
        {
          label: '기본',
          type: 'radio',
          value: ServerRegion.default,
          checked: !currentServerRegion || currentServerRegion === ServerRegion.default
        }
      ];
      if (environment.deploy === Deployment.oper) {
        alertInput.push(
          {
            label: '한국',
            type: 'radio',
            value: ServerRegion.kr,
            checked: currentServerRegion === ServerRegion.kr
          },
          {
            label: '캐나다',
            type: 'radio',
            value: ServerRegion.ca,
            checked: currentServerRegion === ServerRegion.ca
          },
          {
            label: '싱가폴',
            type: 'radio',
            value: ServerRegion.sg,
            checked: currentServerRegion === ServerRegion.sg
          },
          {
            label: '미국',
            type: 'radio',
            value: ServerRegion.us,
            checked: currentServerRegion === ServerRegion.us
          }
        );
      }
      const alert = await this.alertCtrl.create({
        cssClass: 'language-change-alert',
        mode: 'md',
        header: this.translate.instant('language setting'),
        inputs: alertInput,
        buttons: [
          {
            text: this.translate.instant('CANCEL'),
            role: 'cancel'
          },
          {
            text: this.translate.instant('HEADER'),
            role: 'ok',
            handler: async (data) => {
              if (data !== ServerRegion.default) {
                this.userService.setServerRegion(data);
                await this.app.showToast('로그아웃 후 새로고침 해주세요');
              } else {
                localStorage.removeItem('server-region');
              }
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      throw new TooningSetServerRegionError(e);
    }
  }
}
