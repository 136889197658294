import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class MagicUpload {
  @IsNumber() @IsNotEmpty() userId: number;
  @IsNumber() @IsNotEmpty() groupId: number;
  @IsString() @IsNotEmpty() parameter: string;
  @IsBoolean() @IsOptional() isBlur: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
