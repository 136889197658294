import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ResMakerCharacterService } from '../../../../../services/res-maker/character.service';
import { CanvasService } from '../../../../../services/canvas.service';
import { Cut4MakeManualService } from '../../../cut4-make-manual.service';
import { AppService } from '../../../../../services/app.service';
import { Loading } from '../../../../../services/loading';
import { PromiseStatus } from '../../../../../enum/app.enum';
import {
  TooningSearchAllTextError,
  TooningisValidSearchTextError,
  TooningTextReplacerConfirmError,
  TooningExecuteTextReplacerError
} from '../../../../../pages-tooning/errors/TooningErrors';

@Component({
  selector: 'app-modal-replacer-text',
  templateUrl: './text-replacer.component.html',
  styleUrls: ['./text-replacer.component.scss']
})
export class TextReplacerComponent implements OnInit {
  public searchText: string;
  public inputText: string;
  public undefinedText: boolean;
  public multipleSpaceText: boolean;
  public singleSpaceText: boolean;
  public validText: boolean;
  public searchComplete: boolean;
  public totalTextCount: number;
  public popAlert: any;
  public disableNextButton: boolean;

  @Input('canvasIdList') canvasIdList;
  @Input('userId') userId;
  @Input('canvasGroupId') canvasGroupId;

  constructor(
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public resMakerCharacterService: ResMakerCharacterService,
    public canvasService: CanvasService,
    public cut: Cut4MakeManualService,
    public app: AppService,
    public translate: TranslateService,
    public dialog: MatLegacyDialog
  ) {}

  async ngOnInit() {
    try {
      this.disableNextButton = true;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * modal 닫기
   * @returns {Promise<void>}
   */
  async close(): Promise<void> {
    if (this.modalCtrl !== null) {
      await this.modalCtrl.dismiss();
    }
  }

  /**
   * 텍스트를 검색한다
   * @param {string} searchText - 검색 텍스트
   * @returns {Promise<void>}
   */
  async searchAllText(searchText: string): Promise<void> {
    const loading = new Loading();
    this.undefinedText = false;
    this.singleSpaceText = false;
    this.multipleSpaceText = false;
    this.validText = false;
    this.searchComplete = false;
    try {
      await this.isValidSearchText(searchText);
      if (this.validText) {
        await loading.showLoader();
        const data = await this.resMakerCharacterService.countSearchingText(this.userId, this.canvasIdList, searchText).toPromise();
        this.totalTextCount = data.data.countSearchingText;
        this.searchComplete = true;
        if (this.totalTextCount > 0) {
          this.disableNextButton = false;
        }
      }
    } catch (e) {
      await this.app.showToast(this.translate.instant('replacer.text.searchingTextFail'), 300, 'dark', 'middle');
      throw new TooningSearchAllTextError(e, this.app, true);
    } finally {
      loading.hideLoader();
    }
  }

  /**
   * 검색에 적합한 텍스트인지 판별한다
   * @param {string} text - 검색 텍스트
   * @returns {Promise<boolean>}
   */
  async isValidSearchText(text: string): Promise<boolean> {
    // 공백이 연속 2개 이상이면 체크
    const singleSpacingText: boolean = /\s/.test(text);
    const multipleSpacingText: boolean = /\s{2}/.test(text);

    try {
      switch (true) {
        case !text:
          this.undefinedText = true;
          return this.undefinedText;
        case text.length < 2 && singleSpacingText:
          this.singleSpaceText = true;
          return this.singleSpaceText;
        case multipleSpacingText:
          this.multipleSpaceText = true;
          return this.multipleSpaceText;
      }
      this.validText = true;
      return this.validText;
    } catch (e) {
      throw new TooningisValidSearchTextError(e, this.app, true);
    }
  }

  /**
   * 텍스트 일괄 변경 실행 전 확인 알림창
   * @returns {Promise<void>}
   */
  async textReplacerConfirm(): Promise<void> {
    try {
      if (this.searchText && this.inputText) {
        this.popAlert = await this.alertController.create({
          cssClass: 'replacer-confirm',
          subHeader:
            this.translate.instant('replacer.total') +
            this.totalTextCount +
            this.translate.instant('replacer.text.apostrophe') +
            this.searchText +
            this.translate.instant('replacer.text.changeTextExplain1') +
            this.inputText +
            this.translate.instant('replacer.text.changeTextExplain2'),
          message: this.translate.instant('replacer.askContinue'),
          buttons: [
            {
              text: this.translate.instant('replacer.cancel')
            },
            {
              text: this.translate.instant('replacer.okay'),
              handler: async () => {
                this.cut.isModalOpend = true;
                await this.popAlert.dismiss();
                await this.modalCtrl.dismiss({});
                await this.executeTextReplacer();
              }
            }
          ]
        });
        this.popAlert.onDidDismiss().then(async () => {
          this.popAlert = null;
        });
        await this.popAlert.present();
      } else {
        await this.app.showToast(this.translate.instant(this.translate.instant('replacer.text.inputText')), 200, 'primary', 'middle');
      }
    } catch (e) {
      throw new TooningTextReplacerConfirmError(e, null, true);
    }
  }

  /**
   * 텍스트 일괄 변경 실행
   * @returns {Promise<void>}
   */
  async executeTextReplacer(): Promise<void> {
    const loading = new Loading();
    await loading.showLoader('', 10000 * 60 * 3);
    try {
      const canvasPromises = [];
      const canvasB64Promises = [];
      const successList = [];
      const failedList = [];

      for (let canvasId of this.canvasIdList) {
        canvasPromises.push(this.resMakerCharacterService.replaceTextJSON(canvasId, this.userId, this.searchText, this.inputText));
      }

      await Promise.allSettled(canvasPromises).then(
        (results) => {
          results.forEach((result) => {
            if (result.status === PromiseStatus.fulfilled) {
              successList.push(result);
            } else {
              failedList.push(result);
            }
          });
        },
        async (e) => {
          console.error(e);
          await this.app.showToast(failedList + this.translate.instant('replacer.failDetail'), 1000, 'dark', 'middle');
        }
      );

      setTimeout(() => {
        for (const canvasId of this.canvasIdList) {
          canvasB64Promises.push(this.canvasService.updateCanvasB64(canvasId));
        }
      }, 1000);

      await Promise.all(canvasB64Promises).then(
        async () => {
          loading.hideLoader();
          const alert = await this.alertController.create({
            cssClass: 'replacer-confirm',
            subHeader: this.translate.instant('replacer.total') + successList.length + this.translate.instant('replacer.changeCompleted'),
            message: this.translate.instant('replacer.checkCompleted'),
            backdropDismiss: false,
            buttons: [
              {
                text: this.translate.instant('replacer.okay'),
                handler: async () => {
                  await this.app.showToast(this.translate.instant('replacer.text.textDone'), 500, 'primary', 'middle');
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }
              }
            ]
          });
          await alert.present();
        },
        async (e) => {
          console.error(e);
        }
      );
    } catch (e) {
      throw new TooningExecuteTextReplacerError(e, this.app, true);
    } finally {
      loading.hideLoader();
    }
  }
}
