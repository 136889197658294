import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { environment } from 'src/environments/environment';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { TooningGraphQLError, TooningNetworkError } from './pages-tooning/errors/TooningErrors';
import { throwError } from 'rxjs';
import { ServerRegion, ServiceType } from './enum/app.enum';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
// const uri = ''; // <-- add the URL of the GraphQL server here
// const uri = process.env.NODE_ENV === 'production' ? 'https://apollo.toonsquare.co/graphql' : 'http://localhost:8080/graphql'; //our test Graphql Server which returns rates
// const uri = 'https://apollo.toonsquare.co/graphql'; //our test Graphql Server which returns rates
const uri = environment.graphql_url;
const uri2 = `https://beta-api.tooning.io/graphql`;
const uriList = {
  [ServerRegion.default]: environment.graphql_url,
  [ServerRegion.ca]: environment.graphql_cc_url,
  [ServerRegion.us]: environment.graphql_uw_url,
  [ServerRegion.sg]: environment.graphql_as_url,
  [ServerRegion.kr]: environment.graphql_an_url
};
const websocketList = {
  [ServerRegion.default]: {
    schema: environment.websocket.schema,
    host: environment.websocket.host,
    port: environment.websocket.port
  },
  [ServerRegion.ca]: {
    schema: environment.websocket_cc.schema,
    host: environment.websocket_cc.host,
    port: environment.websocket_cc.port
  },
  [ServerRegion.us]: {
    schema: environment.websocket_uw.schema,
    host: environment.websocket_uw.host,
    port: environment.websocket_uw.port
  },
  [ServerRegion.sg]: {
    schema: environment.websocket_as.schema,
    host: environment.websocket_as.host,
    port: environment.websocket_as.port
  },
  [ServerRegion.kr]: {
    schema: environment.websocket_an.schema,
    host: environment.websocket_an.host,
    port: environment.websocket_an.port
  }
};

let websocketSchema = environment.websocket.schema;
const websocketEndPoint = `${websocketSchema}${environment.websocket.host}:${environment.websocket.port}/subscriptions`;

export function createApollo(httpLink: HttpLink) {
  // 모든 graphql 요청 전송 전에 실행됨.
  // localStorage 에서 토큰이 있으면 요청 헤더에 추가
  let userId = '';
  let boardUserId = '';
  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('token');
    let user: any;
    if (token) {
      const temp = localStorage.getItem('user');
      if (temp) {
        user = JSON.parse(temp);
        if (user && user.hasOwnProperty('id')) {
          userId = user.id.toString();
        }
      }
    }
    const boardUser = localStorage.getItem('boardUser');
    if (boardUser) {
      const boardUserObj = JSON.parse(boardUser);
      if (boardUserObj && boardUserObj.hasOwnProperty('boardUserId')) {
        boardUserId = boardUserObj.boardUserId.toString();
      }
    }
    return {
      headers: {
        token: token ? token : '',
        userid: userId,
        board_user_id: boardUserId,
        serviceType: ServiceType.Editor
      }
    };
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error
    }
  });
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, nodes, source, positions, originalError, extensions }) => {
        const messages = [];
        messages.push(`[GraphQL error]`);
        messages.push(`message: ${message}`);
        messages.push(`location: ${JSON.stringify(locations)}`);
        messages.push(`path: ${JSON.stringify(path)}`);
        messages.push(`nodes: ${nodes}`);
        messages.push(`source: ${source}`);
        messages.push(`positions: ${positions}`);
        messages.push(`originalError: ${originalError}`);
        messages.push(`extensions:`);
        for (let [key, value] of Object.entries(extensions)) {
          if (key === 'exception') {
            messages.push(' exception');
            for (let [key2, value2] of Object.entries(value)) {
              messages.push(` ${key2} : ${value2}`);
            }
          } else {
            messages.push(`${key} : ${JSON.stringify(value)}`);
          }
        }
        throwError(new TooningGraphQLError(messages.join('\n'), null, true));
      });
    }
    if (networkError) {
      let slack = true;
      // @ts-ignore
      const { status, statusText, ok, message, error } = networkError;
      const messages = [];
      messages.push('graphql.module.ts');
      messages.push(`status : ${status}`);
      messages.push(`statusText : ${statusText}`);
      messages.push(`ok : ${ok}`);
      messages.push(`message : ${message}`);
      messages.push(`operationName : ${operation.operationName}`);
      messages.push(`variables : ${JSON.stringify(operation.variables)}`);
      messages.push(`error : ${JSON.stringify(error)}`);
      throwError(new TooningNetworkError(messages.join('\n'), null, slack));
    }
  });
  let _link;
  try {
    const ws = new WebSocketLink({
      uri: websocketEndPoint,
      options: {
        reconnect: true
      }
    });
    _link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      ws,
      localStorage.getItem('token')
        ? ApolloLink.from([
            errorLink,
            auth,
            httpLink.create({
              uri,
              extractFiles: (body) => extractFiles(body, isExtractableFile)
            })
          ])
        : ApolloLink.from([
            errorLink,
            httpLink.create({
              uri,
              extractFiles: (body) => extractFiles(body, isExtractableFile)
            })
          ])
    );
  } catch (e) {
    _link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      localStorage.getItem('token')
        ? ApolloLink.from([
            errorLink,
            auth,
            httpLink.create({
              uri: uri,
              extractFiles: (body) => extractFiles(body, isExtractableFile)
            })
          ])
        : ApolloLink.from([
            errorLink,
            httpLink.create({
              uri: uri,
              extractFiles: (body) => extractFiles(body, isExtractableFile)
            })
          ])
    );
  }
  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = _link;
  return {
    link: link,
    cache: new InMemoryCache(),
    name: `web-${userId}`,
    version: environment.appVersion
  };
}

/**
 * 새로운 아폴로 client option 만들어주는 함수
 * @param {HttpLink} httpLink
 * @param {ServerRegion | string} region
 * @return {{cache: InMemoryCache, link: any, name: string, version: string}}
 */
export function createWithRegion(httpLink: HttpLink, region: ServerRegion | string) {
  // 모든 graphql 요청 전송 전에 실행됨.
  // localStorage 에서 토큰이 있으면 요청 헤더에 추가
  let userId = '';
  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('token');
    let user: any;
    if (token) {
      const temp = localStorage.getItem('user');
      if (temp) {
        user = JSON.parse(temp);
        userId = user.id.toString();
      }
    }
    return {
      headers: {
        token: token ? token : '',
        userid: userId,
        serviceType: ServiceType.Editor
      }
    };
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 100,
      retryIf: (error, _operation) => !!error
    }
  });
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, nodes, source, positions, originalError, extensions }) => {
        const messages = [];
        messages.push(`[GraphQL error]`);
        messages.push(`message: ${message}`);
        messages.push(`location: ${JSON.stringify(locations)}`);
        messages.push(`path: ${JSON.stringify(path)}`);
        messages.push(`nodes: ${nodes}`);
        messages.push(`source: ${source}`);
        messages.push(`positions: ${positions}`);
        messages.push(`originalError: ${originalError}`);
        messages.push(`extensions:`);
        for (let [key, value] of Object.entries(extensions)) {
          if (key === 'exception') {
            messages.push(' exception');
            for (let [key2, value2] of Object.entries(value)) {
              messages.push(` ${key2} : ${value2}`);
            }
          } else {
            messages.push(`${key} : ${JSON.stringify(value)}`);
          }
        }
        throwError(new TooningGraphQLError(messages.join('\n'), null, true));
      });
    }
    if (networkError) {
      let slack = true;
      // @ts-ignore
      const { status, statusText, ok, message, error } = networkError;
      const messages = [];
      messages.push('graphql.module.ts');
      messages.push(`status : ${status}`);
      messages.push(`statusText : ${statusText}`);
      messages.push(`ok : ${ok}`);
      messages.push(`message : ${message}`);
      messages.push(`operationName : ${operation.operationName}`);
      messages.push(`variables : ${JSON.stringify(operation.variables)}`);
      messages.push(`error : ${JSON.stringify(error)}`);
      throwError(new TooningNetworkError(messages.join('\n'), null, slack));
    }
  });
  let _linkTemp;
  const regionUri = uriList[region];
  try {
    const websocketEndPoint = `${websocketList[region].schema}${websocketList[region].host}:${websocketList[region].port}/subscriptions`;
    const ws = new WebSocketLink({
      uri: websocketEndPoint,
      options: {
        reconnect: true
      }
    });
    _linkTemp = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      ws,
      localStorage.getItem('token')
        ? ApolloLink.from([
            errorLink,
            auth,
            httpLink.create({
              uri: regionUri,
              extractFiles: (body) => extractFiles(body, isExtractableFile)
            })
          ])
        : ApolloLink.from([
            errorLink,
            httpLink.create({
              uri: regionUri,
              extractFiles: (body) => extractFiles(body, isExtractableFile)
            })
          ])
    );
  } catch (e) {
    _linkTemp = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      localStorage.getItem('token')
        ? ApolloLink.from([
            errorLink,
            auth,
            httpLink.create({
              uri: regionUri,
              extractFiles: (body) => extractFiles(body, isExtractableFile)
            })
          ])
        : ApolloLink.from([
            errorLink,
            httpLink.create({
              uri: regionUri,
              extractFiles: (body) => extractFiles(body, isExtractableFile)
            })
          ])
    );
  }
  const tempResult = {
    link: _linkTemp,
    cache: new InMemoryCache(),
    name: `web-${userId}`,
    version: environment.appVersion
  };
  return tempResult;
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
// @ts-ignore
export class GraphQLModule {}
