import { IsArray, IsBoolean, IsEnum, IsInstance, IsNotEmpty, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { SkuType } from '../../enum/app.enum';
import { Character } from '../character/character';
import { EtcUpload } from '../etcUpload/etcUpload';
import * as _ from 'lodash';

export class Sku {
  @IsNumber() @IsNotEmpty() id: number;
  @IsNumber() @IsNotEmpty() point: number;
  @IsNumber() @IsNotEmpty() page: number;
  @IsNumber() @IsNotEmpty() objectIndex: number;
  @IsEnum(SkuType) @IsNotEmpty() skuType: SkuType;
  @IsInstance(Character) @IsOptional() character: Character | undefined;
  @IsInstance(EtcUpload) @IsOptional() etcUpload: EtcUpload | undefined;
  @IsObject() @IsOptional() resource: Character | EtcUpload | undefined;
  @IsString() @IsOptional() thumbnail: string;
  @IsString() @IsOptional() nameKo: string;
  @IsString() @IsOptional() nameEn: string;
  @IsArray() @IsOptional() purchase: Array<any>;
  @IsBoolean() isChecked: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    if (input.character) {
      // https://github.com/toonsquare/tooning-repo/issues/615
      // this.skuList[0].character 와 this.skuList[1].character 의 character는 달러야한다.
      this.character = _.cloneDeep(input.character);
    }
    if (input.etcUpload) {
      this.etcUpload = _.cloneDeep(input.etcUpload);
      this.etcUpload.id = this.etcUpload.sourceId;
    }

    // api에서는 purchase 데이터가 배열로 내려오므로, 클라이언트 인터페이스에 맞춰주기 위해 purchase 를 객체로 저장한다.
    if (Array.isArray(this.purchase)) {
      if (this.purchase.length === 0) {
        this.purchase = null;
      } else {
        this.purchase = this.purchase[0];
      }
    }
    return this;
  }
}

export class SkuData {
  @IsOptional() skuType?: SkuType;
  @IsOptional() id?: number;
  point?: number;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
