import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Loading } from '../../services/loading';
import { AppService } from '../../services/app.service';
import { AnalyticsService } from '../../services/google/analytics/analytics.service';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BasePage } from '../../base-page';
import { CustomUser } from '../../model/user/cutom/cutomUser';
import { CountryCode, CustomUserDefaultMetaData, LanguageType, SignupInputType, UserPageViewType, VerificationType } from '../../enum/app.enum';
import { validate } from 'class-validator';
import { UserEngagementEnum } from '../../enum/userEngagement.enum';
import { UserLoginType } from '../../enum/UserLoginType.enum';
import { LoginPage } from '../login/login.page';
import {
  logErrorMessage,
  TooningAlertExistsUser,
  TooningCustomUserSignUpError,
  TooningErrorCode,
  TooningServerConnectionError,
  TooningSignUpChangeVerificationTypeError,
  TooningSignUpUIError,
  TooningSignupVerificationUIError,
  TooningVerifyEmailError,
  TooningVerifyPhoneNumberError
} from '../../pages-tooning/errors/TooningErrors';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { KakaoServiceService } from '../../services/kakao-service.service';
import { GoogleService } from '../../services/google/auth/google.service';
import { ResMakerCharacterService } from '../../services/res-maker/character.service';
import { Cut4MakeManualService } from '../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import codes from 'country-calling-code';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { RegexService } from '../../services/regex.service';

const TIME_LEFT_STRING = '10:00';
const TIME_LEFT = 60 * 10;
const SECOND = 1000;
const phoneNumberRegex = /^01([016789])-?(\d{3,4})-?(\d{4})$/;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss']
})
export class SignupPage extends BasePage implements OnInit, OnChanges, OnDestroy {
  // @ts-ignore
  @ViewChild('nameInput') nameInput: ElementRef;
  // @ts-ignore
  @ViewChild('emailInput') emailInput: ElementRef;

  @ViewChild('verificationCodeInput') verificationCodeInput: ElementRef;
  public customUser: CustomUser = new CustomUser();
  public usedLanguage: string;
  @Input('isDemo') isDemo: boolean;
  @Input('isModal') isModal: boolean;
  @Input('canvasId') canvasId: number;
  @Input('viewType') viewType: string;
  @Output() viewChange: EventEmitter<any> = new EventEmitter();
  @Output() modalClose: EventEmitter<any> = new EventEmitter();
  public alert: any;
  public showVerificationCodeField = false;
  @Input() public verificationCode: string;
  public isTimer: boolean;
  public timeleft = TIME_LEFT_STRING;
  public isVerified: boolean = false;
  public serverVerificationCode;
  public CustomUserDefaultMetaData = CustomUserDefaultMetaData;
  private signupIsProgressing = false;
  private loading = new Loading();
  private hasSignupError = false;
  private hasValidationError = false;
  private signupAlert: HTMLIonAlertElement;
  private keydownObservable$: Observable<Event>;
  private timerList = [];
  public countryList = codes;
  public filteredCountries: string[];
  private control = new FormControl('');
  public verificationType = VerificationType;
  public userVerificationType: string = VerificationType.email;
  public defaultCountry = { country: 'South Korea (대한민국)', countryCode: '+82', isoCode2: 'KR', isoCode3: 'KOR' };
  public selectedCountry = this.defaultCountry;
  public isKoreaUser = true;
  public isCountrySelectMoreClick: boolean = false;
  public userPageViewType = UserPageViewType;
  public signupInputType = SignupInputType;
  public languageType = LanguageType;
  public isClickedVerificationBtn: boolean = false;
  public isEssentialChecked = false;

  public validationErrorCheck = {
    /**
     * 유저 이름 형식 체크
     * @param {CustomUser} user
     * @return {boolean}
     */
    name: function (user: CustomUser): boolean {
      return !(
        !user.name ||
        (user.name.length >= CustomUserDefaultMetaData.minNameLength && user.name.length <= CustomUserDefaultMetaData.maxNameLength)
      );
    },
    /**
     * 유저 이메일 형식 체크
     * @param {CustomUser} user
     * @return {boolean}
     */
    email: function (user: CustomUser): boolean {
      const emailRegex = /[\w-.]+@([\w-]+\.)+([a-zA-Z]{2,4}|\d{1,3})$/;
      return !(!user.email || emailRegex.test(user.email));
    },
    /**
     * 유저 비밀번호 형식 체크
     * @param {CustomUser} user
     * @return {boolean}
     */
    password: function (user: CustomUser): boolean {
      return !(!user.password || (user.password.length >= 4 && user.password.length <= 20));
    },
    /**
     * 유저 비밀번호 확인 형식 체크
     * @param {CustomUser} user
     * @return {boolean}
     */
    passwordConfirm: function (user: CustomUser): boolean {
      return !(!user.passwordConfirm || (user.password && user.password === user.passwordConfirm));
    },
    /**
     * 인증번호 형식 체크
     * @param {string} inputCode
     * @return {boolean}
     */
    verifyCode: function (inputCode: string): boolean {
      return !(!inputCode || inputCode.length === 6);
    },
    /**
     * 유저 폰번호 형식 체크
     * @param {CustomUser} user
     * @return {boolean}
     */
    phone: function (user: CustomUser): boolean {
      const patternPhone = /01[016789]-[^0]\d{2,3}-\d{3,4}/;
      return !(!user.phoneNumber || patternPhone.test(user.phoneNumber));
    }
  };

  constructor(
    public app: AppService,
    public kakao: KakaoServiceService,
    private googleService: GoogleService,
    private analyticsService: AnalyticsService,
    public modalCtrl: ModalController,
    private translate: TranslateService,
    public alertController: AlertController,
    public characterService: ResMakerCharacterService,
    public cut: Cut4MakeManualService,
    private regexService: RegexService,
    public loginPage: LoginPage
  ) {
    super(app);
  }

  async ngOnInit() {
    this.subscriptions.push(
      // 사용자가 입력한 국가 정보를 가지고 국가리스트 필터
      this.control.valueChanges
        .pipe(
          startWith(''),
          map((value) => {
            this.filteredCountries = this._filter(value || '');
          })
        )
        .subscribe()
    );

    this.viewType = this.userPageViewType.signupAgreement;
    this.viewChange.emit(this.viewType);
    this.isKoreaUser = await this.isUserCountryKorea();
    this.addKeyboardEvents();
  }

  async ngOnChanges(changes) {
    this.usedLanguage = this.getUsedLanguage();

    // 화면 변경 (회원가입 입력 -> 회원가입 이용약관 동의)
    if (changes.viewType && changes.viewType.currentValue === this.userPageViewType.signupAgreement) {
      this.isVerified = false;
      this.timeleft = TIME_LEFT_STRING;
      this.isTimer = null;
      this.userVerificationType = VerificationType.email;
      this.verificationCode = null;
      this.alert = null;
      this.customUser = new CustomUser();
      this.removeValidationErrorMessage();
      this.isClickedVerificationBtn = false;
      this.isEssentialChecked = false;
    }
  }

  getUsedLanguage() {
    let usedLanguage = this.app.cache.getWorkingLanguage();
    if (!usedLanguage) {
      usedLanguage = this.app.getDefaultLanguage();
    }
    return usedLanguage;
  }

  async onKeyDown(event) {
    if (this.signupAlert) {
      if (!this.hasSignupError) {
        await this.signupAlert.dismiss('keyboard', 'OK');
      } else {
        await this.signupAlert.dismiss('keyboard');
        this.focusToEmailInput();
      }
      this.signupAlert = null;
      return;
    }
    if (+event.keyCode === 13) {
      if (this.checkDisabled()) {
        return;
      }
      await this.customSignup();
    }
  }

  addKeyboardEvents() {
    this.keydownObservable$ = fromEvent(window, 'keydown');
    this.subscriptions.push(
      this.keydownObservable$.pipe(debounceTime(1000)).subscribe(async (event) => {
        await this.onKeyDown(event);
        console.log('onKeyDown 회원가입');
      })
    );
  }

  focusToNameInput() {
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 200);
  }

  focusToEmailInput() {
    setTimeout(() => {
      this.emailInput.nativeElement.focus();
    }, 200);
  }

  /**
   * 화면에 보이고 있는 에러메시지 안보이게 변경
   * @return {void}
   */
  removeValidationErrorMessage(): void {
    try {
      if (!this.hasValidationError) {
        return;
      }
      // @ts-ignore
      // tslint:disable-next-line:variable-name
      for (const div of document.getElementsByClassName(`ValidationError`)) {
        div.style.display = 'none';
      }
    } catch (e) {
      throw new TooningSignUpUIError(e);
    }
  }

  /**
   * 회원가입 시도할 때 형식 맞지 않을 경우 에러 문구 보여주기
   * @param validationErrors 형식 오류
   * @return {Promise<void>}
   */
  async showValidationErrorMessage(validationErrors: any): Promise<void> {
    console.log(validationErrors);
    if (document.querySelector('.ValidationError') != null) {
      // @ts-ignore
      // tslint:disable-next-line:variable-name
      for (const _div of document.querySelectorAll(`.ValidationError`)) {
        _div.style.display = 'none';
      }
    }
    // tslint:disable-next-line:no-shadowed-variable
    for (const validate of validationErrors) {
      const errorDiv = document.querySelector('#warning' + validate.property) as HTMLElement;

      if (errorDiv != null) {
        const message = Object.values(validate.constraints)[0];
        // @ts-ignore
        const divMessage = this.translate.instant(message);
        errorDiv.innerHTML = divMessage;
        errorDiv.style.display = 'block';
      } else {
        this.app.orange(`validate.property is emtpy ${validate.property}`);
        if (this.app.usedLanguage === LanguageType.ko) {
          await this.app.showToast(`${validate.property} 가 없습니다`);
        } else {
          await this.app.showToast(`There is no ${validate.property}`);
        }
      }
    }
    try {
      this.loading.hideLoader();
    } catch (e) {
      this.app.orange(e.message);
    }
  }

  /**
   * 커스텀 회원가입
   * @return {Promise<void>}
   */
  async customSignup(): Promise<void> {
    if (this.signupIsProgressing) {
      this.app.orange('signup is progressing');
      return;
    }

    this.customUser.language = this.usedLanguage;
    const validationErrors = await validate(this.customUser);
    if (validationErrors.length > 0) {
      this.hasValidationError = true;
      this.app.orange('validation error');
      await this.showValidationErrorMessage(validationErrors);
      return;
    }

    this.hasValidationError = false;
    if (this.customUser.password !== this.customUser.passwordConfirm) {
      await this.app.presentAlert(this.translate.instant('please check your password'));
      this.customUser.password = '';
      this.customUser.passwordConfirm = '';
      return;
    }
    const loading = new Loading();
    try {
      await loading.showLoader('');
      const signupResult = await this.app.user.signupCustom(this.customUser);
      this.hasSignupError = false;
      if (signupResult.engagement === UserEngagementEnum.signUp) {
        const platform = 'web';
        this.analyticsService.signUpPlatform(platform);
        await this.app.loginCacheSetting(signupResult.token, UserLoginType.custom);
        this.modalClose.emit();
        await this.cut.goUrl(this.isDemo, signupResult.id, this.canvasId, this.app.currentService);
        await this.loginPage.signUpCompleteModal();
      }
    } catch (e) {
      this.hasSignupError = true;
      try {
        const { graphQLErrors } = e;
        if (!graphQLErrors) {
          return;
        }
        for (const gError of graphQLErrors) {
          const errorCode = +gError.extensions.exception.code;
          if (errorCode === TooningErrorCode.TOONING_SERVER_CUSTOM_SIGNUP_EMAIL_EXIST_ERROR) {
            this.signupAlert = await this.app.presentAlert(this.translate.instant('user already exist'));
            this.isVerified = false;
          } else {
            this.signupAlert = await this.app.presentAlert(this.translate.instant('signup unknown error'));
            throw new TooningCustomUserSignUpError(e, this.app, true);
          }
        }
      } catch (e) {
        this.signupAlert = await this.app.presentAlert(this.translate.instant('server connection error'));
        throw new TooningServerConnectionError(e, this.app, true);
      }
    } finally {
      loading.hideLoader();
      this.signupIsProgressing = false;
    }
  }

  // 로그인 모달
  async openModalLogin() {
    this.app.modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: {
        isModal: true
      },
      cssClass: 'modal-size-login'
    });

    this.app.modal.onDidDismiss().then(async ({ data }) => {
      this.app.modal = null;
    });
    return await this.app.modal.present();
  }

  /**
   * 버튼 Disabled 조건 체크
   * @return {boolean}
   */
  checkDisabled(): boolean {
    const { name, email, password, passwordConfirm } = this.customUser;
    return _.isEmpty(name) || _.isEmpty(email) || _.isEmpty(password) || _.isEmpty(passwordConfirm) || !this.isVerified;
  }

  /**
   * Send verification email
   * @param {string} email
   * @return {Promise<void>}
   */
  async verifyEmail(email: string): Promise<void> {
    try {
      this.clearTimer();

      const validationErrors = await validate(this.customUser, { skipMissingProperties: true });
      if (validationErrors.length > 0) {
        for (const error of validationErrors) {
          if (error.property === this.signupInputType.email) {
            this.hasValidationError = true;
            await this.showValidationErrorMessage(validationErrors);
            return;
          }
        }
      }
      this.hasValidationError = false;

      const wrongVerificationCode = document.querySelector('#warningverifyCode');
      if (wrongVerificationCode) wrongVerificationCode.remove();
      this.verificationCode = '';

      const fetchResult = await this.app.user.checkAndSetServerRegion(UserLoginType.custom, false, null, null, this.verificationType.email, email);

      if (fetchResult) {
        this.alertExistsUser(this.verificationType.email);
        return;
      }

      const result = await this.app.user.sendVerificationEmail(email, this.usedLanguage);

      if (result.result) {
        console.log(result);
        if (this.alert !== null) {
          return;
        }
        this.isClickedVerificationBtn = true;
        this.serverVerificationCode = result.message;
        this.alert = true; // await 이므로 중복으로 뜨는 것을 막기위해
        this.alert = await this.alertController.create({
          cssClass: 'basic-dialog email-verification-alert',
          header: this.translate.instant('email verification'),
          message: this.translate.instant('email verification desc'),
          buttons: [
            {
              text: this.translate.instant('confirm'),
              handler: async (data) => {
                this.showVerificationCodeField = true;
                this.startTimer();
              }
            }
          ]
        });
        await this.alert.present();
        this.alert.onDidDismiss().then(async (data) => {
          this.alert = null;
        });
      } else {
        // 이메일 전송 실패
        console.error('인증 이메일 전송에 실패하였습니다.');
        this.isClickedVerificationBtn = false;
        throw new TooningVerifyEmailError('인증 이메일 전송 실패', this.app, true);
      }
    } catch (e) {
      console.error(e);
      this.isClickedVerificationBtn = false;
      throw new TooningVerifyEmailError(e, this.app, true);
    }
  }

  /**
   * 인증 유효 시간 설정 (Default 10분)
   */
  startTimer() {
    let time = TIME_LEFT,
      minutes,
      seconds;

    this.isTimer = true;
    const timer = setInterval(() => {
      minutes = parseInt((time / 60).toString(), 10);
      seconds = parseInt((time % 60).toString(), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      this.timeleft = minutes + ':' + seconds;

      if (--time < 0) {
        this.alertController
          .create({
            cssClass: 'basic-dialog',
            header: this.translate.instant('notice'),
            message: this.translate.instant('verification timeout desc'),
            buttons: [
              {
                text: this.translate.instant('confirm'),
                handler: async (data) => {
                  this.timeleft = TIME_LEFT_STRING;
                  this.showVerificationCodeField = false;
                  this.clearSecretCodeError();
                  this.clearTimer();
                }
              }
            ]
          })
          .then((alert) => alert.present());
        // 타이머 초기화
        this.clearTimer();
      }
    }, SECOND);
    this.timerList.push(timer);
  }

  /**
   * 타이머 초기화
   */
  clearTimer() {
    //timerList 다죽이기
    for (const time of this.timerList) {
      clearTimeout(time);
    }
    this.timerList = [];
    this.isTimer = false;
  }

  /**
   * 코드 확인
   * @param {string} verificationCode userInput verificationCode
   * @param {string} verificationType 인증 타입
   * @return {Promise<void>}
   */
  async verifyCode(verificationCode: string, verificationType: string = this.verificationType.email): Promise<void> {
    if (this.isTimer) {
      this.clearSecretCodeError();
      // 인증
      if (verificationCode === this.serverVerificationCode) {
        // 인증 완료
        if (verificationType === this.verificationType.email) {
          this.isVerified = true;
        }

        this.showVerificationCodeField = false;

        // 타이머 초기화
        this.clearTimer();
      } else {
        // 인증 실패
        const divMessage = this.translate.instant('wrong verification code');
        const warningDiv = `<div id='warningverifyCode' class='ValidationError'>${divMessage}</div>`;

        document.querySelector('#verification-code').insertAdjacentHTML('afterend', warningDiv);
      }
    } else {
      // 검증 시간 만료
      // Do nothing
    }
  }

  /**
   * clear Secret Code Error Messages
   * @return {void}
   */
  clearSecretCodeError(): void {
    try {
      if (document.querySelector('.ValidationError') != null) {
        // @ts-ignore
        // tslint:disable-next-line:variable-name
        for (const _div of document.querySelectorAll(`.ValidationError`)) {
          _div.style.display = 'none';
        }
      }
    } catch (e) {
      throw new TooningSignupVerificationUIError(e);
    }
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy 회원가입');
    super.ngOnDestroy();
    /**
     * Timer Clear
     */
    if (this.isTimer) {
      this.clearTimer();
    }
  }

  /**
   * 문자로 사용자 인증
   * @param {string} phoneNumber 유저가 입력한 핸드폰 번호
   * @return {Promise<void>}
   */
  async verifyPhoneNumber(phoneNumber: string): Promise<void> {
    try {
      if (!phoneNumberRegex.test(phoneNumber)) {
        await this.app.showToast(this.translate.instant('잘못된 번호입니다. 확인 후 다시 입력해 주세요.'));
        return;
      }

      this.clearTimer();

      const fetchResult = await this.app.user.checkAndSetServerRegion(
        UserLoginType.custom,
        false,
        null,
        null,
        this.verificationType.sms,
        phoneNumber
      );

      if (fetchResult) {
        this.alertExistsUser(this.verificationType.sms);
        return;
      }

      phoneNumber = phoneNumber.replace(/-/g, '');
      const resultPhoneNumber = this.selectedCountry.countryCode.concat(phoneNumber);
      const result = await this.app.user.authSNS(resultPhoneNumber);

      if (result.result) {
        console.log(result);

        if (this.alert) return;

        this.serverVerificationCode = result.message;
        this.alert = true; // await 이므로 중복으로 뜨는 것을 막기위해
        this.alert = await this.alertController.create({
          cssClass: 'basic-dialog email-verification-alert',
          header: this.translate.instant('signup page.phone_text1'),
          message: this.translate.instant('signup page.phone_text2'),
          buttons: [
            {
              text: this.translate.instant('confirm'),
              handler: async (data) => {
                this.showVerificationCodeField = true;
                this.startTimer();
              }
            }
          ]
        });
        await this.alert.present();
        this.alert.onDidDismiss().then(async (data) => {
          this.alert = null;
        });
      }
    } catch (e) {
      logErrorMessage('signup.verifyPhoneNumber', e.message, e.stack, this.app.user.getUserEmail(), true);
      throw new TooningVerifyPhoneNumberError(e);
    }
  }

  /**
   * 국가코드로 flag 아이콘 가져오기
   * @param countryCode 국가코드
   * @return {string}
   */
  getFlagEmoji(countryCode): string {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }

  /**
   * 사용자가 입력한 국가 정보를 가지고 국가리스트 필터 기능
   * @param {string} value
   * @return {string[]}
   * @private
   */
  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    // @ts-ignore
    return this.countryList.filter((country) => this._normalizeValue(country.country).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  /**
   * 핸드폰 번호 입력시 자동 하이픈 생성
   * @return {void}
   */
  autoHypenPhone(): void {
    this.customUser.phoneNumber = this.regexService.phoneNumberAutoHypen(this.customUser.phoneNumber);
  }

  /**
   * 인증 방식 변경 함수
   * @return {void}
   */
  changeVerificationType(): void {
    try {
      if (this.userVerificationType === this.verificationType.email) {
        this.userVerificationType = this.verificationType.sms;
      } else {
        this.userVerificationType = this.verificationType.email;
        this.customUser.phoneNumber = null;
      }
      this.showVerificationCodeField = false;
      this.verificationCode = null;
      this.isClickedVerificationBtn = false;
      this.clearSecretCodeError();
      this.clearTimer();
      this.removeValidationErrorMessage();
    } catch (e) {
      throw new TooningSignUpChangeVerificationTypeError(e, this.app, true);
    }
  }

  /**
   * 한국에서 접속한 유저인지 확인하는 함수
   * @return {Promise<boolean>}
   */
  async isUserCountryKorea(): Promise<boolean> {
    const country = await (await this.app.user.getAnonymousUserCountry().toPromise()).data.message;

    if (country === CountryCode.dev || country === CountryCode.kr) return true;

    if (this.app.usedLanguage === LanguageType.ko) return true;

    return false;
  }

  /**
   * 사용자 동의 전체 동의 체크박스 컨트롤
   * @param {Event} event 체크박스 변경 이벤트
   * @return {void}
   */
  changeCheckbox(event: Array<boolean>): void {
    try {
      if (!event || event.length !== 5) return;

      this.customUser.promotion = event[4];
      this.isEssentialChecked = event[1] && event[2] && event[3];
    } catch (e) {
      throw new TooningSignUpUIError(e);
    }
  }

  /**
   * 오류 문구 보여주는 함수
   * @param {HTMLDivElement} warningDiv
   * @return {void}
   */
  showWarningText(warningDiv: HTMLDivElement): void {
    const id = warningDiv.id;
    const checkType = id.replace('warning', '');
    if (!this.signupInputType.hasOwnProperty(checkType)) return;

    try {
      if (this.validationErrorCheck[checkType](this.customUser)) {
        warningDiv.style.display = 'block';
      } else warningDiv.style.display = 'none';
    } catch (e) {
      throw new TooningSignUpUIError(e);
    }
  }

  /**
   * 이미 가입한 유저 경고 알럿 띄우기
   * @param {VerificationType} verificationType 인증 타입 email, sms
   * @return {void}
   */
  alertExistsUser(verificationType: VerificationType): void {
    try {
      const message =
        verificationType === this.verificationType.email
          ? this.translate.instant('signupPage.signupform.already-text-1')
          : this.translate.instant('signupPage.signupform.already-text-2');

      this.alertController
        .create({
          cssClass: 'basic-dialog',
          header: this.translate.instant('notice'),
          message,
          buttons: [
            {
              text: this.translate.instant('confirm'),
              role: 'cancel'
            }
          ]
        })
        .then((alert) => alert.present());
    } catch (e) {
      throw new TooningAlertExistsUser(e);
    }
  }
}
