/**
 * Class LoadingTimerManager
 */
class LoadingTimerManager {
  private static instance: LoadingTimerManager;
  private timerList = [];


  /**
   * instance 가 없을 경우 생성하며, 생성된 경우는 사용 중인 instance 를 사용합니다.
   */
  public static getInstance() {
    try {
      if (!LoadingTimerManager.instance) {
        LoadingTimerManager.instance = new LoadingTimerManager();
      }
      return LoadingTimerManager.instance;
    } catch (e) {
      throw new Error(`LoadingTimerManager error : ${e.message}`);
    }
  }

  /**
   * setTimer instance 를 저장한다.
   * @param timer
   */
  saveTimer(timer) {
    this.timerList.push(timer);
  }

  /**
   * new Loading 시 생성된 timer 모드를 clear 한다
   */
  removeTimer() {
    for (const time of this.timerList) {
      clearTimeout(time);
    }
    this.timerList = [];
  }
}

export { LoadingTimerManager };
