import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EduGroupPriceForm } from '../../interfaces/app.interface';
import { Currency, UsedPeriodUnit } from '../../enum/app.enum';
import { EduApplicantsService } from '../../services/eduApplicants/edu-applicants.service';
import { AppService } from '../../services/app.service';
import {
  TooningCreatePriceError,
  TooningDeletePriceError,
  TooningInitEduGroupPriceError,
  TooningTooltipOpenError,
  TooningUpdatePriceError
} from '../../pages-tooning/errors/TooningErrors';
import Sortable, { SortableEvent } from 'sortablejs';
import { AlertController } from '@ionic/angular';
import { Loading } from '../../services/loading';

@Component({
  selector: 'app-add-edu-group-price',
  templateUrl: './add-edu-group-price.component.html',
  styleUrls: ['./add-edu-group-price.component.scss']
})
export class AddEduGroupPriceComponent implements OnInit, AfterViewInit {
  @ViewChild('sortableJs', { static: false }) sortableJs: ElementRef;
  public eduGroupPriceList: Array<EduGroupPriceForm> = [];
  public eduGroupIndex: number = 0;

  public usedPeriodUnitEnum = UsedPeriodUnit;
  public currencyEnum = Currency;
  public isEditMode = false;
  public sortableJsInstance: Sortable;
  private loading = new Loading();

  constructor(private eduService: EduApplicantsService, private app: AppService, private alertCtrl: AlertController) {}

  async ngOnInit() {
    await this.initEduGroupPrice();
  }

  ngAfterViewInit() {
    this.sortableJsInstance = new Sortable(this.sortableJs.nativeElement, {
      filter: '.filter',
      handle: '.handle',
      disabled: !this.isEditMode,
      onEnd: (event) => this.sortableMove(event)
    });
  }

  /**
   * 요금제 생성 함수
   * @return {Promise<void>}
   */
  async createPrice(): Promise<void> {
    try {
      const newItem: EduGroupPriceForm = {
        minPersonnel: 1,
        maxPersonnel: 300,
        usedPeriod: 1,
        usedPeriodUnit: UsedPeriodUnit.month,
        pricePerPerson: 0,
        costPerPerson: 0,
        currency: Currency.KRW,
        isPromotion: false,
        order: this.eduGroupPriceList.length
      };
      const { data } = await this.eduService.createEduGroupPrice(newItem).toPromise();
      newItem.id = data;
      this.eduGroupPriceList.unshift(newItem);
      if (data) {
        await this.app.showToast('단체 요금제 생성 완료');
      }
    } catch (e) {
      await this.app.showToast(e.message);
      throw new TooningCreatePriceError(e);
    }
  }

  /**
   * 요금제 update
   * @return {Promise<void>}
   */
  async updatePrice(): Promise<void> {
    try {
      const updateList: Array<EduGroupPriceForm> = [];
      updateList.push(...this.eduGroupPriceList.filter((groupPrice) => groupPrice.dirty === true));
      // validation check
      const invalidTargetList = updateList.filter(
        (groupPrice) => groupPrice.isPromotion && !(groupPrice.promotionStartDate || groupPrice.promotionEndDate)
      );
      if (invalidTargetList.length > 0) {
        for (let target of invalidTargetList) {
          const index = this.eduGroupPriceList.findIndex((groupPrice) => groupPrice.order === target.order);
          this.sortableJs.nativeElement.getElementsByTagName('tr')[index].style.backgroundColor = 'var(--editor-primary-10)';
        }
        const alert = await this.alertCtrl.create({
          header: '알림',
          cssClass: 'basic-dialog',
          message: '요금제를 저장하시려면 이벤트의 <br> 시작·종료날짜를 입력해주세요.',
          backdropDismiss: false,
          buttons: [
            {
              text: '확인',
              role: 'cancel'
            }
          ]
        });
        await alert.present();
        return;
      }
      if (updateList.length > 0) {
        updateList.forEach((groupPrice) => delete groupPrice.dirty);
        const { data } = await this.eduService.updateEduGroupPrice(updateList).toPromise();
        if (data) {
          await this.app.showToast('갱신 성공');
          this.isEditMode = false;
        }
      } else {
        await this.app.showToast('변경된 요금제가 없습니다.');
      }
    } catch (e) {
      throw new TooningUpdatePriceError(e);
    }
  }

  /**
   * 요금제 삭제
   * @param {number} eduGroupPriceId 삭제할 price id
   * @param {number} index array에서 뺄 index
   * @return {Promise<void>}
   */
  async deletePrice(eduGroupPriceId: number, index: number): Promise<void> {
    try {
      const alert = await this.alertCtrl.create({
        header: '삭제하시겠습니까?',
        cssClass: 'basic-dialog',
        message: '삭제된 항목은 되돌릴 수 없습니다',
        backdropDismiss: false,
        buttons: [
          { text: '취소', role: 'cancel' },
          {
            text: '확인',
            handler: async () => {
              await this.loading.showLoader();
              const { data } = await this.eduService.deleteEduGroupPrice(eduGroupPriceId).toPromise();
              if (data) {
                await this.app.showToast('삭제 성공');
                this.eduGroupPriceList.splice(index, 1);
              }
              this.loading.hideLoader();
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      throw new TooningDeletePriceError(e);
    }
  }

  /**
   * 정렬 이벤트 발생시 호출되는 함수
   * @param {Sortable.SortableEvent} event
   * @return {void}
   */
  sortableMove(event: SortableEvent): void {
    const items = this.sortableJsInstance.toArray();
    items.forEach((itemId, index) => {
      const item = this.eduGroupPriceList.find((form) => +form.id === +itemId);
      if (item) {
        item.order = index + 1;
        item.dirty = true;
      }
    });
  }

  /**
   * 편집 모드로 진입
   * @return {void}
   */
  changeEditMode(): void {
    this.isEditMode = !this.isEditMode;
    this.sortableJsInstance.option('disabled', !this.isEditMode);
  }

  /**
   * 모달 닫기
   * @return {void}
   */
  closeModal(): void {
    this.app.modal.dismiss();
  }

  /**
   * 요금제 초기화 함수
   * @return {Promise<void>}
   */
  async initEduGroupPrice(): Promise<void> {
    try {
      if (this.isEditMode) this.changeEditMode();
      await this.loading.showLoader();
      this.eduGroupPriceList = [];
      const fetchData = await this.eduService.getEduGroupPriceList().toPromise();
      this.eduGroupPriceList = fetchData.data;
      this.eduGroupPriceList.forEach((eduGroupPrice) => {
        eduGroupPrice.dirty = false;
        if (eduGroupPrice.isPromotion) {
          eduGroupPrice.promotionStartDate = eduGroupPrice.promotionStartDate.toLocaleString().replace('Z', '');
          eduGroupPrice.promotionEndDate = eduGroupPrice.promotionEndDate.toLocaleString().replace('Z', '');
        }
      });
      this.eduGroupPriceList.sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
    } catch (e) {
      throw new TooningInitEduGroupPriceError(e);
    } finally {
      this.loading.hideLoader();
    }
  }

  /**
   * 툴팁 오픈 함수
   * @return {Promise<void>}
   */
  async tooltipOpen(): Promise<void> {
    try {
      const alert = await this.alertCtrl.create({
        header: '삭제된 요금제 복구',
        cssClass: 'basic-dialog',
        message:
          '삭제된 요금제는 개발팀에 요청해 복구할 수 있지만, 직접 <strong>동일한 설정의 요금제를 새로 추가</strong>해서 오류 없이 이용 가능합니다.<label class="important-strong">(권장)</label>',
        backdropDismiss: false,
        buttons: [
          {
            text: '확인',
            role: 'cancel'
          }
        ]
      });
      //@ts-ignore
      alert.getElementsByClassName('important-strong')[0].style.color = '#EB5757';
      await alert.present();
    } catch (e) {
      throw new TooningTooltipOpenError(e);
    }
  }
}
