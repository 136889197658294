import { IsNotEmpty, IsIn, ValidateIf } from 'class-validator';
import { AppComponent } from '../../app.component';
import { ResMakerEtcUloadService } from './etcUpload.service';
import * as _ from 'lodash';

// 기본속성이나 페이지에 표현되지 않는 속성들
// 발행상태 및 상세페이지별 완료 여부
export class ModelEtcUploadMeta {
  // implements ModelCharacterBase {

  public isSet: boolean;
  public sourceId: number;

  constructor(data?: Partial<ModelEtcUploadMeta>) {
    if (data != null) {
      Object.assign(this, data);
    } else {
      this.init();
    }
  }

  public init() {
    this.status = 'prepare';
    this.id = null;
  }

  @IsNotEmpty() id: number; // 백ㅇ id, DB에 저장된 상태가 아니면 null
  @IsIn(['publish', 'prepare']) status: string; // 배포상태 - 검증통과시에만 published 가능하게
}

export class ModelEtcUploadStep {
  // implements ModelCharacterBase {

  constructor(public appComPo: AppComponent, public resMakerEtcUloadService: ResMakerEtcUloadService) {}

  // step Check
  public stepTFCheck(itemId, sourceType) {
    return new Promise(async (resolve, reject) => {
      try {
        if (itemId === 0) {
          this.appComPo.iconMark = _.fill(Array(3), 'square-outline');
          return;
        }
        const etcUploadStepData = await this.resMakerEtcUloadService.getEtcUploadEachStepData(itemId, sourceType);
        etcUploadStepData.subscribe(
          (result) => {
            const StepTF = result.data;
            let index = 0;

            for (const step in StepTF.getEtcUploadEachStepData) {
              // @ts-ignore
              this.appComPo.iconMark[index] = StepTF.getEtcUploadEachStepData[step] ? 'checkbox-outline' : 'square-outline';
              index++;
            }

            resolve(true);
          },
          (err) => {
            console.error('err:::::::', err);
            reject(err);
          }
        );
      } catch (e) {
        reject(e);
      }
    });
  }
}

// 캐릭터 소개 페이지 정보들
export class ModelEtcUploadBasic {
  // implements ModelCharacterBase {

  constructor(data?: Partial<ModelEtcUploadBasic>) {
    if (data != null) {
      Object.assign(this, data);
    } else {
      this.init();
    }
  }

  public init() {
    this.tags = [];
  }

  // validator 사용법 : https://github.com/typestack/class-validator#usage
  // validator 리스트 : https://github.com/typestack/class-validator#validation-decorators
  @IsNotEmpty() name_ko: string;
  @IsNotEmpty() name_en: string;
  @IsNotEmpty() desc_ko: string;
  @IsNotEmpty() desc_en: string;
  @IsNotEmpty() name_fr: string;
  @IsNotEmpty() name_jp: string;
  @IsNotEmpty() desc_fr: string;
  @IsNotEmpty() desc_jp: string;

  @IsNotEmpty() imgName: string;
  fabricObject: object;
  tags: string[];
  sourceType: string;
  base64: string;

  // 요청보낼때 쓰이는 데이터
  @ValidateIf((o) => o.imgPath == null)
  @IsNotEmpty()
  fileImg: File;
  // db에서 받아올떄만 있는 데이터
  @ValidateIf((o) => o.fileImg == null)
  imgPath: string;
}

export class ModelEtcUpload {
  // implements ModelCharacterBase {

  @IsNotEmpty() sourceId: number;
  @IsNotEmpty() sourceType: string;
  @IsNotEmpty() base64: string;
}

export class ModelEtcUploadType {
  // implements ModelCharacterBase {

  constructor(data?: Partial<ModelEtcUpload>) {
    if (data != null) {
      Object.assign(this, data);
    }
  }

  @IsNotEmpty() etcUpload: ModelEtcUpload[];
  @IsNotEmpty() etcType: string;
}
