import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-no-item',
  templateUrl: './no-item.component.html',
  styleUrls: ['./no-item.component.scss']
})
export class NoItemComponent implements OnInit {
  constructor(public translate: TranslateService) {}
  ngOnInit() {}
}
