import { Injectable } from '@angular/core';
import { Subscription } from 'apollo-angular';
import { gql } from '@apollo/client';

@Injectable({
  providedIn: 'root'
})
export class PointChargeSubscriptionService extends Subscription {
  document = gql`
    subscription pointChargeDone($topic: ID!) {
      pointChargeDone(topic: $topic) {
        id
        point
        result
        message
        date
      }
    }
  `;
}
