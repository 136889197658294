import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validationMsg'
})
export class ValidationMsgPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    let converted = '';
    switch (value) {
      case 'isNotEmpty':
        converted = '내용을 입력해주세요';
        break;
      case 'isIn':
        converted = '항목을 선택해주세요';
        break;
      case 'isUrl':
        converted = 'url로 입력해주세요';
        break;
      case 'isDate':
        converted = 'Date 타입이 아닙니다.';
        break;
      case 'isEmail':
        converted = 'Email 형식이 아닙니다.';
        break;
      case 'length':
        converted = '입력 가능한 글자수를 초과하였습니다.';
        break;
      default:
        converted = '잘못된 입력입니다';
    }
    return converted;
  }
}
