import { Injectable } from '@angular/core';
import { GraphqlApiService } from '../../../services/api/graphql.api.service';
import { BoardFilterTypes, BoardSettingOptionsEnum } from '../enums/board.enum';
import { gql } from '@apollo/client';
import { firstValueFrom } from 'rxjs';
import { ApiReturnType, EntityStatus } from '../interfaces/common.type';
import {
  BoardBoardType,
  BoardCommentType,
  BoardGroupType,
  BoardPostCreateInputType,
  BoardPostType,
  BoardPostUpdateInputType,
  BoardSettingOptionsType,
  BoardSettingUpdatedKeyType,
  BoardUpdateKey,
  BoardUpdatePostOrderInputType,
  BoardUserType
} from '../interfaces/board.type';
import { CanAccessBoardReturns, GetBoardCapacityReturns } from '../interfaces/api-return.interface';

@Injectable({
  providedIn: 'root'
})
export class BoardApiService {
  constructor(public graphql: GraphqlApiService) {}

  /**
   * 보드 리스트 가져오기
   * @param {BoardFilterTypes} type
   * @return {Promise<ApiReturnType<BoardBoardType[]>>>}
   */
  public async getBoardList(type: BoardFilterTypes = BoardFilterTypes.recent): Promise<ApiReturnType<BoardBoardType[]>> {
    try {
      const {
        data: {
          getBoardList: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query GetBoardList($boardFilterType: BoardFilterType!) {
              getBoardList(boardFilterType: $boardFilterType) {
                result
                errorCode
                errorMessage
                data {
                  nowBoardUserPermission
                  id
                  boardKey
                  title
                  description
                  wallpaperS3Key
                  wallpaperThumbnailS3Key
                  address
                  createdDate
                  updatedDate
                  contentLastUpdatedDate
                  generatorBoardUser {
                    boardUserId
                    userId
                    userName
                    isAnonymous
                    role
                  }
                  setting {
                    showGroup
                  }
                }
              }
            }
          `,
          {
            boardFilterType: type
          }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 생성
   * @param {string} boardTitle
   * @return {Promise<ApiReturnType<BoardBoardType>>}
   */
  public async createBoard(boardTitle?: string): Promise<ApiReturnType<BoardBoardType>> {
    try {
      const {
        data: {
          createBoard: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation CreateBoard($boardTitle: String) {
              createBoard(boardTitle: $boardTitle) {
                result
                errorCode
                errorMessage
                data {
                  nowBoardUserPermission
                  id
                  boardKey
                  title
                  description
                  wallpaperS3Key
                  wallpaperThumbnailS3Key
                  address
                  createdDate
                  updatedDate
                  contentLastUpdatedDate
                  generatorBoardUser {
                    boardUserId
                    userId
                    userName
                    isAnonymous
                    role
                  }
                  setting {
                    showGroup
                  }
                }
              }
            }
          `,
          { boardTitle }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 업데이트
   * @param {{boardId: number, updatedValue: string, updatedKey: string}} input
   * @return {Promise<ApiReturnType<BoardBoardType>>}
   */
  public async updateBoard(input: { boardId: number; updatedValue: string; updatedKey: BoardUpdateKey }): Promise<ApiReturnType<BoardBoardType>> {
    try {
      const {
        data: {
          updateBoard: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation UpdateBoard($updatedValue: String!, $updatedKey: BoardUpdateKey!, $boardId: Int!) {
              updateBoard(updatedValue: $updatedValue, updatedKey: $updatedKey, boardId: $boardId) {
                result
                errorCode
                errorMessage
                data {
                  nowBoardUserPermission
                  id
                  boardKey
                  title
                  description
                  wallpaperS3Key
                  createdDate
                  updatedDate
                  contentLastUpdatedDate
                  setting {
                    layoutType
                    linkPrivacyType
                    orderType
                    reactionType
                    showComment
                    showGroup
                    showWriterTimestamp
                    visitorPermissionType
                  }
                }
              }
            }
          `,
          input
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 삭제
   * @param {number} boardId
   * @return {Promise<ApiReturnType<void>>}
   */
  public async deleteBoard(boardId: number): Promise<ApiReturnType<void>> {
    try {
      const {
        data: {
          deleteBoard: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation DeleteBoard($boardId: Int!) {
              deleteBoard(boardId: $boardId) {
                result
                errorCode
                errorMessage
              }
            }
          `,
          { boardId }
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 삭제한 보드 복원하기
   * @param {number} boardId
   * @return {Promise<ApiReturnType<void>>}
   */
  public async restoreBoard(boardId: number): Promise<ApiReturnType<void>> {
    try {
      const {
        data: {
          restoreBoard: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation RestoreBoard($boardId: Int!) {
              restoreBoard(boardId: $boardId) {
                result
                errorCode
                errorMessage
              }
            }
          `,
          { boardId }
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 유저 생성
   * @return {Promise<ApiReturnType<BoardUserType>>}
   */
  public async createBoardUser(): Promise<ApiReturnType<BoardUserType>> {
    try {
      const {
        data: {
          createBoardUser: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation CreateBoardUser {
              createBoardUser {
                result
                errorCode
                errorMessage
                data {
                  boardUserId
                  userId
                  userName
                  isAnonymous
                  role
                  userKey
                }
              }
            }
          `
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 투닝 유저아이디로 보드유저 정보 확인
   * @return {Promise<ApiReturnType<BoardUserType | null>>}
   */
  public async getBoardUserByUserId(): Promise<ApiReturnType<BoardUserType | null>> {
    try {
      const {
        data: {
          getBoardUserByUserId: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query GetBoardUserByUserId {
              getBoardUserByUserId {
                result
                errorCode
                errorMessage
                data {
                  boardUserId
                  userId
                  userName
                  userKey
                  isAnonymous
                  role
                  userImgUrl
                }
              }
            }
          `
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 접근 가능한지 확인
   * @return {Promise<ApiReturnType<CanAccessBoardReturns>>}
   */
  public async canAccessBoard(boardKey: string): Promise<ApiReturnType<CanAccessBoardReturns>> {
    try {
      const {
        data: {
          canAccessBoard: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query CanAccessBoard($boardKey: String!) {
              canAccessBoard(boardKey: $boardKey) {
                result
                errorCode
                errorMessage
                data {
                  canAccess
                  notAccessReason
                }
              }
            }
          `,
          { boardKey }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 디테일 정보 가져오기
   * @param {string} boardKey
   * @return {Promise<ApiReturnType>}
   */
  public async getBoardDetailByBoardKey(boardKey: string): Promise<ApiReturnType<BoardBoardType>> {
    try {
      const {
        data: {
          getBoardDetailByBoardKey: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query GetBoardDetailByBoardKey($boardKey: String!) {
              getBoardDetailByBoardKey(boardKey: $boardKey) {
                result
                errorCode
                errorMessage
                data {
                  nowBoardUserPermission
                  id
                  boardKey
                  title
                  description
                  wallpaperS3Key
                  address
                  createdDate
                  updatedDate
                  contentLastUpdatedDate
                  generatorBoardUser {
                    boardUserId
                    userId
                    userName
                    isAnonymous
                    role
                  }
                  setting {
                    layoutType
                    orderType
                    reactionType
                    visitorPermissionType
                    linkPrivacyType
                    showGroup
                    showComment
                    showWriterTimestamp
                  }
                }
              }
            }
          `,
          { boardKey }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 그룹리스트 가져오기
   * @param {number} boardId
   * @return {Promise<ApiReturnType<BoardGroupType[]>>}
   */
  public async getGroupList(boardId: number): Promise<ApiReturnType<BoardGroupType[]>> {
    try {
      const {
        data: {
          getGroupList: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query GetGroupList($boardId: Int!) {
              getGroupList(boardId: $boardId) {
                result
                errorCode
                errorMessage
                data {
                  id
                  title
                  order
                }
              }
            }
          `,
          { boardId }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 포스트리스트 가져오기
   * @param {number} boardId
   * @return {Promise<ApiReturnType<BoardPostType[]>>}
   */
  public async getPostList(boardId: number): Promise<ApiReturnType<BoardPostType[]>> {
    try {
      const {
        data: {
          getPostList: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query GetPostList($boardId: Int!) {
              getPostList(boardId: $boardId) {
                result
                errorCode
                errorMessage
                data {
                  id
                  boardId
                  groupId
                  title
                  attachmentUrl
                  attachment {
                    fileName
                    s3Key
                    fileSize
                  }
                  postType
                  content
                  order
                  writerBoardUser {
                    boardUserId
                    userId
                    userName
                    isAnonymous
                  }
                  comments {
                    id
                    writerBoardUser {
                      boardUserId
                      userId
                      userName
                      isAnonymous
                      userImgUrl
                    }
                    content
                    createdDate
                    updatedDate
                  }
                  createdDate
                  updatedDate
                }
              }
            }
          `,
          { boardId }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 그룹 생성
   * @param {{boardId: number, title: string}} input
   * @return {Promise<ApiReturnType<BoardGroupType>>}
   */
  public async createGroup(input: { boardId: number; title: string }): Promise<ApiReturnType<BoardGroupType>> {
    try {
      const {
        data: {
          createGroup: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation CreateGroup($input: CreateGroupInput!) {
              createGroup(input: $input) {
                result
                errorCode
                errorMessage
                data {
                  id
                  title
                  order
                }
              }
            }
          `,
          { input }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 그룹 업데이트
   * @param {{groupId: number, updateKey: string, updateValue: string}} input
   * @return {Promise<ApiReturnType<BoardGroupType>>}
   */
  public async updateGroup(input: { groupId: number; title: string }): Promise<ApiReturnType<BoardGroupType>> {
    try {
      const {
        data: {
          updateGroup: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation UpdateGroup($title: String!, $groupId: Int!) {
              updateGroup(title: $title, groupId: $groupId) {
                result
                errorCode
                errorMessage
                data {
                  id
                  title
                  order
                }
              }
            }
          `,
          input
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 그룹 제거
   * @param {number} groupId
   * @return {Promise<ApiReturnType<void>>}
   */
  public async deleteGroup(groupId: number): Promise<ApiReturnType<void>> {
    try {
      const {
        data: {
          deleteGroup: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation DeleteGroup($groupId: Int!) {
              deleteGroup(groupId: $groupId) {
                result
                errorCode
                errorMessage
              }
            }
          `,
          { groupId }
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * url 썸네일 받기
   * @param {string} attachmentUrl
   * @return {Promise<ApiReturnType<string>>}
   */
  public async getThumbnailUrl(attachmentUrl: string): Promise<ApiReturnType<string>> {
    try {
      const {
        data: {
          getThumbnailUrl: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            query GetThumbnailUrl($attachmentUrl: String!) {
              getThumbnailUrl(attachmentUrl: $attachmentUrl) {
                result
                errorCode
                errorMessage
                data
              }
            }
          `,
          { attachmentUrl }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 새로운 포스트 생성
   * @param {BoardPostCreateInputType} input
   * @return {Promise<ApiReturnType<BoardPostType>>}
   */
  public async createPost(input: BoardPostCreateInputType): Promise<ApiReturnType<BoardPostType>> {
    try {
      const {
        data: {
          createPost: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation CreatePost($input: CreatePostInput!) {
              createPost(input: $input) {
                result
                errorCode
                errorMessage
                data {
                  id
                  boardId
                  groupId
                  title
                  content
                  postType
                  attachmentUrl
                  order
                  writerBoardUser {
                    boardUserId
                    userId
                    userName
                    userKey
                    isAnonymous
                    role
                  }
                  comments {
                    id
                    writerBoardUser {
                      boardUserId
                      userId
                      userName
                      isAnonymous
                      userImgUrl
                    }
                    content
                    createdDate
                    updatedDate
                  }
                  attachment {
                    fileName
                    s3Key
                    fileSize
                  }
                  createdDate
                  updatedDate
                }
              }
            }
          `,
          { input }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 포스트 업데이트
   * @param {{postId: number, title: string, content: string, attachmentUrl: string, thumbnailUrl: string}} input
   * @return {Promise<ApiReturnType<BoardPostType>>}
   */
  public async updatePost(input: BoardPostUpdateInputType): Promise<ApiReturnType<BoardPostType>> {
    try {
      const {
        data: {
          updatePost: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation UpdatePost($input: UpdatePostInput!) {
              updatePost(input: $input) {
                result
                errorCode
                errorMessage
                data {
                  id
                  boardId
                  groupId
                  title
                  content
                  postType
                  attachmentUrl
                  order
                  writerBoardUser {
                    boardUserId
                    userId
                    userName
                    userKey
                    isAnonymous
                    role
                  }
                  comments {
                    id
                    writerBoardUser {
                      boardUserId
                      userId
                      userName
                      isAnonymous
                      userImgUrl
                    }
                    content
                    createdDate
                    updatedDate
                  }
                  attachment {
                    fileName
                    s3Key
                    fileSize
                  }
                  createdDate
                  updatedDate
                }
              }
            }
          `,
          { input }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 포스트 삭제
   * @param {number} boardId
   * @param {number} postId
   * @return {Promise<ApiReturnType<void>>}
   */
  public async deletePost(boardId: number, postId: number): Promise<ApiReturnType<void>> {
    try {
      const {
        data: {
          deletePost: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation DeletePost($boardId: Int!, $postId: Int!) {
              deletePost(boardId: $boardId, postId: $postId) {
                result
                errorCode
                errorMessage
              }
            }
          `,
          { boardId, postId }
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 설정 리스트 가져오기
   * @param {{filter: BoardSettingUpdatedKeyType[], status: EntityStatus[]}} input
   * @return {Promise<ApiReturnType<any>>}
   */
  async getBoardSettingsOptions(
    input: { filter: BoardSettingUpdatedKeyType[]; status: EntityStatus[] } = {
      filter: [BoardSettingOptionsEnum.All],
      status: ['Active', 'Pending']
    }
  ): Promise<ApiReturnType<BoardSettingOptionsType[]>> {
    try {
      const {
        data: {
          getBoardSettingsOptions: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query GetBoardSettingsOptions($status: [EntityStatus!]!, $filter: [BoardSettingAndInfoOptionType!]!) {
              getBoardSettingsOptions(status: $status, filter: $filter) {
                data {
                  options {
                    displayName
                    entityStatus
                    id
                    metaData
                    name
                  }
                  settingOptionName
                }
                errorCode
                errorMessage
                result
              }
            }
          `,
          input
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 그룹 모드 - 그룹 정렬 업데이트
   * @return {Promise<ApiReturnType<void>>}
   */
  async updateGroupOrder(input: { curGroupId: number; prevGroupId: number; nextGroupId: number }): Promise<ApiReturnType<void>> {
    try {
      const {
        data: {
          updateGroupOrder: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation UpdateGroupOrder($input: UpdateGroupOrderInput!) {
              updateGroupOrder(input: $input) {
                result
                errorCode
                errorMessage
              }
            }
          `,
          { input }
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 그룹 모드 - 포스트 정렬 업데이트
   * @param {BoardUpdatePostOrderInputType} input
   * @return {Promise<ApiReturnType<void>>}
   */
  async updatePostOrder(input: BoardUpdatePostOrderInputType): Promise<ApiReturnType<void>> {
    try {
      const {
        data: {
          updatePostOrder: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation UpdatePostOrder($input: UpdatePostOrderInput!) {
              updatePostOrder(input: $input) {
                result
                errorCode
                errorMessage
              }
            }
          `,
          { input }
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 댓글 생성하기
   * @param {{boardId: number, postId: number, content: string}} input
   * @return {Promise<ApiReturnType<BoardCommentType>>}
   */
  async createComment(input: { boardId: number; postId: number; content: string }): Promise<ApiReturnType<BoardCommentType>> {
    try {
      const {
        data: {
          createComment: { result, data, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation CreateComment($boardId: Int!, $postId: Int!, $content: String!) {
              createComment(boardId: $boardId, postId: $postId, content: $content) {
                result
                errorCode
                errorMessage
                data {
                  id
                  writerBoardUser {
                    boardUserId
                    userId
                    userName
                    isAnonymous
                    userImgUrl
                  }
                  content
                  createdDate
                  updatedDate
                }
              }
            }
          `,
          { ...input }
        )
      );

      return { result, data, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 댓글 수정하기
   * @param {{boardId: number, commentId: number, content: string}} input
   * @return {Promise<ApiReturnType<BoardCommentType>>}
   */
  async updateComment(input: { boardId: number; commentId: number; content: string }): Promise<ApiReturnType<BoardCommentType>> {
    try {
      const {
        data: {
          updateComment: { result, data, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation UpdateComment($boardId: Int!, $commentId: Int!, $content: String!) {
              updateComment(boardId: $boardId, commentId: $commentId, content: $content) {
                result
                errorCode
                errorMessage
                data {
                  id
                  writerBoardUser {
                    boardUserId
                    userId
                    userName
                    isAnonymous
                  }
                  content
                  createdDate
                  updatedDate
                }
              }
            }
          `,
          { ...input }
        )
      );

      return { result, data, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 댓글 삭제하기
   * @param {{boardId: number, commentId: number, content: string}} input
   * @return {Promise<ApiReturnType<BoardCommentType>>}
   */
  async deleteComment(input: { boardId: number; commentId: number }): Promise<ApiReturnType<BoardCommentType>> {
    try {
      const {
        data: {
          deleteComment: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation DeleteComment($boardId: Int!, $commentId: Int!) {
              deleteComment(boardId: $boardId, commentId: $commentId) {
                result
                errorCode
                errorMessage
              }
            }
          `,
          { ...input }
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 휴지통 보드 전체 영구 삭제
   * @return {Promise<ApiReturnType<void>>}
   */
  async emptyBoardTrash(): Promise<ApiReturnType<void>> {
    try {
      const {
        data: {
          emptyBoardTrash: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation EmptyBoardTrash {
              emptyBoardTrash {
                result
                errorCode
                errorMessage
              }
            }
          `,
          {}
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 휴지통 선택 보드 영구 삭제
   * @param {number} boardId
   * @return {Promise<ApiReturnType<void>>}
   */
  async permanentlyDeleteBoard(boardId: number): Promise<ApiReturnType<void>> {
    try {
      const {
        data: {
          permanentlyDeleteBoard: { result, errorCode, errorMessage }
        }
      } = await firstValueFrom(
        this.graphql.mutate(
          gql`
            mutation PermanentlyDeleteBoard($boardId: Int!) {
              permanentlyDeleteBoard(boardId: $boardId) {
                result
                errorCode
                errorMessage
              }
            }
          `,
          {
            boardId
          }
        )
      );

      return { result, errorCode, errorMessage };
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 보드 용량 가져오기
   * @param {number} boardId
   * @return {Promise<ApiReturnType<GetBoardCapacityReturns>>>}
   */
  public async getBoardCapacity(boardId: number): Promise<ApiReturnType<GetBoardCapacityReturns>> {
    try {
      const {
        data: {
          getBoardCapacity: { result, errorCode, errorMessage, data }
        }
      } = await firstValueFrom(
        this.graphql.query(
          gql`
            query GetBoardCapacity($boardId: Int!) {
              getBoardCapacity(boardId: $boardId) {
                result
                errorCode
                errorMessage
                data {
                  currentUsage
                  capacityLimit
                }
              }
            }
          `,
          {
            boardId
          }
        )
      );

      return { result, errorCode, errorMessage, data };
    } catch (e) {
      throw new Error(e);
    }
  }
}
