import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DirectionChangeNumber'
})
export class DirectionChangeNumberPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    let converted = 0;
    switch (value) {
      case 'side':
        converted = 1;
        break;
      case 'back':
        converted = 2;
        break;
    }
    return converted;
  }
}
