<div class='custom-header'>
  <div class='header-buttons end' *ngIf='isModal'>
    <ion-buttons slot='end' *ngIf='isModal'>
      <ion-button (click)='modalCtrl.dismiss()'>
        <ion-icon color='black' name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
</div>

<ion-content appScrollbarTheme>
  <div class='modal-content-wrapper'>
    <div class='title-box'>
      <div class='title' [innerHTML]="'pad help message 1' | translate"></div>
      <div class='title' [innerHTML]="'pad help message 2' | translate"></div>
    </div>

    <div class='content-layout' [class.beta]='isBetaUser'>
      <!--   일반 & 교육용    -->
      <div class='plan-box'>
        <div class='content-box disabled-content-box-color'>
          <div class='content-box-top'>
            <div class='content-box-title disabled-text-color'>
              {{(isBetaUser ? "edu pro" : 'pad-help.message-1-1') | translate}}
            </div>
            <div class='content-box-sub-title disabled-text-color'>
              {{'pad-help.message-1-2' | translate}}
            </div>
          </div>
          <div class='substance-box'>
            <div *ngIf='!isBetaUser' class='content-box-substance disabled-text-color'>
              <ion-icon name='checkmark-outline' class='default-icon-color'></ion-icon>
              {{'pad-help.message-1-3' | translate}}
            </div>
            <div class='content-box-substance disabled-text-color'>
              <ion-icon name='checkmark-outline' class='default-icon-color'></ion-icon>
              {{(isBetaUser ? "pad-help.message-3-3" : 'pad-help.message-1-4') | translate}}
            </div>
            <div class='content-box-substance disabled-text-color'>
              <ion-icon name='checkmark-outline' class='default-icon-color'></ion-icon>
              {{(isBetaUser ? "pad-help.message-3-4" : 'pad-help.message-1-5') | translate}}
            </div>
            <div class='content-box-substance disabled-text-color'>
              <ion-icon name='checkmark-outline' class='default-icon-color'></ion-icon>
              {{(isBetaUser ? "pad-help.message-3-5" : 'pad-help.message-1-6') | translate}}
            </div>
            <div class='content-box-substance disabled-text-color'>
              <ion-icon name='checkmark-outline' class='default-icon-color'></ion-icon>
              {{'pad-help.message-1-7' | translate}}
            </div>
          </div>
          <div class='button-box'>
            <button [ngClass]='app.isDesktopWidth()?"custom-size-l":"custom-size-xs"'
                    class='context-button disabled-button-color disabled-weight'>{{'pad-help.message-1-8' | translate}}</button>
          </div>
        </div>
      </div>

      <!--   프로   -->
      <div class='plan-box'>
        <div class='content-box relative-box service-content-box'>
          <div class='bookmark-icon'>
            <ion-icon name='bookmark-sharp'></ion-icon>
          </div>
          <div class='content-box-top'>
            <div class='content-box-title service-weight'>
              {{'pad-help.message-2-1' | translate}}
            </div>
            <div class='content-box-sub-title'>
            <span
              class='pay-span'>{{this.plans?.events[0]?.price | currency: app.getCurrencyCode() : 'symbol' : app.hasDecimalPrice() ? '1.2' : '1.0'}}</span>
              &nbsp; /{{'month' | translate}}
            </div>
          </div>
          <div class='substance-box'>
            <div *ngIf='!isBetaUser' class='content-box-substance'>
              <ion-icon name='checkmark-outline'></ion-icon>
              {{'pad-help.message-2-2' | translate}}
            </div>
            <div class='content-box-substance'>
              <ion-icon name='checkmark-outline'></ion-icon>
              {{'pad-help.message-2-3' | translate}}
            </div>
            <div class='content-box-substance'>
              <ion-icon name='checkmark-outline'></ion-icon>
              {{'pad-help.message-2-4' | translate}}
            </div>
            <div class='content-box-substance'>
              <ion-icon name='checkmark-outline'></ion-icon>
              {{'pad-help.message-2-5' | translate}}
            </div>
            <div class='content-box-substance'>
              <ion-icon name='checkmark-outline'></ion-icon>
              {{'pad-help.message-2-6' | translate}}
            </div>
          </div>
          <div class='button-box'>
            <button (click)='close()' [ngClass]='app.isDesktopWidth()?"custom-size-l":"custom-size-xs"'
                    class='context-button service-button service-weight'>{{'pad-help.message-2-7' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

