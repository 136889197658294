import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class AllHair {
  id?: number;
  @IsNumber() @IsNotEmpty() characterId: number;
  @IsNumber() @IsNotEmpty() frontHairOrder: number;
  @IsNumber() @IsNotEmpty() backHairOrder: number;

  @IsString() @IsNotEmpty() frontBase64: string;
  @IsString() @IsNotEmpty() sideBase64: string;
  @IsString() @IsNotEmpty() backBase64: string;
  @IsString() @IsOptional() fabricObject?: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
