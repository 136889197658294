import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BasePage } from '../../../../base-page';
import { AppService } from '../../../../services/app.service';
import { ModalController } from '@ionic/angular';
import { CanvasGroupService } from '../../../../services/canvasGroup/canvas-group.service';
import { Cut4MakeManualService } from '../../../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { ResMakerCharacterService } from '../../../../services/res-maker/character.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from '../../../../enum/app.enum';
import { TooningCanvasGroupSubmitError } from '../../../errors/TooningErrors';
import { FormControl, Validators } from '@angular/forms';
import _ from 'lodash';

@Component({
  selector: 'app-popover-submit-canvas',
  templateUrl: './popover-submit-canvas.component.html',
  styleUrls: ['./popover-submit-canvas.component.scss']
})
export class PopoverSubmitCanvasComponent extends BasePage implements OnInit, OnDestroy {
  public isValidate: boolean = true;
  public isClicked: boolean = false;
  @Input('canvasId') canvasId;
  @Input('canvasIdList') canvasIdList;
  @Input('fromCut4List') fromCut4List;
  @Input('fromEditor') fromEditor;

  public folderCode = new FormControl('', [Validators.required, Validators.maxLength(20), Validators.pattern('[0-9]+@[0-9]+')]);

  constructor(
    public app: AppService,
    public modalCtrl: ModalController,
    public canvasGroupService: CanvasGroupService,
    public cut: Cut4MakeManualService,
    public characterService: ResMakerCharacterService,
    public translate: TranslateService
  ) {
    super(app);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.folderCode.valueChanges.subscribe((newValue) => {
        if (newValue === '') {
          this.isValidate = true;
        }
      })
    );
  }

  ionViewDidLeave() {
    this.cut.isSubmitAlertOpen = false;
  }

  /**
   * 모달 닫는 함수
   * @return {Promise<void>}
   */
  async close(): Promise<void> {
    const targetCanvasGroup = this.folderCode.value.split('@')[1];
    await this.modalCtrl.dismiss({ targetCanvasGroup: targetCanvasGroup });
  }

  /**
   * 작업물 사본 제출 함수
   * @return {Promise<void>}
   */
  async submitCanvas(): Promise<void> {
    // 폴더 코드가 비었을 경우
    if (this.folderCode.value === '') return;

    // 폴더 코드 유효성 검사
    if (!this.folderCode.valid) {
      this.isValidate = false;
      return;
    }

    this.isClicked = true;
    this.isValidate = true;

    // 작업물 저장
    if (!this.fromCut4List) {
      await this.cut.saveCut(false, false);
    }

    try {
      const isTextTemplate = this.app.cache.user.role === UserRole.textTemplate;
      const inputFolderCode = this.folderCode.value;

      let submitResult = false;
      if (this.canvasId) {
        submitResult = await this.canvasGroupService.submitCanvasGroup(+this.canvasId, +this.app.cache.user.id, isTextTemplate, inputFolderCode);
      }
      if (_.isArray(this.canvasIdList) && this.canvasIdList.length !== 0) {
        submitResult = await this.canvasGroupService.submitCanvasesGroup(this.canvasIdList, +this.app.cache.user.id, inputFolderCode);
      }

      if (submitResult) {
        if (this.app.popover) await this.close();
        const targetCanvasGroup = inputFolderCode.split('@')[1];
        const submittedCanvasGroupInfo = await this.canvasGroupService.getSubmittedCanvasGroupInfo(+targetCanvasGroup).toPromise();
        const message =
          this.translate.instant('cut4-list.files') +
          //@ts-ignore
          submittedCanvasGroupInfo.userName +
          this.translate.instant('cut4-list.has') +
          //@ts-ignore
          submittedCanvasGroupInfo.canvasGroupName +
          this.translate.instant('cut4-list.submittedFolder');
        await this.app.showToast(message);
      } else {
        this.isValidate = false;
      }
    } catch (e) {
      await this.app.showToast(this.translate.instant('share-folder.An error has occurred. please try again later.'));
      throw new TooningCanvasGroupSubmitError(e, null, true);
    } finally {
      this.isClicked = false;
    }
  }
}
