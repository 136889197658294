import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FetchResult, gql, ApolloQueryResult } from '@apollo/client';
import { map, tap } from 'rxjs/operators';
import { Card } from '../../model/card/card';
import { GraphqlApiService } from '../api/graphql.api.service';
import { BillingType } from '../../enum/app.enum';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  constructor(public graphql: GraphqlApiService) {}

  // create card
  public createCard(card: Card): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation cardCreate($card: InputCardCreate!) {
          cardCreate(card: $card) {
            result
            id
            errorCode
            message
          }
        }
      `,
      {
        card
      }
    );
  }

  // point create card
  public createPointCard(card: Card): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation pointCardCreate($card: InputPointCardCreate!) {
          pointCardCreate(card: $card) {
            result
            id
            errorCode
            message
          }
        }
      `,
      {
        card
      }
    );
  }

  public subscriberCreate(
    cardId: number,
    userId: number,
    payPlanId: number,
    payEventId: number,
    type: number = BillingType.CARD
  ): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation subscriberCreate($cardId: Float!, $userId: Float!, $payPlanId: Float!, $payEventId: Float) {
          subscriberCreate(cardId: $cardId, userId: $userId, payPlanId: $payPlanId, payEventId: $payEventId) {
            result
            id
            errorCode
            message
          }
        }
      `,
      {
        cardId,
        userId,
        payPlanId,
        payEventId
      }
    );
  }

  /**
   * 이 유저가 지금으로부터 1분 전에 구독 신청을 한 기록이 있는지 확인
   * @param userId - 유저 아이디
   */
  public isSubscriptionDoneInOneMinute(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query isSubscriptionDoneInOneMinute($userId: Float!) {
            isSubscriptionDoneInOneMinute(userId: $userId)
          }
        `,
        {
          userId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.isSubscriptionDoneInOneMinute;
          return result;
        })
      );
  }

  // read card
  public getCards(userId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query cards($userId: ID!) {
            cards(userId: $userId) {
              id
              number
              expirationYear
              expirationMonth
              default
              ownerBirth
              createdDate
              subscriber {
                isCancel
                freePeriod
                paymentPlan {
                  planName
                  price
                  payPeriod
                }
                payEvent {
                  name
                  freePeriod
                  price
                  status
                }
              }
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.cards.map((card) => {
            return new Card().deserialize(card);
          });
          return results;
        })
      );
  }

  public deleteCard(id: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation cardDelete($id: ID!) {
          cardDelete(id: $id) {
            result
            errorCode
            message
          }
        }
      `,
      {
        id
      }
    );
  }

  public updateCard(card: Card): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation cardUpdate($card: InputCardUpdate!) {
          cardUpdate(card: $card) {
            result
            errorCode
            message
          }
        }
      `,
      {
        card
      }
    );
  }

  // cancelSubscription
  public controlSubscription(userId: number, isCancel: boolean, type: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation controlSubscription($userId: ID!, $isCancel: Boolean!, $type: Int!) {
          controlSubscription(userId: $userId, isCancel: $isCancel, type: $type)
        }
      `,
      {
        userId,
        isCancel,
        type
      }
    );
  }

  public checkCustomerKey(userId: number) {
    return this.graphql
      .query(
        gql`
          query checkCustomerKey($userId: ID!) {
            checkCustomerKey(userId: $userId) {
              result
              customerKey
            }
          }
        `,
        {
          userId
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.checkCustomerKey;
          return result;
        })
      );
  }
}
