import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { TooningPage, TooningAi } from '../../interfaces/app.interface';
import { MagicApiType } from '../../enum/app.enum';

export class Page implements TooningPage {
  @IsNumber() @IsNotEmpty() id: number;
  @IsNumber() @IsNotEmpty() userId: number;
  @IsNumber() @IsOptional() order: number;
  @IsNumber() @IsOptional() canvasId: number;
  @IsString() @IsOptional() json: string;
  @IsString() @IsOptional() base64: string;
  @IsString() @IsOptional() aiResult: string;

  dirty: boolean;
  fetched: boolean;
  fabricObject: any;
  ai: TooningAi;
  objectsSize: number;
  img: string;
  isFirstEnter: boolean;
  isChecked: boolean;
  fontFamilies: string[] | string;

  default(): TooningPage {
    this.ai = {
      sentence: '',
      entity: {
        characters: null,
        backgrounds: null,
        others: null
      },
      dependency: null,
      layoutPreset: {
        characterCount: 0,
        layoutNum: 0
      },
      magicInfo: {
        input: {
          api_type: MagicApiType.img2img,
          prompt: '',
          negative_prompt: '',
          width: 512,
          height: 512,
          steps: 28,
          cfg_scale: 7,
          seed: -1,
          override_settings: { sd_vae: 'vae-ft-mse-840000-ema-pruned.safetensors' },
          init_images: undefined,
          denoising_strength: 0.5
        }
      }
    };
    return {
      fabricObject: this.fabricObject,
      id: this.id,
      userId: this.userId,
      order: this.order,
      canvasId: this.canvasId,
      json: this.json,
      base64: this.base64,
      aiResult: this.aiResult,
      dirty: this.dirty,
      fetched: this.fetched,
      ai: this.ai,
      objectsSize: this.objectsSize,
      img: this.img,
      isFirstEnter: this.isFirstEnter,
      isChecked: this.isChecked,
      fontFamilies: this.fontFamilies
    };
  }

  sanitize() {
    delete this.dirty;
    delete this.fetched;
    delete this.fabricObject;
    delete this.ai;
    delete this.objectsSize;
    delete this.isFirstEnter;
    delete this.isChecked;
    delete this.fontFamilies;
  }

  // tslint:disable-next-line:variable-name

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
