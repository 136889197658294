import { Injectable } from '@angular/core';
import { ModelCharacterBasic, ModelCharacterMeta } from './character.service.model';
import { UserService } from '../user.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap, timeout } from 'rxjs/operators';
import { GraphqlApiService } from '../api/graphql.api.service';
import { gql, ApolloQueryResult, FetchResult } from '@apollo/client';
import { ExecutionResult } from 'graphql';
import { validate } from 'class-validator';
import { AppService } from '../app.service';
import { AllHair } from '../../model/allHair/allHair';
import { AllBody } from '../../model/allBody/allBody';
import { DomSanitizer } from '@angular/platform-browser';
import { DefaultCharacter } from '../../model/defaultCharacter/defaultCharacter';
import { EachStepTF } from '../../model/step/eachStepTF';
import { InputCharacterResourceUpdate } from '../../model/characterResouce/characterResource';
import { Cache } from '../../interfaces/cache.interface';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Sku, SkuData } from '../../model/sku/sku';
import { Loading } from '../loading';
import { ResourceStatus } from '../../enum/app.enum';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

// ? 역할 : 생성 및 수정중인 캐릭터의 데이터 관리
// * 각 페이지에 해당하는 필수 데이터들에 대한 공통적인 검증로직 및 저장/로드 관리
export class ResMakerCharacterService {
  // 캐릭터 상세 페이지별 데이터
  public dataMeta: ModelCharacterMeta;
  public dataBasic: ModelCharacterBasic;
  private getCharacterResourcesTimeout = 10 * 2000; // 20초
  private cacheStatus = true;
  private caches: Cache = {
    characterListCacheKey: null
  };
  // 발행상태 및 상세페이지별 완료 여부
  // public stepStatus: ModelCharacterStatus;

  constructor(
    public app: AppService,
    public userService: UserService,
    public graphql: GraphqlApiService,
    private domSanitizer: DomSanitizer,
    private http: HttpClient,
    public alertController: AlertController
  ) {
    this.clear();
  }

  public get cache() {
    return this.cacheStatus;
  }

  public set cache(status: boolean) {
    this.cacheStatus = status;
  }

  get dataMetaCopy(): ModelCharacterMeta {
    return new ModelCharacterMeta(this.dataMeta);
  }

  public cacheClear() {
    this.caches = {
      characterListCacheKey: null
    };
    this.app.greenLog('character.service cache cleared');
  }

  // 신규 캐릭터 생성 (캐릭터 생성시에 실행)
  create() {
    this.dataMeta = new ModelCharacterMeta();
    this.dataBasic = new ModelCharacterBasic();
  }

  // 캐릭터 정보 초기화
  clear() {
    this.app.redLog('character dataMeta cleared');
    this.dataMeta = this.dataBasic = null;
  }

  // 새캐릭터 여부 확인
  public isNewCharacter(): boolean {
    return this.dataMeta != null && this.dataMeta.id == null;
  }

  // 캐릭터 null 여부 확인
  public isAvailableCharacter(): boolean {
    return this.dataMeta != null;
  }

  // 각 step validation 저장
  public eachStepTFControl(eachStepData: EachStepTF): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation eachStepTFControl($eachStepData: InputEachStepTFControl!) {
          eachStepTFControl(eachStepData: $eachStepData) {
            baseStep
            faceStep
            bodyStep
            handStep
            hairStep
            etcStep
            headPositionStep
            armIndexStep
            legIndexStep
            headRotateStep
            bodyRotateStep
            expressionTagStep
            colorSetStep
            bodyIntentionStep
            bodyTagStep
            defaultSetStep
          }
        }
      `,
      {
        eachStepData
      }
    );
  }

  // 파일 업로드 스텝 체크
  public uploadStepCheck(characterId: number, type: string) {
    return this.graphql
      .mutate(
        gql`
          mutation uploadStepCheck($characterId: ID!, $type: String!) {
            uploadStepCheck(characterId: $characterId, type: $type)
          }
        `,
        {
          characterId,
          type
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.uploadStepCheck;
          return results;
        })
      )
      .toPromise();
  }

  public getHttpUrl(url): Observable<any> {
    try {
      let results$;
      if (this.caches[url]) {
        this.app.orange(`getHttpUrl cache ${url}`);
        results$ = this.caches[url];
      } else {
        results$ = this.caches[url] = this.http.get(url).pipe(shareReplay());
      }
      return results$;
    } catch (e) {
      console.error(`getHttpUrl error`);
      console.error(e.message);
      throw new Error(`getHttpUrl : ${e}`);
    }
  }

  // step TF 목록 가져오기
  public getStepData(characterId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query getStepData($characterId: ID!) {
          getStepData(characterId: $characterId) {
            baseStep
            faceStep
            bodyStep
            handStep
            hairStep
            etcStep
            headPositionStep
            armIndexStep
            legIndexStep
            headRotateStep
            bodyRotateStep
            expressionTagStep
            colorSetStep
            bodyIntentionStep
            bodyTagStep
            defaultSetStep
          }
        }
      `,
      {
        characterId
      }
    );
  }

  // get categories
  public getCharacterCategories() {
    return this.graphql
      .query(
        gql`
          query getCharacterCategories {
            getCharacterCategories {
              id
              ko_name
              en_name
              fr_name
              jp_name
            }
          }
        `
      )
      .pipe(
        map((results) => {
          results.data = results.data.getCharacterCategories.map((category) => {
            return category;
          });
          return results;
        })
      )
      .toPromise();
  }

  // create category
  characterCategoryCreate(categoryInfo) {
    return this.graphql
      .mutate(
        gql`
          mutation characterCategoryCreate($categoryInfo: InputCharacterCategoryNameCreate!) {
            characterCategoryCreate(categoryInfo: $categoryInfo) {
              id
              ko_name
              en_name
              fr_name
              jp_name
            }
          }
        `,
        {
          categoryInfo
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.characterCategoryCreate;
          return results;
        })
      )
      .toPromise();
  }

  // delete category
  public characterCategoryDelete(characterCategoryId: number) {
    return this.graphql
      .mutate(
        gql`
          mutation characterCategoryDelete($characterCategoryId: ID!) {
            characterCategoryDelete(characterCategoryId: $characterCategoryId)
          }
        `,
        {
          characterCategoryId
        }
      )
      .toPromise();
  }

  // update category
  public characterCategoryUpdate(characterCategoryId: number, categoryName: string, nameType: string) {
    return this.graphql
      .mutate(
        gql`
          mutation characterCategoryUpdate($characterCategoryId: ID!, $categoryName: String!, $nameType: String!) {
            characterCategoryUpdate(characterCategoryId: $characterCategoryId, categoryName: $categoryName, nameType: $nameType)
          }
        `,
        {
          characterCategoryId,
          categoryName,
          nameType
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.characterCategoryUpdate;
          return results;
        })
      )
      .toPromise();
  }

  // 캐릭터 만들기 with 기본정보
  createCharacter(data, files, skuData?: SkuData): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation characterCreate($data: InputCharacterCreate!, $files: [Upload!]!, $skuData: InputSkuData) {
            characterCreate(data: $data, files: $files, skuData: $skuData) {
              id
              imgPath
            }
          }
        `,
        {
          data,
          files,
          skuData
        }
      )
      .pipe(
        tap((result) => {
          if (this.dataMeta === null) {
            this.create();
          }

          this.dataMeta.id = +result.data.characterCreate.id;
          this.dataBasic = new ModelCharacterBasic(data);
          this.dataBasic.imgPath = result.data.characterCreate.imgPath;
          this.app.cache.setWorkingCharacter(+result.data.characterCreate.id);
        })
      );
  }

  // 캐릭터 리스트에 필요한 정보들 갖고오기
  getCharacters(): Observable<ApolloQueryResult<any>> {
    let results$;
    if (this.cache && this.caches.characterListCacheKey) {
      results$ = this.caches.characterListCacheKey;
    } else {
      results$ = this.caches.characterListCacheKey = this.graphql
        .query(
          gql`
            {
              characters {
                id
                status
                name_ko
                desc_ko
                name_en
                desc_en
                name_fr
                desc_fr
                name_jp
                desc_jp
                imgPath
                order
                target
                style
                characterCategory {
                  id
                  ko_name
                  en_name
                  fr_name
                  jp_name
                }
                sku {
                  point
                }
              }
            }
          `
        )
        .pipe(shareReplay());
    }
    return results$;
  }

  getCategoryCharacters(categoryId: number): Observable<ApolloQueryResult<any>> {
    let results$;
    if (this.cache && this.caches.characterListCacheKey) {
      results$ = this.caches.characterListCacheKey;
    } else {
      results$ = this.caches.characterListCacheKey = this.graphql
        .query(
          gql`
            query getCategoryCharacters($categoryId: ID!) {
              getCategoryCharacters(categoryId: $categoryId) {
                id
                status
                name_ko
                desc_ko
                name_en
                desc_en
                name_fr
                desc_fr
                name_jp
                desc_jp
                imgPath
                order
                target
                style
                authorType
                manager
                characterCategory {
                  id
                  ko_name
                  en_name
                  fr_name
                  jp_name
                }
                sku {
                  point
                }
              }
            }
          `,
          {
            categoryId
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data = cloneResult.data.getCategoryCharacters;
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return results$;
  }

  // 선택된 캐릭터 Get
  selectedCharacter(charaterCategoryId: number): Observable<ApolloQueryResult<any>> {
    let results$;
    if (this.cache && this.caches.characterListCacheKey) {
      results$ = this.caches.characterListCacheKey;
    } else {
      results$ = this.caches.characterListCacheKey = this.graphql
        .query(
          gql`
            query selectedCharacter($charaterCategoryId: ID!) {
              selectedCharacter(charaterCategoryId: $charaterCategoryId) {
                id
                status
                name_ko
                desc_ko
                name_en
                desc_en
                name_fr
                desc_fr
                name_jp
                desc_jp
                imgPath
                order
                target
                style
              }
            }
          `,
          {
            charaterCategoryId
          }
        )
        .pipe(
          map((results) => {
            const cloneResult = _.cloneDeep(results);
            cloneResult.data = cloneResult.data.selectedCharacter.map((character) => {
              return character;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return results$;
  }

  characterAuthorTypeUpdate(categoryId: number, authorType: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation characterAuthorTypeUpdate($categoryId: Int!, $authorType: String!) {
          characterAuthorTypeUpdate(categoryId: $categoryId, authorType: $authorType)
        }
      `,
      {
        categoryId,
        authorType
      }
    );
  }

  // AllHair 순서 업데이트
  characterOrderUpdate(characterId: number, order: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation characterOrderUpdate($characterId: ID!, $order: Int!) {
          characterOrderUpdate(characterId: $characterId, order: $order)
        }
      `,
      {
        characterId,
        order
      }
    );
  }

  // 카테고리 order 변경
  characterCategoryOrderUpdate(categoryId: number, order: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation characterCategoryOrderUpdate($categoryId: ID!, $order: Int!) {
          characterCategoryOrderUpdate(categoryId: $categoryId, order: $order)
        }
      `,
      {
        categoryId,
        order
      }
    );
  }

  UpdateResource(id: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation resourceUpdate($id: ID!) {
          resourceUpdate(id: $id) {
            id
          }
        }
      `,
      {
        id
      }
    );
  }

  // 캐릭터 하나 필요한 정보들 갖고오기
  getCharacter(id: number): Observable<ApolloQueryResult<any>> {
    let results$;
    if (this.cache && this.caches[id]) {
      this.app.greenLog(`getCharacter use cache id :${id}`);
      results$ = this.caches[id];
    } else {
      this.app.greenLog(`getCharacter first request :${id}`);
      results$ = this.caches[id] = this.graphql
        .query(
          gql`
            query character($id: ID!) {
              character(id: $id) {
                name_ko
                name_en
                name_fr
                name_jp
                id
                status
                desc_ko
                desc_en
                desc_fr
                desc_jp
                version
                imgPath
                order
                style
                colorSet
                authorType
                sku {
                  point
                }
                defaultCharacters {
                  fabricObject
                }
              }
            }
          `,
          {
            id
          }
        )
        .pipe(shareReplay());
    }

    return results$;
  }

  // 캐릭터 업데이트 with 기본정보
  updateCharacterBasic(data, skuData?: SkuData, file?): Observable<FetchResult<any>> {
    // 정상 캐릭터가 아니면 아무것도 안함
    if (!this.isAvailableCharacter() || this.isNewCharacter()) {
      return null;
    }
    // 파일변경 여부에 따라 gql데이터 준비
    this.app.redLog(this.dataMeta.id);
    data = { id: this.dataMeta.id, ...data };
    const query =
      file == null
        ? gql`
            mutation characterUpdateBasic($data: InputCharacterUpdateBasic!, $skuData: InputSkuData) {
              characterUpdateBasic(data: $data, skuData: $skuData) {
                id
              }
            }
          `
        : gql`
            mutation characterUpdateBasic($data: InputCharacterUpdateBasic!, $skuData: InputSkuData, $file: [Upload!]) {
              characterUpdateBasic(data: $data, skuData: $skuData, file: $file) {
                imgPath
              }
            }
          `;
    const param: any = file == null ? { data, skuData } : { data, skuData, file };
    // 보내고 끗
    return this.graphql.mutate(query, param).pipe(
      tap((data) => {
        console.log(data.data.characterUpdateBasic);
      })
    );
  }

  updateCharacterColorSet(characterId: number, colorSet: string) {
    return this.graphql
      .mutate(
        gql`
          mutation updateCharacterColorSet($characterId: ID!, $colorSet: String!) {
            updateCharacterColorSet(characterId: $characterId, colorSet: $colorSet)
          }
        `,
        {
          characterId,
          colorSet
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.updateCharacterColorSet;
          return results;
        })
      )
      .toPromise();
  }

  getEnterpriseCharacters(userId: number, tagText?: any): Observable<ApolloQueryResult<any>> {
    let result$;
    result$ = this.graphql
      .query(
        gql`
          query getEnterpriseCharacters($userId: ID!, $tagText: String) {
            getEnterpriseCharacters(userId: $userId, tagText: $tagText) {
              id
              status
              name_ko
              desc_ko
              name_en
              desc_en
              name_fr
              desc_fr
              name_jp
              desc_jp
              imgPath
              order
              target
              style
              authorType
            }
          }
        `,
        {
          userId,
          tagText
        }
      )
      .pipe(
        tap((result) => {
          result.data.getEnterpriseCharacters = result.data.getEnterpriseCharacters.map((character) => {
            character.imgPath = character.imgPath + '/150x';
            return character;
          });
          return result;
        }),
        shareReplay()
      );

    return result$;
  }

  /**
   * 태그로 캐릭터 검색
   * @param userId 사용자 id 번호
   * @param role 사용자 권한 admin/user/beta/student/teacher/template/donation/textTemplate/event
   * @param skip 건너뛸 리소스 수
   * @param take 받아올 리소스 수
   * @param isScroll 스크롤 내려서 호출인지 처음 호출인지
   * @param tagText 검색어
   */
  characterTagSearch(userId: number, role: string, skip: number, take: number, isScroll: boolean, tagText?: any): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['characterTagSearch', userId, role, skip, take, isScroll, tagText]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query characterTagSearch($userId: ID!, $role: String!, $skip: Int!, $take: Int!, $isScroll: Boolean!, $tagText: String) {
              characterTagSearch(userId: $userId, role: $role, skip: $skip, take: $take, isScroll: $isScroll, tagText: $tagText) {
                id
                status
                name_ko
                desc_ko
                name_en
                desc_en
                name_fr
                desc_fr
                name_jp
                desc_jp
                imgPath
                order
                target
                style
                authorType
                sku {
                  point
                  purchase {
                    id
                  }
                }
              }
            }
          `,
          {
            userId,
            role,
            skip,
            take,
            isScroll,
            tagText
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data.characterTagSearch = cloneResult.data.characterTagSearch.map((character) => {
              character.imgPath = character.imgPath + '/150x';
              if (character.sku != null) {
                character.sku = new Sku().deserialize(character.sku);
                return character;
              }
              return character;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  /**
   * 통합검색
   * @param userId 사용자 id 번호
   * @param tagText 검색어
   * @param role 사용자 권한 admin/user/beta/student/teacher/template/donation/textTemplate/event
   * @param language 사용중인 언어
   */
  integratedSearch(userId: number, tagText: string, role: string, language: string): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['integratedSearch', userId, tagText, role, language]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query integratedSearch($userId: ID!, $tagText: String!, $role: String!, $language: String!) {
              integratedSearch(userId: $userId, tagText: $tagText, role: $role, language: $language) {
                template {
                  id
                  title
                  base64
                  language
                  pageCount
                  canvas {
                    id
                    pages {
                      id
                    }
                  }
                }
                textTemplate {
                  id
                  title
                  base64
                  language
                }
                character {
                  id
                  name_ko
                  name_en
                  name_fr
                  name_jp
                  imgPath
                  authorType
                  sku {
                    point
                    purchase {
                      id
                    }
                  }
                }
                outputEtcType {
                  etcType
                  etcUpload {
                    sourceId
                    sourceType
                    base64
                    authorType
                    sku {
                      point
                      purchase {
                        id
                      }
                    }
                  }
                }
              }
            }
          `,
          {
            userId,
            tagText,
            role,
            language
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            if (cloneResult.data.integratedSearch.character) {
              cloneResult.data.integratedSearch.character = cloneResult.data.integratedSearch.character.map((character) => {
                character.imgPath = character.imgPath + '/400x';
                if (character.sku != null) {
                  character.sku = new Sku().deserialize(character.sku);
                  return character;
                }
                return character;
              });
            }

            if (cloneResult.data.integratedSearch.template) {
              cloneResult.data.integratedSearch.template = cloneResult.data.integratedSearch.template.map((template) => {
                template.base64 = template.base64 + '/150px';
                return template;
              });
            }

            if (cloneResult.data.integratedSearch.outputEtcType) {
              cloneResult.data.integratedSearch.outputEtcType = cloneResult.data.integratedSearch.outputEtcType.map((etcType) => {
                etcType.etcUpload = etcType.etcUpload.map((etc) => {
                  etc.base64 = etc.base64 + '/100x';
                  if (etc.sku != null) {
                    etc.sku = new Sku().deserialize(etc.sku);
                    return etc;
                  }
                  return etc;
                });
                return etcType;
              });
            }
            cloneResult.data = cloneResult.data.integratedSearch;
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  public getTooningAuthorCharacter(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getTooningAuthorCharacter {
            getTooningAuthorCharacter {
              id
            }
          }
        `
      )
      .pipe(
        tap((results) => {
          results.data = results.data.getTooningAuthorCharacter;
          return results;
        })
      );
  }

  getCharacterInCategory(userId: number, categoryId: number, role: string, skip: number, take: number): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['getCharacterInCategory', userId, categoryId, role, skip, take]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query getCharacterInCategory($userId: ID!, $categoryId: ID!, $role: String!, $skip: Int!, $take: Int!) {
              getCharacterInCategory(userId: $userId, categoryId: $categoryId, role: $role, skip: $skip, take: $take) {
                id
                status
                name_ko
                desc_ko
                name_en
                desc_en
                name_fr
                desc_fr
                name_jp
                desc_jp
                imgPath
                order
                target
                style
                authorType
                sku {
                  point
                  purchase {
                    id
                  }
                }
              }
            }
          `,
          {
            userId,
            categoryId,
            role,
            skip,
            take
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data.getCharacterInCategory = cloneResult.data.getCharacterInCategory.map((character) => {
              character.imgPath = character.imgPath + '/400x';
              if (character.sku != null) {
                character.sku = new Sku().deserialize(character.sku);
                return character;
              }
              return character;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  getEnterpriseCharacterCategory(userId: number, authorType: string): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['getEnterpriseCharacterCategory', userId, authorType]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query getEnterpriseCharacterCategory($userId: ID!, $authorType: String!) {
              getEnterpriseCharacterCategory(userId: $userId, authorType: $authorType) {
                id
                ko_name
                en_name
                fr_name
                jp_name
                character {
                  id
                  status
                  name_ko
                  desc_ko
                  name_en
                  desc_en
                  name_fr
                  desc_fr
                  name_jp
                  desc_jp
                  imgPath
                  order
                  target
                  style
                  authorType
                }
              }
            }
          `,
          {
            userId,
            authorType
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data.getEnterpriseCharacterCategory = cloneResult.data.getEnterpriseCharacterCategory.map((categories) => {
              for (const character of categories.character) {
                character.imgPath = character.imgPath + '/400x';
              }
              return categories;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  categorySearchWithCharacterTag(userId: number, role: string, skip: number, take: number, authorType: string): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['categorySearchWithCharacterTag', userId, role, skip, take, authorType]).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query categorySearchWithCharacterTag($userId: ID!, $role: String!, $skip: Int!, $take: Int!, $authorType: String!) {
              categorySearchWithCharacterTag(userId: $userId, role: $role, skip: $skip, take: $take, authorType: $authorType) {
                id
                ko_name
                en_name
                fr_name
                jp_name
                character {
                  id
                  status
                  name_ko
                  desc_ko
                  name_en
                  desc_en
                  name_fr
                  desc_fr
                  name_jp
                  desc_jp
                  imgPath
                  order
                  target
                  style
                  authorType
                  sku {
                    id
                    skuType
                    point
                    purchase {
                      id
                    }
                  }
                }
              }
            }
          `,
          {
            userId,
            role,
            skip,
            take,
            authorType
          }
        )
        .pipe(
          map((result) => {
            const cloneResult = _.cloneDeep(result);
            cloneResult.data.categorySearchWithCharacterTag = cloneResult.data.categorySearchWithCharacterTag.map((categories) => {
              for (const character of categories.character) {
                character.imgPath = character.imgPath + '/400x';
                if (character.sku != null) {
                  character.sku = new Sku().deserialize(character.sku);
                }
              }
              return categories;
            });
            return cloneResult;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  getCategoryWithCharacter(): Observable<ApolloQueryResult<any>> {
    let result$;
    const cacheKey = _.compact(['getCategoryWithCharacter']).join('_');
    if (this.caches[cacheKey]) {
      result$ = this.caches[cacheKey];
    } else {
      result$ = this.caches[cacheKey] = this.graphql
        .query(
          gql`
            query getCategoryWithCharacter {
              getCategoryWithCharacter {
                id
                ko_name
                en_name
                fr_name
                jp_name
                character {
                  id
                  status
                  name_ko
                  desc_ko
                  name_en
                  desc_en
                  name_fr
                  desc_fr
                  name_jp
                  desc_jp
                  imgPath
                  order
                  target
                  style
                  authorType
                }
              }
            }
          `
        )
        .pipe(
          tap((result) => {
            return result;
          }),
          shareReplay()
        );
    }
    return result$;
  }

  /**
   * 캐릭터에 대한 태그 및 태그 번역본을 저장한다.
   * @param characterId - 캐릭터 ID
   * @param tags - 저장할 태그
   * ex) [{
   "ko": "사랑",
   "en": "Love",
   "fr": "Amour",
   "ja": "愛"
   }]
   */
  characterTagsCreate(characterId: number, tags: string[]): Observable<FetchResult<any>> {
    let query: any;
    let param: any;
    query = gql`
      mutation CharacterTagsCreate($characterId: ID!, $tags: [JSONObject!]!) {
        CharacterTagsCreate(characterId: $characterId, tags: $tags)
      }
    `;
    param = { characterId, tags };
    for (const tag of tags) {
      // @ts-ignore
      delete tag.__typename;
    }
    return this.graphql.mutate(query, param);
  }

  createCharacterResourceAllinOne(data): Observable<FetchResult<any>> {
    console.log(data);
    // const filedata = files.files
    return this.graphql.mutate(
      gql`
        mutation characterResourceCreateAllinOne($data: [InputCharacterResourceCreateAllinOne!]!) {
          characterResourceCreateAllinOne(data: $data)
        }
      `,
      {
        data
      }
    );
  }

  // 캐릭터 리소스 가져오기
  // 캐릭터 삭제
  deleteCharacter(id: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation characterDelete($id: ID!) {
          characterDelete(id: $id)
        }
      `,
      {
        id
      }
    );
  }

  getArmId(characterId: number, direction: string, part: string, order: number): Observable<FetchResult<any>> {
    return this.graphql.query(
      gql`
        query getArmId($characterId: ID!, $part: String!, $direction: String!, $order: ID!) {
          getArmId(characterId: $characterId, part: $part, direction: $direction, order: $order) {
            id
          }
        }
      `,
      {
        characterId,
        direction,
        order,
        part
      }
    );
  }

  // 캐릭터 리소기 만들기
  createCharacterResource(data, files): Observable<FetchResult<any>> {
    // const filedata = files.files
    return this.graphql.mutate(
      gql`
        mutation characterResourceCreate($data: InputCharacterResourceCreate!, $files: [Upload!]!) {
          characterResourceCreate(data: $data, files: $files)
        }
      `,
      {
        data,
        files
      }
    );
  }

  /**
   * 캐릭터 리소스 가져오기
   * @param {number} characterId - 캐릭터 아이디
   * @param {boolean} insertCache - 캐시 여부
   * @param {string} part - 캐릭터 리소스 파트
   * @param {string} direction - 정측후 방향
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getCharacterResources(characterId: number, insertCache = true, part?: string, direction?: string): Observable<ApolloQueryResult<any>> {
    try {
      const cacheId = _.compact(['getCharacterResources', characterId, part, direction]).join('_');
      const cloudState = true;

      let results$;
      if (this.cache && this.caches[cacheId] && insertCache) {
        this.app.greenLog(`getCharacterResources cached id : ${cacheId}`);
        results$ = this.caches[cacheId];
      } else if (part) {
        results$ = this.caches[cacheId] = this.graphql
          .query(
            gql`
              query characterResource($characterId: ID!, $insertCache: Boolean!, $cloudState: Boolean!, $part: String, $direction: String) {
                characterResource(characterId: $characterId, insertCache: $insertCache, cloudState: $cloudState, part: $part, direction: $direction) {
                  id
                  part
                  direction
                  imgPath
                  imgName
                  order
                  fabricObject
                  armId
                  tags {
                    id
                    name
                  }
                  hands {
                    id
                    part
                    direction
                    imgPath
                    imgName
                    order
                    fabricObject
                    base64
                    thumbnailX
                    thumbnailY
                    thumbnailScale
                    base64
                  }
                  arm {
                    id
                    part
                    direction
                    imgName
                    imgPath
                    fabricObject
                  }
                  relativePositionX
                  relativePositionY
                  rotationShapeHeadX
                  rotationShapeHeadY
                  rotationShapeBodyX
                  rotationShapeBodyY
                  thumbnailX
                  thumbnailY
                  thumbnailScale
                  base64
                }
              }
            `,
            {
              characterId,
              insertCache,
              cloudState,
              part,
              direction
            }
          )
          .pipe(
            map((results) => {
              const cloneResults = _.cloneDeep(results);
              cloneResults.data.characterResource.map((resource) => {
                const tagArray = resource.tags.map((tag) => {
                  return tag.name;
                });
                resource.imgPath = resource.imgPath + '/400x';
                resource.tags = tagArray;
              });
              return cloneResults;
            }),
            shareReplay(),
            timeout(this.getCharacterResourcesTimeout),
            catchError((error) => {
              return throwError(error);
            })
          );
      } else {
        // 어드민 캐릭터 생성 페이지
        results$ = this.caches[cacheId] = this.graphql
          .query(
            gql`
              query characterResource($characterId: ID!, $insertCache: Boolean!, $cloudState: Boolean!) {
                characterResource(characterId: $characterId, insertCache: $insertCache, cloudState: $cloudState) {
                  id
                  direction
                  part
                  imgPath
                  imgName
                  order
                  armId
                  fabricObject
                  tags {
                    id
                    name
                  }
                  relativePositionX
                  relativePositionY
                  rotationShapeHeadX
                  rotationShapeHeadY
                  rotationShapeBodyX
                  rotationShapeBodyY
                }
              }
            `,
            {
              characterId,
              insertCache,
              cloudState
            }
          )
          .pipe(
            map((results) => {
              const cloneResults = _.cloneDeep(results);
              cloneResults.data.characterResource.map((resource) => {
                const tagArray = resource.tags.map((tag) => {
                  return tag.name;
                });
                resource.tags = tagArray;
              });

              return cloneResults;
            }),
            shareReplay()
          );
      }
      return results$;
    } catch (e) {
      console.error(e);
    }
  }

  // 캐릭터 리소스 가져오기
  getCharacterResourcesByOrder(
    characterId: number,
    insertCache: boolean,
    part: string,
    direction: string,
    order: number
  ): Observable<ApolloQueryResult<any>> {
    const cacheId = _.compact(['getCharacterResourcesByOrder', characterId, part, direction, order]).join('_');
    const cloudState = true;

    let results$;
    if (this.cache && this.caches[cacheId] && insertCache) {
      this.app.greenLog(`getCharacterResourcesByOrder cached id : ${cacheId}`);
      results$ = this.caches[cacheId];
    } else {
      results$ = this.caches[cacheId] = this.graphql
        .query(
          gql`
            query characterResource(
              $characterId: ID!
              $insertCache: Boolean!
              $cloudState: Boolean!
              $part: String!
              $direction: String!
              $order: ID!
            ) {
              characterResource(
                characterId: $characterId
                insertCache: $insertCache
                cloudState: $cloudState
                part: $part
                direction: $direction
                order: $order
              ) {
                id
                direction
                part
                imgPath
                imgName
                order
                fabricObject
                armId
                tags {
                  id
                  name
                }
                hands {
                  id
                  part
                  direction
                  imgPath
                  imgName
                  order
                  fabricObject
                }
                relativePositionX
                relativePositionY
                rotationShapeHeadX
                rotationShapeHeadY
                rotationShapeBodyX
                rotationShapeBodyY
              }
            }
          `,
          {
            characterId,
            insertCache,
            cloudState,
            part,
            direction,
            order
          }
        )
        .pipe(
          map((results) => {
            const cloneResult = _.cloneDeep(results);
            cloneResult.data.characterResource.map((resource) => {
              const tagArray = resource.tags.map((tag) => {
                return tag.name;
              });
              resource.tags = tagArray;
            });

            return cloneResult;
          }),
          shareReplay()
        );
    }

    return results$;
  }

  // 캐릭터 리소스 순서 업데이트
  characterResourceReorder(characterId: number, order: number): Observable<FetchResult<any>> {
    this.app.greenLog('characterId : ' + characterId + ' order : ' + order);
    return this.graphql.mutate(
      gql`
        mutation characterResourceReorder($characterId: ID!, $order: ID!) {
          characterResourceReorder(characterId: $characterId, order: $order)
        }
      `,
      {
        characterId,
        order
      }
    );
  }

  // 캐릭터 리소스 업데이트
  updateCharacterResource(data, files): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation characterResourceUpdate($data: InputCharacterResourceUpdate!, $files: [Upload!]!) {
          characterResourceUpdate(data: $data, files: $files) {
            id
            angle
            angleX
            angleY
          }
        }
      `,
      {
        data,
        files
      }
    );
  }

  // 캐릭터 리소스 다수 업데이트
  characterResourceUpdateArray(data: InputCharacterResourceUpdate[], files, partArr): Observable<FetchResult<any>> {
    this.app.greenLog(data);
    return this.graphql
      .mutate(
        gql`
          mutation characterResourceUpdateArray($data: [InputCharacterResourceUpdate!]!, $files: [Upload!]!, $partArr: [Int!]!) {
            characterResourceUpdateArray(data: $data, files: $files, partArr: $partArr) {
              sourceId
              base64
              partArr
            }
          }
        `,
        {
          data,
          files,
          partArr
        }
      )
      .pipe(
        tap((result) => {
          return result.data.characterResourceUpdateArray;
        })
      );
  }

  // 캐릭터 리소스 삭제
  deleteCharacterResource(characterId: number, part: string, direction: string, armId: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation characterResourceDelete($characterId: ID!, $part: String!, $direction: String!, $armId: String) {
          characterResourceDelete(characterId: $characterId, part: $part, direction: $direction, armId: $armId)
        }
      `,
      {
        characterId,
        part,
        direction,
        armId
      }
    );
  }

  // 리소스 일괄 삭제
  deleteAllResource(characterId: number, part: string): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation deleteAllResource($characterId: ID!, $part: String!) {
          deleteAllResource(characterId: $characterId, part: $part)
        }
      `,
      {
        characterId,
        part
      }
    );
  }

  /**
   * 파파고 api 를 이용해 태그를 여러 언어로 번역한다.
   * @param tag - 번역할 태그 (should be Korean)
   */
  translateTag(tag: string): Promise<any> {
    return this.graphql
      .query(
        gql`
          query translateTag($tag: String!) {
            translateTag(tag: $tag) {
              name {
                ko
                en
                fr
                ja
              }
            }
          }
        `,
        {
          tag
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.translateTag;
          return result;
        })
      )
      .toPromise();
  }

  // 캐릭터리소스,allBody와 연결하면서 태그 생성
  createTag(characterResourceId: number, allBodyId: number, tag: string): Observable<FetchResult<any>> {
    console.log(characterResourceId);
    console.log(allBodyId);
    console.log(tag);

    let query: any;
    let param: any;
    if (characterResourceId != null) {
      query = gql`
        mutation addTag($characterResourceId: ID!, $tag: String!) {
          TagCreate(tag: $tag, characterResourceId: $characterResourceId)
        }
      `;
      param = { tag, characterResourceId };
    } else if (allBodyId != null) {
      query = gql`
        mutation addTag($allBodyId: ID!, $tag: String!) {
          TagCreate(tag: $tag, allBodyId: $allBodyId)
        }
      `;
      param = { tag, allBodyId };
    }

    return this.graphql.mutate(query, param);
  }

  allBodyTagsCreate(allBodyId: number, tags: string[]): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation allBodyTagsCreate($allBodyId: ID!, $tags: [String!]!) {
            allBodyTagsCreate(allBodyId: $allBodyId, tags: $tags)
          }
        `,
        {
          allBodyId,
          tags
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.allBodyTagsCreate;
          return result;
        })
      );
  }

  // 캐릭터리소스와 태그연결 삭제
  deleteTag(characterResourceId: number, allBodyId: number, tag: string): Observable<FetchResult<any>> {
    console.log(characterResourceId);
    console.log(allBodyId);
    console.log(tag);

    let query: any;
    let param: any;

    if (characterResourceId != null) {
      query = gql`
        mutation deleteTag($characterResourceId: ID!, $tag: String!) {
          TagDelete(tag: $tag, characterResourceId: $characterResourceId)
        }
      `;
      param = { tag, characterResourceId };
    } else if (allBodyId != null) {
      query = gql`
        mutation deleteTag($allBodyId: ID!, $tag: String!) {
          TagDelete(tag: $tag, allBodyId: $allBodyId)
        }
      `;
      param = { tag, allBodyId };
    }

    return this.graphql.mutate(query, param);
  }

  // 몸전체 fabricObject 올리기
  createAllBody(data: AllBody, files): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation allBodyCreate($data: InputAllBodyCreate!, $files: [Upload!]!) {
            allBodyCreate(data: $data, files: $files) {
              id
            }
          }
        `,
        {
          data,
          files
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.allBodyCreate;
          return result;
        })
      );
  }

  updateAllBody(data: AllBody, files): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation addAllBody($data: InputAllBodyUpdate!, $files: [Upload!]!) {
          allBodyUpdateV2(data: $data, files: $files)
        }
      `,
      {
        data,
        files
      }
    );
  }

  // 몸전체 fabricObject 가져오기
  getAllBody(characterId: number): Observable<ApolloQueryResult<Array<AllBody>>> {
    const cacheId = _.compact(['allBody', characterId]).join('_');
    const cloudState = true;

    let results$;
    if (this.cache && this.caches[cacheId]) {
      this.app.greenLog(`getCharacterResources cached id : ${cacheId}`);
      results$ = this.caches[cacheId];
    } else {
      results$ = this.caches[cacheId] = this.graphql
        .query(
          gql`
            query allBody($characterId: ID!, $cloudState: Boolean!) {
              allBody(characterId: $characterId, cloudState: $cloudState) {
                id
                fabricObject
                leftAllOrder
                rightAllOrder
                leftArmOrder
                leftHandOrder
                rightArmOrder
                rightHandOrder
                legOrder
                leftAllOrder
                rightAllOrder
                backHairOrder
                faceExpressionOrder
                faceShapeOrder
                frontHairOrder
                frontBase64
                sideBase64
                backBase64
                tags {
                  id
                  name
                }
                intent {
                  id
                  name_ko
                }
                headAngle
                bodyAngle
                rotation
                scale
              }
            }
          `,
          {
            characterId,
            cloudState
          }
        )
        .pipe(
          map((results) => {
            const cloneResult = _.cloneDeep(results);
            cloneResult.data.allBody.map((bodySet) => {
              bodySet.frontBase64 = bodySet.frontBase64 + '/100x';
              bodySet.sideBase64 = bodySet.sideBase64 + '/100x';
              bodySet.backBase64 = bodySet.backBase64 + '/100x';
            });
            return cloneResult;
          }),
          map((results) => {
            results = results.data.allBody.map((bodySet) => {
              return new AllBody().deserialize(bodySet);
            });
            return results;
          }),
          shareReplay()
        );
    }

    return results$;
  }

  // 몸전체 fabricObject  update
  updateAllBodyIntent(id: number, intentId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        mutation updateAllBody($id: ID!, $intentId: ID!) {
          allBodyUpdate(id: $id, intentId: $intentId)
        }
      `,
      {
        id,
        intentId
      }
    );
  }

  // allBody 삭제
  deleteAllBody(id: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        mutation deleteAllBody($id: ID!) {
          allBodyDelete(id: $id)
        }
      `,
      {
        id
      }
    );
  }

  // 모든 allBody 삭제
  deleteEveryAllBody(characterId: number): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation deleteEveryAllBody($characterId: ID!) {
            deleteEveryAllBody(characterId: $characterId)
          }
        `,
        {
          characterId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.deleteEveryAllBody;
          return result;
        })
      )
      .toPromise();
  }

  // Intent 가져오기
  getAllIntent(): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(gql`
      query getAllIntent {
        Intent {
          id
          name_ko
        }
      }
    `);
  }

  getIntent(intentName: string): Promise<any> {
    return this.graphql
      .query(
        gql`
          query getIntent($intentName: String!) {
            getIntent(intentName: $intentName) {
              id
              name_ko
            }
          }
        `,
        {
          intentName
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getIntent;
          return result;
        })
      )
      .toPromise();
  }

  // 머리 세트 올리기
  createAllHair(data: AllHair): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        mutation allHairCreate($data: InputAllHairCreate!) {
          allHairCreate(data: $data)
        }
      `,
      {
        data
      }
    );
  }

  // 머리 세트 가져 오기
  getAllHair(characterId: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query allHair($characterId: ID!) {
            allHair(characterId: $characterId) {
              id
              order
              frontHairOrder
              backHairOrder
              frontBase64
              sideBase64
              backBase64
              fabricObject
            }
          }
        `,
        {
          characterId
        }
      )
      .pipe(
        map((results) => {
          results = results.data.allHair.map((hairSet) => {
            return new AllHair().deserialize(hairSet);
          });
          return results;
        })
      );
  }

  // 헤어 스타일 업데이트
  updateAllHair(data: AllHair): Observable<FetchResult<any>> {
    return this.graphql.query(
      gql`
        mutation allHairUpdate($data: InputAllHairUpdate!) {
          allHairUpdate(data: $data)
        }
      `,
      {
        data
      }
    );
  }

  // 머리 세트 삭제하기
  deleteAllHair(id: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        mutation allHairDelete($id: ID!) {
          allHairDelete(id: $id)
        }
      `,
      {
        id
      }
    );
  }

  // AllHair 순서 업데이트
  allHairOrderUpdate(id: number, characterId: number, order: number): Observable<FetchResult<any>> {
    this.app.greenLog('characterId : ' + characterId + ' order : ' + order);
    return this.graphql.mutate(
      gql`
        mutation allHairOrderUpdate($id: ID!, $characterId: ID!, $order: ID!) {
          allHairOrderUpdate(id: $id, characterId: $characterId, order: $order)
        }
      `,
      {
        id,
        characterId,
        order
      }
    );
  }

  // Default character 생성
  createDefaultCharacter(data: DefaultCharacter, files): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation defaultCharacterCreate($data: InputDefaultCharacterCreate!, $files: [Upload!]!) {
          defaultCharacterCreate(data: $data, files: $files)
        }
      `,
      {
        data,
        files
      }
    );
  }

  // Default character 가져오기
  getDefaultCharacter(characterId: number): Observable<ApolloQueryResult<Array<DefaultCharacter>>> {
    return this.graphql
      .query(
        gql`
          query defaultCharacter($characterId: ID!) {
            defaultCharacter(characterId: $characterId) {
              id
              leftArmOrder
              leftHandOrder
              rightArmOrder
              rightHandOrder
              legOrder
              leftAllOrder
              rightAllOrder
              backHairOrder
              faceExpressionOrder
              faceShapeOrder
              frontHairOrder
              accessaryOrder
              glassesOrder
              beardOrder
              hairSetOrder
              bodySetOrder
              frontBase64
              sideBase64
              backBase64
              fabricObject
            }
          }
        `,
        {
          characterId
        }
      )
      .pipe(
        map((results) => {
          results = results.data.defaultCharacter.map((bodySet) => {
            bodySet.frontBase64 = this.domSanitizer.bypassSecurityTrustUrl(bodySet.frontBase64);
            bodySet.sideBase64 = this.domSanitizer.bypassSecurityTrustUrl(bodySet.sideBase64);
            bodySet.backBase64 = this.domSanitizer.bypassSecurityTrustUrl(bodySet.backBase64);
            return new DefaultCharacter().deserialize(bodySet);
          });
          return results;
        })
      );
  }

  // 헤어 스타일 업데이트
  updateDefaultCharacter(data: DefaultCharacter, files): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation defaultCharacterUpdate($data: InputDefaultCharacterUpdate!, $files: [Upload!]!) {
          defaultCharacterUpdate(data: $data, files: $files)
        }
      `,
      {
        data,
        files
      }
    );
  }

  // defaultCharacter 일괄 업데이
  defaultCharacterUpdateArray(data: DefaultCharacter[]): Observable<FetchResult<any>> {
    this.app.greenLog(data);

    return this.graphql.mutate(
      gql`
        mutation defaultCharacterUpdateArray($data: [InputDefaultCharacterUpdate!]!) {
          defaultCharacterUpdateArray(data: $data)
        }
      `,
      {
        data
        // files: fileData
      }
    );
  }

  // Default character 삭제
  deleteDefaultCharacter(id?: number, characterId?: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        mutation defaultCharacterDelete($id: ID, $characterId: ID) {
          defaultCharacterDelete(id: $id, characterId: $characterId)
        }
      `,
      {
        id,
        characterId
      }
    );
  }

  cloneCharacter(characterId: number, userId: number) {
    return this.graphql
      .mutate(
        gql`
          mutation cloneCharacter($characterId: ID!, $userId: ID!) {
            cloneCharacter(characterId: $characterId, userId: $userId)
          }
        `,
        {
          characterId,
          userId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.cloneCharacter;
          return result;
        })
      )
      .toPromise();
  }

  updateMaxHeaderRotation(characterId: number, headerRotation: number): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation updateMaxHeaderRotation($characterId: ID!, $headerRotation: Int!) {
            updateMaxHeaderRotation(characterId: $characterId, headerRotation: $headerRotation)
          }
        `,
        {
          characterId,
          headerRotation
        }
      )
      .pipe(
        map((results) => {
          return results;
        })
      );
  }

  async getMaxHeaderRotation(characterId: number) {
    return this.graphql
      .query(
        gql`
          query getMaxHeaderRotation($characterId: ID!) {
            getMaxHeaderRotation(characterId: $characterId)
          }
        `,
        {
          characterId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.getMaxHeaderRotation;
          return results;
        })
      )
      .toPromise();
  }

  resetPointData(userId: number): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation resetPointData($userId: ID!) {
          resetPointData(userId: $userId)
        }
      `,
      {
        userId
      }
    );
  }

  /**
   * 캐릭터에 속한 태그들을 조회한다.
   * @param id - 캐릭터 ID
   */
  getTagListForCharacter(id: number): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query getTagListForCharacter($id: ID!) {
          getTagListForCharacter(id: $id) {
            name {
              ko
              en
              fr
              ja
            }
            tagId
          }
        }
      `,
      {
        id
      }
    );
  }

  /**
   * 직접 입력했던 기존 태그를 가져온다
   * @param {number} tagId - 태그 아이디
   * @returns {Promise<ApolloQueryResult<any>>}
   */
  getOriginalTags(tagId: number): Promise<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getOriginalTags($tagId: ID!) {
            getOriginalTags(tagId: $tagId) {
              languageType
            }
          }
        `,
        {
          tagId
        }
      )
      .toPromise();
  }

  // 캐릭터 선택&로드
  import(id: number): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query character($id: ID!) {
            character(id: $id) {
              status
              name_ko
              desc_ko
              name_en
              desc_en
              name_fr
              desc_fr
              name_jp
              desc_jp
              version
              isSTCenable
              isStyleEnable
              hasCustomColor
              color_name
              color_desc
              gameLink
              imgPath
              type
              target
              style
              authorType
              tags {
                id
                name
              }
              characterCategory {
                id
                ko_name
                en_name
                fr_name
                jp_name
              }
              sku {
                id
                point
              }
              characterFeatureLimit {
                aiLimit
                skinLimit
                bitmapFilterLimit
                layerSeparationLimit
                faceHidingLimit
              }
            }
          }
        `,
        { id }
      )
      .pipe(
        tap(async (data) => {
          console.log('import!!!!');
          console.log(data.data.character);

          // 캐릭터 캐쉬
          if (data.data.character !== null) {
            this.app.cache.setWorkingCharacter(id.toString());
            this.app.cache.setWorkingCharacterName(data.data.character.name_ko);
            // 각 캐릭터 객체에 맵핑
            this.dataBasic = new ModelCharacterBasic(data.data.character);
            this.dataMeta = new ModelCharacterMeta({ id });
            // 페이지별 완료여부 확인
            this.dataMeta.basicStep = (await validate(this.dataBasic)).length <= 0;
          }

          return data;
        })
      );
  }

  // 캐릭터 발행처리
  setPublishState(characterStatus: string, characterId: number): Observable<ApolloQueryResult<any>> {
    // 캐릭터 정보가 없거나, 미완료 입력상태거나 변경이 필요없으면 거름
    const data = {
      id: characterId,
      status: characterStatus
    };
    return this.graphql.query(
      gql`
        mutation characterUpdateBasic($data: InputCharacterUpdateBasic!) {
          characterUpdateBasic(data: $data) {
            id
          }
        }
      `,
      {
        data
      }
    );
  }

  async isAllStepChecked(stepData, sourceId) {
    const stepArr: boolean[] = Object.values(stepData);
    if (stepArr.find((step) => step === false) !== undefined) {
      return false;
    } else {
      return true;
    }
  }

  // 공통 캐릭터 소스 업로드
  /**
   *  공통부분 업로드 쿼리 요청 함수
   * @param data 쿼리에 필요한 정보
   * @param svgFiles svg 파일
   * @param fabricFiles fabric 파일
   * @param userId 업로드한 유저 아이디
   */
  async commonCharacterResourceCreate(data, svgFiles, fabricFiles, userId): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation commonCharacterResourceCreate(
            $data: InputCommonCharacterResource!
            $svgFiles: [Upload!]!
            $fabricFiles: [Upload!]!
            $userId: ID!
          ) {
            commonCharacterResourceCreate(data: $data, svgFiles: $svgFiles, fabricFiles: $fabricFiles, userId: $userId)
          }
        `,
        {
          data,
          svgFiles,
          fabricFiles,
          userId
        }
      )
      .toPromise();
  }

  async commonCharacterHandCreate(data, svgFiles, fabricFiles, userId): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation commonCharacterHandCreate($data: InputCommonCharacterResource!, $svgFiles: [Upload!]!, $fabricFiles: [Upload!]!, $userId: ID!) {
            commonCharacterHandCreate(data: $data, svgFiles: $svgFiles, fabricFiles: $fabricFiles, userId: $userId)
          }
        `,
        {
          data,
          svgFiles,
          fabricFiles,
          userId
        }
      )
      .toPromise();
  }

  async deleteAll(characterId: number, part: string, list: [{ data: [{ img: string[] }] }]) {
    const loading = new Loading();
    try {
      const alert = await this.alertController.create({
        header: `${part}  이(가) 삭제됩니다!`,
        message: '정말 삭제하시겠습니까?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Okay',
            handler: async () => {
              try {
                await loading.showLoader();
                await this.deleteAllResource(characterId, part).toPromise();
                this.imgArrayDelete(list);
                await this.app.showToast('삭제 완료');
                await this.setPublishState(ResourceStatus.prepare, characterId).toPromise();
              } catch (e) {
                await this.app.showToast(`삭제 에러 ${e.message}`);
                throw new Error(`deleteAll : ${e.message}`);
              } finally {
                loading.hideLoader();
              }
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      loading.hideLoader();
      throw new Error(e.message);
    }
  }

  imgArrayDelete(list: [{ data: [{ img: string[] }] }]) {
    try {
      list.forEach((element) => {
        element.data.forEach((inner) => {
          inner.img = [];
        });
      });
    } catch (e) {
      throw new Error(`imgArrayDelete : ${e.message}`);
    }
  }

  /**
   * lambda를 통해 Threshold값 적용
   * @param {string} base64
   * @param {string} type
   * @return {Promise<any>} Threshold가 적용된 이미지
   */
  async applyThreshold(base64: string, type: string): Promise<any> {
    return this.graphql
      .query(
        gql`
          mutation applyThreshold($base64: String!, $type: String!) {
            applyThreshold(base64: $base64, type: $type)
          }
        `,
        {
          base64,
          type
        }
      )
      .pipe(
        tap((result) => {
          result.data = result.data.applyThreshold;
          return result;
        })
      )
      .toPromise();
  }

  // 유효성 검증 공통로직
  private _isValidPart(obj: any, requiredFields: Array<string>): boolean {
    if (obj == null) {
      return false;
    }
    for (const key of requiredFields) {
      if (obj[key] == null) {
        return false;
      }
    }
    return true;
  }

  /**
   * Deep copy function for TypeScript.
   * @param T Generic type of target/copied value.
   * @param target Target value to be copied.
   * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
   * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
   */
  private _deepCopy = <T>(target: T): T => {
    if (target === null) {
      return target;
    }
    if (target instanceof Date) {
      return new Date(target.getTime()) as any;
    }
    if (target instanceof Array) {
      const cp = [] as any[];
      (target as any[]).forEach((v) => {
        cp.push(v);
      });
      return cp.map((n: any) => this._deepCopy<any>(n)) as any;
    }
    if (typeof target === 'object' && Object.is(target, {})) {
      const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
      Object.keys(cp).forEach((k) => {
        cp[k] = this._deepCopy<any>(cp[k]);
      });
      return cp as T;
    }
    return target;
  };

  /**
   * 리소스 관련 정보(히스토리)를 요청하는 함수
   * @param characterId 히스토리를 얻고 싶은 캐릭터 아이디
   */
  async getAllCharacterResourceHistory(characterId: number) {
    return this.graphql
      .query(
        gql`
          query getAllCharacterResourceHistory($characterId: ID!) {
            getAllCharacterResourceHistory(characterId: $characterId) {
              imgName
              imgPath
              uploaderName
            }
          }
        `,
        {
          characterId
        }
      )
      .toPromise();
  }

  /**
   * 캐릭터 담당자 변경 요청 함수
   * @param characterId 변경할 캐릭터 아이디
   * @param manager 담당자 이름
   */
  async changeCharacterManager(characterId: number, manager: string): Promise<any> {
    return this.graphql
      .mutate(
        gql`
          mutation changeCharacterManager($characterId: ID!, $manager: String!) {
            changeCharacterManager(characterId: $characterId, manager: $manager)
          }
        `,
        {
          characterId,
          manager
        }
      )
      .toPromise();
  }

  /**
   * 캐릭터 담당자 변경 요청 함수, 한 카테고리 모든 캐릭터 한번에 변경
   * @param characterCategoryId 변경할 카테고리 아이디
   * @param manager 담당자 이름
   */
  public changeAllCharacterInCategoryManager(characterCategoryId: number, manager: string) {
    return this.graphql
      .mutate(
        gql`
          mutation changeAllCharacterInCategoryManager($characterCategoryId: ID!, $manager: String!) {
            changeAllCharacterInCategoryManager(characterCategoryId: $characterCategoryId, manager: $manager)
          }
        `,
        {
          characterCategoryId,
          manager
        }
      )
      .toPromise();
  }

  /**
   * redis 캐쉬 삭제 쿼리 요청 함수
   * @param key 삭제할 캐쉬의 키
   */
  public redisDelete(key: string) {
    return this.graphql
      .query(
        gql`
          query redisDelete($key: String!) {
            redisDelete(key: $key)
          }
        `,
        {
          key
        }
      )
      .toPromise();
  }

  /**
   * 편집툴에서 고유 IP 캐릭터의 기능에 대한 제한 정보를 가져온다
   * @param characterId - 기능 제한 정보를 가져올 캐릭터 아이디
   * @return {Promise<ApolloQueryResult<any>>}
   */
  public getCharacterFeatureLimit(characterId: number): Promise<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getCharacterFeatureLimit($characterId: ID!) {
            getCharacterFeatureLimit(characterId: $characterId) {
              aiLimit
              skinLimit
              bitmapFilterLimit
              layerSeparationLimit
              faceHidingLimit
            }
          }
        `,
        { characterId }
      )
      .toPromise();
  }

  /**
   * 어드민에서 고유 IP 캐릭터의 기능에 대한 제한을 설정한다
   * @param characterId - 기능 제한 정보를 수정할 캐릭터 아이디
   * @param {string} key - 제한 기능
   * @param {boolean} value - 제한 여부
   * @return {Observable<FetchResult<any>>}
   *
   */
  public updateCharacterFeatureLimit(characterId: number, key: string, value: boolean): Observable<FetchResult<any>> {
    let mutation: any;
    let param: any;
    mutation = gql`
      mutation updateCharacterFeatureLimit($characterId: ID!, $key: String!, $value: Boolean!) {
        updateCharacterFeatureLimit(characterId: $characterId, key: $key, value: $value)
      }
    `;
    param = { characterId, key, value };
    return this.graphql.mutate(mutation, param);
  }

  /**
   * 해당 캐릭터의 이미지 경로를 가져온다
   * @param {number} characterId - 캐릭터 아이디
   * @return {Promise<ApolloQueryResult<any>>}
   */
  getCharacterJPG(characterId: number): Promise<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getCharacterJPG($characterId: ID!) {
            getCharacterJPG(characterId: $characterId) {
              imgPath
            }
          }
        `,
        {
          characterId
        }
      )
      .pipe(
        map((result) => {
          result.data = result.data.getCharacterJPG;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 캐릭터 교체 기능에서 기업용 캐릭터 리스트를 가져온다
   * @returns {Promise<any>}
   */
  async getEnterPriseCharacterList(): Promise<any> {
    return this.graphql
      .query(
        gql`
          query getEnterPriseCharacterList {
            getEnterPriseCharacterList {
              id
              name_ko
            }
          }
        `
      )
      .pipe(
        map((result) => {
          result = result.data.getEnterPriseCharacterList;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 캐릭터 교체 기능에서 투닝 캐릭터 리스트를 가져온다
   * @returns {Promise<any>}
   */
  async getTooningCharacterList(): Promise<any> {
    return this.graphql
      .query(
        gql`
          query getTooningCharacterList {
            getTooningCharacterList {
              id
              name_ko
            }
          }
        `
      )
      .pipe(
        map((result) => {
          result = result.data.getTooningCharacterList;
          return result;
        })
      )
      .toPromise();
  }

  /**
   * 선택된 모든 페이지에 존재하는 모든 캐릭터들의 총 개수를 가져온다
   * @param {number} userId - 사용자 아이디
   * @param {[number]} canvasIdList - 캔버스 아이디 리스트
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  countSearchingCharacter(userId: number, canvasIdList: [number]): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query countSearchingCharacter($userId: ID!, $canvasIdList: [ID!]!) {
          countSearchingCharacter(userId: $userId, canvasIdList: $canvasIdList)
        }
      `,
      { userId, canvasIdList }
    );
  }

  /**
   * 선택된 모든 페이지에 존재하는 검색된 텍스트의 총 개수를 가져온다
   * @param {number} userId - 사용자 아이디
   * @param {[number]} canvasIdList - 캔버스 아이디 리스트
   * @param {string} searchText - 검색 텍스트
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  countSearchingText(userId: number, canvasIdList: [number], searchText: string): Observable<ApolloQueryResult<any>> {
    return this.graphql.query(
      gql`
        query countSearchingText($userId: ID!, $canvasIdList: [ID!]!, $searchText: String!) {
          countSearchingText(userId: $userId, canvasIdList: $canvasIdList, searchText: $searchText)
        }
      `,
      { userId, canvasIdList, searchText }
    );
  }

  /**
   * 캔버스를 클론하고 기존 캐릭터를 교체한다. 후에 캔버스를 클론하지 않는 것으로 복구 예정.
   * @param {number} userId - 사용자 아이디
   * @param {[number]} canvasId - 캔버스 아이디
   * @param {number} characterId - 교체할 기업용 캐릭터 아이디
   * @param {number} canvasGroupId - 캔버스 그룹 아이디
   * @returns {Promise<ExecutionResult<any>>} - 클론 캔버스 실행 결과
   */
  replaceCharacterJSON(userId: number, canvasId: number, characterId: number, canvasGroupId?: number): Promise<ExecutionResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation replaceCharacterJSON($userId: ID!, $canvasId: ID!, $characterId: ID!, $canvasGroupId: Int) {
            replaceCharacterJSON(userId: $userId, canvasId: $canvasId, characterId: $characterId, canvasGroupId: $canvasGroupId) {
              canvasId
              result
            }
          }
        `,
        {
          userId,
          canvasId,
          characterId,
          canvasGroupId
        }
      )
      .pipe(
        map((results) => {
          results.data = results.data.replaceCharacterJSON;
          return results;
        })
      )
      .toPromise();
  }

  /**
   * 검색 텍스트를 입력 텍스트로 교체한다
   * @param {number} canvasId - 캔버스 아이디
   * @param {number} userId - 사용자 아이디
   * @param {string} searchText - 검색 텍스트
   * @param {string} inputText - 입력 텍스트
   * @returns {Promise<boolean>}
   */
  async replaceTextJSON(canvasId: number, userId: number, searchText: string, inputText: string): Promise<boolean> {
    const result: any = await this.graphql
      .mutate(
        gql`
          mutation replaceTextJSON($canvasId: ID!, $userId: ID!, $searchText: String!, $inputText: String!) {
            replaceTextJSON(canvasId: $canvasId, userId: $userId, searchText: $searchText, inputText: $inputText)
          }
        `,
        {
          canvasId,
          userId,
          searchText,
          inputText
        }
      )
      .toPromise();
    return result;
  }

  /**
   * 캐릭터의 AuthorType 가져오기
   * @param {number | string} characterId AuthorType을 확인할 캐릭터 아이디
   * @return {Promise<string>}
   */
  async getCharacterAuthorType(characterId: number | string): Promise<string> {
    const result: any = await this.graphql
      .query(
        gql`
          query getCharacterAuthorType($characterId: ID!) {
            getCharacterAuthorType(characterId: $characterId)
          }
        `,
        { characterId }
      )
      .toPromise();

    return result.data.getCharacterAuthorType;
  }

  // /**
  //  * 캔버스 클론하는 방식으로 변경해서 주석처리. 후에 복구 예정. 원코드는 https://github.com/toonsquare/tooning-repo/pull/4681 참고
  //  * 기존 캐릭터를 타겟 캐릭터로 교체한다
  //  * @param {number} userId - 사용자 아이디
  //  * @param {number} canvasId - 캔버스 아이디
  //  * @param {number} characterId - 교체할 캐릭터 아이디
  //  * @returns {Promise<boolean>}
  //  */
  // async updateCharacterJSON(userId: number, role: string, canvasId: number, characterId: number): Promise<boolean> {
  //   const result: any = await this.graphql
  //     .mutate(
  //       gql`
  //         mutation updateCharacterJSON($userId: ID!, $role: String!, $canvasId: ID!, $characterId: ID!) {
  //           updateCharacterJSON(userId: $userId, role: $role, canvasId: $canvasId, characterId: $characterId)
  //         }
  //       `,
  //       {
  //         userId,
  //         canvasId,
  //         role,
  //         characterId
  //       }
  //     )
  //     .toPromise();
  //   return result;
  // }
}
