import { IsNotEmpty, IsIn, IsHexColor, IsOptional, IsUrl, ValidateIf } from 'class-validator';
import { AppComponent } from '../../app.component';
import { ResMakerCharacterService } from './character.service';
import * as _ from 'lodash';
import { Version } from '../../enum/app.enum';
import { take } from 'rxjs/operators';
import { CharacterFeatureLimitInterface } from '../../interfaces/app.interface';

// 기본속성이나 페이지에 표현되지 않는 속성들
// 발행상태 및 상세페이지별 완료 여부
const stepCount = 16;

export class ModelCharacterMeta {
  // implements ModelCharacterBase {

  public basicStep: boolean;
  public faceStep: boolean;
  @IsNotEmpty() id: number; // 캐릭터 id, DB에 저장된 상태가 아니면 null

  constructor(data?: Partial<ModelCharacterMeta>) {
    if (data != null) {
      Object.assign(this, data);
    } else {
      this.init();
    }
  }

  public init() {
    this.id = null;
  }
}

export class ModelCharacterStep {
  constructor(public appComPo: AppComponent, public resMakerCharacterService: ResMakerCharacterService) {}

  // step Check
  public async stepTFCheck(characterId) {
    // tslint:disable-next-line:forin
    if (characterId === 0) {
      this.appComPo.iconMark = _.fill(Array(stepCount), 'square-outline');
      return;
    }
    const etcUploadStepData = await this.resMakerCharacterService.getStepData(characterId);
    etcUploadStepData.pipe(take(1)).subscribe(
      (result) => {
        const StepTF = result.data;
        let index = 0;
        this.appComPo.iconMark = _.fill(Array(stepCount), 'square-outline');

        // tslint:disable-next-line:forin
        for (const step in StepTF.getStepData[0]) {
          // @ts-ignore
          this.appComPo.iconMark[index] = StepTF.getStepData[0][step] ? 'checkbox-outline' : 'square-outline';
          index++;
        }
      },
      (err) => {
        this.appComPo.iconMark = _.fill(Array(stepCount), 'square-outline');
        console.error('err:::::::', err);
      }
    );
  }
}

// 캐릭터 소개 페이지 정보들
export class ModelCharacterBasic {
  // implements ModelCharacterBase {

  // validator 리스트 : https://github.com/typestack/class-validator#validation-decorators
  @IsNotEmpty() name_ko: string;

  // validator 사용법 : https://github.com/typestack/class-validator#usage
  @IsNotEmpty() desc_ko: string;
  @IsNotEmpty() name_en: string;
  @IsNotEmpty() desc_en: string;
  @IsNotEmpty() name_fr: string;
  @IsNotEmpty() desc_fr: string;
  @IsNotEmpty() name_jp: string;
  @IsNotEmpty() desc_jp: string;
  @IsNotEmpty() authorType: string;
  @IsNotEmpty() version: Version;
  @IsNotEmpty() tags: any[];
  isSTCenable: boolean;
  isStyleEnable: boolean;
  hasCustomColor: boolean;
  @IsIn(['smart', 'one']) type: string;
  @IsIn(['illustration', 'webToon', 'daily']) style: string;
  @IsIn(['all', '4cut']) target: string;
  @IsHexColor() color_name: string;
  @IsHexColor() color_desc: string;
  @IsOptional() @ValidateIf((e) => e.gameLink !== '') @IsUrl() gameLink?: string;
  // 요청보낼때 쓰이는 데이터
  @ValidateIf((o) => o.imgPath == null)
  @IsNotEmpty()
  fileImg: File;
  // db에서 받아올떄만 있는 데이터
  @ValidateIf((o) => o.fileImg == null)
  imgPath: string;
  @IsNotEmpty() featureLimit: CharacterFeatureLimitInterface;

  constructor(data?: Partial<ModelCharacterBasic>) {
    if (data != null) {
      Object.assign(this, data);
    } else {
      this.isSTCenable = this.isStyleEnable = this.hasCustomColor = false;
      this.color_name = this.color_desc = '#000000';
    }
  }
}

export class ModelCharacterResource {
  // implements ModelCharacterBase {

  @IsNotEmpty() selectedCharacters: number[];
  @IsNotEmpty() resourcePart: string;
  @IsNotEmpty() resourceDirection: string;
  @IsNotEmpty() order: number[];
  @IsOptional() armOrder: number[];

  constructor(data?: Partial<ModelCharacterResource>) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}

export class ModelCharacter {
  // implements ModelCharacterBase {

  @IsNotEmpty() id: number;
  // tslint:disable-next-line:variable-name
  @IsNotEmpty() name_ko: string;
  // tslint:disable-next-line:variable-name
  @IsNotEmpty() name_en: string;
  @IsNotEmpty() imgPath: string;

  constructor(data?: Partial<ModelCharacter>) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
