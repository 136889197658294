import { Command } from './command';
import { Page } from '../../model/page/page';
import { TemplateService } from '../../services/template/template.service';

/**
 * Class TemplatesCopyCommand 전체 페이지를 적용 할때 사용
 * @extends Command
 */
class TemplatesCopyCommand extends Command {
  protected callback;
  protected pageIds: number[];
  protected canvasId: number;
  protected copiedAllPage: Page[];
  protected beforePageIndex: number;
  protected beforePageLength: number;
  protected templateService: TemplateService;

  /**
   * Create TemplatesCopyCommand
   * @param data
   */
  constructor(data) {
    try {
      super(data);
      this.templateService = data.templateService;
      this.callback = data.callback;
      this.commandName = 'Templates-Copy-Command';
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Execute TemplatesCopyCommand
   */
  async execute() {
    try {
      await super.execute();
      await this.cut.updatePageSync();
      const { copiedAllPage, canvasId, beforePageIndex, beforePageLength } = await this.callback();
      this.copiedAllPage = copiedAllPage;
      this.pageIds = copiedAllPage.map((page) => +page.id);
      this.canvasId = canvasId;
      this.beforePageLength = beforePageLength;
      this.beforePageIndex = beforePageIndex;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo TemplatesCopyCommand
   */
  async redo() {
    try {
      await super.redo();
      await this.cut.pageService.pageMultiRestore(+this.cut.app.cache.user.id, this.canvasId, this.pageIds).toPromise();
      await this.templateService.pushPage(this.copiedAllPage, this.beforePageIndex + 1, this.beforePageLength);
      await this.cut.app.showToast(this.cut.translate.instant('added'), 1000);
      if (this.cut.app.isDesktopWidth()) {
        await this.templateService.pageScroll(this.cut.panelIndex);
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo TemplatesCopyCommand
   */
  async undo() {
    try {
      await super.undo();
      await this.cut.pageService.pageMultiDelete(+this.cut.app.cache.user.id, this.canvasId, this.pageIds).toPromise();
      const beforePages = this.cut.pageList.filter((page) => {
        if (!this.pageIds.includes(+page.id)) {
          return page;
        }
      });
      await this.cut.goPage(this.beforePageIndex);
      this.cut.pageList = beforePages;
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { TemplatesCopyCommand };
