import { Component, OnInit } from '@angular/core';
import { HelpEmail, LanguageType } from '../../enum/app.enum';
import { AppService } from '../../services/app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-help-center-support',
  templateUrl: './help-center-support.component.html',
  styleUrls: ['./help-center-support.component.scss']
})
export class HelpCenterSupportComponent implements OnInit {
  private _supportMail: string = 'support@tooning.io';

  constructor(public app: AppService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.supportMail = this.app.usedLanguage === LanguageType.jp ? HelpEmail.jp : HelpEmail.default;
  }

  /**
   * support 메일 Getter
   * @return {string}
   */
  public get supportMail() {
    return this._supportMail;
  }

  /**
   * support 메일 Setter
   * @param {string} value
   * @return {void}
   */
  private set supportMail(value: string) {
    this._supportMail = value;
  }

  /**
   * 이메일 복사
   * @return {Promise<void>}
   */
  async copyEmail(): Promise<void> {
    await navigator.clipboard.writeText(this.supportMail);

    await this.app.showToast(this.translate.instant('help-center.copy-email'), 3000, 'dark');
  }
}
