import { Injectable, OnInit } from '@angular/core';
import { AppService } from './services/app.service';
import { fabric } from 'fabric';
import { logErrorMessage } from './pages-tooning/errors/TooningErrors';
import { TooningPropertyKeys } from 'dist/out-tsc/interfaces/app.tooningPropertyKeys.interface';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FabricjsOverride implements OnInit {
  constructor(public app: AppService) {}

  ngOnInit() {}

  /**
   * fabricJs 함수 몇가지를 js의 prototype 을 사용 하여, tooning 에 맞게 overwrite 한다.
   */
  overriddenFabricJs() {
    fabric.Image.prototype.srcFromAttribute = true;
    // 소수점 2짜리에서 올림 내림 처리되는 부분을 수정(내부 기본값 2); 두께 버그 #533
    fabric.Object.NUM_FRACTION_DIGITS = 10;
    //@ts-ignore
    fabric.minCacheSideLimit = 10;
    // by Branden,Ari
    fabric.IText.prototype.removeStyleFromTo = function (start, end) {
      try {
        var cursorStart = this.get2DCursorLocation(start, true),
          cursorEnd = this.get2DCursorLocation(end, true),
          lineStart = cursorStart.lineIndex,
          charStart = cursorStart.charIndex,
          lineEnd = cursorEnd.lineIndex,
          charEnd = cursorEnd.charIndex,
          i,
          styleObj,
          stylesLength = Object.keys(this.styles).length,
          stylesInLineStart = this.styles[lineStart],
          stylesInLinEnd = this.styles[lineEnd],
          styleLineStartLength = stylesInLineStart ? Object.keys(stylesInLineStart).length : 0,
          stylesLineEndLength = stylesInLinEnd ? Object.keys(stylesInLinEnd).length : 0,
          unwrappedTextLinesStartLength = this._unwrappedTextLines[lineStart].length,
          unwrappedTextLinesEndLength = this._unwrappedTextLines[lineEnd].length;

        if (lineStart !== lineEnd) {
          // this.styles 비어있을 경우 방어 코드
          // 여러 라인에 텍스트 남김없이 다 지웠을 때 스타일 초기화 방어 코드

          // https://github.com/toonsquare/tooning-repo/issues/2446 이 문제를 해결하기 위해
          // stylesInLineEnd 가 없는 경우는 리턴한다.
          if (!stylesInLinEnd) {
            return;
          }

          if (Object.keys(this.styles).length && stylesLineEndLength && stylesLineEndLength === unwrappedTextLinesEndLength) {
            stylesInLinEnd[stylesLineEndLength] = stylesInLinEnd[stylesLineEndLength - 1];
          }

          // step1 remove the trailing of lineStart
          if (this.styles[lineStart]) {
            for (i = charStart; i < unwrappedTextLinesStartLength; i++) {
              delete this.styles[lineStart][i];
            }
          }
          // step2 move the trailing of lineEnd to lineStart if needed
          if (stylesInLinEnd) {
            // 위 방어 코드에 의해 마지막 줄 style 의 length 는
            // 무조건 실제 텍스트의 length 보다 1 기므로 실제 텍스트 length 에 +1 해줌
            for (i = charEnd; i < unwrappedTextLinesEndLength + 1; i++) {
              styleObj = stylesInLinEnd[i];
              if (styleObj) {
                this.styles[lineStart] || (this.styles[lineStart] = {});
                this.styles[lineStart][charStart + i - charEnd] = styleObj;
              }
            }
          }
          // step3 detects lines will be completely removed.
          for (i = lineStart + 1; i <= lineEnd; i++) {
            delete this.styles[i];
          }
          // step4 shift remaining lines.
          this.shiftLineStyles(lineEnd, lineStart - lineEnd);
        } else {
          // remove and shift left on the same line
          if (this.styles[lineStart]) {
            styleObj = this.styles[lineStart];
            var diff = charEnd - charStart,
              numericChar,
              _char;

            // this.styles 비어있을 경우 방어 코드
            // 한 라인에 텍스트 남김없이 다 지웠을 때 스타일 초기화 방어 코드
            if (Object.keys(this.styles).length && styleLineStartLength === unwrappedTextLinesStartLength) {
              this.styles[lineStart][styleLineStartLength] = this.styles[lineStart][styleLineStartLength - 1];
            }

            for (i = charStart; i < charEnd; i++) {
              delete styleObj[i];
            }
            for (_char in this.styles[lineStart]) {
              numericChar = parseInt(_char, 10);
              if (numericChar >= charEnd) {
                styleObj[numericChar - diff] = styleObj[_char];
                delete styleObj[_char];
              }
            }
          }
        }
      } catch (e) {
        logErrorMessage('fabric.IText.prototype.removeStyleFromTo', e.message, e.stack);
      }
    };

    // by Branden,Ari
    fabric.IText.prototype.insertNewlineStyleObject = function (lineIndex, charIndex, qty, copiedStyle) {
      try {
        var currentCharStyle,
          newLineStyles = {},
          somethingAdded = false,
          unwrappedTextLinesLengthInLineIndex = this._unwrappedTextLines[lineIndex].length,
          isEndOfLine = unwrappedTextLinesLengthInLineIndex === charIndex ? true : false,
          refNumber, // 참고할 스타일 위치
          isUpperAddLine = false, // 윗쪽에 빈줄 스타일 추가,
          stylesInLineIndex = this.styles[lineIndex],
          styleLength = Object.keys(this.styles).length;

        // 커서 위치에 따라 참고할 스타일 위치 변경
        if (charIndex === unwrappedTextLinesLengthInLineIndex && unwrappedTextLinesLengthInLineIndex !== 0) {
          refNumber = -1;
        } else if (charIndex === 0) {
          refNumber = 0;
          // this.styles 비어있을 경우 방어 코드
          if (styleLength) {
            isUpperAddLine = true;
          }
        } else if (charIndex < unwrappedTextLinesLengthInLineIndex) {
          refNumber = 1;
        }

        qty || (qty = 1);
        this.shiftLineStyles(lineIndex, qty);
        if (stylesInLineIndex) {
          currentCharStyle = stylesInLineIndex[charIndex + refNumber]; // 참고 할 스타일 값 currentCharStyle에 저장
        }
        // we clone styles of all chars
        // after cursor onto the current line
        for (var index in stylesInLineIndex) {
          var numIndex = parseInt(index, 10);
          if (numIndex >= charIndex) {
            somethingAdded = true;
            newLineStyles[numIndex - charIndex] = stylesInLineIndex[index];
            // remove lines from the previous line since they're on a new line now
            if (!(isEndOfLine && charIndex === 0)) {
              delete stylesInLineIndex[index];
            }
          }
        }

        // 위에 빈 라인 생길 때 빈 라인 스타일 지정
        if (isUpperAddLine) {
          // https://github.com/toonsquare/tooning-repo/issues/2446
          // 위 이슈를 해결 하기 위해 styleLength 가 lineIndex  보다 작은 경우는  리턴한다.
          //  line 이 아래와 같이 2줄 이라면,
          // [ ['a', 'b', 'c'],
          //   ['d', 'e', 'f'] ]
          // styles 도
          // { 0 : {...},
          //   1:  {...} } 이렇게 라인별 스타일이 존재하여야하나 없는 경우가 있어서 아래 조건문 추가
          if (styleLength < lineIndex) {
            throw new Error(`라인 ${lineIndex} 에 style 이 없습니다`);
          }

          stylesInLineIndex[0] = newLineStyles[0];
        }

        var styleCarriedOver = false;
        if (somethingAdded && !isEndOfLine) {
          // if is end of line, the extra style we copied
          // is probably not something we want
          this.styles[lineIndex + qty] = newLineStyles;
          styleCarriedOver = true;
        }
        if (styleCarriedOver) {
          // skip the last line of since we already prepared it.
          qty--;
        }
        // for the all the lines or all the other lines
        // we clone current char style onto the next (otherwise empty) line
        while (qty > 0) {
          if (copiedStyle && copiedStyle[qty - 1]) {
            this.styles[lineIndex + qty] = { 0: fabric.util.object.clone(copiedStyle[qty - 1]) };
          } else if (currentCharStyle) {
            this.styles[lineIndex + qty] = { 0: fabric.util.object.clone(currentCharStyle) };
          } else {
            delete this.styles[lineIndex + qty];
          }
          qty--;
        }
        this._forceClearCache = true;
      } catch (e) {
        logErrorMessage('fabric.IText.prototype.insertNewlineStyleObject', e.message, e.stack);
      }
    };

    // by who?
    fabric.Object.prototype['sortObjectByZindex'] = function () {
      try {
        if (this.type === 'activeSelection') {
          const sortedObject = _.sortBy(this._objects, (ob) => {
            return this.canvas.getObjects().indexOf(ob);
          });
          this._objects = sortedObject;
        }
        return this;
      } catch (e) {
        logErrorMessage('fabric.Object.prototype[sortObjectByZindex]', e.message, e.stack, this.app.getUserEmail(), true);
      }
    };

    //오브젝트 캐쉬 처리 함수 - 일부 요소가 잘려서 렌더링되는 현상 개선 (by.Riley)
    // @ts-ignore
    fabric.Object.prototype._updateCacheCanvas = function (): boolean {
      try {
        const targetCanvas = this.canvas;
        if (this.noScaleCache && targetCanvas && targetCanvas._currentTransform) {
          const target = targetCanvas._currentTransform.target,
            action = targetCanvas._currentTransform.action;
          if (this === target && action.slice && action.slice(0, 5) === 'scale' && !target.strokeUniform) {
            return false;
          }
        }

        let canvas = this._cacheCanvas,
          dims = this._limitCacheSize(this._getCacheCanvasDimensions()),
          // @ts-ignore
          minCacheSize = fabric.minCacheSideLimit,
          width = dims.width,
          height = dims.height,
          drawingWidth,
          drawingHeight,
          zoomX = dims.zoomX,
          zoomY = dims.zoomY,
          dimensionsChanged = width !== this.cacheWidth || height !== this.cacheHeight,
          zoomChanged = this.zoomX !== zoomX || this.zoomY !== zoomY,
          shouldRedraw = dimensionsChanged || zoomChanged,
          additionalWidth = 0,
          additionalHeight = 0,
          shouldResizeCanvas = false;
        if (dimensionsChanged) {
          const canvasWidth = this._cacheCanvas.width,
            canvasHeight = this._cacheCanvas.height,
            sizeGrowing = width > canvasWidth || height > canvasHeight,
            sizeShrinking = (width < canvasWidth * 0.9 || height < canvasHeight * 0.9) && canvasWidth > minCacheSize && canvasHeight > minCacheSize;
          shouldResizeCanvas = sizeGrowing || sizeShrinking;
          if (sizeGrowing && !dims.capped && (width > minCacheSize || height > minCacheSize)) {
            additionalWidth = width * 0.1;
            additionalHeight = height * 0.1;
          }
        }
        if (this instanceof fabric.Text && this.path) {
          shouldRedraw = true;
          shouldResizeCanvas = true;
          additionalWidth += this.getHeightOfLine(0) * this.zoomX;
          additionalHeight += this.getHeightOfLine(0) * this.zoomY;
        }
        if (shouldRedraw) {
          if (shouldResizeCanvas) {
            canvas.width = Math.ceil(width + additionalWidth);
            canvas.height = Math.ceil(height + additionalHeight);
          } else {
            this._cacheContext.setTransform(1, 0, 0, 1, 0, 0);
            this._cacheContext.clearRect(0, 0, canvas.width, canvas.height);
          }
          drawingWidth = dims.x / 2;
          drawingHeight = dims.y / 2;

          // cache canvas상에서 오브젝트가 정중앙에 위치하도록 위치(cachetranslation) 수정
          let transX = 0;
          let transY = 0;
          if (this.transformMatrix) {
            transX = this.transformMatrix[4];
            transY = this.transformMatrix[5];
          }
          this.cacheTranslationX = Math.round(canvas.width / 2 - drawingWidth) + drawingWidth + transX * zoomX;
          this.cacheTranslationY = Math.round(canvas.height / 2 - drawingHeight) + drawingHeight + transY * zoomY;
          this.cacheWidth = width;
          this.cacheHeight = height;
          this._cacheContext.translate(this.cacheTranslationX, this.cacheTranslationY);
          this._cacheContext.scale(zoomX, zoomY);
          this.zoomX = zoomX;
          this.zoomY = zoomY;
          return true;
        }
        return false;
      } catch (e) {
        logErrorMessage('fabric.Object.prototype._updateCacheCanvas for iphone and ipad', e.message, e.stack, this.app.getUserEmail(), true);
      }
    };

    //캐릭터 동작/각도 등 변경 시 위치 이상해지는 문제 수정(by. Riley)
    //@ts-ignore
    fabric.Group.prototype.addWithUpdate = function (object) {
      var nested = !!this.group;
      this._restoreObjectsState();
      fabric.util.resetObjectTransform(this);
      if (object) {
        if (nested && this.group.resource_type !== 'character-svg') {
          const m = this.group.calcTransformMatrix();
          // if this group is inside another group, we need to pre transform the object
          // @ts-ignore
          fabric.util.removeTransformFromObject(object, m);
        }
        this._objects.push(object);
        object.group = this;
        object._set('canvas', this.canvas);
      }
      this._calcBounds();
      this._updateObjectsCoords();
      this.dirty = true;
      if (nested && this.group.resource_type !== 'character-svg') {
        this.group.addWithUpdate();
      } else {
        this.setCoords();
      }
      return this;
    };

    /**
     * fabric.Pattern 의 toObject 를 override 한다.
     * @param propertiesToInclude
     * @return {any}
     */
    fabric.Pattern.prototype.toObject = function (propertiesToInclude) {
      var NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS,
        source,
        toFixed = fabric.util.toFixed,
        object;

      //https://github.com/toonsquare/tooning-repo/issues/7395
      //위 문제를 해결하기 위해 아래 조건을 추가한다.
      if (this.source) {
        console.log(this.source);
        // <img> element
        if (typeof this.source.src === 'string') {
          source = this.source.src;
        }
        // <canvas> element
        else if (typeof this.source === 'object' && this.source.toDataURL) {
          source = this.source.toDataURL();
        }
      } else {
        console.log(`fabric.Pattern.toObject 시도 시, this.source 가 없습니다 해당 기능은 워터 마크에서 사용합니다.`);
      }
      object = {
        type: 'pattern',
        source: source,
        repeat: this.repeat,
        crossOrigin: this.crossOrigin,
        offsetX: toFixed(this.offsetX, NUM_FRACTION_DIGITS),
        offsetY: toFixed(this.offsetY, NUM_FRACTION_DIGITS),
        patternTransform: this.patternTransform ? this.patternTransform.concat() : null
      };
      fabric.util.populateWithProperties(this, object, propertiesToInclude);

      return object;
    };
    // transformMatrix 계속 필요 (by. Riley)
    fabric.Object.prototype.toObject = function (propertiesToInclude) {
      propertiesToInclude = (propertiesToInclude || []).concat(TooningPropertyKeys);
      var NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS,
        toFixed = fabric.util.toFixed,
        object = {
          type: this.type,
          version: fabric.version,
          originX: this.originX,
          originY: this.originY,
          left: toFixed(this.left, NUM_FRACTION_DIGITS),
          top: toFixed(this.top, NUM_FRACTION_DIGITS),
          width: toFixed(this.width, NUM_FRACTION_DIGITS),
          height: toFixed(this.height, NUM_FRACTION_DIGITS),
          fill: this.fill && this.fill.toObject ? this.fill.toObject() : this.fill,
          stroke: this.stroke && this.stroke.toObject ? this.stroke.toObject() : this.stroke,
          strokeWidth: toFixed(this.strokeWidth, NUM_FRACTION_DIGITS),
          strokeDashArray: this.strokeDashArray ? this.strokeDashArray.concat() : this.strokeDashArray,
          strokeLineCap: this.strokeLineCap,
          strokeDashOffset: this.strokeDashOffset,
          strokeLineJoin: this.strokeLineJoin,
          strokeUniform: this.strokeUniform,
          strokeMiterLimit: toFixed(this.strokeMiterLimit, NUM_FRACTION_DIGITS),
          scaleX: toFixed(this.scaleX, NUM_FRACTION_DIGITS),
          scaleY: toFixed(this.scaleY, NUM_FRACTION_DIGITS),
          angle: toFixed(this.angle, NUM_FRACTION_DIGITS),
          flipX: this.flipX,
          flipY: this.flipY,
          opacity: toFixed(this.opacity, NUM_FRACTION_DIGITS),
          shadow: this.shadow && this.shadow.toObject ? this.shadow.toObject() : this.shadow,
          visible: this.visible,
          backgroundColor: this.backgroundColor,
          fillRule: this.fillRule,
          paintFirst: this.paintFirst,
          globalCompositeOperation: this.globalCompositeOperation,
          transformMatrix: this.transformMatrix ? this.transformMatrix.concat() : null,
          skewX: toFixed(this.skewX, NUM_FRACTION_DIGITS),
          skewY: toFixed(this.skewY, NUM_FRACTION_DIGITS)
        };

      if (this.clipPath && !this.clipPath.excludeFromExport) {
        //@ts-ignore
        object.clipPath = this.clipPath.toObject(propertiesToInclude);
        //@ts-ignore
        object.clipPath.inverted = this.clipPath.inverted;
        //@ts-ignore
        object.clipPath.absolutePositioned = this.clipPath.absolutePositioned;
      }

      fabric.util.populateWithProperties(this, object, propertiesToInclude);
      if (!this.includeDefaultValues) {
        object = this._removeDefaultValues(object);
      }

      return object;
    };

    // fabricjs version up하면서 transformMatrix를 우리는 여전히 렌더링 시 고려해야함(by. Riley)
    fabric.Object.prototype.render = function (ctx) {
      // do not render if width/height are zeros or object is not visible
      if (this.isNotVisible()) {
        return;
      }
      if (this.canvas && this.canvas.skipOffscreen && !this.group && !this.isOnScreen()) {
        return;
      }
      ctx.save();
      this._setupCompositeOperation(ctx);
      this.drawSelectionBackground(ctx);
      this.transform(ctx);
      this._setOpacity(ctx);
      this._setShadow(ctx, this);
      if (this.transformMatrix) {
        ctx.transform.apply(ctx, this.transformMatrix);
      }
      if (this.shouldCache()) {
        this.renderCache();
        this.drawCacheOnCanvas(ctx);
      } else {
        this._removeCacheCanvas();
        this.dirty = false;
        this.drawObject(ctx);
        if (this.objectCaching && this.statefullCache) {
          this.saveState({ propertySet: 'cacheProperties' });
        }
      }
      ctx.restore();
    };

    // 제스쳐에서 두 손가락을 이용해 회전/스케일시키면 이동 제한 (by. Riley)
    //@ts-ignore
    fabric.Canvas.prototype.__onTransformGesture = function (e, self) {
      if (this.isDrawingMode || !e.touches || e.touches.length !== 2 || 'gesture' !== self.gesture) {
        return;
      }

      var target = this.findTarget(e);
      target.lockMovementX || (target.lockMovementX = true);
      target.lockMovementY || (target.lockMovementY = true);
      if ('undefined' !== typeof target) {
        this.__gesturesParams = {
          e: e,
          self: self,
          target: target
        };

        this.__gesturesRenderer();
      }

      this.fire('touch:gesture', {
        target: target,
        e: e,
        self: self
      });
    };

    // 중복으로 주석처리: 텍스트박스 자간이슈 발생시 확인 필요 (by. Paul)
    // https://github.com/toonsquare/tooning-repo/issues/2695
    //@ts-ignore
    fabric.Textbox.prototype._wrapLine = function (_line, lineIndex, desiredWidth, reservedSpace) {
      var lineWidth = 0,
        splitByGrapheme = this.splitByGrapheme,
        graphemeLines = [],
        line = [],
        // spaces in different languages?
        words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
        word = '',
        offset = 0,
        infix = splitByGrapheme ? '' : ' ',
        wordWidth = 0,
        infixWidth = 0,
        largestWordWidth = 0,
        lineJustStarted = true,
        additionalSpace = this._getWidthOfCharSpacing(),
        reservedSpace = reservedSpace || 0;
      // fix a difference between split and graphemeSplit
      if (words.length === 0) {
        words.push([]);
      }
      desiredWidth -= reservedSpace;
      for (var i = 0; i < words.length; i++) {
        // if using splitByGrapheme words are already in graphemes.
        word = splitByGrapheme ? words[i] : fabric.util.string.graphemeSplit(words[i]);
        wordWidth = this._measureWord(word, lineIndex, offset);
        // 중복으로 주석처리: 텍스트박스 자간이슈 발생시 확인 필요 (by. Paul)
        // offset += word.length;

        lineWidth += infixWidth + wordWidth - additionalSpace;
        if (lineWidth > desiredWidth && !lineJustStarted) {
          graphemeLines.push(line);
          line = [];
          lineWidth = wordWidth;
          lineJustStarted = true;
        } else {
          lineWidth += additionalSpace;
        }

        if (!lineJustStarted && !splitByGrapheme) {
          line.push(infix);
        }
        line = line.concat(word);

        infixWidth = splitByGrapheme ? 0 : this._measureWord([infix], lineIndex, offset);
        offset++;
        lineJustStarted = false;
        // keep track of largest word
        if (wordWidth > largestWordWidth) {
          largestWordWidth = wordWidth;
        }
      }

      i && graphemeLines.push(line);

      if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
        this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
      }
      return graphemeLines;
    };

    // circle 관련된 코드 degree에서 radian으로 재 변경(by.Riley)
    //@ts-ignore
    fabric.Circle.prototype.endAngle = 2 * Math.PI;

    // circle 관련된 코드 degree에서 radian으로 재 변경(by.Riley)
    //@ts-ignore
    fabric.Circle.prototype._toSVG = function () {
      var svgString,
        x = 0,
        y = 0,
        angle = (this.endAngle - this.startAngle) % (2 * Math.PI);

      if (angle === 0) {
        svgString = ['<circle ', 'COMMON_PARTS', 'cx="' + x + '" cy="' + y + '" ', 'r="', this.radius, '" />\n'];
      } else {
        //@ts-ignore
        var startX = fabric.util.cos(this.startAngle) * this.radius,
          //@ts-ignore
          startY = fabric.util.sin(this.startAngle) * this.radius,
          //@ts-ignore
          endX = fabric.util.cos(this.endAngle) * this.radius,
          //@ts-ignore
          endY = fabric.util.sin(this.endAngle) * this.radius,
          largeFlag = angle > Math.PI ? '1' : '0';
        svgString = [
          '<path d="M ' + startX + ' ' + startY,
          ' A ' + this.radius + ' ' + this.radius,
          ' 0 ',
          +largeFlag + ' 1',
          ' ' + endX + ' ' + endY,
          '" ',
          'COMMON_PARTS',
          ' />\n'
        ];
      }
      return svgString;
    };

    // circle 관련된 코드 degree에서 radian으로 재 변경(by.Riley)
    //@ts-ignore
    fabric.Circle.prototype._render = function (ctx) {
      ctx.beginPath();
      ctx.arc(0, 0, this.radius, this.startAngle, this.endAngle, false);
      this._renderPaintInOrder(ctx);
    };

    // stroke에 gradient가 들어있는 경우 에러 방지(by.Riley)
    //@ts-ignore
    fabric.Object.prototype._setStrokeStyles = function (ctx, decl) {
      var stroke = decl.stroke;
      if (stroke) {
        ctx.lineWidth = decl.strokeWidth;
        ctx.lineCap = decl.strokeLineCap;
        ctx.lineDashOffset = decl.strokeDashOffset;
        ctx.lineJoin = decl.strokeLineJoin;
        ctx.miterLimit = decl.strokeMiterLimit;
        if (stroke.toLive) {
          if (stroke.gradientUnits === 'percentage') {
            // need to transform gradient in a pattern.
            // this is a slow process. If you are hitting this codepath, and the object
            // is not using caching, you should consider switching it on.
            // we need a canvas as big as the current object caching canvas.
            this._applyPatternForTransformedGradient(ctx, stroke);
          } else {
            // is a simple gradient or pattern
            ctx.strokeStyle = stroke.toLive(ctx, this);
            this._applyPatternGradientTransform(ctx, stroke);
          }
        } else {
          // is a color
          ctx.strokeStyle = decl.stroke;
        }
      }
    };

    // canvas2d backendfilter일때 빈 필터 어레이에들어간 basefilter에서 에러 메세지 뱉지 않도록 임의 함수 추가 by Riley
    fabric.Image.filters.BaseFilter.prototype.applyTo2d = function () {
      console.log('원래 없는 basefilter.applyTo2d()가 돌아감');
      return;
    };

    // multi selection 시 내부 요소들의 angle, flipX, flipY가 이상해지는 오류 임시 대처 by Riley
    // 추후 fabric version up 작업 시 https://github.com/fabricjs/fabric.js/pull/8425 참고할 것
    //@ts-ignore
    fabric.util.applyTransformToObject = function (object, transform) {
      var options = fabric.util.qrDecompose(transform),
        center = new fabric.Point(options.translateX, options.translateY),
        originalFlipX = object.flipX,
        originalFlipY = object.flipY;

      object.flipX = false;
      object.flipY = false;
      object.set('scaleX', options.scaleX);
      object.set('scaleY', options.scaleY);
      object.skewX = options.skewX;
      object.skewY = options.skewY;

      if (Math.round(Math.abs(object.angle - options.angle)) === 180 && object.group) {
        if (object.group.flipX && object.group.flipY) {
          object.flipX = !originalFlipX;
          object.flipY = !originalFlipY;
        } else if (object.group.flipX) {
          object.flipX = !originalFlipX;
          object.flipY = originalFlipY;
        } else if (object.group.flipY) {
          object.flipX = originalFlipX;
          object.flipY = !originalFlipY;
        } else {
          object.flipX = originalFlipX;
          object.flipY = originalFlipY;
        }
        options.angle += 180;
      }

      while (options.angle >= 360) {
        options.angle -= 360;
      }

      object.angle = options.angle;
      object.setPositionByOrigin(center, 'center', 'center');
    };

    // 드래그로 멀티 셀렉션 후 빠르게 delete 키 입력 시 에러 발생 방지(by Riley)
    fabric.Object.prototype.drawBordersInGroup = function (ctx, options, styleOverride) {
      styleOverride = styleOverride || {};
      //@ts-ignore
      var bbox = fabric.util.sizeAfterTransform(this.width, this.height, options),
        strokeWidth = this.strokeWidth,
        strokeUniform = this.strokeUniform,
        borderScaleFactor = this.borderScaleFactor,
        width = bbox.x + strokeWidth * (strokeUniform && this.canvas ? this.canvas.getZoom() : options.scaleX) + borderScaleFactor,
        height = bbox.y + strokeWidth * (strokeUniform && this.canvas ? this.canvas.getZoom() : options.scaleY) + borderScaleFactor;
      ctx.save();
      this._setLineDash(ctx, styleOverride.borderDashArray || this.borderDashArray);
      ctx.strokeStyle = styleOverride.borderColor || this.borderColor;
      ctx.strokeRect(-width / 2, -height / 2, width, height);

      ctx.restore();
      return this;
    };

    fabric.StaticCanvas.prototype.toDataURL = function (options) {
      options || (options = {});

      var format = options.format || 'png',
        quality = options.quality || 1,
        multiplier = (options.multiplier || 1) * (options.enableRetinaScaling ? this.getRetinaScaling() : 1),
        canvasEl = this.toCanvasElement(multiplier, options);
      // @ts-ignore
      const data = fabric.util.toDataURL(canvasEl, format, quality);
      canvasEl = null;
      return data;
    };

    // 텍스트에서 텍스트를 전부 지우면 스타일까지 전부 날아감
    // 전부 지웠다가 새로 텍스트를 입력하더라도 최근 스타일이 그대로 남아 있도록 하기 위해서 changed이벤트를 fire할때 이전 스타일을 담아서 fire하도로 수정함
    // by.riley
    fabric.IText.prototype.onInput = function (e) {
      var fromPaste = this.fromPaste;
      this.fromPaste = false;
      e && e.stopPropagation();
      if (!this.isEditing) {
        return;
      }
      // decisions about style changes.
      var nextText = this._splitTextIntoLines(this.hiddenTextarea.value).graphemeText,
        charCount = this._text.length,
        nextCharCount = nextText.length,
        removedText,
        insertedText,
        charDiff = nextCharCount - charCount,
        selectionStart = this.selectionStart,
        selectionEnd = this.selectionEnd,
        selection = selectionStart !== selectionEnd,
        copiedStyle,
        removeFrom,
        removeTo;
      let allDeleteBeforeStyle; // 추가된 변수
      if (this.hiddenTextarea.value === '') {
        allDeleteBeforeStyle = _.cloneDeep(this.styles);
        this.styles = {};
        this.updateFromTextArea();
        this.fire('changed', { allDeleteBeforeStyle }); // 전부 지워지는 경우의 changed에 대해서만 해당 값이 있다
        if (this.canvas) {
          this.canvas.fire('text:changed', { target: this });
          this.canvas.requestRenderAll();
        }
        return;
      }

      var textareaSelection = this.fromStringToGraphemeSelection(
        this.hiddenTextarea.selectionStart,
        this.hiddenTextarea.selectionEnd,
        this.hiddenTextarea.value
      );
      var backDelete = selectionStart > textareaSelection.selectionStart;

      if (selection) {
        removedText = this._text.slice(selectionStart, selectionEnd);
        charDiff += selectionEnd - selectionStart;
      } else if (nextCharCount < charCount) {
        if (backDelete) {
          removedText = this._text.slice(selectionEnd + charDiff, selectionEnd);
        } else {
          removedText = this._text.slice(selectionStart, selectionStart - charDiff);
        }
      }
      insertedText = nextText.slice(textareaSelection.selectionEnd - charDiff, textareaSelection.selectionEnd);
      if (removedText && removedText.length) {
        if (insertedText.length) {
          // let's copy some style before deleting.
          // we want to copy the style before the cursor OR the style at the cursor if selection
          // is bigger than 0.
          copiedStyle = this.getSelectionStyles(selectionStart, selectionStart + 1, false);
          // now duplicate the style one for each inserted text.
          copiedStyle = insertedText.map(function () {
            // this return an array of references, but that is fine since we are
            // copying the style later.
            return copiedStyle[0];
          });
        }
        if (selection) {
          removeFrom = selectionStart;
          removeTo = selectionEnd;
        } else if (backDelete) {
          // detect differences between forwardDelete and backDelete
          removeFrom = selectionEnd - removedText.length;
          removeTo = selectionEnd;
        } else {
          removeFrom = selectionEnd;
          removeTo = selectionEnd + removedText.length;
        }
        this.removeStyleFromTo(removeFrom, removeTo);
      }
      if (insertedText.length) {
        //@ts-ignore
        if (fromPaste && insertedText.join('') === fabric.copiedText && !fabric.disableStyleCopyPaste) {
          copiedStyle = fabric.copiedTextStyle;
        }
        this.insertNewStyleBlock(insertedText, selectionStart, copiedStyle);
      }
      this.updateFromTextArea();
      this.fire('changed');
      if (this.canvas) {
        this.canvas.fire('text:changed', { target: this });
        this.canvas.requestRenderAll();
      }
    };

    fabric.Text.prototype.styleHas = function (property, lineIndex) {
      if (!this.styles || !property || property === '') {
        return false;
      }
      if (typeof lineIndex !== 'undefined' && !this.styles[lineIndex]) {
        return false;
      }

      const filteredStyles = {};
      // this.styles undefined 값을 제외하고 새로운 객체에 추가
      for (const key in this.styles) {
        for (var key2 in this.styles[key]) {
          if (this.styles.hasOwnProperty(key) && this.styles[key][key2] !== undefined) {
            filteredStyles[key] = this.styles[key];
          }
        }
      }
      this.styles = filteredStyles;

      var obj = typeof lineIndex === 'undefined' ? this.styles : { 0: this.styles[lineIndex] };
      // eslint-disable-next-line
      for (var p1 in obj) {
        // eslint-disable-next-line
        for (var p2 in obj[p1]) {
          if (typeof obj[p1][p2][property] !== 'undefined') {
            return true;
          }
        }
      }
      return false;
    };

    //@ts-ignore
    fabric.BaseBrush.prototype._setBrushStyles = function (ctx = this.canvas.contextTop) {
      ctx.strokeStyle = this.color;
      ctx.lineWidth = this.width;
      ctx.lineCap = this.strokeLineCap;
      ctx.miterLimit = this.strokeMiterLimit;
      ctx.lineJoin = this.strokeLineJoin;
      ctx.setLineDash(this.strokeDashArray || []);
    };

    //@ts-ignore
    fabric.Text.prototype.handleFiller = function (ctx, property, filler) {
      let offsetX, offsetY;
      if (filler && filler.toLive) {
        if (filler.gradientUnits === 'percentage' && !filler.patternTransform) {
          offsetX = -this.width / 2;
          offsetY = -this.height / 2;
          ctx.translate(offsetX, offsetY);

          let coords = {
            x1: this.width * filler.coords.x1,
            y1: this.height * filler.coords.y1,
            x2: this.width * filler.coords.x2,
            y2: this.height * filler.coords.y2
          };

          let gradient = ctx.createLinearGradient(coords.x1, coords.y1, coords.x2, coords.y2);

          for (let i = 0; i < filler.colorStops.length; i++) {
            let stop = filler.colorStops[i];
            gradient.addColorStop(stop.offset, stop.color);
          }
          ctx[property] = gradient;
          return { offsetX: offsetX, offsetY: offsetY };
        } else if (filler.gradientTransform || filler.patternTransform) {
          // need to transform gradient in a pattern.
          // this is a slow process. If you are hitting this codepath, and the object
          // is not using caching, you should consider switching it on.
          // we need a canvas as big as the current object caching canvas.
          offsetX = -this.width / 2;
          offsetY = -this.height / 2;
          ctx.translate(offsetX, offsetY);

          ctx[property] = this._applyPatternGradientTransformText(filler);
          return { offsetX: offsetX, offsetY: offsetY };
        } else {
          // is a simple gradient or pattern
          ctx[property] = filler.toLive(ctx, this);
          console.log(ctx[property]);
          return this._applyPatternGradientTransform(ctx, filler);
        }
      } else {
        // is a color
        ctx[property] = filler;
      }
      return { offsetX: 0, offsetY: 0 };
    };
  }
}
