import { environment } from '../../../../environments/environment';

const Slack = require('slack');

const errorMessageToken = environment.slack_bot_token_error_message;
const errorMessageBot = new Slack({ token: errorMessageToken });
const boardClientErrorChannel = '02_24_보드_front_에러';

async function slackBoardClientError(message: any, functionName?: string) {
  const blocks = [
    {
      type: 'divider'
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*환경* : ${environment.deploy}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*시간* :  ${new Date()}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*메세지* :  \n${message}`
      }
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*함수 이름* :  \n${functionName}`
      }
    }
  ];

  return new Promise((resolve, reject) => {
    errorMessageBot.chat
      .postMessage({
        channel: boardClientErrorChannel,
        text: '보드 클라이언트 에러',
        blocks: JSON.stringify(blocks)
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

export default slackBoardClientError;
