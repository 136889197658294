<ion-header class='ion-no-border'>
  <ion-toolbar>
    <ion-title slot='start'>{{ 'Payment details' | translate }}</ion-title>
    <ion-buttons class='m-0  mr-2' slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content appScrollbarTheme>
  <div class='container'>
    <ul *ngFor='let history of historyList'>
      <li>
        <div class='d-block'>
          <div class='payment-hist-type-font'>
            <div *ngIf='history.paymentHistType === paymentHistoryEnum.pointCharge' style='align-items: center'>
              <img
                src='assets/payment-system/tooning-coin.png' alt='tooning-coin.png'/>
              <p>
                {{'point charge history' | translate}}
              </p>
            </div>
            <div *ngIf='history.paymentHistType === paymentHistoryEnum.subscription' class='d-flex align-items-center'>
              <img src='assets/payment-system/tooning_logo.png' alt='tooning_logo.png'/>
              <p>
                {{ 'tooning PRO' | translate}} {{'payment' | translate}}
              </p>
            </div>
          </div>
          <div class='payment-created-date-font'>{{changeDateFormat(+history.createdDate, 'YY.MM.DD HH:mm:ss')}}</div>
        </div>
        <div class='payment-price-font'>{{history.price | currency: history.currency :'symbol'}}</div>
      </li>
    </ul>
  </div>
</ion-content>
