<ion-header>
  <ion-toolbar>
    <ion-title slot='start'>{{ 'setting' | translate }}</ion-title>
    <ion-buttons slot='end'>
      <ion-button (click)='close()'>
        <ion-icon name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content #content appScrollbarTheme>


  <div class='px-3'>
    <ion-list class='mt-3'>
      <ion-list-header>
        <h5 style='font-weight: bold'>{{'setting' | translate}}</h5>
      </ion-list-header>

      <!--    Snap    -->
      <ion-item lines='full'>
        <ion-label>{{ 'snap' | translate }}
        </ion-label>
        <ion-toggle [(ngModel)]='cut.snapStatus' slot='end' editor></ion-toggle>
        <div tabindex='0'></div>
      </ion-item>

      <ion-item lines='full'>
        <ion-label>{{ 'networkStatus.lab.title' | translate }}
        </ion-label>
        <ion-toggle [(ngModel)]='networkStatusService.enableNetworkStatus'
                    slot='end' editor></ion-toggle>
        <div tabindex='0'></div>
      </ion-item>

      <!--    언어 변경    -->
      <ion-item lines='full' button (click)='app.changeLanguageService.alertChangeLanguage()'>
        <ion-label> {{ 'language setting' | translate }}</ion-label>
        <ion-label slot='end'>{{languageOriginalEnum[app.usedLanguage]}}
        </ion-label>

        <div tabindex='0'></div>
      </ion-item>

    </ion-list>

    <ion-list class='mt-3'>
      <ion-list-header>
        <h5 style='font-weight: bold'>{{'policy related menu' | translate}}</h5>
      </ion-list-header>

      <ion-item (click)='app.termsAndUse()' button detail lines='full'>
        <ion-label> {{'policy 2' | translate}} </ion-label>
        <div tabindex='0'></div>
      </ion-item>

      <ion-item (click)='app.copyRight()' button detail lines='full'>
        <ion-label> {{'copyright title' | translate}} </ion-label>
        <div tabindex='0'></div>
      </ion-item>

      <ion-item (click)='app.privacyPolicy()' button detail lines='full'>
        <ion-label> {{'privacy-statement title' | translate}} </ion-label>
        <div tabindex='0'></div>
      </ion-item>
    </ion-list>


  </div>

</ion-content>
