import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class Tag {
  @IsNumber() @IsNotEmpty() id: number;
  // tslint:disable-next-line:variable-name
  @IsString() @IsNotEmpty() name_ko: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
