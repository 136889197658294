import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PointService } from '../../services/point/point.service';
import { AppService } from '../../services/app.service';
import { PaypalRequestType } from '../../enum/app.enum';
import { PaypalService } from '../../services/paypal/paypal.service';

const { loadScript } = require('@paypal/paypal-js');

import { environment } from '../../../environments/environment';
import { TooningPayPalInitError } from '../../pages-tooning/errors/TooningErrors';
import { AnalyticsService } from '../../services/google/analytics/analytics.service';

@Component({
  selector: 'app-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.scss']
})
export class PaypalButtonComponent implements OnInit, AfterViewInit {
  @ViewChild('paypalButton', { static: true }) paypalEl: ElementRef;
  @Input() type: PaypalRequestType;
  @Input() amount: number;
  @Input() point: number;
  @Input() bonus: number;
  @Input() userId: number;
  @Input() tooningPlanId: number;
  @Input() paypalPlanId: string;
  @Input() eventId: number;
  @Input() pointChargePricePlanId: number;
  @Output() completed: EventEmitter<any> = new EventEmitter();

  public isLoading;

  constructor(
    private pointService: PointService,
    private paypalService: PaypalService,
    public app: AppService,
    private analyticsService: AnalyticsService
  ) {}

  ngAfterViewInit(): void {
    console.log('clientId', environment.paypalConfig.clientId);
    console.log('currency', this.app.getCurrencyCode());

    if (this.type === PaypalRequestType.order) {
      this.isLoading = true;
      loadScript({
        intent: 'capture',
        'client-id': environment.paypalConfig.clientId,
        currency: this.app.getCurrencyCode(),
        debug: true
      }).then((paypal) => {
        this.isLoading = false;
        const order = {
          purchase_units: [
            {
              amount: {
                currency_code: this.app.getCurrencyCode(),
                value: this.amount.toFixed(2)
              }
            }
          ]
        };
        paypal
          .Buttons({
            fundingSource: 'paypal',
            createOrder: async (data, actions) => {
              this.analyticsService.sendGA('paypalStart', this.app.cache.user.id, this.app.cache.user.country + '/' + this.app.currentService);
              return actions.order.create(order);
            },
            onApprove: async (data, actions) => {
              try {
                const response = await this.pointService
                  .pointChargeWithPaypal({
                    userId: +this.app.cache.user.id,
                    point: this.point,
                    bonusPoint: this.bonus,
                    paypalOrderId: data.orderID,
                    amount: this.amount,
                    pointPriceId: +this.pointChargePricePlanId
                  })
                  .toPromise();

                if (response.data.result) {
                  this.completed.emit(response.data);
                  this.analyticsService.sendGA('purchasePoint', this.app.cache.user.country + ': ' + this.point, this.app.currentService);
                  return Promise.resolve();
                } else {
                  return Promise.reject(response.data);
                }
              } catch (error) {
                console.error(error);
                alert('Paypal Payment is failed');
                return Promise.reject(error);
              }
            },
            onCancel: async (data) => {
              // Show a cancel page, or return to cart
              this.analyticsService.sendGA('paypalOnCancel', this.app.cache.user.id, this.app.cache.user.country + '/' + this.app.currentService);
              console.log(data);
              alert('Paypal Payment is canceled');
            },
            onError: async (err) => {
              // Show an error page here, when an error occurs
              this.analyticsService.sendGA('paypalOnError', this.app.cache.user.id, this.app.cache.user.country + '/' + this.app.currentService);
              console.log(err);
              alert('Error Occurred. Please try again');
              throw new TooningPayPalInitError(err, this.app, true);
            }
          })
          .render(this.paypalEl.nativeElement);
      });
    } else if (this.type === PaypalRequestType.subscription) {
      this.isLoading = true;
      loadScript({
        'client-id': environment.paypalConfig.clientId,
        currency: this.app.getCurrencyCode(),
        vault: true,
        intent: 'subscription'
      }).then((paypal) => {
        this.isLoading = false;
        const subscription = {
          plan_id: this.paypalPlanId,
          application_context: {
            user_action: 'CONTINUE' // 디폴트 값은 SUBSCRIBE_NOW. 서버에서 작업 완료후 ACTIVATE 시키기 위해 CONTINUE 로 변경
          }
        };
        paypal
          .Buttons({
            fundingSource: 'paypal',
            createSubscription(data, actions) {
              return actions.subscription.create(subscription);
            },
            onApprove: async (data, actions) => {
              // console.log(data);
              try {
                const response = await this.paypalService
                  .subscribeWithPaypal(
                    data.billingToken,
                    this.userId,
                    this.eventId,
                    this.tooningPlanId,
                    data.facilitatorAccessToken,
                    data.orderID,
                    data.paymentID,
                    data.subscriptionID
                  )
                  .toPromise();
                // console.log(response);

                const { data: resultData } = response;
                if (resultData.result) {
                  this.completed.emit(resultData);
                  return Promise.resolve();
                } else {
                  return Promise.reject(resultData);
                }
              } catch (error) {
                console.error(error);
                return Promise.reject(error);
              }
            },
            onCancel(data) {
              // Show a cancel page, or return to cart
              console.log(data);
              alert('Paypal Payment is canceled');
            },
            onError(err) {
              // Show an error page here, when an error occurs
              console.log(err);
              switch (err?.errorCode) {
                case 'ALREADY SUBSCRIBED':
                  alert('Subscribed Already');
                  break;
                default:
                  alert('Error Occurred. Please try again');
                  break;
              }
              throw new TooningPayPalInitError(err, this.app, true);
            }
          })
          .render(this.paypalEl.nativeElement);
      });
    }
  }

  ngOnInit() {}
}
