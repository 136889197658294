<div *ngIf="app.update.progress > 0">
  <div>
    <ion-progress-bar [value]="app.update.progress / 100"></ion-progress-bar>
  </div>
</div>

<div *ngIf="app.update.show_update_button && !app.update.next_time">
  <div class="popup">
    <div class="popup-contents ion-padding">
      <div class="popup-text ion-margin">{{ 'update message1' | translate }}<br /><br />{{ 'update message2' | translate }}<br />{{ 'update message3' | translate }}<br />{{ 'update message4' | translate }}<br /></div>
      <div class="download-bottom">
        <ion-button class="download-next" (click)="clickNext()">
          {{ 'update message btn 1' | translate }}
        </ion-button>
        <ion-button class="download" (click)="clickDoUpdate()">
          {{ 'update message btn 2' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="app.update.ready_to_reload">
  <div class="popup">
    <div class="popup-contents ion-padding">
      <div class="popup-text ion-margin">
        {{ 'update message5' | translate }}<br /><br />
        {{ 'update message6' | translate }}<br /><br /><br />{{ 'update message7' | translate }}<br /><br />
      </div>
      <div class="download-bottom">
        <ion-button class="download-next" (click)="clickNext()">
          {{ 'update message btn 1' | translate }}
        </ion-button>
        <ion-button class="download" (click)="clickReload()">
          {{ 'update message btn 3' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="show_reload">
  <div class="popup">
    <div class="popup-contents ion-padding">
      <div class="popup-text ion-margin">
        {{ 'update message8' | translate }}
        <br />
        {{ 'update message9' | translate }}
      </div>
      <ion-spinner name="crescent" class="ion-margin"></ion-spinner>
    </div>
  </div>
</div>
