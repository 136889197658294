<app-header *ngIf='!isModal'></app-header>
<ion-header *ngIf='isModal' class='ion-no-border'>
  <ion-toolbar>
    <ion-buttons *ngIf='viewType!==userPageViewType.loginSocial' slot='start'>
      <ion-button (click)='backButtonControl()'>
        <ion-icon class='back-btn' name='chevron-back-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot='end'>
      <ion-button (click)='close()'>
        <ion-icon class='close-btn' name='close-outline' slot='icon-only'></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content appScrollbarTheme>
  <!-- isLoginPageMove 로그인 후 로그인 페이지면 메인으로 이동 -->
  <div class='login-page-wrapper' (animationstart)='isLoginPageMove()'>
    <ng-container [ngSwitch]='isLoginProgressing'>

      <!--  로딩 중    -->
      <div *ngSwitchCase='true' class='m-auto'>
        <ion-spinner></ion-spinner>
        <div class='mt-3'>{{'isLoginProgressing' | translate}}</div>
      </div>

      <ng-container *ngSwitchCase='false'>
        <!-- 회원가입    -->
        <app-signup *ngIf='viewType?.includes(userPageViewType.signup)'
                    class='signup animation_fadeIn'
                    [canvasId]='canvasId' [viewType]='viewType' [isDemo]='isDemo' [isModal]='isModal'
                    (modalClose)='modalControl()' (viewChange)='viewChange($event)'>
        </app-signup>
        <!--  로그인  -->

        <div *ngIf='viewType?.includes(userPageViewType.login)'
          class='login-container animation_fadeIn'
             [style.margin-top.px]='isModal?25:52'>

          <div class='login-title'>{{(isSignup || viewType===userPageViewType.loginSocialAgreement ? 'signupPage.title' : 'loginPage.title') | translate}}</div>

          <ng-container [ngSwitch]='viewType'>
            <!--   소셜 로그인      -->
            <div *ngSwitchCase='userPageViewType.loginSocial' class='social-login-mode'>
              <div class='social-login-wrapper'>
                <div style='height: 44px; margin-bottom: 15px'
                     data-cy='google'
                     id="{{'google_login_'+app.googleButtonNum}}"></div>

                <div class='button-box btn-kakao position-relative' data-cy='kakao' (click)='kakaoLoginGo()' matRipple>
                  <img src='assets/login/login_btn_1.png' class='btn-icon' alt='login button 1' />
                  {{(isSignup ? 'loginPage.signup.kakao' : 'loginPage.login.kakao') | translate}}
                </div>
                <div class='button-box btn-facebook position-relative' data-cy='facebook' (click)='facebookLoginGo()'
                     matRipple>
                  <img src='assets/login/login_btn_4.png' class='btn-icon' alt='facebook button icon' />
                  {{(isSignup ? 'loginPage.signup.facebook' : 'loginPage.login.facebook') | translate}}
                </div>
                <div #customLoginWrapper class='button-box m-0 btn-custom position-relative' data-cy='email' matRipple
                     (click)='customJoinButtonClickHandler()'>
                  <img src='assets/login/login_btn_7.png' class='btn-custom-icon' alt='tooning button icon'/>
                  {{(isSignup ? 'loginPage.signup.normal' : 'loginPage.login.normal') | translate}}
                </div>
              </div>
              <!-- 다른 방법으로 로그인    -->
              <div class='other-social-login-wrapper'>

                <div class='other-login-text-container'>
                  <div class='horizontal-line'></div>
                  <div class='other-login-text'>
                    {{(isSignup ? 'signupPage.etc' : 'loginPage.etc') | translate}}
                  </div>
                  <div class='horizontal-line'></div>
                </div>
                <!--       웨일스페이스, 쿨스쿨       -->
                <div data-cy='social-login' class='d-flex justify-content-center'
                     style='padding-left: 20px;padding-right: 20px;'>
                  <!-- 웨일 로그인 로그인 버튼-->
                  <div data-cy='whale' (click)='whalespaceLoginGo()' class='d-flex flex-column sns-icon btn-whale'
                       *ngIf='!app.isHybrid()'
                       matRipple>
                    <img src='assets/login/login_btn_5.png' class='m-auto' alt='whale button icon'/>
                  </div>
                  <!-- 쿨스쿨 로그인 로그인 버튼-->
                  <div data-cy='coolSchool' (click)='coolSchoolLoginGo()'
                       class='d-flex flex-column sns-icon btn-coolshcool'
                       *ngIf='!app.isHybrid()' matRipple>
                    <img src='assets/login/login_btn_6.png' class='m-auto' alt='coolschool button icon' />
                  </div>
                </div>
              </div>
            </div>

            <!--    소셜 로그인 이용약관 동의        -->
            <div *ngSwitchCase='userPageViewType.loginSocialAgreement' class='social-login-agreement-mode'>
              <app-sns-terms-agreement [snsInfo]='snsInfo' (signupButtonClick)='snsSignup($event)' class='agreement-content'></app-sns-terms-agreement>
            </div>

            <!--    커스텀 로그인      -->
            <div *ngSwitchCase='userPageViewType.loginCustom' class='custom-login-mode'>
              <div class='email-wrapper'>
                <div class='label'>{{'email' | translate}}</div>
                <div #loginEmail (keydown)='removeValidationErrorMessage(loginEmail)'
                     class='ui form field' id='login-email'>
                  <input #loginEmailInput [(ngModel)]='customLoginUser.email'
                         [maxlength]='CustomUserDefaultMetaData.maxEmailLength' data-cy='inputRegisteredEmail'
                         placeholder='tooning@example.com' class='custom-login-input' type='text' />
                </div>
              </div>
              <div class='password-wrapper'>
                <div class='label'>{{'Password' | translate}}</div>
                <div #loginPassword (keydown)='removeValidationErrorMessage(loginPassword)'
                     class='ui form field' id='login-password'>
                  <input #passwordInput [(ngModel)]='customLoginUser.password'
                         [maxlength]='CustomUserDefaultMetaData.maxPasswordLength'
                         placeholder="{{'Password' | translate}}" class='custom-login-input password-input'
                         type='password' data-cy='password' />
                  <div class='password-view-mode-button' [class]='passwordIsView?"eye-on":"eye-off"'
                       (click)='passwordViewModeChange(passwordInput)'>
                  </div>
                </div>
              </div>
              <button data-cy='login-btn' class='button-box login-btn m-0' (click)='loginCustom()'
                      matRipple>{{ 'login' | translate}} </button>
            </div>
          </ng-container>

          <div #bottom class='bottom-container' *ngIf='viewType !== userPageViewType.loginSocialAgreement'>
            <div class='d-flex justify-content-center'>
              <label class='signed-up-text'>
                {{(isSignup ? 'already signed up' : 'yet signed up') | translate}}
              </label>
              <label data-cy='signup' (click)='changeViewTypeClickHandler()'
                     type='button' class='label-text'>{{(isSignup ? 'loginPage.title' : 'signup') | translate}}</label>
            </div>
            <div *ngIf='!isSignup' (click)='moveToFindPassword()'
                 class='signup-forget-text'>{{'signup forget' | translate}}</div>
            <div *ngIf='isSignup' class='text-center m-auto w-100 policy-text'>
              <div><strong (click)='app.termsAndUse()' type='button'>{{'policy 2' | translate}}</strong> / <strong
                (click)='app.privacyPolicy()'
                type='button'>{{'policy 4' | translate}}</strong>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ion-content>
