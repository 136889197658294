import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TemplateService } from '../../services/template/template.service';
import { CanvasService } from '../../services/canvas.service';
import { fill, uniq } from 'lodash';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { ModelTemplateInput } from '../../services/template/template.service.model';
import { Loading } from '../../services/loading';
import { AlertController, IonSlides, ModalController } from '@ionic/angular';
import { Cut4MakeManualService } from '../../pages-4cut/cut4-make-manual2/cut4-make-manual.service';
import { categoryType, LanguageType, TemplateType, UserRole } from '../../enum/app.enum';
import {
  logErrorMessage,
  TooningBadRequestError,
  TooningErrorCode,
  TooningServerConnectionError,
  TooningUnauthorizedError,
  TooningUnkownNetworkError
} from '../../pages-tooning/errors/TooningErrors';
import HttpStatusCode from '../../pages-tooning/enums/httpErrors.enum';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss']
})
export class CreateTemplateComponent implements OnInit {
  public readyToRender = false;
  public checkList;
  public categoryList;
  public canvasData;
  public pageBase64List = [];
  public index = 0;
  public templateTags = [];
  public pageTags = [];
  public templageBackUpTags = [];
  public pageBackUpTags = [];
  public modelTemplateInput = new ModelTemplateInput();
  public categoryIds = [];
  public recentCategoryIdList = [];
  public purposeCategoryIdList = [];
  public subjectCategoryIdList = [];
  public educationCategoryIdList = [];
  public memeCategoryIdList = [];
  public memeResourceCategoryIdList = [];
  public templateId;
  public status = false;
  public memeStatus;
  public isGifTemplate: boolean;
  public paid = false;
  public language = LanguageType.ko;
  public prevLanguage = LanguageType.ko;
  public loading = new Loading();
  public isNew = true;
  public slideOpts: any;
  public pageTagObjList = [];
  public templateTagText;
  public selectedCategoryList = [];
  public templateEnterEvent$ = new Subject<string>();
  public pageEnterEvent$ = new Subject<string>();
  @Input('canvasId') canvasId;
  // @ts-ignore
  @ViewChild('slides1D') slides: IonSlides;
  private templateEnterTag$ = new Subject<string>();
  private pageEnterTag$ = new Subject<string>();
  public UserRole = UserRole;
  // @ts-ignore
  @ViewChild('content') private content: any;
  @Input('samsungResource') samsungResource;
  public categoryType = categoryType;
  public isInit: boolean = true;

  constructor(
    public templateService: TemplateService,
    public canvasService: CanvasService,
    public app: AppService,
    public modalCtrl: ModalController,
    public alertController: AlertController,
    public cut: Cut4MakeManualService
  ) {}

  ngOnInit() {
    this.templateEnterTag$.pipe(throttleTime(500)).subscribe(async (enterTag) => {
      this.templageBackUpTags.push(enterTag);
      this.templateTags.push(enterTag);
      if (navigator.platform === 'mac' || navigator.platform === 'MacIntel') await this.app.delay(300);
      this.templateTagText = '';
    });

    this.templateEnterEvent$.pipe(throttleTime(500)).subscribe(async (event) => {
      await this.templateTagEnter(event);
    });

    this.slideOpts = {
      initialSlide: 0,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };
  }

  async ionViewWillEnter() {
    this.readyToRender = false;
    await this.getCategoryNameList();
    const thumbnailOrder = await this.getData();

    this.readyToRender = true;
    //  뷰그려지고 진행 필요.
    //  대표화면 설정으로 이동
    if (!this.isNew) {
      await this.app.delay(200);
      await this.slides.slideTo(thumbnailOrder, 300);
    }
  }

  /**
   * canvasId로 템플릿 받아오기
   */
  async checkTemplate() {
    try {
      const { data } = await this.templateService.checkTemplate(this.canvasId);
      return data;
    } catch (e) {
      await this.app.showToast(`템플릿 조회 실패 ${e}`);
    }
  }

  /**
   * data set
   */
  async getData() {
    let thumbnailOrder = 0;

    // 업데이트 , 신규 생성 구분
    let getCanvas = await this.checkTemplate();

    if (getCanvas.template) {
      // 신규 아님
      this.isNew = false;

      // 템플릿 id, language, status값 할당
      this.templateId = getCanvas.template.id;
      this.language = getCanvas.template.language;
      this.prevLanguage = getCanvas.template.language;
      this.status = getCanvas.template.status;
      if (getCanvas.template.meme) {
        this.memeStatus = getCanvas.template.meme.status;
      }
      if (getCanvas.template.isGifTemplate) {
        this.isGifTemplate = true;
      }

      this.paid = getCanvas.template.paid;

      // template tag 리스트 담기
      for (const tag of getCanvas.template.tags) {
        const tagData = tag.name;
        this.templateTags.push(tagData);
      }

      // 카테고리 아이디 리스트 담기
      for (const element of getCanvas.template.templateCategory) {
        switch (element.categoryType) {
          case categoryType.recent:
            this.recentCategoryIdList.push(element.id);
            break;
          case categoryType.purpose:
            this.purposeCategoryIdList.push(element.id);
            break;
          case categoryType.subject:
            this.subjectCategoryIdList.push(element.id);
            break;
          case categoryType.education:
            this.educationCategoryIdList.push(element.id);
            break;
          case categoryType.meme:
            this.memeCategoryIdList.push(element.id);
            break;
          case categoryType.memeResource:
            this.memeResourceCategoryIdList.push(element.id);
            break;
        }
      }

      if (this.memeCategoryIdList.length > 0) this.samsungResource = categoryType.meme;
      else if (this.memeResourceCategoryIdList.length > 0) this.samsungResource = categoryType.memeResource;

      thumbnailOrder = getCanvas.template.thumbnailOrder;
    } else {
      // 신규
      this.isNew = true;
      this.isInit = false;
    }

    // 칸바스, 페이지 데이터 가져오기
    getCanvas = await this.canvasService.getCanvas(this.canvasId);
    getCanvas = getCanvas.data;
    // 페이지가 없을 경우
    if (getCanvas.pages.length <= 0) {
      await this.app.showToast('해당 캔버스는 페이지가 존재하지 않습니다. 페이지를 생성 후 다시 해주세요');
      await this.modalCtrl.dismiss();
      return;
    }

    if (getCanvas.canvasSize === null) {
      getCanvas.canvasSize = this.cut.canvasSizeNull.size;
    } else {
      const parseSize = JSON.parse(getCanvas.canvasSize);
      if (parseSize.id === 'custom') {
        getCanvas.canvasSize = `${parseSize.w * parseSize.web} x ${parseSize.h * parseSize.web}px`;
      } else {
        getCanvas.canvasSize = parseSize.size;
      }
    }
    this.canvasData = getCanvas;

    // 페이지 썸네일 리스트 get
    for (const page of this.canvasData.pages) {
      if (this.isNew) {
        this.pageTagObjList.push({
          id: page.id,
          pageTags: []
        });
      }
      this.pageBase64List.push(page.base64);
    }

    this.checkList = fill(Array(this.pageBase64List.length), false);

    if (!this.isNew) {
      if (this.checkList[thumbnailOrder] !== undefined) {
        this.index = thumbnailOrder;
        this.checkList[thumbnailOrder] = true;
      } else {
        thumbnailOrder = 0;
        this.index = 0;
        await this.app.showToast('기존 썸네일 페이지가 삭제되었습니다. 재설정해주세요 :)');
      }
      this.modelTemplateInput.isRenewUpdateDate = false;
    } else {
      this.modelTemplateInput.author = getCanvas.owner.userName;
    }

    return thumbnailOrder;
  }

  /**
   * 카테고리 리스트 불러오기
   */
  async getCategoryNameList() {
    // 카테고리 이름 리스트
    const { data } = await this.templateService.getCategoryList().toPromise();
    this.categoryList = data;
  }

  // 이미지 페이지 이동
  movePage(isBack) {
    const pageLength = this.pageBase64List.length;
    if (isBack) {
      if (this.index === 0) {
        this.index = pageLength - 1;
      } else {
        --this.index;
      }
    } else {
      if (pageLength === this.index + 1) {
        this.index = 0;
      } else {
        ++this.index;
      }
    }
  }

  // check function
  checkFunction() {
    let i = 0;
    for (const check of this.checkList) {
      if (this.index !== i) {
        this.checkList[i] = false;
      }
      ++i;
    }
  }

  /**
   * 템플릿 태그 입력
   * @param event 페이지 태그 입력 이벤트
   */
  public async templateTagEnter(event) {
    if (event.target.value !== '') {
      const tagValue = event.target.value;
      let findTag;
      this.templateTagText = tagValue;
      const tagInCategory = [];

      let splitTag = this.cut.inputTagCheck(tagValue, this.templateTags);
      if (!splitTag) {
        await this.app.showToast('중복된 태그 내용입니다. 다른 태그를 넣어주세요');
        return;
      }

      // 카테고리 이름과 동일한 태그 제거
      for (const tag of splitTag) {
        switch (this.language) {
          case LanguageType.ko:
            findTag = this.categoryList.find((category) => category.name_ko === tag);
            break;
          case LanguageType.en:
            findTag = this.categoryList.find((category) => category.name_en === tag);
            break;
          case LanguageType.fr:
            findTag = this.categoryList.find((category) => category.name_fr === tag);
            break;
          case LanguageType.jp:
            findTag = this.categoryList.find((category) => category.name_jp === tag);
            break;
        }

        if (findTag) {
          tagInCategory.push(tag);
        }
      }

      if (tagInCategory.length > 0) {
        splitTag = splitTag.filter((tag) => tagInCategory.find((categoryTag) => categoryTag !== tag));
        this.app.showToast(`카테고리 이름과 동일한 태그를 입력할 수 없습니다. 카테고리 이름 : ${tagInCategory}`, 3000);
      }

      if (this.templateTags.length !== 0) {
        if (splitTag.length === 1) {
          this.templateEnterTag$.next(splitTag[0]);
        } else if (splitTag.length > 1) {
          this.templateTags.push(...splitTag.filter((tag) => this.templateTags.indexOf(tag) === -1));
          this.templateTagText = '';
        }
      } else {
        this.templateTags.push(...splitTag);
        event.target.value = '';
      }

      await this.app.delay(200);
      await this.content.scrollToBottom(500);
    } else {
      await this.app.showToast('입력된 내용이 없습니다.');
    }
  }

  /**
   * 템플릿 태그 삭제
   * @param selectedTag 선택한 태그
   */
  public removeTemplateTag(selectedTag: number) {
    this.templateTags.splice(selectedTag, 1);
  }

  /**
   * 템플릿 카테고리 태그 삭제
   * @param selectedCategory 선택한 카테고리
   * @param type 카테고리 타입 purpose/subject/education
   */
  public removeCategoryTag(selectedCategory, type) {
    if (type === categoryType.recent) {
      this.templateTags = this.templateTags.filter((tag) => tag !== this.findCategoryName(this.recentCategoryIdList[selectedCategory]));
      this.recentCategoryIdList.splice(selectedCategory, 1);
    } else if (type === categoryType.purpose) {
      this.templateTags = this.templateTags.filter((tag) => tag !== this.findCategoryName(this.purposeCategoryIdList[selectedCategory]));
      this.purposeCategoryIdList.splice(selectedCategory, 1);
    } else if (type === categoryType.subject) {
      this.templateTags = this.templateTags.filter((tag) => tag !== this.findCategoryName(this.subjectCategoryIdList[selectedCategory]));
      this.subjectCategoryIdList.splice(selectedCategory, 1);
    } else if (type === categoryType.education) {
      this.templateTags = this.templateTags.filter((tag) => tag !== this.findCategoryName(this.educationCategoryIdList[selectedCategory]));
      this.educationCategoryIdList.splice(selectedCategory, 1);
    } else if (type === categoryType.meme) {
      this.templateTags = this.templateTags.filter((tag) => tag !== this.findCategoryName(this.memeCategoryIdList[selectedCategory]));
      this.memeCategoryIdList.splice(selectedCategory, 1);
      if (this.memeCategoryIdList.length === 0) this.samsungResource = null;
    } else if (type === categoryType.memeResource) {
      this.templateTags = this.templateTags.filter((tag) => tag !== this.findCategoryName(this.memeResourceCategoryIdList[selectedCategory]));
      this.memeResourceCategoryIdList.splice(selectedCategory, 1);
      if (this.memeResourceCategoryIdList.length === 0) this.samsungResource = null;
    }
  }

  /**
   * 템플릿 발행
   */
  async createTemplate() {
    try {
      const checkIndex = this.checkList.findIndex((check) => check === true);
      this.categoryIds = [
        ...this.recentCategoryIdList,
        ...this.subjectCategoryIdList,
        ...this.purposeCategoryIdList,
        ...this.educationCategoryIdList,
        ...this.memeCategoryIdList,
        ...this.memeResourceCategoryIdList
      ];
      let templateId;
      if (checkIndex < 0) {
        await this.app.showToast('대표 이미지를 선택해주세요');
        return;
      }

      if (!this.canvasData.title) {
        await this.app.showToast('제목을 입력하세요');
        return;
      }

      if (this.categoryIds.length <= 0) {
        await this.app.showToast('카테고리를 선택하세요');
        return;
      }

      this.modelTemplateInput.categoryIds = this.categoryIds;
      this.modelTemplateInput.pageId = this.canvasData.pages[checkIndex].id;
      this.modelTemplateInput.language = this.language;
      this.modelTemplateInput.title = this.canvasData.title;
      this.modelTemplateInput.thumbnailOrder = checkIndex;
      this.modelTemplateInput.status = this.status;
      this.modelTemplateInput.memeStatus = this.samsungResource && this.memeStatus;
      this.modelTemplateInput.paid = this.paid;
      this.modelTemplateInput.isGifTemplate = this.samsungResource === categoryType.meme && this.isGifTemplate;
      if (this.isNew) {
        this.modelTemplateInput.canvasId = this.canvasId;

        await this.loading.showLoader('템플릿 생성중,,');
        const { data } = await this.templateService.createTemplate(this.modelTemplateInput);
        templateId = +data.id;
      } else {
        this.modelTemplateInput.templateId = this.templateId;
        await this.loading.showLoader('템플릿 업데이트중,,');
        await this.templateService.updateTemplate(this.modelTemplateInput);

        // 언어 타입 변경 시 태그는 리셋시켜준다.
        if (this.isDifferentPrevLang()) this.tagReset();
        templateId = this.templateId;
      }

      const allCategoryLists = [
        this.recentCategoryIdList,
        this.subjectCategoryIdList,
        this.purposeCategoryIdList,
        this.educationCategoryIdList,
        this.memeCategoryIdList,
        this.memeResourceCategoryIdList
      ];

      for (const categoryList of allCategoryLists) {
        for (const categoryId of categoryList) {
          this.pushCategoryName(categoryId);
        }
      }

      this.templateTags.push(this.canvasData.title);
      this.templateTags = [...uniq(this.templateTags)];
      // 템플릿 태그 생성
      await this.templateService.TemplateTagsCreate(templateId, TemplateType.basic, this.templateTags);
      await this.modalCtrl.dismiss({
        id: this.canvasId,
        isDelete: false,
        status: this.status
      });
      await this.app.showToast('템플릿 생성을 완료했습니다.', 3000, 'dark', 'middle');
    } catch (error) {
      logErrorMessage('createTemplate', error.message, error.stack, this.app.cache.user.userEmail, true);
      const { graphQLErrors, networkError } = error;

      if (graphQLErrors) {
        for (const gError of graphQLErrors) {
          const errorCode = +gError.extensions.exception.code;
          switch (errorCode) {
            case TooningErrorCode.TOONING_SERVER_NOT_EXIST_TEMPLATE_CATEGORY_ERR:
              await this.app.showToast('존재하지 않는 템플릿입니다. 관리자에게 문의해주세요 :)', 3000, 'dark', 'middle');
              break;
            case TooningErrorCode.TOONING_SERVER_NOT_EXIST_CANVAS_ERR:
              await this.app.showToast('존재하지 않는 캔버스입니다. 관리자에게 문의해주세요 :)', 3000, 'dark', 'middle');
              break;
            case TooningErrorCode.TOONING_SERVER_TEMPLATE_TAGS_CREATE_ERR:
              await this.app.showToast('템플릿 태그 생성 중간에 오류가 발생했습니다. 관리자에게 문의해주세요 :)', 3000, 'dark', 'middle');
              break;
            default:
              await this.app.showToast(`템플릿 생성 및 업데이트 실패. 관리자에게 문의해주세요 :)`, 3000, 'dark', 'middle');
              break;
          }
        }
      }

      if (networkError) {
        switch (networkError.status) {
          case HttpStatusCode.BAD_REQUEST:
            await this.app.showToast('잘못된 요청입니다');
            throw new TooningBadRequestError(error.message, this.app);
          case HttpStatusCode.UNAUTHORIZED:
            this.app.orange('unauthorized access');
            this.app.redirectLogin();
            throw new TooningUnauthorizedError(error.message, this.app);
          case 0:
            throw new TooningServerConnectionError(error.message, this.app, true);
          default:
            this.app.redLog(networkError.message);
            await this.app.showToast(error.message);
            throw new TooningUnkownNetworkError(error.message, this.app, true);
        }
      }
    } finally {
      this.loading.hideLoader();
    }
  }

  pushCategoryName(categoryId: number) {
    const findTag = this.templateTags.find((tag) => tag === this.findCategoryName(categoryId));
    if (!findTag) {
      this.templateTags.push(this.findCategoryName(categoryId));
    }
  }

  async slideChanged() {
    this.index = await this.slides.getActiveIndex();
    this.pageBackUpTags = [];
    console.log(this.index);
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  /**
   * 템플릿 카테고리 선택
   * @param type 카테고리 타입 purpose/subject/education
   */
  async presentAlertCheckbox(type) {
    const inputArr = [];
    let isCheck = false;
    let index = 0;
    let idListByType;
    const categoryListByType = this.categoryList.filter((category) => category.categoryType === type);
    switch (type) {
      case categoryType.recent:
        idListByType = this.recentCategoryIdList;
        break;
      case categoryType.purpose:
        idListByType = this.purposeCategoryIdList;
        break;
      case categoryType.subject:
        idListByType = this.subjectCategoryIdList;
        break;
      case categoryType.education:
        idListByType = this.educationCategoryIdList;
        break;
      case categoryType.meme:
        idListByType = this.memeCategoryIdList;
        break;
      case categoryType.memeResource:
        idListByType = this.memeResourceCategoryIdList;
        break;
      default:
        throw new Error('categoryType type error');
    }

    for (const category of categoryListByType) {
      let categoryName;

      isCheck = idListByType.length > 0 && idListByType.includes(category.id);

      categoryName = this.valueByLanguage(this.language, category);
      inputArr.push({
        name: categoryName,
        type: type === categoryType.memeResource ? 'radio' : 'checkbox',
        label: categoryName,
        value: category.id,
        checked: isCheck
      });
      ++index;
    }
    const alert = await this.alertController.create({
      cssClass: 'basic-dialog',
      header: '카테고리 선택',
      inputs: inputArr,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.samsungResource = null;
            console.log('Confirm Cancel');
          }
        },
        {
          text: 'Ok',
          handler: (selectedIds) => {
            switch (type) {
              case categoryType.recent:
                this.recentCategoryIdList = selectedIds;
                break;
              case categoryType.purpose:
                this.purposeCategoryIdList = selectedIds;
                break;
              case categoryType.subject:
                this.subjectCategoryIdList = selectedIds;
                break;
              case categoryType.education:
                this.educationCategoryIdList = selectedIds;
                break;
              case categoryType.meme:
                if (selectedIds.length === 0) {
                  this.samsungResource = null;
                }
                this.memeCategoryIdList = selectedIds;
                break;
              case categoryType.memeResource:
                this.memeResourceCategoryIdList = [];
                if (selectedIds === undefined) {
                  this.samsungResource = null;
                } else {
                  this.memeResourceCategoryIdList.push(selectedIds);
                }
                break;
              default:
                // Handle other cases or provide a default action if needed
                break;
            }
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * 카테고리 이름 검색
   * @param categoryId 찾을 카테고리 이름
   */
  findCategoryName(categoryId) {
    const findCategory = this.categoryList.find((category) => category.id === categoryId);
    return this.valueByLanguage(this.language, findCategory);
  }

  /**
   * 템플릿 삭제 팝업
   */
  async deleteTemplate() {
    const alert = await this.alertController.create({
      cssClass: 'basic-dialog',
      header: '템플릿을 내리시겠습니까?',
      message: '템플릿 만들기 설정 내용이 모두 지워집니다.\n' + '참고 : 템플릿에서 내려도 파일이 삭제되지는 않습니다.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        },
        {
          text: 'Ok',
          handler: () => {
            this.deleteTemplateOk();
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * 템플릿 삭제
   */
  async deleteTemplateOk() {
    try {
      await this.templateService.deleteTemplate(this.templateId);
      await this.app.showToast('템플릿을 성공적으로 내렸습니다.');
      await this.modalCtrl.dismiss({
        id: this.canvasId,
        isDelete: true
      });
    } catch (e) {
      console.error(e);
      await this.app.showToast('템플릿을 삭제하지 못했습니다. 관리자에게 문의하세요 :)');
    }
  }

  /**
   * 태그 목록 텍스트로 복사
   * @param tagList 복사 할 태그 리스트
   */
  async tagCopy(tagList) {
    const getBox = document.getElementById('forTagCapyId');
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = tagList;
    getBox.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    getBox.removeChild(selBox);
    await this.app.showToast('복사가 완료되었습니다.');
  }

  /**
   * 언어체크 해서 해당 언어 값 리턴
   * @param language 사용 언어
   * @param value 체크 할 카테고리
   */
  valueByLanguage(language: string, value) {
    try {
      let returnValue;
      switch (language) {
        case LanguageType.ko:
          returnValue = value.name_ko;
          break;
        case LanguageType.en:
          returnValue = value.name_en;
          break;
        case LanguageType.fr:
          returnValue = value.name_fr;
          break;
        case LanguageType.jp:
          returnValue = value.name_jp;
          break;
      }

      return returnValue;
    } catch (e) {
      throw new Error(`valueByLanguage : ${e}`);
    }
  }

  /**
   * 템플릿 언어 바꼈는지 체크
   */
  isDifferentPrevLang() {
    try {
      return this.language !== this.prevLanguage;
    } catch (e) {
      throw new Error(`isDifferentPrevLang : ${e}`);
    }
  }

  /**
   * 템플릿 태그 리셋
   */
  tagReset() {
    try {
      this.templateTags = [];
    } catch (e) {
      throw new Error(`tagReset : ${e}`);
    }
  }

  /**
   * 삼성 밈 관련 카테고리 선택 함수
   * @return {Promise<void>}
   */
  async samsungSetting(): Promise<void> {
    if (this.isInit) {
      this.isInit = false;
      return;
    }

    if (this.samsungResource === categoryType.meme) {
      await this.presentAlertCheckbox(categoryType.meme);
    } else if (this.samsungResource === categoryType.memeResource) {
      await this.presentAlertCheckbox(categoryType.memeResource);
    }
  }
}
