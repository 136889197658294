<ion-header>
  <ion-toolbar>
    <ion-toolbar>
      <ion-title>
        {{ 'template setting' | translate }}
      </ion-title>
      <ion-buttons class='m-0 mr-2' slot='end'>
        <ion-button (click)='close()'>
          <ion-icon name='close-outline' slot='icon-only'></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-toolbar>
</ion-header>
<ion-content appScrollbarTheme #content>
  <div class='row py-4 px-2' id='forTagCapyId'>
    <div class='col-md-6' *ngIf='readyToRender'>
      <div class='row'>
        <div class='col-12 py-3'>
          <mat-form-field class='w-100'>
            <input matInput maxlength='100' placeholder='제목을 입력하세요' autocomplete='off' [(ngModel)]='canvasData.title' />
          </mat-form-field>
        </div>
        <div class='col-12 text-right'>
          {{ canvasData.canvasSize }}
        </div>
      </div>
      <div class='row'>
        <div class='col-12'>
          <div style='width: 80%; margin: auto'>
            <ion-slides #slides1D [options]='slideOpts' pager='false' (ionSlideDidChange)='slideChanged()'>
              <ion-slide *ngFor='let img of pageBase64List; let index = index'>
                <img alt='template image' src='{{ img }}' style='width: 100%' class='rounded-xl mx-2' />
              </ion-slide>
            </ion-slides>
          </div>
        </div>
        <div class='col-8'>
          <ion-item lines='none' button='false'>
            <ion-label
            ><b>{{ 'Representative thumbnail screen' | translate }}</b></ion-label
            >
            <ion-checkbox slot='start' color='main' (click)='checkFunction()'
                          [(ngModel)]='checkList[index]'></ion-checkbox>
            <div tabindex='0'></div>
          </ion-item>
        </div>
        <div class='col-4'>
          <div class='w-100 font-weight-bolder text-center' style='margin-top: 18px'>{{ index + 1 }}
            / {{ pageBase64List.length }}</div>
          <div class='swiper-button-next' (click)='slides1D.slideNext()'></div>
          <div class='swiper-button-prev' (click)='slides1D.slidePrev()'></div>
        </div>
      </div>
    </div>
    <div class='col-md-6' *ngIf='readyToRender'>
      <div class='ui placeholder segment p-0'>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <div class='col-12'>
              <ion-item>
                <ion-label>언어</ion-label>
                <ion-select interface='alert' [(ngModel)]='language'>
                  <ng-container *ngFor='let languageType of app.languageTypeList'>
                    <ion-select-option [value]='languageType'>{{languageType}}</ion-select-option>
                  </ng-container>
                </ion-select>
                <div tabindex='0'></div>
              </ion-item>
            </div>
          </div>

          <div class='row'>
            <div class='col-12'>
              <ion-item detail lines='full' (click)='presentAlertCheckbox(categoryType.recent)' button='true'>
                <ion-label>최신 카테고리</ion-label>
                <div tabindex='0'></div>
              </ion-item>
            </div>
            <div class='col-md-12 p-3 d-flex flex-wrap'>
              <ion-chip *ngFor='let id of recentCategoryIdList; let i = index'>
                <ion-label>{{ findCategoryName(id) }}</ion-label>
                <ion-icon name='close-circle' (click)='removeCategoryTag(i, categoryType.recent)'></ion-icon>
              </ion-chip>
            </div>
          </div>
          <div class='row'>
            <div class='col-12'>
              <ion-item detail lines='full' (click)='presentAlertCheckbox(categoryType.subject)' button='true'>
                <ion-label>주제별 카테고리</ion-label>
                <div tabindex='0'></div>
              </ion-item>
            </div>
            <div class='col-md-12 p-3 d-flex flex-wrap'>
              <ion-chip *ngFor='let id of subjectCategoryIdList; let i = index'>
                <ion-label>{{ findCategoryName(id) }}</ion-label>
                <ion-icon name='close-circle' (click)='removeCategoryTag(i, categoryType.subject)'></ion-icon>
              </ion-chip>
            </div>
          </div>
          <div class='row'>
            <div class='col-12'>
              <ion-item detail lines='full' (click)='presentAlertCheckbox(categoryType.purpose)' button='true'>
                <ion-label>유형별 카테고리</ion-label>
                <div tabindex='0'></div>
              </ion-item>
            </div>
            <div class='col-md-12 p-3 d-flex flex-wrap'>
              <ion-chip *ngFor='let id of purposeCategoryIdList; let i = index'>
                <ion-label>{{ findCategoryName(id) }}</ion-label>
                <ion-icon name='close-circle' (click)='removeCategoryTag(i, categoryType.purpose)'></ion-icon>
              </ion-chip>
            </div>
          </div>
          <div class='row'>
            <div class='col-12'>
              <ion-item detail lines='full' (click)='presentAlertCheckbox(categoryType.education)' button='true'>
                <ion-label>교과별 카테고리</ion-label>
                <div tabindex='0'></div>
              </ion-item>
            </div>
            <div class='col-md-12 p-3 d-flex flex-wrap'>
              <ion-chip *ngFor='let id of educationCategoryIdList; let i = index'>
                <ion-label>{{ findCategoryName(id) }}</ion-label>
                <ion-icon name='close-circle' (click)='removeCategoryTag(i, categoryType.education)'></ion-icon>
              </ion-chip>
            </div>
          </div>
        </div>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <ion-radio-group allow-empty-selection [(ngModel)]='samsungResource' (ionChange)='samsungSetting()'>
            <ion-item>
              <ion-label>삼성 밈</ion-label>
              <ion-radio slot='end' value='meme'></ion-radio>
            </ion-item>
            <div class='col-md-12 p-3 d-flex flex-wrap' *ngIf='samsungResource === categoryType.meme'>
              <ion-chip *ngFor='let id of memeCategoryIdList; let i = index'>
                <ion-label>{{ findCategoryName(id) }}</ion-label>
                <ion-icon name='close-circle' (click)='removeCategoryTag(i, categoryType.meme)'></ion-icon>
              </ion-chip>
            </div>
            <ion-item>
              <ion-label>삼성 리소스</ion-label>
              <ion-radio slot='end' value='memeResource'></ion-radio>
            </ion-item>
            <div class='col-md-12 p-3 d-flex flex-wrap' *ngIf='samsungResource ===categoryType.memeResource'>
              <ion-chip *ngFor='let id of memeResourceCategoryIdList; let i = index'>
                <ion-label>{{ findCategoryName(id) }}</ion-label>
                <ion-icon name='close-circle' (click)='removeCategoryTag(i, categoryType.memeResource)'></ion-icon>
              </ion-chip>
            </div>

          </ion-radio-group>
        </div>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <div class='col-md-12 p-3' style="font-family: 'Noto Sans', sans-serif; font-size: 14px">
              <b>전체 태그입력</b>
            </div>
          </div>

          <div class='row'>
            <mat-form-field class='w-100 px-2'>
              <input matInput maxlength='100' placeholder='예시)코로나 / 쉼표로 구분, 엔터로 자동입력.' autocomplete='off'
                     (keydown.enter)='templateEnterEvent$.next($event)' [(ngModel)]='templateTagText' />
            </mat-form-field>
            <div class='row w-100'>
              <div class='col-md-11 pt-1 d-flex flex-wrap' style='margin-top: -10px; margin-bottom: 0'>
                <ion-chip *ngFor='let tag of templateTags; let i = index'>
                  <ion-label>{{ tag }}</ion-label>
                  <ion-icon name='close-circle' (click)='removeTemplateTag(i)'></ion-icon>
                </ion-chip>
              </div>
              <div class='col-md-1 pl-1'>
                <button style='background-color: white' (click)='tagCopy(templateTags)'><i
                  class='far fa-copy fa-2x'></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <ion-item id='paid' class='w-100'>
              <ion-label>유료 템플릿</ion-label>
              <ion-toggle slot='end' name='status' [(ngModel)]='paid'></ion-toggle>
            </ion-item>
          </div>
        </div>
        <div *ngIf='samsungResource === categoryType.meme' class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <ion-item id='gifTemplate' class='w-100'>
              <ion-label>GIF 템플릿</ion-label>
              <ion-toggle slot='end' name='gifPublish' [(ngModel)]='isGifTemplate'></ion-toggle>
            </ion-item>
          </div>
        </div>
        <div class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <ion-item id='status' class='w-100'>
              <ion-label>투닝 발행</ion-label>
              <ion-toggle slot='end' name='status' [(ngModel)]='status'></ion-toggle>
            </ion-item>
          </div>
        </div>
        <div *ngIf='samsungResource' class='m-2 p-2' style='background-color: white; border-radius: 3%'>
          <div class='row'>
            <ion-item id='meme-status' class='w-100'>
              <ion-label>삼성 발행</ion-label>
              <ion-toggle slot='end' name='meme-status' [(ngModel)]='memeStatus'></ion-toggle>
            </ion-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer *ngIf='readyToRender' class='d-flex justify-content-between'
><!---->
  <div>
    <ion-button class='ml-2' color='medium' mode='ios' (click)='deleteTemplate()' *ngIf='!isNew' >
      {{ 'template unload' | translate }}
    </ion-button>
  </div>
  <div class='d-flex'>
    <ion-item lines='none' button='false' *ngIf='!isNew'>
      <ion-label style='margin-bottom: 12px'>{{ 'Pulling up' | translate }}</ion-label>
      <ion-checkbox slot='start' color='main' [(ngModel)]='modelTemplateInput.isRenewUpdateDate'></ion-checkbox>
      <div tabindex='0'></div>
    </ion-item>
    <ion-button class='mr-2' color='main' mode='ios' (click)='createTemplate()' >
      {{ 'complete' | translate }}
    </ion-button>
  </div>
</ion-footer>
