import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, Length } from 'class-validator';

export class Card {
  @IsNumber() @IsNotEmpty() userId: number;
  @IsNumber() @IsOptional() payEventId?: number;
  @IsNumber() @IsOptional() payPlanId?: number;
  @IsNumber() @IsOptional() id?: number;
  subscriber: any;
  createdDate: Date;
  @IsString()
  @IsNotEmpty()
  @Length(14, 19, {
    message: 'at least 14 digits'
  })
  number: string;

  @IsString()
  @IsNotEmpty()
  @Length(2, 2, {
    message: 'please 2 digit'
  })
  password: string;

  @IsString()
  @IsNotEmpty()
  @Length(2, 2, {
    message: 'expiration year 2 digits'
  })
  expirationYear: string;

  @IsString()
  @IsNotEmpty()
  @Length(2, 2, {
    message: 'expiration month 2 digits'
  })
  expirationMonth: string;

  @IsString()
  @IsNotEmpty()
  @Length(6, 10, {
    message: 'yyddmm format'
  })
  ownerBirth: string;

  @IsBoolean() @IsNotEmpty() default: boolean;
  '__typename': string;

  deserialize(input: any) {
    // 카드번호는 뒷 네자리만 표시
    const last4Digits = input.number.slice(-4);
    const maskedNumber = last4Digits.padStart(input.number.length, '*');
    input.number = maskedNumber;

    Object.assign(this, input);
    delete this.__typename;
    return this;
  }
}
