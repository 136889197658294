import { Command } from './command';
import { JsonChangeCommandData } from '../../interfaces/app.interface';

/**
 * Class JsonChangeCommand
 * @extends Command
 */
class JsonChangeCommand extends Command {
  protected beforeDataLessJsonString;
  protected afterDataLessJsonString;

  /**
   * Create JsonChangeCommand
   * @param {JsonChangeCommandData} data
   */
  constructor(data: JsonChangeCommandData) {
    super(data);
    this.beforeDataLessJsonString = data.beforeDataLessJsonString;
    this.afterDataLessJsonString = data.afterDataLessJsonString;
    this.commandName = 'json-Change-Command';
  }

  /**
   * ttt 작동 시 canvas를 통째로 toDatalessJSON()을 통해 beforeDataLessJsonString과 afterDataLessJsonString에 저장
   * @returns {Promise<void>}
   */
  async execute() {
    super.execute();
  }

  /**
   * redo시 저장된 afterDataLessJsonString을 loadFromJSON()을 통해 다시 불러옴
   * @returns {Promise<void>}
   */
  async redo() {
    try {
      super.redo();
      await this.cut.loadFromJSON(this.afterDataLessJsonString);
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * undo시 저장된 beforeDataLessJsonString을 loadFromJSON()을 통해 다시 불러옴
   * @returns {Promise<void>}
   */
  async undo() {
    try {
      super.undo();
      await this.cut.loadFromJSON(this.beforeDataLessJsonString);
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { JsonChangeCommand };
