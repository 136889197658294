import { Command } from './command';
import { PageChangeCommandData } from '../../interfaces/app.interface';

/**
 * Class PageChangeCommand
 * @extends Command
 */
class PageChangeCommand extends Command {
  public beforeIndex: number;
  public afterIndex: number;
  protected event: Event;
  protected callback;

  /**
   * Create PageChangeCommand
   * @param {PageChangeCommandData} data
   */
  constructor(data: PageChangeCommandData) {
    super(data);
    this.beforeIndex = data.beforeIndex;
    this.afterIndex = data.afterIndex;
    this.event = data.event;
    this.callback = data.callback;
    this.commandName = 'Page-Change-Command';
  }

  /**
   * Execute PageChangeCommand
   */
  async execute() {
    super.execute();
    try {
      // page change 시 updatePageSync로 하면 toDataLessJson + network 시간 까지 되어서 너무 느리다
      // updatePage 는 toDataLessJson 이후에는 update 는 browser main thread 가 여유가 있을때 시도하여 page change 가 빠르다.
      // 하지만 불안핟...싸늘하다...
      await this.cut.updatePage(this.beforeIndex);
      await this.callback(this.afterIndex, this.event);
      this.cut.setShowPreviewCanvas();
    } catch (e) {
      throw new Error(`execute error in page-change-command.ts : ${e.message}`);
    }
  }

  /**
   * Redo PageChangeCommand
   */
  async redo() {
    try {
      super.redo();
      this.cut.isRedoing = true;
      this.cut.setKeyActivation = false;
      await this.callback(this.afterIndex, this.event);
      this.cut.setKeyActivation = true;
      this.cut.pageList[this.afterIndex].isChanged = true;
      this.cut.setShowPreviewCanvas();
      this.cut.isRedoing = false;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.cut.setKeyActivation = true;
      this.cut.isPageChangeCommandRedoing = false;
    }
  }

  /**
   * Undo PageChangeCommand
   */
  async undo() {
    try {
      super.undo();
      this.cut.isUndoing = true;
      this.cut.setKeyActivation = false;
      await this.callback(this.beforeIndex, this.event);
      this.cut.setKeyActivation = true;
      if (this.cut.pageList.some((page) => page.isChecked)) {
        this.cut.pageList[this.beforeIndex].isChanged = true;
      } else {
        throw new Error('selectedPanelList is empty');
      }
      this.cut.setShowPreviewCanvas();
      this.cut.isUndoing = false;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.cut.setKeyActivation = true;
      this.cut.isPageChangeCommandUndoing = false;
    }
  }
}

export { PageChangeCommand };
