import { Component, Input } from '@angular/core';
import { MagicApi } from '../../../../interfaces/app.interface';
import { AppService } from '../../../../services/app.service';
import { Cut4MakeManualService } from '../../../cut4-make-manual2/cut4-make-manual.service';
import { LanguageType } from '../../../../enum/app.enum';

@Component({
  selector: 'app-style-information',
  templateUrl: './style-information.component.html',
  styleUrls: ['./style-information.component.scss']
})
export class StyleInformationComponent {
  @Input('data') data: MagicApi;
  public languageType = LanguageType;

  constructor(public app: AppService, public cut: Cut4MakeManualService) {}

  /**
   * 팝오버 닫기
   * @return {void}
   */
  close(): void {
    this.app.popover.dismiss();
  }
}
