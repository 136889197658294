import { Command } from './command';
import { ObjectAddCommandData } from '../../interfaces/app.interface';
import { ResourceType } from '../../enum/app.enum';

/**
 * Class ObjectAddMultiCommand
 * @extends Command
 */
class ObjectAddMultiCommand extends Command {
  protected callback;
  protected cut;
  protected addObList;
  protected idList;
  protected dataLessObjectString;

  /**
   * Create ObjectAddMultiCommand
   * @param {ObjectAddCommandData} data
   */
  constructor(data: ObjectAddCommandData) {
    super(data);
    this.commandName = 'Object-Add-Multi-Command';
    this.callback = data.callback;
  }

  /**
   * Execute ObjectAddMultiCommand
   * @return {Promise<any>}
   */
  async execute(): Promise<any> {
    try {
      super.execute();
      this.idList = [];
      this.addObList = await this.callback();
      for (const element of this.addObList) {
        this.idList.push(element.resource_selection_id);
      }
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo ObjectAddMultiCommand
   * @return {Promise<any>}
   */
  async redo(): Promise<any> {
    try {
      let redoObjectParentIdArray = [];
      super.redo();

      for (const element of this.addObList) {
        const object = element;
        object.resource_type === ResourceType.characterSvg && (this.cut.characterLengthOnPage += 1);
        redoObjectParentIdArray.push(object.parentId);
        const dataLessObjectString = JSON.stringify(object.toDatalessObject());
        await this.cut.addObFromJson([JSON.parse(dataLessObjectString)], false, false, true, null);
      }

      // redo object들이 복사, 붙여넣기한 리소스인지 확인
      if (this.cut.checkIsCopyResource(redoObjectParentIdArray)) {
        this.cut.setAddPosition();
      }

      this.cut.forceNoFocus();
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo ObjectAddMultiCommand
   * @param objects multi add undo할 objects
   * @return {Promise<any>}
   */
  async undo(objects): Promise<any> {
    try {
      let undoObjectParentIdArray = [];
      super.undo(objects);
      for (const element of objects) {
        for (let k = 0; k < this.idList.length; k++) {
          if (element.resource_selection_id === this.idList[k]) {
            this.cut.canvas.remove(element);
            element.resource_type === ResourceType.characterSvg && (this.cut.characterLengthOnPage -= 1);
            undoObjectParentIdArray.push(element.parentId);
          }
        }
      }

      // undo object들이 복사, 붙여넣기한 리소스인지 확인
      if (this.cut.checkIsCopyResource(undoObjectParentIdArray)) {
        this.cut.setDeletePosition();
      }

      this.cut.forceNoFocus();
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { ObjectAddMultiCommand };
