import { ObjectScaleCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { ObjectMoveCommand } from './object-move-command';
import { fabric } from 'fabric';
import { ResourceType, SelectedType } from '../../enum/app.enum';

/**
 * Class ObjectScaleCommand
 * @extends ObjectMoveCommand
 */
class ObjectScaleCommand extends ObjectMoveCommand {
  protected readonly beforeScaleX: number;
  protected readonly beforeScaleY: number;
  protected readonly beforeWidth: number;
  protected readonly afterScaleX: number;
  protected readonly afterScaleY: number;
  protected readonly afterWidth: number;
  protected readonly beforeFlipX: boolean;
  protected readonly afterFlipX: boolean;
  protected readonly beforeFlipY: boolean;
  protected readonly afterFlipY: boolean;
  protected readonly data;

  /**
   * Create ObjectScaleCommand
   * @param {ObjectScaleCommandData} data
   */
  constructor(data: ObjectScaleCommandData) {
    super(data);
    this.beforeScaleX = data.beforeScaleX;
    this.beforeScaleY = data.beforeScaleY;
    this.beforeWidth = data.beforeWidth;
    this.afterScaleX = data.afterScaleX;
    this.afterScaleY = data.afterScaleY;
    this.afterWidth = data.afterWidth;
    this.beforeFlipX = data.beforeFlipX;
    this.afterFlipX = data.afterFlipX;
    this.beforeFlipY = data.beforeFlipY;
    this.afterFlipY = data.afterFlipY;
    this.commandName = 'Object-Scale-Command';
  }

  /**
   * Execute ObjectScaleCommand
   */
  execute() {
    try {
      super.execute();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo ObjectScaleCommand
   * @param {TooningFabricObject[]} objects
   * @return {void}
   */
  redo(objects: TooningFabricObject[]): void {
    try {
      super.redo(objects);
      if (this.type === SelectedType.activeSelection) {
        // 여러개 선택
        const selectionList = [];
        objects.forEach((object) => {
          for (const element of this.id) {
            if (object.resource_selection_id === element) {
              selectionList.push(object);
            }
          }
        });
        this.cut.canvas.discardActiveObject();
        const selectedObjects = new fabric.ActiveSelection(selectionList, {
          canvas: this.cut.canvas
        });
        selectedObjects.set({
          scaleX: (selectedObjects.scaleX * this.afterScaleX) / this.beforeScaleX,
          scaleY: (selectedObjects.scaleY * this.afterScaleY) / this.beforeScaleY,
          flipX: this.afterFlipX,
          flipY: this.afterFlipY
        });
        this.cut.canvas.setActiveObject(selectedObjects);
        selectedObjects.setCoords();
      } else {
        objects.forEach((object) => {
          if (object.resource_selection_id === this.id[0]) {
            // 텍스트의 경우 가로로 스케일 조정할 때 width값 전달
            if (object.resource_type === ResourceType.text) {
              object.set({
                width: this.afterWidth
              });
            }
            object.set({
              scaleX: this.afterScaleX,
              scaleY: this.afterScaleY
            });
            object.set({
              flipX: this.afterFlipX,
              flipY: this.afterFlipY
            });
            object.setCoords();
          } else {
            console.log('object move command 타겟이 없습니다.');
          }
        });
      }
      this.cut.setPositionBoxButton();
      this.cut.forceNoFocus();
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo ObjectScaleCommand
   * @param {TooningFabricObject[]} objects
   * @return {void}
   */
  undo(objects: TooningFabricObject[]): void {
    try {
      super.undo(objects);
      if (this.type === SelectedType.activeSelection) {
        // 여러개 선택
        const selectionList = [];
        objects.forEach((object) => {
          for (const element of this.id) {
            if (object.resource_selection_id === element) {
              selectionList.push(object);
            }
          }
        });
        this.cut.canvas.discardActiveObject();
        const selectedObjects = new fabric.ActiveSelection(selectionList, {
          canvas: this.cut.canvas
        });
        // @ts-ignore
        selectedObjects.set({
          scaleX: (selectedObjects.scaleX / this.afterScaleX) * this.beforeScaleX,
          scaleY: (selectedObjects.scaleY / this.afterScaleY) * this.beforeScaleY,
          flipX: this.afterFlipX,
          flipY: this.afterFlipY
        });
        this.cut.canvas.setActiveObject(selectedObjects);
        selectedObjects.setCoords();
      } else {
        objects.forEach((object) => {
          if (object.resource_selection_id === this.id[0]) {
            // 텍스트의 경우 가로로 스케일 조정할 때 width값 전달
            if (object.resource_type === ResourceType.text) {
              object.set({
                width: this.beforeWidth
              });
            }
            object.set({
              scaleX: this.beforeScaleX,
              scaleY: this.beforeScaleY
            });
            object.set({
              flipX: this.beforeFlipX,
              flipY: this.beforeFlipY
            });
            this.cut.setWaterMarkResize(object, object.scaleX, object.scaleY);
            object.setCoords();
          } else {
            console.log('object move command 타겟이 없습니다.');
          }
        });
      }

      this.cut.setPositionBoxButton();
      this.cut.forceNoFocus();
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { ObjectScaleCommand };
