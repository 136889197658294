import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import HttpStatusCode from '../pages-tooning/enums/httpErrors.enum';
import { ErrorHandlerService } from '../services/error/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    let userId = '';

    if (token) {
      const temp = localStorage.getItem('user');
      if (temp) {
        const user = JSON.parse(temp);
        userId = user.id.toString();
      }
      request = request.clone({
        setHeaders: {
          token,
          userId
        }
      });
    }

    // 보드 유저 정보 세팅
    const boardUser = localStorage.getItem('boardUser');
    if (boardUser) {
      let boardUserId = '';
      const boardUserObj = JSON.parse(boardUser);
      if (boardUserObj && boardUserObj.hasOwnProperty('boardUserId')) {
        boardUserId = boardUserObj.boardUserId.toString();
      }
      request = request.clone({
        headers: request.headers.set('board_user_id', boardUserId)
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        try {
          switch (+error.status) {
            //  위 3가지는 네트워크 상태가 문제가 없는 상태
            case HttpStatusCode.BAD_REQUEST: // 이 응답은 잘못된 문법으로 인하여 서버가 요청을 이해할 수 없음을 의미합니다.
            case HttpStatusCode.UNAUTHORIZED: // 비록 HTTP 표준에서는 "미승인(unauthorized)"를 명확히 하고 있지만, 의미상 이 응답은 "비인증(unauthenticated)"을 의미합니다. 클라이언트는 요청한 응답을 받기 위해서는 반드시 스스로를 인증해야 합니다.
            case HttpStatusCode.INTERNAL_SERVER_ERROR:
              ErrorHandlerService.tokenInterceptorErrorHandler(error, userId, token);
              break;
            case HttpStatusCode.UNKNOWN:
              break;
            default:
              console.error(error);
              break;
          }
          return throwError(error);
        } catch (e) {
          return throwError(e);
        }
      })
    );
  }
}
