import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../../../services/google/analytics/analytics.service';

@Component({
  selector: 'app-landing-main-popup',
  templateUrl: './landing-main-popup.component.html',
  styleUrls: ['./landing-main-popup.component.scss']
})
export class LandingMainPopupComponent implements OnInit {
  public isVimeoDelayPlay: boolean;
  constructor(public app: AppService, public modalCtrl: ModalController, private analyticsService: AnalyticsService) {}

  /**
   * 채널톡 버튼 채크 하고 팝업 처리
   */
  ngOnInit(): void {
    if (this.app.isChannelButton) {
      this.app.channelTalkHide();
    }
    this.isVimeoDelayPlay = false;
    setTimeout(() => {
      this.isVimeoDelayPlay = true;
    }, 1500);
  }

  /**
   * 팝업 닫기 쿠키 처리
   * @param setCookie
   * @returns {Promise<void>}
   */
  async close(setCookie) {
    try {
      if (setCookie) {
        this.app.setCookie('mainPopup', 'true', { 'max-age': 60 * 60 * 24 * 7 });
      }
      this.analyticsService.landingPopUpClose(setCookie);
      await this.modalCtrl.dismiss();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 자세히 보기 이동 버튼 링크 처리
   * @returns {Promise<void>}
   */
  async goPage() {
    try {
      this.app.goExternal(this.app.mainPopup[this.app.usedLanguage].link);
      this.analyticsService.landingPopUpMore();
      await this.modalCtrl.dismiss();
    } catch (e) {
      console.error(e);
    }
  }
}
