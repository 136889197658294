import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

export enum TooltipPosition {
  Left = 'Left',
  Right = 'Right',
  Top = 'Top',
  Bottom = 'Bottom'
}

/**
 * 1. stlye custom => tooning-tooltip.directive.scss에 관련 클래스 설정
 * 2. addClass() 함수 사용
 */
@Directive({
  selector: '[tooningTooltip]'
})
export class TooningTooltipDirective {
  @Input('tooningTooltip') tooltipHtml: string;
  @Input() position = TooltipPosition.Bottom;
  @Input() tailSize: string = '8';

  private tooltipElement: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltipElement) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltipElement) {
      this.hideTooltip();
    }
  }

  /**
   * 툴팁 만들어주는 함수
   * @private
   */
  private showTooltip(): void {
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.setProperty(this.tooltipElement, 'innerHTML', this.tooltipHtml);
    this.renderer.addClass(this.tooltipElement, 'tooning-tooltip');
    this.renderer.appendChild(document.body, this.tooltipElement);
    this.applyPosition(this.tooltipElement);
    this.setArrowPosition(this.position);
    this.setTailSize();
  }

  /**
   * 툴팁 지워주는 함수
   * @private
   */
  private hideTooltip(): void {
    this.renderer.removeChild(document.body, this.tooltipElement);
    this.tooltipElement = null;
  }

  /**
   * document.body에 추가해서 요소의 절대 위치를 계산해서 세팅해주는 함수
   * @param {HTMLElement} element
   * @private
   */
  private applyPosition(element: HTMLElement): void {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const scrollTop = document.documentElement.scrollTop;
    const scrollLeft = document.documentElement.scrollLeft;
    //tooltip과 요소의 거리
    const margin = 12;

    const top = rect.top + scrollTop + rect.height + margin;
    const left = rect.left + scrollLeft + rect.width / 2 - element.offsetWidth / 2;
    this.renderer.setStyle(element, 'top', `${top}px`);
    this.renderer.setStyle(element, 'left', `${left}px`);
  }

  /**
   * tooltip위치 설정, 관련 css는 tooning-tooltip.directive.scss에서 설정
   * @param {TooltipPosition} position
   * @private
   */
  private setArrowPosition(position: TooltipPosition): void {
    // 화살표 위치와 색상 설정
    switch (position) {
      case TooltipPosition.Top:
        this.renderer.addClass(this.tooltipElement, 'top');
        break;
      case TooltipPosition.Bottom:
        this.renderer.addClass(this.tooltipElement, 'bottom');
        break;
      case TooltipPosition.Left:
        this.renderer.addClass(this.tooltipElement, 'left');
        break;
      case TooltipPosition.Right:
        this.renderer.addClass(this.tooltipElement, 'right');
        break;
    }
  }

  /**
   * tooltip size 설정, 관련 css는 tooning-tooltip.directive.scss에서 설정
   * @param {TooltipPosition} position
   * @private
   */
  private setTailSize(): void {
    switch (this.tailSize) {
      case '8':
        this.renderer.addClass(this.tooltipElement, 'size-8');
    }
  }
}
