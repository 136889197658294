import { Command } from './command';
import { ObjectIndexChangeSetCommandData, TooningFabricObject } from '../../interfaces/app.interface';

/**
 * Class ObjectIndexChangeSetCommand
 * @extends Command
 */
class ObjectIndexChangeSetCommand extends Command {
  protected beforeIdList: string[];
  protected afterIdList: string[];

  /**
   * Create ObjectIndexChangeSetCommand
   * @param {ObjectIndexChangeSetCommandData} data
   */
  constructor(data: ObjectIndexChangeSetCommandData) {
    super(data);
    this.beforeIdList = data.beforeIdList;
    this.afterIdList = data.afterIdList;
    this.commandName = 'Object-Index-Change-Set-Command';
  }

  async execute() {
    super.execute();
  }

  /**
   * Redo ObjectIndexChangeSetCommand
   * @param {TooningFabricObject[]} objects
   */
  async redo(objects: TooningFabricObject[]) {
    try {
      super.redo(objects);
      this.indexChange(objects, this.afterIdList);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo ObjectIndexChangeSetCommand
   * @param {TooningFabricObject[]} objects
   */
  async undo(objects: TooningFabricObject[]) {
    try {
      super.undo(objects);
      this.indexChange(objects, this.beforeIdList);
    } catch (e) {
      throw new Error(e);
    }
  }

  indexChange(objects, idList) {
    for (const element of objects) {
      const object = element;
      for (let k = 0; k < this.beforeIdList.length; k++) {
        if (object.resource_selection_id === idList[k]) {
          object.moveTo(k);
        }
      }
    }
    this.updatePageThumbnail();
  }
}

export { ObjectIndexChangeSetCommand };
