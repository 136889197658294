<div #notificationAlert class='notification'>
  <!--  <ion-slide *ngIf='app.isCountDownNotify && !app.isSessionClose && secondsToDday && minutesToDday && hoursToDday'>-->
  <!--    <div class='align-items-center'>-->
  <!--      <div class='d-flex w-100 h-100 justify-content-center align-items-center'>-->
  <!--        <div class='notification-text'>-->
  <!--          {{'notification.update.title' | translate}} {{'notification.update.context-1' | translate}}-->
  <!--          <b class='text-color'> {{hoursToDday}} : {{minutesToDday}}: {{secondsToDday}}</b>-->
  <!--          {{'notification.update.context-2' | translate}}-->
  <!--        </div>-->
  <!--        <div (click)='notificationClose()' class='notification-close'>-->
  <!--          <img style='cursor: pointer;' src='/assets/landing-v2/close-button.png'>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--  </ion-slide>-->
  <!--<div #notificationAlert class='notification'-->
  <!--     *ngIf='app.isMaintenanceNotify && !app.isSessionClose && localizedSchedule'>-->
  <!--  <div class='align-items-center h-100'>-->
  <!--    <div class='d-flex w-100 h-100 justify-content-center align-items-center'>-->
  <!--      <div class='notification-text'>-->
  <!--        {{'notification.maintenanceUpdate.title' | translate}} {{'notification.maintenanceUpdate.context-1' | translate}}-->
  <!--        <b class='text-color'> {{localizedSchedule}}</b>{{'notification.maintenanceUpdate.context-2' | translate}}-->
  <!--      </div>-->
  <!--      <div (click)='notificationClose()' class='notification-close'>-->
  <!--        <img style='cursor: pointer;' src='/assets/landing-v2/close-button.png'>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->

  <ion-slide *ngIf='app.isNotify && !app.isSessionClose'>
    <div class='align-items-center'>
      <div class='d-flex w-100 h-100 justify-content-center align-items-center'>
        <div (click)='routeTo()' class='d-flex align-items-center' style='cursor: pointer'>
          <div class='notification-text' style='white-space: nowrap'>
            <b>{{'notification.noti.title' | translate }}</b>{{ 'notification.noti.context-1' | translate }}
          </div>
          <img src='/assets/landing-v2/chevron-forward-circle-1.png' class='notification-forward'>
        </div>
        <div (click)='notificationClose()' class='notification-close'>
          <img style='cursor: pointer;' src='/assets/landing-v2/close-button.png'>
        </div>
      </div>
    </div>
  </ion-slide>

</div>