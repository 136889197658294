import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FaceEmotionString'
})
export class FaceEmotionStringPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    let converted = '';
    switch (value) {
      case 'neutral':
        converted = '잘봤어요';
        break;
      case 'angry':
        converted = '화나요';
        break;
      case 'disgust':
        converted = '싫어요';
        break;
      case 'joy':
        converted = '좋아요';
        break;
      case 'sad':
        converted = '슬퍼요';
        break;
      case 'scare':
        converted = '무서워요';
        break;
      case 'surprise':
        converted = '놀라워요';
        break;
      default:
        converted = '잘봤어요';
    }
    return converted;
  }
}
