import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TooningAnalyticsServiceError } from '../../../pages-tooning/errors/TooningErrors';
import { CommandType } from '../../../enum/app.enum';

let host = window.location.host;

let isLocalhost = false;

if (!['tooning.io', 'dev-app.tooning.io'].includes(host)) {
  isLocalhost = true;
}

// localhost에서는 gtag를 무시
if (isLocalhost) {
  // @ts-ignore
  window.gtag = function () {};
}

declare let gtag: Function; // firebase project id : UA-177665980-1

@Injectable({
  providedIn: 'root'
})
// gtag 이벤트 리스트 아래 참조
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export class AnalyticsService {
  public platform;
  private analyticsEnabled = true;
  private isInit = false;
  private oauthList = {
    kakao: 'kakao',
    google: 'google'
  };
  private subscriptions = [];

  constructor(private router: Router, private plt: Platform) {
    try {
      this.platform = this.getPlatform();
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  public get loginMethod() {
    return this.oauthList;
  }

  resetSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  setProperty() {
    try {
      gtag('event', 'setUserProperty', {
        event_label: 'angular'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  // gtag.js 이벤트 트래킹
  // engagement 관련
  getNotification() {
    try {
      gtag('event', 'getNotification', {
        event_category: 'notification',
        event_label: 'get'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  confirmNotification() {
    try {
      gtag('event', 'confirmNotification', {
        event_category: 'notification',
        event_label: 'confirm'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 저작 툴 내에서 링크 공유 누른 후 공유링크 복사 클릭 시 카운트
   * @return {void}
   */
  shareTool(): void {
    try {
      gtag('event', 'link-share', {
        event_label: 'link-share-tool'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 나의 작업에서 링크 공유 누른 후 공유링크 복사 클릭 시 카운트
   * @return {void}
   */
  shareSite(): void {
    try {
      gtag('event', 'link-share', {
        event_label: 'link-share-site'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 저작 툴 내에서 링크 공유 누른 후 공유설정 -> 복제가능 or 읽기전용 클릭 시 카운트
   * @param {string} user 유저의 구독 유무와 기능
   * @return {void}
   */
  shareSettingTool(user: string): void {
    try {
      gtag('event', 'share-Setting-Tool', {
        event_label: user
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 나의 작업에서 링크 공유 누른 후 공유설정 -> 복제가능 or 읽기전용 클릭 시 카운트
   * @param {string} user 유저의 구독 유무와 기능
   * @return {void}
   */
  shareSettingSite(user: string): void {
    try {
      gtag('event', 'share-Setting-site', {
        event_label: user
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 저작 툴 내에서 링크 공유 누른 후 instagram personal 클릭 시 카운트
   * @return {void}
   */
  shareInstagramTool(): void {
    try {
      gtag('event', 'share-Instagram', {
        event_label: 'instagram personal'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  snsShare(shareType: string) {
    try {
      gtag('event', 'snsShare', {
        event_label: shareType
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  signUp(method) {
    try {
      gtag('event', 'sign_up', {
        event_label: method
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  userUpdate(loginType) {
    try {
      gtag('event', 'update', {
        event_label: loginType
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  sentimentAI(text) {
    try {
      gtag('event', 'sentiment', {
        event_category: 'AI',
        event_label: text
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  shotToCharacterAI() {
    try {
      gtag('event', 'shotToCharacter', {
        event_category: 'AI'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  signUpPlatform(platform) {
    try {
      gtag('event', 'sign_up_platform', {
        event_category: 'engagement',
        event_label: platform
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 사용자가 로그인 시 로그인 방법, 아이디 트래킹
   * @param {string} method
   * @param {number} id
   */
  logIn(method: string, id: number) {
    try {
      gtag('event', 'login', {
        event_category: 'method',
        event_label: method
      });
      gtag('event', 'login', {
        event_category: 'id',
        event_label: id
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 무료체험하기 유저 로그인
   * @param {string} method
   * @return void
   */
  freeTrial(method: string): void {
    try {
      gtag('event', 'freeTrial', {
        event_label: method
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 베타 유저 로그인
   * @param {string} method
   * @return void
   */
  betaLogIn(method): void {
    try {
      gtag('event', 'betaLogin', {
        event_label: method
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 학생 유저 로그인
   * @param {string} method
   * @return void
   */
  studentLogIn(method): void {
    try {
      gtag('event', 'studentLogIn', {
        event_label: method
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 선생님 유저 로그인
   * @param {string} method
   * @return void
   */
  teacherLogIn(method): void {
    try {
      gtag('event', 'teacherLogIn', {
        event_label: method
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 기업 유저 로그인
   * @param {string} method
   * @return void
   */
  enterpriseLogIn(method): void {
    try {
      gtag('event', 'enterpriseLogIn', {
        event_label: method
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 일반 유저 로그인
   * @param {string} method
   * @return void
   */
  generalUserLogIn(method: string): void {
    try {
      gtag('event', 'generalUserLogIn', {
        event_label: method
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   *  TemplateList 사이트에서 템플릿 선택 후 편집 누를 경우 카운트
   * @param {string} info
   * @return void
   */
  templateSiteSelect(info: string): void {
    try {
      gtag('event', 'templateSiteSelect', {
        event_label: info
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   *  편집툴 내에서 템플릿 선택하였을 때 카운트
   * @param {string} info
   * @return void
   */
  templateToolSelect(info: string): void {
    try {
      gtag('event', 'templateToolSelect', {
        event_label: info
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   *  편집툴 내에서 템플릿 선택 후 모든 페이지 적용이 아닌 하나만 클릭 시 카운트
   * @param {string} name
   * @return void
   */
  templateToolApplyOneSelect(name: string): void {
    try {
      gtag('event', 'templateTool-ApplyOne-Select', {
        event_label: name
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   *  편집툴 내에서 템플릿 선택 후 모든 페이지 적용 클릭 시 카운트
   * @param {string} name
   * @return void
   */
  templateToolApplyAllSelect(name: string): void {
    try {
      gtag('event', 'templateTool-ApplyAll-Select', {
        event_label: name
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  templateLogIn(method) {
    try {
      gtag('event', 'templateLogIn', {
        event_label: method
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  changeLanguage(language) {
    try {
      gtag('event', 'language', {
        to_language: language
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  logOut() {
    try {
      gtag('event', 'logout', {
        event_label: 'logout menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  download(format) {
    try {
      gtag('event', 'download', {
        event_category: 'content',
        event_label: format
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  // 아이템 관련
  search(category, term) {
    try {
      gtag('event', 'search', {
        event_category: category,
        event_label: term
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  viewItemList(contentType) {
    try {
      gtag('event', 'view_item_list', {
        event_label: contentType
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  viewItem(category, name) {
    try {
      category = category === '말풍선' ? 'bubble' : category;
      category = category === '소품' ? 'item' : category;
      category = category === '효과' ? 'effect' : category;
      category = category === '배경' ? 'background' : category;

      gtag('event', 'view_item', {
        event_category: category,
        event_label: name
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  // 메뉴 관련

  editor() {
    try {
      gtag('event', 'editor', {
        event_category: 'menu',
        event_label: 'editor menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  community() {
    try {
      gtag('event', 'community', {
        event_category: 'menu',
        event_label: 'community menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  general() {
    try {
      gtag('event', 'general', {
        event_category: 'menu',
        event_label: 'general menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  help() {
    try {
      gtag('event', 'help', {
        event_category: 'menu',
        event_label: 'help menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  copyRight() {
    try {
      gtag('event', 'copyRight', {
        event_category: 'menu',
        event_label: 'copyRight menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  privacyPolicy() {
    try {
      gtag('event', 'privacyPolicy', {
        event_category: 'menu',
        event_label: 'privacyPolicy menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  termsAndUse() {
    try {
      gtag('event', 'termsAndUse', {
        event_category: 'menu',
        event_label: 'termsAndUse menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  accountSetting() {
    try {
      gtag('event', 'accountSetting', {
        event_category: 'menu',
        event_label: 'account setting menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  admin() {
    try {
      gtag('event', 'admin', {
        event_category: 'menu',
        event_label: 'admin menu'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  // error 관련
  error(category, message) {
    try {
      gtag('event', 'error', {
        event_category: category,
        event_label: message
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  // platform 관련

  web() {
    try {
      gtag('event', 'web', {
        event_category: 'platform',
        event_label: 'web'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  browser(browser) {
    try {
      gtag('event', 'web', {
        event_category: 'platform',
        event_label: browser
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  // 결제 관련
  beginCheckout() {
    try {
      gtag('event', 'begin_checkout', {
        event_label: 'begin checkout'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  checkoutProgress() {
    try {
      gtag('event', 'checkout_progress', {
        event_label: 'checkout progress checkout'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  subscription(cardId, userId) {
    try {
      gtag('event', 'pro-subscription', {
        transaction_id: userId,
        event_category: 'pro-subscription',
        event_label: 'pro-subscription',
        value: 12000
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  pointCardRegistry(cardId, userId) {
    try {
      gtag('event', 'point-card-purchase', {
        transaction_id: cardId,
        affiliation: 'tooning web',
        items: [
          {
            id: 'pointCardRegistry',
            name: 'Tooning point',
            brand: 'Tooning',
            userId,
            category: 'point'
          }
        ]
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  unSubscription(cardId) {
    try {
      gtag('event', 'refund', {
        transaction_id: cardId,
        event_category: 'unSubscription',
        event_label: cardId
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  formatConvert(type) {
    try {
      gtag('event', 'formatConvert', {
        event_label: type
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  pointUseStart(userId) {
    try {
      gtag('event', 'pointUseStart', {
        event_category: 'pointUse',
        event_label: 'start',
        userId
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  pointUseSuccess(userId, point) {
    try {
      gtag('event', 'pointUseSuccess', {
        event_category: 'pointUse',
        event_label: 'success',
        userId,
        value: point
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  pointUseFail(userId) {
    try {
      gtag('event', 'pointUseFail', {
        event_category: 'pointUse',
        event_label: 'fail',
        userId
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  pointUseFinish(userId) {
    try {
      gtag('event', 'pointUseFinish', {
        event_category: 'pointUse',
        event_label: 'finish',
        userId
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * ffmpeg(gif, mp4, mov)가 얼마나 사용되었는지 확인(google analytics)
   */
  async ffmpegDownload(type: string) {
    try {
      gtag('event', 'ffmpegDownload', {
        event_category: 'ffmpegDownload',
        event_label: `${type} finish`
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  command(type: CommandType, instanceName) {
    try {
      gtag('event', `${instanceName}-${type}`, {
        event_category: `command-${type}`,
        event_label: instanceName
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * ai 필터가 종류별로 얼마나 사용되었는지 확인(google analytics)
   * @param {string} type
   */
  aiFilterUse(type: string) {
    try {
      gtag('event', 'aiFilterUse', {
        event_category: 'aiFilter',
        event_label: `${type} filter`
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  // unengagement

  public getPlatform() {
    if (this.plt.is('capacitor') || this.plt.is('cordova')) {
      if (this.plt.is('ios')) {
        return 'ios';
      } else if (this.plt.is('android')) {
        return 'android';
      }
    } else {
      return 'web';
    }
  }

  canvasClone() {
    try {
      gtag('event', 'canvasClone', {
        event_label: 'canvasClone'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 저작툴 내에서 캔버스 사이즈 조정을 하였을 때 카운트
   * @param {string} size
   * @return {void}
   */
  applyCanvas(size: string): void {
    try {
      gtag('event', 'applyCanvas', {
        event_label: size
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 저작툴 Tooning 캐릭터 클릭 했을 시 (artistic character 클릭 후)
   * @return {void}
   */
  tooningCharacter(): void {
    try {
      gtag('event', 'tooning-Character-tab', {
        event_label: 'tooning-Character-tab'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 저작툴 Artistic 캐릭터 클릭 했을 시
   * @return {void}
   */
  artisticCharacter(): void {
    try {
      gtag('event', 'artistic-Character-tab', {
        event_label: 'artistic-Character-tab'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 메인 페이지 진입 시 사용자의 네트워크 상태
   * @param {string} effectiveType
   * @param {number} rtt
   * @param {number} downLink
   * @param {boolean} saveData
   * @return {void}
   */
  networkInfo(effectiveType: string, rtt: number, downLink: number, saveData: boolean): void {
    try {
      gtag('event', 'networkInfo', {
        event_category: 'effectiveType',
        event_label: effectiveType
      });

      gtag('event', 'networkInfo', {
        event_category: 'rtt',
        event_label: rtt + 'ms'
      });

      gtag('event', 'networkInfo', {
        event_category: 'downLink',
        event_label: downLink + 'mb/s'
      });

      gtag('event', 'networkInfo', {
        event_category: 'saveData',
        event_label: saveData
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 저작툴 Ai 버튼 클릭 시 카운트
   * @return {void}
   */
  aiBtnClick(): void {
    try {
      gtag('event', 'aiBtnClick', {
        event_label: 'aiBtnClick'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 모바일에서 오브젝트를 롱프레스 하였을 시
   * @return {void}
   */
  longPressMobile(): void {
    try {
      gtag('event', 'longPress-mobile', {
        event_label: 'longPress-mobile'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 모바일에서 오브젝트 롱프레스 후 multiselect(여러개 선택하기) 기능을 눌렀을 시
   * @return {void}
   */
  multiSelectMobile(): void {
    try {
      gtag('event', 'multiSelect-mobile', {
        event_label: 'multiSelect-mobile'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 메인페이지 스크롤 정도
   * @param {string} scroll
   * @return {void}
   */
  landingScroll(scroll: string): void {
    try {
      gtag('event', 'landingScroll', {
        event_category: 'landingScroll',
        event_label: scroll
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 저작툴 Ai 버튼 클릭 후 글로이미지변경(TTI) 클릭 시
   * @return {void}
   */
  textToImgComponent(): void {
    try {
      gtag('event', 'textToImgComponent', {
        event_label: 'textToImgComponent'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * TTI '글로 이미지 생성' 클릭 시 정보 가져오기 ( 입력글, 그림체 스타일, 생성단계, 원본텍스트반영정도, 이미지비율)
   * @param {string} text
   * @param {string} style
   * @param {string} steps
   * @param {string} guidance_scale
   * @param {string} size
   * @return {void}
   */
  ttiInfo(text, style, steps, guidance_scale, size): void {
    try {
      gtag('event', 'TTIInfo', {
        event_category: 'Text',
        event_label: text
      });
      gtag('event', 'TTIInfo', {
        event_category: 'Style',
        event_label: style
      });
      gtag('event', 'TTIInfo', {
        event_category: 'Steps',
        event_label: steps
      });
      gtag('event', 'TTIInfo', {
        event_category: 'Guidance_scale',
        event_label: guidance_scale
      });
      gtag('event', 'TTIInfo', {
        event_category: 'Size',
        event_label: size
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * TTI 세부 설정 클릭 시 GA 트래킹 함수
   * @return {void}
   */
  ttiAdvancedSetting(): void {
    try {
      gtag('event', 'TTIAdvancedSetting', {
        event_label: 'TTIAdvancedSetting'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 준비중인 캐릭터 클릭 시 해당 캐릭터 이름 전송
   * @param {string} name
   * @return {void}
   */
  preparingCharacter(name): void {
    try {
      gtag('event', 'preparingCharacter', {
        event_label: name
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 3D 모드 얼마나 사용하는지
   * 3D 모드 버튼 클릭시 event_label: 3d demo open
   * 3D 모드에서 적용 버튼 클릭시 event_label: 3d demo apply
   * @param {string} labelName open 인지 apply 인지
   * @return {void}
   */
  threeDDemo(labelName: string): void {
    try {
      gtag('event', '3dDemo', {
        event_label: '3d demo ' + labelName
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * AI 버튼 클릭 후 GPT 버튼 클릭시
   * @return {void}
   */
  gptAnalytics(): void {
    try {
      gtag('event', 'storyGeneratorOpen', {
        event_label: 'Story Generator Open'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * Pro 요금제 해지시 이유 수집
   * @param {string} reason 선택 이유
   * @param {string} obtReason  기타 이유
   */
  unsubscribeReason(reason: string, obtReason?: string): void {
    try {
      gtag('event', 'unsubscribeReason', {
        event_label: reason + obtReason
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 랜딩 장관상 팝업 더 알아보기 클릭시 수집
   * @return {void}
   */
  landingPopUpMore(): void {
    try {
      gtag('event', 'landingPopUpMoreClick', {
        event_label: 'landingPopUpMoreClick'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 랜딩 장관상 팝업 7일간 보지 않기 클릭시 수집
   * @param {string} setCookie 7일간 보지않기 유무
   * @return {void}
   */
  landingPopUpClose(setCookie: string): void {
    try {
      gtag('event', 'landingPopUpMoreClose', {
        event_label: setCookie
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 3d page 진입시 GA 쳬크
   * @return {void}
   */
  enterTo3dEditor(): void {
    try {
      gtag('event', '3d enter', {
        event_label: '3d enter'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 투닝 매직 탭 클릭시 수집
   * @return {void}
   */
  magicTabClick(): void {
    try {
      gtag('event', 'magicTabClick', {
        event_label: 'magicTabClick'
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * Magic Editor page 진입시 GA 쳬크
   * @param {string} type  어디서 들어오는지 (header, main, bottom)
   * @return {void}
   */
  enterToMagicEditor(type: string): void {
    try {
      gtag('event', 'MagicEditorEnter', {
        event_label: type
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * Magic Editor 생성시 프롬프트 정보
   * @param {string} user   beginner/expert 정보
   * @param {string} model  스타일 정보
   * @param {object} info   inputData 정보
   * @param {number} freeCount 무료 이용권 횟수
   * @return {void}
   */
  magicGenerateInfo(user: string, model: string, info: object, freeCount: number): void {
    try {
      let point: boolean = false;
      if (freeCount === 0) {
        point = true;
      }
      gtag('event', user + ' MagicGenerateInfo', {
        event_category: 'Style',
        // @ts-ignore
        event_label: model
      });
      gtag('event', user + ' MagicGenerateInfo', {
        event_category: 'Positive',
        // @ts-ignore
        event_label: info.input.prompt
      });
      gtag('event', user + ' MagicGenerateInfo', {
        event_category: 'Negative',
        // @ts-ignore
        event_label: info.input.negative_prompt
      });
      gtag('event', user + ' MagicGenerateInfo', {
        event_category: 'Api Type',
        // @ts-ignore
        event_label: info.input.api_type
      });
      gtag('event', user + ' MagicGenerateInfo', {
        event_category: 'Batch size',
        // @ts-ignore
        event_label: info.input.batch_size
      });
      gtag('event', user + ' MagicGenerateInfo', {
        event_category: 'Canvas size',
        // @ts-ignore
        event_label: info.input.width + ' X ' + info.input.height
      });
      gtag('event', user + ' MagicGenerateInfo', {
        event_category: 'Seed',
        // @ts-ignore
        event_label: info.input.seed
      });
      gtag('event', user + ' MagicGenerateInfo', {
        event_category: 'Point',
        event_label: point
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * Magic Editor 3d 적용 시 수집
   * @param {string} info  3d 적용 true, false
   * @return {void}
   */
  magic3DApply(info: string): void {
    try {
      gtag('event', 'magic3DApply', {
        event_label: info
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * Magic Editor beginner/expert 변경 수집
   * @param {string} info  beginner/expert
   * @return {void}
   */
  magicLevelChange(info: string): void {
    try {
      gtag('event', 'magicLevelChange', {
        event_label: info
      });
    } catch (e) {
      throw new TooningAnalyticsServiceError(e);
    }
  }

  /**
   * 입문자에서 더보기 유형 수집
   * @param {string} tabTitle
   * @return {void}
   */
  magicMoreButton(tabTitle: string): void {
    gtag('event', 'magicMoreButton', {
      event_label: tabTitle
    });
  }

  /**
   * 비교모드 클릭시
   * @param {string} mode
   * @return {void}
   */
  magicComparisonMode(mode: string): void {
    gtag('event', 'magicComparisonMode', {
      event_label: mode
    });
  }

  /**
   * 뷰 모드 변경시
   * @param {string} mode
   * @return {void}
   */
  magicViewModeChange(mode: string): void {
    gtag('event', 'magicViewModeChange', {
      event_label: mode
    });
  }

  /**
   * 이미지 다운로드 시
   * @param {string} type
   */
  magicImageDownload(type: string): void {
    gtag('event', 'magicImageDownload', {
      event_label: type
    });
  }

  /**
   * 이미지 다른 그룹으로 보낼 시
   * @return {void}
   */
  magicSendToGroup(): void {
    gtag('event', 'magicSendtoGroup', {
      event_label: 'magicSendtoGroup'
    });
  }

  /**
   * 이미지 삭제 시
   * @param {boolean} type  true : 여러장 삭제, false : 한장 삭제
   * @return {void}
   */
  magicDeleteImage(type: boolean): void {
    gtag('event', 'magicDeleteImage', {
      event_label: 'isMany?: ' + type
    });
  }

  /**
   * 생성된 이미지 캔버스에 적용할 시
   * @return {void}
   */
  magicApplyToCanvas(): void {
    gtag('event', 'magicApplyToCanvas', {
      event_label: 'magicApplyToCanvas'
    });
  }

  /**
   * 비기너에서 전문가탭으로 보낼 시
   * @return {void}
   */
  magicApply3D(): void {
    gtag('event', 'magicApply3D', {
      event_label: 'magicApply3D'
    });
  }

  /**
   * 그룹 추가 시
   * @return {void}
   */
  magicAddGroup(): void {
    gtag('event', 'magicAddGroup', {
      event_label: 'magicAddGroup'
    });
  }

  /**
   * 이미지 업로드 시
   * @return {void}
   */
  magicUploadImage(): void {
    gtag('event', 'magicUploadImage', {
      event_label: 'magicUploadImage'
    });
  }

  /**
   * 비기너에서 전문가탭으로 보낼 시
   * @return {void}
   */
  magicSendToExpert(): void {
    gtag('event', 'magicSendToExpert', {
      event_label: 'magicSendToExpert'
    });
  }
  /**
   * GA 트래킹 보내는 함수
   * @param {string} eventName
   * @param {string | number} event_label
   * @param {string} event_category
   * @return {void}
   */
  sendGA(eventName: string, event_label: string | number, event_category?: string): void {
    gtag('event', eventName, {
      event_label,
      event_category
    });
  }
}
