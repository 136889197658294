import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-title-logo',
  templateUrl: './title-logo.component.html',
  styleUrls: ['./title-logo.component.scss']
})
export class TitleLogoComponent implements OnInit {
  currentStyles: any;

  constructor(public app: AppService) {}

  ngOnInit() {
    // style="margin-top: 15px;max-width: 60px"
    this.currentStyles = {
      'margin-top': this.app.isIos() ? '12px' : '17px',
      'max-width': this.app.isIos() ? '60px' : '80px'
    };
  }
}
