import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class EtcUploadEachStepTF {
  @IsNumber() @IsNotEmpty() itemId: number;
  @IsString() @IsNotEmpty() sourceType: string;
  @IsBoolean() @IsOptional() baseStep?: boolean;
  @IsBoolean() @IsOptional() listStep?: boolean;
  @IsBoolean() @IsOptional() structureStep?: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
