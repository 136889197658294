import { Command } from './command';
import { ObjectFlipXCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { ResourceName, SelectedType } from '../../enum/app.enum';
import { fabric } from 'fabric';

/**
 * Class ObjectFlipXCommand
 * @extends Command
 */
class ObjectFlipXCommand extends Command {
  protected readonly beforeFlipX: boolean;
  protected readonly afterFlipX: boolean;

  /**
   * Create ObjectFlipXCommand
   * @param {ObjectFlipXCommandData} data
   */
  constructor(data: ObjectFlipXCommandData) {
    super(data);
    this.beforeFlipX = data.beforeFlipX;
    this.afterFlipX = data.afterFlipX;
    this.commandName = 'Object-FlipX-Command';
  }

  /**
   * object가 flipX 되었을 때 실행되는 command
   */
  async execute() {
    try {
      super.execute();
      const originalFlips = [];
      let objects;
      const isGroup = this.cut.selectedOb.resource_name === ResourceName.groupName;
      const isActive = this.cut.selectedOb.type === SelectedType.activeSelection;
      if (isGroup || isActive) {
        objects = this.cut.selectedOb.getObjects();
        objects.forEach((object) => {
          originalFlips.push(object.flipX);
        });
      }
      console.log('originalFlips', originalFlips);
      console.log('object flipX execute');
      this.cut.selectedOb.toggle('flipX');
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object flipX command redo command
   * flipX undo 처리된 object를 다시 뒤집어준다.
   * @param {TooningFabricObject[]} objects
   * @return {void}
   */
  redo(objects: TooningFabricObject[]): void {
    try {
      super.redo(objects);
      this.toggleFlipX(objects);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * object flipX command undo command
   * flipX 처리된 object를 다시 뒤집어준다.
   * @param {TooningFabricObject[]} objects
   * @return {void}
   */
  undo(objects: TooningFabricObject[]): void {
    try {
      super.undo(objects);
      this.toggleFlipX(objects);
    } catch (e) {
      throw new Error(e);
    }
  }

  toggleFlipX(objects) {
    const originalFlips = [];
    if (this.type === SelectedType.activeSelection) {
      objects.forEach((object) => {
        for (const element of this.id) {
          if (object.resource_selection_id === element) {
            originalFlips.push(object);
          }
        }
      });
      this.cut.canvas.discardActiveObject();
      const selectedObjects = new fabric.ActiveSelection(originalFlips, {
        canvas: this.cut.canvas
      });
      this.cut.canvas.setActiveObject(selectedObjects);
      selectedObjects.toggle('flipX');
      selectedObjects.setCoords();
    } else {
      objects.forEach((object) => {
        if (object.resource_selection_id === this.id[0]) {
          object.toggle('flipX');
        }
      });
    }
    this.updatePageThumbnail();
  }
}

export { ObjectFlipXCommand };
