import { PageAddCommand } from './page-add-command';

/**
 * Class TemplateCopyCommand PageAddCommand 를 상속한다.
 * @extends PageAddCommand
 */
class TemplateCopyCommand extends PageAddCommand {
  constructor(data) {
    super(data);
    this.commandName = 'Template-Copy-Command';
  }

  async execute(): Promise<any> {
    try {
      await super.execute();
    } catch (e) {
      throw new Error(e);
    }
  }

  async redo() {
    try {
      await super.redo();
    } catch (e) {
      throw new Error(e);
    }
  }

  async undo() {
    try {
      await super.undo();
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { TemplateCopyCommand };
