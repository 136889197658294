import { IsNotEmpty, IsOptional, ValidateIf } from 'class-validator';

export class ModelTemplateInput {
  constructor(data?: Partial<ModelTemplateInput>) {
    if (data !== null) {
      Object.assign(this, data);
    }
  }
  @IsNotEmpty() categoryIds: number[];
  @IsNotEmpty() thumbnailOrder: number;
  @IsNotEmpty() language: string;
  @IsNotEmpty() pageId: string;
  @IsNotEmpty() title: string;
  @IsNotEmpty({ message: '투닝 발행 여부를 선택해주세요' }) status: boolean;
  @IsNotEmpty() paid: boolean;
  @IsNotEmpty() author: boolean;
  @IsOptional({ message: '밈 발행 여부를 선택해주세요' }) memeStatus?: boolean;
  @IsOptional({ message: '템플릿이 GIF 용 템플릿인지 선택해주세요' }) isGifTemplate?: boolean;
  canvasId: string;
  templateId: string;
  isRenewUpdateDate: boolean;
}

export class ModelTextTemplateInput {
  constructor(data?: Partial<ModelTextTemplateInput>) {
    if (data !== null) {
      Object.assign(this, data);
    }
  }
  @IsNotEmpty() categoryIds: number[];
  @IsNotEmpty() id: number;
  @IsOptional() status: boolean;
  @IsOptional() paid: boolean;
  @IsOptional() language: string;
  @IsOptional() title: string;
  @IsOptional() isRenewUpdateDate: boolean;
}

export class ModelTemplateCategoryInput {
  constructor(data?: Partial<ModelTemplateCategoryInput>) {
    if (data !== null) {
      Object.assign(this, data);
    }
  }
  @IsNotEmpty() categoryType: string;
  // tslint:disable-next-line:variable-name
  @IsOptional() name_ko: string;
  // tslint:disable-next-line:variable-name
  @IsOptional() name_en: string;
  // tslint:disable-next-line:variable-name
  @IsOptional() name_fr: string;
  // tslint:disable-next-line:variable-name
  @IsOptional() name_jp: string;

  // 요청보낼때 쓰이는 데이터
  @ValidateIf((o) => o.imgPath == null)
  @IsNotEmpty()
  fileImg: File;
  // db에서 받아올떄만 있는 데이터
  @ValidateIf((o) => o.fileImg == null)
  imgPath: string;
}

export class ModelTextTemplateCategoryInput {
  constructor(data?: Partial<ModelTextTemplateCategoryInput>) {
    if (data !== null) {
      Object.assign(this, data);
    }
  }
  @IsNotEmpty() id: number;
  // tslint:disable-next-line:variable-name
  @IsNotEmpty() status: boolean;
  // tslint:disable-next-line:variable-name
  @IsOptional() name_ko: string;
  // tslint:disable-next-line:variable-name
  @IsOptional() name_en: string;
  // tslint:disable-next-line:variable-name
  @IsOptional() name_fr: string;
  // tslint:disable-next-line:variable-name
  @IsOptional() name_jp: string;
}

export class TemplatePageCopyId {
  constructor(data?: Partial<TemplatePageCopyId>) {
    if (data !== null) {
      Object.assign(this, data);
    }
  }
  @IsNotEmpty() userId: number;
  @IsNotEmpty() templateId: number;
  @IsNotEmpty() pageId: number;
  @IsNotEmpty() canvasId: number;
}

export class TemplateAllPageCopyId {
  constructor(data?: Partial<TemplateAllPageCopyId>) {
    if (data !== null) {
      Object.assign(this, data);
    }
  }
  @IsNotEmpty() userId: number;
  @IsNotEmpty() templateId: number;
  @IsNotEmpty() canvasId: number;
}

export class ModelTemplate {
  constructor(data?: Partial<ModelTemplate>) {
    if (data !== null) {
      Object.assign(this, data);
    }
  }
  @IsNotEmpty() id: number;
  @IsNotEmpty() title: string;
  @IsNotEmpty() base64: string;
  @IsNotEmpty() language: string;
}

export class ModelTextTemplate {
  constructor(data?: Partial<ModelTextTemplate>) {
    if (data !== null) {
      Object.assign(this, data);
    }
  }
  @IsNotEmpty() id: number;
  @IsNotEmpty() title: string;
  @IsNotEmpty() base64: string;
  @IsNotEmpty() language: string;
}
