import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-hotkey',
  templateUrl: './hotkey.component.html',
  styleUrls: ['./hotkey.component.css']
})
export class HotkeyComponent implements OnInit {
  constructor(public modalCtrl: ModalController) {}

  public ctrl = 'Ctrl';
  ngOnInit(): void {
    if (navigator.platform === 'MacIntel' || navigator.platform === 'mac' || navigator.platform.indexOf('Linux') > -1) {
      this.ctrl = 'Command';
    }
  }

  async close() {
    await this.modalCtrl.dismiss({});
  }
}
