import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { PayGuard } from './guards/pay.guard';
import { DomainGuard } from './guards/domain.guard';
import { TooningPricePlanPageModule } from './pages-tooning/tooning-price-plan/tooning-price-plan.module';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.landing.description',
      imageUrl: 'https://tooning.io/assets/kakao/tooning2.png',
      helpCenter: false,
      serviceID: 'editor'
    },
    loadChildren: () => import('./pages-tooning/tooning-landing-main-v2/tooning-landing-main-v2.module').then((m) => m.TooningLandingMainV2PageModule)
  },
  {
    canActivate: [AuthGuard, AdminGuard],
    path: 'admin',
    data: { title: '관리자 메인 메뉴', sidemenu: false, topMenu: false, mostChrome: false },
    loadChildren: () => import('./pages-common/start/start.module').then((m) => m.StartPageModule)
  },
  {
    path: 'signup',
    data: { title: 'Signup', splitPaneDisabled: true, sidemenu: false, topMenu: false, mostChrome: false },
    loadChildren: () => import('./pages-member/login/login.module').then((m) => m.LoginPageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages-member/login/login.module').then((m) => m.LoginPageModule),
    data: { title: 'Login', splitPaneDisabled: true, sidemenu: false, topMenu: false, mostChrome: false }
  },
  {
    path: 'login/:root/:canvasId',
    loadChildren: () => import('./pages-member/login/login.module').then((m) => m.LoginPageModule),
    data: { title: 'Login', splitPaneDisabled: true, sidemenu: false, topMenu: false, mostChrome: false }
  },
  {
    path: 'login/:reactive',
    loadChildren: () => import('./pages-member/login/login.module').then((m) => m.LoginPageModule),
    data: { title: 'Login', splitPaneDisabled: true, sidemenu: false, topMenu: false, mostChrome: false }
  },
  {
    path: 'payment-management',
    canActivate: [AuthGuard],
    data: { title: '결제 관리 시스템', sidemenu: false, topMenu: true, mostChrome: false },
    loadChildren: () => import('./page-payment/payment-management/payment-management.module').then((m) => m.PaymentManagementPageModule)
  },
  // 회원관리 시스템
  {
    path: 'admin-list',
    canActivate: [AuthGuard],
    data: { title: '관리자 회원관리 시스템', sidemenu: false, topMenu: true, mostChrome: false },
    loadChildren: () => import('./pages-member/admin-list/admin-list.module').then((m) => m.AdminListPageModule)
  },
  // 그룹 시스템
  {
    path: 'group-list',
    canActivate: [AuthGuard],
    data: { title: '그룹 관리', sidemenu: false, topMenu: true, mostChrome: true },
    loadChildren: () => import('./pages-group/pages-group-list/group-list.module').then((m) => m.GroupListPageModule)
  },
  {
    path: 'school-list',
    canActivate: [AuthGuard],
    data: { title: '학교 관리', sidemenu: false, topMenu: true, mostChrome: true },
    loadChildren: () => import('./pages-group/school/school-list/school-list.module').then((m) => m.SchoolListPageModule)
  },
  {
    path: 'school-make-basic',
    canActivate: [AuthGuard],
    data: { title: '학교 정보 관리', sidemenu: true, sidemenuID: 'School', topMenu: true, mostChrome: true },
    loadChildren: () =>
      import('./pages-group/school/pages-school-make/school-make-basic/school-make-basic.module').then((m) => m.SchoolMakeBasicPageModule)
  },
  {
    path: 'school-make-enrollment',
    data: { title: '학교 정보 관리', sidemenu: true, sidemenuID: 'School', topMenu: true, mostChrome: true },
    loadChildren: () =>
      import('./pages-group/school/pages-school-make/school-make-enrollment/school-make-enrollment.module').then(
        (m) => m.SchoolMakeEnrollmentPageModule
      )
  },
  {
    path: 'school-registration-termination',
    data: { title: '학교 정보 관리', sidemenu: true, sidemenuID: 'School', topMenu: true, mostChrome: true },
    loadChildren: () =>
      import('./pages-group/school/pages-school-make/school-registration-termination/school-registration-termination.module').then(
        (m) => m.SchoolRegistrationTerminationPageModule
      )
  },
  {
    path: 'school-make-lookup',
    data: { title: '학교 정보 관리', sidemenu: true, sidemenuID: 'School', topMenu: true, mostChrome: true },
    loadChildren: () =>
      import('./pages-group/school/pages-school-make/school-make-lookup/school-make-lookup.module').then((m) => m.SchoolMakeLookupPageModule)
  },
  {
    path: 'b2g-admin',
    canActivate: [AuthGuard],
    data: { title: 'b2g', sidemenu: true, topMenu: true, mostChrome: true },
    loadChildren: () => import('./page-b2g/b2g-admin/b2g-admin.module').then((m) => m.B2gAdminPageModule)
  },
  {
    path: 'tooning-landing-main',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.landing.description',
      imageUrl: 'https://tooning.io/assets/kakao/tooning2.png',
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/tooning-landing-main-v2/tooning-landing-main-v2.module').then((m) => m.TooningLandingMainV2PageModule)
  },
  {
    path: 'template-list',
    redirectTo: 'template-list/home',
    pathMatch: 'full'
  },
  {
    path: 'card-registration/:plan',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages-tooning/card-registration/card-registration.module').then((m) => m.CardRegistrationPageModule)
  },
  {
    path: 'point-card-registration',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages-tooning/point-card-registration/point-card-registration.module').then((m) => m.PointCardRegistrationPageModule)
  },
  {
    path: 'card-registration-complete/:isModal',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    canActivate: [PayGuard],
    loadChildren: () =>
      import('./pages-tooning/card-registration-complete/card-registration-complete.module').then((m) => m.CardRegistrationCompletePageModule)
  },
  {
    path: 'point-card-registration-complete/:pointAmount',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    canActivate: [PayGuard],
    loadChildren: () =>
      import('./pages-tooning/point-card-registration-complete/point-card-registration-complete.module').then(
        (m) => m.PointCardRegistrationCompletePageModule
      )
  },
  {
    path: 'account-setting',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages-tooning/account-setting/account-setting.module').then((m) => m.AccountSettingPageModule)
  },
  {
    path: 'tooning-price-plan',
    data: {
      title: 'meta.payment.title',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.payment.description',
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/tooning-price-plan/tooning-price-plan.module').then((m) => m.TooningPricePlanPageModule)
  },
  {
    path: 'gpt/tooning-price-plan',
    data: {
      title: 'meta.payment.title',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.payment.description',
      helpCenter: false,
      serviceID: 'gpt'
    },
    loadChildren: () => import('./pages-tooning/tooning-price-plan/tooning-price-plan.module').then((m) => m.TooningPricePlanPageModule)
  },
  {
    path: 'magic/tooning-price-plan',
    data: {
      title: 'meta.payment.title',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.payment.description',
      helpCenter: false,
      serviceID: 'magic'
    },
    loadChildren: () => import('./pages-tooning/tooning-price-plan/tooning-price-plan.module').then((m) => m.TooningPricePlanPageModule)
  },
  {
    path: 'board/tooning-price-plan',
    data: {
      title: 'meta.payment.title',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.payment.description',
      helpCenter: false,
      serviceID: 'board'
    },
    loadChildren: () => import('./pages-tooning/tooning-price-plan/tooning-price-plan.module').then((m) => m.TooningPricePlanPageModule)
  },
  {
    path: 'pricing-plan-inquiry',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/pricing-plan-inquiry/pricing-plan-inquiry.module').then((m) => m.PricingPlanInquiryPageModule)
  },
  {
    path: 'external-issued-account/:estimateId',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-tooning/external-issued-account/external-issued-account.module').then((m) => m.ExternalIssuedAccountPageModule)
  },
  {
    path: 'help-center/:serviceType',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/help-center/help-center.module').then((m) => m.HelpCenterPageModule)
  },
  {
    path: 'help-detail/:faqId',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/help-detail/help-detail.module').then((m) => m.HelpDetailPageModule)
  },
  {
    path: 'paid-guide-modal',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-common/paid-guide-modal/paid-guide-modal.module').then((m) => m.PaidGuideModalPageModule)
  },
  {
    path: 'card-registration-complete',
    canActivate: [PayGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-tooning/card-registration-complete/card-registration-complete.module').then((m) => m.CardRegistrationCompletePageModule)
  },
  {
    path: 'card-registration-fail',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/card-registration-fail/card-registration-fail.module').then((m) => m.CardRegistrationFailPageModule)
  },
  {
    path: 'point-card-registration-complete',
    canActivate: [PayGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    loadChildren: () =>
      import('./pages-tooning/point-card-registration-complete/point-card-registration-complete.module').then(
        (m) => m.PointCardRegistrationCompletePageModule
      )
  },
  {
    path: 'account-setting',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/account-setting/account-setting.module').then((m) => m.AccountSettingPageModule)
  },
  {
    path: 'payment-history',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/payment-history/payment-history.module').then((m) => m.PaymentHistoryPageModule)
  },
  {
    path: 'tooning-setting',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/tooning-setting/tooning-setting.module').then((m) => m.TooningSettingPageModule)
  },
  {
    path: 'terms-of-use',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/terms-of-use/terms-of-use.module').then((m) => m.TermsOfUsePageModule)
  },
  {
    path: 'privacy-statement',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/privacy-statement/privacy-statement.module').then((m) => m.PrivacyStatementPageModule)
  },
  // font
  {
    path: 'font-make-list',
    canActivate: [AuthGuard],
    data: { title: '폰트 관리', sidemenu: true, topMenu: true, mostChrome: true, sidemenuID: 'font' },
    loadChildren: () => import('./pages-res-maker/font-make/font-make-list/font-make-list.module').then((m) => m.FontMakeListPageModule)
  },
  // 선생님 인증
  {
    path: 'teacher-certification',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/teacher-certification/teacher-certification.module').then((m) => m.TeacherCertificationPageModule)
  },

  // 툰편집
  {
    path: '4cut-list',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.work.description'
    },
    loadChildren: () => import('./pages-4cut/cut4-list/cut4-list.module').then((m) => m.Cut4ListPageModule)
  },
  {
    path: '4cut-make-manual2/:id',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: true,
      editor: true
    },
    loadChildren: () => import('./pages-4cut/cut4-make-manual2/cut4-make-manual2.module').then((m) => m.Cut4MakeManual2PageModule)
  },
  {
    path: 'canvas-share/:id',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-4cut/canvas-share/canvas-share.module').then((m) => m.CanvasSharePageModule)
  },
  {
    path: 'canvas-share/global',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-4cut/canvas-share/canvas-share.module').then((m) => m.CanvasSharePageModule)
  },
  {
    path: '4cut-edit-3d',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: true,
      editor: true
    },
    loadChildren: () => import('./pages-4cut/cut4-edit-three-d/cut4-edit-three-d.module').then((m) => m.Cut4EditThreeDPageModule)
  },
  {
    path: 'magic',
    data: {
      title: 'Tooning Magic',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'magicLanding',
      topMenu: false,
      mostChrome: false,
      serviceID: 'magic'
    },
    loadChildren: () => import('./pages-magic/magic-edu/magic-edu.module').then((m) => m.MagicEduPageModule)
  },
  {
    path: 'magic/editor',
    canActivate: [AuthGuard],
    data: {
      title: 'Magic Editor',
      editor: true,
      serviceID: 'magic-studio'
    },
    loadChildren: () => import('./pages-4cut/ai-drawing/ai-drawing.module').then((m) => m.AiDrawingPageModule)
  },
  {
    path: 'page-etri-exhibition',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./etri-exhibition-2022/page-etri-exhibition/page-etri-exhibition.module').then((m) => m.PageEtriExhibitionPageModule)
  },
  {
    path: 'results-screen',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./etri-exhibition-2022/results-screen/results-screen.module').then((m) => m.ResultsScreenPageModule)
  },
  {
    path: 'template-list/:root',
    data: {
      title: 'meta.templates.title',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.templates.description',
      imageUrl: 'https://tooning.io/assets/template/tooning_templates_array.png',
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/template-list/template-list.module').then((m) => m.TemplateListPageModule)
  },
  {
    path: 'template-detail/:searchText',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/template-detail/template-detail.module').then((m) => m.TemplateDetailPageModule)
  },
  {
    path: 'template-detail/:searchText/:language',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/template-detail/template-detail.module').then((m) => m.TemplateDetailPageModule)
  },

  // template admin
  {
    path: 'template-category-list',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: '템플릿 관리',
      sidemenu: false,
      topMenu: true,
      mostChrome: false
    },
    loadChildren: () => import('./pages-res-maker/template-category-list/template-category-list.module').then((m) => m.TemplateCategoryListPageModule)
  },
  {
    path: 'template-make-basic',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: '템플릿 관리',
      splitPaneDisabled: false,
      sidemenu: true,
      sidemenuID: 'template',
      topMenu: true,
      ResType: 'template-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/template-make/template-make-basic/template-make-basic.module').then((m) => m.TemplateMakeBasicPageModule)
  },
  {
    path: 'template-make-list/:languageType',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: '템플릿 관리',
      splitPaneDisabled: false,
      sidemenu: true,
      sidemenuID: 'template',
      topMenu: false,
      ResType: 'template-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/template-make/template-make-list/template-make-list.module').then((m) => m.TemplateMakeListPageModule)
  },
  // 텍스트 템플릿
  {
    path: 'text-template-category-list',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: '텍스트 템플릿 관리',
      sidemenu: false,
      topMenu: true,
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/text-template-category-list/text-template-category-list.module').then((m) => m.TextTemplateCategoryListPageModule)
  },
  {
    path: 'text-template-make-basic',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: '텍스트 템플릿 관리',
      splitPaneDisabled: false,
      sidemenu: true,
      sidemenuID: 'textTemplate',
      topMenu: true,
      ResType: 'text-template-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/text-template-make/text-template-make-basic/text-template-make-basic.module').then(
        (m) => m.TextTemplateMakeBasicPageModule
      )
  },
  {
    path: 'text-template-make-list/:languageType',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: '템플릿 관리',
      splitPaneDisabled: false,
      sidemenu: true,
      sidemenuID: 'textTemplate',
      topMenu: true,
      ResType: 'text-template-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/text-template-make/text-template-make-list/text-template-make-list.module').then(
        (m) => m.TextTemplateMakeListPageModule
      )
  },
  {
    path: 'default-word',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: '기본 검색 단어',
      sidemenu: false,
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-res-maker/default-word/default-word.module').then((m) => m.DefaultWordPageModule)
  },
  {
    path: 's3-file-upload',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 's3-file-upload',
      sidemenu: false,
      topMenu: true,
      mostChrome: false
    },
    loadChildren: () => import('./pages-res-maker/s3-file-upload/s3-file-upload.module').then((m) => m.S3FileUploadPageModule)
  },
  // 리소스 업로드 관리자
  {
    path: 'resmaker',
    redirectTo: 'type-selection',
    pathMatch: 'full',
    data: { title: 'ResMaker - 리소스관리자', sidemenu: false, sidemenuID: 'resmaker', topMenu: true, mostChrome: false }
  },
  {
    path: 'type-selection',
    canActivate: [AuthGuard, AdminGuard],
    data: { title: 'ResMaker - 리소스관리자', sidemenu: false, sidemenuID: 'resmaker', topMenu: true, mostChrome: false },
    loadChildren: () => import('./pages-res-maker/type-selection/type-selection.module').then((m) => m.TypeSelectionPageModule)
  },
  {
    path: 'character-list-category',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-list-category',
      mostChrome: false
    },
    loadChildren: () => import('./pages-res-maker/character-category/character-category.module').then((m) => m.CharacterCategoryPageModule)
  },
  {
    path: 'character-category-view/:category',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-category-view',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-category-view/character-category-view.module').then((m) => m.CharacterCategoryViewPageModule)
  },
  {
    path: 'character-make-basic',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-basic/character-make-basic.module').then((m) => m.CharacterMakeBasicPageModule)
  },
  {
    path: 'character-make-upload',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-upload/character-make-upload.module').then((m) => m.CharacterMakeUploadPageModule)
  },

  {
    path: 'character-make-face',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-face/character-make-face.module').then((m) => m.CharacterMakeFacePageModule)
  },
  {
    path: 'character-make-body',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-body/character-make-body.module').then((m) => m.CharacterMakeBodyPageModule)
  },
  {
    path: 'character-make-hand',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-hand/character-make-hand.module').then((m) => m.CharacterMakeHandPageModule)
  },
  {
    path: 'character-make-hair',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-hair/character-make-hair.module').then((m) => m.CharacterMakeHairPageModule)
  },
  {
    path: 'character-make-etc',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-etc/character-make-etc.module').then((m) => m.CharacterMakeEtcPageModule)
  },
  {
    path: 'character-make-position',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-position/character-make-position.module').then((m) => m.CharacterMakePositionPageModule)
  },
  {
    path: 'character-make-arm-index',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-arm-index/character-make-arm-index.module').then(
        (m) => m.CharacterMakeArmIndexPageModule
      )
  },

  {
    path: 'character-make-leg-index',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-leg-index/character-make-leg-index.module').then(
        (m) => m.CharacterMakeLegIndexPageModule
      )
  },
  {
    path: 'character-make-rotate-head',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-rotate-head/character-make-rotate-head.module').then(
        (m) => m.CharacterMakeRotateHeadPageModule
      )
  },
  {
    path: 'character-make-rotate-body',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-rotate-body/character-make-rotate-body.module').then(
        (m) => m.CharacterMakeRotateBodyPageModule
      )
  },
  {
    path: 'character-make-tag-body',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-tag-body/character-make-tag-body.module').then((m) => m.CharacterMakeTagBodyPageModule)
  },
  {
    path: 'character-make-tag-head',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-tag-head/character-make-tag-head.module').then((m) => m.CharacterMakeTagHeadPageModule)
  },
  {
    path: 'character-make-color-set',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-color-set/character-make-color-set.module').then(
        (m) => m.CharacterMakeColorSetPageModule
      )
  },

  {
    path: 'character-make-intention-body',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-intention-body/character-make-intention-body.module').then(
        (m) => m.CharacterMakeIntentionBodyPageModule
      )
  },
  {
    path: 'character-make-face-thumbnail/:resourceType',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-face-thumbnail/character-make-face-thumbnail.module').then(
        (m) => m.CharacterMakeFaceThumbnailPageModule
      )
  },
  {
    path: 'character-make-face-etc-thumbnail',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-face-etc-thumbnail/character-make-face-etc-thumbnail.module').then(
        (m) => m.CharacterMakeFaceEtcThumbnailPageModule
      )
  },

  // etcUpload category => 소품 배경을 위한 카테고리 리스트
  // {
  //   path: 'etcUpload-category-list/:resourcetype',
  //   canActivate: [AuthGuard, AdminGuard],
  //   data: {
  //     title: 'ResMaker - 리소스관리자',
  //     sidemenu: true,
  //     sidemenuID: 'resmaker',
  //     topMenu: true,
  //     ResType: 'etcUpload-category-list',
  //     mostChrome: false
  //   },
  //   loadChildren: () =>
  //     import('./pages-res-maker/etcUpload-category-list/etcUpload-category-list.module').then((m) => m.EtcUploadCategoryListPageModule)
  // },
  // etcUpload category => 소품 배경을 위한 카테고리 리스트
  {
    path: 'etcUpload-category-list/:resourcetype/:AuthorType',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'etcUpload-category-list',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/etcUpload-category-list/etcUpload-category-list.module').then((m) => m.EtcUploadCategoryListPageModule)
  },

  // etcUpload-list => 소품 배경 detail 리스트
  {
    path: 'etcUpload-make-basic/:resourcetype/:AuthorType',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'etcUpload-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/etcUpload-make/etcUpload-make-basic/etcUpload-make-basic.module').then((m) => m.EtcUploadMakeBasicPageModule)
  },
  {
    path: 'etcUpload-make-list/:resourcetype/:AuthorType',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'etcUpload-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/etcUpload-make/etcUpload-make-list/etcUpload-make-list.module').then((m) => m.EtcUploadMakeListPageModule)
  },
  {
    path: 'etcUpload-make-addOrSet/:sourceId/:isSet/:resourcetype/:AuthorType',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'etcUpload-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/etcUpload-make/etcUpload-make-addOrSet/etcUpload-make-addOrSet.module').then((m) => m.EtcUploadMakeAddOrSetPageModule)
  },
  {
    path: 'find-password',
    loadChildren: () => import('./pages-tooning/find-password/find-password.module').then((m) => m.FindPasswordPageModule)
  },
  {
    path: 'set-author-type',
    loadChildren: () => import('./pages-common/set-author-type/set-author-type.module').then((m) => m.SetAuthorTypePageModule)
  },
  {
    path: 'toonsquare-admin-news',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'toonsquare -  관리자',
      sidemenu: true,
      sidemenuID: 'toonsquare-admin',
      topMenu: true,
      mostChrome: false
    },
    loadChildren: () => import('./pages-toonsquare/news/news.module').then((m) => m.NewsPageModule)
  },
  {
    path: 'oauth/callback/whalespace',
    loadChildren: () => import('./pages-tooning/whalespace/whalespace.module').then((m) => m.WhalespacePageModule)
  },
  {
    path: 'oauth/callback/cool-school',
    loadChildren: () => import('./pages-tooning/cool-school/cool-school.module').then((m) => m.CoolSchoolPageModule)
  },
  {
    canActivate: [AuthGuard, AdminGuard],
    path: 'tag-setting/:resourcetype/:id',
    loadChildren: () => import('./pages-res-maker/tag-setting/tag-setting.module').then((m) => m.TagSettingPageModule)
  },
  {
    path: 'character-make-upload-history',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-upload-history/character-make-upload-history.module').then(
        (m) => m.CharacterMakeUploadHistoryPageModule
      )
  },
  {
    path: 'character-make-default-set',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'ResMaker - 리소스관리자',
      sidemenu: true,
      sidemenuID: 'resmaker',
      topMenu: true,
      ResType: 'character-make',
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-res-maker/character-make/character-make-default-set/character-make-default-set.module').then(
        (m) => m.CharacterMakeDefaultSetPageModule
      )
  },
  {
    path: 'canvas-share-error/:state',
    loadChildren: () => import('./pages-common/canvas-share-error/canvas-share-error.module').then((m) => m.CanvasShareErrorPageModule)
  },
  {
    path: 'b2g-group-admin',
    canActivate: [AuthGuard],
    data: { title: 'b2g', sidemenu: true, topMenu: true, mostChrome: true },
    loadChildren: () => import('./page-b2g/b2g-group-admin/b2g-group-admin.module').then((m) => m.B2gGroupAdminPageModule)
  },
  {
    path: 'admin/faq',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'FaQ',
      topMenu: true,
      mostChrome: false
    },
    loadChildren: () => import('./pages-res-maker/faq-service/faq-service.module').then((m) => m.FaqServicePageModule)
  },
  {
    path: 'admin/faq/:serviceType',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'FaQ',
      topMenu: true,
      mostChrome: false
    },
    loadChildren: () => import('./pages-res-maker/faq-list/faq-list.module').then((m) => m.FaqListPageModule)
  },
  {
    path: 'admin/faq/:serviceType/make',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'FaQ 생성',
      sidemenu: false,
      topMenu: true,
      mostChrome: false
    },
    loadChildren: () => import('./pages-res-maker/faq-make/faq-make.module').then((m) => m.FaqMakePageModule)
  },
  // 투닝 GPT 랜딩. 보류
  // {
  //   path: 'gpt',
  //   data: {
  //     title: 'Tooning GPT',
  //     splitPaneDisabled: true,
  //     sidemenu: true,
  //     sidemenuID: 'gptLanding',
  //     topMenu: false,
  //     mostChrome: false,
  //     serviceID: 'gpt'
  //   },
  //   loadChildren: () => import('./pages-gpt/pages-gpt.module').then((m) => m.PagesGptPageModule)
  // },
  {
    path: 'admin/gpt',
    canActivate: [AuthGuard, AdminGuard],
    data: {
      title: 'Tooning Gpt Admin',
      sidemenu: true,
      sidemenuID: 'gptAdmin',
      serviceID: 'gptAdmin'
    },
    loadChildren: () => import('./pages-gpt/gpt-admin/gpt-admin.module').then((m) => m.GptAdminPageModule)
  },
  {
    path: 'gpt/editor',
    loadChildren: () => import('./pages-gpt/gpt-editor/gpt-editor.module').then((m) => m.GptEditorPageModule),
    data: {
      title: 'Tooning GPT Editor',
      editor: true,
      serviceID: 'gpt'
    }
  },
  {
    path: 'board',
    data: {
      title: 'Tooning Board',
      serviceID: 'board'
    },
    loadChildren: () => import('./board/board.module').then((m) => m.BoardPageModule)
  },
  {
    path: '3d/editor',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () => import('./pages-4cut/td-editor/td-editor.module').then((m) => m.TdEditorPageModule)
  },
  {
    path: '3d/editor/:id',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () => import('./pages-4cut/td-editor/td-editor.module').then((m) => m.TdEditorPageModule)
  },
  // 잘못된 URL 접근시 페이지 이동
  { path: '**', redirectTo: '/page404', pathMatch: 'full' },
  {
    path: 'page404',
    loadChildren: () => import('./pages-common/page404/page404.module').then((m) => m.Page404PageModule)
  }
];

// @ts-ignore
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
