<div [style.padding-top.px]='app.isDesktopWidth() ? 20 : 10'
     [style.padding-bottom.px]='app.isDesktopWidth() ? 20 : 10'
     [style.width]='app.isDesktopWidth() ? "fit-content" : "100%"'>
  <div class='footer-item-box'>
    Toonsquare Corp.
  </div>
  <div class='footer-item-box' *ngIf='app.isDesktopWidth()'>
    CEO : Hoyoung Lee | Business Number : 879-88-01529(Republic of Korea) |
  </div>
  <div class='footer-item-box' *ngIf='!app.isDesktopWidth()'>
    {{ 'business number' | translate }} : 879-88-01529 {{ 'republic' | translate }}
    <br>
    {{ 'mail order' | translate }} : 2020{{ 'seoul' | translate }}0385{{ 'ho' | translate }}
  </div>
  <div class='footer-item-box'>
    Mail-order-sails registration number : 2020-Seoul Seocho-0385Ho |
  </div>
  <div class='footer-item-box'>
    #407, Heekyung Building. 39, Maeheon-ro-8-gil, Seocho-gu, Seoul, Republic of Korea
  </div>
  <div class='footer-item-box' style='margin-top: 24px;'>
    ⓒ toonsquare. All rights reserved.
  </div>

</div>