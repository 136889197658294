import {
  CloudWatchLogsClient,
  CreateLogStreamCommand,
  CreateLogStreamCommandInput,
  PutLogEventsCommand,
  PutLogEventsCommandInput
} from '@aws-sdk/client-cloudwatch-logs';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { environment } from '../../../environments/environment';
import { TooningMakeLogStreamNameError } from '../../pages-tooning/errors/TooningErrors';

let nextSequenceToken: string | null;
const cloudWatchClient = new CloudWatchLogsClient({
  region: 'ap-northeast-2',
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: 'ap-northeast-2' },
    identityPoolId: 'ap-northeast-2:4d8503db-09d7-41bc-8611-0e87306fd430'
  })
});

const logGroupName = `/client/web/${environment.deploy}/tooning`;
/**
 * LocalStorage 에서 user id 정보를 가져온다.
 * @return {string | null}
 */
const getUserIndexFromLocalStorage = (): string | null => {
  const temp = window.localStorage.getItem('user');
  let user;
  let id: string | null = null;
  if (temp) {
    user = JSON.parse(temp);
  }
  if (user) {
    id = user.id.toString();
  }
  return id;
};
/**
 * CloudWatch 에서 사용할  log stream 이름을 만든다.
 * @param {string | null} userEmail
 */
const makeLogStreamName = (userEmail: string | null): string => {
  try {
    let id = getUserIndexFromLocalStorage();
    let logStreamUserIdPart = id || 'notLogin';
    let logStreamUserEmailPart = userEmail ? userEmail.toString() : 'anonymous';
    return `${logStreamUserIdPart}@${logStreamUserEmailPart}`;
  } catch (e) {
    throw new TooningMakeLogStreamNameError(e);
  }
};
/**
 * 개발시 참조 링크 : //https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cloudwatch-logs/classes/cloudwatchlogs.html
 * cloudWatch 로 로그 전달하는 외부 함수
 * @param {string} message 전달하려는 메세지
 * @param {string|null} userEmail log stream 이름으로 저장될 사용자 이메일
 * @return {void}
 */
const cloudWatch = (message: string, userEmail?: string | null): void => {
  try {
    let logStreamName = makeLogStreamName(userEmail);
    _sendLogData(message, logStreamName)
      .then((data) => {
        nextSequenceToken = data.nextSequenceToken;
        window.localStorage.setItem('nextSequenceToken', data.nextSequenceToken);
      })
      .catch(async (error) => {
        console.error(`stream : ${logStreamName},  message:${error.message}`);
        // user id 를 이름으로 만들어진 stream 이 없을 경우 생성한다.
        if (error.name === 'ResourceNotFoundException') {
          await makeCloudWatchLogStream(logStreamName);
          nextSequenceToken = null;
        }
        // sequence token 이 없을 경우 유효한 다음 토큰을 가져와서 세팅한다.
        if (error.name === 'DataAlreadyAcceptedException' || error.name === 'InvalidSequenceTokenException') {
          nextSequenceToken = error.expectedSequenceToken;
          if (!nextSequenceToken) {
            window.localStorage.removeItem('nextSequenceToken');
          }
        }

        // next sequence token 이 null 인 경우는 user id를 이름으로 stream 이 새로 생성된 경우 이므로 localstorage 저장된 걸 리셋 한다.

        _sendLogData(message, logStreamName).then();
      });
  } catch (e) {
    console.error(e);
  }
};
/**
 * CloudWatch 에 log 를 전달하는 내부 함수
 * @param {string} message
 * @param {string} logStreamName
 * @return {Promise<any>}
 */
const _sendLogData = (message: string, logStreamName: string): Promise<any> => {
  try {
    const params3: PutLogEventsCommandInput = {
      logEvents: [{ timestamp: new Date().getTime(), message }],
      logGroupName,
      logStreamName
    };
    if (nextSequenceToken) {
      params3.sequenceToken = nextSequenceToken;
    } else {
      params3.sequenceToken = window.localStorage.getItem('nextSequenceToken');
    }
    return cloudWatchClient.send(new PutLogEventsCommand(params3));
  } catch (e) {
    console.error(e);
  }
};
/**
 * user id 를 이름으로하는 stream 이 없는 경우 생성
 * @param {string | null} logStreamName
 * @return {Promise<void>}
 */
const makeCloudWatchLogStream = async (logStreamName: string | null): Promise<void> => {
  if (!logStreamName) {
    throw Error('logStreamName is empty');
  }
  const paramsForLogStream: CreateLogStreamCommandInput = { logGroupName, logStreamName };
  try {
    await cloudWatchClient.send(new CreateLogStreamCommand(paramsForLogStream));
  } catch (e) {
    if (e.name === 'ResourceAlreadyExistsException') {
      console.warn('cloudwatch userId stream name already exist');
    } else {
      throw e;
    }
  }
};
export { cloudWatch, makeCloudWatchLogStream, makeLogStreamName };
