import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommaSeparatedNumberPipe } from './comma-separated-number.pipe';
import { ShortenPipe } from './shorten.pipe';
import { ValidationMsgPipe } from './validationMsg.pipe';
import { IntentionFacePipe } from './intention-face.pipe';
import { DirectionChangeStringPipe } from './directionChangeString.pipe';
import { DirectionChangeNumberPipe } from './directionChangeNumber.pipe';
import { DirectionChangeMonthPipe } from './directionChangeMonth.pipe';
import { ByPassSecurityPipe } from './bypass-security';
import { FaceEmotionStringPipe } from './faceEmotionString.pipe';

@NgModule({
  declarations: [
    CommaSeparatedNumberPipe,
    ShortenPipe,
    ValidationMsgPipe,
    IntentionFacePipe,
    DirectionChangeStringPipe,
    DirectionChangeNumberPipe,
    DirectionChangeMonthPipe,
    ByPassSecurityPipe,
    FaceEmotionStringPipe
  ],
  imports: [CommonModule],
  exports: [
    CommaSeparatedNumberPipe,
    ShortenPipe,
    ValidationMsgPipe,
    IntentionFacePipe,
    DirectionChangeStringPipe,
    DirectionChangeNumberPipe,
    DirectionChangeMonthPipe,
    ByPassSecurityPipe,
    FaceEmotionStringPipe
  ]
})
export class Pipes {}
