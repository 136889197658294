import { Command } from './command';
import * as _ from 'lodash';
import { PageAddCommandData } from '../../interfaces/app.interface';

/**
 * Class PageAddCommand
 */
class PageAddCommand extends Command {
  protected callback: any;
  protected newPageId: number;
  protected page: any;
  protected beforePageIndex;
  protected afterPageIndex;

  /**
   * Create PageAddCommand
   * @param {PageAddCommandData} data
   */
  constructor(data: PageAddCommandData) {
    super(data);
    this.callback = data.callback;
    this.commandName = 'Page-Add-Command';
  }

  /**
   * Execute PageAddCommand
   */
  async execute(): Promise<false | number> {
    try {
      this.cut.setKeyActivation = false;
      super.execute();
      this.beforePageIndex = this.cut.panelIndex;
      await this.cut.updatePage(this.beforePageIndex);
      this.newPageId = await this.callback();
      this.afterPageIndex = this.cut.panelIndex;
      this.page = this.cut.pageList[this.cut.panelIndex];
      this.cut.setKeyActivation = true;
      this.cut.setShowPreviewCanvas();
      return this.newPageId;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo PageAddCommand
   */
  async redo(): Promise<void> {
    super.redo();
    try {
      if (!this.newPageId) {
        throw new Error('page-add-command 에서 undo 할 newPageId 가 없습니다.');
      }
      await this.cut.pageService.restorePage(this.newPageId).toPromise();
      this.cut.pageList.splice(this.afterPageIndex, 0, this.page);
      await this.cut.goPage(this.afterPageIndex);
      this.cut.panelIndex = this.afterPageIndex;
      this.updatePageThumbnail();
      this.cut.setShowPreviewCanvas();
      return;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo PageAddCommand
   */
  async undo(): Promise<void> {
    super.undo();
    try {
      if (!this.newPageId) {
        console.warn(`page-add-command 에서 undo 할 newPageId 가 없습니다.`);
        throw new Error('page-add-command 에서 undo 할 newPageId 가 없습니다.');
      }
      await this.cut.pageService.deletePage(this.newPageId).toPromise();
      _.pullAt(this.cut.pageList, this.afterPageIndex);
      await this.cut.goPage(this.beforePageIndex);
      this.cut.panelIndex = this.beforePageIndex;
      this.updatePageThumbnail();
      this.cut.setShowPreviewCanvas();
      return;
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { PageAddCommand };
