import { Injectable } from '@angular/core';
import { Page } from '../model/page/page';
import { gql, FetchResult } from '@apollo/client';
import { AppService } from './app.service';
import { UserService } from './user.service';
import { AlertController } from '@ionic/angular';
import { GraphqlApiService } from './api/graphql.api.service';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, tap, timeout, flatMap } from 'rxjs/operators';
import { UserRole } from '../enum/app.enum';
import { S3Service } from './aws/s3.service';
import { InputPageCreateObjectKeys } from '../interfaces/s3.interface';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private CREATE_PAGE_TIMEOUT = 10 * 1000; // 10초
  private UPDATE_PAGE_TIMEOUT = 10 * 1000; // 10초

  constructor(
    public app: AppService,
    public userService: UserService,
    public graphql: GraphqlApiService,
    public alertController: AlertController,
    private s3Service: S3Service
  ) {}

  /**
   * 페이지 동기화로 받아오기
   * @param {number} id 받아올 페이지 아이디
   * @return {Promise<Page>}
   */
  public async getPageAsync(id: number): Promise<Page> {
    const result: Promise<any> = this.graphql
      .query(
        gql`
          query getPage($id: ID!) {
            page(id: $id) {
              json
              base64
              order
              aiResult
            }
          }
        `,
        {
          id
        }
      )
      .toPromise();
    return result;
  }

  /**
   * 페이지 받아오기
   * @param {number} id 받아올 페이지 아이디
   * @return {Promise<Page>}기
   */
  public async getPage(id: number): Promise<Page> {
    const result: any = await this.graphql
      .query(
        gql`
          query getPage($id: ID!) {
            page(id: $id) {
              json
              base64
              order
              aiResult
            }
          }
        `,
        {
          id
        }
      )
      .toPromise();

    return new Page().deserialize(result.data.page);
  }

  /**
   * 페이지 목록 불러오기
   * @param {number} userId 페이지 소유 유저 아이디
   * @param {number} canvasId 페이지 들어있는 캔버스 아이디
   * @return {Promise<Page[]>}
   */
  async getPages(userId: number, canvasId: number): Promise<Page[]> {
    const result: any = await this.graphql
      .query(
        gql`
          query getPages($userId: ID!, $canvasId: ID!) {
            pagees(userId: $userId, canvasId: $canvasId) {
              id
              base64
              order
              json
              aiResult
            }
          }
        `,
        {
          userId,
          canvasId
        }
      )
      .toPromise();

    const temp: Array<Page> = [];
    result.data.pagees.map((page) => {
      temp.push(new Page().deserialize(page));
    });
    return temp;
  }

  /**
   * 페이지 삭제
   * @param {number} id
   * @return {Observable<FetchResult<any>>}
   */
  deletePage(id: number): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation deletePage($id: ID!) {
            pageDelete(id: $id)
          }
        `,
        {
          id
        }
      )
      .pipe(
        tap((results) => {
          results.data = +results.data.deletePage;
          return results;
        })
      );
  }

  /**
   * 삭제한 페이지 되살림
   * @param id 되살릴 페이지 id
   */
  restorePage(id: number): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation restorePage($id: ID!) {
            pageRestore(id: $id)
          }
        `,
        {
          id
        }
      )
      .pipe(
        tap((results) => {
          results.data = +results.data.restorePage;
          return results;
        })
      );
  }

  /**
   * 여러 페이지 한꺼번에 삭제
   * @param {number} userId 사용자 아이디
   * @param {number} canvasId 캔버스 아이디
   * @param {number[]} pageIdList 삭제할 페이지 아이디 리스트
   * @return {Observable<FetchResult<any>>}
   */
  pageMultiDelete(userId: number, canvasId: number, pageIdList: number[]): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation pageMultiDelete($userId: ID!, $canvasId: ID!, $pageIdList: [ID!]!) {
            pageMultiDelete(userId: $userId, canvasId: $canvasId, pageIdList: $pageIdList) {
              id
              base64
              order
            }
          }
        `,
        {
          userId,
          canvasId,
          pageIdList
        }
      )
      .pipe(
        tap((results) => {
          results.data = +results.data.pageMultiDelete;
          return results;
        })
      );
  }

  /**
   * 여러 페이지 최신화
   * @param {number} userId 사용자 아이디
   * @param {number} canvasId 캔버스 아이디
   * @param {number[]} pageIdList 최신화 할 페이지 아이디 리스트
   * @return {Observable<FetchResult<any>>}
   */
  pageMultiRestore(userId: number, canvasId: number, pageIdList: number[]): Observable<FetchResult<any>> {
    return this.graphql
      .mutate(
        gql`
          mutation pageMultiRestore($userId: ID!, $canvasId: ID!, $pageIdList: [ID!]!) {
            pageMultiRestore(userId: $userId, canvasId: $canvasId, pageIdList: $pageIdList) {
              id
              base64
              order
            }
          }
        `,
        {
          userId,
          canvasId,
          pageIdList
        }
      )
      .pipe(
        tap((results) => {
          results.data = +results.data.pageMultiRestore;
          return results;
        })
      );
  }

  /**
   * @description page 생성 하며 timeout 이 세팅되어 있다. timeout 으로 페이지 생성이 안되면, 에러러 간주되며 핸들링이 필요하다.
   * @param {Page} data
   * @param {Blob[]} files
   * @return {Observable<FetchResult<any>>}
   */
  createPage(data: Page, files: Blob[]): Observable<FetchResult<any>> {
    let pageCreate$: Observable<any>;
    if (files) {
      pageCreate$ = from(this.s3Service.putPageObject(files)).pipe(
        tap((keys: InputPageCreateObjectKeys) => {
          files = undefined;
        }),
        flatMap((keys: InputPageCreateObjectKeys) => {
          return this.graphql
            .mutate(
              gql`
                mutation pageCreateWithoutFiles($data: InputPageCreate!, $keys: InputPageCreateObjectKeys!) {
                  pageCreateWithoutFiles(data: $data, keys: $keys) {
                    id
                    base64
                    json
                  }
                }
              `,
              {
                data,
                keys
              }
            )
            .pipe(
              tap((result) => {
                result.data = result.data.pageCreateWithoutFiles;
              }),
              timeout(this.CREATE_PAGE_TIMEOUT),
              catchError((error) => {
                throw error;
              })
            );
        })
      );
    }
    return pageCreate$;
  }

  /**
   * web worker 를 이용하여 페이지 업데이트 되기 전/후에 대한 json diff 를 구한다.
   * @param fabricObjBeforeUpdate 페이지 업데이트 이전의 fabric obj
   * @param fabricObjAfterUpdate 페이지 업데이트 이후의 fabric obj
   * @return {Promise<string>} 업데이트된 페이지에 대한 json diff 가 들어있다.
   * @private
   */
  private async getDiffFabricObj(fabricObjBeforeUpdate: any, fabricObjAfterUpdate: any): Promise<string> {
    const jsondiffpatchWorker = new Worker(new URL('../workers/jsonDiff.worker', import.meta.url), {
      type: 'module'
    });

    const diffFabricObj: string = await new Promise((resolve, reject) => {
      jsondiffpatchWorker.onmessage = (event) => {
        resolve(event.data);
      };

      jsondiffpatchWorker.onerror = (event) => {
        jsondiffpatchWorker.terminate();
        reject(new Error(`jsondiffpatch in worker error : ${JSON.stringify(event.message)}`));
      };

      jsondiffpatchWorker.postMessage({ fabricObjBeforeUpdate, fabricObjAfterUpdate });
    });

    jsondiffpatchWorker.terminate();
    return diffFabricObj;
  }

  /**
   * page 를 update 하며 timeout 이 세팅되어 있다.
   * timeout 으로 페이지가 저장되지 않으면 에러로 간주하며 errorHandler 를 통해 핸들링 해줘야한다.
   * @param {Page} data
   * @param {UserRole} userRole
   * @param {Blob[]} files
   * @param isJsonDiff 페이지의 변경사항(json diff) 만 서버에 전송할 건지 여부
   * @param fabricObjBeforeUpdate 페이지 업데이트 이전의 fabric obj
   * @param fabricObjAfterUpdate 페이지 업데이트 이후의 fabric obj
   */
  updatePage(data: Page, userRole: UserRole, files?: Blob[], fabricObjBeforeUpdate?: any, fabricObjAfterUpdate?: any): Observable<FetchResult<any>> {
    if (data instanceof Page) {
      data.sanitize();
    }
    return this.updatePageWithoutJsonDiff(data, userRole, files);
  }

  /**
   * 페이지 업데이트한다.
   * @param {Page} data
   * @param {UserRole} userRole
   * @param {Blob[]} files
   */
  updatePageWithoutJsonDiff(data: Page, userRole: UserRole, files?: Blob[]): Observable<FetchResult<any>> {
    let pageUpdate$;
    if (files) {
      pageUpdate$ = from(this.s3Service.putPageObject(files, data, userRole)).pipe(
        tap((keys: InputPageCreateObjectKeys) => {
          files = undefined;
        }),
        flatMap((keys) => {
          return this.graphql
            .mutate(
              gql`
                mutation pageUpdateWithoutFiles($data: InputPageUpdate!, $userRole: UserRole!, $keys: InputPageCreateObjectKeys!) {
                  pageUpdateWithoutFiles(data: $data, userRole: $userRole, keys: $keys)
                }
              `,
              {
                data,
                userRole,
                keys
              }
            )
            .pipe(
              timeout(this.UPDATE_PAGE_TIMEOUT),
              catchError((error) => {
                return throwError(error);
              }),
              tap((results) => {
                results.data = results.data.pageUpdateWithoutFiles;
              })
            );
        })
      );
    } else {
      pageUpdate$ = this.graphql
        .mutate(
          gql`
            mutation pageUpdate($data: InputPageUpdate!, $userRole: UserRole!, $files: [Upload!]) {
              pageUpdate(data: $data, userRole: $userRole, files: $files)
            }
          `,
          {
            data,
            userRole,
            files
          }
        )
        .pipe(
          timeout(this.UPDATE_PAGE_TIMEOUT),
          catchError((error) => {
            return throwError(error);
          }),
          tap((results) => {
            results.data = results.data.pageUpdate;
          })
        );
    }
    return pageUpdate$;
  }

  /**
   * 페이지 썸네일 업데이트하기 (해당 페이지의 s3 key 값에 대해 객체 update)
   * @param page - 업데이트할 페이지
   * @param file - 페이지 썸네일 이미지파일
   */
  updatePageThumbnail(page: Page, file: Blob): Observable<FetchResult<any>> {
    if (page instanceof Page) {
      page.sanitize();
    }
    return this.graphql
      .mutate(
        gql`
          mutation pageThumbnailUpdate($page: InputPageUpdate!, $file: Upload!) {
            pageThumbnailUpdate(page: $page, file: $file)
          }
        `,
        {
          page,
          file
        }
      )
      .pipe(
        map((results) => {
          return results;
        })
      );
  }
}
