import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';

import { FooterComponent } from './footer/footer.component';
import { AppVersionComponent } from './app-version/app-version.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalTemplateViewComponent } from './modal/modal-template-view/modal-template-view.component';
import { ScrollbarThemeModule } from '../../directive/scrollbar-theme';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { PopoverLinkcopyComponent } from './popover/popover-linkcopy/popover-linkcopy.component';
import { ClipboardModule } from 'ngx-clipboard';
import { MatLegacyRippleModule } from '@angular/material/legacy-core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { ServiceTabComponent } from './service-tab/service-tab.component';
import { PopoverMenuAacountComponent } from './popover/popover-menu-aacount/popover-menu-aacount.component';
import { LandingMainPopupComponent } from '../tooning-landing-main-v2/landing-main-popup/landing-main-popup.component';
import { FooterIconComponent } from './footer-icon/footer-icon.component';
import { FooterCompanyInfoComponent } from './footer-company-info/footer-company-info.component';
import { ServiceTebStudioComponent } from './service-teb-studio/service-teb-studio.component';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AppVersionComponent,
    ModalTemplateViewComponent,
    PopoverLinkcopyComponent,
    PopoverMenuAacountComponent,
    ServiceTabComponent,
    ServiceTebStudioComponent,
    LandingMainPopupComponent,
    FooterIconComponent,
    FooterCompanyInfoComponent
  ],
  imports: [
    CommonModule,
    IonicModule,

    TranslateModule,
    ClipboardModule,
    FormsModule,
    ScrollbarThemeModule,
    MatLegacyMenuModule,
    ReactiveFormsModule,
    MatLegacyRippleModule,
    MatLegacyTooltipModule,
    ClickOutsideModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    AppVersionComponent,
    ModalTemplateViewComponent,
    MatLegacyRippleModule,
    ServiceTabComponent,
    FooterIconComponent,
    FooterCompanyInfoComponent,
    ServiceTebStudioComponent
  ],
  entryComponents: [ModalTemplateViewComponent, PopoverLinkcopyComponent]
})
export class LandingModule {}
