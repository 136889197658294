import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../../services/app.service';
import { SnsSignupInfo } from '../../interfaces/app.interface';

@Component({
  selector: 'app-sns-terms-agreement',
  templateUrl: './sns-terms-agreement.component.html',
  styleUrls: ['./sns-terms-agreement.component.scss']
})
export class SnsTermsAgreementComponent implements OnInit {
  @Input('snsInfo') snsInfo: SnsSignupInfo;
  @Output('signupButtonClick') signupButtonClick: EventEmitter<SnsSignupInfo> = new EventEmitter();
  public checkBoxStatus: Array<boolean> = [false, false, false, false, false];

  constructor(public app: AppService) {}

  ngOnInit() {}

  /**
   * 이용약관 동의 체크박스 변경 핸들러
   * @param {Array<boolean>} checkBoxStatus
   * @return {void}
   */
  changeAgreementHandler(checkBoxStatus: Array<boolean>): void {
    this.checkBoxStatus = checkBoxStatus;
  }
}
