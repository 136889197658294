import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputCharactersDirective } from './input-characters.directive';
import { InputIntegerDirective } from './input-integer.directive';

@NgModule({
  declarations: [InputCharactersDirective, InputIntegerDirective],
  imports: [CommonModule],
  exports: [InputIntegerDirective, InputCharactersDirective]
})
export class InputModule {}
