import { Injectable } from '@angular/core';
import { gql } from '@apollo/client';
import { map, timeout } from 'rxjs/operators';
import { AppService } from '../app.service';
import { GraphqlApiService } from '../api/graphql.api.service';

@Injectable({
  providedIn: 'root'
})
export class AiFilterService {
  private animeGANTimeout = 10 * 1000;

  constructor(public app: AppService, public graphql: GraphqlApiService) {}

  /**
   * 원본 이미지와 ai 필터 종류를 가지고 이미지 변환을 요
   * @param base64
   * @param type
   * @return {Promise<any>}
   */
  aiFilter(base64, type): Promise<any> {
    return this.graphql
      .query(
        gql`
          mutation aiFilter($base64: String!, $type: String!) {
            aiFilter(base64: $base64, type: $type)
          }
        `,
        {
          base64,
          type
        }
      )
      .pipe(
        timeout(this.animeGANTimeout),
        map((result) => {
          result.data = result.data.aiFilter;
          return result;
        })
      )
      .toPromise();
  }
}
