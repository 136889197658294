import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent {
  // tslint:disable-next-line:variable-name
  public show_reload = false;

  constructor(public app: AppService, public translate: TranslateService) {}


  async clickNext() {
    this.app.update.next_time = true;
  }

  async clickNextReload() {
    this.app.update.ready_to_reload = false;
    this.app.update.next_time = true;
    this.app.cache.setLocal('reload_next', true);
  }

  async clickDoUpdate() {
    this.app.doUpdate();
  }

  async clickReload() {
    this.app.update.ready_to_reload = false;
    this.show_reload = true;
    await this.app.reloadApp();
  }
}
