import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlertController, LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { UserService } from './user.service';
import { CacheService } from './cache.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxImageCompressService } from 'ngx-image-compress';

import mergeImages from 'merge-images';
import { GoogleService } from './google/auth/google.service';
import { AnalyticsService } from './google/analytics/analytics.service';
import { PaymentService } from './payment/payment.service';
import { UserLoginType } from '../enum/UserLoginType.enum';
import {
  TooningAlerOnDidDismissError,
  TooningBadRequestError,
  TooningCheckPaypelUserError,
  TooningClientError,
  TooningCustomUserUpdateHandlerError,
  TooningErrorCode,
  TooningFontMatchingError,
  TooningLogOutError,
  TooningPresentAlertError,
  TooningServerConnectionError,
  TooningUnauthorizedError,
  TooningUnkownNetworkError
} from '../pages-tooning/errors/TooningErrors';
import { TranslateService } from '@ngx-translate/core';
import HttpStatusCode from '../pages-tooning/enums/httpErrors.enum';
import { Capacitor } from '@capacitor/core';
import {
  AuthorType,
  CharacterStatus,
  CountryCode,
  Currency,
  Deployment,
  DomainType,
  LanguageKey,
  LanguageKeyV2,
  LanguageKeyV3,
  LanguageKeyV4,
  LanguageType,
  ServerRegion,
  ServiceType,
  UserRoleWithSubscriptionType
} from '../enum/app.enum';
import { shareReplay } from 'rxjs/operators';
import { LoadingTimerManager } from './loadingTimerManager';
import { DemoClass } from './demoClass';
import { ChangeLanguageService } from './changeLanguage.service';
import { KakaoServiceService } from './kakao-service.service';
import * as _ from 'lodash';
import { MagicCheckServiceTypeError } from '../pages-tooning/errors/MagicErrors';
import { BehaviorSubject } from 'rxjs';

// const logBeautify = require('log-beautify');
const S3_JSON_DATA = 'https://tooning-json-db.s3.ap-northeast-2.amazonaws.com/json-file/jsonData';
const MAGIC = 'magic';
const FILE_NAME = 'app.service.ts';

/**
 * mergeImage 함수의 option 타입 명시
 */
type mergeImageOption = {
  crossOrigin: string;
  format: string;
  height: number;
  width: number;
};

@Injectable({
  providedIn: 'root'
})
export class AppService {
  // 앱버전
  public appversion = '!!app_version!!';
  public RootServiceType: DomainType = DomainType.Io; //도메인 체크 후 저장 되는 변수
  public isEditorOpen: boolean; //CUT4데디터 진입 체크
  public isMainPopup: boolean;
  public mainPopup: {};
  public isChannelButton: boolean = false; //isChannelButton
  public isProduction = environment.production;
  public cloudfrontStatus = environment.cloudfrontStatus;
  public self;
  public backbtn: boolean;
  public service = {};
  public hairScale = 1;
  public dimmer = false;
  public temp: any = { user_email: '' };
  public loaderToShow: any = null;
  public hereUrl = '/'; // 페이지 이동시 URL 값
  public isOpenSideMenu = false;
  public isSplitPane: boolean;
  public loginStatus: boolean;
  public anonymousCountry: any;
  public isAdmin: boolean;
  public isPaidMember: boolean;
  public userRoleWithSubscription: UserRoleWithSubscriptionType;
  public isDonation: boolean;
  public openWindow = null;
  public usedLanguage;
  public modal = null;
  public popover = null;
  public languageKey = LanguageKey.ko;
  public languageKeyV2 = LanguageKeyV2.ko;
  public languageKeyV3 = LanguageKeyV3.ko;
  public languageKeyV4 = LanguageKeyV4.ko;
  public languageTypeList = [LanguageType.ko, LanguageType.en, LanguageType.fr, LanguageType.jp];
  public worker;

  // 로그인 상태 구독용
  public loginStatusSubject = new BehaviorSubject<boolean>(false);
  public loginStatus$ = this.loginStatusSubject.asObservable();

  // 업데이트 관련
  public update = {
    working: false,
    need_update: false,
    progress: 0,
    ready_to_reload: false,
    show_update_button: false,
    next_time: false,
    request_update: false,
    updating: false
  };
  public green = this.greenLog;
  public red = this.redLog;
  public blue = this.blueLog;
  public canvasList = [];
  public isIonBackdrop = false;
  public isIonBackdropOpacity0 = false;

  public sortableJsOptions: object = { filter: '.filter', preventOnFilter: true };

  // S3에 올려져 있는  https://notion-link.s3.ap-northeast-2.amazonaws.com/notionLink 설정
  public s3JsonData: any;

  public sourceAuthorType: any;
  public demoClass: DemoClass;
  public changeLanguageService: ChangeLanguageService;
  public isMaintenanceNotify: boolean = false;
  public isNotify: boolean = false;
  public isCountDownNotify: boolean = false;
  public isTopbar: boolean = false;
  public isSessionClose: boolean = false;
  public isUpdate: boolean = false;
  public dueMaintenanceDate: string;
  public startMaintenanceDate: string;
  public uniqueNumberList: number[] = [];
  public googleButtonNum: number = 0;
  public serviceID: string = ''; // 라우터를 통한 페이지의 ID - 서비스를 구분하기 위한 키
  private loadingTimerManager: LoadingTimerManager;
  public currentService: ServiceType = ServiceType.Editor;
  public isDemo: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _cache: CacheService,
    private _user: UserService,
    public plt: Platform,
    public toastController: ToastController,
    public navCtrl: NavController,
    public loadingController: LoadingController,
    private deviceService: DeviceDetectorService,
    private imageCompress: NgxImageCompressService,
    private ngZone: NgZone,
    public googleService: GoogleService,
    public analyticsService: AnalyticsService,
    private alertController: AlertController,
    private translate: TranslateService,
    public paymentService: PaymentService,
    private kakao: KakaoServiceService,
    public route: ActivatedRoute
  ) {
    //최초 로그인 시 인터넷이 일시적으로 접속 불가능하다면 alertController 를 lazy loading 할 수 없으므로 미리로딩해 둔다.
    //networkStatusService 구현 시, 사용한 패턴
    this.alertController.create({ animated: false }).then(async (alert) => {
      await alert.present();
      await alert.dismiss();
    });
    this.loadingTimerManager = LoadingTimerManager.getInstance();
  }

  get user() {
    return this._user;
  }

  get cache() {
    return this._cache;
  }

  /**
   * 로그인 유저 정보 캐쉬 처리
   * @param {string} token
   * @param {string} kind
   * @returns {Promise<void>}
   */
  async loginCacheSetting(token: string, kind: UserLoginType): Promise<void> {
    // client 로그인 처리
    await this.user.login(token, kind);
    await this.user.fetch();
    this.cache.user = await this.user.currentUser();
    this.loginStatus = true;
    this.loginStatusSubject.next(true);
    this.orange(this.loginStatus);
  }

  /**
   * s3데이터 검증하는 함수
   * @param link 이동하고자 하는 링크
   */
  public s3LinkGoValidat(link) {
    try {
      if (link === undefined) {
        this.showToast(this.translate.instant('warning 1')).then((r) => {});
        return;
      }
      this.goExternal(link);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 서비스 이용약관 페이지로 이동시켜주는 함수
   * @returns {Promise<void>}
   */
  public async termsAndUse() {
    try {
      this.analyticsService.termsAndUse();
      this.goExternal('/terms-of-use');
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 개인정보방침 페이지로 이동시켜주는 함수
   * @returns {Promise<void>}
   */
  public async privacyPolicy() {
    try {
      this.analyticsService.privacyPolicy();
      this.goExternal('/privacy-statement');
    } catch (e) {
      console.error(e);
    }
  }

  public async copyRight() {
    try {
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.copyRight[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 헬프센터로 이동
   * @param {boolean} isNewTab
   * @return {void}
   */
  public goHelpCenter(isNewTab: boolean = false): void {
    if (isNewTab) this.goExternal('/help-center/editor');
    else this.go('/help-center/editor');
  }

  public async serviceIntroductionLinkGeneral() {
    try {
      this.analyticsService.general();
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.serviceIntroductionLink.general[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  public async serviceIntroductionLinkEducation() {
    try {
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.serviceIntroductionLink.education[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  public async serviceIntroductionLinkUseCases() {
    try {
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.serviceIntroductionLink.useCases[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  public async serviceIntroductionLinkDigitalTextbook() {
    try {
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.serviceIntroductionLink.digitalTextbook[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  public async serviceIntroductionLinkBusiness() {
    try {
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.serviceIntroductionLink.business[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 랜딩페이지 기업용 문의 클릭 시 구글 타입폼 링크로 이동 및 GA askB2BCharacter 트래킹
   * @return {Promise<void>}
   */
  public async typeformLinkBusiness(): Promise<void> {
    try {
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.typeformLink.business[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  async goNotionVersionCommunity() {
    try {
      this.analyticsService.community();
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.community[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 단체 Pro 타입폼 페이지 열기
   * @return {Promise<void>}
   */
  async goTypeFormBusinessGroup(): Promise<void> {
    try {
      await this.s3JsonDataUndefine();
      this.s3LinkGoValidat(this.s3JsonData.typeformLink.businessGroup[this.usedLanguage]);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * s3 데이터를 못받았으면 다시 받기
   */
  public async s3JsonDataUndefine() {
    try {
      if (this.s3JsonData === undefined) {
        this.s3JsonData = await this.getS3JsonData(S3_JSON_DATA);
        console.log(this.s3JsonData);
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * s3 데이터를 받는 함수
   * @param {string} url
   * @return {any} JSON.parse() 가 any 를 리턴하기 때문에 리턴타입 any
   */
  async getS3JsonData(url: string): Promise<any> {
    try {
      const newestUrl = `${url}?v=${new Date().getTime()}`;
      const json = await this.http.get(newestUrl).pipe(shareReplay()).toPromise();
      return typeof json === 'string' ? JSON.parse(json) : json;
    } catch (e) {
      throw new TooningClientError(FILE_NAME + 'getS3JsonData' + e, null, true);
    }
  }

  public getEnv() {
    return environment;
  }

  /**
   * 라우터를 통해 페이지 이동
   * @param {string} url 이동할 페이지
   * @return {void}
   */
  public go(url: string): void {
    this.router.navigate([url]);
  }

  /**
   * 페이지 이동시 queryParams에 값을 넣어서 이동
   * @param {string} url 이동할 url
   * @param params 넣을 값정보
   * @return {void}
   */
  public goQueryParams(url: string, params: any): void {
    this.router.navigate([url], { queryParams: params });
  }

  /**
   * 이전 페이지를 현재 페이지로 대체
   * @param {string} url 대체 하고싶은 url
   * @return {void}
   */
  public goReplace(url: string): void {
    this.router.navigate([url], { replaceUrl: true });
  }

  public goExternalLocal(fullUrl) {
    window.location.replace(fullUrl);
  }

  /**
   * 새 탭에서 링크 열기
   * @param {string} fullUrl
   * @return {void}
   */
  public goExternal(fullUrl: string): void {
    this.openWindow = window.open(fullUrl, '_blank');
  }

  public go_none(url) {
    // 애니메이션 없이 이동
    this.navCtrl.navigateRoot([url]);
  }

  // https://fourcut-admin-web.s3-website.ap-northeast-2.amazonaws.com
  public redirectLogin() {
    console.log('will redirectLogin');
    if (this.router) {
      if (this.router.url) {
        if (this.router.url !== '/login' && this.router.url !== 'login') {
          this.cache.setRedirect(this.router.url);
        }

        console.log('will redirectLogin url - ' + this.router.url);
      }
    }
    window.localStorage.removeItem('token');
    this.go('/login');
  }

  async loading(message?) {
    const loading = await this.loadingController.create({
      message: message || '조회중..',
      cssClass: 'custom-loading'
    });
    await loading.present();
    return loading;
  }

  // 브라우저 환경 이면 false - 네이티브 환경이면 true

  public historyBack() {
    this.backbtn = false;
    window.history.back();
  }

  public getPlatform() {
    return Capacitor.getPlatform();
  }

  // 넒이 반환
  public isDesktopWidth() {
    let returnData;
    if (this.plt.width() > 767) {
      returnData = true;
    } else {
      returnData = false;
    }
    return returnData;
  }

  public isTableWidth() {
    let returnData;
    if (this.plt.width() > 767 && this.plt.width() < 1025 && this.isTablet()) {
      returnData = true;
    } else {
      returnData = false;
    }
    return returnData;
  }

  public isPropertToolbar() {
    let returnData;
    if (this.plt.width() > 767 && this.plt.width() < 1350 && this.isTablet()) {
      returnData = true;
    } else {
      returnData = false;
    }
    return returnData;
  }

  public isWidthMin(size) {
    let returnData;
    if (this.plt.width() < size) {
      returnData = true;
    } else {
      returnData = false;
    }
    return returnData;
  }

  public isScreenWidthGreaterThan(size) {
    return this.plt.width() > size;
  }

  /**
   * 웹인지 앱인지 구분
   * * | Platform Name   | Description                        |
   * |-----------------|------------------------------------|
   * | android         | on a device running Android.       |
   * | cordova         | on a device running Cordova.       |
   * | ios             | on a device running iOS.           |
   * | ipad            | on an iPad device.                 |
   * | iphone          | on an iPhone device.               |
   * | phablet         | on a phablet device.               |
   * | tablet          | on a tablet device.                |
   * | electron        | in Electron on a desktop device.   |
   * | pwa             | as a PWA app.                      |
   * | mobile          | on a mobile device.                |
   * | mobileweb       | on a mobile device in a browser.   |
   * | desktop         | on a desktop device.               |
   * | hybrid          | is a cordova or capacitor app.     |
   */
  public isApp() {
    return this.getPlatform() !== 'web';
  }

  /**
   * 브라우져가 Safari 인지 아닌지 확인 하다.
   * @return {boolean}
   */
  public isSafari(): boolean {
    return (navigator.vendor.match(/apple/i) || '').length > 0;
  }

  public isDesktop() {
    if (!this.plt.is('desktop')) {
      // 터치를 지원하는 노트북(삼성 노트북)을 모바일로 인식하는 문제로 추가
      if (this.isMobile() && this.isDesktopWidth() && !this.isTablet()) {
        return true;
      }
    }

    return this.plt.is('desktop');
  }

  public isIos() {
    return this.plt.is('ios');
  }

  public isIpad() {
    return this.plt.is('ipad');
  }

  public isIphone() {
    return this.plt.is('iphone');
  }

  public isAndroid() {
    return this.plt.is('android');
  }

  public isTablet() {
    return this.plt.is('tablet');
  }

  // 모바일 - 브라우저나 앱
  public isMobile() {
    return this.plt.is('mobile');
  }

  public isHybrid() {
    return this.plt.is('hybrid');
  }

  /**
   * 해외 접속 유저인지 아닌지 체크
   */
  public isOverseasUser() {
    const currentCountry = this.cache.user?.country ?? this.anonymousCountry;

    return currentCountry !== CountryCode.kr && currentCountry !== CountryCode.dev;
  }

  /**
   * Return Currency Code Using from Tooning
   * KRW, USD, EUR, JPY
   * https://ko.wikipedia.org/wiki/ISO_4217
   */
  public getCurrencyCode() {
    const currentCountry = this.cache.user?.country ?? this.anonymousCountry;

    if (currentCountry === CountryCode.kr || currentCountry === CountryCode.dev) {
      return Currency.KRW;
    } else if (currentCountry === CountryCode.fr) {
      return Currency.EUR;
    } else if (currentCountry === CountryCode.jp) {
      return Currency.JPY;
    } else if (currentCountry === CountryCode.ca) {
      return Currency.CAD;
    } else {
      return Currency.USD;
    }
  }

  public hasDecimalPrice() {
    const currentCountry = this.getCurrencyCode();
    if (currentCountry === Currency.USD || currentCountry === Currency.EUR || currentCountry === Currency.CAD) {
      return true;
    }
    return false;
  }

  public isBrowserCheck() {
    const getDeviceInfo = this.deviceService.getDeviceInfo();
    // getDeviceInfo.browser
    return getDeviceInfo.browser;
  }

  public getDeviceInfo() {
    const getDeviceInfo = this.deviceService.getDeviceInfo();
    // getDeviceInfo.browser
    return getDeviceInfo;
  }

  public async showToast(Content, Duration = 2000, Color = 'dark', Position = 'bottom'): Promise<void> {
    const toast = await this.toastController.create({
      message: Content,
      // @ts-ignore
      position: Position,
      color: Color,
      duration: Duration
    });
    toast.style.cssText = 'text-align: center';
    await toast.present();
  }

  public async getPixabayPhoto(searchWord: string, options: string): Promise<any> {
    // q=yellow+flowers
    const query = `q=${encodeURIComponent(searchWord)}`;

    // @ts-ignore
    return fetch(`${environment.pixabay_url}&${options}&${query}`).then((response) => response.json());
  }

  public hideLoader() {
    this.loadingController.dismiss();
  }

  public orange(message) {
    if (environment.deploy !== Deployment.oper) {
      message = JSON.stringify(message, null, 4);
      // logBeautify.warn(message);
    }
  }

  public greenLog(message) {
    if (environment.deploy !== Deployment.oper) {
      message = JSON.stringify(message, null, 4);
      // logBeautify.success(message);
    }
  }

  public trace(message) {
    if (environment.deploy !== Deployment.oper) {
      // logBeautify.trace(message);
    }
  }

  public yellow(message) {
    if (environment.deploy !== Deployment.oper) {
      message = JSON.stringify(message, null, 4);
      // logBeautify.info(message);
    }
  }

  public pink(message) {
    if (environment.deploy !== Deployment.oper) {
      message = JSON.stringify(message, null, 4);
      // logBeautify.info(message);
    }
  }

  public redLog(message) {
    if (environment.deploy !== Deployment.oper) {
      message = JSON.stringify(message, null, 4);
      // logBeautify.warn(message);
    }
  }

  public blueLog(message) {
    if (environment.deploy !== Deployment.oper) {
      message = JSON.stringify(message, null, 4);
      // logBeautify.info(message);
    }
  }

  public tableLog(message) {
    if (environment.deploy !== Deployment.oper) {
      // console.table(message);
    }
  }

  public shoutOut(message) {
    if (environment.deploy !== Deployment.oper) {
      // logBeautify.show(message);
    }
  }

  // 딜레이 시키는 함수
  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(null), ms)).then(() => console.log('fired'));
  }

  loadingAllhide() {
    this.loadingTimerManager.removeTimer();
    const loadingAll = document.getElementsByClassName('_loadingClass');
    if (loadingAll.length != 0) {
      for (const i in loadingAll) {
        // @ts-ignore
        try {
          loadingAll[i].remove();
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }

  /**
   * 로그아웃
   * @param {boolean} showToast
   * @return {Promise<void>}
   */
  async logout(showToast = true): Promise<void> {
    try {
      this.canvasList = [];
      const kind = window.localStorage.getItem('kind');
      let isKakao = false;

      switch (kind) {
        case UserLoginType.google:
          try {
            this.orange('google logout');
            await this.googleService.logout();
          } catch (e) {
            throw new TooningLogOutError(e, this, false);
          }
          break;
        case UserLoginType.kakao:
          try {
            await this.kakao.logout();
          } catch (e) {
            throw new TooningLogOutError(e, this, false);
          } finally {
            isKakao = true;
          }
          break;
      }
      this.loginStatus = false;
      this.loginStatusSubject.next(false);
      this.isAdmin = false;
      this.isPaidMember = false;
      this.userRoleWithSubscription = UserRoleWithSubscriptionType.FreeUser;
      this.cache.removeRedirect();
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('user');
      window.localStorage.removeItem('kind');
      window.localStorage.removeItem('nextSequenceToken');
      window.localStorage.removeItem('boardUser');

      // 로그 아웃하면 캐시에 있는 유저 정보도 삭제해야한다.
      // 캐시에 있는 유저 정보를 토대로 요청을 하지만 서버에는 로그아웃처리 되었기 때문에 401 code를 내뱉는다.
      // 따라서 캐시를 삭제하고 cache에 user가 있을때를 체킹하는 것이 좋아보인다.
      // delete this.cache.user
      this.cache.user = null;
      this.cache.volatile_datas.ready_order = [];
      this.cache.volatile_datas.coupon_upload_temp = [];

      if (showToast) {
        await this.showToast(this.translate.instant('logOut 2'));
      }

      if (this.isMagic()) {
        window.location.replace('/magic');
      } else if (this.isGpt()) {
        window.location.replace('/gpt/editor');
      } else if (this.isBoard()) {
        window.location.replace('/board');
      } else {
        window.location.replace('/');
      }
    } catch (e) {
      console.error(e);
    }
  }

  // app update 체크 후 업데이트
  public async checkUpdate() {
    console.log('UpdateComponent checkUpdate isApp:' + this.isApp());
  }

  // 업데이트 진행
  public async doUpdate() {
    this.update.show_update_button = false;
  }

  // 페이지 새로 고침
  public async reloadApp(): Promise<void> {
    console.log('reloadApp');
  }

  // 원시그널 관련 끝

  /**
   * merge-images 노드 모듈의 mergeImage 함수
   * @param {object[]} imageList merge할 image의 배열
   * @param {mergeImageOption} option image merge 시 적용 옵션
   * @return {Promise<string>} merge된 image의 base64 리턴
   */
  public mergeImage(imageList: object[], option: mergeImageOption): Promise<string> {
    return new Promise((resolve, reject) => {
      mergeImages(imageList, option)
        .then(async (b64) => {
          resolve(b64);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * 기본 alert 를 popup 한다.
   * @param message alert 에 출력될 메세지
   * @param {() => void} handler
   * @param {{backdropDismiss: boolean}} options 기본 옵션을 확장을 원하면 추가 옵션을 전달한다.
   *            backdropDismiss : false 이면, alert 주변이 click 되지 않는다.
   * @return {Promise<HTMLIonAlertElement>}
   */
  async presentAlert(message, handler = () => {}, options: { backdropDismiss: boolean } = { backdropDismiss: true }): Promise<HTMLIonAlertElement> {
    const ok = 'OK';
    const keyboard = 'keyboard';
    let defaultOptions = {
      backdropDismiss: true,
      header: this.translate.instant('notice'),
      cssClass: 'basic-dialog',
      message,
      buttons: [
        {
          role: ok,
          text: ok,
          handler: async (data) => {
            try {
              this.orange('presetAlert handler');
              await handler();
            } catch (e) {
              if (e instanceof TooningCustomUserUpdateHandlerError) {
                throw e;
              } else {
                throw new TooningPresentAlertError(e, null, true);
              }
            }
          }
        }
      ]
    };
    if (options) {
      defaultOptions = Object.assign(defaultOptions, options);
    }
    const alert = await this.alertController.create(defaultOptions);
    alert.onDidDismiss().then((data) => {
      try {
        this.orange(data.role);
        // 키보드로 dismiss ok를 보낸 경우만 핸들러 실행,
        // 마우스나 손으로 alert ok 한경우는 아래 콜백을 실행하지 않음
        if (data.data === keyboard) {
          this.orange('presetAlert onDidDismiss by keyboard');
          if (data.role === ok) {
            this.orange('presetAlert onDidDismiss role is ok');
            handler();
          } else {
            this.orange('presetAlert onDidDismiss role is not ok');
          }
        } else {
          this.orange('presetAlert onDidDismiss by click or touch');
        }
      } catch (e) {
        throw new TooningAlerOnDidDismissError(e, null, true);
      }
    });
    await alert.present();
    return alert;
  }

  async checkNetworkError(error, networkError) {
    if (networkError) {
      this.loadingAllhide(); // 로딩 떠 있으면 제거
      switch (networkError.status) {
        case HttpStatusCode.BAD_REQUEST:
          await this.showToast(this.translate.instant('bad request'));
          throw new TooningBadRequestError(error, this);
        case HttpStatusCode.UNAUTHORIZED:
          this.orange('unauthorized access');
          this.redirectLogin();
          throw new TooningUnauthorizedError(error, this);
        case TooningErrorCode.TOONING_UNKNOWN_NETWORK_ERROR:
          await this.showToast(this.translate.instant('unknown net work Err'));
          throw new TooningServerConnectionError(error, this, true);
        default:
          await this.showToast(error);
          throw new TooningUnkownNetworkError(error, this, true);
      }
    }
  }

  /**
   * 본인이 구매한 상품인 지 아닌 지 체크
   * return false - 구매한 상품
   * return true - 구매하지 않은 상품
   */
  isNotPurchased(item) {
    try {
      if (this.isAdmin) {
        return false;
      }

      // 기업용 캐릭터인 경우
      if (item.status === CharacterStatus.enterpriseView) {
        return false;
      }

      // 무료 상품인 경우
      if (item.sku === null) {
        return false;
      }

      // 구독자의 경우, outside 상품 중에서만 구매한 상품인 지 체크
      if (this.isPaidMember) {
        if (item.authorType === AuthorType.outside) {
          if (!item.sku.purchase) {
            return true;
          }
        }
      }

      // 구독자가 아닌 경우, 모든 상품에 대해 구매한 상품인 지 체크
      if (!this.isPaidMember) {
        if (!item.sku.purchase) {
          return true;
        }
      }

      return false;
    } catch (e) {
      console.error(`isNotPurchased : ${e}`);
    }
  }

  channelTalkShow() {
    if (!this.isMainPopup) {
      // @ts-ignore
      window.ChannelIO('showChannelButton');
      this.isChannelButton = true;
    }
  }

  channelTalkHide() {
    // @ts-ignore
    // window.ChannelIO('shutdown');
    window.ChannelIO('hideChannelButton');
    this.isChannelButton = false;
  }

  public shouldHavePoint() {
    const currentCountry = this.getCurrencyCode();
    if (currentCountry === Currency.USD || currentCountry === Currency.EUR) {
      return true;
    }
    return false;
  }

  getDefaultLanguage(): LanguageType {
    try {
      let defaultLanguage;
      if (/^ko\b/.test(navigator.language)) {
        defaultLanguage = LanguageType.ko;
      } else if (this.isFrench()) {
        defaultLanguage = LanguageType.fr;
      } else if (this.isJapan()) {
        defaultLanguage = LanguageType.jp;
      } else {
        defaultLanguage = LanguageType.en;
      }

      return defaultLanguage;
    } catch (e) {
      throw new Error(`getDefaultLanguage : ${e}`);
    }
  }

  isFrench(): boolean {
    try {
      return /^(fr|fr-BE|fr-CA|fr-CH|fr-LU)\b/.test(navigator.language);
    } catch (e) {
      throw new Error(`isFrench : ${e}`);
    }
  }

  isJapan(): boolean {
    try {
      return /^(ja|jp|ja-JP)\b/.test(navigator.language);
    } catch (e) {
      throw new Error(`isJapan : ${e}`);
    }
  }

  /**
   * @param allFonts 서버에서 내려준 Font 정보 리스트
   * @param fontName 언어별 폰트 이름
   * @returns {(string|void)}
   */
  getFontNameByUsedLanguage(allFonts: Array<any>, fontName: string): string {
    try {
      if (fontName === 'Nanum Square Regular') {
        fontName = 'NanumSquare Regular';
      }
      if (!allFonts || allFonts.length === 0) {
        console.error(`allFonts list is empty`);
        throw new Error(`allFonts list is empty`);
      }
      if (!fontName) {
        console.error(`fontName is empty`);
        throw new Error(`fontName is empty`);
      }

      // tslint:disable-next-line:no-shadowed-variable
      this.setLanguageKey(this.usedLanguage);
      let font = allFonts
        .filter((fontObject) => {
          if (fontObject.enName === fontName || fontObject.koName === fontName || fontObject.jpName === fontName || fontObject.frName === fontName) {
            return fontObject;
          }
        })
        .pop();
      // 폰트가 없는 케이스
      if (!font) {
        console.log(`Can not find ${fontName}`);
        //나눔 고딕 regular 기본 폰트로 세팅
        font = allFonts[52];
      }
      const fontFamilyNameByUsedLanguage = font[this.languageKeyV4];
      if (!fontFamilyNameByUsedLanguage) {
        console.log(`fontFamily name is empty`);
        throw new Error(`fontFamily name is empty`);
      }
      return fontFamilyNameByUsedLanguage;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  setLanguageKey(usedLanguage: LanguageType) {
    try {
      switch (usedLanguage) {
        case LanguageType.ko:
          this.languageKey = LanguageKey.ko;
          this.languageKeyV2 = LanguageKeyV2.ko;
          this.languageKeyV3 = LanguageKeyV3.ko;
          this.languageKeyV4 = LanguageKeyV4.ko;
          break;
        case LanguageType.en:
          this.languageKey = LanguageKey.en;
          this.languageKeyV2 = LanguageKeyV2.en;
          this.languageKeyV3 = LanguageKeyV3.en;
          this.languageKeyV4 = LanguageKeyV4.en;
          break;
        case LanguageType.fr:
          this.languageKey = LanguageKey.fr;
          this.languageKeyV2 = LanguageKeyV2.fr;
          this.languageKeyV3 = LanguageKeyV3.fr;
          this.languageKeyV4 = LanguageKeyV4.fr;
          break;
        case LanguageType.jp:
          this.languageKey = LanguageKey.jp;
          this.languageKeyV2 = LanguageKeyV2.jp;
          this.languageKeyV3 = LanguageKeyV3.jp;
          this.languageKeyV4 = LanguageKeyV4.jp;
          break;
      }
    } catch (e) {
      throw new Error(`setLanguageType : ${e}`);
    }
  }

  // 리소스 사이즈 스케일 줄이는 함수
  public resizeImageFn(base64Str, maxWidth = 200, maxHeight = 200): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  }

  // max-age 초단위
  public setCookie(
    name,
    value,
    options?: {
      secure?: any;
      path?: any;
      expires?: any;
      'max-age'?: any;
    }
  ) {
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    // tslint:disable-next-line:forin
    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  public getCookie(name) {
    const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  /**
   * sortableJsOptions 세팅해주는 함수
   * ngonIt 쪽에서 호출, 도중에 에러 발생했을때 대비 디폴트 값 { filter: '.filter', preventOnFilter: true };
   * @return {void}
   */
  public setSortableJsOptions(): void {
    if (this.isTablet()) {
      this.sortableJsOptions = {
        filter: '.filter',
        preventOnFilter: true,
        delay: 100,
        scroll: false,
        handle: '.handle'
      };
    } else if (this.isDesktop()) {
      this.sortableJsOptions = { filter: '.filter', preventOnFilter: true };
    } else {
      this.sortableJsOptions = { handle: '.handle', filter: '.filter', preventOnFilter: true };
    }
  }

  /**
   * 페이플 유저인지 체크하는 함수
   * @return {Promise<boolean>}
   */
  public async checkPaypelUser(): Promise<boolean> {
    try {
      const checkPaypel = await this.paymentService.isPaypel(+this.cache.user.id).toPromise(); // 서버쪽에서 페이플인지 확인해서 넘겨주는 코드 필요
      return checkPaypel.data.isPaypel;
    } catch (e) {
      throw new TooningCheckPaypelUserError(e);
    }
  }

  async navigateToMagicWithParam(param: string) {
    await this.router.navigate(['/magic/admin', param]);
  }

  async navigateToMagicCategoryWithParam(category: string, param: string) {
    await this.router.navigate([`/magic/admin/${category}`, param]);
  }

  /**
   * 이미지 메타 데이터 가져오는 함수
   * @param {string} url 이미지 url
   * @return {Promise<HTMLImageElement>}
   */
  public getImageMetaData(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  }

  /**
   * value 가 boolean type 인지 체크
   * @param value
   * @return {boolean}
   */
  public isBoolean(value: any): boolean {
    return _.isBoolean(value);
  }

  /**
   * value 가 boolean type 인지 체크 하고 실제 값이 true 인지 체크하는 함수
   * @param {any} value
   * @return {boolean}
   */
  public isBooleanTrue(value: any): boolean {
    return !!(_.isBoolean(value) && value);
  }

  /**
   * 현재 페이지가 매직인지 체크
   * @return {boolean}
   */
  public isMagic(): boolean {
    if (this.router.url.includes(MAGIC)) {
      return true;
    }
    return false;
  }

  /**
   * 현재 페이지가 GPT 인지 체크
   * @returns {boolean}
   */
  public isGpt(): boolean {
    return this.router.url.includes(ServiceType.Gpt);
  }

  /**
   * Checks if the current service is a studio.
   *
   * @returns {boolean} - True if the current service is a studio, false otherwise.
   */
  public isStudio(): boolean {
    return this.RootServiceType === DomainType.Studio;
  }

  /**
   * 현재 페이지가 보드 인지 체크
   * @returns {boolean}
   */
  public isBoard(): boolean {
    return this.router.url.includes(ServiceType.Board);
  }

  /**
   * 서비스타입 체크
   * @return {ServiceType}
   */
  checkServiceType(): ServiceType {
    try {
      if (this.route.snapshot.queryParams.isMagic) {
        return ServiceType.Magic;
      }

      switch (true) {
        case this.serviceID === ServiceType.Studio || this.router.url.includes(ServiceType.Studio):
          return ServiceType.Studio;
        case this.serviceID === ServiceType.Magic || this.router.url.includes(ServiceType.Magic):
          return ServiceType.Magic;
        case this.serviceID === ServiceType.Gpt || this.router.url.includes(ServiceType.Gpt):
          return ServiceType.Gpt;
        case this.serviceID === ServiceType.Board || this.router.url.includes(ServiceType.Board):
          return ServiceType.Board;
        default:
          return ServiceType.Editor;
      }
    } catch (e) {
      throw new MagicCheckServiceTypeError(e);
    }
  }

  /**
   * 로딩창이 떠있는지 여부 리턴
   * @return {boolean}
   */
  public isLoading(): boolean {
    const loadingAll = document.getElementsByClassName('_loadingClass');
    return loadingAll.length !== 0;
  }

  changeFormatServerRegionToCountry(serverRegion) {
    let result;
    switch (serverRegion) {
      case ServerRegion.ca:
        result = CountryCode.ca;
        break;
      case ServerRegion.us:
        result = CountryCode.us;
        break;
      case ServerRegion.kr:
        result = CountryCode.kr;
        break;
      case ServerRegion.sg:
        result = CountryCode.sg;
        break;
      default:
        result = this.user.getUserCountry();
        break;
    }
    return result;
  }

  // 하단foot 함수
  async linkedinGo() {
    this.goExternal('https://www.linkedin.com/company/toonsquare/');
  }

  async facebookGo() {
    await this.s3JsonDataUndefine();
    this.s3LinkGoValidat(this.s3JsonData.footerLink.facebook[this.usedLanguage]);
  }

  async instagramGo() {
    await this.s3JsonDataUndefine();
    this.s3LinkGoValidat(this.s3JsonData.footerLink.instagram[this.usedLanguage]);
  }

  async youtubeGo() {
    await this.s3JsonDataUndefine();
    this.s3LinkGoValidat(this.s3JsonData.footerLink.youtube[this.usedLanguage]);
  }

  async discordGo() {
    this.goExternal('https://discord.gg/GWgcdnveZn');
  }
}
