import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { GraphqlApiService } from '../api/graphql.api.service';
import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { Observable } from 'rxjs';
import { BookmarkResourceType } from '../../directive/bookmark/bookmark';
import { map, tap } from 'rxjs/operators';
import { BookmarkTypes } from '../../interfaces/app.interface';
import { InputBookmarkAndResourceList, InputBookmarkListByCharacter } from '../../model/common/paging';
import { Sku } from '../../model/sku/sku';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  public totalBookmarkCount: number = 0;
  public bookmarkResourceTypes: BookmarkTypes;
  constructor(private graphql: GraphqlApiService, public app: AppService) {}

  /**
   * 북마크 추가 쿼리 요청 함수
   * @param inputAddBookmark
   * @return {Observable<FetchResult<any>>}
   */
  public addBookmark(inputAddBookmark: any): Observable<FetchResult<any>> {
    return this.graphql.mutate(
      gql`
        mutation addBookmark($inputAddBookmark: InputAddBookmark!) {
          addBookmark(inputAddBookmark: $inputAddBookmark) {
            bookmarkId
            resourceId
            resourceType
          }
        }
      `,
      { inputAddBookmark }
    );
  }

  /**
   * 북마크 삭제 쿼리 요청 함수
   * @param {number} id
   * @return {Observable<FetchResult<{deleteBookmark: boolean}>>}
   */
  public deleteBookmark(id: number): Observable<FetchResult<{ deleteBookmark: boolean }>> {
    return this.graphql.mutate(
      gql`
        mutation deleteBookmark($id: Float!) {
          deleteBookmark(id: $id)
        }
      `,
      { id }
    );
  }

  /**
   * 리소스 타입 기준으로 북마크 정보 가져오는 쿼리 요청 함수
   * @param {BookmarkResourceType} resourceType
   * @param {number[]} resourceIds
   * @return {Observable<ApolloQueryResult<{getBookmarkedListByResources: Array<{bookmarkId: number, resourceId: number, resourceType: BookmarkResourceType}>}>>}
   */
  public getBookmarkedListByResources(
    resourceType: BookmarkResourceType,
    resourceIds: number[]
  ): Observable<
    ApolloQueryResult<{ getBookmarkedListByResources: Array<{ bookmarkId: number; resourceId: number; resourceType: BookmarkResourceType }> }>
  > {
    const inputBookmarkedListByResources: { resourceType: BookmarkResourceType; resourceIds: number[] } = {
      resourceType: resourceType,
      resourceIds: resourceIds
    };
    if (inputBookmarkedListByResources.resourceIds.length === 0) {
      return;
    }
    return this.graphql.query(
      gql`
        query getBookmarkedListByResources($inputBookmarkedListByResources: InputBookmarkedListByResources!) {
          getBookmarkedListByResources(inputBookmarkedListByResources: $inputBookmarkedListByResources) {
            bookmarkId
            resourceId
            resourceType
          }
        }
      `,
      {
        inputBookmarkedListByResources
      }
    );
  }

  /**
   * 유저의 총 즐겨찾기 리소스 수를 가져옵니다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getBookmarkCount(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getBookmarkCount {
            getBookmarkCount
          }
        `
      )
      .pipe(
        tap((results) => {
          results.data = results.data.getBookmarkCount;
          this.totalBookmarkCount = results.data;
          console.log(results.data);
          return results;
        })
      );
  }

  /**
   * 유저의 즐겨찾기 리소스 타입을 가져옵니다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getBookmarkResourceTypes(): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getBookmarkResourceTypes {
            getBookmarkResourceTypes {
              isCharacter
              isText
              isTemplate
              isItem
              isBalloon
              isBackground
              isEffect
            }
          }
        `
      )
      .pipe(
        tap((results) => {
          results.data = results.data.getBookmarkResourceTypes;
          this.bookmarkResourceTypes = results.data;
          return results;
        })
      );
  }

  /**
   * 유저의 즐겨찾기 리소스 타입을 가져옵니다.
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getBookmarkAndResourceList(inputBookmarkAndResourceList: InputBookmarkAndResourceList): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getBookmarkAndResourceList($inputBookmarkAndResourceList: InputBookmarkAndResourceList!) {
            getBookmarkAndResourceList(inputBookmarkAndResourceList: $inputBookmarkAndResourceList) {
              paging {
                totalCount
                last
              }
              list {
                bookmarkId
                resourceId
                resourceType
                template {
                  id
                  title
                  updatedDate
                  base64
                  language
                  pageCount
                  canvas {
                    id
                    pages {
                      id
                      base64
                    }
                  }
                }
                character {
                  id
                  status
                  name_ko
                  desc_ko
                  name_en
                  desc_en
                  name_fr
                  desc_fr
                  name_jp
                  desc_jp
                  imgPath
                  order
                  style
                  authorType
                  sku {
                    id
                    point
                    purchase {
                      id
                    }
                  }
                }
                text {
                  id
                  base64
                  fabricObj
                  paid
                  title
                }
                balloon {
                  sourceId
                  sourceType
                  base64
                }
                item {
                  sourceId
                  sourceType
                  base64
                  sku {
                    id
                    point
                    purchase {
                      id
                    }
                  }
                }
                effect {
                  sourceId
                  sourceType
                  base64
                }
                background {
                  sourceId
                  sourceType
                  base64
                  sku {
                    id
                    point
                    purchase {
                      id
                    }
                  }
                }
              }
            }
          }
        `,
        {
          inputBookmarkAndResourceList
        }
      )
      .pipe(
        map((results) => {
          const favoriteList = results.data.getBookmarkAndResourceList.list.map((item) => {
            if (item.character && item.character.imgPath) {
              item.character.imgPath = item.character.imgPath + '/400x';
            }
            if (item.character?.sku != null) {
              item.character.sku = new Sku().deserialize(item.character.sku);
              return item;
            } else if (item.item?.sku != null) {
              item.item.sku = new Sku().deserialize(item.item.sku);
              return item;
            } else if (item.background?.sku != null) {
              item.background.sku = new Sku().deserialize(item.background.sku);
              return item;
            }
            return item;
          });
          return favoriteList;
        })
      );
  }

  /**
   * 유저의 캐릭터 즐겨찾기 목록을 가져옵니다
   * @returns {Observable<ApolloQueryResult<any>>}
   */
  getBookmarkAndResourceListByCharacter(inputBookmarkListByCharacter: InputBookmarkListByCharacter): Observable<ApolloQueryResult<any>> {
    return this.graphql
      .query(
        gql`
          query getBookmarkAndResourceListByCharacter($inputBookmarkListByCharacter: InputBookmarkListByCharacter!) {
            getBookmarkAndResourceListByCharacter(inputBookmarkListByCharacter: $inputBookmarkListByCharacter) {
              paging {
                totalCount
                last
              }
              list {
                bookmarkId
                id
                status
                name_ko
                desc_ko
                name_en
                desc_en
                name_fr
                desc_fr
                name_jp
                desc_jp
                imgPath
                order
                style
                authorType
                sku {
                  id
                  point
                  purchase {
                    id
                  }
                }
                categoryId
                categoryKoName
                categoryEnName
                categoryFrName
                categoryJpName
              }
            }
          }
        `,
        {
          inputBookmarkListByCharacter
        }
      )
      .pipe(
        map((results) => {
          const bookmarkList = results.data.getBookmarkAndResourceListByCharacter.list.map((item) => {
            if (item && item.imgPath) {
              item.imgPath = item.imgPath + '/400x';
            }
            if (item?.sku != null) {
              item.sku = new Sku().deserialize(item.sku);
              return item;
            }
            return item;
          });
          return bookmarkList;
        })
      );
  }
}
