import { Injectable } from '@angular/core';
import { LanguageOriginalType, LanguageType } from '../enum/app.enum';
import { AppService } from './app.service';
import { TemplateService } from './template/template.service';
import { AnalyticsService } from './google/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangeLanguageService {
  public languageOriginalEnum = LanguageOriginalType;
  public usedLanguageSubject$ = new Subject<string>();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private app: AppService,
    private templateService: TemplateService,
    private analyticsService: AnalyticsService,
    private alertController: AlertController
  ) {}

  /**
   * 언어 변경 처리
   * @param language - ko-en-fr-jp
   * @returns {Promise<void>}
   */
  async changeLanguage(language) {
    if (await this.languageChangeBlockInGpt(language)) {
      return;
    }
    if (await this.languageChangeBlockInBoard(language)) {
      return;
    }
    if (this.app.cache.getWorkingLanguage() !== language) {
      this.analyticsService.changeLanguage(language);
    }
    this.app.cache.setWorkingLanguage(language);
    this.app.usedLanguage = language;
    this.usedLanguageSubject$.next(this.app.usedLanguage);

    if (this.app.usedLanguage === LanguageType.jp) {
      document.getElementsByTagName('html')[0].lang = LanguageType.ja;
    } else {
      document.getElementsByTagName('html')[0].lang = this.app.usedLanguage;
    }

    await this.translate.use(language).toPromise(); // return 값은 JSON 나옴
    this.app.setLanguageKey(this.app.usedLanguage);

    if (this.router.url.includes('/template-list/')) {
      this.templateService.isLanguageChange = true;
      this.templateService.defaultCategory = false;
      this.templateService.templateSearch$.next({ skip: 0 });
    }

    if (this.app.usedLanguage === LanguageType.jp) {
      document.getElementsByTagName('html')[0].lang = LanguageType.ja;
    } else {
      document.getElementsByTagName('html')[0].lang = this.app.usedLanguage;
    }
  }

  async languageChangeBlockInGpt(language) {
    let result = false;
    if (this.app.serviceID === 'gpt' && language !== LanguageType.ko) {
      result = true;
      const alert = await this.alertController.create({
        header: this.translate.instant('NOTICE'),
        message: this.translate.instant('gpt-used-only-kr'),
        buttons: [
          {
            text: this.translate.instant('header'),
            role: 'cancel'
          }
        ]
      });
      await alert.present();
    }
    return result;
  }

  async languageChangeBlockInBoard(language) {
    let result = false;
    if (this.app.isBoard() && language !== LanguageType.ko) {
      result = true;
      const alert = await this.alertController.create({
        header: this.translate.instant('NOTICE'),
        message: this.translate.instant('board-used-only-kr'),
        buttons: [
          {
            text: this.translate.instant('header'),
            role: 'cancel'
          }
        ]
      });
      await alert.present();
    }
    return result;
  }

  /**
   * 언어 변경을 alert 으로 뛰우는 형태
   * @returns {Promise<HTMLIonAlertElement>}
   */
  async alertChangeLanguage() {
    let cssClass = 'language-change-alert';
    if (this.router.url.includes('magic/editor')) cssClass += ' magic';

    const alert = await this.alertController.create({
      cssClass,
      mode: 'md',
      header: this.translate.instant('language setting'),
      inputs: [
        {
          label: '한국어',
          type: 'radio',
          value: LanguageType.ko,
          checked: this.app.usedLanguage === LanguageType.ko
        },
        {
          label: 'English',
          type: 'radio',
          value: LanguageType.en,
          checked: this.app.usedLanguage === LanguageType.en
        },
        {
          label: 'Français',
          type: 'radio',
          value: LanguageType.fr,
          checked: this.app.usedLanguage === LanguageType.fr
        },
        {
          label: '日本語',
          type: 'radio',
          value: LanguageType.jp,
          checked: this.app.usedLanguage === LanguageType.jp
        }
      ],
      buttons: [
        {
          text: this.translate.instant('CANCEL'),
          role: 'cancel',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        },
        {
          text: this.translate.instant('HEADER'),
          role: 'ok',
          handler: async (data) => {
            await this.changeLanguage(data);
          }
        }
      ]
    });
    await alert.present();
    return alert;
  }
}
