import { Component, OnInit, HostListener, Input, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppService } from '../../services/app.service';
import { LanguageType } from '../../enum/app.enum';
import { HelpCenterSearchModalComponent } from '../help-center-search-modal/help-center-search-modal.component';

@Component({
  selector: 'app-help-center-button',
  templateUrl: './help-center-button.component.html',
  styleUrls: ['./help-center-button.component.scss']
})
export class HelpCenterButtonComponent implements OnChanges {
  @Input() currentPath: string;
  constructor(public app: AppService, private modalCtrl: ModalController) {}

  public languageType = LanguageType;
  public longLanguageList = [this.languageType.en, this.languageType.fr];
  public isOpenWebSearchComponent = false;
  public isOpenMobileSearchModal = false;

  ngOnChanges() {
    this.isOpenWebSearchComponent = false;
    this.isOpenMobileSearchModal = false;
  }

  /**
   * 헬프센터 버튼 클릭 웹 기준 버튼 상단에 검색창이 뜨고, 모바일 기준은 모달이 뜬다.
   * @return {Promise<void>}
   */
  async clickHelpCenterButton(): Promise<void> {
    if (this.app.isDesktopWidth()) {
      this.isOpenWebSearchComponent = !this.isOpenWebSearchComponent;
      return;
    }

    await this.openSearchModal();
  }

  /**
   * 모바일 기준 헬프센터 검색 모달을 띄운다.
   * @return {Promise<void>}
   */
  async openSearchModal(): Promise<void> {
    if (this.isOpenMobileSearchModal && this.app.modal) {
      this.isOpenMobileSearchModal = false;
      return;
    }

    this.isOpenMobileSearchModal = true;
    this.app.modal = await this.modalCtrl.create({
      component: HelpCenterSearchModalComponent,
      componentProps: {
        isModal: true
      },
      cssClass: 'modal-size-help-center'
    });

    this.app.modal.onDidDismiss().then(() => {
      this.app.modal = null;
      this.isOpenMobileSearchModal = false;
    });

    await this.app.modal.present();
  }

  /**
   * 사이즈 변경에 따른 검색 모달 닫기
   * @return {Promise<void>}
   */
  @HostListener('window:resize', ['$event'])
  handleResize() {
    if (this.app.isDesktopWidth() && this.app.modal) {
      this.app.modal.dismiss();
      this.app.modal = null;
      this.isOpenMobileSearchModal = false;
    }
  }
}
