import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface Page {
  label: number;
  value: number;
  index: number;
}

export interface ConfigInstance {
  id?: string;
  itemsPerPage: number;

  currentPage: number;

  totalItems?: number;
}

@Directive({
  selector: '[appPaginationExtend]',
  exportAs: 'appPaginationExtend'
})
export class PaginationExtendDirective implements OnChanges {
  public pages: Page[] = [];
  @Input() instance: ConfigInstance;
  @Input() maxSize: number;
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageBoundsCorrection = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.instance) {
      this.pages = [];
      this.setTotalPages();
    }
  }

  /**
   * 앞으로 가기 버튼, 한페이지를 넘어가는게 아니라 한 세트가 넘어가짐
   * @return {void}
   */
  previous(): void {
    this.setCurrent(this.pages[0].value - this.maxSize);
  }
  /**
   * 뒤로 가기 버튼, 한페이지를 넘어가는게 아닐 한세트가 넘어감
   * @return {void}
   */
  next(): void {
    this.setCurrent(this.pages[this.maxSize - 1].value + 1);
  }
  /**
   * 전체 페이지에서 첫번째 페이지인지 확인해주는 함수
   * @return {void}
   */
  isFirstPage(): boolean {
    return this.pages[0].value === 1;
  }
  /**
   * 전체 페이지에서 마지막 페이지인지 확인해주는 함수
   * @return {boolean}
   */
  isLastPage(): boolean {
    return this.pages[this.pages.length - 1].value === Math.ceil(this.instance.totalItems / this.instance.itemsPerPage);
  }
  /**
   * 현재 페이지를 변경해주는 함수
   * @return {void}
   */
  setCurrent(pageValue: number): void {
    this.instance.currentPage = pageValue;
    this.pageChange.emit(pageValue);
  }
  /**
   * 현재 세팅된 페이지를 retunr해주는 함수
   * @return {number}
   */
  getCurrent(): number {
    const currentPage = this.pages.find((page) => page.value === this.instance.currentPage);
    return currentPage?.index || 0;
  }

  /**
   * 한세트 페이지 세팅해주는 함수
   * @return {void}
   */
  setTotalPages(): void {
    const firstPage = this.maxSize * Math.floor((this.instance.currentPage - 1) / this.maxSize) + 1;
    const totalPageLength = Math.ceil(this.instance.totalItems / this.instance.itemsPerPage);
    for (let i = 0; i < this.maxSize; i++) {
      const setValue: Page = { value: firstPage + i, label: firstPage + i, index: i };
      if (setValue.value > totalPageLength) {
        break;
      } else {
        this.pages.push(setValue);
      }
    }
  }
}
