export enum Char3DSideBarType {
  Character = 'character',
  AiStory = 'AIstory',
  Item = 'item',
  Background = 'background',
  Effect = 'effect',
  Environment = 'environment',
  MyPage = 'mypage'
}

export enum ResourceType3d {
  Character3D = 'character3D',
  Background3D = 'background3D',
  Character = 'Character',
  Bone = 'Bone'
}

export enum ControlMode {
  Select = 'select',
  Translate = 'translate',
  Rotate = 'rotate',
  Scale = 'scale'
}

export enum ControlSpace {
  Local = 'local',
  World = 'world'
}

export enum MenuType {
  LeftMenu = 'leftMenu',
  ModeMenu = 'modeMenu',
  SceneCut = 'sceneCut'
}

export enum ItemStatus {
  Publish = 'publish',
  Prepare = 'prepare',
  AdminView = 'adminView'
}

export enum MagicOptions {
  Width = 'width',
  Height = 'height',
  Denoising = 'denoising',
  PicCount = 'pic-count',
  HiresSteps = 'hires-steps',
  BGRemover = 'bg-remover'
}

/**
 * 충돌 방지를 위한 임시 서비스타입
 * TODO: develop 브랜치에 머지할때 app.enum.ts 의 ServiceType 으로 이동
 */
export enum ServiceTypeTemp {
  All = 'all',
  LandingMain = 'tooning-landing-main',
  Editor = 'editor',
  Magic = 'magic',
  Character = 'character',
  Td = 'td'
}

export enum TitleBarButtonKey {
  Size = 'size',
  TabOut = 'tabOut',
  TabIn = 'tabIn',
  Close = 'close'
}

export enum DirectionKey {
  LowerLeft = 'left',
  LowerRight = 'right',
  UpperLeft = 'Left',
  UpperRight = 'Right'
}

export enum OutputKey {
  SelectIndex = 'selectIndex'
}

export enum AnimationType {
  Motion = 'motion',
  Pose = 'pose'
}

export enum ResourceType {
  Character = 'character',
  CharacterStyle = 'style',
  Animation = 'animation',
  Background = 'background'
}

export enum TdCharacterPartType {
  HairFront = 'hairFront',
  HairSide = 'hairSide',
  HairBack = 'hairBack',
  SetOutfit = 'setOutfit',
  LayeringTop = 'layeringTop',
  Top = 'top',
  Bottom = 'bottom',
  Outer = 'outer',
  Shoes = 'shoes',
  Acc = 'acc',
  AllBody = 'allBody',
  LeftEye = 'leftEye',
  LeftEyebrow = 'leftEyebrow',
  RightEye = 'rightEye',
  RightEyebrow = 'rightEyebrow',
  Mouth = 'mouth'
}

export enum TdRenderMode {
  Toon = 'toon',
  Outline = 'outline'
}

export enum CreateNameType {
  HairFront = '앞머리',
  HairSide = '옆머리',
  HairBack = '뒷머리',
  SetOutfit = '한벌옷',
  LayeringTop = '겹상의',
  Top = '상의',
  Bottom = '하의',
  Outer = '겉옷',
  Shoes = '신발',
  Acc = '악세사리'
}

export enum ResourceMeshName {
  Control = 'Control',
  Body = 'Body',
  Ani = 'Ani_',
  LeftEye = 'Base_L_Eye',
  LeftEyebrow = 'Base_L_Eyebrow',
  RightEye = 'Base_R_Eye',
  RightEyebrow = 'Base_R_Eyebrow',
  Mouth = 'Base_Mouth'
}

export enum TransformKey {
  Position = 'position',
  Rotation = 'rotation',
  Scale = 'scale',
  Target = 'target'
}
