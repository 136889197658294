<div [class]='app.currentService' class='tab-container'>
    <div class='max-width-container d-flex'>

        <div (click)='goToSelectService("studio")'
             [ngClass]='app.checkServiceType()===serviceTypeEnum.Studio&&"selected-cell"'
             class='tab-cell'
             id='top-header-studio-tap'>
            <span>{{'studio.tab_title'|translate}}</span>
        </div>

        <div (click)='goToSelectService("magic")' *ngIf="app.isAdmin"
             [ngClass]='app.checkServiceType()===serviceTypeEnum.Magic&&"selected-cell"'
             class='tab-cell'
             id='top-header-magic-tap'>
            <span>{{'magic-landing.dep1.text2'|translate}}</span>
            <sup class='plus-superscript'>PLUS</sup>
        </div>
        <div (click)='goToSelectService("editor")' *ngIf="app.isAdmin"
             [ngClass]='app.checkServiceType()===serviceTypeEnum.Editor&&"selected-cell"'
             class='tab-cell'
             id='top-header-editor-tap'>
            <span>{{'tooning editor'|translate}}</span>
            <sup class='plus-superscript'>PLUS</sup>
        </div>


    </div>
</div>
