import { keys } from 'ts-transformer-keys';
import { ResourceType, Version } from '../enum/app.enum';
import { SafeUrl } from '@angular/platform-browser';
import { RecListInterface, TextHistoryInterface } from './app.interface';

// 키 함수를 쓰기위해 활성화 한다.

/**
 * packages/tooning-ionic-admin/dist/out-tsc/interfaces/app.interface.js  - 실제 빌드에서 추가 되는 파일 - 값 재빌드시 자동 추가
 * 기본 Fabric Object 의 property 를 확장 해서 사용하기 위해 확장돤 Tooning property를 아래와 같이 정의 한다.
 */
interface TooningProperty {
  isView: any;
  characterVersion: Version;
  etcUploadVersion: Version;
  imgIsChange: string;
  checked: boolean;
  checkBoxVisible: boolean;
  isUploading: boolean;
  already_fabric_object: string;
  resource_id: number;
  previous_conversion_type: string;
  resource_type: ResourceType;
  resource_type_sub: string;
  resource_direction: string;
  resource_preview: string;
  resource_previewList: string;
  resource_name: string;
  isBubbleGroup: boolean;
  resource_name_en: string;
  resource_name_fr: string;
  resource_name_jp: string;
  resource_layer_name: string;
  resource_selection: string;
  resource_selection_id: string;
  resource_order: string;
  resource_bundle: string;
  resource_sku: string;
  resource_is_head_position_change: string;
  resource_head_original_position: string;
  resource_is_body_position_change: string;
  resource_body_original_position: string;
  resource_filterType: string;
  resource_isVisible: object;
  original_object?: string;
  selectable: boolean;
  evented: boolean;
  id: number;
  fill_id: string;
  stroke_id: string;
  legIndex: number;
  armIndex: number;
  direction: string;
  part: string;
  targetTextId: string;
  relativePositionX: number;
  relativePositionY: number;
  rotationShapeBodyX: number;
  rotationShapeBodyY: number;
  rotationShapeHeadX: number;
  rotationShapeHeadY: number;
  recList: RecListInterface;
  bodyAngle: string;
  headAngle: string;
  headAngleNumber: string;
  colorSet: object;
  colorSetIs: boolean;
  tags: string[];
  textHistory: TextHistoryInterface;
  breakWords: boolean;
  maxHeaderRotation: number;
  onBlur: boolean;
  isFitWaterMark: boolean;
  strokeUniform: boolean;
  objects: any;
  threshold: number;
  edgeCurrentImgSrc: string;
  edgeBackToImgSrc: string;
  isEdgeDetected: boolean;
  edgeOriginalFlipX: boolean;
  edgeOriginalFlipY: boolean;
  edgeOriginalWidth: number;
  edgeOriginalHeight: number;
  edgeOriginalScaleX: number;
  edgeOriginalScaleY: number;
  resource_preview_trust_url?: SafeUrl;
  styles: object;
  toPngBeforeAngle: number;
  crossOrigin: string;
  cashImg: boolean;
  parentId: string;
  userInputText: string;
  isEditing: boolean;
  bgInfo: object;
  wasText: boolean;
  lockMovementX: boolean;
  lockMovementY: boolean;
  lockScalingX: boolean;
  lockScalingY: boolean;
  lockRotation: boolean;
  hasControls: boolean;
  borderScaleFactor: number;
  borderColor: string;
  padding: number;
  isFirstChangeColor: boolean;
  isEditableOnSamsungKeyboard: boolean;
  originScaleX?: number;
  originScaleY?: number;
}

const TooningPropertyKeys = keys<TooningProperty>();

export { TooningProperty, TooningPropertyKeys };
