import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PayGuard } from './guards/pay.guard';
import { AdminGuard } from './guards/admin.guard';

// loadChildren: () => import('./pages-marketing/studio/studio.module').then((m) => m.StudioPageModule)

// @ts-ignore
const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Tooning Studio',
      helpCenter: false,
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.landing.description',
      imageUrl: 'https://tooning.io/assets/kakao/tooning2.png',
      serviceID: 'studio'
    },
    loadChildren: () => import('./pages-studio/studio-index/studio-index.module').then((m) => m.StudioIndexPageModule)
  },
  {
    path: 'ir',
    data: {
      title: 'Tooning Studio IR',
      helpCenter: false,
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.landing.description',
      imageUrl: 'https://tooning.io/assets/kakao/tooning2.png',
      serviceID: 'studio'
    },
    loadChildren: () => import('./pages-marketing/stuido-ir/stuido-ir-routing.module').then((m) => m.StuidoIRPageRoutingModule)
  },
  {
    path: 'signup',
    data: { title: 'Signup', splitPaneDisabled: true, sidemenu: false, topMenu: false, mostChrome: false },
    loadChildren: () => import('./pages-member/login/login.module').then((m) => m.LoginPageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages-member/login/login.module').then((m) => m.LoginPageModule),
    data: { title: 'Login', splitPaneDisabled: true, sidemenu: false, topMenu: false, mostChrome: false }
  },
  {
    path: 'login/:root/:canvasId',
    loadChildren: () => import('./pages-member/login/login.module').then((m) => m.LoginPageModule),
    data: { title: 'Login', splitPaneDisabled: true, sidemenu: false, topMenu: false, mostChrome: false }
  },
  {
    path: 'login/:reactive',
    loadChildren: () => import('./pages-member/login/login.module').then((m) => m.LoginPageModule),
    data: { title: 'Login', splitPaneDisabled: true, sidemenu: false, topMenu: false, mostChrome: false }
  },
  {
    path: 'magic',
    data: {
      title: 'Tooning Magic',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'magicLanding',
      topMenu: false,
      mostChrome: false,
      serviceID: 'magic'
    },
    loadChildren: () => import('./pages-magic/pages-magic.module').then((m) => m.PagesMagicPageModule)
  },
  {
    path: 'magic/editor',
    canActivate: [AuthGuard],
    data: {
      title: 'Magic Editor',
      topMenu: false,
      editor: true,
      serviceID: 'magic'
    },
    loadChildren: () => import('./pages-4cut/ai-drawing/ai-drawing.module').then((m) => m.AiDrawingPageModule)
  },
  {
    path: 'tooning-editor',
    data: {
      title: 'Tooning',
      topMenu: false,
      mostChrome: false,
      serviceID: 'editor'
    },
    loadChildren: () => import('./pages-tooning/tooning-landing-main-v2/tooning-landing-main-v2.module').then((m) => m.TooningLandingMainV2PageModule)
  },
  {
    path: 'template-list',
    redirectTo: 'template-list/home',
    pathMatch: 'full'
  },
  {
    path: 'card-registration/:plan',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages-tooning/card-registration/card-registration.module').then((m) => m.CardRegistrationPageModule)
  },
  {
    path: 'point-card-registration',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages-tooning/point-card-registration/point-card-registration.module').then((m) => m.PointCardRegistrationPageModule)
  },
  {
    path: 'card-registration-complete/:isModal',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    canActivate: [PayGuard],
    loadChildren: () =>
      import('./pages-tooning/card-registration-complete/card-registration-complete.module').then((m) => m.CardRegistrationCompletePageModule)
  },
  {
    path: 'point-card-registration-complete/:pointAmount',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    canActivate: [PayGuard],
    loadChildren: () =>
      import('./pages-tooning/point-card-registration-complete/point-card-registration-complete.module').then(
        (m) => m.PointCardRegistrationCompletePageModule
      )
  },
  {
    path: 'account-setting',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages-tooning/account-setting/account-setting.module').then((m) => m.AccountSettingPageModule)
  },
  {
    path: 'tooning-price-plan',
    data: {
      title: 'meta.payment.title',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.payment.description',
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/tooning-price-plan/tooning-price-plan.module').then((m) => m.TooningPricePlanPageModule)
  },
  {
    path: 'gpt/tooning-price-plan',
    data: {
      title: 'meta.payment.title',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.payment.description',
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/tooning-price-plan/tooning-price-plan.module').then((m) => m.TooningPricePlanPageModule)
  },
  {
    path: 'pricing-plan-inquiry',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/pricing-plan-inquiry/pricing-plan-inquiry.module').then((m) => m.PricingPlanInquiryPageModule)
  },
  {
    path: 'external-issued-account/:estimateId',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-tooning/external-issued-account/external-issued-account.module').then((m) => m.ExternalIssuedAccountPageModule)
  },
  {
    path: 'help-center/:serviceType',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/help-center/help-center.module').then((m) => m.HelpCenterPageModule)
  },
  {
    path: 'help-detail/:faqId',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/help-detail/help-detail.module').then((m) => m.HelpDetailPageModule)
  },
  {
    path: 'paid-guide-modal',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-common/paid-guide-modal/paid-guide-modal.module').then((m) => m.PaidGuideModalPageModule)
  },
  {
    path: 'card-registration-complete',
    canActivate: [PayGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () =>
      import('./pages-tooning/card-registration-complete/card-registration-complete.module').then((m) => m.CardRegistrationCompletePageModule)
  },
  {
    path: 'card-registration-fail',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/card-registration-fail/card-registration-fail.module').then((m) => m.CardRegistrationFailPageModule)
  },
  {
    path: 'point-card-registration-complete',
    canActivate: [PayGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    loadChildren: () =>
      import('./pages-tooning/point-card-registration-complete/point-card-registration-complete.module').then(
        (m) => m.PointCardRegistrationCompletePageModule
      )
  },
  {
    path: 'account-setting',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/account-setting/account-setting.module').then((m) => m.AccountSettingPageModule)
  },
  {
    path: 'payment-history',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/payment-history/payment-history.module').then((m) => m.PaymentHistoryPageModule)
  },
  {
    path: 'tooning-setting',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/tooning-setting/tooning-setting.module').then((m) => m.TooningSettingPageModule)
  },
  {
    path: 'terms-of-use',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/terms-of-use/terms-of-use.module').then((m) => m.TermsOfUsePageModule)
  },
  {
    path: 'privacy-statement',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/privacy-statement/privacy-statement.module').then((m) => m.PrivacyStatementPageModule)
  },
  // font
  {
    path: 'font-make-list',
    canActivate: [AuthGuard],
    data: { title: '폰트 관리', sidemenu: true, topMenu: true, mostChrome: true, sidemenuID: 'font' },
    loadChildren: () => import('./pages-res-maker/font-make/font-make-list/font-make-list.module').then((m) => m.FontMakeListPageModule)
  },
  // 선생님 인증
  {
    path: 'teacher-certification',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-tooning/teacher-certification/teacher-certification.module').then((m) => m.TeacherCertificationPageModule)
  },

  // 툰편집
  {
    path: '4cut-list',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.work.description'
    },
    loadChildren: () => import('./pages-4cut/cut4-list/cut4-list.module').then((m) => m.Cut4ListPageModule)
  },
  {
    path: '4cut-make-manual2/:id',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: true,
      editor: true
    },
    loadChildren: () => import('./pages-4cut/cut4-make-manual2/cut4-make-manual2.module').then((m) => m.Cut4MakeManual2PageModule)
  },
  {
    path: 'canvas-share/:id',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-4cut/canvas-share/canvas-share.module').then((m) => m.CanvasSharePageModule)
  },
  {
    path: 'canvas-share/global',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false
    },
    loadChildren: () => import('./pages-4cut/canvas-share/canvas-share.module').then((m) => m.CanvasSharePageModule)
  },
  {
    path: '4cut-edit-3d',
    canActivate: [AuthGuard],
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: false,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: true,
      editor: true
    },
    loadChildren: () => import('./pages-4cut/cut4-edit-three-d/cut4-edit-three-d.module').then((m) => m.Cut4EditThreeDPageModule)
  },
  {
    path: 'template-list/:root',
    data: {
      title: 'meta.templates.title',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.templates.description',
      imageUrl: 'https://tooning.io/assets/template/tooning_templates_array.png',
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/template-list/template-list.module').then((m) => m.TemplateListPageModule)
  },
  {
    path: 'template-detail/:searchText',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/template-detail/template-detail.module').then((m) => m.TemplateDetailPageModule)
  },
  {
    path: 'template-detail/:searchText/:language',
    data: {
      title: 'Tooning',
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      helpCenter: false
    },
    loadChildren: () => import('./pages-tooning/template-detail/template-detail.module').then((m) => m.TemplateDetailPageModule)
  },

  // 잘못된 URL 접근시 페이지 이동
  { path: '**', redirectTo: '/page404', pathMatch: 'full' },
  {
    path: 'page404',
    data: {
      title: 'Tooning Studio',
      helpCenter: false,
      splitPaneDisabled: true,
      sidemenu: true,
      sidemenuID: 'tooningLanding',
      topMenu: false,
      mostChrome: false,
      description: 'meta.landing.description',
      imageUrl: 'https://tooning.io/assets/kakao/tooning2.png',
      serviceID: 'studio'
    },
    loadChildren: () => import('./pages-common/page404/page404.module').then((m) => m.Page404PageModule)
  }
];

// @ts-ignore
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class StudioRoutingModule {}
