import { CommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { ResourceType, SelectedType } from '../../enum/app.enum';

/**
 * Class Command
 */
class Command {
  public target: {
    type: any;
    resource_id?: number;
    resource_selection_id: string;
    getObjects: () => Array<any>;
    resource_type: string;
    isMultiSelected?: boolean;
  };

  protected readonly type: string;
  protected id: string[];
  protected cut: any;
  protected commandName: string;

  /**
   * Create Command
   * @param {CommandData} data
   */
  constructor(data: CommandData) {
    this.id = [];
    this.cut = data.cut;
    this.commandName = 'command';
    if (data.target) {
      this.target = data.target;
      if (data.target.type === SelectedType.activeSelection || data.target.isMultiSelected) {
        this.type = SelectedType.activeSelection;
        const list = data.target.getObjects();
        for (const element of list) {
          this.id.push(element.resource_selection_id);
        }
      } else {
        this.id = [data.target.resource_selection_id];
        this.type = SelectedType.singleSelection;
      }
    } else {
      this.target = undefined;
      this.id = undefined;
      this.type = undefined;
    }
  }

  /**
   * 리소스 구매시 waterMark 를 제거한다.
   * @param resource_id
   * @return {void}
   */
  removeWaterMark(resource_id: number): void {
    try {
      if (this.target && this.target.resource_id === resource_id) {
        const waterMark = this.cut.getLastObj(this.target as TooningFabricObject);
        if (waterMark && waterMark.resource_type === ResourceType.waterMark) {
          waterMark.visible = false;
          console.log(`${this.target.resource_id} remove water mark ${this.constructor.name}`);
          this.updatePageThumbnail();
        } else {
          console.warn(`리소스 타입이 워터마크가 아니거나, 워터마크가 없습니다. - in command.ts`);
        }
      }
    } catch (e) {
      throw new Error(`${this.constructor.name} removeWaterMark error : ${e.message}`);
    }
  }

  /**
   * Execute Command
   * @param objects
   */
  execute(objects?) {}

  /**
   *  Redo Command
   * @param objects
   * @param cut
   */
  redo(objects?, cut?) {}

  /**
   * Undo Command
   * @param objects
   * @param cut
   */
  undo(objects?, cut?) {}

  /**
   * 캔버스 우측 pageList 의 썸네일을 update 해준다.
   */
  updatePageThumbnail() {
    try {
      if (this.cut.pageList.length > 0) {
        this.cut.updatePagePreviewInLocal(this.cut.panelIndex);
        this.cut.canvas.requestRenderAll();
      }
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { Command };
