import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from '../../../services/app.service';
import { ModalController } from '@ionic/angular';
import { LanguageOriginalType, ServiceType } from '../../../enum/app.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public languageOriginalEnum = LanguageOriginalType;
  public serviceTypeEnum = ServiceType;

  constructor(public app: AppService, public modalCtrl: ModalController) {}

  ngOnInit() {

  }
}
