import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { AuthorType } from '../../enum/app.enum';
import { FeatureLimitedList } from './featureLimitedList';

export class Character {
  @IsNumber() @IsNotEmpty() id: number;
  // tslint:disable-next-line:variable-name
  @IsNumber() @IsNotEmpty() resource_id: number;
  // tslint:disable-next-line:variable-name
  @IsString() @IsNotEmpty() name_ko: string;
  // tslint:disable-next-line:variable-name
  @IsString() @IsNotEmpty() name_en: string;
  @IsEnum(AuthorType) @IsNotEmpty() authorType: AuthorType;
  // tslint:disable-next-line:variable-name
  @IsString() @IsOptional() resource_preview: string;
  // tslint:disable-next-line:variable-name
  @IsString() @IsOptional() resource_name: string;
  // tslint:disable-next-line:variable-name
  @IsString() @IsOptional() resource_type: string;
  // tslint:disable-next-line:variable-name
  @IsBoolean() @IsOptional() visible: boolean;
  @IsArray() @IsOptional() objects: Array<any>;

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.id) {
      this.resource_id = this.id;
    } else {
      this.id = this.resource_id;
    }
    return this;
  }
}

export class CharacterFeatureLimit {
  @IsBoolean() @IsNotEmpty() isView: boolean;
  @IsObject() @IsOptional() list?: FeatureLimitedList;

  deserialize(input: any) {
    Object.assign(this, input);

    this.list = new FeatureLimitedList().deserialize(input);
    return this;
  }
}
