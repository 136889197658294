import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { PopoverController } from '@ionic/angular';
import { AnalyticsService } from '../../../../services/google/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { TooningCanvasShareLinkCopyError } from '../../../errors/TooningErrors';

@Component({
  selector: 'app-popover-linkcopy',
  templateUrl: './popover-linkcopy.component.html',
  styleUrls: ['./popover-linkcopy.component.scss']
})
export class PopoverLinkcopyComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('canvasId') canvasId;
  public url: string;
  public appVersion: string;
  public readyToRender: boolean;

  constructor(
    public app: AppService,
    public popoverController: PopoverController,
    public analyticsService: AnalyticsService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.appVersion = environment.appVersion;
    this.url = `${environment.share_server_url}/canvas-share/${this.canvasId}`;
  }

  async close() {
    await this.popoverController.dismiss();
  }

  async copied(event) {
    await this.app.showToast(this.translate.instant('SHARE_LINK_COPY.COMPLETE'), 300);
    await this.popoverController.dismiss({
      copy: true
    });
  }

  async copyError(event) {
    console.error(event);
    await this.app.showToast(this.translate.instant('SHARE_LINK_COPY.FAIL'), 300);
    await this.popoverController.dismiss({
      copy: false
    });
    throw new TooningCanvasShareLinkCopyError(event.message, this.app, true);
  }
}
