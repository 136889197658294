import { Component, Input, OnInit } from '@angular/core';
import { BasePage } from '../../../../base-page';
import { AppService } from '../../../../services/app.service';
import { PopoverController } from '@ionic/angular';
import { CanvasGroupService } from '../../../../services/canvasGroup/canvas-group.service';
import { TranslateService } from '@ngx-translate/core';
import { CanvasGroupShare } from '../../../../model/canvasGroup/canvasGroup';
import { TooningCanvasGroupCodeCopyError } from '../../../errors/TooningErrors';

@Component({
  selector: 'app-popover-sharing-canvas',
  templateUrl: './popover-sharing-canvas.component.html',
  styleUrls: ['./popover-sharing-canvas.component.scss']
})
export class PopoverSharingCanvasComponent extends BasePage implements OnInit {
  public isSharing: boolean = false;
  public canvasGroupId: string;
  @Input('canvasGroup') canvasGroup;

  constructor(
    public app: AppService,
    public popoverCtrl: PopoverController,
    public canvasGroupService: CanvasGroupService,
    public translate: TranslateService
  ) {
    super(app);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isSharing = this.canvasGroup.share;
    this.canvasGroupId = `${this.app.cache.user.id}@${this.canvasGroup.id}`;
  }

  async close() {
    if (this.app.popover !== null) {
      await this.popoverCtrl.dismiss();
    }
  }

  async changeIsSharing() {
    const canvasGroupShare = new CanvasGroupShare();
    canvasGroupShare.id = 17;
    console.log(`this.isSharing :${this.isSharing}, canvasGroupId : ${this.canvasGroup.id}`);
    canvasGroupShare.share = this.isSharing;
    canvasGroupShare.id = this.canvasGroup.id;
    const result = await this.canvasGroupService.canvasGroupShare(canvasGroupShare).toPromise();
    this.canvasGroup.share = result.data.canvasGroupShare.share;
  }

  copyToClipboard(el: HTMLDivElement) {
    console.log(el.innerText);

    if (navigator.clipboard) {
      navigator.clipboard.writeText(el.innerText).then(
        () => {
          this.app.showToast(this.translate.instant('share-folder.The code copied.'));
        },
        (error) => {
          this.app.showToast(this.translate.instant('share-folder.An error has occurred. please try again later.'));
          throw new TooningCanvasGroupCodeCopyError(error);
        }
      );
    } else {
      console.log('This browser does not support Clipboard API');
    }
  }
}
