import { Command } from './command';
import { ObjectGroupCommandData, TooningFabricObject } from '../../interfaces/app.interface';
import { fabric } from 'fabric';

/**
 * Class ObjectGroupCommand
 * @extends Command
 */
class ObjectGroupCommand extends Command {
  protected ob;
  protected afterGroupID;
  protected callback;

  /**
   * Create ObjectGroupCommand
   * @param {ObjectGroupCommand} data
   */
  constructor(data: ObjectGroupCommandData) {
    super(data);
    this.callback = data.callback;
    this.commandName = 'Object-Group-Command';
  }

  /**
   * Execute ObjectGroupCommand
   */
  async execute() {
    try {
      super.execute();
      this.ob = this.callback();
      this.afterGroupID = this.ob.resource_selection_id;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Redo ObjectGroupCommand
   * @param {TooningFabricObject[]} objects group command redo할 objects
   */
  async redo(objects: TooningFabricObject[]) {
    try {
      super.redo(objects);
      this.cut.canvas.discardActiveObject();
      const selectionList = [];
      objects.forEach((object) => {
        for (const element of this.id) {
          if (object.resource_selection_id === element) {
            selectionList.push(object);
          }
        }
      });
      const selectedObjects = new fabric.ActiveSelection(selectionList, {
        canvas: this.cut.canvas
      });
      this.cut.canvas.setActiveObject(selectedObjects);
      selectedObjects.setCoords();
      const ob = this.cut.groupCreateGo();
      ob.resource_selection_id = this.afterGroupID;
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Undo ObjectGroupCommand
   * @param {TooningFabricObject[]} objects group command undo할 objects
   */
  async undo(objects: TooningFabricObject[]) {
    try {
      super.undo(objects);
      for (const element of objects) {
        if (element.resource_selection_id === this.afterGroupID) {
          this.cut.onFocus(element);
          await this.cut.groupCancelGo();
          break;
        }
      }
      this.updatePageThumbnail();
    } catch (e) {
      throw new Error(e);
    }
  }
}

export { ObjectGroupCommand };
