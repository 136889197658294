import { fromEvent, Observable, Subscription } from 'rxjs';

export class IdleTimer {
  timeout: number;
  onTimeout: any;
  eventHandler: any;
  timeoutTracker: any;
  interval: any;
  mousemoveObservable$: Observable<Event>;
  mouseclickObservable$: Observable<Event>;
  mousemoveSubscription$: Subscription;
  mouseclickSubscription$: Subscription;

  /**
   * 특정 시간동안 아무런 움직임이 없으면, 해당 유저를 로그아웃 시킨다.
   * @param timeout - 몇 초동안 움직임 없으면 로그아웃 시킬건지?
   * @param onTimeout - timeout 된 경우 콜백 함수
   * @param onExpired - expire 된 경우 콜백 함수
   */
  constructor({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    // check the initial state if already exist expiredTime
    const expiredTime = parseInt(localStorage.getItem('_expiredTime') || '0', 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      this.cleanUp();
      return;
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  /**
   * interval to track expired time in localStorage (every 1s)
   */
  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      // B 탭에서 expired 된 경우, A 탭의 localStorage 는 비어있기 때문에 null 인 경우, 0 으로 세팅
      const expiredTime = parseInt(localStorage.getItem('_expiredTime') || '0', 10);

      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  /**
   * calculate new expired time
   */
  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    // 모든 움직임마다 계속 업데이트하면 부하갈 수 있으니까 움직임이 있고 100ms 후에 expiredTime 갱신하도록
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem('_expiredTime', String(Date.now() + this.timeout * 1000));
    }, 100);
  }

  /**
   * add the event listeners to track user interaction
   */
  tracker() {
    this.mousemoveObservable$ = fromEvent(window, 'mousemove');
    this.mousemoveSubscription$ = this.mousemoveObservable$.subscribe((event) => {
      this.updateExpiredTime();
    });
    this.mouseclickObservable$ = fromEvent(window, 'click');
    this.mouseclickSubscription$ = this.mouseclickObservable$.subscribe((event) => {
      this.updateExpiredTime();
    });
  }

  /**
   * timeout 으로 로그아웃 된 경우, 관련 변수 전부 제거
   */
  cleanUp() {
    localStorage.removeItem('_expiredTime');
    clearInterval(this.interval);
    if (this.mousemoveSubscription$) {
      this.mousemoveSubscription$.unsubscribe();
    }
    if (this.mouseclickSubscription$) {
      this.mouseclickSubscription$.unsubscribe();
    }
  }
}
